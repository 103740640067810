import React from 'react'
import { Headline } from '@velocity/ui'
import { connect } from 'react-redux'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { AppState } from 'mlp-client/src/types'
import { getUser } from 'mlp-client/src/user/selectors'

export interface StepConfig {
  showInsuranceDepartmentLabel?: boolean
  showTimelineLabel?: boolean
  amountOfWorkingDays?: number
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
  email: string
}

export class ThankYouStep extends React.PureComponent<Props> {
  render() {
    const {
      config: {
        showInsuranceDepartmentLabel,
        amountOfWorkingDays,
        showTimelineLabel,
      },
      email,
      closeFlow,
    } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id="myLeaseplan.damageReport.steps.thankYou.pageTitle" />
        </Headline>
        <Section headerText="myLeaseplan.damageReport.steps.thankYou.whatsNextHeader">
          <Translation
            id="myLeaseplan.damageReport.steps.thankYou.reviewLabel"
            replace={{ daysAmount: amountOfWorkingDays }}
          />
          {showInsuranceDepartmentLabel ? (
            <Translation id="myLeaseplan.damageReport.steps.thankYou.insuranceDepartment" />
          ) : (
            <Translation
              id="myLeaseplan.damageReport.steps.thankYou.informLabel"
              replace={{ email }}
            />
          )}

          {showTimelineLabel && (
            <Translation id="myLeaseplan.damageReport.steps.thankYou.timelineLabel" />
          )}
        </Section>
      </ThankyouPageLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'email'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  email: getUser(state).email,
})

export default connect(mapStateToProps)(ThankYouStep)
