import { getCookies } from 'mlp-client/src/cookies'

import { CID_KEY } from './constants'

export const getCIDFromCookie = () => {
  const cookies = getCookies()

  // GA1.1.22222222.11111111 -> 22222222.11111111, GA1.1. is redundant
  return cookies[CID_KEY]?.slice(6) ?? ''
}
