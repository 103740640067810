import { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'

import * as actions from './actions'
import { getDateWighoutOffset, getSubmitBuyRequestBody } from './utils'

const defaultRequestOptions = {
  throwError: true,
}

export function* buyRequest({
  payload: { flowData, onComplete },
}: actions.SubmitBuyRequest): SagaIterator {
  const body = yield call(getSubmitBuyRequestBody, flowData)
  const requestBody = {
    body: {
      ...body,
      buyoutDate: getDateWighoutOffset(flowData.buyDate),
    },
  }
  let isRequestSuccessful: boolean = true

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/vehicle-buyouts/',
      requestBody,
      defaultRequestOptions,
    )
  } catch {
    isRequestSuccessful = false
  } finally {
    yield call(onComplete, !isRequestSuccessful)
  }
}

export default [takeLatest(actions.ActionTypes.BUY_REQUEST, buyRequest)]
