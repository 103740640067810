import { Address, AddressTypes } from 'mlp-client/src/user/types'

import { FormValues } from './LocationStep'

export const addressKeys = [
  'street',
  'houseNumber',
  'zipCode',
  'city',
  'country',
]

export const getInitialLocation = (
  address: Address,
  country: string,
): FormValues => {
  switch (address?.type) {
    case AddressTypes.HOME:
    case AddressTypes.WORK:
      return {
        addressType: address.type,
        country,
      }
    case AddressTypes.OTHER:
      return {
        addressType: address.type,
        street: address.addressLine1,
        houseNumber: address.houseNumber,
        zipCode: address.zipCode,
        city: address.city,
        country,
      }
    default:
      return { country }
  }
}
