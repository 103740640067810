export enum ContractTypes {
  LEASE = 'Lease',
  ORDER = 'Order',
}

export const enum LoadingStatuses {
  SUCCESS = 'success',
  FAILED = 'failed',
  LOADING = 'loading',
}

export enum ViewContractSections {
  VehicleDetails = 'vehicleDetails',
  Contract = 'contractDetails',
  Insurance = 'insurance',
  Tyres = 'tyres',
}

export enum VehicleSectionProperties {
  LICENSE_PLATE = 'LICENSE_PLATE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  COLOR = 'COLOR',
  VIN = 'VIN',
  FUEL = 'FUEL',
  TYPE = 'TYPE',
  POWER = 'POWER',
  CO2 = 'CO2',
  TRANSMISSION = 'TRANSMISSION',
  ESTIMATED_FUEL_KM = 'ESTIMATED_FUEL_KM',
}

export enum InsuranceSectionProperties {
  COMPANY = 'COMPANY',
  TYPE = 'TYPE',
  NUMBER = 'NUMBER',
  POLICY_NUMBER = 'POLICY_NUMBER',
  FULL_DESCRIPTION = 'FULL_DESCRIPTION',
}

export enum ContractSectionProperties {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  TAXATION_VALUE = 'TAXATION_VALUE',
  ENVIRONMENTAL_TAXATION_VALUE = 'ENVIRONMENTAL_TAXATION_VALUE',
  MAX_MILEAGE = 'MAX_MILEAGE',
  LAST_KNOWN_MILEAGE = 'LAST_KNOWN_MILEAGE',
  LAST_KNOWN_FUEL_MILEAGE = 'LAST_KNOWN_FUEL_MILEAGE',
  DATE_LAST_KNOWN_MILEAGE = 'DATE_LAST_KNOWN_MILEAGE',
  TYPE = 'TYPE',
  NUMBER = 'NUMBER',
  DISPLAY_NUMBER = 'DISPLAY_NUMBER',
  OBJECT_ID = 'OBJECT_ID',
  CONTRACTED_FUEL = 'CONTRACTED_FUEL',
  CONTRACTED_INSURANCE = 'CONTRACTED_INSURANCE',
  CONTRACTED_MAINTENANCE = 'CONTRACTED_MAINTENANCE',
  CONTRACTED_TYRES = 'CONTRACTED_TYRES',
}

export enum TyresSectionProperties {
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  LIMIT = 'LIMIT',
  SIZE = 'SIZE',
  STANDARD_TYRE_USED = 'STANDARD_TYRE_USED',
  WINTER_TYRE_USED = 'WINTER_TYRE_USED',
  REMAINING_TYRES = 'REMAINING_TYRES',
  TYRES_MODULE_DESCRIPTION = 'TYRES_MODULE_DESCRIPTION',
}

export enum ContractOptionsListProperties {
  MAINTENANCE = 'maintenance',
  ROADSIDE_ASSISTANCE = 'roadsideAssistance',
  TYRES = 'tyres',
  DAMAGE_SERVICE = 'damageService',
  RADIO_LICENCE_FEE = 'radioLicenceFee',
}

export enum ContractOptionsListMaintenanceProperties {
  MAINTENANCE_MODULE = 'maintenanceModule',
  REPLACEMENT_VEHICLE_MODULE = 'replacementVehicleModule',
  CAR_WASH_MODULE = 'carWashModule',
  PICK_UP_AND_DELIVERY_MODULE = 'pickUpAndDelivery',
}

export enum ContractOptionsListRoadsideAssistanceProperties {
  EUROPEAN_COVERAGE_MODULE = 'europeanCoverageModule',
}

export enum ContractOptionsListTyresProperties {
  TYRE_REPLACEMENT_MODULE = 'tyreReplacementModule',
  TYRE_STORAGE = 'tyreStorageModule',
  TYRE_STORAGE_LOCATION = 'tyreStorageLocation',
}

export enum DamageRecordStatus {
  OPEN = 'current',
  CLOSED = 'closed',
}

export enum ColumnLayout {
  single,
  double,
}

export enum ViewContractTabs {
  Contract = 'contractDetails',
  Vehicle = 'carDetails',
}

export enum NotificationStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DELETED = 'deleted',
}

export enum NotificationType {
  PREDICTIVE_MAINTENANCE = 'PredictiveMaintenance',
  PREDICTIVE_MILEAGE = 'PredictiveMileage',
}
