import {
  DashboardRoadsideAssistance,
  RoadSideAssistanceTileButton,
} from 'mlp-client/src/components/road-side-assistance/types'
import { getContentSection } from 'mlp-client/src/content/utils'
import { ContentPage, ParagraphSection } from 'mlp-client/src/content/types'

export const getRoadsideAssistanceButtonInfo = (
  part: string,
  roadsideAssistance: DashboardRoadsideAssistance,
  pageContent: ContentPage | undefined,
  dataTagId?: string,
): RoadSideAssistanceTileButton | null => {
  const { Title: message, Subtitle: telephone } =
    getContentSection<ParagraphSection>(pageContent?.Sections, part) ?? {}

  if (!telephone) {
    return null
  }

  return {
    telephone,
    message,
    modalTitle: roadsideAssistance.modalTitle,
    buttonLabel: roadsideAssistance.buttonLabel,
    dataTagId,
  }
}
