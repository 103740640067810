import Classnames from 'classnames'
import React from 'react'

export interface Props {
  code: string
  label: string
}

const Flag: React.FC<Props> = ({ code, label }) => (
  <span className={Classnames('flag-icon', `flag-icon--${code}`)}>
    <span className="flag-icon__label">{label}</span>
  </span>
)

export default Flag
