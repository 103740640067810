import { createSelector } from 'reselect'

import {
  getContracts,
  getLeaseContracts,
  getOrders,
} from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import { findOrderContract } from 'mlp-client/src/contracts/utils'
import { Locales } from 'mlp-client/src/localization/enums'

export const hasOrders = createSelector(
  getLeaseContracts,
  getOrders,
  (contracts, orders): boolean => contracts.length === 0 && orders.length > 1,
)

export const hasOnlyOneOrder = createSelector(
  getContracts,
  getOrders,
  (contracts, orders): boolean => contracts.length === 1 && orders.length === 1,
)

export const getOrderById = createSelector(
  getContracts,
  contracts => (contractId?: string): Contract =>
    contracts.find(contract => contract.id === contractId) ||
    findOrderContract(contracts),
)

export const isFrance = (locale: Locales): boolean =>
  [Locales.EN_FR, Locales.FR].includes(locale)

export const isDecomissioning = (locale: Locales): boolean =>
  [Locales.EN_FI, Locales.FI].includes(locale)
