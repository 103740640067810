import { AnalyticsState } from 'mlp-client/src/types'

import { Action, ActionTypes } from './actions'

export const defaultState: AnalyticsState = {
  trackedPageViews: [],
}

const analyticsReducer = (
  state: AnalyticsState = defaultState,
  action: Action,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.PAGE_VIEW:
      return {
        ...state,
        trackedPageViews: [action.payload, ...state.trackedPageViews],
      }
    default:
      return state
  }
}

export default analyticsReducer
