import {
  CashClaimCategory,
  StepNames,
} from 'mlp-client/src/flows/cash-claim/enums'
import {
  FlowData,
  FuelcardServiceType,
  StepKey,
} from 'mlp-client/src/flows/fuel-card/types'
import { FUEL_MANAGEMENT_TYPE } from 'mlp-client/src/contracts/types'

export const getFuelCardsSteps = (flowData: FlowData): readonly StepKey[] => {
  if (flowData.fuelCardServiceType === FuelcardServiceType.ALL_STOLEN) {
    return [StepKey.SELECT_FUEL_CARD, StepKey.ALL_LOST_AND_STOLEN_CONFIRM]
  } else {
    return [
      StepKey.SELECT_FUEL_CARD,
      StepKey.SELECT_SERVICE_TYPE,
      StepKey.THANK_YOU,
    ]
  }
}

export const getSubCategoryStep = (category: string): StepNames | undefined => {
  switch (category) {
    case CashClaimCategory.FUEL:
      return StepNames.FUEL_TYPE
    case CashClaimCategory.ROAD_SIDE_ASSISTANCE:
      return StepNames.ROAD_SIDE_ASSISTANCE
    case CashClaimCategory.LIQUIDS:
      return StepNames.LIQUIDS
  }
}

export const isFuelManagement = (type: string) => type === FUEL_MANAGEMENT_TYPE
