import { Spacing } from '@leaseplan/ui'
import React from 'react'

import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import ImagePreview from 'mlp-client/src/components/image-preview/ImagePreview'
import { Attachments } from 'mlp-client/src/types'

import { EditButtonLabel } from './EditButtonLabel'

interface Props {
  dataE2eId?: string
  photos: Attachments
  title: React.ReactElement | string
  onClick(): void
}

type DefaultProps = Required<Pick<Props, 'dataE2eId'>>
type InternalProps = DefaultProps & Props

export class PhotoTile extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    dataE2eId: 'photo',
  }

  render() {
    const { dataE2eId, title, photos, onClick, children } = this.props

    return (
      <FlowStepTile
        dataE2eId={dataE2eId}
        title={title}
        btnLabel={EditButtonLabel}
        onBtnClick={onClick}
      >
        {children && <Spacing mb={1}>{children}</Spacing>}
        <ImagePreview attachments={photos} />
      </FlowStepTile>
    )
  }
}
