import styled from 'styled-components'

/**
 * This component is here to help you render full width content
 *
 * ||||||||||||||||||||||||
 *   <FullWidthContent />
 * |||||              |||||
 * |||||              |||||
 * |||||  <Content /> |||||
 * |||||              |||||
 * |||||              |||||
 * ||||||||||||||||||||||||
 *
 */
export const FullWidthContent = styled.div`
  margin-left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
`
