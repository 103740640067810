import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { FlowData } from 'mlp-client/src/flows/fuel-card/types'
import { Options } from 'mlp-client/src/form/types'
import { CheckboxList } from 'mlp-client/src/form/components/fields/CheckboxFields'
import { noEmptyObject } from 'mlp-client/src/form/validations'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { arrayToFlagObject } from 'mlp-client/src/flows/fuel-card/utils'

export interface Props extends FlowProps<FlowData> {
  fuelCardsOptions: Options
  loadFuelTypes(): void
}

export interface FormData {
  fuelCardTypes: { [key: string]: boolean }
}

export class SelectFuelCardTypes extends React.PureComponent<Props> {
  onSubmit = ({ fuelCardTypes }: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData({ fuelCardTypes: Object.keys(fuelCardTypes) }, goToNextStep)
  }

  componentDidMount() {
    const { fuelCardsOptions, loadFuelTypes } = this.props

    if (!fuelCardsOptions.length) {
      loadFuelTypes()
    }
  }

  render() {
    const {
      fuelCardsOptions,
      flowData: { fuelCardTypes },
    } = this.props

    const SelectFuelCardStepForm = (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{ fuelCardTypes: arrayToFlagObject(fuelCardTypes) }}
      >
        {({ handleSubmit, hasValidationErrors }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <CheckboxList
              name="fuelCardTypes"
              options={fuelCardsOptions}
              validations={[noEmptyObject]}
            />
            <Spacing mt={2} />
            <NextStepButton
              onClick={handleSubmit}
              disabled={hasValidationErrors}
            />
          </form>
        )}
      </Form>
    )

    if (!fuelCardsOptions.length) {
      return null
    }

    return (
      <TwoColumnFlowStep titleLabel="myLeaseplan.fuelCard.steps.selectFuelCardTypes.legacyTitle">
        {SelectFuelCardStepForm}
      </TwoColumnFlowStep>
    )
  }
}
