import { Spacing, SpacingProps } from '@leaseplan/ui'
import styled from 'styled-components'

export const ModalContentCenteredStyled = styled(Spacing).attrs<SpacingProps>({
  pt: 8,
  pb: 8,
  mb: -8,
})`
  text-align: center;
`
