import { mq } from '@leaseplan/ui'
import styled from 'styled-components'

/*
 * To avoid footer logo from overlapping on content
 * And to manage spacing w/o mobile app
 */
export const StepLayoutWrapper = styled.section`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  ${mq.from('tablet')`
      padding-bottom: 48px;
  `}
  ${mq.until('tablet')`
      padding-bottom: 24px;
  `}
  ${mq.from('tablet')`
    margin-bottom: 96px
  `};
`
