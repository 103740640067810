import { Spacing } from '@leaseplan/ui'
import { Text, TextProps } from '@velocity/ui'
import moment from 'moment'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepTile, {
  Location,
} from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { INVARIANT_DATE_FORMAT } from 'mlp-client/src/constants'

import { EditButtonLabel } from './EditButtonLabel'

export interface Props {
  dateTime: Date | string
  dateFormat?: string
  title?: React.ReactNode
  location: Location | string
  dataE2eId?: string
  dayPeriod?: string
  onClick(): void
}

type DefaultProps = Required<Pick<Props, 'dateFormat' | 'dataE2eId' | 'title'>>
type InternalProps = DefaultProps & Props

const isTextLocation = (location: Location | string): location is string =>
  typeof location === 'string'

const textProps: TextProps = {
  variant: '200',
  loose: true,
  component: 'span',
}

export class DateLocationTile extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    dateFormat: 'LLLL',
    dataE2eId: 'location',
    title: (
      <Translation id="myLeaseplan.serviceRequest.steps.confirmBooking.whenAndWhere" />
    ),
  }

  render() {
    const {
      dateFormat,
      dateTime,
      dayPeriod,
      title,
      location,
      dataE2eId,
      onClick,
    } = this.props

    const baseDate = moment(dateTime, INVARIANT_DATE_FORMAT).format(dateFormat)

    const date = dayPeriod ? `${baseDate} ${dayPeriod}` : `${baseDate}`

    return (
      <FlowStepTile
        dataE2eId={dataE2eId}
        title={title}
        location={isTextLocation(location) ? undefined : location}
        btnLabel={EditButtonLabel}
        onBtnClick={onClick}
      >
        <Spacing mb={2}>
          <Text {...textProps}>{date}</Text>
        </Spacing>
        {isTextLocation(location) && <Text {...textProps}>{location}</Text>}
      </FlowStepTile>
    )
  }
}
