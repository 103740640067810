import { Auth0ParseHashError, WebAuth } from 'auth0-js'

import {
  storeTokenInSession,
  getAccessTokenForMobile,
  getExpiresAtForMobile,
  postMessage,
  PostMessageType,
} from './mobile-utils'
import { InitAuth, LocalStorageSession } from './types'

const supportedAuthenticationLanguages: readonly SupportedAuthenticationLanguages[] = [
  'en',
  'fr',
  'fi',
  'da',
  'de',
]

const enum LocalStorageAuthKeys {
  ExpiresAt = 'expires_at',
  AccessToken = 'access_token',
  IdToken = 'id_token',
  IsMobileApp = 'is_mobile_app',
}

export const defaultAuthenticationLanguage: SupportedAuthenticationLanguages =
  'en'

let auth0: WebAuth
let clientId: string
let logoutRedirectUri: string
let tokenRenewalTimeout: number

const isSupportedAuthenticationLanguage = (
  lang: string,
): lang is SupportedAuthenticationLanguages =>
  supportedAuthenticationLanguages.includes(lang as any)

export const initAuth = ({
  domain,
  clientId: clientID,
  audience,
  isMobile,
  initialMobileCredentials,
}: InitAuth) => {
  const callBackOrigin = window.location.origin

  auth0 = new WebAuth({
    domain,
    clientID,
    audience,
    redirectUri: `${callBackOrigin}/auth/login-redirect/`,
    responseType: 'token',
    scope: 'email',
  })

  if (isMobile) {
    const { accessToken, expiresAt } = initialMobileCredentials

    storeTokenInSession(accessToken, expiresAt)
  }

  window.localStorage.setItem(
    LocalStorageAuthKeys.IsMobileApp,
    JSON.stringify(isMobile),
  )
  clientId = clientID
  logoutRedirectUri = `${callBackOrigin}/auth/logout-redirect/`
  scheduleRenewal()
}

export const authorize = (lang: string, connection?: string) => {
  auth0.authorize({
    lang: isSupportedAuthenticationLanguage(lang)
      ? lang
      : defaultAuthenticationLanguage,
    // `lang` is not in auth0-js typings and creates a warning
    //   "Following parameters are not allowed on the `/authorize` endpoint: [lang]"
    // but unlike `language` (which also creates a warning) it actually sets the language.
    ...(connection ? { connection } : {}),
  })
}

export const renewToken = () => {
  if (isMobileApp()) {
    postMessage({
      type: PostMessageType.RenewAccessToken,
    })
  } else {
    auth0.checkSession({}, (error, result) => {
      if (!error) {
        storeSession(result)
      }
    })
  }
}

export const scheduleRenewal = () => {
  const expiresAt = loadExpiresAtFromStorage()
  const delay = expiresAt - Date.now()

  if (delay > 0) {
    tokenRenewalTimeout = window.setTimeout(() => {
      renewToken()
    }, delay - 60 * 1000)
  }
}

export const processLogin = (): Promise<
  LocalStorageSession | Auth0ParseHashError
> | null =>
  new Promise((resolve, reject) => {
    auth0.parseHash((err, authResult) => {
      if (err) {
        return reject(err)
      }

      const { accessToken, expiresIn, idToken } = authResult

      if (
        typeof accessToken === 'undefined' ||
        typeof expiresIn === 'undefined' ||
        typeof idToken === 'undefined'
      ) {
        return reject(
          new Error(
            `We expected the following values to be defined
            accessToken: ${accessToken}
            expiresIn: ${expiresIn}
            idToken: ${idToken}`,
          ),
        )
      }

      return resolve(
        storeSession({
          accessToken,
          expiresIn,
          idToken,
        }),
      )
    })
  })

export const logout = () => {
  deleteSession()
  window.clearTimeout(tokenRenewalTimeout)
  auth0.logout({
    clientID: clientId,
    returnTo: logoutRedirectUri,
  })
}

export const getAccessToken = (): string => {
  const session = retrieveSessionFromLocalStorage()

  return session ? session.accessToken : ''
}

export const isAuthenticated = (): boolean =>
  new Date().getTime() < loadExpiresAtFromStorage()

const storeSession = ({
  expiresIn,
  accessToken,
  idToken,
}: {
  expiresIn: number
  accessToken: string
  idToken: string
}) => {
  persistSessionToLocalStorage({
    accessToken,
    idToken,
    expiresAt: JSON.stringify(expiresIn * 1000 + new Date().getTime()),
  })

  return retrieveSessionFromLocalStorage()
}

export const persistSessionToLocalStorage = (
  itemsToPersist: LocalStorageSession | null,
) => {
  if (!itemsToPersist) {
    return
  }

  localStorage.setItem(
    LocalStorageAuthKeys.AccessToken,
    itemsToPersist.accessToken,
  )
  localStorage.setItem(LocalStorageAuthKeys.IdToken, itemsToPersist.idToken)
  localStorage.setItem(LocalStorageAuthKeys.ExpiresAt, itemsToPersist.expiresAt)

  scheduleRenewal()
}

export const retrieveSessionFromLocalStorage = (): LocalStorageSession | null => {
  const accessToken = loadAccessTokenFromStorage()

  if (!accessToken) {
    return null
  }

  return {
    accessToken,
    idToken: loadIdTokenFromStorage(),
    expiresAt: loadExpiresAtFromStorage(),
  }
}

export const deleteSession = () => {
  const localStorage = window.localStorage

  localStorage.removeItem(LocalStorageAuthKeys.AccessToken)
  localStorage.removeItem(LocalStorageAuthKeys.IdToken)
  localStorage.removeItem(LocalStorageAuthKeys.ExpiresAt)
}

const loadAccessTokenFromStorage = (): string =>
  isMobileApp()
    ? getAccessTokenForMobile()
    : window.localStorage.getItem(LocalStorageAuthKeys.AccessToken)

const loadIdTokenFromStorage = () =>
  window.localStorage.getItem(LocalStorageAuthKeys.IdToken)

const loadExpiresAtFromStorage = () =>
  JSON.parse(
    isMobileApp()
      ? getExpiresAtForMobile()
      : window.localStorage.getItem(LocalStorageAuthKeys.ExpiresAt),
  )

const isMobileApp = () =>
  JSON.parse(
    window.localStorage.getItem(LocalStorageAuthKeys.IsMobileApp) || 'false',
  )
