import moment from 'moment'
import { createSelector } from 'reselect'

import {
  SelectedVehicles,
  Timeline,
  TimelineItem,
} from 'mlp-client/src/timeline/types'
import { AppState } from 'mlp-client/src/types'

export const getTimeline = (state: AppState) => state.timeline

export const getSelectedVehicles = createSelector(
  getTimeline,
  (timeline: Timeline) => timeline.selectedVehicles,
)

const filterByContractId = (
  selectedContractIds: readonly string[],
  timeline: Timeline,
) =>
  [...timeline.timeline].filter((item: TimelineItem): boolean => {
    if (!selectedContractIds.length) {
      return true
    }

    return selectedContractIds.indexOf(item.contractId) > -1
  })

export const getSortedTimelines = createSelector(
  getTimeline,
  getSelectedVehicles,
  (
    timeline: Timeline,
    selectedVehicles: SelectedVehicles,
  ): readonly TimelineItem[] => {
    const selectedContractIds = selectedVehicles.filter(key =>
      Boolean(selectedVehicles[key]),
    )
    const filteredItems = selectedContractIds.length
      ? filterByContractId(selectedContractIds, timeline)
      : [...timeline.timeline]

    return filteredItems.sort(sortByDateDesc)
  },
)

const sortByDateDesc = (
  prevItem: TimelineItem,
  nextItem: TimelineItem,
): number =>
  moment(nextItem.modifiedDate).valueOf() -
  moment(prevItem.modifiedDate).valueOf()

export const getPagination = (state: AppState) => state.timeline.pagination
