import React from 'react'

interface DefaultProps {
  height: number
  title: string
  width: number
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

export default class PdfIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    height: 48,
    title: 'PDFIcon',
    width: 48,
  }

  render() {
    const { width, height, title } = this.props as PropsWithDefaults

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <g
          id="pdf-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g transform="translate(-1.000000, -1.000000)" fill="#F06400">
            <g>
              <path
                d="M15.131,21.9973 L3.477,21.9973 C2.65,21.9973 1.977,21.3243 1.977,20.4973 L1.977,3.0003 C1.977,2.1733 2.65,1.5003 3.477,1.5003 L9.596,1.5003 C9.97,1.5003 10.33,1.6393 10.607,1.8923 L16.144,6.9693 C16.453,7.2523 16.631,7.6553 16.631,8.0753 L16.631,10.1323 C16.631,10.6843 16.183,11.1323 15.631,11.1323 C15.078,11.1323 14.631,10.6843 14.631,10.1323 L14.631,8.2953 L9.402,3.5003 L3.977,3.5003 L3.977,19.9973 L14.631,19.9973 L14.631,19.5343 C14.631,18.9813 15.079,18.5343 15.631,18.5343 C16.183,18.5343 16.631,18.9813 16.631,19.5343 L16.631,20.4973 C16.631,21.3243 15.958,21.9973 15.131,21.9973"
                id="Fill-7"
              />
              <path
                d="M15.4172,9.5627 L9.5472,9.5627 C8.9952,9.5627 8.5472,9.1157 8.5472,8.5627 L8.5472,2.5097 C8.5472,1.9577 8.9952,1.5097 9.5472,1.5097 C10.1002,1.5097 10.5472,1.9577 10.5472,2.5097 L10.5472,7.5627 L15.4172,7.5627 C15.9692,7.5627 16.4172,8.0097 16.4172,8.5627 C16.4172,9.1157 15.9692,9.5627 15.4172,9.5627"
                id="Fill-9"
              />
              <path
                d="M15.5,16.5 L16.5,16.5 L16.5,13.5 L15.5,13.5 L15.5,16.5 Z M10.5,14.5 L11.5,14.5 L11.5,13.5 L10.5,13.5 L10.5,14.5 Z M22,13.5 L20.5,13.5 L20.5,14.5 L22,14.5 L22,16 L20.5,16 L20.5,18 L19,18 L19,12 L22,12 L22,13.5 Z M18,16.5 C18,17.33 17.33,18 16.5,18 L14,18 L14,12 L16.5,12 C17.33,12 18,12.67 18,13.5 L18,16.5 Z M13,14.5 C13,15.33 12.33,16 11.5,16 L10.5,16 L10.5,18 L9,18 L9,12 L11.5,12 C12.33,12 13,12.67 13,13.5 L13,14.5 Z"
                id="Fill-11"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
