import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import * as React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { DashboardSectionType } from 'mlp-client/src/dashboard/enums'
import { getIdOfTranslation } from 'mlp-client/src/translations/utils'

export interface Props {
  resourceSegment: DashboardSectionType
}

const StyledGridItem = styled(GridItem)`
  display: flex;
`

const SText = styled(Text)`
  color: ${props => props.theme.velocity.styling.secondary.main.default};
`

class DashboardSection extends React.PureComponent<Props> {
  private mapToGridItems = (child: React.ReactChild) =>
    child && (
      <StyledGridItem span={{ mobile: 12, tablet: 6, desktop: 3 }}>
        {child}
      </StyledGridItem>
    )

  render() {
    const { resourceSegment, children } = this.props

    return (
      <Spacing mb={4}>
        <Spacing mb={2}>
          <Headline variant="100" component="h6" withMarginBottom>
            <Translation id={getDashboardSectionTitle(resourceSegment)} />
          </Headline>
          <SText bold>
            <Translation id={getDashboardSectionSubtitle(resourceSegment)} />
          </SText>
        </Spacing>
        <Grid>{React.Children.map(children, this.mapToGridItems)}</Grid>
      </Spacing>
    )
  }
}

export const getDashboardSectionTitle = (
  resourceSegment: string,
  withoutParentKey?: boolean,
) =>
  getIdOfTranslation(
    'title',
    null,
    `dashboard.${resourceSegment}`,
    withoutParentKey,
  )

export const getDashboardSectionSubtitle = (
  resourceSegment: string,
  withoutParentKey?: boolean,
) =>
  getIdOfTranslation(
    'subtitle',
    null,
    `dashboard.${resourceSegment}`,
    withoutParentKey,
  )

export default DashboardSection
