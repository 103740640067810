import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import Localization from 'mlp-client/src/localization/Localization'
import { Geolocation } from 'mlp-client/src/types'
import { formatTimelineDate } from 'mlp-client/src/timeline-details/components/views/supplier-details/utils'

export interface Props {
  dropOffDate: string
  locationLabel: string
  supplierAddress: string
  supplierGeolocation: Geolocation
}

const SupplierDetails: React.FC<Props> = ({
  dropOffDate,
  locationLabel,
  supplierAddress,
  supplierGeolocation,
}): React.ReactElement => {
  const supplierLocation = {
    title: locationLabel,
    address: supplierAddress,
    coordinates: {
      lat: supplierGeolocation.latitude,
      lng: supplierGeolocation.longitude,
    },
  }

  return (
    !!supplierAddress && (
      <FlowStepTile
        title={
          <Translation
            id="myLeaseplan.timeline.tyreBooking.detail.whenWhere"
            data-e2e-id="bookingDetailsForm_whenAndWhereTitle"
          />
        }
        location={supplierLocation}
        children={
          <Localization render={() => formatTimelineDate(dropOffDate)} />
        }
      />
    )
  )
}

export { SupplierDetails }
export default SupplierDetails
