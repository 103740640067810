import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

import { defaultIcons } from './icons-collection/defaultIcons'
import { lpluIcons } from './icons-collection/lpluIcons'
import { IconTypes } from './types'

const getIcons = (): IconTypes => {
  let icons = defaultIcons

  if (!isLeaseplan()) {
    icons = lpluIcons
  }

  return icons
}

const IconSelector = getIcons()

export default IconSelector
