import { Hide, GridItem } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import { TileProps } from 'mlp-client/src/dashboard/type'

import TileWithIcons from './TileWithIcons'
import MobileTile from './MobileTile'

export const StyledGridItem = styled(GridItem)`
  cursor: pointer;
  div {
    height: 100%;
  }
`

const defaultSpan = {
  mobile: 12,
  tablet: 6,
  desktop: 3,
}

export const BasicTile: React.FC<TileProps> = props => {
  const contractId = useSelector(getCurrentContractId)

  return (
    <StyledGridItem
      data-e2e-id={props.name}
      span={props.span || defaultSpan}
      data-disabled={props.disabled}
    >
      <Hide until="tablet" implementation="js">
        <TileWithIcons
          {...props}
          toParams={{ contractId }}
          defaultContent={!contractId}
        />
      </Hide>
      <Hide from="tablet" implementation="js">
        <MobileTile {...props} toParams={{ contractId }} />
      </Hide>
    </StyledGridItem>
  )
}
