import { Text } from '@velocity/ui'
import React from 'react'

import { LicensePlate } from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense.styled'
import Translation from 'mlp-client/src/localization/Translation'

export interface Props {
  makeModel: string
  licensePlate: string
  htmlFor?: string
  className?: string
  isFuelManagement: boolean
}

class VehicleModelLicenseLabel extends React.PureComponent<Props> {
  render() {
    const {
      makeModel,
      licensePlate,
      htmlFor,
      className,
      isFuelManagement,
    } = this.props

    return (
      <label htmlFor={htmlFor} className={className}>
        {isFuelManagement ? (
          <Text variant="200">
            <Translation id={'myLeaseplan.multipleCars.actions.fuelMandate'} />
          </Text>
        ) : (
          <>
            <Text variant="200">{makeModel}</Text>{' '}
            <LicensePlate>{licensePlate}</LicensePlate>
          </>
        )}
      </label>
    )
  }
}

export default VehicleModelLicenseLabel
