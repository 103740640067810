import Classnames from 'classnames'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { MyAccountIcon } from '@velocity/ui/marketing-icons/draft'

import { ChevronDownIcon } from 'mlp-client/src/components/icons'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'
import DropdownPanel from 'mlp-client/src/components/layout/top-navigation/desktop/DropdownPanel'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'
import { getUser } from 'mlp-client/src/user/selectors'
import { User } from 'mlp-client/src/user/types'
import { MainMenuTopNavigation } from 'mlp-client/src/analytics/actions'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'

export interface Props extends RouteComponentProps {
  user?: User
  trackMainMenuTopNavigation?(eventLabel: string): void
}

interface State {
  open: boolean
}

class LoggedInButton extends React.PureComponent<Props, State> {
  mainElement: HTMLElement

  state = { open: false }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside)
  }

  closePanel = () => {
    this.setState({ open: false })
  }

  attachMainElement = (element: HTMLElement) => {
    this.mainElement = element
  }

  handleClickOutside = (e: MouseEvent) => {
    if (!this.mainElement.contains(e.target as Element) && this.state.open) {
      this.closePanel()
    }
  }

  handleLogoutClick = (title: string) => {
    this.props.trackMainMenuTopNavigation(title)
  }

  handleButtonClick = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  render() {
    const { open } = this.state
    const { user } = this.props

    return (
      <TopNavigationContext.Consumer>
        {({ logout, buttonStructure }) => (
          <div ref={this.attachMainElement}>
            <button
              className="main-menu-widgets__link"
              type="button"
              onClick={this.handleButtonClick}
              aria-expanded={open}
              data-e2e-id="navMenuProfileButton-myLeasePlan"
              data-tag-id="button-toggle.myLeasePlan"
            >
              <MyAccountIcon size="XXS" className="u-margin-right-tiny" />
              {!user && <Translation id="myLeaseplan.navigation.myLeasePlan" />}
              {user && (
                <span id="user">
                  {user.firstName} {user.lastName}
                </span>
              )}
              <div>
                <ChevronDownIcon
                  className={Classnames(
                    'icon u-margin-left-small dropdown-menu-item__chevron',
                    { 'dropdown-menu-item__chevron--open': open },
                  )}
                  title="Open menu"
                />
              </div>
            </button>
            <LocalizationContext.Consumer>
              {({ translate }) => (
                <DropdownPanel open={open}>
                  <ul className="o-list-bare u-padding-horizontal u-padding-vertical-tiny sub-menu-item">
                    {buttonStructure.map(item => (
                      <li key={item.title} className="dropdown-menu-item__item">
                        <RouteLink
                          className="dropdown-menu-item__link"
                          to={item.to || '404'}
                          onClick={() =>
                            this.handleLogoutClick(translate(item.title))
                          }
                        >
                          {item.title && <Translation id={item.title} />}
                        </RouteLink>
                      </li>
                    ))}
                    <li className="dropdown-menu-item__item">
                      <div
                        className="dropdown-menu-item__link logout"
                        onClick={() => {
                          logout()
                          this.handleLogoutClick(
                            translate('myLeaseplan.subNavigation.logout'),
                          )
                        }}
                      >
                        <Translation id="myLeaseplan.subNavigation.logout" />
                      </div>
                    </li>
                  </ul>
                </DropdownPanel>
              )}
            </LocalizationContext.Consumer>
          </div>
        )}
      </TopNavigationContext.Consumer>
    )
  }
}

export type ReduxProps = Pick<Props, 'user'>
type DispatchProps = Pick<Props, 'trackMainMenuTopNavigation'>

export const mapStateToProps = createStructuredSelector({
  user: getUser,
})

export const mapDispatchToProps: DispatchProps = {
  trackMainMenuTopNavigation: (eventLabel: string) =>
    new MainMenuTopNavigation({ eventLabel }),
}

export { LoggedInButton }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoggedInButton))
