import { createGlobalStyle } from 'styled-components'

export const noBodyScrollClass = 'no-body-scroll'

/*
 * Global styles that are not component specific
 * NEVER-EVER put styles for components in here
 */
export const GlobalStyle = createGlobalStyle`
/* stylelint-disable */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-ms-clear {
  display: none;
}

.${noBodyScrollClass} {
  overflow: hidden;
}
/* stylelint-enable */
`
