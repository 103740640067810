export enum SelectDocumentValues {
  CONTACT_US = 'contactUsItem',
}

export const DownloadableDocumentValues = [
  'Driversguide',
  'Accidentreport',
  'Vehiclereturnchecklist',
  'Fairwear',
  'Registrationcarddecree',
  'Registrationcertificate',
]
