import {
  FlowData,
  FuelCards,
  FuelCardTypes,
} from 'mlp-client/src/flows/fuel-card/types'

export const enum ActionTypes {
  GET_FUEL_CARD_TYPES_REQUEST = '@@myLeaseplan/fuelCardRequest/GET_FUEL_CARD_TYPES_REQUEST',
  GET_FUEL_CARD_TYPES_SUCCESS = '@@myLeaseplan/fuelCardRequest/GET_FUEL_CARD_TYPES_SUCCESS',
  GET_FUEL_CARD_TYPES_FAIL = '@@myLeaseplan/fuelCardRequest/GET_FUEL_CARD_TYPES_FAIL',
  LOAD_FUEL_CARDS = '@@myLeaseplan/fuelCardRequest/LOAD_FUEL_CARDS',
  LOAD_FUEL_CARDS_SUCCESS = '@@myLeaseplan/fuelCardRequest/LOAD_FUEL_CARDS_SUCCESS',
  LOAD_FUEL_CARDS_FAIL = '@@myLeaseplan/fuelCardRequest/LOAD_FUEL_CARDS_FAIL',
  SUBMIT_FUEL_CARD = '@@myLeaseplan/fuelCardRequest/SUBMIT_FUEL_CARD',
}

export class GetFuelCardTypesRequest {
  readonly type = ActionTypes.GET_FUEL_CARD_TYPES_REQUEST
}

export class GetFuelCardTypesSuccess {
  readonly type = ActionTypes.GET_FUEL_CARD_TYPES_SUCCESS

  constructor(public payload: { data: FuelCardTypes }) {}
}

export class GetFuelCardTypesFail {
  readonly type = ActionTypes.GET_FUEL_CARD_TYPES_FAIL
}

export class SubmitFuelCard {
  readonly type = ActionTypes.SUBMIT_FUEL_CARD

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(error: boolean): void
    },
  ) {}
}

export class LoadFuelCards {
  readonly type = ActionTypes.LOAD_FUEL_CARDS
}

export class LoadFuelCardsSuccess {
  readonly type = ActionTypes.LOAD_FUEL_CARDS_SUCCESS

  constructor(public payload: { fuelCards: FuelCards }) {}
}

export class LoadFuelCardsFail {
  readonly type = ActionTypes.LOAD_FUEL_CARDS_FAIL
}

export type Action =
  | GetFuelCardTypesRequest
  | GetFuelCardTypesSuccess
  | GetFuelCardTypesFail
  | SubmitFuelCard
  | LoadFuelCards
  | LoadFuelCardsSuccess
  | LoadFuelCardsFail
