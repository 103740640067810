import { Color, mq } from '@leaseplan/ui'
import styled from 'styled-components'

const cellPadding = '15px'
const firstCellPadding = '25px'

export const TableStyled = styled.table`
  width: 100%;
  margin: 0 0 36px 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 1px 1px 6px ${Color.steel20};
  tbody {
    ${mq.until('lap')`
        display: inline-table;
        `};
    cursor: pointer;
    width: 100%;
    &:nth-child(even) {
      background-color: ${Color.white};
    }
    &:nth-child(odd) {
      background-color: rgba(
        204,
        239,
        239,
        0.2
      ); /* Color.aquaBlue20 with 0.2 opacity */
    }
    &:hover {
      background-color: rgba(
        204,
        239,
        239,
        0.4
      ); /* Color.aquaBlue20 with 0.4 opacity */
    }
    tr:first-child {
      background-color: transparent;
    }
  }
  td:first-child {
    padding-left: ${firstCellPadding};
  }
  td:last-child {
    padding-right: ${firstCellPadding};
  }
  th:first-child {
    padding-left: ${firstCellPadding};
  }
  th:last-child {
    padding-right: ${firstCellPadding};
  }
`

export const CellStyled = styled.td`
  text-align: left;
  padding: ${cellPadding};
  word-break: break-word;
  ${mq.from('lap')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
  ${mq.until('lap')`
    .field-radio__label {
      display: inline;
      margin-left: 20px;
    }
  `};
`

export const RowStyled = styled.tr`
  &:nth-child(even) {
    background-color: rgba(
      204,
      239,
      239,
      0.2
    ); /* Color.aquaBlue20 with 0.2 opacity */
  }
  &:nth-child(odd) {
    background-color: ${Color.white};
  }
`

export const HeaderCellStyled = styled.th`
  text-align: left;
  padding: ${cellPadding};
  color: ${Color.white};
  background-color: ${Color.petrolBlue80};
  white-space: nowrap;

  ${mq.until('lap')`
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 40%;
  `};
`
