import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const MaintenanceIconLeasys: FC<IconProps> = props => {
  MaintenanceIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g>
        <path
          fill={ColorEnumSelector(color)}
          d="M32.14,38.77h1.13l0.27-0.05c0.23-0.04,0.46-0.09,0.69-0.14c0.04-0.01,0.07-0.02,0.08-0.03
			c0.02-0.01-0.08-0.07-0.16-0.15c-0.34-0.36-0.7-0.71-1.05-1.06c-0.56-0.55-1.13-1.13-1.67-1.74c-0.56-0.63-0.8-1.49-0.65-2.35
			c0.16-0.9,0.72-1.69,1.5-2.11c1.23-0.67,2.64-0.44,3.68,0.59c0.37,0.37,0.74,0.74,1.11,1.11c0.47,0.47,0.94,0.94,1.42,1.41
			c0.06,0.06,0.1,0.06,0.1,0.06c0.05-0.27,0.1-0.5,0.12-0.73c0.24-1.94-0.28-3.57-1.61-4.97c-1.09-1.15-2.38-1.75-3.83-1.78
			c-0.56-0.02-1.14,0.07-1.75,0.15c-0.22,0.03-0.44,0.06-0.66,0.08c-0.26,0.03-0.86,0.1-1.41-0.44c-3.56-3.57-7.12-7.14-10.68-10.7
			c-0.44-0.45-0.58-0.98-0.41-1.59c0.28-1.01,0.32-1.96,0.13-2.89c-0.4-1.91-1.43-3.28-3.14-4.19c-1.28-0.68-2.64-0.84-4.15-0.49
			c-0.08,0.02-0.12,0.04-0.14,0.05c0.01,0.01,0.03,0.03,0.06,0.06l0.49,0.49c0.24,0.24,0.48,0.48,0.72,0.72l0.44,0.44
			c0.4,0.4,0.82,0.81,1.2,1.26c0.86,1.01,0.88,2.57,0.03,3.65c-0.55,0.7-1.31,1.11-2.16,1.17c-0.85,0.05-1.72-0.27-2.4-0.93
			c-0.84-0.82-1.67-1.66-2.49-2.5c-0.08-0.08-0.13-0.11-0.15-0.12c0,0.02-0.02,0.07-0.03,0.16c-0.04,0.27-0.09,0.54-0.14,0.81
			l-0.05,0.27v0.67c0.04,0.18,0.08,0.37,0.11,0.56c0.08,0.43,0.16,0.83,0.29,1.17c0.87,2.18,2.38,3.42,4.6,3.8
			c0.85,0.14,1.76,0.09,2.76-0.16c0.62-0.16,1.16,0,1.62,0.46c3.54,3.56,7.08,7.11,10.64,10.65c0.44,0.44,0.58,0.97,0.44,1.58
			c-0.37,1.55-0.33,2.8,0.12,3.91c0.84,2.05,2.3,3.28,4.47,3.74L32.14,38.77z M33.37,40.77h-1.34c-0.07,0-0.14-0.01-0.22-0.02
			l-0.6-0.13c-2.83-0.6-4.81-2.27-5.9-4.94c-0.6-1.46-0.68-3.08-0.25-4.97c-3.47-3.46-6.94-6.93-10.39-10.41
			c-1.22,0.28-2.33,0.33-3.41,0.15c-2.93-0.5-4.99-2.19-6.12-5.03c-0.21-0.52-0.31-1.04-0.4-1.55c-0.04-0.2-0.07-0.4-0.12-0.6
			c-0.02-0.07-0.03-0.15-0.03-0.22v-0.87c0-0.06,0.01-0.12,0.02-0.17l0.07-0.36c0.05-0.25,0.09-0.5,0.13-0.76
			c0.19-1.23,1-1.64,1.46-1.77c0.52-0.16,1.33-0.17,2.12,0.65c0.81,0.83,1.63,1.65,2.46,2.46c0.26,0.26,0.56,0.38,0.87,0.36
			c0.27-0.02,0.52-0.16,0.72-0.41c0.25-0.32,0.26-0.83,0.02-1.11c-0.34-0.4-0.72-0.77-1.1-1.15L10.9,9.48
			c-0.23-0.24-0.47-0.47-0.7-0.71L9.7,8.27C9.11,7.68,8.92,6.95,9.15,6.21c0.16-0.51,0.57-1.17,1.6-1.4
			c1.98-0.46,3.85-0.23,5.55,0.67c2.23,1.19,3.63,3.06,4.15,5.55c0.25,1.17,0.21,2.39-0.1,3.63c3.47,3.47,6.93,6.93,10.39,10.4
			c0.2-0.02,0.36-0.04,0.53-0.07c0.66-0.09,1.34-0.18,2.05-0.17c2.01,0.03,3.77,0.84,5.25,2.4c1.74,1.84,2.46,4.06,2.14,6.6
			c-0.04,0.31-0.1,0.64-0.19,0.96c-0.2,0.73-0.73,1.27-1.41,1.46c-0.7,0.19-1.45-0.02-2-0.57c-0.48-0.47-0.96-0.95-1.43-1.43
			c-0.37-0.37-0.73-0.73-1.1-1.1c-0.42-0.42-0.86-0.5-1.31-0.25c-0.25,0.14-0.44,0.4-0.49,0.7c-0.03,0.15-0.04,0.44,0.17,0.68
			c0.5,0.56,1.03,1.09,1.59,1.65c0.37,0.36,0.73,0.73,1.08,1.1c0.2,0.21,0.55,0.65,0.66,1.19c0.22,1.09-0.48,2.09-1.62,2.33
			c-0.24,0.05-0.48,0.1-0.72,0.14l-0.36,0.07C33.5,40.77,33.43,40.77,33.37,40.77"
        />
      </g>
    </svg>
  )
}

export default MaintenanceIconLeasys
