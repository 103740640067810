import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Action, Dispatch } from 'redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import { Vehicle } from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import {
  getContracts,
  showMultipleVehicles,
} from 'mlp-client/src/contracts/selectors'
import { getVehicleByContractId } from 'mlp-client/src/contracts/utils'
import TimelineDetailLayout from 'mlp-client/src/timeline-details/components/TimelineDetailLayout/TimelineDetailLayout'
import {
  LoadBooking,
  ResetCancellationStatus,
} from 'mlp-client/src/timeline-details/actions'
import { CancellationStatus } from 'mlp-client/src/timeline-details/enums'
import { getTimelineDetails } from 'mlp-client/src/timeline-details/selectors'
import { BookingData } from 'mlp-client/src/timeline-details/types'
import { AppState } from 'mlp-client/src/types'

interface Params {
  id: string
}

export interface Props extends RouteComponentProps<Params> {
  booking: BookingData
  cancellationStatus?: CancellationStatus
  isCancelling: boolean
  isLoading: boolean
  locationLabel: string
  pageTitle: string
  vehicle?: Vehicle
  dismissCancellationFeedback(): void
  loadBooking(): void
  component: React.ElementType<ChildComponentProps>
}

export interface ChildComponentProps {
  booking: BookingData
  id: string
  locationLabel: string
  vehicle?: Vehicle
}

class TimelineBookingDetails extends PureComponent<Props> {
  componentDidMount() {
    this.props.loadBooking()
  }

  componentDidUpdate(prevProps: Props) {
    const { cancellationStatus, loadBooking } = this.props

    if (
      cancellationStatus === CancellationStatus.SUCCESS &&
      cancellationStatus !== prevProps.cancellationStatus
    ) {
      loadBooking()
    }
  }

  componentWillUnmount() {
    const { cancellationStatus, dismissCancellationFeedback } = this.props

    if (cancellationStatus) {
      dismissCancellationFeedback()
    }
  }

  renderMain = (): React.ReactFragment => {
    const {
      booking,
      match: {
        params: { id },
      },
      locationLabel,
      vehicle,
      component: Component,
    } = this.props

    return (
      <Component
        booking={booking}
        id={id}
        locationLabel={locationLabel}
        vehicle={vehicle}
      />
    )
  }

  render() {
    const { booking, isCancelling, isLoading, pageTitle } = this.props

    return (
      <MyleaseplanPage metaTitle={pageTitle}>
        <TimelineDetailLayout loading={isLoading || isCancelling}>
          {!isLoading && !isCancelling && booking && (
            <GridLayout main={this.renderMain()} />
          )}
        </TimelineDetailLayout>
      </MyleaseplanPage>
    )
  }
}

export type ReduxProps = Pick<
  Props,
  | 'booking'
  | 'cancellationStatus'
  | 'isCancelling'
  | 'isLoading'
  | 'locationLabel'
  | 'pageTitle'
  | 'vehicle'
>
export type DispatchProps = Pick<
  Props,
  'dismissCancellationFeedback' | 'loadBooking'
>
export type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapTranslationsToProps = {
  locationLabel: 'myLeaseplan.timeline.bookingMaintenance.items.locationLabel',
  pageTitle: 'myLeaseplan.timeline.base.title',
}

export const mapStateToProps = (state: AppState): ReduxProps => {
  const {
    booking,
    cancellationStatus,
    isCancelling,
    isLoading,
  } = getTimelineDetails(state)

  const vehicle =
    showMultipleVehicles(state) && booking
      ? getVehicleByContractId(getContracts(state), booking.contractId)
      : undefined

  return {
    booking,
    cancellationStatus,
    isCancelling,
    isLoading,
    vehicle,
    ...getTranslations(state, mapTranslationsToProps),
  }
}

export const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  {
    match: {
      params: { id },
    },
  }: OwnProps,
): DispatchProps => ({
  loadBooking: () => {
    dispatch(new LoadBooking({ id }))
  },
  dismissCancellationFeedback: () => {
    dispatch(new ResetCancellationStatus())
  },
})

export { TimelineBookingDetails }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimelineBookingDetails)
