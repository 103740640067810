import Classnames from 'classnames'
import _debounce from 'lodash/debounce'
import { withRouter } from 'react-router-dom'
import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Transition } from 'react-transition-group'

import { AppState, NavigationItem } from 'mlp-client/src/types'
import { parseLocale } from 'mlp-client/src/utils'
import { from } from 'mlp-client/src/utils/media-query/mq'
import {
  getLocale,
  getLocalizedRoute,
  getRoutes,
} from 'mlp-client/src/localization/selectors'
import { Logout } from 'mlp-client/src/auth/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { Routes } from 'mlp-client/src/localization/types'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import { getCurrentQuoteId } from 'mlp-client/src/quotes/selectors'

import { getActiveItem, getItems } from './selectors'
import { ScrollDirection } from './enums'
import {
  defaultContext,
  TopNavigationContext,
  TopNavigationContextType,
} from './TopNavigationContext'
import PrimaryLane from './desktop/PrimaryLane'
import MobileTakeover from './mobile/MobileTakeover'

export interface Props extends RouteComponentProps {
  locale: string
  logoutRoute?: null | string
  navigationStructure?: readonly NavigationItem[]
  routeList: Routes
  upperNavigationPopup?: ReactNode
  logout(nextRoute: string): void
  isAuthenticated: boolean
  hideLoginLink?: boolean
  contractId: string
  quoteId?: string
}

class TopNavigation extends React.PureComponent<
  Props,
  TopNavigationContextType
> {
  direction: ScrollDirection = ScrollDirection.UP
  lastScrollPosition: number = 0
  scrollPositionOnSwitch: number = 0
  ticking: boolean = false
  requestAnimationFrameID: number

  topNav: HTMLDivElement | null = null

  state = defaultContext

  componentDidMount() {
    const topNavHeight = (from('lap') && this.topNav?.clientHeight) ?? 0

    this.setState(prevState => ({
      logout: this.logout,
      isDesktop: from('lap'),
      placeholderHeight: topNavHeight,
      parsedLocale: parseLocale(this.props.locale) || prevState.parsedLocale,
      onToggleMenu: this.handleToggleTakeover,
    }))

    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.onScroll)

    this.handleResize()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.upperNavigationPopup !== this.props.upperNavigationPopup) {
      this.handleResize()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.handleResize)
    cancelAnimationFrame(this.requestAnimationFrameID)
    this.handleResize.cancel()
  }

  handleToggleTakeover = (active: boolean) => {
    this.setState(({ mobileTakeover }) => ({
      mobileTakeover: { ...mobileTakeover, active },
    }))
  }

  handleTakeoverTransition = (transition: number) => {
    this.setState(({ mobileTakeover }) => ({
      mobileTakeover: { ...mobileTakeover, transition },
    }))
  }

  handleResize = _debounce(() => {
    this.setState({
      isDesktop: from('lap'),
      placeholderHeight: this.topNav?.clientHeight ?? 0,
    })
  }, 100)

  requestTick = () => {
    if (!this.ticking) {
      this.requestAnimationFrameID = requestAnimationFrame(
        this.updateScrollDirection,
      )
    }

    this.ticking = true
  }

  isTopOfScreen = () => window.pageYOffset === 0

  shouldAppear = () =>
    this.isTopOfScreen() ||
    (this.lastScrollPosition > window.pageYOffset && window.pageYOffset > 150)

  shouldToggleDirection = (lastScrollDirection: ScrollDirection) =>
    this.isTopOfScreen() ||
    (this.direction !== lastScrollDirection &&
      this.hasPassedMinimumOffsetForScroll())

  hasPassedMinimumOffsetForScroll = () =>
    Math.abs(window.pageYOffset - this.scrollPositionOnSwitch) > 180

  updateScrollDirection = () => {
    this.ticking = false

    const { scrollDirection } = this.state

    this.direction = this.shouldAppear()
      ? ScrollDirection.UP
      : ScrollDirection.DOWN

    this.scrollPositionOnSwitch =
      this.direction === scrollDirection
        ? this.lastScrollPosition
        : this.scrollPositionOnSwitch

    if (this.shouldToggleDirection(scrollDirection)) {
      this.scrollPositionOnSwitch = this.lastScrollPosition

      this.setState({
        scrollDirection: this.direction,
      })
    }

    this.lastScrollPosition = window.pageYOffset
  }

  onScroll = () => from('desktop') && this.requestTick()

  logout = () => {
    const { logout, logoutRoute } = this.props

    logout(logoutRoute)
  }

  isScrollingUp = (direction: ScrollDirection) =>
    direction === ScrollDirection.UP

  isOneLaneScrollingUpOnDesktop = (
    direction: ScrollDirection,
    allowed: boolean,
  ) => this.isScrollingUp(direction) && allowed

  isOneLaneScrollingDownOnDesktop = (
    direction: ScrollDirection,
    allowed: boolean,
  ) => !this.isScrollingUp(direction) && allowed

  render() {
    const {
      locale,
      match,
      navigationStructure,
      routeList,
      upperNavigationPopup,
      isAuthenticated,
      hideLoginLink,
      contractId,
      quoteId,
    } = this.props

    const { isDesktop, mobileTakeover, scrollDirection } = this.state

    // Navigation items:
    const navItems = getItems(navigationStructure, isAuthenticated)

    const navActiveItem = getActiveItem({
      routeList,
      locale,
      match,
      items: navItems,
    })

    // Mobile Takeover
    const isTakeOverTransitioning = [
      Transition.ENTERING,
      Transition.EXITING,
    ].includes(mobileTakeover.transition)
    const isTakenOver = mobileTakeover.active && !isTakeOverTransitioning
    const shouldAllowAnimation = isDesktop

    return (
      <TopNavigationContext.Provider
        value={{ ...this.state, navActiveItem, navItems }}
      >
        <div
          className={Classnames({
            // Make sure this className stays on the outer-wrapping element:
            // It prevents scrolling on the underlying website when the MobileTakeOver is enabled.
            'top-navigation__wrap--takeover': isTakenOver,
          })}
        >
          <div
            className={Classnames({
              'top-navigation__wrap': true,
              'top-navigation__wrap--is-mobile-takeover-active': isTakenOver,
              'top-navigation__logo--animate-up--primary-lane-only': this.isOneLaneScrollingUpOnDesktop(
                scrollDirection,
                shouldAllowAnimation,
              ),
              'top-navigation__logo--animate-down--primary-lane-only': this.isOneLaneScrollingDownOnDesktop(
                scrollDirection,
                shouldAllowAnimation,
              ),
            })}
            ref={topNav => {
              this.topNav = topNav
            }}
          >
            {upperNavigationPopup}

            <nav className="top-navigation">
              <div className="top-navigation__lane" data-e2e-id="mainMenu">
                {/*
                ------------------------------------
                Desktop: Primary lane
                ------------------------------------
              */}
                <PrimaryLane
                  hideLoginLink={hideLoginLink}
                  contractId={contractId}
                  quoteId={quoteId}
                />
                {/*
                ------------------------------------
                Mobile: Takeover
                ------------------------------------
              */}
                <MobileTakeover
                  onTransitionStateChange={this.handleTakeoverTransition}
                  hideLoginLink={hideLoginLink}
                  contractId={contractId}
                  quoteId={quoteId}
                />
              </div>
            </nav>
          </div>
        </div>
      </TopNavigationContext.Provider>
    )
  }
}

export type ReduxProps = Pick<
  Props,
  | 'locale'
  | 'logoutRoute'
  | 'routeList'
  | 'isAuthenticated'
  | 'contractId'
  | 'quoteId'
>
export type DispatchProps = Pick<Props, 'logout'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  locale: getLocale(state),
  logoutRoute: getLocalizedRoute(state, 'myLeaseplan.external.mainHomePage'),
  routeList: getRoutes(state),
  isAuthenticated: !!getUser(state),
  contractId: getCurrentContractId(state),
  quoteId: getCurrentQuoteId(state),
})

export const mapDispatchToProps: DispatchProps = {
  logout: nextRoute => new Logout({ nextRoute }),
}

export { TopNavigation }
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopNavigation),
)
