import EndOfLeasePage from 'mlp-client/src/end-of-lease/components/Page'
import { FeatureConfiguration } from 'mlp-client/src/end-of-lease/types'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const endOfLeaseFeature: MyLeaseplanFeature<FeatureConfiguration> = {
  enabled: true,
  view: EndOfLeasePage,
  configuration: {
    description: 'myLeaseplan.return.steps.endLease.label',
    options: [
      {
        id: 'buy',
        title: 'myLeaseplan.return.steps.endLease.buy',
        description: 'myLeaseplan.return.steps.endLease.buyDescription',
        link: {
          to: 'myLeaseplan.buy.base',
        },
      },
      {
        id: 'extendContract',
        title: 'myLeaseplan.return.steps.endLease.extend',
        description: 'myLeaseplan.return.steps.endLease.extendSubtitle',
        link: {
          to: 'myLeaseplan.extendContract.base',
        },
      },
      {
        id: 'return',
        title: 'myLeaseplan.return.steps.endLease.return',
        description: 'myLeaseplan.return.steps.endLease.returnDescription',
        link: {
          to: 'myLeaseplan.return.base',
        },
      },
    ],
  },
}
