import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import HappyCustomerIcon from 'mlp-client/src/components/icons/happy-customer-icon/HappyCustomerIcon'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import { NoRecords } from 'mlp-client/src/components/no-records/NoRecords'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import { FinesRecord } from 'mlp-client/src/contracts/types'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { FineCard } from 'mlp-client/src/flows/fine-history/components/fine-card/FineCard'
import { finesListSelector } from 'mlp-client/src/flows/fine-history/selectors'
import { FeatureConfiguration } from 'mlp-client/src/flows/fine-history/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'

export interface Props extends MyLeaseplanFeatureProps<FeatureConfiguration> {
  records: readonly FinesRecord[]
}

const translatePath = makeTranslationPath('myLeaseplan.fines')

export class FinesPage extends React.PureComponent<Props> {
  private renderRecordList() {
    const {
      featureConfig: { configuration },
      records,
    } = this.props
    const batchSize = configuration ? configuration.batchSize : 6

    return (
      <RevealingItemList items={records} batchSize={batchSize}>
        {this.renderRecords}
      </RevealingItemList>
    )
  }

  private renderRecords(records: readonly FinesRecord[]) {
    return records.map((record, idx) => (
      <FineCard key={`${record.date}-${idx}`} {...record} />
    ))
  }

  render() {
    const {
      featureConfig: { enabled, configuration },
      records,
    } = this.props

    if (!enabled) {
      return <NotFound data-test-id="notFound" />
    }

    return (
      <MyleaseplanPage metaTitle={translatePath('pageTitle')}>
        <Spacing mb={1}>
          <Headline variant="400" component="h1" withMarginBottom>
            <Translation id={translatePath('pageTitle')} />
          </Headline>
        </Spacing>
        {!records || records.length === 0 ? (
          <NoRecords
            headerTextLabel={translatePath('noHistory.title')}
            contentTextLabel={translatePath('noHistory.subtitle')}
          >
            <HappyCustomerIcon width="100" height="100" />
          </NoRecords>
        ) : (
          <Grid justifyContent="space-between" direction="row">
            <GridItem span={{ mobile: 12, lap: 7 }}>
              {this.renderRecordList()}
            </GridItem>
            <GridItem span={{ mobile: 12, lap: 4 }}>
              <FAQ
                faqKey={FAQKeys.FINES_PAGE}
                heading={configuration && configuration.headingFAQ}
              />
            </GridItem>
          </Grid>
        )}
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'records'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  records: finesListSelector(state),
})

export default connect(mapStateToProps)(FinesPage)
