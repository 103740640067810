import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import moment from 'moment'
import React from 'react'

import TranslatedRichText from 'mlp-client/src/localization/TranslatedRichText'
import Translation from 'mlp-client/src/localization/Translation'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import DateTimeFieldGroup from 'mlp-client/src/flows/components/date-time-field-group/DateTimeFieldGroup'
import OtherLocationFieldgroup from 'mlp-client/src/flows/components/other-location-field-group/OtherLocationFieldgroup'
import { mapTimes } from 'mlp-client/src/flows/maintenance/utils'
import { Options } from 'mlp-client/src/form/types'

export interface FormValues {
  date: string
  time: string
  street: string
  zipCode: string
  city: string
  country: string
  houseNumber?: string
}
export interface Props {
  onSubmit(): void
  hasValidationErrors: boolean
  notBefore: {
    date: Date
    message: string
  }
  placeholder: string
  cities: Options
  hasSupplier: boolean
  timeSlots: readonly number[]
  isLoading: boolean
  isSuppliersCompleted: boolean
  fetchSuppliers(values: FormValues, prevalidate: boolean): void
  values: FormValues
  enableWeekend: boolean
  hideCountry?: boolean
  hideHouseNumber?: boolean
  prevalidate?: boolean
}

class SelectDateAndLocationForm extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props): void {
    const {
      values,
      fetchSuppliers,
      hasValidationErrors,
      isLoading,
      prevalidate,
    } = this.props

    if (
      !isLoading &&
      !hasValidationErrors &&
      values.street &&
      values.zipCode &&
      values.city &&
      (values.street !== prevProps.values.street ||
        values.date !== prevProps.values.date ||
        values.zipCode !== prevProps.values.zipCode ||
        values.city !== prevProps.values.city)
    ) {
      fetchSuppliers(values, prevalidate)
    }
  }

  render() {
    const {
      onSubmit,
      hasValidationErrors,
      notBefore,
      placeholder,
      cities,
      timeSlots,
      hasSupplier,
      values,
      isLoading,
      enableWeekend,
      hideCountry,
      hideHouseNumber,
      isSuppliersCompleted,
    } = this.props

    return (
      <form onSubmit={onSubmit}>
        <Spacing mb={2}>
          <Headline variant="200" withMarginBottom component="h3">
            <Translation id="Date and time" />
          </Headline>
          <DateTimeFieldGroup
            date={{
              placeholder,
              notBefore,
              enableWeekend,
            }}
            time={{
              enabled: true,
              options: mapTimes(timeSlots),
            }}
          />
          {!hasSupplier && (
            <Spacing mt={2}>
              <div data-e2e-id="noSuppliers">
                <TranslatedRichText
                  id={
                    'myLeaseplan.maintenance.steps.selectSupplier.noSuppliers'
                  }
                  replace={{ date: moment(values.date).format('dddd, LL') }}
                />
              </div>
            </Spacing>
          )}
        </Spacing>
        <Headline variant="200" withMarginBottom component="h3">
          <Translation id="myLeaseplan.maintenance.steps.selectDateAndLocation.location.title" />
        </Headline>
        <Text data-e2e-id="infoBlock">
          <Translation id="myLeaseplan.maintenance.steps.selectDateAndLocation.location.subTitle" />
        </Text>

        <Spacing mt={2} mb={3}>
          <OtherLocationFieldgroup
            cities={cities}
            hideCountry={hideCountry}
            hideHouseNumber={hideHouseNumber}
          />
        </Spacing>

        <NextStepButton
          onClick={onSubmit}
          disabled={
            hasValidationErrors ||
            !hasSupplier ||
            isLoading ||
            !isSuppliersCompleted
          }
        />
      </form>
    )
  }
}
export { SelectDateAndLocationForm }
export default SelectDateAndLocationForm
