import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { LoadFuelCards } from 'mlp-client/src/flows/fuel-card/actions'
import NoFuelCard from 'mlp-client/src/flows/fuel-card/components/no-fuel-card/NoFuelCard'
import NotificationBlock from 'mlp-client/src/flows/fuel-card/components/notification-block/NotificationBlock'
import {
  getFuelCardOptions,
  getFuelCardsStatus,
} from 'mlp-client/src/flows/fuel-card/selectors'
import {
  FlowData,
  FuelcardServiceType,
} from 'mlp-client/src/flows/fuel-card/types'
import { Options } from 'mlp-client/src/form/types'
import { AppState } from 'mlp-client/src/types'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StyledButtonOutlined } from 'mlp-client/src/components/button/StyledButton'

export interface Props extends FlowProps<FlowData> {
  options: Options
  fuelCardsStatus: { isLoaded: boolean; isLoading: boolean }
  loadOptions(): void
  config: {
    phoneNumber: string
  }
}

interface State {
  isLoading: boolean
}

export interface FormData {
  fuelCard: string
}

class SelectFuelCardStep extends React.PureComponent<Props, State> {
  state: State = {
    isLoading: false,
  }

  onSubmit = ({ fuelCard }: FormData) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData({ fuelCardIDs: [fuelCard] }, goToNextStep)
  }

  onAllStolenClick = () => {
    const { goToNextStep, setFlowData, options } = this.props

    setFlowData(
      {
        fuelCardServiceType: FuelcardServiceType.ALL_STOLEN,
        // If all cards stolen or lost, we need to send all the fuel cards
        fuelCardIDs: options.map(option => option.value),
      },
      goToNextStep,
    )
  }

  componentDidMount() {
    const { options, loadOptions, setFlowData } = this.props

    // reset step data
    setFlowData({
      fuelCardIDs: [],
      fuelCardServiceType: undefined,
    })

    if (!options.length) {
      this.setState({ isLoading: true })
      loadOptions()
    }
  }

  render() {
    const {
      options,
      fuelCardsStatus,
      config: { phoneNumber },
    } = this.props

    if (fuelCardsStatus.isLoaded && !options.length) {
      return (
        <StepLayout isLoading={fuelCardsStatus.isLoading}>
          <NoFuelCard />
        </StepLayout>
      )
    }

    return (
      <StepLayout isLoading={fuelCardsStatus.isLoading}>
        <TwoColumnFlowStep
          posterTitleLabel="myLeaseplan.fuelCard.steps.selectFuelCard.title"
          posterTextLabel="myLeaseplan.fuelCard.steps.selectFuelCard.posterSubtitle"
          posterImgMedium="/static/images/my-leaseplan/fuel-card-flow-small.jpg"
          posterImgLarge="/static/images/my-leaseplan/fuel-card-flow-big.jpg"
          posterImgSmall="/static/images/my-leaseplan/mobile/fuel-card-mobile.png"
          titleLabel="myLeaseplan.fuelCard.steps.selectFuelCard.subtitle"
          side={
            <LocalizationContext.Consumer>
              {({ translate }) => (
                <NotificationBlock phoneNumber={translate(phoneNumber)} />
              )}
            </LocalizationContext.Consumer>
          }
        >
          <Form onSubmit={this.onSubmit}>
            {({ handleSubmit }: FormRenderProps) =>
              fuelCardsStatus.isLoaded && (
                <form onSubmit={handleSubmit}>
                  <RadioClickAndGoList
                    name="fuelCard"
                    validations={[]}
                    options={options}
                    onChange={handleSubmit}
                  />
                  {options.length > 1 && (
                    <Spacing mt={2}>
                      <StyledButtonOutlined
                        type="button"
                        variant="secondary"
                        onClick={this.onAllStolenClick}
                        data-e2e-id="allStolenCards"
                      >
                        <Translation id="myLeaseplan.fuelCard.types.allStolen" />
                      </StyledButtonOutlined>
                    </Spacing>
                  )}
                </form>
              )
            }
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'options' | 'fuelCardsStatus'>
type DispatchProps = Pick<Props, 'loadOptions'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  options: getFuelCardOptions(state),
  fuelCardsStatus: getFuelCardsStatus(state),
})

export const mapDispatchToProps: DispatchProps = {
  loadOptions: () => new LoadFuelCards(),
}

export { SelectFuelCardStep }
export default connect(mapStateToProps, mapDispatchToProps)(SelectFuelCardStep)
