import { camelCase } from 'camel-case'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { getColorFromStatus } from 'mlp-client/src/timeline/utils'
import { CardTag } from 'mlp-client/src/components/card-tag/CardTag'

export interface Props {
  status: string
}

const BookingStatus: React.FC<Props> = ({ status }) => {
  const bookingStatus = camelCase(status)
  const backgroundColor = getColorFromStatus(bookingStatus)

  return (
    <CardTag
      dataE2eId={`${bookingStatus}Status`}
      backgroundColor={backgroundColor}
    >
      <Translation id={`myLeaseplan.timeline.status.${bookingStatus}`} />
    </CardTag>
  )
}

export { BookingStatus }
export default BookingStatus
