import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'
import { Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import omit from 'lodash/omit'
import { SubmissionErrors, FormApi } from 'final-form'
import pick from 'lodash/pick'

import { AppState } from 'mlp-client/src/types'
import { getCountry } from 'mlp-client/src/localization/selectors'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { Options } from 'mlp-client/src/form/types'
import { required } from 'mlp-client/src/form/validations'
import { getUser } from 'mlp-client/src/user/selectors'
import {
  Address,
  AddressTypes,
  User,
  AddressData,
} from 'mlp-client/src/user/types'
import {
  getLocationOptions,
  getUserAddressByType,
  getUserOtherAddress,
} from 'mlp-client/src/user/utils'
import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import OtherLocationFieldgroup, {
  FormValues as OtherLocationFieldgroupValues,
} from 'mlp-client/src/flows/components/other-location-field-group/OtherLocationFieldgroup'
import { RadioCardField } from 'mlp-client/src/form/components/fields/RadioFields'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import { ValidateByAddress } from 'mlp-client/src/contracts/actions'

import { getInitialLocation, addressKeys } from './utils'

export interface LocationFlowData {
  address?: Address
  addressType?: AddressTypes
  date?: string
  time?: string
}

interface AdditionalComponent {
  Component: React.ReactType
  config?: GenericObject
}

export interface StepConfig {
  showOtherLocation?: boolean
  hideCountry?: boolean
  hideHouseNumber?: boolean
  editStep?: string
  addressToHide?: AddressTypes[]
  side?: React.ReactNode
  options?: Options
  isValidateByGeo?: boolean
}

export interface Props extends StepViewProps<StepConfig, LocationFlowData> {
  dispatch: Dispatch<Action>
  user: User
  countryCode: string
  additionalComponents?: readonly AdditionalComponent[]
  submitFlow?(
    data: LocationFlowData,
    errorCallback: (error: boolean) => void,
  ): void
  titleLabel?: string
  descriptionLabel?: string
}

export interface FormValues extends OtherLocationFieldgroupValues {
  addressType?: AddressTypes
}

type FormComplete = (errors?: SubmissionErrors) => void

const defaultTitleLabel =
  'myLeaseplan.serviceRequest.steps.selectLocation.title'
const defaultDescriptionLabel =
  'myLeaseplan.serviceRequest.steps.selectLocation.locationDetails'

class LocationStep extends React.PureComponent<Props> {
  getFormSubmitData = (values: FormValues) => {
    const { addressType, ...otherData } = omit(values, [...addressKeys])
    const addressData = pick(values, addressKeys) as AddressData
    const addressByType =
      addressType === AddressTypes.OTHER
        ? getUserOtherAddress(addressData)
        : getUserAddressByType(this.props.user, addressType)

    return { ...otherData, address: addressByType, addressType }
  }

  onSubmit = (
    values: FormValues,
    _formApi: FormApi,
    onFormComplete: FormComplete,
  ) => {
    const {
      goToNextStep,
      setFlowData,
      submitFlow,
      dispatch,
      config: { isValidateByGeo },
    } = this.props
    const formData = this.getFormSubmitData(values)

    if (formData.addressType === AddressTypes.OTHER && isValidateByGeo) {
      dispatch(
        new ValidateByAddress({
          location: formData.address,
          onComplete: isError => {
            if (!isError) {
              setFlowData(formData, goToNextStep)
            } else {
              onFormComplete({ isError })
            }
          },
        }),
      )
    } else if (submitFlow) {
      const callback = (error: boolean) => {
        if (error) {
          onFormComplete({ error })
        } else {
          goToNextStep()
          onFormComplete()
        }
      }

      submitFlow(formData, callback)
    } else {
      setFlowData(formData, goToNextStep)
    }
  }

  getInitialValues = (): FormValues => {
    const {
      countryCode,
      flowData: { address, ...restFlowData },
    } = this.props
    const country = address?.country?.toLocaleLowerCase() ?? countryCode

    return {
      ...restFlowData,
      ...getInitialLocation(address, country),
    }
  }

  renderOtherLocationForm = (addressType: FormValues) => {
    const {
      config: { hideHouseNumber, hideCountry, options },
    } = this.props

    return addressType === AddressTypes.OTHER ? (
      <OtherLocationFieldgroup
        hideHouseNumber={hideHouseNumber}
        hideCountry={hideCountry}
        countries={options}
      />
    ) : null
  }

  renderAdditionalComponents = () => {
    const {
      additionalComponents,
      config: { editStep },
      flowData,
      goToStep,
    } = this.props

    return additionalComponents.map(
      (component: AdditionalComponent, i: number) => {
        const { Component, config } = component

        return (
          <Component
            key={`additional_location_component${i}`}
            editClickHandler={() => goToStep(editStep)}
            flowData={flowData}
            config={config}
          />
        )
      },
    )
  }

  render() {
    const {
      user,
      additionalComponents,
      config: { showOtherLocation, side, addressToHide = [] },
      titleLabel = defaultTitleLabel,
      descriptionLabel = defaultDescriptionLabel,
    } = this.props
    const addressesToHide = !showOtherLocation
      ? [AddressTypes.OTHER, ...addressToHide]
      : [...addressToHide]

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel={titleLabel} marginTitle={2} side={side}>
          <Form
            keepDirtyOnReinitialize
            initialValues={this.getInitialValues()}
            onSubmit={this.onSubmit}
            render={({
              handleSubmit,
              values: { addressType },
              hasValidationErrors,
              submitting,
              submitFailed,
              dirtySinceLastSubmit,
            }: FormRenderProps) => (
              <form onSubmit={handleSubmit}>
                {additionalComponents ? (
                  this.renderAdditionalComponents()
                ) : (
                  <Headline variant="200" withMarginBottom>
                    <Translation id={descriptionLabel} />
                  </Headline>
                )}
                <Spacing mb={2}>
                  <RadioCardField
                    name="addressType"
                    options={getLocationOptions(user, addressesToHide)}
                    validations={[required]}
                  />
                </Spacing>
                {this.renderOtherLocationForm(addressType)}
                <SubmitStatus
                  submitting={submitting}
                  failed={!dirtySinceLastSubmit && submitFailed}
                  failedTranslation="myLeaseplan.serviceRequest.steps.selectLocation.failed"
                >
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors || submitting}
                  />
                </SubmitStatus>
              </form>
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'user' | 'countryCode'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  user: getUser(state),
  countryCode: getCountry(state),
})

export { LocationStep }

export default connect(mapStateToProps)(LocationStep)
