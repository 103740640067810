import { makeTranslationPath } from 'mlp-client/src/utils'
import { EventCategoryFlow } from 'mlp-client/src/analytics/types'
import ContactDetails from 'mlp-client/src/flows/components/steps/contact-details-step/ContactDetailsStep'
import Location from 'mlp-client/src/flows/components/steps/location/LocationStep'
import SelectSupplierStep from 'mlp-client/src/flows/components/steps/select-supplier-step/SelectSupplierStep'
import {
  ConfirmBookingStep,
  SelectMaintenanceTypeStep,
  SelectMalfunctionsStep,
  ThankYouStep,
} from 'mlp-client/src/flows/maintenance/components/steps'
import SpecialInstructionsStep from 'mlp-client/src/flows/maintenance/components/steps/special-instructions-step/SpecialInstructionsStep'
import {
  AdditionalServices,
  MaintenanceType,
  StepNames,
} from 'mlp-client/src/flows/maintenance/enums'
import { FlowData } from 'mlp-client/src/flows/maintenance/types'
import { Options } from 'mlp-client/src/form/types'
import { BookingRequestType } from 'mlp-client/src/types'

const flowName = EventCategoryFlow.MAINTENANCE

const maintenanceTranslationPath = makeTranslationPath(
  'myLeaseplan.maintenance.steps',
)

const maintenanceTypeOption: Options = [
  {
    value: MaintenanceType.Regular,
    title: maintenanceTranslationPath('selectMaintenanceType.regular'),
    dataE2eId: MaintenanceType.Regular,
  },
  {
    value: MaintenanceType.Malfunctions,
    title: maintenanceTranslationPath('selectMaintenanceType.malfunctions'),
    dataE2eId: MaintenanceType.Malfunctions,
  },
]

export const maintenanceTypeStepConfig = {
  stepView: SelectMaintenanceTypeStep,
  stepConfig: {
    options: maintenanceTypeOption,
    title: maintenanceTranslationPath('selectMaintenanceType.subtitle'),
    posterTitleLabel: 'myLeaseplan.serviceRequest.maintenance.posterTitle',
    posterTextLabel: 'myLeaseplan.serviceRequest.maintenance.posterText',
    titleLabel: maintenanceTranslationPath('selectMaintenanceType.title'),
    isDisclaimerEnabled: false,
    collectAndReturnWithoutSuppliersStep: false,
  },
}

export const locationStepConfig = {
  stepView: Location,
  stepConfig: {
    showOtherLocation: true,
    isValidateByGeo: true,
  },
}

export const malfunctionsStepConfig = {
  stepView: SelectMalfunctionsStep,
  stepConfig: {
    titleLabel: 'myLeaseplan.serviceRequest.maintenance.areThereMalfunctions',
    textField: false,
  },
}

export const specialInstructionsStepConfig = {
  stepView: SpecialInstructionsStep,
  stepConfig: {
    titleLabel: maintenanceTranslationPath('updateMileage.extraInformation'),
    metaTitle: maintenanceTranslationPath('updateMileage.detailsTitle'),
    subTitle: maintenanceTranslationPath('specialInstructions.toHelpService'),
    instructionsTitle: maintenanceTranslationPath(
      'specialInstructions.instructions',
    ),
    legalNote: 'myLeaseplan.genericNotifications.OpenTextLegalNote',
    showDescription: true,
  },
}

export const selectSupplierStepConfig = {
  stepView: SelectSupplierStep,
  stepConfig: {
    flow: flowName,
    bookingType: BookingRequestType.SERVICE,
    labels: {
      title: 'myLeaseplan.serviceRequest.steps.selectSupplier.pageTitle',
      noSuppliersNearby:
        'myLeaseplan.serviceRequest.steps.selectSupplier.noSuppliers',
      invalidAddressTitle:
        'myLeaseplan.serviceRequest.steps.selectSupplier.invalidAddressTitle',
      invalidAddress:
        'myLeaseplan.serviceRequest.steps.selectSupplier.invalidAddress',
      changeAddress:
        'myLeaseplan.serviceRequest.steps.selectSupplier.changeAddress',
      availableServiceCenters:
        'myLeaseplan.serviceRequest.steps.selectSupplier.availableServiceCenters',
      hourWarning:
        'myLeaseplan.serviceRequest.steps.selectSupplier.hourWarning',
    },
  },
}

export const confirmBookingStepConfig = {
  stepView: ConfirmBookingStep,
  stepConfig: {
    titleLabel: maintenanceTranslationPath('confirmBooking.title'),
    maintenanceTypeLabel: maintenanceTranslationPath(
      'confirmBooking.isRegularMaintenanceTitle',
    ),
    malfunctionsLabel: maintenanceTranslationPath(
      'confirmBooking.malfunctionsTitle',
    ),
    additionalServicesLabel: maintenanceTranslationPath(
      'confirmBooking.additionalServicesTitle',
    ),
    specialInstructionsLabel: maintenanceTranslationPath(
      'confirmBooking.messageTitle',
    ),
    contactDetailsLabel:
      'myLeaseplan.tyres.steps.checkDetails.listItems.contactDetails.title',
    pickUpTimeAndLocation: maintenanceTranslationPath(
      'confirmBooking.dateAndLocationTitle',
    ),
    noSelectedMalfunctionsLabel: 'myLeaseplan.genericNotifications.notSelected',
    supplierLabel: maintenanceTranslationPath('confirmBooking.whenAndWhere'),
    dateLocationEditStep: StepNames.SELECT_LOCATION,
  },
}

export const thankYouStepConfig = {
  stepView: ThankYouStep,
  stepConfig: {
    titleLabel: maintenanceTranslationPath('thankYou.pageTitle'),
    headerText: maintenanceTranslationPath('thankYou.whatsNextHeader'),
    emailText: maintenanceTranslationPath('thankYou.email'),
    confirmationDynamic: maintenanceTranslationPath(
      'thankYou.confirmationDynamic',
    ),
    statusText: maintenanceTranslationPath('thankYou.statusText'),
    timelineLink: maintenanceTranslationPath('thankYou.statusLink'),
  },
}

export const checkDetailsStepConfig = {
  stepView: ContactDetails,
  stepConfig: {},
}

interface CountryConfig {
  isLocationStepDisabled?: boolean
  isMileageNeededForSuppliersStep?: boolean
  isPickupAddressDefined?: boolean
  isDateAndLocationStepEnabled?: boolean
}

export const getSteps = (countryConfig?: CountryConfig) => (
  flowData: FlowData,
) => {
  const {
    isLocationStepDisabled,
    isMileageNeededForSuppliersStep,
    isPickupAddressDefined,
    isDateAndLocationStepEnabled,
  } = countryConfig || {}
  const showMalfunctions =
    flowData.maintenanceType &&
    flowData.maintenanceType.includes(MaintenanceType.Malfunctions)
  const showDateAndLocation =
    isDateAndLocationStepEnabled &&
    !isPickupAddressDefined &&
    flowData.additionalServices &&
    flowData.additionalServices.includes(AdditionalServices.COLLECT_AND_RETURN)

  const extraSteps = []

  if (showMalfunctions) {
    extraSteps.push(StepNames.SELECT_MALFUNCTIONS)
  }

  if (isMileageNeededForSuppliersStep) {
    extraSteps.push(StepNames.SPECIAL_INSTRUCTIONS)
  }

  if (showDateAndLocation) {
    extraSteps.push(StepNames.SELECT_DATE_AND_LOCATION)
  } else {
    if (!isLocationStepDisabled) {
      extraSteps.push(StepNames.SELECT_LOCATION)
    }

    extraSteps.push(StepNames.SELECT_SUPPLIER)
  }

  if (!isMileageNeededForSuppliersStep) {
    extraSteps.push(StepNames.SPECIAL_INSTRUCTIONS)
  }

  if (flowData.isContractDetailsEnabled) {
    extraSteps.push(StepNames.CHECK_CONTACT_DETAILS)
  }

  return [
    StepNames.SELECT_MAINTENANCE_TYPE,
    ...extraSteps,
    StepNames.CONFIRM_BOOKING,
    StepNames.THANK_YOU,
  ]
}
