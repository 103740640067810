import { createSelector } from 'reselect'

import {
  Malfunctions,
  RepairMaintenanceData,
} from 'mlp-client/src/flows/maintenance/types'
import { AppState, BookingStatus, BookingResult } from 'mlp-client/src/types'
import { getNearbySuppliers } from 'mlp-client/src/contracts/selectors'

export const getMaintenance = (state: AppState) => state.maintenance

export const getRepairMaintenanceData = (
  state: AppState,
): RepairMaintenanceData => getMaintenance(state).repairMaintenanceData

export const getIsLoadingMaintenanceData = (state: AppState): boolean =>
  getRepairMaintenanceData(state).isLoading

export const isBookingConfirmed = (state: AppState): boolean => {
  const bookingResult: BookingResult = getMaintenance(state)
    .maintenanceBookingResult

  return bookingResult && bookingResult.status === BookingStatus.CONFIRMED
}

export const getMalfunctions = (state: AppState): Malfunctions =>
  getRepairMaintenanceData(state).malfunctions

export const getAdditionalServices = createSelector(
  getRepairMaintenanceData,
  data => data.additionalServices,
)

export const getSupplierNameById = (state: AppState, id: string): string =>
  getNearbySuppliers(state).suppliers?.find(supplier => supplier.id === id)
    ?.name
