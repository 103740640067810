import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/fuel-card/types'

export type Props = FlowProps<FlowData>

class FleetManagerStep extends React.PureComponent<Props> {
  renderWorkAddressIsMissingBlock = () => (
    <>
      <Headline component="h1" variant="400" withMarginBottom>
        <Translation id="myLeaseplan.fuelCard.steps.fleetManager.workAddressIsMissing.title" />
      </Headline>
      <Text component="p">
        <Translation id="myLeaseplan.fuelCard.steps.fleetManager.workAddressIsMissing.description" />
      </Text>
    </>
  )

  render() {
    const {
      closeFlow,
      flowData: { hasWorkAddress },
    } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        {hasWorkAddress ? (
          <>
            <Headline component="h1" variant="400" withMarginBottom>
              <Translation id="myLeaseplan.fuelCard.steps.fleetManager.title" />
            </Headline>
            <Spacing mb={0.5}>
              <Headline variant="300">
                <Translation id="myLeaseplan.fuelCard.steps.fleetManager.subtitle" />
              </Headline>
            </Spacing>
            <Text component="p">
              <Translation id="myLeaseplan.fuelCard.steps.fleetManager.description" />
            </Text>
          </>
        ) : (
          this.renderWorkAddressIsMissingBlock()
        )}
      </ThankyouPageLayout>
    )
  }
}

export { FleetManagerStep }
export default FleetManagerStep
