export interface InitAuth {
  audience: string
  clientId: string
  domain: string
  isMobile?: boolean
  initialMobileCredentials?: {
    accessToken: string
    expiresAt: string
  }
}

export interface LocalStorageSession {
  accessToken: string
  idToken: string
  expiresAt: string
}

export interface AuthToken {
  iss?: string
  sub?: string
  aud?: string
  iat?: number
  exp?: number
  azp?: string
  scope?: string
}

export enum ExternalLogoutLinks {
  Default = 'my/login',
  'fr-fr' = 'connexion',
}
