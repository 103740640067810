import React from 'react'
import { connect } from 'react-redux'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import ViewContract from 'mlp-client/src/contracts/components/ViewContract/ViewContract'
import { getContract } from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import { ContractsFeatureConfig as LpisContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { AppState } from 'mlp-client/src/types'

import { filterTaxationValues } from '../utils'

// TODO: improve types in MLP-3306. There is no need for 2 separate types, they are very similar and can be combined.
export interface Props
  extends MyLeaseplanFeatureProps<LpisContractsFeatureConfig> {
  contract: Contract
  isMobileApp: boolean
  metaTitle: string
  metaDescription: string
}

class ViewContractFlow extends React.PureComponent<Props> {
  renderMain() {
    const { featureConfig, contract, isMobileApp } = this.props
    const configuration = filterTaxationValues(
      featureConfig.configuration,
      contract?.fullTypeOfContract,
    )

    return (
      <ViewContract
        config={configuration}
        contract={contract}
        isMobileApp={isMobileApp}
      />
    )
  }

  render() {
    const { metaDescription, metaTitle } = this.props

    return (
      <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
        {this.renderMain()}
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'metaTitle' | 'metaDescription' | 'contract' | 'isMobileApp'
>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  contract: getContract(state),
  isMobileApp: getIsMobileApp(state),
  metaTitle: getTranslation(state, 'myLeaseplan.contract.car.title'),
  metaDescription: getTranslation(state, 'myLeaseplan.contract.view.subtitle'),
})

export { ViewContractFlow }
export default connect(mapStateToProps)(ViewContractFlow)
