import { createSelector } from 'reselect'

import { ApiType } from 'mlp-client/src/features-configuration/types'
import { config } from 'mlp-client/src/app-config'
import {
  getCountry,
  getLanguage,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import { DistanceUnit } from 'mlp-client/src/enums'
import { FeatureStatus } from 'mlp-client/src/features-configuration/enums'
import {
  getCountryHolidays as getCountryHolidaysUtil,
  getSCLanguageCode,
  mapHolidaysDate,
  objectValuesToArray,
} from 'mlp-client/src/utils'
import {
  getUserHomeAddress,
  getUserWorkAddress,
} from 'mlp-client/src/user/utils'
import {
  getMyLeaseplanConfig,
  getShowAllFeatures,
} from 'mlp-client/src/content/selectors'
import { getUser } from 'mlp-client/src/user/selectors'
import { Address } from 'mlp-client/src/user/types'

import { getNextWorkingDate } from '../utils'
import { AppState } from '../types'

export const downloadIosAppLink = 'itms-apps://itunes.apple.com/app/'
export const downloadAndroidAppLink = 'https://play.app.goo.gl/?link='

export const getApiType = (state: AppState): ApiType =>
  getMyLeaseplanConfig(state).apiType

export const getTranslationSelector = (id: string, fallback: string = '') => (
  state: AppState,
) => getTranslation(state, `myLeaseplan.${id}`, fallback)

const isInstallMobileAppEnabled = (state: AppState) =>
  getMyLeaseplanConfig(state).isInstallMobileAppEnabled

export const isIOS = () => /iP(hone|od|ad)/.test(window.navigator.platform)

export const getInstallAppLink = (state: AppState): string => {
  if (!isInstallMobileAppEnabled(state)) {
    return
  }

  const iosInstallLink = config.IOS_INSTALL_APP_URL
  const androidInstallLink = config.ANDROID_INSTALL_APP_URL

  if (isIOS() && iosInstallLink) {
    return downloadIosAppLink + iosInstallLink
  }

  if (!isIOS() && androidInstallLink) {
    return downloadAndroidAppLink + androidInstallLink
  }
}

// createSelector is deliberately NOT used for the selector in order
// to avoid caching of wrong date
export const supplierAvailabilityDateSelector = (
  state: AppState,
  holidays: readonly string[] = [],
) => {
  const supplierAvailabilityTimeFrame = supplierAvailTimeFrameSelector(state)

  return getNextWorkingDate(supplierAvailabilityTimeFrame, holidays)
}

export const getDistanceUnit = (state: AppState): DistanceUnit =>
  getMyLeaseplanConfig(state).distanceUnit

export const isPublicHolidaysDisabled = (state: AppState): boolean => {
  const isPublicHolidaysDisabledFlag = getMyLeaseplanConfig(state)
    .isPublicHolidaysDisabled

  if (isPublicHolidaysDisabledFlag === FeatureStatus.DEV_MOD) {
    return getShowAllFeatures(state)
  }

  return isPublicHolidaysDisabledFlag === FeatureStatus.ENABLED
}

export const getTranslatedDistanceUnit = (
  state: AppState,
): string | undefined => {
  const distanceUnit = getDistanceUnit(state)

  return distanceUnit
    ? getTranslation(state, `myLeaseplan.shared.units.distance.${distanceUnit}`)
    : undefined
}

export const getCountryHolidays = createSelector(
  getCountry,
  getLanguage,
  isPublicHolidaysDisabled,
  getCountryHolidaysUtil,
)

export const getAddressFormat = (state: AppState): readonly string[] => {
  const addressFormat = getMyLeaseplanConfig(state).addressFormat

  return objectValuesToArray(addressFormat)
}

export const getHolidaysDateList = createSelector(
  getCountry,
  getLanguage,
  isPublicHolidaysDisabled,
  mapHolidaysDate,
)

export const getSCLanguage = createSelector(
  getLanguage,
  getCountry,
  getSCLanguageCode,
)

export const supplierAvailTimeFrameSelector = (state: AppState) =>
  getMyLeaseplanConfig(state).supplierAvailabilityTimeFrame

export const isFeatureEnabled = (state: AppState, feature?: string) => {
  const config = getMyLeaseplanConfig(state)

  if (!feature) {
    return config.enabled || getShowAllFeatures(state)
  }

  const featureConfig = config[feature]

  if (typeof featureConfig === 'boolean') {
    return featureConfig || getShowAllFeatures(state)
  }

  if (typeof featureConfig?.enabled === 'boolean') {
    return featureConfig.enabled || getShowAllFeatures(state)
  }

  return false
}

export const getLocationById = (state: AppState, id: string): Address => {
  const driver = getUser(state)

  switch (id) {
    case 'home':
      return getUserHomeAddress(driver.addresses)
    case 'work':
      return getUserWorkAddress(driver.addresses)
    default:
      return null
  }
}
