import { Spacing } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'

import {
  LinkStyled,
  TermsAndConditionsWrapper,
} from './TermsAndConditionsPreLogin.styled'

class TermsAndConditionsPreLogin extends React.PureComponent {
  render() {
    return (
      <>
        <TermsAndConditionsWrapper variant="200">
          <Spacing mr={2} component="span">
            <LinkStyled
              component={RouteLink}
              target="_blank"
              to="myLeaseplan.external.legal.privacyStatement"
            >
              <Translation id="myLeaseplan.login.privacy" />
            </LinkStyled>
          </Spacing>
          <LinkStyled
            component={RouteLink}
            target="_blank"
            to="myLeaseplan.external.legal.termsAndConditions"
          >
            <Translation id="myLeaseplan.login.termsConditions" />
          </LinkStyled>
        </TermsAndConditionsWrapper>
      </>
    )
  }
}

export { TermsAndConditionsPreLogin }
export default TermsAndConditionsPreLogin
