/*

MLP-specific copy of: src/app/features/shared/components/FooterSmall.tsx

Differences with original:
- It's not connected to redux, thus doesn't load items itself
- Doesn't support "dynamic footer structure" (whatever it can be)
- All CSS class-based styling has been replaced with corresponding styled components
*/

import { Color, Grid, GridItem, Hide, Paper, Spacing } from '@leaseplan/ui'
import { Text, TextProps } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { NavigationItem } from 'mlp-client/src/types'
import Translation from 'mlp-client/src/localization/Translation'
import { ContentLayout } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper'
import DynamicLink from 'mlp-client/src/components/dynamic-link/DynamicLink'
import { FooterNavigation } from 'mlp-client/src/analytics/actions'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

const FooterLogoImage = styled.img`
  pointer-events: none;

  ${!isLeaseplan() &&
  `
    width: 200px;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  `}

  ${isLeaseplan() &&
  `
    position: absolute;
    top: -90px;
    right: 0;
    z-index: 1;
    width: 273px;
    height: 143px;
  `}
`

const FooterLogoHolder = styled.div`
  ${!isLeaseplan() &&
  `
    display: flex;
    justify-content: center;
  `}

  ${isLeaseplan() &&
  `
    position: relative;
  `}
`

const CenteredGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const StyledText = styled(Text)`
  display: flex;
  justify-content: center;
  width: 100%;
`

const FooterLink = styled(DynamicLink)`
  text-decoration: none;
  color: ${isLeaseplan() ? Color.thunder : Color.white};
  &:hover {
    text-decoration: underline;
  }
`

const StyledPaper = styled(Paper)`
  > * {
    color: ${isLeaseplan() ? Color.thunder : Color.white};

    background-color: ${isLeaseplan()
      ? Color.white
      : ColorEnumSelector('midOrange')};
  }
`

const footerTextSize: TextProps['variant'] = '100'

const FooterLogo: React.FC<{ src: string; testId?: string }> = props => (
  <Hide until="tablet">
    <FooterLogoHolder>
      <FooterLogoImage src={props.src} data-test-id={props.testId} />
    </FooterLogoHolder>
  </Hide>
)

FooterLogo.displayName = 'FooterLogo'

const FooterCopyrightText: React.FC = () => (
  <Text variant={footerTextSize} loose>
    <Translation
      id="myLeaseplan.shared.footer.copyright"
      replace={{ year: new Date().getFullYear() }}
    />
  </Text>
)

FooterCopyrightText.displayName = 'FooterCopyrightText'

const FooterItem: React.FC<{ item: NavigationItem }> = ({ item }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(
      new FooterNavigation({
        eventLabel: item.title,
      }),
    )
  }

  const renderLink = () => {
    const LinkComponent = !isLeaseplan() ? StyledText : Text

    return (
      <LinkComponent variant={footerTextSize} loose>
        <FooterLink
          data-e2e-id={item.to || item.url}
          data-tag-id={`footer-small-link-${item.to}`}
          to={item.to || item.url || ''}
          onClick={handleClick}
        >
          {item.navigationTitle}
        </FooterLink>
      </LinkComponent>
    )
  }

  const gridSpan = !isLeaseplan()
    ? { mobile: 12, tablet: 6, lap: 4 }
    : { mobile: 12, tablet: 6, lap: 3 }

  return <GridItem span={gridSpan}>{renderLink()}</GridItem>
}

FooterItem.displayName = 'FooterItem'

const FooterItemList: React.FC<{ items: readonly NavigationItem[] }> = ({
  items,
}) => (
  <Grid
    verticalGutter={false}
    justifyContent={isLeaseplan() ? 'flex-start' : 'center'}
  >
    {items.map(item => (
      <FooterItem item={item} key={item.navigationTitle} />
    ))}
  </Grid>
)

FooterItemList.displayName = 'FooterItemList'

const renderLuxembourgFooterContent = (items: readonly NavigationItem[]) => (
  <CenteredGrid>
    <Spacing pb={2}>
      <FooterItemList items={items} />
    </Spacing>
    <FooterLogoHolder>
      <FooterLogoImage
        src="/static/images/logo-footer-leasys.png"
        data-test-id="footerlogo"
      />
    </FooterLogoHolder>
    <Spacing pb={2}>
      <FooterCopyrightText data-test-id="footercopyright" />
    </Spacing>
  </CenteredGrid>
)

const renderDefaultFooterContent = (items: readonly NavigationItem[]) => (
  <Grid gutter={false}>
    <GridItem span={{ mobile: 12, tablet: 9 }}>
      <Spacing pb={2}>
        <FooterItemList items={items} />
      </Spacing>
      <FooterCopyrightText data-test-id="footercopyright" />
    </GridItem>
  </Grid>
)

const renderFooterContent = (items: readonly NavigationItem[]) => {
  if (!isLeaseplan()) {
    return renderLuxembourgFooterContent(items)
  }

  return (
    <>
      <FooterLogo
        src="/static/images/logo-footer-orange.png"
        testId="footerlogo"
      />
      {renderDefaultFooterContent(items)}
    </>
  )
}

interface Props {
  className?: string
  items: readonly NavigationItem[]
}

export const Footer: React.FC<Props> = ({ items = [], className }) => (
  <footer className={className}>
    <StyledPaper elevation={0}>
      <ContentLayout>
        <Spacing pv={2}>{renderFooterContent(items)}</Spacing>
      </ContentLayout>
    </StyledPaper>
  </footer>
)

Footer.displayName = 'Footer'
