import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { DistanceUnit } from 'mlp-client/src/enums'
import { navigation } from 'mlp-client/src/features-configuration/default/navigation'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

/**
 * This is failback config
 * In most cases we need it to show NotFound page for routes without the country code
 */
export const myleaseplanConfig: MyleaseplanConfig = {
  enabled: true,
  loginEnabled: true,
  accountLinkEnabled: false,
  distanceUnit: DistanceUnit.KM,
  login: {
    showRegistrationLink: false,
  },
  navigation,
  isExternalLoginEnabled: !isLeaseplan()
}
