import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

interface DefaultProps extends IconProps {
  className?: string
  title?: string
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

export default class ChevronRightIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: '',
    color: 'thunder',
    height: '12',
    width: '8',
    title: 'Chevron right icon',
  }

  render() {
    const { className, color, height, width, title } = this
      .props as PropsWithDefaults

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 8 12"
        className={className}
      >
        <title>{title}</title>
        <polygon
          points="1.6 12 7.8 5.9 1.6 0 0.2 1.3 5.1 5.9 0.2 10.7 1.6 12"
          fill={ColorEnumSelector(color)}
        />
      </svg>
    )
  }
}
