export interface PageViewEventPayload {
  pageTitle: string
  pageTitleKey: string
}

export interface FlowCancellationPayload {
  eventCategory: string
  flowName: EventCategoryFlow
  currentStepNr: number
  totalStepNrFlow: number
}

export enum EventCategoryService {
  MAIN_SERVICE = 'main service',
  FAST_SERVICE = 'fast service',
}

export enum EventCategoryFlow {
  DOCUMENTS = 'Vehicle Documents',
  TYRES = 'Tyres',
  RETURN = 'Return',
  GLASS_REPAIR = 'Glass Repair',
  FUEL_CARD = 'Fuel Card',
  EXTEND_CONTRACT = 'Extend contract',
  CASH_CLAIM = 'Cash Claim',
  BUY = 'Buy',
  MAINTENANCE = 'Maintenance',
  DAMAGE_REPORT = 'Damage Report',
  MILEAGE_UPDATE = 'Mileage Update',
}

export enum EventAction {
  FLOW_START = 'flow start',
  FLOW_STEP_FORWARD = 'flow step forward',
  FLOW_STEP_BACK = 'flow step back',
  FLOW_FINISHED = 'flow finished',
  FLOW_SELECTION = 'flow selection',
}

export enum EventLabel {
  SERVICE_BOOKED = 'service booked',
  FLOW_SELECTION_OVERVIEW = 'flow selection overview',
}

export enum PageTitle {
  HOME = 'Home Page',
  DASHBOARD = 'Dashboard',
  VEHICLES = 'Vehicles',
  TIMELINE = 'Timeline',
  VEHICLE_ORDER = 'Vehicle Order',
  MAINTENANCE_BOOKING_DETAILS = 'Maintenance Booking Details',
  GLASS_BOOKING_DETAILS = 'Glass Booking Details',
  PROFILE_EDIT = 'Edit Profile',
  PROFILE_EDIT_FR = 'Edit Profile - Fr',
  PROFILE = 'View Profile',
  PROFILE_FR = 'View Profile - Fr',
  LOGIN = 'Login',
  CONTRACT_DETAILS = 'Contract Details',
  CONTRACT_DETAIL_FR = 'Contract Details - Fr',
  MAINTENANCE = 'Maintenance',
  DAMAGE_REPORT = 'Damage Report',
  STOLEN_VEHICLE = 'Stolen Vehicle',
  DAMAGE_REPORT_LIST = 'Damage Report List',
  DAMAGE_REPAIR = 'Damage Repair',
  FUEL_CARD = 'Fuel Card',
  UPDATE_MILEAGE = 'Update Mileage',
  CASH_CLAIM = 'Cash Claim',
  GLASS_REPAIR = 'Glass Repair',
  TYRES = 'Tyres',
  VEHICLE_BUY = 'Vehicle Buy',
  VEHICLE_RETURN = 'Vehicle Return',
  END_OF_LEASE = 'End Of Lease',
  COMPLAINTS = 'Complaints',
  COMPLAINTS_THANK_YOU = 'Complaints - Thank You',
  REQUEST_FOR_INFORMATION = 'Request For Information',
  REQUEST_FOR_INFORMATION_THANK_YOU = 'Request For Information - Thank You',
  SELECT_VEHICLE_DOCUMENT = 'Select Vehicle Document',
  VEHICLE_DOCUMENTS = 'Vehicle Documents',
  ATTACH_CAR = 'Attach Car To Driver',
  UNAUTHORIZED = 'Unauthorized',
  RESET_PASSWORD = 'Reset Password',
  REQUEST_ACCOUNT = 'Request Account',
  FUEL_HISTORY = 'Fuel History',
  FINES = 'Fines',
  EXTEND_CONTRACT = 'Extend Contract',
  UNDER_MAINTENANCE = 'Under Maintenance',
  SOMETHING_WENT_WRONG = 'Something Went Wrong',
  NOT_FOUND = 'Not Found',
  AUTHORIZE = 'Authorize',
  LOGIN_REDIRECT = 'Login Redirect',
  LOGOUT_REDIRECT = 'Logout Redirect',
  REDIRECT_TO_CORRECT_LOCALE = 'Redirect To Correct Locale',
  ONE_PARTY_DAMAGE = 'One Party Damage',
  QUOTE_DETAILS = 'Quote Details',
}
