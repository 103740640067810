import React from 'react'
import { connect } from 'react-redux'

import { CountryCode, Locales } from 'mlp-client/src/localization/enums'
import {
  countryDefaultLocaleSelector,
  getCountry,
  getLocaleParams,
  getLocalizedRoute,
} from 'mlp-client/src/localization/selectors'
import { isAuthenticated } from 'mlp-client/src/auth/auth'
import protectAuthRoute from 'mlp-client/src/auth/protectAuthRoute'
import { AppState } from 'mlp-client/src/types'
import { getCountryCode } from 'mlp-client/src/user/selectors'

export interface Props {
  targetCountryCode?: CountryCode
  targetLocale?: Locales
  currentCountryCode: CountryCode
  currentLocale: Locales
  redirectRoute?: string
}

/**
 * The component redirects an authenticated user to myLP dashboard page
 * if they try to access a page/flow in a country they don't belong to.
 * For instance, if a Finnish user tries to access a French myLP page he/she
 * will be redirected to Finnish dashboard page. The country a user belongs to
 * comes in the authentication token.
 */
export class CountryRouteProtector extends React.PureComponent<Props> {
  canRedirect: boolean = typeof window !== 'undefined'

  get shouldRedirect(): boolean {
    const {
      targetCountryCode,
      targetLocale,
      currentCountryCode,
      redirectRoute,
    } = this.props

    return (
      targetCountryCode &&
      targetLocale &&
      redirectRoute &&
      targetCountryCode !== currentCountryCode
    )
  }

  redirectToTargetRoute(): void {
    const { currentLocale, targetLocale, redirectRoute } = this.props
    const targetRoute = redirectRoute.replace(currentLocale, targetLocale)

    window.location.replace(targetRoute)
  }

  redirect(): void {
    if (this.shouldRedirect && this.canRedirect) {
      this.redirectToTargetRoute()
    }
  }

  componentDidMount() {
    this.redirect()
  }

  componentDidUpdate() {
    this.redirect()
  }

  render() {
    if (this.shouldRedirect) {
      return null
    }

    return this.props.children
  }
}

type ReduxProps = Pick<
  Props,
  | 'targetCountryCode'
  | 'targetLocale'
  | 'currentCountryCode'
  | 'currentLocale'
  | 'redirectRoute'
>

const mapStateToProps = (state: AppState): ReduxProps => {
  const localeParams = getLocaleParams(state)
  const targetCountryCode = isAuthenticated()
    ? getCountryCode(state) || getCountry(state)
    : undefined

  return {
    targetCountryCode,
    targetLocale:
      targetCountryCode &&
      countryDefaultLocaleSelector(state, { countryCode: targetCountryCode }),
    currentCountryCode: CountryCode[localeParams.countryCode],
    currentLocale: localeParams.locale,
    redirectRoute: getLocalizedRoute(state, 'myLeaseplan.homepage'),
  }
}

const ProtectingContainer = protectAuthRoute(
  connect(mapStateToProps)(CountryRouteProtector),
)

export default <T extends {}>(ChildComponent: React.ComponentType<T>) => {
  const protectRoute: React.FC<T> = props => (
    <ProtectingContainer>
      <ChildComponent {...props} />
    </ProtectingContainer>
  )

  protectRoute.displayName = `protectCountryRoute(${
    ChildComponent.displayName || ChildComponent.name
  })`

  return protectRoute
}
