interface IconSize {
  width: number
  height: number
}

export const closedIconSize: IconSize = {
  width: 28,
  height: 39,
}

export const openedIconSize: IconSize = {
  width: 36,
  height: 49,
}
