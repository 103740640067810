import { Currencies } from 'mlp-client/src/localization/enums'
import { getSubCategoryStep } from 'mlp-client/src/features-configuration/fr/utils'
import { MobileAppConfig } from 'mlp-client/src/features-configuration/types'
import Flow from 'mlp-client/src/flows/cash-claim/components/flow/Flow'
import {
  AccountDetailsStep,
  CheckDetailsStep,
  PurchaseDetailsStep,
  RadioListStep,
  ThankYouStep,
} from 'mlp-client/src/flows/cash-claim/components/steps'
import {
  CashClaimCategory,
  StepNames,
} from 'mlp-client/src/flows/cash-claim/enums'
import {
  CashClaimConfiguration,
  FlowData,
  Steps,
} from 'mlp-client/src/flows/cash-claim/types'
import { getCurrenciesOptions } from 'mlp-client/src/utils'
import { liquids } from 'mlp-client/src/features-configuration/data'

import { fuel, categories, roadSideAssistance } from './data'
import { isPreviewFeatureEnabled } from './utils'

const getAllSteps: Steps = {
  [StepNames.SELECT_CLAIM_TYPE]: {
    stepView: RadioListStep,
    stepConfig: {
      options: categories,
      title: 'myLeaseplan.newSetup.flows.cashClaim.steps.selectClaimType.title',
      posterTitleLabel:
        'myLeaseplan.newSetup.flows.cashClaim.steps.selectClaimType.titleLabel',
      posterTextLabel:
        'myLeaseplan.newSetup.flows.cashClaim.steps.selectClaimType.textLabel',
      posterImgSmall:
        '/static/images/my-leaseplan/mobile/cash-claim-mobile.png',
      posterImgMedium:
        '/static/images/my-leaseplan/cash-claim-poster-medium.jpg',
      posterImgLarge: '/static/images/my-leaseplan/cash-claim-poster-large.jpg',
      showFAQ: true,
      fieldName: 'category',
      optionsListE2eId: 'reimbursementTypesList',
    },
  },
  [StepNames.ROAD_SIDE_ASSISTANCE]: {
    stepView: RadioListStep,
    stepConfig: {
      options: roadSideAssistance,
      title:
        'myLeaseplan.newSetup.flows.cashClaim.steps.roadSideAssistance.header',
      fieldName: 'subCategory',
      optionsListE2eId: 'roadsideAssistanceTypesList',
    },
  },
  [StepNames.LIQUIDS]: {
    stepView: RadioListStep,
    stepConfig: {
      options: liquids,
      title: 'myLeaseplan.newSetup.flows.cashClaim.steps.liquids.header',
      fieldName: 'subCategory',
      optionsListE2eId: 'liquidTypesList',
    },
  },
  [StepNames.PURCHASE_DETAILS]: {
    stepView: PurchaseDetailsStep,
    stepConfig: {
      currencies: getCurrenciesOptions(Object.values(Currencies)),
      isReasonEnabled: true,
      defaultCurrency: Currencies.EUR,
    },
  },
  [StepNames.ACCOUNT_DETAILS]: {
    stepView: AccountDetailsStep,
    stepConfig: {
      ibanPlaceholder: 'FR14 2004 1010 0505 0001 3M02 606',
      isIbanEnabled: true,
      isOwnerEnabled: true,
      isBicEnabled: true,
    },
  },
  [StepNames.CHECK_DETAILS]: {
    stepView: CheckDetailsStep,
    stepConfig: {
      categories,
      subCategories: {
        [CashClaimCategory.FUEL]: fuel,
        [CashClaimCategory.ROAD_SIDE_ASSISTANCE]: roadSideAssistance,
        [CashClaimCategory.LIQUIDS]: liquids,
      },
    },
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {
      days: 5,
    },
  },
}

export const cashClaimConfig: MobileAppConfig<CashClaimConfiguration> = {
  enabled: isPreviewFeatureEnabled('cashClaims'),
  mobileApp: {
    disabled: true,
  },
  view: Flow,
  configuration: {
    allSteps: getAllSteps,
    getSteps: (flowData: FlowData): readonly StepNames[] =>
      [
        StepNames.SELECT_CLAIM_TYPE,
        getSubCategoryStep(flowData.category),
        StepNames.PURCHASE_DETAILS,
        StepNames.ACCOUNT_DETAILS,
        StepNames.CHECK_DETAILS,
        StepNames.THANK_YOU,
      ].filter(Boolean),
  },
}
