import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const FuelHistoryIconLeasys: FC<IconProps> = props => {
  FuelHistoryIconLeasys.defaultProps = defaultIconProps

  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={height}
      height={width}
    >
      <g fill={ColorEnumSelector(color)}>
        <rect x="16.82" y="46.17" width="17.58" height="3.46" />
        <g>
          <path d="M27.99,17.6c1.87,0,3.39,1.52,3.39,3.39V25H19.85v-4.01c0-1.87,1.52-3.39,3.39-3.39H27.99z" />
          <path
            d="M45.21,25.64c0,0.8-0.65,1.46-1.46,1.46s-1.46-0.65-1.46-1.46c0-0.8,0.65-1.46,1.46-1.46
				c0.58,0,1.08,0.35,1.31,0.85c0.02,0.07,0.04,0.14,0.06,0.21C45.17,25.37,45.21,25.5,45.21,25.64 M34.41,43.37h-17.6V19.48
				c0-2.62,2.13-4.76,4.76-4.76h8.08c2.62,0,4.76,2.13,4.76,4.76V43.37z M47.55,44.34V27.71c0-0.09-0.05-1.22-0.39-2.58
				c-0.05-0.32-0.14-0.62-0.27-0.91c-0.33-0.97-0.82-1.98-1.57-2.77l-4.79-4.79l-1.41,1.42l4.17,4.17
				c-1.68,0.23-2.99,1.66-2.99,3.41c0,1.91,1.55,3.46,3.46,3.46c0.66,0,1.27-0.2,1.79-0.52v15.75c0,1.81-1.52,1.97-1.98,1.98
				c-2.19,0-2.36-2-2.38-2.38v-7.24c0-1.44-0.43-2.52-1.27-3.22c-1.06-0.88-2.36-0.78-2.76-0.73h-0.77V19.48
				c0-3.73-3.03-6.76-6.76-6.76h-8.08c-3.73,0-6.76,3.03-6.76,6.76v24.88c0,0.55,0.45,1,1,1h19.6c0.55,0,1-0.45,1-1v-9.62h0.84
				l0.17-0.01c0.01,0,0.76-0.12,1.24,0.29c0.36,0.3,0.53,0.86,0.53,1.67v7.26c0.03,1.51,0.98,4.36,4.38,4.36
				C45.17,48.31,47.55,47.25,47.55,44.34"
          />
        </g>
      </g>
    </svg>
  )
}

export default FuelHistoryIconLeasys
