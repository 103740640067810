import { Button, Card, Grid, GridItem, Hide, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import {
  CallUsTile as CallUsTileType,
  CallUsTileContact,
  CallUsTileContractWorkingHours,
} from 'mlp-client/src/flows/fuel-card/types'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { AppState } from 'mlp-client/src/types'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { formatTelephoneHref } from 'mlp-client/src/utils'
import { triggerMobilePhoneAction } from 'mlp-client/src/timeline/components/ContactUsBanner/ContactUsBanner'

import { createWorkingTimeStr } from './utils'

export interface Props {
  callUsTile: CallUsTileType
  isMobileApp?: boolean
}

class CallUsTile extends React.PureComponent<Props> {
  renderDesktopPhoneNumber(phoneNumber: string, dataE2EId?: string) {
    return (
      <Headline variant="100" data-e2e-id={dataE2EId}>
        <Translation id={phoneNumber} />
      </Headline>
    )
  }

  renderMobilePhoneNumber(phoneNumber: string, dataE2EId?: string) {
    const { isMobileApp } = this.props

    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <Spacing mv={1}>
            <Button
              variant="secondary"
              component="a"
              size="s"
              href={!isMobileApp && `tel:${translate(phoneNumber)}`}
              onClick={() =>
                isMobileApp &&
                triggerMobilePhoneAction(
                  formatTelephoneHref(translate(phoneNumber)),
                )
              }
              key={translate(phoneNumber)}
              data-e2e-id={dataE2EId}
            >
              {translate(phoneNumber)}
            </Button>
          </Spacing>
        )}
      </LocalizationContext.Consumer>
    )
  }

  renderWorkingHours = (
    workingHours: CallUsTileContractWorkingHours,
  ): React.ReactNode => {
    const workingTime = createWorkingTimeStr(workingHours)

    return (
      <GridItem key={workingTime} span={12}>
        {this.renderTextComponent(workingTime)}
      </GridItem>
    )
  }

  renderPhoneNumber = (phoneNumber: string): React.ReactElement => (
    <>
      <Hide until="tablet">{this.renderDesktopPhoneNumber(phoneNumber)}</Hide>
      <Hide from="tablet">{this.renderMobilePhoneNumber(phoneNumber)}</Hide>
    </>
  )

  renderTextComponent = (text: string): React.ReactNode => (
    <>
      <Hide from="tablet">
        <Text variant="200">
          <Translation id={text} />
        </Text>
      </Hide>
      <Hide until="tablet">
        <Text>
          <Translation id={text} />
        </Text>
      </Hide>
    </>
  )

  renderContactTile = ({
    phoneNumber,
    subtitle,
    workingHours,
  }: CallUsTileContact): React.ReactNode => (
    <GridItem key={phoneNumber} span={{ mobile: 12, mobileLandscape: 'grow' }}>
      <Spacing mt={1}>
        {this.renderPhoneNumber(phoneNumber)}
        {this.renderTextComponent(subtitle)}
        <Grid gutter={false}>
          {workingHours && workingHours.map(this.renderWorkingHours)}
        </Grid>
      </Spacing>
    </GridItem>
  )

  render() {
    const { title, logo: Logo, contacts, subtitle } = this.props.callUsTile

    return (
      <Card data-e2e-id="fuelCard-callUsTile">
        <Spacing m={3}>
          <Grid
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <GridItem>
              <Logo width="80" height="80" />
            </GridItem>
            <GridItem span={{ mobile: 12, mobileLandscape: 'grow' }}>
              <Headline variant="200" component="h3">
                <Translation id={title} />
              </Headline>
              {this.renderTextComponent(subtitle)}
              <Grid>{contacts && contacts.map(this.renderContactTile)}</Grid>
            </GridItem>
          </Grid>
        </Spacing>
      </Card>
    )
  }
}

type ReduxProps = Pick<Props, 'isMobileApp'>
const mapStateToProps = (state: AppState): ReduxProps => ({
  isMobileApp: getIsMobileApp(state),
})

export { CallUsTile }
export default connect(mapStateToProps)(CallUsTile)
