import {
  BreakpointName,
  Collapse,
  CollapseContent,
  CollapseTrigger,
  IconProps,
  Spacing,
  withBreakpoint,
} from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'

import { FAQItemType } from 'mlp-client/src/FAQ/types'
import { Markdown } from 'mlp-client/src/flows/components/markdown/Markdown'
import { ModalLinkRenderer } from 'mlp-client/src/flows/components/markdown/renderers/custom/ModalLinkRenderer'
import { isDesktopBreakpoint, isTabletBreakpoint } from 'mlp-client/src/utils'

import { AnswerStyled } from './FAQ.styled'

export interface Props {
  item: FAQItemType
  breakpoint: BreakpointName
  open?: boolean
  openedIcon?: React.ComponentType<IconProps>
  closedIcon?: React.ComponentType<IconProps>
}

class FAQItem extends React.PureComponent<Props> {
  get isLargeBreakpoint() {
    return (
      isTabletBreakpoint(this.props.breakpoint) ||
      isDesktopBreakpoint(this.props.breakpoint)
    )
  }

  render() {
    const { openedIcon, closedIcon, item, open } = this.props
    const collapseProps = { open }

    return (
      <Collapse {...collapseProps}>
        <Spacing mb={1}>
          <CollapseTrigger
            openedIcon={openedIcon}
            closedIcon={closedIcon}
            iconPosition="left"
            iconSize="s"
          >
            <Spacing ml={0.5}>
              <Text
                variant={this.isLargeBreakpoint ? '300' : '200'}
                component="p"
                bold
              >
                {item.question}
              </Text>
            </Spacing>
          </CollapseTrigger>
        </Spacing>
        <CollapseContent>
          <Spacing ml={2}>
            <Spacing ml={0.5}>
              <AnswerStyled variant={this.isLargeBreakpoint ? '300' : '200'}>
                <Markdown
                  content={item.answer}
                  renderers={{ Link: ModalLinkRenderer }}
                />
              </AnswerStyled>
            </Spacing>
          </Spacing>
        </CollapseContent>
      </Collapse>
    )
  }
}

export { FAQItem }
export default withBreakpoint(FAQItem)
