import {
  FuelCards,
  FuelcardServiceType,
  StaticSupplier,
  StaticSuppliers,
} from 'mlp-client/src/flows/fuel-card/types'
import { SupplierCard } from 'mlp-client/src/types'
import { Options } from 'mlp-client/src/form/types'

export const getDeliveryPeridosByServiceType = (
  serviceType: string,
  deliveryPeriods: {
    daysForPinRestore: number
    daysForCardDelivery: number
  },
): number | undefined =>
  serviceType === FuelcardServiceType.FORGOT_PIN
    ? deliveryPeriods.daysForPinRestore
    : deliveryPeriods.daysForCardDelivery

export const formatFuelCardNames = (
  types: Options,
  selectedTypes: readonly string[],
): string =>
  selectedTypes
    .map((type: string) => {
      const typeOption = types.find(t => t.value === type)

      return typeOption && typeOption.title
    })
    .join(', ')

export const mapFuelCardsToOptions = (fuelCards: FuelCards): Options =>
  fuelCards.map(fuelCard => ({
    value: fuelCard.cardNumber,
    title: fuelCard.name,
    subtitle: fuelCard.cardNumber.replace(/(\d){4}/gi, '$& '),
    dataE2eId: `${fuelCard.name}`,
  }))

export const selectCardName = (id: string, options: Options): string => {
  const option = options.find(item => item.value === id)

  return option && option.title
}

export const getSupplierByName = (
  name: string,
  suppliers: StaticSuppliers,
): StaticSupplier | undefined =>
  suppliers.find(supplier =>
    name.toUpperCase().includes(supplier.key.toUpperCase()),
  )

export const getMappedSupplier = (
  info: string,
  supplier: StaticSupplier,
  weekDaysTranslations: string,
): SupplierCard => {
  const workingHours: string = supplier.isWorkingOnlyWeekDays
    ? weekDaysTranslations.replace('{workingHours}', supplier.workingHours)
    : supplier.workingHours

  return {
    info: info
      .replace('{phoneNumber}', supplier.phone)
      .replace('{workingHours}', workingHours),
    name: supplier.name,
    logo: supplier.logo,
  }
}

export const isSupplierAvailable = (
  supplier: StaticSupplier,
  options: Options,
): boolean =>
  Boolean(
    options.find(option =>
      Boolean(getSupplierByName(option.title, [supplier])),
    ),
  )

export const getServiceTypes = (serviceTypes: readonly string[]): Options =>
  serviceTypes.map(type => ({
    value: type,
    title: `myLeaseplan.fuelCard.types.${type}`,
  }))

export const arrayToFlagObject = (array: readonly string[]) =>
  array?.reduce(
    (object, item) => ({
      ...object,
      [item]: true,
    }),
    {},
  )
