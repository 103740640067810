import _merge from 'lodash/merge'

import {
  Country,
  CountryDefaults,
  CountrySpecific,
  Feature,
  MileageUnit,
} from 'mlp-client/src/config/types'

export const defaults: CountryDefaults = {
  visible: true,
  minimalFooter: false,
  features: {
    myLeasePlan: {
      enabled: false,
      loginEnabled: false,
      accountLinkEnabled: false,
      // Please don't add any default config for the sub-features.
      // Sub-features should be declared explicitly in the country config file.
    },
  },
  formatting: {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    licensePlate: {
      skipCharacters: 0,
    },
    currency: {
      symbolPrefix: true,
      symbolSuffix: false,
      priceSuffix: true,
    },
    addressDirection: 'ltr',
    secondAddressLineDirection: 'ltr',
    mileageUnit: MileageUnit.kilometer,
  },
  enableLanguageSelector: false,
}

const createConfig = (
  specificConfig: (intercom: readonly Feature[]) => CountrySpecific,
  defaultConfig: CountryDefaults = defaults,
) => (intercom: readonly Feature[]): Country =>
  _merge({}, defaultConfig, specificConfig(intercom))

export default createConfig
