import React from 'react'

import { DistanceUnit } from 'mlp-client/src/enums'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'

export const defaultContext = {
  enabled: true,
  loginEnabled: true,
  accountLinkEnabled: true,
  distanceUnit: DistanceUnit.KM,
  showSalutation: false,
}

export const MyLeaseplanContext = React.createContext<MyleaseplanConfig>(
  defaultContext,
)
