import { getCountry } from 'mlp-client/src/localization/selectors'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureName,
} from 'mlp-client/src/features-configuration/types'
import { getShowAllFeatures } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'

import { config as countries } from './index'

export const getMyLeaseplanConfig = (country: string): MyleaseplanConfig => {
  const config = countries[country.toLowerCase()]

  if (!config) {
    throw new Error(`No configuration found for this country: ${country}`)
  }

  return config
}

export const getMyLeaseplanFeatureConfig = (
  country: string,
  feature: MyLeaseplanFeatureName,
): MyleaseplanConfig[MyLeaseplanFeatureName] => {
  const countryConfig = getMyLeaseplanConfig(country)

  return countryConfig[feature]
}

export const isMyLeaseplanFeatureEnabled = (
  state: AppState,
  feature: MyLeaseplanFeatureName,
): boolean => {
  const featureConfig = getMyLeaseplanFeatureConfig(getCountry(state), feature)

  return featureConfig?.enabled || getShowAllFeatures(state)
}
