import React from 'react'
import { useSelector } from 'react-redux'

import { AppState } from 'mlp-client/src/types'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import NoNavigationWrapper from 'mlp-client/src/components/NoNavigationWrapper'

import { SomethingWentWrong } from './SomethingWentWrong'

export const SomethingWentWrongPage: React.FC = () => {
  const title = useSelector((state: AppState) =>
    getTranslation(state, 'myLeaseplan.somethingWentWrong.title'),
  )

  const link = {
    to: 'myLeaseplan.external.mainHomePage',
    description: 'Leaseplan.com',
  }

  return (
    <NoNavigationWrapper>
      <SomethingWentWrong title={title} link={link} />
    </NoNavigationWrapper>
  )
}

SomethingWentWrongPage.displayName = 'SomethingWentWrongPage'

export default SomethingWentWrongPage
