import React, { PureComponent } from 'react'
import { Grid, GridItem, Paper, Spacing } from '@leaseplan/ui'

export interface Props {
  columnsAmount: number
}

export class EmptyStateMobile extends PureComponent<Props> {
  render() {
    const { children } = this.props

    return (
      <Paper>
        <Grid justifyContent="center">
          <GridItem>
            <Spacing pv={4}>{children || null}</Spacing>
          </GridItem>
        </Grid>
      </Paper>
    )
  }
}

export default EmptyStateMobile
