import moment from 'moment'
import React, { PureComponent } from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { GeneralListTile } from 'mlp-client/src/components/confirm-step-tiles'
import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import VehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import Localization from 'mlp-client/src/localization/Localization'
import TimelineDetailsHeader from 'mlp-client/src/timeline-details/components/TimelineDetailsHeader/TimelineDetailsHeader'
import { mapGlassDamageToLabels } from 'mlp-client/src/timeline-details/utils'
import CancellationButton from 'mlp-client/src/timeline-details/components/views/cancellation-button/CancellationButton'
import { SpecialInstructions } from 'mlp-client/src/timeline-details/components/views/special-instructions/SpecialInstructions'
import { SupplierDetails } from 'mlp-client/src/timeline-details/components/views/supplier-details/SupplierDetails'
import { ChildComponentProps } from 'mlp-client/src/timeline-details/components/views/timeline-booking-details/TimelineBookingDetails'
import { GlassDamageType } from 'mlp-client/src/types'

class Maintenance extends PureComponent<ChildComponentProps> {
  renderBookingTitle = (
    glassDamageType: GlassDamageType,
  ): React.ReactElement<any> => {
    const titleId = glassDamageType
      ? 'myLeaseplan.glassRepair.dashboard.title'
      : 'myLeaseplan.timeline.maintenance.items.title'

    const dataE2eId = glassDamageType
      ? 'bookingDetailsForm_glassRepairTitle'
      : 'bookingDetailsForm_maintenanceTitle'

    return <Translation id={titleId} data-e2e-id={dataE2eId} />
  }

  renderDropOffDate = (
    dropOffDate: string,
    glassDamageType: GlassDamageType,
  ): React.ReactFragment => (
    <>
      {this.renderBookingTitle(glassDamageType)}
      <Localization
        render={() => (
          <>
            {dropOffDate && ` - ${moment.parseZone(dropOffDate).format('LL')}`}
          </>
        )}
      />
    </>
  )

  renderBookingTypeLabel = (
    regular: boolean,
    glassDamageType: GlassDamageType,
  ): React.ReactElement<any> => {
    const maintenanceBookingType = regular
      ? 'myLeaseplan.maintenance.steps.confirmBooking.isRegularMaintenance'
      : 'myLeaseplan.maintenance.steps.confirmBooking.isNotRegularMaintenance'
    const glassBookingType =
      glassDamageType && mapGlassDamageToLabels(glassDamageType)

    const bookingTypeId = glassBookingType || maintenanceBookingType

    return (
      <Translation
        id={bookingTypeId}
        data-e2e-id="bookingDetailsForm_malfunctionsTitle"
      />
    )
  }

  renderMalfunctions = (items: readonly string[]): React.ReactNodeArray =>
    items.map((item: string) => (
      <Translation
        key={item}
        id={`myLeaseplan.maintenance.malfunctions.${item}`}
      />
    ))

  renderServices = (items: readonly string[]): React.ReactNodeArray =>
    items.map((item: string) => (
      <Translation
        key={item}
        id={`myLeaseplan.maintenance.steps.selectAdditionalServices.services.${item}.title`}
      />
    ))

  render() {
    const {
      booking: {
        additionalServices,
        caseNumber,
        dropOffDate,
        glassDamageType,
        malfunctions,
        regular,
        specialInstructions,
        status,
        supplierAddress,
        supplierGeolocation,
        caseId,
      },
      locationLabel,
      vehicle,
    } = this.props

    return (
      <>
        <TimelineDetailsHeader
          status={status}
          title={this.renderBookingTitle(glassDamageType)}
          titleWithDate={this.renderDropOffDate(dropOffDate, glassDamageType)}
          caseNumber={
            caseNumber && (
              <Translation
                id={'myLeaseplan.timeline.glassBooking.detail.casenr'}
                replace={{ number: caseNumber }}
              />
            )
          }
        />

        <GeneralListTile
          dataE2eId="bookingTitle"
          title={this.renderBookingTitle(glassDamageType)}
          data-test-id="bookingTitle"
        >
          {this.renderBookingTypeLabel(regular, glassDamageType)}
          {vehicle && <VehicleModelLicense vehicle={vehicle} />}
        </GeneralListTile>

        {malfunctions && malfunctions.length > 0 && (
          <FlowStepTile
            title={
              <Translation id="myLeaseplan.maintenance.steps.confirmBooking.malfunctionsTitle" />
            }
          >
            <FlowStepChevronList
              children={this.renderMalfunctions(malfunctions)}
            />
          </FlowStepTile>
        )}

        {additionalServices && additionalServices.length > 0 && (
          <FlowStepTile
            title={
              <Translation
                id="myLeaseplan.maintenance.steps.confirmBooking.additionalServicesTitle"
                data-e2e-id="bookingDetailsForm_additionalServicesTitle"
              />
            }
          >
            <FlowStepChevronList
              children={this.renderServices(additionalServices)}
            />
          </FlowStepTile>
        )}

        <SupplierDetails
          dropOffDate={dropOffDate}
          locationLabel={locationLabel}
          supplierAddress={supplierAddress}
          supplierGeolocation={supplierGeolocation}
        />
        <SpecialInstructions message={specialInstructions} />
        <CancellationButton
          dropOffDate={dropOffDate}
          caseId={caseId}
          status={status}
        />
      </>
    )
  }
}

export { Maintenance }
export default Maintenance
