import styled from 'styled-components'
import { Button, Color, GridItem, TextInput } from '@leaseplan/ui'

export const StyledTextInput = styled(TextInput)`
  border: none;

  &:active,
  &:focus {
    box-shadow: none;
  }
`

export const StyledButton = styled(Button)`
  border-radius: 2px;
  background-color: ${Color.white};
  padding: 5px;
  border: none;

  &:hover {
    background-color: #eee !important;
  }

  &:active,
  &:focus {
    outline: none;
    background-color: #eee !important;
  }
`

export const StyledGridItem = styled(GridItem)`
  border-radius: 2px;
  display: flex;
  padding: 0;
`
