import {
  CashClaimIconLeasys,
  DamageIconLeasys,
  DocumentsIconLeasys,
  FinesIconLeasys,
  FuelCardIconLeasys,
  FuelHistoryIconLeasys,
  GlassIconLeasys,
  MaintenanceIconLeasys,
  MileageIconLeasys,
  ReturnIcon,
  TyresIconLeasys,
} from '..'
import { IconTypes } from '../types'

export const lpluIcons: IconTypes = {
  maintenance: MaintenanceIconLeasys,
  tyre: TyresIconLeasys,
  cashClaim: CashClaimIconLeasys,
  damage: DamageIconLeasys,
  documents: DocumentsIconLeasys,
  fines: FinesIconLeasys,
  fuelCard: FuelCardIconLeasys,
  fuelHistory: FuelHistoryIconLeasys,
  glass: GlassIconLeasys,
  mileage: MileageIconLeasys,
  return: ReturnIcon,
}
