import { ColorName } from '@leaseplan/ui'
import React from 'react'

import {
  StyledBreakpoint,
  StyledProgress,
  StyledProgressBar,
} from 'mlp-client/src/components/progress-bar/ProgressBar.styled'
import { calculatePercentage } from 'mlp-client/src/components/progress-bar/utils'

export interface Props {
  currentPercent: number
  expectedPercent?: number
  showBreakpoint?: boolean
  strokeColor?: ColorName
  excessStrokeColor?: ColorName
}

export type DefaultProps = Required<
  Pick<Props, 'strokeColor' | 'expectedPercent'>
>

class ProgressBar extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    strokeColor: 'aquaBlue',
    expectedPercent: 0,
  }

  render() {
    const {
      currentPercent,
      strokeColor,
      expectedPercent,
      showBreakpoint,
      excessStrokeColor,
    } = this.props

    return (
      <StyledProgressBar>
        {showBreakpoint && expectedPercent && (
          <StyledBreakpoint position={expectedPercent} />
        )}
        <StyledProgress
          width={currentPercent}
          expectedPercent={calculatePercentage(expectedPercent, currentPercent)}
          strokeColor={strokeColor}
          excessStrokeColor={excessStrokeColor || strokeColor}
        />
      </StyledProgressBar>
    )
  }
}

export { ProgressBar }
export default ProgressBar
