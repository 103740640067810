import React from 'react'
import { IconProps } from '@leaseplan/ui'

// this is not regular icon but have to use IconProps to pass type checking
export class MacifIcon extends React.PureComponent<IconProps> {
  render() {
    return (
      <img
        src="/static/images/my-leaseplan/driver-company-brand/macif-c-parti.png"
        alt="Macif"
      />
    )
  }
}

export default MacifIcon
