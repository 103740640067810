import { Card } from '@leaseplan/ui'
import styled from 'styled-components'

import DynamicLink from 'mlp-client/src/components/dynamic-link/DynamicLink'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

export const StyledDynamicLink = styled(DynamicLink)`
  text-decoration: none;
  display: block;
`

export const StyledCard = styled(Card)`
  &:hover ${/* sc-selector */ SecondaryDarkText}:first-child, &:hover svg {
    color: ${isLeaseplan()
      ? ColorEnumSelector('midOrange')
      : ColorEnumSelector('bloodOrange')};
  }
`
