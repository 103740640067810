import Classnames from 'classnames'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import {
  TopNavigationContext,
  TopNavigationContextType,
} from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'

interface Props {}

interface State {
  open: boolean
}

class Hamburger extends React.PureComponent<Props, State> {
  state = { open: false }

  handleButtonClick = (
    onToggleMenu: TopNavigationContextType['onToggleMenu'],
  ) => () => {
    this.setState(
      prevState => ({ open: !prevState.open }),
      () => onToggleMenu(this.state.open),
    )
  }

  render() {
    return (
      <TopNavigationContext.Consumer>
        {({ onToggleMenu }) => (
          <button
            className={Classnames('hamburger u-hide-from@lap', {
              'hamburger--active': this.state.open,
            })}
            type="button"
            onClick={this.handleButtonClick(onToggleMenu)}
            data-e2e-id="navMenuButton-hamburger"
          >
            <span className="hamburger__label">
              <Translation id="myLeaseplan.shared.menu" />
            </span>
            <span className="hamburger__box">
              <span className="hamburger__inner" />
            </span>
          </button>
        )}
      </TopNavigationContext.Consumer>
    )
  }
}

export default Hamburger
