import {
  Breakpoint,
  BreakpointName,
  List,
  Paper,
  Spacing,
  withBreakpoint,
} from '@leaseplan/ui'
import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import { Vehicle } from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import { ChevronDownIcon } from 'mlp-client/src/components/icons'
import Translation from 'mlp-client/src/localization/Translation'
import { VehicleSelection } from 'mlp-client/src/analytics/actions'

import {
  ChevronIconWrapper,
  Container,
  Divider,
  DropdownWrapper,
  ListItemStyled,
  ListItemWrapper,
  SelectedCarWrapper,
  SelectedItemWrapper,
  SelectedVehicleModelStyled,
  VehicleModelStyled,
  WrapperInner,
  WrapperOuter,
  LicensePlateOrStatusSmall,
  LicensePlateOrStatusStyled,
} from './VehicleSelect.styled'
import CarIcon from './CarIcon'

interface Props {
  selectedVehicleId: string
  vehicles: readonly Vehicle[]
  compactView?: boolean
  onSelect(vehicleId: string): void
  breakpoint: BreakpointName
  trackVehicleSelection?(eventLabel: string): void
}

interface State {
  open: boolean
}

class VehicleSelect extends React.PureComponent<Props, State> {
  private container = React.createRef<HTMLDivElement>()

  state = {
    open: false,
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  toggle = () => {
    if (this.props.vehicles.length > 1) {
      if (!this.state.open) {
        document.addEventListener('click', this.handleOutsideClick)
      } else {
        document.removeEventListener('click', this.handleOutsideClick)
      }

      this.setState((oldState: State) => ({
        open: !oldState.open,
      }))
    }
  }

  onItemClick = (vehicle?: Vehicle) => () => {
    this.props.onSelect(vehicle.id)
    this.toggle()
    this.props.trackVehicleSelection(
      `${vehicle.model} - ${vehicle.licensePlate}`,
    )
  }

  handleOutsideClick = (e: MouseEvent) => {
    if (this.state.open && this.container) {
      const node = this.container.current

      if (node && !node.contains(e.target as Element)) {
        this.toggle()
      }
    }
  }

  getVehicleById = (
    vehicles: readonly Vehicle[],
    id: string,
  ): Vehicle | undefined => vehicles.find(vehicle => vehicle.id === id)

  render() {
    const { selectedVehicleId, vehicles, breakpoint, compactView } = this.props
    const { open } = this.state
    const isMobile = Breakpoint[Breakpoint.mobile] === breakpoint
    const selectedVehicle = this.getVehicleById(vehicles, selectedVehicleId)

    const collapseComponent = (
      <WrapperInner>
        <SelectedItemWrapper
          data-e2e-id="selected-vehicle"
          onClick={this.toggle}
          compact={compactView}
        >
          {!compactView && (
            <Spacing mr={isMobile ? 0.5 : 1}>
              <CarIcon
                image={selectedVehicle?.imageUrl}
                size="m"
                isDropdownOpened={open}
              />
            </Spacing>
          )}
          <SelectedCarWrapper flexWrap={!compactView && isMobile}>
            <SelectedVehicleModelStyled variant={compactView ? '300' : '400'}>
              {selectedVehicle.isFuelManagement ? (
                <Translation id="myLeaseplan.multipleCars.actions.fuelMandate" />
              ) : (
                selectedVehicle.model
              )}
            </SelectedVehicleModelStyled>
            {!selectedVehicle.isFuelManagement && (
              <LicensePlateOrStatusStyled
                licensePlate={selectedVehicle.licensePlate}
                status={selectedVehicle.status}
              />
            )}
          </SelectedCarWrapper>
          <ChevronIconWrapper selectable={vehicles.length > 1}>
            <ChevronDownIcon
              className={Classnames('mobile-menu-link-list__chevron', {
                'mobile-menu-link-list__chevron--open': open,
              })}
            />
          </ChevronIconWrapper>
        </SelectedItemWrapper>
        {open && (
          <DropdownWrapper data-e2e-id="vehicles-list">
            <List listStyleType="none">
              {vehicles
                .filter(vehicle => vehicle.id !== selectedVehicle.id)
                .map(vehicle => (
                  <ListItemStyled key={vehicle.id}>
                    <Divider visible={open} />
                    <ListItemWrapper
                      className="list-item"
                      onClick={this.onItemClick(vehicle)}
                      data-e2e-id={vehicle.licensePlate}
                    >
                      {!compactView && (
                        <Spacing mr={0.5}>
                          <CarIcon image={vehicle?.imageUrl} size="s" />
                        </Spacing>
                      )}
                      <VehicleModelStyled variant="100">
                        {vehicle.isFuelManagement ? (
                          <Translation id="myLeaseplan.multipleCars.actions.fuelMandate" />
                        ) : (
                          vehicle.model
                        )}
                      </VehicleModelStyled>
                      {!vehicle.isFuelManagement && (
                        <LicensePlateOrStatusSmall
                          licensePlate={vehicle.licensePlate}
                          status={vehicle.status}
                        />
                      )}
                    </ListItemWrapper>
                  </ListItemStyled>
                ))}
            </List>
          </DropdownWrapper>
        )}
      </WrapperInner>
    )

    return (
      <Container ref={this.container} data-e2e-id="vehicle-select">
        <WrapperOuter selectable={vehicles.length > 1}>
          {open ? (
            <Paper elevation={12}>{collapseComponent}</Paper>
          ) : (
            collapseComponent
          )}
        </WrapperOuter>
      </Container>
    )
  }
}

type DispatchProps = Pick<Props, 'trackVehicleSelection'>

export const mapDispatchToProps: DispatchProps = {
  trackVehicleSelection: (eventLabel: string) =>
    new VehicleSelection({ eventLabel }),
}

export { VehicleSelect }
export default withBreakpoint(connect(null, mapDispatchToProps)(VehicleSelect))
