import React from 'react'
import { GoogleMap, OverlayView } from '@react-google-maps/api'

import { markerOffset } from './utils'
import { mapStyle } from './mapStyle'
import { Coordinates } from './types'

interface DefaultProps {
  coordinates: Coordinates
}

interface NonDefaultProps {
  customMarker?: React.ReactNode
  customMarkerOffset?(width: number, height: number): { x: number; y: number }
}

type Props = Partial<DefaultProps> & NonDefaultProps

const Map: React.FC<Props> = props => {
  const onLoad = (map?: google.maps.Map): void => {
    if (map) {
      // recenter map to show tooltip completely
      map.setCenter({
        lat: props.coordinates.lat + 0.004,
        lng: props.coordinates.lng,
      })
    }
  }

  const {
    coordinates = { lat: 52.3600009, lng: 4.8830301 },
    customMarker,
    customMarkerOffset = markerOffset,
  } = props

  return (
    <GoogleMap
      mapContainerStyle={{ height: '19.3rem' }}
      zoom={12}
      center={coordinates}
      onLoad={props.coordinates ? onLoad : undefined}
      options={{
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: mapStyle,
      }}
    >
      {customMarker && (
        <OverlayView
          position={coordinates}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={customMarkerOffset}
        >
          {customMarker}
        </OverlayView>
      )}
    </GoogleMap>
  )
}

export default React.memo(Map)
