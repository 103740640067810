import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import { LoadNotifications } from 'mlp-client/src/contracts/actions'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import { NotificationStatus } from 'mlp-client/src/contracts/enums'

import * as actions from './actions'

export function* deletePredictiveMaintenanceCase({
  payload: {
    caseId,
    mileage,
    cancellationReason,
    onComplete,
    loadNotifications = true,
  },
}: actions.DeletePredictiveMaintenanceCaseRequest): SagaIterator {
  const body = {
    caseId,
    mileage,
    cancellationReason,
  }

  try {
    const contract = yield select(getCurrentContract)

    yield call(
      ApiSagas.delete,
      '/myleaseplan/lpis/deleteBooking',
      {
        body,
      },
      { throwError: true },
    )

    sessionStorage.setItem(
      `notification_status_${contract.id}`,
      NotificationStatus.DELETED,
    )

    yield put(new actions.DeletePredictiveMaintenanceCaseSuccess())

    if (loadNotifications) {
      yield put(new LoadNotifications())
    }

    yield call(onComplete, false)
  } catch (err) {
    yield call(onComplete, true)
  }
}

export default [
  takeLatest(
    actions.ActionTypes.DELETE_PREDICTIVE_MAINTENANCE_CASE,
    deletePredictiveMaintenanceCase,
  ),
]
