export enum FeatureStatus {
  ENABLED = 'enabled',
  DEV_MOD = 'dev_mod',
  DISABLED = 'disabled',
}

export enum NavigationStructure {
  ONLY_DASHBOARD_NAVIGATION_STRUCTURE = 'ONLY_DASHBOARD_NAVIGATION_STRUCTURE',
  NO_CONTRACT_NAVIGATION_STRUCTURE = 'NO_CONTRACT_NAVIGATION_STRUCTURE',
  NAVIGATION_STRUCTURE = 'NAVIGATION_STRUCTURE',
  MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE = 'MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE',
  READONLY_NAVIGATION_STRUCTURE = 'READONLY_NAVIGATION_STRUCTURE',
  FUEL_MANAGEMENT_NAVIGATION_STRUCTURE = 'FUEL_MANAGEMENT_NAVIGATION_STRUCTURE',
  MULTIPLE_ORDERS_NAVIGATION_STRUCTURE = 'MULTIPLE_ORDERS_NAVIGATION_STRUCTURE',
  NAVIGATION_STRUCTURE_QUOTE = 'NAVIGATION_STRUCTURE_QUOTE',
}
