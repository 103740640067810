import { Spacing } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'

import { TimelineCardConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import TimelineCard from 'mlp-client/src/timeline/components/TimelineCard/TimelineCard'
import { TimelineCardContent } from 'mlp-client/src/timeline/types'
import FuelCardIcon from 'mlp-client/src/components/icons/fuel-card-light-icon/FuelCardLightIcon'

interface Props {
  content: TimelineCardContent
  config: TimelineCardConfig
}

const IconContainer = styled(Spacing)`
  text-align: center;
`

export class FuelCard extends React.PureComponent<Props> {
  renderIcon = () => (
    <IconContainer pt={1}>
      <FuelCardIcon />
    </IconContainer>
  )

  render() {
    const { content, config } = this.props

    return (
      <TimelineCard
        content={content}
        config={config}
        titleReplace={{ cardName: content.eventDetails.fuelCardID }}
        descriptionReplace={{ cardName: content.eventDetails.fuelCardID }}
        icon={this.renderIcon()}
      />
    )
  }
}
