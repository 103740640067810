import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { ContractPropertiesTable } from 'mlp-client/src/contracts/components/ContractPropertiesTable/ContractPropertiesTable'
import { SectionProperties } from 'mlp-client/src/contracts/components/SectionProperties/SectionProperties'
import {
  ViewContractTabs,
  ViewContractSections,
} from 'mlp-client/src/contracts/enums'
import ContractOptionsList from 'mlp-client/src/contracts/components/ContractOptionsList/ContractOptionsList'
import ContractStatusBlock from 'mlp-client/src/contracts/components/ContractStatusBlock/ContractStatusBlock'
import PolicyDocuments from 'mlp-client/src/contracts/components/PolicyDocuments/PolicyDocuments'
import { Contract } from 'mlp-client/src/contracts/types'
import {
  getSectionData,
  getTabTitle,
  isSectionAllowedByService,
} from 'mlp-client/src/contracts/utils'
import {
  ContractsFeatureConfig,
  SectionPropertyList,
  TabsList,
} from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import Tabs, { TabItem } from 'mlp-client/src/components/tabs/Tabs'

export interface Props {
  contract: Contract
  isMobileApp: boolean
  config: ContractsFeatureConfig
}

class ViewContract extends React.PureComponent<Props> {
  shouldRenderPolicyDocuments = (tabKey: string) => {
    const { config, isMobileApp } = this.props

    return (
      tabKey === ViewContractTabs.Contract &&
      config.policyDocuments &&
      !isMobileApp
    )
  }

  shouldRenderContractStatusBlock = (tabKey: string) => {
    const { config } = this.props

    return tabKey === ViewContractTabs.Contract && config.contractStatusBlock
  }

  renderContractOptions = (tabKey: string) => {
    const { config } = this.props
    const { contractOptionsSection, services } = config
    const isConfigEmpty =
      !contractOptionsSection || isEmpty(contractOptionsSection)

    if (tabKey === ViewContractTabs.Contract && !isConfigEmpty) {
      return (
        <ContractOptionsList
          options={contractOptionsSection}
          servicesConfig={services}
        />
      )
    }

    return null
  }

  renderSide = (tabKey: string) => {
    const renderDocuments = this.shouldRenderPolicyDocuments(tabKey)
    const renderContractStatus = this.shouldRenderContractStatusBlock(tabKey)

    return renderDocuments || renderContractStatus ? (
      <GridItem span={{ desktop: 3.75, mobile: 12, tablet: 12, lap: 3.75 }}>
        {renderDocuments && <PolicyDocuments />}
        {renderContractStatus && <ContractStatusBlock />}
      </GridItem>
    ) : null
  }

  renderSections = (tabConfig: TabsList[ViewContractTabs]) =>
    Object.entries(tabConfig).map(
      ([sectionKey, sectionConfig]: [
        ViewContractSections,
        SectionPropertyList,
      ]) => {
        const {
          config: { currency, services },
          contract,
        } = this.props

        let { properties, title } = getSectionData(
          contract,
          sectionConfig,
          sectionKey,
          currency,
          services,
        )

        if (services && contract.services) {
          const showServiceSection = isSectionAllowedByService(
            contract.services,
            services,
            sectionKey,
          )

          if (!showServiceSection) {
            properties = []
          }
        }

        if (!properties || !properties.length) {
          return null
        }

        return (
          <SectionProperties
            key={sectionKey}
            dataE2eId={sectionKey}
            titleTranslationId={title}
          >
            <ContractPropertiesTable properties={properties} />
          </SectionProperties>
        )
      },
    )

  renderTab = (tabKey: string, tabConfig: TabsList[ViewContractTabs]) => (
    <Grid gutter={false} justifyContent="space-between">
      <GridItem
        key={tabKey}
        span={
          this.shouldRenderPolicyDocuments(tabKey) ||
          this.shouldRenderContractStatusBlock(tabKey)
            ? {
                mobile: 12,
                tablet: 12,
                desktop: 8,
                lap: 8,
              }
            : 12
        }
      >
        {this.renderSections(tabConfig)}
        {this.renderContractOptions(tabKey)}
      </GridItem>
      {this.renderSide(tabKey)}
    </Grid>
  )

  render() {
    const { config } = this.props

    if (!config || !config.tabs) {
      return null
    }

    const { tabs } = config

    // If its only one tab, then render a page layout
    // instead of tabs layout.
    if (Object.keys(tabs).length < 2) {
      return this.renderTab(ViewContractTabs.Contract, tabs.contractDetails)
    }

    return (
      <Tabs scroll={false} collapseOnMobile={false}>
        {Object.entries(tabs).map(([tabKey, tabConfig]) => (
          <TabItem
            key={tabKey}
            data-tab-label={getTabTitle(tabKey)}
            data-e2e-id={tabKey}
          >
            <Spacing mt={3}>{this.renderTab(tabKey, tabConfig)}</Spacing>
          </TabItem>
        ))}
      </Tabs>
    )
  }
}

export { ViewContract }
export default ViewContract
