import log from 'mlp-client/src/log'
import { NavigationItem } from 'mlp-client/src/types'

import {
  ContentSection,
  FooterColumn,
  FooterLink,
  FooterResponse,
} from './types'

/**
 * Picks a content section from the supplied list of sections by its id or name
 */
export const getContentSection = <T extends ContentSection = ContentSection>(
  contentSections: readonly ContentSection[] | undefined,
  id: string,
): T | undefined => {
  // return early to avoid unnecessary warnings in the console when the content is not
  // in the store yet
  if (!contentSections) {
    return undefined
  }

  const section =
    contentSections.find(
      s => s._Id === id || s._Name.toLowerCase() === id.toLowerCase(),
    ) ?? {}

  if (!section) {
    log.warn(
      `getContentSection: unable to find "${id}" section in the supplied section list`,
    )
  }

  return section as T
}

const linkToNavigationItem = (link: FooterLink): NavigationItem => ({
  title: link.Label,
  navigationTitle: link.Label,
  to: link.Url,
})
const combineLinks = (acc: readonly FooterLink[], next: FooterColumn) => [
  ...acc,
  ...next.Links,
]

export const mapFooterToNavigationItems = (
  footerResponse: FooterResponse | undefined,
): readonly NavigationItem[] =>
  footerResponse?.FooterMenu?.reduce(combineLinks, []).map(
    linkToNavigationItem,
  ) ?? []
