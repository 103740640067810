import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { createStructuredSelector } from 'reselect'

import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'
import { isAuthenticated } from 'mlp-client/src/auth/auth'
import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
import { AppState } from 'mlp-client/src/types'

import { SetNextRoute } from './actions'
import { getIsMobileApp } from './selectors'

interface State {
  isMounted: boolean
  shouldRedirect: boolean
}

export interface Props extends RouteComponentProps<{}> {
  isAuthenticated: boolean
  isMobileApp: boolean
  redirectRoute: string
  saveCurrentRoute(route: string): void
}

export class AuthRouteProtector extends React.PureComponent<Props, State> {
  state: State = { isMounted: false, shouldRedirect: true }

  static getDerivedStateFromProps = (props: Props) =>
    props.isAuthenticated ? { shouldRedirect: false } : null

  private onRedirect(): void {
    const {
      isMobileApp,
      saveCurrentRoute,
      location: { pathname, search },
    } = this.props

    if (isMobileApp) {
      // timeout is needed because the app has to patch the
      // postMessage in their webview
      setTimeout(() => postMessage({ type: PostMessageType.Unauthorized }), 100)

      return
    }

    if (pathname) {
      saveCurrentRoute(`${pathname}${search}`)
    }
  }

  componentDidMount() {
    this.setState({ isMounted: true })
  }

  componentDidUpdate() {
    if (this.state.shouldRedirect) {
      this.onRedirect()
    }
  }

  render() {
    const { isMounted, shouldRedirect } = this.state
    const { redirectRoute, children } = this.props

    if (!isMounted) {
      return null
    }

    if (shouldRedirect) {
      return <Redirect to={redirectRoute} />
    }

    return (
      <>
        <Helmet meta={[{ name: 'robots', content: 'noindex,nofollow' }]} />
        {children}
      </>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'isAuthenticated' | 'isMobileApp' | 'redirectRoute'
>
type DispatchProps = Pick<Props, 'saveCurrentRoute'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isAuthenticated,
  isMobileApp: getIsMobileApp,
  redirectRoute: (state: AppState) =>
    getLocalizedRoute(state, 'myLeaseplan.login'),
})

const mapDispatchToProps: DispatchProps = {
  saveCurrentRoute: (pathname: string) =>
    new SetNextRoute({
      route: pathname,
    }),
}

const AuthWrapper = connect<
  ReduxProps,
  DispatchProps,
  // see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/38678#issuecomment-541596488
  { children?: React.ReactNode }
>(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AuthRouteProtector))

export default <T extends {}>(ProtectedComponent: React.ComponentType<T>) => {
  const protectAuthRoute: React.SFC<T> = props => (
    <AuthWrapper>
      <ProtectedComponent {...props} />
    </AuthWrapper>
  )

  protectAuthRoute.displayName = `protectAuthRoute(${
    ProtectedComponent.displayName ||
    ProtectedComponent.name ||
    'ProtectedComponent'
  })`

  return protectAuthRoute
}
