import { Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

interface Props {
  label: string
  to: string
  linkLabel: string
}

export const ItemWithLink: React.StatelessComponent<Props> = props => {
  const { label, to, linkLabel } = props

  return (
    <>
      <Text component="span" loose>
        <Translation id={label} />
      </Text>{' '}
      <StyledButtonLink
        to={to}
        component={RouteLink}
        size="m"
        fontWeight="regular"
      >
        <Translation id={linkLabel} />
      </StyledButtonLink>
    </>
  )
}

export default ItemWithLink
