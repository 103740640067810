import { ButtonChevronRightIcon, Spacing } from '@leaseplan/ui'
import { Text, VelocityConsumer } from '@velocity/ui'
import React from 'react'

import {
  StyledIconText,
  StyledLink,
  StyledPaper,
} from 'mlp-client/src/dashboard/components/tiles/DashboardTile.styled'
import { TileProps } from 'mlp-client/src/dashboard/type'
import { GrayMainText } from 'mlp-client/src/components/styled/TextStyle'

class MobileTile extends React.PureComponent<TileProps> {
  render() {
    const {
      title,
      hasModal,
      to,
      toParams,
      disabled,
      summary,
      disabledTitle,
      disabledSummary,
      modalTrigger: ModalTriggerComponent,
    } = this.props
    const iconColor = disabled ? 'steel20' : 'petrolBlue'
    const content = (
      <Spacing p={2}>
        <StyledIconText
          icon={ButtonChevronRightIcon}
          iconSize="s"
          iconHoverAnimation="forward"
          size="s"
          iconPosition="right"
          fontWeight="bold"
          color={iconColor}
          {...(disabled && { element: 'span' })}
        >
          <VelocityConsumer>
            {({ theme }) => (
              <Text
                bold
                style={{
                  color: disabled
                    ? theme.styling.gray.dark
                    : theme.styling.secondary.dark.default,
                }}
              >
                {disabled ? disabledTitle : title}
              </Text>
            )}
          </VelocityConsumer>
          <GrayMainText loose>
            {disabled ? disabledSummary : summary}
          </GrayMainText>
        </StyledIconText>
      </Spacing>
    )

    switch (true) {
      case disabled:
        return (
          <StyledPaper backgroundColor="white" stretch={true}>
            {content}
          </StyledPaper>
        )
      case hasModal:
        return (
          <StyledPaper backgroundColor="white" stretch={true}>
            <ModalTriggerComponent {...this.props}>
              {content}
            </ModalTriggerComponent>
          </StyledPaper>
        )
      default:
        return (
          <StyledPaper backgroundColor="white" stretch={true}>
            <StyledLink to={to} params={toParams} children={content} />
          </StyledPaper>
        )
    }
  }
}

export { MobileTile }
export default MobileTile
