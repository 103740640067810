import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import Page from 'mlp-client/src/flows/fuel-history/components/pages/page/Page'
import { FeatureConfiguration } from 'mlp-client/src/flows/fuel-history/types'

import { isPreviewFeatureEnabled } from './utils'

export const fuelHistoryFeature: MyLeaseplanFeature<FeatureConfiguration> = {
  enabled: isPreviewFeatureEnabled('fuelHistory'),
  view: Page,
  configuration: {
    batchSize: 6,
  },
}
