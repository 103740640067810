import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Text, colorMixins } from '@velocity/ui'
import styled from 'styled-components'

import Flag from 'mlp-client/src/localization/Flag'

import {
  SORTED_COUNTRIES,
  KEYS,
  HEADER_ITEM_HEIGHT,
  ITEM_HEIGHT,
  OVERFLOW_BORDER,
  DISPLAYED_ITEMS,
} from './constants'
import CountryItem from './CountryItem'
import { CountryCodeInfo, CountryNames } from './types'

interface Props {
  onChange: (value: string) => void
  countryCodeInfo: CountryCodeInfo
  setCountryCodeInfo: (value: CountryCodeInfo) => void
  setShowCountryList: (show: boolean) => void
  dataE2eId: string
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
`

const CountryList: React.FC<Props> = ({
  onChange,
  countryCodeInfo,
  setCountryCodeInfo,
  setShowCountryList,
  dataE2eId,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const [typedCountryName, setTypedCountryName] = useState('')
  const countryList = useMemo(() => {
    const filteredCountryList = SORTED_COUNTRIES.filter(
      ({ flagName, name }) =>
        flagName !== countryCodeInfo.flagName &&
        name.toLowerCase().includes(typedCountryName),
    )

    return filteredCountryList.length
      ? filteredCountryList
      : SORTED_COUNTRIES.filter(
          ({ flagName }) => flagName !== countryCodeInfo.flagName,
        )
  }, [countryCodeInfo.flagName, typedCountryName])

  const containerList = useRef<HTMLUListElement>()

  const changeValue = useCallback(
    (value: CountryCodeInfo) => {
      onChange(`00${value.countryPhoneCode}`)
      setCountryCodeInfo(value)
    },
    [onChange, setCountryCodeInfo],
  )

  const scrollContainerUp = (top: number) =>
    containerList.current.scrollTo({ top })

  const setScrollTop = useCallback((key: string, nextItemIndex: number) => {
    const scrolledHeight = HEADER_ITEM_HEIGHT + ITEM_HEIGHT * nextItemIndex
    const scrollDifference = scrolledHeight - containerList.current.scrollTop

    switch (key) {
      case KEYS.ARROW_UP:
        if (scrollDifference < 0) {
          scrollContainerUp(containerList.current.scrollTop - ITEM_HEIGHT)
        }

        break
      case KEYS.ARROW_DOWN:
        if (scrollDifference > OVERFLOW_BORDER) {
          scrollContainerUp(scrolledHeight - ITEM_HEIGHT * DISPLAYED_ITEMS)
        }

        break
    }
  }, [])

  const windowKeydownHandler = useCallback(
    (e: KeyboardEvent): void => {
      e.preventDefault()

      switch (e.key) {
        case KEYS.ARROW_UP:
          if (selectedItemIndex === 0) {
            setSelectedItemIndex(countryList.length - 1)
            scrollContainerUp(ITEM_HEIGHT * countryList.length - 1)
          } else {
            setSelectedItemIndex(index => index - 1)
            setScrollTop(KEYS.ARROW_UP, selectedItemIndex - 1)
          }

          break
        case KEYS.ARROW_DOWN:
          if (selectedItemIndex === countryList.length - 1) {
            setSelectedItemIndex(0)
            scrollContainerUp(0)
          } else {
            setSelectedItemIndex(index => index + 1)
            setScrollTop(KEYS.ARROW_DOWN, selectedItemIndex + 1)
          }

          break
        case KEYS.ENTER:
          const { flagName, countryPhoneCode } = countryList[selectedItemIndex]

          setShowCountryList(false)
          changeValue({ flagName, countryPhoneCode })
          break

        case KEYS.BACKSPACE:
          scrollContainerUp(0)
          setSelectedItemIndex(0)
          setTypedCountryName(name => name.slice(0, -1))
          break

        default:
          const onlyLettersRegex = /^[a-zA-Z\s]$/

          if (e.key.search(onlyLettersRegex) !== -1) {
            scrollContainerUp(0)
            setSelectedItemIndex(0)
            setTypedCountryName(name => `${name}${e.key.toLowerCase()}`)

            return
          }
      }
    },
    [
      changeValue,
      countryList,
      selectedItemIndex,
      setScrollTop,
      setShowCountryList,
    ],
  )

  useEffect(() => {
    document.addEventListener('keydown', windowKeydownHandler, false)

    return () => {
      document.removeEventListener('keydown', windowKeydownHandler, false)
    }
  }, [changeValue, countryCodeInfo, windowKeydownHandler])

  return (
    <ul
      ref={containerList}
      className="u-padding-vertical-tiny"
      data-e2e-id={dataE2eId}
    >
      <li className="text--capitalize phone-input-country-item-default">
        <Text className="ellipsis-tail" component="span">
          <Flag
            code={countryCodeInfo.flagName}
            label={countryCodeInfo.flagName}
          />
          <Text className="phone-input-country-list-name" component="span">
            {CountryNames[countryCodeInfo.flagName.toUpperCase()]}
          </Text>
        </Text>
        <SText component="span">+{countryCodeInfo.countryPhoneCode}</SText>
      </li>
      <hr className="phone-input-hr" />

      {countryList.map(({ flagName, name, countryPhoneCode }, index) => (
        <CountryItem
          key={flagName}
          flagName={flagName}
          name={name}
          countryPhoneCode={countryPhoneCode}
          changeValue={changeValue}
          isSelected={selectedItemIndex === index}
          onMouseEnter={() => setSelectedItemIndex(index)}
        />
      ))}
    </ul>
  )
}

export default CountryList
