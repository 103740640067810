export enum MaintenanceType {
  Regular = 'Regular',
  Malfunctions = 'Malfunctions',
}

export enum StepNames {
  CONFIRM_BOOKING = 'confirmBooking',
  SELECT_LOCATION = 'selectLocation',
  SELECT_MALFUNCTIONS = 'selectMalfunctions',
  SELECT_MAINTENANCE_TYPE = 'selectMaintenanceType',
  SELECT_SUPPLIER = 'selectSupplier',
  SPECIAL_INSTRUCTIONS = 'specialInstructions',
  UPDATE_MILEAGE = 'updateMileage',
  SELECT_DATE_AND_LOCATION = 'selectDateAndLocation',
  THANK_YOU = 'thankYou',
  CHECK_CONTACT_DETAILS = 'checkContactDetails',
}

export enum AdditionalServices {
  COLLECT_AND_RETURN = 'CollectAndReturn',
  INSIDE_WASH = 'Inside_wash',
}

export enum AdditionalServicesLabelReplacement {
  PICKUP_WORKPLACE = 'PickupWorkplace',
}
