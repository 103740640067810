import React from 'react'
import { Color } from '@leaseplan/ui'

interface Props {
  color: Color
  width: number
  height: number
  title: string
}

type DefaultProps = Required<
  Pick<Props, 'width' | 'height' | 'color' | 'title'>
>

type InternalProps = Props & DefaultProps

export default class FuelCardIcon extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    title: 'FuelCard',
    color: Color.petrolBlue,
    height: 48,
    width: 48,
  }
  render() {
    const { width, height, title, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 64 64`}
      >
        <title>{title}</title>
        <g fill={color} fillRule="evenodd">
          <path
            fill={color}
            fillRule="evenodd"
            d="M46.322 40.822a3.177 3.177 0 0 0 3.173-3.172c0-1.288-1.617-6.747-3.173-11.603-1.556 4.856-3.173 10.315-3.173 11.603a3.177 3.177 0 0 0 3.173 3.172zm0-21.078a1.5 1.5 0 0 1 1.42 1.02c.794 2.347 4.753 14.195 4.753 16.886a6.18 6.18 0 0 1-6.173 6.172 6.18 6.18 0 0 1-6.173-6.172c0-2.69 3.959-14.539 4.752-16.887a1.5 1.5 0 0 1 1.42-1.019zM59 43.18V20.387c0-.26-.027-.512-.076-.758-.014-.066-.042-.13-.059-.194-.046-.177-.092-.354-.162-.52-.027-.066-.068-.126-.099-.19-.078-.157-.154-.315-.25-.46-.048-.071-.112-.135-.166-.204a3.8 3.8 0 0 0-3.008-1.494H9.653a3.8 3.8 0 0 0-3.008 1.494c-.054.069-.118.133-.166.205-.096.144-.172.302-.25.459-.03.064-.072.124-.099.19-.07.166-.116.343-.162.52-.017.065-.045.128-.059.194a3.874 3.874 0 0 0-.076.758V43.18A3.825 3.825 0 0 0 9.653 47H55.18A3.825 3.825 0 0 0 59 43.18zm2.965-23.48c.023.228.035.457.035.687V43.18c0 3.76-3.06 6.82-6.82 6.82H9.653a6.828 6.828 0 0 1-6.82-6.82V20.387c0-.23.013-.46.035-.686.005-.052.016-.103.023-.156.021-.173.045-.347.08-.517.008-.044.021-.086.031-.13.04-.177.082-.353.136-.527l.027-.078c.061-.187.127-.373.203-.554.005-.011.011-.021.015-.032a7.384 7.384 0 0 1 .28-.578c.103-.188.214-.372.335-.55.004-.007.01-.012.014-.02.117-.171.242-.34.377-.503l.038-.043c.114-.136.23-.271.354-.398.006-.006.015-.008.021-.014a6.796 6.796 0 0 1 4.851-2.034H55.18c1.897 0 3.614.78 4.851 2.034.006.006.015.008.02.014.126.127.241.262.355.398l.038.043c.135.163.26.332.377.504l.014.019a6.723 6.723 0 0 1 .615 1.128l.015.032c.076.18.142.367.203.554.01.026.02.052.027.078.054.174.097.35.136.528.01.043.023.085.032.129.034.17.058.344.08.517.006.053.017.104.022.156zm-48.89 12.915H23.71V30.27h-2.421a1.5 1.5 0 1 1 0-3h2.421v-2.345H13.076v2.345h2.422a1.5 1.5 0 0 1 0 3h-2.422v2.345zm12.134-10.69a1.5 1.5 0 0 1 1.5 1.5v10.69a1.5 1.5 0 0 1-1.5 1.5H11.576a1.5 1.5 0 0 1-1.5-1.5v-10.69a1.5 1.5 0 0 1 1.5-1.5h13.633zm-1.471 17.057a1.5 1.5 0 1 1 0 3h-12.32a1.5 1.5 0 1 1 0-3h12.32z"
          />
        </g>
      </svg>
    )
  }
}
