import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { createStructuredSelector } from 'reselect'
import { RouteComponentProps, withRouter } from 'react-router'
import { MyAccountIcon } from '@velocity/ui/marketing-icons/draft'

import { AppState } from 'mlp-client/src/types'
import { ChevronDownIcon } from 'mlp-client/src/components/icons'
import Flag from 'mlp-client/src/localization/Flag'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'
import {
  isLanguageSelectorEnabled,
  getCurrentCountryLabel,
  getLocaleAvailableLanguages,
  getCountry,
} from 'mlp-client/src/localization/selectors'
import CurrentLocale from 'mlp-client/src/components/layout/top-navigation/desktop/CurrentLocale'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import {
  TopNavigationContext,
  TopNavigationContextType,
} from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'
import { getUser } from 'mlp-client/src/user/selectors'
import { User } from 'mlp-client/src/user/types'
import LoginButton from 'mlp-client/src/components/layout/top-navigation/desktop/LoginButton'
import { buildUrlForLanguage } from 'mlp-client/src/components/layout/top-navigation/utils'
import { Language } from 'mlp-client/src/localization/types'

export interface Props extends RouteComponentProps {
  availableLanguages: readonly Language[]
  enableLanguageSelector: boolean
  currentCountryLabel: string
  user?: User
  hideLoginLink?: boolean
  country: string
}

interface State {
  myLeasePlanOpen: boolean
  myLocaleOpen: boolean
}

class TakeoverWidgets extends React.PureComponent<Props, State> {
  state = {
    myLeasePlanOpen: true,
    myLocaleOpen: false,
  }

  toggleMyLeasePlan = () => {
    this.setState(prevState => ({
      myLeasePlanOpen: !prevState.myLeasePlanOpen,
    }))
  }

  toggleMyLocale = () => {
    this.setState(prevState => ({ myLocaleOpen: !prevState.myLocaleOpen }))
  }

  renderMyLeasePlanAuthenticated = (context: TopNavigationContextType) => {
    const { logout, buttonStructure } = context
    const { myLeasePlanOpen } = this.state
    const { user } = this.props

    // TODO: Cleanup and seperate subcomponents to their respected files
    return [
      <button
        key="myLeasePlan-button"
        onClick={this.toggleMyLeasePlan}
        className="mobile-menu-link-list__button mobile-menu-link-list__button--light"
      >
        <MyAccountIcon size="XXS" className="u-margin-right-tiny" />
        {!user && <Translation id="myLeaseplan.navigation.myLeasePlan" />}
        {user && (
          <span>
            {user.firstName} {user.lastName}
          </span>
        )}
        <ChevronDownIcon
          className={Classnames('mobile-menu-link-list__chevron', {
            'mobile-menu-link-list__chevron--open': myLeasePlanOpen,
          })}
        />
      </button>,
      <TransitionGroup key="myLeasePlan-transitionGroup" component="span">
        {myLeasePlanOpen && (
          <CSSTransition
            classNames="transition-slide"
            enter={true}
            exit={true}
            timeout={{ enter: 300, exit: 300 }}
          >
            <ul className="o-list-bare">
              {buttonStructure.map(item => (
                <li key={item.title}>
                  <RouteLink
                    to={item.to || '404'}
                    className="mobile-menu-link-list__link mobile-menu-link-list__button--light mobile-menu-link-list__button--subitem"
                  >
                    {item.title && <Translation id={item.title} />}
                  </RouteLink>
                </li>
              ))}
              <li>
                <button
                  className="mobile-menu-link-list__button mobile-menu-link-list__button--light mobile-menu-link-list__button--subitem"
                  onClick={logout}
                >
                  <Translation id="myLeaseplan.subNavigation.logout" />
                </button>
              </li>
            </ul>
          </CSSTransition>
        )}
      </TransitionGroup>,
    ]
  }

  renderMyLeasePlanUnauthenticated = () => (
    <LoginButton className="mobile-menu-link-list__link mobile-menu-link-list__button--light" />
  )

  handleLanguageSelect = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const { location, country } = this.props
    const { language } = event.currentTarget.dataset

    window.location.replace(buildUrlForLanguage(location, country, language))
  }

  renderAvailableLanguages = (preferredLanguageCode: string) => ({
    label,
    code: language,
  }: Language) => (
    <li key={label} className="mobile-menu-link-list__link --no-padding">
      <a
        onClick={this.handleLanguageSelect}
        data-language={language}
        className={Classnames(
          'main-menu-widgets__link --larger text--capitalize',
          {
            'dropdown-menu-item__item--active-flag':
              preferredLanguageCode === language,
          },
        )}
      >
        {label}
      </a>
    </li>
  )

  render() {
    const {
      enableLanguageSelector,
      availableLanguages,
      currentCountryLabel,
      user,
      hideLoginLink,
    } = this.props

    const { myLocaleOpen } = this.state

    return (
      <MyLeaseplanContext.Consumer>
        {({ accountLinkEnabled }) => (
          <TopNavigationContext.Consumer>
            {topNavigationContext => {
              const {
                parsedLocale: { country, language },
              } = topNavigationContext

              return (
                <div>
                  <ul className="o-list-bare mobile-menu-link-list">
                    {user && (
                      <li className="mobile-menu-link-list__item u-hide-from@tablet">
                        {this.renderMyLeasePlanAuthenticated(
                          topNavigationContext,
                        )}
                      </li>
                    )}
                    {!user && accountLinkEnabled && !hideLoginLink && (
                      <li className="mobile-menu-link-list__item u-hide-from@tablet">
                        {this.renderMyLeasePlanUnauthenticated()}
                      </li>
                    )}
                  </ul>

                  {enableLanguageSelector ? (
                    <div>
                      <button
                        key="myLeasePlan-button"
                        onClick={this.toggleMyLocale}
                        className="mobile-menu-link-list__button mobile-menu-link-list__button--light"
                      >
                        <CurrentLocale />
                        <ChevronDownIcon
                          className={Classnames(
                            'mobile-menu-link-list__chevron',
                            {
                              'mobile-menu-link-list__chevron--open': myLocaleOpen,
                            },
                          )}
                        />
                      </button>

                      <TransitionGroup
                        key="myLeasePlan-transitionGroup"
                        component="span"
                      >
                        {myLocaleOpen && (
                          <CSSTransition
                            classNames="transition-slide"
                            enter={true}
                            exit={true}
                            timeout={{ enter: 300, exit: 300 }}
                          >
                            <ul className="o-list-bare mobile-menu-link-list__button--subitem line-height-mobile">
                              <li className="main-menu-item-dropdown__item--label">
                                <Translation id="myLeaseplan.languageSelector.location" />
                              </li>
                              <li className="mobile-menu-link-list__link --no-padding --larger text--capitalize --no-link current-locale">
                                <Flag code={country} label={country} />
                                <span>{currentCountryLabel}</span>
                              </li>
                              <li className="main-menu-item-dropdown__item--label">
                                <Translation id="myLeaseplan.languageSelector.language" />
                              </li>
                              {availableLanguages?.map(
                                this.renderAvailableLanguages(language),
                              )}
                            </ul>
                          </CSSTransition>
                        )}
                      </TransitionGroup>
                    </div>
                  ) : (
                    <ul className="o-list-bare mobile-menu-link-list u-padding-bottom">
                      <li className="mobile-menu-link-list__flag">
                        <CurrentLocale />
                      </li>
                    </ul>
                  )}
                </div>
              )
            }}
          </TopNavigationContext.Consumer>
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'currentCountryLabel'
  | 'availableLanguages'
  | 'enableLanguageSelector'
  | 'user'
  | 'country'
>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  availableLanguages: getLocaleAvailableLanguages,
  currentCountryLabel: getCurrentCountryLabel,
  enableLanguageSelector: isLanguageSelectorEnabled,
  user: getUser,
  country: getCountry,
})

export { TakeoverWidgets }
export default connect(mapStateToProps)(withRouter(TakeoverWidgets))
