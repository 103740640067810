import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { createMiddleware, EventsMap } from 'redux-beacon'

import { ActionTypes } from './actions'
import * as eventCreators from './eventCreators'

/* Analytics-specific types */
export interface TrackingEvent extends FlowNavigationPayload {
  event: string
  eventValue?: number
}

export interface FlowNavigationPayload {
  eventCategory: string
  eventAction: string
  eventLabel?: string
  eventLabelEn?: string
  flowName?: string
  currentStepNr?: number
  totalStepNrFlow?: number
}

const eventsMap: EventsMap = {
  // [ActionTypes.BOOKING_CANCELLATION]: eventCreators.bookingCancellation,
  [ActionTypes.FLOW_ACTIVATION]: eventCreators.flowActivation,
  [ActionTypes.FLOW_NAVIGATION]: eventCreators.flowNavigation,
  [ActionTypes.PAGE_VIEW]: eventCreators.trackPageView,
  [ActionTypes.FOOTER_NAVIGATION]: eventCreators.footerNavigation,
  [ActionTypes.MAIN_MENU_TOP_NAVIGATION]: eventCreators.mainMenuTopNavigation,
  [ActionTypes.FLOW_CANCELLATION]: eventCreators.flowCancellation,
  [ActionTypes.DASHBOARD_NAVIGATION]: eventCreators.dashboardNavigation,
  [ActionTypes.VEHICLE_SELECTION]: eventCreators.vehicleSelection,
}

const gtm = GoogleTagManager()

const gtmWrapper = (events: TrackingEvent[]) => {
  if (window.dataLayer) {
    gtm(events)
  }
}

export default createMiddleware(eventsMap, gtmWrapper)
