import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import Supplier from 'mlp-client/src/components/suppliers/Supplier'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { SubmitFuelCard } from 'mlp-client/src/flows/fuel-card/actions'
import {
  getSelectedCardName,
  getSelectedSupplier,
} from 'mlp-client/src/flows/fuel-card/selectors'
import {
  CallUsTile as CallUsTileType,
  FlowData,
  FuelcardServiceType,
  StaticSuppliers,
} from 'mlp-client/src/flows/fuel-card/types'
import { AppState, SupplierCard } from 'mlp-client/src/types'
import { getServiceTypes } from 'mlp-client/src/flows/fuel-card/utils'
import { RadioCardField } from 'mlp-client/src/form/components/fields/RadioFields'
import { required } from 'mlp-client/src/form/validations'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import { FormSubmission } from 'mlp-client/src/form/types'

import CallUsTile from './CallUsTile'

export interface Props extends FlowProps<FlowData>, FormSubmission<FlowData> {
  cardName: string
  supplier?: SupplierCard
  config: {
    serviceTypes: readonly string[]
    suppliers: StaticSuppliers
    callUsTile?: CallUsTileType
  }
}

export class SelectServiceTypeWithSuppliersStep extends React.PureComponent<Props> {
  render() {
    const { cardName, supplier, config, flowData, onSubmit } = this.props

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({
          handleSubmit,
          hasValidationErrors,
          values,
          submitting,
          submitFailed,
          dirtySinceLastSubmit,
        }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep
                titleLabel="myLeaseplan.fuelCard.steps.selectServiceType.subTitleWithCardName"
                titleLabelReplace={{
                  cardName,
                }}
              >
                <RadioCardField
                  name="fuelCardServiceType"
                  validations={[required]}
                  options={getServiceTypes(config.serviceTypes)}
                />
                {values?.fuelCardServiceType ===
                  FuelcardServiceType.LOST_STOLEN && (
                  <>
                    {config?.callUsTile && (
                      <Spacing mt={2}>
                        <CallUsTile callUsTile={config.callUsTile} />
                      </Spacing>
                    )}

                    {supplier && (
                      <Spacing mt={2}>
                        <Supplier supplier={supplier} />
                      </Spacing>
                    )}
                  </>
                )}
                {values?.fuelCardServiceType ===
                  FuelcardServiceType.FORGOT_PIN && (
                  <Spacing mt={2}>
                    <Text
                      data-e2e-id={`${FuelcardServiceType.FORGOT_PIN}_infoMessage`}
                    >
                      <Translation
                        id="myLeaseplan.fuelCard.steps.selectServiceType.forgetPinInfo"
                        replace={{ cardName }}
                      />
                    </Text>
                  </Spacing>
                )}

                {values?.fuelCardServiceType ===
                  FuelcardServiceType.DAMAGED && (
                  <Spacing mt={2}>
                    <Text
                      data-e2e-id={`${FuelcardServiceType.DAMAGED}_infoMessage`}
                    >
                      <Translation
                        id="myLeaseplan.fuelCard.steps.selectServiceType.malfunctionInfo"
                        replace={{ cardName }}
                      />
                    </Text>
                  </Spacing>
                )}
                <Spacing mt={2} />
                <SubmitStatus
                  submitting={submitting}
                  failed={!dirtySinceLastSubmit && submitFailed}
                >
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={submitting || hasValidationErrors}
                  />
                </SubmitStatus>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

export type ReduxProps = Pick<Props, 'supplier' | 'cardName'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (
  state: AppState,
  { config, flowData }: OwnProps,
): ReduxProps => {
  // The user cannot select more than one fuel card for now.
  // only if all of them are lost or stolen
  const [fuelCardId] = flowData.fuelCardIDs

  return {
    supplier: getSelectedSupplier(state, config.suppliers, fuelCardId),
    cardName: getSelectedCardName(fuelCardId)(state),
  }
}

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep, setFlowData } = props

    dispatch(
      new SubmitFuelCard({
        flowData,
        onComplete: error => {
          if (!error) {
            setFlowData(flowData)
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectServiceTypeWithSuppliersStep)
