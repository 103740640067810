import { SagaIterator } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { getUser } from 'mlp-client/src/user/selectors'
import { ApiSagas } from 'mlp-client/src/api'
import { NotificationStatus } from 'mlp-client/src/contracts/enums'

import * as actions from './actions'

export function* submitLastMileage(
  action: actions.SubmitLastMileage,
): SagaIterator {
  const {
    mileage,
    mileageDate,
    contractId,
    onComplete,
    attachmentKeys,
  } = action.payload
  const options = {
    throwError: true,
  }

  try {
    const user = yield select(getUser)

    yield call(
      ApiSagas.post,
      `/myleaseplan/lpis/mileage`,
      {
        body: {
          contractId,
          mileage,
          mileageDate,
          attachmentKeys,
          userId: user.id,
        },
      },
      options,
    )

    sessionStorage.setItem(
      `notification_status_${contractId}`,
      NotificationStatus.DELETED,
    )

    yield call(onComplete)
  } catch (error) {
    yield call(onComplete, { error: '' })
  }
}

export default [
  takeLatest(actions.ActionTypes.SUBMIT_LAST_MILEAGE, submitLastMileage),
]
