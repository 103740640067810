import {
  getLanguage,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import {
  TileOption,
  TileOptions,
  TileProps,
  TilesConfig,
} from 'mlp-client/src/dashboard/type'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles'
import { AppState } from 'mlp-client/src/types'

export const getTileConfig = (
  state: AppState,
  options: TileOptions = [],
): TilesConfig => options.map(option => getTileProps(state, option))

export const getTileProps = (
  state: AppState,
  { localizedLinks, ...props }: TileOption,
): TileProps => {
  const language = getLanguage(state)

  return {
    ...props,
    view: props.view || BasicTile,
    title: props.title && getTranslation(state, props.title),
    summary: getTranslation(state, props.summary),
    disabledTitle:
      props.disabledTitle && getTranslation(state, props.disabledTitle),
    disabledSummary:
      props.disabledSummary && getTranslation(state, props.disabledSummary),
    to: localizedLinks ? localizedLinks[language] : props.to,
  }
}
