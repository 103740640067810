import { Grid, GridItem, Spacing, Timeline, TimelineItem } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import Tabs, { TabItem } from 'mlp-client/src/components/tabs/Tabs'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import ViewContract from 'mlp-client/src/contracts/components/ViewContract/ViewContract'
import { Contract, FUEL_MANAGEMENT_TYPE } from 'mlp-client/src/contracts/types'
import { Quote } from 'mlp-client/src/quotes/types'
import { getContract } from 'mlp-client/src/contracts/selectors'
import { getQuote } from 'mlp-client/src/quotes/selectors'
import { AppState } from 'mlp-client/src/types'
import { filterTaxationValues } from 'mlp-client/src/contracts/utils'
import {
  TimelineItem as TimelineItemType,
  TimelineItems,
} from 'mlp-client/src/order-status/types'
import { timeLineItemsConfig } from 'mlp-client/src/order-status/options'
import { getCurrentStatusValue } from 'mlp-client/src/order-status/utils'
import TimelineItemComponent from 'mlp-client/src/order-status/components/TimelineItemContent'
import { ContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { Locales } from 'mlp-client/src/localization/enums'
import { getLocale } from 'mlp-client/src/localization/selectors'
import { isFrance } from 'mlp-client/src/order-status/selectors'

interface Props {
  dashboardContent: React.ReactElement<{}>
  contract: Contract
  quote: Quote
  isMobileApp: boolean
  locale: Locales
}

class DashboardTabs extends React.PureComponent<Props> {
  getTimeLineItems = (): TimelineItems => {
    const { contract, quote, locale } = this.props
    const isFrenchLocale = isFrance(locale)

    if (contract) {
      const currentStatus =
        contract?.orderStatus ||
        timeLineItemsConfig(null, null, isFrenchLocale)[0].itemId

      return timeLineItemsConfig(contract.id, null, isFrenchLocale).map(
        item => {
          const SubtitleComponent = item.subtitle

          return {
            ...item,
            subtitle: <SubtitleComponent />,
            status: getCurrentStatusValue(item.itemId, currentStatus),
          }
        },
      )
    }

    if (quote) {
      const currentStatus =
        quote?.status ||
        timeLineItemsConfig(null, null, isFrenchLocale)[0].itemId

      return timeLineItemsConfig(null, quote.id, isFrenchLocale).map(item => {
        const SubtitleComponent = item.subtitle

        return {
          ...item,
          subtitle: <SubtitleComponent />,
          status: getCurrentStatusValue(item.itemId, currentStatus),
        }
      })
    }

    return []
  }

  renderItem = (item: TimelineItemType) => {
    const { status, itemId } = item

    return (
      <TimelineItem status={status} key={itemId}>
        <TimelineItemComponent {...item} />
      </TimelineItem>
    )
  }

  render() {
    return (
      <MyLeaseplanContext.Consumer>
        {context => {
          const { dashboardContent, contract, quote, isMobileApp } = this.props

          const featureConfig = contract
            ? context.contracts?.configuration
            : context.quotes?.configuration
          const type = contract ? contract?.fullTypeOfContract : ''
          const config = contract
            ? filterTaxationValues(
                featureConfig as ContractsFeatureConfig,
                type,
              )
            : null

          return (
            <Tabs scroll={false} collapseOnMobile={false}>
              <TabItem
                key="tab-serviceAndQuickActions"
                data-tab-label="myLeaseplan.dashboard.serviceAndQuickActions"
                data-e2e-id="serviceAndQuickActions"
              >
                {dashboardContent}
              </TabItem>
              {contract && contract.type !== FUEL_MANAGEMENT_TYPE && (
                <TabItem
                  key="tab-carAndContract"
                  data-tab-label="myLeaseplan.subNavigation.carContract"
                  data-e2e-id="carAndContract"
                >
                  <Spacing mt={3}>
                    <ViewContract
                      config={config}
                      contract={contract}
                      isMobileApp={isMobileApp}
                    />
                  </Spacing>
                </TabItem>
              )}
              {quote && (
                <TabItem
                  key="tab-quote"
                  data-tab-label="myLeaseplan.subNavigation.carContract"
                >
                  <Spacing mt={3}>
                    <Grid verticalGutter={false}>
                      <GridItem span={{ mobile: 12, tablet: 10, lap: 7 }}>
                        <Grid justifyContent="center">
                          <GridItem span={{ mobile: 12, tablet: 11, lap: 12 }}>
                            <Timeline>
                              {this.getTimeLineItems().map(this.renderItem)}
                            </Timeline>
                          </GridItem>
                        </Grid>
                      </GridItem>
                      <GridItem span={{ mobile: 12, tablet: 10, lap: 5 }}>
                        <Spacing ml={2}>
                          {/*<OrderDetails vehicleDetails={vehicleDetails} />*/}
                        </Spacing>
                      </GridItem>
                    </Grid>
                  </Spacing>
                </TabItem>
              )}
            </Tabs>
          )
        }}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<Props, 'contract' | 'quote' | 'isMobileApp' | 'locale'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contract: getContract(state),
  quote: getQuote(state),
  isMobileApp: getIsMobileApp(state),
  locale: getLocale(state),
})

export default connect(mapStateToProps)(DashboardTabs)
