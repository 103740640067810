import React from 'react'

import { TextStyled } from 'mlp-client/src/components/vehicle-overview-tiles/VehicleOverviewTile.styled'
import { VehicleTileLinks } from 'mlp-client/src/components/vehicle-overview-tiles/types'
import {
  GlassIcon,
  MaintenanceIcon,
} from 'mlp-client/src/dashboard/components/icons'
import ContractIcon from 'mlp-client/src/components/icons/contract-icon/ContractIcon'

const renderThreeDotsIcon = () => (
  <TextStyled bold variant="200">
    ...
  </TextStyled>
)

export const tileLinksFirstColumn: VehicleTileLinks = [
  {
    to: 'myLeaseplan.dashboard',
    dataE2eId: 'bookService',
    icon: MaintenanceIcon,
    translationId: 'service',
  },
  {
    to: 'myLeaseplan.damageReport.base',
    dataE2eId: 'reportDamage',
    icon: GlassIcon,
    translationId: 'damage',
  },
]
export const tileLinksSecondColumn: VehicleTileLinks = [
  {
    to: 'myLeaseplan.contract.detail',
    dataE2eId: 'viewContract',
    icon: ContractIcon,
    translationId: 'contract',
  },
  {
    to: 'myLeaseplan.dashboard',
    dataE2eId: 'moreActions',
    icon: renderThreeDotsIcon,
    translationId: 'more',
  },
]

export const tileLinks = () => ({
  tileLinksFirstColumn,
  tileLinksSecondColumn,
})
