import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

interface Props {
  title?: string
  color?: string
  width?: number
  height?: number
  className?: string
}

type DefaultProps = Required<
  Pick<Props, 'title' | 'color' | 'width' | 'height'>
>

type InternalProps = Props & DefaultProps
export default class ChevronDownloadIcon extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    title: 'myLeaseplan.shared.icons.chevronDownload.title',
    color: '#326E7D',
    width: 16,
    height: 20,
  }

  render() {
    const { width, height, className, title, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 20"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <title>
          <Translation id={title} />
        </title>
        <path
          d="M7.448 11.874V1.212a1 1 0 1 1 2 0V11.82l3.315-3.316a1 1 0 0 1 1.414 1.415l-4.695 4.697a1.498 1.498 0 0 1-1.06.438c-.385 0-.769-.147-1.062-.438L2.665 9.919A.999.999 0 1 1 4.08 8.503l3.369 3.37zM1 19.5a1 1 0 0 1 0-2l14-.013a1 1 0 0 1 .001 2l-14 .013z"
          fill={color}
        />
      </svg>
    )
  }
}
