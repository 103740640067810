import React from 'react'

import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'
import { isExternal } from 'mlp-client/src/utils'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

// uses `onClick` or `href` based on the action property
const LoginPageLink = ({
  action,
  children,
}: {
  action: string | React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
}) => {
  const className =
    'link link--18 link--bold link--no-underline link--mid-orange link--hover login-page-list-item'

  if (typeof action === 'function') {
    return (
      <button className={className} onClick={action}>
        {children}
      </button>
    )
  } else {
    return (
      <a
        className={className}
        href={action}
        target={isExternal(action) ? '_blank' : '_self'}
      >
        {children}
      </a>
    )
  }
}

interface Props {
  linkText: React.ReactNode
  labelText?: React.ReactNode
  action: string | React.MouseEventHandler<HTMLButtonElement>
}

class LoginPageListItem extends React.PureComponent<Props> {
  render() {
    const { action, linkText, labelText } = this.props

    return (
      <div className="u-margin-bottom-small u-margin-top u-padding-top-small">
        <div className="u-margin-bottom-tiny">
          <SecondaryDarkText component="h3" variant="400">
            {labelText}
          </SecondaryDarkText>
        </div>
        <LoginPageLink action={action}>
          <ChevronRightIcon color="midOrange" className="u-margin-right-tiny" />
          {linkText}
        </LoginPageLink>
      </div>
    )
  }
}

export { LoginPageListItem, LoginPageLink }
export default LoginPageListItem
