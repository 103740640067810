import React from 'react'

import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'

import { Header } from './Header'

export interface Props {
  headerText?: string
}

export const Section: React.StatelessComponent<Props> = props => {
  const { headerText, children } = props

  return (
    <>
      {headerText && <Header text={headerText} />}
      {children && (
        <FlowStepChevronList width="18px" height="18px" textSize="300">
          {React.Children.toArray(children)}
        </FlowStepChevronList>
      )}
    </>
  )
}

export default Section
