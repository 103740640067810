import {
  Color,
  Grid,
  GridItem,
  IconText,
  Link,
  mq,
  Spacing,
} from '@leaseplan/ui'
import { colorMixins, Text } from '@velocity/ui'
import * as React from 'react'
import styled from 'styled-components'

export const SpacingRow = styled(Spacing)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const Right = styled.div`
  margin-left: auto;
`

export const VehicleOverviewGrid = styled(Grid)`
  ${mq.from('lap')`
    flex-wrap: nowrap;
  `}
`

export const VehicleGridItem = styled(GridItem)`
  min-width: 40%;
  ${mq.from('lap')`
    border-right: 1px solid ${Color.gallery};
  `}
  ${mq.until('lap')`
    border-bottom: 1px solid ${Color.gallery};
  `}
`

export const VehicleTileLinksGridItem = styled(GridItem)`
  ${mq.from('lap')`
    max-width: 60%;
  `}
`

export const IconTextStyled = styled(IconText)`
  display: flex;
  padding: 0;

  > svg:first-of-type {
    flex: 0 0 24px;
  }
`
export const LinkStyled = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`
export const TextStyled = styled(Text)`
  width: 24px;
  text-align: center;
  line-height: 16px;
  color: ${colorMixins.grayDarkColor};
`
export const RowStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  max-width: 100%;
`

export const SpacingStyled = styled(Spacing)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ColumnStyled = styled(props => <Spacing m={1} {...props} />)`
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 30%;
`

export const CellStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 25%;
  & + & {
    margin-left: 24px;
  }
  ${mq.from('lap')`
    max-width: none;
    & + & {
      margin-left: 0;
      margin-top: 24px;
    }
  `}
`
export const VehicleOverviewTileText = styled(Text)`
  line-height: 24px;
  white-space: nowrap;
  color: ${colorMixins.grayDarkColor};
`
