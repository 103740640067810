import { mileageUpdateConfig } from 'mlp-client/src/features-configuration/default/mileageUpdate'
import { navigation } from 'mlp-client/src/features-configuration/default/navigation'
import { UserProfileAddressKeys as Address } from 'mlp-client/src/user/enums'
import { DistanceUnit } from 'mlp-client/src/enums'
import { FeatureStatus } from 'mlp-client/src/features-configuration/enums'
import {
  ApiType,
  MyleaseplanConfig,
  SharedMyLeaseplanConfig,
} from 'mlp-client/src/features-configuration/types'
import {
  CashClaimIcon,
  FuelCardIcon,
  FuelHistoryIcon,
} from 'mlp-client/src/dashboard/components/icons'
import {
  tileLinksFirstColumn,
  tileLinksSecondColumn,
} from 'mlp-client/src/features-configuration/default/multipleVehicles'

import { attachCarToDriverFeature } from './attachCarToDriver'
import { buyVehicleFeature } from './buy'
import { cashClaimConfig } from './cashClaim'
import { contractsConfig } from './contracts'
import { dashboardConfig } from './dashboard'
import { editProfileConfig } from './editProfile'
import { extendContractFeature } from './extendContract'
import { fuelCardsConfig } from './fuelCards'
import { glassRepairFeature } from './glassRepair'
import { maintenanceConfig } from './maintenance'
import { returnVehicleFeature } from './return'
import { timelineConfig } from './timeline'
import { tyresConfig } from './tyres'
import {
  vehicleDocumentsFeature,
  vehicleDocumentsPageFeature,
} from './vehicleDocuments'
import { damageReportConfig } from './damage-report'
import { loginConfig } from './login'
import { fuelHistoryFeature } from './fuelHistory'
import { finesFeature } from './fines'
import { endOfLeaseFeature } from './endOfLease'

export const sharedMyLeaseplanConfig: SharedMyLeaseplanConfig = {
  enabled: true,
  loginEnabled: true,
  accountLinkEnabled: false,
  myAccountLink: 'myLeaseplan.homepage',
}

export const baseMyLeaseplanConfig: MyleaseplanConfig = {
  ...sharedMyLeaseplanConfig,
  apiType: ApiType.lpis,
  alternatePhoneDisplay: true,
  policyDocuments: true,
  minSupplierDistance: 75,
  servicePhoneNumber: 'myLeaseplan.serviceRequest.servicePhone',
  supplierAvailabilityTimeFrame: 5,
  distanceUnit: DistanceUnit.KM,
  addressFormat: [
    `{${Address.number}}{${Address.suffix}} {${Address.street}}`,
    `{${Address.zipCode}} {${Address.city}}`,
    `{${Address.country}}`,
  ],
  isPublicHolidaysDisabled: FeatureStatus.ENABLED,

  // If the sub-feature is disabled, it will be enabled when showAllFeature flag exists.
  // if the sub-feature is not explicitly declared, it will not be enabled with showAllFeature.
  // Enabled: True => Live in PROD
  // Enabled: False => Hidden under feature flag.

  /**
   * MyLeasePlan sub-features
   */

  // Old configuration
  welcomeScreen: {
    enabled: true,
    allowedServices: ['bookService', 'fuelCard', 'damage', 'documents'],
  },
  users: {
    enabled: true,
  },
  complaints: {
    enabled: true,
  },
  requestForInformation: {
    enabled: true,
  },
  isExternalLoginEnabled: true,
  supportsMultipleVehicles: {
    enabled: true,
    configuration: {
      tileLinks: contract => {
        if (contract.type === 'Fuel Management') {
          return {
            tileLinksFirstColumn: [
              {
                to: 'myLeaseplan.fuelHistory.base',
                dataE2eId: 'fuelHistory',
                icon: FuelHistoryIcon,
                translationId: 'fuelHistory',
              },
              {
                to: 'myLeaseplan.fuelCard.base',
                dataE2eId: 'fuelCard',
                icon: FuelCardIcon,
                translationId: 'fuelCard',
              },
            ],
            tileLinksSecondColumn: [
              {
                to: 'myLeaseplan.cashClaim.base',
                dataE2eId: 'cashClaim',
                icon: CashClaimIcon,
                translationId: 'cashClaim',
              },
            ],
          }
        }

        return {
          tileLinksFirstColumn,
          tileLinksSecondColumn,
        }
      },
    },
  },
}

export const myleaseplanConfig: MyleaseplanConfig = {
  ...baseMyLeaseplanConfig,
  navigation,
  login: loginConfig,
  showSalutation: false,
  fuelCard: fuelCardsConfig,
  attachCarToDriver: attachCarToDriverFeature,
  tyres: tyresConfig,
  buy: buyVehicleFeature,
  return: returnVehicleFeature,
  endOfLease: endOfLeaseFeature,
  timeline: timelineConfig,
  cashClaims: cashClaimConfig,
  editProfile: editProfileConfig,
  fines: finesFeature,
  fuelHistory: fuelHistoryFeature,
  extendContract: extendContractFeature,
  glassRepair: glassRepairFeature,
  contracts: contractsConfig,
  vehicleDocuments: vehicleDocumentsFeature,
  vehicleDocumentsPage: vehicleDocumentsPageFeature,
  dashboard: dashboardConfig,
  maintenance: maintenanceConfig,
  damageReport: damageReportConfig,
  mileageUpdate: mileageUpdateConfig,
}
