import { Locales } from 'mlp-client/src/localization/enums'
import {
  FeatureMap,
  TileOption,
  TilesConfig,
} from 'mlp-client/src/dashboard/type'
import { User } from 'mlp-client/src/user/types'
import { getIdOfTranslation } from 'mlp-client/src/translations/utils'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { Service, Services } from 'mlp-client/src/contracts/types'

export const filterTileConfig = (
  tilesConfig: TilesConfig,
  features: FeatureMap,
): TilesConfig => tilesConfig.filter(config => features[config.name])

export const getUserName = (showFullName: boolean, user: User): string =>
  showFullName ? `${user.firstName} ${user.lastName}` : user.firstName

/**
 * Those two utils were moved from 'mlp-client/src/dashboard/components/SimpleDashboardTile'
 * because we use them for some purposes on server side in mobile utils. But component SimpleDashboardTile
 * is not used itself
 */

export const getTranslationIdOfDashboardTitle = (
  resourceSegment: string,
  typeKey: string,
  withoutParentKey?: boolean,
  isContractExpired?: boolean,
) => {
  const id = isContractExpired ? 'steps.endLease.ended' : 'dashboard.title'

  return getIdOfTranslation(id, resourceSegment, typeKey, withoutParentKey)
}

export const getTranslationIdOfDashboardSummary = (
  resourceSegment: string,
  typeKey: string,
  withoutParentKey?: boolean,
) =>
  getIdOfTranslation(
    'dashboard.summary',
    resourceSegment,
    typeKey,
    withoutParentKey,
  )

export const isFuelCardAlwaysDisplayed = (config: MyleaseplanConfig): boolean =>
  Boolean(config.dashboard?.configuration?.alwaysDisplayFuelCardTile)

export const filterTilesByServiceNumbers = (services: Services) => ({
  serviceNumbersIncluded,
  serviceNumbersExcluded,
}: TileOption): boolean => {
  if (!serviceNumbersIncluded && !serviceNumbersExcluded) {
    return true
  }

  if (serviceNumbersIncluded) {
    return services.some(({ serviceNumber }: Service) =>
      serviceNumbersIncluded.includes(serviceNumber),
    )
  }

  return !services.some(({ serviceNumber }: Service) =>
    serviceNumbersExcluded.includes(serviceNumber),
  )
}

export const isLuxembourg = (locale: Locales): boolean =>
  [Locales.FR_LU, Locales.EN_LU].includes(locale)
