import { CountrySpecific } from 'mlp-client/src/config/types'
import { sharedMyLeaseplanConfig } from 'mlp-client/src/features-configuration/de'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'

import createConfig from './defaults'

const specificConfig = (): CountrySpecific => ({
  label: 'Germany',
  code: CountryCode.de,
  tld: '.de',
  languages: [
    { label: 'English', code: LanguageCode.en, locale: Locales.EN_DE },
    {
      label: 'Deutsch',
      code: LanguageCode.de,
      locale: Locales.DE,
      preferred: true,
    },
  ],
  features: {
    myLeasePlan: sharedMyLeaseplanConfig,
  },
  enableLanguageSelector: true,
  noContractAllowed: false,
})

export default createConfig(specificConfig)
