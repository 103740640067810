import { createSelector } from 'reselect'

import {
  hasContracts,
  isContractsLoaded,
  isContractsLoading,
} from 'mlp-client/src/contracts/selectors'
import { isWelcomeVisible } from 'mlp-client/src/welcome-screen/utils'
import {
  getMyLeaseplanConfig,
  isFeatureEnabled,
} from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'

export const getWelcomeScreenAllowedServices = createSelector(
  getMyLeaseplanConfig,
  config => config.welcomeScreen?.allowedServices,
)

export const getIsWelcomeScreenEnable = (state: AppState): boolean =>
  isFeatureEnabled(state, 'welcomeScreen')

export const isWelcomeScreenVisible = createSelector(
  getIsWelcomeScreenEnable,
  isContractsLoaded,
  isContractsLoading,
  hasContracts,
  isWelcomeVisible,
)
