import moment from 'moment'

import { CallUsTileContractWorkingHours } from 'mlp-client/src/flows/fuel-card/types'

export const createWorkingTimeStr = ({
  startDay,
  startHour,
  endDay,
  endHour,
}: CallUsTileContractWorkingHours): string => {
  const startDayShort: string = startDay ? moment.weekdaysShort(startDay) : ''
  const endDayShort: string = endDay ? moment.weekdaysShort(endDay) : ''
  const daysPeriod: string = endDayShort
    ? `${startDayShort} - ${endDayShort}`
    : startDayShort
  const timePeriod: string = `${startHour} - ${endHour}`

  return `${daysPeriod}: ${timePeriod}`
}
