import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { getMalfunctions } from 'mlp-client/src/flows/maintenance/selectors'
import { FlowData, Imalfunction } from 'mlp-client/src/flows/maintenance/types'
import {
  arrayToFlagObject,
  convertMalfunctionsToOptions,
} from 'mlp-client/src/flows/maintenance/utils'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { CheckboxList } from 'mlp-client/src/form/components/fields/CheckboxFields'
import { AppState } from 'mlp-client/src/types'
import { noEmptyObject } from 'mlp-client/src/form/validations'
import { Options } from 'mlp-client/src/form/types'

export interface StepConfig {
  titleLabel: string
  textField?: boolean
}

export interface Props extends StepViewProps<StepConfig, FlowData> {
  malfunctions: Options
}

export interface FormData {
  malfunctionsType: Imalfunction[]
}

class SelectMalfunctionsStep extends React.PureComponent<Props> {
  submitHandler = (values: FormData) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        malfunctionsType: Object.keys(values.malfunctionsType).map(key => ({
          malfunction: key,
          description: values[`${key}_description`] || '',
        })),
      },
      goToNextStep,
    )
  }

  render() {
    const {
      malfunctions,
      config: { titleLabel, textField },
      flowData: { malfunctionsType },
    } = this.props
    const malfunction = malfunctionsType?.map(value => value.malfunction)
    const description = malfunctionsType
      ?.map(value => ({
        [`${value.malfunction}_description`]: value.description,
      }))
      .reduce((acc, i) => ({ ...acc, ...i }), {})
    const initialData = {
      malfunctionsType: arrayToFlagObject(malfunction),
      ...description,
    }

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel={titleLabel}>
          <Form onSubmit={this.submitHandler} initialValues={initialData}>
            {({ handleSubmit, hasValidationErrors }) => (
              <form
                onSubmit={handleSubmit}
                name="maintenance-selectMalfunctions"
              >
                <CheckboxList
                  name="malfunctionsType"
                  options={malfunctions}
                  validations={[noEmptyObject]}
                  freeTextField={textField}
                />
                <Spacing mb={2} />
                <NextStepButton
                  onClick={handleSubmit}
                  disabled={hasValidationErrors}
                />
              </form>
            )}
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'malfunctions'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  malfunctions: convertMalfunctionsToOptions(getMalfunctions(state)),
})

export { SelectMalfunctionsStep }
export default connect(mapStateToProps)(SelectMalfunctionsStep)
