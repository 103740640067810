import { Color, Link } from '@leaseplan/ui'
import { Text, colorMixins } from '@velocity/ui'
import styled from 'styled-components'

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${Color.steel80};
`

export const TermsAndConditionsWrapper = styled(Text)`
  display: flex;
  flex-wrap: wrap;
  color: ${colorMixins.gray80Color};
`
