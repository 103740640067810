import currencySymbolMap from 'currency-symbol-map'
import numeral from 'numeral'
import React from 'react'

import log from 'mlp-client/src/log'

interface DefaultProps {
  format: string
}

interface NonDefaultProps {
  amount: number
  currencyCode: string
}

type Props = Partial<DefaultProps> & NonDefaultProps
type PropsWithDefaults = Readonly<DefaultProps & NonDefaultProps>

class LocalizedPrice extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    format: '0,0',
  }

  render() {
    const { amount, format, currencyCode } = this.props as PropsWithDefaults

    return (
      <span data-component="LocalizedPrice">
        {localizedPrice(amount, currencyCode, format)}
      </span>
    )
  }
}

export const localizedPrice = (
  amount: number,
  currencyCode: string,
  format: string = '0,0',
): string => {
  const price = numeral(amount).format(format)
  const currencySymbol = currencySymbolMap(currencyCode)

  let decimalSuffix = ''

  if (format === LocalizedPrice.defaultProps.format) {
    decimalSuffix = localizedSuffix()
  }

  if (!currencySymbol) {
    log.warn(`Price: symbol for currency code ${currencyCode} not found`)
  } else {
    const currency = numeral.localeData().currency
    const prefix =
      currency.symbolPrefix ||
      (!currency.symbolPrefix && !currency.symbolSuffix)
        ? `${currencySymbol} `
        : ''
    const suffix = currency.symbolSuffix ? ` ${currencySymbol}` : ''

    return `${prefix}${price}${decimalSuffix}${suffix}`
  }

  return `${price}${decimalSuffix}`
}

const localizedDecimalSeparator = (): string =>
  numeral.localeData().delimiters.decimal

const localizedSuffix = (): string =>
  'priceSuffix' in numeral.localeData().currency &&
  numeral.localeData().currency.priceSuffix === false
    ? ''
    : `${localizedDecimalSeparator()}-`

export default LocalizedPrice
