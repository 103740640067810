import React from 'react'

import { isLeaseplan } from 'mlp-client/src/VelocitySelector'
import Translation from 'mlp-client/src/localization/Translation'
import { FullWidthBanner } from 'mlp-client/src/components/layout'
import {
  WhiteHeadline,
  WhiteText,
} from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  name: string
}

export const getBannerImageSrc = () => {
  if (isLeaseplan()) {
    return {
      imgMedium: '/static/images/my-leaseplan/dashboard-header-small.jpg',
      imgLarge: '/static/images/my-leaseplan/dashboard-header-large.jpg',
    }
  }

  return {
    imgMedium: '/static/images/my-leaseplan/dashboard-header-small-leasys.png',
    imgLarge: '/static/images/my-leaseplan/dashboard-header-small-leasys.png',
  }
}

class TopBanner extends React.PureComponent<Props> {
  render() {
    const { name } = this.props
    const imgSource = getBannerImageSrc()

    return (
      <FullWidthBanner
        imgMedium={imgSource.imgMedium}
        imgLarge={imgSource.imgLarge}
      >
        <>
          <WhiteHeadline variant="500" withMarginBottom>
            <Translation
              replace={{ name }}
              id="myLeaseplan.dashboard.welcome"
            />
          </WhiteHeadline>
          <WhiteText component="div" variant="400">
            <Translation id="myLeaseplan.dashboard.subtitleOne" />
          </WhiteText>
        </>
      </FullWidthBanner>
    )
  }
}

export { TopBanner }
export default TopBanner
