import { Headline } from '@velocity/ui'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import { contractEndDateSelector } from 'mlp-client/src/contracts/selectors'
import { ReplacementTypes } from 'mlp-client/src/flows/return/enums'
import {
  DownloadedDocs,
  ReturnNote,
  StepViewProps,
} from 'mlp-client/src/flows/return/types'
import { getReturnNotes } from 'mlp-client/src/flows/return/utils'
import { getDocuments } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { StyledLink } from 'mlp-client/src/components/link/StyledLink'

export interface StepConfig {
  listItemLabels: ReadonlyArray<{
    key: string
    replacement?: ReplacementTypes
    showForLateReturn?: boolean
  }>
  downloadedDocs?: DownloadedDocs
}
export interface Props extends StepViewProps<StepConfig> {
  notes: readonly ReturnNote[]
  contractEndDate: string
}

const translatePath = makeTranslationPath(
  'myLeaseplan.newSetup.flows.return.steps.thankYou',
)

export class ThankYouStep extends React.PureComponent<Props> {
  getLinkReplacements = (notes: readonly ReturnNote[]) =>
    notes.reduce(
      (replacements, note, index) => ({
        ...replacements,
        [`link${index + 1}`]: () => this.generateLink(note),
      }),
      {},
    )

  generateLink = (note: ReturnNote) => (
    <React.Fragment key={note.linkText}>
      <StyledLink
        href={note.downloadUrl}
        target="_blank"
        download={true}
        data-e2e-id={note.linkDataE2eId}
      >
        <Translation id={note.linkText} />
      </StyledLink>
    </React.Fragment>
  )

  renderListItems = () => {
    const {
      contractEndDate,
      notes,
      flowData: { returnDate, email, user },
      config: { listItemLabels },
    } = this.props

    return listItemLabels
      .filter(
        label =>
          !label.showForLateReturn ||
          moment(returnDate).isAfter(contractEndDate, 'day'),
      )
      .map(label => {
        const replacements =
          label.replacement === ReplacementTypes.LINKS
            ? this.getLinkReplacements(notes)
            : label.replacement === ReplacementTypes.EMAIL
            ? { email: () => email || user.email }
            : undefined

        return (
          <Translation
            id={translatePath(label.key)}
            key={label.key}
            replace={replacements}
          />
        )
      })
  }
  render() {
    const { closeFlow } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline variant="400" component="h1" withMarginBottom>
          <Translation id={translatePath('pageTitle')} />
        </Headline>
        <Section headerText={translatePath('whatsNext')} />
        <Section>{this.renderListItems()}</Section>
      </ThankyouPageLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'notes' | 'contractEndDate'>
type OwnProps = Omit<Props, keyof ReduxProps>

export const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): ReduxProps => {
  const documents = getDocuments(state)
  const {
    config: { downloadedDocs },
  } = ownProps

  return {
    contractEndDate: contractEndDateSelector(state),
    notes: getReturnNotes(documents, downloadedDocs),
  }
}

export default connect(mapStateToProps)(ThankYouStep)
