import { DownloadableDocuments } from './types'

export const enum ActionTypes {
  LOAD_DOWNLOADABLE_DOCUMENTS = '@@my-downloadable-documents/LOAD_DOWNLOADABLE_DOCUMENTS',
  LOAD_DOWNLOADABLE_DOCUMENTS_SUCCESS = '@@my-downloadable-documents/LOAD_DOWNLOADABLE_DOCUMENTS_SUCCESS',
  LOAD_DOWNLOADABLE_DOCUMENTS_FAILED = '@@my-downloadable-documents/LOAD_DOWNLOADABLE_DOCUMENTS_FAILED',

  DOWNLOAD_DOWNLOADABLE_DOCUMENT = '@@my-downloadable-documents/DOWNLOAD_DOWNLOADABLE_DOCUMENTS',
  DOWNLOAD_DOWNLOADABLE_DOCUMENT_SUCCESS = '@@my-downloadable-documents/DOWNLOAD_DOWNLOADABLE_DOCUMENTS_SUCCESS',
  DOWNLOAD_DOWNLOADABLE_DOCUMENT_FAILED = '@@my-downloadable-documents/DOWNLOAD_DOWNLOADABLE_DOCUMENTS_FAILED',
}

export class LoadDownloadableDocuments {
  readonly type = ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS
}

export class LoadDownloadableDocumentsSuccess {
  readonly type = ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS_SUCCESS

  constructor(
    public payload: {
      downloadableDocuments: DownloadableDocuments
    },
  ) {}
}

export class LoadDownloadableDocumentsFailed {
  readonly type = ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS_FAILED
}

export class DownloadDownloadableDocument {
  readonly type = ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT

  constructor(
    public payload: {
      documentId: string
      documentName: string
    },
  ) {}
}

export class DownloadDownloadableDocumentSuccess {
  readonly type = ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT_SUCCESS
}

export class DownloadDownloadableDocumentFailed {
  readonly type = ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT_FAILED
}

export type Action =
  | LoadDownloadableDocuments
  | LoadDownloadableDocumentsSuccess
  | LoadDownloadableDocumentsFailed
  | DownloadDownloadableDocument
  | DownloadDownloadableDocumentSuccess
  | DownloadDownloadableDocumentFailed
