import { TextInput } from '@leaseplan/ui'
import { colorMixins } from '@velocity/ui'
import styled, { css } from 'styled-components'

interface StyledComponentProps {
  className?: string
  variant?: 'primary' | 'secondary'
}

export const MileageUnit = styled.div`
  color: ${colorMixins.secondaryDarkColor};
  text-transform: ${(props: StyledComponentProps) =>
    props.variant === 'secondary' ? 'lowercase' : 'uppercase'};
  right: -70px;
  bottom: 0;
  height: 50px;
  width: 70px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

const secondaryCss = css`
  font-size: 18px;
  margin: 0 auto 6px;

  input[type='text'] {
    font-size: 24px;
    letter-spacing: 1.7px;
    padding-right: 70px;
  }
`

export const StyledInput = styled(TextInput)`
  ${(props: StyledComponentProps) =>
    props.variant === 'secondary' && secondaryCss}
`
export const InputWrapper = styled.div`
  position: relative;
  display: inline-block;
  overflow: visible;
  white-space: nowrap;
  width: 175px;
`

export const ErrorMessage = styled.div`
  margin-top: 12px;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.velocity.styling.system.error.text};
`
