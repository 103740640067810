import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  FlowData,
  impactPoints,
} from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { CarPointSelect } from 'mlp-client/src/form/components/fields/car-point-select/CarPointSelect'
import Translation from 'mlp-client/src/localization/Translation'
import { required } from 'mlp-client/src/form/validations'

export interface StepConfig {}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

export type FormData = Pick<FlowData, 'firstImpactPoint' | 'damagePoints'>

export class PointSelectionStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData(formData, goToNextStep)
  }
  render() {
    const {
      flowData: { firstImpactPoint, damagePoints },
    } = this.props
    const listElementLabel = (
      <Spacing mb={2}>
        <Headline variant="200">
          <Translation
            id="myLeaseplan.damageReport.steps.pointSelection.otherDamages"
            data-e2e-id="otherDamages"
          />
        </Headline>
      </Spacing>
    )

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.pointSelection.description">
          <Form
            onSubmit={this.submitHandler}
            initialValues={{ firstImpactPoint, damagePoints }}
          >
            {({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit}>
                  <Spacing mb={2}>
                    <Headline variant="200" component="h5">
                      <Translation id="myLeaseplan.damageReport.steps.pointSelection.firstImpactPoint" />
                    </Headline>
                  </Spacing>
                  <CarPointSelect
                    name="firstImpactPoint"
                    points={impactPoints}
                    hideList={true}
                    validations={[required]}
                  />
                  <Spacing mb={2}>
                    <Headline variant="200" component="h5">
                      <Translation
                        id="myLeaseplan.damageReport.steps.pointSelection.damagePoints"
                        data-e2e-id="damagePoints"
                      />
                    </Headline>
                  </Spacing>

                  <CarPointSelect
                    name="damagePoints"
                    points={impactPoints}
                    multiSelect={true}
                    listElementLabel={listElementLabel}
                    hideList={false}
                    validations={[required]}
                  />
                  <Spacing mt={4} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                </form>
              )
            }}
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default PointSelectionStep
