import React, { useContext } from 'react'
import { Transition } from 'react-transition-group'

import { NavigationItem } from 'mlp-client/src/types'
import { DROPDOWN_STRUCTURE } from 'mlp-client/src/features-configuration/default/navigation'
import { CountryCode, LanguageCode } from 'mlp-client/src/localization/enums'
import { Locale } from 'mlp-client/src/localization/types'

import { ScrollDirection } from './enums'

export interface TopNavigationContextType {
  mobileTakeover: {
    active: boolean
    transition: number
  }
  logout?: () => void
  isDesktop: boolean
  scrollDirection: ScrollDirection
  placeholderHeight: number
  buttonStructure: NavigationItem[]
  parsedLocale: Locale
  onToggleMenu?: (active: boolean) => void
  navItems?: readonly NavigationItem[]
  navActiveItem?: NavigationItem
}

export const defaultContext: TopNavigationContextType = {
  mobileTakeover: {
    active: false,
    transition: Transition.UNMOUNTED,
  },
  isDesktop: true,
  scrollDirection: ScrollDirection.UP,
  placeholderHeight: 0,
  buttonStructure: DROPDOWN_STRUCTURE,
  parsedLocale: {
    language: LanguageCode.en,
    country: CountryCode.us,
  },
}

export const TopNavigationContext = React.createContext<TopNavigationContextType>(
  defaultContext,
)

export const useTopNavigationContext = () => useContext(TopNavigationContext)
