import { Icon, IconProps } from '@leaseplan/ui'
import React from 'react'

export default class ExternalLinkIcon extends React.PureComponent<IconProps> {
  static defaultProps: IconProps = {
    title: 'External link',
    width: '14px',
    height: '14px',
  }

  render() {
    return (
      <Icon {...this.props}>
        <path d="M20.997 4.605l-9.931 9.931a.997.997 0 0 1-1.414 0 .999.999 0 0 1 0-1.414L19.753 3.021h-6.74a1 1 0 1 1 0-2h8.984a1 1 0 0 1 1 1V10.9a1 1 0 1 1-2 0V4.605zm-18 17.38a1 1 0 0 1-1-1V6.018a1 1 0 0 1 1-1h6.02a1 1 0 1 1 0 2h-5.02v12.967h13.015v-5.96a1 1 0 1 1 2 0v6.96a1 1 0 0 1-1 1H2.996z" />
      </Icon>
    )
  }
}
