import { SagaIterator } from 'redux-saga'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/form/components/fields/file-upload-field/actions'
import { createUploadFileChannel } from 'mlp-client/src/form/fileUploadChannel'

export function* uploadFileSaga({
  payload: { file, meta },
}: actions.SubmitFileUpload): SagaIterator {
  const state = yield select()
  const channel = yield call(
    createUploadFileChannel,
    '/api2/files/',
    file,
    meta,
    state,
  )

  while (true) {
    const { progress = 0, err, success, result } = yield take(channel)

    if (err) {
      yield put(new actions.FileUploadFailure({ meta, err }))

      return
    }

    if (success) {
      yield put(new actions.FileUploadSuccess({ meta, result }))

      return
    }

    yield put(new actions.FileUploadProgress({ meta, progress }))
  }
}

export default [
  takeEvery(actions.ActionTypes.SUBMIT_FILE_UPLOAD, uploadFileSaga),
]
