import { DamageReportFeature } from 'mlp-client/src/features-configuration/types'
import Flow from 'mlp-client/src/flows/damage-report/components/flow/Flow'
import { DamageReportFlowConfiguration } from 'mlp-client/src/flows/damage-report/types'
import {
  DamageSides,
  StepNames,
} from 'mlp-client/src/flows/damage-report/enums'
import {
  CheckDetailsStep,
  DamageSideStep,
  DamageUploadStep,
  EuropeanAccidentStatementStep,
  SelectDamageNatureTypeStep,
  StolenVehicleStep,
  ThankYouStep,
} from 'mlp-client/src/flows/damage-report/components/steps'
import {
  pointSelectionConfig,
  whenAndWhereConfig,
} from 'mlp-client/src/features-configuration/default/damage-report'

export const damageReportConfig: DamageReportFeature<DamageReportFlowConfiguration> = {
  enabled: true,
  view: Flow,
  configuration: {
    getSteps: flowData => {
      const steps = [StepNames.SELECT_DAMAGE_NATURE_TYPE]

      if (flowData.damageNatureType === StepNames.STOLEN_VEHICLE) {
        return [...steps, StepNames.STOLEN_VEHICLE]
      }

      return [
        ...steps,
        ...(flowData.isAmbiguousType
          ? [StepNames.SELECT_ONE_OR_TWO_SIDED_DAMAGE]
          : []),
        StepNames.UPLOAD_DAMAGE,
        StepNames.POINT_SELECTION,
        StepNames.WHEN_AND_WHERE,
        StepNames.EUROPEAN_ACCIDENT_STATEMENT,
        StepNames.CHECK_DETAILS,
        StepNames.THANK_YOU,
      ]
    },
    allSteps: {
      [StepNames.SELECT_DAMAGE_NATURE_TYPE]: {
        stepView: SelectDamageNatureTypeStep,
        stepConfig: {
          posterImgLarge:
            '/static/images/my-leaseplan/damage-report-poster-large.jpg',
          posterImgMedium:
            '/static/images/my-leaseplan/damage-report-poster-medium.jpg',
          posterImgSmall:
            '/static/images/my-leaseplan/mobile/damage-report-mobile.png',
          posterTitleLabel:
            'myLeaseplan.damageReport.steps.selectDamageNatureType.headerTitle',
          title: 'myLeaseplan.damageReport.steps.selectDamageNatureType.title',
        },
      },
      [StepNames.SELECT_ONE_OR_TWO_SIDED_DAMAGE]: {
        stepView: DamageSideStep,
        stepConfig: {
          title:
            'myLeaseplan.damageReport.steps.selectOneOrTwoSidedDamage.pageTitle',
          options: [
            {
              value: DamageSides.ONE_SIDED,
              title: 'myLeaseplan.damageReport.oneSided',
            },
            {
              value: DamageSides.TWO_SIDED,
              title: 'myLeaseplan.damageReport.twoSided',
            },
          ],
        },
      },
      [StepNames.STOLEN_VEHICLE]: {
        stepView: StolenVehicleStep,
        stepConfig: {},
      },
      [StepNames.UPLOAD_DAMAGE]: {
        stepView: DamageUploadStep,
        stepConfig: {
          isPoliceStationEnabled: false,
        },
      },
      [StepNames.POINT_SELECTION]: pointSelectionConfig,
      [StepNames.WHEN_AND_WHERE]: whenAndWhereConfig,
      [StepNames.EUROPEAN_ACCIDENT_STATEMENT]: {
        stepView: EuropeanAccidentStatementStep,
        stepConfig: {},
      },
      [StepNames.CHECK_DETAILS]: {
        stepView: CheckDetailsStep,
        stepConfig: {},
      },
      [StepNames.THANK_YOU]: {
        stepView: ThankYouStep,
        stepConfig: {
          amountOfWorkingDays: 5,
          showTimelineLabel: true,
        },
      },
    },
  },
}
