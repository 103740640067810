import { createSelector } from 'reselect'

import { AppState } from 'mlp-client/src/types'
import { isFeatureEnabled } from 'mlp-client/src/selectors'
import {
  getContracts,
  getLeaseContracts,
} from 'mlp-client/src/contracts/selectors'

import { Quotes, Quote } from './types'

const getQuotesState = (state: AppState) => state.quotes

export const isQuotesLoading = (state: AppState): boolean => {
  const quotesState = getQuotesState(state)

  return quotesState.loading
}

export const isQuotesLoaded = (state: AppState): boolean => {
  const quotesState = getQuotesState(state)

  return quotesState.isLoaded
}

export const getQuotes = (state: AppState): Quotes => {
  const quotesState = getQuotesState(state)

  return quotesState.quotes
}

export const getCurrentQuoteId = (state: AppState): string =>
  state.quotes.currentQuote || null

export const getCurrentQuoteDetails = (state: AppState): Quote =>
  state.quotes.currentQuoteDetails || null

export const hasQuotes = createSelector(
  getLeaseContracts,
  getQuotes,
  (contracts, quotes): boolean => contracts.length === 0 && quotes.length > 1,
)

export const getQuoteById = createSelector(
  getQuotes,
  quotes => (quoteId?: string): Quote =>
    quotes.find(quote => quote.id === quoteId),
)

export const getQuote = (state: AppState): Quote => getCurrentQuote(state)

export const getCurrentQuote = createSelector(
  getCurrentQuoteId,
  getQuoteById,
  (currentQuoteId: string, quoteById: (quoteId: string) => Quote): Quote =>
    quoteById(currentQuoteId),
)

export const hasOnlyOneQuote = createSelector(
  getContracts,
  getQuotes,
  (contracts, quotes): boolean => contracts.length === 0 && quotes.length === 1,
)

export const hasMultipleQuotesAndContracts = createSelector(
  getContracts,
  getQuotes,
  (contracts, quotes) => [...contracts, ...quotes].length > 1,
)

export const isQuotesFeatureEnabled = (state: AppState): boolean =>
  isFeatureEnabled(state, 'quotes')
