import { getContentTopics } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'
import { Options } from 'mlp-client/src/form/types'

export const pageContentEndpoint = 'shared/mappings/?app=myleaseplan&name=rfis'

export const getRequestForInformationTopics = (state: AppState): Options => {
  const topics = getContentTopics(state, pageContentEndpoint)

  return topics?.length
    ? topics
        // filter out items that have no category
        .filter(topic => topic.Category)
        .map(topic => ({
          title: topic.Title,
          value: JSON.stringify({
            category: topic.Category,
            subCategory: topic.Subcategory,
            serviceType: topic.ServiceType,
          }),
        }))
    : []
}
