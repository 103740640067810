import { Button, ModalContent, ModalTitle } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import { useSelector } from 'react-redux'

import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { FrenchPhoneNumber } from 'mlp-client/src/components/french-phone-number/FrenchPhoneNumber'
import { formatTelephoneHref } from 'mlp-client/src/utils'
import { Locales } from 'mlp-client/src/localization/enums'

export interface Props {
  phoneNumber: string
  title: string
  message?: string
}

const PhoneNumberModalContent: React.FC<Props> = ({
  title,
  message,
  phoneNumber,
}) => {
  const locale = useSelector(getUserLocale)
  const hasMessage = Boolean(message)

  return (
    <ModalContent data-e2e-id="phoneNumber_modal">
      <ModalTitle>{title}</ModalTitle>

      {hasMessage && (
        <Text component="p" className="u-margin-bottom">
          {message}
        </Text>
      )}

      {locale === Locales.FR ? (
        <FrenchPhoneNumber number={phoneNumber} />
      ) : (
        <Button
          className="btn"
          component="a"
          href={formatTelephoneHref(phoneNumber)}
        >
          {phoneNumber}
        </Button>
      )}
    </ModalContent>
  )
}

export default PhoneNumberModalContent
