import { TimelineItemStatus } from '@leaseplan/ui'
import React from 'react'

import {
  ConfirmedByDealer,
  Delivered,
  OrderAtADealer,
  PendingApproval,
  ReadyToDelivery,
  PendingApprovalQuote,
} from 'mlp-client/src/order-status/components/timeline-item-details'
import { OrderStatuses } from 'mlp-client/src/order-status/enums'
import { TimelineItemConfig } from 'mlp-client/src/order-status/types'

export const defaultTimeLineItemStatus: TimelineItemStatus = 'inactive'

export const timeLineItemsConfig = (
  orderId?: string,
  quoteId?: string,
  isFrenchLocale = false,
): TimelineItemConfig =>
  !isFrenchLocale
    ? getDefaultConfig(orderId, quoteId)
    : getFrenchConfig(orderId, quoteId)

export const getDefaultConfig = (
  orderId?: string,
  quoteId?: string,
): TimelineItemConfig => [
  {
    itemId: OrderStatuses.PENDING_APPROVAL,
    title: 'myLeaseplan.genericNotifications.statusCarService.pendingApproval',
    subtitle: () =>
      orderId ? (
        <PendingApproval orderId={orderId} />
      ) : (
        <PendingApprovalQuote quoteId={quoteId} />
      ),
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.ORDER_AT_DEALER,
    title: 'myLeaseplan.genericNotifications.statusCarService.dealerDate',
    subtitle: OrderAtADealer,
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.CONFIRMED_BY_DEALER,
    title:
      'myLeaseplan.genericNotifications.statusCarService.confirmedByDealer',
    subtitle: () => <ConfirmedByDealer orderId={orderId} />,
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.CAR_READY_TO_DELIVERY,
    title: 'myLeaseplan.genericNotifications.statusCarService.carReadyDelivery',
    subtitle: () => <ReadyToDelivery orderId={orderId} />,
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.DELIVERED,
    title: 'myLeaseplan.genericNotifications.statusCarService.delivered',
    subtitle: Delivered,
    status: defaultTimeLineItemStatus,
  },
]

export const getFrenchConfig = (
  orderId?: string,
  quoteId?: string,
): TimelineItemConfig => [
  {
    itemId: OrderStatuses.ORDER_AT_DEALER,
    title: 'myLeaseplan.genericNotifications.statusCarService.pendingApproval',
    subtitle: () =>
      orderId ? (
        <PendingApproval orderId={orderId} />
      ) : (
        <PendingApprovalQuote quoteId={quoteId} />
      ),
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.CONFIRMED_BY_DEALER,
    title: 'myLeaseplan.genericNotifications.statusCarService.dealerDate',
    subtitle: OrderAtADealer,
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.ORDER_VALIDATED,
    title:
      'myLeaseplan.genericNotifications.statusCarService.confirmedByDealer',
    subtitle: () => <ConfirmedByDealer orderId={orderId} />,
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.CAR_READY_TO_DELIVERY,
    title: 'myLeaseplan.genericNotifications.statusCarService.carReadyDelivery',
    subtitle: () => <ReadyToDelivery orderId={orderId} />,
    status: defaultTimeLineItemStatus,
  },
  {
    itemId: OrderStatuses.DELIVERED,
    title: 'myLeaseplan.genericNotifications.statusCarService.delivered',
    subtitle: Delivered,
    status: defaultTimeLineItemStatus,
  },
]
