import { ButtonChevronDownIcon, Card, Spacing } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

import { RevealButton } from './RevealingItemList.styled'

export interface Props<T> {
  items: readonly T[]
  initialVisibleBatches?: number
  batchSize: number
  onReveal?(visibleBatches: number): void
  children(items: T[]): readonly React.ReactElement[] | React.ReactNode
}

interface State {
  visibleBatches: number
  batchSize: number
}

interface InputData {
  batchSize: number
  batchesNumber: number
  itemsNumber: number
}

type DefaultProps = Required<Pick<Props<{}>, 'initialVisibleBatches'>>
type InternalProps<T> = Props<T> & DefaultProps

export class RevealingItemList<T> extends React.PureComponent<
  InternalProps<T>,
  State
> {
  static defaultProps: DefaultProps = {
    initialVisibleBatches: 1,
  }

  state = this.normalizeInput({
    batchSize: this.props.batchSize,
    itemsNumber: this.props.items.length,
    batchesNumber: this.props.initialVisibleBatches,
  })

  private normalizeInput({
    batchesNumber,
    batchSize,
    itemsNumber,
  }: InputData): State {
    const normalizedBatchSize = batchSize < 1 ? 1 : Math.floor(batchSize)

    let normalizedBatchNumber = Math.floor(batchesNumber)

    if (normalizedBatchNumber < 1) {
      normalizedBatchNumber = 1
    } else if (normalizedBatchNumber * normalizedBatchSize > itemsNumber) {
      normalizedBatchNumber = Math.ceil(itemsNumber / normalizedBatchSize)
    }

    return {
      visibleBatches: normalizedBatchNumber,
      batchSize: normalizedBatchSize,
    }
  }

  private clickHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { visibleBatches } = this.state
    const { onReveal } = this.props
    const nextVisibleBatches = visibleBatches + 1

    this.setState({
      visibleBatches: nextVisibleBatches,
    })

    if (onReveal) {
      onReveal(nextVisibleBatches)
    }

    // It prevents redirection to the next step
    e.preventDefault()
  }

  componentDidUpdate({
    items: prevItems,
    batchSize: prevBatchSize,
  }: InternalProps<T>) {
    const { items, batchSize: currBatchSize } = this.props

    if (prevItems !== items || prevBatchSize !== currBatchSize) {
      const { batchSize, visibleBatches } = this.normalizeInput({
        batchSize: currBatchSize,
        batchesNumber: this.state.visibleBatches,
        itemsNumber: items.length,
      })

      if (
        batchSize !== this.state.batchSize ||
        visibleBatches !== this.state.visibleBatches
      ) {
        this.setState({
          batchSize,
          visibleBatches,
        })
      }
    }
  }

  render() {
    const { items, children: renderItems } = this.props
    const { visibleBatches, batchSize } = this.state
    const visibleItems = items.slice(0, visibleBatches * batchSize)
    const isThereMoreItems = items.length > visibleItems.length
    const itemList = renderItems(visibleItems)

    return (
      <>
        {itemList}
        {isThereMoreItems && (
          <Spacing mt={1}>
            <Card>
              <RevealButton
                onClick={this.clickHandler}
                data-e2e-id="loadMore_button"
              >
                <Translation id="myLeaseplan.loadMore" />{' '}
                <ButtonChevronDownIcon />
              </RevealButton>
            </Card>
          </Spacing>
        )}
      </>
    )
  }
}
