import { ButtonLink } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'

interface ShadowBlockProps {
  isShadowVisible?: boolean
}

export const ShadowBlock = styled.div<ShadowBlockProps>`
  ${props =>
    props.isShadowVisible
      ? `&:before {
      content: '';
      display: block;
      z-index: 2;
      width: 100%;
      height: 2rem;
      bottom: 0;
      position: absolute;
      background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
    }`
      : `&:before {
      display: none;
    }`}
`

export interface Props {
  children?: React.ReactNode
  transitionTime?: {
    open: number
    close: number
  }
  easing?: string
  open?: boolean
  minHeight?: number
  viewMoreText?: React.ReactNode
  viewLessText?: React.ReactNode
}

type DefaultProps = Required<
  Pick<
    Props,
    | 'transitionTime'
    | 'easing'
    | 'open'
    | 'minHeight'
    | 'viewMoreText'
    | 'viewLessText'
  >
>
type InternalProps = Props & DefaultProps

export interface State {
  shouldOpenOnNextCycle?: boolean
  shouldSwitchToAutoOnNextCycle: boolean
  height: string | number
  isClosed: boolean
  transition: string
  overflow: any
  isDisabled?: boolean
}

export class ShowMore extends React.PureComponent<InternalProps, State> {
  innerContainer: HTMLElement
  outerContainer: HTMLElement

  static defaultProps: DefaultProps = {
    transitionTime: {
      open: 500,
      close: 500,
    },
    easing: 'ease-in-out',
    open: false,

    minHeight: 75,
    viewMoreText: (
      <>
        <Translation id="myLeaseplan.shared.viewMore.more" /> &gt;
      </>
    ),
    viewLessText: (
      <>
        &lt; <Translation id="myLeaseplan.shared.viewMore.less" />
      </>
    ),
  }

  state = {
    isClosed: !this.props.open,
    shouldSwitchToAutoOnNextCycle: false,
    height: this.props.open ? 'auto' : this.props.minHeight,
    transition: this.props.open
      ? 'none'
      : `height ${this.props.transitionTime.close}ms ${this.props.easing}`,
    overflow: 'hidden',
    isDisabled: false,
    shouldOpenOnNextCycle: false,
  }

  componentDidMount() {
    if (
      this.innerContainer &&
      this.innerContainer.offsetHeight > this.props.minHeight
    ) {
      this.setState({
        isDisabled: true,
      })
    } else {
      this.setState({
        height: 'auto',
      })
    }
  }

  componentDidUpdate(prevProps: InternalProps, prevState: State) {
    const { shouldOpenOnNextCycle, shouldSwitchToAutoOnNextCycle } = this.state

    if (shouldOpenOnNextCycle) {
      this.openContainer()
    }

    if (prevState.height === 'auto' && shouldSwitchToAutoOnNextCycle) {
      window.setTimeout(() => {
        // Set small timeout to ensure a true re-render
        this.setState({
          height: this.props.minHeight,
          overflow: 'hidden',
          isClosed: true,
          shouldSwitchToAutoOnNextCycle: false,
        })
      }, 50)
    }

    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        this.triggerContainerExpansion()
      } else {
        this.closeContainer()
      }
    }
  }

  closeContainer() {
    const { transitionTime, easing } = this.props
    const transition = `height ${
      transitionTime.close ? transitionTime.close : transitionTime.open
    }ms ${easing}`

    this.setState({
      transition,
      shouldSwitchToAutoOnNextCycle: true,
      height: this.innerContainer.offsetHeight,
    })
  }

  triggerContainerExpansion() {
    this.setState({
      shouldOpenOnNextCycle: true,
    })
  }

  openContainer = () => {
    this.setState({
      height: this.innerContainer.offsetHeight,
      transition: `height ${this.props.transitionTime.open}ms ${this.props.easing}`,
      isClosed: false,
      shouldOpenOnNextCycle: false,
    })
  }

  onViewMoreClick = (
    event: Pick<React.MouseEvent<HTMLButtonElement>, 'preventDefault'>,
  ) => {
    event.preventDefault()

    if (this.state.isClosed) {
      this.triggerContainerExpansion()
    } else {
      this.closeContainer()
    }
  }

  handleTransitionEnd = () => {
    if (!this.state.isClosed) {
      this.setState({
        height: 'auto',
        overflow: 'hidden',
      })
    }
  }

  render() {
    const { height, transition, overflow, isClosed, isDisabled } = this.state

    const { viewMoreText, viewLessText, children } = this.props

    const containerStyle = {
      height,
      overflow,
      transition,
      WebkitTransition: transition,
      msTransition: transition,
    }

    return (
      <div>
        <div
          className="view-more"
          ref={ref => (this.outerContainer = ref)}
          style={containerStyle}
          onTransitionEnd={this.handleTransitionEnd}
        >
          <ShadowBlock isShadowVisible={isDisabled && isClosed}>
            <div ref={(ref: HTMLElement) => (this.innerContainer = ref)}>
              {children}
            </div>
          </ShadowBlock>
        </div>
        {isDisabled && (
          <ButtonLink
            onClick={this.onViewMoreClick}
            color="steel80"
            fontWeight="regular"
            size="s"
            data-e2e-id="show-more-info"
          >
            {isClosed ? viewMoreText : viewLessText}
          </ButtonLink>
        )}
      </div>
    )
  }
}
