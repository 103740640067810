import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { FlowData } from 'mlp-client/src/flows/fuel-card/types'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { required } from 'mlp-client/src/form/validations'
import { getServiceTypes } from 'mlp-client/src/flows/fuel-card/utils'

export interface Props extends FlowProps<FlowData> {
  config: {
    serviceTypes: readonly string[]
  }
}

export type FormData = Pick<FlowData, 'fuelCardServiceType'>

export class SelectServiceTypeStep extends React.PureComponent<Props> {
  onSubmit = (formData: FormData) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(formData, goToNextStep)
  }

  render() {
    const {
      config: { serviceTypes },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel="myLeaseplan.fuelCard.steps.selectServiceType.subtitle"
          posterTitleLabel="myLeaseplan.fuelCard.steps.selectFuelCard.title"
          posterTextLabel="myLeaseplan.fuelCard.steps.selectServiceType.posterSubtitle"
          posterImgMedium="/static/images/my-leaseplan/fuel-card-flow-small.jpg"
          posterImgLarge="/static/images/my-leaseplan/fuel-card-flow-big.jpg"
          posterImgSmall="/static/images/my-leaseplan/mobile/fuel-card-mobile.png"
        >
          <Form onSubmit={this.onSubmit}>
            {({ handleSubmit }: FormRenderProps) => (
              <form onSubmit={handleSubmit}>
                <RadioClickAndGoList
                  name="fuelCardServiceType"
                  options={getServiceTypes(serviceTypes)}
                  validations={[required]}
                  onChange={handleSubmit}
                />
              </form>
            )}
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default SelectServiceTypeStep
