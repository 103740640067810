import React from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

interface Props {
  className?: string
  width: number
  height: number
  color: string
}

type DefaultProps = Required<Pick<Props, 'width' | 'height' | 'color'>>
type InternalProps = Props & DefaultProps

class EmptyMapMarkerIcon extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    width: 20,
    height: 20,
    color: ColorEnumSelector('midOrange'),
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 20 20"
        className={this.props.className}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="Garages_list-view"
            transform="translate(-15.000000, -306.000000)"
            fill={this.props.color}
          >
            <g id="Group" transform="translate(14.000000, 297.000000)">
              <g
                id="icons/24/petrol/location"
                transform="translate(0.000000, 9.000000)"
              >
                <g id="Group-10" transform="translate(0.500000, 0.000000)">
                  <path
                    d="M10.2635,19.1699167 C9.88266667,19.1699167 9.52766667,18.99825 9.29016667,18.69825 C9.15433333,18.5274167 8.97933333,18.31575 8.77683333,18.06825 C6.98183333,15.88575 3.24516667,11.3424167 3.24516667,7.63658333 C3.24516667,3.76741667 6.3935,0.61825 10.2635,0.61825 C14.1335,0.61825 17.2818333,3.76741667 17.2818333,7.63658333 C17.2818333,9.52325 16.3718333,11.7674167 14.4993333,14.49825 C14.2393333,14.8790833 13.7218333,14.9749167 13.341,14.7140833 C12.961,14.4540833 12.8643333,13.9349167 13.1251667,13.55575 C14.7776667,11.1449167 15.6151667,9.15325 15.6151667,7.63658333 C15.6151667,4.68575 13.2151667,2.28491667 10.2635,2.28491667 C7.31183333,2.28491667 4.91183333,4.68575 4.91183333,7.63658333 C4.91183333,10.7449167 8.52266667,15.1349167 10.0643333,17.0090833 C10.136,17.0974167 10.2043333,17.1799167 10.2693333,17.2590833 C10.4251667,17.0749167 10.616,16.8524167 10.8051667,16.63075 C11.126,16.2549167 11.4426667,15.8849167 11.5676667,15.7324167 C11.861,15.37825 12.386,15.3290833 12.741,15.6199167 C13.096,15.91325 13.146,16.43825 12.8535,16.79325 C12.7251667,16.94825 12.401,17.32825 12.0718333,17.71325 C11.7035,18.14575 11.3276667,18.5849167 11.2368333,18.69825 C10.9993333,18.99825 10.6451667,19.1699167 10.2635,19.1699167"
                    id="Fill-7"
                  />
                  <path
                    d="M10.2635,6.72366667 C9.64933333,6.72366667 9.15016667,7.22366667 9.15016667,7.837 C9.15016667,8.45116667 9.64933333,8.95116667 10.2635,8.95116667 C10.8776667,8.95116667 11.3768333,8.45116667 11.3768333,7.837 C11.3768333,7.22366667 10.8776667,6.72366667 10.2635,6.72366667 M10.2635,10.6178333 C8.731,10.6178333 7.4835,9.37033333 7.4835,7.837 C7.4835,6.30366667 8.731,5.057 10.2635,5.057 C11.7968333,5.057 13.0435,6.30366667 13.0435,7.837 C13.0435,9.37033333 11.7968333,10.6178333 10.2635,10.6178333"
                    id="Fill-9"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default EmptyMapMarkerIcon
