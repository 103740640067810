import { all } from 'redux-saga/effects'

import { default as cashClaim } from 'mlp-client/src/flows/cash-claim/sagas'
import { default as form } from 'mlp-client/src/form/components/fields/file-upload-field/sagas'
import { default as mileageUpdate } from 'mlp-client/src/flows/mileage-update/sagas'

import { default as attachCar } from './attach-car/sagas'
import { default as auth } from './auth/sagas'
import { default as complaints } from './complaints/sagas'
import { default as content } from './content/sagas'
import { default as contracts } from './contracts/sagas'
import { default as quotes } from './quotes/sagas'
import { default as damageReport } from './flows/damage-report/sagas'
import { default as FAQ } from './FAQ/sagas'
import { default as buy } from './flows/buy/sagas'
import { default as extendContract } from './flows/extend-contract/sagas'
import { default as fuelCard } from './flows/fuel-card/sagas'
import { default as fuelHistory } from './flows/fuel-history/sagas'
import { default as glassRepair } from './flows/glass-repair/sagas'
import { default as maintenance } from './flows/maintenance/sagas'
import { default as predictiveMaintenance } from './flows/predictive-maintenance/sagas'
import { default as returnFlow } from './flows/return/sagas'
import { default as tyres } from './flows/tyres/sagas'
import { default as vehicleDocuments } from './flows/vehicle-documents/sagas'
import { default as profile } from './profile/sagas'
import { default as requestForInformation } from './request-for-information/sagas'
import { default as timelineDetail } from './timeline-details/sagas'
import { default as timeline } from './timeline/sagas'
import { default as user } from './user/sagas'
import { default as init } from './init/sagas'
import { default as analytics } from './analytics/sagas'
import { default as localization } from './localization/sagas'
import { default as downloadableDocuments } from './downloadable-documents/sagas'

export const features = [
  ...attachCar,
  ...auth,
  ...buy,
  ...complaints,
  ...contracts,
  ...quotes,
  ...FAQ,
  ...fuelCard,
  ...fuelHistory,
  ...maintenance,
  ...profile,
  ...returnFlow,
  ...timeline,
  ...timelineDetail,
  ...tyres,
  ...user,
  ...mileageUpdate,
  ...vehicleDocuments,
  ...glassRepair,
  ...cashClaim,
  ...damageReport,
  ...requestForInformation,
  ...content,
  ...form,
  ...extendContract,
  ...predictiveMaintenance,
  ...init,
  ...analytics,
  ...localization,
  ...downloadableDocuments,
]

export default function* rootSaga() {
  yield all(features)
}
