import React from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'

import { FeatureConfiguration as AttachCarToDriverConfig } from 'mlp-client/src/attach-car/types'
import {
  RoadsideAssistanceConfig as RoadsideAssistanceConfiguration,
  RoadsideAssistanceConfigWithUrl,
} from 'mlp-client/src/components/road-side-assistance/types'
import { Config as DashboardConfiguration } from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import { FeatureConfiguration as EndOfLeaseConfiguration } from 'mlp-client/src/end-of-lease/types'
import { DistanceUnit } from 'mlp-client/src/enums'
import {
  FeatureStatus,
  NavigationStructure,
} from 'mlp-client/src/features-configuration/enums'
import {
  ContractCurrency,
  ContractsFeatureConfig as LpisContractsFeatureConfig,
  TimelineFeatureConfig,
} from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { BuyFlowConfiguration } from 'mlp-client/src/flows/buy/types'
import { ExtendContractFlowConfiguration } from 'mlp-client/src/flows/extend-contract/types'
import { FeatureConfiguration as FineHistoryConfiguration } from 'mlp-client/src/flows/fine-history/types'
import { FuelCardFlowConfiguration } from 'mlp-client/src/flows/fuel-card/components/flow/Flow'
import { FeatureConfiguration as FuelHistoryConfiguration } from 'mlp-client/src/flows/fuel-history/types'
import {
  GlassRepairFlowConfiguration,
  GlassRepairStaticPageConfiguration,
} from 'mlp-client/src/flows/glass-repair/types'
import { MaintenanceFlowConfiguration } from 'mlp-client/src/flows/maintenance/types'
import { PredictiveMaintenanceFeatureConfiguration as PredictiveMaintenanceConfig } from 'mlp-client/src/flows/predictive-maintenance/types'
import { ReturnFlowConfiguration } from 'mlp-client/src/flows/return/types'
import { Props as TyreServicesConfiguration } from 'mlp-client/src/flows/tyres/components/tyre-services/TyreServices'
import { TyresFlowConfiguration } from 'mlp-client/src/flows/tyres/types'
import { Options } from 'mlp-client/src/form/types'
import { ContactDetails } from 'mlp-client/src/order-status/types'
import { EditProfileConfig as EditProfileConfiguration } from 'mlp-client/src/profile/types'
import { AddressFormat, User } from 'mlp-client/src/user/types'
import {
  AdditionalServices,
  AdditionalServicesLabelReplacement,
} from 'mlp-client/src/flows/maintenance/enums'
import { MatchParams, NavigationItem } from 'mlp-client/src/types'
import {
  DamageReportFlowConfiguration,
  IframeSrc,
} from 'mlp-client/src/flows/damage-report/types'
import { Login } from 'mlp-client/src/components/login/types'
import { MultipleVehicleConfiguration } from 'mlp-client/src/components/vehicle-overview-tiles/types'
import { MileageUpdateFlowConfig } from 'mlp-client/src/flows/mileage-update/types'
import {
  ViewQuoteSections,
  ViewQuoteTabs,
  ContractSectionProperties,
  VehicleSectionProperties,
  DealerBrokerSectionProperties,
  VehiclePriceSectionProperties,
  IncludedServicesSectionProperties,
} from 'mlp-client/src/quotes/enums'

export enum ApiType {
  lpis = 'lpis',
}

export interface MyLeaseplanFeature<T> {
  enabled: boolean
  view?:
    | React.ComponentType<MyLeaseplanFeatureProps<T>>
    | string
    | React.ComponentType
  configuration?: T
}

interface ReturnFeature extends MyLeaseplanFeature<{}> {
  isCollectAndReturnEnabled?: boolean
  useBuyPublicPageInsteadOfFlow?: boolean
}

export type ReplaceLabels = {
  [P in AdditionalServices]?: AdditionalServicesLabelReplacement
}

interface TimelineFeature {
  roadsideAssistance?: boolean
}

export type AllowedServices = ReadonlyArray<
  'bookService' | 'fuelCard' | 'damage' | 'documents'
>

interface WelcomeScreenFeature extends MyLeaseplanFeature<{}> {
  allowedServices?: AllowedServices
}

interface VehicleDocumentsFeature extends MobileAppConfig<{}> {
  lpis?: {
    estimatedTimeOfDelivery: number
  }
}

export interface OrderStatus {
  showVehicleDetails: boolean
  showOrderNumber: boolean
  isBannerVisibleOnDashboard?: boolean
  firstWorkingDay?: number
  lastWorkingDay?: number
  openingHours?: number
  closingHours?: number
  contacts?: ContactDetails
  showOrders?: boolean
}

export type Navigation = {
  [key in NavigationStructure]: NavigationItem[]
}

export interface MobileAppConfig<T> extends MyLeaseplanFeature<T> {
  mobileApp?: {
    showNativeHeader?: boolean
    disabled?: boolean
  }
}

export interface CountryConfigList {
  [key: string]: MyleaseplanConfig
}

export interface SharedMyLeaseplanConfig {
  /**
   * The whole MyLP is enabled.
   */
  enabled: boolean

  /**
   * MyLP login button is enabled on the login page (e.g. `/de-de/login`).
   */
  loginEnabled: boolean
  /**
   * MyLP login page link is visible in the top navigation header.
   */
  accountLinkEnabled: boolean
  /**
   * MyLP My Account link is temporary introduced here to allow gradual countries activation on Standalone MyLP app
   * TODO remove when MyLP app is separated
   */
  myAccountLink?: string
  myAccountLinkOrigin?: string
}

export interface MyleaseplanConfig
  extends MyLeaseplanFeatures,
    SharedMyLeaseplanConfig {
  isPublicHolidaysDisabled?: FeatureStatus
  showPrivateAddressLabel?: boolean
  showCompanyAddress?: boolean
  policyDocuments?: boolean
  supplierAvailabilityTimeFrame?: number
  showSalutation?: boolean
  salutation?: Options
  minSupplierDistance?: number
  servicePhoneNumber?: string
  alternatePhoneDisplay?: boolean
  isInstallMobileAppEnabled?: boolean
  distanceUnit: DistanceUnit
  addressFormat?: AddressFormat
  apiType?: ApiType
  orderStatus?: OrderStatus
  navigation?: Navigation
  login?: Login
  profile?: {
    hideCountryCode?: boolean
  }
  isExternalLoginEnabled?: boolean
  showSupplierNotFound?: boolean
  noContractAllowedLocale?: boolean
}

export interface MyLeaseplanFeatures {
  attachCarToDriver?: MyLeaseplanFeature<AttachCarToDriverConfig>
  timeline?: MyLeaseplanFeature<TimeLineConfig>
  maintenance?: MyLeaseplanFeature<MaintenanceFlowConfiguration>
  damageReport?: DamageReportFeature<DamageReportConfig>
  damageRepair?: MyLeaseplanFeature<{}>
  damageAssessment?: MyLeaseplanFeature<{}>
  fuelCard?: MyLeaseplanFeature<FuelCardConfig>
  cashClaims?: MobileAppConfig<{}>
  vehicleDocuments?: VehicleDocumentsFeature
  roadsideAssistance?: MyLeaseplanFeature<RoadsideAssistanceConfig>
  glassRepair?: GlassRepairConfig
  tyres?: TyresConfig
  serviceCard?: MyLeaseplanFeature<{}>
  contracts?: MyLeaseplanFeature<LpisContractsFeatureConfig>
  mileageUpdate?: MyLeaseplanFeature<MileageUpdateFlowConfig>
  users?: MyLeaseplanFeature<{}>
  return?: ReturnConfig
  complaints?: MyLeaseplanFeature<{}>
  requestForInformation?: MyLeaseplanFeature<{}>
  buy?: MyLeaseplanFeature<BuyFlowConfiguration>
  supportsMultipleVehicles?: MyLeaseplanFeature<MultipleVehicleConfiguration>
  vehicleDocumentsPage?: MobileAppConfig<{}>
  welcomeScreen?: WelcomeScreenFeature
  editProfile?: MyLeaseplanFeature<EditProfileConfiguration>
  fuelHistory?: MyLeaseplanFeature<FuelHistoryConfiguration>
  fines?: MyLeaseplanFeature<FineHistoryConfiguration>
  endOfLease?: MyLeaseplanFeature<EndOfLeaseConfig>
  extendContract?: MyLeaseplanFeature<ExtendContractFlowConfiguration>
  predictiveMaintenance?: MyLeaseplanFeature<PredictiveMaintenanceConfig>
  dashboard?: MyLeaseplanFeature<DashboardConfiguration>
  quotes?: MyLeaseplanFeature<QuotesFeatureConfig>
}

export type MyLeaseplanFeatureName = keyof MyLeaseplanFeatures

interface LocationState {
  returnTo?: string
}

export interface MyLeaseplanFeatureProps<T = {}>
  extends Partial<
    RouteComponentProps<MatchParams, StaticContext, LocationState>
  > {
  feature: MyLeaseplanFeatureName
  featureConfig: MyLeaseplanFeature<T>
  view?:
    | React.ComponentType<MyLeaseplanFeatureProps<T>>
    | string
    | React.ComponentType
  user?: User
}

export interface MyLeaseplanFeaturePropsWithRouteComponentProps<T = {}>
  extends RouteComponentProps {
  feature: MyLeaseplanFeatureName
  featureConfig: MyLeaseplanFeature<T>
  view?:
    | React.ComponentType<MyLeaseplanFeatureProps<T>>
    | string
    | React.ComponentType
}

export interface DamageReportFeature<T> extends MobileAppConfig<T> {
  tileRoute?: string
  onePartyIframeSrc?: string
}

export interface QuotesFeatureConfig {
  tabs: TabsList
  policyDocuments?: boolean
  contractStatusBlock?: boolean
  currency?: ContractCurrency
}

export interface IframePageConfig {
  title: string
  breadcrumbTitle: string
  iframeSrc?: IframeSrc
  damageRepairIframeSrc?: IframeSrc
}

export type FuelCardConfig = FuelCardFlowConfiguration | {}
export type TimeLineConfig = TimelineFeature | TimelineFeatureConfig

export type GlassRepairConfig =
  | MobileAppConfig<{}>
  | MyLeaseplanFeature<
      GlassRepairStaticPageConfiguration | GlassRepairFlowConfiguration
    >

export type TyresConfig =
  | MobileAppConfig<{} | TyreServicesConfiguration>
  | MyLeaseplanFeature<TyresFlowConfiguration>

export type RoadsideAssistanceConfig =
  | RoadsideAssistanceConfiguration
  | RoadsideAssistanceConfigWithUrl
  | {}

export type ReturnConfig =
  | ReturnFeature
  | MyLeaseplanFeature<ReturnFlowConfiguration>

export type EndOfLeaseConfig = EndOfLeaseConfiguration | {}

export type DamageReportConfig =
  | DamageReportFlowConfiguration
  | IframePageConfig

export type SectionProperty =
  | DealerBrokerSectionProperties
  | VehicleSectionProperties
  | ContractSectionProperties
  | VehiclePriceSectionProperties
  | IncludedServicesSectionProperties

export type SectionPropertyList = readonly SectionProperty[]

export type Tab = {
  [key in ViewQuoteSections]?: SectionPropertyList
}

export type TabsList = {
  [key in ViewQuoteTabs]?: Tab
}
