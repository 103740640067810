import Dashboard, {
  Config,
} from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  DamageReportTile,
  FuelCardTile,
  MaintenanceTile,
  ReturnTile,
} from 'mlp-client/src/dashboard/components/tiles'
import { MileageUpdateModalTrigger } from 'mlp-client/src/dashboard/components/MileageUpdateModalTrigger'
import { RoadSideAssistanceWithPhoneNumbers } from 'mlp-client/src/components/road-side-assistance/components'
import { TileOptions } from 'mlp-client/src/dashboard/type'
import { isFuelManagement } from 'mlp-client/src/features-configuration/lu/utils'
import { Contract } from 'mlp-client/src/contracts/types'
import IconSelector from 'mlp-client/src/dashboard/components/icons/IconSelector'

export const dashboardConfig: MyLeaseplanFeature<Config> = {
  enabled: true,
  view: Dashboard,
  configuration: {
    alwaysDisplayFuelCardTile: false,
    numberOfDaysToReturn: 90,
    showNolsWarning: true,
    servicesConfig: (contract: Contract) => {
      if (isFuelManagement(contract?.type)) {
        return []
      }

      const features: TileOptions = [
        {
          view: MaintenanceTile,
          title: 'myLeaseplan.serviceRequest.maintenance.dashboard.title',
          summary: 'myLeaseplan.serviceRequest.maintenance.dashboard.summary',
          disabledTitle:
            'myLeaseplan.serviceRequest.maintenance.dashboard.notAvailableTitle',
          disabledSummary:
            'myLeaseplan.serviceRequest.maintenance.dashboard.notAvailableSummary',
          to: 'myLeaseplan.maintenance.base',
          icon: IconSelector.maintenance,
          name: 'maintenance',
        },
        {
          view: DamageReportTile,
          name: 'damageReport',
          title: 'myLeaseplan.damageReport.dashboard.title',
          summary: 'myLeaseplan.damageReport.dashboard.summary',
          icon: IconSelector.damage,
          to: 'myLeaseplan.damageReport.damageList',
        },
        {
          name: 'tyres',
          title: 'myLeaseplan.tyres.dashboard.title',
          summary: 'myLeaseplan.tyres.dashboard.summary',
          to: 'myLeaseplan.tyres.base',
          icon: IconSelector.tyre,
          shouldShow: contract.tyres?.contractTyresModule,
        },
        {
          title: 'myLeaseplan.glassRepair.dashboard.title',
          summary: 'myLeaseplan.glassRepair.dashboard.summary',
          to: 'myLeaseplan.glassRepair.base',
          icon: IconSelector.glass,
          name: 'glassRepair',
        },
      ]

      const filteredTiles = features.filter(
        f => f.shouldShow || !f.hasOwnProperty('shouldShow'),
      )

      return filteredTiles
    },
    quickActionsConfig: ({ type }): TileOptions => {
      const config: TileOptions = [
        {
          modalTrigger: MileageUpdateModalTrigger,
          hasModal: true,
          title: 'myLeaseplan.mileageUpdate.dashboard.title',
          summary: 'myLeaseplan.mileageUpdate.dashboard.summary',
          icon: IconSelector.mileage,
          name: 'mileageUpdate',
          to: 'myLeaseplan.mileageUpdate.base',
        },
        {
          view: ReturnTile,
          summary: 'myLeaseplan.return.dashboard.summary',
          to: 'myLeaseplan.endOfLease.base',
          icon: IconSelector.return,
          name: 'return',
        },
        {
          name: 'cashClaims',
          title: 'myLeaseplan.cashClaim.dashboard.title',
          summary: 'myLeaseplan.cashClaim.dashboard.summary',
          to: 'myLeaseplan.cashClaim.base',
          icon: IconSelector.cashClaim,
        },
        {
          view: FuelCardTile,
          name: 'fuelCard',
          title: 'myLeaseplan.fuelCard.dashboard.title',
          summary: 'myLeaseplan.fuelCard.dashboard.summary',
          to: 'myLeaseplan.fuelCard.base',
          icon: IconSelector.fuelCard,
        },
        {
          name: 'vehicleDocuments',
          title: 'myLeaseplan.vehicleDocuments.dashboard.title',
          summary: 'myLeaseplan.vehicleDocuments.dashboard.summary',
          to: 'myLeaseplan.vehicleDocuments.selectDocument',
          icon: IconSelector.documents,
        },
        {
          name: 'fines',
          title: 'myLeaseplan.fines.dashboard.title',
          summary: 'myLeaseplan.fines.dashboard.summary',
          to: 'myLeaseplan.fines.base',
          icon: IconSelector.fines,
        },
        {
          name: 'fuelHistory',
          title: 'myLeaseplan.fuelHistory.dashboard.title',
          summary: 'myLeaseplan.fuelHistory.dashboard.summary',
          to: 'myLeaseplan.fuelHistory.base',
          icon: IconSelector.fuelHistory,
        },
      ]

      if (isFuelManagement(type)) {
        const availableTiles = ['cashClaims', 'fuelCard', 'fuelHistory']

        return config.filter(item => availableTiles.includes(item.name))
      }

      return config
    },
    roadsideAssistanceConfig: ({ type }) =>
      !isFuelManagement(type) && {
        enabled: true,
        view: RoadSideAssistanceWithPhoneNumbers,
        configuration: {
          primaryNumber: 'myLeaseplan.dashboard.roadsideAssistance.phone',
          primaryNumberIntro:
            'myLeaseplan.dashboard.roadsideAssistance.subtitle',
        },
      },
  },
}
