import { Weekday } from 'mlp-client/src/enums'
import { CONTACT_US_PHONE_NUMBER } from 'mlp-client/src/features-configuration/dk/data'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import FuelCardsFlow, {
  FuelCardFlowConfiguration,
} from 'mlp-client/src/flows/fuel-card/components/flow/Flow'
import {
  LostAndStolenStep,
  SelectFuelCardStep,
  SelectServiceTypeWithSuppliersStep,
  ThankYouStep,
} from 'mlp-client/src/flows/fuel-card/components/steps'
import {
  FuelcardServiceType,
  StepKey,
} from 'mlp-client/src/flows/fuel-card/types'
import FuelCardsIcon from 'mlp-client/src/components/icons/fuel-card-icon/FuelCardIcon'

import { getFuelCardsSteps } from './utils'

const allSteps = {
  [StepKey.SELECT_FUEL_CARD]: {
    stepKey: StepKey.SELECT_FUEL_CARD,
    stepView: SelectFuelCardStep,
    stepConfig: {
      phoneNumber: CONTACT_US_PHONE_NUMBER,
    },
  },
  [StepKey.ALL_LOST_AND_STOLEN_CONFIRM]: {
    stepKey: StepKey.ALL_LOST_AND_STOLEN_CONFIRM,
    stepView: LostAndStolenStep,
    stepConfig: {
      confirmLabel: 'myLeaseplan.shared.confirm',
      infoLabel: 'myLeaseplan.fuelCard.steps.selectServiceType.allStolenInfo',
    },
  },
  [StepKey.SELECT_SERVICE_TYPE]: {
    stepKey: StepKey.SELECT_SERVICE_TYPE,
    stepView: SelectServiceTypeWithSuppliersStep,
    stepConfig: {
      serviceTypes: [
        FuelcardServiceType.FORGOT_PIN,
        FuelcardServiceType.LOST_STOLEN,
        FuelcardServiceType.DAMAGED,
      ],
    },
  },
  [StepKey.THANK_YOU]: {
    stepKey: StepKey.THANK_YOU,
    stepView: ThankYouStep,
    stepConfig: {
      hideCashClaim: true,
      isSupportVisible: false,
      isSupportInNonMalfunctionVisible: true,
      isTimelineVisible: false,
      isTimelineIfMalfunctionVisible: true,
      isBlockCardLabelVisible: true,
      deliveryPeriods: {
        daysForPinRestore: 10,
        daysForCardDelivery: 10,
      },
    },
  },
}

export const fuelCardsConfig: MyLeaseplanFeature<FuelCardFlowConfiguration> = {
  enabled: true,
  view: FuelCardsFlow,
  configuration: {
    allSteps,
    getSteps: getFuelCardsSteps,
    sharedConfig: {
      suppliers: [],
      callUsTile: {
        title:
          'myLeaseplan.fuelCard.steps.selectServiceType.lostStolenNew.title',
        subtitle:
          'myLeaseplan.fuelCard.steps.selectServiceType.lostStolenNew.subtitle',
        logo: FuelCardsIcon,
        contacts: [
          {
            phoneNumber: CONTACT_US_PHONE_NUMBER,
            subtitle:
              'myLeaseplan.fuelCard.steps.selectServiceType.lostStolenNew.workingHours',
            workingHours: [
              {
                startDay: Weekday.Monday,
                endDay: Weekday.Thursday,
                startHour: '08:30',
                endHour: '16:30',
              },
              {
                startDay: Weekday.Friday,
                startHour: '08:30',
                endHour: '16:00',
              },
            ],
          },
          {
            phoneNumber:
              'myLeaseplan.fuelCard.steps.selectServiceType.lostStolenNew.outsideWorkingHoursPhone',
            subtitle:
              'myLeaseplan.fuelCard.steps.selectServiceType.lostStolenNew.outsideWorkingHours',
          },
        ],
      },
    },
  },
}
