import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { AppState } from 'mlp-client/src/types'

import { getLocalizedRouteFromState } from './selectors'
import { isLocalizedRouteIdentifier } from './utils'

export interface Props {
  to: string
  params?: string | GenericObject
  toRoute: string
}

export const TranslatedRedirect: React.FC<Props> = ({ toRoute }: Props) => {
  if (!toRoute) {
    return null
  }

  return <Redirect to={toRoute} />
}

TranslatedRedirect.displayName = 'TranslatedRedirect'

type ReduxProps = Pick<Props, 'toRoute'>
type OwnProps = Omit<Props, keyof ReduxProps>

const mapStateToProps = (state: AppState, props: OwnProps): ReduxProps => ({
  toRoute: isLocalizedRouteIdentifier(props.to)
    ? getLocalizedRouteFromState(state, props)
    : props.to,
})

export default connect(mapStateToProps)(TranslatedRedirect)
