export {
  formatAddress,
  formatAddressCard,
  formatAddressLine,
  getAddressModel,
  isAddressValid,
} from './address/address'

export {
  isDesktopBreakpoint,
  isMobile,
  isMobileBreakpoint,
  isObject,
  isTabletBreakpoint,
  isExternal,
  isJavascript,
  isMail,
  isPhone,
} from './checks/checks'

export {
  replaceContent,
  isStringReplacementsArray,
  replaceContentString,
  replaceContentStringByEntries,
} from './content/content'
export type {
  Replacement,
  ReplacementItem,
  Replacements,
  StringReplacement,
  StringReplacementItem,
  StringReplacements,
} from './content/content'

export {
  getNextWorkingDate,
  formatDate,
  formatRelativeDate,
  getCountryHolidays,
  isWorkingDay,
  mapHolidaysDate,
} from './date/date'

export { saveFile } from './file/file'

export {
  formatNumber,
  formatPersonName,
  formatTelephoneHref,
  getFormName,
  getCurrenciesOptions,
  objectValuesToArray,
  transformUrlV2,
  makeTranslationPath,
  trimZerosCountryCode,
  formatPhoneNumber,
  formatBrand,
} from './formatters/formatters'

export { isLicensePlateValid } from './license-plate/license-plate'

export {
  getSCLanguageCode,
  isContentLangUnmatchesLocal,
  parseLocale,
} from './locale/locale'

export { until, from } from './media-query/mq'

export { scroller, calculateOffset } from './scroller/scroller'

export {
  merge,
  slicer,
} from './redux-session-persistence/provisionalReduxSessionPersistence'
