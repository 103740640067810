import { mq, Spacing, SpacingProps } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import { MessageBar } from '@velocity/ui/draft'
import React from 'react'
import styled from 'styled-components'
import { Form, FormRenderProps } from 'react-final-form'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import MileageIcon from 'mlp-client/src/components/icons/mileage-icon/MileageIcon'
import { SubmitLastMileage } from 'mlp-client/src/flows/mileage-update/actions'
import { makeTranslationPath } from 'mlp-client/src/utils'
import MileageField from 'mlp-client/src/form/components/fields/mileage-field/Mileage'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import { FormSubmission } from 'mlp-client/src/form/types'
import Loader from 'mlp-client/src/components/loader/Loader'
import FileUpload from 'mlp-client/src/form/components/fields/file-upload-field/FileUploadField'
import { filesRequiredAndUploaded } from 'mlp-client/src/form/validations'
import { Attachments } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'
import { MileageIconLeasys } from 'mlp-client/src/dashboard/components/icons'

const StyledWrapper = styled(Spacing).attrs<SpacingProps>({
  pt: { mobile: 2, mobileLandscape: 3 },
  ph: { mobile: 2, mobileLandscape: 8 },
  pb: { mobile: 2, mobileLandscape: 4 },
})`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${mq.from('mobileLandscape')`
    width: 540px;
    max-width: 100vw;
    min-height: 440px;
  `};
`

const translatePath = makeTranslationPath(
  'myLeaseplan.mileageUpdate.steps.enterCarMileage',
)

const selectIcon = () => {
  if (!isLeaseplan()) {
    return (
      <MileageIconLeasys
        width={'100'}
        height={'100'}
        color={ColorEnumSelector('midOrange')}
      />
    )
  }

  return <MileageIcon width={100} height={100} />
}

export interface FormValues {
  mileage: number
  contractId: string
  photoMileage?: Attachments
}

export interface Props extends FormSubmission<FormValues> {
  contractId: string
  lastKnownMileage?: number
  photoMileage?: Attachments
  renderSuccess?(mileage: number): React.ReactNode
  labels?: {
    formHeading?: string
    formText?: string
    noteText?: string
    submitButton?: string
  }
}

interface State {
  submittedMileage?: number
}

class MileageUpdate extends React.PureComponent<Props, State> {
  state: State = {}

  onSubmit: FormSubmission<FormValues>['onSubmit'] = (
    formData: FormValues,
    formApi,
    onComplete,
  ) => {
    this.setState({ submittedMileage: Number(formData.mileage) })
    this.props.onSubmit(formData, formApi, onComplete)
  }

  lastKnownMileageRender = (
    formattedMileage: string | undefined,
  ): React.ReactElement =>
    formattedMileage && (
      <Text component="p" variant="100">
        {formattedMileage}
      </Text>
    )

  renderSuccess(): React.ReactElement {
    const { renderSuccess } = this.props

    if (typeof renderSuccess === 'function') {
      return (
        <StyledWrapper>
          {renderSuccess(this.state.submittedMileage)}
        </StyledWrapper>
      )
    }

    return (
      <div data-e2e-id="mileageUpdateThankYouModal">
        <StyledWrapper>
          <div>
            <Headline variant="300" component="h3" withMarginBottom>
              <Translation id="myLeaseplan.mileageUpdate.steps.thankYou.title" />
            </Headline>
            <Text component="p" variant="200">
              <Translation id="myLeaseplan.mileageUpdate.steps.thankYou.subtitle" />
            </Text>
          </div>
        </StyledWrapper>
      </div>
    )
  }

  renderLoading(): React.ReactElement {
    return (
      <StyledWrapper>
        <Loader fixed={true} loading={true} data-test-id="Loader" />
      </StyledWrapper>
    )
  }

  renderFailure(): React.ReactElement {
    return (
      <SMessageBarContainer>
        <MessageBar variant="alert">
          <MessageBar.Text>
            <Translation id="myLeaseplan.shared.formSubmitFailedMessage" />
          </MessageBar.Text>
        </MessageBar>
      </SMessageBarContainer>
    )
  }

  render() {
    const {
      contractId,
      lastKnownMileage,
      photoMileage = [],
      labels: {
        formHeading = translatePath('title'),
        formText = translatePath('text'),
        noteText = translatePath('note'),
        submitButton = translatePath('nextButton'),
      } = {},
    } = this.props

    if (!contractId) {
      return <StyledWrapper>{this.renderFailure()}</StyledWrapper>
    }

    return (
      <Form<FormValues>
        onSubmit={this.onSubmit}
        initialValues={{ contractId, photoMileage }}
        keepDirtyOnReinitialize={true}
      >
        {({
          handleSubmit,
          hasValidationErrors,
          submitFailed,
          submitting,
          submitSucceeded,
          values,
        }: FormRenderProps) => {
          if (submitting) {
            return this.renderLoading()
          }

          if (submitSucceeded) {
            return this.renderSuccess()
          }

          return (
            <StyledWrapper>
              <form onSubmit={handleSubmit} data-e2e-id="mileageUpdate_modal">
                <Spacing mb={1}>{selectIcon()}</Spacing>
                <Headline variant="300" component="h3" withMarginBottom>
                  <Translation id={formHeading} />
                </Headline>
                {submitFailed && this.renderFailure()}
                {formText && (
                  <Spacing mb={2}>
                    <Text component="p" variant="200">
                      <Translation id={formText} />
                    </Text>
                  </Spacing>
                )}
                <MileageField
                  lastKnownMileage={lastKnownMileage}
                  allowOnlyIntegers={true}
                  lastKnownMileageRender={this.lastKnownMileageRender}
                  noMargin={true}
                  variant="secondary"
                  required={true}
                />
                <MyLeaseplanContext.Consumer>
                  {context => (
                    <>
                      {context.mileageUpdate?.configuration?.showModalNote &&
                        noteText && (
                          <Spacing mt={1}>
                            <Text component="p" variant="100">
                              <Translation id={noteText} />
                            </Text>
                          </Spacing>
                        )}
                      {context.mileageUpdate?.configuration?.uploadImage && (
                        <Spacing mt={2}>
                          <Spacing mb={0.5}>
                            <Text bold>
                              <Translation id="myLeaseplan.updateMileage.uploadPicture.label" />
                            </Text>
                          </Spacing>
                          <FileUpload
                            name="photoMileage"
                            validations={[filesRequiredAndUploaded]}
                            accept="image/*"
                            disabled={values.photoMileage?.length > 0}
                            multiple={false}
                          />
                        </Spacing>
                      )}
                    </>
                  )}
                </MyLeaseplanContext.Consumer>
                <Spacing mb={2} />
                <SubmitButton
                  stretch={false}
                  dataE2eId="button-next-step"
                  label={submitButton}
                  onClick={handleSubmit}
                  disabled={hasValidationErrors || submitting}
                />
              </form>
            </StyledWrapper>
          )
        }}
      </Form>
    )
  }
}

export type DispatchProps = Pick<Props, 'onSubmit'>

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (formData, formApi, onComplete) => {
    dispatch(
      new SubmitLastMileage({
        mileage: formData.mileage,
        contractId: formData.contractId,
        mileageDate: new Date(),
        attachmentKeys: formData.photoMileage.map(
          attachments => attachments.key,
        ),
        onComplete,
      }),
    )
  },
})

export { MileageUpdate }
export default connect(null, mapDispatchToProps)(MileageUpdate)
