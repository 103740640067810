import { GridItem, Spacing } from '@leaseplan/ui'
import styled from 'styled-components'

export const CenteredGridItem = styled(GridItem)`
  text-align: center;
`

export const CenteredSpacing = styled(Spacing)`
  text-align: center;
`
