import { Color, IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'

class DriversCheckIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 36 36"
      >
        <path
          fill={Color[color]}
          fillRule="evenodd"
          d="M19.4 7.92a.85.85 0 0 1 .847.852.85.85 0 0 1-.847.852H4.851a2.166 2.166 0 0 0-2.157 2.17v12.944c0 1.196.969 2.17 2.157 2.17h25.71a2.166 2.166 0 0 0 2.157-2.17v-5.904c0-.471.38-.852.847-.852a.85.85 0 0 1 .847.852v5.904c0 2.135-1.728 3.873-3.851 3.873H4.851C2.73 28.611 1 26.873 1 24.738V11.794c0-2.136 1.729-3.873 3.851-3.873zm-3.748 3.506a.85.85 0 0 1 .847.852V24.27a.85.85 0 0 1-.847.852H5.991a.849.849 0 0 1-.847-.852V12.278c0-.47.379-.852.847-.852zm-2.616 8.2c-.58.846-1.44 1.085-2.214 1.085-.77 0-1.623-.236-2.204-1.068-.73.211-1.328.543-1.78.99v2.785h7.967v-2.816c-.452-.443-1.045-.772-1.769-.977zm16.199 2.01c.468 0 .847.38.847.851 0 .47-.379.852-.847.852H19.004a.849.849 0 0 1-.847-.852c0-.47.379-.852.847-.852zM27.805 5C31.772 5 35 8.246 35 12.236c0 3.99-3.228 7.236-7.195 7.236a7.141 7.141 0 0 1-2.194-.346h-6.607a.849.849 0 0 1-.847-.852c0-.47.379-.852.847-.852h3.793a7.253 7.253 0 0 1-1.672-2.51h-2.121a.849.849 0 0 1-.847-.851c0-.471.379-.852.847-.852h1.678a7.276 7.276 0 0 1-.073-.973C20.61 8.246 23.838 5 27.805 5zM10.822 15.399c-.824 0-1.2.727-1.2 1.402 0 2.054.676 2.195 1.108 2.205h.183c.432-.01 1.108-.151 1.108-2.205 0-.675-.376-1.402-1.2-1.402zm3.983-2.27H6.838v5.406a6.42 6.42 0 0 1 1.188-.486 7.523 7.523 0 0 1-.098-1.248c0-1.77 1.244-3.106 2.894-3.106 1.649 0 2.893 1.335 2.893 3.106 0 .461-.036.865-.095 1.228.425.128.823.287 1.185.483V13.13zm13-6.425c-3.034 0-5.502 2.481-5.502 5.532 0 3.05 2.468 5.532 5.502 5.532 3.033 0 5.5-2.481 5.5-5.532 0-3.05-2.467-5.532-5.5-5.532zm-2.68 6.03a.85.85 0 0 0-1.207 0 .862.862 0 0 0 0 1.214l2.008 2.019a.85.85 0 0 0 1.207 0l5.02-5.048a.862.862 0 0 0 0-1.213.85.85 0 0 0-1.207 0l-4.417 4.44-1.404-1.412z"
        />
      </svg>
    )
  }
}

export default DriversCheckIcon
