import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import {
  getTranslation,
  getLanguage,
} from 'mlp-client/src/localization/selectors'
import CarRepairDarkThemedIcon from 'mlp-client/src/components/icons/car-repair-dark-themed-icon/CarRepairDarkThemedIcon'
import TermsAndConditionsPreLogin from 'mlp-client/src/components/TermsAndConditionsPreLogin'
import { AppState } from 'mlp-client/src/types'
import TopNavigation from 'mlp-client/src/components/layout/top-navigation/TopNavigation'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { Login as LoginAction } from 'mlp-client/src/auth/actions'
import { isFeatureEnabled } from 'mlp-client/src/selectors'

import {
  LoginNavigationSection,
  LoginInformationSection,
  TermsAndConditionsFooter,
  LoginSideBar,
  LoginSideBarBody,
} from './Login.styled'
import LoginPageList from './LoginPageList'

interface Props {
  dispatch: Dispatch<Action>
  languageCode: string
  metaDescription: string
  metaTitle: string
  isExternalLoginEnabled: boolean
}

class Login extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.isExternalLoginEnabled) {
      this.props.dispatch(new LoginAction())
    }
  }

  render() {
    const { metaTitle, metaDescription, isExternalLoginEnabled } = this.props

    return isExternalLoginEnabled ? null : (
      <MyLeaseplanContext.Consumer>
        {({ login }) => (
          <div>
            <Helmet
              title={metaTitle}
              meta={[{ name: 'description', content: metaDescription }]}
            />
            <TopNavigation navigationStructure={[]} hideLoginLink={true} />
            <div className="o-layout o-layout--v-stretch o-layout--v-stretch-minnav o-layout--flush u-bg--white">
              <LoginSideBar className="o-layout__item o-layout__item--center u-1/1 u-1/3@lap u-1/2@tablet">
                <LoginNavigationSection>
                  <LoginSideBarBody>
                    <div>
                      <LoginPageList login={login} />
                    </div>
                    <TermsAndConditionsFooter>
                      <TermsAndConditionsPreLogin />
                    </TermsAndConditionsFooter>
                  </LoginSideBarBody>
                </LoginNavigationSection>
              </LoginSideBar>
              <div className="o-layout__item o-layout__item--v-center o-layout__item--center u-2/3@lap u-1/2@tablet u-bg--sand u-hide-until@tablet">
                <LoginInformationSection className="o-position--h-center u-2/3">
                  <Translation
                    element="h4"
                    className="u-heading--petrol-blue u-h4 u-margin-bottom-medium"
                    id="myLeaseplan.introduction.faqProposal"
                  />
                  <CarRepairDarkThemedIcon />
                  {login.hideFaqProposalSubLabel ? null : (
                    <Translation
                      element="p"
                      className="u-margin-top-small u-txt--16 u-txt--petrol-blue"
                      id="myLeaseplan.introduction.faqProposalSub"
                    />
                  )}
                </LoginInformationSection>
              </div>
            </div>
          </div>
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'languageCode' | 'metaDescription' | 'metaTitle' | 'isExternalLoginEnabled'
>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  metaTitle: getTranslation(state, 'myLeaseplan.login.title', ''),
  metaDescription: getTranslation(state, 'myLeaseplan.login.subtitle', ''),
  languageCode: getLanguage(state),
  isExternalLoginEnabled:
    isFeatureEnabled(state) &&
    isFeatureEnabled(state, 'isExternalLoginEnabled'),
})

export { Login }
export default connect(mapStateToProps)(Login)
