import { Action, ActionTypes } from 'mlp-client/src/contracts/actions'
import * as actions from 'mlp-client/src/flows/fuel-card/actions'
import { FuelCards, FuelCardTypes } from 'mlp-client/src/flows/fuel-card/types'

export interface State {
  fuelCardTypes: {
    data: FuelCardTypes
    error: boolean
    loading: boolean
  }
  fuelCards: {
    data: FuelCards
    isLoaded: boolean
    loading: boolean
  }
}

export const defaultState: State = {
  fuelCardTypes: {
    data: [],
    error: false,
    loading: false,
  },
  fuelCards: {
    data: [],
    isLoaded: false,
    loading: false,
  },
}

export default (
  state: State = defaultState,
  action: actions.Action | Action,
): State => {
  switch (action.type) {
    case actions.ActionTypes.LOAD_FUEL_CARDS: {
      return {
        ...state,
        fuelCards: {
          ...state.fuelCards,
          isLoaded: false,
          loading: true,
        },
      }
    }

    case actions.ActionTypes.GET_FUEL_CARD_TYPES_REQUEST: {
      return {
        ...state,
        fuelCardTypes: {
          data: [],
          loading: true,
          error: false,
        },
      }
    }

    case actions.ActionTypes.GET_FUEL_CARD_TYPES_SUCCESS:
      return {
        ...state,
        fuelCardTypes: {
          loading: false,
          error: false,
          data: action.payload.data,
        },
      }
    case actions.ActionTypes.GET_FUEL_CARD_TYPES_FAIL:
      return {
        ...state,
        fuelCardTypes: {
          ...defaultState.fuelCardTypes,
          error: true,
        },
      }
    case actions.ActionTypes.LOAD_FUEL_CARDS_SUCCESS:
      return {
        ...state,
        fuelCards: {
          isLoaded: true,
          loading: false,
          data: action.payload.fuelCards,
        },
      }
    case actions.ActionTypes.LOAD_FUEL_CARDS_FAIL:
      return {
        ...state,
        fuelCards: {
          data: [],
          isLoaded: true,
          loading: false,
        },
      }
    case ActionTypes.SET_CURRENT_CONTRACT:
      return {
        ...state,
        fuelCards: defaultState.fuelCards,
      }
    default:
      return state
  }
}
