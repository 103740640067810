import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import {
  ExtraInformationFieldgroup,
  FormValues as ExtraInformationFormValues,
} from 'mlp-client/src/flows/return/components/extra-information-field-group/ExtraInformationFieldgroup'
import { getShippingAddress } from 'mlp-client/src/flows/return/selectors'
import { FlowData, StepViewProps } from 'mlp-client/src/flows/return/types'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import { Options } from 'mlp-client/src/form/types'
import { required } from 'mlp-client/src/form/validations'
import { AppState } from 'mlp-client/src/types'

export interface Props extends StepViewProps<{}> {
  shippingAddressOptions: Options
}

export type FormValues = Pick<FlowData, 'shippingAddress'> &
  ExtraInformationFormValues

class AdditionalInformationStep extends React.PureComponent<Props> {
  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        shippingAddress: values.shippingAddress,
        extraDimensions: values.extraDimensions,
        message: values.message,
      },
      goToNextStep,
    )
  }
  render() {
    const { shippingAddressOptions, flowData } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel="myLeaseplan.genericNotifications.AdditionalInfo"
          marginTitle={2}
        >
          <Form
            onSubmit={this.onSubmit}
            initialValues={{
              shippingAddress: flowData.shippingAddress,
              extraDimensions: flowData.extraDimensions,
              message: flowData.message,
            }}
            render={({ handleSubmit, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <Spacing mb={2}>
                  <SelectField
                    name="shippingAddress"
                    validations={[required]}
                    options={shippingAddressOptions}
                    label={{
                      id: 'myLeaseplan.return.steps.howReturnNew.returnAddress',
                    }}
                  />
                </Spacing>
                <Spacing mb={2}>
                  <ExtraInformationFieldgroup />
                </Spacing>
                <NextStepButton
                  disabled={hasValidationErrors}
                  onClick={handleSubmit}
                />
              </form>
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}
type ReduxProps = Pick<Props, 'shippingAddressOptions'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  shippingAddressOptions: getShippingAddress(state),
})

export { AdditionalInformationStep }
export default connect(mapStateToProps)(AdditionalInformationStep)
