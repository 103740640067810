import { CountrySpecific } from 'mlp-client/src/config/types'
import { sharedMyLeaseplanConfig } from 'mlp-client/src/features-configuration/fr'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'

import createConfig from './defaults'

const specificConfig = (): CountrySpecific => ({
  label: 'France',
  code: CountryCode.fr,
  tld: '.fr',
  timezone: 'Europe/Paris',
  languages: [
    {
      label: 'Français',
      code: LanguageCode.fr,
      locale: Locales.FR,
      preferred: true,
    },
    {
      label: 'English',
      code: LanguageCode.en,
      locale: Locales.EN_FR,
      preferred: false,
    },
  ],
  features: {
    myLeasePlan: sharedMyLeaseplanConfig,
  },
  formatting: {
    delimiters: {
      thousands: ' ',
    },
    licensePlate: {
      skipCharacters: 0,
    },
    currency: {
      symbolPrefix: false,
      symbolSuffix: true,
      priceSuffix: false,
    },
  },
  pardotTracking: {
    piAId: '138401',
    piCId: '13676',
  },
  noContractAllowed: false,
  enableLanguageSelector: true,
})

export default createConfig(specificConfig)
