import moment from 'moment'
import { createSelector } from 'reselect'

import {
  canDoMaintenanceBooking,
  getCurrentContract,
} from 'mlp-client/src/contracts/selectors'
import { State } from 'mlp-client/src/flows/glass-repair/reducers'
import { FlowData } from 'mlp-client/src/flows/glass-repair/types'
import { getDamageTypeOptions } from 'mlp-client/src/flows/glass-repair/utils'
import { getUserContactInfo } from 'mlp-client/src/user/selectors'
import { INVARIANT_DATE_FORMAT } from 'mlp-client/src/constants'
import {
  AppState,
  BookingRequest,
  BookingRequestType,
  BookingStatus,
} from 'mlp-client/src/types'
import { getCountry } from 'mlp-client/src/localization/selectors'
import { mapLocation } from 'mlp-client/src/utils/address/address'

const getGlassRepairState = (state: AppState): State => state.glassRepair.flow

export const getBookingRequest = (
  state: AppState,
  flowData: FlowData,
): BookingRequest => {
  const contract = getCurrentContract(state)
  const { email, phone } = getUserContactInfo(state)
  const mappedAddress = flowData.address && mapLocation(flowData.address)

  return {
    email: flowData.email || email,
    bookingType: BookingRequestType.GLASS,
    contractId: contract.nolsId,
    glassDamageType: flowData.windowDamageType,
    malfunctions: [],
    address:
      mappedAddress && !mappedAddress.country
        ? {
            ...mappedAddress,
            country: getCountry(state),
          }
        : mappedAddress,
    supplierId: flowData.supplier && flowData.supplier.id,
    phoneNumber: flowData.phone?.number || phone.number,
    phoneCountryCode: flowData.phone?.code,
    dropOffDate:
      flowData.supplier &&
      moment(new Date(flowData.date))
        .startOf('day')
        .add(flowData.time, 'hours')
        .format(INVARIANT_DATE_FORMAT),
    // TODO: no decision on what to do with damageDate yet: it should be either optional or we should
    // add a step to the flow to collect the date. Set a mock date (yesterday) for now.
    // https://leaseplan-digital.atlassian.net/browse/MLP-3032
    damageDate: moment().subtract(1, 'days').format(INVARIANT_DATE_FORMAT),
  }
}

export const isBookingConfirmed = (state: AppState): boolean => {
  const bookingResult = getGlassRepairState(state).bookingResult

  return (
    Boolean(bookingResult) && bookingResult.status === BookingStatus.CONFIRMED
  )
}

export const getDamageTypes = createSelector(
  canDoMaintenanceBooking,
  getDamageTypeOptions,
)
