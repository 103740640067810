import { Headline } from '@velocity/ui'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { ReplaceLabels } from 'mlp-client/src/features-configuration/types'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { LoadRepairMaintenanceData } from 'mlp-client/src/flows/maintenance/actions'
import { AdditionalServices } from 'mlp-client/src/flows/maintenance/components/steps/select-maintenance-type-step/AdditionalServices'
import { MaintenanceType } from 'mlp-client/src/flows/maintenance/enums'
import { getRepairMaintenanceData } from 'mlp-client/src/flows/maintenance/selectors'
import {
  FlowData,
  RepairMaintenanceData,
} from 'mlp-client/src/flows/maintenance/types'
import { arrayToFlagObject } from 'mlp-client/src/flows/maintenance/utils'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { CheckboxList } from 'mlp-client/src/form/components/fields/CheckboxFields'
import { noEmptyObject } from 'mlp-client/src/form/validations'
import { AppState } from 'mlp-client/src/types'
import { Options } from 'mlp-client/src/form/types'

export interface StepConfig {
  options: Options
  title: string
  posterTitleLabel: string
  posterTextLabel: string
  titleLabel: string
  isDisclaimerEnabled: boolean
  collectAndReturnWithoutSuppliersStep: boolean
  replaceLabels?: ReplaceLabels
  disableAdditionalServices?: boolean
}

export interface Props extends StepViewProps<StepConfig, FlowData> {
  predictiveMileage?: number
  repairMaintenanceData: RepairMaintenanceData
  loadRepairMaintenanceData(flowData: FlowData): void
}

export interface FormData {
  maintenanceType: Record<string, boolean>
  additionalServices: Record<string, boolean>
}

class SelectMaintenanceTypeStep extends React.PureComponent<Props> {
  submitHandler = (values: FormData) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        additionalServices: values.additionalServices
          ? Object.keys(values.additionalServices)
          : [],
        maintenanceType: Object.keys(values.maintenanceType),
      },
      goToNextStep,
    )
  }

  componentDidMount(): void {
    const {
      repairMaintenanceData,
      loadRepairMaintenanceData,
      flowData,
    } = this.props

    if (!repairMaintenanceData || !repairMaintenanceData.isLoading) {
      loadRepairMaintenanceData(flowData)
    }
  }

  render() {
    const {
      config: {
        options,
        title,
        posterTitleLabel,
        posterTextLabel,
        titleLabel,
        collectAndReturnWithoutSuppliersStep,
        isDisclaimerEnabled,
        replaceLabels,
        disableAdditionalServices,
      },
      repairMaintenanceData: { additionalServices: additionalServicesOptions },
      flowData: { maintenanceType, additionalServices, predictiveMileage },
    } = this.props

    const initialMaintenanceType = predictiveMileage
      ? { [MaintenanceType.Regular]: true }
      : undefined

    const selectMalfunctionsForm = (
      <>
        <Headline variant="200" component="h3">
          <Translation hideOnEmpty={true} id={title} />
        </Headline>
        <Form
          keepDirtyOnReinitialize
          onSubmit={this.submitHandler}
          initialValues={{
            additionalServices: arrayToFlagObject(additionalServices),
            maintenanceType:
              arrayToFlagObject(maintenanceType) || initialMaintenanceType,
          }}
        >
          {({
            handleSubmit,
            hasValidationErrors,
            values: { maintenanceType, additionalServices },
          }) => (
            <form onSubmit={handleSubmit}>
              <CheckboxList
                name="maintenanceType"
                options={options}
                validations={[noEmptyObject]}
              />

              {maintenanceType &&
                !disableAdditionalServices &&
                Object.entries(maintenanceType).length > 0 && (
                  <AdditionalServices
                    additionalServices={additionalServicesOptions}
                    selectedAdditionalServices={additionalServices}
                    collectAndReturnWithoutSuppliersStep={
                      collectAndReturnWithoutSuppliersStep
                    }
                    isDisclaimerEnabled={isDisclaimerEnabled}
                    replaceLabels={replaceLabels}
                  />
                )}

              <NextStepButton
                onClick={handleSubmit}
                disabled={hasValidationErrors}
              />
            </form>
          )}
        </Form>
      </>
    )

    return (
      <StepLayout>
        <TwoColumnFlowStep
          posterTitleLabel={posterTitleLabel}
          posterTextLabel={posterTextLabel}
          titleLabel={titleLabel}
          posterImgMedium="/static/images/my-leaseplan/maintenance-poster-medium.jpg"
          posterImgLarge="/static/images/my-leaseplan/maintenance-poster-large.jpg"
          posterImgSmall="/static/images/my-leaseplan/mobile/maintenance-repair-mobile.png"
          side={<FAQ faqKey={FAQKeys.MAINTENANCE_AND_REPAIR_PAGE} />}
        >
          {selectMalfunctionsForm}
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'repairMaintenanceData'>
type DispatchProps = Pick<Props, 'loadRepairMaintenanceData'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  repairMaintenanceData: getRepairMaintenanceData(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadRepairMaintenanceData: flowData =>
    dispatch(new LoadRepairMaintenanceData({ flowData })),
})

export { SelectMaintenanceTypeStep }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectMaintenanceTypeStep)
