import React from 'react'

import { QuotesStatuses } from 'mlp-client/src/quotes/enums'

import { LicensePlate } from '../vehicle-model-license/VehicleModelLicense.styled'
import { Status } from './LicensePlateOrStatus.styled'

interface Props {
  className?: string
  licensePlate: string
  status: QuotesStatuses
}

const LicensePlateOrStatus: React.FC<Props> = ({
  licensePlate,
  status,
  className,
}) =>
  licensePlate ? (
    <LicensePlate className={className}>{licensePlate}</LicensePlate>
  ) : status ? (
    <Status className={className} status={status}>
      {status}
    </Status>
  ) : null

export default LicensePlateOrStatus
