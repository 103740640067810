import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { FlowData } from 'mlp-client/src/flows/cash-claim/types'
import { Options } from 'mlp-client/src/form/types'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { required } from 'mlp-client/src/form/validations'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'

export interface StepConfig {
  options: Options
  optionsListE2eId?: string
  fieldName: string
  title?: string
  posterTitleLabel?: string
  posterTextLabel?: string
  posterImgSmall?: string
  posterImgMedium?: string
  posterImgLarge?: string
  showFAQ?: boolean
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

export interface FormData {
  radioInputValue: string
}

class RadioListStep extends React.PureComponent<Props> {
  onSubmit = ({ radioInputValue }: FormData) => {
    const {
      setFlowData,
      goToNextStep,
      config: { fieldName },
    } = this.props

    setFlowData({ [fieldName]: radioInputValue }, goToNextStep)
  }

  render() {
    const {
      config: {
        options,
        optionsListE2eId,
        title,
        posterTitleLabel,
        posterTextLabel,
        posterImgSmall,
        posterImgMedium,
        posterImgLarge,
        showFAQ,
      },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel={title}
          posterTitleLabel={posterTitleLabel}
          posterTextLabel={posterTextLabel}
          posterImgMedium={posterImgMedium}
          posterImgLarge={posterImgLarge}
          posterImgSmall={posterImgSmall}
          side={
            showFAQ && <FAQ faqKey={FAQKeys.CASH_CLAIM_SELECT_CLAIM_TYPE} />
          }
        >
          <Form onSubmit={this.onSubmit}>
            {({ handleSubmit }: FormRenderProps) => (
              <form onSubmit={handleSubmit}>
                <RevealingItemList batchSize={6} items={options}>
                  {options => (
                    <RadioClickAndGoList
                      name="radioInputValue"
                      validations={[required]}
                      options={options}
                      dataE2eId={optionsListE2eId}
                      onChange={handleSubmit}
                    />
                  )}
                </RevealingItemList>
              </form>
            )}
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default RadioListStep
