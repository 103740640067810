import { GridItem, mq } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import styled from 'styled-components'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 120px;
  padding: 30px 20px;
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  ${mq.until('lap')`
    margin-bottom: 12px;
  `}
`

export const DistanceWrapper = styled.div<{ highlighted: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-height: 23px;
  background-color: ${p =>
    p.highlighted
      ? ColorEnumSelector('midOrange')
      : ColorEnumSelector('petrolBlue')};
`

export const SupplierName = styled(Text)`
  margin-bottom: 10px;
`

export const Address = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SupplierWrapper = styled(GridItem)`
  width: 90%;
`

export const IconWrapper = styled(GridItem)`
  align-self: center;
  width: 10%;
`
