export enum CashClaimCategory {
  FUEL = 'fuel',
  LIQUIDS = 'liquids',
  TOLL = 'toll',
  MAINTENANCE = 'maintenance',
  TYRE_SERVICE = 'tyreServices',
  ROAD_SIDE_ASSISTANCE = 'roadSideAssistance',
  DAMAGE_REPAIR = 'damageRepair',
  MOT_EXPENSES = 'mot',
}

export enum FuelType {
  BENZIN = 'benzin',
  DIESEL = 'diesel',
  ELECTRIC = 'electric',
  EURO_95 = 'euro95',
  EURO_SUPER = 'eurosuper',
  HIGH_PERFOMANCE = 'highPerformance',
  HYDROGEN = 'hydrogen',
  PREMIUM_DIESEL = 'premiumDiesel',
  PREMIUM_PETROL = 'premiumPetrol',
}

export enum LiquidsType {
  AD_BLUE = 'adBlue',
  COOLANT = 'coolant',
  OIL = 'oil',
  WIND_SCREEN_WIPER = 'windscreenWiper',
}

export enum RoadSideAssistanceType {
  ACCOMMODATION = 'accommodation',
  PUBLIC_TRANSPORT = 'publicTransport',
  REPLACEMENT_VEHICLE = 'replacementVehicle',
  TAXI = 'taxi',
  TRANSPORTATION_VEHICLE = 'transportationOfTheVehicle',
  OTHER = 'other',
}

export const enum StepNames {
  SELECT_CLAIM_TYPE = 'selectClaimType',
  PURCHASE_DETAILS = 'purchaseDetails',
  ACCOUNT_DETAILS = 'accountDetails',
  ROAD_SIDE_ASSISTANCE = 'roadSideAssistance',
  FUEL_TYPE = 'fuelType',
  LIQUIDS = 'liquids',
  CHECK_DETAILS = 'checkDetails',
  THANK_YOU = 'thankYou',
}
