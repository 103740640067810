import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import {
  required,
  maxLength as maxLengthValidator,
} from 'mlp-client/src/form/validations'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { FormSubmission, Options } from 'mlp-client/src/form/types'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'

export interface Props extends FormSubmission<FormData> {
  topics?: Options
  descriptionPlaceholder?: string
  topicLabel?: string
  descriptionLabel?: string
  buttonLabel?: string
  pageTitle?: string
  pageDescription?: string
  categoryPlaceholder?: string
  submittingLabel?: string
}

export interface FormData {
  topic: string
  description: string
}

class InformationForm extends React.PureComponent<Props> {
  render() {
    const {
      topics,
      descriptionPlaceholder,
      topicLabel,
      descriptionLabel,
      buttonLabel,
      pageTitle,
      pageDescription,
      categoryPlaceholder,
      submittingLabel,
      onSubmit,
    } = this.props

    return (
      <>
        <Headline variant="400" withMarginBottom component="h1">
          <Translation id={pageTitle} />
        </Headline>
        <Text>
          <Translation id={pageDescription} />
        </Text>
        <Spacing mv={1}>
          <ReactFinalForm<FormData> onSubmit={onSubmit}>
            {({
              handleSubmit,
              hasValidationErrors,
              submitting,
              submitErrors,
            }: FormRenderProps) => (
              <form onSubmit={handleSubmit}>
                <Spacing pv={2}>
                  <SelectField
                    placeholder={{ id: categoryPlaceholder }}
                    label={{ id: topicLabel }}
                    options={topics}
                    name="topic"
                    validations={[required]}
                  />
                </Spacing>
                <Spacing pv={2}>
                  <TextAreaField
                    name="description"
                    placeholder={{ id: descriptionPlaceholder }}
                    label={{ id: descriptionLabel }}
                    validations={[required, maxLengthValidator(5000)]}
                    legalNote={{ id: 'myLeaseplan.shared.openTextLegalNote' }}
                  />
                </Spacing>
                <Spacing mv={2}>
                  <SubmitStatus
                    submitting={submitting}
                    submittingTranslation={submittingLabel}
                    failed={!!submitErrors}
                    failedMessages={submitErrors && Object.values(submitErrors)}
                  >
                    <SubmitButton
                      onClick={handleSubmit}
                      disabled={hasValidationErrors || submitting}
                      label={buttonLabel}
                      dataE2eId="confirmCustomerSupportButton"
                    />
                  </SubmitStatus>
                </Spacing>
              </form>
            )}
          </ReactFinalForm>
        </Spacing>
      </>
    )
  }
}

export { InformationForm }
