import { Card, Grid, GridItem } from '@leaseplan/ui'
import { Text, colorMixins } from '@velocity/ui'
import styled from 'styled-components'
import { Accordion, Paper } from '@material-ui/core'

import RouteLink from 'mlp-client/src/localization/RouteLink'

const boxShadowBorder = 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'

export const StyledRouteLink = styled(RouteLink)`
  text-decoration: none;
`

export const StyledCard = styled(Card)`
  position: relative;
`

export const VehicleGridStyled = styled(Grid)`
  margin-bottom: 6px;
`
export const GridItemStyled = styled(GridItem)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const DateStyled = styled(Text)`
  position: absolute;
  top: 12px;
  left: 24px;
  text-transform: uppercase;
  color: ${colorMixins.gray60Color};
  font-size: 10px;
`
export const ContentWrapper = styled.div`
  padding-top: 6px;
  padding-bottom: 12px;
`
export const GridItemCentered = styled.div`
  align-self: center;
`
export const StyledCardChild = styled(Paper)`
  padding: 10px;
  box-shadow: ${boxShadowBorder};
  width: 100%;
`
export const StyledAccordion = styled(Accordion)`
  box-shadow: ${boxShadowBorder};
`
