import { ButtonLink } from '@leaseplan/ui'
import styled from 'styled-components'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export const StyledButtonLink = styled(ButtonLink)`
  color: ${ColorEnumSelector('bloodOrange')} !important;

  svg {
    color: ${ColorEnumSelector('bloodOrange')};
  }
`
