import { NoCarNoContractDashboard } from 'mlp-client/src/attach-car/components'
import { FeatureConfiguration } from 'mlp-client/src/attach-car/types'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const attachCarToDriverFeature: MyLeaseplanFeature<FeatureConfiguration> = {
  enabled: true,
  view: NoCarNoContractDashboard,
  configuration: {
    descriptionTextId: 'myLeaseplan.dashboard.noCarFleetManager',
    buttonLink: {
      labelId: 'myLeaseplan.dashboard.goToShowroom',
      url: 'myLeaseplan.external.showroom',
    },
    isRequestAccountLinkEnabled: true,
  },
}
