import React from 'react'

interface Props {
  title?: string
  width?: number | string
  height?: number | string
  color?: string
}

type DefaultProps = Required<
  Pick<Props, 'title' | 'width' | 'height' | 'color'>
>
type InternalProps = Props & DefaultProps

class SearchLocationMarkerIcon extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    title: 'Search Location',
    width: 20,
    height: 28,
    color: '#004A5D',
  }

  render() {
    const { width, height, color, title } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <path
          fill={color}
          fillRule="evenodd"
          d="M10 0c5.523 0 10 3.994 10 10 0 6.248-10 17.467-10 17.467S0 16.257 0 10C0 3.994 4.477 0 10 0zm0 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
        />
      </svg>
    )
  }
}

export default SearchLocationMarkerIcon
