import React from 'react'
import { ModalTrigger } from '@leaseplan/ui'

import { TileProps, ModalContent } from '../type'
import { DamageIconCircled } from './icons'
import { DashboardModalContent } from './DashboardModalContent'

const modalContent: ModalContent = {
  title: 'myLeaseplan.app.roland.page.title',
  icon: DamageIconCircled,
  immediateAssistance: 'myLeaseplan.app.roland.page.immediateAssistance',
  hotline: 'myLeaseplan.app.roland.page.hotline',
  phoneNumber: 'myLeaseplan.app.roland.page.phone',
  button: 'myLeaseplan.app.roland.page.button',
}

export const RolandAppModalTrigger: React.FC<TileProps> = props => (
  <ModalTrigger
    trigger={props.children}
    modalContent={
      <DashboardModalContent {...props} modalContent={modalContent} />
    }
  />
)
