import { AppState } from 'mlp-client/src/types'
import { StaticPageSuppliersData } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'

export const getStaticPageSuppliers = (
  state: AppState,
  showOpeningHours: boolean = true,
): StaticPageSuppliersData => {
  const { staticPageSuppliers } = state

  const suppliers = staticPageSuppliers.results.map(supplier => ({
    ...supplier,
    openingHours: showOpeningHours ? supplier.openingHours : undefined,
  }))

  return {
    suppliers,
    searchRadius: staticPageSuppliers.searchRadius,
    searchLocation: staticPageSuppliers.searchLocation,
    isLoading: staticPageSuppliers.isLoading,
    error: staticPageSuppliers.error,
  }
}
