import { Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import { isBookingConfirmed } from 'mlp-client/src/flows/maintenance/selectors'
import { FlowData } from 'mlp-client/src/flows/maintenance/types'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { supplierAvailTimeFrameSelector } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'
import { StyledLink } from 'mlp-client/src/components/link/StyledLink'

export interface StepConfig {
  titleLabel: string
  headerText: string
  confirmationDynamic: string
  emailText: string
  statusText: string
  timelineLink: string
  declinedBooking?: string
}

export interface Props extends StepViewProps<StepConfig, FlowData> {
  bookingConfirmed: boolean
  daysAmount: number
}

class ThankYouStep extends React.PureComponent<Props> {
  render() {
    const {
      bookingConfirmed,
      daysAmount,
      closeFlow,
      config: {
        titleLabel,
        headerText,
        confirmationDynamic,
        declinedBooking,
        emailText,
        statusText,
        timelineLink,
      },
    } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id={titleLabel} />
        </Headline>
        <Section headerText={headerText}>
          <Translation id={emailText} />
          {!bookingConfirmed && (
            <Translation id={confirmationDynamic} replace={{ daysAmount }} />
          )}
          {declinedBooking && <Translation id={declinedBooking} />}
          <>
            <Translation id={statusText} />{' '}
            <StyledLink component={RouteLink} to="myLeaseplan.timeline.base">
              <Translation id={timelineLink} />
            </StyledLink>
          </>
        </Section>
      </ThankyouPageLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'bookingConfirmed' | 'daysAmount'>
type OwnProps = Omit<Props, keyof ReduxProps>
type DispatchProps = Pick<Props, never>

const mapStateToProps = (state: AppState): ReduxProps => ({
  bookingConfirmed: isBookingConfirmed(state),
  daysAmount: supplierAvailTimeFrameSelector(state),
})

export { ThankYouStep }
export default connect<ReduxProps, DispatchProps, OwnProps>(mapStateToProps)(
  ThankYouStep,
)
