import { Currencies } from 'mlp-client/src/localization/enums'
import ViewContract from 'mlp-client/src/contracts/components/ViewContractFlow'
import {
  ViewContractTabs,
  ContractSectionProperties,
  InsuranceSectionProperties,
  VehicleSectionProperties,
  ViewContractSections,
} from 'mlp-client/src/contracts/enums'
import { ContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const contractsConfig: MyLeaseplanFeature<ContractsFeatureConfig> = {
  enabled: true,
  view: ViewContract,
  configuration: {
    policyDocuments: false,
    contractStatusBlock: true,
    currency: { code: Currencies.DKK, format: '0.00' },
    tabs: {
      [ViewContractTabs.Contract]: {
        [ViewContractSections.VehicleDetails]: [
          VehicleSectionProperties.LICENSE_PLATE,
          VehicleSectionProperties.MAKE,
          VehicleSectionProperties.MODEL,
          VehicleSectionProperties.VIN,
          VehicleSectionProperties.COLOR,
          VehicleSectionProperties.FUEL,
          VehicleSectionProperties.TRANSMISSION,
          VehicleSectionProperties.POWER,
          VehicleSectionProperties.CO2,
          VehicleSectionProperties.ESTIMATED_FUEL_KM,
        ],
        [ViewContractSections.Contract]: [
          ContractSectionProperties.OBJECT_ID,
          ContractSectionProperties.START_DATE,
          ContractSectionProperties.END_DATE,
          ContractSectionProperties.TAXATION_VALUE,
          ContractSectionProperties.ENVIRONMENTAL_TAXATION_VALUE,
        ],
        [ViewContractSections.Insurance]: [
          InsuranceSectionProperties.COMPANY,
          InsuranceSectionProperties.TYPE,
          InsuranceSectionProperties.POLICY_NUMBER,
        ],
      },
    },
  },
}
