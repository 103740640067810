import { isValidBIC, isValidIBAN } from 'ibantools'
import moment from 'moment'

import { Files } from 'mlp-client/src/form/components/fields/file-upload-field/types'
import { Weekday } from 'mlp-client/src/enums'
import { FileStatus } from 'mlp-client/src/form/components/fields/file-upload-field/enums'
import { leaveOnlyNumbers } from 'mlp-client/src/flows/cash-claim/utils'
import { Time } from 'mlp-client/src/form/components/fields/time-input-field/types'
import { isLicensePlateValid } from 'mlp-client/src/utils'
import { Phone } from 'mlp-client/src/profile/types'
import { CountryPhoneCodes } from 'mlp-client/src/form/components/fields/PhoneInput/types'

import { TranslationType } from './types'

export const required = (value: unknown): TranslationType =>
  value ? undefined : { id: 'myLeaseplan.shared.requiredMessage' }

export const minValue = (minValue: number) => (value: string) =>
  Number(value) >= minValue
    ? undefined
    : {
        id: 'myLeaseplan.validations.minValue',
        replace: [
          {
            key: 'minValue',
            value: minValue,
          },
        ],
      }

export const iban = (value: string): TranslationType =>
  isValidIBAN(value.replace(/\s/g, '').toUpperCase())
    ? undefined
    : { id: 'myLeaseplan.shared.ibanMessage' }

// Now it requires country code in bic, it means that we can input only Luxemburg's BIC if we in Luxemburg
export const bic = (value: string): TranslationType =>
  isValidBIC(value.toUpperCase())
    ? undefined
    : { id: 'myLeaseplan.shared.bicMessage' }

export const noEmptyObject = (value: GenericObject): TranslationType =>
  value && Object.keys(value).length
    ? undefined
    : { id: 'myLeaseplan.shared.requiredMessage' }

export const minLength = (minLength: number) => (value: string) => {
  if (!value || value.length >= minLength) {
    return undefined
  }

  return {
    id: 'myLeaseplan.shared.textareaField.error.minLength',
    replace: [
      {
        key: 'minLength',
        value: minLength,
      },
    ],
  }
}

export const accountNumber = (value: string): TranslationType => {
  const withoutSpaceValue = leaveOnlyNumbers(value)

  return minLength(10)(withoutSpaceValue) || maxLength(14)(withoutSpaceValue)
}

export const maxLength = (maxLength: number) => (value: string) => {
  if (!value || value.length <= maxLength) {
    return undefined
  }

  return {
    id: 'myLeaseplan.shared.textareaField.error.maxLength',
    replace: [
      {
        key: 'maxLength',
        value: maxLength,
      },
    ],
  }
}

export const validEmail = (value: string | undefined): TranslationType => {
  const regex = /\S+@\S+\.\S+/

  return String(value).match(regex)
    ? undefined
    : { id: 'myLeaseplan.shared.requiredMessage' }
}

export const dateNotAfter = (after: Date) => (
  selected: string,
): TranslationType => {
  const afterDate = moment(after).startOf('day')
  const selectedDate = moment(selected).startOf('day')

  if (selectedDate.isAfter(afterDate)) {
    return {
      id: 'myLeaseplan.newSetup.shared.forms.date.validations.dateNotInPast',
    }
  }

  return undefined
}

export const dateNotBefore = (before: Date) => (
  selected: string,
): TranslationType => {
  const beforeDate = moment(new Date(before)).startOf('day')
  const selectedDate = moment(new Date(selected)).startOf('day')

  if (selectedDate.isBefore(beforeDate)) {
    return {
      id: 'myLeaseplan.newSetup.shared.forms.date.validations.dateNotInPast',
    }
  }

  return undefined
}

export const dateNotOnWeekends = (selected: string) => {
  const selectedDate = moment(selected)
  const days = [Weekday.Saturday, Weekday.Sunday]

  if (days.includes(selectedDate.day())) {
    return {
      id:
        'myLeaseplan.newSetup.shared.forms.date.validations.notDuringWeekends',
    }
  }

  return undefined
}

export const invalidDate = (date: string) => {
  if (!date || !moment(new Date(date)).isValid()) {
    return {
      id: 'myLeaseplan.newSetup.shared.forms.date.validations.invalidDate',
    }
  }

  return undefined
}

export const filesUploaded = (files: Files) => {
  const uncompletedFiles = files.filter(
    file => file.status === FileStatus.UPLOADING,
  )

  return !uncompletedFiles.length ? undefined : { id: '' }
}

export const filesRequiredAndUploaded = (files: Files) => {
  const uploadedFiles = files.filter(file => file.status === FileStatus.SUCCESS)
  const uncompletedFiles = files.filter(
    file => file.status === FileStatus.UPLOADING,
  )

  return uploadedFiles.length && !uncompletedFiles.length
    ? undefined
    : { id: '' }
}

export const timeIsValid = (time: Time) => {
  if (!time?.hours || !time.minutes) {
    return { id: 'myLeaseplan.shared.requiredMessage' }
  }

  if (!time.hours.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3])$/)) {
    return { id: 'myLeaseplan.shared.input.time.hour.invalidFormat' }
  }

  if (
    !time.minutes.match(/^([0-9]|0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])$/)
  ) {
    return { id: 'myLeaseplan.shared.input.time.minute.invalidFormat' }
  }
}

export const licensePlate = (country: string, message: string) => (
  value: string,
) => {
  const isValid = isLicensePlateValid(value, country)

  return isValid ? undefined : { id: message }
}

export const phoneNumber = (value: string) =>
  !value || String(value).match(/^[0-9+-_ ]{0,3}[(0-9)_ ]{0,5}[0-9-_ ]{6,16}$/)
    ? undefined
    : {
        id: 'myLeaseplan.validations.phoneNumber',
      }

export const getPhoneNumberValidators = (numberRequired?: boolean) => {
  const baseValidators = [
    ({ number, code }: Phone) => {
      const numberWithoutSpaces = number.replace(/\s/g, '')

      if (code === `00${CountryPhoneCodes.DK}`) {
        return (
          minLength(5)(numberWithoutSpaces) || maxLength(8)(numberWithoutSpaces)
        )
      }

      if (code === `00${CountryPhoneCodes.FR}`) {
        return (
          minLength(5)(numberWithoutSpaces) || maxLength(9)(numberWithoutSpaces)
        )
      }

      return (
        minLength(4)(numberWithoutSpaces) || maxLength(12)(numberWithoutSpaces)
      )
    },
  ]
  const numberRequiredValidators = [
    ...baseValidators,
    ({ number }: Phone) => required(number),
  ]

  return numberRequired ? numberRequiredValidators : baseValidators
}

export const franceAddressValidators = (value: string) =>
  value.trim().match(/^[0-9]/)
    ? undefined
    : { id: 'myLeaseplan.validations.invalidHomeAddress' }
