import { Color } from '@leaseplan/ui'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import pickBy from 'lodash/pickBy'

import { LoaderIcon } from './LoaderIcon'

interface StylingProps {
  isBusy: boolean
  isFixedPosition: boolean
  isAbsolutePosition: boolean
  isCentered: boolean
}

/**
 * Converts CSS alpha to hex value
 * @param alpha alpha channel value 0..1
 */
const alphaToHex = (alpha: number): string =>
  Math.floor(256 * alpha).toString(16)

/**
 * Returns color representation in #RGBA notation
 */
const cssRgba = (color: Color, alpha: number): string =>
  color + alphaToHex(alpha)

const shadeOverlay = css<StylingProps>`
  position: ${props => (props.isFixedPosition ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;

  transition: opacity 0.2s 0.2s ease-in-out;
  opacity: ${props => (props.isBusy ? 1 : 0)};

  background-color: ${
    /* sc-value */ props =>
      cssRgba(Color.sand, props.isFixedPosition ? 0.5 : 0.2)
  };

  content: '';
  pointer-events: none;
`

const roll = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledLoaderInternal = styled.div`
  position: ${props => (props.isAbsolutePosition ? 'static' : 'relative')};
  z-index: 10;
  &:after {
    ${shadeOverlay}
  }
`

const Spinner = styled(LoaderIcon)<StylingProps>`
  position: ${props => (props.isFixedPosition ? 'fixed' : 'absolute')};
  top: ${props =>
    props.isFixedPosition || props.isCentered ? 'calc(50% - 50px)' : '100px'};
  left: calc(50% - 50px);
  z-index: ${props => (props.isBusy ? 2 : 1)};

  transition: opacity 0.2s 0.2s ease-in-out;
  opacity: ${props => (props.isBusy ? 1 : 0)};

  animation: ${props =>
    props.isBusy
      ? css`
          ${roll} 1s linear infinite
        `
      : 'none'};

  pointer-events: none;
`

export const Sheet = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`

export const LowIndexWrap = styled.div`
  display: flex;
  min-height: 100vh;
  flex-flow: column nowrap;
  z-index: 0;
`

interface Props extends StylingProps {
  showSpinner?: boolean
  className?: string
  children?: React.ReactNode
}

export const LoaderView: React.FC<Props> = props => {
  const dataProps = pickBy(props, (_val, key) => key.startsWith('data-'))

  return (
    <StyledLoaderInternal
      {...dataProps}
      isBusy={props.isBusy}
      isFixedPosition={props.isFixedPosition}
      isAbsolutePosition={props.isAbsolutePosition}
      isCentered={props.isCentered}
      className={props.className}
    >
      {props.showSpinner && (
        <Spinner
          isBusy={props.isBusy}
          isFixedPosition={props.isFixedPosition}
          isAbsolutePosition={props.isAbsolutePosition}
          isCentered={props.isCentered}
        />
      )}
      {props.children}
    </StyledLoaderInternal>
  )
}

LoaderView.displayName = 'LoaderView'
