import { Locales } from 'mlp-client/src/localization/enums'

export const enum ActionTypes {
  SELECT_VEHICLE_DOCUMENT = '@@myLeaseplan/vehicleDocuments/SELECT_VEHICLE_DOCUMENT',

  LOAD_PAGE_CONTENT = '@@myLeaseplan/vehicleDocuments/LOAD_PAGE_CONTENT',
  LOAD_PAGE_CONTENT_FAILED = '@@myLeaseplan/vehicleDocuments/LOAD_PAGE_CONTENT_FAILED',
  LOAD_PAGE_CONTENT_SUCCESS = '@@myLeaseplan/vehicleDocuments/LOAD_PAGE_CONTENT_SUCCESS',
}

export class SelectDocument {
  readonly type = ActionTypes.SELECT_VEHICLE_DOCUMENT

  constructor(
    public payload: {
      documentId: string
    },
  ) {}
}

export class LoadPageContent {
  readonly type = ActionTypes.LOAD_PAGE_CONTENT
  constructor(public payload: { endpoint: string; locale: Locales }) {}
}

export class LoadPageContentFailed {
  readonly type = ActionTypes.LOAD_PAGE_CONTENT_FAILED
}

export class LoadPageContentSuccess {
  readonly type = ActionTypes.LOAD_PAGE_CONTENT_SUCCESS
}

export type Action =
  | SelectDocument
  | LoadPageContent
  | LoadPageContentFailed
  | LoadPageContentSuccess
