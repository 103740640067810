import numeral from 'numeral'
import { camelCase } from 'camel-case'

import { PersonName } from '../../types'
import { isObject } from '../checks/checks'
import { Options } from '../../form/types'

export const formatTelephoneHref = (telephone: string = '') => {
  const formattedPhoneNumber = telephone.replace(/\([0-9]\)|-|\s/g, '')

  // See https://code.tutsplus.com/tutorials/mobile-web-quick-tip-phone-number-links--mobile-7667
  return formattedPhoneNumber !== '' ? `tel://${formattedPhoneNumber}` : ''
}

export const formatNumber = (
  inputValue: number | string,
  defaultValue: string = '',
  format?: string,
): string => {
  const numericValue = inputValue && Number(inputValue)

  return isNaN(numericValue) || !numericValue
    ? defaultValue
    : numeral(numericValue).format(format)
}

const makeSpaceSeparateString = (...parts: string[]): string =>
  parts.filter(Boolean).join(' ')

// TODO: add country-specific name formatting
export const formatPersonName = ({
  salutation,
  firstName,
  lastName,
  insertion,
}: PersonName): string =>
  makeSpaceSeparateString(salutation, firstName, insertion, lastName)

export const getFormName = (flow: string, step?: string): string =>
  step ? `${flow}-${step}` : flow

export const objectValuesToArray = (obj: GenericObject): readonly any[] => {
  if (isObject(obj)) {
    return Object.values(obj).map(x => objectValuesToArray(x))
  }

  return obj
}

export const makeTranslationPath = (path: string) => (definition: string) =>
  `${path}.${definition}`

export const getCurrenciesOptions = (currencies: readonly string[]): Options =>
  currencies.map(currency => ({
    title: currency,
    value: currency,
  }))

/**
 * Sitecore passes route keys in a format like `sitecore.content.BE.publicpages.Pages.Fleet_Management_Pages.Our_Services`.
 * The function transforms a key to a format throughout the application, i.e.
 * `contentRoute.publicpages.pages.fleetManagementPages.ourServices`
 *
 * @param route a route key in sitecore-specific format
 * @returns a route key in an application-wide format
 */
export const transformUrlV2 = (route: string): string => {
  const camelCased = route
    .split('.')
    .map(segment => camelCase(segment))
    .join('.')

  return camelCased.replace(/^sitecore\.content\.\w{2,3}\./, 'contentRoute.')
}

export const trimZerosCountryCode = (code: string) =>
  code.slice(0, 2) === '00' ? code.slice(2) : code

export const formatPhoneNumber = (code?: string, phone?: string) =>
  code && phone ? `+${trimZerosCountryCode(code)} ${phone}` : phone

export const formatBrand = (brand?: string) =>
  brand.replace(/\W+/g, '_').toLowerCase()
