import React from 'react'

import FlowNavigation from 'mlp-client/src/components/myleaseplan-flow/flow-navigation/FlowNavigation'
import { StepLayoutWrapper } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout.styled'
import MyLpFooterSmall from 'mlp-client/src/components/MyLpFooterSmall'
import { MainContent } from 'mlp-client/src/components/layout'
import Loader from 'mlp-client/src/components/loader/Loader'

export interface Props {
  isLoading?: boolean
  hideSpinner?: boolean
}

export class StepLayout extends React.PureComponent<Props> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { children, isLoading, hideSpinner } = this.props

    return (
      <MainContent>
        <FlowNavigation />
        <StepLayoutWrapper>
          <Loader loading={isLoading} fixed={true} hideSpinner={hideSpinner}>
            <div className="my-leaseplan-view">{children}</div>
          </Loader>
        </StepLayoutWrapper>
        <MyLpFooterSmall />
      </MainContent>
    )
  }
}
