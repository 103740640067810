import { Card, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text, colorMixins } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import { FormatDate } from 'mlp-client/src/localization/FormatDate'
import Price from 'mlp-client/src/localization/Price'
import { FinesRecord } from 'mlp-client/src/contracts/types'
import { formatAddressCard, formatRelativeDate } from 'mlp-client/src/utils'
import { BlackText } from 'mlp-client/src/components/styled/TextStyle'

interface WhereAndWhenProps {
  dataE2eId: string
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
  text-transform: uppercase;
  font-size: 10px;
`

const WhereAndWhen: React.FC<WhereAndWhenProps> = ({ children, dataE2eId }) => (
  <Text variant="200" data-e2e-id={dataE2eId}>
    {children}
  </Text>
)

export interface Props extends FinesRecord {}

export class FineCard extends React.PureComponent<Props> {
  render() {
    const {
      date: fineDate,
      amount: fineAmount,
      isoCurrencyCode: currency,
      violationTypeDescription: violationType,
      offenceDate,
      offencePostalCode,
      offenceTown,
      offenceCountry,
    } = this.props
    const isAddressAvailable = Boolean(
      offencePostalCode || offenceTown || offenceCountry,
    )

    return (
      <Spacing mb={1}>
        <Card data-e2e-id={`fineTile-${fineDate}`}>
          <Spacing pt={1} ph={2} pb={2}>
            <Spacing mb={0.5}>
              <SText bold variant="100">
                {formatRelativeDate(fineDate)}
              </SText>
            </Spacing>

            <Grid>
              <GridItem span="grow">
                <Headline
                  variant="100"
                  component="h6"
                  data-e2e-id="fineType"
                  withMarginBottom
                >
                  {violationType}
                </Headline>
                {isAddressAvailable && (
                  <WhereAndWhen dataE2eId="fineAddress">
                    {formatAddressCard({
                      zipCode: offencePostalCode,
                      city: offenceTown,
                      country: offenceCountry,
                    })}
                  </WhereAndWhen>
                )}
                <WhereAndWhen dataE2eId="fineDate">
                  <FormatDate date={offenceDate} dateFormat="ll" />
                </WhereAndWhen>
              </GridItem>
              <GridItem>
                <Price currency={currency}>
                  {formatPrice => (
                    <BlackText bold data-e2e-id="finePrice">
                      {formatPrice(fineAmount)}
                    </BlackText>
                  )}
                </Price>
              </GridItem>
            </Grid>
          </Spacing>
        </Card>
      </Spacing>
    )
  }
}
