import React from 'react'

import { IframePage } from 'mlp-client/src/flows/components/pages/iframe-page/IframePage'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'

class OnePartyDamagePage extends React.PureComponent {
  render() {
    return (
      <MyLeaseplanContext.Consumer>
        {context => (
          <IframePage
            iframeSrc={context.damageReport?.onePartyIframeSrc}
            breadcrumbTitle="myLeaseplan.damageReport.onePartyIframe.title"
            title="myLeaseplan.damageReport.onePartyIframe.title"
          />
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

export { OnePartyDamagePage }
export default OnePartyDamagePage
