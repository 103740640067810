import { Color, IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'

class ContractIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 62.5 62.5"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={Color[color]}
          fillRule="evenodd"
          d="M52 57.311V5.806c0-.931-.758-1.689-1.688-1.689H14.21a1.69 1.69 0 0 0-1.688 1.689v51.505A1.69 1.69 0 0 0 14.21 59h36.103c.93 0 1.688-.758 1.688-1.689zM50.312 1.117A4.695 4.695 0 0 1 55 5.806v51.505A4.695 4.695 0 0 1 50.312 62H14.21a4.693 4.693 0 0 1-4.688-4.689V5.806a4.693 4.693 0 0 1 4.688-4.689h36.103zM45.901 12.53a1.5 1.5 0 1 1 0 3H18.128c-.83 0-1.5-.67-1.5-1.5 0-.829.67-1.5 1.5-1.5H45.9zm0 9.297a1.5 1.5 0 1 1 0 3H18.128c-.83 0-1.5-.671-1.5-1.5 0-.83.67-1.5 1.5-1.5H45.9zm0 9.296a1.5 1.5 0 1 1 0 3H18.128c-.83 0-1.5-.671-1.5-1.5s.67-1.5 1.5-1.5H45.9zm-15.508 15.13a1.5 1.5 0 1 1 0 3H18.127a1.5 1.5 0 1 1 0-3h12.266z"
        />
      </svg>
    )
  }
}

export default ContractIcon
