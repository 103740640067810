import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/FAQ/actions'
import { FAQItemType, FAQSitecoreItemType } from 'mlp-client/src/FAQ/types'
import { ApiSagas } from 'mlp-client/src/api'

export function* fetchFAQ(action: actions.FetchFAQ): SagaIterator {
  const { faqKey, locale } = action.payload
  const faqKeyWithLocale = `${faqKey}_${locale}`
  const params = { locale, faqKey, app: 'myleaseplan' }

  try {
    const response: readonly FAQSitecoreItemType[] = yield call(
      ApiSagas.get,
      '/myleaseplan/faq',
      { params },
      { throwError: true },
    )
    const items: readonly FAQItemType[] = response.map(item => ({
      question: item.Question,
      answer: item.Answer,
      id: item._Id,
    }))
    const data = {
      [faqKeyWithLocale]: items,
    }

    yield put(new actions.FetchFAQSuccess(data))
  } catch {
    return
  }
}

export default [takeLatest(actions.ActionTypes.FETCH_FAQ, fetchFAQ)]
