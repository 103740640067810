import React from 'react'
import styled from 'styled-components'
import { List, ListItem } from '@leaseplan/ui'
import { Text } from '@velocity/ui'

import {
  filterFiles,
  isPdf,
} from 'mlp-client/src/components/image-preview/utils'
import { Attachment, Attachments } from 'mlp-client/src/types'
import PdfIcon from 'mlp-client/src/components/icons/pdf-icon/PdfIcon'

export const StyledImagePreview = styled.img`
  max-height: 100%;
`

interface Props {
  attachments: Attachments
}

class ImagePreview extends React.PureComponent<Props> {
  getFilePreview = (file: Attachment) => {
    if (isPdf(file)) {
      return <PdfIcon />
    } else {
      return <StyledImagePreview src={file.preview} />
    }
  }

  render() {
    const { attachments } = this.props

    return (
      <List listStyleType="none">
        {filterFiles(attachments).map((attachment: Attachment) => (
          <ListItem key={attachment.key}>
            <div className="u-display--flex u-align-items--center ">
              <div className="field-file__thumbnail">
                {this.getFilePreview(attachment)}
              </div>
              <div className="field-file__filename">
                <Text variant="200">{attachment.name}</Text>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    )
  }
}

export { ImagePreview }
export default ImagePreview
