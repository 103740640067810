import { Hide, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import VehicleOverviewTile from 'mlp-client/src/components/vehicle-overview-tiles/VehicleOverviewTile'
import VehiclesOverviewTable from 'mlp-client/src/components/vehicles-overview-table/VehiclesOverviewTableComponent'
import withUser from 'mlp-client/src/user/components/withUser'
import { User } from 'mlp-client/src/user/types'
import TopBanner from 'mlp-client/src/vehicles/components/TopBanner'
import WelcomeScreen from 'mlp-client/src/welcome-screen/WelcomeScreen'
import { AppState } from 'mlp-client/src/types'
import {
  hasManyContracts,
  showMultipleVehicles,
  hasManyOrders,
} from 'mlp-client/src/contracts/selectors'
import BannerCardWrapper from 'mlp-client/src/order-status/components/banner/BannerCardWrapper'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { hasOrders } from 'mlp-client/src/order-status/selectors'
import OrdersOverviewTable from 'mlp-client/src/components/orders-overview-table/OrdersOverviewTable'

export interface Props {
  metaTitle: string
  metaDescription: string
  user?: User
  showCompactLayout: boolean
  showMultipleVehicles: boolean
  userHasOnlyMultipleOrders: boolean
  showCompactOrdersLayout: boolean
}

class Vehicles extends React.PureComponent<Props> {
  render() {
    const {
      metaTitle,
      metaDescription,
      showCompactLayout,
      user,
      showMultipleVehicles,
      userHasOnlyMultipleOrders,
      showCompactOrdersLayout,
    } = this.props

    if (!showMultipleVehicles && !userHasOnlyMultipleOrders) {
      return <TranslatedRedirect to="myLeaseplan.dashboard" />
    }

    if (showCompactLayout) {
      return (
        <MyleaseplanPage
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        >
          <WelcomeScreen />
          <VehiclesOverviewTable />
          <MyLeaseplanContext.Consumer>
            {context =>
              context.orderStatus?.showOrders && showCompactOrdersLayout ? (
                <OrdersOverviewTable />
              ) : (
                <BannerCardWrapper />
              )
            }
          </MyLeaseplanContext.Consumer>
        </MyleaseplanPage>
      )
    }

    if (showCompactOrdersLayout && userHasOnlyMultipleOrders) {
      return (
        <MyleaseplanPage
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        >
          <Spacing pb={2} pt={2}>
            <Headline
              variant="400"
              component="h1"
              data-e2e-id="vehicleTableHeader"
              withMarginBottom
            >
              <Translation
                replace={{ name: user?.firstName }}
                id="myLeaseplan.dashboard.welcome"
              />
            </Headline>
            <Text data-e2e-id="vehicleTableSubheader">
              <Translation id="myLeaseplan.multipleCars.titles.subtitle" />
            </Text>
          </Spacing>
          <WelcomeScreen />
          <OrdersOverviewTable />
        </MyleaseplanPage>
      )
    }

    return (
      <MyleaseplanPage
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        banner={<TopBanner name={user?.firstName} />}
      >
        <WelcomeScreen />
        <Hide from="tablet">
          <Spacing pb={1}>
            <Headline variant="200" data-e2e-id="mobileHomepageSubheader">
              <Translation id="myLeaseplan.multipleCars.titles.subtitle" />
            </Headline>
          </Spacing>
        </Hide>
        <VehicleOverviewTile />
        <MyLeaseplanContext.Consumer>
          {context =>
            context.orderStatus?.showOrders && showCompactOrdersLayout ? (
              <OrdersOverviewTable />
            ) : (
              <BannerCardWrapper />
            )
          }
        </MyLeaseplanContext.Consumer>
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'metaTitle'
  | 'metaDescription'
  | 'showCompactLayout'
  | 'showMultipleVehicles'
  | 'userHasOnlyMultipleOrders'
  | 'showCompactOrdersLayout'
>

const mapStateToProps = (state: AppState): ReduxProps => ({
  metaTitle: getTranslation(state, 'myLeaseplan.dashboard.title'),
  metaDescription: getTranslation(state, 'myLeaseplan.dashboard.subtitle'),
  showCompactLayout: hasManyContracts(state),
  showCompactOrdersLayout: hasManyOrders(state),
  showMultipleVehicles: showMultipleVehicles(state),
  userHasOnlyMultipleOrders: hasOrders(state),
})

export { Vehicles }
export default connect(mapStateToProps)(withUser(Vehicles))
