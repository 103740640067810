import Classnames from 'classnames'
import React from 'react'

import { DynamicLink } from 'mlp-client/src/components/dynamic-link/DynamicLink'
import { ChevronCircleDownIcon } from 'mlp-client/src/components/icons'
import Translation from 'mlp-client/src/localization/Translation'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'

import DropdownPanel from './DropdownPanel'

interface State {
  open: boolean
}

interface Props {
  contractId: string
  quoteId?: string
}

class MainMenuDropdown extends React.PureComponent<Props, State> {
  mainElement: HTMLElement

  state = { open: false }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside)
  }

  attachMainElement = (element: HTMLElement) => {
    this.mainElement = element
  }

  handleClickOutside = (e: MouseEvent) => {
    if (!this.mainElement.contains(e.target as Element) && this.state.open) {
      this.closePanel()
    }
  }

  togglePanel = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  closePanel = () => {
    this.setState({ open: false })
  }

  handleClick = () => {
    this.closePanel()
  }

  render() {
    return (
      <TopNavigationContext.Consumer>
        {({ scrollDirection, navActiveItem, navItems }) => {
          const { open } = this.state

          if (scrollDirection === 'down' && open) {
            this.closePanel()
          }

          return (
            <div className="main-menu-item-dropdown">
              <span className="u-padding-small" ref={this.attachMainElement}>
                <button
                  className="main-menu-item-dropdown__button"
                  onClick={this.togglePanel}
                  aria-expanded={open}
                >
                  {navActiveItem.navigationTitle ? (
                    <span>{navActiveItem.navigationTitle}</span>
                  ) : (
                    navActiveItem.title && (
                      <Translation id={navActiveItem.title} />
                    )
                  )}
                  <ChevronCircleDownIcon
                    className={Classnames(
                      'icon main-menu-item-dropdown__chevron u-margin-left-small',
                      {
                        'main-menu-item-dropdown__chevron--open': open,
                      },
                    )}
                  />
                </button>
                <DropdownPanel open={open}>
                  <ul className="o-list-bare u-padding-vertical-tiny">
                    {navItems.map(item => {
                      const to = item.to || item.url || ''
                      const dropdownClassItem = Classnames(
                        'main-menu-item-dropdown__item u-1/1',
                        {
                          'main-menu-item-dropdown__item--active':
                            item === navActiveItem,
                        },
                      )

                      return (
                        <li key={item.title} className="o-list-bare__item">
                          <DynamicLink
                            data-tag-id={`main-menu-dropdown-link-${to}`}
                            to={to}
                            params={
                              item.useContractId
                                ? { contractId: this.props.contractId }
                                : {}
                            }
                            target={item.target || '_self'}
                            className={dropdownClassItem}
                            onClick={this.handleClick}
                          >
                            {item.navigationTitle ? (
                              <span>{item.navigationTitle}</span>
                            ) : (
                              item.title && <Translation id={item.title} />
                            )}
                          </DynamicLink>
                        </li>
                      )
                    })}
                  </ul>
                </DropdownPanel>
              </span>
            </div>
          )
        }}
      </TopNavigationContext.Consumer>
    )
  }
}

export default MainMenuDropdown
