import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'

import { replaceContentString, makeTranslationPath } from 'mlp-client/src/utils'
import { localizedPrice } from 'mlp-client/src/components/price/Price'
import { Currencies } from 'mlp-client/src/localization/enums'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { ReturnMethods } from 'mlp-client/src/flows/return/enums'
import { FlowData, StepViewProps } from 'mlp-client/src/flows/return/types'
import { getOptionsWithSubtitle } from 'mlp-client/src/flows/return/utils'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { Options } from 'mlp-client/src/form/types'
import { AddressData, Address } from 'mlp-client/src/user/types'
import {
  getUserOtherAddress,
  getUserWorkAddress,
} from 'mlp-client/src/user/utils'
import { AppState } from 'mlp-client/src/types'

export interface StepConfig {
  returnMethods: Options
  dropoffAddress?: AddressData
  pickUpFee?: number
  currency?: Currencies
  formatAdrress(address: Address): string
}

export interface Props extends StepViewProps<StepConfig> {
  pickUpLabel: string
}

type FormValues = Pick<FlowData, 'returnMethod'>

const translatePath = makeTranslationPath(
  'myLeaseplan.newSetup.flows.return.steps.returnMethod',
)

class ReturnMethodStep extends React.PureComponent<Props> {
  userWorkAddress: Address
  dropoffAddress: Address

  constructor(props: Props) {
    super(props)
    const {
      flowData: { country, user },
      config: { dropoffAddress },
    } = this.props

    this.userWorkAddress = getUserWorkAddress(user.addresses)
    this.dropoffAddress =
      dropoffAddress && getUserOtherAddress({ ...dropoffAddress, country })
  }

  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    const address =
      values.returnMethod === ReturnMethods.DROP_OFF
        ? this.dropoffAddress
        : this.userWorkAddress

    setFlowData({ returnMethod: values.returnMethod, address }, goToNextStep)
  }

  getOptions = (): Options => {
    const {
      config: { returnMethods, formatAdrress },
      pickUpLabel,
    } = this.props

    const subtitles = {
      [ReturnMethods.PICK_UP]: `${
        this.userWorkAddress && formatAdrress(this.userWorkAddress)
      }\n${pickUpLabel}`,
      [ReturnMethods.DROP_OFF]: `${
        this.dropoffAddress && formatAdrress(this.dropoffAddress)
      }`,
    }

    return getOptionsWithSubtitle(returnMethods, subtitles)
  }

  render() {
    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel={translatePath('pageTitle')}
          side={<FAQ faqKey={FAQKeys.RETURN_METHOD_PAGE} />}
        >
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit }) => (
              <RadioClickAndGoList
                validations={[]}
                name="returnMethod"
                options={this.getOptions()}
                onChange={handleSubmit}
              />
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'pickUpLabel'>
type DispatchProps = Pick<Props, never>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, props: OwnProps): ReduxProps => {
  const {
    config: { currency, pickUpFee },
  } = props

  const pickUpLabel = replaceContentString(
    getTranslation(state, translatePath('pickupFee'), ''),
    { amount: localizedPrice(pickUpFee, currency) },
  )

  return { pickUpLabel }
}

export { ReturnMethodStep }
export default connect(mapStateToProps)(ReturnMethodStep)
