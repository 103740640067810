import { editProfileConfig as defaultConfig } from 'mlp-client/src/features-configuration/default/editProfile'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { EditProfileConfig } from 'mlp-client/src/profile/types'

export const editProfileConfig: MyLeaseplanFeature<EditProfileConfig> = {
  ...defaultConfig,
  configuration: {
    ...defaultConfig.configuration,
    addressFields: defaultConfig.configuration.addressFields.filter(
      field => field.name !== 'address.houseNumberSuffix',
    ),
  },
}
