import { Quotes, Quote } from './types'

export const enum ActionTypes {
  LOAD_QUOTES = '@@my-contracts/LOAD_QUOTES',
  LOAD_QUOTES_SUCCESS = '@@my-contracts/LOAD_QUOTES_SUCCESS',
  LOAD_QUOTES_FAILED = '@@my-contracts/LOAD_QUOTES_FAILED',
  SET_CURRENT_QUOTE = '@@my-contracts/SET_CURRENT_QUOTE',
  DEFINE_CURRENT_QUOTE = '@@my-contracts/DEFINE_CURRENT_QUOTE',
  GET_QUOTE_BY_ID = '@@my-contracts/GET_QUOTE_BY_ID',
  GET_QUOTE_BY_ID_SUCCESS = '@@my-contracts/GET_QUOTE_BY_ID_SUCCESS',
  GET_QUOTE_BY_ID_FAILED = '@@my-contracts/GET_QUOTE_BY_ID_FAILED',
}

export class LoadQuotes {
  readonly type = ActionTypes.LOAD_QUOTES
  constructor(
    readonly payload: {
      selectedQuoteId?: string
    },
  ) {}
}

export class LoadQuotesSuccess {
  readonly type = ActionTypes.LOAD_QUOTES_SUCCESS

  constructor(
    readonly payload: {
      quotes: Quotes
    },
  ) {}
}

export class LoadQuotesFailed {
  readonly type = ActionTypes.LOAD_QUOTES_FAILED
}

export class SetCurrentQuote {
  readonly type = ActionTypes.SET_CURRENT_QUOTE

  constructor(
    readonly payload: {
      quoteId: string
    },
  ) {}
}

export class DefineCurrentQuoteId {
  readonly type = ActionTypes.DEFINE_CURRENT_QUOTE

  constructor(readonly payload: string) {}
}

export class GetQuoteById {
  readonly type = ActionTypes.GET_QUOTE_BY_ID
  constructor(
    readonly payload: {
      quoteId: string
    },
  ) {}
}

export class GetQuoteByIdSuccess {
  readonly type = ActionTypes.GET_QUOTE_BY_ID_SUCCESS

  constructor(
    readonly payload: {
      quote: Quote
    },
  ) {}
}

export class GetQuoteByIdFailed {
  readonly type = ActionTypes.GET_QUOTE_BY_ID_FAILED
}

export type Action =
  | LoadQuotes
  | LoadQuotesSuccess
  | LoadQuotesFailed
  | SetCurrentQuote
  | DefineCurrentQuoteId
  | GetQuoteById
  | GetQuoteByIdSuccess
  | GetQuoteByIdFailed
