import { Actions, ActionTypes } from 'mlp-client/src/init/actions'

export const defaultState = false

export default (state = defaultState, action: Actions) => {
  if (action.type === ActionTypes.INIT_FINISHED) {
    return true
  } else {
    return state
  }
}
