import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { generatePath, withRouter, RouteComponentProps } from 'react-router'
import { compose } from 'redux'

import { MainContentWrapper } from 'mlp-client/src/components/layout'
import VehicleSelect from 'mlp-client/src/components/vehicle-select/VehicleSelect'
import { SetCurrentContract } from 'mlp-client/src/contracts/actions'
import { SetCurrentQuote } from 'mlp-client/src/quotes/actions'
import {
  getContract,
  getLeaseContracts,
} from 'mlp-client/src/contracts/selectors'
import { Contract, Contracts } from 'mlp-client/src/contracts/types'
import { Quote, Quotes } from 'mlp-client/src/quotes/types'
import {
  isOrderOrContract,
  mapContractToVehicle,
} from 'mlp-client/src/contracts/utils'
import { AppState } from 'mlp-client/src/types'
import {
  getLocale,
  noContractAllowedLocale,
} from 'mlp-client/src/localization/selectors'
import { getQuotes, getQuote } from 'mlp-client/src/quotes/selectors'

export interface Props extends RouteComponentProps {
  contracts: Contracts
  quotes: Quotes
  activeContract: Contract
  activeQuote: Quote
  setActiveContract(contract: Contract): void
  setActiveQuote(quote: Quote): void
  locale: string
}

interface State {
  selectedContract: Contract
  selectedQuote?: Quote
}

class MultipleContractTopBanner extends React.PureComponent<Props> {
  state: State = {
    selectedContract: this.props.activeContract,
    ...(noContractAllowedLocale
      ? { selectedQuote: this.props.activeQuote }
      : {}),
  }

  componentDidUpdate(oldProps: Props) {
    const newProps = this.props

    if (oldProps.activeContract !== newProps.activeContract) {
      this.setState({
        selectedContract: newProps.activeContract,
      })
    }

    if (
      noContractAllowedLocale &&
      oldProps.activeQuote !== newProps.activeQuote
    ) {
      this.setState({
        selectedQuote: newProps.activeQuote,
      })
    }
  }

  setActiveEntity = (entityId: string) => {
    const entity =
      this.props.contracts.find(contract => contract.id === entityId) ||
      this.props.quotes.find(quote => quote.id === entityId)

    if (isOrderOrContract(entity)) {
      this.props.setActiveContract(entity)
    } else {
      this.props.setActiveQuote(entity)
    }

    this.props.history.replace(
      generatePath(this.props.match.path, {
        entityId,
        locale: this.props.locale,
      }),
    )
  }

  render() {
    const entities = [...this.props.contracts, ...this.props.quotes]
    const options = entities.map(mapContractToVehicle)

    const selectedOptionId =
      (this.state.selectedContract && this.state.selectedContract.id) ||
      (noContractAllowedLocale &&
        this.state.selectedQuote &&
        this.state.selectedQuote.id)

    return (
      <Spacing mt={6}>
        <MainContentWrapper>
          <Grid gutter={false}>
            <GridItem span={{ mobile: 12, tablet: 6 }}>
              {selectedOptionId && (
                <VehicleSelect
                  vehicles={options}
                  selectedVehicleId={selectedOptionId}
                  onSelect={this.setActiveEntity}
                />
              )}
            </GridItem>
          </Grid>
        </MainContentWrapper>
      </Spacing>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'contracts' | 'activeContract' | 'locale' | 'quotes' | 'activeQuote'
>
type DispatchProps = Pick<Props, 'setActiveContract' | 'setActiveQuote'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contracts: getLeaseContracts(state),
  quotes: getQuotes(state),
  activeContract: getContract(state),
  activeQuote: getQuote(state),
  locale: getLocale(state),
})

const mapDispatchToProps: DispatchProps = {
  setActiveContract: (contract: Contract) =>
    new SetCurrentContract({ contractId: contract.id }),
  setActiveQuote: (quote: Quote) => new SetCurrentQuote({ quoteId: quote.id }),
}

export { MultipleContractTopBanner }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(MultipleContractTopBanner)
