import { Action, ActionTypes } from 'mlp-client/src/contracts/actions'
import * as actions from 'mlp-client/src/flows/maintenance/actions'
import { BookingResult } from 'mlp-client/src/types'
import { RepairMaintenanceData } from 'mlp-client/src/flows/maintenance/types'

export interface State {
  repairMaintenanceData: RepairMaintenanceData
  maintenanceBookingResult: BookingResult
}

export const loadRepairMaintenanceData = (state: State) => ({
  ...state,
  repairMaintenanceData: {
    ...state.repairMaintenanceData,
    isLoading: true,
  },
})

export const setMaintenanceBookingResult = (
  state: State,
  action: actions.SetMaintenanceBookingResult,
) => ({
  ...state,
  maintenanceBookingResult: action.payload.data.bookingResult,
})

export const setRepairMaintenanceData = (
  state: State,
  action: actions.LoadRepairMaintenanceDataSuccess,
) => ({
  ...state,
  repairMaintenanceData: {
    ...state.repairMaintenanceData,
    isLoading: false,
    ...action.payload.data,
  },
})

export const defaultState: State = {
  repairMaintenanceData: {
    malfunctions: [],
    additionalServices: [],
    isLoading: false,
  },
  maintenanceBookingResult: undefined,
}

export const maintenanceReducer = (
  state: State = defaultState,
  action: actions.Action | Action,
): State => {
  switch (action.type) {
    case actions.ActionType.SET_MAINTENANCE_BOOKING_RESULT:
      return setMaintenanceBookingResult(state, action)
    case actions.ActionType.LOAD_REPAIR_MAINTENANCE_DATA:
      return loadRepairMaintenanceData(state)
    case actions.ActionType.LOAD_REPAIR_MAINTENANCE_DATA_SUCCESS:
      return setRepairMaintenanceData(state, action)
    case ActionTypes.SET_CURRENT_CONTRACT:
      return defaultState
    default:
      return state
  }
}

export default maintenanceReducer
