import React from 'react'
import { colorMixins, Text } from '@velocity/ui'
import Classnames from 'classnames'
import styled from 'styled-components'

import Flag from 'mlp-client/src/localization/Flag'

import { CountryCodeInfo, CountryType } from './types'

interface Props {
  changeValue: (value: CountryCodeInfo) => void
  isSelected: boolean
  onMouseEnter: () => void
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
`

const CountryItem: React.FC<Props & Partial<CountryType>> = ({
  flagName,
  name,
  countryPhoneCode,
  changeValue,
  isSelected,
  onMouseEnter,
}) => {
  const classNamesItem = Classnames(
    'text--capitalize phone-input-country-item',
    {
      'phone-input-country-item-selected': isSelected,
    },
  )

  return (
    <li
      onMouseEnter={onMouseEnter}
      onClick={() => changeValue({ flagName, countryPhoneCode })}
      className={classNamesItem}
    >
      <Text className="ellipsis-tail" component="span">
        <Flag code={flagName} label={flagName} />
        <Text className="phone-input-country-list-name" component="span">
          {name}
        </Text>
      </Text>
      <SText component="span">+{countryPhoneCode}</SText>
    </li>
  )
}

export default CountryItem
