import React from 'react'
import { mq } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import styled from 'styled-components'
import Tooltip from 'rc-tooltip'

import { TableOrderVehicleData } from 'mlp-client/src/components/overview-table/types'

interface Props {
  item: TableOrderVehicleData
}

const StyledText = styled(Text)`
  ${mq.from('lap')`
    max-width: 360px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`

class SubtitleCell extends React.PureComponent<Props> {
  render() {
    const { item } = this.props

    return (
      <Tooltip overlay={item.subtitle} placement="top">
        <StyledText variant="200">{item.subtitle}</StyledText>
      </Tooltip>
    )
  }
}

export default SubtitleCell
