import { Grid, GridItem, Spacing, spacingUnit } from '@leaseplan/ui'
import { Text, TextProps, colorMixins } from '@velocity/ui'
import numeral from 'numeral'
import React from 'react'
import styled from 'styled-components'

import ProgressBar from 'mlp-client/src/components/progress-bar/ProgressBar'
import { calculatePercentage } from 'mlp-client/src/components/progress-bar/utils'
import {
  ErrorText,
  SecondaryDarkText,
  GrayDark80Text,
} from 'mlp-client/src/components/styled/TextStyle'

const Container = styled.div`
  margin-bottom: ${spacingUnit * 2}px;
`

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
`

export interface Props {
  header?: React.ReactNode
  link?: React.ReactNode
  maxValue: number
  currentValue: number
  expectedValue?: number
  units?: React.ReactNode
  errorMessage?: React.ReactNode
  excessMessage?: React.ReactNode
  currentValueSize?: TextProps['variant']
  dataE2eId?: string
  contextText?: React.ReactNode
  showMaxValue?: boolean
  showCurrentValue?: boolean
  formatter?: (value: number) => string
}

type DefaultProps = Required<
  Pick<Props, 'dataE2eId' | 'showMaxValue' | 'showCurrentValue'>
>
type InternalProps = Props & DefaultProps
export class ProgressBarWidget extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    dataE2eId: '',
    showMaxValue: true,
    showCurrentValue: true,
  }
  render() {
    const {
      header,
      link,
      currentValue,
      units,
      maxValue,
      expectedValue,
      errorMessage,
      excessMessage,
      currentValueSize,
      dataE2eId,
      contextText,
      showMaxValue,
      showCurrentValue,
      formatter = value => numeral(value).format(),
    } = this.props

    if (!maxValue || !currentValue) {
      return null
    }

    return (
      <Container data-e2e-id={`mileageProgressBar${dataE2eId}`}>
        {header && (
          <Grid>
            <GridItem span="grow">
              <SText variant="100">{header}</SText>
            </GridItem>
            {link && (
              <GridItem span="auto" data-e2e-id={`link${dataE2eId}`}>
                {link}
              </GridItem>
            )}
          </Grid>
        )}
        <Grid gutter={false} verticalGutter={false} alignItems="flex-end">
          <GridItem span="grow">
            {showCurrentValue && (
              <SecondaryDarkText
                bold
                variant={currentValueSize}
                component="span"
              >
                {`${formatter(currentValue)} `}
                {units}
              </SecondaryDarkText>
            )}
            {showCurrentValue && showMaxValue && (
              <SText component="span">{'/'}</SText>
            )}
            {showMaxValue && (
              <SText component="span">
                {`${formatter(maxValue)} `}
                {units}
              </SText>
            )}
            {contextText && (
              <Text component="p" variant="200">
                {contextText}
              </Text>
            )}
          </GridItem>
          {!header && link && (
            <GridItem span="auto" data-e2e-id={`EditMileageLink${dataE2eId}`}>
              {link}
            </GridItem>
          )}
        </Grid>
        <Spacing mt={0.5} />
        {maxValue > currentValue ? (
          <>
            <ProgressBar
              currentPercent={calculatePercentage(currentValue, maxValue)}
              expectedPercent={
                expectedValue &&
                expectedValue < maxValue &&
                calculatePercentage(expectedValue, maxValue)
              }
              showBreakpoint={Boolean(expectedValue)}
            />
            {Boolean(
              expectedValue && currentValue > expectedValue && excessMessage,
            ) && (
              <Spacing mt={0.5}>
                <GrayDark80Text variant="200">{excessMessage}</GrayDark80Text>
              </Spacing>
            )}
          </>
        ) : (
          <>
            <ProgressBar
              currentPercent={100}
              expectedPercent={calculatePercentage(maxValue, currentValue)}
              excessStrokeColor="error"
            />
            <Spacing mt={0.5}>
              <ErrorText variant="200">{errorMessage}</ErrorText>
            </Spacing>
          </>
        )}
      </Container>
    )
  }
}
