export const enum CancellationStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const enum TimelineItemStatus {
  NEW = 'new',
  IN_PROGRESS = 'inProgress',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  UNCONFIRMED = 'unconfirmed',
}
