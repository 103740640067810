import React from 'react'

import {
  RoutesContext,
  RoutesContextProps,
} from 'mlp-client/src/localization/RoutesProvider'

export function withRoutes<P extends RoutesContextProps>(
  Comp: React.ComponentType<P>,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return class Component extends React.PureComponent<
    Omit<P, keyof RoutesContextProps>
  > {
    static displayName: string = `withRoutes(${Comp.displayName || Comp.name})`

    render() {
      return (
        <RoutesContext.Consumer>
          {routesContext => {
            const propsWithContext = {
              ...this.props,
              ...routesContext,
            }

            return <Comp {...(propsWithContext as P)} />
          }}
        </RoutesContext.Consumer>
      )
    }
  }
}
