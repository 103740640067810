import React from 'react'
import { useSelector } from 'react-redux'

import { TileProps } from 'mlp-client/src/dashboard/type'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles/BasicTile'
import {
  canDoMaintenanceBooking,
  hasContracts,
} from 'mlp-client/src/contracts/selectors'

export const MaintenanceTile: React.FC<TileProps> = props => {
  const enableMaintenanceBooking = useSelector(canDoMaintenanceBooking)
  const hasNoContracts = !useSelector(hasContracts)

  return (
    <BasicTile
      {...props}
      disabled={!enableMaintenanceBooking || hasNoContracts}
    />
  )
}
