import { DisabledDay } from '@leaseplan/ui'
import moment from 'moment'
import React from 'react'
import { Field } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StringValidations, TranslationType } from 'mlp-client/src/form//types'
import {
  determineValidationState,
  getErrorMessage,
  validateString,
} from 'mlp-client/src/form//utils'
import { isMobile } from 'mlp-client/src/utils'

import { StyledDatePicker } from './DatePickerField.styled'

const formatDate = (
  date: Date,
  format: string | readonly string[],
  locale: string,
): string =>
  moment(date)
    .locale(locale)
    .format(Array.isArray(format) ? format[0] : format)

const parseDate = (
  dateText: string,
  format: string | readonly string[],
  locale: string,
): Date | undefined => {
  const formats = [].concat(format)
  const momentDate = formats
    .map(format => moment(dateText, format, locale, true))
    .find(date => date.isValid())

  if (momentDate) {
    return momentDate.toDate()
  }
}

export default ({
  name,
  validations,
  initialMonth = new Date(),
  dateFormat = 'LL',
  disabledDays,
  label,
  placeholder,
  onChange,
}: {
  name: string
  validations: StringValidations
  initialMonth?: Date
  dateFormat?: string
  disabledDays?: DisabledDay[]
  label?: TranslationType
  placeholder?: TranslationType
  onChange?(value: string): void
}) => {
  const [
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
  ] = moment.months()
  const [mon, tue, wed, thu, fri, sat, sun] = moment.weekdaysShort()

  return (
    <LocalizationContext.Consumer>
      {({ translate, locale }) => (
        <Field name={name} validate={validateString(validations)}>
          {({ input, meta }) => (
            <StyledDatePicker
              {...input}
              placeholder={
                placeholder &&
                translate(placeholder.id, placeholder.replaceValues)
              }
              initialMonth={initialMonth}
              months={[
                jan,
                feb,
                mar,
                apr,
                may,
                jun,
                jul,
                aug,
                sep,
                oct,
                nov,
                dec,
              ]}
              textInputProps={{
                onBlur: e => {
                  // workaround due to onblur event fires when date is clicked  and that cause validation message after first selection of the day
                  const relatedTarget = e.relatedTarget as Element

                  if (
                    !(
                      relatedTarget &&
                      (relatedTarget.classList.contains('DayPicker-Day') ||
                        relatedTarget.classList.contains('DayPicker-wrapper'))
                    )
                  ) {
                    input.onBlur(e)
                  }
                },
                onFocus: input.onFocus,
                errorMessage: meta.error && getErrorMessage(meta, translate),
                validationState: determineValidationState(meta),
                label: label && translate(label.id, label.replaceValues),
                autoComplete: 'off',
              }}
              invalidDateMessage={
                meta.error && getErrorMessage(meta, translate)
              }
              onChange={(value: Date | undefined) => {
                const formatedDate = value ? moment(value).format() : ''

                input.onChange(formatedDate)

                if (onChange) {
                  onChange(formatedDate)
                }
              }}
              disabledDays={disabledDays || null}
              defaultValue={input.value && new Date(input.value)}
              weekdaysShort={[mon, tue, wed, thu, fri, sat, sun]}
              format={dateFormat}
              parseDate={parseDate}
              formatDate={formatDate}
              locale={locale}
              isMobile={isMobile()}
            />
          )}
        </Field>
      )}
    </LocalizationContext.Consumer>
  )
}
