import { CountryConfigList } from './types'
import {
  myleaseplanConfig as dk,
  baseMyLeaseplanConfig as baseDK,
} from './dk/index'
import {
  myleaseplanConfig as fr,
  baseMyLeaseplanConfig as baseFR,
} from './fr/index'
import {
  myleaseplanConfig as lu,
  baseMyLeaseplanConfig as baseLU,
} from './lu/index'
import {
  myleaseplanConfig as de,
  baseMyLeaseplanConfig as baseDE,
} from './de/index'
import { myleaseplanConfig as lp } from './lp/index'

export const baseConfig: CountryConfigList = {
  dk: baseDK,
  fr: baseFR,
  lu: baseLU,
  de: baseDE,
  lp,
}

export const config: CountryConfigList = {
  dk,
  fr,
  lu,
  de,
  lp,
}
