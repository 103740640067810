import React from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

interface DefaultProps {
  title: string
  color: string
  width: number
  height: number
  isFlipped: boolean
}

interface NonDefaultProps {
  className?: string
}

export type Props = Partial<DefaultProps> & NonDefaultProps

export default class ChevronDownIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    title: 'Arrow down',
    color: ColorEnumSelector('thunder'),
    width: 12,
    height: 8,
    isFlipped: false,
  }
  render() {
    const { title, width, height, className, color, isFlipped } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 8"
        xmlns="http://www.w3.org/2000/svg"
        style={isFlipped ? { transform: `rotate(180deg)` } : {}}
        className={className}
      >
        <title>{title}</title>
        <path
          d="M.82 2.202L6.5 8.048l5.53-5.846L10.806.925 6.5 5.495 2.045.924"
          fill={color}
        />
      </svg>
    )
  }
}
