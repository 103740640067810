import React from 'react'

import ErrorBoundary from 'mlp-client/src/components/error-boundary/ErrorBoundary'
import { PageTitle } from 'mlp-client/src/analytics/types'

import MyLeaseplanApp from './MyLeaseplanApp'

export interface PageTitleData {
  pageTitle: PageTitle
  pageTitleKey: string
}

const withMyLeaseplanApp = <T extends PageTitleData>(
  ChildComponent: React.ComponentType<T>,
) => {
  const withMyLeaseplanApp: React.FC<T> = props => (
    <MyLeaseplanApp
      pageTitle={props.pageTitle}
      pageTitleKey={props.pageTitleKey}
    >
      <ErrorBoundary>
        <ChildComponent {...props} />
      </ErrorBoundary>
    </MyLeaseplanApp>
  )

  withMyLeaseplanApp.displayName = `withMyLeaseplanApp(${
    ChildComponent.displayName || ChildComponent.name
  })`

  return withMyLeaseplanApp
}

export { withMyLeaseplanApp }
export default withMyLeaseplanApp
