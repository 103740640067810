import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import {
  getCurrentContract,
  hasContracts,
} from 'mlp-client/src/contracts/selectors'
import {
  hasOnlyOneOrder,
  hasOrders,
} from 'mlp-client/src/order-status/selectors'
import TopNavigation from 'mlp-client/src/components/layout/top-navigation/TopNavigation'
import { AppState } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { Navigation } from 'mlp-client/src/features-configuration/types'
import { Contract, FUEL_MANAGEMENT_TYPE } from 'mlp-client/src/contracts/types'
import { noContractAllowedLocale } from 'mlp-client/src/localization/selectors'
import {
  hasMultipleQuotesAndContracts,
  hasOnlyOneQuote,
  hasQuotes,
} from 'mlp-client/src/quotes/selectors'

export interface Props {
  showMultipleVehiclesNavigation: boolean
  userHasContract: boolean
  userHasOnlyOneOrder: boolean
  userHasMultipleOrders?: boolean
  upperNavigationPopup?: ReactNode
  contract?: Contract
  noContractAllowedLocale: boolean
  userHasOnlyOneQuote: boolean
  userHasMultipleQuotes: boolean
}

// TODO: after removal of FM, the component name becomes confusing as it doesn't support a case
// when a user may have other than "driver" roles.
export class DoubleUserRoleTopNavigation extends React.PureComponent<Props> {
  getNavigationStructure = (navigation?: Navigation) => {
    const {
      showMultipleVehiclesNavigation,
      userHasContract,
      userHasOnlyOneQuote,
      userHasOnlyOneOrder,
      contract,
      userHasMultipleOrders,
      userHasMultipleQuotes,
      noContractAllowedLocale,
    } = this.props

    if (!navigation) {
      return undefined
    }

    if (userHasOnlyOneOrder && !noContractAllowedLocale) {
      return navigation.READONLY_NAVIGATION_STRUCTURE
    }

    if (
      (userHasOnlyOneOrder && noContractAllowedLocale) ||
      (!userHasContract && userHasMultipleQuotes && noContractAllowedLocale)
    ) {
      return navigation.NO_CONTRACT_NAVIGATION_STRUCTURE
    }

    if (!userHasContract && userHasOnlyOneQuote && noContractAllowedLocale) {
      return navigation.NAVIGATION_STRUCTURE_QUOTE
    }

    if (userHasMultipleOrders) {
      return navigation.MULTIPLE_ORDERS_NAVIGATION_STRUCTURE
    }

    if (showMultipleVehiclesNavigation) {
      return navigation.MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE
    }

    if (!userHasContract && !noContractAllowedLocale) {
      return navigation.ONLY_DASHBOARD_NAVIGATION_STRUCTURE
    }

    if (contract?.type === FUEL_MANAGEMENT_TYPE) {
      return navigation.FUEL_MANAGEMENT_NAVIGATION_STRUCTURE
    }

    return navigation.NAVIGATION_STRUCTURE
  }

  render() {
    const { upperNavigationPopup } = this.props

    return (
      <MyLeaseplanContext.Consumer>
        {({ navigation }) => (
          <TopNavigation
            navigationStructure={this.getNavigationStructure(navigation)}
            upperNavigationPopup={upperNavigationPopup}
          />
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'showMultipleVehiclesNavigation'
  | 'userHasOnlyOneOrder'
  | 'userHasContract'
  | 'contract'
  | 'userHasMultipleOrders'
  | 'noContractAllowedLocale'
  | 'userHasOnlyOneQuote'
  | 'userHasMultipleQuotes'
>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  showMultipleVehiclesNavigation: hasMultipleQuotesAndContracts,
  userHasOnlyOneOrder: hasOnlyOneOrder,
  userHasContract: hasContracts,
  contract: getCurrentContract,
  userHasMultipleOrders: hasOrders,
  noContractAllowedLocale,
  userHasOnlyOneQuote: hasOnlyOneQuote,
  userHasMultipleQuotes: hasQuotes,
})

export default connect(mapStateToProps)(DoubleUserRoleTopNavigation)
