import { EventDefinition } from 'redux-beacon'

import * as actions from 'mlp-client/src/analytics/actions'
import { TrackingEvent } from 'mlp-client/src/analytics/middleware'

export const trackPageView = (action: actions.PageView) => ({
  event: 'pageView',
  meta: action.payload,
})

export const flowNavigation: EventDefinition<TrackingEvent> | null = ({
  payload,
}: actions.FlowNavigation) => ({
  event: 'genericEvent',
  ...payload,
})

export const flowActivation: EventDefinition<TrackingEvent> | null = ({
  payload: { eventLabel },
}: actions.FlowActivation) => ({
  event: 'genericEvent',
  eventAction: 'click',
  eventCategory: 'dashboard',
  eventLabel,
})

export const footerNavigation: EventDefinition<TrackingEvent> | null = ({
  payload: { eventLabel },
}: actions.FooterNavigation) => ({
  event: 'genericEvent',
  eventCategory: 'internal navigation',
  eventAction: 'footer link',
  eventLabel,
})

export const mainMenuTopNavigation: EventDefinition<TrackingEvent> | null = ({
  payload: { eventLabel },
}: actions.MainMenuTopNavigation) => ({
  event: 'genericEvent',
  eventCategory: 'internal navigation',
  eventAction: 'main menu top link',
  eventLabel,
})

export const dashboardNavigation: EventDefinition<TrackingEvent> | null = ({
  payload: { eventLabel },
}: actions.DashboardNavigation) => ({
  event: 'genericEvent',
  eventCategory: 'internal navigation',
  eventAction: 'dashboard navigation',
  eventLabel,
})

export const flowCancellation: EventDefinition<TrackingEvent> | null = ({
  payload,
}: actions.FlowCancellation) => ({
  event: 'genericEvent',
  eventAction: 'click',
  eventLabel: 'flow cancellation',
  ...payload,
})

export const vehicleSelection: EventDefinition<TrackingEvent> | null = ({
  payload: { eventLabel },
}: actions.VehicleSelection) => ({
  event: 'genericEvent',
  eventCategory: 'internal navigation',
  eventAction: 'choose vehicle from dropdown',
  eventLabel,
})
