import React, { PureComponent } from 'react'
import { Grid, GridItem } from '@leaseplan/ui'

import { CellStyled } from '../Table.styled'

export interface Props {
  columnsAmount: number
}

export class EmptyStateWide extends PureComponent<Props> {
  render() {
    const { columnsAmount, children } = this.props

    return (
      <tbody>
        <tr>
          <CellStyled colSpan={columnsAmount}>
            <Grid justifyContent="center">
              <GridItem>{children || null}</GridItem>
            </Grid>
          </CellStyled>
        </tr>
      </tbody>
    )
  }
}

export default EmptyStateWide
