import { Radio, RadioList, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { AppState } from 'mlp-client/src/types'
import { Address, AddressTypes } from 'mlp-client/src/user/types'

export interface Props {
  homeAddress: Address | null
  workAddress: Address | null
  otherAddress: Address | null
  selectedAddressType: AddressTypes
  workAddressLabel: string
  homeAddressLabel: string
  otherAddressLabel: string
  onAddressChange(address: Address): () => void
}

export class AddressSelector extends React.PureComponent<Props> {
  render() {
    const {
      homeAddress,
      homeAddressLabel,
      workAddress,
      selectedAddressType,
      workAddressLabel,
      otherAddressLabel,
      otherAddress,
      onAddressChange,
    } = this.props

    if (!workAddress && !homeAddress && !otherAddress) {
      return null
    }

    const shouldShowOtherAddressForm = () => {
      if (!homeAddress && !workAddress) {
        return true
      }

      return false
    }

    return (
      <Spacing mb={2}>
        <RadioList direction="row">
          {workAddress && (
            <Radio
              name="address"
              label={workAddressLabel}
              value={workAddress.type}
              onChange={onAddressChange(workAddress)}
              checked={workAddress.type === selectedAddressType}
            />
          )}
          {homeAddress && (
            <Radio
              name="address"
              label={homeAddressLabel}
              value={homeAddress.type}
              onChange={onAddressChange(homeAddress)}
              checked={homeAddress.type === selectedAddressType}
            />
          )}
          <Radio
            name="address"
            label={otherAddressLabel}
            value={otherAddress.type}
            onChange={onAddressChange(otherAddress)}
            checked={
              shouldShowOtherAddressForm() ||
              otherAddress.type === selectedAddressType
            }
          />
        </RadioList>
      </Spacing>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'workAddressLabel' | 'homeAddressLabel' | 'otherAddressLabel'
>

const mapTranslationsToProps = {
  workAddressLabel: 'myLeaseplan.supplierMap.page.nearWork',
  homeAddressLabel: 'myLeaseplan.supplierMap.page.nearHome',
  otherAddressLabel: 'myLeaseplan.supplierMap.page.otherAddress',
}

const mapStateToProps = (state: AppState): ReduxProps =>
  getTranslations(state, mapTranslationsToProps)

export default connect(mapStateToProps)(AddressSelector)
