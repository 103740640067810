import {
  ButtonChevronRightIcon,
  ButtonLink,
  Grid,
  GridItem,
  ModalTrigger,
  Paper,
  Spacing,
} from '@leaseplan/ui'
import { Headline, Text, colorMixins } from '@velocity/ui'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { getShowAllFeatures } from 'mlp-client/src/selectors'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { ProgressBarWidget } from 'mlp-client/src/components/progress-bar/ProgressBarWidget'
import { ContentWrapper } from 'mlp-client/src/contracts/components/ContractPropertiesTable.styled'
import { Contract } from 'mlp-client/src/contracts/types'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import MileageUnitText from 'mlp-client/src/form/components/mileage/MileageUnitText'
import MileageUpdate from 'mlp-client/src/components/mileage-update-modal/MileageUpdate'
import { formatRelativeDate } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import {
  calculateExpectedMileage,
  getMonthDiff,
} from 'mlp-client/src/contracts/utils'

export interface Props {
  contract: Contract
  isShown: boolean
  showMileageUpdate: boolean
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
  text-transform: uppercase;
  font-size: 10px;
`

class ContractStatusBlock extends React.PureComponent<Props> {
  render() {
    const {
      contract: {
        id,
        startDate,
        vehicle,
        maximumAllowedMileage,
        duration,
        vehicle: { dateLastKnownMileage },
      },
      isShown,
      showMileageUpdate,
    } = this.props

    if (!isShown) {
      return null
    }

    const link = showMileageUpdate ? (
      <ModalTrigger
        trigger={
          <ButtonLink href="#" size="s" icon={ButtonChevronRightIcon}>
            <Translation id="myLeaseplan.contract.update" />
          </ButtonLink>
        }
        modalContent={
          <MileageUpdate
            contractId={id}
            lastKnownMileage={Number(vehicle.lastKnownMileage)}
          />
        }
      />
    ) : undefined
    const contractMonths = Number(duration)
    const monthsExpired = getMonthDiff(moment.now(), startDate)
    const allowedMileage = Number(maximumAllowedMileage)

    return (
      <Paper>
        <ContentWrapper>
          <Grid>
            <GridItem>
              {dateLastKnownMileage && (
                <Spacing mb={0.5}>
                  <SText bold>{formatRelativeDate(dateLastKnownMileage)}</SText>
                </Spacing>
              )}
              <Headline variant="200">
                <Translation id="myLeaseplan.contract.contract.contract.status" />
              </Headline>
            </GridItem>
          </Grid>
          <ProgressBarWidget
            maxValue={allowedMileage}
            currentValue={Number(vehicle.lastKnownMileage) || 0}
            expectedValue={calculateExpectedMileage(
              allowedMileage,
              contractMonths,
              monthsExpired,
            )}
            header={
              <Translation id="myLeaseplan.contract.contract.contract.properties.mileage" />
            }
            link={link}
            units={<MileageUnitText />}
            errorMessage={
              <Translation
                id="myLeaseplan.contract.contract.contract.mileageError"
                replace={{
                  number: () => (
                    <span key="myLeaseplan.contract.contract.contract.mileageError">
                      {numeral(
                        Number(vehicle.lastKnownMileage) - allowedMileage,
                      ).format()}{' '}
                      <MileageUnitText />
                    </span>
                  ),
                }}
              />
            }
            excessMessage={
              <Translation id="myLeaseplan.contract.contract.contract.mileageNotification" />
            }
            currentValueSize="400"
            dataE2eId="mileage"
          />

          <ProgressBarWidget
            maxValue={contractMonths}
            currentValue={monthsExpired}
            units={<Translation id="myLeaseplan.contract.contract.months" />}
            header={
              <Translation id="myLeaseplan.contract.contract.contract.properties.duration" />
            }
            errorMessage={
              <Translation
                id="myLeaseplan.contract.contract.contract.durationError"
                replace={{
                  number: numeral(monthsExpired - contractMonths).format(),
                }}
              />
            }
            currentValueSize="400"
            dataE2eId="duration"
          />
        </ContentWrapper>
      </Paper>
    )
  }
}

type ReduxProps = Pick<Props, 'contract' | 'isShown' | 'showMileageUpdate'>
export const mapStateToProps = (state: AppState): ReduxProps => ({
  contract: getCurrentContract(state),
  isShown: getShowAllFeatures(state),
  showMileageUpdate: !getIsMobileApp(state),
})

export { ContractStatusBlock }
export default connect(mapStateToProps)(ContractStatusBlock)
