import { Button, Spacing } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { NavigationParams } from 'mlp-client/src/types'

export interface Props {
  label: string
  link: string
  params?: NavigationParams
}

export const SubmitButton: React.StatelessComponent<Props> = props => {
  const { label, link, params } = props

  return (
    <Spacing mt={4} mb={1}>
      <Button
        component={RouteLink}
        to={link}
        params={params}
        stretch={true}
        data-e2e-id={'backToDashboard'}
      >
        <Translation id={label} />
      </Button>
    </Spacing>
  )
}

export default SubmitButton
