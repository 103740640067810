import { Link, mq } from '@leaseplan/ui'
import styled from 'styled-components'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export const StyledSupplier = styled.div`
  ${mq.from('mobileLandscape')`
    display: flex;
    align-items: center;
  `}
  ${mq.until('mobileLandscape')`
    display: block;
    text-align: center;
  `}
`

export const StyledSupplierSide = styled.div`
  width: 150px;
  ${mq.until('mobileLandscape')`
    margin: 0 auto 12px;
  `}

  ${mq.from('mobileLandscape')`
    flex: 0 0 150px;
    margin: 0 24px 0 0;
  `}
`

export const StyledSupplierMain = styled.div`
  overflow: hidden;
`

export const StyledSupplierLink = styled(Link)`
  display: block;
  overflow-wrap: break-word;
  text-decoration: none;
  color: ${ColorEnumSelector('bloodOrange')};
`
