import _omit from 'lodash/omit'
import _get from 'lodash/get'
import qs, { stringify as stringifyQS } from 'qs'
import pathToRegexp from 'path-to-regexp'

import { Routes } from 'mlp-client/src/localization/types'
import { NavigationParams } from 'mlp-client/src/types'
import { isExternal, isMail, isPhone } from 'mlp-client/src/utils'
import log from 'mlp-client/src/log'
import { Currencies, Locales } from 'mlp-client/src/localization/enums'

const LOCALE_VALIDATION = '[a-z]{2}-[a-z]{2}'

const MAPPED_EN_LU_PATH = 'lu/english'
const MAPPED_FR_LU_PATH = 'lu/francais'

export const getLocalizedRouteMatch = (
  routes: Routes,
  currentLocale: string,
  path: string,
): string => {
  // If path matches a routes lookup, get it from state
  if (path.match(/^([a-z0-9]+\.){1,}/i)) {
    const routeLookup = String(_get(routes, `${currentLocale}.${path}`) || '')

    return routeLookup && `/:locale(${LOCALE_VALIDATION})${routeLookup}`

    // otherwise return as the route itself (for example '/(select-country-language)?/' is not a route lookup)
  } else {
    return path
  }
}

/**
 * Returns true when the given string is a localized route identifier (LRI).
 *
 * We recognize a LRI by:
 *  - Should contain a . (But may not start with a .)
 *  - Should NOT contain a '/'
 *
 */
export const isLocalizedRouteIdentifier = (route: string): boolean =>
  !!route && !!route.match(/^[\w-]+\.[\w-]+/) && !route.match(/\//)

// Returns a given route and replaces possible variables (such as /some/route/:carTypeId) with values from the given replace object
export const getLocalizedRouteFromRouteList = (
  routes: Routes,
  locale: string,
  path: string,
  params: string | GenericObject = {},
) => {
  if (!path) {
    log.warn('getLocalizedRouteFromRouteList: Path empty')

    return null
  }

  if (!isLocalizedRouteIdentifier(path)) {
    return null
  }

  const route = String(_get(routes, `${locale}.${path}`) || '')

  if (!route) {
    log.warn(
      `getLocalizedRouteFromRouteList: Route not found in route list. Link the ${path} key to a page in the routes file`,
    )

    return null
  }

  return expandRoute(route, locale, params)
}

/**
 * Adds a trailing slash to a url if it is missing
 * For example:
 *
 * /nl-nl/overzicht/detail -> /nl-nl/overzicht/detail/
 * /nl-nl/overzicht/detail/ -> /nl-nl/overzicht/detail/
 * /nl-nl/overzicht/detail#hash -> /nl-nl/overzicht/detail/#hash
 * /nl-nl/overzicht/detail?query -> /nl-nl/overzicht/detail/?query
 * /nl-nl/overzicht/detail?query#hash -> /nl-nl/overzicht/detail/?query#hash
 * /nl-nl/overzicht/detail#query?hash -> Don't do this, it's crazy talk
 */

export const addTrailingSlash = (url: string): string => {
  if (!url || hasTrailingSlash(url)) {
    return url
  }

  const hasHash = url.match(/#/)
  const hasQuery = url.match(/\?/)

  if (hasQuery) {
    const urlParts = url.split('?')

    return `${urlParts[0]}/?${urlParts[1]}`
  } else if (hasHash) {
    const urlParts = url.split('#')

    return `${urlParts[0]}/#${urlParts[1]}`
  } else {
    return `${url}/`
  }
}

export const hasTrailingSlash = (url: string) => {
  if (!url) {
    return url
  }

  const queryPath = url.split('?')[0]
  const hashPath = url.split('#')[0]

  return (
    queryPath.charAt(queryPath.length - 1) === '/' ||
    hashPath.charAt(hashPath.length - 1) === '/'
  )
}

export class UrlPath {
  private basePath: string
  private directories: string[] = []
  private queryParams: GenericObject = {}

  constructor(basePath = '') {
    this.basePath = basePath
  }

  addDirectory(directory: string) {
    this.directories.push(directory)
  }

  addQueryParam(name: string, value: any) {
    if (!name) {
      return
    }

    this.queryParams[name] = Array.isArray(value) ? value.join(',') : value
  }

  private composeDirectories() {
    return addTrailingSlash(this.directories.join('/'))
  }

  private composeQueryParams() {
    return qs.stringify(this.queryParams, {
      addQueryPrefix: true,
      encode: false,
    })
  }

  toString(): string {
    return this.basePath + this.composeDirectories() + this.composeQueryParams()
  }
}
/**
 * Replaces route params
 *
 * For example:
 *
 * /business/word-zakelijk-leaser/:carTypeId/:colorId/:step?/
 *
 * should become:
 *
 * /business/word-zakelijk-leaser/123/345/6/
 *
 * Unused optional route params (/:step?/) will be removed if no matching key is found in the replace object
 */

export const replaceRouteParams = (
  to: string = '',
  params: GenericObject = {},
) => {
  const queryParams: { [key: string]: string } = {}
  /* First try stricter path-to-regex reverse compile
   * If it fails, continue with our own, looser implementation
   * TODO: Choose one method, preferably the stricter
   */

  try {
    const appendableQueryParams = { ...params }
    const baseUrl = pathToRegexp.compile(to)(params, {
      encode: (value, token) => {
        if (params[token.name]) {
          delete appendableQueryParams[token.name]
        }

        return value.replace(/\\/g, '')
      },
    })

    const urlPath = new UrlPath(baseUrl)

    Object.entries(appendableQueryParams).forEach(([k, v]) =>
      urlPath.addQueryParam(k, v),
    )

    return urlPath.toString()
  } catch {
    // Swallow errors
  }

  let path = to

  Object.keys(params).forEach((key: string): void => {
    const value = params[key]

    if (value) {
      const segmentValue: string = `/${value}`
      const regex = new RegExp(`/\:${key}(\\([a-z-_\\\\d+\\/]*\\))?\\??`, 'g')

      if (!path.match(regex)) {
        Object.assign(queryParams, {
          [key]: value,
        })
      } else {
        path = path.replace(regex, segmentValue)
      }
    }
  })

  // Clean up optional params
  path = path.replace(/\/:[a-z(.*\\+?\/)]*\?/gi, '')

  const queryString: string = stringifyQS(queryParams)

  if (queryString && queryString.length > 0) {
    path += `?${queryString}`
  }

  // Warn of non-optionals are still present
  if (path.match(/(\/:[a-z]*)/gi)) {
    // eslint-disable-next-line no-console
    console.warn(
      `replaceRouteParams: some route params were not replaced (route: ${path} - params: ${JSON.stringify(
        params,
      )})`,
    )
  }

  return path
}

export const expandRoute = (
  route: string,
  currentLocale: string,
  params: NavigationParams = {},
): string => {
  let routeWithResolvedParams: string

  if (params && typeof params === 'object') {
    // Sometimes locale would appear in the params and this would be added
    // to the url as '?locale=nl-nl' polluting search engine results.
    routeWithResolvedParams = replaceRouteParams(route, _omit(params, 'locale'))
  } else {
    routeWithResolvedParams = replaceRouteParams(route, {}) // Needs to happen anyways to remove unused route params

    if (typeof params === 'string') {
      routeWithResolvedParams += params
    }
  }

  if (
    isExternal(routeWithResolvedParams) ||
    isPhone(routeWithResolvedParams) ||
    isMail(routeWithResolvedParams)
  ) {
    return routeWithResolvedParams
  }

  return addTrailingSlash(`/${currentLocale}${routeWithResolvedParams}`)
}

export const getRouteTranslation = (
  locale: string,
  to: string,
  params: NavigationParams,
  routes: Routes,
) => {
  if (!isLocalizedRouteIdentifier(to)) {
    return to
  }

  const path = String(_get(routes, `${locale}.${to}`) || '')

  return !!path ? expandRoute(path, locale, params) : null
}

export const isKnownLocale = (loc: string | undefined): loc is Locales =>
  Boolean(loc) && Object.values(Locales).includes(loc as Locales)

export const formatPrice = (locale: Locales, currency: Currencies) => (
  price: number,
): string => {
  const intl = new Intl.NumberFormat([locale, Locales.EN_US], {
    style: 'currency',
    currency: currency || Currencies.EUR,
  })

  return intl.format(price)
}

export const localeToPathMappingLU = {
  [Locales.EN_LU]: MAPPED_EN_LU_PATH,
  [Locales.FR_LU]: MAPPED_FR_LU_PATH,
}
