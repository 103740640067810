import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import FuelCardsFlow, {
  FuelCardFlowConfiguration,
} from 'mlp-client/src/flows/fuel-card/components/flow/Flow'
import {
  CheckDetailsStep,
  FleetManagerStep,
  SelectFuelCardTypesStep,
  SelectServiceTypeStep,
  ThankYouStep,
} from 'mlp-client/src/flows/fuel-card/components/steps'
import {
  FuelcardServiceType,
  StepKey,
} from 'mlp-client/src/flows/fuel-card/types'

import { getFuelCardsSteps } from './utils'

const allSteps = {
  [StepKey.FLEET_MANAGER]: {
    stepKey: StepKey.FLEET_MANAGER,
    stepView: FleetManagerStep,
    stepConfig: {},
  },
  [StepKey.SELECT_FUEL_CARD_TYPES]: {
    stepKey: StepKey.SELECT_FUEL_CARD_TYPES,
    stepView: SelectFuelCardTypesStep,
    stepConfig: {},
  },
  [StepKey.SELECT_SERVICE_TYPE]: {
    stepKey: StepKey.SELECT_SERVICE_TYPE,
    stepView: SelectServiceTypeStep,
    stepConfig: {
      serviceTypes: [
        FuelcardServiceType.FORGOT_PIN,
        FuelcardServiceType.LOST_STOLEN,
        FuelcardServiceType.DAMAGED,
        FuelcardServiceType.NEW,
      ],
    },
  },
  [StepKey.CHECK_DETAILS]: {
    stepKey: StepKey.CHECK_DETAILS,
    stepView: CheckDetailsStep,
    stepConfig: {
      isLocationEditable: false,
    },
  },
  [StepKey.THANK_YOU]: {
    stepKey: StepKey.THANK_YOU,
    stepView: ThankYouStep,
    stepConfig: {
      hideCashClaim: false,
      isSupportVisible: true,
      isSupportInNonMalfunctionVisible: false,
      isTimelineVisible: true,
      isTimelineIfMalfunctionVisible: false,
      isBlockCardLabelVisible: false,
    },
  },
}

export const fuelCardsConfig: MyLeaseplanFeature<FuelCardFlowConfiguration> = {
  enabled: true,
  view: FuelCardsFlow,
  configuration: {
    allSteps,
    getSteps: getFuelCardsSteps,
  },
}
