import { createSelector } from 'reselect'

import {
  ContentSection,
  DocumentsSection,
  SiteCoreDocument,
} from 'mlp-client/src/content/types'
import { getDocumentItems as getDocumentItemsUtil } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/utils'
import { getContentSection } from 'mlp-client/src/content/utils'
import { getPageContent } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'
import { getUser } from 'mlp-client/src/user/selectors'
import { isValidBrand } from 'mlp-client/src/utils/checks/checks'
import { formatBrand } from 'mlp-client/src/utils'

export const pageContentEndpoint = 'myleaseplan/page/get/document downloads'

export const getSelectedDocumentId = (state: AppState) =>
  state.vehicleDocuments.selectedDocumentId

const isDocumentsSection = (
  section: ContentSection,
): section is DocumentsSection => 'Documents' in section

export const getDocuments = (state: AppState): readonly SiteCoreDocument[] => {
  const pageContent = getPageContent(state, pageContentEndpoint)
  const user = getUser(state)
  let documentsPath = 'Documents'

  if (isValidBrand(user?.brand)) {
    documentsPath = `${documentsPath}_${formatBrand(user?.brand)}`
  }

  const contentSections = pageContent?.Sections
  const documentSection =
    contentSections && getContentSection(contentSections, documentsPath)

  if (!documentSection || !isDocumentsSection(documentSection)) {
    return []
  }

  return (documentSection.Documents || []).filter(document => !!document.Title)
}

export const getSelectedDocument = createSelector(
  getDocuments,
  getSelectedDocumentId,
  (
    documents: readonly SiteCoreDocument[],
    selectedDocumentId: string,
  ): SiteCoreDocument =>
    documents.find(document => document.Title === selectedDocumentId),
)

export const getDocumentItems = createSelector(
  getDocuments,
  getDocumentItemsUtil,
)

export const isDocumentsLoading = (state: AppState) =>
  state.vehicleDocuments.isLoading
