import React from 'react'
import moment from 'moment'

import { formatDate } from 'mlp-client/src/utils'

export interface Props {
  date: moment.Moment | string | Date
  dateFormat?: string
}

export const FormatDate: React.FC<Props> = ({ date, dateFormat }) => (
  <span>{formatDate(date, dateFormat)}</span>
)
