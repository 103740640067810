import { mq, Spacing, Button, Grid } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { TileProps, ModalContent } from 'mlp-client/src/dashboard/type'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const StyledHeading = styled(Headline)`
  margin-bottom: 0;
`

const StyledGrid = styled(Grid)`
  width: 100vw;
  min-height: 100vh;
  text-align: center;

  ${mq.from('mobileLandscape')`
    max-width: 540px;
    min-height: 100px;
  `};
`

export interface Props extends TileProps {
  modalContent: ModalContent
}

export const DashboardModalContent: React.FC<Props> = props => {
  const { to, target, modalContent } = props
  const {
    title,
    icon: IconComponent,
    immediateAssistance,
    hotline,
    phoneNumber,
    button,
    damageReport,
  } = modalContent

  return (
    <StyledGrid justifyContent="center" alignItems="center">
      <Spacing
        pt={{ mobile: 2, mobileLandscape: 3 }}
        ph={{ mobile: 2, mobileLandscape: 4 }}
        pb={{ mobile: 2, mobileLandscape: 4 }}
      >
        <StyledHeading variant="400" component="h3" data-e2e-id="title">
          <Translation id={title} />
        </StyledHeading>

        <IconComponent />

        <div data-e2e-id="text-assistance">
          <Text component="p" bold>
            <Translation id={immediateAssistance} />
          </Text>
          <Text component="p">
            <Translation id={hotline} />{' '}
            <LocalizationContext.Consumer>
              {({ translate }) => (
                <Text component="span" bold>
                  <StyledLink href={`tel:${translate(phoneNumber)}`}>
                    {translate(phoneNumber)}
                  </StyledLink>
                </Text>
              )}
            </LocalizationContext.Consumer>
          </Text>
        </div>

        {to && button ? (
          <Spacing mt={4}>
            <StyledLink href={to} target={target}>
              <Button data-e2e-id="button-link">
                <Translation id={button} />
              </Button>
            </StyledLink>
          </Spacing>
        ) : damageReport ? (
          <Spacing mt={1} mb={3}>
            <Text component="p" data-e2e-id="text-damageReport">
              <Translation id={damageReport} />
            </Text>
          </Spacing>
        ) : null}
      </Spacing>
    </StyledGrid>
  )
}
