import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { CardLink } from 'mlp-client/src/components/card-link/CardLink'
import { EndOfLeaseOption } from 'mlp-client/src/end-of-lease/types'

export interface Props extends EndOfLeaseOption, RouteComponentProps {
  clickHandler?(e: React.MouseEvent<HTMLElement>): void
}

const HeaderBlock = styled(Spacing)`
  &:first-child {
    margin-top: 0;
  }
`

const buildHeaderElement = ({ title, description }: Props['header']) => (
  <HeaderBlock mt={3} mb={1}>
    <Headline variant="200">
      <Translation id={title} />
    </Headline>
    {description && (
      <Text>
        <Translation id={description} />
      </Text>
    )}
  </HeaderBlock>
)

export const Option: React.FC<Props> = props => {
  const {
    clickHandler,
    header,
    link,
    location: { pathname: returnTo },
    id,
    title,
    description,
  } = props

  return (
    <>
      {header && buildHeaderElement(header)}
      <Spacing mb={1}>
        <CardLink
          link={{ ...link, state: { returnTo } }}
          dataE2eId={id}
          title={title}
          description={description}
          clickHandler={clickHandler}
        />
      </Spacing>
    </>
  )
}

export default withRouter(Option)
