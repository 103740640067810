import { camelCase } from 'camel-case'

import {
  TyreDamageTypes,
  TyreServiceTypes,
} from 'mlp-client/src/flows/tyres/enums'
import { Options, Options as FormOptions } from 'mlp-client/src/form/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import {
  DamageCauses,
  DamageReportTypes,
  PartyDamages,
  WhoseFault,
} from 'mlp-client/src/flows/damage-report/types'
import { LiquidsType } from 'mlp-client/src/flows/cash-claim/enums'

const damageTypesTranslations = makeTranslationPath(
  'myLeaseplan.damageReport.types',
)

const whoseFaultTranslations = makeTranslationPath(
  'myLeaseplan.damageReport.steps.whatHappened',
)

const selectCashClaimCategoriesTranslations = makeTranslationPath(
  'myLeaseplan.cashClaim.categories',
)

export const options = {
  damageTypes: [
    {
      title: `myLeaseplan.newSetup.flows.tyres.constants.damageTypes.${camelCase(
        TyreDamageTypes.PUNCTURE,
      )}`,
      value: TyreDamageTypes.PUNCTURE,
      image: {
        backgroundImage: '/static/images/my-leaseplan/tyres-punctured-tyre.jpg',
        backgroundSize: 'cover',
      },
    },
    {
      title: `myLeaseplan.newSetup.flows.tyres.constants.damageTypes.${camelCase(
        TyreDamageTypes.OTHER,
      )}`,
      value: TyreDamageTypes.OTHER,
      image: {
        backgroundImage: '/static/images/my-leaseplan/tyres-other-damage.jpg',
        backgroundSize: 'cover',
      },
    },
  ],
  tyreServices: Object.values(TyreServiceTypes).map((item: string) => ({
    value: item,
    title: `myLeaseplan.newSetup.flows.tyres.constants.services.${item}`,
  })),
}

export const otherOnePartyDamage = {
  title: damageTypesTranslations('other'),
  value: DamageReportTypes.OTHER,
  damageCause: DamageCauses.ONE_PARTY,
}
export const backingOnePartyDamage = {
  title: damageTypesTranslations('backing'),
  value: DamageReportTypes.BACKING,
  damageCause: DamageCauses.ONE_PARTY,
}
export const fireOnePartyDamage = {
  title: damageTypesTranslations('fireDamage'),
  value: DamageReportTypes.FIRE_DAMAGE,
  damageCause: DamageCauses.ONE_PARTY,
}
export const vandalismOnePartyDamage = {
  title: damageTypesTranslations('vandalism'),
  value: DamageReportTypes.VANDALISM,
  damageCause: DamageCauses.ONE_PARTY,
}
export const burglaryOnePartyDamage = {
  title: damageTypesTranslations('burglary'),
  value: DamageReportTypes.BURGLARY,
  damageCause: DamageCauses.ONE_PARTY,
}
export const fallenObjectsOnePartyDamage = {
  title: damageTypesTranslations('fallenObjects'),
  value: DamageReportTypes.FALLEN_OBJECTS,
  damageCause: DamageCauses.ONE_PARTY,
}
export const parkingOnePartyDamage = {
  title: damageTypesTranslations('Parking'),
  value: DamageReportTypes.PARKING,
  damageCause: DamageCauses.ONE_PARTY,
}
export const animalsOnePartyDamage = {
  title: damageTypesTranslations('animals'),
  value: DamageReportTypes.ANIMALS,
  damageCause: DamageCauses.ONE_PARTY,
}
export const oneSidedCollisionOnePartyDamage = {
  title: damageTypesTranslations('oneSidedCollision'),
  value: DamageReportTypes.ONE_SIDED_COLLISION,
  damageCause: DamageCauses.ONE_PARTY,
}
export const theftOfVehicleOnePartyDamage = {
  title: damageTypesTranslations('theftOfVehicle'),
  value: DamageReportTypes.THEFT,
  damageCause: DamageCauses.ONE_PARTY,
}
export const attemptToTheftOnePartyDamage = {
  title: damageTypesTranslations('attemptToTheft'),
  value: DamageReportTypes.THEFT_ATTEMPT,
  damageCause: DamageCauses.ONE_PARTY,
}

export const openedDoorTwoPartyDamage = {
  title: damageTypesTranslations('openedDoor'),
  value: DamageReportTypes.OPENED_DOOR,
  damageCause: DamageCauses.TWO_PARTY,
}
export const otherTwoPartyDamage = {
  title: damageTypesTranslations('other'),
  value: DamageReportTypes.OTHER,
  damageCause: DamageCauses.TWO_PARTY,
}
export const backingTwoPartyDamage = {
  title: damageTypesTranslations('backing'),
  value: DamageReportTypes.BACKING,
  damageCause: DamageCauses.TWO_PARTY,
}
export const twoSidedCollisionTwoPartyDamage = {
  title: damageTypesTranslations('twoSidedCollision'),
  value: DamageReportTypes.TWO_SIDED_COLLISION,
  damageCause: DamageCauses.TWO_PARTY,
}
export const multipleCollisionTwoPartyDamage = {
  title: damageTypesTranslations('multipleCollision'),
  value: DamageReportTypes.MULTIPLE_COLLISION,
  damageCause: DamageCauses.TWO_PARTY,
}
export const fallenObjectsTwoPartyDamage = {
  title: damageTypesTranslations('fallenObjectsTP'),
  value: DamageReportTypes.FALLEN_OBJECTS_TP,
  damageCause: DamageCauses.TWO_PARTY,
}
export const parkedCarTwoPartyDamage = {
  title: damageTypesTranslations('parkedCar'),
  value: DamageReportTypes.PARKED_CAR,
  damageCause: DamageCauses.TWO_PARTY,
}
export const lostEffectsTwoPartyDamage = {
  title: damageTypesTranslations('lostEffects'),
  value: DamageReportTypes.LOST_EFFECTS,
  damageCause: DamageCauses.TWO_PARTY,
}
export const changedLanesTwoPartyDamage = {
  title: damageTypesTranslations('changedLanes'),
  value: DamageReportTypes.CHANGED_LANES,
  damageCause: DamageCauses.TWO_PARTY,
}
export const rightOfWayTwoPartyDamage = {
  title: damageTypesTranslations('rightOfWay'),
  value: DamageReportTypes.RIGHT_OF_WAY,
  damageCause: DamageCauses.TWO_PARTY,
}
export const damageNonFaultTwoPartyDamage = {
  title: damageTypesTranslations('damageNonFault'),
  value: DamageReportTypes.DAMAGE_NON_FAULT,
  damageCause: DamageCauses.TWO_PARTY,
}
export const damageSupplierTwoPartyDamage = {
  title: damageTypesTranslations('damageSupplier'),
  value: DamageReportTypes.DAMAGE_SUPPLIER,
  damageCause: DamageCauses.TWO_PARTY,
}
export const damageToPropertyTwoPartyDamage = {
  title: damageTypesTranslations('damageToProperty'),
  value: DamageReportTypes.DAMAGE_TO_PROPERTY,
  damageCause: DamageCauses.TWO_PARTY,
}
export const intakeCarDamageOnePartyDamage = {
  title: damageTypesTranslations('intakeCarDamage'),
  value: DamageReportTypes.INTAKE_CAR_DAMAGE,
  damageCause: DamageCauses.ONE_PARTY,
}
export const naturalPerilsOnePartyDamage = {
  title: damageTypesTranslations('naturalPerils'),
  value: DamageReportTypes.NATURAL_PERILS,
  damageCause: DamageCauses.ONE_PARTY,
}
export const occurencePossibleWbfTwoPartyDamage = {
  title: damageTypesTranslations('occurencePossibleWbf'),
  value: DamageReportTypes.OCCURRENCE_POSSIBLE_WBF,
  damageCause: DamageCauses.TWO_PARTY,
}
export const chainCollisionTwoPartyDamage = {
  title: damageTypesTranslations('chainCollision'),
  value: DamageReportTypes.CHAIN_COLLISION,
  damageCause: DamageCauses.TWO_PARTY,
}
export const otherAtFaultTwoPartyDamage = {
  title: damageTypesTranslations('otherAtFault'),
  value: DamageReportTypes.OTHER_AT_FAULT,
  damageCause: DamageCauses.TWO_PARTY,
}
export const partialTheftOnePartyDamage = {
  title: damageTypesTranslations('partialTheft'),
  value: DamageReportTypes.PARTIAL_THEFT,
  damageCause: DamageCauses.ONE_PARTY,
}
export const pedestrianBicycleTramTwoPartyDamage = {
  title: damageTypesTranslations('pedestrianBicycleTram'),
  value: DamageReportTypes.PEDESTRIAN_BICYCLE_TRAM,
  damageCause: DamageCauses.TWO_PARTY,
}
export const rearendedTwoPartyDamage = {
  title: damageTypesTranslations('rearended'),
  value: DamageReportTypes.REARENDED,
  damageCause: DamageCauses.TWO_PARTY,
}
export const reversingTwoPartyDamage = {
  title: damageTypesTranslations('reversing'),
  value: DamageReportTypes.REVERSING,
  damageCause: DamageCauses.TWO_PARTY,
}
export const rsaFnolReportOnePartyDamage = {
  title: damageTypesTranslations('rsaFnolReport'),
  value: DamageReportTypes.RSA_FNOL_REPORT,
  damageCause: DamageCauses.ONE_PARTY,
}
export const satNavTheftOnePartyDamage = {
  title: damageTypesTranslations('satNavTheft'),
  value: DamageReportTypes.SAT_NAV_THEFT,
  damageCause: DamageCauses.ONE_PARTY,
}

export const uninsuredLossOnePartyDamage = {
  title: damageTypesTranslations('uninsuredLoss'),
  value: DamageReportTypes.UNINSURED_LOSS,
  damageCause: DamageCauses.ONE_PARTY,
}

export const vehicleTheftDamageOnePartyDamage = {
  title: damageTypesTranslations('vehicleTheftDamage'),
  value: DamageReportTypes.VEHICLE_THEFT_DAMAGE,
  damageCause: DamageCauses.ONE_PARTY,
}

export const partyDamages: PartyDamages = []

export const whoseFault: FormOptions = [
  {
    title: whoseFaultTranslations('myFault'),
    value: WhoseFault.MY_FAULT,
  },
  {
    title: whoseFaultTranslations('otherFault'),
    value: WhoseFault.OTHER_FAULT,
  },
]

export const liquids: Options = [
  {
    title: selectCashClaimCategoriesTranslations(
      'liquids.subcategories.adBlue.title',
    ),
    value: LiquidsType.AD_BLUE,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'liquids.subcategories.coolant.title',
    ),
    value: LiquidsType.COOLANT,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'liquids.subcategories.oil.title',
    ),
    value: LiquidsType.OIL,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'liquids.subcategories.windscreenWiper.title',
    ),
    value: LiquidsType.WIND_SCREEN_WIPER,
  },
]
