import { Text } from '@velocity/ui'
import moment from 'moment'
import React from 'react'

import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { INVARIANT_DATE_FORMAT } from 'mlp-client/src/constants'

import { EditButtonLabel } from './EditButtonLabel'

interface Props {
  dateTime: Date | string
  dateFormat?: string
  title: React.ReactElement | string
  onClick(): void
}

type DefaultProps = Required<Pick<Props, 'dateFormat'>>
type InternalProps = DefaultProps & Props

export class DateTimeTile extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    dateFormat: 'LLLL',
  }

  render() {
    const { title, dateFormat, dateTime, onClick } = this.props

    return (
      <FlowStepTile
        dataE2eId="date"
        title={title}
        btnLabel={EditButtonLabel}
        onBtnClick={onClick}
      >
        <Text variant="200">
          {moment(dateTime, INVARIANT_DATE_FORMAT).format(dateFormat)}
        </Text>
      </FlowStepTile>
    )
  }
}
