import { Documents } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/types'
import { SiteCoreDocument } from 'mlp-client/src/content/types'
import { Locales } from 'mlp-client/src/localization/enums'

import { DownloadableDocumentValues } from './enums'

export const getDocumentItems = (
  vehicleDocuments: SiteCoreDocument[],
): Documents =>
  vehicleDocuments.map(vehicleDocument => ({
    documentId: vehicleDocument.Title || '',
    title: vehicleDocument.Title,
    intro: vehicleDocument.Intro,
    description: vehicleDocument.Description,
    downloadUrl: vehicleDocument.DownloadUrl,
    externalUrl: vehicleDocument.ExternalUrl,
    requestable: Boolean(
      vehicleDocument &&
        vehicleDocument.DocumentType &&
        vehicleDocument.DocumentType.Name,
    ),
    name: vehicleDocument._Name,
    downloadable: DownloadableDocumentValues.includes(vehicleDocument._Name),
  }))

export const isDenmark = (locale: Locales): boolean =>
  [Locales.DA_DK, Locales.EN_DK].includes(locale)
