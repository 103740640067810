import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { Weekday } from 'mlp-client/src/enums'
import { MappedAddress } from 'mlp-client/src/types'

export enum FuelcardServiceType {
  FORGOT_PIN = 'forgotPin',
  LOST_STOLEN = 'lostStolen',
  MALFUNCTIONING = 'malfunctioning',
  DAMAGED = 'Damaged',
  ALL_STOLEN = 'allStolen',
  NEW = 'new',
}

export enum StepKey {
  SELECT_FUEL_CARD = 'selectFuelCard',
  SELECT_FUEL_CARD_TYPES = 'selectFuelCardTypes',
  SELECT_SERVICE_TYPE = 'selectServiceType',
  SELECT_LOCATION = 'selectLocation',
  CHECK_DETAILS = 'checkDetails',
  THANK_YOU = 'thankYou',
  FLEET_MANAGER = 'fleetManager',
  ALL_LOST_AND_STOLEN_CONFIRM = 'allLostAndStolenConfirm',
}

// FlowData Type should describe all the data we collect form steps
export interface FlowData {
  hasWorkAddress: boolean
  fuelCardServiceType?: string
  fuelCardIDs?: readonly string[]
  fuelCardTypes?: readonly string[]
}

export type FuelCardType = string
export type FuelCardTypes = readonly FuelCardType[]

export interface FuelCardSubmitBody {
  reason: string
  fuelcardIds: readonly string[]
  address: MappedAddress
}

export interface FuelCard {
  cardNumber: string
  name: string
  validFrom: string
  validTo: string
  status: string
}

export type FuelCards = readonly FuelCard[]

export interface StaticSupplier {
  name: string
  phone: string
  logo: string
  workingHours: string
  isWorkingOnlyWeekDays?: boolean
  key: string
}

export interface CallUsTileContractWorkingHours {
  startDay: Weekday
  endDay?: Weekday
  startHour: string
  endHour: string
}

export interface CallUsTileContact {
  phoneNumber: string
  subtitle: string
  workingHours?: readonly CallUsTileContractWorkingHours[]
}

export interface CallUsTile {
  title: string
  subtitle?: string
  logo?: React.ComponentType<IconProps>
  contacts?: readonly CallUsTileContact[]
}

export type StaticSuppliers = readonly StaticSupplier[]
