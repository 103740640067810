import React from 'react'

interface Props {
  onChange?(event: GenericObject, index: number): void
  render(args: GenericObject): any
  value: string
}

export interface OptionProps {
  value: string
  group: string
  name: string
  index: number
  focusHandler(focus: boolean): void
  disabled?: boolean
}

class RadioList extends React.PureComponent<Props> {
  getOptionProps = ({
    value,
    group,
    name,
    index,
    focusHandler,
    disabled = false,
  }: OptionProps) => {
    const handlers = {
      onChange: (event: GenericObject) =>
        this.props.onChange && this.props.onChange(event, index),
      onFocus: () => focusHandler(true),
      onBlur: () => focusHandler(false),
    }

    return {
      value,
      checked: this.getIsSelected(value),
      type: 'radio',
      name: `${group}-${name}`,
      id: `field--${group}--${name}-${index}`,
      ...(disabled ? { readOnly: true } : handlers),
    }
  }

  getIsSelected = (value: string) => this.props.value === value

  render() {
    return this.props.render({
      getOptionProps: this.getOptionProps,
      getIsSelected: this.getIsSelected,
    })
  }
}

export default RadioList
