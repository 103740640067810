import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import { StepViewProps } from 'mlp-client/src/flows/buy/types'
import { makeTranslationPath } from 'mlp-client/src/utils'

const buildTranslationId = makeTranslationPath('myLeaseplan.buy.steps.thankYou')

export interface Props extends StepViewProps {}

export class ThankYouStep extends React.PureComponent<Props> {
  render() {
    const {
      closeFlow,
      flowData: { user },
    } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline variant="400" component="h1" withMarginBottom>
          <Translation id={buildTranslationId('title')} />
        </Headline>
        <Section headerText={buildTranslationId('whatsNext')}>
          <Translation
            replace={{ email: user.email }}
            id={buildTranslationId('quoteLabel')}
          />
          <Translation id={buildTranslationId('statusLabel')} />
        </Section>
      </ThankyouPageLayout>
    )
  }
}

export default ThankYouStep
