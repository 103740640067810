import { ButtonLink, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'

const OrderTitleFallback: React.FC = (): React.ReactElement => (
  <>
    <Spacing mb={2}>
      <Breadcrumbs>
        <ButtonLink
          to={'myLeaseplan.vehicles'}
          component={RouteLink}
          size="s"
          fontWeight="regular"
          color="steel60"
        >
          <Translation id="myLeaseplan.subNavigation.vehicles" />
        </ButtonLink>
        <Translation id="myLeaseplan.orderStatus.title" />
      </Breadcrumbs>
    </Spacing>
    <Spacing mb={3}>
      <Headline variant="400" withMarginBottom component="h1">
        <Translation id="myLeaseplan.orderStatus.vehicleStatus" />
      </Headline>
    </Spacing>
  </>
)

export default React.memo(OrderTitleFallback)
