import React from 'react'
import { Text } from '@velocity/ui'
import styled from 'styled-components'

const StyledCardTag = styled.div<{ backgroundColor: string }>`
  display: inline-flex;
  padding: 1px 6px;
  border-radius: 2px;
  letter-spacing: 1px;
  background-color: ${props => props.backgroundColor};
`

const SText = styled(Text)`
  color: ${props => props.theme.velocity.styling.gray.white};
  text-transform: uppercase;
`

export interface Props {
  children?: React.ReactNode
  backgroundColor?: string
  dataE2eId?: string
}

const CardTag: React.FC<Props> = ({
  dataE2eId,
  children,
  backgroundColor = 'petrolBlue',
}) => (
  <StyledCardTag data-e2e-id={dataE2eId} backgroundColor={backgroundColor}>
    <SText bold variant="100">
      {children}
    </SText>
  </StyledCardTag>
)

export { CardTag }
