import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  ExtendContractFlow,
  ExtendLeaseContract,
  ThankYouStep,
} from 'mlp-client/src/flows/extend-contract/components'
import { StepNames } from 'mlp-client/src/flows/extend-contract/enums'
import {
  ExtendContractFlowConfiguration,
  Steps,
} from 'mlp-client/src/flows/extend-contract/types'

const allSteps: Steps = {
  [StepNames.EXTEND_LEASE_CONTRACT]: {
    stepView: ExtendLeaseContract,
    stepConfig: {
      minExtensionDuration: 1,
      maxExtensionDuration: 60,
    },
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {},
  },
}

export const extendContractFeature: MyLeaseplanFeature<ExtendContractFlowConfiguration> = {
  enabled: true,
  view: ExtendContractFlow,
  configuration: {
    getSteps: () => [StepNames.EXTEND_LEASE_CONTRACT, StepNames.THANK_YOU],
    allSteps,
  },
}
