import { colorMixins, Text } from '@velocity/ui'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  min-width: 0;
`
export const VehicleModel = styled(Text)`
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const LicensePlate = styled(Text)`
  white-space: nowrap;
  border: 1px solid ${colorMixins.gray20Color};
  padding: 0 0.5em;
  border-radius: 2px;
  background: ${props => props.theme.velocity.styling.gray.white};
  display: inline-block;
  height: 26px;
  line-height: 1.6;
  color: ${colorMixins.grayMainColor};
`
LicensePlate.displayName = 'LicensePlate'
