import { Color, Link, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { ReplaceLabels } from 'mlp-client/src/features-configuration/types'
import { getAdditionalServicesOptions } from 'mlp-client/src/flows/maintenance/utils'
import { CheckboxList } from 'mlp-client/src/form/components/fields/CheckboxFields'

const LinkStyled = styled(Link)`
  color: ${Color.thunder};
`

export interface Props {
  additionalServices: ReadonlyArray<readonly string[]>
  selectedAdditionalServices: Record<string, boolean>
  collectAndReturnWithoutSuppliersStep: boolean
  isDisclaimerEnabled: boolean
  replaceLabels?: ReplaceLabels
}

export class AdditionalServices extends React.PureComponent<Props> {
  getOptions = () => {
    const {
      additionalServices,
      selectedAdditionalServices,
      collectAndReturnWithoutSuppliersStep,
      replaceLabels,
    } = this.props

    return getAdditionalServicesOptions(
      additionalServices,
      selectedAdditionalServices,
      collectAndReturnWithoutSuppliersStep,
      replaceLabels,
    )
  }

  renderDisclaimer = () => {
    const { isDisclaimerEnabled } = this.props

    if (!isDisclaimerEnabled) {
      return null
    }

    return (
      <Spacing mb={4}>
        <Text variant="100">
          <Translation
            id="myLeaseplan.maintenance.steps.selectMaintenanceType.disclaimer"
            data-e2e-id="additionalServices_disclaimer"
          />
          <LinkStyled
            to="myLeaseplan.contract.detail"
            component={RouteLink}
            data-e2e-id="myLeaseplan.contract.detail"
          >
            <Text variant="100">
              <Translation id="myLeaseplan.maintenance.steps.selectMaintenanceType.disclaimerLink" />
            </Text>
          </LinkStyled>
        </Text>
      </Spacing>
    )
  }

  render() {
    const { additionalServices } = this.props

    if (!additionalServices || !additionalServices.length) {
      return null
    }

    return (
      <>
        <Spacing mb={2}>
          <Headline variant="200" component="h3">
            <Translation id="myLeaseplan.maintenance.steps.selectAdditionalServices.subtitle" />
          </Headline>
        </Spacing>

        <CheckboxList name="additionalServices" options={this.getOptions()} />
        {this.renderDisclaimer()}
      </>
    )
  }
}
