import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { filterRouteParams } from 'mlp-client/src/components/myleaseplan-flow/utils'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import {
  ExtendContractFlowConfiguration,
  FlowData,
  StepDefinition,
} from 'mlp-client/src/flows/extend-contract/types'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'
import { AppState } from 'mlp-client/src/types'

export interface Props
  extends MyLeaseplanFeatureProps<ExtendContractFlowConfiguration> {
  contractId: string
}

interface State {
  returnToPath?: string
}

export class Flow extends React.PureComponent<Props, State> {
  state: State = {}

  static getDerivedStateFromProps(props: Props, state: State) {
    const { state: locationState } = props.location

    if (locationState && locationState.returnTo && !state.returnToPath) {
      return {
        returnToPath: locationState.returnTo,
      }
    }

    return null
  }

  renderStep = (stepParameters: FlowProps<FlowData>) => {
    const { name } = stepParameters.activeStep
    const {
      configuration: { allSteps },
    } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = allSteps[name] as StepDefinition

    if (StepView) {
      return <StepView {...stepParameters} config={{ ...stepConfig }} />
    }

    return <NotFound />
  }

  onFlowClose = (isMoveBack: boolean) => {
    const { match, contractId } = this.props
    const exitPath =
      (isMoveBack && this.state.returnToPath) || 'myLeaseplan.dashboard'
    const params = {
      ...filterRouteParams(match.params),
      contractId,
    }

    return <TranslatedRedirect to={exitPath} params={params} />
  }

  render() {
    const { featureConfig, contractId, user } = this.props

    return (
      <MyleaseplanFlow
        stepTitlePrefix="myLeaseplan.extendContract"
        routePrefix="myLeaseplan.extendContract.steps"
        getSteps={featureConfig.configuration.getSteps}
        onClose={this.onFlowClose}
        render={this.renderStep}
        initialFlowData={{ contractId, user }}
        eventCategory={EventCategoryService.FAST_SERVICE}
        flowName={EventCategoryFlow.EXTEND_CONTRACT}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'contractId' | 'user'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  contractId: getCurrentContractId,
})

export default connect(mapStateToProps)(Flow)
