import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { FAQItemType } from 'mlp-client/src/FAQ/types'
import { AppState } from 'mlp-client/src/types'

export const getFAQs = (
  state: AppState,
  faqKey: string,
): readonly FAQItemType[] | undefined => {
  const locale = getUserLocale(state)

  if (!locale) {
    return undefined
  }

  const faqKeyWithLocale = `${faqKey}_${locale}`

  return state.FAQ[faqKeyWithLocale]
}
