import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { options } from 'mlp-client/src/features-configuration/data'
import { tyresSuppliers } from 'mlp-client/src/features-configuration/lu/data'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import Flow from 'mlp-client/src/flows/tyres/components/flow/Flow'
import { RoadSideAssistancePage } from 'mlp-client/src/flows/tyres/components/pages'
import {
  ConfirmationStep,
  DamageTypeStep,
  ProblemDescriptionStep,
  ThankYouStep,
  TyreServicesStep,
  TyreServiceStep,
} from 'mlp-client/src/flows/tyres/components/steps'
import { StepNames, TyreServiceTypes } from 'mlp-client/src/flows/tyres/enums'
import {
  FlowData,
  Steps,
  TyresFlowConfiguration,
} from 'mlp-client/src/flows/tyres/types'

const allSteps: Steps = {
  [StepNames.CONFIRMATION]: {
    stepView: ConfirmationStep,
    stepConfig: {},
  },
  [StepNames.SEASONAL_TYRES_SUPPLIER]: {
    stepView: TyreServicesStep,
    stepConfig: {
      suppliers: tyresSuppliers,
      isBannerDisabled: true,
      headerSubtitleLabel:
        'myLeaseplan.newSetup.pages.tyreServices.partnerContact',
      headerSubtitleSinglePartnerLabel:
        'myLeaseplan.newSetup.pages.tyreServices.singlePartnerContact',
      faqKey: FAQKeys.SEASONAL_TYRES_PAGE,
    },
  },
  [StepNames.TYRES_SUPPLIER]: {
    stepView: TyreServicesStep,
    stepConfig: {
      suppliers: tyresSuppliers,
      isBannerDisabled: true,
      headerSubtitleLabel:
        'myLeaseplan.newSetup.pages.tyreServices.partnerContact',
      headerSubtitleSinglePartnerLabel:
        'myLeaseplan.newSetup.pages.tyreServices.singlePartnerContact',
      faqKey: FAQKeys.SEASONAL_TYRES_PAGE,
    },
  },
  [StepNames.DAMAGE_TYPE]: {
    stepView: DamageTypeStep,
    stepConfig: {
      damageTypes: options.damageTypes,
    },
  },
  [StepNames.PROBLEM_DESCRIPTION]: {
    stepView: ProblemDescriptionStep,
    stepConfig: {},
  },
  [StepNames.TYRE_SERVICE]: {
    stepView: TyreServiceStep,
    stepConfig: {
      tyreServices: options.tyreServices,
      showModal: {
        [TyreServiceTypes.WORN_OUT_TYRES]: {
          text:
            'myLeaseplan.newSetup.flows.tyres.constants.servicesModal.luxembourg.modalText',
        },
        [TyreServiceTypes.SEASONAL_TYRE_CHANGE]: {
          text:
            'myLeaseplan.newSetup.flows.tyres.constants.servicesModal.luxembourg.modalText',
        },
        [TyreServiceTypes.TYRE_PRESSURE_WARNING]: {
          text:
            'myLeaseplan.newSetup.flows.tyres.constants.servicesModal.luxembourg.modalText',
        },
        [TyreServiceTypes.STEERING_PULLING_LEFT_RIGHT]: {
          text:
            'myLeaseplan.newSetup.flows.tyres.constants.servicesModal.luxembourg.modalText',
        },
        [TyreServiceTypes.STEERING_VIBRATION]: {
          text:
            'myLeaseplan.newSetup.flows.tyres.constants.servicesModal.luxembourg.modalText',
        },
      },
    },
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {},
  },
  [StepNames.ROADSIDE_ASSISTANCE]: {
    stepView: RoadSideAssistancePage,
    stepConfig: {},
  },
}

export const getSteps = (flowData: FlowData): readonly StepNames[] => {
  const { tyreServiceType } = flowData

  switch (tyreServiceType) {
    case TyreServiceTypes.DAMAGED_TYRES:
      return [
        StepNames.TYRE_SERVICE,
        StepNames.DAMAGE_TYPE,
        StepNames.ROADSIDE_ASSISTANCE,
      ]

    case TyreServiceTypes.WORN_OUT_TYRES:
    case TyreServiceTypes.TYRE_PRESSURE_WARNING:
      return [StepNames.TYRES_SUPPLIER]

    case TyreServiceTypes.SEASONAL_TYRE_CHANGE:
      // here we only return our custom page due to supplier is not sent from backend yet
      // @TODO implement additional logic when we can receive supplier info
      return [StepNames.SEASONAL_TYRES_SUPPLIER]

    case TyreServiceTypes.STEERING_PULLING_LEFT_RIGHT:
    case TyreServiceTypes.STEERING_VIBRATION:
      return [StepNames.TYRES_SUPPLIER]

    case TyreServiceTypes.OTHER:
      return [
        StepNames.TYRE_SERVICE,
        StepNames.PROBLEM_DESCRIPTION,
        StepNames.CONFIRMATION,
        StepNames.THANK_YOU,
      ]

    default:
      return [StepNames.TYRE_SERVICE]
  }
}

export const tyresConfig: MyLeaseplanFeature<TyresFlowConfiguration> = {
  enabled: true,
  view: Flow,
  configuration: {
    allSteps,
    getSteps,
  },
}
