import { Weekday } from 'mlp-client/src/enums'
import {
  Geolocation,
  Supplier,
  SupplierAddress,
  WorkingHours,
} from 'mlp-client/src/types'
import { Coordinates } from 'mlp-client/src/components/map/types'

export const getFormattedAddress = (supplier: Supplier): string => {
  const { street, postcode, city, country } = supplier.address

  return [street, postcode, city, country].filter(x => x).join(', ')
}

export const todayOpeningHoursAsString = (supplier: Supplier): string => {
  const todayDate = new Date()
  const today = Weekday[todayDate.getDay()].toLowerCase()

  if (supplier.openingHours) {
    return openingHoursAsString(supplier.openingHours[today])
  }
}

export const openingHoursAsString = (
  openingHours: readonly WorkingHours[],
): string => {
  if (!openingHours || !openingHours.length) {
    return ''
  }

  return openingHours.map(({ from, until }) => `${from} — ${until}`).join(', ')
}

export const isSupplierOpen = (
  supplier: Supplier,
  dayIndex: number,
  hour: number,
) => {
  if (isNaN(dayIndex) || isNaN(hour) || !supplier.openingHours) {
    return false
  }

  const weekday = Weekday[dayIndex].toLowerCase()
  const openingHours = supplier.openingHours[weekday]

  if (openingHours.length) {
    return openingHours.some((workingHours: WorkingHours) => {
      if (!workingHours) {
        return false
      }

      const startHour = timeToInt(workingHours.from, true)
      const endHour = timeToInt(workingHours.until, false)

      return startHour <= hour && hour <= endHour
    })
  }

  return false
}

export const getFormattedSupplierAddress = (
  address: SupplierAddress,
): string => {
  const { street, number, suffix, postcode, city, country } = address
  const streetAndNr = number ? `${street} ${number}` : street

  return [streetAndNr, suffix, postcode, city, country]
    .filter(x => x)
    .join(', ')
}

export const getSupplierLocation = (
  address: SupplierAddress,
  location: Geolocation,
  name: string,
) => ({
  address: getFormattedSupplierAddress(address),
  coordinates: formatCoordinates(location),
  title: name,
})

export const timeToInt = (time: string, isStartTime?: boolean): number => {
  const timeAsNumber = parseFloat(time.replace(':', '.'))

  // If number is float we should round it
  // because we only support hours in dropdown time
  if (timeAsNumber % 1 !== 0 && isStartTime) {
    // start time should be incremented 08:30 => 09:00
    return Math.trunc(timeAsNumber) + 1
  }

  // if the number is not float or it's not start time
  // 18:30 => 18:00
  return Math.trunc(timeAsNumber)
}

const formatCoordinates = (geolocation: Geolocation): Coordinates => ({
  lat: geolocation?.latitude || 0,
  lng: geolocation?.longitude || 0,
})
