import { TextProps, VelocityConsumer } from '@velocity/ui'
import React from 'react'

import {
  Container,
  LicensePlate,
  VehicleModel,
} from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense.styled'
import Translation from 'mlp-client/src/localization/Translation'
import { QuotesStatuses } from 'mlp-client/src/quotes/enums'

export interface Vehicle {
  id?: string
  model: string
  licensePlate?: string
  isFuelManagement?: boolean
  status?: QuotesStatuses
  imageUrl?: string
}

export interface Props {
  vehicle: Vehicle
  textColor?: string
  textSize?: TextProps['variant']
  bold?: TextProps['bold']
  dataE2EId?: string
}

class VehicleModelLicense extends React.PureComponent<Props> {
  render() {
    const {
      textColor,
      bold = false,
      textSize = '200',
      dataE2EId = 'activeVehicle',
      vehicle,
    } = this.props

    return (
      <Container data-e2e-id={dataE2EId}>
        <VelocityConsumer>
          {({ theme }) => (
            <VehicleModel
              style={{ color: textColor || theme.styling.gray.main }}
              variant={textSize}
              bold={bold}
              data-e2e-id="model"
            >
              {vehicle.isFuelManagement ? (
                <Translation
                  id={'myLeaseplan.multipleCars.actions.fuelMandate'}
                />
              ) : (
                vehicle.model
              )}
            </VehicleModel>
          )}
        </VelocityConsumer>
        {!vehicle.isFuelManagement && (
          <LicensePlate data-e2e-id="licensePlate">
            {vehicle.licensePlate}
          </LicensePlate>
        )}
      </Container>
    )
  }
}

export { VehicleModelLicense }
export default VehicleModelLicense
