import { SagaIterator } from 'redux-saga'
import { select, takeLatest } from 'redux-saga/effects'

import {
  persistSessionToLocalStorage,
  retrieveSessionFromLocalStorage,
} from 'mlp-client/src/auth/auth'

import { getLocale } from './selectors'
import * as actions from './actions'

export function* switchLocale(action: actions.SwitchLocale): SagaIterator {
  // When switching locale, we need to clear sessionStorage to prevent bugs with localized form configurations
  const locale = yield select(getLocale)

  if (locale !== action.payload.locale) {
    const favorites = window.localStorage.getItem('favorites')

    // Saving of session needed because we want avoid relogin after changing of the locale
    const authSession = retrieveSessionFromLocalStorage()

    window.sessionStorage.clear()
    window.localStorage.clear()
    window.localStorage.setItem('favorites', favorites)

    if (authSession) {
      persistSessionToLocalStorage(authSession)
    }
  }

  // A timeout is needed to have potential other listeners handle this action as well, before we navigate away
  const localeUrl = `${window.location.origin}/${action.payload.locale}`

  setTimeout(() => {
    window.location.assign(localeUrl)
  }, 0)
}

export default [takeLatest(actions.ActionType.SWITCH_LOCALE, switchLocale)]
