import { ButtonLink, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import Supplier from 'mlp-client/src/components/suppliers/Supplier'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { AppState, SupplierCard } from 'mlp-client/src/types'
import { getContract } from 'mlp-client/src/contracts/selectors'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
export interface Props {
  headerTitleLabel?: string
  headerSubtitleLabel?: string
  headerSubtitleSinglePartnerLabel?: string
  isBannerDisabled?: boolean
  faqKey?: string
  suppliers: readonly SupplierCard[]
  isTyreStorageShown?: boolean
  tyreStorage?: string
  isMobileApp?: boolean
}

const titleLabel = 'myLeaseplan.newSetup.pages.tyreServices.title'
const subTitleLabel = 'myLeaseplan.newSetup.pages.tyreServices.subTitle'

class TyreServices extends React.PureComponent<Props> {
  renderHeader(): React.ReactNode {
    const {
      headerTitleLabel,
      headerSubtitleLabel,
      headerSubtitleSinglePartnerLabel,
      suppliers,
    } = this.props

    return (
      <>
        <Headline variant="400" component="h1" withMarginBottom>
          <Translation id={headerTitleLabel} />
        </Headline>
        <Text component="p" loose>
          <Translation
            id={
              headerSubtitleSinglePartnerLabel &&
              suppliers &&
              suppliers.length === 1
                ? headerSubtitleSinglePartnerLabel
                : headerSubtitleLabel
            }
          />
        </Text>
      </>
    )
  }

  renderSupplier(supplier: SupplierCard): React.ReactElement<{}> {
    const { name } = supplier

    return (
      <Spacing mb={2} key={name}>
        <Supplier supplier={supplier} />
      </Spacing>
    )
  }

  renderMain(): React.ReactNode {
    const { suppliers, isTyreStorageShown, tyreStorage } = this.props

    if (!suppliers || !suppliers.length) {
      return null
    }

    return (
      <>
        {suppliers.map((supplier: SupplierCard) =>
          this.renderSupplier(supplier),
        )}
        {isTyreStorageShown && tyreStorage && (
          <>
            <Text bold data-e2e-id="tyreStorage">
              <Translation id="myLeaseplan.contract.services.tyres.tyreStore.title" />
            </Text>
            {`: `}
            {tyreStorage}
          </>
        )}
      </>
    )
  }

  getBreadcrumbs = () => {
    const { isMobileApp } = this.props

    return (
      <Breadcrumbs
        color="white"
        separator={<ChevronRightIcon color={'white'} />}
      >
        <ButtonLink
          to="myLeaseplan.dashboard"
          component={RouteLink}
          size="s"
          fontWeight="regular"
          onClick={() =>
            isMobileApp &&
            postMessage({ type: PostMessageType.DashboardNavigation })
          }
        >
          <Translation id="myLeaseplan.subNavigation.dashboard" />
        </ButtonLink>
        <Translation id={titleLabel} />
      </Breadcrumbs>
    )
  }

  getNoBannerBreadcrumbs = () => {
    const { isBannerDisabled, headerTitleLabel, isMobileApp } = this.props

    if (!isBannerDisabled) {
      return null
    }

    return (
      <Spacing mb={3}>
        <Breadcrumbs>
          <ButtonLink
            to="myLeaseplan.dashboard"
            component={RouteLink}
            size="s"
            fontWeight="regular"
            onClick={() =>
              isMobileApp &&
              postMessage({ type: PostMessageType.DashboardNavigation })
            }
          >
            <Translation id="myLeaseplan.subNavigation.dashboard" />
          </ButtonLink>
          <Translation id={headerTitleLabel} />
        </Breadcrumbs>
      </Spacing>
    )
  }

  getBanner = (breadcrumbs: React.ReactNode) => {
    const { isBannerDisabled } = this.props

    if (isBannerDisabled) {
      return null
    }

    return (
      <FlowStepPoster
        imgMedium="/static/images/my-leaseplan/tyre-services-poster-medium.jpg"
        imgLarge="/static/images/my-leaseplan/tyre-services-poster-large.jpg"
        title={<Translation id={titleLabel} />}
        text={<Translation id={subTitleLabel} hideOnEmpty={true} />}
        breadcrumbs={breadcrumbs}
      />
    )
  }

  render() {
    const header = this.renderHeader()
    const main = this.renderMain()
    const breadcrumbs = this.getBreadcrumbs()
    const noBannerBreadcrumbs = this.getNoBannerBreadcrumbs()
    const { faqKey } = this.props

    return (
      <MyleaseplanPage
        metaTitle={titleLabel}
        banner={this.getBanner(breadcrumbs)}
      >
        {noBannerBreadcrumbs}
        <Spacing mb={3}>
          <GridLayout main={header} mainSpan={12} />
        </Spacing>
        {/* @TODO Property sideMaxWidth were added because GidLayout implemented in wrong way.
         *  @TODO It contains hardcoded sizing. We need to fix it in MLP-2842 */}
        <GridLayout main={main} side={<FAQ faqKey={faqKey} />} />
      </MyleaseplanPage>
    )
  }
}

export type ReduxProps = Pick<Props, 'tyreStorage' | 'isMobileApp'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  tyreStorage: getContract(state)?.tyres?.tyreStorageLocationName,
  isMobileApp: getIsMobileApp(state),
})

export { TyreServices }
export default connect(mapStateToProps)(TyreServices)
