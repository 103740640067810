import { Grid, GridItem, GridItemProps, Spacing } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'

import { MainContentWrapper } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper'

export interface Props {
  mainSpan?: GridItemProps['span']
  sideSpan?: GridItemProps['span']
  main: React.ReactNode
  side?: React.ReactNode
  mainMaxWidth?: string
  sideMaxWidth?: string
}

type DefaultProps = Required<
  Pick<Props, 'mainSpan' | 'sideSpan' | 'mainMaxWidth'>
>
type InternalProps = Props & DefaultProps

const Content = styled.div`
  max-width: ${({ maxWidth }: { maxWidth: string }) =>
    maxWidth ? maxWidth : 'none'};
`

const ContentWrapper = styled(GridItem)`
  padding: 0;
`

export class GridLayout extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    mainMaxWidth: '600px',
    mainSpan: { desktop: 8, mobile: 12, tablet: 12, lap: 8 },
    sideSpan: { desktop: 4, mobile: 12, tablet: 12, lap: 4 },
  }

  render() {
    const {
      mainSpan,
      sideSpan,
      main,
      side,
      mainMaxWidth,
      sideMaxWidth = mainMaxWidth,
    } = this.props

    return (
      <MainContentWrapper>
        <Grid gutter={false}>
          <ContentWrapper span={mainSpan}>
            <Content maxWidth={mainMaxWidth}>{main}</Content>
          </ContentWrapper>
          {side && (
            <ContentWrapper span={sideSpan}>
              <Content maxWidth={sideMaxWidth}>
                <Spacing mt={{ mobile: 3, lap: 0 }}>{side}</Spacing>
              </Content>
            </ContentWrapper>
          )}
        </Grid>
      </MainContentWrapper>
    )
  }
}
