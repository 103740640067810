import { Button, Grid, GridItem } from '@leaseplan/ui'
import * as React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { RoadsideAssistanceConfigWithUrl } from 'mlp-client/src/components/road-side-assistance/types'
import DashboardBanner from 'mlp-client/src/dashboard/components/DashboardBanner'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import RoadsideAssistanceIcon from 'mlp-client/src/components/icons/roadside-assistance-icon/RoadsideAssistanceIcon'
import RoadsideAssistanceIconDesktop from 'mlp-client/src/components/icons/roadside-assistance-icon/RoadsideAssistanceIconDesktop'
import { AppState } from 'mlp-client/src/types'

const redirectHandler = (url: string) => () => window.open(url, '_blank')

export interface Props
  extends MyLeaseplanFeatureProps<RoadsideAssistanceConfigWithUrl> {
  title: string
  btnLabel?: string
}

class RoadSideAssistanceWithUrl extends React.PureComponent<Props> {
  renderAssistanceButton = (url: string, label: string) => (
    <Button
      component="button"
      variant="primary"
      onClick={url && redirectHandler(url)}
      data-tag-id="roadside-assistance-phonenumber-button"
    >
      {label}
    </Button>
  )

  render() {
    const { title, btnLabel, featureConfig } = this.props
    const { configuration } = featureConfig

    return (
      <Grid>
        <GridItem span="grow">
          <DashboardBanner
            title={title}
            AssistanceButton={this.renderAssistanceButton(
              configuration?.url ?? '',
              btnLabel,
            )}
            mobIcon={RoadsideAssistanceIcon}
            desktopIcon={RoadsideAssistanceIconDesktop}
          />
        </GridItem>
      </Grid>
    )
  }
}

type ReduxProps = Pick<Props, 'btnLabel' | 'title'>
export type OwnProps = Omit<Props, keyof ReduxProps>

const mapTranslationsToProps = {
  btnLabel:
    'myLeaseplan.genericNotifications.roadSideAssistance.assistanceButton',
  title: 'myLeaseplan.genericNotifications.roadSideAssistance.title',
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  ...getTranslations(state, mapTranslationsToProps),
})

export default connect(mapStateToProps)(RoadSideAssistanceWithUrl)
export { RoadSideAssistanceWithUrl }
