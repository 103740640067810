import { getTranslation } from 'mlp-client/src/localization/selectors'
import { AppState } from 'mlp-client/src/types'

export const getShippingAddress = (state: AppState) => {
  const returnLeasePlanCar = getTranslation(
    state,
    'myLeaseplan.return.steps.additionalInformation.returnLeasePlanCar',
    'myLeaseplan.return.steps.additionalInformation.returnLeasePlanCar',
  )

  const returnOtherCompanyCar = getTranslation(
    state,
    'myLeaseplan.return.steps.additionalInformation.returnOtherCompanyCar',
    'myLeaseplan.return.steps.additionalInformation.returnOtherCompanyCar',
  )

  const returnOtherCar = getTranslation(
    state,
    'myLeaseplan.return.steps.additionalInformation.returnOtherCar',
    'myLeaseplan.return.steps.additionalInformation.returnOtherCar',
  )

  return [
    {
      title: returnLeasePlanCar,
      value: returnLeasePlanCar,
    },

    {
      title: returnOtherCompanyCar,
      value: returnOtherCompanyCar,
    },
    {
      title: returnOtherCar,
      value: returnOtherCar,
    },
  ]
}
