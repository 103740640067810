import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { AppState } from 'mlp-client/src/types'
import { getOrderById } from 'mlp-client/src/order-status/selectors'
import { getVehicleDetails } from 'mlp-client/src/order-status/utils'
import { VehicleDetails } from 'mlp-client/src/order-status/types'

export interface Props {
  vehicleDetails?: VehicleDetails
  children?: React.ReactNode
  orderId?: string
}

class PendingApproval extends React.PureComponent<Props> {
  renderYourVehicleLabel = (): React.ReactNode => (
    <Translation key="yourVehicle" id="myLeaseplan.orderStatus.yourVehicle" />
  )

  render() {
    const { model, make } = this.props.vehicleDetails

    if (!model && !make) {
      return (
        <Translation
          replace={{ make: this.renderYourVehicleLabel, model: '' }}
          id="myLeaseplan.genericNotifications.statusCarService.pendingApprovalInfo.subtitle"
        />
      )
    }

    return make || model ? (
      <Translation
        replace={{ make, model }}
        id="myLeaseplan.genericNotifications.statusCarService.pendingApprovalInfo.subtitle"
      />
    ) : (
      <Translation id="myLeaseplan.genericNotifications.statusCarService.pendingApprovalInfo.noMakeAndModelSubtitle" />
    )
  }
}

type ReduxProps = Pick<Props, 'vehicleDetails'>
type OwnProps = Omit<Props, keyof ReduxProps>

export const mapStateToProps = (
  state: AppState,
  props: OwnProps,
): ReduxProps => ({
  vehicleDetails: (getVehicleDetails(getOrderById(state)(props.orderId)) ??
    {}) as VehicleDetails,
})

export { PendingApproval }
export default connect(mapStateToProps)(PendingApproval)
