import React from 'react'
import { VelocityProvider } from '@velocity/ui'
import { Color } from '@leaseplan/ui'

import { overrideThemeLPLU } from 'mlp-client/src/styles/velocityTheme/lpluTheme'

import 'mlp-client/src/styles/lpluMain.scss'

enum LpluColor {
  aquaBlue = '#00b1af',
  aquaBlue20 = '#ccefef',
  aquaBlue40 = '#99e0df',
  aquaBlue60 = '#66d0cf',
  aquaBlue80 = '#33c1bf',
  black = '#000',
  bloodOrange = '#c20e1a', //changed
  error = '#e7401e',
  error20 = '#fef4f4',
  error40 = '#fbdcd6',
  freshGreen = '#a9c90e',
  freshGreen20 = '#eef4cf',
  freshGreen40 = '#dde99f',
  freshGreen60 = '#cbdf6e',
  freshGreen80 = '#bad43e',
  gallery = '#eee',
  grassGreen = '#009a17',
  grassGreen20 = '#ccebd1',
  grassGreen40 = '#99d7a2',
  grassGreen60 = '#66c274',
  grassGreen80 = '#33ae45',
  lightOrange = '#ed8b00',
  midOrange = '#18305b', //changed
  mist = '#aa0c17', //changed
  petrolBlue = '#18305b', //changed for RadioClickAndGoList.tsx
  petrolBlue20 = '#ccdbdf',
  petrolBlue40 = '#99b7be',
  petrolBlue60 = '#66929e',
  petrolBlue80 = '#336e7d',
  purple = '#962472',
  sand = '#f6f6f6',
  steel = '#545656',
  steel20 = '#ddd',
  steel40 = '#bbb',
  steel60 = '#989a9a',
  steel80 = '#767878',
  thunder = '#2d2d2d',
  white = '#fff',
  error60 = '#f5d1c9',
  errorDark = '#c12d0f',
  grassGreenDarker = '#007011',
  yellow20 = '#fefed0',
  yellow40 = '#baba00',
  blue20 = '#dbf0ff',
  blue60 = '#87cdff',
  grey = '#9c9b9b',
}

export const LpluTheme = { ...Color, ...LpluColor }

export type LpluTheme = typeof LpluTheme

const LPLUTheme: React.FC = ({ children }) => (
  <VelocityProvider themeOverrides={overrideThemeLPLU}>
    {children}
  </VelocityProvider>
)

export default LPLUTheme
