import React from 'react'
import { connect } from 'react-redux'

import {
  getLocalizedRoute,
  getLocale,
} from 'mlp-client/src/localization/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import { AppState } from 'mlp-client/src/types'
import { getOrders } from 'mlp-client/src/contracts/selectors'
import { Locales } from 'mlp-client/src/localization/enums'

import BannerCard from './BannerCard'

export interface Props {
  ordersWithStatus?: readonly Contract[]
  orderPageLink?: string
  locale: Locales
}

class BannerCardWrapper extends React.PureComponent<Props> {
  render() {
    const { ordersWithStatus, orderPageLink, locale } = this.props

    return (
      <>
        {ordersWithStatus?.length > 1 ? (
          <>
            {ordersWithStatus.map((order: Contract) => (
              <div key={order.id}>
                <BannerCard
                  order={order}
                  to={`${orderPageLink}${order.id}`}
                  locale={locale}
                />
              </div>
            ))}
          </>
        ) : (
          <BannerCard
            order={ordersWithStatus[0]}
            to={orderPageLink}
            locale={locale}
          />
        )}
      </>
    )
  }
}

type ReduxProps = Pick<Props, 'ordersWithStatus' | 'orderPageLink' | 'locale'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  ordersWithStatus: getOrders(state).filter(
    status => status?.orderStatus?.length,
  ),
  orderPageLink: getLocalizedRoute(state, 'myLeaseplan.vehicleOrder'),
  locale: getLocale(state),
})

export { BannerCardWrapper }
export default connect(mapStateToProps)(BannerCardWrapper)
