import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { filterRouteParams } from 'mlp-client/src/components/myleaseplan-flow/utils'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import {
  canReturn,
  getCurrentContractId,
} from 'mlp-client/src/contracts/selectors'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureProps,
} from 'mlp-client/src/features-configuration/types'
import {
  FlowData,
  ReturnFlowConfiguration,
  StepDefinition,
} from 'mlp-client/src/flows/return/types'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'
import { AppState } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { getCountry } from 'mlp-client/src/localization/selectors'

export interface Props
  extends MyLeaseplanFeatureProps<ReturnFlowConfiguration> {
  contractId: string
  country: string
  canReturn(config: MyleaseplanConfig): boolean
}

interface State {
  returnToPath?: string
}

export class Flow extends React.PureComponent<Props, State> {
  state: State = {}

  static stepTitlePrefix = 'myLeaseplan.newSetup.flows.return'

  static getDerivedStateFromProps(props: Props, state: State) {
    const { state: locationState } = props.location

    if (locationState && locationState.returnTo && !state.returnToPath) {
      return {
        returnToPath: locationState.returnTo,
      }
    }

    return null
  }

  renderStep = (stepParameters: FlowProps<FlowData>) => {
    const { name } = stepParameters.activeStep
    const {
      configuration: { allSteps },
    } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = allSteps[name] as StepDefinition

    if (StepView) {
      return <StepView {...stepParameters} config={{ ...stepConfig }} />
    }

    return <NotFound />
  }

  onFlowClose = (isMoveBack: boolean) => {
    const { match, contractId } = this.props
    const exitPath =
      (isMoveBack && this.state.returnToPath) || 'myLeaseplan.dashboard'
    const params = {
      ...filterRouteParams(match.params),
      contractId,
    }

    return <TranslatedRedirect to={exitPath} params={params} />
  }

  render() {
    return (
      <MyLeaseplanContext.Consumer>
        {context => {
          const {
            featureConfig,
            contractId,
            user,
            country,
            canReturn,
          } = this.props

          if (!canReturn(context)) {
            return <NotFound data-e2e-id="notFound" />
          }

          return (
            <MyleaseplanFlow
              stepTitlePrefix={Flow.stepTitlePrefix}
              routePrefix="myLeaseplan.return.steps"
              getSteps={featureConfig.configuration?.getSteps}
              onClose={this.onFlowClose}
              render={this.renderStep}
              initialFlowData={{ contractId, user, country }}
              eventCategory={EventCategoryService.FAST_SERVICE}
              flowName={EventCategoryFlow.RETURN}
            />
          )
        }}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<Props, 'contractId' | 'user' | 'country' | 'canReturn'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  contractId: getCurrentContractId,
  country: getCountry,
  canReturn,
})

export default connect(mapStateToProps)(Flow)
