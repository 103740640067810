import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { FlowData } from 'mlp-client/src/flows/tyres/types'
import { StyledLink } from 'mlp-client/src/components/link/StyledLink'

export interface Props extends StepViewProps<{}, FlowData> {}

export class ThankYouStep extends React.PureComponent<Props> {
  timelineLink = (): React.ReactNode => (
    <StyledLink component={RouteLink} to="myLeaseplan.timeline.base">
      <Translation id="myLeaseplan.newSetup.flows.tyres.steps.thankYou.listItems.timelineLink" />
    </StyledLink>
  )

  render() {
    const { closeFlow } = this.props

    return (
      <ThankyouPageLayout
        metaTitle="myLeaseplan.newSetup.flows.tyres.steps.thankYou.title"
        onClick={closeFlow}
      >
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id="myLeaseplan.newSetup.flows.tyres.steps.thankYou.pageTitle" />
        </Headline>
        <Section headerText="myLeaseplan.newSetup.flows.tyres.steps.thankYou.description">
          <Translation id="myLeaseplan.newSetup.flows.tyres.steps.thankYou.listItems.contactLabel" />
          <Translation
            id="myLeaseplan.newSetup.flows.tyres.steps.thankYou.listItems.timelineLabel"
            replace={{ timelineLink: this.timelineLink }}
          />
        </Section>
      </ThankyouPageLayout>
    )
  }
}

export default ThankYouStep
