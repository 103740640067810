import Classnames from 'classnames'
import React from 'react'

import { NavigationItem } from 'mlp-client/src/types'
import { DynamicLink } from 'mlp-client/src/components/dynamic-link/DynamicLink'
import { ChevronDownIcon } from 'mlp-client/src/components/icons'
import Translation from 'mlp-client/src/localization/Translation'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'

import DropdownPanel from './DropdownPanel'

interface DefaultProps {
  active: boolean
}

interface NonDefaultProps {
  className?: string
  label: string
  items: readonly NavigationItem[]
  contractId: string
}

export type Props = Partial<DefaultProps> & NonDefaultProps

interface State {
  open: boolean
}

class MainMenuDropdownItem extends React.PureComponent<Props, State> {
  mainElement: HTMLElement

  static defaultProps: DefaultProps = {
    active: false,
  }

  state = { open: false }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside)
  }

  attachMainElement = (element: HTMLElement) => {
    this.mainElement = element
  }

  handleClickOutside = (e: MouseEvent) => {
    if (
      !this.mainElement.contains(e.target as HTMLElement) &&
      this.state.open
    ) {
      this.closePanel()
    }
  }

  togglePanel = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  closePanel = () => {
    this.setState({ open: false })
  }

  render() {
    const { active, className, label, items, contractId } = this.props
    const { open } = this.state

    return (
      <TopNavigationContext.Consumer>
        {({ scrollDirection, navActiveItem }) => {
          if (scrollDirection === 'down' && open) {
            this.closePanel()
          }

          return (
            <span
              className={Classnames('dropdown-menu-item', className)}
              ref={this.attachMainElement}
            >
              <button
                className={Classnames('dropdown-menu-item__button', {
                  'dropdown-menu-item__button--active': active,
                })}
                onClick={this.togglePanel}
                aria-expanded={open}
              >
                <Translation id={label} />
                <ChevronDownIcon
                  className={Classnames(
                    'icon dropdown-menu-item__chevron u-margin-left-tiny',
                    { 'dropdown-menu-item__chevron--open': open },
                  )}
                />
              </button>
              <DropdownPanel open={open}>
                <ul className="o-list-bare u-padding-vertical-tiny">
                  {items.map(item => (
                    <li
                      key={item.title}
                      className={Classnames(
                        'dropdown-menu-item__item u-padding-horizontal',
                        {
                          'dropdown-menu-item__item--active': !!navActiveItem,
                        },
                      )}
                    >
                      <DynamicLink
                        data-tag-id={`main-menu-dropdown-item-link-${item.to}`}
                        to={item.to || item.url || ''}
                        params={item.useContractId ? { contractId } : {}}
                        target={item.target || '_self'}
                        className={Classnames('dropdown-menu-item__link', {
                          'dropdown-menu-item__link--active': !!navActiveItem,
                        })}
                      >
                        {item.navigationTitle ? (
                          <span>{item.navigationTitle}</span>
                        ) : (
                          item.title && <Translation id={item.title} />
                        )}
                      </DynamicLink>
                    </li>
                  ))}
                </ul>
              </DropdownPanel>
            </span>
          )
        }}
      </TopNavigationContext.Consumer>
    )
  }
}

export default MainMenuDropdownItem
