import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import DateTimeFieldGroup, {
  DateProps,
  FormValues,
  TimeProps,
} from 'mlp-client/src/flows/components/date-time-field-group/DateTimeFieldGroup'
import { Options } from 'mlp-client/src/form/types'

export interface StepFormValues extends FormValues {}
export interface Props {
  title?: string
  subtitle?: string
  side?: React.ReactNode
  warningBlock?: React.ReactNode
  date?: DateProps
  time?: TimeProps
  selectedDate?: Date
  selectedTime?: string
  onSubmit(values?: StepFormValues): void
}

const DateTimeStep: React.FC<Props> = ({
  title,
  subtitle,
  side,
  warningBlock,
  date: {
    title: dateTitle,
    placeholder,
    notBefore,
    notAfter,
    dateAddInfo,
    enableWeekend,
    onChange: onDateChange,
  } = {
    defaultValue: '',
  },
  time,
  selectedDate,
  selectedTime,
  onSubmit,
}) => {
  let timeEnabled: boolean
  let timeTitle: string
  let timeOptions: Options

  if (time && time.enabled) {
    ;({ enabled: timeEnabled, title: timeTitle, options: timeOptions } = time)
  }

  return (
    <StepLayout>
      <TwoColumnFlowStep titleLabel={title} textLabel={subtitle} side={side}>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            date: selectedDate,
            time: selectedTime,
          }}
          render={({ handleSubmit, hasValidationErrors }) => (
            <form onSubmit={handleSubmit}>
              <Spacing mb={2}>
                <DateTimeFieldGroup
                  date={{
                    title: dateTitle,
                    placeholder,
                    notBefore,
                    notAfter,
                    enableWeekend,
                    dateAddInfo,
                    onChange: onDateChange,
                  }}
                  time={{
                    enabled: timeEnabled,
                    title: timeTitle,
                    options: timeOptions,
                  }}
                />
              </Spacing>
              {warningBlock}
              <NextStepButton
                onClick={handleSubmit}
                disabled={hasValidationErrors}
              />
            </form>
          )}
        />
      </TwoColumnFlowStep>
    </StepLayout>
  )
}

export { DateTimeStep }
export default DateTimeStep
