import {
  ButtonChevronRightIcon,
  CardContent,
  Grid,
  GridItem,
} from '@leaseplan/ui'
import { LocationState } from 'history'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import {
  GrayMainText,
  SecondaryDarkText,
} from 'mlp-client/src/components/styled/TextStyle'

import { StyledCard, StyledDynamicLink } from './CardLink.styled'

export interface Props {
  link: {
    to: string
    target?: '_blank' | '_self'
    state?: LocationState
    params?: object
  }
  title: string
  description?: string
  clickHandler?(e: React.MouseEvent<HTMLElement>): void
  dataE2eId?: string
}

export const CardLink: React.FC<Props> = ({
  link: { to, params, target, state },
  title,
  description,
  clickHandler,
  dataE2eId,
}) => (
  <StyledCard
    border={true}
    borderColor="steel20"
    hover={true}
    hoverElevation={4}
    data-e2e-id="optionCard"
  >
    <StyledDynamicLink
      to={to}
      params={params}
      locationState={state}
      target={target}
      onClick={clickHandler}
      data-e2e-id={dataE2eId}
    >
      <CardContent>
        <Grid alignItems="center">
          <GridItem span="grow">
            <SecondaryDarkText bold>
              <Translation id={title} />
            </SecondaryDarkText>
            {description && (
              <GrayMainText variant="200">
                <Translation id={description} />
              </GrayMainText>
            )}
          </GridItem>
          <GridItem>
            <ButtonChevronRightIcon color="steel40" />
          </GridItem>
        </Grid>
      </CardContent>
    </StyledDynamicLink>
  </StyledCard>
)
