import React from 'react'
import { Headline, Text } from '@velocity/ui'

import DynamicLink from 'mlp-client/src/components/dynamic-link/DynamicLink'
import MapsMarkerIcon from 'mlp-client/src/components/icons/maps-marker-icon/MapsMarkerIcon'
import { GrayMainText } from 'mlp-client/src/components/styled/TextStyle'

import { getGoogleMapsRouteLink } from './utils'

interface NonDefaultProps {
  viewOnGoogleMaps: string
}

interface DefaultProps {
  title: React.ReactNode
  address: string
}

export type Props = Partial<DefaultProps> & NonDefaultProps

export default class MarkerTooltip extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    title: 'Title',
    address: 'Paris, France',
  }

  render() {
    const { title, address, viewOnGoogleMaps } = this.props

    return (
      <div className="marker-tooltip--wrapper">
        <div className="marker-tooltip--container u-padding-small u-margin-bottom-small">
          <Headline variant="100" component="h6" data-e2e-id="title">
            {title}
          </Headline>
          <Text variant="100" data-e2e-id="address">
            {address}
          </Text>
          <div className="u-margin-top--tiny">
            <DynamicLink to={getGoogleMapsRouteLink(address)}>
              <GrayMainText variant="100">{viewOnGoogleMaps}</GrayMainText>
            </DynamicLink>
          </div>
        </div>
        <div className="o-layout u-margin-top-tiny">
          <div className="o-layout__item o-layout__item--center">
            <MapsMarkerIcon />
          </div>
        </div>
      </div>
    )
  }
}
