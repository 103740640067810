import { StepNames } from 'mlp-client/src/flows/damage-report/enums'
import { StepDefinition } from 'mlp-client/src/flows/types'
import { StepConfig as SelectDamageTypeConfig } from 'mlp-client/src/flows/damage-report/components/steps/damage-nature-type-step/DamageNatureTypeStep'
import { StepConfig as DamageSideConfig } from 'mlp-client/src/flows/damage-report/components/steps/damage-sides-step/DamageSideStep'
import { StepConfig as DamageUploadConfig } from 'mlp-client/src/flows/damage-report/components/steps/damage-upload-step/DamageUploadStep'
import { StepConfig as PointSelectorConfig } from 'mlp-client/src/flows/damage-report/components/steps/point-selection-step/PointSelectionStep'
import { StepConfig as WhenAndWhereConfig } from 'mlp-client/src/flows/damage-report/components/steps/when-and-where-step/WhenAndWhereStep'
import { StepConfig as EuropeanAccidentConfig } from 'mlp-client/src/flows/damage-report/components/steps/european-accident-statement-step/EuropeanAccidentStatementStep'
import { StepConfig as InsuranceConfig } from 'mlp-client/src/flows/damage-report/components/steps/insurance-step/InsuranceStep'
import { StepConfig as DamageCauseConfig } from 'mlp-client/src/flows/damage-report/components/steps/damage-cause-step/DamageCauseStep'
import { StepConfig as WhatHappenedConfig } from 'mlp-client/src/flows/damage-report/components/steps/what-happened-step/WhatHappenedStep'
import { StepConfig as CheckDetailsConfig } from 'mlp-client/src/flows/damage-report/components/steps/check-details-step/CheckDetailsStep'
import { StepConfig as ThankYouConfig } from 'mlp-client/src/flows/damage-report/components/steps/thank-you-step/ThankYouStep'
import { Attachments } from 'mlp-client/src/types'
import { Locales } from 'mlp-client/src/localization/enums'
import { Point } from 'mlp-client/src/form/components/car-point-select/types'
import { Option } from 'mlp-client/src/form/types'
import { Time } from 'mlp-client/src/form/components/fields/time-input-field/types'

export type IframeSrc = { [key in Locales]?: string }

export enum ImpactPointLabels {
  FRONT = 'before',
  FRONT_LEFT = 'frontLeft',
  FRONT_RIGHT = 'frontRight',
  SIDE_LEFT = 'leftSide',
  SIDE_RIGHT = 'leftSide',
  REAR = 'back',
  REAR_LEFT = 'backLeft',
  REAR_RIGHT = 'backRight',
  SPARE = 'spare',
  INSIDE = 'inside',
  BEDROCK = 'bedrock',
  FLAG = 'flag',
  TOTAL = 'total',
  NO_IMPACT = 'noImpact',
}

export enum DamageCauses {
  ONE_PARTY = 'oneParty',
  TWO_PARTY = 'twoParty',
  GLASS_ONLY = 'glassOnly',
  TYRES = 'tyres',
}

export enum ContentEndpoint {
  STOLEN_VEHICLE = 'myleaseplan/page/index/Vehicle Theft',
}

export type PartyDamages = readonly PartyDamage[]

interface PartyDamage extends Option {
  damageCause: DamageCauses
}

export enum WhoseFault {
  MY_FAULT = 'myFault',
  OTHER_FAULT = 'otherFault',
}

export enum DamageReportTypes {
  ANIMALS = 'Collision with animal',
  CHAIN_COLLISION = 'Chain Collision - TP at fault',
  DAMAGE_NON_FAULT = 'Damage when pursuing (police) - Non Fault',
  DAMAGE_SUPPLIER = 'Damage due to supplier',
  DAMAGE_TO_PROPERTY = 'Damage to TP property (excluding vehicle)',
  INTAKE_CAR_DAMAGE = 'Damage upon intake of car (Unfair wear & tear)',
  NATURAL_PERILS = 'Natural perils',
  OCCURRENCE_POSSIBLE_WBF = 'Occurrence with possible WBF',
  PARTIAL_THEFT = 'Partial Theft - damage and/or parts (excluding SAT Nav)',
  OTHER_AT_FAULT = 'Other - TP at fault',
  PEDESTRIAN_BICYCLE_TRAM = 'Pedestrian / bicycle / tram',
  REARENDED = 'Rearended by TP',
  REVERSING = 'TP reversing',
  FIRE_DAMAGE = 'Fire',
  VANDALISM = 'Vandalism',
  BACKING = 'Backing',
  BURGLARY = 'Burglary',
  FALLEN_OBJECTS = 'Fallen objects',
  PARKING = 'The vehicle was damaged while parked',
  THEFT = 'Theft of vehicle',
  THEFT_ATTEMPT = 'Attempt of theft',
  ONE_SIDED_COLLISION = 'One sided collision',
  OTHER = 'Other',
  CHANGED_LANES = 'Changing lanes',
  OPENED_DOOR = 'Opens door',
  PARKED_CAR = 'Parked car hit by TP',
  RIGHT_OF_WAY = 'TP failed to give right of way',
  TWO_SIDED_COLLISION = 'Two sided collision',
  MULTIPLE_COLLISION = 'Multiple collision damage',
  FALLEN_OBJECTS_TP = 'Fallen objects on the vehicle',
  LOST_EFFECTS = 'Lost effects',
  RSA_FNOL_REPORT = 'RSA / FNOL  report',
  SAT_NAV_THEFT = 'Theft - Sat Nav',
  UNINSURED_LOSS = 'Uninsured Loss (Keys, License Plate etc.)',
  VEHICLE_THEFT_DAMAGE = 'Recovered vehicle after Theft',
}

export interface FlowData {
  damageNatureType?: string
  isAmbiguousType?: boolean
  oneOrTwo?: string
  picture?: Attachments
  description?: string
  policeStationName?: string
  europeanAccidentStatement?: Attachments
  thirdPartyInfo?: ThirdPartyInfo
  noThirdPartyInfo?: boolean
  firstName?: string
  damageCause?: DamageCauses
  whoseFault?: string
  selectWhatHappened?: string
  firstImpactPoint?: string
  damagePoints?: string
  where?: string
  date?: Date
  time?: Time
}

export interface ThirdPartyInfo {
  insuranceCompany?: string
  policyNumber?: string
  firstName?: string
  lastName?: string
  licensePlate?: string
  phoneNumber?: string
}

export interface Steps {
  [StepNames.SELECT_DAMAGE_NATURE_TYPE]?: StepDefinition<SelectDamageTypeConfig>
  [StepNames.SELECT_ONE_OR_TWO_SIDED_DAMAGE]?: StepDefinition<DamageSideConfig>
  [StepNames.STOLEN_VEHICLE]?: StepDefinition<{}>
  [StepNames.UPLOAD_DAMAGE]?: StepDefinition<DamageUploadConfig>
  [StepNames.POINT_SELECTION]?: StepDefinition<PointSelectorConfig>
  [StepNames.WHEN_AND_WHERE]?: StepDefinition<WhenAndWhereConfig>
  [StepNames.EUROPEAN_ACCIDENT_STATEMENT]?: StepDefinition<EuropeanAccidentConfig>
  [StepNames.INSURANCE]?: StepDefinition<InsuranceConfig>
  [StepNames.SELECT_DAMAGE_CAUSE]?: StepDefinition<DamageCauseConfig>
  [StepNames.WHAT_HAPPENED]?: StepDefinition<WhatHappenedConfig>
  [StepNames.THANK_YOU]?: StepDefinition<ThankYouConfig>
  [StepNames.CHECK_DETAILS]?: StepDefinition<CheckDetailsConfig>
}
export interface DamageReportFlowConfiguration {
  getSteps(flowData: FlowData): readonly StepNames[]
  allSteps: Steps
}

export interface PartyTypes {
  isFirstParty: boolean
  isThirdParty: boolean
}

export interface DamageNatureType {
  name: string
  isFirstParty: boolean
  isThirdParty: boolean
}

export enum RequestStatus {
  Success = 'success',
  Failed = 'failed',
  Pending = 'pending',
  Idle = 'idle',
}

export type DamageNatureTypes = readonly DamageNatureType[]

export enum ImpactPointValues {
  FRONT = 'Before',
  FRONT_LEFT = 'Front Left',
  FRONT_RIGHT = 'Front Right',
  SIDE_LEFT = 'Left Side',
  SIDE_RIGHT = 'Right Side',
  REAR = 'Back',
  REAR_LEFT = 'Back Left',
  REAR_RIGHT = 'Back Right',
  SPARE = 'spare',
  INSIDE = 'Inside',
  BEDROCK = 'Bedrock',
  FLAG = 'Flag',
  TOTAL = 'Total',
  NO_IMPACT = 'No Impact',
}

export const impactPoints: readonly Point[] = [
  {
    label: ImpactPointLabels.FRONT_RIGHT,
    value: ImpactPointValues.FRONT_RIGHT,
    left: '13%',
    top: '16%',
  },
  {
    label: ImpactPointLabels.FRONT_LEFT,
    value: ImpactPointValues.FRONT_LEFT,
    left: '13%',
    bottom: '16%',
  },
  {
    label: ImpactPointLabels.FRONT,
    value: ImpactPointValues.FRONT,
    left: '5%',
    top: '50%',
    centeredY: true,
  },
  {
    label: ImpactPointLabels.SIDE_RIGHT,
    value: ImpactPointValues.SIDE_RIGHT,
    left: '50%',
    top: '16%',
    centeredX: true,
  },
  {
    label: ImpactPointLabels.SIDE_LEFT,
    value: ImpactPointValues.SIDE_LEFT,
    left: '50%',
    bottom: '16%',
    centeredX: true,
  },
  {
    label: ImpactPointLabels.REAR_RIGHT,
    value: ImpactPointValues.REAR_RIGHT,
    right: '13%',
    top: '16%',
  },
  {
    label: ImpactPointLabels.REAR,
    value: ImpactPointValues.REAR,
    right: '5%',
    top: '50%',
    centeredY: true,
  },
  {
    label: ImpactPointLabels.REAR_LEFT,
    value: ImpactPointValues.REAR_LEFT,
    right: '13%',
    bottom: '16%',
  },
  {
    label: ImpactPointLabels.INSIDE,
    value: ImpactPointValues.INSIDE,
    showInList: true,
  },
  {
    label: ImpactPointLabels.BEDROCK,
    value: ImpactPointValues.BEDROCK,
    showInList: true,
  },
  {
    label: ImpactPointLabels.FLAG,
    value: ImpactPointValues.FLAG,
    showInList: true,
  },
  {
    label: ImpactPointLabels.TOTAL,
    value: ImpactPointValues.TOTAL,
    showInList: true,
  },
  {
    label: ImpactPointLabels.NO_IMPACT,
    value: ImpactPointValues.NO_IMPACT,
    showInList: true,
  },
]

export interface SubmitRequestBody {
  incidentNature: string
  contractId: string
  isDriverFault: boolean
  attachmentKeys: string[]
  description: string
  incidentDateTime: string
  thirdPartyInvolvement: boolean
  locationDescription: string
  postCode: string
  initialImpactPoints: string[]
  damagePoints: ImpactPointValues[]
  otherImpactPoints: ImpactPointValues[]
  policeStationName?: string
  thirdPartyInfo?: ThirdPartyInfo
}
