import {
  ButtonLink,
  EditIcon,
  ModalTrigger,
  Paper,
  Spacing,
} from '@leaseplan/ui'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import { ProgressBarWidget } from 'mlp-client/src/components/progress-bar/ProgressBarWidget'
import VehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { Contract } from 'mlp-client/src/contracts/types'
import {
  calculateExpectedMileage,
  getLastKnownMileage,
  getMaximumAllowedMileage,
  getMonthDiff,
  mapContractToVehicle,
} from 'mlp-client/src/contracts/utils'
import MileageUnitText from 'mlp-client/src/form/components/mileage/MileageUnitText'
import MileageUpdate from 'mlp-client/src/components/mileage-update-modal/MileageUpdate'
import { CarMissingIcon } from 'mlp-client/src/components/icons'

import {
  LinkStyled,
  SpacingRow,
  VehicleGridItem,
  VehicleOverviewGrid,
  VehicleTileLinksGridItem,
} from './VehicleOverviewTile.styled'
import VehicleOverviewTileLinks from './VehicleOverviewTileLinks'

const EditIconStyled = styled(EditIcon)`
  &:hover {
    cursor: pointer;
  }
`

export interface Props {
  contract: Contract
  setActiveContract(contract: Contract): void
}

class VehicleOverviewTileWide extends React.PureComponent<Props> {
  render() {
    const { contract, setActiveContract } = this.props
    const vehicle = mapContractToVehicle(contract)
    const monthsExpired = getMonthDiff(moment.now(), contract.startDate)
    const maxMileage = getMaximumAllowedMileage(contract)
    const lastKnownMileage = getLastKnownMileage(contract)
    const contractMonths = Number(contract.duration)
    const expectedValue = calculateExpectedMileage(
      maxMileage,
      contractMonths,
      monthsExpired,
    )

    const link = (
      <MyLeaseplanContext.Consumer>
        {context =>
          context.mileageUpdate.enabled && (
            <ModalTrigger
              trigger={
                <EditIconStyled color="steel80" width="18px" height="18px" />
              }
              modalContent={
                <MileageUpdate
                  contractId={contract.id}
                  lastKnownMileage={lastKnownMileage}
                />
              }
            />
          )
        }
      </MyLeaseplanContext.Consumer>
    )

    return (
      <Paper
        hover={true}
        data-e2e-id={`vehicleOverview-${vehicle.licensePlate}`}
      >
        <VehicleOverviewGrid gutter={false} alignItems="center">
          <VehicleGridItem span={{ tablet: 12, lap: 'grow', desktop: 'grow' }}>
            <SpacingRow m={2}>
              <ButtonLink
                component={RouteLink}
                to="myLeaseplan.dashboard"
                params={{ contractId: contract.id }}
                onClick={() => setActiveContract(contract)}
              >
                <CarMissingIcon />
              </ButtonLink>
              <Spacing ml={1}>
                <LinkStyled
                  component={RouteLink}
                  to="myLeaseplan.dashboard"
                  params={{ contractId: contract.id }}
                  onClick={() => setActiveContract(contract)}
                >
                  <VehicleModelLicense vehicle={vehicle} bold textSize="300" />
                </LinkStyled>
                {!vehicle.isFuelManagement && (
                  <Spacing mt={0.5}>
                    <ProgressBarWidget
                      maxValue={maxMileage}
                      currentValue={lastKnownMileage}
                      units={<MileageUnitText />}
                      expectedValue={expectedValue}
                      link={link}
                      dataE2eId={contract.id}
                    />
                  </Spacing>
                )}
              </Spacing>
            </SpacingRow>
          </VehicleGridItem>
          <VehicleTileLinksGridItem
            span={{ tablet: 12, lap: 'auto', desktop: 'auto' }}
          >
            <Spacing m={2}>
              <VehicleOverviewTileLinks
                contract={contract}
                onHandleClick={setActiveContract}
              />
            </Spacing>
          </VehicleTileLinksGridItem>
        </VehicleOverviewGrid>
      </Paper>
    )
  }
}

export { VehicleOverviewTileWide }
export default VehicleOverviewTileWide
