import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getLocale } from 'mlp-client/src/localization/selectors'
import { ApiSagas } from 'mlp-client/src/api'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import * as actions from 'mlp-client/src/flows/components/pages/supplier-map-page/actions'
import { SupplierResponse } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'
import { mapLocation } from 'mlp-client/src/utils/address/address'

const defaultOptions = {
  throwError: true,
}

export function* loadSuppliers(action: actions.LoadSuppliers): SagaIterator {
  const { address, bookingType } = action.payload
  const { street, city, postcode, country } = mapLocation(address)

  const locale = yield select(getLocale)
  const contract = yield select(getCurrentContract)

  try {
    const data: SupplierResponse = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/nearbySuppliers',
      {
        params: {
          locale,
          street,
          city,
          postcode,
          country,
          bookingType,
          contractId: contract.nolsId,
        },
      },
      defaultOptions,
    )

    yield put(new actions.LoadSuppliersSuccess({ data }))
  } catch {
    yield put(new actions.LoadSuppliersFailure())
  }
}

export default [takeLatest(actions.ActionTypes.LOAD_SUPPLIERS, loadSuppliers)]
