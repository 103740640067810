import moment from 'moment'

const dateWithoutTimeFormat = 'dddd DD MMMM YYYY'

export const formatTimelineDate = (time: string) => {
  const dateTime = moment(time).parseZone()

  if (dateTime.hour() === 0) {
    return dateTime.format(dateWithoutTimeFormat)
  }

  return dateTime.format('LLLL')
}
