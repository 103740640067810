import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'

import { getCurrentTimeLineItem } from 'mlp-client/src/order-status/utils'
import { timeLineItemsConfig } from 'mlp-client/src/order-status/options'
import Translation from 'mlp-client/src/localization/Translation'
import OrderAndDeliveryIcon from 'mlp-client/src/components/icons/oreder-and-delivery-icon/OrderAndDeliveryIcon'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { Contract, ContractVehicle } from 'mlp-client/src/contracts/types'
import Banner from 'mlp-client/src/components/banner/Banner'
import { Locales } from 'mlp-client/src/localization/enums'
import { isFrance } from 'mlp-client/src/order-status/selectors'

export interface Props {
  order: Contract
  to: string
  locale: Locales
}

class BannerCard extends React.PureComponent<Props> {
  renderDefaultVehicle = (): React.ReactNode => (
    <Translation key="defaultVehicle" id="myLeaseplan.orderStatus.vehicle" />
  )

  getOrderedVehicle = (vehicle: ContractVehicle) =>
    vehicle?.make || vehicle?.model
      ? `${vehicle.make} ${vehicle.model}`
      : this.renderDefaultVehicle

  render() {
    const { order, to, locale } = this.props

    if (!order) {
      return null
    }

    const timeLineItem = getCurrentTimeLineItem(
      timeLineItemsConfig(order.id, null, isFrance(locale)),
      order.orderStatus,
    )

    if (!timeLineItem) {
      return null
    }

    const SubTitleComponent = timeLineItem.subtitle

    return (
      <MyLeaseplanContext.Consumer>
        {context =>
          context.orderStatus?.isBannerVisibleOnDashboard ? (
            <Spacing mb={3}>
              <Banner
                statusInfo={
                  <Text bold>
                    <Translation
                      id="myLeaseplan.dashboard.banner.yourCarStatus"
                      replace={{
                        car: this.getOrderedVehicle(order?.vehicle),
                      }}
                    />
                  </Text>
                }
                title={timeLineItem.title}
                subTitle={<SubTitleComponent />}
                to={to}
                buttonLabel="myLeaseplan.dashboard.banner.seeDetails"
                icon={<OrderAndDeliveryIcon width={128} height={128} />}
              />
            </Spacing>
          ) : null
        }
      </MyLeaseplanContext.Consumer>
    )
  }
}

export default BannerCard
