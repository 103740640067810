import { Breakpoint, Color, withBreakpoint } from '@leaseplan/ui'
import * as React from 'react'

import ChevronLeftIcon from 'mlp-client/src/components/icons/chevron-left-icon/ChevronLeftIcon'
import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'

import { StyledBreadcrumbsList } from './Breadcrumbs.styled'
import { BreadcrumbsProps } from './Breadcrumbs.types'
import BreadcrumbsCollapsed from './BreadcrumbsCollapsed'
import BreadcrumbsSeparator from './BreadcrumbsSeparator'

const buildSeparatorElement = (separator: React.ReactNode, key: string) => (
  <BreadcrumbsSeparator key={key}>{separator}</BreadcrumbsSeparator>
)

function insertSeparators(
  items: JSX.Element[],
  separator: string | React.ReactNode,
  isMobile: boolean,
) {
  if (isMobile) {
    return [
      <BreadcrumbsSeparator key={'separator'}>
        {separator}
      </BreadcrumbsSeparator>,
      ...items,
    ]
  }

  return items.reduce(
    (acc: JSX.Element[], current: JSX.Element, index: number) => {
      const currentSegment =
        index < items.length - 1
          ? [current, buildSeparatorElement(separator, `separator-${index}`)]
          : [current]

      return [...acc, ...currentSegment]
    },
    [],
  )
}

export const BreadcrumbsComponent = (props: BreadcrumbsProps) => {
  const {
    breakpoint,
    children,
    itemsAfterCollapse = 2,
    itemsBeforeCollapse = 1,
    maxItems = 3,
    separator = <ChevronRightIcon color={'steel60'} />,
    color = 'steel60',
  } = props

  const [expanded, setExpanded] = React.useState(false)

  const isMobile = Breakpoint[Breakpoint.mobile] === breakpoint

  const renderItemsBeforeAndAfter = (allItems: JSX.Element[]) => {
    const handleClickExpand = () => {
      setExpanded(true)
    }

    if (itemsBeforeCollapse + itemsAfterCollapse >= allItems.length) {
      return allItems
    }

    return [
      ...allItems.slice(0, itemsBeforeCollapse),
      <BreadcrumbsCollapsed
        expanded={expanded}
        key="ellipsis"
        onClick={handleClickExpand}
      />,
      ...allItems.slice(allItems.length - itemsAfterCollapse, allItems.length),
    ]
  }

  const breadcrumbsNodes = React.Children.toArray(children)

  if (!breadcrumbsNodes.length) {
    return null
  }

  const filteredNodes =
    isMobile && breadcrumbsNodes.length > 1
      ? breadcrumbsNodes.slice(-2, -1)
      : breadcrumbsNodes

  const breadcrumbsElements = filteredNodes.map(
    (child: React.ReactNode, index: number) => (
      <li key={`child-${index}`}>{child}</li>
    ),
  )
  const breadcrumbsToRender =
    expanded || (maxItems && breadcrumbsElements.length <= maxItems)
      ? breadcrumbsElements
      : renderItemsBeforeAndAfter(breadcrumbsElements)

  return (
    <nav>
      <StyledBreadcrumbsList color={color}>
        {insertSeparators(
          breadcrumbsToRender,
          isMobile ? <ChevronLeftIcon color={Color[color]} /> : separator,
          isMobile,
        )}
      </StyledBreadcrumbsList>
    </nav>
  )
}

export const Breadcrumbs = withBreakpoint(BreadcrumbsComponent)
