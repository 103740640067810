import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { filterRouteParams } from 'mlp-client/src/components/myleaseplan-flow/utils'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureProps,
} from 'mlp-client/src/features-configuration/types'
import {
  CallUsTile,
  FlowData,
  StaticSuppliers,
  StepKey,
} from 'mlp-client/src/flows/fuel-card/types'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { isFuelCardEnabled } from 'mlp-client/src/flows/fuel-card/selectors'
import { getUserWorkAddress } from 'mlp-client/src/user/utils'

interface StepDefinition {
  stepKey: StepKey
  stepView: React.ComponentType | React.NamedExoticComponent
  stepConfig: GenericObject
}

type StepConfiguration = { [key in StepKey]?: StepDefinition }

export interface FuelCardFlowConfiguration {
  getSteps(flowData: FlowData): readonly StepKey[]
  allSteps: StepConfiguration
  sharedConfig?: {
    suppliers: StaticSuppliers
    callUsTile?: CallUsTile
  }
}

export interface Props
  extends MyLeaseplanFeatureProps<FuelCardFlowConfiguration> {
  isEnabled(config: MyleaseplanConfig): boolean
}

class Flow extends React.PureComponent<Props> {
  static routePrefix = 'myLeaseplan.fuelCard.steps.'
  static stepTitlePrefix = 'myLeaseplan.fuelCard'

  renderStep = (stepParameters: FlowProps<FlowData>) => {
    const { name } = stepParameters.activeStep
    const { configuration } = this.props.featureConfig
    const { allSteps, sharedConfig = {} } = configuration
    const { stepConfig = {}, stepView: StepView } = allSteps[name]

    if (StepView) {
      // This Dynamic component is not typed here for now
      // but these is a convention for the expected props
      // Every step should only accept (FLow API Data & Step config)
      // every step should type (inside component) what it expected for the outside world.
      return (
        <StepView
          {...stepParameters}
          config={{ ...stepConfig, ...sharedConfig }}
        />
      )
    }

    return <NotFound />
  }

  onFlowClose = () => {
    const { match } = this.props

    return (
      <TranslatedRedirect
        to="myLeaseplan.dashboard"
        params={filterRouteParams(match.params)}
      />
    )
  }

  render() {
    return (
      <MyLeaseplanContext.Consumer>
        {context => {
          const { featureConfig, user, isEnabled } = this.props

          if (!isEnabled(context)) {
            return <NotFound data-e2e-id="notFound" />
          }

          const initialFlowData = {
            hasWorkAddress: Boolean(getUserWorkAddress(user.addresses)),
          }

          return (
            <MyleaseplanFlow
              stepTitlePrefix={Flow.stepTitlePrefix}
              routePrefix={Flow.routePrefix}
              getSteps={featureConfig.configuration?.getSteps}
              onClose={this.onFlowClose}
              render={this.renderStep}
              eventCategory={EventCategoryService.FAST_SERVICE}
              flowName={EventCategoryFlow.FUEL_CARD}
              initialFlowData={initialFlowData}
            />
          )
        }}
      </MyLeaseplanContext.Consumer>
    )
  }
}

export type ReduxProps = Pick<Props, 'isEnabled'>

export const mapStateToProps = createStructuredSelector({
  isEnabled: isFuelCardEnabled,
})

export { Flow }
export default connect(mapStateToProps)(Flow)
