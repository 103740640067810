import { DisabledDay, Grid, GridItem, Text } from '@leaseplan/ui'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { Weekday } from 'mlp-client/src/enums'
import { DatePickerField } from 'mlp-client/src/form/components/fields/DatePickerFields'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import { Options } from 'mlp-client/src/form/types'
import {
  dateNotAfter,
  dateNotBefore,
  dateNotOnWeekends,
  invalidDate,
  required,
} from 'mlp-client/src/form/validations'
import { getCountryHolidays } from 'mlp-client/src/selectors'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'

export interface FormValues {
  date?: string
  time?: string
}

const translateDateValidationPath = makeTranslationPath(
  'myLeaseplan.newSetup.shared.forms.date.validations',
)

interface PeriodValidation {
  date: Date
  message: string
}

export interface DateProps {
  title?: string
  placeholder?: string
  notBefore?: PeriodValidation
  notAfter?: PeriodValidation
  enableWeekend?: boolean
  dateAddInfo?: React.ReactNode
  onChange?(val: string): void
}

export type TimeProps =
  | { enabled: false }
  | {
      enabled: true
      options: Options
      title?: string
      hideEmptyOption?: boolean
      onChange?(val: string): void
    }

export interface Props {
  date?: DateProps
  time?: TimeProps
  todayDate: string
  publicHolidays: DisabledDay[]
}

const DateTimeFieldGroup: React.FC<Props> = ({
  todayDate,
  date: {
    title: dateTitle,
    placeholder,
    notBefore = {
      date: new Date(todayDate),
      message: translateDateValidationPath('dateNotInPast'),
    },
    notAfter = {},
    dateAddInfo,
    enableWeekend,
    onChange: onDateChange,
  },
  time,
  publicHolidays,
}) => {
  let timeEnabled: boolean
  let timeTitle: string
  let timeOptions: Options
  let timeHideEmptyOption: boolean
  let onTimeChange: (val: string) => void

  if (time?.enabled) {
    ;({
      enabled: timeEnabled,
      title: timeTitle,
      options: timeOptions,
      hideEmptyOption: timeHideEmptyOption,
      onChange: onTimeChange,
    } = time)
  }

  const validations = [
    invalidDate,
    ...(notBefore.date ? [dateNotBefore(notBefore.date)] : []),
    ...(notAfter.date ? [dateNotAfter(notAfter.date)] : []),
    ...(!enableWeekend ? [dateNotOnWeekends] : []),
    required,
  ]

  return (
    <LocalizationContext.Consumer>
      {({ translate }) => (
        <Grid>
          <GridItem>
            <Text fontWeight="bold" component="p" color="thunder">
              <Translation id={dateTitle} />
            </Text>
            <DatePickerField
              name="date"
              validations={validations}
              initialMonth={notBefore.date}
              placeholder={placeholder ? { id: placeholder } : undefined}
              onChange={onDateChange}
              disabledDays={[
                {
                  message: notAfter.message && translate(notAfter.message),
                  after: notAfter.date,
                },
                {
                  message: translate(notBefore.message),
                  before: notBefore.date,
                },
                {
                  daysOfWeek: !enableWeekend && [
                    Weekday.Saturday,
                    Weekday.Sunday,
                  ],
                  message: translate(
                    translateDateValidationPath('notDuringWeekends'),
                  ),
                },
                ...publicHolidays,
              ]}
            />
            {dateAddInfo}
          </GridItem>
          {timeEnabled && (
            <GridItem span="auto">
              <Text fontWeight="bold" component="p" color="thunder">
                <Translation id={timeTitle} />
              </Text>
              <SelectField
                name="time"
                validations={[required]}
                options={timeOptions}
                hideEmptyOption={timeHideEmptyOption}
                onChange={onTimeChange}
              />
            </GridItem>
          )}
        </Grid>
      )}
    </LocalizationContext.Consumer>
  )
}

type ReduxProps = Pick<Props, 'publicHolidays' | 'todayDate'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  todayDate: moment().startOf('day').format(),
  publicHolidays: getCountryHolidays(state),
})

export { DateTimeFieldGroup }
export default connect(mapStateToProps)(DateTimeFieldGroup)
