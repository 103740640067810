import React from 'react'

import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import TyreServices, {
  Props as TyreServicesProps,
} from 'mlp-client/src/flows/tyres/components/tyre-services/TyreServices'

interface Props extends MyLeaseplanFeatureProps<TyreServicesProps> {}

export const TyreServicesPage = (props: Props) => (
  <TyreServices {...props.featureConfig.configuration} />
)
export default TyreServicesPage
