import { Breakpoint, BreakpointName } from '@leaseplan/ui'

import { brandWhitelist } from 'mlp-client/src/enums'

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth ||
  481

const isMobileAgent = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent,
  )

export const isMobile = () => !isMobileAgent() || getWidth() <= 480

export const isMobileBreakpoint = (breakpoint: BreakpointName) => {
  switch (breakpoint) {
    case Breakpoint[Breakpoint.mobile]:
    case Breakpoint[Breakpoint.mobileLandscape]:
      return true
    default:
      return false
  }
}

export const isTabletBreakpoint = (breakpoint: BreakpointName) =>
  breakpoint === Breakpoint[Breakpoint.tablet]

export const isDesktopBreakpoint = (breakpoint: BreakpointName) => {
  switch (breakpoint) {
    case Breakpoint[Breakpoint.lap]:
    case Breakpoint[Breakpoint.desktop]:
    case Breakpoint[Breakpoint.wide]:
      return true
    default:
      return false
  }
}

export const isObject = (
  value: string | number | boolean | object,
): value is object =>
  value && typeof value === 'object' && value.constructor === Object

export const isExternal = (url: string): boolean =>
  /^https?:\/\//.test(url) || /^www/.test(url)

export const isJavascript = (url: string): boolean => /^javascript:/.test(url)

export const isMail = (url: string): boolean => /^mailto:/.test(url)

export const isPhone = (url: string): boolean => /^tel:/.test(url)

export const isValidBrand = (id?: string): boolean =>
  brandWhitelist.includes(id)
