import {
  ButtonChevronLeftIcon,
  GridItem,
  Spacing,
  withBreakpoint,
} from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import ReactIdSwiper, { SwiperRefNode } from 'react-id-swiper'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import CardRadioList from 'mlp-client/src/form/components/fields/RadioFields/supplier-card-radio-list/CardRadioList'
import EmptyMapMarkerIcon from 'mlp-client/src/components/icons/empty-map-marker-icon/EmptyMapMarkerIcon'
import { AppState } from 'mlp-client/src/types'
import { isDesktopBreakpoint } from 'mlp-client/src/utils'
import { Option, Options } from 'mlp-client/src/form/types'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'

import { SupplierMapCard } from './supplier-map-card/SupplierMapCard'
import {
  BackToList,
  CardsWrapper,
  DesktopMapWrapper,
  MapAndListWrapper,
  MobileMapWrapper,
  ViewOnMap,
} from './SelectSupplier.styled'
import SupplierMap from './suppliers-map/SupplierMap'
import { Props, ReduxProps, State, Suppliers } from './types'
import { supplierToOption } from './utils'

const loadMoreIncrement = 5

class SelectSupplier extends React.PureComponent<Props, State> {
  swiperRef: React.RefObject<SwiperRefNode>

  state = {
    maxItemsToShow: loadMoreIncrement,
    highlightedSupplierId: '',
    isMobileMapOpen: false,
    selectedSupplierId: '',
  }

  constructor(props: Props) {
    super(props)
    this.swiperRef = React.createRef()
  }

  componentDidMount() {
    // on mobile we highlight the first supplier
    if (!isDesktopBreakpoint(this.props.breakpoint)) {
      const [firstSupplier] = this.props.suppliers

      this.changeHighlightedSupplier(firstSupplier.id)()
    }
  }

  componentDidUpdate() {
    // if the swiper have the DOM elements of our slides, stop updating.
    const swiper = this.swiperRef.current?.swiper

    if (swiper && swiper.slides && !swiper.slides.length) {
      swiper.update()
    }
  }

  componentWillUnmount() {
    if (this.swiperRef.current?.swiper) {
      this.swiperRef.current.swiper.destroy()
    }
  }

  onSlideChange = () => {
    const activeSupplierId = this.getActiveSupplierId()

    this.changeHighlightedSupplier(activeSupplierId)()
  }

  loadMore = () => {
    this.setState((prevState: State) => ({
      maxItemsToShow: prevState.maxItemsToShow + loadMoreIncrement,
    }))
  }

  /**
   * @desc Change the highlightedSupplierId in state, its used in both list of suppliers and the Map.
   * When you hover over a list item the correspondent supplier on map is selected, and the other way arround.
   * and on mobile we also swipe to the right supplier card
   */
  changeHighlightedSupplier = (
    supplierId: string,
    shouldSwipe: boolean = false,
  ) => () => {
    this.setState({
      highlightedSupplierId: supplierId,
    })

    if (this.swiperRef.current?.swiper && shouldSwipe) {
      const supplierIndex = this.props.suppliers.findIndex(
        supplier => supplier.id === supplierId,
      )

      this.swiperRef.current.swiper.slideTo(supplierIndex)
    }
  }

  openMobileMap = () => {
    this.setState({
      isMobileMapOpen: true,
    })
  }

  closeMobileMap = (event?: React.FormEvent<HTMLButtonElement>) => {
    if (event) {
      // To prevent the from from submitting.
      event.preventDefault()
    }

    this.setState({
      isMobileMapOpen: false,
    })
  }

  getActiveSupplierId = (): string | null => {
    if (this.swiperRef.current?.swiper) {
      const { suppliers } = this.props
      const { activeIndex } = this.swiperRef.current.swiper

      return suppliers[activeIndex] ? suppliers[activeIndex].id : null
    }

    return null
  }

  renderMap = (suppliers: Suppliers, supplierOptions: Options) => {
    const { breakpoint } = this.props

    if (!isDesktopBreakpoint(breakpoint)) {
      return this.renderMobileMap(suppliers, supplierOptions)
    }

    return this.renderDesktopMap(suppliers)
  }

  getMapBoundsPadding = (): google.maps.Padding | undefined => {
    const { breakpoint } = this.props
    const isDesktop = isDesktopBreakpoint(breakpoint)

    // Due to the cards overlay on mobile map we need different padding for the markers
    if (!isDesktop) {
      return {
        top: 80,
        bottom: 400,
        left: 20,
        right: 20,
      }
    }
  }

  renderSuppliersMap = (filteredSuppliers: Suppliers, mapOptions = {}) => {
    const { breakpoint, searchLocation } = this.props
    const isDesktop = isDesktopBreakpoint(breakpoint)

    return (
      <SupplierMap
        searchLocation={searchLocation}
        suppliers={filteredSuppliers}
        onMarkerClick={this.changeHighlightedSupplier}
        highlightedSupplierId={this.state.highlightedSupplierId}
        mapOptions={mapOptions}
        showSupplierNameOnMarkers={isDesktop}
        mapBoundsPadding={this.getMapBoundsPadding()}
      />
    )
  }

  renderCards = (supplierOptions: Options) => (
    <ReactIdSwiper
      ref={this.swiperRef}
      loop={false}
      slidesPerView="auto"
      on={{ slideChange: this.onSlideChange }}
      normalizeSlideIndex={true}
    >
      {supplierOptions.map((supplierOption: Option) => (
        <SupplierMapCard
          form={this.props.form}
          key={supplierOption.value}
          value={supplierOption.value}
          supplierName={supplierOption.title}
          distance={supplierOption.ribbon.text}
          supplierAddress={supplierOption.subtitle}
        />
      ))}
    </ReactIdSwiper>
  )

  renderMobileMap = (suppliers: Suppliers, supplierOptions: Options) => {
    const { isMobileMapOpen } = this.state

    // We only hide the map, so we don't have to re-instantiate Google Map if the user want to see the map again.
    return (
      <MobileMapWrapper isMobileMapOpen={isMobileMapOpen}>
        <BackToList
          variant="secondary"
          onClick={this.closeMobileMap}
          icon={ButtonChevronLeftIcon}
          iconPosition="left"
        >
          <Translation id="myLeaseplan.serviceRequest.supplier.map.returnToList" />
        </BackToList>
        {this.renderSuppliersMap(suppliers, { zoomControl: false })}
        <CardsWrapper>{this.renderCards(supplierOptions)}</CardsWrapper>
      </MobileMapWrapper>
    )
  }

  renderDesktopMap = (suppliers: Suppliers) => (
    <DesktopMapWrapper
      span={{
        desktop: 8,
        lap: 8,
        mobile: 12,
        tablet: 12,
      }}
    >
      {this.renderSuppliersMap(suppliers)}
    </DesktopMapWrapper>
  )

  render() {
    const { suppliers = [], labels, measureUnit } = this.props

    const mappedSuppliers: Options = suppliers.map(
      supplierToOption(measureUnit),
    )

    return (
      <>
        <Spacing mb={1}>
          <Headline variant="100">
            <Translation
              id={labels.availableServiceCenters}
              replace={{ amount: mappedSuppliers.length }}
            />
          </Headline>
        </Spacing>

        <ViewOnMap onClick={this.openMobileMap}>
          <EmptyMapMarkerIcon />
          <Translation id="myLeaseplan.serviceRequest.supplier.linkToMap" />
        </ViewOnMap>

        <MapAndListWrapper wrap="wrap" gutter={false}>
          <GridItem
            span={{
              desktop: 4,
              lap: 4,
              mobile: 12,
              tablet: 8,
            }}
          >
            <RevealingItemList batchSize={100} items={mappedSuppliers}>
              {options => (
                <CardRadioList
                  name="supplier"
                  options={options}
                  onCardHover={this.changeHighlightedSupplier}
                  highlightedValue={this.state.highlightedSupplierId}
                  form={this.props.form}
                />
              )}
            </RevealingItemList>
          </GridItem>
          {this.renderMap(suppliers, mappedSuppliers)}
        </MapAndListWrapper>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  measureUnit: getTranslation(
    state,
    'myLeaseplan.shared.units.distance.kilometer',
  ),
})

export { SelectSupplier }
export default withBreakpoint(connect(mapStateToProps)(SelectSupplier))
