import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { SubmitVehicleDocumentsRequest } from 'mlp-client/src/flows/vehicle-documents/actions'
import SelectLocationStep, {
  StepConfig as LocationStepConfig,
} from 'mlp-client/src/flows/components/steps/location/LocationStep'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { FlowData } from 'mlp-client/src/flows/vehicle-documents/types'
import { mapAddress } from 'mlp-client/src/utils/address/address'

export interface Props extends StepViewProps<LocationStepConfig, FlowData> {
  createVehicleDocumentsRequest(
    data: FlowData,
    callback: (error: boolean) => void,
  ): void
}

class LocationStep extends React.PureComponent<Props> {
  render() {
    const {
      flowData: { address },
      ...locationProps
    } = this.props

    return (
      <SelectLocationStep
        titleLabel="myLeaseplan.vehicleDocuments.steps.selectLocation.pageTitle"
        descriptionLabel="myLeaseplan.vehicleDocuments.steps.selectLocation.description"
        flowData={{ address }}
        {...locationProps}
        submitFlow={this.props.createVehicleDocumentsRequest}
      />
    )
  }
}

type DispatchProps = Pick<Props, 'createVehicleDocumentsRequest'>
type OwnProps = Omit<Props, keyof DispatchProps>

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  createVehicleDocumentsRequest: (
    data: FlowData,
    onComplete: (error: boolean) => void,
  ) => {
    const {
      flowData: { contractId, documentType },
    } = props

    dispatch(
      new SubmitVehicleDocumentsRequest({
        request: {
          address: data.address && mapAddress(data.address),
          contractId,
          documentType,
        },
        onComplete,
      }),
    )
  },
})

export { LocationStep }
export default connect<DispatchProps>(
  undefined,
  mapDispatchToProps,
)(LocationStep)
