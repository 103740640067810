import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Form, FormRenderProps } from 'react-final-form'

import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { Contract } from 'mlp-client/src/contracts/types'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import { SubmitLastMileage } from 'mlp-client/src/flows/mileage-update/actions'
import { getLastKnownMileage } from 'mlp-client/src/flows/mileage-update/utils'
import { MileageLabel } from 'mlp-client/src/form/components/mileage/MileageLabel'
import { AppState } from 'mlp-client/src/types'
import MileageField from 'mlp-client/src/form/components/fields/mileage-field/Mileage'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/mileage-update/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import { FormSubmission } from 'mlp-client/src/form/types'

export interface Props extends FlowProps<FlowData>, FormSubmission<FormData> {
  contract: Contract
}

export interface FormData {
  mileage: number
  contractId: string
}

class EnterCarMileageStep extends React.PureComponent<Props> {
  onSubmit: FormSubmission<FormData>['onSubmit'] = (
    formData: FormData,
    formApi,
    onComplete,
  ) => {
    const { goToNextStep, onSubmit } = this.props

    onSubmit(formData, formApi, data => {
      if (data) {
        onComplete(data)
      } else {
        onComplete()
        goToNextStep()
      }
    })
  }

  render() {
    const { contract } = this.props

    if (!contract) {
      return null
    }

    const lastKnownMileage = parseInt(getLastKnownMileage(contract), 10) || 0
    const mileageLabelKey = `myLeaseplan.mileageUpdate.steps.enterCarMileage.updateCurrentMileageLabel`

    return (
      <Form<FormData>
        onSubmit={this.onSubmit}
        initialValues={{ contractId: contract.id }}
      >
        {({
          handleSubmit,
          hasValidationErrors,
          submitting,
        }: FormRenderProps) => (
          <StepLayout isLoading={submitting}>
            <TwoColumnFlowStep
              textLabel="myLeaseplan.mileageUpdate.steps.enterCarMileage.text"
              titleLabel="myLeaseplan.mileageUpdate.steps.enterCarMileage.title"
            >
              <form onSubmit={handleSubmit}>
                <MileageField
                  lastKnownMileage={lastKnownMileage}
                  label={<MileageLabel labelKey={mileageLabelKey} />}
                  allowOnlyIntegers={true}
                  required={true}
                />
                <Spacing mt={2} />
                <SubmitButton
                  disabled={hasValidationErrors || submitting}
                  onClick={handleSubmit}
                  label="myLeaseplan.mileageUpdate.steps.enterCarMileage.nextButton"
                  dataE2eId="button-next-step"
                />
              </form>
            </TwoColumnFlowStep>
          </StepLayout>
        )}
      </Form>
    )
  }
}

type ReduxProps = Pick<Props, 'contract'>
type DispatchProps = Pick<Props, 'onSubmit'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  contract: getCurrentContract(state),
})

export const mapDispatchToProps: DispatchProps = {
  onSubmit: (formData, formApi, onComplete) =>
    new SubmitLastMileage({
      ...formData,
      mileageDate: new Date(),
      onComplete,
    }),
}

export { EnterCarMileageStep }
export default connect(mapStateToProps, mapDispatchToProps)(EnterCarMileageStep)
