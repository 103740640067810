import React from 'react'

import { CarMissingIcon } from 'mlp-client/src/components/icons'

import { Wrapper } from './CarIcon.styled'

interface Props {
  image: string
  size: 's' | 'm'
  isDropdownOpened?: boolean
}

const SIZES_PROPERTIES = {
  carMissing: {
    width: 47,
    height: 47,
  },
  img: {
    s: {
      width: 50,
    },
    m: {
      width: 65,
    },
  },
}

const CarIcon: React.FC<Props> = ({ image, size, isDropdownOpened }) =>
  image ? (
    <Wrapper size={size} isDropdownOpened={isDropdownOpened}>
      <img src={image} width={SIZES_PROPERTIES.img[size].width} alt="CarIcon" />
    </Wrapper>
  ) : (
    <CarMissingIcon
      width={SIZES_PROPERTIES.carMissing.width}
      height={SIZES_PROPERTIES.carMissing.height}
    />
  )

export default CarIcon
