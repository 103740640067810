import { Button, Hide, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import Supplier from 'mlp-client/src/components/suppliers/Supplier'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { SubmitFuelCard } from 'mlp-client/src/flows/fuel-card/actions'
import CallUsTile from 'mlp-client/src/flows/fuel-card/components/steps/select-service-type-with-suppliers-step/CallUsTile'
import {
  getFuelCardOptions,
  workHoursTranslationSelector,
} from 'mlp-client/src/flows/fuel-card/selectors'
import {
  CallUsTile as CallUsTileType,
  FlowData,
  StaticSuppliers,
} from 'mlp-client/src/flows/fuel-card/types'
import {
  getMappedSupplier,
  isSupplierAvailable,
} from 'mlp-client/src/flows/fuel-card/utils'
import { Options, FormSubmission } from 'mlp-client/src/form/types'
import { AppState } from 'mlp-client/src/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'

export interface Props extends FlowProps<FlowData>, FormSubmission<FlowData> {
  supplierInfo: string
  weekDaysTranslation: string
  options: Options
  config: {
    suppliers?: StaticSuppliers
    callUsTile?: CallUsTileType
    confirmLabel?: string
    infoLabel?: string
  }
}

class LostAndStolenStep extends React.PureComponent<Props> {
  render() {
    const {
      supplierInfo,
      config: { infoLabel, confirmLabel, callUsTile, suppliers },
      options,
      weekDaysTranslation,
      flowData,
      onSubmit,
    } = this.props

    return (
      <ReactFinalForm<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel="myLeaseplan.fuelCard.steps.allLostAndStolenConfirm.mainTitle">
                <Hide from="mobile">
                  <Headline variant="200">
                    <Translation id="myLeaseplan.fuelCard.types.sorry" />
                  </Headline>
                </Hide>
                <Hide until="mobile">
                  <Headline variant="200" withMarginBottom>
                    <Translation id="myLeaseplan.fuelCard.types.sorry" />
                  </Headline>
                </Hide>
                <Text>
                  <Translation
                    id={
                      infoLabel ||
                      'myLeaseplan.fuelCard.steps.allLostAndStolenConfirm.info'
                    }
                  />
                </Text>
                {suppliers &&
                  suppliers.map(
                    supplier =>
                      isSupplierAvailable(supplier, options) && (
                        <Spacing mv={2} key={supplier.name}>
                          <Supplier
                            supplier={getMappedSupplier(
                              supplierInfo,
                              supplier,
                              weekDaysTranslation,
                            )}
                          />
                        </Spacing>
                      ),
                  )}
                {callUsTile && (
                  <Spacing mv={2}>
                    <CallUsTile callUsTile={callUsTile} />
                  </Spacing>
                )}
                <Spacing mv={2}>
                  <SubmitStatus
                    submitting={submitting}
                    failed={submitFailed}
                    submittingTranslation="myLeaseplan.timeline.fuelCard.items.status.processing"
                    failedTranslation="myLeaseplan.error.callServiceDesk"
                  >
                    <Button
                      onClick={handleSubmit}
                      data-e2e-id="buttonDone"
                      disabled={submitting}
                    >
                      <Translation
                        id={confirmLabel || 'myLeaseplan.shared.done'}
                      />
                    </Button>
                  </SubmitStatus>
                </Spacing>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </ReactFinalForm>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'supplierInfo' | 'options' | 'weekDaysTranslation'
>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps | DispatchProps)>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  supplierInfo: getTranslation(
    state,
    'myLeaseplan.fuelCard.steps.selectServiceType.supplierContactInfo',
  ),
  options: getFuelCardOptions(state),
  weekDaysTranslation: workHoursTranslationSelector(state),
})

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, formApi, formOnComplete) => {
    const { closeFlow } = props

    dispatch(
      new SubmitFuelCard({
        flowData,
        onComplete: error => {
          if (!error) {
            closeFlow()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

export { LostAndStolenStep }
export default connect(mapStateToProps, mapDispatchToProps)(LostAndStolenStep)
