import { createSelector } from 'reselect'

import { AppState } from '../types'
import { CountryCode } from '../localization/enums'
import { ContactInfo, User, UserInfo, UserProfile } from './types'
import {
  convertUserToUserProfile,
  getUserModel,
  getUserContactsInformation,
} from './utils'

export const getCountryCode = (state: AppState): CountryCode => {
  const userInfoData = getUserInfoData(state)
  let locale = userInfoData?.defaultLocale?.split('-')[1]

  if (!locale) {
    //fallback for absent DefaultLocale
    locale = userInfoData?.countryCode?.replace('LP', '')
  }

  return locale?.toLowerCase() as CountryCode
}

export const getUserInfoData = (state: AppState): UserInfo =>
  state.user.userInfo

export const getUser = (state: AppState): User => {
  const user = state.user.user

  return user ? getUserModel(user) : null
}

export const getAuthenticatedUser = (state: AppState): User => getUser(state)

export const getUserId = (state: AppState): string => getUser(state)?.id

export const getIsUserLoading = (state: AppState) => state.user.loading

export const getIsUserLoadingFailed = (state: AppState) => state.user.error

export const userProfileSelector = createSelector(getUser, (user):
  | UserProfile
  | undefined => {
  if (!user) {
    return undefined
  }

  return convertUserToUserProfile(user)
})

export const getUserContactInfo = createSelector(
  getUser,
  getUserContactsInformation,
)

export const getUserContacts = (state: AppState): ContactInfo => ({
  ...getUserContactInfo(state),
})
