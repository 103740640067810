import { User, UserInfo } from './types'
import * as actions from './actions'

export interface State {
  error: boolean
  loading: boolean
  roles: readonly string[]
  user: null | User
  userInfo: null | UserInfo
}

export const defaultState: State = {
  error: false,
  loading: false,
  roles: [],
  user: null,
  userInfo: null,
}

const loadUserInfo = (state: State) => ({
  ...state,
  loading: true,
})

const loadUserInfoFailed = () => ({
  ...defaultState,
  loading: false,
  error: true,
})

const loadUserInfoSuccess = (state: State, userInfo: UserInfo) => ({
  ...state,
  userInfo,
  loading: false,
})

const loadUser = (state: State, { noLoading }: { noLoading?: boolean }) => ({
  ...state,
  loading: !noLoading,
})

const loadUserSuccess = (state: State, user: User) => ({
  ...state,
  user,
  loading: false,
})

const loadUserFailed = () => ({
  ...defaultState,
  loading: false,
  error: true,
})

const updateUserSuccess = (state: State, error = false) => ({
  ...state,
  error,
})

const clearUser = () => ({
  ...defaultState,
})

const setUserRoles = (state: State, userRoles: readonly string[]) => ({
  ...state,
  roles: userRoles,
})

const userReducer = (state = defaultState, action: actions.Action): State => {
  switch (action.type) {
    case actions.ActionTypes.LOAD_USER_INFO:
      return loadUserInfo(state)
    case actions.ActionTypes.LOAD_USER_INFO_SUCCESS:
      return loadUserInfoSuccess(state, action.payload)
    case actions.ActionTypes.LOAD_USER_INFO_FAILED:
      return loadUserInfoFailed()
    case actions.ActionTypes.LOAD_USER:
      return loadUser(state, action.payload)
    case actions.ActionTypes.LOAD_USER_SUCCESS:
      return loadUserSuccess(state, action.payload)
    case actions.ActionTypes.LOAD_USER_FAILED:
      return loadUserFailed()
    case actions.ActionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state)
    case actions.ActionTypes.CLEAR_USER:
      return clearUser()
    case actions.ActionTypes.SET_USER_ROLES:
      return setUserRoles(state, action.payload)
    default:
      return state
  }
}

export default userReducer
