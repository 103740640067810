import { SubmissionErrors } from 'final-form'

export const enum ActionTypes {
  SUBMIT_LAST_MILEAGE = '@@myLeaseplan/mileageUpdateRequest/SUBMIT_LAST_MILEAGE',
}

export class SubmitLastMileage {
  readonly type = ActionTypes.SUBMIT_LAST_MILEAGE
  constructor(
    public payload: {
      mileage: number
      contractId: string
      mileageDate?: Date
      attachmentKeys?: readonly string[]
      onComplete: (errors?: SubmissionErrors) => void
    },
  ) {}
}

export type Action = SubmitLastMileage
