import { Grid, GridItem } from '@leaseplan/ui'
import { MessageBar } from '@velocity/ui/draft'
import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import { ResetCancellationStatus } from 'mlp-client/src/timeline-details/actions'
import { CancellationStatus } from 'mlp-client/src/timeline-details/enums'
import { getTimelineDetails } from 'mlp-client/src/timeline-details/selectors'
import { AppState } from 'mlp-client/src/types'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'

interface Props {
  bookingCancellationStatus: string
  dismissBookingCancellationStatus?(): void
}

interface State {
  showNotification: boolean
}

// TODO: refactor this into a shared Notifications component
class BookingCancellationMessage extends React.PureComponent<Props, State> {
  state = {
    showNotification: true,
  }

  dismissNotification = () => {
    this.props.dismissBookingCancellationStatus()
  }

  render() {
    const { bookingCancellationStatus } = this.props
    const success = bookingCancellationStatus === CancellationStatus.SUCCESS

    return bookingCancellationStatus && this.state.showNotification ? (
      <Grid>
        <GridItem span="grow">
          <SMessageBarContainer>
            <MessageBar
              variant={success ? 'success' : 'alert'}
              onClose={this.dismissNotification}
            >
              <MessageBar.Text>
                <Translation
                  id={
                    success
                      ? 'myLeaseplan.maintenance.steps.cancelBooking.confirmedCancellation'
                      : 'myLeaseplan.maintenance.steps.cancelBooking.unableToCancel'
                  }
                  data-e2e-id={`bookingCancellation_${
                    success ? 'successMessage' : 'unableToCancelMessage'
                  }`}
                />
              </MessageBar.Text>
            </MessageBar>
          </SMessageBarContainer>
        </GridItem>
      </Grid>
    ) : null
  }
}

type ReduxProps = Pick<Props, 'bookingCancellationStatus'>
type DispatchProps = Pick<Props, 'dismissBookingCancellationStatus'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  bookingCancellationStatus: getTimelineDetails(state).cancellationStatus,
})

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  dismissBookingCancellationStatus: () => {
    dispatch(new ResetCancellationStatus())
  },
})

export { BookingCancellationMessage }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingCancellationMessage)
