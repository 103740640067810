import React from 'react'
import { connect } from 'react-redux'

import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { filterRouteParams } from 'mlp-client/src/components/myleaseplan-flow/utils'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import {
  FlowData,
  StepDefinition,
  VehicleDocumentsFlowConfiguration,
} from 'mlp-client/src/flows/vehicle-documents/types'
import { getSelectedDocument } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import { SelectDocument } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/actions'
import { SiteCoreDocument } from 'mlp-client/src/content/types'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'
import { AppState } from 'mlp-client/src/types'

interface ReduxProps {
  contractId: string
  selectedDocument: SiteCoreDocument
}

export interface Props
  extends ReduxProps,
    MyLeaseplanFeatureProps<VehicleDocumentsFlowConfiguration> {
  selectDocument(documentId: string): void
}

interface State {
  returnToPath?: string
}

export class Flow extends React.PureComponent<Props, State> {
  state: State = {}

  static getDerivedStateFromProps(props: Props, state: State) {
    const { state: locationState } = props.location

    if (locationState && locationState.returnTo && !state.returnToPath) {
      return {
        returnToPath: locationState.returnTo,
      }
    }

    return null
  }

  componentWillUnmount() {
    this.props.selectDocument(null)
  }

  renderStep = (stepParameters: FlowProps<FlowData>) => {
    const { name } = stepParameters.activeStep
    const {
      configuration: { allSteps },
    } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = allSteps[name] as StepDefinition

    if (StepView) {
      return <StepView {...stepParameters} config={{ ...stepConfig }} />
    }

    return <NotFound />
  }

  onFlowClose = (isMoveBack: boolean) => {
    const { match } = this.props
    const exitPath =
      (isMoveBack && this.state.returnToPath) || 'myLeaseplan.dashboard'

    return (
      <TranslatedRedirect
        to={exitPath}
        params={filterRouteParams(match.params)}
      />
    )
  }

  render() {
    const { featureConfig, contractId, selectedDocument } = this.props

    if (!selectedDocument) {
      return (
        <TranslatedRedirect
          to={'myLeaseplan.vehicleDocuments.selectDocument'}
        />
      )
    }

    return (
      <MyleaseplanFlow
        stepTitlePrefix="myLeaseplan.vehicleDocuments"
        routePrefix="myLeaseplan.vehicleDocuments.steps"
        getSteps={featureConfig.configuration.getSteps}
        onClose={this.onFlowClose}
        render={this.renderStep}
        initialFlowData={{
          contractId,
          documentType: selectedDocument?.DocumentType?.FriendlyName,
        }}
        eventCategory={EventCategoryService.FAST_SERVICE}
        flowName={EventCategoryFlow.DOCUMENTS}
      />
    )
  }
}

type DispatchProps = Pick<Props, 'selectDocument'>

export const mapDispatchToProps: DispatchProps = {
  selectDocument: (documentId: string) => new SelectDocument({ documentId }),
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  contractId: getCurrentContractId(state),
  selectedDocument: getSelectedDocument(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(Flow)
