import { Icon, IconProps } from '@leaseplan/ui'
import React from 'react'

type DefaultProps = Required<Pick<IconProps, 'width' | 'height'>>
type InternalProps = IconProps & DefaultProps

class OutlinedLocationIcon extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    width: '64px',
    height: '86px',
  }

  render() {
    return (
      <Icon viewBox="0 0 64 86" {...this.props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-411.000000, -586.000000)"
            strokeWidth="2.5"
            stroke="currentColor"
          >
            <g transform="translate(413.000000, 588.000000)">
              <path d="M30,0 C46.5684,0 60,11.9250291 60,29.8543689 C60,48.5077767 30,82 30,82 C30,82 0,48.536835 0,29.8543689 C0,11.9246311 13.4312,0 30,0 Z M30,41.7961165 C36.627417,41.7961165 42,36.449614 42,29.8543689 C42,23.2591239 36.627417,17.9126214 30,17.9126214 C23.372583,17.9126214 18,23.2591239 18,29.8543689 C18,36.449614 23.372583,41.7961165 30,41.7961165 Z" />
            </g>
          </g>
        </g>
      </Icon>
    )
  }
}

export default OutlinedLocationIcon
