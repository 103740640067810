import React from 'react'
import { connect } from 'react-redux'
import { MyAccountIcon } from '@velocity/ui/marketing-icons/draft'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import Translation from 'mlp-client/src/localization/Translation'
import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'
import { AppState } from 'mlp-client/src/types'

interface Props {
  loginLink: string
  className?: string
}

const LoginButton: React.FC<Props> = ({
  loginLink,
  className = 'main-menu-widgets__link',
}) => (
  <RouteLink to={loginLink} rel="nofollow" className={className}>
    <MyAccountIcon size="XXS" className="u-margin-right-tiny" />
    <Translation id="myLeaseplan.navigation.myLeasePlan" />
  </RouteLink>
)

type ReduxProps = Pick<Props, 'loginLink'>

const mapStateToProps = (state: AppState): ReduxProps => {
  // This code may run on MLP-2.0, as well as on the main front-end (both client and server). To ensure that we properly
  // redirect to MLP-2.0 (from main, for live locales) or to the main front-end (from MLP-2.0, for non-live locales), we
  // simply make the link always absolute for now.
  const baseUrl =
    typeof window === 'undefined'
      ? process.env.PLATFORM_URL
      : window.location.origin
  const myAccountLink = 'myLeaseplan.homepage'

  return {
    loginLink: `${baseUrl}${getLocalizedRoute(state, myAccountLink)}`,
  }
}

export { LoginButton }
export default connect(mapStateToProps)(LoginButton)
