import React from 'react'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'

export interface Props {
  render(): React.ReactNode | string
}

// This component invokes updating when user changes locale
// Here you can pass NOT only moment instance, but also numeric
// All items, that should update when user change locale, but is not do it

// If you need update Translation 'replace'
// Do NOT use it to update moment instance in Translation's 'replace' prop
// Put moment into function

// ---- BAD -----
// <Translation replace={{time: moment().format('LLLL')}} />
// <Translation replace={{time: () => <Localization render={() => moment().format('LLLL')}/> }} />
// <Translation replace={{time: () => moment().format('LLLL')}} />

// ---- GOOD -----
// const time = () => moment().format('LLLL')
// <Translation replace={{time}} />

export default class Localization extends React.PureComponent<Props> {
  render() {
    return (
      <LocalizationContext.Consumer>
        {this.props.render}
      </LocalizationContext.Consumer>
    )
  }
}
