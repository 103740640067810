import { Spacing, Text } from '@leaseplan/ui'
import currencySymbolMap from 'currency-symbol-map'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import { FormatDate } from 'mlp-client/src/localization/FormatDate'
import { GeneralListTile } from 'mlp-client/src/components/confirm-step-tiles'
import { EditButtonLabel } from 'mlp-client/src/components/confirm-step-tiles/EditButtonLabel'
import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import ImagePreview from 'mlp-client/src/components/image-preview/ImagePreview'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { SubmitCashClaimRequest } from 'mlp-client/src/flows/cash-claim/actions'
import { StepNames } from 'mlp-client/src/flows/cash-claim/enums'
import { FlowData } from 'mlp-client/src/flows/cash-claim/types'
import { Options, FormSubmission } from 'mlp-client/src/form/types'
import { Attachments } from 'mlp-client/src/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import { ConfirmFlowButton } from 'mlp-client/src/flows/components/buttons'
import ActiveVehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/ActiveVehicleModelLicense'

import { getSubCategoryTitle, getTitle } from './utils'

export interface StepConfig {
  categories: Options
  subCategories: { [key: string]: Options }
}

export interface Props extends FlowProps<FlowData>, FormSubmission<FlowData> {
  config: StepConfig
}

class ConfirmStep extends React.PureComponent<Props> {
  handleEditClick = (step: StepNames) => () => this.props.goToStep(step)

  render() {
    const {
      flowData,
      config: { categories, subCategories },
      onSubmit,
    } = this.props
    const {
      category,
      owner,
      iban,
      bic,
      accountNumber,
      amount,
      subCategory,
      currency,
      date,
      receipt,
      reason,
    } = flowData

    const price = `${currencySymbolMap(currency)} ${amount}`
    const categoryTitle = getTitle(category, categories)
    const subCategoryTitle = getSubCategoryTitle(
      subCategory,
      category,
      subCategories,
    )

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({
          handleSubmit,
          submitting,
          submitFailed,
          submitErrors,
        }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel="myLeaseplan.newSetup.flows.cashClaim.steps.checkDetails.pageTitle">
                <GeneralListTile
                  dataE2eId={'cashClaim'}
                  title={
                    <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.checkDetails.category.title" />
                  }
                  onClick={this.handleEditClick(StepNames.SELECT_CLAIM_TYPE)}
                >
                  {categoryTitle && <Translation id={categoryTitle} />}
                  {subCategoryTitle && <Translation id={subCategoryTitle} />}
                  <ActiveVehicleModelLicense />
                </GeneralListTile>
                <FlowStepTile
                  dataE2eId={'purchaseDetails'}
                  title={
                    <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.checkDetails.purchaseDetails.title" />
                  }
                  btnLabel={EditButtonLabel}
                  onBtnClick={this.handleEditClick(StepNames.PURCHASE_DETAILS)}
                >
                  <Spacing mv={0.5}>
                    <ImagePreview attachments={receipt as Attachments} />
                  </Spacing>
                  <FlowStepChevronList>
                    <FormatDate date={date} dateFormat="LL" />
                    {price}
                    {reason}
                  </FlowStepChevronList>
                </FlowStepTile>
                <GeneralListTile
                  dataE2eId={'bankAccountDetails'}
                  title={
                    <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.checkDetails.account.title" />
                  }
                  onClick={this.handleEditClick(StepNames.ACCOUNT_DETAILS)}
                >
                  {owner}
                  {iban && (
                    <>
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.iban.label" />
                      :{' '}
                      {submitErrors?.IbanNumber ? (
                        <Text color="error" fontWeight="bold">
                          {iban}
                        </Text>
                      ) : (
                        iban
                      )}
                      {submitErrors?.IbanNumber && (
                        <Text
                          color="error"
                          component="p"
                          data-e2e-id="ibanValidationError"
                        >
                          <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.checkDetails.account.validationError.iban" />
                        </Text>
                      )}
                    </>
                  )}
                  {accountNumber && (
                    <>
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.accountNumber.label" />
                      : {accountNumber}
                    </>
                  )}
                  {bic && (
                    <>
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.bic.label" />
                      :{' '}
                      {submitErrors?.Bic ? (
                        <Text color="error" fontWeight="bold">
                          {bic}
                        </Text>
                      ) : (
                        bic
                      )}
                      {submitErrors?.Bic && (
                        <Text
                          color="error"
                          component="p"
                          data-e2e-id="bicValidationError"
                        >
                          <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.checkDetails.account.validationError.bic" />
                        </Text>
                      )}
                    </>
                  )}
                </GeneralListTile>
                <Spacing mb={2} mt={2}>
                  <SubmitStatus submitting={submitting} failed={submitFailed}>
                    <ConfirmFlowButton
                      onClick={handleSubmit}
                      disabled={submitting}
                    />
                  </SubmitStatus>
                </Spacing>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof DispatchProps>

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep } = props

    dispatch(
      new SubmitCashClaimRequest({
        flowData,
        onComplete: error => {
          if (!error) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete(error)
          }
        },
      }),
    )
  },
})

export { ConfirmStep }
export default connect(null, mapDispatchToProps)(ConfirmStep)
