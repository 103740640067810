import { Card, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { FetchFAQ } from 'mlp-client/src/FAQ/actions'
import {
  ChevronSolidCircleBottomIcon,
  ChevronSolidCircleRightIcon,
  ChevronSolidCircleTopIcon,
} from 'mlp-client/src/FAQ/components/FAQ.styled'
import FAQItem from 'mlp-client/src/FAQ/components/FAQItem'
import { getFAQs } from 'mlp-client/src/FAQ/selectors'
import { FAQItemType } from 'mlp-client/src/FAQ/types'
import { AppState } from 'mlp-client/src/types'

export interface Props {
  fetchFAQ?(faqKey: string, locale: string): void
  faqKey?: string
  faqItems?: readonly FAQItemType[]
  locale?: string
  dataE2eId?: string
  heading?: boolean
}

type DefaultProps = Required<Pick<Props, 'heading'>>

class FAQ extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    heading: true,
  }

  componentDidMount() {
    this.handleFAQload()
  }

  componentDidUpdate() {
    this.handleFAQload()
  }

  handleFAQload(): void {
    const { faqItems, faqKey, locale, fetchFAQ } = this.props

    if (faqKey && fetchFAQ && locale && !faqItems) {
      fetchFAQ(faqKey, locale)
    }
  }

  renderMultipleFAQ(items: readonly FAQItemType[]): React.ReactNode {
    const { heading } = this.props

    return (
      <>
        {heading && (
          <Spacing pb={0.5}>
            <Headline variant="200" withMarginBottom>
              <Translation id="myLeaseplan.genericNotifications.frequentlyAskedQuestions" />
            </Headline>
          </Spacing>
        )}

        {items.map(item => (
          <FAQItem
            item={item}
            key={item.id}
            openedIcon={ChevronSolidCircleTopIcon}
            closedIcon={ChevronSolidCircleBottomIcon}
          />
        ))}
      </>
    )
  }

  renderOneFAQ(item: FAQItemType): React.ReactNode {
    return (
      <FAQItem
        item={item}
        open={true}
        openedIcon={ChevronSolidCircleRightIcon}
      />
    )
  }

  render() {
    const { faqItems, dataE2eId, faqKey } = this.props

    if (!faqItems || !faqItems.length) {
      return null
    }

    return (
      <Card
        backgroundColor={null}
        border={true}
        borderColor="steel20"
        data-e2e-id={`${faqKey || dataE2eId}FAQ`}
      >
        <Spacing pt={2} pb={1} ph={2}>
          {faqItems.length === 1 && this.renderOneFAQ(faqItems[0])}
          {faqItems.length > 1 && this.renderMultipleFAQ(faqItems)}
        </Spacing>
      </Card>
    )
  }
}

type ReduxProps = Pick<Props, 'faqItems' | 'locale'>
type DispatchProps = Pick<Props, 'fetchFAQ'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, props: OwnProps): ReduxProps => ({
  faqItems: getFAQs(state, props.faqKey),
  locale: getUserLocale(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchFAQ: (faqKey: string, locale: string) => {
    dispatch(new FetchFAQ({ faqKey, locale }))
  },
})

export { FAQ }
export default connect(mapStateToProps, mapDispatchToProps)(FAQ)
