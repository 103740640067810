import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import Page from 'mlp-client/src/flows/fine-history/components/pages/page/Page'
import { FeatureConfiguration } from 'mlp-client/src/flows/fine-history/types'

import { isPreviewFeatureEnabled } from './utils'

export const finesFeature: MyLeaseplanFeature<FeatureConfiguration> = {
  enabled: isPreviewFeatureEnabled('fines'),
  view: Page,
  configuration: {
    batchSize: 6,
    headingFAQ: false,
  },
}
