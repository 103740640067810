import React from 'react'
import { ButtonLink } from '@leaseplan/ui'

import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'
import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { WhiteText } from 'mlp-client/src/components/styled/TextStyle'
import {
  isMobileApp as isMobileAppUtil,
  postMessage,
  PostMessageType,
} from 'mlp-client/src/auth/mobile-utils'

export interface Props {
  currentStepTitle?: string | React.ReactNode
  currentStepId?: string
  contractId: string
}

export const DashboardBreadcrumbs: React.FC<Props> = ({
  currentStepTitle,
  currentStepId,
  contractId,
}) => (
  <Breadcrumbs color="white" separator={<ChevronRightIcon color={'white'} />}>
    <ButtonLink
      to="myLeaseplan.dashboard"
      params={{ contractId }}
      component={RouteLink}
      size="s"
      fontWeight="regular"
      onClick={() =>
        isMobileAppUtil() &&
        postMessage({ type: PostMessageType.DashboardNavigation })
      }
    >
      <Translation id="myLeaseplan.subNavigation.dashboard" />
    </ButtonLink>
    <WhiteText variant="200" component="span">
      {currentStepTitle}
      {currentStepId && <Translation id={currentStepId} />}
    </WhiteText>
  </Breadcrumbs>
)
