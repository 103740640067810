import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { config } from 'mlp-client/src/app-config'
import {
  getLocale,
  routesSelector,
} from 'mlp-client/src/localization/selectors'
import { Routes } from 'mlp-client/src/localization/types'

export interface RoutesContextProps {
  routes: Routes
  locale: string
}

export const RoutesContext = React.createContext<RoutesContextProps>({
  routes: undefined,
  locale: config.DEFAULT_LOCALE,
})

export interface Props extends RoutesContextProps {
  children?: React.ReactNode
}

class RoutesProvider extends React.Component<Props> {
  render() {
    const { locale, routes, children } = this.props

    return (
      <RoutesContext.Provider value={{ locale, routes }}>
        {children}
      </RoutesContext.Provider>
    )
  }
}

export const mapStateToProps = createStructuredSelector({
  locale: getLocale,
  routes: routesSelector,
})

export { RoutesProvider }
export default connect(mapStateToProps)(RoutesProvider)
