import { camelCase } from 'camel-case'
import moment from 'moment'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import { getFormattedTime } from 'mlp-client/src/flows/components/steps/select-supplier-step/utils'
import { ReplaceLabels } from 'mlp-client/src/features-configuration/types'
import {
  AdditionalServices,
  MaintenanceType,
} from 'mlp-client/src/flows/maintenance/enums'
import {
  AdditionalServices as AdditionalServicesType,
  FlowData,
  Malfunctions,
  SubmittedData,
} from 'mlp-client/src/flows/maintenance/types'
import { Option, Options } from 'mlp-client/src/form/types'
import { INVARIANT_DATE_FORMAT } from 'mlp-client/src/constants'
import { Address } from 'mlp-client/src/user/types'
import { AppState } from 'mlp-client/src/types'
import { mapLocation } from 'mlp-client/src/utils/address/address'

export const convertMalfunctionsToOptions = (
  malfunctions: Malfunctions,
): Options =>
  malfunctions.map(
    ({ id, value }): Option => ({
      value: id,
      title: value,
      dataE2eId: id,
    }),
  )

export const getIntersectionItems = (
  firstArray: readonly any[],
  secondArray: readonly any[],
) => firstArray.filter(value => secondArray.includes(value))

export const arrayToFlagObject = (array: readonly string[]) =>
  array?.reduce(
    (object, item) => ({
      ...object,
      [item]: true,
    }),
    {},
  )

export const convertAdditionalServicesToOptions = (
  additionalServices: AdditionalServicesType,
  selectedAdditionalServices: readonly string[],
  extendedCollectAndReturn: boolean,
  replaceLabels: ReplaceLabels = {},
): Options => {
  const selectedCollectAndReturn = selectedAdditionalServices.includes(
    AdditionalServices.COLLECT_AND_RETURN,
  )
  const selectedInsideWash = selectedAdditionalServices.includes(
    AdditionalServices.INSIDE_WASH,
  )

  return additionalServices.reduce((acc, serviceItems) => {
    // if an option is selected all the other options in the same array should be disabled.
    const [selectedOption] = getIntersectionItems(
      selectedAdditionalServices,
      serviceItems,
    )

    const servicesAsOptions = serviceItems.map(
      (service): Option => {
        const disableCollectAndReturn =
          selectedInsideWash &&
          service === AdditionalServices.COLLECT_AND_RETURN
        const disableInsideWash =
          selectedCollectAndReturn && service === AdditionalServices.INSIDE_WASH

        const disabled =
          (selectedOption && selectedOption !== service) ||
          disableCollectAndReturn ||
          disableInsideWash

        const serviceTitle =
          service in replaceLabels ? replaceLabels[service] : service

        if (
          extendedCollectAndReturn &&
          service === AdditionalServices.COLLECT_AND_RETURN
        ) {
          return {
            disabled,
            value: service,
            title: `myLeaseplan.maintenance.steps.selectAdditionalServices.services.${serviceTitle}.title`,
            subtitle:
              'myLeaseplan.maintenance.steps.selectMaintenanceType.additionalService.deliverySubtitle',
            description: `myLeaseplan.maintenance.steps.selectMaintenanceType.additionalService.deliveryNote`,
            dataE2eId: service,
          }
        }

        return {
          disabled,
          value: service,
          title: `myLeaseplan.maintenance.steps.selectAdditionalServices.services.${serviceTitle}.title`,
          subtitle: `myLeaseplan.maintenance.steps.selectAdditionalServices.services.${serviceTitle}.subtitle`,
          dataE2eId: service,
        }
      },
    )

    return [...acc, ...servicesAsOptions]
  }, [] as Options)
}

export const getMalfunctionsWithTranslatedValues = (
  state: AppState,
  malfunctions: readonly string[],
): Malfunctions =>
  malfunctions.map(malfunction => {
    const translatedValue = getTranslation(
      state,
      `myLeaseplan.serviceRequest.maintenance.malfunctions.${malfunction}`,
    )

    return {
      id: malfunction,
      value: translatedValue,
    }
  })

export const getMaintenanceTypeOptions = (): Options =>
  Object.values(MaintenanceType).map(
    (maintenanceType: string): Option => ({
      value: maintenanceType,
      title: `myLeaseplan.newSetup.flows.selectMaintenanceType.${camelCase(
        maintenanceType,
      )}`,
    }),
  )

export const getCityTitle = (
  city: string,
  cities: Options,
): string | undefined => {
  const cityOption = cities.find(option => option.value === city)

  return cityOption && cityOption.title
}

export const mapTimes = (times: readonly number[]): Options =>
  times.map(time => ({
    title: getFormattedTime(time),
    value: String(time),
  }))

export const setTimeToDate = (
  date: string,
  time: string,
  format?: string,
): string =>
  moment(new Date(date)).startOf('day').add(time, 'hours').format(format)

export const mapSubmitBody = (
  flowData: FlowData,
  countryCode: string,
): SubmittedData => {
  const {
    specialInstructions,
    mileage,
    email,
    phone,
    additionalServices = [],
    address,
    date,
    time,
    supplier: { id: supplierId },
    malfunctionsType = [],
    maintenanceType,
    contractId,
    locale,
  } = flowData

  const bookingDate = date
  const bookingTime = Number(time)
  const malfunctions = malfunctionsType
  const appointmentDateTime = setTimeToDate(
    bookingDate,
    String(bookingTime),
    INVARIANT_DATE_FORMAT,
  )

  return {
    contractId,
    email,
    phoneNumber: phone.number,
    phoneCountryCode: formatCountryCode(phone.code),
    locale,
    supplierId,
    additionalServices,
    malfunctions,
    mileage: Number(mileage),
    specialInstructions: specialInstructions || '',
    address: mapLocation({
      ...address,
      country: address.country || countryCode,
    }),
    dropOffDate: appointmentDateTime,
    collectionTime: flowData.hasCollectAddress
      ? getFormattedTime(bookingTime)
      : undefined,
    regular: maintenanceType.includes(MaintenanceType.Regular),
    malfunctionReported: Boolean(malfunctions.length),
  }
}

export const getAdditionalServicesOptions = (
  additionalServices: AdditionalServicesType,
  selectedAdditionalServices: Record<string, boolean> = {},
  collectAndReturnWithoutSuppliersStep: boolean = false,
  replaceLabels: ReplaceLabels = undefined,
) =>
  convertAdditionalServicesToOptions(
    additionalServices,
    (selectedAdditionalServices && Object.keys(selectedAdditionalServices)) ||
      [],
    collectAndReturnWithoutSuppliersStep,
    replaceLabels,
  )

export const getLabelFromOption = (
  value: string,
  options: Options,
): string | undefined => options.find(option => option.value === value)?.title

export const formatAddress = (address: Address) =>
  `${address.addressLine1}, ${address.zipCode}, ${address.city}`

export const formatCountryCode = (code: string): string =>
  code ? code.replace(/^(0*)/, '00') : code
