import classnames from 'classnames'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'
import PointViewList from 'mlp-client/src/form/components/car-point-select/PointViewList'
import { Points } from 'mlp-client/src/form/components/car-point-select/types'
import CheckboxList from 'mlp-client/src/form/components/checkbox-list/CheckboxList'
import RadioList from 'mlp-client/src/form/components/radio-list/RadioList'

interface State {
  carImageLoaded: boolean
}

export interface Props {
  handleChange?(event: GenericObject, index?: number): void
  handleFocus?(focus: boolean): void
  multiSelect?: boolean
  hideList?: boolean
  listOverview?: boolean
  points: Points
  listElementLabel?: React.ReactNode
  listOverviewLabel?: React.ReactNode
  name?: string
  label?: string
  disabled?: boolean
  hideLabel?: boolean
  value?: string
}

class CarPointSelect extends React.PureComponent<Props, State> {
  static defaultProps = {
    hideList: false,
    multiSelect: false,
  }

  state: State = { carImageLoaded: false }

  carImageLoaded = () => {
    this.setState({ carImageLoaded: true })
  }

  pointSvg(checked: boolean) {
    return (
      <svg
        className={classnames('car-points-select__point', {
          'car-points-select__point--checked': checked,
        })}
        viewBox="0 0 60 60"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="359.5"
          y="7.5"
          width="57"
          height="57"
          rx="28.5"
          transform="translate(-358 -6)"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    )
  }

  renderCarListComponent = ({
    getOptionProps,
    getIsSelected,
  }: GenericObject) => {
    const { name, label, disabled, handleFocus, hideLabel, points } = this.props

    return (
      <fieldset name={name}>
        <div>
          {label && (
            <Translation
              id={label}
              element="label"
              className={`field__label${hideLabel ? ' u-hidden-visually' : ''}`}
            />
          )}
          <div
            className={classnames(
              'car-points-select',
              `car-points-select--impact`,
            )}
          >
            <img
              className="car-points-select__car-image"
              src="/static/images/my-leaseplan/impact-points-bg.png"
              onLoad={this.carImageLoaded}
            />
            {this.state.carImageLoaded &&
              points
                .filter(point => !point.showInList)
                .map(
                  (
                    {
                      value: pointValue,
                      top,
                      right,
                      bottom,
                      left,
                      centeredX,
                      centeredY,
                    },
                    index,
                  ) => (
                    <label
                      className={classnames('car-points-select__label', {
                        'car-points-select__label--centeredX': centeredX,
                        'car-points-select__label--centeredY': centeredY,
                      })}
                      key={pointValue}
                      style={{ top, left, bottom, right }}
                    >
                      {this.pointSvg(getIsSelected(pointValue))}
                      <input
                        className="car-points-select__input"
                        disabled={disabled}
                        {...getOptionProps({
                          disabled,
                          name,
                          index,
                          value: pointValue,
                          focusHandler: handleFocus,
                        })}
                      />
                    </label>
                  ),
                )}
          </div>
        </div>
      </fieldset>
    )
  }

  render() {
    const {
      value,
      multiSelect,
      hideList,
      listOverview,
      handleChange,
      points,
      listElementLabel,
      listOverviewLabel,
    } = this.props

    const CarElement = multiSelect ? CheckboxList : RadioList

    const carElement = (
      <CarElement
        onChange={handleChange}
        value={value || ''}
        render={(args: GenericObject) => this.renderCarListComponent(args)}
      />
    )

    const listElement = hideList ? null : (
      <>
        {listElementLabel}
        <PointViewList
          points={points}
          handleChange={handleChange}
          value={value}
          multiSelect={multiSelect}
        />
      </>
    )

    const arrayValues = value ? value.split(',') : []
    const pointValues = points
      .filter(point => point.showInList && arrayValues.includes(point.value))
      .map(point => (
        <Translation
          id={`myLeaseplan.components.carPointSelect.impact.${point.label}`}
          key={point.label}
          data-e2e-id={point.label}
        />
      ))

    const listOverviewElement =
      listOverview && pointValues.length > 0 ? (
        <>
          {listOverviewLabel}
          <FlowStepChevronList>{pointValues}</FlowStepChevronList>
        </>
      ) : null

    return (
      <div>
        {carElement} {listElement} {listOverviewElement}
      </div>
    )
  }
}

export default CarPointSelect
