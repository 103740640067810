import {
  ButtonLink,
  Card,
  CardContent,
  DownloadIcon,
  IconTextProps,
  Spacing,
} from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import { DownloadableDocument } from 'mlp-client/src/downloadable-documents/types'
import { DownloadDownloadableDocument } from 'mlp-client/src/downloadable-documents/actions'
import Translation from 'mlp-client/src/localization/Translation'

interface Props {
  downloadableDocument?: DownloadableDocument
}

const commonLinkProps: IconTextProps = {
  fontWeight: 'light',
  size: 's',
  color: 'midOrange',
}

const spaceBetweenProps = {
  display: 'flex',
  justifyContent: 'space-between',
}

export const DownloadableDocumentCard: FC<Props> = props => {
  const dispatch = useDispatch()
  const { downloadableDocument } = props

  if (downloadableDocument) {
    return (
      <Spacing mb={2}>
        <Card>
          <CardContent>
            <Headline variant="100" style={spaceBetweenProps}>
              {downloadableDocument.documentName}
              <ButtonLink
                icon={DownloadIcon}
                onClick={() => {
                  dispatch(
                    new DownloadDownloadableDocument({
                      documentId: downloadableDocument.globalDocumentId,
                      documentName: downloadableDocument.documentName,
                    }),
                  )
                }}
                {...commonLinkProps}
              >
                <Translation id="myLeaseplan.vehicleDocuments.steps.documentDetail.buttons.download" />
              </ButtonLink>
            </Headline>
          </CardContent>
        </Card>
      </Spacing>
    )
  }

  return null
}
