import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Translation from 'mlp-client/src/localization/Translation'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import { DamageRecords } from 'mlp-client/src/contracts/types'
import DamageList from 'mlp-client/src/flows/damage-report/components/pages/damage-list-page/damage-list/DamageList'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { DashboardBreadcrumbs } from 'mlp-client/src/dashboard/components/DashboardBreadcrumbs'
import { AppState } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import {
  getCurrentContractId,
  getDamages,
} from 'mlp-client/src/contracts/selectors'

export interface Props {
  damages: DamageRecords
  contractId: string
}

class DamageListPage extends React.PureComponent<Props> {
  render() {
    const { damages, contractId } = this.props

    const title = (
      <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.pageTitle" />
    )

    const banner = (
      <FlowStepPoster
        title={title}
        imgMedium="/static/images/my-leaseplan/damage-report-poster-medium.jpg"
        imgLarge="/static/images/my-leaseplan/damage-report-poster-large.jpg"
        imgSmall="/static/images/my-leaseplan/mobile/damage-report-mobile.png"
        breadcrumbs={
          <DashboardBreadcrumbs
            currentStepTitle={title}
            contractId={contractId}
          />
        }
      />
    )

    return (
      <MyLeaseplanContext.Consumer>
        {context =>
          context.damageReport?.enabled ? (
            <MyleaseplanPage
              metaTitle="myLeaseplan.damageReport.steps.vehicleDamages.pageTitle"
              banner={banner}
            >
              <GridLayout
                main={<DamageList damages={damages} contractId={contractId} />}
                side={<FAQ faqKey={FAQKeys.DAMAGE_REPORT_PAGE} />}
              />
            </MyleaseplanPage>
          ) : (
            <NotFound />
          )
        }
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<Props, 'damages' | 'contractId'>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  damages: getDamages,
  contractId: getCurrentContractId,
})

export { DamageListPage }
export default connect(mapStateToProps)(DamageListPage)
