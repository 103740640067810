import { NavigationItem } from 'mlp-client/src/types'
import { Navigation } from 'mlp-client/src/features-configuration/types'

export const ONLY_DASHBOARD_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myLeaseplan.subNavigation.dashboard',
    to: 'myLeaseplan.dashboard',
    useContractId: true,
  },
]

export const NO_CONTRACT_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myLeaseplan.subNavigation.dashboard',
    to: 'myLeaseplan.dashboard',
  },
  {
    title: 'myLeaseplan.timeline.base.title',
    to: 'myLeaseplan.timeline.base',
  },
]

export const NAVIGATION_STRUCTURE: NavigationItem[] = [
  ...ONLY_DASHBOARD_NAVIGATION_STRUCTURE,
  {
    title: 'myLeaseplan.timeline.base.title',
    to: 'myLeaseplan.timeline.base',
  },
  {
    title: 'myLeaseplan.subNavigation.carContract',
    to: 'myLeaseplan.contract.detail',
    useContractId: true,
  },
]

export const NAVIGATION_STRUCTURE_QUOTE: NavigationItem[] = [
  ...ONLY_DASHBOARD_NAVIGATION_STRUCTURE,
  {
    title: 'myLeaseplan.timeline.base.title',
    to: 'myLeaseplan.timeline.base',
  },
  {
    title: 'myLeaseplan.subNavigation.carContract',
    to: 'myLeaseplan.vehicleOrder',
    useQuoteId: true,
  },
]

export const MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myLeaseplan.subNavigation.vehicles',
    to: 'myLeaseplan.homepage',
  },
  {
    title: 'myLeaseplan.subNavigation.notifications',
    to: 'myLeaseplan.timeline.base',
  },
]

export const MULTIPLE_ORDERS_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myLeaseplan.subNavigation.vehicles',
    to: 'myLeaseplan.homepage',
  },
]

export const READONLY_NAVIGATION_STRUCTURE: NavigationItem[] = [
  {
    title: 'myLeaseplan.orderStatus.title',
    to: 'myLeaseplan.vehicleOrder',
  },
]

export const DROPDOWN_STRUCTURE: NavigationItem[] = [
  {
    title: 'myLeaseplan.subNavigation.profile',
    to: 'myLeaseplan.profile.detail',
  },
  {
    title: 'myLeaseplan.serviceCard.title',
    to: 'myLeaseplan.serviceCard',
  },
]

export const FUEL_MANAGEMENT_NAVIGATION_STRUCTURE: NavigationItem[] = [
  ...ONLY_DASHBOARD_NAVIGATION_STRUCTURE,
  {
    title: 'myLeaseplan.timeline.base.title',
    to: 'myLeaseplan.timeline.base',
  },
]

export const navigation: Navigation = {
  ONLY_DASHBOARD_NAVIGATION_STRUCTURE,
  NO_CONTRACT_NAVIGATION_STRUCTURE,
  NAVIGATION_STRUCTURE,
  MULTIPLE_VEHICLES_NAVIGATION_STRUCTURE,
  MULTIPLE_ORDERS_NAVIGATION_STRUCTURE,
  READONLY_NAVIGATION_STRUCTURE,
  FUEL_MANAGEMENT_NAVIGATION_STRUCTURE,
  NAVIGATION_STRUCTURE_QUOTE,
}
