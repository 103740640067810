import { ButtonLink, Color, Grid, GridItem, Link, Spacing } from '@leaseplan/ui'
import { CloseIcon } from '@velocity/ui/draft'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { getInstallAppLink } from 'mlp-client/src/selectors'
import { until } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { WhiteText } from 'mlp-client/src/components/styled/TextStyle'

// styled component is used because it is not possible for configure velocity flexible
export const Container = styled.div`
  padding: 10px;
  background: ${Color.petrolBlue};
`

export const WhiteUppercaseText = styled(WhiteText)`
  text-transform: uppercase;
`

export const InstallButtonSpacing = styled(Spacing)`
  text-align: right;
  a:hover {
    text-decoration: none !important;
  }
`

const NoPaddingGridItem = styled(GridItem)`
  padding-left: 6px !important;
  padding-right: 6px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IconGridItem = styled(NoPaddingGridItem)`
  @media (max-width: 320px) {
    display: none;
  }
`

const iconSrc = '/static/images/my-leaseplan/app_icon_preview.png'

interface Props {
  installAppLink?: string
  isMobileApp: boolean
}

type ReduxProps = Pick<Props, 'installAppLink' | 'isMobileApp'>

interface State {
  isInstallPopupVisible: boolean
}

class InstallMobileApp extends React.PureComponent<Props> {
  state: State = {
    isInstallPopupVisible:
      until('lap') &&
      window.sessionStorage.getItem('isInstallPopupVisible') !== 'false',
  }

  hidePopup = () => {
    window.sessionStorage.setItem('isInstallPopupVisible', 'false')

    this.setState({ isInstallPopupVisible: false })
  }

  render() {
    const { installAppLink, isMobileApp } = this.props

    if (isMobileApp || !installAppLink || !this.state.isInstallPopupVisible) {
      return null
    }

    return (
      <Container>
        <Grid alignItems="center" justifyContent="center">
          <NoPaddingGridItem span={1}>
            <Link onClick={this.hidePopup}>
              <CloseIcon size="xs" color="white" />
            </Link>
          </NoPaddingGridItem>
          <NoPaddingGridItem span="grow">
            <Grid>
              <IconGridItem span="auto">
                <img src={iconSrc} height={50} width={50} />
              </IconGridItem>
              <NoPaddingGridItem span={{ mobile: 8, tablet: 8 }}>
                <WhiteText variant="200" bold component="p">
                  <Translation id="myLeaseplan.navigation.installApp.title" />
                </WhiteText>
                <WhiteText variant="100" component="p">
                  <Translation id="myLeaseplan.navigation.installApp.subtitle" />
                </WhiteText>
              </NoPaddingGridItem>
            </Grid>
          </NoPaddingGridItem>
          <NoPaddingGridItem span={3}>
            <InstallButtonSpacing pr={4}>
              <ButtonLink href={installAppLink} onClick={this.hidePopup}>
                <WhiteUppercaseText variant="100" component="p">
                  <Translation id="myLeaseplan.navigation.installApp.installButton" />
                </WhiteUppercaseText>
              </ButtonLink>
            </InstallButtonSpacing>
          </NoPaddingGridItem>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  installAppLink: getInstallAppLink,
  isMobileApp: getIsMobileApp,
})

export { InstallMobileApp }
export default connect(mapStateToProps)(InstallMobileApp)
