import React from 'react'

import { TileProps } from 'mlp-client/src/dashboard/type'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'

import { BasicTile } from './BasicTile'

export const DamageReportTile: React.FC<TileProps> = props => (
  <MyLeaseplanContext.Consumer>
    {context => <BasicTile {...props} to={context.damageReport.tileRoute} />}
  </MyLeaseplanContext.Consumer>
)
