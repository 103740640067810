import React from 'react'
import { Field, FieldInputProps } from 'react-final-form'

import CarPointSelectFiled from 'mlp-client/src/form/components/car-point-select/CarPointSelect'
import { Point } from 'mlp-client/src/form/components/car-point-select/types'
import { StringValidations } from 'mlp-client/src/form/types'
import { validateString } from 'mlp-client/src/form/utils'

export interface Props {
  name: string
  points: readonly Point[]
  hideList: boolean
  multiSelect?: boolean
  listElementLabel?: React.ReactNode
  validations: StringValidations
}

const onChangeHandler = (input: FieldInputProps<any, HTMLElement>) => (
  e: React.ChangeEvent<HTMLInputElement>,
) => {
  input.onChange(e.target.value)
}

const CarPointSelect = ({
  name,
  points,
  hideList,
  multiSelect,
  listElementLabel,
  validations,
}: Props) => (
  <Field name={name} validate={validateString(validations)}>
    {({ input }) => (
      <CarPointSelectFiled
        name={name}
        points={points}
        hideList={hideList}
        handleChange={onChangeHandler(input)}
        multiSelect={multiSelect}
        listElementLabel={listElementLabel}
        value={input.value}
      />
    )}
  </Field>
)

export { CarPointSelect }
