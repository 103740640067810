import React from 'react'

import { Coordinates } from 'mlp-client/src/components/map/types'

export interface Props {
  address: string
  children(coordinates: Coordinates | undefined): React.ReactNode
}

export interface State {
  coordinates: Coordinates
}

export class Geocoder extends React.PureComponent<Props, State> {
  state: State = {
    coordinates: undefined,
  }

  componentDidMount() {
    const { address } = this.props

    if (typeof window.google !== 'undefined') {
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode({ address }, result => {
        const location = result?.[0]?.geometry?.location

        this.setState({
          coordinates: {
            lat: location?.lat(),
            lng: location?.lng(),
          },
        })
      })
    }
  }

  render() {
    return this.props.children(this.state.coordinates)
  }
}

export default Geocoder
