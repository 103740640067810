import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/flows/glass-repair/actions'
import sagas from 'mlp-client/src/flows/components/pages/supplier-map-page/sagas'
import { getBookingRequest } from 'mlp-client/src/flows/glass-repair/selectors'
import { DamageReport } from 'mlp-client/src/flows/glass-repair/types'
import { User } from 'mlp-client/src/user/types'
import { getUser } from 'mlp-client/src/user/selectors'
import { ApiSagas } from 'mlp-client/src/api'
import { BookingResult } from 'mlp-client/src/types'

export function* loadDamageReports(): SagaIterator {
  const user: User = yield select(getUser)

  yield call(
    ApiSagas.get,
    `/myleaseplan/lpis/damage-reports?userId=${encodeURIComponent(user.id)}`,
    {
      successAction: (data: readonly DamageReport[]) =>
        new actions.GetDamageReportsSuccess({ data }),
      errorAction: () => new actions.GetDamageReportsFailed(),
    },
  )
}

export function* submitGlassRepair({
  payload: { flowData, onComplete },
}: actions.SubmitGlassRepairRequest): SagaIterator {
  const state = yield select()
  const body = yield call(getBookingRequest, state, flowData)

  try {
    const result: BookingResult = yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/bookings/',
      {
        body,
      },
      { throwError: true },
    )

    yield put(new actions.SubmitGlassRepairSuccess({ result }))
    yield call(onComplete, false)
  } catch (err) {
    yield call(onComplete, true)
  }
}

export default [
  takeLatest(actions.ActionTypes.GET_DAMAGE_REPORTS_REQUEST, loadDamageReports),
  takeLatest(
    actions.ActionTypes.SUBMIT_GLASS_REPAIR_REQUEST,
    submitGlassRepair,
  ),
  ...sagas,
]
