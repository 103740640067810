import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import { NavigationItem } from 'mlp-client/src/types'
import { DynamicLink } from 'mlp-client/src/components/dynamic-link/DynamicLink'
import Translation from 'mlp-client/src/localization/Translation'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { MainMenuTopNavigation } from 'mlp-client/src/analytics/actions'

import TakeoverWidgets from './TakeoverWidgets'

export interface Props {
  phoneNumber?: string
  contractId: string
  hideLoginLink?: boolean
  trackMainMenuTopNavigation?(eventLabel: string): void
}

class TakeoverPrimary extends React.PureComponent<Props> {
  renderLink = (item: NavigationItem, isActive: boolean) => (
    <LocalizationContext.Consumer>
      {({ translate }) => (
        <DynamicLink
          data-tag-id={`mobile-menu-primary-item-link-${item.to || item.url}`}
          to={item.to || item.url || ''}
          params={
            item.useContractId ? { contractId: this.props.contractId } : {}
          }
          target={item.target || '_self'}
          className={Classnames(
            'js-focus-handle',
            'mobile-menu-link-list__button',
            { 'mobile-menu-link-list__button--active': isActive },
          )}
          onClick={() =>
            this.props.trackMainMenuTopNavigation(translate(item.title))
          }
        >
          {item.navigationTitle ? (
            <span>{item.navigationTitle}</span>
          ) : (
            item.title && <Translation id={item.title} />
          )}
        </DynamicLink>
      )}
    </LocalizationContext.Consumer>
  )

  render() {
    return (
      <TopNavigationContext.Consumer>
        {({ navItems, navActiveItem }) => (
          <div className="takeover-primary mobile-takeover__pane takeover-primary--on-top">
            <div className="takeover-primary__inner mobile-takeover__pane-inner">
              <ul className="o-list-bare mobile-menu-link-list">
                <li className="mobile-menu-link-list__item">
                  {this.renderLink(
                    {
                      to: 'myLeaseplan.external.mainHomePage',
                      title: 'myLeaseplan.shared.home',
                    },
                    false,
                  )}
                </li>
                {navItems
                  .filter(item => item.to || item.url)
                  .map(item => (
                    <li
                      key={item.title}
                      className="mobile-menu-link-list__item"
                    >
                      {this.renderLink(item, navActiveItem === item)}
                    </li>
                  ))}
              </ul>
              <TakeoverWidgets hideLoginLink={this.props.hideLoginLink} />
            </div>
          </div>
        )}
      </TopNavigationContext.Consumer>
    )
  }
}

type DispatchProps = Pick<Props, 'trackMainMenuTopNavigation'>

export const mapDispatchToProps: DispatchProps = {
  trackMainMenuTopNavigation: (eventLabel: string) =>
    new MainMenuTopNavigation({ eventLabel }),
}

export { TakeoverPrimary }
export default connect(undefined, mapDispatchToProps)(TakeoverPrimary)
