import { LocationChangeAction } from 'connected-react-router'

import { Country } from 'mlp-client/src/config/types'
import countryConfigs from 'mlp-client/src/config'
import { Locales } from 'mlp-client/src/localization/enums'
import { Routes } from 'mlp-client/src/localization/types'
import { isKnownLocale } from 'mlp-client/src/localization/utils'

import * as actions from './actions'

export interface State {
  countries: readonly Country[]
  routes?: Routes
  locale: Locales
}

const defaultState = {
  countries: countryConfigs,
  locale: Locales.EN_LP,
}

const locationChange = (state: State, pathname: string) => {
  const locale = pathname.split('/')[1]?.toLowerCase()

  if (isKnownLocale(locale) && state.locale !== locale) {
    return {
      ...state,
      locale,
    }
  }

  return state
}

export default (
  state: State = defaultState,
  action: actions.Actions | LocationChangeAction,
): State => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return locationChange(state, action.payload.location.pathname)
    case actions.ActionType.SET_LOCATION:
      return locationChange(state, action.payload.pathname)
    case actions.ActionType.SET_ROUTES:
      return {
        ...state,
        routes: action.payload.routes,
      }
    default:
      return state
  }
}
