import React from 'react'
import { Action, Dispatch } from 'redux'
import { RouterState } from 'connected-react-router'

import { State as AuthState } from 'mlp-client/src/auth/reducers'
import { State as ContractsState } from 'mlp-client/src/contracts/reducers'
import { State as QuotesState } from 'mlp-client/src/quotes/reducers'
import { State as FAQState } from 'mlp-client/src/FAQ/reducers'
import { State as FuelCard } from 'mlp-client/src/flows/fuel-card/reducers'
import { State as FuelHistory } from 'mlp-client/src/flows/fuel-history/reducers'
import { State as GlassRepairState } from 'mlp-client/src/flows/glass-repair/reducers'
import { State as Documents } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/reducers'
import { State as MaintenanceState } from 'mlp-client/src/flows/maintenance/reducers'
import { State as FormState } from 'mlp-client/src/form/components/fields/file-upload-field/reducers'
import { State as TimelineDetailsState } from 'mlp-client/src/timeline-details/types'
import { Timeline as TimelineState } from 'mlp-client/src/timeline/types'
import { State as UserState } from 'mlp-client/src/user/reducers'
import { SupplierResponse } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'
import { CountryConfigList } from 'mlp-client/src/features-configuration/types'
import { LIABILITY } from 'mlp-client/src/flows/damage-report/enums'
import { State as DamageReportState } from 'mlp-client/src/flows/damage-report/reducers'
import { State as ContentState } from 'mlp-client/src/content/reducers'
import { State as DownloadableDocumentState } from 'mlp-client/src/downloadable-documents/reducers'
import { PageTitle } from 'mlp-client/src/analytics/types'
import {
  BookingResultAddress,
  BookingResultSupplier,
  Malfunctions,
} from 'mlp-client/src/flows/maintenance/types'
import { DriverDetailsState } from 'mlp-client/src/attach-car/reducer'

import { State as ProfileState } from './profile/reducers'
import { State as LocalizationState } from './localization/reducers'
import { MyLeaseplanFeatureName } from './features-configuration/types'

export interface DamageReportCategory {
  name?: string
  isFirstParty?: boolean
  isThirdParty?: boolean
}

export interface DamageReport {
  id: string
  description: string
  impactPoints: readonly string[]
  firstImpactPoint: string
  locationDescription: string
  contractId: string
  unilateralDamage: boolean
  incidentDateTime: string
  liability: LIABILITY
  caseNumber: string
  category: DamageReportCategory
  attachments: readonly File[]
}

export interface Geolocation {
  latitude: number
  longitude: number
}

export interface Address {
  street: string
  number: string
  suffix: string
  zipCode: string
  city: string
  stateProvince: string
  country: string
}

export interface WorkingHours {
  from?: string
  until?: string
}

export interface SupplierCardUrl {
  websiteLabel: string
  websiteUrl: string
}

export interface SupplierCard {
  logo: string
  name: string
  info?: string
  phone?: string
  urls?: readonly SupplierCardUrl[]
}

// values have not been defined in atlas yet
export const enum DocumentType {
  EuropeanAccidentStatement = 'EUROPEAN_ACCIDENT_STATEMENT',
  Photo = 'PHOTO',
  Damage = 'DAMAGE',
  Receipt = 'RECEIPT',
}

export interface TitleLabelReplace {
  [key: string]: string | number
  [key: number]: string | number
}

export interface Attachment {
  id: string
  key: string
  name: string
  preview: string
  progress: number
  status: 'uploading' | 'success' | 'error'
}

export type Attachments = readonly Attachment[]

export type EditClickHandler<T extends string = string> = (
  step: T,
) => () => void

export interface MatchParams {
  prefix?: string
}

export interface AnalyticsState {
  trackedPageViews: readonly GenericObject[]
}

export interface AppState {
  auth: AuthState
  contracts: ContractsState
  quotes: QuotesState
  vehicleDocuments: Documents
  content: ContentState
  maintenance: MaintenanceState
  glassRepair: {
    flow: GlassRepairState
  }
  timeline: TimelineState
  timelineDetails: TimelineDetailsState
  fuelCard: FuelCard
  fuelHistory: FuelHistory
  user: UserState
  FAQ: FAQState
  form: FormState
  staticPageSuppliers: SupplierResponse
  init: boolean
  countries: CountryConfigList
  damageReport: DamageReportState
  profile: ProfileState
  localization: LocalizationState
  dictionary: GenericObject
  analytics: AnalyticsState
  router: RouterState
  driverDetails: DriverDetailsState
  registrationCertificateDocs: ContractsState //refactored reducer for sonar
  downloadableDocuments: DownloadableDocumentState
}

interface FetchDataPayload<P, Q> {
  params: P
  query: Q
  dispatch: Dispatch<Action>
  getState(): AppState
}

type FetchDataResponse =
  | Action
  | Promise<Action>
  | Promise<Action[]>
  | Promise<GenericObject[]>

export type FetchData<P = {}, Q = {}> = (
  arg: FetchDataPayload<P, Q>,
) => FetchDataResponse

export interface PersonName {
  salutation: string
  firstName: string
  lastName: string
  insertion?: string
}

export interface Route {
  enabled?: string
  status?: number
  path: string
  enabledForFeature?: readonly ['myLeasePlan']
  exact?: boolean
  strict?: boolean
  component: React.ComponentType<any>
  props?: {
    feature: MyLeaseplanFeatureName
  }
  pageTitle: PageTitle
  pageTitleKey?: string
}

export interface RouteFactories {
  [key: string]: () => readonly Route[]
}

// counterpart of Config type from `src/app/config/config.types.tsx`
// TODO: extend ClientEnv interface (if suitable) from `apps/mlp-server/src/universal/clientEnv.ts`
// once FCQ-467 is done
export interface AppConfiguration {
  IDENTITY_API: string
  IDENTITY_CLIENT_ID: string
  IDENTITY_CLIENT_ID_MOBILE: string
  IDENTITY_AUDIENCE: string
  ENABLE_MY_LEASEPLAN: boolean
  ANDROID_INSTALL_APP_URL?: string
  IOS_INSTALL_APP_URL?: string
  DEFAULT_LOCALE: string
  API_BASE_URL: string
  GOOGLE_MAPS_API_KEY: string
  LEGACY_MLP_REDIRECT: string
  API_TOKEN: string
  PLATFORM_URL: string
}

export type NavigationParams = null | undefined | string | GenericObject

export interface NearbySuppliers {
  status: string
  suppliers: readonly Supplier[]
  reason?: string
  searchLocation: Geolocation
  noSuppliersByMalfunctions: boolean
  isSuppliersNumberFetched: boolean
}

export type Suppliers = readonly Supplier[]
export type OpeningHours = Partial<
  Record<
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
    | 'saturday'
    | 'sunday',
    readonly WorkingHours[]
  >
>

export interface Supplier {
  id: string
  distance: number
  name: string
  location: Geolocation
  address: SupplierAddress
  phone: string
  leadTimeInDays: number
  additionalServices: readonly string[]
  logoUrl: string
  timeSlots: readonly string[]
  availableHours?: readonly number[]
  openingHours?: OpeningHours
  website?: string
}

export interface SupplierAddress {
  street: string
  number?: string
  suffix?: string
  postcode: string
  city: string
  state?: string
  country: string
}

export interface MappedLocation {
  street: string
  city: string
  houseNumber?: string
  postcode: string
  state?: string
  country: string
  number?: string
  suffix?: string
}

export interface MappedAddress {
  street: string
  houseNumber: string
  numberSuffix: string
  zipcode: string
  city: string
  province: string
  country: string
}

interface BookingBase {
  contractId: string
  dropOffDate: string
  specialInstructions?: string
  otherMalfunctions?: string | Malfunctions
  malfunctions: readonly string[] | Malfunctions
  additionalServices?: readonly string[]
  addressType?: string
  address: MappedLocation | BookingResultAddress
  mileage?: number
  email: string
  phoneNumber: string
  phoneCountryCode: string
  supplierId: string
  regular?: boolean
  damageDate?: string
}

export const enum BookingRequestType {
  SERVICE = 'Service',
  GLASS = 'Glass',
  TYRES = 'Tyres',
  DAMAGE = 'Damage',
}

export const enum GlassDamageType {
  SMALL_CHIP = 'SmallChip',
  LARGE_CHIP = 'LargeChip',
  CRACK = 'Crack',
}

interface ServiceBookingRequest extends BookingBase {
  bookingType: BookingRequestType.SERVICE
}

interface GlassBookingRequestBase extends BookingBase {
  bookingType: BookingRequestType.GLASS
  glassDamageType: GlassDamageType
}

type GlassBookingRequest = GlassBookingRequestBase &
  Required<Pick<BookingBase, 'damageDate'>>
export type BookingRequest = ServiceBookingRequest | GlassBookingRequest

export const enum BookingStatus {
  UNCONFIRMED = 'Unconfirmed',
  CONFIRMED = 'Confirmed',
}

export interface BookingResult extends BookingBase {
  status: BookingStatus
  id: string
  caseNumber: string
  supplierAddress: string
  supplierGeolocation?: Geolocation
  lastUpdate?: string
  supplier?: BookingResultSupplier
}

export interface NavigationItem {
  title?: string
  navigationTitle?: string
  to?: string
  url?: string
  items?: readonly NavigationItem[]
  params?: NavigationParams
  target?: string
  useContractId?: boolean
  useQuoteId?: boolean
}

export type ItemPredicate = (item: NavigationItem) => boolean

export interface XCoordinates {
  left: number
  right: number
}
