import { Spacing } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import ReturnNotes from 'mlp-client/src/flows/return/components/return-notes/ReturnNotes'
import { StepViewProps } from 'mlp-client/src/flows/return/types'
import { StyledNextButton } from 'mlp-client/src/components/button/StyledButton'

export interface StepConfig {
  fairwear?: string
  checklist?: string
  checkReturnList?: string
  hideFAQ?: boolean
}
export interface Props extends StepViewProps<StepConfig> {}

class ReturnVehicleStep extends React.PureComponent<Props> {
  render() {
    const {
      goToNextStep,
      config: { fairwear, checklist, hideFAQ, checkReturnList },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel="myLeaseplan.newSetup.flows.return.steps.returnVehicle.label"
          side={
            hideFAQ ? undefined : <FAQ faqKey={FAQKeys.VEHICLE_RETURN_PAGE} />
          }
        >
          <Spacing mb={2}>
            <ReturnNotes {...{ fairwear, checklist, checkReturnList }} />
          </Spacing>
          <StyledNextButton
            stretch={true}
            onClick={goToNextStep}
            data-e2e-id="buttonNextStep"
          >
            <Translation id="myLeaseplan.newSetup.shared.nextButton" />
          </StyledNextButton>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export { ReturnVehicleStep }
export default ReturnVehicleStep
