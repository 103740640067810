import React from 'react'
import { connect } from 'react-redux'

import { hasContracts } from 'mlp-client/src/contracts/selectors'
import { noContractAllowedLocale } from 'mlp-client/src/localization/selectors'
import {
  hasOnlyOneOrder,
  hasOrders,
} from 'mlp-client/src/order-status/selectors'
import { AppState } from 'mlp-client/src/types'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'

export interface Props {
  hasOnlyOneOrder: boolean
  hasOrders: boolean
  hasContracts: boolean
  noContractAllowedLocale: boolean
  children?: React.ReactNode
}

export class OnlyOrder extends React.PureComponent<Props> {
  render() {
    const {
      hasOnlyOneOrder,
      hasOrders,
      hasContracts,
      children,
      noContractAllowedLocale,
    } = this.props

    if (!noContractAllowedLocale) {
      if (!hasOrders && !hasContracts) {
        return <TranslatedRedirect to="myLeaseplan.attachCarToDriver.base" />
      }

      if (hasOnlyOneOrder) {
        return <TranslatedRedirect to="myLeaseplan.vehicleOrder" />
      }
    }

    return children
  }
}

type ReduxProps = Pick<
  Props,
  'hasContracts' | 'hasOnlyOneOrder' | 'hasOrders' | 'noContractAllowedLocale'
>

const mapStateToProps = (state: AppState): ReduxProps => ({
  hasOnlyOneOrder: hasOnlyOneOrder(state),
  hasOrders: hasOrders(state),
  hasContracts: hasContracts(state),
  noContractAllowedLocale: noContractAllowedLocale(state),
})

const OnlyOrderWrapper = connect(mapStateToProps)(OnlyOrder)

export default <T extends {}>(ChildComponent: React.ComponentType<T>) => {
  const onlyOrderExists: React.FC<T> = props => (
    <OnlyOrderWrapper data-test-id="OnlyOrder">
      <ChildComponent {...props} />
    </OnlyOrderWrapper>
  )

  onlyOrderExists.displayName = `onlyOrderExists(${
    ChildComponent.displayName || ChildComponent.name
  })`

  return onlyOrderExists
}
