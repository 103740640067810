import { Text } from '@velocity/ui'
import React from 'react'

import { TableLeaseVehicleData } from 'mlp-client/src/components/overview-table/types'

interface Props {
  item: TableLeaseVehicleData
}

export class LicenceCell extends React.PureComponent<Props> {
  render() {
    const { item } = this.props

    return (
      <Text variant="200" bold>
        {item?.licence || '-'}
      </Text>
    )
  }
}

export default LicenceCell
