import React from 'react'
import { GoogleMapProps } from '@react-google-maps/api'

import MapWithMarkers from 'mlp-client/src/components/map-with-markers/MapWithMarkers'
import {
  SeachLocationMarker,
  SupplierMarker,
} from 'mlp-client/src/components/map-with-markers/markers'
import {
  getGoogleMapCoordinates,
  getSupplierGoogleMapCoordinates,
} from 'mlp-client/src/flows/components/steps/select-supplier-step/utils'
import { Geolocation, Supplier } from 'mlp-client/src/types'

export interface Props {
  searchLocation: Geolocation
  suppliers: readonly Supplier[]
  highlightedSupplierId: string
  mapOptions?: GoogleMapProps
  showSupplierNameOnMarkers: boolean
  mapBoundsPadding?: number | google.maps.Padding
  onMarkerClick(
    supplierId: string,
    shouldSwipe: boolean,
  ): (event?: React.SyntheticEvent<HTMLElement>) => void
}

class SuppliersMap extends React.PureComponent<Props> {
  map: google.maps.Map

  handleMapInstance = (map?: google.maps.Map): void | null => {
    if (!map) {
      return
    }

    this.map = map
    this.extendMapBounds(map)
  }
  /**
   * @desc Extends the map bounds with an extra padding, so all our markers are visible
   */
  extendMapBounds = (map?: google.maps.Map): void | null => {
    const { searchLocation, suppliers, mapBoundsPadding } = this.props

    // If no suppliers found, don't extend map bounds.
    if (!suppliers.length) {
      return
    }

    const bounds = new google.maps.LatLngBounds()

    suppliers.forEach(supplier => {
      bounds.extend(getSupplierGoogleMapCoordinates(supplier))
    })

    if (searchLocation) {
      bounds.extend(getGoogleMapCoordinates(searchLocation))
    }

    if (mapBoundsPadding !== undefined) {
      map.fitBounds(bounds, mapBoundsPadding)
    } else {
      map.fitBounds(bounds, 20)
    }
  }

  componentDidUpdate() {
    if (this.map) {
      this.extendMapBounds(this.map)
    }
  }

  getMarkers = () => {
    const {
      highlightedSupplierId,
      onMarkerClick,
      searchLocation,
      suppliers,
      showSupplierNameOnMarkers,
    } = this.props

    const suppliersMarkers = suppliers.map(supplier => (
      <SupplierMarker
        key={supplier.id}
        supplierName={supplier.name}
        position={getSupplierGoogleMapCoordinates(supplier)}
        onClick={onMarkerClick(supplier.id, true)}
        isOpen={supplier.id === highlightedSupplierId}
        showSupplierName={showSupplierNameOnMarkers}
      />
    ))

    // For LPIS we have the search Location in the response body
    if (searchLocation) {
      const searchLocationMarker = (
        <SeachLocationMarker
          key={searchLocation.latitude}
          position={getGoogleMapCoordinates(searchLocation)}
        />
      )

      return [...suppliersMarkers, searchLocationMarker]
    }

    return suppliersMarkers
  }

  getMapCenter = (): google.maps.LatLngLiteral | undefined => {
    const { searchLocation } = this.props

    return searchLocation ? getGoogleMapCoordinates(searchLocation) : undefined
  }

  render() {
    return (
      <MapWithMarkers
        markers={this.getMarkers()}
        handleMapInstance={this.handleMapInstance}
        mapOptions={{ ...this.props.mapOptions, center: this.getMapCenter() }}
      />
    )
  }
}

export default SuppliersMap
