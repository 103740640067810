import { Grid, GridItem, Link, Text } from '@leaseplan/ui'
import React from 'react'

import { CheckboxField } from 'mlp-client/src/form/components/fields/CheckboxFields'
import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { required } from 'mlp-client/src/form/validations'

const TermsAndConditionsField: React.FC = () => (
  <Grid>
    <GridItem span={1}>
      <CheckboxField name="termsAndConditions" validations={[required]} />
    </GridItem>
    <GridItem span={11}>
      <div>
        <Text size="s">
          <Translation id="myLeaseplan.shared.footer.legal.accept1" />{' '}
          <Link
            component={RouteLink}
            target="_blank"
            to="myLeaseplan.external.legal.privacyStatement"
          >
            <Translation id="myLeaseplan.shared.footer.legal.privacyStatement" />
          </Link>{' '}
          <Translation id="myLeaseplan.shared.footer.legal.accept2" />{' '}
          <Link
            component={RouteLink}
            target="_blank"
            to="myLeaseplan.external.legal.termsAndConditions"
          >
            <Translation id="myLeaseplan.shared.footer.legal.termsOfUse" />
          </Link>
        </Text>
      </div>
    </GridItem>
  </Grid>
)

export default TermsAndConditionsField
