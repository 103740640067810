import React from 'react'
import Classnames from 'classnames'

import Flag from 'mlp-client/src/localization/Flag'
import { useTopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'

export interface Props {
  className?: string
}

const CurrentLocale: React.FC<Props> = ({ className }) => {
  const {
    parsedLocale: { country, language },
  } = useTopNavigationContext()

  return (
    <div className={Classnames('current-locale', className)}>
      <Flag code={country} label={country} />
      {language}
    </div>
  )
}

export default CurrentLocale
