import { ButtonChevronRightIcon, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, match, RouteComponentProps } from 'react-router'

// import the following layout components from individual modules as
// importing them from single `mlp-client/src/components/layout` module
// creates a circular dependency
import { MainContent } from 'mlp-client/src/components/layout/main-content/MainContent'
import { MainContentWrapper } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper'
import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import DoubleUserRoleTopNavigation from 'mlp-client/src/components/layout/top-navigation/DoubleUserRoleTopNavigation'
import InstallMobileApp from 'mlp-client/src/components/modals/instal-mobile-app/InstallMobileApp'
import MyLpFooterSmall from 'mlp-client/src/components/MyLpFooterSmall'
import { AppState } from 'mlp-client/src/types'
import { SetCurrentContract } from 'mlp-client/src/contracts/actions'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import { getCurrentQuoteId } from 'mlp-client/src/quotes/selectors'
import { SetCurrentQuote } from 'mlp-client/src/quotes/actions'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

import { MainPaddings } from './MyleaseplanPage.styled'

export interface MatchParams {
  contractId: string
  quoteId: string
}

export interface Props extends RouteComponentProps {
  metaTitle?: string
  metaDescription?: string
  meta?: Array<{ name: string; content: string }>
  isMobileApp: boolean
  banner?: React.ReactNode
  showMobileBackButton?: boolean
  title?: string
  header?: string
  children?: React.ReactNode
  setActiveContract: (contractId: string) => void
  setActiveQuote: (quoteId: string) => void
  contractId: string
  quoteId: string
  match: match<MatchParams>
}

export class MyleaseplanPage extends React.Component<Props> {
  componentDidMount() {
    const {
      contractId,
      quoteId,
      setActiveContract,
      setActiveQuote,
      match: { params },
    } = this.props

    if (params.contractId && contractId !== params.contractId) {
      setActiveContract(params.contractId)
    }

    if (params.quoteId && quoteId !== params.quoteId) {
      setActiveQuote(params.quoteId)
    }
  }

  renderTopNavigation = () => {
    if (this.props.isMobileApp) {
      return null
    }

    return (
      <DoubleUserRoleTopNavigation
        upperNavigationPopup={<InstallMobileApp />}
      />
    )
  }

  getMeta = () => {
    const { metaDescription, meta = [] } = this.props

    if (metaDescription) {
      return [...meta, { name: 'description', content: metaDescription }]
    }

    return meta
  }

  render() {
    const {
      title,
      isMobileApp,
      banner,
      children,
      header,
      showMobileBackButton,
    } = this.props

    return (
      <MainContent>
        <Helmet title={title} meta={this.getMeta()} />
        {this.renderTopNavigation()}
        {banner}
        <MainContentWrapper>
          <MainPaddings isMobileApp={isMobileApp}>
            {header && (
              <Headline variant="400" withMarginBottom>
                <Translation id={header} />
              </Headline>
            )}
            {isMobileApp && showMobileBackButton && (
              <Spacing mb={1}>
                <StyledButtonLink
                  to="myLeaseplan.dashboard"
                  icon={ButtonChevronRightIcon}
                  component={RouteLink}
                  params={{
                    contractId: this.props.match.params.contractId,
                    quoteId: this.props.match.params.quoteId,
                  }}
                  iconAnimation="rotate180"
                  iconAnimationState="active"
                  id="backToDashboardButton"
                >
                  <Translation id="myLeaseplan.shared.back" />
                </StyledButtonLink>
              </Spacing>
            )}
            <div>{children}</div>
          </MainPaddings>
        </MainContentWrapper>
        <MyLpFooterSmall />
      </MainContent>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'isMobileApp' | 'title' | 'contractId' | 'quoteId'
>
type DispatchProps = Pick<Props, 'setActiveContract' | 'setActiveQuote'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (state: AppState, ownProps: OwnProps) =>
  createStructuredSelector<AppState, ReduxProps>({
    isMobileApp: getIsMobileApp,
    title: () =>
      ownProps.metaTitle
        ? getTranslation(state, ownProps.metaTitle)
        : ownProps.metaTitle,
    contractId: getCurrentContractId,
    quoteId: getCurrentQuoteId,
  })

const mapDispatchToProps: DispatchProps = {
  setActiveContract: (contractId: string) =>
    new SetCurrentContract({ contractId }),
  setActiveQuote: (quoteId: string) => new SetCurrentQuote({ quoteId }),
}

// moved to separate variable because of known TS issue with withRouter()
const withReduxMyleaseplanPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyleaseplanPage)

export default withRouter(withReduxMyleaseplanPage)
