import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, compose } from 'redux'

import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
import Translation from 'mlp-client/src/localization/Translation'
import { RoutesContextProps } from 'mlp-client/src/localization/RoutesProvider'
import { getRouteTranslation } from 'mlp-client/src/localization/utils'
import { withRoutes } from 'mlp-client/src/localization/withRoutes'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import {
  FlowEnd,
  RegisterFlow,
} from 'mlp-client/src/components/myleaseplan-flow/flow/actions'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import {
  canReturn,
  getCurrentContractId,
} from 'mlp-client/src/contracts/selectors'
import { contractFormattedEndDateSelector } from 'mlp-client/src/end-of-lease/selectors'
import { FeatureConfiguration } from 'mlp-client/src/end-of-lease/types'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureProps,
} from 'mlp-client/src/features-configuration/types'
import { DashboardBreadcrumbs } from 'mlp-client/src/dashboard/components/DashboardBreadcrumbs'
import { AppState } from 'mlp-client/src/types'
import { isLocalizedRouteIdentifier } from 'mlp-client/src/end-of-lease/utils'

import Option from './Option'

export interface Props
  extends MyLeaseplanFeatureProps<FeatureConfiguration>,
    RoutesContextProps {
  contractEndDate: string
  contractId: string
  canReturn(config: MyleaseplanConfig): boolean
  isMobileApp: boolean
  flowDidStart(): void
  flowWillEnd(): void
  context: React.ContextType<typeof MyLeaseplanContext>
}

export class EndOfLeasePage extends React.PureComponent<Props> {
  componentDidMount() {
    const { isMobileApp, flowDidStart } = this.props

    if (isMobileApp) {
      // It dispatchs REGISTER_FLOW action
      // that previous mobile app versions depends on.
      flowDidStart()
    }
  }

  componentWillUnmount() {
    if (this.props.isMobileApp) {
      // It dispatchs FLOW_END action
      // that previous mobile app versions depends on.
      this.props.flowWillEnd()
    }
  }

  onClick = (url: string) => (event: React.MouseEvent<HTMLElement>) => {
    if (!this.props.isMobileApp || isLocalizedRouteIdentifier(url)) {
      return
    }

    event.preventDefault()

    const { locale, routes, contractId } = this.props

    postMessage({
      url: getRouteTranslation(locale, url, { contractId }, routes),
      type: PostMessageType.OpenLink,
    })
  }

  render() {
    return (
      <MyLeaseplanContext.Consumer>
        {context => {
          const {
            contractEndDate,
            canReturn,
            featureConfig: { configuration },
            contractId,
          } = this.props

          if (!canReturn(context)) {
            return <NotFound data-e2e-id="notFound" />
          }

          const options = configuration ? configuration.options : []
          const pageSubTitle = configuration && configuration.description
          const optionList = options.map(opt => {
            const link = { ...opt.link, params: { contractId } }

            return (
              <Option
                key={opt.id}
                {...opt}
                link={link}
                clickHandler={this.onClick(opt.link.to)}
              />
            )
          })

          const breadcrumbs = (
            <DashboardBreadcrumbs
              currentStepId="myLeaseplan.return.steps.endLease.title"
              contractId={contractId}
            />
          )

          const banner = (
            <FlowStepPoster
              title={
                <Translation
                  id="myLeaseplan.return.steps.endLease.headerTitle"
                  hideOnEmpty={true}
                />
              }
              imgMedium="/static/images/my-leaseplan/drivers-return-header-small.jpg"
              imgLarge="/static/images/my-leaseplan/drivers-return-header-big.jpg"
              breadcrumbs={breadcrumbs}
            />
          )

          return (
            <MyleaseplanPage
              metaTitle="myLeaseplan.return.steps.endLease.title"
              banner={banner}
              showMobileBackButton={true}
            >
              <Spacing mb={3}>
                <Headline component="h1" variant="400" withMarginBottom>
                  <Translation
                    id="myLeaseplan.return.steps.endLease.pageTitle"
                    replace={{ endDate: contractEndDate }}
                  />
                </Headline>
                {pageSubTitle && (
                  <Text variant="400">
                    <Translation id={pageSubTitle} />
                  </Text>
                )}
              </Spacing>
              <Grid justifyContent="space-between" direction="row">
                <GridItem span={{ mobile: 12, lap: 7 }}>{optionList}</GridItem>
                <GridItem span={{ mobile: 12, lap: 4 }}>
                  <FAQ faqKey={FAQKeys.END_OF_LEASE_SELECT_OPTION} />
                </GridItem>
              </Grid>
            </MyleaseplanPage>
          )
        }}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'contractEndDate' | 'canReturn' | 'isMobileApp' | 'contractId'
>
type DispatchProps = Pick<Props, 'flowDidStart' | 'flowWillEnd'>

// TODO: MLP-3892 don't use createStructuredSelector until canReturn selector refactoring is done
const mapStateToProps = (state: AppState): ReduxProps => ({
  canReturn: canReturn(state),
  contractEndDate: contractFormattedEndDateSelector(state),
  isMobileApp: getIsMobileApp(state),
  contractId: getCurrentContractId(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  flowDidStart: () => dispatch(new RegisterFlow()),
  flowWillEnd: () => dispatch(new FlowEnd()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRoutes,
)(EndOfLeasePage)
