import React from 'react'

import Modal from 'mlp-client/src/flows/components/markdown/components/modal/Modal'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

const INFOBOX = 'InfoboxId:'

export const ModalLinkRenderer: React.FC<{
  href: string
  children: string
}> = ({ href, children }) => {
  if (href.includes(INFOBOX)) {
    return (
      <Modal
        infoboxContentsType={decodeURI(href).replace(
          new RegExp(`(${INFOBOX})|\\W`, 'g'),
          '',
        )}
        link={children}
      />
    )
  }

  return (
    <StyledButtonLink href={href} target="_blank" fontWeight="light" size="s">
      {children}
    </StyledButtonLink>
  )
}
