import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { SupplierMapPage } from 'mlp-client/src/flows/components/pages/supplier-map-page/SupplierMapPage'
import { SupplierMapConfiguration } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'
import { BookingRequestType } from 'mlp-client/src/types'

export const tyresConfig: MyLeaseplanFeature<SupplierMapConfiguration> = {
  enabled: true,
  view: SupplierMapPage,
  configuration: {
    bookingType: BookingRequestType.TYRES,
    title: 'myLeaseplan.newSetup.flows.tyres.steps.page.title',
    subTitle: 'myLeaseplan.newSetup.flows.tyres.steps.page.subTitle',
    breadcrumbBaseTitle: 'myLeaseplan.subNavigation.dashboard',
    breadcrumbBaseLink: 'myLeaseplan.dashboard',
    breadcrumbLinkTitle:
      'myLeaseplan.newSetup.flows.tyres.steps.tyreService.posterTitle',
    showOpeningHours: false,
  },
}
