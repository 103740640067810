import { Action, Dispatch, Middleware } from 'redux'
import { push } from 'connected-react-router'

import { ActionTypes as ContractActionTypes } from 'mlp-client/src/contracts/actions'

export const enum PostMessageType {
  LoadStart = 'LOAD_START',
  LoadEnd = 'LOAD_END',
  Unauthorized = 'UNAUTHORIZED',
  RenewAccessToken = 'RENEW_ACCESS_TOKEN',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  NotFound = 'NOT_FOUND',
  OpenLink = 'OPEN_LINK',
  LocationChange = 'LOCATION_CHANGE',
  OpenPhoneNumber = 'OPEN_PHONE_NUMBER',
  DashboardNavigation = 'DASHBOARD_NAVIGATION',
}

const enum LocalStorageMobileAuthKeys {
  ExpiresAt = 'mobile_expires_at',
  AccessToken = 'mobile_access_token',
  IsMobileApp = 'mobile_is_mobile_app',
}

const ensureMyLeaseplanExists = (): typeof window.myLeasePlan => {
  let { myLeasePlan } = window

  if (!myLeasePlan) {
    myLeasePlan = {}
    window.myLeasePlan = myLeasePlan
  }

  return myLeasePlan
}

const actionMapping = {
  [ContractActionTypes.LOAD_CONTRACTS_SUCCESS]: PostMessageType.LoadEnd,
  ['@@router/LOCATION_CHANGE']: PostMessageType.LocationChange,
}

/**
 * For security reason, it's safer to not store `accessToken` and `expiresAt`
 * at LocalStorage for a mobile app and it's better to load those data from memory.
 *
 * This can add vulnerability for Android Rooted devices.
 */
export const storeTokenInSession = (accessToken: string, expiresAt: string) => {
  window.localStorage.setItem(
    LocalStorageMobileAuthKeys.AccessToken,
    accessToken,
  )
  window.localStorage.setItem(LocalStorageMobileAuthKeys.ExpiresAt, expiresAt)
}

export const getAccessTokenForMobile = () =>
  window.localStorage.getItem(LocalStorageMobileAuthKeys.AccessToken)
export const getExpiresAtForMobile = () =>
  window.localStorage.getItem(LocalStorageMobileAuthKeys.ExpiresAt)

export const postMessage = (message: GenericObject) =>
  window.postMessage(JSON.stringify(message), window.location.origin)

export const isMobileApp = (): boolean => {
  let isMobileClient = new URLSearchParams(window.location.search).has(
    'isMobileApp',
  )

  if (isMobileClient) {
    window.localStorage.setItem(
      LocalStorageMobileAuthKeys.IsMobileApp,
      JSON.stringify(isMobileClient),
    )
  } else {
    isMobileClient = JSON.parse(
      window.localStorage.getItem(LocalStorageMobileAuthKeys.IsMobileApp) ||
        'false',
    )
  }

  return isMobileClient
}

export const isLoadedFromMobileApp = (): boolean => isMobileApp()

export const setBootstrapHandler = (
  callback: (token: string, expiresAt: string) => void,
): void => {
  const myLeasePlan = ensureMyLeaseplanExists()

  myLeasePlan.bootstrapApp = (token, expiresAt) => {
    storeTokenInSession(token, expiresAt)
    callback(token, expiresAt)
  }
}

export const initMobileIntegration = (dispatch: Dispatch<Action>): void => {
  const myLeasePlan = ensureMyLeaseplanExists()

  myLeasePlan.addToken = storeTokenInSession
  myLeasePlan.routePush = (path: string) => dispatch(push(path))
}

export const mobileIntegrationMiddleware: Middleware = () => (
  next: Dispatch<Action>,
) => (action: Action) => {
  if (actionMapping[action.type]) {
    postMessage({
      ...action,
      type: actionMapping[action.type],
    })
  }

  return next(action)
}
