import {
  Action,
  ActionTypes,
  SelectDocument,
} from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/actions'

export interface State {
  isLoading: boolean
  isLoaded: boolean
  selectedDocumentId: string
}

export const defaultState: State = {
  isLoaded: false,
  isLoading: false,
  selectedDocumentId: null,
}

export const documentsLoading = (state: State): State => ({
  ...state,
  isLoading: true,
  isLoaded: false,
})

export const documentsLoaded = (state: State): State => ({
  ...state,
  isLoading: false,
  isLoaded: true,
})

export const selectDocument = (state: State, action: SelectDocument) => ({
  ...state,
  selectedDocumentId: action.payload.documentId,
})

const vehicleDocumentsReducer = (
  state: State = defaultState,
  action: Action,
): State => {
  switch (action.type) {
    case ActionTypes.LOAD_PAGE_CONTENT:
      return documentsLoading(state)
    case ActionTypes.LOAD_PAGE_CONTENT_FAILED:
    case ActionTypes.LOAD_PAGE_CONTENT_SUCCESS:
      return documentsLoaded(state)
    case ActionTypes.SELECT_VEHICLE_DOCUMENT:
      return selectDocument(state, action)
    default:
      return state
  }
}

export default vehicleDocumentsReducer
