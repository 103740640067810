import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form } from 'react-final-form'
import _isEqual from 'lodash/isEqual'
import moment from 'moment'

import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import {
  AppState,
  BookingRequestType,
  Geolocation,
  SupplierAddress,
  Suppliers,
} from 'mlp-client/src/types'
import { Address } from 'mlp-client/src/user/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { StepViewProps } from 'mlp-client/src/flows/types'
import {
  getHolidaysDateList,
  supplierAvailabilityDateSelector,
} from 'mlp-client/src/selectors'
import { FetchSuppliersRequestData } from 'mlp-client/src/flows/components/steps/select-supplier-step/types'
import {
  getTimesFromAllSuppliers,
  mapSuppliers,
  showMessageBar,
} from 'mlp-client/src/flows/components/steps/select-supplier-step/utils'
import SelectSupplierStepForm from 'mlp-client/src/flows/components/steps/select-supplier-step/SelectSupplierStepForm'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import SuppliersNotFound from 'mlp-client/src/flows/components/suppliers-not-found/SuppliersNotFound'
import { isSupplierOpen } from 'mlp-client/src/components/suppliers/utils'
import {
  getNearbySuppliersSlots,
  getNearbySuppliersStatus,
  getNearbySuppliersReason,
  getSuppliersSeachLocation,
  getNoSuppliersByMalfunctions,
} from 'mlp-client/src/contracts/selectors'
import {
  LoadNearbySuppliers,
  ResetNearbySuppliers,
} from 'mlp-client/src/contracts/actions'
import { Imalfunction } from 'mlp-client/src/flows/maintenance/types'

export interface Labels {
  title: string
  noSuppliersNearby: string
  invalidAddressTitle: string
  invalidAddress: string
  changeAddress: string
  availableServiceCenters: string
  hourWarning: string
}

export interface StepConfig {
  bookingType?: BookingRequestType
  labels?: Labels
  isMobile?: boolean
  enableWeekends?: boolean
}

export interface SelectSupplierFlowData {
  supplier?: {
    id: string
    location?: Geolocation
    address?: SupplierAddress
    name: string
  }
  date?: string
  time?: string
  malfunctionsType?: Imalfunction[]
  additionalServices?: readonly string[]
  mileage?: string
  address?: Address
}

export interface Props
  extends StepViewProps<StepConfig, SelectSupplierFlowData> {
  nearbySuppliers: Suppliers
  nearbySuppliersStatus: string
  nearbySuppliersReason: string
  minSupplierDistance: number
  servicePhoneNumber?: string
  startDate?: Date
  isLoading: boolean
  noSuppliersByMalfunctions: boolean
  searchLocation: Geolocation
  fetchSuppliers(
    requestData: FetchSuppliersRequestData,
    onComplete: () => void,
    prevalidate: boolean,
  ): void
  resetSuppliers(): void
}

interface State {
  selectedDate: null | string
  selectedTime: null | string
  availableSuppliers: Suppliers
  times: readonly number[]
  isLoading: boolean
}

export interface FormValues {
  date: string
  time: string
  supplier: string
  supplierInfo: {
    location: Geolocation
    address: SupplierAddress
    name: string
  }
}

const defaultLabels = {
  title: 'myLeaseplan.maintenance.steps.selectSupplier.pageTitle',
  noSuppliersNearby: 'myLeaseplan.maintenance.steps.selectSupplier.noSuppliers',
  invalidAddressTitle:
    'myLeaseplan.maintenance.steps.selectSupplier.invalidAddressTitle',
  invalidAddress: 'myLeaseplan.maintenance.steps.selectSupplier.invalidAddress',
  changeAddress: 'myLeaseplan.maintenance.steps.selectSupplier.changeAddress',
  availableServiceCenters:
    'myLeaseplan.maintenance.steps.selectSupplier.availableServiceCenters',
  hourWarning: 'myLeaseplan.maintenance.steps.selectSupplier.hourWarning',
}

class SelectSupplierStep extends React.PureComponent<Props, State> {
  state: State = {
    selectedDate: null,
    selectedTime: null,
    availableSuppliers: [],
    times: [],
    isLoading: true,
  }

  setAvailableSuppliers = () => {
    const { startDate, nearbySuppliers } = this.props
    const { selectedDate, selectedTime } = this.state
    const selectedWeekday = selectedDate
      ? moment(selectedDate).day()
      : moment(startDate).day()
    const suppliers = mapSuppliers(nearbySuppliers, selectedWeekday)
    const times = getTimesFromAllSuppliers(suppliers)

    const [firstHour] = times
    const activeDate = selectedDate || startDate
    const availableSuppliers = nearbySuppliers.filter(supplier =>
      isSupplierOpen(
        supplier,
        moment(activeDate).day(),
        Number(selectedTime) || firstHour,
      ),
    )

    this.setState({ availableSuppliers, times })
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (
      !_isEqual(prevProps.nearbySuppliers, this.props.nearbySuppliers) ||
      prevState.selectedTime !== this.state.selectedTime
    ) {
      this.setAvailableSuppliers()
    }
  }

  componentWillUnmount(): void {
    this.props.resetSuppliers()
  }

  handleSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props
    const { availableSuppliers } = this.state
    const { date, time, supplier } = values

    const { location, name, address } = availableSuppliers.find(
      item => item.id === supplier,
    )

    setFlowData(
      {
        date: date.toString(),
        time,
        supplier: {
          id: supplier,
          location,
          address,
          name,
        },
      },
      goToNextStep,
    )
  }

  loadSuppliers = (prevalidate: boolean) => {
    const {
      fetchSuppliers,
      flowData: {
        mileage,
        address,
        malfunctionsType: selectedMalfunctions,
        additionalServices,
      },
      startDate,
      config: { bookingType },
    } = this.props
    const { selectedDate } = this.state

    this.setState({ isLoading: true })
    fetchSuppliers(
      {
        location: address,
        additionalServices,
        bookingType,
        date: moment(selectedDate || startDate),
        selectedMalfunctions,
        mileage,
      },
      () => this.setState({ isLoading: false }),
      prevalidate,
    )
  }

  onDateChange = (value: string) => {
    this.setState({ selectedDate: value })
  }

  onTimeChange = (value: string) => {
    this.setState({ selectedTime: value })
  }

  handleNoSuppliersButtonClick = () => {
    this.props.resetSuppliers()

    if (this.props.noSuppliersByMalfunctions) {
      this.props.closeFlow()
    } else {
      this.props.goToPreviousStep()
    }
  }

  showSuppliersNotFound = (showSupplierNotFound: boolean) => {
    const { nearbySuppliersReason, noSuppliersByMalfunctions } = this.props

    return (
      nearbySuppliersReason === 'invalidAddress' ||
      (showSupplierNotFound && noSuppliersByMalfunctions)
    )
  }

  render() {
    const {
      startDate,
      config: { labels = defaultLabels },
      flowData,
      resetSuppliers,
      noSuppliersByMalfunctions,
    } = this.props
    const { selectedDate, availableSuppliers, times, isLoading } = this.state
    const [time] = times

    return (
      <StepLayout isLoading={isLoading}>
        <TwoColumnFlowStep
          titleLabel={labels.title}
          mainSpan="grow"
          mainMaxWidth={null}
        >
          <MyLeaseplanContext.Consumer>
            {context =>
              this.showSuppliersNotFound(context.showSupplierNotFound) ? (
                <SuppliersNotFound
                  noSuppliersByMalfunctions={noSuppliersByMalfunctions}
                  onClick={this.handleNoSuppliersButtonClick}
                />
              ) : (
                <Form<Record<string, any>>
                  onSubmit={this.handleSubmit}
                  initialValues={{
                    time: flowData.time || time?.toString(),
                    date: selectedDate || startDate,
                  }}
                  keepDirtyOnReinitialize
                  render={({ handleSubmit, errors, form }) => (
                    <SelectSupplierStepForm
                      {...this.props}
                      handleSubmit={handleSubmit}
                      validationErrors={errors}
                      times={times}
                      selectedDate={selectedDate}
                      labels={labels}
                      form={form}
                      availableSuppliers={availableSuppliers}
                      noSuppliersByMalfunctions={noSuppliersByMalfunctions}
                      showWarning={showMessageBar(
                        availableSuppliers,
                        context.minSupplierDistance,
                      )}
                      servicePhoneNumber={context.servicePhoneNumber}
                      onDateChange={this.onDateChange}
                      onTimeChange={this.onTimeChange}
                      loadSuppliers={() =>
                        this.loadSuppliers(context.showSupplierNotFound)
                      }
                      resetSuppliers={resetSuppliers}
                      minSupplierDistance={context.minSupplierDistance}
                    />
                  )}
                />
              )
            }
          </MyLeaseplanContext.Consumer>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'startDate'
  | 'nearbySuppliers'
  | 'nearbySuppliersStatus'
  | 'nearbySuppliersReason'
  | 'searchLocation'
  | 'noSuppliersByMalfunctions'
>

type OwnProps = Omit<Props, keyof ReduxProps>

const mapStateToProps = (state: AppState, ownProps: OwnProps): ReduxProps => ({
  startDate:
    (ownProps.flowData.date && new Date(ownProps.flowData.date)) ||
    supplierAvailabilityDateSelector(state, getHolidaysDateList(state)),
  nearbySuppliers: getNearbySuppliersSlots(state),
  nearbySuppliersStatus: getNearbySuppliersStatus(state),
  nearbySuppliersReason: getNearbySuppliersReason(state),
  searchLocation: getSuppliersSeachLocation(state),
  noSuppliersByMalfunctions: getNoSuppliersByMalfunctions(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSuppliers: (
    requestData: FetchSuppliersRequestData,
    onComplete: () => void,
    prevalidate: boolean,
  ) => {
    dispatch(new LoadNearbySuppliers({ requestData, onComplete, prevalidate }))
  },
  resetSuppliers: () => dispatch(new ResetNearbySuppliers()),
})

export { SelectSupplierStep }
export default connect(mapStateToProps, mapDispatchToProps)(SelectSupplierStep)
