import * as actions from './actions'
import { DownloadableDocuments } from './types'

export interface State {
  downloadableDocuments: DownloadableDocuments
}

export const defaultState: State = {
  downloadableDocuments: [],
}

export const loadDownloadableDocuments = (state: State) => ({
  ...state,
})

export const loadDownloadableDocumentsSuccess = (
  state: State,
  action: actions.LoadDownloadableDocumentsSuccess,
) => ({
  ...state,
  downloadableDocuments: action.payload.downloadableDocuments,
})

export const loadDownloadableDocumentsFailed = (state: State) => ({
  ...state,
})

export const downloadDownloadableDocument = (state: State) => ({
  ...state,
})

export const downloadDownloadableDocumentSuccess = (state: State) => ({
  ...state,
})
export const downloadDownloadableDocumentFailed = (state: State) => ({
  ...state,
})

export default (state = defaultState, action: actions.Action): State => {
  switch (action.type) {
    case actions.ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS:
      return loadDownloadableDocuments(state)
    case actions.ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS_SUCCESS:
      return loadDownloadableDocumentsSuccess(state, action)
    case actions.ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS_FAILED:
      return loadDownloadableDocumentsFailed(state)
    case actions.ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT:
      return downloadDownloadableDocument(state)
    case actions.ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT_SUCCESS:
      return downloadDownloadableDocumentSuccess(state)
    case actions.ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT_FAILED:
      return downloadDownloadableDocumentSuccess(state)
    default:
      return state
  }
}
