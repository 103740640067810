import { Button, Grid, GridItem, mq } from '@leaseplan/ui'
import styled from 'styled-components'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export const MapAndListWrapper = styled(Grid)`
  ${mq.from('lap')`
    background-color: white;
  `}
`

export const DesktopMapWrapper = styled(GridItem)`
  ${mq.until('lap')`
    display: none;
  `}
`

export const MobileMapWrapper = styled.div<{ isMobileMapOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: white;
  display: ${props => (props.isMobileMapOpen ? 'block' : 'none')};
  ${mq.from('lap')`
    display: none;
  `};
`

export const ViewOnMap = styled.div`
  display: flex;
  margin-bottom: 12px;
  color: ${ColorEnumSelector('midOrange')};
  ${mq.from('lap')`
    display: none;
  `}
`

export const BackToList = styled(Button)`
  position: absolute;
  top: 17px;
  left: 12px;
  z-index: 5;
`

export const CardsWrapper = styled.div`
  position: absolute;
  bottom: 24px;
  z-index: 5;
  width: 100%;
  height: 125px;
`
