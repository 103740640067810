import React from 'react'

import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'

import { EditButtonLabel } from './EditButtonLabel'

interface Props {
  dataE2eId?: string
  title: React.ReactNode
  onClick?(): void
}

export const GeneralListTile: React.FC<Props> = ({
  children,
  dataE2eId,
  title,
  onClick,
}) => (
  <FlowStepTile
    title={title}
    btnLabel={EditButtonLabel}
    onBtnClick={onClick}
    dataE2eId={dataE2eId}
  >
    <FlowStepChevronList>
      {Array.isArray(children) ? children : [children]}
    </FlowStepChevronList>
  </FlowStepTile>
)

GeneralListTile.displayName = 'GeneralListTile'
