import { Color, Link, Text } from '@leaseplan/ui'
import styled from 'styled-components'

export const StyledPhoneNumberBlock = styled(Link)`
  align-items: center;
  justify-content: flex-start;
  display: block;
  width: 100%;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`
export const StyledPhoneNumberWrapper = styled('div')`
  line-height: 60px;
  height: 60px;
  text-align: center;
  white-space: nowrap;
`
export const StyledPhoneNumber = styled('span')`
  display: block;
  height: 100%;
  padding: 0 25px 0 10px;
  border: 1px solid ${Color.steel60};
  background: #fff;
  text-align: center;
  white-space: nowrap;
`
export const StyledPhoneNumberText = styled(Text)`
  line-height: 30px;
`
export const StyledFeeWrapper = styled('div')`
  height: 60px;
  background: #a50f78;
  padding: 5px 10px 5px 25px;
`
export const StyledFee = styled('div')`
  background: url(/static/images/components/frenchphonenumber-paid.svg)
    no-repeat center center;
  width: 100%;
  height: 83%;
`
