import {
  CheckboxCard,
  CheckedEvent,
  IconText,
  InformationIcon,
  Spacing,
} from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import { Field, FieldInputProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'
import { ObjectValidations, Options } from 'mlp-client/src/form/types'
import { composeValidators } from 'mlp-client/src/form/utils'
import {
  maxLength as maxLengthValidator,
  required,
} from 'mlp-client/src/form/validations'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

const onCheckboxHandler = (
  input: FieldInputProps<any, HTMLElement>,
  value: string,
) => ({ checked }: CheckedEvent<any>): void => {
  const newValue = { ...input.value, [value]: checked }

  if (!checked) {
    delete newValue[value]
  }

  input.onChange(newValue)
}

export interface Props {
  name: string
  options: Options
  validations?: ObjectValidations
  batchSize?: number
  freeTextField?: boolean
}

const CheckboxList = ({
  name,
  validations,
  options,
  batchSize = 6,
  freeTextField,
}: Props) => (
  <Field name={name} validate={validations && composeValidators(validations)}>
    {({ input }) => (
      <fieldset name={name}>
        <RevealingItemList batchSize={batchSize} items={options}>
          {(options: Options) =>
            options.map(
              ({
                value,
                title,
                disabled,
                subtitle,
                description,
                dataE2eId = value,
              }) => (
                <Spacing mb={1} key={value}>
                  <CheckboxCard
                    {...input}
                    value={value}
                    checked={input.value?.[value]}
                    disabled={disabled}
                    onChange={onCheckboxHandler(input, value)}
                    data-e2e-id={dataE2eId}
                    id={`${input.name}-${value}`}
                  >
                    <SecondaryDarkText bold component="p">
                      <Translation id={title} />
                    </SecondaryDarkText>
                    {subtitle && (
                      <Text variant="200">
                        <Translation id={subtitle} hideOnEmpty={true} />
                      </Text>
                    )}
                    {description && input.value?.[value] && (
                      <Spacing mt={0.5}>
                        <hr />
                        <IconText
                          icon={InformationIcon}
                          iconSize="s"
                          data-e2e-id={`${value}_description`}
                        >
                          <Text variant="100">
                            <Translation id={description} />
                          </Text>
                        </IconText>
                      </Spacing>
                    )}
                  </CheckboxCard>
                  {freeTextField && input.value?.[value] && (
                    <Spacing mb={1} mt={1}>
                      <TextAreaField
                        name={`${value}_description`}
                        data-e2e-id={`${value}_id`}
                        validations={[required, maxLengthValidator(5000)]}
                      />
                    </Spacing>
                  )}
                </Spacing>
              ),
            )
          }
        </RevealingItemList>
      </fieldset>
    )}
  </Field>
)

export { CheckboxList }
export default CheckboxList
