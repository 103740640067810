/*
 * MLP-specific copy of `src/app/utils/Api.tsx`
 *
 * Differences with the original:
 * - Has been split into 3 modules: types, API and Saga API. This module represents API.
 */

import * as qs from 'qs'
import superagent from 'superagent'

import { config } from 'mlp-client/src/app-config'

import * as ApiTypes from './types'

export class Api {
  static request(
    url: string,
    {
      method = 'GET',
      params = {},
      body = {},
      headers = {},
    }: ApiTypes.Request = {},
  ) {
    const query = qs.stringify(params, {
      indices: false,
      strictNullHandling: true,
    })

    const baseUrl = url.includes('http') ? '' : config.API_BASE_URL

    const superagentRequest = superagent(method, `${baseUrl}${url}`)
      .redirects(0)
      .query(query)

    if (method !== 'GET') {
      superagentRequest.send(body).set('Content-Type', 'application/json')
    }

    superagentRequest.set({
      Accept: 'application/json',
      ...headers,
    })

    if (
      headers.Accept &&
      headers.Accept.toString()
        .toLowerCase()
        .includes('application/octet-stream')
    ) {
      superagentRequest.responseType('blob')
    }

    return superagentRequest
  }

  static get(
    url: string,
    {
      params = {},
      headers = {},
    }: { params?: GenericObject; headers?: GenericObject } = {},
  ) {
    return Api.request(url, { params, headers, method: 'GET' })
  }

  static delete(
    url: string,
    {
      body = {},
      params = {},
      headers = {},
    }: {
      body?: GenericObject
      params?: GenericObject
      headers?: GenericObject
    } = {},
  ) {
    return Api.request(url, { body, params, headers, method: 'DELETE' })
  }

  static post(
    url: string,
    {
      body = {},
      headers = {},
    }: { body?: GenericObject; headers?: GenericObject } = {},
  ) {
    return Api.request(url, { body, headers, method: 'POST' })
  }

  static put(
    url: string,
    {
      body = {},
      headers = {},
      params = {},
    }: {
      body?: GenericObject
      params?: GenericObject
      headers?: GenericObject
    } = {},
  ) {
    return Api.request(url, { body, params, headers, method: 'PUT' })
  }

  static patch(
    url: string,
    {
      body = {},
      headers = {},
    }: { body?: GenericObject; headers?: GenericObject } = {},
  ) {
    return Api.request(url, { body, headers, method: 'PATCH' })
  }
}
