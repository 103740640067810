import React from 'react'
import { Spacing } from '@leaseplan/ui'
import moment from 'moment'
import { FormApi, ValidationErrors } from 'final-form'

import TranslatedRichText from 'mlp-client/src/localization/TranslatedRichText'
import Translation from 'mlp-client/src/localization/Translation'
import DateTimeFieldGroup from 'mlp-client/src/flows/components/date-time-field-group/DateTimeFieldGroup'
import { mapTimes } from 'mlp-client/src/flows/components/steps/select-supplier-step/utils'
import MessageBar from 'mlp-client/src/flows/components/steps/select-supplier-step/MessageBar'
import { default as SelectSupplier } from 'mlp-client/src/flows/components/steps/select-supplier-step/SelectSupplier'
import { Suppliers } from 'mlp-client/src/types'

import { Props as SelectSupplierStepProps, Labels } from './SelectSupplierStep'

export interface Props extends SelectSupplierStepProps {
  selectedDate: null | string
  validationErrors: ValidationErrors
  times: readonly number[]
  availableSuppliers: Suppliers
  labels: Labels
  showWarning: boolean
  form: FormApi
  onDateChange(value: string): void
  onTimeChange?(value: string): void
  handleSubmit: (
    event?: React.SyntheticEvent<HTMLFormElement>,
  ) => Promise<object | undefined> | undefined
  loadSuppliers(): void
  resetSuppliers(): void
}

class SelectSupplierStepForm extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.loadSuppliers()
  }

  componentDidUpdate(prevProps: Props) {
    const { selectedDate, validationErrors } = this.props

    if (
      selectedDate &&
      prevProps.selectedDate !== selectedDate &&
      !validationErrors?.date
    ) {
      this.props.loadSuppliers()
    }

    if (prevProps.selectedDate !== selectedDate && validationErrors?.date) {
      this.props.resetSuppliers()
    }
  }

  render() {
    const {
      handleSubmit,
      startDate,
      nearbySuppliersStatus,
      minSupplierDistance,
      searchLocation,
      servicePhoneNumber,
      times,
      onDateChange,
      onTimeChange,
      config: { enableWeekends },
      selectedDate,
      labels,
      form,
      availableSuppliers,
      showWarning,
    } = this.props

    const activeDate = selectedDate || startDate
    const { availableServiceCenters, noSuppliersNearby } = labels
    const timesAsOptions = mapTimes(times)

    const selectSuppliersForm = activeDate ? (
      <>
        {(nearbySuppliersStatus === 'failed' ||
          (availableSuppliers.length === 0 &&
            nearbySuppliersStatus === 'success')) && (
          <Spacing mb={2}>
            <div data-e2e-id="noSupplier_infoMessage">
              <TranslatedRichText
                id={noSuppliersNearby}
                replace={{ date: moment(activeDate).format('dddd, LL') }}
              />
            </div>
          </Spacing>
        )}

        {nearbySuppliersStatus === 'success' &&
          availableSuppliers.length > 0 && (
            <SelectSupplier
              labels={{ availableServiceCenters }}
              suppliers={availableSuppliers}
              searchLocation={searchLocation}
              form={form}
            />
          )}
      </>
    ) : (
      <Spacing mb={2}>
        <Translation id="myLeaseplan.genericNotifications.selectDate" />
      </Spacing>
    )

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Spacing mb={3}>
            <DateTimeFieldGroup
              date={{
                enableWeekend: enableWeekends,
                notBefore: {
                  date: startDate,
                  message: 'myLeaseplan.validations.dateNotInPast',
                },
                onChange: onDateChange,
              }}
              time={
                timesAsOptions?.length && {
                  enabled: true,
                  options: timesAsOptions,
                  hideEmptyOption: true,
                  onChange: onTimeChange,
                }
              }
            />
          </Spacing>
          {selectSuppliersForm}
        </form>
        {showWarning && (
          <MessageBar
            phone={servicePhoneNumber}
            distance={minSupplierDistance}
          />
        )}
      </>
    )
  }
}

export { SelectSupplierStepForm }
export default SelectSupplierStepForm
