import { Select } from '@leaseplan/ui'
import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import {
  determineValidationState,
  getErrorMessage,
  validateString,
} from 'mlp-client/src/form//utils'
import {
  Option,
  Options,
  StringValidations,
  TranslationType,
} from 'mlp-client/src/form/types'

export const StyledSelect = styled(Select)`
  + div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 45px;
  }
`
export default ({
  name,
  validations,
  options,
  label,
  hideEmptyOption,
  placeholder,
  onChange,
  dataE2eId = name,
}: {
  name: string
  validations: StringValidations
  options: Options
  label?: TranslationType
  hideEmptyOption?: boolean
  placeholder?: TranslationType
  onChange?(value: string): void
  dataE2eId?: string
}) => (
  <LocalizationContext.Consumer>
    {({ translate }) => (
      <Field name={name} validate={validateString(validations)}>
        {({ input, meta }) => (
          <StyledSelect
            {...input}
            onChange={e => {
              input.onChange(e.value)

              if (onChange) {
                onChange(e.value)
              }
            }}
            label={label && translate(label.id, label.replaceValues)}
            validationState={determineValidationState(meta)}
            errorMessage={meta.error && getErrorMessage(meta, translate)}
            placeholder={
              placeholder &&
              translate(placeholder.id, placeholder.replaceValues)
            }
            data-e2e-id={dataE2eId}
          >
            {!hideEmptyOption && (
              <option disabled={true} value="">
                {translate('myLeaseplan.shared.input.select.chooseOption')}
              </option>
            )}
            {options.map((item: Option) => (
              <option key={item.value} value={item.value}>
                {translate(item.title)}
              </option>
            ))}
          </StyledSelect>
        )}
      </Field>
    )}
  </LocalizationContext.Consumer>
)
