import { Content } from 'mlp-client/src/content/types'
import * as actions from 'mlp-client/src/content/actions'
import { NavigationItem } from 'mlp-client/src/types'
import { Locales } from 'mlp-client/src/localization/enums'

export interface State {
  footer?: {
    footerLinks?: readonly NavigationItem[]
    locale?: Locales
  }
  showAllFeatures: boolean
  debugTranslations: boolean
  content?: Record<string, Content>
}

export const defaultState: State = {
  showAllFeatures: false,
  debugTranslations: false,
  footer: {},
}

const saveContent = (
  state: State,
  { payload }: actions.LoadContentSuccess,
) => ({
  ...state,
  content: {
    ...state.content,
    [payload.endpoint]: payload.result,
  },
})

const loadFooterMenuSuccess = (
  state: State,
  { payload: { locale, result } }: actions.LoadFooterMenuSuccess,
): State => ({
  ...state,
  footer: {
    ...state.footer,
    locale,
    footerLinks: result.filter(
      item => (item.to || item.url) && item.navigationTitle,
    ),
  },
})

const loadFooterMenuFailed = (state: State): State => ({
  ...state,
  footer: {
    footerLinks: undefined,
    locale: undefined,
  },
})

export default (
  state: State = defaultState,
  action: actions.Actions,
): State => {
  switch (action.type) {
    case actions.ActionTypes.SET_SHOW_ALL_FEATURES:
      return { ...state, showAllFeatures: true }
    case actions.ActionTypes.LOAD_CONTENT_SUCCESS:
      return saveContent(state, action)
    case actions.ActionTypes.SET_DEBUG_TRANSLATIONS:
      return { ...state, debugTranslations: true }
    case actions.ActionTypes.LOAD_FOOTER_MENU_SUCCESS:
      return loadFooterMenuSuccess(state, action)
    case actions.ActionTypes.LOAD_FOOTER_MENU_FAILED:
      return loadFooterMenuFailed(state)
    default:
      return state
  }
}
