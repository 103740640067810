import { BreakpointName, withBreakpoint } from '@leaseplan/ui'
import React from 'react'

import { Contract } from 'mlp-client/src/contracts/types'
import { isDesktopBreakpoint } from 'mlp-client/src/utils'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'

import {
  CellStyled,
  ColumnStyled,
  RowStyled,
} from './VehicleOverviewTile.styled'
import VehicleOverviewTileLink from './VehicleOverviewTileLink'

export interface Props {
  contract: Contract
  breakpoint: BreakpointName
  onHandleClick(contract: Contract): void
}

class VehicleOverviewTileLinks extends React.PureComponent<Props> {
  render() {
    const { contract, breakpoint, onHandleClick } = this.props
    const isDesktop = isDesktopBreakpoint(breakpoint)

    return (
      <MyLeaseplanContext.Consumer>
        {context => {
          const {
            tileLinksFirstColumn,
            tileLinksSecondColumn,
          } = context.supportsMultipleVehicles.configuration.tileLinks(contract)

          if (isDesktop) {
            return (
              <RowStyled>
                <ColumnStyled>
                  {tileLinksFirstColumn.map(tileLink => (
                    <CellStyled
                      onClick={() => onHandleClick(contract)}
                      key={tileLink.translationId}
                    >
                      <VehicleOverviewTileLink option={tileLink} />
                    </CellStyled>
                  ))}
                </ColumnStyled>
                <ColumnStyled>
                  {tileLinksSecondColumn.map(tileLink => (
                    <CellStyled
                      onClick={() => onHandleClick(contract)}
                      key={tileLink.translationId}
                    >
                      <VehicleOverviewTileLink option={tileLink} />
                    </CellStyled>
                  ))}
                </ColumnStyled>
              </RowStyled>
            )
          }

          return (
            <RowStyled>
              {tileLinksFirstColumn.map(tileLink => (
                <CellStyled
                  onClick={() => onHandleClick(contract)}
                  key={tileLink.translationId}
                >
                  <VehicleOverviewTileLink option={tileLink} />
                </CellStyled>
              ))}
              {tileLinksSecondColumn.map(tileLink => (
                <CellStyled
                  onClick={() => onHandleClick(contract)}
                  key={tileLink.translationId}
                >
                  <VehicleOverviewTileLink option={tileLink} />
                </CellStyled>
              ))}
            </RowStyled>
          )
        }}
      </MyLeaseplanContext.Consumer>
    )
  }
}

export { VehicleOverviewTileLinks }
export default withBreakpoint(VehicleOverviewTileLinks)
