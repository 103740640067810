import React from 'react'

import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'

interface Props {
  disabled?: boolean
  onClick?(values: GenericObject): void
}

const NextStepButton: React.FC<Props> = ({ disabled, onClick }) => (
  <SubmitButton
    disabled={disabled}
    dataE2eId="button-next-step"
    onClick={onClick}
    label="myLeaseplan.newSetup.shared.nextButton"
  />
)

export default NextStepButton
