import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import EditProfileForm from 'mlp-client/src/profile/components/edit-profile-form/EditProfileForm'
import { SubmitProfileForm } from 'mlp-client/src/profile/actions'
import {
  EditProfileConfig,
  SubmitProfileFormTypes,
} from 'mlp-client/src/profile/types'
import { User } from 'mlp-client/src/user/types'
import { getUser } from 'mlp-client/src/user/selectors'
import { AppState } from 'mlp-client/src/types'
import { FormSubmission } from 'mlp-client/src/form/types'

export interface Props
  extends MyLeaseplanFeatureProps<EditProfileConfig>,
    FormSubmission<SubmitProfileFormTypes> {
  user: User
  metaTitle: string
  metaDescription: string
}

class EditProfile extends React.PureComponent<Props> {
  render() {
    const {
      metaDescription,
      metaTitle,
      user,
      featureConfig,
      onSubmit,
    } = this.props
    const main = (
      <EditProfileForm
        user={user}
        onSubmit={onSubmit}
        config={featureConfig.configuration}
      />
    )

    return (
      <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
        <GridLayout main={main} />
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'metaDescription' | 'metaTitle' | 'user'>
type DispatchProps = Pick<Props, 'onSubmit'>

const mapTranslationsToProps = {
  metaTitle: 'myLeaseplan.profile.edit.title',
  metaDescription: 'myLeaseplan.profile.edit.subtitle',
}

export const mapStateToProps = (state: AppState): ReduxProps => ({
  user: getUser(state),
  ...getTranslations(state, mapTranslationsToProps),
})

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (formData, formApi, onComplete) => {
    dispatch(new SubmitProfileForm({ formData, onComplete }))
  },
})

export { EditProfile }
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
