import { PictogramProps } from '@leaseplan/ui'
import React from 'react'

type DefaultProps = Required<Pick<PictogramProps, 'height' | 'width'>>
type InternalProps = PictogramProps & DefaultProps

export default class RoadsideAssistanceIconDesktop extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    height: '64',
    width: '64',
  }

  render() {
    const { width, height } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 64 64"
      >
        <g fill="#F06400" fillRule="evenodd">
          <path d="M11.16 41.784h19.914c1.62 0 3.14-.715 4.172-1.963l7.728-9.349h2.648l.005.001H53.5v19.512H35.537a8.375 8.375 0 0 0-1.717-3.674 8.352 8.352 0 0 0-5.65-3.072 8.35 8.35 0 0 0-6.168 1.823 8.338 8.338 0 0 0-2.977 5.034H11.16v-8.312zm38.593-26.566H53.5v12.254h-6.064l2.317-12.254zM6.27 11.94a.683.683 0 0 1-.476-.823.684.684 0 0 1 .822-.493l39.316 8.658-.73 3.864L6.268 11.94zm15.644 39.088a5.36 5.36 0 0 1 1.976-3.633 5.362 5.362 0 0 1 3.964-1.173 5.37 5.37 0 0 1 3.634 1.976 5.372 5.372 0 0 1 1.173 3.965 5.371 5.371 0 0 1-1.976 3.634 5.352 5.352 0 0 1-3.965 1.172 5.362 5.362 0 0 1-3.633-1.975 5.37 5.37 0 0 1-1.173-3.966zM5.65 24.256h2.51v-2.77H5.65v2.77zM60.5 57H33.722a8.327 8.327 0 0 0 1.842-4.015H60.5a1.5 1.5 0 0 0 0-3h-4v-4.507h3.75a1.5 1.5 0 0 0 0-3H56.5V13.718a1.5 1.5 0 0 0-1.5-1.5h-6.49c-.72 0-1.34.513-1.475 1.221l-.547 2.894L7.273 7.697c-1.935-.44-3.902.761-4.388 2.684-.484 1.905.653 3.878 2.52 4.427v3.678H4.15a1.5 1.5 0 0 0-1.5 1.5v5.77a1.5 1.5 0 0 0 1.5 1.5h1.318v1.13c0 1.998.991 2.811 1.715 3.405.397.326.684.561.91 1.015.28.592.294 1.167.034 1.578-.22.351-.633.544-1.159.544-1.126 0-1.314-1.06-1.33-1.18a1.501 1.501 0 0 0-2.969.433c.262 1.804 1.72 3.747 4.3 3.747 1.568 0 2.916-.708 3.695-1.943.808-1.28.86-2.952.127-4.488-.52-1.053-1.205-1.615-1.705-2.025-.548-.45-.618-.508-.618-1.086v-1.13H9.66a1.5 1.5 0 0 0 1.5-1.5v-5.77a1.5 1.5 0 0 0-1.5-1.5H8.405v-2.809l36.235 10.43-.257 1.365H42.27c-.447 0-.87.2-1.156.544l-8.18 9.893a2.405 2.405 0 0 1-1.859.875H9.66a1.5 1.5 0 0 0-1.5 1.5v11.312a1.5 1.5 0 0 0 1.5 1.5h9.358a8.352 8.352 0 0 0 1.735 3.785c.034.041.072.078.106.12H3.404a1.5 1.5 0 1 0 0 3H60.5a1.5 1.5 0 0 0 0-3z" />
          <path d="M27.013 54.19a2.608 2.608 0 1 0 .547-5.187 2.608 2.608 0 0 0-.547 5.187M50.994 20.645a1.718 1.718 0 1 0 0 3.436 1.718 1.718 0 0 0 0-3.436" />
        </g>
      </svg>
    )
  }
}
