import { DamageReportFeature } from 'mlp-client/src/features-configuration/types'
import {
  DamageCauses,
  DamageReportFlowConfiguration,
} from 'mlp-client/src/flows/damage-report/types'
import Flow from 'mlp-client/src/flows/damage-report/components/flow/Flow'
import { StepNames } from 'mlp-client/src/flows/damage-report/enums'
import {
  CheckDetailsStep,
  DamageCauseStep,
  DamageUploadStep,
  EuropeanAccidentStatementStep,
  InsuranceStep,
  ThankYouStep,
  WhatHappenedStep,
  WhenAndWhereStep,
} from 'mlp-client/src/flows/damage-report/components/steps'
import {
  damageCauses,
  partyDamages,
} from 'mlp-client/src/features-configuration/dk/data'
import { pointSelectionConfig } from 'mlp-client/src/features-configuration/default/damage-report'
import { whoseFault } from 'mlp-client/src/features-configuration/data'

export const whatHappenedConfig = {
  stepView: WhatHappenedStep,
  stepConfig: {
    whoseFaultOptions: whoseFault,
    whatHappenedOptions: partyDamages,
  },
}

export const damageReportConfig: DamageReportFeature<DamageReportFlowConfiguration> = {
  enabled: true,
  view: Flow,
  tileRoute: 'myLeaseplan.damageReport.damageList',
  mobileApp: {
    disabled: true,
  },
  configuration: {
    getSteps: flowData => [
      StepNames.SELECT_DAMAGE_CAUSE,
      StepNames.WHAT_HAPPENED,
      StepNames.UPLOAD_DAMAGE,
      StepNames.POINT_SELECTION,
      StepNames.WHEN_AND_WHERE,
      ...(flowData.damageCause === DamageCauses.TWO_PARTY
        ? [StepNames.EUROPEAN_ACCIDENT_STATEMENT, StepNames.INSURANCE]
        : []),
      StepNames.CHECK_DETAILS,
      StepNames.THANK_YOU,
    ],
    allSteps: {
      [StepNames.SELECT_DAMAGE_CAUSE]: {
        stepView: DamageCauseStep,
        stepConfig: {
          options: damageCauses,
        },
      },
      [StepNames.WHAT_HAPPENED]: whatHappenedConfig,
      [StepNames.UPLOAD_DAMAGE]: {
        stepView: DamageUploadStep,
        stepConfig: {
          isPoliceStationEnabled: true,
        },
      },
      [StepNames.POINT_SELECTION]: pointSelectionConfig,
      [StepNames.WHEN_AND_WHERE]: {
        stepView: WhenAndWhereStep,
        stepConfig: {
          label: 'myLeaseplan.address.labels.fillAddress',
        },
      },
      [StepNames.EUROPEAN_ACCIDENT_STATEMENT]: {
        stepView: EuropeanAccidentStatementStep,
        stepConfig: {
          isFileOptionalWhenThirdParty: true,
          disclaimer:
            'myLeaseplan.damageReport.steps.europeanAccidentStatement.subtitle',
        },
      },
      [StepNames.INSURANCE]: {
        stepView: InsuranceStep,
        stepConfig: {},
      },
      [StepNames.CHECK_DETAILS]: {
        stepView: CheckDetailsStep,
        stepConfig: {
          whatHappenedOptions: partyDamages,
          isInsuranceEnabled: true,
        },
      },
      [StepNames.THANK_YOU]: {
        stepView: ThankYouStep,
        stepConfig: {
          amountOfWorkingDays: 5,
          showTimelineLabel: true,
        },
      },
    },
  },
}
