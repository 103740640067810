import { mq } from '@leaseplan/ui'
import styled from 'styled-components'

const LoginNavigationSection = styled.section`
  height: 100%;
`

const LoginInformationSection = styled.section`
  padding: 150px 0 0;
`

const TermsAndConditionsFooter = styled.footer`
  text-align: left;
  padding-top: 24px;
  padding-bottom: 36px;
`

const LoginSideBar = styled.div`
  box-shadow: 6px 0 10px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
`

const LoginSideBarBody = styled.div`
  padding: 150px 96px 0;
  ${mq.until('wide')`
    padding: 150px 48px 0;
  `}
  ${mq.until('lap')`
    padding: 150px 24px 0;
  `}
  ${mq.until('tablet')`
    padding: 50px 24px 0;
  `}
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export {
  LoginNavigationSection,
  LoginInformationSection,
  TermsAndConditionsFooter,
  LoginSideBar,
  LoginSideBarBody,
}
