import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Translation from 'mlp-client/src/localization/Translation'
import { getUserContacts } from 'mlp-client/src/user/selectors'
import { ContactInfo } from 'mlp-client/src/user/types'
import { AppState } from 'mlp-client/src/types'
import { formatPhoneNumber } from 'mlp-client/src/utils'

import { GeneralListTile } from './GeneralListTile'

export interface Props {
  title?: React.ReactNode
  contacts: ContactInfo
  dataE2eId?: string
  onClick(): void
}

type DefaultProps = Required<Pick<Props, 'dataE2eId' | 'title'>>
type InternalProps = DefaultProps & Props

export class ContactDetailsTile extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    dataE2eId: 'contactDetails',
    title: (
      <Translation id="myLeaseplan.tyres.steps.checkDetails.listItems.contactDetails.title" />
    ),
  }

  render() {
    const { title, dataE2eId, contacts, onClick } = this.props
    const { phone, ...restContractsInfo } = contacts

    return (
      <GeneralListTile dataE2eId={dataE2eId} title={title} onClick={onClick}>
        {Object.values(restContractsInfo)}
        {phone.number && formatPhoneNumber(phone.code, phone.number)}
      </GeneralListTile>
    )
  }
}

interface ContainerProps extends Props {
  flow: string
  step: string
}

class ContactDetailsContainer extends React.PureComponent<ContainerProps> {
  render() {
    const { flow, step, ...restProps } = this.props

    return <ContactDetailsTile {...restProps} />
  }
}

type ReduxProps = Pick<ContainerProps, 'contacts'>
type OwnProps = Omit<ContainerProps, keyof ReduxProps>

const mapStateToProps = createStructuredSelector<
  AppState,
  OwnProps,
  ReduxProps
>({
  contacts: getUserContacts,
})

export default connect(mapStateToProps)(ContactDetailsContainer)
