import { Text } from '@velocity/ui'
import React from 'react'

import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { formatNumber } from 'mlp-client/src/utils'
import { Attachments } from 'mlp-client/src/types'
import ImagePreview from 'mlp-client/src/components/image-preview/ImagePreview'

import { EditButtonLabel } from './EditButtonLabel'

interface Props {
  mileage: number | string
  title: React.ReactElement | string
  photos?: Attachments
  distanceUnitLabel: string
  onClick(): void
}

export const MileageTile: React.FC<Props> = ({
  distanceUnitLabel,
  mileage,
  title,
  onClick,
  photos,
}) => (
  <FlowStepTile
    dataE2eId="mileage"
    title={title}
    btnLabel={EditButtonLabel}
    onBtnClick={onClick}
  >
    {mileage && (
      <Text variant="100">{`${formatNumber(
        mileage,
      )} ${distanceUnitLabel}`}</Text>
    )}
    {photos && <ImagePreview attachments={photos} />}
  </FlowStepTile>
)

MileageTile.displayName = 'MileageTile'
