import { GridItem, Hide, Paper, Spacing } from '@leaseplan/ui'
import { Headline, Text, colorMixins } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { RoadsideAssistanceConfig } from 'mlp-client/src/components/road-side-assistance/types'
import { CenteredGridItem } from 'mlp-client/src/components/styled/CenteredGridItem'
import BannerIcon from 'mlp-client/src/dashboard/components/BannerIcon'
import { GridStyled } from 'mlp-client/src/dashboard/components/DashboardBanner'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { StyledButtonOutlined } from 'mlp-client/src/components/button/StyledButton'

export interface Props
  extends MyLeaseplanFeatureProps<RoadsideAssistanceConfig> {}

const TextNoWrap = styled(Text)`
  white-space: nowrap;
  color: ${colorMixins.secondaryDarkColor};
`

class RoadSideAssistanceWithPhoneNumbers extends React.PureComponent<Props> {
  renderDesktopItem(intro: string, phoneNumber: string, dataE2EId: string) {
    return (
      <SecondaryDarkText variant="400" component="span">
        {' '}
        <Translation
          id={intro}
          replace={{
            phoneNumber: () => (
              <TextNoWrap
                variant="400"
                component="span"
                bold
                key={phoneNumber}
                data-e2e-id={dataE2EId}
              >
                <Translation id={phoneNumber} />
              </TextNoWrap>
            ),
          }}
        />
      </SecondaryDarkText>
    )
  }

  renderMobileItem(intro: string, phoneNumber: string, dataE2EId: string) {
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <Text variant="200">
            <Translation
              id={intro}
              replace={{
                phoneNumber: () => (
                  <StyledButtonOutlined
                    variant="secondary"
                    stretch={true}
                    component="a"
                    href={`tel:${translate(phoneNumber)}`}
                    key={translate(phoneNumber)}
                    data-e2e-id={dataE2EId}
                  >
                    {translate(phoneNumber)}
                  </StyledButtonOutlined>
                ),
              }}
            />
          </Text>
        )}
      </LocalizationContext.Consumer>
    )
  }
  render() {
    const {
      featureConfig: { configuration },
    } = this.props

    const {
      primaryNumber,
      secondaryNumber,
      primaryNumberIntro,
      secondaryNumberIntro,
    } = configuration

    return (
      <Paper>
        <Spacing p={2}>
          <GridStyled alignItems="center" verticalGutter={false}>
            <GridItem>
              <BannerIcon />
            </GridItem>
            <GridItem span="grow" data-e2e-id="roadAssistance">
              <GridStyled justifyContent="space-between" alignItems="center">
                <Hide until="tablet">
                  <GridItem>
                    <Headline variant="200" component="span">
                      <Spacing mr={1} component="span">
                        <Translation id="myLeaseplan.dashboard.roadsideAssistanceTitle" />
                      </Spacing>
                    </Headline>
                    {primaryNumberIntro &&
                      this.renderDesktopItem(
                        primaryNumberIntro,
                        primaryNumber,
                        'primaryNumber',
                      )}
                    {secondaryNumberIntro &&
                      this.renderDesktopItem(
                        secondaryNumberIntro,
                        secondaryNumber,
                        'secondaryNumber',
                      )}
                  </GridItem>
                </Hide>
                <Hide from="tablet">
                  <CenteredGridItem span="grow">
                    <Headline variant="200">
                      <Translation id="myLeaseplan.dashboard.roadsideAssistanceTitle" />
                    </Headline>
                    {primaryNumberIntro &&
                      this.renderMobileItem(
                        primaryNumberIntro,
                        primaryNumber,
                        'primaryButton',
                      )}
                    {secondaryNumberIntro && (
                      <Spacing mt={1} mb={2}>
                        {this.renderMobileItem(
                          secondaryNumberIntro,
                          secondaryNumber,
                          'secondaryButton',
                        )}
                      </Spacing>
                    )}
                  </CenteredGridItem>
                </Hide>
              </GridStyled>
            </GridItem>
          </GridStyled>
        </Spacing>
      </Paper>
    )
  }
}

export { RoadSideAssistanceWithPhoneNumbers }
export default RoadSideAssistanceWithPhoneNumbers
