import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'

import { Options } from 'mlp-client/src/form/types'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  DamageNatureTypes,
  FlowData,
  RequestStatus,
} from 'mlp-client/src/flows/damage-report/types'
import { AppState } from 'mlp-client/src/types'
import {
  getDamageNatureTypes,
  getDamageNatureTypeStatus,
} from 'mlp-client/src/flows/damage-report/selectors'
import { GetDamageNatureTypesRequest } from 'mlp-client/src/flows/damage-report/actions'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { isAmbiguousType } from 'mlp-client/src/flows/damage-report/utils'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'
import { getDamageNatureOptions } from 'mlp-client/src/flows/damage-report/components/steps/damage-nature-type-step/utils'

export interface StepConfig {
  title?: string
  posterTitleLabel?: string
  posterTextLabel?: string
  posterImgSmall?: string
  posterImgMedium?: string
  posterImgLarge?: string
  showFAQ?: boolean
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
  status?: string
  getDamageNatureTypes(): void
  options: Options
  damageNatureTypes: DamageNatureTypes
}

type FormValues = Pick<FlowData, 'damageNatureType'>

class DamageNatureTypeStep extends React.PureComponent<Props> {
  componentDidMount() {
    const { options, status, getDamageNatureTypes } = this.props

    if (!options?.length || status === RequestStatus.Failed) {
      getDamageNatureTypes()
    }
  }

  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData, damageNatureTypes } = this.props

    setFlowData(
      {
        damageNatureType: values.damageNatureType,
        isAmbiguousType: isAmbiguousType(
          values.damageNatureType,
          damageNatureTypes,
        ),
      },
      goToNextStep,
    )
  }

  render() {
    const {
      options,
      config: {
        posterImgLarge,
        posterImgMedium,
        posterImgSmall,
        posterTitleLabel,
        title,
      },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          posterImgLarge={posterImgLarge}
          posterImgMedium={posterImgMedium}
          posterImgSmall={posterImgSmall}
          posterTitleLabel={posterTitleLabel}
          titleLabel={title}
        >
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit }) => (
              <RevealingItemList batchSize={6} items={options}>
                {options => (
                  <RadioClickAndGoList
                    validations={[]}
                    name="damageNatureType"
                    options={options}
                    onChange={handleSubmit}
                    dataE2eId={'damageNatureTypeList'}
                  />
                )}
              </RevealingItemList>
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'options' | 'status' | 'damageNatureTypes'>
type DispatchProps = Pick<Props, 'getDamageNatureTypes'>

export const mapStateToProps = (state: AppState): ReduxProps => {
  const damageNatureTypes = getDamageNatureTypes(state)

  return {
    damageNatureTypes,
    options: getDamageNatureOptions(damageNatureTypes),
    status: getDamageNatureTypeStatus(state),
  }
}

export const mapDispatchToProps: DispatchProps = {
  getDamageNatureTypes: () => new GetDamageNatureTypesRequest(),
}

export { DamageNatureTypeStep }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DamageNatureTypeStep)
