import { CountrySpecific } from 'mlp-client/src/config/types'
import { sharedMyLeaseplanConfig } from 'mlp-client/src/features-configuration/lu'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'

import createConfig from './defaults'

const specificConfig = (): CountrySpecific => ({
  label: 'Luxembourg',
  code: CountryCode.lu,
  tld: '.lu',
  visible: true,
  languages: [
    {
      label: 'Français',
      code: LanguageCode.fr,
      locale: Locales.FR_LU,
      preferred: true,
    },
    {
      label: 'English',
      code: LanguageCode.en,
      locale: Locales.EN_LU,
      preferred: false,
    },
  ],
  features: {
    myLeasePlan: sharedMyLeaseplanConfig,
  },
  enableLanguageSelector: true,
  noContractAllowed: false,
})

export default createConfig(specificConfig)
