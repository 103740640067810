import { createSelector } from 'reselect'

import { Origin } from 'mlp-client/src/enums'
import { AppState } from 'mlp-client/src/types'

export const getLoading = (state: AppState) => state.auth.loading

export const getIdentityClientId = (state: AppState) =>
  state.auth.identityClientId

export const getIsMobileApp = (state: AppState) => state.auth.isMobileApp

export const getOrigin = createSelector(getIsMobileApp, isMobile =>
  isMobile ? Origin.Mobile : Origin.Web,
)

export const getNextRoute = (state: AppState) => state.auth.nextRoute
