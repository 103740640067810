export const markerOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -height,
})

/**
 * https://developers.google.com/maps/documentation/urls/guide#directions-action
 */
export const getGoogleMapsRouteLink = (coordinates: string) => {
  const encodedCoordinates = encodeURIComponent(coordinates)

  return `https://www.google.com/maps/dir/?api=1&destination=${encodedCoordinates}`
}
