import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Dispatch } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'
import isUndefined from 'lodash/isUndefined'

import Translation from 'mlp-client/src/localization/Translation'
import {
  PostAttachCar,
  ValidateLicensePlate,
} from 'mlp-client/src/attach-car/actions'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { getUser } from 'mlp-client/src/user/selectors'
import { User } from 'mlp-client/src/user/types'
import { AppState } from 'mlp-client/src/types'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import {
  licensePlate,
  maxLength,
  phoneNumber,
  required,
  validEmail,
} from 'mlp-client/src/form/validations'
import { DriverDetails, FormData } from 'mlp-client/src/attach-car/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import SalutationField from 'mlp-client/src/form/components/fields/salutation-field/SalutationField'
import TermsAndConditionsField from 'mlp-client/src/form/components/fields/terms-and-conditions-field/TermsAndConditionsField'
import { CenteredGridItem } from 'mlp-client/src/components/styled/CenteredGridItem'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import {
  FormSubmission,
  StringValidation,
  TranslationType,
} from 'mlp-client/src/form/types'
import ThankYou from 'mlp-client/src/attach-car/components/thank-you/ThankYou'
import { MainContentCenter } from 'mlp-client/src/components/layout/main-content-center/MainContentCenter'
import { getCountry } from 'mlp-client/src/localization/selectors'
import { CountryCode } from 'mlp-client/src/localization/enums'
import { getAttachCarUrlParams } from 'mlp-client/src/attach-car/utils'
import {
  isFetching,
  licensePlateExists,
} from 'mlp-client/src/attach-car/selectors'

export interface Props
  extends RouteComponentProps<{}>,
    FormSubmission<FormData> {
  user?: User
  countryCode: CountryCode
  driverDetails: DriverDetails
  licensePlateExists: boolean
  validateLicensePlate(licensePlate: string): void
  isFetching: boolean
}

function getPhoneNumber(user: User): string {
  return user?.phoneNumber || user?.mobilePhoneNumber
}

const RequestAccount: React.FC<Props> = props => {
  const {
    location,
    countryCode,
    user,
    licensePlateExists,
    isFetching,
    validateLicensePlate,
    onSubmit,
  } = props

  const [licensePlateString, setLicensePlateString] = useState('')

  const licensePlateValidation: StringValidation = useCallback(
    (value: string) => {
      const requiredResult = required(value)
      const isValidLicensePlate = licensePlate(
        countryCode,
        'myLeaseplan.attachCarToDriver.invalidLicensePlate',
      )(value)

      if (!value) {
        setLicensePlateString('')
      }

      if (isUndefined(requiredResult) && isUndefined(isValidLicensePlate)) {
        if (licensePlateString !== value) {
          validateLicensePlate(value)
          setLicensePlateString(value)
        }

        if (licensePlateExists === false || isFetching === true) {
          return { id: 'myLeaseplan.attachCarToDriver.inexistentLicensePlate' }
        }

        return undefined
      }

      return requiredResult || isValidLicensePlate
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, licensePlateString],
  )

  const params = getAttachCarUrlParams(countryCode, location.search)

  const validate = () => {
    const errors: Partial<{ [key in keyof FormData]: TranslationType }> = {}

    return errors
  }

  const form = (
    <ReactFinalForm<FormData>
      initialValues={{
        firstName: user?.firstName || params.firstName,
        lastName: user?.lastName || params.lastName,
        emailAddress: user?.email || params.email,
        telephoneNumber: getPhoneNumber(user) || params.phone,
        salutation: params.salutation,
        licensePlate: params.licensePlate,
        contractNumber: '',
        termsAndConditions: false,
      }}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({
        handleSubmit,
        hasValidationErrors,
        submitting,
        submitSucceeded,
        submitErrors,
        submitFailed,
        pristine,
      }: FormRenderProps) =>
        !submitSucceeded ? (
          <Grid justifyContent="center" alignItems="center">
            <CenteredGridItem span={10}>
              <Spacing pv={2} component="section">
                <Headline component="h1" variant="400" withMarginBottom>
                  <Translation id="myLeaseplan.attachCarToDriver.title" />
                </Headline>
                <Text variant="400">
                  <Translation id="myLeaseplan.attachCarToDriver.subtitle" />
                </Text>
              </Spacing>
            </CenteredGridItem>
            <GridItem span={7}>
              <form onSubmit={handleSubmit}>
                <TextField
                  onChange={licensePlateValidation}
                  data-test-id="licensePlateField"
                  name="licensePlate"
                  validations={!submitting ? [licensePlateValidation] : []}
                  label={{
                    id: 'myLeaseplan.attachCarToDriver.leasePlanIdentification',
                  }}
                  placeholder={{
                    id: 'myLeaseplan.attachCarToDriver.licensePlatePlaceholder',
                  }}
                />
                <Spacing mt={2} />
                <MyLeaseplanContext.Consumer>
                  {context =>
                    context.showSalutation && (
                      <>
                        <SalutationField
                          options={context.salutation || []}
                          validations={[required]}
                        />
                        <Spacing mb={2} />
                      </>
                    )
                  }
                </MyLeaseplanContext.Consumer>

                <TextField
                  name="firstName"
                  validations={[required, maxLength(40)]}
                  label={{
                    id: 'myLeaseplan.shared.input.fullName.firstName.label',
                  }}
                  placeholder={{
                    id:
                      'myLeaseplan.shared.input.fullName.firstName.placeholder',
                  }}
                />
                <Spacing mt={2} />
                <TextField
                  name="lastName"
                  validations={[required, maxLength(80)]}
                  label={{
                    id: 'myLeaseplan.shared.input.fullName.lastName.label',
                  }}
                  placeholder={{
                    id:
                      'myLeaseplan.shared.input.fullName.lastName.placeholder',
                  }}
                />
                <Spacing mt={2} />
                <TextField
                  name="emailAddress"
                  validations={[validEmail, required]}
                  disabled={!!user}
                  label={{ id: 'myLeaseplan.shared.input.email.label' }}
                  placeholder={{
                    id: 'myLeaseplan.shared.input.email.placeholder',
                  }}
                />
                <Spacing mt={2} />
                <TextField
                  name="telephoneNumber"
                  validations={[required, maxLength(16), phoneNumber]}
                  label={{ id: 'myLeaseplan.shared.input.phone.label' }}
                  placeholder={{
                    id: 'myLeaseplan.shared.input.phone.placeholder',
                  }}
                />

                <Spacing mt={2}>
                  <TermsAndConditionsField />
                </Spacing>
                <Spacing mv={3}>
                  <SubmitStatus
                    submitting={submitting}
                    failed={submitFailed}
                    failedMessages={submitErrors && Object.values(submitErrors)}
                  >
                    <SubmitButton
                      disabled={hasValidationErrors || submitting || pristine}
                      onClick={handleSubmit}
                      label="myLeaseplan.attachCarToDriver.submit"
                      dataE2eId="submitRequestAccountFormButton"
                    />
                  </SubmitStatus>
                </Spacing>
              </form>
            </GridItem>
          </Grid>
        ) : (
          <ThankYou />
        )
      }
    </ReactFinalForm>
  )

  return (
    <MyleaseplanPage>
      <MainContentCenter>{form}</MainContentCenter>
    </MyleaseplanPage>
  )
}

type ReduxProps = Pick<
  Props,
  'countryCode' | 'user' | 'isFetching' | 'licensePlateExists'
>
type DispatchProps = Pick<Props, 'onSubmit' | 'validateLicensePlate'>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  countryCode: getCountry,
  user: getUser,
  isFetching,
  licensePlateExists,
})

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onSubmit: (formData: FormData, formApi, onComplete: any) => {
    dispatch(
      new PostAttachCar({
        attachCar: formData,
        onComplete,
      }),
    )
  },
  validateLicensePlate: (licensePlate: string): void => {
    dispatch(
      new ValidateLicensePlate({
        licensePlate,
      }),
    )
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RequestAccount),
)

export { RequestAccount }
