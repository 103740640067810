import { ButtonLink, Spacing } from '@leaseplan/ui'
import { Headline, Text, colorMixins } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import BookingCancellationMessage from 'mlp-client/src/timeline-details/components/BookingCancellationModal/BookingCancellationMessage'
import { BookingStatus } from 'mlp-client/src/timeline-details/components/views/booking-status/BookingStatus'
import { GrayDark60Text } from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  title: React.ReactNode
  titleWithDate: React.ReactNode
  subTitle?: React.ReactNode
  date?: React.ReactNode
  status: string
  caseNumber?: React.ReactNode
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
  text-transform: uppercase;
`

class TimelineDetailsHeader extends React.PureComponent<Props> {
  render() {
    const {
      title,
      titleWithDate,
      subTitle,
      date,
      status,
      caseNumber,
      children,
    } = this.props

    return (
      <header>
        <Spacing mb={2} mr={1}>
          <Breadcrumbs>
            <ButtonLink
              to="myLeaseplan.timeline.base"
              component={RouteLink}
              size="s"
              fontWeight="regular"
              color="steel60"
            >
              <Translation id="myLeaseplan.timeline.base.title" />
            </ButtonLink>
            <GrayDark60Text variant="100">{title}</GrayDark60Text>
          </Breadcrumbs>
        </Spacing>

        <BookingCancellationMessage />

        <Spacing mb={2}>
          <Headline variant="400" withMarginBottom>
            {titleWithDate}
          </Headline>
          {subTitle && (
            <Headline variant="200" withMarginBottom>
              {subTitle}
            </Headline>
          )}
          {caseNumber && (
            <Spacing mt={1}>
              <Text variant="400" bold>
                {caseNumber}
              </Text>
            </Spacing>
          )}
          {date && (
            <Spacing mt={1}>
              <SText variant="100" bold>
                {date}
              </SText>
            </Spacing>
          )}
          <BookingStatus status={status} />
        </Spacing>
        {children}
      </header>
    )
  }
}

export default TimelineDetailsHeader
