import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class FinesIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M50.48 47.808a1.5 1.5 0 1 1 3 0v9.503a4.694 4.694 0 0 1-4.688 4.69H12.689A4.694 4.694 0 0 1 8 57.31V5.806a4.694 4.694 0 0 1 4.688-4.689h36.103a4.694 4.694 0 0 1 4.688 4.69v2.751a1.5 1.5 0 1 1-3 0V5.806a1.69 1.69 0 0 0-1.688-1.689H12.689A1.69 1.69 0 0 0 11 5.807V57.31a1.69 1.69 0 0 0 1.688 1.69h36.103a1.69 1.69 0 0 0 1.688-1.69v-9.503zM42.38 12.53a1.5 1.5 0 1 1 0 3H16.606a1.5 1.5 0 1 1 0-3h25.775zm1.5 10.797a1.5 1.5 0 0 1-1.5 1.5H16.606a1.5 1.5 0 1 1 0-3h25.775a1.5 1.5 0 0 1 1.5 1.5zM17.373 45.07a1.5 1.5 0 0 1 1.5-1.5h2.98a11.665 11.665 0 0 1-.128-1.639c0-.437.027-.868.074-1.293h-2.926a1.5 1.5 0 0 1 0-3h3.667c1.726-4.429 6.025-7.58 11.058-7.58 1.686 0 3.316.346 4.843 1.03a1.5 1.5 0 1 1-1.224 2.738 8.81 8.81 0 0 0-3.619-.769c-3.336 0-6.245 1.853-7.76 4.581h7.14a1.5 1.5 0 1 1 0 3H24.83a8.891 8.891 0 0 0-.105 1.293c0 .56.058 1.107.158 1.64h8.094a1.5 1.5 0 1 1 0 3h-6.929c1.564 2.534 4.358 4.233 7.55 4.233a8.796 8.796 0 0 0 3.861-.88 1.5 1.5 0 0 1 1.31 2.7 11.773 11.773 0 0 1-5.17 1.18c-4.903 0-9.12-2.986-10.929-7.234h-3.797a1.5 1.5 0 0 1-1.5-1.5zm37.807-8.413c1.879 0 3.239 1.36 3.239 3.24 0 1.879-1.36 3.239-3.239 3.239-1.84 0-3.24-1.36-3.24-3.24 0-1.88 1.4-3.24 3.24-3.24zm-2.32-2.6l-.52-20.917h5.68l-.48 20.917h-4.68z"
        />
      </svg>
    )
  }
}

export default FinesIcon
