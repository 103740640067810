import { Button, Grid, GridItem, ModalTrigger } from '@leaseplan/ui'
import * as React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import { getPageContent } from 'mlp-client/src/selectors'
import { getRoadsideAssistanceTileButton } from 'mlp-client/src/components/road-side-assistance/selectors'
import {
  DashboardRoadsideAssistance,
  RoadSideAssistanceTileButton,
} from 'mlp-client/src/components/road-side-assistance/types'
import DashboardBanner from 'mlp-client/src/dashboard/components/DashboardBanner'
import { getRoadsideAssistanceButtonInfo } from 'mlp-client/src/components/road-side-assistance/utils'
import { dashboardContentPath } from 'mlp-client/src/constants'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { AppState } from 'mlp-client/src/types'
import { LoadContent } from 'mlp-client/src/content/actions'
import { ContentPage } from 'mlp-client/src/content/types'
import PhoneNumberModalContent from 'mlp-client/src/dashboard/components/PhoneNumberModalContent'
import { User } from 'mlp-client/src/user/types'
import { formatBrand } from 'mlp-client/src/utils/formatters/formatters'
import { getUser } from 'mlp-client/src/user/selectors'
import { isValidBrand } from 'mlp-client/src/utils/checks/checks'

const ROADSIDE_ASSISTANCE = 'Roadsideassistance'
const CUSTOMER_SUPPORT = 'Customersupport'

export interface Props extends MyLeaseplanFeatureProps {
  roadsideAssistanceButtonSupport: DashboardRoadsideAssistance
  roadsideAssistanceButtonAssistance: DashboardRoadsideAssistance
  roadsideAssistanceTitle: string
  loadPageContent(): void
  pageContent?: ContentPage
  user?: User
}

class RoadSideAssistanceWithModals extends React.PureComponent<Props> {
  componentDidMount() {
    const { loadPageContent, pageContent } = this.props

    if (!pageContent) {
      loadPageContent()
    }
  }

  renderButton = (button: RoadSideAssistanceTileButton) => {
    if (!button) {
      return null
    }

    const {
      buttonLabel: label,
      telephone,
      modalTitle,
      message,
      dataTagId,
    } = button

    return (
      <ModalTrigger
        modalContent={
          <PhoneNumberModalContent
            phoneNumber={telephone}
            title={modalTitle}
            message={message}
          />
        }
        trigger={
          <Button
            component="button"
            variant="secondary"
            data-tag-id={dataTagId}
            data-e2e-id="serviceSupport"
          >
            {label}
          </Button>
        }
      />
    )
  }

  render() {
    const {
      roadsideAssistanceButtonAssistance,
      roadsideAssistanceButtonSupport,
      roadsideAssistanceTitle,
      pageContent,
      user,
    } = this.props
    let brand = null

    if (isValidBrand(user?.brand)) {
      brand = formatBrand(user?.brand)
    }

    let roadsideAssistance = getRoadsideAssistanceButtonInfo(
      `${ROADSIDE_ASSISTANCE}_${brand}`,
      roadsideAssistanceButtonAssistance,
      pageContent,
      'roadside-assistance-phonenumber-button',
    )
    let roadsideSupport = getRoadsideAssistanceButtonInfo(
      `${CUSTOMER_SUPPORT}_${brand}`,
      roadsideAssistanceButtonSupport,
      pageContent,
      'roadside-assistance-support-phonenumber-button',
    )

    if (!roadsideAssistance) {
      roadsideAssistance = getRoadsideAssistanceButtonInfo(
        ROADSIDE_ASSISTANCE,
        roadsideAssistanceButtonAssistance,
        pageContent,
        'roadside-assistance-phonenumber-button',
      )
    }

    if (!roadsideSupport) {
      roadsideSupport = getRoadsideAssistanceButtonInfo(
        CUSTOMER_SUPPORT,
        roadsideAssistanceButtonSupport,
        pageContent,
        'roadside-assistance-support-phonenumber-button',
      )
    }

    const showRoadSideAssistance = Boolean(
      roadsideAssistance || roadsideSupport,
    )

    return showRoadSideAssistance ? (
      <Grid>
        <GridItem span="grow">
          <DashboardBanner
            title={roadsideAssistanceTitle}
            AssistanceButton={this.renderButton(roadsideAssistance)}
            SupportButton={this.renderButton(roadsideSupport)}
          />
        </GridItem>
      </Grid>
    ) : null
  }
}

type ReduxProps = Pick<
  Props,
  | 'roadsideAssistanceTitle'
  | 'roadsideAssistanceButtonAssistance'
  | 'roadsideAssistanceButtonSupport'
  | 'pageContent'
  | 'user'
>

type DispatchProps = Pick<Props, 'loadPageContent'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  roadsideAssistanceTitle: getTranslation(
    state,
    'myLeaseplan.dashboard.roadsideAssistance.title',
  ),
  roadsideAssistanceButtonAssistance: getRoadsideAssistanceTileButton(
    state,
    'assistance',
  ),
  roadsideAssistanceButtonSupport: getRoadsideAssistanceTileButton(
    state,
    'support',
  ),
  pageContent: getPageContent(state, dashboardContentPath),
  user: getUser(state),
})

export const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
): DispatchProps => ({
  loadPageContent: () => {
    dispatch(new LoadContent({ endpoint: dashboardContentPath }))
  },
})

export { RoadSideAssistanceWithModals }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoadSideAssistanceWithModals)
