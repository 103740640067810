import { Card, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'

const CardStyled = styled(Card)`
  text-align: center;
`

export interface Props {
  headerTextLabel: string
  contentTextLabel: string
}

class NoRecords extends React.PureComponent<Props> {
  render() {
    const { children, headerTextLabel, contentTextLabel } = this.props

    return (
      <CardStyled
        elevation={0}
        backgroundColor="mist"
        border={true}
        stretch={true}
        data-e2e-id="no-records"
      >
        <Spacing pv={4} ph={8}>
          {children}
          <Headline variant="300" withMarginBottom>
            <Translation id={headerTextLabel} />
          </Headline>
          <Text>
            <Translation id={contentTextLabel} />
          </Text>
        </Spacing>
      </CardStyled>
    )
  }
}

export { NoRecords }
export default NoRecords
