import Classnames from 'classnames'
import React from 'react'
import {
  CSSTransition,
  Transition,
  TransitionGroup,
} from 'react-transition-group'

import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'

import TakeoverPrimary from './TakeoverPrimary'

export interface Props {
  onTransitionStateChange(transitionState: number): void
  hideLoginLink?: boolean
  contractId: string
  quoteId?: string
}

interface State {
  transitionState: number
}

class MobileTakeover extends React.PureComponent<Props, State> {
  state = {
    transitionState: Transition.UNMOUNTED,
  }

  setTransitionState = (transitionState: number) => {
    const { onTransitionStateChange } = this.props

    this.setState({ transitionState }, () =>
      onTransitionStateChange(transitionState),
    )
  }

  render() {
    const { contractId, hideLoginLink } = this.props
    const isTransitioning = [Transition.ENTERING, Transition.EXITING].includes(
      this.state.transitionState,
    )

    return (
      <TopNavigationContext.Consumer>
        {({ mobileTakeover: { active } }) => (
          <TransitionGroup
            className={Classnames('u-hide-from@lap', {
              'mobile-takeover__wrap--active': active,
              'mobile-takeover__wrap--transitioning': isTransitioning,
            })}
          >
            {active && (
              <CSSTransition
                onEnter={() => this.setTransitionState(Transition.ENTERING)}
                onEntered={() => this.setTransitionState(Transition.ENTERED)}
                onExit={() => this.setTransitionState(Transition.EXITING)}
                onExited={() => this.setTransitionState(Transition.EXITED)}
                classNames="transition-mobile-takeover"
                timeout={{ appear: 200, enter: 200, exit: 200 }}
                appear={true}
                enter={true}
                exit={true}
                unmountOnExit={true}
              >
                <div className="mobile-takeover">
                  <TakeoverPrimary
                    hideLoginLink={hideLoginLink}
                    contractId={contractId}
                  />
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </TopNavigationContext.Consumer>
    )
  }
}

export default MobileTakeover
