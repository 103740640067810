import {
  CheckboxCard,
  CheckedEvent,
  Grid,
  GridItem,
  RadioCard,
  Spacing,
} from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import {
  Point,
  Points,
} from 'mlp-client/src/form/components/car-point-select/types'
import {
  addSelectedValue,
  hasSelectedValue,
  removeSelectedValue,
} from 'mlp-client/src/form/utils'

export interface Props {
  handleChange(event: GenericObject): void
  points: Points
  multiSelect: boolean
  value: string
}

class PointViewList extends React.PureComponent<Props> {
  onChange = (event: CheckedEvent<string>): void => {
    const handleEvent = {
      target: {
        value: event.checked
          ? addSelectedValue(this.props.value, event.value)
          : removeSelectedValue(this.props.value, event.value),
      },
    }

    this.props.handleChange(handleEvent)
  }

  renderListBody = (label: string) => (
    <Grid justifyContent="space-around">
      <GridItem span="grow">
        <Headline variant="100">
          <Translation
            id={`myLeaseplan.components.carPointSelect.impact.${label}`}
          />
        </Headline>
      </GridItem>
    </Grid>
  )

  renderPointsCheckboxList = (point: Point) => (
    <CheckboxCard
      onChange={this.onChange}
      value={point.value}
      checked={this.getIsSelected(point.value)}
    >
      {this.renderListBody(point.label)}
    </CheckboxCard>
  )

  renderPointsRadioCardList = (point: Point) => (
    <RadioCard
      checked={this.props.value === point.value}
      onFocus={this.props.handleChange}
      value={point.value}
    >
      {this.renderListBody(point.label)}
    </RadioCard>
  )

  getIsSelected = (value: string) => hasSelectedValue(this.props.value, value)

  render() {
    const { points, multiSelect } = this.props

    return (
      <>
        {points
          .filter(point => point.showInList)
          .map(point => (
            <Spacing mb={1} key={`field-${point.value}`}>
              {multiSelect
                ? this.renderPointsCheckboxList(point)
                : this.renderPointsRadioCardList(point)}
            </Spacing>
          ))}
      </>
    )
  }
}

export { PointViewList }
export default PointViewList
