import { Button, ButtonChevronRightIcon, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { SubmitFuelCard } from 'mlp-client/src/flows/fuel-card/actions'
import {
  getFuelCardNames,
  getSelectedFuelCardLocation,
} from 'mlp-client/src/flows/fuel-card/selectors'
import { FlowData, StepKey } from 'mlp-client/src/flows/fuel-card/types'
import { Address } from 'mlp-client/src/user/types'
import { AppState } from 'mlp-client/src/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import { FormSubmission } from 'mlp-client/src/form/types'
import { getFormattedAddress } from 'mlp-client/src/utils/address/address'

export interface Props extends FlowProps<FlowData>, FormSubmission<FlowData> {
  cardNames: string
  address: Address
  config: {
    isLocationEditable: boolean
  }
}

export class CheckDetailsStep extends React.PureComponent<Props> {
  editStep = (stepName: string) => () => {
    const { goToStep } = this.props

    goToStep(stepName)
  }

  render() {
    const { address, flowData, cardNames, config, onSubmit } = this.props

    const editLabel = (
      <Translation id="myLeaseplan.fuelCard.steps.checkDetails.editButton" />
    )

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel="myLeaseplan.fuelCard.steps.checkDetails.subtitle">
                <FlowStepTile
                  title={
                    <Translation id="myLeaseplan.fuelCard.steps.selectFuelCard.title" />
                  }
                  dataE2eId="FuelCard"
                  btnLabel={editLabel}
                  onBtnClick={this.editStep(StepKey.SELECT_FUEL_CARD_TYPES)}
                >
                  {cardNames}
                </FlowStepTile>
                <FlowStepTile
                  title={
                    <Translation id="myLeaseplan.fuelCard.steps.selectServiceType.title" />
                  }
                  dataE2eId="ServiceType"
                  btnLabel={editLabel}
                  onBtnClick={this.editStep(StepKey.SELECT_SERVICE_TYPE)}
                >
                  <Translation
                    id={`myLeaseplan.fuelCard.types.${flowData.fuelCardServiceType}`}
                  />
                </FlowStepTile>
                <FlowStepTile
                  title={
                    <Translation
                      id={`myLeaseplan.fuelCard.steps.selectLocation.${address.type}`}
                    />
                  }
                  dataE2eId="location"
                  btnLabel={config.isLocationEditable && editLabel}
                  onBtnClick={this.editStep(StepKey.SELECT_LOCATION)}
                >
                  {getFormattedAddress(address)}
                </FlowStepTile>
                <Spacing mt={2}>
                  <SubmitStatus submitting={submitting} failed={submitFailed}>
                    <Button
                      onClick={handleSubmit}
                      disabled={submitting}
                      icon={ButtonChevronRightIcon}
                      stretch={true}
                      data-e2e-id="confirmationButton"
                    >
                      <Translation id="myLeaseplan.fuelCard.steps.checkDetails.nextButton" />
                    </Button>
                  </SubmitStatus>
                </Spacing>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

type ReduxProps = Pick<Props, 'address' | 'cardNames'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (
  state: AppState,
  { flowData }: OwnProps,
): ReduxProps => ({
  address: getSelectedFuelCardLocation(state),
  cardNames: getFuelCardNames(flowData.fuelCardTypes)(state),
})

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep } = props

    dispatch(
      new SubmitFuelCard({
        flowData,
        onComplete: error => {
          if (!error) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckDetailsStep)
