import { Attachment, Attachments } from 'mlp-client/src/types'
import { FileStatus } from 'mlp-client/src/form/components/fields/file-upload-field/enums'

export const isFileUploadSuccessful = (file: Attachment): boolean =>
  file.status === FileStatus.SUCCESS

export const filterFiles = (files: Attachments): Attachments =>
  files.filter(isFileUploadSuccessful)

export const isPdf = (file: Attachment): boolean => file.name.endsWith('.pdf')
