import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const FinesIconLeasys: FC<IconProps> = props => {
  FinesIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g fill={ColorEnumSelector(color)}>
        <path
          d="M3.31,33.71h38.73v-3.34c-3.8-0.49-6.75-3.75-6.75-7.68c0-3.94,2.95-7.19,6.75-7.69v-3.34H3.31v3.34
				c3.8,0.49,6.75,3.75,6.75,7.69c0,3.93-2.95,7.19-6.75,7.68V33.71z M43.04,35.71H2.31c-0.55,0-1-0.45-1-1v-5.28c0-0.55,0.45-1,1-1
				c3.17,0,5.75-2.58,5.75-5.75s-2.58-5.75-5.75-5.75c-0.55,0-1-0.45-1-1v-5.28c0-0.55,0.45-1,1-1h40.73c0.55,0,1,0.45,1,1v5.28
				c0,0.55-0.45,1-1,1c-3.17,0-5.75,2.58-5.75,5.75s2.58,5.75,5.75,5.75c0.55,0,1,0.45,1,1v5.28C44.04,35.26,43.6,35.71,43.04,35.71
				"
        />
        <path
          d="M27.92,16.1c0-0.55-0.45-1-1-1c-3.99,0-7.34,2.36-8.47,5.59h-2.93c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.53
				c-0.01,0.1-0.04,0.2-0.04,0.31c0,0.27,0.06,0.53,0.09,0.79h-1.65c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h2.16
				c1.28,2.98,4.5,5.1,8.3,5.1c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1c-2.62,0-4.87-1.27-6.04-3.1h0.7c0.55,0,1-0.45,1-1
				c0-0.55-0.45-1-1-1h-1.47c-0.04-0.26-0.09-0.52-0.09-0.79c0-0.11,0.03-0.2,0.04-0.31h3.08c0.55,0,1-0.45,1-1s-0.45-1-1-1h-2.57
				c1.05-2.11,3.5-3.59,6.35-3.59C27.47,17.1,27.92,16.66,27.92,16.1"
        />
      </g>
    </svg>
  )
}

export default FinesIconLeasys
