import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RouteComponentProps, withRouter, match } from 'react-router'
import { compose } from 'redux'

import Loader from 'mlp-client/src/components/loader/Loader'
import { LoadContracts } from 'mlp-client/src/contracts/actions'
import { Contract, Contracts } from 'mlp-client/src/contracts/types'
import {
  getContracts,
  isContractsLoaded,
  isContractsLoading,
} from 'mlp-client/src/contracts/selectors'
import withUser, { UserProps } from 'mlp-client/src/user/components/withUser'
import { AppState } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import SomethingWentWrongPage from 'mlp-client/src/components/something-went-wrong/SomethingWentWrongPage'

type UserContracts = readonly Contract[]

interface ContractProps {
  contracts: UserContracts
}

interface MatchProps {
  contractId: string
}

export interface Props extends UserProps, ContractProps, RouteComponentProps {
  isContractsLoading: boolean
  isContractsLoaded: boolean
  fetchUserContracts(selectedContractId: string): void
  children(contracts: UserContracts): React.ReactNode
  match: match<MatchProps>
}

export class LoadContractsContainer extends React.PureComponent<Props> {
  private get shouldFetchContracts(): boolean {
    const { isContractsLoading, isContractsLoaded } = this.props

    return !isContractsLoading && !isContractsLoaded
  }

  loadContracts = (forceContractUpdate?: boolean) => {
    const {
      fetchUserContracts,
      location: { search },
    } = this.props

    if (this.shouldFetchContracts || forceContractUpdate) {
      const query = new URLSearchParams(search)
      const contractId = query.get('contractId')

      fetchUserContracts(contractId)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isContractsLoaded, user } = this.props
    const shouldReloadContracts =
      prevProps.isContractsLoaded && !isContractsLoaded
    const isUserChanged = prevProps.user.id !== user.id

    if (shouldReloadContracts || isUserChanged) {
      this.loadContracts(true)
    }
  }

  render() {
    const {
      isContractsLoaded,
      children,
      isContractsLoading,
      contracts,
    } = this.props

    if (isContractsLoading || !isContractsLoaded) {
      return <Loader loading={true} fixed={true} />
    }

    if (contracts.length === 0) {
      return (
        <MyLeaseplanContext.Consumer>
          {config =>
            config.contracts?.configuration?.showErrorIfEmptyContracts ? (
              <SomethingWentWrongPage />
            ) : (
              children(contracts)
            )
          }
        </MyLeaseplanContext.Consumer>
      )
    }

    return children(contracts)
  }
}

type ReduxProps = Pick<
  Props,
  'contracts' | 'isContractsLoading' | 'isContractsLoaded'
>
type DispatchProps = Pick<Props, 'fetchUserContracts'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isContractsLoading,
  isContractsLoaded,
  contracts: getContracts,
})

const mapDispatchToProps: DispatchProps = {
  fetchUserContracts: selectedContractId =>
    new LoadContracts({ selectedContractId }),
}

const ContractsProvider = compose(
  withUser,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LoadContractsContainer)

export default <T extends Partial<ContractProps>>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const withContracts: React.SFC<Omit<T, keyof ContractProps>> = props => (
    <ContractsProvider>
      {(contracts: Contracts) => (
        <ProtectedComponent {...(props as T)} contracts={contracts} />
      )}
    </ContractsProvider>
  )

  withContracts.displayName = `withContracts(${
    ProtectedComponent.displayName || ProtectedComponent.name
  })`

  return withContracts
}
