import { BookingResult } from 'mlp-client/src/types'
import {
  FlowData,
  RepairMaintenanceDataResponse,
} from 'mlp-client/src/flows/maintenance/types'

export const enum ActionType {
  SET_MAINTENANCE_BOOKING_RESULT = '@@myLeaseplan/maintenance/lpis/SET_MAINTENANCE_BOOKING_RESULT',
  LOAD_REPAIR_MAINTENANCE_DATA = '@@myLeaseplan/maintenance/lpis/LOAD_REPAIR_MAINTENANCE_DATA',
  LOAD_REPAIR_MAINTENANCE_DATA_SUCCESS = '@@myLeaseplan/maintenance/lpis/LOAD_REPAIR_MAINTENANCE_DATA_SUCCESS',
  SUBMIT_MAINTENANCE_BOOKING = '@@myLeaseplan/maintenance/lpis/SUBMIT_MAINTENANCE_BOOKING',
}

export class SetMaintenanceBookingResult {
  readonly type = ActionType.SET_MAINTENANCE_BOOKING_RESULT

  constructor(public payload: { data: { bookingResult: BookingResult } }) {}
}

export class LoadRepairMaintenanceData {
  readonly type = ActionType.LOAD_REPAIR_MAINTENANCE_DATA

  constructor(
    public payload: {
      flowData: FlowData
    },
  ) {}
}

export class LoadRepairMaintenanceDataSuccess {
  readonly type = ActionType.LOAD_REPAIR_MAINTENANCE_DATA_SUCCESS

  constructor(public payload: { data: RepairMaintenanceDataResponse }) {}
}

export class SubmitMaintenanceBooking {
  readonly type = ActionType.SUBMIT_MAINTENANCE_BOOKING

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(error: boolean): void
    },
  ) {}
}

export type Action =
  | SetMaintenanceBookingResult
  | LoadRepairMaintenanceData
  | LoadRepairMaintenanceDataSuccess
  | SubmitMaintenanceBooking
