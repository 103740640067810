import { Action, ActionTypes } from './actions'

export interface DriverDetailsState {
  licensePlateExists: boolean
  isFetching: boolean
}

export const defaultState: DriverDetailsState = {
  licensePlateExists: null,
  isFetching: false,
}

const driverDetailsReducer = (
  state: DriverDetailsState = defaultState,
  action: Action,
): DriverDetailsState => {
  switch (action.type) {
    case ActionTypes.VALIDATE_LICENSE_PLATE:
      return {
        ...state,
        isFetching: true,
      }
    case ActionTypes.VALIDATE_LICENSE_PLATE_SUCCESS:
      if (action.payload === null) {
        return {
          licensePlateExists: null,
          isFetching: false,
        }
      }

      return {
        isFetching: false,
        licensePlateExists: action.payload,
      }
    default:
      return null
  }
}

export default driverDetailsReducer
