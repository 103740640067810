import { ButtonChevronRightIcon, Spacing } from '@leaseplan/ui'
import moment from 'moment'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import BookingCancellationModal from 'mlp-client/src/timeline-details/components/BookingCancellationModal/BookingCancellationModal'
import { getCancellationAvailability } from 'mlp-client/src/timeline-details/utils'
import { StyledButtonOutlined } from 'mlp-client/src/components/button/StyledButton'

export interface Props {
  dropOffDate: string
  caseId: string
  status: string
}

class CancellationButton extends React.PureComponent<Props> {
  render() {
    const { dropOffDate, caseId, status } = this.props
    const isCancellationAvailable = getCancellationAvailability({
      status,
      bookingDate: dropOffDate,
    })

    return (
      isCancellationAvailable && (
        <>
          <Spacing m={1}>
            <Translation
              id="myLeaseplan.timeline.maintenance.detail.statusContent.canChange.youCanMakeChangesUntil"
              replace={{ date: moment.parseZone(dropOffDate).format('LLLL') }}
            />
          </Spacing>

          <BookingCancellationModal caseId={caseId} bookingDate={dropOffDate}>
            <StyledButtonOutlined
              variant="secondary"
              stretch={true}
              icon={ButtonChevronRightIcon}
              data-e2e-id="bookingDetailsForm_cancelButton"
            >
              <Translation id="myLeaseplan.timeline.bookingMaintenance.items.cancel" />
            </StyledButtonOutlined>
          </BookingCancellationModal>
        </>
      )
    )
  }
}

export { CancellationButton }
export default CancellationButton
