import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { Contract } from 'mlp-client/src/contracts/types'
import { formatDate, makeTranslationPath } from 'mlp-client/src/utils'
import { getCurrentTimeLineItem } from 'mlp-client/src/order-status/utils'
import { timeLineItemsConfig } from 'mlp-client/src/order-status/options'
import { getShortMakeModel } from 'mlp-client/src/contracts/utils'
import { TableOrderVehicleData } from 'mlp-client/src/components/overview-table/types'
import MakeModelCell from 'mlp-client/src/components/overview-table/MakeModelCell'

import TitleCell from './TitleCell'
import SubtitleCell from './SubtitleCell'

const translatePath = makeTranslationPath('myLeaseplan.orderCars.labels')

export const tableColumns = [
  {
    header: <Translation id={translatePath('model')} />,
    key: 'makeModel',
    cell: MakeModelCell,
  },
  {
    header: <Translation id={translatePath('deliveryDate')} />,
    key: 'expectedDelivery',
  },
  {
    header: <Translation id={translatePath('status')} />,
    key: 'title',
    cell: TitleCell,
  },
  {
    header: <Translation id={translatePath('details')} />,
    key: 'subtitle',
    cell: SubtitleCell,
  },
]

export const getData = (
  contract: Contract,
  isFrenchLocale: boolean,
): TableOrderVehicleData => {
  const { id, orderStatus, estimatedDeliveryDate, vehicle } = contract
  const timeLineItem = getCurrentTimeLineItem(
    timeLineItemsConfig(id, null, isFrenchLocale),
    orderStatus,
  )

  if (!timeLineItem) {
    return null
  }

  const SubtitleComponent = timeLineItem.subtitle
  const shortModel = getShortMakeModel(contract)

  return {
    id,
    orderStatus,
    title: timeLineItem.title,
    subtitle: <SubtitleComponent />,
    makeModel: vehicle.model || vehicle.make ? shortModel : '-',
    expectedDelivery: estimatedDeliveryDate
      ? formatDate(estimatedDeliveryDate, 'LL')
      : '-',
  }
}
