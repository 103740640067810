import React from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

interface DefaultProps {
  width: string
  height: string
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

class MapsMarkerIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    width: '30',
    height: '42',
  }

  render() {
    const { height, width } = this.props as PropsWithDefaults

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 42"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <linearGradient
            x1="39.281%"
            y1="50.013%"
            x2="161.133%"
            y2="50.013%"
            id="linearGradient-1"
          >
            <stop stopColor={ColorEnumSelector('midOrange')} offset="0%" />
            <stop stopColor={ColorEnumSelector('midOrange')} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="linearGradient-2"
          >
            <stop stopColor="#FAD961" offset="0%" />
            <stop stopColor="#F76B1C" offset="100%" />
          </linearGradient>
          <radialGradient
            fx="50%"
            fy="50%"
            r="72.065%"
            gradientTransform="rotate(68.424 .5 .5)"
            id="radialGradient-3"
          >
            <stop stopColor="#FFF" stopOpacity=".5" offset="0%" />
            <stop stopOpacity=".5" offset="100%" />
          </radialGradient>
          <path
            d="M15,6.00002 C20.081,6.00002 24.2,10.11902 24.2,15.20002 C24.2,20.28102 20.081,24.40002 15,24.40002 C9.919,24.40002 5.8,20.28102 5.8,15.20002 C5.8,10.11902 9.919,6.00002 15,6.00002"
            id="path-4"
          />
        </defs>
        <g id="Flow---Tyre-Change-✔️" fill="none" fillRule="evenodd">
          <g
            id="Tyre-Change/desktop/1.6.1-Supplier-Detail"
            transform="translate(-465 -396)"
          >
            <g id="modal" transform="translate(140 166)">
              <g id="map">
                <g id="pointer" transform="translate(325 230)">
                  <path
                    d="M15,0 C23.2842,0 30,5.9916 30,15 C30,24.3722 15,41.2 15,41.2 C15,41.2 0,24.3868 0,15 C0,5.9914 6.7156,0 15,0"
                    id="Fill-1"
                    fill="url(#linearGradient-1)"
                  />
                  <path
                    d="M15.00002,0 L15.00002,41.2 C15.00002,41.2 1.99999999e-05,24.3868 1.99999999e-05,15 C1.99999999e-05,5.9914 6.71562,0 15.00002,0"
                    id="Fill-1"
                    fillOpacity=".3"
                    fill="url(#linearGradient-2)"
                    style={{
                      mixBlendMode: 'multiply',
                    }}
                  />
                  <g id="Page-1">
                    <use fill="url(#linearGradient-1)" xlinkHref="#path-4" />
                    <use
                      fillOpacity=".181"
                      fill="#FFF"
                      style={{ mixBlendMode: 'lighten' }}
                      xlinkHref="#path-4"
                    />
                    <use
                      fill="url(#radialGradient-3)"
                      style={{ mixBlendMode: 'overlay' }}
                      xlinkHref="#path-4"
                    />
                  </g>
                  <path
                    d="M15.04,8.8 C18.5966,8.8 21.48,11.6834 21.48,15.24 C21.48,18.7968 18.5966,21.68 15.04,21.68 C11.4834,21.68 8.6,18.7968 8.6,15.24 C8.6,11.6834 11.4834,8.8 15.04,8.8"
                    id="Fill-1"
                    fill="#FFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default MapsMarkerIcon
