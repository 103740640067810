import moment from 'moment'

import {
  DamageCauses,
  DamageNatureTypes,
  FlowData,
  IframeSrc,
  impactPoints,
  ImpactPointValues,
  PartyDamages,
  SubmitRequestBody,
  ThirdPartyInfo,
  WhoseFault,
} from 'mlp-client/src/flows/damage-report/types'
import { Locales } from 'mlp-client/src/localization/enums'
import { DamageRecord } from 'mlp-client/src/contracts/types'
import { DamageRecordStatus } from 'mlp-client/src/contracts/enums'
import { Options } from 'mlp-client/src/form/types'
import { Time } from 'mlp-client/src/form/components/fields/time-input-field/types'

export const getIframeSrcFromConfig = (config: IframeSrc, locale: Locales) =>
  config?.[locale]

export const isAmbiguousType = (
  value: string,
  damageNatureTypes: DamageNatureTypes,
): boolean => {
  const type = damageNatureTypes.find(type => value === type.name)

  return type?.isFirstParty && type?.isThirdParty
}

export const getDamageReportTypesKey = (typeName: string): string => {
  // @TODO find proper solution.
  // Some keys have `/` and we can't add this in sitecore.
  const damageReportType = typeName.replace(/\//g, '')

  return `myLeaseplan.damageReport.types.${damageReportType}`
}

const otherPointValues = impactPoints
  .filter(point => point.showInList)
  .map(point => point.value)

export const filterOtherImpactPoints = (
  points: ImpactPointValues[],
): ImpactPointValues[] =>
  points.filter(point => otherPointValues.includes(point))

export const filterDamagePoints = (
  points: ImpactPointValues[],
): ImpactPointValues[] =>
  points.filter(point => !otherPointValues.includes(point))

export const mapDamageReportRequestBody = (
  contractId: string,
  {
    selectWhatHappened,
    damageNatureType,
    whoseFault,
    europeanAccidentStatement,
    picture,
    description,
    damageCause,
    where,
    firstImpactPoint,
    damagePoints,
    policeStationName,
    thirdPartyInfo,
    date,
    time,
  }: FlowData,
): SubmitRequestBody => {
  const thirdPartyInvolvement =
    damageCause && damageCause === DamageCauses.TWO_PARTY
  const isDriverFault = whoseFault && whoseFault === WhoseFault.MY_FAULT

  return {
    incidentNature: damageNatureType || selectWhatHappened,
    contractId,
    isDriverFault,
    attachmentKeys: [
      ...europeanAccidentStatement.map(attachment => attachment.key),
      ...picture.map(attachment => attachment.key),
    ].filter(Boolean),
    description,
    incidentDateTime: mapIncidentDateTime(date, time).toISOString(true),
    thirdPartyInvolvement,
    locationDescription: where,
    postCode: '',
    initialImpactPoints: [firstImpactPoint],
    damagePoints: filterDamagePoints(
      damagePoints.split(',') as ImpactPointValues[],
    ),
    otherImpactPoints: filterOtherImpactPoints(
      damagePoints.split(',') as ImpactPointValues[],
    ),
    policeStationName,
    thirdPartyInfo:
      !thirdPartyInfo || isThirdPartyInfoEmpty(thirdPartyInfo)
        ? undefined
        : thirdPartyInfo,
  }
}

export const mapIncidentDateTime = (date: Date, { hours, minutes }: Time) => {
  const momentDate = moment(date)

  momentDate.set({
    minute: parseInt(minutes, 10),
    hour: parseInt(hours, 10),
    second: 0,
    millisecond: 0,
  })

  return momentDate
}

export const sortDamageRecords = (a: DamageRecord, b: DamageRecord): number => {
  if (
    (a.status !== b.status && b.status === DamageRecordStatus.CLOSED) ||
    (a.status === b.status && moment(a.date).isAfter(b.date))
  ) {
    return -1
  }

  if (
    (a.status !== b.status && a.status === DamageRecordStatus.CLOSED) ||
    moment(a.date).isBefore(b.date)
  ) {
    return 1
  }

  return 0
}

export const mapPartyDamagesToOptions = (
  partyDamages: PartyDamages = [],
  selectedCause: DamageCauses,
): Options =>
  partyDamages.filter(({ damageCause }) => damageCause === selectedCause)

export const isThirdPartyInfoEmpty = (
  thirdPartyInfo: ThirdPartyInfo,
): boolean =>
  Boolean(
    Object.keys(thirdPartyInfo).filter(key => Boolean(thirdPartyInfo[key]))
      .length === 0,
  )
