import { Hide } from '@leaseplan/ui'
import React from 'react'

import {
  CellphoneHandIcon,
  CellphoneIcon,
} from 'mlp-client/src/components/icons'

export interface Props {
  mobIcon?: any
  desktopIcon?: any
}

type DefaultProps = Required<Pick<Props, 'mobIcon' | 'desktopIcon'>>

class BannerIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    mobIcon: CellphoneHandIcon,
    desktopIcon: CellphoneIcon,
  }

  renderIcon = (Icon: any, width: string, height: string) => () => (
    <Icon width={width} height={height} />
  )

  render() {
    const { desktopIcon, mobIcon } = this.props

    return (
      <>
        <Hide
          until="desktop"
          implementation="js"
          component={this.renderIcon(desktopIcon, '36', '36')}
        />
        <Hide
          from="desktop"
          implementation="js"
          component={this.renderIcon(mobIcon, '128', '128')}
        />
      </>
    )
  }
}

export { BannerIcon }
export default BannerIcon
