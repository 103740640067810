import styled from 'styled-components'

export const ContentLayout = styled.div`
  width: 100%;
  max-width: 1278px;
  padding-right: 24px;
  padding-left: 24px;
  margin-left: auto;
  margin-right: auto;
`

export const MainContentWrapper = styled(ContentLayout)`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
`
