import * as actions from 'mlp-client/src/FAQ/actions'
import { FAQItemType } from 'mlp-client/src/FAQ/types'

export interface State {
  [key: string]: readonly FAQItemType[]
}

const defaultState: State = {}

const fetchFAQSuccess = (state: State, action: actions.FetchFAQSuccess) => ({
  ...state,
  ...action.payload,
})

const FAQReducer = (
  state: State = defaultState,
  action: actions.Action,
): State => {
  if (action.type === actions.ActionTypes.FETCH_FAQ_SUCCESS) {
    return fetchFAQSuccess(state, action)
  } else {
    return state
  }
}

export default FAQReducer
