import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

class OrderAtADealer extends React.PureComponent {
  render() {
    return (
      <Translation id="myLeaseplan.genericNotifications.statusCarService.dealerDateInfo.subtitle" />
    )
  }
}

export { OrderAtADealer }
export default OrderAtADealer
