import moment from 'moment'

import { Attachments } from 'mlp-client/src/types'

import {
  FlowData,
  MappedCashClaimErrors,
  SubmittingFlowData,
  CashClaimErrors,
} from './types'

export const getMappedCashClaimErrors = (
  cashClaimErrors: CashClaimErrors = [],
): MappedCashClaimErrors | undefined => {
  if (cashClaimErrors.length === 0) {
    return
  }

  const validationErrors: MappedCashClaimErrors = {}

  cashClaimErrors.forEach(error => {
    validationErrors[error.field] = true
  })

  return validationErrors
}

export const leaveOnlyNumbers = (value: string): string => {
  const onlyNumbers = value.match(/\d+/g)

  return onlyNumbers ? onlyNumbers.join('') : ''
}

export const addSpace = (value: string): string =>
  value.length > 4 ? value.slice(0, 4) + ' ' + value.slice(4) : value

export const formatAccountNumberForTextField = (
  accountNumber: string,
): string =>
  Boolean(accountNumber) ? addSpace(leaveOnlyNumbers(accountNumber)) : ''

export const formatAccountNumberForRequestBody = (
  accountNumber: string,
): string => accountNumber && leaveOnlyNumbers(accountNumber).padStart(14, '0')

export const getSubmitCashClaimRequestBody = (
  flowData: FlowData,
): SubmittingFlowData => ({
  amount: Number(flowData.amount),
  currencyIsoCode: flowData.currency,
  ibanNumber: flowData.iban,
  accountNumber: formatAccountNumberForRequestBody(flowData.accountNumber),
  bic: flowData.bic,
  detailedMessage: flowData.reason,
  accountHolderName: flowData.owner,
  attachmentKeys: getAttachmentKeys(flowData.receipt as Attachments),
  purchaseDate: getDateWithZeroTime(flowData.date),
  type: flowData.category,
  typeValue: flowData.subCategory,
})

export const getDateWithZeroTime = (date: Date): Date =>
  moment(date).utc().startOf('day').toDate()

export const getAttachmentKeys = (receipt: Attachments): readonly string[] =>
  receipt.map(attachment => attachment.key)
