import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { Contract } from 'mlp-client/src/contracts/types'
import {
  getEndDateOfContract,
  getLastKnownMileage,
  getMaximumAllowedMileage,
  mapContractToVehicle,
} from 'mlp-client/src/contracts/utils'
import { makeTranslationPath, formatDate } from 'mlp-client/src/utils'
import MakeModelCell from 'mlp-client/src/components/overview-table/MakeModelCell'
import { TableLeaseVehicleData } from 'mlp-client/src/components/overview-table/types'

import LicenceCell from './LicenceCell'
import MileageCell from './MileageCell'

const translatePath = makeTranslationPath('myLeaseplan.multipleCars.labels')

export const tableColumns = [
  {
    header: <Translation id={translatePath('model')} />,
    key: 'makeModel',
    cell: MakeModelCell,
  },
  {
    header: <Translation id={translatePath('licence')} />,
    key: 'licence',
    cell: LicenceCell,
  },
  {
    header: <Translation id={translatePath('mileage')} />,
    key: 'mileage',
    cell: MileageCell,
  },
  {
    header: <Translation id={translatePath('contract')} />,
    key: 'contract',
  },
  {
    header: <Translation id={translatePath('startDate')} />,
    key: 'startDate',
  },
  {
    header: <Translation id={translatePath('endDate')} />,
    key: 'endDate',
  },
]

export const getData = (contract: Contract): TableLeaseVehicleData => {
  const vehicle = mapContractToVehicle(contract)
  const lastKnownMileage = getLastKnownMileage(contract)
  const maximumAllowedMileage = getMaximumAllowedMileage(contract)
  const endDate = getEndDateOfContract(contract)

  return {
    contract: contract.id,
    id: contract.id,
    licence: vehicle.licensePlate ? vehicle.licensePlate : null,
    makeModel: vehicle.model || '-',
    mileage: lastKnownMileage ? lastKnownMileage : null,
    maximumAllowedMileage: maximumAllowedMileage ? maximumAllowedMileage : null,
    startDate: contract.startDate ? formatDate(contract.startDate, 'LL') : null,
    endDate: endDate ? formatDate(endDate, 'LL') : null,
  }
}
