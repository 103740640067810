import React, { useState } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { validateString } from 'mlp-client/src/form//utils'
import {
  PointSelectOption,
  PointSelectOptions,
  StringValidations,
} from 'mlp-client/src/form/types'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

/**
 * In non-production environments this currently triggers a console warning:
 * "You must pass type="radio" prop to your Field(tyres) component.
 * Without it we don't know how to unpack your value prop - "undefined"."
 * This is an error in react-final form, as the form works perfectly. Also,
 * see: https://github.com/final-form/react-final-form/issues/392
 */

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${(props: { name: string }) =>
    props.name === 'tyres' ? '280px' : '465px'};
`

const CarImage = styled.img`
  width: 100%;
  height: auto;
  user-select: none;
`

const StyledLabel = styled.label`
  position: absolute;
  cursor: pointer;

  ${(props: Partial<PointSelectOption> & { name: string }) => ({
    transform: `translate(${(props.centeredX && '-50%') || '0'}, ${
      (props.centeredY && '-50%') || '0'
    })`,
    top: props.top,
    right: props.right,
    bottom: props.bottom,
    left: props.left,
    width: props.name === 'tyres' ? '21%' : '13%',
  })}
`

const StyledPoint = styled.svg`
  display: block;
  width: 100%;
  height: auto;
  max-height: 60px;

  rect {
    stroke: ${ColorEnumSelector('lightOrange')};
    stroke-width: 3px;

    ${(props: { checked: boolean }) =>
      props.checked && `fill: ${ColorEnumSelector('lightOrange')}`}
  }
`

const StyledRadio = styled.input`
  position: absolute;
  visibility: hidden;
`

export default ({
  name,
  validations,
  options,
}: {
  name: string
  validations: StringValidations
  options: PointSelectOptions
}) => {
  const image = '/static/images/my-leaseplan/tyre-points-bg.png'

  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <LocalizationContext.Consumer>
      {() => (
        <Field name={name} validate={validateString(validations)}>
          {({ input }) => (
            <Container name={name}>
              <CarImage src={image} onLoad={() => setImageLoaded(true)} />
              {imageLoaded &&
                options.map((option: PointSelectOption) => (
                  <StyledLabel
                    key={option.value}
                    top={option.top}
                    right={option.right}
                    bottom={option.bottom}
                    left={option.left}
                    centeredX={option.centeredX}
                    centeredY={option.centeredY}
                    name={name}
                  >
                    <StyledPoint
                      checked={input.value === option.value}
                      viewBox="0 0 60 60"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="359.5"
                        y="7.5"
                        width="57"
                        height="57"
                        rx="28.5"
                        transform="translate(-358 -6)"
                        fill="none"
                        fillRule="evenodd"
                      />
                    </StyledPoint>
                    <StyledRadio {...input} value={option.value} type="radio" />
                  </StyledLabel>
                ))}
            </Container>
          )}
        </Field>
      )}
    </LocalizationContext.Consumer>
  )
}
