import { camelCase } from 'camel-case'
import moment from 'moment'

import {
  ReadableCashClaimStatus,
  TimelineItemStatus,
  ServiceTypes,
  TimelineEventTypes,
} from 'mlp-client/src/timeline/enums'
import {
  TimelineCardContent,
  TimelineItem,
  TimelineItemData,
} from 'mlp-client/src/timeline/types'
import { formatDate, formatRelativeDate } from 'mlp-client/src/utils'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

export const getColorFromStatus = (status: string): string => {
  switch (camelCase(status)) {
    case TimelineItemStatus.NEW:
      return ColorEnumSelector('midOrange')
    case TimelineItemStatus.DECLINED:
    case TimelineItemStatus.CANCELLED:
    case TimelineItemStatus.OPEN:
    case ReadableCashClaimStatus.CANCELLED:
      return ColorEnumSelector('bloodOrange')
    case TimelineItemStatus.ARCHIVED:
    case TimelineItemStatus.CONFIRMED:
    case TimelineItemStatus.ACCEPTED:
    case TimelineItemStatus.SEND:
    case TimelineItemStatus.CREATED:
    case TimelineItemStatus.PAID:
    case TimelineItemStatus.CLOSED:
    case ReadableCashClaimStatus.ACCEPTED:
    case ReadableCashClaimStatus.TO_BE_PAID:
      return ColorEnumSelector('grassGreen')
    case TimelineItemStatus.PROCESSING:
    case TimelineItemStatus.UNCONFIRMED:
    case TimelineItemStatus.AWAITING_CONFIRMATION:
    case ReadableCashClaimStatus.ALLOW_CANCELLATION:
    case ReadableCashClaimStatus.IN_PROGRESS:
      if (!isLeaseplan()) {
        return ColorEnumSelector('grey')
      }

      return ColorEnumSelector('petrolBlue')
    default:
      return ColorEnumSelector('steel')
  }
}

export const getCardContent = (
  data: TimelineItemData,
): TimelineCardContent => ({
  caseId: data.caseId,
  type: data.type,
  showLeftInfo: data.showLeftInfo,
  eventDetails: data.eventDetails,
  vehicle: data.vehicle,
  status: data.status,
  processPhase: data.processPhase,
  modifiedDate: formatRelativeDate(data.modifiedDate),
  bookingDate: formatDate(data?.eventDetails?.bookingDate || data?.createdDate),
  childCases: data.childCases,
})

export const getTimelineEventType = (
  timelineItem: TimelineItem,
): TimelineEventTypes => {
  if (
    timelineItem.type === TimelineEventTypes.BOOKING &&
    timelineItem.eventDetails
  ) {
    if (timelineItem.eventDetails.glassDamageType) {
      return TimelineEventTypes.GLASS_DAMAGE
    }

    if (timelineItem.eventDetails.serviceType === ServiceTypes.TYRE) {
      return TimelineEventTypes.TYRE_BOOKING
    }
  }

  return timelineItem.type
}

export const formatPickupDate = (pickupDate: string): string =>
  moment(pickupDate).format('LLLL')

export const formatProcessPhase = (processPhase: string) => {
  if (processPhase.includes('-')) {
    const stringArr = processPhase.split('-')
    const processPhaseStrip = stringArr[1]

    if (processPhaseStrip.indexOf(' ') === 0) {
      return processPhaseStrip.replace(/\s/, '')
    }

    return processPhaseStrip
  }

  return processPhase
}

export const extractNumber = (processPhase: string) => {
  if (processPhase.includes('-')) {
    const stringArr = processPhase.split('-')

    return stringArr[0].replace(/\s/g, '')
  }

  return processPhase
}

const reportDamagePhases = {
  1: 'envoyer',
  2: 'attente info',
  3: 'attende nols',
  4: 'obtenir constat',
  5: 'demande',
  6: 'closed',
  7: 'constat original',
}

const possibleSubcategoryValues = {
  nonImmobilized: 'non immobilized',
  immobilized: 'immobilized',
  reportDamage: 'report damage',
}

export const getReportDamagePhase = (reportDamagePhase: string) =>
  Object.keys(reportDamagePhases).find(key =>
    reportDamagePhase?.toLowerCase().includes(reportDamagePhases[key]),
  ) || reportDamagePhase

export const caseSubcategorySelector = (subcategoryName: string): string =>
  Object.keys(possibleSubcategoryValues).find(key =>
    subcategoryName?.toLowerCase().includes(possibleSubcategoryValues[key]),
  ) || subcategoryName
