import { Button, RadioList, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import { MessageBar } from '@velocity/ui/draft'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import Loader from 'mlp-client/src/components/loader/Loader'
import { getContractById } from 'mlp-client/src/contracts/selectors'
import { getLastKnownMileage } from 'mlp-client/src/contracts/utils'
import { DeletePredictiveMaintenanceCaseRequest } from 'mlp-client/src/flows/predictive-maintenance/actions'
import { CancellationReasons } from 'mlp-client/src/flows/predictive-maintenance/types'
import MileageField from 'mlp-client/src/form/components/fields/mileage-field/Mileage'
import { RadioField } from 'mlp-client/src/form/components/fields/RadioFields'
import { TranslationType } from 'mlp-client/src/form/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'

interface FormValues {
  reason: CancellationReasons
  mileage?: string
}

export interface Props {
  caseId: string
  contractId: string
  lastKnownMileage: number
  onSubmit(
    data: FormValues & {
      onComplete(error: boolean): void
    },
  ): void
  onFinished(): void
}

const REQUIRED_MESSAGE = { id: 'myLeaseplan.shared.requiredMessage' }

const translatePath = makeTranslationPath(
  'myLeaseplan.predictiveMaintenance.updateMileage.noThanks',
)

export const PredictiveMaintenanceRejection: React.FC<Props> = ({
  lastKnownMileage,
  onSubmit,
  onFinished,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const handleRequestComplete = (isError: boolean) => {
    setIsSubmitting(false)
    setIsError(isError)

    if (!isError) {
      onFinished()
    }
  }

  const handleSubmit = (values: FormValues) => {
    setIsSubmitting(true)
    onSubmit({ ...values, onComplete: handleRequestComplete })
  }

  const validate = (values: FormValues) => {
    const errors: Partial<{ [key in keyof FormValues]: TranslationType }> = {}

    if (!values.reason) {
      errors.reason = REQUIRED_MESSAGE
    }

    if (!values.mileage) {
      errors.mileage = REQUIRED_MESSAGE
    }

    return errors
  }

  return (
    <Spacing>
      <Loader loading={isSubmitting} />
      <Headline component="h2" variant="400" withMarginBottom>
        <Translation id={translatePath('title')} />
      </Headline>
      <Text variant="200">
        <Translation id={translatePath('description')} />
      </Text>
      {isError && (
        <SMessageBarContainer>
          <MessageBar
            variant="alert"
            data-e2e-id="predictiveMileageRejectionErrorMessage"
          >
            <MessageBar.Text>
              <Translation id="myLeaseplan.shared.formSubmitFailedMessage" />
            </MessageBar.Text>
          </MessageBar>
        </SMessageBarContainer>
      )}
      <Form
        onSubmit={handleSubmit}
        data-e2e-id="predictiveMileageRejectionForm"
        validate={validate}
      >
        {formProps => (
          <form onSubmit={formProps.handleSubmit}>
            <Spacing mt={1} mb={2}>
              <RadioList>
                <RadioField
                  name="reason"
                  value={CancellationReasons.tooEarly}
                  label={{
                    id: translatePath('tooEarly'),
                  }}
                  disabled={isSubmitting}
                />
                <RadioField
                  name="reason"
                  value={CancellationReasons.alreadyBooked}
                  label={{
                    id: translatePath('alreadyBooked'),
                  }}
                  disabled={isSubmitting}
                />
                <Spacing mt={0.25} ml={2.5}>
                  <MileageField
                    name="mileage"
                    variant="secondary"
                    allowOnlyIntegers={true}
                    lastKnownMileage={lastKnownMileage}
                  />
                </Spacing>
              </RadioList>
            </Spacing>
            <Button
              type="submit"
              disabled={
                isSubmitting ||
                formProps.submitting ||
                formProps.pristine ||
                formProps.hasValidationErrors
              }
            >
              <Translation id="myLeaseplan.shared.input.submit.label" />
            </Button>
          </form>
        )}
      </Form>
    </Spacing>
  )
}

type ReduxProps = Pick<Props, 'lastKnownMileage'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, ownProps: OwnProps): ReduxProps => {
  const contract = getContractById(state)(ownProps.contractId)

  return {
    lastKnownMileage: getLastKnownMileage(contract),
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  ownProps: OwnProps,
): DispatchProps => ({
  onSubmit: ({ reason, mileage, onComplete }) =>
    dispatch(
      new DeletePredictiveMaintenanceCaseRequest({
        cancellationReason: reason,
        mileage,
        caseId: ownProps.caseId,
        onComplete,
      }),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PredictiveMaintenanceRejection)
