import { Text } from '@velocity/ui'
import React, { ReactElement } from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'

interface Props {
  message?: string
}

const SpecialInstructions: React.FC<Props> = ({ message }): ReactElement =>
  message ? (
    <FlowStepTile
      title={
        <Translation
          id="myLeaseplan.maintenance.steps.confirmBooking.messageTitle"
          data-e2e-id="bookingDetailsForm_messageTitle"
        />
      }
    >
      <Text variant="200">{message}</Text>
    </FlowStepTile>
  ) : null

export { SpecialInstructions }
export default SpecialInstructions
