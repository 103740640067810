import _get from 'lodash/get'
import { createSelector } from 'reselect'

import { getDocuments } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import { AppState } from 'mlp-client/src/types'

const DOCUMENT_NAME_KEY = 'EUACCIDENTSTATEMENT'

export const getEuAccidentData = (state: AppState) => {
  const documents = getDocuments(state)
  const document = documents.filter(document => {
    const documentType = _get(document, 'DocumentType', {})
    const documentName = _get(document, '_Name', '').toUpperCase()

    return (
      _get(documentType, 'Name', '').toUpperCase() === DOCUMENT_NAME_KEY ||
      documentName === DOCUMENT_NAME_KEY
    )
  })

  return {
    vehicleDocuments: documents,
    formDownloadLink: _get(document, [0, 'DownloadUrl'], ''),
  }
}

export const getDamageReport = (state: AppState) => state.damageReport

export const getDamageNatureTypes = createSelector(
  getDamageReport,
  damageReport => damageReport.damageNatureTypes,
)
export const getDamageNatureTypeStatus = createSelector(
  getDamageReport,
  damageReport => damageReport.status,
)
