export type TenantId = 'leaseplan-mlp' | 'mylp-lplu'

export const defaultLeaseplanTenant: TenantId = 'leaseplan-mlp'
export const lpluTenant: TenantId = 'mylp-lplu'

export const hostNameMapping: HostNameTenantIdMap = {
  default: defaultLeaseplanTenant,

  'test-my.leaseplan.com': defaultLeaseplanTenant,
  'acc-my.leaseplan.com': defaultLeaseplanTenant,
  'staging-my.leaseplan.com': defaultLeaseplanTenant,
  'my.leaseplan.com': defaultLeaseplanTenant,

  'preview-mlp-': defaultLeaseplanTenant,
  'mlp-test.leaseplandigital.com': defaultLeaseplanTenant,
  'mlp-preprod.leaseplandigital.com': defaultLeaseplanTenant,
  'mlp-prod.leaseplandigital.com': defaultLeaseplanTenant,

  'leaseplan-mlp.test.leaseplan.io': defaultLeaseplanTenant,
  'leaseplan-mlp.preprod.leaseplan.io': defaultLeaseplanTenant,
  'leaseplan-mlp.prod.leaseplan.io': defaultLeaseplanTenant,

  'leaseplan-mlp-lplu.test.leaseplan.io': lpluTenant,
  'leaseplan-mlp-lplu.preprod.leaseplan.io': lpluTenant,
  'leaseplan-mlp-lplu.prod.leaseplan.io': lpluTenant,

  'preview-lplu-': lpluTenant,
  'mlp-test-lplu.leaseplandigital.com': lpluTenant,
  'mlp-preprod-lplu.leaseplandigital.com': lpluTenant,
  'mlp-prod-lplu.leaseplandigital.com': lpluTenant,

  'test-my-lu.leaseplan.com': lpluTenant,
  'acc-my-lu.leaseplan.com': lpluTenant,
  'staging-my-lu.leaseplan.com': lpluTenant,
  'my-lu.leaseplan.com': lpluTenant,
  'driver.myleasys.lu': lpluTenant,
}

export type HostNameTenantIdMap = Record<string, TenantId>

export const getTenantId = (href: string) => {
  for (const tenantHostName in hostNameMapping) {
    if (href.includes(tenantHostName)) {
      return hostNameMapping[tenantHostName]
    }
  }

  return hostNameMapping.default
}
