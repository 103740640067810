import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { editProfileConfig as defaultConfig } from 'mlp-client/src/features-configuration/default/editProfile'
import { EditProfileConfig } from 'mlp-client/src/profile/types'
import {
  franceAddressValidators,
  maxLength,
  required,
} from 'mlp-client/src/form/validations'

export const editProfileConfig: MyLeaseplanFeature<EditProfileConfig> = {
  ...defaultConfig,
  configuration: {
    ...defaultConfig.configuration,
    addressFields: [
      {
        name: 'address.addressLine1',
        validations: [required, maxLength(255), franceAddressValidators],
        label: { id: 'myLeaseplan.shared.input.address.streetname.label' },
        placeholder: {
          id: 'myLeaseplan.shared.input.address.streetname.placeholder',
        },
        dataE2eId: 'street',
        wrapperProps: {
          span: { mobile: 12, tablet: 9, desktop: 9 },
        },
      },
      {
        name: 'address.zipCode',
        validations: [required, maxLength(10)],
        label: { id: 'myLeaseplan.shared.input.address.zip.label' },
        placeholder: { id: 'myLeaseplan.shared.input.address.zip.placeholder' },
        dataE2eId: 'zipCode',
        wrapperProps: {
          span: { mobile: 12, tablet: 3, desktop: 3 },
        },
      },
      {
        name: 'address.city',
        validations: [required, maxLength(255)],
        label: { id: 'myLeaseplan.shared.input.address.city.label' },
        placeholder: {
          id: 'myLeaseplan.shared.input.address.city.placeholder',
        },
        dataE2eId: 'city',
      },
    ],
  },
}
