import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import { MessageBar } from '@velocity/ui/draft'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import {
  DateLocationTile,
  GeneralListTile,
} from 'mlp-client/src/components/confirm-step-tiles'
import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import ActiveVehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/ActiveVehicleModelLicense'
import ConfirmFlowButton from 'mlp-client/src/flows/components/buttons/confirm-flow-button/ConfirmFlowButton'
import { getSupplierLocation } from 'mlp-client/src/components/suppliers/utils'
import {
  MaintenanceType,
  StepNames,
} from 'mlp-client/src/flows/maintenance/enums'
import {
  getAdditionalServices,
  getMalfunctions,
} from 'mlp-client/src/flows/maintenance/selectors'
import { FlowData } from 'mlp-client/src/flows/maintenance/types'
import {
  convertMalfunctionsToOptions,
  formatAddress,
  getAdditionalServicesOptions,
  getLabelFromOption,
  setTimeToDate,
} from 'mlp-client/src/flows/maintenance/utils'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { AppState, Supplier } from 'mlp-client/src/types'
import { FormSubmission, Options } from 'mlp-client/src/form/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import { SubmitMaintenanceBooking } from 'mlp-client/src/flows/maintenance/actions'
import { formatPhoneNumber } from 'mlp-client/src/utils'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'

export interface StepConfig {
  titleLabel: string
  maintenanceTypeLabel: string
  malfunctionsLabel: string
  additionalServicesLabel: string
  specialInstructionsLabel: string
  contactDetailsLabel: string
  pickUpTimeAndLocation: string
  noSelectedMalfunctionsLabel: string
  supplierLabel: string
  dateLocationEditStep: StepNames
  disableAdditionalServices?: boolean
}

export interface Props
  extends StepViewProps<StepConfig, FlowData>,
    FormSubmission<FlowData> {
  supplier: Supplier
  malfunctions: Options
  additionalServices: Options
}

interface TileProps {
  name: string
  title: string
  children?: React.ReactNode
  onBtnClick?(): void
  key?: string
}

class ConfirmBookingStep extends React.PureComponent<Props> {
  onEdit = (stepName: string) => () => {
    const { goToStep } = this.props

    goToStep(stepName)
  }

  correctContractDetails = () => {
    const { goToStep, setFlowData } = this.props

    setFlowData(
      {
        isContractDetailsEnabled: true,
      },
      () => goToStep(StepNames.CHECK_CONTACT_DETAILS),
    )
  }

  getItemsFromValues = (
    values: readonly string[],
    options: Options,
  ): readonly React.ReactNode[] =>
    values.map(value => {
      const label = getLabelFromOption(value, options)

      return <Translation id={label} key={value} />
    })

  renderTile = ({ name, title, children, key, onBtnClick }: TileProps) => (
    <FlowStepTile
      dataE2eId={key || name}
      key={key || name}
      title={<Translation id={title} />}
      btnLabel={<Translation id="myLeaseplan.newSetup.shared.edit" />}
      onBtnClick={onBtnClick || this.onEdit(name)}
    >
      <FlowStepChevronList children={React.Children.toArray(children)} />
    </FlowStepTile>
  )

  render() {
    const {
      flowData,
      config: {
        titleLabel,
        maintenanceTypeLabel,
        malfunctionsLabel,
        additionalServicesLabel,
        specialInstructionsLabel,
        contactDetailsLabel,
        pickUpTimeAndLocation,
        noSelectedMalfunctionsLabel,
        supplierLabel,
        dateLocationEditStep = StepNames.SELECT_LOCATION,
        disableAdditionalServices,
      },
      malfunctions,
      additionalServices: additionalServicesOptions,
      onSubmit,
    } = this.props
    const {
      maintenanceType,
      additionalServices,
      specialInstructions,
      email,
      phone,
      isUserUpdateFailed,
      malfunctionsType,
      date,
      time,
      address,
      hasCollectAddress,
      supplier: {
        address: supplierAddress,
        location: supplierLocation,
        name: supplierName,
      },
    } = flowData

    const isRegularMaintenanceLabel = maintenanceType.includes(
      MaintenanceType.Regular,
    )
      ? `myLeaseplan.maintenance.steps.confirmBooking.isRegularMaintenance`
      : `myLeaseplan.maintenance.steps.confirmBooking.isNotRegularMaintenance`
    const isMalfunctionStepAllowed = maintenanceType.includes(
      MaintenanceType.Malfunctions,
    )
    const noSelectedItems = (
      <Translation key="noSelectedItems" id={noSelectedMalfunctionsLabel} />
    )

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel={titleLabel}>
                <GeneralListTile
                  dataE2eId="maintenance"
                  title={<Translation id={maintenanceTypeLabel} />}
                  onClick={this.onEdit(StepNames.SELECT_MAINTENANCE_TYPE)}
                >
                  <Translation
                    key="maintenance-type"
                    id={isRegularMaintenanceLabel}
                  />
                  <ActiveVehicleModelLicense />
                </GeneralListTile>
                {isMalfunctionStepAllowed && (
                  <GeneralListTile
                    dataE2eId="malfunctions"
                    title={<Translation id={malfunctionsLabel} />}
                    onClick={this.onEdit(StepNames.SELECT_MALFUNCTIONS)}
                  >
                    {Boolean(malfunctionsType.length)
                      ? this.getItemsFromValues(
                          malfunctionsType?.map(item => item.malfunction),
                          malfunctions,
                        )
                      : noSelectedItems}
                  </GeneralListTile>
                )}
                {additionalServices && !disableAdditionalServices && (
                  <GeneralListTile
                    dataE2eId="additionalServices"
                    title={<Translation id={additionalServicesLabel} />}
                    onClick={this.onEdit(StepNames.SELECT_MAINTENANCE_TYPE)}
                  >
                    {Boolean(additionalServices.length)
                      ? this.getItemsFromValues(
                          additionalServices,
                          additionalServicesOptions,
                        )
                      : noSelectedItems}
                  </GeneralListTile>
                )}
                {hasCollectAddress ? (
                  this.renderTile({
                    name: StepNames.SELECT_DATE_AND_LOCATION,
                    title: pickUpTimeAndLocation,
                    children: [
                      setTimeToDate(date, time, 'LLLL'),
                      formatAddress(address),
                      <Translation
                        key="supplierInfo"
                        id="myLeaseplan.maintenance.steps.confirmBooking.supplierInfo"
                        replace={{
                          supplier: supplierName,
                        }}
                      />,
                    ],
                  })
                ) : (
                  <DateLocationTile
                    dateTime={setTimeToDate(date, time)}
                    location={getSupplierLocation(
                      supplierAddress,
                      supplierLocation,
                      supplierName,
                    )}
                    onClick={this.onEdit(dateLocationEditStep)}
                    data-test-id="supplierLocationTile"
                    dataE2eId="whenAndWhere"
                    title={
                      <Translation key="supplierInfo" id={supplierLabel} />
                    }
                  />
                )}
                {specialInstructions && (
                  <FlowStepTile
                    dataE2eId="message"
                    title={<Translation id={specialInstructionsLabel} />}
                    btnLabel={
                      <Translation id="myLeaseplan.newSetup.shared.edit" />
                    }
                    onBtnClick={this.onEdit(StepNames.SPECIAL_INSTRUCTIONS)}
                  >
                    <Text variant="200">{specialInstructions}</Text>
                  </FlowStepTile>
                )}
                {(email || phone) && (
                  <FlowStepTile
                    dataE2eId="contactDetails"
                    key="contactDetails"
                    title={<Translation id={contactDetailsLabel} />}
                    btnLabel={
                      <Translation id="myLeaseplan.newSetup.shared.edit" />
                    }
                    onBtnClick={this.correctContractDetails}
                  >
                    <FlowStepChevronList
                      children={[
                        email,
                        formatPhoneNumber(phone?.code, phone?.number),
                      ]}
                    />
                    {isUserUpdateFailed && (
                      <SMessageBarContainer>
                        <MessageBar variant="warning">
                          <MessageBar.Text>
                            <Translation id="myLeaseplan.genericNotifications.cantSavePhoneNumber" />
                          </MessageBar.Text>
                        </MessageBar>
                      </SMessageBarContainer>
                    )}
                  </FlowStepTile>
                )}
                <Spacing mv={1} pv={1}>
                  <Text variant="100" component="p">
                    <Translation id="myLeaseplan.newSetup.flows.tyres.steps.confirmation.legalNotice" />
                  </Text>
                </Spacing>
                <SubmitStatus submitting={submitting} failed={submitFailed}>
                  <ConfirmFlowButton
                    onClick={handleSubmit}
                    disabled={submitting}
                  />
                </SubmitStatus>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

type ReduxProps = Pick<Props, 'malfunctions' | 'additionalServices'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep } = props

    dispatch(
      new SubmitMaintenanceBooking({
        flowData,
        onComplete: error => {
          if (!error) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

const mapStateToProps = (state: AppState): ReduxProps => ({
  malfunctions: convertMalfunctionsToOptions(getMalfunctions(state)),
  additionalServices: getAdditionalServicesOptions(
    getAdditionalServices(state),
  ),
})

export { ConfirmBookingStep }
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBookingStep)
