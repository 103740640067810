import {
  BreakpointName,
  Grid,
  GridItem,
  Spacing,
  withBreakpoint,
} from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import FuelCardsIcon from 'mlp-client/src/components/icons/fuel-card-icon/FuelCardIcon'
import FuelCard from 'mlp-client/src/flows/fuel-history/components/fuel-card/FuelCard'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import { NoRecords } from 'mlp-client/src/components/no-records/NoRecords'
import { State as FuelHistory } from 'mlp-client/src/flows/fuel-history/reducers'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import FuelSpendingWidget from 'mlp-client/src/flows/fuel-history/components/fuel-spending-widget/FuelSpendingWidget'
import { getFuelHistory } from 'mlp-client/src/flows/fuel-history/selectors'
import {
  FeatureConfiguration,
  FuelRecord,
} from 'mlp-client/src/flows/fuel-history/types'
import { isDesktopBreakpoint, makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { LoadFuelHistory } from 'mlp-client/src/flows/fuel-history/actions'
import Loader from 'mlp-client/src/components/loader/Loader'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'

export interface Props extends MyLeaseplanFeatureProps<FeatureConfiguration> {
  fuelHistory: FuelHistory
  breakpoint: BreakpointName
  loadFuelHistory(): void
}
const translatePath = makeTranslationPath('myLeaseplan.fuelHistory')

class FuelPage extends React.PureComponent<Props> {
  private renderRecordList() {
    const {
      featureConfig: { configuration },
      fuelHistory: { fuelRecords },
    } = this.props
    const batchSize = configuration ? configuration.batchSize : 6

    return (
      <RevealingItemList items={fuelRecords} batchSize={batchSize}>
        {FuelPage.renderRecords}
      </RevealingItemList>
    )
  }

  private static renderRecords(records: readonly FuelRecord[]) {
    return records.map((record, idx) => (
      <FuelCard key={`${record.date}-${idx}`} {...record} />
    ))
  }

  componentDidMount() {
    const { loadFuelHistory, fuelHistory } = this.props

    if (!fuelHistory?.isLoading) {
      loadFuelHistory()
    }
  }

  render() {
    const {
      fuelHistory: { fuelRecords, fuelSpendingRecord, isLoading, hasError },
      breakpoint,
    } = this.props

    if (isLoading) {
      return <Loader loading={true} absolute={true} fixed={true} />
    }

    if (hasError) {
      throw new Error('loadFuelHistory request failed')
    }

    const isDesktop = isDesktopBreakpoint(breakpoint)

    return (
      <MyleaseplanPage metaTitle={translatePath('pageTitle')}>
        <Spacing mb={1}>
          <Headline variant="400" withMarginBottom>
            <Translation id={translatePath('pageTitle')} />
          </Headline>
        </Spacing>
        {!fuelRecords ? (
          <NoRecords
            headerTextLabel={translatePath('noHistory.title')}
            contentTextLabel={translatePath('noHistory.subtitle')}
          >
            <FuelCardsIcon width="110px" height="110px" color="steel40" />
          </NoRecords>
        ) : (
          <Grid
            justifyContent="space-between"
            direction={isDesktop ? 'row' : 'column-reverse'}
          >
            <GridItem
              span={{ tablet: 12, mobile: 12, lap: 7 }}
              data-e2e-id="fuelHistoryList"
            >
              {this.renderRecordList()}
            </GridItem>
            <GridItem span={{ tablet: 12, mobile: 12, lap: 4 }}>
              {fuelSpendingRecord && (
                <FuelSpendingWidget fuelSpendingRecord={fuelSpendingRecord} />
              )}
              <Spacing mt={2}>
                <FAQ faqKey={FAQKeys.FUEL_PAGE} />
              </Spacing>
            </GridItem>
          </Grid>
        )}
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'fuelHistory'>
type DispatchProps = Pick<Props, 'loadFuelHistory'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  fuelHistory: getFuelHistory(state),
})

const mapDispatchToProps: DispatchProps = {
  loadFuelHistory: () => new LoadFuelHistory(),
}

export { FuelPage }
export default withBreakpoint(
  connect(mapStateToProps, mapDispatchToProps)(FuelPage),
)
