import { GridItem, Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import {
  ExtraInformationFieldgroup,
  FormValues as ExtraInformationFormValues,
} from 'mlp-client/src/flows/return/components/extra-information-field-group/ExtraInformationFieldgroup'
import { FlowData, StepViewProps } from 'mlp-client/src/flows/return/types'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import { required, validEmail } from 'mlp-client/src/form/validations'
import { getUserFullName } from 'mlp-client/src/user/utils'
import { makeTranslationPath } from 'mlp-client/src/utils'
import PhoneInput from 'mlp-client/src/form/components/fields/PhoneInput/PhoneInput'

export interface StepConfig {
  hideExtraInformation?: boolean
}
export interface Props extends StepViewProps<StepConfig> {}

export type FormValues = Pick<FlowData, 'name' | 'phone' | 'email'> &
  ExtraInformationFormValues

const translatePath = makeTranslationPath(
  'myLeaseplan.genericNotifications.contactInformation',
)

const translateFormPath = makeTranslationPath(
  'myLeaseplan.newSetup.shared.forms',
)

class ContactInformationStep extends React.PureComponent<Props> {
  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        name: values.name,
        email: values.email,
        phone: {
          code: values.phone.code,
          number: values.phone.number,
        },
        extraDimensions: values.extraDimensions,
        message: values.message,
      },
      goToNextStep,
    )
  }
  render() {
    const {
      flowData: { name, email, phone, extraDimensions, message, user },
      config: { hideExtraInformation },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel={translatePath('contactInformation')}
          textLabel={translatePath('detailsAddMissingInfo')}
          marginTitle={2}
        >
          <Form
            onSubmit={this.onSubmit}
            initialValues={{
              extraDimensions,
              message,
              name: name || getUserFullName(user),
              email: email || user.email,
              phone: {
                code:
                  phone?.code ||
                  user.phoneCountryCode ||
                  user.mobilePhoneCountryCode,
                number:
                  phone?.number || user.phoneNumber || user.mobilePhoneNumber,
              },
            }}
            render={({ handleSubmit, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <Spacing mb={2}>
                  <GridItem span={8}>
                    <TextField
                      name="name"
                      validations={[required]}
                      label={{
                        id: translateFormPath('name.label'),
                      }}
                    />
                  </GridItem>
                </Spacing>
                <Spacing mb={2}>
                  <GridItem span={8}>
                    <TextField
                      name="email"
                      validations={[required, validEmail]}
                      label={{
                        id: translateFormPath('email.label'),
                      }}
                    />
                  </GridItem>
                </Spacing>
                <Spacing mb={2}>
                  <GridItem span={8}>
                    <PhoneInput
                      name="phone"
                      size="m"
                      label={translateFormPath('phoneNumber.label')}
                      placeholder={{
                        id: 'myLeaseplan.shared.input.phone.placeholder',
                      }}
                      required
                    />
                  </GridItem>
                </Spacing>
                {!hideExtraInformation && (
                  <Spacing mb={2}>
                    <ExtraInformationFieldgroup />
                  </Spacing>
                )}
                <NextStepButton
                  disabled={hasValidationErrors}
                  onClick={handleSubmit}
                />
              </form>
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export { ContactInformationStep }
export default ContactInformationStep
