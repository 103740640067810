import {
  CountriesObj,
  CountryCodes,
  CountryNames,
  CountryPhoneCodes,
  CountryType,
} from './types'

// Countries Object could be replaced with this library: https://github.com/catamphetamine/libphonenumber-js
// Also it provides ability to use country specific formatting and validation
const COUNTRIES: Readonly<Partial<CountriesObj>> = Object.freeze(
  Object.entries(CountryCodes).reduce(
    (acc: Partial<CountriesObj>, [key, val]) => ({
      ...acc,
      [key]: {
        name: CountryNames[key],
        countryPhoneCode: CountryPhoneCodes[key],
        flagName: val,
      },
    }),
    {},
  ),
)

export const SORTED_COUNTRIES: readonly CountryType[] = Object.freeze(
  Object.values(COUNTRIES).sort(({ name: nameA }, { name: nameB }): number =>
    nameA > nameB ? 1 : -1,
  ),
)

export const BUTTON_AND_INPUT_SPANS = {
  M: {
    button: { mobile: 5.5, tablet: 3.5, desktop: 3.5 },
    input: { mobile: 6.5, tablet: 8.5, desktop: 8.5 },
  },

  L: {
    button: { mobile: 4.5, tablet: 2.5, desktop: 2.5 },
    input: { mobile: 7.5, tablet: 9.5, desktop: 9.5 },
  },
}

export const KEYS = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ENTER: 'Enter',
  BACKSPACE: 'Backspace',
}

export const HEADER_ITEM_HEIGHT = 57
export const ITEM_HEIGHT = 38
export const OVERFLOW_BORDER = 180
export const DISPLAYED_ITEMS = 4
