import { Card, Collapse, CollapseTrigger, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import { ChevronSolidCircleRightIcon } from 'mlp-client/src/FAQ/components/FAQ.styled'
import {
  FrenchPhoneRenderer,
  PhoneRenderer,
} from 'mlp-client/src/flows/components/markdown/renderers/custom'
import { ItemRenderer } from 'mlp-client/src/flows/components/markdown/renderers/standard'

export const FAQ: React.FC<{
  intro: string
  e2eId: string
  phoneRenderer: 'french' | 'default'
}> = ({ intro, e2eId, phoneRenderer }) =>
  intro ? (
    <Card
      backgroundColor={null}
      border={true}
      borderColor="steel20"
      data-e2e-id={e2eId}
    >
      <Spacing pt={2} pb={1} ph={2}>
        <Collapse open={true}>
          <Spacing mb={1}>
            <CollapseTrigger
              openedIcon={ChevronSolidCircleRightIcon}
              iconPosition="left"
              iconSize="s"
            >
              <Spacing ml={0.5}>
                <Text variant="200" component="div">
                  <ReactMarkdown
                    source={intro}
                    renderers={{
                      ...ReactMarkdown.renderers,
                      Link:
                        phoneRenderer === 'french'
                          ? FrenchPhoneRenderer
                          : PhoneRenderer,
                      Paragraph: ItemRenderer,
                    }}
                  />
                </Text>
              </Spacing>
            </CollapseTrigger>
          </Spacing>
        </Collapse>
      </Spacing>
    </Card>
  ) : null
