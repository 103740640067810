import {
  Button,
  ButtonChevronRightIcon,
  Modal,
  ModalButtonGroup,
  ModalContent,
  ModalTitle,
  mq,
} from '@leaseplan/ui'
import moment from 'moment'
import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { dashboardContentPath } from 'mlp-client/src/constants'
import { CancelBooking } from 'mlp-client/src/timeline-details/actions'
import { removeTimezone } from 'mlp-client/src/timeline-details/utils'
import { getPageContent } from 'mlp-client/src/selectors'
import { getContentSection } from 'mlp-client/src/content/utils'
import { ParagraphSection } from 'mlp-client/src/content/types'
import { isMobile } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import {
  StyledButton,
  StyledButtonOutlined,
} from 'mlp-client/src/components/button/StyledButton'

const StyledModal = styled(Modal)`
  h1 {
    font-size: 22px;
  }
  ${mq.until('mobileLandscape')`
  h1 {
    font-size: 18px;
  }
  `}
`

const cancellationTimeLimit = -24 // number of hours

export interface Props {
  caseId: string
  bookingDate: string
  customerSupportContent?: ParagraphSection
  cancelBooking(): void
  children?: React.ReactElement
}

interface State {
  isOpen: boolean
}

class BookingCancellationModal extends React.PureComponent<Props, State> {
  state = {
    isOpen: false,
  }

  cancelBooking = () => {
    this.closeModal()
    this.props.cancelBooking()
  }

  showModal = () => {
    this.setState({
      isOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      isOpen: false,
    })
  }

  getPhoneNumberForDesktop = (telephone: string) => {
    if (!isMobile()) {
      return telephone
    } else {
      return (
        <Translation id="myLeaseplan.dashboard.roadsideAssistance.support.buttonLabel" />
      )
    }
  }

  render() {
    const { bookingDate, children, customerSupportContent } = this.props
    const telephone = customerSupportContent?.Subtitle
    const hasTimeToCancel =
      moment().diff(removeTimezone(bookingDate), 'hours') <
      cancellationTimeLimit

    return (
      <>
        {!children && (
          <Button
            variant="secondary"
            stretch={true}
            icon={ButtonChevronRightIcon}
          >
            <Translation id="myLeaseplan.timeline.bookingMaintenance.items.cancel" />
          </Button>
        )}

        {children &&
          React.cloneElement(children, {
            onClick: this.showModal,
          })}

        <StyledModal
          wide={true}
          open={this.state.isOpen}
          onClose={this.closeModal}
          disableFullScreenMobile={false}
        >
          <div data-e2e-id="bookingCancellation_modal">
            <ModalContent>
              <ModalTitle>
                {hasTimeToCancel ? (
                  <Translation id="myLeaseplan.maintenance.steps.cancelBooking.title" />
                ) : (
                  <Translation id="myLeaseplan.maintenance.steps.cancelBooking.contactLeaseplanTitle" />
                )}
              </ModalTitle>
              {!hasTimeToCancel && (
                <Translation id="myLeaseplan.maintenance.steps.cancelBooking.unableToCancel" />
              )}
            </ModalContent>
            <ModalButtonGroup>
              {!hasTimeToCancel ? (
                <StyledButton
                  component="a"
                  href={`tel:${telephone}`}
                  stretch={true}
                >
                  {this.getPhoneNumberForDesktop(telephone)}
                </StyledButton>
              ) : (
                <StyledButton
                  onClick={this.cancelBooking}
                  stretch={true}
                  data-e2e-id="bookingCancellation_cancelModalButton"
                >
                  <Translation id="myLeaseplan.maintenance.steps.cancelBooking.cancelModal" />
                </StyledButton>
              )}
              <StyledButtonOutlined
                onClick={this.closeModal}
                stretch={true}
                variant="secondary"
                data-e2e-id="bookingCancellation_goBackButton"
              >
                <Translation id="myLeaseplan.maintenance.steps.cancelBooking.goBack" />
              </StyledButtonOutlined>
            </ModalButtonGroup>
          </div>
        </StyledModal>
      </>
    )
  }
}

type ReduxProps = Pick<Props, 'customerSupportContent'>
type DispatchProps = Pick<Props, 'cancelBooking'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  customerSupportContent: getContentSection(
    getPageContent(state, dashboardContentPath)?.Sections,
    'Customersupport',
  ),
})

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  cancelBooking: () => dispatch(new CancelBooking({ caseId: props.caseId })),
})

export { BookingCancellationModal }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingCancellationModal)
