import React, { PureComponent } from 'react'
import { Spacing } from '@leaseplan/ui'

import CellWrapper from './CellWrapper'
import { EmptyStateMobile } from './EmptyState'
import {
  CellStyled,
  HeaderCellStyled,
  RowStyled,
  TableStyled,
} from './Table.styled'
import { TableProps } from './types'

export class TableMobile extends PureComponent<TableProps> {
  render() {
    const { columns, rows, data, emptyState, defaultValue } = this.props

    if (!data) {
      return (
        <EmptyStateMobile columnsAmount={columns.length}>
          {emptyState}
        </EmptyStateMobile>
      )
    }

    return data.map(item => (
      <Spacing key={item.key} mb={2}>
        <TableStyled>
          <tbody>
            {columns.map(({ header, key, cell: Cell }) =>
              rows && rows.TrComponent ? (
                <rows.TrComponent
                  key={`${item.key}${key}`}
                  {...rows.props}
                  item={item}
                  columns={columns}
                >
                  <RowStyled data-e2e-id={item.key}>
                    <HeaderCellStyled data-e2e-id={key}>
                      {header}
                    </HeaderCellStyled>
                    <CellStyled>
                      <CellWrapper
                        columnKey={key}
                        item={item}
                        cell={Cell}
                        rowsProps={rows && rows.props}
                        defaultValue={defaultValue}
                      />
                    </CellStyled>
                  </RowStyled>
                </rows.TrComponent>
              ) : (
                <RowStyled key={`${item.key}${key}`} data-e2e-id={item.key}>
                  <HeaderCellStyled data-e2e-id={key}>
                    {header}
                  </HeaderCellStyled>
                  <CellStyled>
                    <CellWrapper
                      columnKey={key}
                      item={item}
                      cell={Cell}
                      rowsProps={rows && rows.props}
                      defaultValue={defaultValue}
                    />
                  </CellStyled>
                </RowStyled>
              ),
            )}
          </tbody>
        </TableStyled>
      </Spacing>
    ))
  }
}

export default TableMobile
