import React from 'react'
import { Field } from 'react-final-form'
import { TextInput } from '@leaseplan/ui'

import Translation from 'mlp-client/src/localization/Translation'
import { TranslationType } from 'mlp-client/src/form/types'
import {
  determineValidationState,
  getErrorMessage,
  composeValidators,
} from 'mlp-client/src/form/utils'
import { Time } from 'mlp-client/src/form/components/fields/time-input-field/types'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'

export interface Props {
  name: string
  validations: ReadonlyArray<(value: Time) => TranslationType>
}

const TimeInputField: React.FC<Props> = ({ name, validations }) => (
  <LocalizationContext.Consumer>
    {({ translate }) => (
      <Field<Time> name={name} validate={composeValidators(validations)}>
        {({ input, meta }) => (
          <>
            <label className="field__label">
              <Translation id="myLeaseplan.shared.input.time.label" />
              <Translation
                id={'myLeaseplan.shared.input.time.example'}
                element="span"
                className="field__example"
              />
            </label>
            <div className="o-pack o-pack--tiny o-pack--tiny-pull o-pack--auto o-pack--middle u-2/3@mobileLandscape">
              <div className="o-pack__item u-1/4@tablet u-1/2@mobile">
                <TextInput
                  {...input}
                  name="hours"
                  onChange={e => {
                    input.onChange({
                      ...input.value,
                      hours: e.value,
                    })
                  }}
                  type="text"
                  placeholder={translate(
                    'myLeaseplan.shared.input.time.hour.placeholder',
                  )}
                  value={input.value?.hours || ''}
                  validationState={determineValidationState(meta)}
                />
              </div>
              <div className="o-pack__item">:</div>
              <div className="o-pack__item u-1/4@tablet u-1/2@mobile">
                <TextInput
                  {...input}
                  name="minutes"
                  onChange={e => {
                    input.onChange({
                      ...input.value,
                      minutes: e.value,
                    })
                  }}
                  type="text"
                  placeholder={translate(
                    'myLeaseplan.shared.input.time.minute.placeholder',
                  )}
                  value={input.value?.minutes || ''}
                  validationState={determineValidationState(meta)}
                />
              </div>
              <div className="o-pack__item u-5/7" />
            </div>
            {meta.error && (
              <div className="field-feedback field-feedback--invalid">
                {getErrorMessage(meta, translate)}
              </div>
            )}
          </>
        )}
      </Field>
    )}
  </LocalizationContext.Consumer>
)

export default TimeInputField
