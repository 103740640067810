import { Grid, GridItem, Spacing, Timeline, TimelineItem } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, match } from 'react-router'

import {
  getLocale,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import Loader from 'mlp-client/src/components/loader/Loader'
import OrderDetails from 'mlp-client/src/order-status/components/OrderDetails'
import OrderTitle from 'mlp-client/src/order-status/components/OrderTitle'
import OrderTitleFallback from 'mlp-client/src/order-status/components/OrderTitleFallback'
import TimelineItemComponent from 'mlp-client/src/order-status/components/TimelineItemContent'
import {
  BaseOrderInfo,
  TimelineItem as TimelineItemType,
  TimelineItems,
  VehicleDetails,
} from 'mlp-client/src/order-status/types'
import { AppState } from 'mlp-client/src/types'
import { Contract } from 'mlp-client/src/contracts/types'
import { Quote } from 'mlp-client/src/quotes/types'
import { timeLineItemsConfig } from 'mlp-client/src/order-status/options'
import {
  getCurrentStatusValue,
  getBaseOrderInfo,
  getVehicleDetails,
} from 'mlp-client/src/order-status/utils'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import { getOrderById, isFrance } from 'mlp-client/src/order-status/selectors'
import { getQuoteById } from 'mlp-client/src/quotes/selectors'
import { Locales } from 'mlp-client/src/localization/enums'

interface MatchProps {
  orderId: string
  quoteId: string
}

export interface Props extends RouteComponentProps {
  metaTitle: string
  metaDescription: string
  isLoading?: boolean
  vehicleDetails?: VehicleDetails
  baseOrderInfo: BaseOrderInfo
  timelineItems?: TimelineItems
  contract: Contract
  quote: Quote
  match: match<MatchProps>
  locale: Locales
}

type DefaultProps = Required<Pick<Props, 'isLoading'>>

type InternalProps = Props & DefaultProps

class Page extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    isLoading: false,
  }

  getTimeLineItems = (): TimelineItems => {
    const { contract, quote, locale } = this.props

    const isCurrentLocaleFrance = isFrance(locale)

    if (contract) {
      const currentStatus =
        contract?.orderStatus ||
        timeLineItemsConfig(null, null, isCurrentLocaleFrance)[0].itemId

      return timeLineItemsConfig(contract.id).map(item => {
        const SubtitleComponent = item.subtitle

        return {
          ...item,
          subtitle: <SubtitleComponent />,
          status: getCurrentStatusValue(item.itemId, currentStatus),
        }
      })
    }

    if (quote) {
      const currentStatus =
        quote?.status ||
        timeLineItemsConfig(null, null, isCurrentLocaleFrance)[0].itemId

      return timeLineItemsConfig(null, quote.id, isCurrentLocaleFrance).map(
        item => {
          const SubtitleComponent = item.subtitle

          return {
            ...item,
            subtitle: <SubtitleComponent />,
            status: getCurrentStatusValue(item.itemId, currentStatus),
          }
        },
      )
    }

    return []
  }

  renderItem = (item: TimelineItemType) => {
    const { status, itemId } = item

    return (
      <TimelineItem status={status} key={itemId}>
        <TimelineItemComponent {...item} />
      </TimelineItem>
    )
  }

  render() {
    const {
      isLoading,
      metaTitle,
      metaDescription,
      baseOrderInfo,
      vehicleDetails,
      contract,
      quote,
    } = this.props

    if (contract) {
      return (
        <MyleaseplanPage
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        >
          <Loader loading={isLoading} fixed={true}>
            <Grid verticalGutter={false}>
              <GridItem span={{ mobile: 12, tablet: 10, lap: 7 }}>
                {baseOrderInfo?.make && baseOrderInfo?.model ? (
                  <OrderTitle baseOrderInfo={baseOrderInfo} />
                ) : (
                  <OrderTitleFallback />
                )}
                <Grid justifyContent="center">
                  <GridItem span={{ mobile: 12, tablet: 11, lap: 12 }}>
                    <Timeline>
                      {this.getTimeLineItems().map(this.renderItem)}
                    </Timeline>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem span={{ mobile: 12, tablet: 10, lap: 5 }}>
                <Spacing ml={2}>
                  <OrderDetails vehicleDetails={vehicleDetails} />
                </Spacing>
              </GridItem>
            </Grid>
          </Loader>
        </MyleaseplanPage>
      )
    } else if (quote) {
      return (
        <MyleaseplanPage
          metaTitle={metaTitle}
          metaDescription={metaDescription}
        >
          <Loader loading={isLoading} fixed={true}>
            <Grid verticalGutter={false}>
              <GridItem span={{ mobile: 12, tablet: 10, lap: 7 }}>
                {quote?.make && quote?.model ? (
                  <OrderTitle baseOrderInfo={quote} />
                ) : (
                  <OrderTitleFallback />
                )}

                <Grid justifyContent="center">
                  <GridItem span={{ mobile: 12, tablet: 11, lap: 12 }}>
                    <Timeline>
                      {this.getTimeLineItems().map(this.renderItem)}
                    </Timeline>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem span={{ mobile: 12, tablet: 10, lap: 5 }}>
                <Spacing ml={2}>
                  {/*<OrderDetails vehicleDetails={vehicleDetails} />*/}
                </Spacing>
              </GridItem>
            </Grid>
          </Loader>
        </MyleaseplanPage>
      )
    } else {
      return <NotFound />
    }
  }
}

type meta = 'metaTitle' | 'metaDescription'
type contractProps = 'baseOrderInfo' | 'vehicleDetails' | 'contract'
type ReduxProps = Pick<Props, meta | contractProps | 'quote' | 'locale'>

type DispatchProps = Pick<Props, never>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (
  state: AppState,
  props: OwnProps,
): ReduxProps => {
  const contract = getOrderById(state)(props.match.params.orderId)
  const quote = getQuoteById(state)(props.match.params.quoteId)

  return {
    metaTitle: getTranslation(state, 'myLeaseplan.orderStatus.title'),
    metaDescription: getTranslation(state, 'myLeaseplan.orderStatus.title'),
    contract,
    quote,
    baseOrderInfo: getBaseOrderInfo(contract),
    vehicleDetails: getVehicleDetails(contract),
    locale: getLocale(state),
  }
}

export { Page }
export default withRouter(connect(mapStateToProps)(Page))
