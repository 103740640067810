export const enum ActionTypes {
  FETCH_DICTIONARY_ERROR = '@@sitecore/FETCH_DICTIONARY_ERROR',
  FETCH_DICTIONARY_COMPLETED = '@@sitecore/FETCH_DICTIONARY_COMPLETED',
}

export class FetchDictionaryError {
  readonly type = ActionTypes.FETCH_DICTIONARY_ERROR
  constructor(
    public payload: {
      status: string
      message: string
    },
  ) {}
}

export class FetchDictionaryCompleted {
  readonly type = ActionTypes.FETCH_DICTIONARY_COMPLETED
  constructor(public payload: GenericObject) {}
}

export type Action = FetchDictionaryError | FetchDictionaryCompleted
