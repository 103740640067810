import { DistanceUnit } from 'mlp-client/src/enums'
import {
  ApiType,
  MyleaseplanConfig,
  SharedMyLeaseplanConfig,
} from 'mlp-client/src/features-configuration/types'
import { UserProfileAddressKeys as Address } from 'mlp-client/src/user/enums'
import { navigation } from 'mlp-client/src/features-configuration/default/navigation'
import { mileageUpdateConfig } from 'mlp-client/src/features-configuration/default/mileageUpdate'
import { FeatureStatus } from 'mlp-client/src/features-configuration/enums'
import { tileLinks } from 'mlp-client/src/features-configuration/default/multipleVehicles'

import { contractsConfig } from './contracts'
import { editProfileConfig } from './editProfile'
import { fuelCardsConfig } from './fuelCards'
import { glassRepairFeature } from './glassRepair'
import { maintenanceConfig } from './maintenance'
import { timelineConfig } from './timeline'
import { tyresConfig } from './tyres'
import {
  vehicleDocumentsFeature,
  vehicleDocumentsPageFeature,
} from './vehicleDocuments'
import { dashboardConfig } from './dashboard'
import { damageReportConfig } from './damage-report'
import { loginConfig } from './login'
import { predictiveMaintenanceConfig } from './predictiveMaintenance'
import { endOfLeaseFeature } from './endOfLease'
import { attachCarToDriverFeature } from './attachCarToDriver'
import { finesFeature } from './fines'
import { fuelHistoryFeature } from './fuelHistory'
import { cashClaimConfig } from './cashClaim'
import { orderStatusFeature } from './orderStatus'

export const sharedMyLeaseplanConfig: SharedMyLeaseplanConfig = {
  enabled: true,
  loginEnabled: false,
  accountLinkEnabled: true,
  myAccountLink: 'myLeaseplan.homepage',
}

export const baseMyLeaseplanConfig: MyleaseplanConfig = {
  ...sharedMyLeaseplanConfig,
  isExternalLoginEnabled: true,
  isPublicHolidaysDisabled: FeatureStatus.ENABLED,
  apiType: ApiType.lpis,
  alternatePhoneDisplay: true,
  isInstallMobileAppEnabled: true,
  distanceUnit: DistanceUnit.KM,
  supplierAvailabilityTimeFrame: 3,
  addressFormat: [
    `{${Address.number}}{${Address.suffix}} {${Address.street}}`,
    `{${Address.zipCode}} {${Address.city}}`,
    `{${Address.country}}`,
  ],

  // If the sub-feature is disabled, it will be enabled when showAllFeature flag exists.
  // if the sub-feature is not explicitly declared, it will not be enabled with showAllFeature.

  /**
   * MyLeasePlan sub-features
   */

  users: {
    enabled: true,
  },
  return: {
    enabled: true,
    isCollectAndReturnEnabled: true,
    useBuyPublicPageInsteadOfFlow: true,
  },
  complaints: {
    enabled: true,
  },
  requestForInformation: {
    enabled: true,
  },
  supportsMultipleVehicles: {
    enabled: true,
    configuration: {
      tileLinks,
    },
  },
  predictiveMaintenance: predictiveMaintenanceConfig,
}

export const myleaseplanConfig: MyleaseplanConfig = {
  ...baseMyLeaseplanConfig,
  navigation,
  login: loginConfig,
  showSalutation: true,
  // before, this values were in country profile
  salutation: [
    {
      value: 'Monsieur',
      title: 'Monsieur',
    },
    {
      value: 'Madame',
      title: 'Madame',
    },
  ],
  fuelCard: fuelCardsConfig,
  tyres: tyresConfig,
  timeline: timelineConfig,
  endOfLease: endOfLeaseFeature,
  attachCarToDriver: attachCarToDriverFeature,
  editProfile: editProfileConfig,
  glassRepair: glassRepairFeature,
  contracts: contractsConfig,
  vehicleDocuments: vehicleDocumentsFeature,
  vehicleDocumentsPage: vehicleDocumentsPageFeature,
  dashboard: dashboardConfig,
  maintenance: maintenanceConfig,
  damageReport: damageReportConfig,
  mileageUpdate: {
    ...mileageUpdateConfig,
    configuration: { ...mileageUpdateConfig.configuration, uploadImage: false },
  },
  fines: finesFeature,
  fuelHistory: fuelHistoryFeature,
  cashClaims: cashClaimConfig,
  orderStatus: orderStatusFeature,
}
