import React, { useEffect } from 'react'
import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import { Form } from 'react-final-form'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import FileUpload from 'mlp-client/src/form/components/fields/file-upload-field/FileUploadField'
import Translation from 'mlp-client/src/localization/Translation'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import {
  filesUploaded,
  maxLength,
  minLength,
  required,
} from 'mlp-client/src/form/validations'

export interface StepConfig {
  isPoliceStationEnabled?: boolean
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

export type FormData = Pick<
  FlowData,
  'description' | 'picture' | 'policeStationName'
>

export class DamageUploadStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData(formData, goToNextStep)
  }
  render() {
    const {
      flowData: { description, policeStationName, picture = [] },
      config: { isPoliceStationEnabled },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.uploadDamage.pageTitle">
          <Form
            onSubmit={this.submitHandler}
            initialValues={{ description, policeStationName, picture }}
            keepDirtyOnReinitialize={true}
            render={({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit}>
                  <TextAreaField
                    name="description"
                    placeholder={{
                      id:
                        'myLeaseplan.damageReport.steps.uploadDamage.placeholder',
                    }}
                    validations={[minLength(10), required]}
                    legalNote={{
                      id: 'myLeaseplan.shared.openTextLegalNote',
                    }}
                  />
                  <Spacing mt={2} />
                  <FileUpload
                    name="picture"
                    validations={[filesUploaded]}
                    label="myLeaseplan.damageReport.steps.uploadDamage.upload"
                    accept="image/*"
                  />
                  <Text loose>
                    <Translation id="myLeaseplan.upload.legal.otherPersons" />
                  </Text>
                  {isPoliceStationEnabled && (
                    <Spacing mt={2}>
                      <Text loose>
                        <Translation id="myLeaseplan.damageReport.steps.uploadDamage.disclaimer" />
                      </Text>
                      <TextField
                        name="policeStationName"
                        validations={[maxLength(255)]}
                        label={{
                          id:
                            'myLeaseplan.damageReport.steps.uploadDamage.policeStation',
                        }}
                      />
                    </Spacing>
                  )}
                  <Spacing mt={4} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                </form>
              )
            }}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default DamageUploadStep
