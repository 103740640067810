import { GridItem } from '@leaseplan/ui'
import styled from 'styled-components'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export const Logo = styled.div`
  background-color: ${ColorEnumSelector('midOrange')};
  mask-image: url('/static/images/my-leaseplan/dashboard/no-contract.svg');
  height: 260px;
  width: 260px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
`

export const CenteredGridItem = styled(GridItem)`
  text-align: center;
`
