import React from 'react'
import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Text, Card } from '@velocity/ui'

import Translation from 'mlp-client/src/localization/Translation'
import { makeTranslationPath, formatDate } from 'mlp-client/src/utils'
import { Slider } from 'mlp-client/src/components/slider/Slider'
import { GrayDark80Text } from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  duration: number
  minExtensionDuration: number
  maxExtensionDuration: number
  handleChange(event: number): void
  contractEndDate: string
  extensionDate: string
}

const buildTranslationId = makeTranslationPath(
  'myLeaseplan.extendContract.steps.extendLeaseContract',
)

export class ExtendContractSlider extends React.PureComponent<Props> {
  render() {
    const {
      duration,
      minExtensionDuration,
      maxExtensionDuration,
      handleChange,
      contractEndDate,
      extensionDate,
    } = this.props

    return (
      <Card>
        <Spacing m={2}>
          <Spacing mb={1}>
            <Text bold>
              <Translation
                data-e2e-id="extendMonths"
                replace={{
                  amount: duration,
                }}
                id={buildTranslationId('duration')}
              />
            </Text>
          </Spacing>
          <Spacing mb={2}>
            <Slider
              dataE2EId="extendContractSlider"
              value={duration}
              min={minExtensionDuration}
              max={maxExtensionDuration}
              step={1}
              onChange={handleChange}
            />
          </Spacing>
          <Grid>
            <GridItem>
              <GrayDark80Text component="p">
                <Translation id={buildTranslationId('currentEndDate')} />
              </GrayDark80Text>
              <GrayDark80Text component="p">
                <Translation id={buildTranslationId('newEndDate')} />
              </GrayDark80Text>
            </GridItem>
            <GridItem>
              <GrayDark80Text component="p" data-e2e-id="currentEndDate">
                {formatDate(contractEndDate, 'LL')}
              </GrayDark80Text>
              <Text bold component="p" data-e2e-id="newEndDate">
                {formatDate(extensionDate, 'LL')}
              </Text>
            </GridItem>
          </Grid>
        </Spacing>
      </Card>
    )
  }
}
