import { EventAction } from 'mlp-client/src/analytics/types'
import { TrackFlowNavigationBySteps } from 'mlp-client/src/components/myleaseplan-flow/flow/types'

export const trackFlowNavigationBySteps = ({
  payload: {
    eventCategory,
    currentStepNr,
    eventLabel,
    eventLabelEn,
    totalStepNrFlow,
    flowName,
  },
  dispatchFlowNavigation,
  stepIndex,
  previousStep,
}: TrackFlowNavigationBySteps): void => {
  const trackingPayload = {
    eventCategory,
    eventLabel,
    eventLabelEn,
    currentStepNr,
    totalStepNrFlow,
    flowName,
  }

  if (stepIndex === 0 && previousStep === 0) {
    return dispatchFlowNavigation({
      ...trackingPayload,
      eventAction: EventAction.FLOW_START,
    })
  }

  if (stepIndex > previousStep) {
    dispatchFlowNavigation({
      ...trackingPayload,
      eventAction: EventAction.FLOW_STEP_FORWARD,
    })
  }

  if (stepIndex < previousStep) {
    dispatchFlowNavigation({
      ...trackingPayload,
      eventAction: EventAction.FLOW_STEP_BACK,
    })
  }

  if (currentStepNr === totalStepNrFlow) {
    dispatchFlowNavigation({
      ...trackingPayload,
      eventAction: EventAction.FLOW_FINISHED,
      eventLabel: '',
    })
  }
}

/**
 * Filter the route params to keep only the params we need
 * while navigating through the flow steps
 *
 * For now we only need:
 * - driverId
 * - prefix
 */
export const filterRouteParams = (params: GenericObject) => {
  if (!params) {
    return {}
  }

  const { driverId, prefix } = params

  return {
    ...(driverId && { driverId }),
    ...(prefix && { prefix }),
  }
}

/**
 * Flatten Flow Data
 * so it becomes simple object that we can easily type & use
 *
 * @param flowData: [0: {data1: 'value1'}, 1: {data2: 'value2'}]
 * @returns flatData: {data1: 'value1', data2: 'value2'}
 */
export const flattenFlowData = (
  flowData: readonly GenericObject[],
  defaultData: GenericObject = {},
): GenericObject => {
  if (!Array.isArray(flowData)) {
    return {}
  }

  return flowData.reduce(
    (acc, stepData) => ({ ...acc, ...stepData }),
    defaultData,
  )
}

export const normalizePrefix = (prefix: string): string =>
  prefix.endsWith('.') ? prefix : `${prefix}.`
