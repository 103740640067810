import ViewContract from 'mlp-client/src/contracts/components/ViewContractFlow'
import {
  ViewContractTabs,
  ContractOptionsListMaintenanceProperties,
  ContractOptionsListProperties,
  ContractOptionsListTyresProperties,
  ContractSectionProperties,
  InsuranceSectionProperties,
  VehicleSectionProperties,
  ViewContractSections,
} from 'mlp-client/src/contracts/enums'
import { ContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const contractsConfig: MyLeaseplanFeature<ContractsFeatureConfig> = {
  enabled: true,
  view: ViewContract,
  configuration: {
    policyDocuments: true,
    contractStatusBlock: true,
    contractOptionsSection: {
      [ContractOptionsListProperties.MAINTENANCE]: [
        ContractOptionsListMaintenanceProperties.MAINTENANCE_MODULE,
      ],
      [ContractOptionsListProperties.TYRES]: [
        ContractOptionsListTyresProperties.TYRE_REPLACEMENT_MODULE,
        ContractOptionsListTyresProperties.TYRE_STORAGE,
        ContractOptionsListTyresProperties.TYRE_STORAGE_LOCATION,
      ],
    },
    tabs: {
      [ViewContractTabs.Contract]: {
        [ViewContractSections.VehicleDetails]: [
          VehicleSectionProperties.LICENSE_PLATE,
          VehicleSectionProperties.MAKE,
          VehicleSectionProperties.MODEL,
          VehicleSectionProperties.VIN,
          VehicleSectionProperties.FUEL,
          VehicleSectionProperties.POWER,
        ],
        [ViewContractSections.Contract]: [
          ContractSectionProperties.START_DATE,
          ContractSectionProperties.END_DATE,
        ],
        [ViewContractSections.Insurance]: [
          InsuranceSectionProperties.FULL_DESCRIPTION,
        ],
      },
    },
  },
}
