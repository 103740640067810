import { ModalContent, ModalTitle, ModalTrigger } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'mlp-client/src/types'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { Markdown } from 'mlp-client/src/flows/components/markdown/Markdown'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

import { decodeHtml } from './utils'

export interface Props {
  infoboxContentsType: string
  link: string
  getTranslationByKey: (key: string) => string
}

class Modal extends React.PureComponent<Props> {
  render() {
    const { infoboxContentsType, link, getTranslationByKey } = this.props

    return (
      <ModalTrigger
        trigger={
          <StyledButtonLink
            data-e2e-id={`sitecoreModalLink${infoboxContentsType}`}
          >
            {link}
          </StyledButtonLink>
        }
        modalContent={
          <>
            <ModalContent
              data-e2e-id={`sitecoreModalHeader${infoboxContentsType}`}
            >
              <ModalTitle>
                {decodeHtml(
                  getTranslationByKey(
                    `features.myLeaseplan.tyres.infoboxContents.${infoboxContentsType}.title`,
                  ),
                )}
              </ModalTitle>
            </ModalContent>
            <ModalContent
              data-e2e-id={`sitecoreModalContent${infoboxContentsType}`}
            >
              <Markdown
                content={getTranslationByKey(
                  `features.myLeaseplan.tyres.infoboxContents.${infoboxContentsType}.description`,
                )}
              />
            </ModalContent>
          </>
        }
      />
    )
  }
}

type ReduxProps = Pick<Props, 'getTranslationByKey'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  getTranslationByKey: (key: string): string => getTranslation(state, key),
})

export default connect(mapStateToProps)(Modal)
