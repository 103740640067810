import { VehicleDocumentsRequest } from 'mlp-client/src/flows/vehicle-documents/types'

export const enum ActionTypes {
  VEHICLE_DOCUMENTS_REQUEST = '@@myLeaseplan/vehicle-documents/VEHICLE_DOCUMENTS_REQUEST',
}

export class SubmitVehicleDocumentsRequest {
  readonly type = ActionTypes.VEHICLE_DOCUMENTS_REQUEST

  constructor(
    public payload: {
      request: VehicleDocumentsRequest
      onComplete(isError: boolean): void
    },
  ) {}
}
