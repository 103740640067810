import { SupplierCard } from 'mlp-client/src/types'

export const filterSuppliers = (
  suppliers: readonly SupplierCard[],
  tyreStorageLocationName?: string,
): readonly SupplierCard[] => {
  if (!tyreStorageLocationName) {
    return suppliers
  }

  const locationNameNormalised = normalise(tyreStorageLocationName)

  return suppliers.filter(s => normalise(s.name) === locationNameNormalised)
}

/**
 * A heuristic normalisation based on the fact that the provided location names
 * and location names from Salesforce can differ.
 *
 * Example:
 *
 * - Provided location name: 'Delta Pneus Leudelange S.A'
 * - Value from the backend: 'Delta Pneus Leudelange SA'
 */
function normalise(value: string) {
  const normalisationRegex = /[^\w]*/gi

  return (value || '').replace(normalisationRegex, '').toLowerCase()
}
