import moment from 'moment'

import { getFormName } from 'mlp-client/src/utils'

import { VehicleBuyoutCaseRequest, FlowData, StepWithFormProps } from './types'

export const buildFormName = (props: StepWithFormProps): string => {
  const {
    config: { flowName },
    activeStep: { name: stepName },
  } = props

  return getFormName(flowName, stepName)
}

export const getBuyDate = (contractEndDate: string): string => {
  const todayDate = moment().startOf('day').format()

  return moment(contractEndDate).isBefore(todayDate)
    ? todayDate
    : contractEndDate
}

export const formatContractEndDate = (contractEndDate: string): string =>
  contractEndDate
    ? moment.utc(contractEndDate).startOf('day').toISOString()
    : ''

export const getDateWighoutOffset = (dateTimeIso: string): string => {
  const offsetRegex = /\+\d\d(:?\d\d)?$/i
  const dateTimeMoment = moment.utc(dateTimeIso.replace(offsetRegex, ''))

  return dateTimeMoment.startOf('day').toISOString()
}

export const getSubmitBuyRequestBody = (
  flowData: FlowData,
): VehicleBuyoutCaseRequest => ({
  contractId: flowData.contractId,
  userId: flowData.user.id,
  mileage: flowData.mileage,
  buyoutDate: flowData.buyDate,
  contractEndDate: flowData.contractEndDate,
  attachmentKeys: flowData.photos.map(attachment => attachment.key),
})
