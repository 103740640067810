import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { Locales } from 'mlp-client/src/localization/enums'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { PolicyDocuments as PolicyDocumentsType } from 'mlp-client/src/contracts/types'
import { LoadPageContent } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/actions'
import DocumentsPage from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/components/DocumentsPage'
import {
  getDocumentItems,
  isDocumentsLoading,
  pageContentEndpoint,
} from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import { Documents } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/types'
import { AppState } from 'mlp-client/src/types'
import {
  getPolicyDocuments,
  isPolicyDocumentsEnabled,
  isPolicyDocumentsLoading,
} from 'mlp-client/src/contracts/selectors'
import {
  GetRegistrationCertificateData,
  LoadPolicyDocuments,
} from 'mlp-client/src/contracts/actions'
import { LoadDownloadableDocuments } from 'mlp-client/src/downloadable-documents/actions'

import { isDenmark } from './utils'

export interface Props {
  documents: Documents
  isDocumentsLoading: boolean
  locale: Locales
  policyDocuments: PolicyDocumentsType
  policyDocumentsEnabled: boolean
  isPolicyDocumentsLoading: boolean
  loadContent(locale: Locales): void
  loadPolicyDocuments(): void
  loadDownloadableDocuments(): void
  loadCertificateData(): void
  children: React.ReactNode
}

class VehicleDocumentsPage extends React.PureComponent<Props> {
  componentDidMount() {
    const {
      loadContent,
      loadPolicyDocuments,
      loadDownloadableDocuments,
      loadCertificateData,
      locale,
      policyDocumentsEnabled,
    } = this.props

    loadContent(locale)
    loadCertificateData()

    if (isDenmark(locale)) {
      loadDownloadableDocuments()
    }

    if (policyDocumentsEnabled) {
      loadPolicyDocuments()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { loadContent, locale } = this.props

    if (prevProps.locale !== locale) {
      loadContent(locale)
    }
  }

  render() {
    const {
      isDocumentsLoading,
      isPolicyDocumentsLoading,
      documents,
      policyDocuments,
      policyDocumentsEnabled,
    } = this.props

    return (
      <DocumentsPage
        documents={documents}
        policyDocuments={policyDocumentsEnabled && policyDocuments}
        isLoading={
          isDocumentsLoading ||
          (policyDocumentsEnabled && isPolicyDocumentsLoading)
        }
      />
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'documents'
  | 'isDocumentsLoading'
  | 'locale'
  | 'policyDocuments'
  | 'policyDocumentsEnabled'
  | 'isPolicyDocumentsLoading'
>
type DispatchProps = Pick<
  Props,
  | 'loadContent'
  | 'loadPolicyDocuments'
  | 'loadDownloadableDocuments'
  | 'loadCertificateData'
>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isDocumentsLoading,
  isPolicyDocumentsLoading,
  documents: getDocumentItems,
  locale: getUserLocale,
  policyDocuments: getPolicyDocuments,
  policyDocumentsEnabled: isPolicyDocumentsEnabled,
})

export const mapDispatchToProps: DispatchProps = {
  loadContent: (locale: Locales) =>
    new LoadPageContent({
      locale,
      endpoint: pageContentEndpoint,
    }),
  loadPolicyDocuments: () => new LoadPolicyDocuments(),
  loadDownloadableDocuments: () => new LoadDownloadableDocuments(),
  loadCertificateData: () => new GetRegistrationCertificateData(),
}

export { VehicleDocumentsPage }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleDocumentsPage)
