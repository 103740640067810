import moment from 'moment'

import { INVARIANT_DATE_FORMAT } from 'mlp-client/src/constants'

import { FlowData, ExtendContractRequest } from './types'

export const getExtensionDate = (
  value: number,
  contractEndDate: string,
): string =>
  moment(contractEndDate)
    .startOf('day')
    .add(value, 'months')
    .format(INVARIANT_DATE_FORMAT)

export const getSubmitExtendContractRequestBody = (
  flowData: FlowData,
): ExtendContractRequest => ({
  extensionDate: flowData.extensionDate,
  contractId: flowData.contractId,
  userId: flowData.user.id,
})
