import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import BrandLogo from 'mlp-client/src/components/layout/top-navigation/desktop/BrandLogo'
import ClickableLogo from 'mlp-client/src/components/layout/top-navigation/desktop/ClickableLogo'
import MainMenuDropdown from 'mlp-client/src/components/layout/top-navigation/desktop/MainMenuDropdown'
import MainMenuItems from 'mlp-client/src/components/layout/top-navigation/desktop/MainMenuItems'
import MainMenuWidgets from 'mlp-client/src/components/layout/top-navigation/desktop/MainMenuWidgets'
import { User } from 'mlp-client/src/user/types'
import { getUser } from 'mlp-client/src/user/selectors'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'
import { Locales } from 'mlp-client/src/localization/enums'
import { getPageContent } from 'mlp-client/src/selectors'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { ContentPage } from 'mlp-client/src/content/types'
import { LoadContent } from 'mlp-client/src/content/actions'
import {
  getBrandLogoPart,
  pageContentEndpoint,
} from 'mlp-client/src/components/layout/top-navigation/selectors'
import { AppState } from 'mlp-client/src/types'
import { getContentImage } from 'mlp-client/src/content/selectors'

export interface Props {
  user?: User
  hideLoginLink?: boolean
  contractId: string
  quoteId?: string
  locale?: Locales
  loadPageContent(locale: Locales): void
  pageContent?: ContentPage
}

interface State {
  overflow: {
    calculated: boolean
    mainMenuItems: boolean
  }
}

class PrimaryLane extends React.PureComponent<Props, State> {
  componentDidMount() {
    const { loadPageContent, locale, pageContent } = this.props

    if (!pageContent) {
      loadPageContent(locale)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { loadPageContent, locale } = this.props

    if (prevProps.locale !== locale) {
      loadPageContent(locale)
    }
  }

  // We need to keep a reference to some elements to be able to determine if they're overflowing their container.
  overflowChildRefs: { mainMenuItems: HTMLElement | null } = {
    mainMenuItems: null,
  }

  attachMainMenuItems = (element: HTMLElement) => {
    this.overflowChildRefs.mainMenuItems = element
  }

  state = {
    overflow: {
      calculated: false,
      mainMenuItems: false,
    },
  }

  handleOverflowChange = (overflow: boolean) => {
    this.setState({
      overflow: { calculated: true, mainMenuItems: overflow },
    })
  }

  render() {
    const { user, hideLoginLink, contractId, quoteId, pageContent } = this.props
    const { overflow } = this.state
    const wrapperClassList = Classnames(
      'o-wrapper',
      'o-wrapper--lg',
      'o-wrapper--no-gutter',
      'top-navigation__primary',
      {},
    )

    let brandLogoPart = user ? getBrandLogoPart(user?.brand) : null
    let brandLogoImg = getContentImage(brandLogoPart, pageContent)

    return (
      <TopNavigationContext.Consumer>
        {({ navActiveItem }) => (
          <div className={wrapperClassList} data-e2e-id="mainMenu">
            <div className="top-navigation__logo" data-e2e-id="logoMainMenu">
              <ClickableLogo />
            </div>
            <div
              className={Classnames({
                'u-padding-right-small': user?.brand,
              })}
            >
              <BrandLogo src={brandLogoImg?.ImageUrl} />
            </div>
            <div className="top-navigation__main-items u-padding-left-small">
              <MainMenuItems
                hidden={overflow.mainMenuItems && !!navActiveItem}
                onOverflowChange={this.handleOverflowChange}
                contractId={contractId}
                quoteId={quoteId}
              />
              {overflow.mainMenuItems && navActiveItem && (
                // When main-menu-items are overflowing in the primary-lane and there is an item active:
                // We render a dropdown containing all main-menu-items in the primary lane.
                <MainMenuDropdown contractId={contractId} quoteId={quoteId} />
              )}
            </div>
            {/*
          ------------------------------------
          Widgets (e.g. MyLeasePlan-button, flag) etc.
          ------------------------------------
        */}
            <MainMenuWidgets hideLoginLink={hideLoginLink} />
          </div>
        )}
      </TopNavigationContext.Consumer>
    )
  }
}

export type ReduxProps = Pick<Props, 'user' | 'locale' | 'pageContent'>
export type DispatchProps = Pick<Props, 'loadPageContent'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  user: getUser(state),
  locale: getUserLocale(state),
  pageContent: getPageContent(state, pageContentEndpoint),
})

export const mapDispatchToProps: DispatchProps = {
  loadPageContent: (locale: Locales) =>
    new LoadContent({
      locale,
      endpoint: pageContentEndpoint,
    }),
}

export { PrimaryLane }
export default connect(mapStateToProps, mapDispatchToProps)(PrimaryLane)
