import React from 'react'
import { OverlayView } from '@react-google-maps/api'

import { getPixelPositionOffset } from 'mlp-client/src/components/map-with-markers/utils'
import SearchLocationMarkerIcon from 'mlp-client/src/components/icons/search-location-marker-icon/SearchLocationMarkerIcon'

export interface Props {
  position: google.maps.LatLngLiteral
}

export class SeachLocationMarker extends React.PureComponent<Props> {
  render() {
    const { position } = this.props

    return (
      <OverlayView
        getPixelPositionOffset={getPixelPositionOffset}
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <SearchLocationMarkerIcon />
      </OverlayView>
    )
  }
}
