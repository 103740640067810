/**
 * Returns true when the given string is a localized route identifier (LRI).
 *
 * We recognize an LRI by:
 *  - Should contain a . (But may not start with a .)
 *  - Should NOT contain a '/'
 *  It's a copy from sitecore
 */
export const isLocalizedRouteIdentifier = (route: string): boolean =>
  !!route && /^[\w-]+\.[\w-]+/.test(route) && !/\//.test(route)
