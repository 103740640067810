import { Login } from 'mlp-client/src/components/login/types'

export const loginConfig: Login = {
  showRegistrationLink: false,
  loginPages: [
    {
      labelTranslation: 'myLeaseplan.loginPages.leasePlanOnline.label',
      linkTextTranslation: 'myLeaseplan.loginPages.leasePlanOnline.linkText',
      url: 'https://www.leaseplan.dk/page/my-leaseplan-login',
    },
    {
      labelTranslation: 'myLeaseplan.loginPages.myFleet.label',
      linkTextTranslation: 'myLeaseplan.loginPages.myFleet.login',
      url: 'https://myfleet.leaseplan.dk/',
    },
  ],
}
