import { SagaIterator } from 'redux-saga'
import { call, put, select, all } from 'redux-saga/effects'

import { getLocale } from 'mlp-client/src/localization/selectors'
import { ApiSagas } from 'mlp-client/src/api'
import { isLuxembourg } from 'mlp-client/src/dashboard/utils'

import { FetchDictionaryCompleted, FetchDictionaryError } from './actions'
import { slashSandwich } from './utils'

export function* fetchDictionary(): SagaIterator {
  const locale = yield select(getLocale)
  let localesForRequest = [locale]

  /** REMOVED; starting from 2023 we only support 2 languages for every country*/
  
  // // Sitecore doesn't work with 'en-nl' locale for now, so we request English dictionary by 'en-lp' locale
  // // also need to remove duplicate request for English dictionary
  // if (locale.split('-')[0] !== LanguageCode.en) {
  //   localesForRequest = [Locales.EN_LP, locale]
  // }
  // some countries have only one enabled language, so we need to request English for Google Analytic
  // the order is: [english locale, (selected locale)]

  const Myleaseplan = 'myleaseplan'
  const Myleasys = 'myleasys'
  let siteCoreApp = Myleaseplan

  if (isLuxembourg(locale)) {
    siteCoreApp = Myleasys
  }

  try {
    const dictionaries = yield all(
      localesForRequest.map(lang =>
        call(
          ApiSagas.get,
          slashSandwich([
            'sitecore',
            'sitedata',
            'dictionary',
            siteCoreApp,
            lang,
          ]),
          { params: { dlang: lang } },
          { throwError: true },
        ),
      ),
    )

    yield put(
      new FetchDictionaryCompleted({
        enResult: dictionaries[0].myLeaseplan,
        // in case if we have only one request (English dictionary), need to duplicate dictionary saving for currently selected locale
        result: dictionaries[1]?.myLeaseplan || dictionaries[0].myLeaseplan,
      }),
    )
  } catch (error) {
    yield put(new FetchDictionaryError(error))
  }
}
