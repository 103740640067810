import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { FlowData } from 'mlp-client/src/flows/return/types'
import { CheckboxField } from 'mlp-client/src/form/components/fields/CheckboxFields'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { makeTranslationPath } from 'mlp-client/src/utils'

export type FormValues = Pick<FlowData, 'extraDimensions' | 'message'>

const translatePath = makeTranslationPath(
  'myLeaseplan.newSetup.flows.return.steps.contactExtraInformation',
)

const translateSharedPath = makeTranslationPath('myLeaseplan.newSetup.shared')

const ExtraInformationFieldgroup: React.FC = () => (
  <>
    <Headline variant="400" withMarginBottom>
      <Translation id={translatePath('extraInformation')} />
    </Headline>
    <CheckboxField
      name="extraDimensions"
      label={
        <>
          <Text bold>
            <Translation id={translatePath('extraDimentionsCar.title')} />
          </Text>
          <Spacing>
            <Text variant="200">
              <Translation id={translatePath('extraDimentionsCar.subTitle')} />
            </Text>
          </Spacing>
        </>
      }
    />
    <Spacing mt={2}>
      <TextAreaField
        name="message"
        data-e2e-id="extraInformation"
        label={{
          id: translateSharedPath('forms.otherDetails.label'),
        }}
        placeholder={{
          id: translateSharedPath('forms.specialInstructions.placeholder'),
        }}
        legalNote={{
          id: translateSharedPath('openTextLegalNote'),
        }}
      />
    </Spacing>
  </>
)

export { ExtraInformationFieldgroup }
export default ExtraInformationFieldgroup
