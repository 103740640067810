import React from 'react'
import { Grid, GridItem, Text } from '@leaseplan/ui'

import { Options, StringValidations } from 'mlp-client/src/form/types'
import { RadioField } from 'mlp-client/src/form/components/fields/RadioFields'
import Translation from 'mlp-client/src/localization/Translation'

export interface Props {
  options: Options
  validations: StringValidations
}

const SalutationField: React.FC<Props> = ({ options, validations }) => (
  <>
    <Text fontWeight="bold" color="black">
      <Translation id="myLeaseplan.shared.input.salutation.label" />
    </Text>
    <Grid data-e2e-id="salutation">
      {options.map(option => (
        <GridItem key={option.value}>
          <RadioField
            name="salutation"
            value={option.value}
            label={{ id: option.title }}
            validations={validations}
          />
        </GridItem>
      ))}
    </Grid>
  </>
)

export default SalutationField
