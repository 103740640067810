import React from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

interface Props {
  title?: string
  width?: number
  height?: number
}

type DefaultProps = Required<Pick<Props, 'title' | 'width' | 'height'>>
type InternalProps = Props & DefaultProps

class SupplierMarkerIcon extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    title: 'Supplier',
    width: 28,
    height: 39,
  }

  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 39 52"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{this.props.title}</title>
        <defs>
          <linearGradient
            id="c"
            x1="39.281%"
            x2="161.133%"
            y1="50.013%"
            y2="50.013%"
          >
            <stop offset="0%" stopColor="#ED8221" />
            <stop offset="100%" stopColor="#E0322D" />
          </linearGradient>
          <path
            id="b"
            d="M18.367 0c10.144 0 18.368 7.271 18.368 18.204C36.735 29.578 18.367 50 18.367 50S0 29.596 0 18.204C0 7.27 8.223 0 18.367 0"
          />
          <filter
            id="a"
            width="135.4%"
            height="126%"
            x="-17.7%"
            y="-15%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="2"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.297752491 0"
            />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 2)">
            <path
              fill="#000"
              filter="url(#a)"
              d="M18.367 0c10.144 0 18.368 7.271 18.368 18.204C36.735 29.578 18.367 50 18.367 50S0 29.596 0 18.204C0 7.27 8.223 0 18.367 0"
            />
            <path
              fill="url(#c)"
              d="M18.367 0c10.144 0 18.368 7.271 18.368 18.204C36.735 29.578 18.367 50 18.367 50S0 29.596 0 18.204C0 7.27 8.223 0 18.367 0"
            />
            <path
              fill={ColorEnumSelector('midOrange')}
              d="M18.367 0c10.144 0 18.368 7.271 18.368 18.204C36.735 29.578 18.367 50 18.367 50S0 29.596 0 18.204C0 7.27 8.223 0 18.367 0"
            />
          </g>
          <g fill="#FFF">
            <path
              stroke="#FFF"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M24.713 20.648a.73.73 0 0 0-.827.139l-2.328 2.273-4.715 4.733a.73.73 0 0 0-.193.684l.115.486c.57 1.691.153 3.45-1.067 4.654a4.458 4.458 0 0 1-3.988 1.193l2.81-2.772a.732.732 0 0 0 .217-.516l.017-2.484a.73.73 0 0 0-.725-.736l-2.485-.017c-.194.017-.38.075-.518.21l-2.81 2.773a4.456 4.456 0 0 1 1.247-3.97 4.452 4.452 0 0 1 5.03-.847.73.73 0 0 0 .828-.14l7.123-6.986a.729.729 0 0 0 .15-.825 4.454 4.454 0 0 1 .915-5.018 4.453 4.453 0 0 1 3.988-1.193l-2.81 2.773a.73.73 0 0 0-.218.514l-.017 2.485a.729.729 0 0 0 .725.735l2.486.017a.752.752 0 0 0 .517-.21l2.81-2.772a4.455 4.455 0 0 1-1.246 3.97 4.454 4.454 0 0 1-5.03.847z"
            />
            <path d="M13.658 26.453l8.734-8.734 2.92 2.92-8.734 8.734z" />
          </g>
        </g>
      </svg>
    )
  }
}

export default SupplierMarkerIcon
