import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { getMyLeaseplanConfig } from 'mlp-client/src/features-configuration/config'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { AppState } from 'mlp-client/src/types'
import { getCountry } from 'mlp-client/src/localization/selectors'
import { TrackPageViewEvent } from 'mlp-client/src/analytics/actions'
import { PageViewEventPayload } from 'mlp-client/src/analytics/types'

export interface Props {
  config: MyleaseplanConfig
  children?: React.ReactNode
  pageTitle: string
  pageTitleKey: string
  pushPageViewEvent(event: PageViewEventPayload): void
}

class MyLeaseplanApp extends React.PureComponent<Props> {
  componentDidMount() {
    const { pushPageViewEvent, pageTitle, pageTitleKey } = this.props

    pushPageViewEvent({ pageTitle, pageTitleKey })
  }

  render() {
    const { children, config } = this.props

    return (
      <MyLeaseplanContext.Provider value={config}>
        {children}
      </MyLeaseplanContext.Provider>
    )
  }
}

type ReduxProps = Pick<Props, 'config'>
type DispatchProps = Pick<Props, 'pushPageViewEvent'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  config: getMyLeaseplanConfig(getCountry(state)),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  pushPageViewEvent: (event: PageViewEventPayload) =>
    dispatch(new TrackPageViewEvent(event)),
})

export { MyLeaseplanApp }
export default connect(mapStateToProps, mapDispatchToProps)(MyLeaseplanApp)
