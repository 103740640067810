import { Options } from 'mlp-client/src/form/types'

import * as actions from './actions'

export interface State {
  countries: Options
  languages: Options
}

const defaultState: State = {
  countries: [],
  languages: [],
}

export default (state = defaultState, action: actions.Action): State => {
  switch (action.type) {
    case actions.ActionTypes.LOADED_COUNTRY_PROFILE:
      return {
        ...action.payload,
      }
    default:
      return state
  }
}
