import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { makeTranslationPath } from 'mlp-client/src/utils'

import { VehicleTileLink } from './types'
import {
  IconTextStyled,
  LinkStyled,
  SpacingStyled,
  VehicleOverviewTileText,
} from './VehicleOverviewTile.styled'

export interface Props {
  option: VehicleTileLink
}

const translatePath = makeTranslationPath('myLeaseplan.multipleCars.actions')

class VehicleOverviewTileLink extends React.PureComponent<Props> {
  render() {
    const {
      to,
      icon,
      translationId,
      dataE2eId,
      target = '_self',
    } = this.props.option

    return (
      <LinkStyled
        component={RouteLink}
        to={to}
        data-e2e-id={dataE2eId}
        target={target}
      >
        <IconTextStyled
          icon={icon}
          iconProps={{ color: 'steel', width: '24px', height: '24px' }}
        >
          <SpacingStyled ml={0.5}>
            <VehicleOverviewTileText variant="200">
              <Translation id={translatePath(translationId)} />
            </VehicleOverviewTileText>
          </SpacingStyled>
        </IconTextStyled>
      </LinkStyled>
    )
  }
}

export { VehicleOverviewTileLink }
export default VehicleOverviewTileLink
