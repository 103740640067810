import React, { ComponentClass, PureComponent, ReactNode } from 'react'

import HeaderCell from './HeaderCell'
import { HeaderCellProps } from './types'

type HeaderCellAsFunction = (props: GenericObject) => ReactNode
type HeaderCellAsComponent = ComponentClass<HeaderCellProps>

export interface Props {
  header: ReactNode
  headerCell: HeaderCellAsComponent | HeaderCellAsFunction
  dataE2EId: string
}

export class HeaderCellWrapper extends PureComponent<Props> {
  render() {
    const { header, headerCell, dataE2EId, ...props } = this.props

    if (!headerCell) {
      return <HeaderCell dataE2EId={dataE2EId}>{header}</HeaderCell>
    }

    if (!headerCell.prototype.isReactComponent) {
      return (headerCell as HeaderCellAsFunction)({ header, ...props })
    }

    const HeaderCellCustom = headerCell as HeaderCellAsComponent

    return <HeaderCellCustom dataE2EId={dataE2EId} header={header} {...props} />
  }
}

export default HeaderCellWrapper
