import React from 'react'
import { Grid, GridItem } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import { Field } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { TranslationType } from 'mlp-client/src/form/types'
import {
  determineValidationState,
  getErrorMessage,
  composeValidators,
  onlyNumbersAndSpaces,
} from 'mlp-client/src/form/utils'
import { getPhoneNumberValidators } from 'mlp-client/src/form/validations'
import { ErrorText } from 'mlp-client/src/components/styled/TextStyle'

import { StyledTextInput, StyledGridItem } from './PhoneInput.styled'
import CountryListButton from './CountryListButton'
import { BUTTON_AND_INPUT_SPANS } from './constants'

export interface Props {
  label?: string
  placeholder?: TranslationType
  name: string
  disabled?: boolean
  required?: boolean
  size?: 'm' | 'l'
}

const PhoneInput: React.FC<Props> = ({
  placeholder,
  label,
  name,
  disabled = false,
  required,
  size = 'l',
}) => (
  <LocalizationContext.Consumer>
    {({ translate }) => (
      <Field
        name={name}
        validate={composeValidators(getPhoneNumberValidators(required))}
        render={({ meta: containerMeta, input: containerInput }) => (
          <>
            <Text bold>{translate(label)}</Text>
            <Grid verticalGutter={false}>
              <GridItem span={12}>
                <StyledGridItem
                  span={12}
                  className={`phone-input-border-color-${
                    determineValidationState(containerMeta) || 'default'
                  }`}
                >
                  <GridItem
                    span={BUTTON_AND_INPUT_SPANS[size.toUpperCase()].button}
                  >
                    <Field
                      name={`${name}.code`}
                      render={({ input }) => (
                        <CountryListButton
                          onChange={input.onChange}
                          value={input.value}
                          fieldName={name}
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem
                    span={BUTTON_AND_INPUT_SPANS[size.toUpperCase()].input}
                  >
                    <Field
                      name={`${name}.number`}
                      render={({ input }) => (
                        <StyledTextInput
                          {...containerInput}
                          value={input.value}
                          onChange={e => input.onChange(e.value)}
                          validationState={determineValidationState(
                            containerMeta,
                          )}
                          placeholder={
                            placeholder &&
                            translate(placeholder.id, placeholder.replaceValues)
                          }
                          type="text"
                          data-e2e-id={`${name}Number`}
                          disabled={disabled}
                        />
                      )}
                      parse={onlyNumbersAndSpaces}
                    />
                  </GridItem>
                </StyledGridItem>
              </GridItem>
              {containerMeta.error && (
                <GridItem span={12}>
                  <ErrorText>
                    {getErrorMessage(containerMeta, translate)}
                  </ErrorText>
                </GridItem>
              )}
            </Grid>
          </>
        )}
      />
    )}
  </LocalizationContext.Consumer>
)

export default PhoneInput
