import { getTranslation } from 'mlp-client/src/localization/selectors'
import { DashboardRoadsideAssistance } from 'mlp-client/src/components/road-side-assistance/types'
import { AppState } from 'mlp-client/src/types'

export const getRoadsideAssistanceTileButton = (
  state: AppState,
  buttonName: string,
): DashboardRoadsideAssistance => ({
  buttonLabel: getTranslation(
    state,
    `myLeaseplan.dashboard.roadsideAssistance.${buttonName}.buttonLabel`,
    '',
  ),
  modalTitle: getTranslation(
    state,
    `myLeaseplan.dashboard.roadsideAssistance.${buttonName}.modalTitle`,
    '',
  ),
})
