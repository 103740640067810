import { CardContent, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import { camelCase } from 'camel-case'
import * as React from 'react'
import { AccordionSummary } from '@material-ui/core'
import { connect } from 'react-redux'
import { ExpandMoreOutlined } from '@material-ui/icons'

import Translation from 'mlp-client/src/localization/Translation'
import { Vehicle } from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import { LicensePlate } from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense.styled'
import { TimelineCardConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import {
  ContentWrapper,
  DateStyled,
  GridItemCentered,
  GridItemStyled,
  StyledAccordion,
  StyledCard,
  StyledRouteLink,
  VehicleGridStyled,
} from 'mlp-client/src/timeline/components/TimelineCard/TimelineCard.styled'
import {
  TimelineCardContent,
  TimelineChildItem,
} from 'mlp-client/src/timeline/types'
import {
  getColorFromStatus,
  getReportDamagePhase,
} from 'mlp-client/src/timeline/utils'
import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'
import { CardTag } from 'mlp-client/src/components/card-tag/CardTag'
import { GrayMainText } from 'mlp-client/src/components/styled/TextStyle'
import { TimelineChildCard } from 'mlp-client/src/timeline/components/TimelineCard/TimelineChildCard'
import { AppState } from 'mlp-client/src/types'
import { getTranslation } from 'mlp-client/src/localization/selectors'

export interface Props {
  content: TimelineCardContent
  config: TimelineCardConfig
  icon?: React.ReactNode
  titleReplace?: GenericObject
  descriptionReplace?: GenericObject
  getProcessPhaseTranslation?: (key: string, fallback: string) => string
}

class TimelineCard extends React.PureComponent<Props> {
  renderModifiedDate = (modifiedDate: string) => (
    <DateStyled bold>{modifiedDate}</DateStyled>
  )
  renderVehicleBlock = (vehicle: Vehicle) => (
    <VehicleGridStyled verticalGutter={false} alignContent="flex-start">
      <GridItemStyled span={{ tablet: 12, mobile: 7 }}>
        <GrayMainText variant="200" bold data-e2e-id="model">
          {vehicle.isFuelManagement ? (
            <Translation id={'myLeaseplan.multipleCars.actions.fuelMandate'} />
          ) : (
            vehicle.model
          )}
        </GrayMainText>
      </GridItemStyled>
      {!vehicle.isFuelManagement && (
        <GridItem span={{ tablet: 12, mobile: 'grow' }}>
          <LicensePlate variant="200" data-e2e-id="licensePlate">
            {vehicle.licensePlate}
          </LicensePlate>
        </GridItem>
      )}
    </VehicleGridStyled>
  )

  renderLeftInfo = () => {
    const { content, icon } = this.props
    const { vehicle } = content

    if (vehicle) {
      return this.renderVehicleBlock(vehicle)
    } else if (icon) {
      return icon
    }
  }

  renderChildCases = (childCases: TimelineChildItem[]) => {
    if (childCases?.length > 0) {
      return (
        <Spacing mt={0.5}>
          <StyledAccordion data-e2e-id="accordionChild">
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <Headline variant="100" component="h6">
                <Translation id={`myLeaseplan.timeline.relatedCases`} />
              </Headline>
            </AccordionSummary>
            {childCases.map(childCase => (
              <TimelineChildCard key={childCase.caseId} childCase={childCase} />
            ))}
          </StyledAccordion>
        </Spacing>
      )
    }

    return null
  }

  renderProcessPhase = (processPhase: string) => {
    const { getProcessPhaseTranslation } = this.props

    if (processPhase) {
      return (
        <Spacing mt={0.5}>
          <GrayMainText data-e2e-id="processPhase" variant="200">
            {getProcessPhaseTranslation(
              `myLeaseplan.timeline.reportDamage.processPhase${getReportDamagePhase(
                processPhase,
              )}`,
              processPhase,
            )}
          </GrayMainText>
        </Spacing>
      )
    }

    return null
  }

  renderCardContent() {
    const { content, config, titleReplace, descriptionReplace } = this.props
    const {
      modifiedDate,
      status,
      processPhase,
      showLeftInfo,
      bookingDate,
      vehicleDocument,
      childCases,
    } = content

    return (
      <CardContent>
        {modifiedDate && this.renderModifiedDate(modifiedDate)}
        <ContentWrapper>
          <Grid alignItems="flex-start" gutter={false}>
            {showLeftInfo && (
              <GridItem
                span={{ mobile: 12, tablet: 2 }}
                data-e2e-id="leftInfoBlock"
              >
                <Spacing pr={2}>{this.renderLeftInfo()}</Spacing>
              </GridItem>
            )}
            <GridItem span="grow">
              <Spacing mb={0.5}>
                <Headline variant="100" component="h6">
                  <Translation id={config.title} replace={titleReplace} />
                  {vehicleDocument && ` - ${vehicleDocument?.Title}`}
                </Headline>
              </Spacing>
              {!config.bookingDateVisible && (
                <GrayMainText variant="200">{bookingDate}</GrayMainText>
              )}
              {!config.description && (
                <Text variant="200">
                  <Translation
                    id={config.description}
                    replace={descriptionReplace}
                  />
                </Text>
              )}
              {config.statusVisible && status && (
                <Spacing mt={0.5}>
                  <CardTag
                    backgroundColor={getColorFromStatus(status)}
                    dataE2eId={`${camelCase(status)}Status`}
                  >
                    <Translation
                      id={`myLeaseplan.timeline.status.${camelCase(status)}`}
                    />
                  </CardTag>
                </Spacing>
              )}
              {this.renderProcessPhase(processPhase)}
              {this.renderChildCases(childCases)}
            </GridItem>
            {config.linkToDetails && (
              <GridItemCentered>
                <ChevronRightIcon />
              </GridItemCentered>
            )}
          </Grid>
        </ContentWrapper>
      </CardContent>
    )
  }

  isLink = () => {
    const { content, config } = this.props
    const isBooking = Boolean(
      config.linkToDetails && content.eventDetails?.bookingId,
    )
    const isDamage = Boolean(config.linkToDetails && config.redirectToDetails)

    return isBooking || isDamage
  }

  render() {
    const { content, config } = this.props

    return (
      <Spacing mb={1}>
        <StyledCard hover={Boolean(config.linkToDetails)} hoverElevation={4}>
          {this.isLink() ? (
            <StyledRouteLink
              to={config.linkToDetails}
              params={
                config.redirectToDetails
                  ? {}
                  : { id: content.eventDetails?.bookingId }
              }
            >
              {this.renderCardContent()}
            </StyledRouteLink>
          ) : (
            this.renderCardContent()
          )}
        </StyledCard>
      </Spacing>
    )
  }
}
type ReduxProps = Pick<Props, 'getProcessPhaseTranslation'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  getProcessPhaseTranslation: (key: string, fallback: string): string =>
    getTranslation(state, key, fallback),
})

export default connect(mapStateToProps)(TimelineCard)
