import { ErrorPage, TrafficConeIcon, Link } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'

export interface Props {
  title: string
  link?: {
    to: string
    description: React.ReactElement | string
  }
}

export const SomethingWentWrong: React.FC<Props> = ({ title, link }) => {
  const isMobileApp = useSelector(getIsMobileApp)

  useEffect(() => {
    if (isMobileApp) {
      postMessage({ type: PostMessageType.InternalServerError })
    }
  }, [isMobileApp])

  return (
    <div data-e2e-id="something-went-wrong">
      <ErrorPage
        title={title}
        icon={TrafficConeIcon}
        description={<Translation id="myLeaseplan.somethingWentWrong.intro" />}
      >
        <Text component="p">
          <Translation id="myLeaseplan.somethingWentWrong.goTo" />{' '}
          <Link component={RouteLink} to={link?.to || 'myLeaseplan.dashboard'}>
            {link?.description || (
              <Translation id="myLeaseplan.subNavigation.dashboard" />
            )}
          </Link>
        </Text>
      </ErrorPage>
    </div>
  )
}

export default SomethingWentWrong
