import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { Column } from 'mlp-client/src/components/table/types'

import { TableData } from './types'

export interface Props extends RouteComponentProps {
  item: TableData
  handleClick(item: TableData): void
  columns: readonly Column[]
  vehicleRoute: string
  dashboardRoute: string
}

class RowLink extends React.PureComponent<Props> {
  handleClick = () => {
    const {
      item,
      handleClick,
      vehicleRoute,
      dashboardRoute,
      history,
    } = this.props

    handleClick(item)

    if (dashboardRoute) {
      history.push(dashboardRoute)
    } else {
      history.push(`${vehicleRoute}${item.id}`)
    }
  }

  render() {
    return <tbody onClick={this.handleClick}>{this.props.children}</tbody>
  }
}

export { RowLink }
export default withRouter(RowLink)
