import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

import { AppState } from 'mlp-client/src/types'

export interface Props extends RouteComponentProps<{}> {
  desiredLocale?: string
  url?: string
}

/**
 * This component is used in the My LeasePlan onboarding and password reset
 * flow. If we receive both a url and a desiredLocale passed as query params,
 * we store the desiredLocale in localstorage, and redirect the user to the
 * supplied url. This is a url pointing to auth0 password reset page, where the
 * user can set his/her password and upon submission of that form will be
 * redirected back to this page, without any query params.
 * Then this components retrieves the desiredLocale from the localStorage
 * and uses it to redirect the user to our localized trigger page to redirect to
 * the auth0 login page, in the language of their desired locale.
 *
 * If we for some reason don't find any of the properties needed for this trick
 * to work, we redirect the user to origin of the current url.
 *
 * This funky redirecting is needed because of a limitation of the auth0 API
 * when redirecting back from email templates.
 * See https://auth0.com/docs/email/templates#configuring-the-redirect-to-url
 * for more info.
 */

class RedirectToCorrectAuth0Locale extends React.PureComponent<Props> {
  componentDidMount() {
    const { url, desiredLocale } = this.props

    if (desiredLocale) {
      window.localStorage.setItem('desiredLocale', desiredLocale)
    }

    if (desiredLocale && url) {
      window.location.assign(url)
    } else {
      const locale = window.localStorage.getItem('desiredLocale')

      if (locale) {
        window.localStorage.removeItem('desiredLocale')
        window.location.assign(
          `${window.location.origin}/${locale}/auth/authorize/?path=%2F${locale}%2Fmy`,
        )
      } else {
        window.location.assign(window.location.origin)
      }
    }
  }

  render() {
    return <div />
  }
}

type ReduxProps = Pick<Props, 'desiredLocale' | 'url'>
type DispatchProps = Pick<Props, never>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): ReduxProps => {
  const urlParams = new URLSearchParams(ownProps.location.search)
  const desiredLocale = urlParams.get('locale')
  const urlParam = urlParams.get('url')

  if (urlParam) {
    const url = new URL(urlParam)

    url.searchParams.set('lang', desiredLocale.split('-')[0])
    url.searchParams.set('ui_locales', desiredLocale.split('-')[0])

    return {
      desiredLocale,
      url: url.toString(),
    }
  } else {
    return { desiredLocale }
  }
}

export { RedirectToCorrectAuth0Locale }
export default withRouter(
  connect(mapStateToProps)(RedirectToCorrectAuth0Locale),
)
