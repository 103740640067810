import Classnames from 'classnames'
import React from 'react'

interface DefaultProps {
  title: string
  height: number
  width: number
  className: string
  modifier: string
}

type Props = Partial<DefaultProps>

export default class ChevronCircleDownIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: '',
    modifier: '',
    height: 23,
    width: 23,
    title: 'Circled arrow down',
  }

  render() {
    return (
      <svg
        className={Classnames(
          'chevron-circle-down-icon',
          {
            [`chevron-circle-down-icon--${this.props.modifier || ''}`]: !!this
              .props.modifier,
          },
          this.props.className,
        )}
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 23 23"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{this.props.title}</title>
        <g>
          <path d="M16.872 9.86c.17-.17.17-.447 0-.617L15.74 8.127c-.172-.17-.445-.17-.615 0L11.5 11.72 7.875 8.127c-.17-.17-.443-.17-.614 0L6.13 9.243c-.17.17-.17.447 0 .616l5.065 5.013c.17.17.444.17.614 0l5.065-5.014z" />
          <circle cx="11.5" cy="11.5" r="10.5" />
        </g>
      </svg>
    )
  }
}
