import { Color, Grid, GridItem, Link, Paper, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import Loader from 'mlp-client/src/components/loader/Loader'
import { ContentWrapper } from 'mlp-client/src/contracts/components/ContractPropertiesTable.styled'
import {
  PolicyDocument,
  PolicyDocumentAttachment,
  PolicyDocuments as PolicyDocumentsType,
} from 'mlp-client/src/contracts/types'
import { getBaseFileName, getExtension } from 'mlp-client/src/contracts/utils'
import { AppState } from 'mlp-client/src/types'
import {
  getPolicyDocuments,
  isPolicyDocumentsEnabled,
  isPolicyDocumentsLoading,
} from 'mlp-client/src/contracts/selectors'
import {
  DownloadPolicyDocument,
  LoadPolicyDocuments,
} from 'mlp-client/src/contracts/actions'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export interface Props {
  policyDocuments: PolicyDocumentsType
  policyDocumentsEnabled: boolean
  isLoading: boolean
  loadPolicyDocuments(): void
  downloadPolicyDocument(attachment: PolicyDocumentAttachment): void
}

export const LinkWithoutUnderline = styled(Link)`
  text-decoration: none;
`
const SeeAllLink = styled(RouteLink)`
  text-decoration: none;
  color: ${Color.steel};
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`
const GridItemWordWrap = styled(GridItem)`
  word-break: break-word;
`

const SText = styled(Text)`
  color: ${props => props.theme.velocity.styling.primary.main};
`

export class PolicyDocuments extends React.PureComponent<Props> {
  componentDidMount() {
    const { loadPolicyDocuments, policyDocuments } = this.props

    if (!policyDocuments || !policyDocuments.length) {
      loadPolicyDocuments()
    }
  }

  renderDownloadLinks = (policyDocument: PolicyDocument) =>
    policyDocument.attachments.map(this.renderDownloadLink)

  /*
   * generates the links for the documents including the name
   * of the document and the extracted extension
   */
  renderDownloadLink = (attachment: PolicyDocumentAttachment) => {
    const extension = getExtension(attachment.name)
    const baseFileName = getBaseFileName(attachment.name)

    return (
      <LinkWithoutUnderline
        key={attachment.id}
        onClick={this.downloadPolicyDoc(attachment)}
      >
        <Grid wrap="nowrap">
          <GridItemWordWrap span="grow">
            {baseFileName} ({extension})
          </GridItemWordWrap>
          <GridItem>
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                fill={ColorEnumSelector('bloodOrange')}
                d="M5 18h14v-4.5a.5.5 0 1 1 1 0v5a.498.498 0 0 1-.5.5h-15a.5.5 0 0 1-.5-.5v-5a.5.5 0 1 1 1 0V18zm6.5-3.625V5.5a.5.5 0 1 1 1 0v8.875l2.654-2.543a.511.511 0 0 1 .7 0 .46.46 0 0 1 0 .671l-3.504 3.358a.511.511 0 0 1-.7 0l-3.505-3.358a.46.46 0 0 1 0-.671.511.511 0 0 1 .701 0l2.654 2.543z"
              />
            </svg>
          </GridItem>
        </Grid>
      </LinkWithoutUnderline>
    )
  }

  downloadPolicyDoc = (attachment: PolicyDocumentAttachment) => () =>
    this.props.downloadPolicyDocument(attachment)

  hasPolicyDocuments = (): boolean =>
    this.props.policyDocuments && this.props.policyDocuments.length > 0

  render() {
    const { policyDocuments, isLoading } = this.props

    if (isLoading) {
      return <Loader loading={true} absolute={true} fixed={true} />
    }

    return (
      (this.hasPolicyDocuments() && (
        <>
          <Paper data-e2e-id="policyDocuments">
            <ContentWrapper>
              <Grid verticalGutter={false}>
                <GridItem span="grow">
                  <Headline variant="200" withMarginBottom>
                    <Translation id="myLeaseplan.contract.contract.contract.documentsButton" />
                  </Headline>
                </GridItem>
                <GridItem span="auto">
                  <SeeAllLink to="myLeaseplan.vehicleDocuments.selectDocument">
                    <Translation id="myLeaseplan.genericNotifications.seeAll" />
                    {' >'}
                  </SeeAllLink>
                </GridItem>
              </Grid>
              <Spacing mb={1} />
              <SText variant="200">
                {policyDocuments.map(this.renderDownloadLinks)}
              </SText>
            </ContentWrapper>
          </Paper>
          <Spacing mb={2} />
        </>
      )) ||
      null
    )
  }
}

type ReduxProps = Pick<
  Props,
  'policyDocuments' | 'policyDocumentsEnabled' | 'isLoading'
>
type DispatchProps = Pick<
  Props,
  'loadPolicyDocuments' | 'downloadPolicyDocument'
>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  policyDocuments: getPolicyDocuments,
  policyDocumentsEnabled: isPolicyDocumentsEnabled,
  isLoading: isPolicyDocumentsLoading,
})

const mapDispatchToProps: DispatchProps = {
  loadPolicyDocuments: () => new LoadPolicyDocuments(),
  downloadPolicyDocument: ({
    id,
    contentType,
    name,
  }: PolicyDocumentAttachment) =>
    new DownloadPolicyDocument({
      fileId: id,
      fileName: name,
      documentType: contentType,
    }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyDocuments)
