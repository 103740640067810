import React from 'react'
import styled from 'styled-components'

import {
  MainContent,
  MainContentWrapper,
} from 'mlp-client/src/components/layout'
import ClickableLogo from 'mlp-client/src/components/layout/top-navigation/desktop/ClickableLogo'

const StyledMainContentWrapper = styled(MainContentWrapper)`
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const NoNavigationWrapper: React.FC = ({ children }) => (
  <MainContent>
    <ClickableLogo />
    <StyledMainContentWrapper data-e2e-id="underMaintenance">
      {children}
    </StyledMainContentWrapper>
  </MainContent>
)

export default NoNavigationWrapper
