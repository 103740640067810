import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

class Delivered extends React.PureComponent {
  render() {
    return (
      <Translation id="myLeaseplan.genericNotifications.statusCarService.deliveredInfo.subtitle" />
    )
  }
}

export { Delivered }
export default Delivered
