import { ButtonChevronRightIcon } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'

interface ConfirmFlowButton {
  disabled?: boolean
  onClick?(): void
}

export default (props: ConfirmFlowButton) => (
  <StyledButton
    disabled={props.disabled}
    stretch={true}
    icon={ButtonChevronRightIcon}
    data-e2e-id="button-confirm-request"
    onClick={props.onClick}
  >
    <Translation id="myLeaseplan.newSetup.shared.confirmRequest" />
  </StyledButton>
)
