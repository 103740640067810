import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Text, Headline } from '@velocity/ui'
import { MessageBar } from '@velocity/ui/draft'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'
import { DamageRecords } from 'mlp-client/src/contracts/types'
import { DamageCard } from 'mlp-client/src/flows/damage-report/components/pages/damage-list-page/damage-card/DamageCard'
import { sortDamageRecords } from 'mlp-client/src/flows/damage-report/utils'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

export interface Props {
  damages: DamageRecords
  contractId: string
}

const ListBatchSize = 6

class DamageList extends React.PureComponent<Props> {
  renderCards = (cards: DamageRecords) =>
    cards.map(card => <DamageCard {...card} key={card.description} />)

  render() {
    const { damages, contractId } = this.props

    return (
      <>
        <Headline variant="400" withMarginBottom>
          <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.title" />
        </Headline>
        <Text loose>
          <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.description" />
          {` `}
          <StyledButtonLink
            to="myLeaseplan.damageReport.stolenVehicle"
            component={RouteLink}
          >
            <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.link" />
          </StyledButtonLink>
        </Text>
        <SMessageBarContainer>
          <MessageBar variant="warning">
            <MessageBar.Text>
              <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.pageSubtitle" />
            </MessageBar.Text>
          </MessageBar>
        </SMessageBarContainer>
        <Spacing mv={2}>
          <Grid>
            {Boolean(damages.length) && (
              <GridItem span="grow">
                <SecondaryDarkText variant="500" bold>
                  <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.pageTitle" />
                </SecondaryDarkText>
              </GridItem>
            )}
            <GridItem>
              <StyledButton
                component={RouteLink}
                to="myLeaseplan.damageReport.base"
                params={{ contractId, step: '' }}
              >
                <span>+&nbsp;</span>
                <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.reportDamage" />
              </StyledButton>
            </GridItem>
          </Grid>
        </Spacing>
        <RevealingItemList
          batchSize={ListBatchSize}
          items={[...damages].sort(sortDamageRecords)}
        >
          {this.renderCards}
        </RevealingItemList>
      </>
    )
  }
}
export { DamageList }
export default DamageList
