import Dashboard, {
  Config,
} from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import {
  CashClaimIcon,
  DamageIcon,
  DocumentsIcon,
  GlassIcon,
  MaintenanceIcon,
  MileageIcon,
  TyresIcon,
  FuelCardIcon,
  ReturnIcon,
} from 'mlp-client/src/dashboard/components/icons'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  FuelCardTile,
  MaintenanceTile,
  ReturnTile,
  DamageReportTile,
} from 'mlp-client/src/dashboard/components/tiles'
import { MileageUpdateModalTrigger } from 'mlp-client/src/dashboard/components/MileageUpdateModalTrigger'
import { RoadSideAssistanceWithPhoneNumbers } from 'mlp-client/src/components/road-side-assistance/components'

export const dashboardConfig: MyLeaseplanFeature<Config> = {
  enabled: true,
  view: Dashboard,
  configuration: {
    alwaysDisplayFuelCardTile: false,
    numberOfDaysToReturn: 60,
    showNolsWarning: true,
    servicesConfig: () => [
      {
        view: MaintenanceTile,
        title: 'myLeaseplan.serviceRequest.maintenance.dashboard.title',
        summary: 'myLeaseplan.serviceRequest.maintenance.dashboard.summary',
        disabledTitle:
          'myLeaseplan.serviceRequest.maintenance.dashboard.notAvailableTitle',
        disabledSummary:
          'myLeaseplan.serviceRequest.maintenance.dashboard.notAvailableSummary',
        to: 'myLeaseplan.maintenance.base',
        icon: MaintenanceIcon,
        name: 'maintenance',
      },
      {
        view: DamageReportTile,
        name: 'damageReport',
        title: 'myLeaseplan.damageReport.dashboard.title',
        summary: 'myLeaseplan.damageReport.dashboard.summary',
        disabledTitle:
          'myLeaseplan.serviceRequest.damageReport.dashboard.notAvailableTitle',
        disabledSummary:
          'myLeaseplan.serviceRequest.damageReport.dashboard.notAvailableSummary',
        icon: DamageIcon,
        to: 'myLeaseplan.damageReport.damageList',
      },
      {
        name: 'tyres',
        title: 'myLeaseplan.tyres.dashboard.title',
        summary: 'myLeaseplan.tyres.dashboard.summary',
        disabledTitle:
          'myLeaseplan.serviceRequest.tyres.dashboard.notAvailableTitle',
        disabledSummary:
          'myLeaseplan.serviceRequest.tyres.dashboard.notAvailableSummary',
        to: 'myLeaseplan.tyres.base',
        icon: TyresIcon,
      },
      {
        title: 'myLeaseplan.glassRepair.dashboard.title',
        summary: 'myLeaseplan.glassRepair.dashboard.summary',
        disabledTitle:
          'myLeaseplan.serviceRequest.glassRepair.dashboard.notAvailableTitle',
        disabledSummary:
          'myLeaseplan.serviceRequest.glassRepair.dashboard.notAvailableSummary',
        to: 'myLeaseplan.glassRepair.base',
        icon: GlassIcon,
        name: 'glassRepair',
      },
    ],
    quickActionsConfig: () => [
      {
        modalTrigger: MileageUpdateModalTrigger,
        hasModal: true,
        title: 'myLeaseplan.mileageUpdate.dashboard.title',
        summary: 'myLeaseplan.mileageUpdate.dashboard.summary',
        disabledTitle: 'myLeaseplan.mileageUpdate.dashboard.notAvailableTitle',
        disabledSummary:
          'myLeaseplan.mileageUpdate.dashboard.notAvailableSummary',
        icon: MileageIcon,
        name: 'mileageUpdate',
        to: 'myLeaseplan.mileageUpdate.base',
      },
      {
        view: ReturnTile,
        summary: 'myLeaseplan.return.dashboard.summary',
        disabledSummary: 'myLeaseplan.return.dashboard.notAvailableSummary',
        to: 'myLeaseplan.endOfLease.base',
        icon: ReturnIcon,
        name: 'return',
      },
      {
        name: 'cashClaims',
        title: 'myLeaseplan.cashClaim.dashboard.title',
        summary: 'myLeaseplan.cashClaim.dashboard.summary',
        disabledTitle: 'myLeaseplan.cashClaim.dashboard.notAvailableTitle',
        disabledSummary: 'myLeaseplan.cashClaim.dashboard.notAvailableSummary',
        to: 'myLeaseplan.cashClaim.base',
        icon: CashClaimIcon,
      },
      {
        view: FuelCardTile,
        name: 'fuelCard',
        title: 'myLeaseplan.fuelCard.dashboard.title',
        summary: 'myLeaseplan.fuelCard.dashboard.summary',
        disabledTitle: 'myLeaseplan.fuelCard.dashboard.notAvailableTitle',
        disabledSummary: 'myLeaseplan.fuelCard.dashboard.notAvailableSummary',
        to: 'myLeaseplan.fuelCard.base',
        icon: FuelCardIcon,
      },
      {
        name: 'vehicleDocuments',
        title: 'myLeaseplan.vehicleDocuments.dashboard.title',
        summary: 'myLeaseplan.vehicleDocuments.dashboard.summary',
        disabledTitle:
          'myLeaseplan.vehicleDocuments.dashboard.notAvailableTitle',
        disabledSummary:
          'myLeaseplan.vehicleDocuments.dashboard.notAvailableSummary',
        to: 'myLeaseplan.vehicleDocuments.selectDocument',
        icon: DocumentsIcon,
      },
    ],
    roadsideAssistanceConfig: () => ({
      enabled: true,
      view: RoadSideAssistanceWithPhoneNumbers,
      configuration: {
        primaryNumber:
          'myLeaseplan.dashboard.roadsideAssistance.needHelp.primaryNumber',
        primaryNumberIntro:
          'myLeaseplan.dashboard.roadsideAssistance.needHelp.denmark',
        secondaryNumber:
          'myLeaseplan.dashboard.roadsideAssistance.needHelp.secondaryNumber',
        secondaryNumberIntro:
          'myLeaseplan.dashboard.roadsideAssistance.needHelp.fromEu',
      },
    }),
  },
}
