import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class DocumentsIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M35.4 10.7a1.5 1.5 0 1 1 0 3H14.3a1.5 1.5 0 1 1 0-3h21.1zm0 7a1.5 1.5 0 1 1 0 3H14.3a1.5 1.5 0 1 1 0-3h21.1zm0 7.1a1.5 1.5 0 1 1 0 3H14.3a1.5 1.5 0 1 1 0-3h21.1zM23.6 36.3a1.5 1.5 0 1 1 0 3h-9.3a1.5 1.5 0 1 1 0-3h9.3zM53 58.4V19.2a1 1 0 0 0-1-1h-2.6v33.6a4 4 0 0 1-4 3.9H23.7v2.7c0 .5.4 1 1 1H52c.5 0 .9-.5.9-1zm-36-6.6c0 .5.4.9 1 .9h27.4a1 1 0 0 0 1-1V12.7a1 1 0 0 0-1-1h-2.7v33.5a4 4 0 0 1-4 4H17v2.7zM10.4 45c0 .5.4 1 1 1h27.4a1 1 0 0 0 .9-1V6a1 1 0 0 0-1-.9H11.4a1 1 0 0 0-1 1V45zM52 15.3a4 4 0 0 1 3.9 4v39.1a4 4 0 0 1-4 4H24.7a4 4 0 0 1-4-4v-2.7H18a4 4 0 0 1-4-4V49h-2.7a4 4 0 0 1-4-3.9V6a4 4 0 0 1 4-3.9h27.5a4 4 0 0 1 4 4v2.6h2.6a4 4 0 0 1 4 4v2.7H52z"
        />
      </svg>
    )
  }
}

export default DocumentsIcon
