import { ButtonLink, Card, Grid, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { Locales } from 'mlp-client/src/localization/enums'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import {
  getTranslations,
  getUserLocale,
} from 'mlp-client/src/localization/selectors'
import { FeatureConfiguration } from 'mlp-client/src/attach-car/types'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import DynamicLink from 'mlp-client/src/components/dynamic-link/DynamicLink'
import { MainContentCenter } from 'mlp-client/src/components/layout/main-content-center/MainContentCenter'
import { AppState } from 'mlp-client/src/types'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'
import { StyledNextButton } from 'mlp-client/src/components/button/StyledButton'

import { CenteredGridItem, Logo } from './NoCarNoContractDashboard.styled'

export interface Props extends MyLeaseplanFeatureProps<FeatureConfiguration> {
  metaDescription: string
  metaTitle: string
  locale: Locales
}

type ButtonLink = FeatureConfiguration['buttonLink']

const defaultConfiguration: FeatureConfiguration = {
  isRequestAccountLinkEnabled: false,
}

class NoCarNoContractDashboard extends React.PureComponent<Props> {
  getButtonLinkUrl(buttonLink: ButtonLink | undefined): string | undefined {
    if (!buttonLink) {
      return
    }

    return typeof buttonLink.url === 'string'
      ? buttonLink.url
      : buttonLink.url[this.props.locale]
  }

  render() {
    const {
      metaTitle,
      metaDescription,
      featureConfig: { configuration = defaultConfiguration },
    } = this.props
    const {
      descriptionTextId,
      buttonLink,
      isRequestAccountLinkEnabled,
    } = configuration
    const buttonLinkUrl = this.getButtonLinkUrl(buttonLink)

    return (
      <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
        <MainContentCenter>
          <Grid justifyContent="center" data-e2e-id="noContractDashboard">
            <CenteredGridItem>
              <Card elevation={0}>
                <Spacing pv={4} ph={3}>
                  <Logo />
                  <Headline variant="400" withMarginBottom>
                    <Translation id="myLeaseplan.dashboard.welcomeToMyLeasePlan" />
                  </Headline>
                  {descriptionTextId && (
                    <Spacing mt={3}>
                      <Text>
                        <Translation
                          id={descriptionTextId}
                          data-e2e-id="descriptionText"
                        />
                      </Text>
                    </Spacing>
                  )}
                  {buttonLinkUrl && (
                    <Spacing mt={3}>
                      <StyledNextButton
                        to={buttonLinkUrl}
                        component={DynamicLink}
                        data-e2e-id="carsOverview"
                      >
                        <Translation id={buttonLink.labelId} />
                      </StyledNextButton>
                    </Spacing>
                  )}
                  {isRequestAccountLinkEnabled && (
                    <Spacing mt={3}>
                      <StyledButtonLink
                        fontWeight="regular"
                        component={RouteLink}
                        to="myLeaseplan.requestAccount.base"
                        data-e2e-id="requestAccount"
                      >
                        <Translation id="myLeaseplan.dashboard.alreadyHaveACar" />
                      </StyledButtonLink>
                    </Spacing>
                  )}
                </Spacing>
              </Card>
            </CenteredGridItem>
          </Grid>
        </MainContentCenter>
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'metaDescription' | 'metaTitle' | 'locale'>

const mapTranslationsToProps = {
  metaTitle: 'myLeaseplan.dashboard.title',
  metaDescription: 'myLeaseplan.dashboard.subtitle',
}

export const mapStateToProps = (state: AppState): ReduxProps => ({
  locale: getUserLocale(state),
  ...getTranslations(state, mapTranslationsToProps),
})

export { NoCarNoContractDashboard }
export default connect(mapStateToProps)(NoCarNoContractDashboard)
