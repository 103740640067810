import React, { useState } from 'react'
import { GoogleMap, GoogleMapProps } from '@react-google-maps/api'

const defaultMapOptions: GoogleMapProps['options'] = {
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  rotateControl: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  clickableIcons: false,
}

export interface Props {
  markers: readonly React.ReactNode[]
  handleMapInstance(mapInstance: google.maps.Map): void
  mapOptions?: GoogleMapProps
}

const MapWithMarkers: React.FC<Props> = props => {
  const [renderMarkers, setRenderMarkers] = useState(false)

  const onLoad = React.useCallback(
    map => {
      props.handleMapInstance(map)
      setRenderMarkers(true)
    },
    [props],
  )

  const { markers, mapOptions } = props

  return (
    <GoogleMap
      mapContainerStyle={{ height: '100%', width: '100%', minHeight: '50vh' }}
      zoom={9}
      options={defaultMapOptions}
      onLoad={onLoad}
      {...mapOptions}
    >
      {renderMarkers && markers}
    </GoogleMap>
  )
}

export { MapWithMarkers }
export default React.memo(MapWithMarkers)
