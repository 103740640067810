import { push } from 'connected-react-router'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import {
  getLocalizedRoute,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import * as actions from 'mlp-client/src/complaints/actions'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import { User } from 'mlp-client/src/user/types'
import { getUser } from 'mlp-client/src/user/selectors'

export function* submitComplaint({
  payload: { formData, onComplete },
}: actions.SubmitComplaint): SagaIterator {
  const { topic, description } = formData
  let parsedTopic

  try {
    parsedTopic = JSON.parse(topic)
  } catch {
    parsedTopic = {}
  }

  const user: User = yield select(getUser)
  const contractId = yield select(getCurrentContractId)
  const body = {
    contractId,
    userId: user.id,
    detailedMessage: description,
    caseCategory: parsedTopic.category,
    caseSubCategory: parsedTopic.subCategory,
    serviceType: parsedTopic.serviceType,
  }
  const options = {
    throwError: true,
  }

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/customerSupport',
      { body },
      options,
    )

    const thankYouUrl = yield select(
      getLocalizedRoute,
      'myLeaseplan.complaints.thankYou',
    )

    yield call(onComplete)
    yield put(push(thankYouUrl))
  } catch {
    const error = yield select(getTranslation, 'myLeaseplan.complaint.failed')

    yield call(onComplete, { error })
  }
}

export default [
  takeLatest(actions.ActionTypes.SUBMIT_COMPLAINT, submitComplaint),
]
