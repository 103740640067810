import { Login } from 'mlp-client/src/components/login/types'

export const loginConfig: Login = {
  showRegistrationLink: false,
  loginPages: [
    {
      labelTranslation: 'myLeaseplan.loginPages.leasePlanOnline.label',
      linkTextTranslation: 'myLeaseplan.loginPages.leasePlanOnline.linkText',
      url: 'https://myfleet.leaseplan.de/',
    },
    {
      linkTextTranslation: 'myLeaseplan.loginPages.leasePlanOnline.register',
      url: 'https://e-driver.leaseplan.de/edriver/selfregistration.do?act=init',
    },
    {
      linkTextTranslation:
        'myLeaseplan.loginPages.leasePlanOnline.forgotPassword',
      url: 'https://myfleet.leaseplan.de/',
    },
  ],
}
