import React, { PureComponent, ReactNode } from 'react'

import { EmptyStateWide } from './EmptyState'
import { Row } from './Row'
import { Column, Datum, RowsConfiguration } from './types'

export interface Props {
  columns: readonly Column[]
  rows?: RowsConfiguration
  data: readonly Datum[]
  emptyState?: ReactNode | null
  defaultValue?: ReactNode | null
}

export class TableWideBody extends PureComponent<Props> {
  render() {
    const { data, rows, columns, emptyState, defaultValue } = this.props

    if (!data) {
      return (
        <EmptyStateWide columnsAmount={columns.length}>
          {emptyState}
        </EmptyStateWide>
      )
    }

    if (rows && rows.TrComponent) {
      return data.map(item => (
        <rows.TrComponent
          key={item.key}
          {...rows.props}
          item={item}
          columns={columns}
        >
          <Row
            columns={columns}
            item={item}
            rowsProps={rows && rows.props}
            defaultValue={defaultValue}
          />
        </rows.TrComponent>
      ))
    }

    return (
      <tbody>
        {data.map(item => (
          <Row
            key={item.key}
            columns={columns}
            item={item}
            defaultValue={defaultValue}
            rowsProps={rows && rows.props}
          />
        ))}
      </tbody>
    )
  }
}

export default TableWideBody
