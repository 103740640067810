import {
  Collapse,
  CollapseContent,
  CollapseTrigger,
  Paper,
  Spacing,
} from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import {
  CollapsibleWrapper,
  ContentWrapper,
} from 'mlp-client/src/contracts/components/ContractPropertiesTable.styled'

const DATA_E2E_ID = 'dataE2eId'

export interface Props {
  titleTranslationId: string
  [DATA_E2E_ID]?: string
  children?: React.ReactNode
}

export class SectionProperties extends React.PureComponent<Props> {
  render() {
    const { titleTranslationId, children } = this.props

    // All sections should be collapisble & open by default.
    return (
      <Spacing pb={2}>
        <Paper>
          <ContentWrapper>
            <Collapse defaultOpen={true}>
              <CollapseTrigger data-e2e-id={titleTranslationId}>
                <Headline
                  variant="200"
                  data-e2e-id={this.props[DATA_E2E_ID]}
                  withMarginBottom
                  component="h1"
                >
                  <Translation id={titleTranslationId} />
                </Headline>
              </CollapseTrigger>
              <CollapseContent>
                <CollapsibleWrapper>{children}</CollapsibleWrapper>
              </CollapseContent>
            </Collapse>
          </ContentWrapper>
        </Paper>
      </Spacing>
    )
  }
}
