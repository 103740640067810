import { CardContent, Grid, GridItem, Spacing } from '@leaseplan/ui'
import filesize from 'filesize'
import React from 'react'
import Dropzone, { DropFilesEventHandler, ImageFile } from 'react-dropzone'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import isEqual from 'lodash/isEqual'
import { PlusIcon } from '@velocity/ui/draft'

import { AppState } from 'mlp-client/src/types'
import Translation from 'mlp-client/src/localization/Translation'
import { CameraIcon } from 'mlp-client/src/components/icons'
import {
  SecondaryDarkText,
  PrimaryDarkText,
} from 'mlp-client/src/components/styled/TextStyle'

import { AddFiles, RemoveFile, SubmitFileUpload } from './actions'
import { FileStatus } from './enums'
import { FileItem } from './FileItem'
import { StyledCard, StyledField } from './File.styled'
import { FileList, File as FileType, Files, RejectedFiles } from './types'
import { addFile, removeFile } from './utils'
import { getFiles } from './selectors'

interface FieldFileProps {
  value: Files
  className?: string
  example?: string
  hideLabel?: boolean
  label?: string
  name?: string
  accept?: string // comma separated list of mimetypes
  activeClassName?: string
  disableClick?: boolean
  disablePreview?: boolean
  disabled?: boolean
  disabledClassName?: string
  onChange(e: any): void
  maxSize?: number
  minSize?: number
  multiple?: boolean
  preventDropOnDocument?: boolean
  rejectClassName?: string
  uploadButtonText?: string
  unsupportedFileTypeText?: string
  fileTooLargeText?: string
  files: FileList
  uploadFile(file: File, meta: FileType): void
  removeFile(fileId: string): void
  addFiles(files: Files): void
}

type Props = FieldFileProps

interface State {
  rejectedFiles: RejectedFiles
}

export class FileUpload extends React.PureComponent<Props, State> {
  state: State = {
    rejectedFiles: [],
  }

  static defaultProps: Partial<FieldFileProps> = {
    accept: '',
    activeClassName: 'field-file--active',
    className: '',
    disableClick: false,
    disabled: false,
    disablePreview: false,
    disabledClassName: 'field-file--disabled',
    label: '',
    uploadButtonText: 'myLeaseplan.shared.file.uploadButtonText',
    unsupportedFileTypeText: 'myLeaseplan.shared.file.unsupportedFileType',
    fileTooLargeText: 'myLeaseplan.shared.file.fileTooLarge',
    maxSize: 25000000, // 25 MB
    minSize: 0,
    multiple: true,
    name: 'FileUploadName',
    preventDropOnDocument: true,
    rejectClassName: 'field-file--reject',
  }

  componentDidMount(): void {
    const { value, addFiles } = this.props
    const files: Files = value

    addFiles(files)
  }

  componentWillUnmount(): void {
    const { value, removeFile } = this.props
    const files: Files = value

    files.forEach(file => removeFile(file.id))
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { files, onChange } = this.props

    if (!isEqual(prevProps.files, files)) {
      onChange(Object.values(files))
    }
  }

  onFileAdd = (file: FileType) => {
    const { value, onChange } = this.props

    if (value !== undefined) {
      onChange(addFile(value, file))
    }
  }

  onFileRemove = (fileId: string) => {
    const { value, onChange } = this.props

    if (value) {
      onChange(removeFile(value, fileId))
    }

    this.props.removeFile(fileId)
  }

  onDrop: DropFilesEventHandler = (
    acceptedFiles: ImageFile[],
    rejectedFiles: ImageFile[],
  ): void => {
    this.setState({ rejectedFiles: [] })
    acceptedFiles.forEach(file => {
      const displayFile = {
        name: file.name,
        preview: file.preview,
        progress: 0,
        status: FileStatus.UPLOADING,
        id: String(Math.random()),
        key: '',
      }

      this.props.uploadFile(file, displayFile)
      this.onFileAdd(displayFile)
    })

    rejectedFiles.forEach(({ name, size }) => {
      const { maxSize, fileTooLargeText, unsupportedFileTypeText } = this.props

      const translationId =
        size > maxSize ? fileTooLargeText : unsupportedFileTypeText

      this.setState(state => ({
        rejectedFiles: state.rejectedFiles.concat({
          translationId,
          name,
          maxFileSize: filesize(maxSize, { base: 10 }),
          supportedFileTypes: this.getSupportedFileTypes(),
        }),
      }))
    })
  }

  renderFiles = () => {
    if (this.props.value) {
      const files: Files = this.props.value

      return files.map((file, i) => (
        <Spacing key={i} mb={1}>
          <FileItem
            file={file}
            fileId={file.id}
            onFileRemove={this.onFileRemove}
          />
        </Spacing>
      ))
    }
  }

  getSupportedFileTypes = () => {
    const { accept } = this.props

    if (accept) {
      return accept
        .split(',')
        .map(str => str.trim().replace('.', '').toLowerCase().split('/'))
        .map(val => (val[1] ? (val[1] === '*' ? val[0] : val[1]) : val[0]))
        .join(', ')
    }
  }

  renderRejectedFilesMessage = () => {
    if (!this.state.rejectedFiles || this.state.rejectedFiles.length < 1) {
      return
    }

    return this.state.rejectedFiles.map(
      ({ translationId, ...fileProps }, index) => (
        <Spacing key={`reject-${index}`} mb={1}>
          <PrimaryDarkText>
            <Translation
              element="span"
              id={translationId}
              replace={{
                name: fileProps.name,
                maxFileSize: fileProps.maxFileSize,
                supportedFileTypes: fileProps.supportedFileTypes,
              }}
            />
          </PrimaryDarkText>
        </Spacing>
      ),
    )
  }

  render() {
    const {
      accept,
      activeClassName,
      children,
      className,
      disableClick,
      disablePreview,
      disabled,
      disabledClassName,
      example,
      hideLabel,
      label,
      name,
      maxSize,
      minSize,
      multiple,
      preventDropOnDocument,
      rejectClassName,
      uploadButtonText,
    } = this.props

    const hasRejectedFiles = Boolean(
      this.state.rejectedFiles && this.state.rejectedFiles.length > 0,
    )

    const dropzoneArea = (
      <StyledCard
        border={true}
        borderColor={hasRejectedFiles ? 'bloodOrange' : 'steel20'}
        backgroundColor={hasRejectedFiles ? 'error20' : 'white'}
      >
        <CardContent>
          <Grid alignItems="center">
            <GridItem>
              <CameraIcon width={34} height={34} />
            </GridItem>
            <GridItem span="grow">
              <SecondaryDarkText bold>
                <Translation element="span" id={uploadButtonText} />
              </SecondaryDarkText>
            </GridItem>
            <GridItem>
              <PlusIcon size="xs" color="#D8D8D8" className="plus-icon" />
            </GridItem>
          </Grid>
        </CardContent>
      </StyledCard>
    )

    return (
      <StyledField className={className}>
        {label && (
          <Translation
            element="label"
            id={label}
            className={`field__label${hideLabel ? ' u-hidden-visually' : ''}`}
            htmlFor={`field--${name}`}
          />
        )}
        {example && (
          <Translation
            element="span"
            id={example}
            className="u-txt--14 u-txt--block u-margin-bottom"
          />
        )}
        <Spacing mb={1}>
          <Dropzone
            className="file"
            accept={accept}
            activeClassName={activeClassName}
            disableClick={disableClick}
            disablePreview={disablePreview}
            disabled={disabled}
            disabledClassName={disabledClassName}
            maxSize={maxSize}
            minSize={minSize}
            multiple={multiple}
            name={name}
            onDrop={this.onDrop}
            preventDropOnDocument={preventDropOnDocument}
            rejectClassName={rejectClassName}
          >
            {children || dropzoneArea}
          </Dropzone>
        </Spacing>

        {this.renderRejectedFilesMessage()}

        {this.renderFiles()}
      </StyledField>
    )
  }
}

type DispatchProps = Pick<Props, 'uploadFile' | 'removeFile' | 'addFiles'>
type ReduxProps = Pick<Props, 'files'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  files: getFiles(state),
})
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  uploadFile: (file: ImageFile, meta: FileType) => {
    dispatch(new SubmitFileUpload({ file, meta }))
  },
  removeFile: (fileId: string) => {
    dispatch(new RemoveFile({ fileId }))
  },
  addFiles: (files: Files) => {
    dispatch(new AddFiles({ meta: files }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload)
