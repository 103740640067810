import { SagaIterator } from 'redux-saga'
import { call, put, select, take, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import {
  ActionTypes,
  ResetNotifications,
} from 'mlp-client/src/contracts/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { isPredictiveMaintenanceEnabled as isPredictiveMaintenanceEnabledSelector } from 'mlp-client/src/flows/predictive-maintenance/selectors'
import { getCountry } from 'mlp-client/src/localization/selectors'
import { loadNotifications } from 'mlp-client/src/contracts/sagas'
import {
  getCurrentContract,
  getPredictiveMaintenanceNotification,
} from 'mlp-client/src/contracts/selectors'
import { NotificationStatus } from 'mlp-client/src/contracts/enums'
import { BookingResult } from 'mlp-client/src/types'

import { getMalfunctionsWithTranslatedValues, mapSubmitBody } from './utils'
import { Malfunctions, RepairMaintenanceDataResponse } from './types'
import * as actions from './actions'

export function* loadRepairMaintenanceData(
  action: actions.LoadRepairMaintenanceData,
): SagaIterator {
  const {
    flowData: { locale, contractId },
  } = action.payload
  const user = yield select(getUser)
  const requestUrl = '/myleaseplan/lpis/malfunctions'
  const requestParams = {
    params: {
      locale,
      contractId,
      userId: user.id,
    },
  }
  const options = {
    throwError: true,
  }

  try {
    const result: Omit<RepairMaintenanceDataResponse, 'malfunctions'> & {
      malfunctions: readonly string[]
    } = yield call(ApiSagas.get, requestUrl, requestParams, options)

    // Override the value of malfunctions with sitecore translations.
    const malfunctions: Malfunctions = yield select(
      getMalfunctionsWithTranslatedValues,
      result.malfunctions,
    )

    const repairMaintenanceData: RepairMaintenanceDataResponse = {
      ...result,
      malfunctions,
    }

    yield put(
      new actions.LoadRepairMaintenanceDataSuccess({
        data: repairMaintenanceData,
      }),
    )
  } catch {
    // API_ERROR already dispatched
  }
}

export function* submitMaintenanceBooking(
  action: actions.SubmitMaintenanceBooking,
): SagaIterator {
  const { flowData, onComplete } = action.payload
  let caseId: string
  const isPredictiveMaintenanceEnabled = yield select(
    isPredictiveMaintenanceEnabledSelector,
  )

  if (isPredictiveMaintenanceEnabled) {
    yield call(loadNotifications)
    const loadNotificationsResult = yield take([
      ActionTypes.LOAD_NOTIFICATIONS_FAILED,
      ActionTypes.LOAD_NOTIFICATIONS_SUCCESS,
    ])

    if (
      loadNotificationsResult.type === ActionTypes.LOAD_NOTIFICATIONS_FAILED
    ) {
      return
    }

    const notification = yield select(getPredictiveMaintenanceNotification)

    caseId = notification?.notificationDetails?.caseId
  }

  const countryCode = yield select(getCountry)
  const body = yield call(mapSubmitBody, flowData, countryCode)
  const requestUrl = '/myleaseplan/lpis/bookings'
  const requestBody = {
    body: {
      ...body,
      caseId: body.regular ? caseId : undefined,
    },
  }
  const options = {
    throwError: true,
  }

  try {
    const contract = yield select(getCurrentContract)
    const bookingResult: BookingResult = yield call(
      ApiSagas.post,
      requestUrl,
      requestBody,
      options,
    )

    yield put(
      new actions.SetMaintenanceBookingResult({ data: { bookingResult } }),
    )
    yield call(onComplete, false)
    yield put(new ResetNotifications())

    sessionStorage.setItem(
      `notification_status_${contract.id}`,
      NotificationStatus.DELETED,
    )
  } catch {
    yield call(onComplete, true)
  }
}

export default [
  takeLatest(
    actions.ActionType.LOAD_REPAIR_MAINTENANCE_DATA,
    loadRepairMaintenanceData,
  ),
  takeLatest(
    actions.ActionType.SUBMIT_MAINTENANCE_BOOKING,
    submitMaintenanceBooking,
  ),
]
