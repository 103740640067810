import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

import { isInitialized } from 'mlp-client/src/init/selectors'
import { Init } from 'mlp-client/src/init/actions'
import { AppState } from 'mlp-client/src/types'

export interface Props extends RouteComponentProps {
  isInitialized: boolean
  init(selectedContractId: string): void
  children?: React.ReactNode
}

class Initializer extends React.PureComponent<Props> {
  componentDidMount(): void {
    const {
      location: { search },
    } = this.props
    const query = new URLSearchParams(search)
    const selectedContractId = query.get('contractId')

    this.props.init(selectedContractId)
  }

  render() {
    const { isInitialized, children } = this.props

    return isInitialized ? children : null
  }
}

type ReduxProps = Pick<Props, 'isInitialized'>
type DispatchProps = Pick<Props, 'init'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isInitialized,
})

const mapDispatchToProps: DispatchProps = {
  init: (selectedContractId: string) => new Init({ selectedContractId }),
}

export { Initializer }
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Initializer),
)
