import { ButtonLink, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import {
  getComplaints,
  pageContentEndpoint,
} from 'mlp-client/src/complaints/selectors'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import { getTranslationSelector } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'
import { FormSubmission, Options } from 'mlp-client/src/form/types'
import { SubmitComplaint } from 'mlp-client/src/complaints/actions'
import {
  FormData,
  InformationForm,
} from 'mlp-client/src/components/information-form/InformationForm'
import { LoadContent } from 'mlp-client/src/content/actions'

export interface Props extends FormSubmission<FormData> {
  complaints: Options
  descriptionPlaceholder?: string
  metaTitle: string
  metaDescription: string
  locale: string
  buttonLabel?: string
  topicLabel?: string
  descriptionLabel?: string
  fetchData(locale: string): void
}

class Complaints extends React.PureComponent<Props> {
  componentDidMount(): void {
    this.props.fetchData(this.props.locale)
  }

  componentDidUpdate(prevProps: Props) {
    const { locale } = this.props

    if (prevProps.locale && prevProps.locale !== locale) {
      this.props.fetchData(locale)
    }
  }

  render() {
    const { complaints, metaDescription, metaTitle, onSubmit } = this.props

    return (
      <MyleaseplanPage metaDescription={metaDescription} metaTitle={metaTitle}>
        <Spacing mb={1}>
          <Breadcrumbs>
            <ButtonLink
              to={'myLeaseplan.dashboard'}
              component={RouteLink}
              size="s"
              fontWeight="regular"
              color="steel60"
            >
              <Translation id="myLeaseplan.subNavigation.dashboard" />
            </ButtonLink>
            <Translation id="myLeaseplan.complaint.pageTitle" />
          </Breadcrumbs>
        </Spacing>
        <GridLayout
          main={
            <InformationForm
              buttonLabel="myLeaseplan.complaint.submitLabel"
              topicLabel="myLeaseplan.complaint.topicLabel"
              descriptionLabel="myLeaseplan.complaint.descriptionLabel"
              descriptionPlaceholder="myLeaseplan.complaint.descriptionPlaceholder"
              pageTitle="myLeaseplan.complaint.pageTitle"
              pageDescription="myLeaseplan.complaint.pageDescription"
              categoryPlaceholder="myLeaseplan.complaint.categoryPlaceholder"
              submittingLabel="myLeaseplan.complaint.submitLabel"
              topics={complaints}
              onSubmit={onSubmit}
            />
          }
        />
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'locale' | 'metaTitle' | 'metaDescription' | 'complaints'
>
type DispatchProps = Pick<Props, 'fetchData' | 'onSubmit'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  locale: getUserLocale,
  metaTitle: getTranslationSelector('complaint.metaTitle'),
  metaDescription: getTranslationSelector('complaint.metaDescription'),
  complaints: getComplaints,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchData: locale => {
    dispatch(
      new LoadContent({
        locale,
        endpoint: pageContentEndpoint,
      }),
    )
  },
  onSubmit: (formData, formApi, onComplete) => {
    dispatch(new SubmitComplaint({ formData, onComplete }))
  },
})

export { Complaints }

export default connect(mapStateToProps, mapDispatchToProps)(Complaints)
