import { colorMixins, Headline, Text } from '@velocity/ui'
import styled from 'styled-components'

export const SecondaryDarkText = styled(Text)`
  color: ${colorMixins.secondaryDarkColor};
`

export const PrimaryDarkText = styled(Text)`
  color: ${colorMixins.primaryDarkColor};
`

export const WhiteText = styled(Text)`
  color: ${props => props.theme.velocity.styling.gray.white};
`

export const BlackText = styled(Text)`
  color: ${props => props.theme.velocity.styling.gray.darkest};
`

export const GrayDarkText = styled(Text)`
  color: ${colorMixins.grayDarkColor};
`

export const GrayMainText = styled(Text)`
  color: ${colorMixins.grayMainColor};
`

export const GrayDark80Text = styled(Text)`
  color: ${colorMixins.gray80Color};
`

export const GrayDark60Text = styled(Text)`
  color: ${colorMixins.gray60Color};
`

export const ErrorText = styled(Text)`
  color: ${props => props.theme.velocity.styling.system.error.text};
`

export const WhiteHeadline = styled(Headline)`
  color: ${props => props.theme.velocity.styling.gray.white};
`
export const GrayMainHeading = styled(Headline)`
  color: ${colorMixins.grayMainColor};
`
