import { List, ListItem, SimpleCheckMarkIcon, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ContractOptionsListProperties } from 'mlp-client/src/contracts/enums'
import { OptionsProperty, OptionTileInfo } from 'mlp-client/src/contracts/types'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  serviceKey: ContractOptionsListProperties
  serviceData: OptionTileInfo
}

export default class ServiceProduct extends React.PureComponent<Props> {
  renderProperty = ({
    title,
    key,
    description,
  }: OptionsProperty): React.ReactNode => (
    <ListItem key={key}>
      <Text variant="100" data-e2e-id={key} component="span">
        <Translation id={title} />
        {Boolean(description) && (
          <SecondaryDarkText component="p" bold variant="100">
            {description}
          </SecondaryDarkText>
        )}
      </Text>
    </ListItem>
  )

  render() {
    const { serviceKey, serviceData } = this.props

    const { title, properties } = serviceData

    return (
      <Spacing data-e2e-id={serviceKey} key={serviceKey} mb={2}>
        <Headline variant="200" withMarginBottom>
          <Translation id={title} />
        </Headline>
        <List icon={SimpleCheckMarkIcon} iconSize="s">
          {properties.map(this.renderProperty)}
        </List>
      </Spacing>
    )
  }
}
