import { MessageBar } from '@velocity/ui/draft'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { StepViewProps } from 'mlp-client/src/flows/buy/types'
import DateTimeStep from 'mlp-client/src/flows/components/steps/date-time-step/DateTimeStep'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'

type BuyDateMessage =
  | { enabled: false }
  | {
      enabled: true
      messageId: string
    }
export interface BuyDateMessages {
  beforeContractDateMessage?: BuyDateMessage
  afterContractDateMessage?: BuyDateMessage
}
export interface StepConfig extends BuyDateMessages {
  disableDatesBeforeEndOfContract?: boolean
}
export interface Props extends StepViewProps<StepConfig> {
  todayDate: string
}

interface State {
  selectedDate: string
}

const buildTranslationId = makeTranslationPath('myLeaseplan.buy.steps.date')

const defaultMessages: Required<BuyDateMessages> = {
  beforeContractDateMessage: {
    enabled: true,
    messageId: buildTranslationId('beforeEndDate'),
  },
  afterContractDateMessage: {
    enabled: true,
    messageId: buildTranslationId('afterEndDate'),
  },
}

class DateStep extends React.PureComponent<Props, State> {
  getInitialSelectedDate = (): string => {
    const {
      flowData: { buyDate, contractEndDate },
      todayDate,
    } = this.props

    return (
      buyDate ||
      (moment(contractEndDate).isBefore(todayDate)
        ? todayDate
        : contractEndDate)
    )
  }

  state = { selectedDate: this.getInitialSelectedDate() }

  onDateChange = (value: string): void =>
    this.setState({
      selectedDate: value,
    })

  handleNextButtonClick = (): void => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData({ buyDate: this.state.selectedDate }, goToNextStep)
  }

  getWarningBlock = (key: string, testId: string): React.ReactNode => (
    <SMessageBarContainer>
      <MessageBar variant="warning" data-test-id={testId}>
        <MessageBar.Text>
          <Translation id={key} />
        </MessageBar.Text>
      </MessageBar>
    </SMessageBarContainer>
  )

  render() {
    const {
      flowData: { contractEndDate },
      config: {
        disableDatesBeforeEndOfContract,
        beforeContractDateMessage = defaultMessages.beforeContractDateMessage,
        afterContractDateMessage = defaultMessages.afterContractDateMessage,
      },
    } = this.props

    const { selectedDate } = this.state

    const warningMessage =
      beforeContractDateMessage.enabled &&
      moment(selectedDate).isBefore(contractEndDate, 'day')
        ? this.getWarningBlock(
            beforeContractDateMessage.messageId,
            'before-end-date-warn',
          )
        : afterContractDateMessage.enabled &&
          moment(selectedDate).isAfter(contractEndDate, 'day')
        ? this.getWarningBlock(
            afterContractDateMessage.messageId,
            'after-end-date-warn',
          )
        : undefined

    const disabledBeforeDate = new Date(contractEndDate)

    disabledBeforeDate.setDate(disabledBeforeDate.getDate() + 1)

    return (
      <DateTimeStep
        title={buildTranslationId('title')}
        date={{
          placeholder: buildTranslationId('selectDate'),
          enableWeekend: true,
          notBefore: disableDatesBeforeEndOfContract
            ? {
                date: disabledBeforeDate,
                message: buildTranslationId('notBeforeContractEnd'),
              }
            : undefined,
          onChange: this.onDateChange,
        }}
        selectedDate={new Date(selectedDate)}
        warningBlock={warningMessage}
        onSubmit={this.handleNextButtonClick}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'todayDate'>

const mapStateToProps = (): ReduxProps => ({
  todayDate: moment().startOf('day').format(),
})

export { DateStep }
export default connect(mapStateToProps)(DateStep)
