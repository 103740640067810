import { tyresSuppliers as suppliers } from 'mlp-client/src/features-configuration/dk/data'
import { MobileAppConfig } from 'mlp-client/src/features-configuration/types'
import { TyreServicesPage } from 'mlp-client/src/flows/tyres/components/pages'
import { Props as TyreServicesConfiguration } from 'mlp-client/src/flows/tyres/components/tyre-services/TyreServices'

export const tyresConfig: MobileAppConfig<TyreServicesConfiguration> = {
  enabled: true,
  mobileApp: {
    showNativeHeader: true,
  },
  view: TyreServicesPage,
  configuration: {
    suppliers,
    headerTitleLabel: 'myLeaseplan.tyres.tyreServices.seasonalTyreChange.title',
    headerSubtitleLabel:
      'myLeaseplan.tyres.tyreServices.seasonalTyreChange.partnerContact',
    isTyreStorageShown: true,
  },
}
