import React from 'react'

interface DefaultProps {
  width: number
  height: number
  modifier: string
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

class MileageIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    width: 147,
    height: 146,
    modifier: '',
  }

  render() {
    const { modifier, height, width } = this.props as PropsWithDefaults

    return (
      <svg
        width={width}
        height={height}
        className={`mileage-icon mileage-icon--${modifier}`}
        viewBox="0 0 147 146"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M62.988 16.395c32.653-8.907 66.64 8.91 77.947 40.86 11.308 31.953-3.886 67.235-34.847 80.92-30.962 13.686-67.22 1.146-83.158-28.76-15.938-29.905-6.166-67.063 22.412-85.22" />
        <path d="M86.96 74.37L45.89 3 65.72 60.75l6.926 20.16c.757 3.146 3.325 5.53 6.514 6.044 3.188.515 6.374-.94 8.08-3.687 1.704-2.748 1.594-6.253-.28-8.89v-.007zM2.367 53.468C7.62 37.25 18.134 23.258 32.24 13.718M100.153 28.492L94.36 42.945M117.104 39.405l-4.47 4.703M33.116 79.17l-6.477.12M133.747 77.295l-6.476.12M46.682 45.157l-4.612-4.56M79.954 31.125l-.03-6.495M130.258 99.127l-14.42-5.804M44.07 64.463L29.64 58.658M129.81 57.563l-14.3 6.12M44.39 94.103l-14.292 6.12M51.084 105.188h57.732v16.192H51.084z" />
      </svg>
    )
  }
}

export default MileageIcon
