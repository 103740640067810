import React from 'react'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { CashClaimFlowConfiguration } from 'mlp-client/src/flows/cash-claim/types'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'

export interface Props
  extends MyLeaseplanFeatureProps<CashClaimFlowConfiguration> {}

class Flow extends React.PureComponent<Props> {
  static routePrefix = 'myLeaseplan.cashClaim.steps.'
  static stepTitlePrefix = 'myLeaseplan.newSetup.flows.cashClaim'

  renderStep = (stepParameters: FlowProps) => {
    const { name } = stepParameters.activeStep
    const { configuration } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = configuration.allSteps[name]

    if (StepView) {
      return <StepView {...stepParameters} config={{ ...stepConfig }} />
    }

    return <NotFound />
  }

  onFlowClose = () => {
    const { match } = this.props

    return (
      <TranslatedRedirect to="myLeaseplan.dashboard" params={match.params} />
    )
  }

  render() {
    const { featureConfig } = this.props

    return (
      <MyleaseplanFlow
        stepTitlePrefix={Flow.stepTitlePrefix}
        routePrefix={Flow.routePrefix}
        getSteps={featureConfig.configuration.getSteps}
        onClose={this.onFlowClose}
        render={this.renderStep}
        eventCategory={EventCategoryService.FAST_SERVICE}
        flowName={EventCategoryFlow.CASH_CLAIM}
      />
    )
  }
}

export { Flow }
export default Flow
