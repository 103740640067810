import { AppConfiguration } from './types'

export const SITEDATA_BLACKLIST = [
  // TODO: this route doesn't work in MyLP 2.0
  // '/select-country-language/',
  '/auth/login-redirect/',
  '/auth/logout-redirect/',
  '/auth/redirect-to-correct-auth0-locale/',
]

// TODO: temporary copy of `ClientEnv` and `deserializeClientEnv`
// from apps/mlp-server/src/universal/clientEnv.ts until MLP sources are moved to the new location.
// Remove them from here after the move.
interface ClientEnv {
  IDENTITY_API: string
  IDENTITY_AUDIENCE: string
  IDENTITY_CLIENT_ID: string
  IDENTITY_CLIENT_ID_MOBILE: string
  ENABLE_MY_LEASEPLAN: string
  ANDROID_INSTALL_APP_URL: string
  IOS_INSTALL_APP_URL: string
  LEGACY_MLP_REDIRECT: string
  GOOGLE_MAPS_API_KEY?: string
  API_TOKEN: string
  PLATFORM_URL: string
}

const deserializeClientEnv = (serializedClientEnv: string): ClientEnv =>
  JSON.parse(serializedClientEnv)

const {
  IDENTITY_API,
  IDENTITY_CLIENT_ID,
  IDENTITY_CLIENT_ID_MOBILE,
  IDENTITY_AUDIENCE,
  ENABLE_MY_LEASEPLAN,
  ANDROID_INSTALL_APP_URL,
  IOS_INSTALL_APP_URL,
  LEGACY_MLP_REDIRECT,
  GOOGLE_MAPS_API_KEY,
  API_TOKEN,
  PLATFORM_URL,
} = deserializeClientEnv(window.__CLIENT_RUNTIME_CONFIG__ ?? '{}')

const origin = window.location.origin

export const config: Readonly<AppConfiguration> = {
  IDENTITY_API,
  IDENTITY_CLIENT_ID,
  IDENTITY_CLIENT_ID_MOBILE,
  IDENTITY_AUDIENCE,
  LEGACY_MLP_REDIRECT,
  ENABLE_MY_LEASEPLAN: ENABLE_MY_LEASEPLAN === 'true',
  ANDROID_INSTALL_APP_URL,
  IOS_INSTALL_APP_URL,
  DEFAULT_LOCALE: 'en-us',
  API_BASE_URL: `${origin}/api2`,
  // For local environments, GOOGLE_MAPS_API_KEY is defined in `.env`. For all other environments, under Variables at
  // https://gitlab.leaseplan.io/shared/frontend/mlp-monorepo/-/settings/ci_cd
  GOOGLE_MAPS_API_KEY,
  API_TOKEN,
  PLATFORM_URL,
}
