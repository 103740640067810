import { CountryCode, LanguageCode } from 'mlp-client/src/localization/enums'
import { Locale } from 'mlp-client/src/localization/types'

export const getSCLanguageCode = (
  language: LanguageCode,
  country: string,
): string =>
  language === LanguageCode.en ? language : `${language}-${country}`

export const isContentLangUnmatchesLocal = (
  contentLang: string,
  locale: string,
): boolean => contentLang.split('-')[0] !== locale.split('-')[0]

const stringToLanguageCode = (code: string): LanguageCode | undefined =>
  LanguageCode[code.toLowerCase()]

const stringToCountryCode = (code: string): CountryCode | undefined =>
  CountryCode[code.toLowerCase()]

export const parseLocale = (locale: string): Locale | undefined => {
  const parts = locale.split('-')

  if (parts.length !== 2) {
    // eslint-disable-next-line no-console
    console.warn(`invalid locale ${locale}`)

    return
  }

  const languageCode = stringToLanguageCode(parts[0])

  if (!languageCode) {
    // eslint-disable-next-line no-console
    console.warn(
      `Cannot parse locale '${locale}'. Unknown language code '${parts[0]}'.`,
    )

    return
  }

  const countryCode = stringToCountryCode(parts[1])

  if (!countryCode) {
    // eslint-disable-next-line no-console
    console.warn(
      `Cannot parse locale '${locale}'. Unknown country code '${parts[1]}'.`,
    )

    return
  }

  return {
    language: languageCode,
    country: countryCode,
  }
}
