import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { FlowData } from 'mlp-client/src/flows/cash-claim/types'
import { formatAccountNumberForTextField } from 'mlp-client/src/flows/cash-claim/utils'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import {
  bic,
  iban,
  required,
  accountNumber,
} from 'mlp-client/src/form/validations'

export const StyledTextField = styled(TextField)`
  input {
    text-transform: uppercase;
  }
`

export interface StepConfig {
  ibanPlaceholder?: string
  isOwnerEnabled?: boolean
  isBicEnabled?: boolean
  isIbanEnabled?: boolean
  isAccountNumberEnabled?: boolean
}
export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

type FormData = Pick<FlowData, 'owner' | 'iban' | 'bic' | 'accountNumber'>

class AccountDetailsStep extends React.PureComponent<Props> {
  submitHandler = (values: FormData) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        ...values,
        ...(values.iban && { iban: values.iban.toUpperCase() }),
        ...(values.bic && { bic: values.bic.toUpperCase() }),
      },
      goToNextStep,
    )
  }

  render() {
    const {
      flowData,
      config: {
        isOwnerEnabled,
        isBicEnabled,
        ibanPlaceholder,
        isIbanEnabled,
        isAccountNumberEnabled,
      },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.title">
          <Form onSubmit={this.submitHandler} initialValues={flowData}>
            {({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit} data-e2e-id="accountDetailsForm">
                  <Spacing mb={2}>
                    <Text>
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.subTitle" />
                    </Text>
                  </Spacing>
                  {isOwnerEnabled && (
                    <TextField
                      name="owner"
                      validations={[required]}
                      label={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.owner.label',
                      }}
                      placeholder={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.owner.placeholder',
                      }}
                    />
                  )}
                  {isIbanEnabled && (
                    <StyledTextField
                      name="iban"
                      validations={[required, iban]}
                      label={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.iban.label',
                      }}
                      placeholder={{
                        id: ibanPlaceholder,
                      }}
                    />
                  )}
                  {isAccountNumberEnabled && (
                    <StyledTextField
                      name="accountNumber"
                      validations={[required, accountNumber]}
                      label={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.accountNumber.label',
                      }}
                      placeholder={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.accountNumber.placeholder',
                      }}
                      parse={formatAccountNumberForTextField}
                    />
                  )}
                  {isBicEnabled && (
                    <StyledTextField
                      name="bic"
                      validations={[required, bic]}
                      label={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.bic.label',
                      }}
                      placeholder={{
                        id:
                          'myLeaseplan.newSetup.flows.cashClaim.steps.accountDetails.bic.placeholder',
                      }}
                    />
                  )}
                  <Spacing mb={2} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                </form>
              )
            }}
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export { AccountDetailsStep }
export default AccountDetailsStep
