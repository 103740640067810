import React from 'react'

interface DefaultProps {
  className: string
  modifier: string
  height: number
  width: number
  title: string
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

class CameraIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: '',
    modifier: '',
    height: 21,
    width: 30,
    title: 'camera',
  }

  render() {
    const { className, height, modifier, title, width } = this
      .props as PropsWithDefaults

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 30 21"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className} camera-icon camera-icon--${modifier}`}
      >
        <title>{title}</title>
        <g>
          <path d="M20 12c0 2.762-2.238 5-5 5s-5-2.238-5-5 2.238-5 5-5 5 2.238 5 5z" />
          <path d="M27.193 5.204h-3.4c-2.183 0-3.335-1.65-4.02-2.882C19.324 1.51 18.48 1 17.56 1H12.44c-.92 0-1.763.51-2.214 1.322-.684 1.233-1.836 2.882-4.02 2.882h-3.4C1.81 5.204 1 6.02 1 7.03v11.144C1 19.182 1.81 20 2.807 20h24.386C28.19 20 29 19.182 29 18.174V7.03c0-1.01-.81-1.826-1.807-1.826z" />
          <path d="M3 4V2h4v1.86M24.98 9.638c-.075.265-.353.418-.618.343-.265-.075-.42-.352-.342-.617.075-.266.352-.42.618-.344.265.076.42.352.342.618z" />
        </g>
      </svg>
    )
  }
}

export default CameraIcon
