import { DefinitionList, mq, spacingUnit } from '@leaseplan/ui'
import styled from 'styled-components'

export const UserProfileList = styled(DefinitionList)`
  & dt {
    vertical-align: top;
    width: 40%;
    ${mq.until('tablet')`
      display: block;
      width: auto;
      padding-right: 0;
      margin-bottom: 0;
    `}
  }
  & dd {
    width: 60%;
    margin-bottom: ${p => (p.verticalSpacing || 0) * spacingUnit}px;
    ${mq.until('tablet')`
      display: block;
      width: auto;
      padding-left: 0;
    `}
  }
  & dd:last-of-type {
    margin-bottom: 0;
  }
`
