import { Hide, Spacing } from '@leaseplan/ui'
import { VelocityConsumer } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { FullWidthBanner } from 'mlp-client/src/components/layout'
import ActiveVehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/ActiveVehicleModelLicense'
import { showMultipleVehicles } from 'mlp-client/src/contracts/selectors'
import {
  WhiteHeadline,
  WhiteText,
} from 'mlp-client/src/components/styled/TextStyle'

const RelativeWrapper = styled.div`
  position: relative;
`

const PositionedBreadcrumbs = styled(Spacing)`
  position: absolute;
  top: 0;
`

interface Props {
  imgMedium: string
  imgLarge: string
  imgSmall?: string
  position?: string
  title?: string | React.ReactNode
  text?: string | React.ReactNode
  breadcrumbs?: React.ReactNode
  showMultipleVehicles?: boolean
  titleE2eId?: string
  textE2eId?: string
}

class FlowStepPoster extends React.PureComponent<Props> {
  render() {
    const {
      imgMedium,
      imgLarge,
      imgSmall,
      title,
      text,
      position,
      showMultipleVehicles,
      breadcrumbs,
      titleE2eId,
      textE2eId,
    } = this.props

    return (
      <RelativeWrapper>
        <FullWidthBanner
          imgMedium={imgMedium}
          imgLarge={imgLarge}
          imgSmall={imgSmall}
          position={position}
        >
          {breadcrumbs ? (
            <>
              <PositionedBreadcrumbs mt={{ mobile: 3, tablet: 4 }}>
                {breadcrumbs}
              </PositionedBreadcrumbs>
              <Spacing mt={{ mobile: 7, lap: 0 }}>
                <WhiteHeadline
                  variant="600"
                  data-e2e-id={titleE2eId}
                  withMarginBottom
                >
                  {title}
                </WhiteHeadline>
              </Spacing>
            </>
          ) : (
            <WhiteHeadline
              variant="600"
              data-e2e-id={titleE2eId}
              withMarginBottom
            >
              {title}
            </WhiteHeadline>
          )}

          {showMultipleVehicles ? (
            <VelocityConsumer>
              {({ theme }) => (
                <ActiveVehicleModelLicense
                  textColor={theme.styling.gray.white}
                  textSize="500"
                  bold
                />
              )}
            </VelocityConsumer>
          ) : (
            <Hide until="tablet">
              {breadcrumbs ? (
                <Spacing mb={{ mobile: 3, lap: 0 }}>
                  <WhiteText variant="400" data-e2e-id={textE2eId}>
                    {text}
                  </WhiteText>
                </Spacing>
              ) : (
                <WhiteText variant="400" data-e2e-id={textE2eId}>
                  {text}
                </WhiteText>
              )}
            </Hide>
          )}
        </FullWidthBanner>
      </RelativeWrapper>
    )
  }
}

export type ReduxProps = Pick<Props, 'showMultipleVehicles'>

export const mapStateToProps = createStructuredSelector({
  showMultipleVehicles,
})

export { FlowStepPoster }
export default connect(mapStateToProps)(FlowStepPoster)
