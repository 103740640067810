export { default as SelectDamageNatureTypeStep } from 'mlp-client/src/flows/damage-report/components/steps/damage-nature-type-step/DamageNatureTypeStep'
export { default as DamageSideStep } from 'mlp-client/src/flows/damage-report/components/steps/damage-sides-step/DamageSideStep'
export { default as StolenVehicleStep } from 'mlp-client/src/flows/damage-report/components/steps/stolen-vehicle-step/StolenVehicleStep'
export { default as DamageUploadStep } from 'mlp-client/src/flows/damage-report/components/steps/damage-upload-step/DamageUploadStep'
export { default as PointSelectionStep } from 'mlp-client/src/flows/damage-report/components/steps/point-selection-step/PointSelectionStep'
export { default as WhenAndWhereStep } from 'mlp-client/src/flows/damage-report/components/steps/when-and-where-step/WhenAndWhereStep'
export { default as EuropeanAccidentStatementStep } from 'mlp-client/src/flows/damage-report/components/steps/european-accident-statement-step/EuropeanAccidentStatementStep'
export { default as InsuranceStep } from 'mlp-client/src/flows/damage-report/components/steps/insurance-step/InsuranceStep'
export { default as DamageCauseStep } from 'mlp-client/src/flows/damage-report/components/steps/damage-cause-step/DamageCauseStep'
export { default as WhatHappenedStep } from 'mlp-client/src/flows/damage-report/components/steps/what-happened-step/WhatHappenedStep'
export { default as ThankYouStep } from 'mlp-client/src/flows/damage-report/components/steps/thank-you-step/ThankYouStep'
export { default as CheckDetailsStep } from 'mlp-client/src/flows/damage-report/components/steps/check-details-step/CheckDetailsStep'
