import styled from 'styled-components'

export const Wrapper = styled.div<{
  size: 's' | 'm'
  isDropdownOpened: boolean
}>`
  background: ${({ isDropdownOpened }) =>
    isDropdownOpened ? '#F6F6F6' : 'white'};
  border-radius: 50px;
  width: ${({ size }) => (size === 's' ? '46px' : '70px')};
  height: ${({ size }) => (size === 's' ? '46px' : '70px')};
  position: relative;
  margin-right: ${({ size }) => (size === 's' ? '0px' : '5px')};
  margin-left: ${({ size }) => (size === 's' ? '0px' : '-10px')};
  display: flex;
  justify-content: center;
  align-items: center;
`
