import { GridItem } from '@leaseplan/ui'
import { colorMixins, Text } from '@velocity/ui'
import styled from 'styled-components'

export const CardLabel = styled.label`
  display: flex;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 30px);
  height: 100%;
  padding: 30px 20px;
  background-color: white;
  margin-left: 15px;
  flex-shrink: 0;
  transition-property: transform;
  cursor: pointer;

  &:last-child {
    margin-right: 15px;
  }
`

export const DistanceWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-height: 23px;
  background-color: ${colorMixins.secondaryDarkColor};
`

export const SupplierName = styled(Text)`
  margin-bottom: 5px;
  min-height: 42px;
  line-height: 1.5em;
  color: ${colorMixins.secondaryDarkColor};
`

export const Address = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  color: ${colorMixins.secondaryMainColor};
`

export const SupplierWrapper = styled(GridItem)`
  width: 90%;
`

export const IconWrapper = styled(GridItem)`
  align-self: center;
  width: 10%;
`
