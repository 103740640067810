import { FlowData } from './types'

export const enum ActionTypes {
  SUBMIT_TYRES_REQUEST = '@@myLeaseplan/tyres/SUBMIT_TYRES_REQUEST',
}

export class SubmitTyresRequest {
  readonly type = ActionTypes.SUBMIT_TYRES_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(error: boolean): void
    },
  ) {}
}

export type Action = SubmitTyresRequest
