import { createSelector } from 'reselect'

import { getCountry, getLanguage } from 'mlp-client/src/localization/selectors'
import { getOrigin } from 'mlp-client/src/auth/selectors'
import { getUserInfoData } from 'mlp-client/src/user/selectors'

/*
 * Modified version of the corresponding selector from `src/app/selectors/requestHeaders.selectors.ts`
 */
export const defaultRequestHeadersSelector = createSelector(
  getCountry,
  getLanguage,
  getOrigin,
  getUserInfoData,
  (countryCode, languageCode, origin, userInfo) => {
    const countryCodeAllCaps = countryCode?.toUpperCase()
    const parsedLocale =
      languageCode && countryCodeAllCaps
        ? `${languageCode}-${countryCodeAllCaps}`
        : ''

    return {
      'X-LPD-Locale': parsedLocale,
      'X-LPD-CountryCode': countryCodeAllCaps,
      'X-LPD-Origin': origin,
      'Referrer-Policy': 'no-referrer',
      'X-LPD-ContactId': userInfo?.gin ?? null,
    }
  },
)
