import { Grid, GridItem, Hide, mq, Paper, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import CellphoneHandIcon, {
  Props as PhoneIconProps,
} from 'mlp-client/src/components/icons/cellphone-hand-icon/CellphoneHandIcon'
import CellphoneIcon, {
  Props as DesktopIconProps,
} from 'mlp-client/src/components/icons/cellphone-icon/CellphoneIcon'
import { formatTelephoneHref } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import Translation from 'mlp-client/src/localization/Translation'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { StyledButtonOutlined } from 'mlp-client/src/components/button/StyledButton'
import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'

export interface Props {
  title: string
  buttonLabel: string
  config: {
    phoneNumber: string
  }
  isMobileApp?: boolean
}

const GridStyled = styled(Grid)`
  ${mq.until('lap')`flex-flow: column;`};
`

const StyledText = styled(Text)`
  ${mq.until('lap')`text-align: center;`}
`

const StyledGridItem = styled(GridItem)`
  ${mq.until('lap')`flex-basis: auto;`}
`

export const triggerMobilePhoneAction = (formattedPhoneNumber: string): void =>
  postMessage({
    url: 'tel',
    type: PostMessageType.OpenPhoneNumber,
    payload: { isPhoneNumber: true, phoneNumber: formattedPhoneNumber },
  })

export class ContactUsBanner extends React.PureComponent<Props> {
  renderIcon = (
    Icon:
      | React.ComponentClass<PhoneIconProps>
      | React.ComponentClass<DesktopIconProps>,
    width: number,
    height: number,
  ) => () => <Icon width={width} height={height} />

  renderPhone = () => (
    <GridItem>
      <SecondaryDarkText bold variant="400">
        <Translation id={this.props.config.phoneNumber} />
      </SecondaryDarkText>
    </GridItem>
  )

  renderButtonPhone = () => {
    const { buttonLabel, config, isMobileApp } = this.props

    if (!buttonLabel) {
      return null
    }

    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <>
            <Spacing mt={1} />
            <StyledButtonOutlined
              component="a"
              variant="secondary"
              stretch={true}
              textTransform="uppercase"
              href={
                !isMobileApp &&
                formatTelephoneHref(translate(config.phoneNumber))
              }
              onClick={() =>
                isMobileApp &&
                triggerMobilePhoneAction(
                  formatTelephoneHref(translate(config.phoneNumber)),
                )
              }
            >
              {buttonLabel}
            </StyledButtonOutlined>
          </>
        )}
      </LocalizationContext.Consumer>
    )
  }

  render() {
    const { title } = this.props

    return (
      <Paper data-e2e-id="contactUsBanner">
        <Spacing ph={{ lap: 3, mobile: 2 }} pv={2}>
          <GridStyled
            justifyContent="center"
            alignItems="center"
            verticalGutter={false}
          >
            <Hide
              until="lap"
              implementation="js"
              component={this.renderIcon(CellphoneIcon, 36, 36)}
            />

            <Hide
              from="lap"
              implementation="js"
              component={this.renderIcon(CellphoneHandIcon, 128, 128)}
            />

            <StyledGridItem span="grow">
              <Hide until="tablet" implementation="js">
                <StyledText variant="400">{title}</StyledText>
              </Hide>

              <Hide from="tablet" implementation="js">
                <StyledText>{title}</StyledText>
              </Hide>

              <Hide from="lap" implementation="js">
                <Spacing mb={1} />
              </Hide>
            </StyledGridItem>

            <Hide
              from="mobileLandscape"
              implementation="js"
              component={this.renderButtonPhone}
            />

            <Hide
              until="mobileLandscape"
              implementation="js"
              component={this.renderPhone}
            />
          </GridStyled>
        </Spacing>
      </Paper>
    )
  }
}

type ReduxProps = Pick<Props, 'buttonLabel' | 'title' | 'isMobileApp'>

const mapTranslationsToProps = {
  buttonLabel: 'myLeaseplan.timeline.base.callUsButton',
  title: 'myLeaseplan.timeline.base.callUsInfo',
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  ...getTranslations(state, mapTranslationsToProps),
  isMobileApp: getIsMobileApp(state),
})

export default connect(mapStateToProps)(ContactUsBanner)
