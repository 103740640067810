import ViewContract from 'mlp-client/src/contracts/components/ViewContractFlow'
import {
  ContractSectionProperties,
  InsuranceSectionProperties,
  TyresSectionProperties,
  VehicleSectionProperties,
  ViewContractSections,
  ViewContractTabs,
} from 'mlp-client/src/contracts/enums'
import { ContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const contractsConfig: MyLeaseplanFeature<ContractsFeatureConfig> = {
  enabled: true,
  view: ViewContract,
  configuration: {
    policyDocuments: false,
    contractStatusBlock: true,
    disableEndOfLeaseAfterCurrentDate: false,
    tabs: {
      [ViewContractTabs.Contract]: {
        [ViewContractSections.VehicleDetails]: [
          VehicleSectionProperties.LICENSE_PLATE,
          VehicleSectionProperties.MAKE,
          VehicleSectionProperties.MODEL,
          VehicleSectionProperties.TYPE,
          VehicleSectionProperties.VIN,
          VehicleSectionProperties.FUEL,
          VehicleSectionProperties.POWER,
        ],
        [ViewContractSections.Contract]: [
          ContractSectionProperties.DISPLAY_NUMBER,
          ContractSectionProperties.START_DATE,
          ContractSectionProperties.END_DATE,
          ContractSectionProperties.TYPE,
          ContractSectionProperties.LAST_KNOWN_MILEAGE,
          ContractSectionProperties.DATE_LAST_KNOWN_MILEAGE,
          ContractSectionProperties.CONTRACTED_MAINTENANCE,
          ContractSectionProperties.CONTRACTED_TYRES,
          ContractSectionProperties.CONTRACTED_INSURANCE,
          ContractSectionProperties.CONTRACTED_FUEL,
        ],
        [ViewContractSections.Insurance]: [
          InsuranceSectionProperties.COMPANY,
          InsuranceSectionProperties.TYPE,
          InsuranceSectionProperties.NUMBER,
        ],
        [ViewContractSections.Tyres]: [
          TyresSectionProperties.BRAND,
          TyresSectionProperties.CATEGORY,
          TyresSectionProperties.LIMIT,
          TyresSectionProperties.STANDARD_TYRE_USED,
          TyresSectionProperties.WINTER_TYRE_USED,
          TyresSectionProperties.REMAINING_TYRES,
          TyresSectionProperties.SIZE,
          TyresSectionProperties.TYRES_MODULE_DESCRIPTION,
        ],
      },
    },
  },
}
