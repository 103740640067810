import { Paper } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'

export const Centered = styled.div`
  max-width: 1000px;
  margin: auto;
  width: auto;
`
// copy from shared
export class MainContentCenter extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <Centered>
        <Paper>{children}</Paper>
      </Centered>
    )
  }
}
