import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { AppState } from 'mlp-client/src/types'

interface Props {
  metaDescription: string
  metaTitle: string
}

class Unauthorized extends React.PureComponent<Props, AppState> {
  render() {
    const { metaDescription, metaTitle } = this.props
    const meta = [{ name: 'robots', content: 'noindex,nofollow' }]

    return (
      <MyleaseplanPage
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        meta={meta}
      >
        <section className="o-wrapper u-padding-top-huge u-padding-bottom-huge">
          <div className="o-layout">
            <div className="o-layout__item u-1/2">
              <Translation
                element="h2"
                className="u-margin-bottom-large"
                id="myLeaseplan.unauthorized.title"
              />
              <Translation element="p" id="myLeaseplan.unauthorized.subtitle" />
              <p className="u-padding-vertical-small">
                <RouteLink to="myLeaseplan.login">
                  <Translation
                    className="link link--blood-orange link--16"
                    id="myLeaseplan.unauthorized.loginLinkText"
                  />
                </RouteLink>
                &nbsp;
                <Translation id="myLeaseplan.unauthorized.loginExtraText" />
              </p>
            </div>
            <div className="o-layout__item u-1/2" />
          </div>
        </section>
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'metaDescription' | 'metaTitle'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  metaTitle: getTranslation(state, 'myLeaseplan.unauthorized.title', ''),
  metaDescription: getTranslation(
    state,
    'myLeaseplan.unauthorized.subtitle',
    '',
  ),
})

export { Unauthorized }
export default connect(mapStateToProps)(Unauthorized)
