import React from 'react'
import Classnames from 'classnames'
import { Color } from '@leaseplan/ui'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

interface DefaultProps {
  className: string
  title: string
  color: Color
  width: number
  height: number
}

export type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

class CellphoneIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: '',
    title: 'CellphoneIcon',
    color: ColorEnumSelector('midOrange'),
    width: 251,
    height: 251,
  }

  render() {
    const { title, className, color, height, width } = this
      .props as PropsWithDefaults

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 64 64"
        className={Classnames(className, 'cellphone-icon')}
      >
        <g fill={color}>
          <title>{title}</title>
          <path d="M27.909 9.579h8.329a1.5 1.5 0 1 0 0-3h-8.33a1.5 1.5 0 0 0 0 3M22.684 45.392l-.003-30.379 18.785-.002.003 30.38-18.785.001zM44.027 12.45a1.5 1.5 0 0 0-1.061-.439l-21.785.002a1.5 1.5 0 0 0-1.5 1.5l.003 33.38a1.5 1.5 0 0 0 1.5 1.5l21.785-.002a1.504 1.504 0 0 0 1.5-1.5l-.003-33.38a1.5 1.5 0 0 0-.44-1.06zM32.076 50.323a3.41 3.41 0 1 0 .002 6.819 3.41 3.41 0 0 0-.002-6.82" />
          <path d="M47.121 56.055A2.948 2.948 0 0 1 44.176 59h-24.23A2.948 2.948 0 0 1 17 56.055V7.079a2.948 2.948 0 0 1 2.945-2.945h24.231a2.948 2.948 0 0 1 2.945 2.945v48.976zM44.176 1.134h-24.23A5.952 5.952 0 0 0 14 7.079v48.976A5.952 5.952 0 0 0 19.945 62h24.231a5.952 5.952 0 0 0 5.945-5.945V7.079a5.952 5.952 0 0 0-5.945-5.945z" />
        </g>
      </svg>
    )
  }
}

export default CellphoneIcon
