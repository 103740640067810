import { Locales } from '../localization/enums'
import { config } from '../app-config'
import { ExternalLogoutLinks } from './types'

export const getExternalLogoutLinkByLocale = (locale: Locales) => {

  const linkEnd = ExternalLogoutLinks[locale] || ExternalLogoutLinks.Default

  if (window.__DD_ENV__ !== 'prod') {
    return `${config.PLATFORM_URL}/${locale}/${ExternalLogoutLinks.Default}`
  }

  if (locale === Locales.FR) {
    return `${config.LEGACY_MLP_REDIRECT}/${locale}/${linkEnd}`
  }

  if ([Locales.DA_DK, Locales.EN_DK].includes(locale)) {
    return `${config.LEGACY_MLP_REDIRECT}/${locale}`
  }

  return `${config.PLATFORM_URL || window.location.origin}/${locale}/${ExternalLogoutLinks.Default}`
}
