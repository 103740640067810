import { Card, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text, colorMixins } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import Price from 'mlp-client/src/localization/Price'
import { FuelRecord } from 'mlp-client/src/flows/fuel-history/types'
import { BlackText } from 'mlp-client/src/components/styled/TextStyle'

const StyledGridItem = styled(GridItem)`
  text-align: right;
`

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
  text-transform: uppercase;
`

const WhereAndWhat: React.FC = props => (
  <Text variant="200">{props.children}</Text>
)

class FuelCard extends React.PureComponent<FuelRecord> {
  render() {
    const {
      date: fuelDate,
      amount,
      isoCurrencyCode: currency,
      fuelTypeDescription: violationType,
      fuelAddress,
      fuelAmount,
      fuelType,
      pricePerLiter,
    } = this.props

    return (
      <Spacing mb={1}>
        <Card data-e2e-id={`fuelTile-${fuelDate}`}>
          <Spacing pt={1} ph={2} pb={2}>
            <Spacing mb={0.5}>
              <SText variant="100" bold>
                {moment(fuelDate).format('LL')}
              </SText>
            </Spacing>

            <Grid>
              <GridItem span="grow">
                <Headline
                  variant="100"
                  component="h6"
                  data-e2e-id="titleOfFuelHistoryCard"
                  withMarginBottom
                >
                  {violationType}
                </Headline>
                {fuelAddress && <WhereAndWhat>{fuelAddress}</WhereAndWhat>}
                <WhereAndWhat>{`${fuelType}, ${fuelAmount} litres`}</WhereAndWhat>
              </GridItem>
              <StyledGridItem>
                <Price currency={currency}>
                  {formatPrice => (
                    <BlackText bold data-e2e-id="priceOfFuelHistoryCard">
                      {formatPrice(amount)}
                    </BlackText>
                  )}
                </Price>
                <Price currency={currency}>
                  {formatPrice => (
                    <Text>{`${formatPrice(pricePerLiter)}/l`}</Text>
                  )}
                </Price>
              </StyledGridItem>
            </Grid>
          </Spacing>
        </Card>
      </Spacing>
    )
  }
}

export { FuelCard }
export default FuelCard
