export enum CountryCodes {
  BE = 'be',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  GB = 'gb',
  IT = 'it',
  LU = 'lu',
  NL = 'nl',
  NO = 'no',
  PT = 'pt',
  US = 'us',
  PL = 'pl',
  GR = 'gr',
  SE = 'se',
  TR = 'tr',
  CZ = 'cz',
  FI = 'fi',
  DK = 'dk',
  AT = 'at',
  AU = 'au',
  BR = 'br',
  CA = 'ca',
  HU = 'hu',
  IN = 'in',
  IE = 'ie',
  MX = 'mx',
  RO = 'ro',
  NZ = 'nz',
  RU = 'ru',
  SK = 'sk',
  CH = 'ch',
  AE = 'ae',
}

export enum CountryNames {
  AE = 'United Arab Emirates',
  AT = 'Austria',
  AU = 'Australia',
  BE = 'Belgium',
  BR = 'Brazil',
  CA = 'Canada',
  CH = 'Switzerland',
  CZ = 'Czech Republic',
  DE = 'Germany',
  DK = 'Denmark',
  ES = 'Spain',
  FI = 'Finland',
  FR = 'France',
  GB = 'United Kingdom',
  GR = 'Greece',
  HU = 'Hungary',
  IE = 'Ireland',
  IN = 'India',
  IT = 'Italy',
  LU = 'Luxembourg',
  MX = 'Mexico',
  NL = 'Netherlands',
  NO = 'Norway',
  NZ = 'New Zealand',
  PL = 'Poland',
  PT = 'Portugal',
  RO = 'Romania',
  RU = 'Russia',
  SE = 'Sweden',
  SK = 'Slovakia',
  TR = 'Turkey',
  US = 'United States',
}

export enum CountryPhoneCodes {
  AE = '971',
  AT = '43',
  AU = '61',
  BE = '32',
  BR = '55',
  CA = '1',
  CH = '41',
  CZ = '420',
  DE = '49',
  DK = '45',
  ES = '34',
  FI = '358',
  FR = '33',
  GB = '44',
  GR = '30',
  HU = '36',
  IE = '353',
  IN = '91',
  IT = '39',
  LU = '352',
  MX = '52',
  NL = '31',
  NO = '47',
  NZ = '64',
  PL = '48',
  PT = '351',
  RO = '40',
  RU = '7',
  SE = '46',
  SK = '421',
  TR = '90',
  US = '1',
}

export type CountriesObj = Record<keyof typeof CountryCodes, CountryType>

export interface CountryType {
  name: CountryNames
  countryPhoneCode: CountryPhoneCodes
  flagName: CountryCodes
}

export interface CountryCodeInfo {
  flagName: string
  countryPhoneCode: string
  name?: string
}
