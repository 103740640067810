import { DamageReport, FlowData } from 'mlp-client/src/flows/glass-repair/types'
import { BookingResult } from 'mlp-client/src/types'

export const enum ActionTypes {
  GET_DAMAGE_REPORTS_REQUEST = '@@myLeaseplan/glassRepair/GET_DAMAGE_REPORT_REQUEST',
  GET_DAMAGE_REPORTS_SUCCESS = '@@myLeaseplan/glassRepair/GET_DAMAGE_REPORT_SUCCESS',
  GET_DAMAGE_REPORTS_FAILED = '@@myLeaseplan/glassRepair/GET_DAMAGE_REPORT_FAILED',

  SUBMIT_GLASS_REPAIR_REQUEST = '@@myLeaseplan/glassRepair/SUBMIT_GLASS_REPAIR_REQUEST',
  SUBMIT_GLASS_REPAIR_SUCCESS = '@@myLeaseplan/glassRepair/SUBMIT_GLASS_REPAIR_SUCCESS',
  SUBMIT_GLASS_REPAIR_FAILED = '@@myLeaseplan/glassRepair/SUBMIT_GLASS_REPAIR_FAILED',
  GLASS_REPAIR_REQUEST_CLEAR = '@@myLeaseplan/glassRepair/GLASS_REPAIR_REQUEST_CLEAR',
}

export class GetDamageReportsRequest {
  readonly type = ActionTypes.GET_DAMAGE_REPORTS_REQUEST
}

export class GetDamageReportsSuccess {
  readonly type = ActionTypes.GET_DAMAGE_REPORTS_SUCCESS

  constructor(public payload: { data: readonly DamageReport[] }) {}
}

export class GetDamageReportsFailed {
  readonly type = ActionTypes.GET_DAMAGE_REPORTS_FAILED
}

export class SubmitGlassRepairRequest {
  readonly type = ActionTypes.SUBMIT_GLASS_REPAIR_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(error: boolean): void
    },
  ) {}
}

export class SubmitGlassRepairSuccess {
  readonly type = ActionTypes.SUBMIT_GLASS_REPAIR_SUCCESS

  constructor(
    public payload: {
      result: BookingResult
    },
  ) {}
}

export class SubmitGlassRepairFailed {
  readonly type = ActionTypes.SUBMIT_GLASS_REPAIR_FAILED
}

export class GlassRepairRequestClear {
  readonly type = ActionTypes.GLASS_REPAIR_REQUEST_CLEAR
}

export type Action =
  | GetDamageReportsRequest
  | GetDamageReportsSuccess
  | GetDamageReportsFailed
  | SubmitGlassRepairRequest
  | SubmitGlassRepairSuccess
  | SubmitGlassRepairFailed
  | GlassRepairRequestClear
