import React from 'react'

interface Props {
  type?: string
  className?: string
}

const RichTextList: React.FC<Props> = ({ type, className, children }) =>
  type === 'Bullet' ? (
    <ul className={className}>{children}</ul>
  ) : (
    <ol className={className}>{children}</ol>
  )

RichTextList.displayName = 'RichTextList'

export default RichTextList
