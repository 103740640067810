import { createSelector } from 'reselect'

import { getMyLeaseplanConfig } from 'mlp-client/src/selectors'

export const getPredictiveMaintenanceConfig = createSelector(
  getMyLeaseplanConfig,
  config => config.predictiveMaintenance,
)

export const getAllowedMileageBelowPrediction = createSelector(
  getPredictiveMaintenanceConfig,
  predictiveMaintenance =>
    predictiveMaintenance?.configuration?.allowedMileageBelowPrediction,
)

export const isPredictiveMaintenanceEnabled = createSelector(
  getPredictiveMaintenanceConfig,
  predictiveMaintenance => predictiveMaintenance?.enabled,
)
