import {
  Grid,
  GridItem,
  RadioCard,
  RadioGroup,
  RadioGroupItem,
  Spacing,
} from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { Field } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import {
  determineValidationState,
  getErrorMessage,
  validateString,
} from 'mlp-client/src/form//utils'
import {
  Option,
  Options,
  StringValidations,
  TranslationType,
} from 'mlp-client/src/form/types'

export default ({
  name,
  validations,
  options,
  label,
  dataE2eId,
}: {
  name: string
  validations: StringValidations
  options: Options
  label?: TranslationType
  dataE2eId?: string
}) => (
  <LocalizationContext.Consumer>
    {({ translate }) => (
      <Field name={name} validate={validateString(validations)}>
        {({ input, meta }) => (
          <RadioGroup
            data-e2e-id={dataE2eId}
            {...input}
            onChange={e => input.onChange(e.value)}
            label={label && translate(label.id, label.replaceValues)}
            validationState={determineValidationState(meta)}
            errorMessage={meta.error && getErrorMessage(meta, translate)}
          >
            <Grid gutter={false}>
              {options.map((item: Option) => (
                <GridItem span={12} key={item.value}>
                  <Spacing pv={0.5}>
                    <RadioGroupItem>
                      <RadioCard
                        value={item.value}
                        data-e2e-id={`${name}-${item.value}`}
                      >
                        <Headline variant="100" withMarginBottom>
                          {translate(item.title)}
                        </Headline>
                        {item.subtitle && (
                          <Text variant="200">{translate(item.subtitle)}</Text>
                        )}
                      </RadioCard>
                    </RadioGroupItem>
                  </Spacing>
                </GridItem>
              ))}
            </Grid>
          </RadioGroup>
        )}
      </Field>
    )}
  </LocalizationContext.Consumer>
)
