import React, { FC } from 'react'
import { Grid, GridItem } from '@leaseplan/ui'
import { connect } from 'react-redux'

import {
  DownloadableDocument,
  DownloadableDocuments,
} from 'mlp-client/src/downloadable-documents/types'
import { getDownloadableDocuments } from 'mlp-client/src/downloadable-documents/selectors'
import { AppState } from 'mlp-client/src/types'

import { DownloadableDocumentCard } from './DownloadableDocumentCard'

interface Props {
  renderTitle: JSX.Element
  downloadableDocuments: DownloadableDocuments
}

const DownloadableDocumentTile: FC<Props> = props => {
  const { downloadableDocuments } = props

  if (!downloadableDocuments.length) {
    return null
  }

  return (
    <>
      {props.renderTitle}
      <Grid justifyContent="space-between" direction="row">
        {downloadableDocuments?.map((document: DownloadableDocument) => (
          <GridItem
            span={{ mobile: 12, tablet: 10, desktop: 7 }}
            key={document.documentId}
          >
            <DownloadableDocumentCard downloadableDocument={document} />
          </GridItem>
        ))}
      </Grid>
    </>
  )
}

type ReduxProps = Pick<Props, 'downloadableDocuments'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  downloadableDocuments: getDownloadableDocuments(state),
})

export { DownloadableDocumentTile }

export default connect(mapStateToProps, null)(DownloadableDocumentTile)
