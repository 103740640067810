import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { getCountry } from 'mlp-client/src/localization/selectors'
import { getShowAllFeatures } from 'mlp-client/src/selectors'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureName,
  MyLeaseplanFeatureProps,
} from 'mlp-client/src/features-configuration/types'

interface Props extends Partial<RouteComponentProps> {
  feature: MyLeaseplanFeatureName
}

const getFeatureConfig = (
  config: MyleaseplanConfig,
  feature: MyLeaseplanFeatureName,
): MyLeaseplanFeatureProps => {
  const featureConfig = config[feature]

  return {
    feature,
    featureConfig: {
      configuration: { ...featureConfig.configuration },
      enabled: featureConfig.enabled,
    },
    view: featureConfig.view,
  }
}

export const MyleaseplanFeature: React.FC<Props> = props => {
  const context = useContext(MyLeaseplanContext)
  const country = useSelector(getCountry)
  const showAllFeatures = useSelector(getShowAllFeatures)

  const { feature, ...rest } = props
  const config = getFeatureConfig(context, feature)
  const { featureConfig, view: View } = config
  const viewProps = {
    feature,
    country,
    ...rest,
    ...config,
  }

  if (!featureConfig.enabled) {
    return <NotFound />
  }

  if (!View) {
    throw new Error(
      `You probably forgot to declare the view for your feature ${feature}`,
    )
  }

  if (featureConfig.enabled || showAllFeatures) {
    return <View {...viewProps} />
  }

  return <NotFound data-test-id="notFound" />
}

export default MyleaseplanFeature
