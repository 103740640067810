import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'
import { FormApi } from 'final-form'
import { Field } from 'react-final-form'

import { Option, Options } from 'mlp-client/src/form/types'

import { CardInput } from './card-input/CardInput'

export const CardLabel = styled.label`
  display: flex;
  flex: auto;
`

export const StyledInput = styled.input`
  position: absolute;
  visibility: hidden;
`
StyledInput.displayName = 'StyledInput'

export interface Props {
  form: FormApi
  autoSubmit?: boolean
  onCardHover?(value: Option['value']): () => void
  highlightedValue?: string
  horizontalSpan?: number
  name: string
  value: string
  options: Options
}

export type DefaultProps = Required<
  Pick<Props, 'options' | 'value' | 'horizontalSpan'>
>

export type InternalProps = Props & DefaultProps
class CardRadioList extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    options: [],
    value: '',
    horizontalSpan: 12,
  }

  render() {
    const {
      highlightedValue,
      horizontalSpan,
      options,
      form,
      name,
      onCardHover,
    } = this.props

    return (
      <Grid verticalGutter={false}>
        {options.map((option: Option, index: number) => (
          <GridItem
            span={{
              mobile: 12,
              desktop: horizontalSpan,
              lap: horizontalSpan,
            }}
            key={index}
          >
            <Spacing mb={1}>
              <CardLabel>
                <Field name={name} type="submit" value={option.value}>
                  {({ input }) => (
                    <>
                      <StyledInput
                        {...input}
                        onClick={() => form.change(name, option.value)}
                        data-e2e-id="supplierInput"
                      />
                      <CardInput
                        option={option}
                        onHover={onCardHover}
                        isHighlighted={option.value === highlightedValue}
                      />
                    </>
                  )}
                </Field>
              </CardLabel>
            </Spacing>
          </GridItem>
        ))}
      </Grid>
    )
  }
}

export default CardRadioList
