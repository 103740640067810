import { ChangeEvent } from '@leaseplan/ui'
import React from 'react'
import { Field, FieldMetaState, FieldRenderProps } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { LocalizationContextProps } from 'mlp-client/src/localization/types'
import { DistanceUnit } from 'mlp-client/src/enums'
import { TranslationType } from 'mlp-client/src/form/types'
import { validateString } from 'mlp-client/src/form/utils'
import { formatNumber } from 'mlp-client/src/utils'
import { GrayDarkText } from 'mlp-client/src/components/styled/TextStyle'

import {
  ErrorMessage,
  InputWrapper,
  MileageUnit,
  StyledInput,
} from './Mileage.styled'

export interface Props {
  name?: string
  placeholder?: string
  label?: string | React.ReactElement<any>
  lastKnownMileage?: number
  variant?: 'primary' | 'secondary'
  lastKnownMileageLabel?: string
  distanceUnitLabel: string
  noMargin?: boolean
  required?: boolean
  lastKnownMileageRender?(
    formattedMileage: string | undefined,
  ): React.ReactElement<any>
  allowOnlyIntegers?: boolean
  onChange?(mileage: string): void
  onFocus?(focus: boolean, mileage?: string): void
}

const MIN = 0
const MAX = 1000000

type DefaultProps = Required<
  Pick<
    Props,
    | 'placeholder'
    | 'name'
    | 'lastKnownMileageRender'
    | 'distanceUnitLabel'
    | 'lastKnownMileageLabel'
    | 'variant'
    | 'noMargin'
    | 'required'
  >
>
type InternalProps = Props & DefaultProps

class MileageField extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    placeholder: 'myLeaseplan.components.mileage.placeholder',
    name: 'mileage',
    distanceUnitLabel: `myLeaseplan.shared.units.distance.${DistanceUnit.KM}`,
    lastKnownMileageLabel:
      'myLeaseplan.components.mileage.lastKnownMileageText',
    variant: 'primary',
    required: false,
    noMargin: false,
    lastKnownMileageRender: formattedMileage =>
      formattedMileage && (
        <GrayDarkText
          component="p"
          variant="200"
          loose
          data-e2e-id="lastKnownMileage"
        >
          {formattedMileage}
        </GrayDarkText>
      ),
  }

  private getValidation = (
    testFn: (value: string) => boolean,
    message: TranslationType,
  ) => (value: string) => (!testFn(value) && message) || undefined

  private getFloatValidation = (
    testFn: (value: number) => boolean,
    message: TranslationType,
  ) =>
    this.getValidation((value: string) => {
      if (!value) {
        return true
      }

      const valueAsNumber = parseFloat(value)

      return !Number.isNaN(valueAsNumber) && testFn(valueAsNumber)
    }, message)

  private integerValidator = this.getValidation(
    value => {
      if (!value) {
        return true
      }

      return (
        typeof value === 'string' && value.match(/^(-?[1-9]\d*|0)$/) !== null
      )
    },
    { id: 'myLeaseplan.components.mileage.invalidValue' },
  )

  private onMileageChange = ({
    input,
  }: FieldRenderProps<string, HTMLInputElement>) => (
    event: ChangeEvent<string>,
  ) => {
    const { allowOnlyIntegers, onChange } = this.props
    let newValue = event.value

    if (allowOnlyIntegers) {
      newValue = newValue.replace(/[.,].*$/i, '')
    }

    input.onChange(newValue)

    if (onChange) {
      onChange(newValue)
    }
  }

  private getValidationState(fieldMeta: FieldMetaState<string>) {
    if (fieldMeta.pristine) {
      return 'pristine'
    }

    if (fieldMeta.error && fieldMeta.touched) {
      return 'error'
    }

    if (fieldMeta.valid) {
      return 'valid'
    }

    return undefined
  }

  private renderLastKnownMileage(
    translate: LocalizationContextProps['translate'],
  ) {
    const {
      lastKnownMileage,
      lastKnownMileageLabel,
      lastKnownMileageRender,
      distanceUnitLabel,
    } = this.props

    const formattedLastKnown = lastKnownMileage
      ? `${translate(lastKnownMileageLabel)} ${formatNumber(
          lastKnownMileage,
        )} ${translate(distanceUnitLabel)}`
      : undefined

    return lastKnownMileageRender(formattedLastKnown)
  }

  private renderError(
    fieldMeta: FieldMetaState<string>,
    translate: LocalizationContextProps['translate'],
  ) {
    return (
      (fieldMeta.invalid && fieldMeta.error && fieldMeta.touched && (
        <ErrorMessage data-e2e-id="mileageFieldError">
          {translate(fieldMeta.error.id, fieldMeta.error.replaceValues)}
        </ErrorMessage>
      )) ||
      undefined
    )
  }

  render() {
    const {
      label,
      name,
      placeholder,
      lastKnownMileage,
      allowOnlyIntegers,
      variant,
      distanceUnitLabel,
      onFocus,
      required,
    } = this.props

    const integerValidations = allowOnlyIntegers ? [this.integerValidator] : []
    const validations = [
      this.getFloatValidation(num => num > MIN, {
        id: 'myLeaseplan.components.mileage.negativeValue',
      }),
      this.getFloatValidation(num => num >= (lastKnownMileage || 0), {
        id: 'myLeaseplan.components.mileage.lessThanLastKnown',
      }),
      this.getFloatValidation(num => num < MAX, {
        id: 'myLeaseplan.components.mileage.biggerThanMillion',
      }),
      required &&
        this.getValidation((value: string) => Boolean(value), {
          id: 'myLeaseplan.shared.requiredMessage',
        }),
      ...integerValidations,
    ].filter(Boolean)

    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <Field
            name={name}
            type="number"
            validate={validateString(validations)}
          >
            {fieldProps => (
              <>
                <InputWrapper>
                  <StyledInput
                    {...fieldProps.input}
                    onBlur={() => {
                      fieldProps.input.onBlur()

                      if (onFocus) {
                        onFocus(false, fieldProps.input.value)
                      }
                    }}
                    onFocus={() => {
                      fieldProps.input.onFocus()

                      if (onFocus) {
                        onFocus(true, fieldProps.input.value)
                      }
                    }}
                    onChange={this.onMileageChange(fieldProps)}
                    inputProps={{
                      min: MIN,
                      max: MAX,
                    }}
                    label={label}
                    placeholder={translate(placeholder)}
                    data-e2e-id="mileage"
                    validationState={this.getValidationState(fieldProps.meta)}
                    variant={variant}
                  />
                  <MileageUnit variant={variant}>
                    {translate(distanceUnitLabel)}
                  </MileageUnit>
                </InputWrapper>
                {this.renderLastKnownMileage(translate)}
                {this.renderError(fieldProps.meta, translate)}
              </>
            )}
          </Field>
        )}
      </LocalizationContext.Consumer>
    )
  }
}

export default MileageField
