import { SubmissionErrors } from 'final-form'

import { SubmitProfileFormTypes } from 'mlp-client/src/profile/types'
import { Options } from 'mlp-client/src/form/types'

export const enum ActionTypes {
  SUBMIT_PROFILE_FORM = '@@profile/lpis/SUBMIT_PROFILE_FORM',
  LOAD_COUNTRY_PROFILE = '@@profile/LOAD_COUNTRY_PROFILE',
  LOADED_COUNTRY_PROFILE = '@@profile/LOADED_COUNTRY_PROFILE',
}

export class SubmitProfileForm {
  readonly type = ActionTypes.SUBMIT_PROFILE_FORM

  constructor(
    readonly payload: {
      formData: SubmitProfileFormTypes
      onComplete: (errors?: SubmissionErrors) => void
    },
  ) {}
}

export class LoadCountryProfile {
  readonly type = ActionTypes.LOAD_COUNTRY_PROFILE
}

export class LoadedCountryProfile {
  readonly type = ActionTypes.LOADED_COUNTRY_PROFILE

  constructor(
    readonly payload: {
      countries: Options
      languages: Options
    },
  ) {}
}

export type Action =
  | SubmitProfileForm
  | LoadCountryProfile
  | LoadedCountryProfile
