export const QUOTE_TYPE = 'Quote'

export enum QuotesStatuses {
  CREATED = 'Created',
  EXPIRED = 'Expired',
  APPROVED = 'Approved',
}

export enum ViewQuoteSections {
  DealerBroker = 'dealerBroker',
  VehicleDetails = 'vehicleDetails',
  Contract = 'contractDetails',
  VehiclePrice = 'vehiclePrice',
  IncludedServices = 'includedServices',
  Insurance = 'insurance',
}

export enum ViewQuoteTabs {
  Contract = 'contractDetails',
  Vehicle = 'carDetails',
}

export enum DealerBrokerSectionProperties {
  DEALER = 'DEALER',
  SALESPERSON = 'SALESPERSON',
}

export enum VehicleSectionProperties {
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  MODEL_TYPE = 'MODEL_TYPE',
  MODEL_YEAR = 'MODEL_YEAR',
  CO2_EMISSION = 'CO2_EMISSION',
  ENERGY_TYPE = 'ENERGY_TYPE',
  TRANSMISSION = 'TRANSMISSION',
  DOORS = 'DOORS',
  SEATS = 'SEATS',
}

export enum ContractSectionProperties {
  ANNUAL_MILEAGE = 'ANNUAL_MILEAGE',
  DURATION = 'DURATION',
  INITIAL_NUMBER_OF_PAYMENTS = 'INITIAL_NUMBER_OF_PAYMENTS',
  MONTHLY_PRICE_INCL_VAT = 'MONTHLY_PRICE_INCL_VAT',
  MONTHLY_PRICE_EXCL_VAT = 'MONTHLY_PRICE_EXCL_VAT',
  NUMBER = 'NUMBER',
}

export enum VehiclePriceSectionProperties {
  PRICE_INCL_VAT = 'PRICE_INCL_VAT',
  PRICE_EXCL_VAT = 'PRICE_EXCL_VAT',
  ACCESSORIES_INCL_VAT = 'ACCESSORIES_INCL_VAT',
  ACCESSORIES_EXCL_VAT = 'ACCESSORIES_EXCL_VAT',
  TOTAL_OTHER_INCL_VAT = 'TOTAL_OTHER_INCL_VAT',
  TOTAL_OTHER_EXCL_VAT = 'TOTAL_OTHER_EXCL_VAT',
}

export enum IncludedServicesSectionProperties {
  MAINTENANCE = 'MAINTENANCE',
  REPLACEMENT_VEHICLE = 'REPLACEMENT_VEHICLE',
  ROAD_ASSISTANCE = 'ROAD_ASSISTANCE',
  VEHICLE_TAX_INITIAL = 'VEHICLE_TAX_INITIAL',
}
