import { Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import { SitecoreContentSection } from 'mlp-client/src/content/types'
import { GridLayout } from 'mlp-client/src/components/layout'
import MyleaseplanPage from 'mlp-client/src/components/layout/my-leaseplan-page/MyleaseplanPage'
import { FAQ } from 'mlp-client/src/flows/components/stolen-vehicle/faq/FAQ'
import { MainContent } from 'mlp-client/src/flows/components/stolen-vehicle/main-content/MainContent'

export type Props = {
  breadcrumbs?: React.ReactNode
} & Pick<SitecoreContentSection, 'content' | 'intro' | 'title'>

export const ContentPage: React.FC<Props> = ({
  title,
  intro,
  content,
  breadcrumbs,
}) => (
  <MyleaseplanPage metaTitle={title}>
    {breadcrumbs}
    <Spacing mb={3}>
      <GridLayout
        main={
          <Headline component="h1" variant="400" withMarginBottom>
            {title}
          </Headline>
        }
        mainSpan={12}
      />
    </Spacing>
    <GridLayout
      main={<MainContent content={content} e2eId="content-page-main" />}
      side={
        <FAQ intro={intro} phoneRenderer="default" e2eId="content-page-side" />
      }
      sideMaxWidth="600px"
    />
  </MyleaseplanPage>
)
