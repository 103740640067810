import { Dispatch } from 'redux'
import {
  ButtonChevronRightIcon,
  ButtonLink,
  Card,
  CardContent,
  DownloadIcon,
  Grid,
  GridItem,
  IconTextProps,
  Spacing,
} from '@leaseplan/ui'
import { Text, Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import sanitizeHtml from 'sanitize-html'

import {
  hasRegistrationCertificate,
  getRegistrationCertificateState,
} from 'mlp-client/src/contracts/selectors'
import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import {
  getLocale,
  getTranslations,
} from 'mlp-client/src/localization/selectors'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import { MyleaseplanPage } from 'mlp-client/src/components/layout/index'
import Loader from 'mlp-client/src/components/loader/Loader'
import { ShowMore } from 'mlp-client/src/components/show-more/ShowMore'
import {
  PolicyDocument,
  PolicyDocumentAttachment,
  PolicyDocuments,
  RegistrationCertificate,
} from 'mlp-client/src/contracts/types'
import { getBaseFileName, getExtension } from 'mlp-client/src/contracts/utils'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { SelectDocument } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/actions'
import {
  Document,
  Documents,
} from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/types'
import { ExternalLinkIcon } from 'mlp-client/src/components/icons'
import RequestVehicleDocumentIcon from 'mlp-client/src/components/icons/request-vehicle-document-icon/RequestVehicleDocumentIcon'
import { AppState } from 'mlp-client/src/types'
import {
  DownloadPolicyDocument,
  DownloadRegistrationCertificate,
} from 'mlp-client/src/contracts/actions'
import {
  isMobileApp as isMobileAppUtil,
  postMessage,
  PostMessageType,
} from 'mlp-client/src/auth/mobile-utils'
import { GrayDark60Text } from 'mlp-client/src/components/styled/TextStyle'
import { REGISTRATION_CERTIFICATE } from 'mlp-client/src/constants'
import { Locales } from 'mlp-client/src/localization/enums'
import DownloadableDocumentTile from 'mlp-client/src/downloadable-documents/components/DownloadableDocumentTile'
import { isFrance } from 'mlp-client/src/order-status/selectors'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

import { isDenmark } from '../utils'

const commonLinkProps: IconTextProps = {
  fontWeight: 'light',
  size: 's',
  color: 'midOrange',
}

interface DocumentTileProps {
  title: string
  dataE2eId?: string
}

const DocumentTile: React.FunctionComponent<DocumentTileProps> = ({
  title,
  children,
  dataE2eId,
}) => (
  <Spacing mb={2}>
    <Card data-e2e-id={dataE2eId}>
      <CardContent>
        <Headline variant="100">{title}</Headline>
        {children}
      </CardContent>
    </Card>
  </Spacing>
)

const DefaultTitle: React.FunctionComponent = () => (
  <Spacing mb={2}>
    <Translation
      id="myLeaseplan.vehicleDocuments.emptyDocumentPageText"
      element="h2"
    />
  </Spacing>
)

export interface Props {
  documents: Documents
  policyDocuments?: PolicyDocuments
  metaTitle: string
  documentsPageDefaultTitle: string
  downloadableDocumentsPageTitle: string
  metaDescription: string
  policyDocumentsTileTitle: string
  policyDocumentsTileDescription: string
  vehicleHasRegistrationCertificate: boolean
  registrationCertificate: RegistrationCertificate
  isLoading?: boolean
  isMobileApp: boolean
  locale: Locales
  selectDocument(documentId: string): void
  downloadPolicyDocument(
    fileId: string,
    documentType: string,
    fileName: string,
  ): void
  downloadRegistrationCertificate(documentId: string): void
}

type DefaultProps = Required<Pick<Props, 'isLoading'>>
type InternalProps = Props & DefaultProps

class DocumentsPage extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    isLoading: false,
  }

  componentDidMount() {
    this.props.selectDocument(null)
  }

  isRequestable(document: Document): boolean {
    if (
      document?.requestable &&
      document?.downloadUrl === null &&
      document?.name !== REGISTRATION_CERTIFICATE
    ) {
      return true
    }

    return false
  }

  getButton = (document: Document) => {
    const { documentId, downloadUrl, externalUrl, requestable } = document
    const registrationCertificateFile = this.isRegistrationCertificateFile(
      document,
    )

    if (
      downloadUrl ||
      externalUrl ||
      requestable ||
      registrationCertificateFile
    ) {
      return (
        <Grid>
          {downloadUrl && (
            <GridItem>
              <StyledButtonLink
                download={true}
                href={downloadUrl}
                icon={DownloadIcon}
                {...commonLinkProps}
                data-e2e-id="download-with-document-url"
              >
                <Translation id="myLeaseplan.vehicleDocuments.steps.documentDetail.buttons.download" />
              </StyledButtonLink>
            </GridItem>
          )}
          {externalUrl && (
            <GridItem>
              <StyledButtonLink
                href={externalUrl}
                target={isMobileAppUtil() ? 'self' : '_blank'}
                icon={ExternalLinkIcon}
                {...commonLinkProps}
                data-e2e-id="download-with-external-url"
              >
                <Translation id="myLeaseplan.vehicleDocuments.steps.documentDetail.buttons.goToDocument" />
              </StyledButtonLink>
            </GridItem>
          )}
          {this.isRequestable(document) && (
            <GridItem>
              <StyledButtonLink
                to="myLeaseplan.vehicleDocuments.base"
                {...commonLinkProps}
                data-e2e-id="request-for-a-document"
                icon={RequestVehicleDocumentIcon}
                component={RouteLink}
                onClick={() => this.props.selectDocument(documentId)}
              >
                <Translation id="myLeaseplan.vehicleDocuments.steps.documentDetail.buttons.request" />
              </StyledButtonLink>
            </GridItem>
          )}
          {registrationCertificateFile &&
            this.renderRegistrationCertificateFileLink()}
          {registrationCertificateFile &&
            this.renderRegistrationCertificateRequest(documentId)}
        </Grid>
      )
    }

    return null
  }

  renderDocument = (document: Document) => {
    const { title, description, documentId, name } = document

    return documentId.trim() ? (
      <DocumentTile title={title} key={String(Math.random())} dataE2eId={name}>
        <Text variant="200">
          <Spacing pv={1}>
            <ShowMore open={false}>
              <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
              />
            </ShowMore>
          </Spacing>
        </Text>
        {this.getButton(document)}
      </DocumentTile>
    ) : null
  }

  renderPolicyDocuments = (documents: PolicyDocuments) => (
    <DocumentTile
      title={this.props.policyDocumentsTileTitle}
      dataE2eId="policyDocumentsTile"
      data-test-id="policyDocumentsTile"
    >
      <Text variant="200">
        <Spacing pv={1}>{this.props.policyDocumentsTileDescription}</Spacing>
        <Spacing>{documents.map(this.renderAllPolicyDocuments)}</Spacing>
      </Text>
    </DocumentTile>
  )

  renderAllPolicyDocuments = (policyDocument: PolicyDocument) =>
    policyDocument.attachments.map(this.renderPolicyDocumentLink)

  renderPolicyDocumentLink = (attachment: PolicyDocumentAttachment) => {
    const extension = getExtension(attachment.name)
    const baseFileName = getBaseFileName(attachment.name)

    return (
      <Spacing key={attachment.id}>
        <StyledButtonLink
          icon={DownloadIcon}
          data-e2e-id={`${baseFileName}-download`}
          onClick={this.downloadPolicyDoc(attachment)}
          {...commonLinkProps}
        >
          {baseFileName} ({extension})
        </StyledButtonLink>
      </Spacing>
    )
  }

  downloadPolicyDoc = (attachment: PolicyDocumentAttachment) => () => {
    this.props.downloadPolicyDocument(
      attachment.id,
      attachment.contentType,
      attachment.name,
    )
  }

  isRegistrationCertificateFile = (document: Document): boolean => {
    if (!document || document.name !== REGISTRATION_CERTIFICATE) {
      return false
    }

    return this.props.vehicleHasRegistrationCertificate
  }

  renderRegistrationCertificateFileLink = () => {
    const {
      registrationCertificate,
      downloadRegistrationCertificate,
    } = this.props
    const baseFileName = getBaseFileName(registrationCertificate.fileName)

    return (
      <GridItem key={registrationCertificate.globalDocumentId}>
        <StyledButtonLink
          icon={DownloadIcon}
          data-e2e-id={`${baseFileName}-download`}
          data-test-id={`${baseFileName}-download`}
          onClick={() => {
            downloadRegistrationCertificate(
              registrationCertificate.globalDocumentId,
            )
          }}
          {...commonLinkProps}
        >
          <Translation id="myLeaseplan.vehicleDocuments.steps.documentDetail.buttons.download" />
        </StyledButtonLink>
      </GridItem>
    )
  }

  isRegistrationCertificateFileRequestable = (): boolean => {
    const { registrationCertificate } = this.props

    if (
      registrationCertificate?.globalDocumentId ||
      registrationCertificate?.globalDocumentId !== null
    ) {
      return false
    }

    return true
  }

  renderRegistrationCertificateRequest = (documentId: string) => {
    if (this.isRegistrationCertificateFileRequestable()) {
      return (
        <GridItem>
          <StyledButtonLink
            to="myLeaseplan.vehicleDocuments.base"
            {...commonLinkProps}
            data-e2e-id="request-for-a-document"
            icon={RequestVehicleDocumentIcon}
            component={RouteLink}
            onClick={() => this.props.selectDocument(documentId)}
          >
            <Translation id="myLeaseplan.vehicleDocuments.steps.documentDetail.buttons.request" />
          </StyledButtonLink>
        </GridItem>
      )
    }

    return null
  }

  renderTitle = (title: string, description?: string): JSX.Element => (
    <Grid justifyContent="flex-start" direction="row">
      <GridItem span={{ mobile: 12, tablet: 10, desktop: 7 }}>
        <Spacing mb={2}>
          <Headline id={title} variant="400" component="h1" withMarginBottom>
            {title}
          </Headline>
          <Text>{description}</Text>
        </Spacing>
      </GridItem>
    </Grid>
  )
  renderDownloadableDocumentsSection = (): JSX.Element => {
    const { downloadableDocumentsPageTitle } = this.props

    return (
      <>
        <DownloadableDocumentTile
          renderTitle={this.renderTitle(downloadableDocumentsPageTitle)}
        />
      </>
    )
  }

  renderDocumentsSection = (
    documents: Documents,
    policyDocuments: PolicyDocuments,
    shouldRenderFAQ?: boolean,
  ): JSX.Element => (
    <Grid justifyContent="space-between" direction="row">
      <GridItem span={{ mobile: 12, tablet: 10, desktop: 7 }}>
        {documents.map(this.renderDocument)}
        {policyDocuments &&
          policyDocuments.length > 0 &&
          !this.props.isMobileApp &&
          this.renderPolicyDocuments(policyDocuments)}
      </GridItem>
      {shouldRenderFAQ && (
        <GridItem span={{ mobile: 12, tablet: 10, desktop: 4 }}>
          <FAQ faqKey={FAQKeys.VEHICLE_DOCUMENTS_PAGE} />
        </GridItem>
      )}
    </Grid>
  )

  renderDefaultDocuments = (): JSX.Element => {
    const {
      metaTitle,
      metaDescription,
      documents,
      policyDocuments,
    } = this.props

    return (
      <>
        {this.renderTitle(metaTitle, metaDescription)}
        {this.renderDocumentsSection(documents, policyDocuments, true)}
      </>
    )
  }

  renderDocumentsForFrance = (): JSX.Element => {
    const {
      documentsPageDefaultTitle,
      downloadableDocumentsPageTitle,
      documents,
      policyDocuments,
      isLoading,
    } = this.props

    const downloadableDocuments = documents.filter(
      document => document.downloadable,
    )
    const requestableDocuments = documents.filter(
      document => !document.downloadable,
    )

    if (
      downloadableDocuments.length === 0 &&
      requestableDocuments.length === 0 &&
      !isLoading
    ) {
      return <DefaultTitle />
    }

    return (
      <>
        {downloadableDocuments.length > 0 &&
          this.renderTitle(downloadableDocumentsPageTitle)}
        {this.renderDocumentsSection(
          downloadableDocuments,
          policyDocuments,
          true,
        )}
        {requestableDocuments.length > 0 &&
          this.renderTitle(documentsPageDefaultTitle)}
        {this.renderDocumentsSection(requestableDocuments, policyDocuments)}
      </>
    )
  }
  renderDocuments = (): JSX.Element => {
    const { locale } = this.props

    if (isFrance(locale)) {
      return this.renderDocumentsForFrance()
    }

    if (isDenmark(locale)) {
      return (
        <>
          {this.renderDownloadableDocumentsSection()}
          {this.renderDefaultDocuments()}
        </>
      )
    }

    return this.renderDefaultDocuments()
  }

  renderBackNavigationButton = (
    metaTitle: string,
    isMobileApp: boolean,
  ): JSX.Element => {

    if (isMobileApp) {
      return (
        <Spacing mb={1}>
          <ButtonLink
            to="myLeaseplan.dashboard"
            icon={ButtonChevronRightIcon}
            component={RouteLink}
            iconAnimation="rotate180"
            iconAnimationState="active"
            onClick={() =>
              isMobileApp &&
              postMessage({ type: PostMessageType.DashboardNavigation })
            }
          >
            <Translation id="myLeaseplan.shared.back" />
          </ButtonLink>
        </Spacing>
      )
    }

    return (
      <Spacing mb={2} mr={1} mt={1}>
        <Breadcrumbs>
          <ButtonLink
            to="myLeaseplan.dashboard"
            component={RouteLink}
            size="s"
            fontWeight="regular"
            color="steel60"
          >
            <Translation id="myLeaseplan.subNavigation.dashboard" />
          </ButtonLink>
          <GrayDark60Text variant="200">{metaTitle}</GrayDark60Text>
        </Breadcrumbs>
      </Spacing>
    )
  }

  render() {
    const { metaTitle, metaDescription, isLoading, isMobileApp } = this.props

    return (
      <MyleaseplanPage metaTitle={metaTitle} metaDescription={metaDescription}>
        <Loader loading={isLoading} fixed={true}>
          {this.renderBackNavigationButton(metaTitle, isMobileApp)}
          {this.renderDocuments()}
        </Loader>
      </MyleaseplanPage>
    )
  }
}

type meta =
  | 'metaTitle'
  | 'metaDescription'
  | 'documentsPageDefaultTitle'
  | 'downloadableDocumentsPageTitle'

type registrationCertificate =
  | 'registrationCertificate'
  | 'vehicleHasRegistrationCertificate'

type policyDocuments =
  | 'policyDocumentsTileTitle'
  | 'policyDocumentsTileDescription'

type ReduxProps = Pick<
  Props,
  meta | 'isMobileApp' | policyDocuments | registrationCertificate | 'locale'
>

type DispatchProps = Pick<
  Props,
  | 'selectDocument'
  | 'downloadPolicyDocument'
  | 'downloadRegistrationCertificate'
>

const mapTranslationsToProps = {
  metaTitle: 'myLeaseplan.vehicleDocuments.steps.selectDocument.title',
  documentsPageDefaultTitle:
    'myLeaseplan.vehicleDocuments.steps.selectDocument.documentsPageDefaultTitle',
  downloadableDocumentsPageTitle:
    'myLeaseplan.vehicleDocuments.steps.selectDocument.downloadableDocumentsPageTitle',
  metaDescription:
    'myLeaseplan.vehicleDocuments.steps.selectDocument.description',
  policyDocumentsTileTitle:
    'myLeaseplan.vehicleDocuments.steps.selectDocument.carPolicyTileTitle',
  policyDocumentsTileDescription:
    'myLeaseplan.vehicleDocuments.steps.selectDocument.carPolicyTileDescription',
}

export const mapStateToProps = (state: AppState): ReduxProps => ({
  ...getTranslations(state, mapTranslationsToProps),
  isMobileApp: getIsMobileApp(state),
  vehicleHasRegistrationCertificate: hasRegistrationCertificate(state),
  registrationCertificate: getRegistrationCertificateState(state),
  locale: getLocale(state),
})

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectDocument: (documentId: string) =>
    dispatch(new SelectDocument({ documentId })),
  downloadPolicyDocument: (
    fileId: string,
    documentType: string,
    fileName: string,
  ) => dispatch(new DownloadPolicyDocument({ fileId, documentType, fileName })),
  downloadRegistrationCertificate: (documentId: string) =>
    dispatch(new DownloadRegistrationCertificate({ documentId })),
})

export { DocumentsPage }
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage)
