import { Text, TextProps } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

interface Props extends TextProps {
  labelKey: string
}

export const MileageLabel: React.SFC<Props> = props => {
  const defaultTextProps: TextProps = {
    variant: '300',
    bold: true,
    component: 'p',
  }
  const { labelKey, ...textProps } = props
  const finalTextProps = {
    ...defaultTextProps,
    ...textProps,
  }

  return (
    <Text {...finalTextProps}>
      <Translation id={labelKey} />
    </Text>
  )
}

MileageLabel.displayName = 'MileageLabel'
