import { ModalTrigger } from '@leaseplan/ui'
import React from 'react'

import { TileProps } from 'mlp-client/src/dashboard/type'
import MileageUpdateWithContract from 'mlp-client/src/components/mileage-update-modal/MileageUpdateWithContract'

export const MileageUpdateModalTrigger: React.FC<TileProps> = props => (
  <ModalTrigger
    trigger={props.children}
    modalContent={<MileageUpdateWithContract />}
  />
)
