import { Phone } from 'mlp-client/src/profile/types'

export interface UserProfileAddress {
  street: string
  number: string
  suffix?: string
  zipCode: string
  city: string
  stateProvince?: string
  country: string
  type: string
}

export interface UserProfile {
  salutation: string
  firstName: string
  lastName: string
  insertion?: string
  email: string
  phone?: string
  phoneCountryCode?: string
  mobile?: string
  mobilePhoneCountryCode?: string
  addresses?: UserProfileAddress[]
}

export interface ContactInfo {
  email: string
  phone: Phone
}

/**
 * The address format is an array of strings which contains address keywords
 * Each element in the array represents one address line
 * Each keyword must be wrapped in curly brackets
 * Each keyword will be replaced with the equivalent value, at a later stage
 *
 * Example:
 * ['{street} {number}{suffix}', '{zipCode}, {city}']
 * Would be replaced with:
 * ['Weteringcircuit 15C', '1077GC, Amsterdam']
 */
export type AddressFormat = readonly string[]

export interface UserInfo {
  gin: string
  countryCode: string
  defaultLocale: string
}

export interface User {
  id: string
  contractId?: string
  firstName: string
  lastName: string
  email: string
  birthDate: string
  mobilePhoneNumber: string
  mobilePhoneCountryCode: string
  phoneNumber: string
  phoneCountryCode: string
  correspondenceLanguageCode: string
  addresses: readonly Address[]
  brand?: string
  salutation?: string
}

export interface AddressData {
  street: string
  houseNumber: string
  city: string
  zipCode?: string
  country?: string
}

export interface Address {
  type?: AddressTypes
  addressLine1: string
  houseNumber?: string
  houseNumberSuffix?: string
  city: string
  province?: string
  country?: string
  zipCode: string
}

export enum AddressTypes {
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other',
}
