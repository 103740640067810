import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import Translation from 'mlp-client/src/localization/Translation'
import { DistanceUnit } from 'mlp-client/src/enums'
import { getDistanceUnit } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'

export interface Props {
  distanceUnit?: DistanceUnit
}

export const MileageUnitText: React.FC<Props> = ({
  distanceUnit = DistanceUnit.KM,
}): React.ReactElement => (
  <Translation id={`myLeaseplan.shared.units.distance.${distanceUnit}`} />
)

const mapStateToProps = createStructuredSelector<AppState, Props>({
  distanceUnit: getDistanceUnit,
})

export default connect(mapStateToProps)(MileageUnitText)
