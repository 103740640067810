import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const FuelCardIcon: FC<IconProps> = props => {
  FuelCardIcon.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g>
        <path
          fill={ColorEnumSelector(color)}
          d="M19.56,18.85c0,0.8-0.65,1.46-1.46,1.46s-1.45-0.65-1.45-1.46c0-0.8,0.65-1.46,1.45-1.46
				c0.58,0,1.08,0.35,1.31,0.85c0.02,0.07,0.04,0.14,0.06,0.21C19.52,18.58,19.56,18.71,19.56,18.85 M35.61,33.36
				c0,2.57-2.09,4.67-4.67,4.67H14.42c-2.58,0-4.67-2.1-4.67-4.67v-5.41h1.85l0.19-0.02c0.01,0,0.74-0.11,1.22,0.28
				c0.36,0.3,0.54,0.86,0.54,1.68v2.67c0.03,1.51,0.98,4.36,4.38,4.36c1.6,0,3.98-1.06,3.98-3.98V20.91c0-0.09-0.05-1.22-0.39-2.58
				c-0.05-0.32-0.14-0.63-0.27-0.91c-0.33-0.97-0.82-1.97-1.57-2.77l-4.79-4.79l-1.41,1.41l4.17,4.17
				c-1.69,0.23-2.99,1.66-2.99,3.41c0,1.9,1.55,3.46,3.45,3.46c0.66,0,1.27-0.19,1.79-0.52v11.16c0,1.81-1.52,1.97-1.98,1.98
				c-2.19,0-2.36-2-2.38-2.38V29.9c0-1.44-0.43-2.52-1.27-3.22c-1.06-0.88-2.36-0.78-2.76-0.73H9.75V12c0-2.58,2.09-4.67,4.67-4.67
				h16.52c2.57,0,4.67,2.09,4.67,4.67V33.36z M37.61,33.36V12c0-3.68-2.99-6.67-6.67-6.67H14.42c-3.68,0-6.67,2.99-6.67,6.67v21.36
				c0,3.68,2.99,6.67,6.67,6.67h16.52C34.61,40.03,37.61,37.04,37.61,33.36"
        />
      </g>
    </svg>
  )
}

export default FuelCardIcon
