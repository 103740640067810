import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class FuelCardIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M46.3 40.8a3.2 3.2 0 0 0 3.2-3.1c0-1.3-1.6-6.8-3.2-11.7a81.3 81.3 0 0 0-3.2 11.7 3.2 3.2 0 0 0 3.2 3.1zm0-21a1.5 1.5 0 0 1 1.4 1c.8 2.3 4.8 14.2 4.8 16.9a6.2 6.2 0 0 1-6.2 6.1 6.2 6.2 0 0 1-6.2-6.1c0-2.7 4-14.6 4.8-17a1.5 1.5 0 0 1 1.4-1zM59 43.1V20.4v-.8l-.1-.2-.2-.5-.1-.2-.2-.4-.2-.2a3.8 3.8 0 0 0-3-1.5H9.7a3.8 3.8 0 0 0-3 1.5c-.1 0-.2 0-.2.2l-.3.4v.2l-.2.5v.2a3.9 3.9 0 0 0-.2.8v22.8A3.8 3.8 0 0 0 9.7 47h45.5a3.8 3.8 0 0 0 3.8-3.8zm3-23.5v23.5c0 3.7-3 6.8-6.8 6.8H9.7a6.8 6.8 0 0 1-6.9-6.8V20.4v-.7-.2L3 19V19l.1-.5v-.1l.3-.6a7.4 7.4 0 0 1 .3-.6c0-.2.2-.3.3-.5l.4-.5.4-.5a6.8 6.8 0 0 1 4.9-2h45.5c1.9 0 3.6.7 4.8 2l.4.4.4.6a6.7 6.7 0 0 1 .7 1.1 7 7 0 0 1 .3 1.2v.1l.1.5v.2zm-49 13h10.7v-2.4h-2.4a1.5 1.5 0 1 1 0-3h2.4v-2.4H13.1v2.4h2.4a1.5 1.5 0 0 1 0 3h-2.4v2.3zm12.2-10.8a1.5 1.5 0 0 1 1.5 1.5v10.7a1.5 1.5 0 0 1-1.5 1.5H11.6a1.5 1.5 0 0 1-1.5-1.5V23.4a1.5 1.5 0 0 1 1.5-1.5h13.6zm-1.5 17a1.5 1.5 0 1 1 0 3H11.4a1.5 1.5 0 1 1 0-3h12.3z"
        />
      </svg>
    )
  }
}

export default FuelCardIcon
