import moment from 'moment'
import { createSelector } from 'reselect'

import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { AppState, Geolocation, Suppliers } from 'mlp-client/src/types'
import { isFeatureEnabled } from 'mlp-client/src/selectors'

import { ContractTypes } from './enums'
import {
  Contract,
  Contracts,
  DamageRecords,
  Notification,
  RegistrationCertificate,
  Tyres,
} from './types'
import {
  filterOrderContracts,
  findOrderContract,
  getEndDateOfContractAsString,
  getFuelType as getFuelTypeUtil,
  getIncludedServices as getIncludedServicesUtil,
  getLastKnownMileage,
  getVehicleProperties as getVehiclePropertiesUtil,
  canReturnCar,
  getEndDateOfContract,
} from './utils'

const getContractsState = (state: AppState) => state.contracts

export const isContractsLoading = (state: AppState): boolean => {
  const contractsState = getContractsState(state)

  return contractsState.loading
}

export const isContractsLoaded = (state: AppState): boolean => {
  const contractsState = getContractsState(state)

  return contractsState.isLoaded
}

export const getContracts = (state: AppState): Contracts => {
  const contractsState = getContractsState(state)

  return contractsState.contracts
}

export const getContractById = createSelector(
  getContracts,
  contracts => (contractId: string): Contract =>
    contracts.find(contract => contract.id === contractId) || null,
)

export const getCurrentContractId = (state: AppState): string =>
  state.contracts.currentContract || null

export const getCurrentContract = createSelector(
  getCurrentContractId,
  getContractById,
  (
    currentContractId: string,
    contractById: (contractId: string) => Contract,
  ): Contract => contractById(currentContractId),
)

export const getRegistrationCertificateState = (
  state: AppState,
): RegistrationCertificate =>
  state.registrationCertificateDocs.registrationCertificate || null //refactored reducer for sonar

export const hasRegistrationCertificate = (state: AppState): boolean =>
  Boolean(getRegistrationCertificateState(state))

export const hasContracts = (state: AppState): boolean =>
  Boolean(getContracts(state).length)

export const getEndDate = (state: AppState): moment.Moment => {
  const currentContract = getCurrentContract(state)

  return getEndDateOfContract(currentContract)
}

export const canReturn = (state: AppState) => (
  config: MyleaseplanConfig,
): boolean => {
  const contractEndDate: moment.Moment = getEndDate(state)
  const currentDate: moment.Moment = moment()
  const daysToReturn: number =
    config.dashboard?.configuration?.numberOfDaysToReturn
  const disableEndOfLeaseAfterCurrentDate: boolean | undefined =
    config.contracts?.configuration?.disableEndOfLeaseAfterCurrentDate

  return canReturnCar(
    contractEndDate,
    daysToReturn,
    currentDate,
    disableEndOfLeaseAfterCurrentDate,
  )
}

export const isFuelCardsAvailable = createSelector(
  getCurrentContract,
  (contract: Contract) => contract?.isFuelCardsAvailable || false,
)

export const getContract = (state: AppState): Contract =>
  getCurrentContract(state)

export const getContractTyresInfo = createSelector(
  getContract,
  (contract: Contract) => contract.tyres || {},
)

export const isSeasonalTyreChangeEnabled = createSelector(
  getContractTyresInfo,
  (tyres: Tyres) => Boolean(tyres.seasonalTyreChange),
)

export const getTyreStorageLocationName = createSelector(
  getContractTyresInfo,
  (tyres: Tyres) => tyres.tyreStorageLocationName || null,
)

export const getLeaseContracts = createSelector(getContracts, contracts =>
  contracts.filter(contract => contract.contractType === ContractTypes.LEASE),
)

export const getFirstContract = createSelector(getLeaseContracts, contracts => {
  if (contracts.length) {
    return contracts[0]
  }
})

export const getContractStartUnixTime = createSelector(
  getCurrentContract,
  currentContract => {
    const { startDate } = currentContract

    return moment(startDate).isValid() ? moment(startDate).valueOf() : null
  },
)

export const lastKnownMileageSelector = createSelector(
  getContract,
  getLastKnownMileage,
)

export const contractEndDateSelector = createSelector(
  getContract,
  getEndDateOfContractAsString,
)

const hasMultipleContracts = createSelector(
  getContracts,
  (contracts: Contracts) => contracts.length > 1,
)

export const hasManyContracts = createSelector(
  getLeaseContracts,
  (contracts: Contracts) => contracts.length > 6,
)

export const showMultipleVehicles = (state: AppState): boolean =>
  isFeatureEnabled(state, 'supportsMultipleVehicles') &&
  hasMultipleContracts(state)

const hasMultipleLeaseContracts = createSelector(
  getLeaseContracts,
  (contracts: Contracts) => contracts.length > 1,
)

export const showMultipleLeaseVehicles = (state: AppState): boolean =>
  isFeatureEnabled(state, 'supportsMultipleVehicles') &&
  hasMultipleLeaseContracts(state)

export const getPolicyDocuments = (state: AppState) =>
  state.contracts.policyDocuments

export const isPolicyDocumentsLoading = (state: AppState) =>
  state.contracts.loadingPolicyDocuments

export const isPolicyDocumentsEnabled = (state: AppState): boolean =>
  isFeatureEnabled(state, 'policyDocuments')

export const getOrderContract = createSelector(getContracts, findOrderContract)

export const getOrders = createSelector(getContracts, filterOrderContracts)

export const hasManyOrders = createSelector(
  getOrders,
  (contracts: Contracts) => contracts.length > 3,
)

export const getVehicleInfoForOrder = createSelector(
  getOrderContract,
  contract => getVehiclePropertiesUtil(contract),
)
export const canDoMaintenanceBooking = createSelector(
  getCurrentContract,
  contract => Boolean(contract && contract.isAvailableForBooking),
)

export const getNearbySuppliers = (state: AppState) =>
  state.contracts.nearbySuppliers

export const getSuppliersSeachLocation = (state: AppState): Geolocation =>
  getNearbySuppliers(state).searchLocation

export const getNearbySuppliersStatus = (state: AppState): string =>
  getNearbySuppliers(state).status

export const getNearbySuppliersReason = (state: AppState): string =>
  getNearbySuppliers(state).reason

export const getNearbySuppliersSlots = (state: AppState): Suppliers =>
  getNearbySuppliers(state).suppliers

export const getNoSuppliersByMalfunctions = (state: AppState): boolean =>
  getNearbySuppliers(state).noSuppliersByMalfunctions

export const getSuppliersNumberFetched = (state: AppState): boolean =>
  getNearbySuppliers(state).isSuppliersNumberFetched

export const getIncludedServices = createSelector(
  getCurrentContract,
  getIncludedServicesUtil,
)

export const getFuelType = createSelector(getCurrentContract, getFuelTypeUtil)

export const getDamages = createSelector(
  getCurrentContract,
  (contract: Contract): DamageRecords =>
    (contract.vehicleHistory && contract.vehicleHistory.damages) || [],
)

export const getNotifications = (state: AppState): readonly Notification[] =>
  state.contracts.notifications

export const getPredictiveMaintenanceNotification = createSelector(
  getNotifications,
  notifications =>
    notifications.find(
      notification => notification.notificationType === 'PredictiveMaintenance',
    ),
)
export const getPredictiveMileageModalContractId = (
  state: AppState,
): string | null => state.contracts.showPredictiveMileageModalForContractId
