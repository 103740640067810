import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const MileageIconLeasys: FC<IconProps> = props => {
  MileageIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g fill={ColorEnumSelector(color)}>
        <path
          d="M4.79,36.32h35.74c1.08-2.46,1.62-5.08,1.62-7.8c0-10.74-8.74-19.48-19.48-19.48S3.2,17.77,3.2,28.51
				C3.2,31.21,3.74,33.88,4.79,36.32 M41.17,38.32L41.17,38.32l-37.03,0c-0.39,0-0.74-0.22-0.9-0.57c-1.35-2.87-2.04-6.03-2.04-9.23
				c0-11.84,9.63-21.48,21.48-21.48c11.84,0,21.48,9.63,21.48,21.48c0,3.23-0.7,6.34-2.08,9.23C41.91,38.09,41.56,38.32,41.17,38.32
				"
        />
        <path
          d="M29.38,23.9c0-0.38-0.31-0.68-0.68-0.68c-0.21,0-0.4,0.1-0.52,0.25l-6.2,5.67
				c-0.14,0.08-0.26,0.19-0.35,0.32c-0.12,0.18-0.19,0.39-0.19,0.62c0,0.6,0.49,1.09,1.09,1.09c0.3,0,0.58-0.12,0.78-0.33l5.88-6.46
				C29.3,24.26,29.38,24.09,29.38,23.9"
        />
        <path d="M22.68,17c-0.55,0-1-0.45-1-1v-2.8c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1V16C23.68,16.56,23.23,17,22.68,17" />
        <path
          d="M29.28,19.05c-0.23,0-0.46-0.08-0.65-0.24c-0.42-0.36-0.47-0.99-0.12-1.41l1.81-2.14
				c0.36-0.42,0.99-0.47,1.41-0.12c0.42,0.36,0.48,0.99,0.12,1.41l-1.81,2.14C29.85,18.93,29.57,19.05,29.28,19.05"
        />
        <path
          d="M34.08,24.31c-0.35,0-0.7-0.19-0.88-0.52c-0.26-0.49-0.08-1.09,0.4-1.36l2.46-1.34
				c0.49-0.26,1.09-0.08,1.36,0.4c0.26,0.49,0.08,1.09-0.4,1.36l-2.46,1.34C34.4,24.28,34.24,24.31,34.08,24.31"
        />
        <path d="M38.11,30.8h-2.8c-0.55,0-1-0.45-1-1s0.45-1,1-1h2.8c0.55,0,1,0.45,1,1S38.66,30.8,38.11,30.8" />
        <path
          d="M16.09,19.02c-0.27,0-0.55-0.11-0.75-0.33l-1.87-2.09c-0.37-0.41-0.33-1.04,0.08-1.41
				c0.41-0.37,1.05-0.33,1.41,0.08l1.87,2.09c0.37,0.41,0.33,1.04-0.08,1.41C16.57,18.94,16.33,19.02,16.09,19.02"
        />
        <path
          d="M11.27,24.31c-0.16,0-0.32-0.04-0.48-0.12l-2.46-1.34c-0.48-0.26-0.66-0.87-0.4-1.35
				c0.26-0.49,0.87-0.66,1.36-0.4l2.46,1.34c0.49,0.26,0.67,0.87,0.4,1.36C11.97,24.13,11.62,24.31,11.27,24.31"
        />
        <path d="M10.04,30.8h-2.8c-0.55,0-1-0.45-1-1s0.45-1,1-1h2.8c0.55,0,1,0.45,1,1S10.59,30.8,10.04,30.8" />
      </g>
    </svg>
  )
}

export default MileageIconLeasys
