import { mq, spacingUnit } from '@leaseplan/ui'
import styled from 'styled-components'

import { MainContentWrapper } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper'

export const FullWidthImage = styled.div<{
  imgMedium: string
  imgLarge: string
  imgSmall: string
  position: string
  noGradient?: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;
  background-position: ${p => p.position || '50% 0'};
  background-size: cover;
  hyphens: auto;
  word-break: break-word;
  ${p =>
    p.imgLarge &&
    mq.from('lap')`background-image: ${
      p.noGradient
        ? ''
        : 'linear-gradient(90deg, rgba(0, 0, 0, 0.4), transparent),'
    }url(${p.imgLarge});`}
  ${p =>
    p.imgMedium &&
    mq.until('lap')`background-image: ${
      p.noGradient
        ? ''
        : 'linear-gradient(90deg, rgba(0, 0, 0, 0.4), transparent),'
    }url(${p.imgMedium});`}
  ${p =>
    p.imgSmall &&
    mq.until('mobileLandscape')`background-image: ${
      p.noGradient
        ? ''
        : 'linear-gradient(90deg, rgba(0, 0, 0, 0.4), transparent),'
    }url(${p.imgSmall});`}
  ${p => mq.from('lap')`${p.noGradient ? '' : 'height:340px;'}`}
`

export const BannerContent = styled(MainContentWrapper)`
  flex: 1 0 100%;
  padding: 0 16px;
`

export const BreadcrumbsContent = styled(MainContentWrapper)`
  position: absolute;
  ${mq.until('tablet')`
      top: ${spacingUnit * 2}px;
  `}
  ${mq.from('tablet')`
      top: ${spacingUnit * 3}px;
  `}
`
