import { Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import {
  getTranslation,
  getUserLocale,
} from 'mlp-client/src/localization/selectors'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import Loader from 'mlp-client/src/components/loader/Loader'
import onlyOrderExists from 'mlp-client/src/components/onlyOrderExists'
import {
  getContracts,
  showMultipleLeaseVehicles as getShowMultipleLeaseVehicles,
} from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import { dashboardContentPath } from 'mlp-client/src/constants'
import { TimelineFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import {
  getDocuments,
  pageContentEndpoint,
} from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import { Content, SiteCoreDocument } from 'mlp-client/src/content/types'
import { TimelineBanners } from 'mlp-client/src/timeline/enums'
import {
  ChangePaginationItemsPerPage,
  LoadTimeLine,
  SelectVehicle,
  ChangePaginationPage,
} from 'mlp-client/src/timeline/actions'
import ContactUsBanner from 'mlp-client/src/timeline/components/ContactUsBanner/ContactUsBanner'
import ContactUsWithModal from 'mlp-client/src/timeline/components/ContactUsWithModal/ContactUsWithModal'
import { Timeline } from 'mlp-client/src/timeline/components/Timeline/Timeline'
import {
  getSelectedVehicles,
  getSortedTimelines,
  getTimeline,
  getPagination,
} from 'mlp-client/src/timeline/selectors'
import {
  Pagination,
  SelectedVehicles,
  TimelineItem,
} from 'mlp-client/src/timeline/types'
import { getTimelineEventType } from 'mlp-client/src/timeline/utils'
import { getPageContent } from 'mlp-client/src/selectors'
import { LoadContent } from 'mlp-client/src/content/actions'
import { Locales } from 'mlp-client/src/localization/enums'
import { AppState } from 'mlp-client/src/types'
import { SomethingWentWrong } from 'mlp-client/src/components/something-went-wrong/SomethingWentWrong'

interface Payload {
  endpoint: typeof pageContentEndpoint | typeof dashboardContentPath
  locale?: Locales
}

export interface Props extends MyLeaseplanFeatureProps<TimelineFeatureConfig> {
  metaTitle: string
  isLoading?: boolean
  isFailed?: boolean
  timelines: readonly TimelineItem[]
  pagination: Pagination
  isMobileApp?: boolean
  bannerTitle?: string
  contactButton?: string
  vehicleDocuments: readonly SiteCoreDocument[]
  dashboardContent?: Content
  contracts: readonly Contract[]
  showMultipleVehicles: boolean
  selectedVehicles: SelectedVehicles
  selectVehicle(contractId: string): void
  loadContent(payload: Payload): void
  loadTimeline(): void
  changeItemsPerPage(itemsPerPage: number): void
  changePage(page: number): void
  locale: Locales
}

class ViewTimeline extends React.Component<Props> {
  componentDidMount() {
    const { loadContent, loadTimeline, dashboardContent } = this.props

    loadTimeline()

    loadContent({ endpoint: pageContentEndpoint })

    // dashboardContent is used by underlying component(s), (e.g. ContactUsWithModal), but they
    // don't try to load the content themselves. Instead, they assume the content already exists in the store.
    // The assumption creates implicit component dependency. Moreover, components that do need the content
    // might be rendered conditionally (that's what actually happens with ContactUsWithModal here) but
    // the content is loaded regardless. That might result in unnecessary network requests.
    // TODO: come up with as HOC/Hook which will allow to load content from a component which actually use it.
    if (!dashboardContent) {
      loadContent({ endpoint: dashboardContentPath })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      loadContent,
      locale,
      selectedVehicles,
      loadTimeline,
      changePage,
    } = this.props

    if (prevProps.locale !== locale) {
      loadContent({ endpoint: pageContentEndpoint, locale })
    }

    if (prevProps.selectedVehicles !== selectedVehicles) {
      changePage(0)
      loadTimeline()
    }
  }

  filterTimelineByConfig = () => {
    const {
      timelines,
      featureConfig: { configuration },
    } = this.props
    const configuredCards = Object.keys(configuration.cards)

    return (
      timelines
        // Filter timeline to only display configured cards
        .filter((item: TimelineItem) =>
          configuredCards.includes(getTimelineEventType(item)),
        )
    )
  }

  getConfiguredBanner = () => {
    const { configuration } = this.props.featureConfig

    if (configuration.banner.viewId === TimelineBanners.CONTACT_US_WITH_MODAL) {
      return <ContactUsWithModal />
    }

    return <ContactUsBanner config={configuration.banner.config} />
  }

  render() {
    const {
      isLoading,
      isFailed,
      metaTitle,
      isMobileApp,
      showMultipleVehicles,
      selectedVehicles,
      selectVehicle,
      vehicleDocuments,
      contracts,
      featureConfig,
      pagination,
      changeItemsPerPage,
      changePage,
      loadTimeline,
    } = this.props

    if (isFailed) {
      return (
        <MyleaseplanPage metaTitle={metaTitle}>
          <SomethingWentWrong
            title={metaTitle}
            data-test="something-went-wrong"
          />
        </MyleaseplanPage>
      )
    }

    return (
      <MyleaseplanPage metaTitle={metaTitle}>
        <Loader loading={isLoading} fixed={true}>
          <div className="my-leaseplan-view">
            {!isMobileApp && (
              <Headline component="h1" variant="400" withMarginBottom>
                {metaTitle}
              </Headline>
            )}

            <Timeline
              showMultipleVehicles={showMultipleVehicles}
              vehicleDocuments={vehicleDocuments}
              selectVehicle={selectVehicle}
              selectedVehicles={selectedVehicles}
              contracts={contracts}
              featureConfig={featureConfig.configuration}
              isLoading={isLoading}
              banner={this.getConfiguredBanner()}
              timelineItems={this.filterTimelineByConfig()}
              pagination={pagination}
              changeItemsPerPage={changeItemsPerPage}
              changePage={changePage}
              totalTimelineItems={pagination.totalCount}
              loadTimeline={loadTimeline}
              isMobileApp={isMobileApp}
            />
          </div>
        </Loader>
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'timelines'
  | 'pagination'
  | 'metaTitle'
  | 'isLoading'
  | 'isFailed'
  | 'isMobileApp'
  | 'vehicleDocuments'
  | 'dashboardContent'
  | 'showMultipleVehicles'
  | 'contracts'
  | 'selectedVehicles'
  | 'locale'
>
type DispatchProps = Pick<
  Props,
  | 'selectVehicle'
  | 'loadContent'
  | 'loadTimeline'
  | 'changeItemsPerPage'
  | 'changePage'
>

const mapStateToProps = (state: AppState): ReduxProps => ({
  timelines: getSortedTimelines(state),
  pagination: getPagination(state),
  metaTitle: getTranslation(state, 'myLeaseplan.timeline.base.title'),
  isLoading: getTimeline(state).isLoading,
  isFailed: getTimeline(state).isFailed,
  isMobileApp: getIsMobileApp(state),
  vehicleDocuments: getDocuments(state),
  dashboardContent: getPageContent(state, dashboardContentPath),
  showMultipleVehicles: getShowMultipleLeaseVehicles(state),
  contracts: getContracts(state),
  selectedVehicles: getSelectedVehicles(state),
  locale: getUserLocale(state),
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectVehicle: (contractId: string) =>
    dispatch(new SelectVehicle(contractId)),
  loadContent: (payload: Payload) => dispatch(new LoadContent(payload)),
  loadTimeline: () => dispatch(new LoadTimeLine()),
  changeItemsPerPage: (itemsPerPage: number) =>
    dispatch(new ChangePaginationItemsPerPage(itemsPerPage)),
  changePage: (page: number) => dispatch(new ChangePaginationPage(page)),
})

export { ViewTimeline }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(onlyOrderExists(ViewTimeline))
