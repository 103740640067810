import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import { StepViewProps } from 'mlp-client/src/flows/vehicle-documents/types'
import { makeTranslationPath } from 'mlp-client/src/utils'

const buildTranslationId = makeTranslationPath(
  'myLeaseplan.vehicleDocuments.steps.thankYou',
)

export interface StepConfig {
  estimatedTimeOfDelivery: number
}

export interface Props extends StepViewProps<StepConfig> {}

export class ThankYouStep extends React.PureComponent<Props> {
  render() {
    const {
      closeFlow,
      config: { estimatedTimeOfDelivery },
    } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id={buildTranslationId('pageTitle')} />
        </Headline>
        <Section headerText={buildTranslationId('whatsNext.headerText')}>
          <Translation
            id={buildTranslationId('whatsNext.estimatedTimeOfDelivery')}
            replace={{ numberOfDays: estimatedTimeOfDelivery }}
          />
        </Section>
      </ThankyouPageLayout>
    )
  }
}

export default ThankYouStep
