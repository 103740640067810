import { Spacing } from '@leaseplan/ui'
import React from 'react'

import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { ThankyouPageWrapper } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout.styled'
import { BackToDashboardButton } from 'mlp-client/src/flows/components/buttons'

interface Props {
  onClick?(): void
  metaTitle?: string
}

export class ThankyouPageLayout extends React.PureComponent<Props> {
  render() {
    const { children, onClick, metaTitle } = this.props

    return (
      <MyleaseplanPage metaTitle={metaTitle}>
        <ThankyouPageWrapper data-e2e-id="thankYou">
          {children}
          {onClick && (
            <Spacing mt={2}>
              <BackToDashboardButton onClick={onClick} />
            </Spacing>
          )}
        </ThankyouPageWrapper>
      </MyleaseplanPage>
    )
  }
}
