import React from 'react'
import { connect } from 'react-redux'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import onlyOrderExists from 'mlp-client/src/components/onlyOrderExists'
import { AppState } from 'mlp-client/src/types'
import {
  getCurrentContractId,
  showMultipleVehicles,
} from 'mlp-client/src/contracts/selectors'
import { hasOrders } from 'mlp-client/src/order-status/selectors'

export interface Props {
  showMultipleVehicles: boolean
  userHasOnlyMultipleOrders: boolean
  contractId: string
}

class HomePage extends React.PureComponent<Props> {
  render() {
    const {
      showMultipleVehicles,
      userHasOnlyMultipleOrders,
      contractId,
    } = this.props

    return showMultipleVehicles || userHasOnlyMultipleOrders ? (
      <TranslatedRedirect to="myLeaseplan.vehicles" />
    ) : (
      <TranslatedRedirect to="myLeaseplan.dashboard" params={{ contractId }} />
    )
  }
}

type ReduxProps = Pick<
  Props,
  'showMultipleVehicles' | 'userHasOnlyMultipleOrders' | 'contractId'
>

const mapStateToProps = (state: AppState): ReduxProps => ({
  showMultipleVehicles: showMultipleVehicles(state),
  userHasOnlyMultipleOrders: hasOrders(state),
  contractId: getCurrentContractId(state),
})

export { HomePage }
export default connect(mapStateToProps)(onlyOrderExists(HomePage))
