import { call, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

import { ApiSagas } from 'mlp-client/src/api'
import * as actions from 'mlp-client/src/flows/vehicle-documents/actions'
import vehicleDocumentsPageSagas from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/sagas'

const defaultRequestOptions = {
  throwError: true,
}

export function* vehicleDocumentsRequest({
  payload: { request, onComplete },
}: actions.SubmitVehicleDocumentsRequest): SagaIterator {
  const requestBody = {
    body: {
      ...request,
    },
  }
  let isRequestSuccessful: boolean = true

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/vehicle-documents/',
      requestBody,
      defaultRequestOptions,
    )
    yield call(onComplete, false)
  } catch {
    isRequestSuccessful = false
  } finally {
    yield call(onComplete, !isRequestSuccessful)
  }
}

export default [
  takeLatest(
    actions.ActionTypes.VEHICLE_DOCUMENTS_REQUEST,
    vehicleDocumentsRequest,
  ),
  ...vehicleDocumentsPageSagas,
]
