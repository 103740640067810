import { Checkbox } from '@leaseplan/ui'
import React from 'react'
import { Field } from 'react-final-form'

import { Validator } from 'mlp-client/src/form/types'
import { composeValidators } from 'mlp-client/src/form/utils'

export interface Props {
  name: string
  label?: string | React.ReactElement<any>
  validations?: ReadonlyArray<Validator<boolean>>
  dataE2eId?: string
  disabled?: boolean
}

const CheckboxField: React.FC<Props> = ({
  name,
  label,
  validations = [],
  dataE2eId = name,
  disabled,
}) => (
  <Field<boolean> name={name} validate={composeValidators(validations)}>
    {({ input }) => (
      <Checkbox
        {...input}
        checked={input.value}
        onChange={e => input.onChange(e.checked)}
        label={label}
        data-e2e-id={dataE2eId}
        disabled={disabled}
      />
    )}
  </Field>
)

export default CheckboxField
