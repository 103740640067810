import React from 'react'
import { Text, VelocityConsumer } from '@velocity/ui'
import styled from 'styled-components'

import {
  getOrderDetailsColor,
  getOrderStatusColor,
} from 'mlp-client/src/order-status/utils'
import Translation from 'mlp-client/src/localization/Translation'
import { TableOrderVehicleData } from 'mlp-client/src/components/overview-table/types'

interface Props {
  item: TableOrderVehicleData
}

const StyledText = styled.span<{ backgroundColor: string }>`
  padding: 2px 4px;
  border-radius: 2px;
  text-align: center;
  ${({ backgroundColor }) => backgroundColor && `background:${backgroundColor}`}
`

const SText = styled(Text)`
  text-transform: uppercase;
`

class TitleCell extends React.PureComponent<Props> {
  render() {
    const { item } = this.props

    return (
      <VelocityConsumer>
        {({ theme }) => (
          <StyledText
            backgroundColor={getOrderDetailsColor(item.orderStatus, theme)}
          >
            <SText
              variant="100"
              bold
              style={{ color: getOrderStatusColor(item.orderStatus, theme) }}
              component="span"
            >
              <Translation id={item.title} />
            </SText>
          </StyledText>
        )}
      </VelocityConsumer>
    )
  }
}

export default TitleCell
