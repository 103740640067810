import { Options } from 'mlp-client/src/form/types'
import { GlassDamageType } from 'mlp-client/src/types'

export const damageTypeOptions: Options = [
  {
    title: 'myLeaseplan.glassRepair.types.smallDamage.title',
    value: GlassDamageType.SMALL_CHIP,
    image: {
      backgroundImage:
        '/static/images/my-leaseplan/glass-repair-small-chip.jpg',
    },
  },
  {
    title: 'myLeaseplan.glassRepair.types.bigDamage.title',
    value: GlassDamageType.LARGE_CHIP,
    image: {
      backgroundImage: '/static/images/my-leaseplan/glass-repair-big-crack.jpg',
    },
  },
  {
    title: 'myLeaseplan.glassRepair.types.panoramaDamage.title',
    value: GlassDamageType.CRACK,
    image: {
      backgroundImage: '/static/images/my-leaseplan/glass-repair-panorama.jpg',
    },
  },
]

export const getFirstValue = (options: Options): string => {
  if (options.length) {
    return options[0].value
  }
}

export const getDamageTypeOptions = (isBookingEnabled: boolean): Options =>
  damageTypeOptions.map(option => {
    if (!isBookingEnabled && option.value === GlassDamageType.SMALL_CHIP) {
      return {
        ...option,
        disabled: true,
        subtitle: 'myLeaseplan.genericNotifications.bookingNotAvailable',
      }
    }

    return option
  })

export const getDamageTypeLabel = (
  damageType: GlassDamageType,
): string | undefined => {
  const option = damageTypeOptions.find(option => option.value === damageType)

  return option && option.title
}
