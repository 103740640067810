import { Text, colorMixins } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import MileageUnitText from 'mlp-client/src/form/components/mileage/MileageUnitText'
import { TableLeaseVehicleData } from 'mlp-client/src/components/overview-table/types'

interface Props {
  item: TableLeaseVehicleData
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
`

export class MileageCell extends React.PureComponent<Props> {
  render() {
    const { item } = this.props

    return item.mileage ? (
      <>
        {item.mileage} <MileageUnitText />
        {item.maximumAllowedMileage && (
          <SText variant="200" component="span">
            /{item.maximumAllowedMileage} <MileageUnitText />
          </SText>
        )}
      </>
    ) : (
      '-'
    )
  }
}

export default MileageCell
