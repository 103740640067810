import React from 'react'

import {
  addSelectedValue,
  hasSelectedValue,
  removeSelectedValue,
} from 'mlp-client/src/form/utils'

export interface Props {
  onChange(event: GenericObject, index: number): void
  render(args: GenericObject): any
  value: string
  group?: string
  name?: string
}

class CheckboxList extends React.PureComponent<Props> {
  handleChange = (event: GenericObject, index: number) => {
    this.props.onChange(
      {
        ...event,
        target: {
          value: event.target.checked
            ? addSelectedValue(this.props.value, event.target.value)
            : removeSelectedValue(this.props.value, event.target.value),
        },
      },
      index,
    )
  }

  getOptionProps = ({
    value,
    group,
    name,
    index,
    focusHandler,
  }: {
    value: string
    group: string
    name: string
    index: number
    focusHandler(focus: boolean): void
  }) => ({
    value,
    onChange: (event: GenericObject) => this.handleChange(event, index),
    checked: this.getIsSelected(value),
    onFocus: () => focusHandler(true),
    onBlur: () => focusHandler(false),
    type: 'checkbox',
    name: `${group}-${name}`,
    id: `field--${name}-${index}`,
  })

  getIsSelected = (value: string) => hasSelectedValue(this.props.value, value)

  render() {
    return this.props.render({
      getOptionProps: this.getOptionProps,
      getIsSelected: this.getIsSelected,
      handleChange: this.handleChange,
    })
  }
}

export default CheckboxList
