import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'

export interface StepViewProps<TConfig = {}, FlowData = {}>
  extends FlowProps<FlowData> {
  config: TConfig
}

export interface StepDefinition<TConfig = {}> {
  stepView: React.ComponentType<StepViewProps<TConfig>>
  stepConfig: TConfig
}

export enum TimeWindowTypes {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
}
