import {
  ThemeOverrides,
  VelocityStyling,
  VelocityTheme,
} from '@velocity/ui/theme/VelocityTheme/VelocityTheme.types'

const velocityStylingLPLU: VelocityStyling = {
  gray: {
    20: '#DDDDDD',
    40: '#BBBBBB',
    60: '#989A9A',
    80: '#767878',
    darkest: '#18305b', //changed
    main: '#18305b', //changed
    dark: '#545656',
    light: '#EEEEEE',
    ultralight: '#F6F6F6',
    white: '#FFFFFF',
  },
  secondary: {
    dark: {
      20: '#CCDBDF',
      40: '#99B7BE',
      60: '#66929E',
      80: '#336E7D',
      default: '#18305b', //used by SecondaryDarkText eg. number phone from ContactUsBanner, details on Contract etc
    },
    main: {
      20: '#CCEFEF',
      40: '#99E0DF',
      60: '#66D0CF',
      80: '#33C1BF',
      default: '#00B1AF',
    },
    light: {
      20: '#EEF4CF',
      40: '#DDE99F',
      60: '#CBDF6E',
      80: '#BAD43E',
      default: '#A9C90E',
    },
    fresh: {
      20: '#CCEBD1',
      40: '#99D7A2',
      60: '#66C274',
      80: '#33AE45',
      default: '#009A17',
    },
    intense: '#545656',
    darkcontrast: {
      text: '#2D2D2D',
    },
    lightcontrast: {
      text: '#FFFFFF',
    },
  },
  primary: {
    main: '#18305b', //used for supplier text and policy doc download link
    dark: '#18305b', //used for profile icon, orange texts(text from dashboard), upload photo card on hover
    light: '#ED8B00',
    text: '#FFFFFF',
  },
  system: {
    success: {
      main: '#067417',
      light: '#edf8ee',
      text: '#067417',
    },
    error: {
      main: '#cf3516',
      light: '#fef4f4',
      text: '#cf3516',
    },
    warning: {
      main: '#f0c800',
      light: '#fbf5d5',
      text: '#004A5D',
    },
    info: {
      main: '#87cdff',
      light: '#e7f5ff',
      text: '#004A5D',
    },
  },
  interface: {
    background: {
      main: '#F6F8FB',
      dark: '#F6F6F6',
      light: '#FFFFFF',
      intense: '#EEEEEE',
    },
    text: {
      main: '#2D2D2D',
      dark: '#18305b', //used for most titles/headers in all the app -> equivalent to steel60 and used by GrayDark60Text component
      light: '#989A9A',
      contrast: '#FFFFFF',
      accent: '#18305b', //could not find its usage
    },
  },
  header: {
    background: '#FFFFFF',
    text: '#2D2D2D',
    selector: '#c20e1a', //now used by RadioClickAndGoList
  },
  footer: {
    background: '#EE4C14',
    text: '#FFFFFF',
  },
  buttons: {
    primary: {
      filled: {
        default: 'linear-gradient(90deg, #ED8B00, #18305b)',
        hover: 'linear-gradient(90deg, #ED8B00, #E7401E)',
        active: 'linear-gradient(90deg, #18305b, #E7401E)',
      },
      outlined: {
        outlines: '#18305b', //could not find its usage
        fill: '#FFFFFF',
      },
      link: '#18305b', //could not find its usage
      radius: '20px',
    },
    secondary: {
      filled: {
        default: 'linear-gradient(90deg, #336E7D, #004A5D)',
        hover: 'linear-gradient(90deg, #004A5D, #003d4d)',
      },
      outlined: {
        outlines: '#004A5D', //could not find its usage
        fill: '#FFFFFF',
      },
      link: '#004A5D', //could not find its usage
      radius: '20px',
    },
  },
  icons: {
    primary: {
      main: '#18305b', //used for spinner right now
      light: '#ED8B00',
    },
    secondary: {
      dark: '#004A5D', //could not find its usage
      main: '#00B1AF',
      fresh: '#009A17',
      light: '#A9C90E',
    },
    light: '#FFFFFF',
  },
  forms: {
    text: {
      default: '#2D2D2D',
      placeholder: '#989A9A',
      disabled: '#989A9A',
    },
    background: {
      default: '#FFFFFF',
      disabled: '#EEEEEE',
    },
    border: {
      default: '#989A9A',
      focus: '#545656',
      hover: '#545656',
    },
    check: {
      focus: '#004A5D', //could not find its usage
      default: '#336E7D',
    },
  },
  tooltip: {
    background: '#545656',
    text: '#FFFFFF',
  },
}

const velocityThemeLPLU: VelocityTheme = { styling: velocityStylingLPLU }

export const overrideThemeLPLU: ThemeOverrides = velocityThemeLPLU
