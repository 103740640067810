import React from 'react'
import { connect } from 'react-redux'

import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { AppState } from 'mlp-client/src/types'

import { LoaderView, Sheet, LowIndexWrap } from './Loader.styled'

interface Props {
  loading: boolean
  hideSpinner?: boolean
  fixed?: boolean
  absolute?: boolean
  center?: boolean
  isMobileApp?: boolean
  children?: React.ReactNode
}

class Loader extends React.PureComponent<Props> {
  private content = React.createRef<HTMLDivElement>()

  handleKeyDown = (event: KeyboardEvent) => {
    if (this.props.loading && event.key === 'Tab') {
      event.preventDefault()
    }
  }

  componentDidMount() {
    this.content.current.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    this.content.current.removeEventListener('keydown', this.handleKeyDown)
    postMessage({ type: PostMessageType.LoadEnd })
  }

  // this lifecycle is used to avoid content flicker in mobile app
  // see discussion at https://gitlab.leaseplan.io/shared/frontend/leaseplan-frontend/-/merge_requests/4388?commit_id=78f278a88339f28e42a45f434f64e50e2269602c#note_74598
  getSnapshotBeforeUpdate(prevProps: Props): void {
    const { isMobileApp, loading } = this.props

    if (isMobileApp && prevProps.loading !== loading && loading) {
      postMessage({ type: PostMessageType.LoadStart })
    }

    if (isMobileApp && prevProps.loading !== loading && !loading) {
      postMessage({ type: PostMessageType.LoadEnd })
    }

    return null
  }

  componentDidUpdate(prevProps: Props) {
    const { isMobileApp, loading } = this.props

    if (isMobileApp && prevProps.loading !== loading && !loading) {
      postMessage({ type: PostMessageType.LoadEnd })
    }
  }

  render() {
    const {
      fixed = false,
      absolute = false,
      center = false,
      loading,
      isMobileApp,
      children,
      hideSpinner,
    } = this.props

    return (
      <div ref={this.content}>
        <LoaderView
          isBusy={loading}
          isFixedPosition={fixed}
          isAbsolutePosition={absolute}
          isCentered={center}
          data-e2e-id="loader"
          showSpinner={!isMobileApp && !hideSpinner}
        >
          {loading && <Sheet data-e2e-id="loaderOverlay" />}
          {children && <LowIndexWrap>{children}</LowIndexWrap>}
        </LoaderView>
      </div>
    )
  }
}

type ReduxProps = Pick<Props, 'isMobileApp'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  isMobileApp: getIsMobileApp(state),
})

export { Loader }
export default connect(mapStateToProps)(Loader)
