import * as actions from './actions'

export interface State {
  identityClientId: string
  isMobileApp: boolean
  loading: boolean
  nextRoute: string | null
}

export const defaultState: State = {
  identityClientId: '',
  isMobileApp: false,
  loading: false,
  nextRoute: null,
}

const authReducer = (
  state: State = defaultState,
  action: actions.Actions,
): State => {
  switch (action.type) {
    case actions.ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      }
    case actions.ActionTypes.SET_NEXT_ROUTE:
      return {
        ...state,
        nextRoute: action.payload.route,
      }
    case actions.ActionTypes.CLEAR_NEXT_ROUTE:
      return {
        ...state,
        nextRoute: defaultState.nextRoute,
      }
    default:
      return state
  }
}

export default authReducer
