import React from 'react'
import numeral from 'numeral'

import { formatNumber } from 'mlp-client/src/utils'
import { leaveOnlyNumbers } from 'mlp-client/src/flows/cash-claim/utils'

export interface Props {
  mileage: number | string
}

export const MileageFormatter: React.FC<Props> = ({ mileage }) => {
  const mileageUnit = numeral.localeData().mileageUnit
  const formattedMileage = formatNumber(leaveOnlyNumbers(String(mileage))) || 0

  return <span>{`${formattedMileage} ${mileageUnit}`}</span>
}
