import React from 'react'
import { connect } from 'react-redux'
import { ButtonLink, Hide, Spacing } from '@leaseplan/ui'
import { Headline, Text, colorMixins } from '@velocity/ui'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import { BaseOrderInfo } from 'mlp-client/src/order-status/types'
import { AppState } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { isQuote } from 'mlp-client/src/quotes/utils'

export interface Props {
  isMobileApp: boolean
  baseOrderInfo: BaseOrderInfo
}

const SText = styled(Text)`
  color: ${colorMixins.gray60Color};
  text-transform: uppercase;
`

class OrderTitle extends React.PureComponent<Props> {
  render() {
    const { baseOrderInfo } = this.props

    const {
      make,
      model,
      orderNumber,
      backToPage,
      lastUpdate,
      expectedDelivery,
    } = baseOrderInfo

    const LastUpdateBlock = lastUpdate && (
      <Spacing mb={0.5} mt={0.5}>
        <SText variant="100">
          <Translation
            replace={{ lastUpdate }}
            id="myLeaseplan.orderStatus.lastUpdate"
          />
        </SText>
      </Spacing>
    )

    const breadcrumbs = (
      <Spacing mb={2}>
        <Breadcrumbs>
          <ButtonLink
            to={backToPage || 'myLeaseplan.vehicles'}
            component={RouteLink}
            size="s"
            fontWeight="regular"
            color="steel60"
          >
            <Translation id="myLeaseplan.subNavigation.vehicles" />
          </ButtonLink>
          <Translation id="myLeaseplan.orderStatus.title" />
        </Breadcrumbs>
      </Spacing>
    )

    const titleBlock = (
      <>
        {breadcrumbs}
        <Hide from="tablet">{LastUpdateBlock}</Hide>
        <Headline variant="400" withMarginBottom component="h1">
          {isQuote(baseOrderInfo) ? (
            <>{`${make} ${model} - ${baseOrderInfo.status}`} </>
          ) : (
            <>
              {`${make} ${model} - `}
              <Translation
                replace={{ date: expectedDelivery }}
                id="myLeaseplan.orderStatus.expectedDelivery"
              />
            </>
          )}
        </Headline>
      </>
    )

    return (
      <Spacing mb={3}>
        {backToPage ? titleBlock : <Spacing mt={4}>{titleBlock}</Spacing>}
        <MyLeaseplanContext.Consumer>
          {context =>
            context.orderStatus?.showOrderNumber && (
              <SecondaryDarkText variant="400">
                <Translation
                  replace={{ orderNumber }}
                  id="myLeaseplan.orderStatus.orderNumber"
                />
              </SecondaryDarkText>
            )
          }
        </MyLeaseplanContext.Consumer>
        <Hide until="tablet">{LastUpdateBlock}</Hide>
      </Spacing>
    )
  }
}

type ReduxProps = Pick<Props, 'isMobileApp'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  isMobileApp: getIsMobileApp(state),
})

export { OrderTitle }
export default connect(mapStateToProps)(OrderTitle)
