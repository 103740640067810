import { Button, ChevronRightIcon, Grid, GridItem } from '@leaseplan/ui'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { Address } from 'mlp-client/src/user/types'
import {
  OtherAddressWrapper,
  SubmitButtonWrapper,
} from 'mlp-client/src/flows/components/pages/supplier-map-page/other-address/OtherAddress.styled'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import { maxLength, required } from 'mlp-client/src/form/validations'
import { getAddressModel } from 'mlp-client/src/utils'

export interface Props {
  otherAddress: Address
  searchLabel: string
  onSearch(address: Address): void
}

interface SubmitAddress {
  addressLine1: string
  zipCode: string
  city: string
}

interface SubmitData {
  address: SubmitAddress
}

export class OtherAddress extends React.PureComponent<Props> {
  onFormSubmit = (submitData: SubmitData) => {
    const { otherAddress, onSearch } = this.props
    const { addressLine1, city, zipCode } = submitData.address

    const submittedOtherAddress = getAddressModel({
      ...otherAddress,
      city,
      zipCode,
      addressLine1,
    })

    onSearch(submittedOtherAddress)
  }

  render() {
    const { searchLabel } = this.props

    return (
      <OtherAddressWrapper pb={1} pt={2} ph={2}>
        <Form onSubmit={this.onFormSubmit}>
          {({ handleSubmit }: FormRenderProps) => (
            <Grid alignItems="flex-start" justifyContent="space-between">
              <GridItem span={{ lap: 3, desktop: 3, tablet: 6, mobile: 12 }}>
                <TextField
                  name="address.addressLine1"
                  validations={[maxLength(255)]}
                  label={{
                    id: 'myLeaseplan.shared.input.address.streetname.label',
                  }}
                  placeholder={{
                    id:
                      'myLeaseplan.shared.input.address.streetname.placeholder',
                  }}
                  dataE2eId="street"
                />
              </GridItem>
              <GridItem span={{ lap: 3, desktop: 3, tablet: 6, mobile: 12 }}>
                <TextField
                  name="address.zipCode"
                  validations={[maxLength(10), required]}
                  label={{ id: 'myLeaseplan.shared.input.address.zip.label' }}
                  placeholder={{
                    id: 'myLeaseplan.shared.input.address.zip.placeholder',
                  }}
                  dataE2eId="zipCode"
                />
              </GridItem>
              <GridItem span={{ lap: 3, desktop: 3, tablet: 6, mobile: 12 }}>
                <TextField
                  name="address.city"
                  validations={[maxLength(255), required]}
                  label={{ id: 'myLeaseplan.shared.input.address.city.label' }}
                  placeholder={{
                    id: 'myLeaseplan.shared.input.address.city.placeholder',
                  }}
                  dataE2eId="city"
                />
              </GridItem>
              <SubmitButtonWrapper
                span={{ lap: 3, desktop: 3, tablet: 6, mobile: 12 }}
              >
                <Button
                  icon={ChevronRightIcon}
                  data-e2e-id="searchButton"
                  onClick={handleSubmit}
                >
                  {searchLabel}
                </Button>
              </SubmitButtonWrapper>
            </Grid>
          )}
        </Form>
      </OtherAddressWrapper>
    )
  }
}
