export const enum FAQKeys {
  CASH_CLAIM_SELECT_CLAIM_TYPE = 'cashClaimPage',
  END_OF_LEASE_SELECT_OPTION = 'endOfLease_selectOption',
  TYRE_SERVICES_PAGE = 'tyreServicesPage',
  SEASONAL_TYRES_PAGE = 'seasonalTyresPage',
  VEHICLE_DOCUMENTS_PAGE = 'vehicleDocumentsPage',
  MAINTENANCE_AND_REPAIR_PAGE = 'maintenanceAndRepairPage',
  DAMAGE_REPORT_PAGE = 'damageReportPage',
  VEHICLE_BUY_PAGE = 'vehicleBuyPage',
  VEHICLE_RETURN_PAGE = 'vehicleReturnPage',
  RETURN_METHOD_PAGE = 'returnMethodPage',
  DATE_PAGE = 'returnVehicleWhenPage',
  FINES_PAGE = 'finesPage',
  TYRE_SIZE_PAGE = 'tyreSizePage',
  CURRENT_TYRE_PAGE = 'currentTyrePage',
  FUEL_PAGE = 'fuelPage',
}
export type FAQKeysValues = keyof typeof FAQKeys
