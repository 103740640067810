import { Action, ActionTypes } from 'mlp-client/src/contracts/actions'
import * as actions from 'mlp-client/src/flows/components/pages/supplier-map-page/actions'
import { SupplierResponse } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'

export const defaultState: SupplierResponse = {
  results: [],
  searchLocation: null,
  searchRadius: null,
  isLoading: false,
  error: false,
}

export default (
  state: SupplierResponse = defaultState,
  action: actions.Action | Action,
): SupplierResponse => {
  switch (action.type) {
    case actions.ActionTypes.LOAD_SUPPLIERS:
      return {
        ...defaultState,
        isLoading: true,
      }
    case actions.ActionTypes.LOAD_SUPPLIERS_SUCCESS:
      return {
        ...action.payload.data,
        isLoading: false,
        error: false,
      }
    case actions.ActionTypes.LOAD_SUPPLIERS_FAILURE:
      return {
        ...defaultState,
        error: true,
      }
    case ActionTypes.SET_CURRENT_CONTRACT:
      return defaultState
    default:
      return state
  }
}
