import { SagaIterator } from 'redux-saga'
import {
  put,
  call,
  select,
  takeLatest,
  all,
  take,
  race,
} from 'redux-saga/effects'

import {
  SetShowAllFeatures,
  SetDebugTranslations,
  LoadFooterMenu,
} from 'mlp-client/src/content/actions'
import {
  getCookie,
  setCookie,
  DebugFlags,
  COOKIE_EXPIRATION_DURATION,
} from 'mlp-client/src/cookies'
import { SetRoutes, SetLocation } from 'mlp-client/src/localization/actions'
import { CountryCode } from 'mlp-client/src/localization/enums'
import { getCountry, getLocale } from 'mlp-client/src/localization/selectors'
import { getRoutesByLocale } from 'mlp-client/src/translations/utils'
import { SITEDATA_BLACKLIST } from 'mlp-client/src/app-config'
import { fetchDictionary } from 'mlp-client/src/dictionary/saga'
import {
  LoadUser,
  LoadUserInfo,
  ActionTypes as ActionTypesUser,
} from 'mlp-client/src/user/actions'
import { getCountryCode } from 'mlp-client/src/user/selectors'
import { LoadContracts } from 'mlp-client/src/contracts/actions'
import { LoadQuotes } from 'mlp-client/src/quotes/actions'
import { isQuotesFeatureEnabled } from 'mlp-client/src/quotes/selectors'
import { isAuthenticated } from 'mlp-client/src/auth/auth'

import { ActionTypes, Init, InitFinished } from './actions'

export function* loadDataForAuthenticatedUser(
  selectedContractId: string,
  selectedQuoteId?: string,
): SagaIterator {
  yield put(new LoadUserInfo())
  yield race({
    success: take(ActionTypesUser.LOAD_USER_INFO_SUCCESS),
    failed: take(ActionTypesUser.LOAD_USER_INFO_FAILED),
  })

  const userCountryCode =
    ((yield select(getCountryCode)) as CountryCode) ||
    (yield select(getCountry))
  const appCountryCode = (yield select(getCountry)) as CountryCode
  const isQuotesEnabled = yield select(isQuotesFeatureEnabled)

  if (appCountryCode === userCountryCode) {
    yield all([
      put(new LoadUser({})),
      put(new LoadContracts({ selectedContractId })),
      ...(isQuotesEnabled ? [put(new LoadQuotes({ selectedQuoteId }))] : []),
    ])
  }
}

export function* setDebugFlags(): SagaIterator {
  const params = new URLSearchParams(window.location.search)

  if (
    !getCookie(DebugFlags.SHOW_ALL_FEATURES) &&
    params.has(DebugFlags.SHOW_ALL_FEATURES)
  ) {
    setCookie(DebugFlags.SHOW_ALL_FEATURES, 'true', {
      'Max-Age': COOKIE_EXPIRATION_DURATION,
    })
  }

  if (
    !getCookie(DebugFlags.DEBUG_TRANSLATIONS) &&
    params.has(DebugFlags.DEBUG_TRANSLATIONS)
  ) {
    setCookie(DebugFlags.DEBUG_TRANSLATIONS, 'true', {
      'Max-Age': COOKIE_EXPIRATION_DURATION,
    })
  }

  if (
    getCookie(DebugFlags.SHOW_ALL_FEATURES) ||
    params.has(DebugFlags.SHOW_ALL_FEATURES)
  ) {
    yield put(new SetShowAllFeatures())
  }

  if (
    getCookie(DebugFlags.DEBUG_TRANSLATIONS) ||
    params.has(DebugFlags.DEBUG_TRANSLATIONS)
  ) {
    yield put(new SetDebugTranslations())
  }
}

export function* init({ payload: { selectedContractId } }: Init): SagaIterator {
  const pathname = document.location.pathname

  yield call(setDebugFlags)

  yield put(new SetLocation({ pathname }))

  // We list auth routes with no MyLP locale present to avoid setting locale-based routes and fetching sitecore data.
  if (!SITEDATA_BLACKLIST.includes(pathname)) {
    const userLocale = yield select(getLocale)
    const isUserAuthenticated = isAuthenticated()

    yield all([call(fetchDictionary), put(new LoadFooterMenu({ userLocale }))])

    yield put(
      new SetRoutes({
        routes: {
          [userLocale]: {
            myLeaseplan: getRoutesByLocale(userLocale),
          },
        },
      }),
    )

    if (isUserAuthenticated) {
      yield call(loadDataForAuthenticatedUser, selectedContractId)
    }
  }

  yield put(new InitFinished())
}

export default [takeLatest(ActionTypes.INIT, init)]
