import { Grid, GridItem, Hide, Paper, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import { AppState, SupplierCard, SupplierCardUrl } from 'mlp-client/src/types'
import CellphoneIcon from 'mlp-client/src/components/icons/cellphone-icon/CellphoneIcon'
import TextCenter from 'mlp-client/src/components/styled/TextCenter'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StyledButtonOutlined } from 'mlp-client/src/components/button/StyledButton'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { formatTelephoneHref } from 'mlp-client/src/utils'
import { triggerMobilePhoneAction } from 'mlp-client/src/timeline/components/ContactUsBanner/ContactUsBanner'

import {
  StyledSupplier,
  StyledSupplierLink,
  StyledSupplierMain,
  StyledSupplierSide,
} from './Supplier.styled'

export interface Props {
  supplier: SupplierCard
  isMobileApp?: boolean
}

class Supplier extends React.PureComponent<Props> {
  renderButton() {
    if (!isLeaseplan()) {
      return (
        <CellphoneIcon
          width={24}
          height={24}
          color={ColorEnumSelector('bloodOrange')}
        />
      )
    }

    return <CellphoneIcon width={24} height={24} />
  }

  getCallIcon = () => this.renderButton()

  renderUrls = (): React.ReactNode => {
    const { supplier } = this.props

    if (!supplier.urls || !supplier.urls.length) {
      return null
    }

    return (
      <Grid verticalGutter={false}>
        {supplier.urls.map(this.renderUrlTile)}
      </Grid>
    )
  }

  renderUrlTile = ({
    websiteUrl,
    websiteLabel,
  }: SupplierCardUrl): React.ReactNode => (
    <GridItem
      key={websiteLabel}
      span={{ mobile: 'grow', mobileLandscape: 'auto' }}
    >
      <TextCenter>
        <StyledSupplierLink
          href={websiteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {websiteLabel}
        </StyledSupplierLink>
      </TextCenter>
    </GridItem>
  )

  render() {
    const { supplier, isMobileApp } = this.props

    return supplier ? (
      <Paper>
        <Spacing pv={2} ph={{ mobile: 2, mobileLandscape: 3 }}>
          <StyledSupplier>
            <StyledSupplierSide>
              <img src={supplier.logo} alt={supplier.name} />
            </StyledSupplierSide>
            <StyledSupplierMain
              data-e2e-id={`supplier-information-card-${supplier.name}`}
            >
              <Hide until="mobileLandscape" implementation="js">
                <Headline variant="100">{supplier.name}</Headline>
              </Hide>
              <Hide from="mobileLandscape" implementation="js">
                <Headline variant="100">{supplier.name}</Headline>
              </Hide>
              {supplier.info && <Text component="p">{supplier.info}</Text>}
              {supplier.phone && (
                <LocalizationContext.Consumer>
                  {({ translate }) => (
                    <>
                      <Hide until="mobileLandscape" implementation="js">
                        {translate(supplier.phone)}
                      </Hide>
                      <Hide from="mobileLandscape" implementation="js">
                        <Spacing mv={1}>
                          <StyledButtonOutlined
                            component="a"
                            href={
                              !isMobileApp && `tel:${translate(supplier.phone)}`
                            }
                            onClick={() =>
                              isMobileApp &&
                              triggerMobilePhoneAction(
                                formatTelephoneHref(translate(supplier.phone)),
                              )
                            }
                            icon={this.getCallIcon}
                            variant="secondary"
                            iconPosition="left"
                          >
                            {translate(supplier.phone)}
                          </StyledButtonOutlined>
                        </Spacing>
                      </Hide>
                    </>
                  )}
                </LocalizationContext.Consumer>
              )}
              {this.renderUrls()}
            </StyledSupplierMain>
          </StyledSupplier>
        </Spacing>
      </Paper>
    ) : null
  }
}
type ReduxProps = Pick<Props, 'isMobileApp'>
const mapStateToProps = (state: AppState): ReduxProps => ({
  isMobileApp: getIsMobileApp(state),
})

export { Supplier }
export default connect(mapStateToProps)(Supplier)
