import { CONTACT_US_PHONE_NUMBER } from 'mlp-client/src/features-configuration/lu/data'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import FuelCardsFlow, {
  FuelCardFlowConfiguration,
} from 'mlp-client/src/flows/fuel-card/components/flow/Flow'
import {
  LostAndStolenConfirmStep,
  SelectFuelCardStep,
  SelectServiceTypeWithSuppliersStep,
  ThankYouStep,
} from 'mlp-client/src/flows/fuel-card/components/steps'
import {
  FuelcardServiceType,
  StepKey,
} from 'mlp-client/src/flows/fuel-card/types'

import { getFuelCardsSteps } from './utils'

const allSteps = {
  [StepKey.SELECT_FUEL_CARD]: {
    stepKey: StepKey.SELECT_FUEL_CARD,
    stepView: SelectFuelCardStep,
    stepConfig: {
      phoneNumber: CONTACT_US_PHONE_NUMBER,
    },
  },
  [StepKey.ALL_LOST_AND_STOLEN_CONFIRM]: {
    stepKey: StepKey.ALL_LOST_AND_STOLEN_CONFIRM,
    stepView: LostAndStolenConfirmStep,
    stepConfig: {},
  },
  [StepKey.SELECT_SERVICE_TYPE]: {
    stepKey: StepKey.SELECT_SERVICE_TYPE,
    stepView: SelectServiceTypeWithSuppliersStep,
    stepConfig: {
      serviceTypes: [
        FuelcardServiceType.FORGOT_PIN,
        FuelcardServiceType.LOST_STOLEN,
        FuelcardServiceType.DAMAGED,
      ],
    },
  },
  [StepKey.THANK_YOU]: {
    stepKey: StepKey.THANK_YOU,
    stepView: ThankYouStep,
    stepConfig: {
      hideCashClaim: true,
      isSupportVisible: false,
      isSupportInNonMalfunctionVisible: true,
      isTimelineVisible: false,
      isTimelineIfMalfunctionVisible: true,
      isBlockCardLabelVisible: true,
      deliveryPeriods: {
        daysForPinRestore: 10,
        daysForCardDelivery: 10,
      },
    },
  },
}

export const fuelCardsConfig: MyLeaseplanFeature<FuelCardFlowConfiguration> = {
  enabled: true,
  view: FuelCardsFlow,
  configuration: {
    allSteps,
    getSteps: getFuelCardsSteps,

    // In this property you can add all the shared configuration across steps.
    sharedConfig: {
      suppliers: [],
    },
  },
}
