import React from 'react'
import { useSelector } from 'react-redux'

import { TileProps } from 'mlp-client/src/dashboard/type'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles/BasicTile'
import { getUser } from 'mlp-client/src/user/selectors'

const MACIF_BRAND = 'MACIF C PARTI'

export const MacifTile: React.FC<TileProps> = props => {
  const user = useSelector(getUser)

  return user?.brand === MACIF_BRAND ? <BasicTile {...props} /> : null
}
