import { glassSuppliers } from 'mlp-client/src/features-configuration/lu/data'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import GlassSuppliersStaticPage from 'mlp-client/src/flows/glass-repair/components/pages/glass-suppliers-static-page/GlassSuppliersStaticPage'
import { GlassRepairStaticPageConfiguration } from 'mlp-client/src/flows/glass-repair/types'

export const glassRepairFeature: MyLeaseplanFeature<GlassRepairStaticPageConfiguration> = {
  enabled: true,
  view: GlassSuppliersStaticPage,
  configuration: {
    suppliersList: glassSuppliers,
  },
}
