import { Text } from '@velocity/ui'
import React from 'react'

import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { Contract } from 'mlp-client/src/contracts/types'
import { getMakeModel } from 'mlp-client/src/contracts/utils'

interface Props {
  contract: Contract
  title: React.ReactElement | string
}

export const VehicleTile: React.FC<Props> = ({ contract, title }) => (
  <FlowStepTile dataE2eId="contract" title={title}>
    <Text variant="200">{getMakeModel(contract)}</Text>
  </FlowStepTile>
)

VehicleTile.displayName = 'VehicleTile'
