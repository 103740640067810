import React from 'react'
import { connect } from 'react-redux'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { GetFuelCardTypesRequest } from 'mlp-client/src/flows/fuel-card/actions'
import { SelectFuelCardTypes } from 'mlp-client/src/flows/fuel-card/components/select-fuel-card-types/SelectFuelCardTypes'
import {
  getTranslatedFuelCardTypes,
  isFuelCardsLoading,
} from 'mlp-client/src/flows/fuel-card/selectors'
import { FlowData } from 'mlp-client/src/flows/fuel-card/types'
import { Options } from 'mlp-client/src/form/types'
import { AppState } from 'mlp-client/src/types'

export interface Props extends FlowProps<FlowData> {
  fuelCardsOptions: Options
  loadFuelTypes(): void
  isLoading: boolean
}

class SelectFuelCardTypesStep extends React.PureComponent<Props> {
  render() {
    return (
      <StepLayout>
        <SelectFuelCardTypes {...this.props} />
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'fuelCardsOptions' | 'isLoading'>
type DispatchProps = Pick<Props, 'loadFuelTypes'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  fuelCardsOptions: getTranslatedFuelCardTypes(state),
  isLoading: isFuelCardsLoading(state),
})

export const mapDispatchToProps: DispatchProps = {
  loadFuelTypes: () => new GetFuelCardTypesRequest(),
}

export { SelectFuelCardTypesStep }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectFuelCardTypesStep)
