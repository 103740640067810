import React from 'react'
import styled from 'styled-components'

interface Props {
  src?: string
}

const StyledBrandLogo = styled.img`
  max-width: 156px;
  width: 100%;
`

class BrandLogo extends React.PureComponent<Props> {
  render() {
    const { src } = this.props

    if (!src) {
      return null
    }

    return <StyledBrandLogo src={src} />
  }
}

export default BrandLogo
