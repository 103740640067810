import { DistanceUnit } from 'mlp-client/src/enums'
import { FeatureStatus } from 'mlp-client/src/features-configuration/enums'
import { tileLinks } from 'mlp-client/src/features-configuration/default/multipleVehicles'
import {
  ApiType,
  MyleaseplanConfig,
  SharedMyLeaseplanConfig,
} from 'mlp-client/src/features-configuration/types'
import { mileageUpdateConfig } from 'mlp-client/src/features-configuration/default/mileageUpdate'
import { navigation } from 'mlp-client/src/features-configuration/default/navigation'
import { UserProfileAddressKeys as Address } from 'mlp-client/src/user/enums'

import { dashboardConfig } from './dashboard'
import {
  vehicleDocumentsFeature,
  vehicleDocumentsPageFeature,
} from './vehicleDocuments'
import { attachCarToDriverFeature } from './attachCarToDriver'
import { cashClaimConfig } from './cashClaim'
import { contractsConfig } from './contracts'
import { editProfileConfig } from './editProfile'
import { fuelCardsConfig } from './fuelCards'
import { glassRepairFeature } from './glassRepair'
import { maintenanceConfig } from './maintenance'
import { returnVehicleFeature } from './return'
import { tyresConfig } from './tyres'
import { damageReportConfig } from './damage-report'
import { damageRepairConfig } from './damage-repair'
import { loginConfig } from './login'
import { orderStatusFeature } from './orderStatus'
import { timelineConfig } from './timeline'
import { endOfLeaseFeature } from './endOfLease'
import { buyVehicleFeature } from './buy'
import { CONTACT_US_PHONE_NUMBER } from './data'

export const sharedMyLeaseplanConfig: SharedMyLeaseplanConfig = {
  enabled: true,
  loginEnabled: true,
  accountLinkEnabled: true,
  myAccountLink: 'myLeaseplan.homepage',
}

export const baseMyLeaseplanConfig: MyleaseplanConfig = {
  ...sharedMyLeaseplanConfig,
  apiType: ApiType.lpis,
  isPublicHolidaysDisabled: FeatureStatus.ENABLED,
  alternatePhoneDisplay: true,
  policyDocuments: true,
  minSupplierDistance: 75,
  servicePhoneNumber: CONTACT_US_PHONE_NUMBER,
  distanceUnit: DistanceUnit.KM,
  addressFormat: [
    `{${Address.street}} {${Address.number}}`,
    `{${Address.zipCode}}, {${Address.city}}`,
    `{${Address.country}}`,
  ],
  supplierAvailabilityTimeFrame: 5,

  // If the sub-feature is disabled, it will be enabled when showAllFeature flag exists.
  // if the sub-feature is not explicitly declared, it will not be enabled with showAllFeature.
  // Enabled: True => Live in PROD
  // Enabled: False => Hidden under feature flag.

  /**
   * MyLeasePlan sub-features
   */

  // Old configuration
  welcomeScreen: {
    enabled: true,
    allowedServices: ['bookService', 'fuelCard', 'damage', 'documents'],
  },
  users: {
    enabled: true,
  },
  complaints: {
    enabled: true,
  },
  requestForInformation: {
    enabled: true,
  },
  supportsMultipleVehicles: {
    enabled: true,
    configuration: {
      tileLinks,
    },
  },
  isExternalLoginEnabled: true,
}

export const myleaseplanConfig: MyleaseplanConfig = {
  ...baseMyLeaseplanConfig,
  navigation,
  login: loginConfig,
  showSalutation: false,
  cashClaims: cashClaimConfig,
  attachCarToDriver: attachCarToDriverFeature,
  fuelCard: fuelCardsConfig,
  tyres: tyresConfig,
  timeline: timelineConfig,
  editProfile: editProfileConfig,
  glassRepair: glassRepairFeature,
  return: returnVehicleFeature,
  endOfLease: endOfLeaseFeature,
  buy: buyVehicleFeature,
  contracts: contractsConfig,
  vehicleDocuments: vehicleDocumentsFeature,
  vehicleDocumentsPage: vehicleDocumentsPageFeature,
  dashboard: dashboardConfig,
  maintenance: maintenanceConfig,
  damageReport: damageReportConfig,
  damageRepair: damageRepairConfig,
  mileageUpdate: {
    ...mileageUpdateConfig,
    configuration: { ...mileageUpdateConfig.configuration, uploadImage: false },
  },
  orderStatus: orderStatusFeature,
}
