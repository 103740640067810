import { Currencies } from 'mlp-client/src/localization/enums'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  ConfirmStep,
  ContactInformationStep,
  ReturnFlow,
  ReturnMethodStep,
  ReturnVehicleStep,
  SelectDateStep,
  ThankYouStep,
} from 'mlp-client/src/flows/return/components'
import {
  ReplacementTypes,
  ReturnMethods,
  StepNames,
} from 'mlp-client/src/flows/return/enums'
import {
  FlowData,
  ReturnFlowConfiguration,
  Steps,
} from 'mlp-client/src/flows/return/types'
import { TimeWindowTypes } from 'mlp-client/src/flows/types'
import { Options } from 'mlp-client/src/form/types'
import { AddressData, Address } from 'mlp-client/src/user/types'

const fairwearName = 'Fairwearcar'
const checklistName = 'Vehiclereturnchecklistcar'
// LU specific link (was moved in SiteCore)
const checkReturnListName =
  'https://www.leaseplan.com/-/media/leaseplan-digital/lu/documents/myleaseplan/restitution-report.pdf'

const dropoffAddress: AddressData = {
  street: 'rue Nicolas Brosius',
  houseNumber: '7',
  city: 'Leudelange',
}

const returnMethods: Options = Object.values(ReturnMethods).map(
  (value: string) => ({
    title: `myLeaseplan.newSetup.flows.return.steps.returnMethod.${value}`,
    value,
  }),
)

const timeWindows: Options = Object.values(TimeWindowTypes).map(value => ({
  title: `myLeaseplan.newSetup.flows.return.steps.selectDate.dayPeriod.${value}`,
  value,
}))

const allSteps: Steps = {
  [StepNames.RETURN_VEHICLE]: {
    stepView: ReturnVehicleStep,
    stepConfig: {
      fairwear: fairwearName,
      checklist: checklistName,
      checkReturnList: checkReturnListName,
      hideFAQ: true,
    },
  },
  [StepNames.RETURN_METHOD]: {
    stepView: ReturnMethodStep,
    stepConfig: {
      returnMethods,
      dropoffAddress,
      pickUpFee: 100,
      currency: Currencies.EUR,
      formatAdrress: (address: Address) =>
        `${address.houseNumber} ${address.addressLine1} ${address.city}`,
    },
  },
  [StepNames.SELECT_DATE]: {
    stepView: SelectDateStep,
    stepConfig: {
      timeOptions: timeWindows,
      daysBeforeContract: 10,
      hideTimeWhenDropoff: true,
    },
  },
  [StepNames.CONTACT_INFORMATION]: {
    stepView: ContactInformationStep,
    stepConfig: {},
  },
  [StepNames.CONFIRM]: {
    stepView: ConfirmStep,
    stepConfig: {
      dateEditStep: StepNames.SELECT_DATE,
      contactEditStep: StepNames.CONTACT_INFORMATION,
      extraInfoStep: StepNames.CONTACT_INFORMATION,
    },
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {
      listItemLabels: [
        { key: 'emailConfirmation', replacement: ReplacementTypes.EMAIL },
        { key: 'requestForApproval', showForLateReturn: true },
        { key: 'requestToPickup' },
        { key: 'guidelinesReview', replacement: ReplacementTypes.LINKS },
        { key: 'noVehicleBack' },
      ],
      downloadedDocs: {
        fairwear: fairwearName,
        checklist: checklistName,
        checkReturnList: checkReturnListName,
      },
    },
  },
}

const startSteps = [
  StepNames.RETURN_VEHICLE,
  StepNames.RETURN_METHOD,
  StepNames.SELECT_DATE,
]

const finishSteps = [StepNames.CONFIRM, StepNames.THANK_YOU]

const getReturnSteps = (flowData: FlowData): readonly StepNames[] => {
  const { returnMethod } = flowData

  switch (returnMethod) {
    case ReturnMethods.DROP_OFF:
      return [...startSteps, ...finishSteps]
    case ReturnMethods.PICK_UP:
      return [...startSteps, StepNames.CONTACT_INFORMATION, ...finishSteps]
    default:
      return [...startSteps, ...finishSteps]
  }
}

export const returnVehicleFeature: MyLeaseplanFeature<ReturnFlowConfiguration> = {
  enabled: true,
  view: ReturnFlow,
  configuration: {
    getSteps: getReturnSteps,
    allSteps,
  },
}
