import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import { config } from 'mlp-client/src/app-config'
import { downloadFile } from 'mlp-client/src/utils/file/file'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'

import * as actions from './actions'

export function* loadDownloadableDocuments(): SagaIterator {
  const options = {
    throwError: true,
  }
  const contract = yield select(getCurrentContract)

  try {
    const result = yield call(
      ApiSagas.get,
      `/myleaseplan/lpis/vehicle-downloadable-documents`,
      {
        params: {
          contractId: contract.id,
        },
      },
      options,
    )

    const downloadableDocuments = result || []

    yield put(
      new actions.LoadDownloadableDocumentsSuccess({ downloadableDocuments }),
    )
  } catch {
    yield put(new actions.LoadDownloadableDocumentsFailed())
  }
}

export function* downloadDownloadableDocument(
  action: actions.DownloadDownloadableDocument,
): SagaIterator {
  let { documentName } = action.payload
  const { documentId } = action.payload
  const requestUrl =
    'api2/myleaseplan/lpis/vehicle-downloadable-document-content'
  const apiURL = process.env.PLATFORM_URL || config.PLATFORM_URL

  try {
    const sagaURL: string = yield call(ApiSagas.getStringifiedHeader)
    const requestURL = `${apiURL}/${requestUrl}?documentId=${documentId}&${sagaURL}`
    const encodedDocumentName = encodeURI(
      documentName.replace(new RegExp(/\s/g), '_'),
    )

    downloadFile(requestURL, encodedDocumentName)

    yield put(new actions.DownloadDownloadableDocumentSuccess())
  } catch {
    yield put(new actions.DownloadDownloadableDocumentFailed())
  }
}

export default [
  takeLatest(
    actions.ActionTypes.LOAD_DOWNLOADABLE_DOCUMENTS,
    loadDownloadableDocuments,
  ),
  takeLatest(
    actions.ActionTypes.DOWNLOAD_DOWNLOADABLE_DOCUMENT,
    downloadDownloadableDocument,
  ),
]
