import { SubmissionErrors } from 'final-form'

import { User, UserInfo } from 'mlp-client/src/user/types'
import { Phone } from 'mlp-client/src/profile/types'

export const enum ActionTypes {
  CLEAR_USER = '@@users/CLEAR_USER',
  LOAD_USER_INFO = '@@users/LOAD_USER_INFO',
  LOAD_USER_INFO_SUCCESS = '@@users/LOAD_USER_INFO_SUCCESS',
  LOAD_USER_INFO_FAILED = '@@users/LOAD_USER_INFO_FAILED',
  LOAD_USER = '@@users/LOAD_USER',
  LOAD_USER_SUCCESS = '@@users/LOAD_USER_SUCCESS',
  LOAD_USER_FAILED = '@@users/LOAD_USER_FAILED',
  UPDATE_USER = '@@users/UPDATE_USER',
  UPDATE_USER_SUCCESS = '@@users/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED = '@@users/UPDATE_USER_FAILED',
  SET_USER_ROLES = '@@users/SET_USER_ROLES',
  UPDATE_USER_MOBILE_PHONE_NUMBER = '@@users/UPDATE_USER_PHONE_NUMBER',
  UPDATE_USER_MOBILE_PHONE_NUMBER_FAILED = '@@users/UPDATE_USER_PHONE_NUMBER_FAILED',
}

export class LoadUserInfo {
  readonly type = ActionTypes.LOAD_USER_INFO
}

export class LoadUserInfoSuccess {
  readonly type = ActionTypes.LOAD_USER_INFO_SUCCESS

  constructor(readonly payload: UserInfo) {}
}

export class LoadUserInfoFailed {
  readonly type = ActionTypes.LOAD_USER_INFO_FAILED
}

export class LoadUser {
  readonly type = ActionTypes.LOAD_USER
  constructor(
    readonly payload: {
      cacheDisabled?: boolean
      noLoading?: boolean
    },
  ) {}
}

export class LoadUserSuccess {
  readonly type = ActionTypes.LOAD_USER_SUCCESS

  constructor(readonly payload: User) {}
}

export class LoadUserFailed {
  readonly type = ActionTypes.LOAD_USER_FAILED
}

export class ClearUser {
  readonly type = ActionTypes.CLEAR_USER
}

export class UpdateUser {
  readonly type = ActionTypes.UPDATE_USER

  constructor(
    readonly payload: {
      user: User
    },
  ) {}
}

export class UpdateUserSuccess {
  readonly type = ActionTypes.UPDATE_USER_SUCCESS
}

export class UpdateUserFailed {
  readonly type = ActionTypes.UPDATE_USER_FAILED
}

export class UpdateUserMobilePhoneNumber {
  readonly type = ActionTypes.UPDATE_USER_MOBILE_PHONE_NUMBER

  constructor(
    readonly payload: {
      phone: Phone
      onComplete: (errors?: SubmissionErrors) => void
    },
  ) {}
}

export class UpdateUserMobilePhoneNumberFailed {
  readonly type = ActionTypes.UPDATE_USER_MOBILE_PHONE_NUMBER_FAILED
}

export class SetUserRoles {
  readonly type = ActionTypes.SET_USER_ROLES

  constructor(readonly payload: string[]) {}
}

export type Action =
  | ClearUser
  | LoadUser
  | LoadUserFailed
  | LoadUserSuccess
  | SetUserRoles
  | UpdateUser
  | UpdateUserFailed
  | UpdateUserSuccess
  | UpdateUserMobilePhoneNumber
  | UpdateUserMobilePhoneNumberFailed
  | LoadUserInfo
  | LoadUserInfoSuccess
  | LoadUserInfoFailed
