import { Icon, IconProps } from '@leaseplan/ui'
import React from 'react'

export default class RequestVehicleDocumentIcon extends React.PureComponent<IconProps> {
  static defaultProps: IconProps = {
    title: 'Request document paper',
    width: '14px',
    height: '14px',
  }

  render() {
    return (
      <Icon {...this.props}>
        <path d="M 18.999 8.978 l 0.001 0.045 c 0 0.54 -0.428 0.975 -0.955 0.975 h -5.812 a 0.965 0.965 0 0 1 -0.955 -0.975 V 3.952 H 6.91 v 16.096 h 10.178 v -7.435 c 0 -0.54 0.428 -0.975 0.956 -0.975 c 0.527 0 0.955 0.436 0.955 0.975 v 7.923 c 0 0.807 -0.643 1.464 -1.433 1.464 H 6.433 C 5.643 22 5 21.343 5 20.536 V 3.464 C 5 2.657 5.643 2 6.433 2 h 5.846 c 0.36 0 0.705 0.137 0.97 0.386 l 5.286 4.951 c 0.295 0.277 0.465 0.669 0.465 1.079 v 0.518 l -0.001 0.044 Z m -2.534 -0.931 l -3.277 -3.07 v 3.07 h 3.277 Z" />
      </Icon>
    )
  }
}
