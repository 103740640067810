import {
  Card,
  CardProps,
  ChevronRightIcon,
  Grid,
  GridItem,
  Spacing,
} from '@leaseplan/ui'
import { Text, useVelocityTheme } from '@velocity/ui'
import React from 'react'
import { Field } from 'react-final-form'
import styled, { css } from 'styled-components'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { validateString } from 'mlp-client/src/form//utils'
import { Option, Options, StringValidations } from 'mlp-client/src/form/types'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

const StyledText = styled(Text)`
  color: ${ColorEnumSelector('petrolBlue')};
  white-space: pre-wrap;
`

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;

  > svg {
    width: 22px;
    height: 22px;
    fill: ${ColorEnumSelector('steel40')};
  }
`

export interface StyledCardProps extends CardProps {
  disabled?: boolean
  color?: string
}

const StyledCard: React.FC<StyledCardProps> = styled(Card)`
  > div > div,
  > div > a > div {
    display: flex;
    align-items: center;
  }
  a {
    text-decoration: none;
  }
  ${(props: StyledCardProps) =>
    props.disabled
      ? css`
          opacity: 0.5;
          cursor: default;
        `
      : css`
          &:hover {
            cursor: pointer;
          }
          &:hover ${/*sc-selector*/ StyledText} {
            color: ${props.color};
          }
          &:hover ${/*sc-selector*/ StyledIcon} > svg {
            fill: ${props.color};
          }
        `}
`

const StyledContent = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
`

const StyledImage = styled.div`
  height: 200px;
  width: 100%;
  border-bottom: 1px solid ${ColorEnumSelector('steel20')};
  ${(props: Option['image']) => ({
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundPosition: props.backgroundPosition
      ? props.backgroundPosition
      : 'center center',
    backgroundSize: props.backgroundSize ? props.backgroundSize : 'cover',
    backgroundRepeat: 'no-repeat',
  })}
`

const StyledRadio = styled.input`
  position: absolute;
  visibility: hidden;
`

export interface Props {
  name: string
  contractId?: string
  validations: StringValidations
  options: Options
  onChange?(): void
  dataE2eId?: string
  horizontalSpan?: number
}

const RadioClickAndGoList: React.FC<Props> = ({
  name,
  validations,
  options,
  onChange,
  dataE2eId,
  horizontalSpan = 12,
  contractId,
}) => {
  const theme = useVelocityTheme()

  return (
    <LocalizationContext.Consumer>
      {({ translate }) => (
        <Field name={name} validate={validateString(validations)} type="radio">
          {({ input }) => (
            <Grid
              alignItems="baseline"
              verticalGutter={false}
              data-e2e-id={dataE2eId}
            >
              {options.map((option: Option) => (
                <GridItem
                  span={{
                    mobile: 12,
                    desktop: horizontalSpan,
                    lap: horizontalSpan,
                  }}
                  key={option.value}
                >
                  <Spacing pv={0.5}>
                    {option.to && !option.disabled ? (
                      <StyledCard
                        hover={true}
                        hoverElevation={4}
                        color={theme.styling.header.selector}
                      >
                        <RouteLink
                          to={option.to}
                          target={option.target}
                          data-e2e-id={option.dataE2eId || option.value}
                          params={contractId ? { contractId } : {}}
                        >
                          {option.image && <StyledImage {...option.image} />}
                          <Spacing p={2}>
                            <StyledContent>
                              <StyledText bold component="p">
                                {translate(option.title)}
                              </StyledText>
                              <StyledText component="p">
                                {translate(option.subtitle)}
                              </StyledText>
                            </StyledContent>
                            <StyledIcon>
                              <ChevronRightIcon />
                            </StyledIcon>
                          </Spacing>
                        </RouteLink>
                      </StyledCard>
                    ) : (
                      <label
                        data-e2e-id={`${
                          option.dataE2eId || option.value
                        }-label`}
                      >
                        <StyledCard
                          hover={!option.disabled && true}
                          disabled={option.disabled}
                          hoverElevation={4}
                          color={theme.styling.header.selector}
                        >
                          {option.image && <StyledImage {...option.image} />}
                          <Spacing p={2}>
                            <StyledContent>
                              <StyledText bold component="p">
                                {translate(option.title)}
                              </StyledText>
                              <StyledText component="p">
                                {translate(option.subtitle)}
                              </StyledText>
                            </StyledContent>
                            <StyledIcon>
                              <ChevronRightIcon />
                            </StyledIcon>
                            {!option.disabled && (
                              <StyledRadio
                                {...input}
                                onChange={e => {
                                  input.onChange(e)
                                  onChange()
                                }}
                                value={option.value}
                                type="radio"
                                id={option.value}
                                data-e2e-id={option.dataE2eId || option.value}
                              />
                            )}
                          </Spacing>
                        </StyledCard>
                      </label>
                    )}
                  </Spacing>
                </GridItem>
              ))}
            </Grid>
          )}
        </Field>
      )}
    </LocalizationContext.Consumer>
  )
}

export default RadioClickAndGoList
