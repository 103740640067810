import { AccountIcon, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { Action, Dispatch } from 'redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import {
  getLocalizedRoute,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import * as actions from 'mlp-client/src/auth/actions'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { MainContentCenter } from 'mlp-client/src/components/layout/main-content-center/MainContentCenter'
import { AppState } from 'mlp-client/src/types'

interface Props extends RouteComponentProps<{}> {
  dispatch: Dispatch<Action>
  isMobileApp: boolean
  redirectTo: string
  metaTitle: string
  email?: string
}

const CenteredGridItem = styled(GridItem)`
  text-align: center;
`

class PasswordReset extends React.PureComponent<Props> {
  componentDidMount() {
    const { email } = this.props

    if (email) {
      this.props.dispatch(new actions.ChangePassword({ email }))
    }
  }

  render() {
    const { redirectTo, metaTitle, email } = this.props

    if (!email) {
      return <Redirect to={redirectTo} />
    }

    // Hide this page from search results
    const meta = [{ name: 'robots', content: 'noindex,nofollow' }]

    return (
      <MyleaseplanPage metaTitle={metaTitle} meta={meta}>
        <MainContentCenter>
          <Spacing m={3}>
            <Grid
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Spacing m={3}>
                <AccountIcon width="200px" height="200px" color="midOrange" />
              </Spacing>
              <Spacing mt={3}>
                <Headline variant="300">
                  <Translation id="myLeaseplan.auth.passwordReset.title" />
                </Headline>
              </Spacing>
              <CenteredGridItem>
                <Text>
                  <Translation id="myLeaseplan.auth.passwordReset.resentText" />
                </Text>
                &nbsp;
                <Text bold>{email}</Text>,
                <Text component="p">
                  <Translation id="myLeaseplan.auth.passwordReset.checkMailText" />
                </Text>
              </CenteredGridItem>
              <Spacing mb={3} />
            </Grid>
          </Spacing>
        </MainContentCenter>
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'redirectTo' | 'metaTitle' | 'email'>
type DispatchProps = Pick<Props, 'dispatch'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, ownProps: OwnProps): ReduxProps => {
  const urlParams = new URLSearchParams(ownProps.location.search)

  return {
    redirectTo: getLocalizedRoute(state, 'myLeaseplan.homepage'),
    metaTitle: getTranslation(
      state,
      'myLeaseplan.auth.passwordReset.metaTitle',
      '',
    ),
    email: urlParams.get('email'),
  }
}

export { PasswordReset }
export default withRouter(connect(mapStateToProps)(PasswordReset))
