import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

export interface Props {
  title: string
  subTitle: string
}

export class Header extends React.PureComponent<Props> {
  render() {
    const { title, subTitle } = this.props

    return (
      <Spacing mb={2}>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id={title} />
        </Headline>
        <Text loose>
          <Translation id={subTitle} />
        </Text>
      </Spacing>
    )
  }
}
