import React, { ReactNode } from 'react'
import styled from 'styled-components'

const WrapperIe = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
const MainWrapper = styled.div`
  flex: 1 1 auto;
  flex-flow: column nowrap;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`

interface Props {
  children: ReactNode
}

export class MainContent extends React.PureComponent<Props> {
  render() {
    return (
      <WrapperIe>
        <MainWrapper>{this.props.children}</MainWrapper>
      </WrapperIe>
    )
  }
}
