import { MobileAppConfig } from 'mlp-client/src/features-configuration/types'
import { SupplierMapPage } from 'mlp-client/src/flows/components/pages/supplier-map-page/SupplierMapPage'
import { SupplierMapConfiguration } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'
import { BookingRequestType } from 'mlp-client/src/types'

export const glassRepairFeature: MobileAppConfig<SupplierMapConfiguration> = {
  enabled: true,
  mobileApp: {
    showNativeHeader: true,
  },
  view: SupplierMapPage,
  configuration: {
    bookingType: BookingRequestType.GLASS,
    title: 'myLeaseplan.glassRepair.page.title',
    subTitle: 'myLeaseplan.glassRepair.page.subTitle',
    breadcrumbLinkTitle: 'myLeaseplan.glassRepair.dashboard.title',
    breadcrumbBaseTitle: 'myLeaseplan.subNavigation.dashboard',
    breadcrumbBaseLink: 'myLeaseplan.dashboard',
    showOpeningHours: true,
  },
}
