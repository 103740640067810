import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  DamageCauses,
  FlowData,
} from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import {
  filesRequiredAndUploaded,
  filesUploaded,
} from 'mlp-client/src/form/validations'
import FileUpload from 'mlp-client/src/form/components/fields/file-upload-field/FileUploadField'
import Translation from 'mlp-client/src/localization/Translation'
import { AppState } from 'mlp-client/src/types'
import { getEuAccidentData } from 'mlp-client/src/flows/damage-report/selectors'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { Locales } from 'mlp-client/src/localization/enums'
import { LoadContent } from 'mlp-client/src/content/actions'
import { pageContentEndpoint } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

export interface StepConfig {
  isFileOptionalWhenThirdParty?: boolean
  disclaimer?: string
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
  formDownloadLink: string
  locale: Locales
  isMobileApp: boolean
  loadEuAccidentData(locale: Locales): void
}

export type FormData = Pick<FlowData, 'europeanAccidentStatement'>

export class EuropeanAccidentStatementStep extends React.PureComponent<Props> {
  componentDidMount() {
    const { loadEuAccidentData, locale } = this.props

    loadEuAccidentData(locale)
  }

  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData(formData, goToNextStep)
  }

  render() {
    const {
      flowData: { europeanAccidentStatement = [], damageCause },
      formDownloadLink,
      config,
      isMobileApp,
    } = this.props
    const isThirdPartyInvolved = damageCause === DamageCauses.TWO_PARTY
    const validations =
      isThirdPartyInvolved && !config.isFileOptionalWhenThirdParty
        ? [filesRequiredAndUploaded]
        : [filesUploaded]

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.europeanAccidentStatement.pageTitle">
          <Form
            keepDirtyOnReinitialize={true}
            onSubmit={this.submitHandler}
            initialValues={{ europeanAccidentStatement }}
            render={({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit}>
                  {config?.isFileOptionalWhenThirdParty &&
                    isThirdPartyInvolved && (
                      <Text>
                        <Translation id={config?.disclaimer} />
                      </Text>
                    )}
                  <FileUpload
                    name="europeanAccidentStatement"
                    validations={validations}
                    label="myLeaseplan.damageReport.steps.europeanAccidentStatement.uploadLabel"
                    accept="image/*"
                  />
                  <Text variant="200" component="span">
                    <Translation id="myLeaseplan.damageReport.europeanAccidentStatement.noAccidentStatementForm" />
                  </Text>
                  &nbsp;
                  {formDownloadLink ? (
                    <StyledButtonLink
                      size="s"
                      component="a"
                      fontWeight="regular"
                      href={formDownloadLink}
                      target="_blank"
                    >
                      <Translation id="myLeaseplan.damageReport.europeanAccidentStatement.download" />
                    </StyledButtonLink>
                  ) : (
                    <StyledButtonLink
                      size="s"
                      component={RouteLink}
                      fontWeight="regular"
                      to="myLeaseplan.vehicleDocuments.base"
                      target={isMobileApp ? '_self' : '_blank'}
                    >
                      <Translation id="myLeaseplan.damageReport.europeanAccidentStatement.download" />
                    </StyledButtonLink>
                  )}
                  <Spacing mt={4} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                </form>
              )
            }}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'formDownloadLink' | 'locale' | 'isMobileApp'>
type DispatchProps = Pick<Props, 'loadEuAccidentData'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  ...getEuAccidentData(state),
  locale: getUserLocale(state),
  isMobileApp: getIsMobileApp(state),
})

export const mapDispatchToProps: DispatchProps = {
  loadEuAccidentData: (locale: Locales) =>
    new LoadContent({ locale, endpoint: pageContentEndpoint }),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EuropeanAccidentStatementStep)
