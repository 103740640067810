import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { contractEndDateSelector } from 'mlp-client/src/contracts/selectors'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import DateTimeStep, {
  StepFormValues,
} from 'mlp-client/src/flows/components/steps/date-time-step/DateTimeStep'
import { ReturnMethods } from 'mlp-client/src/flows/return/enums'
import { StepViewProps } from 'mlp-client/src/flows/return/types'
import {
  isDropoff,
  renderCurrentEndDate,
} from 'mlp-client/src/flows/return/utils'
import { Options } from 'mlp-client/src/form/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { GrayDark80Text } from 'mlp-client/src/components/styled/TextStyle'

export interface StepConfig {
  timeOptions: Options
  daysBeforeContract: number
  hideTimeWhenDropoff?: boolean
}

const translatePath = makeTranslationPath(
  'myLeaseplan.newSetup.flows.return.steps.selectDate',
)
const translateDateValidationPath = makeTranslationPath(
  'myLeaseplan.newSetup.shared.forms.date.validations',
)

export interface Props extends StepViewProps<StepConfig> {
  todayDate: string
  contractEndDate: string
}

class SelectDateStep extends React.PureComponent<Props> {
  hideTime: boolean

  constructor(props: Props) {
    super(props)
    const {
      config: { hideTimeWhenDropoff },
      flowData: { returnMethod },
    } = this.props

    this.hideTime = hideTimeWhenDropoff && isDropoff(returnMethod)
  }

  handleNextButtonClick = (values: StepFormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        returnDate: values.date,
        returnDayPeriod: this.hideTime ? undefined : values.time,
      },
      goToNextStep,
    )
  }

  render() {
    const {
      config: { timeOptions, daysBeforeContract },
      flowData,
      todayDate,
      contractEndDate,
    } = this.props

    const { returnMethod } = flowData

    const initialTimePeriod =
      flowData.returnDayPeriod ||
      (timeOptions && timeOptions.length && timeOptions[0].value) ||
      undefined

    const dateBeforeContractEnd = moment(contractEndDate).subtract(
      daysBeforeContract,
      'day',
    )

    const notBefore = dateBeforeContractEnd.isBefore(todayDate)
      ? new Date(todayDate)
      : dateBeforeContractEnd.toDate()

    return (
      <DateTimeStep
        title={translatePath(`${returnMethod}.pageTitle`)}
        subtitle={
          returnMethod === ReturnMethods.PICK_UP &&
          translatePath(`${returnMethod}.pageSubTitle`)
        }
        side={
          returnMethod === ReturnMethods.DROP_OFF && (
            <FAQ faqKey={FAQKeys.DATE_PAGE} />
          )
        }
        date={{
          title:
            returnMethod === ReturnMethods.PICK_UP
              ? translatePath('pickup.date')
              : translatePath('dropoff.date'),
          notBefore: {
            message: translateDateValidationPath('dateNotInPast'),
            date: notBefore,
          },
          notAfter: {
            message: translateDateValidationPath('dateAfterContractEnd'),
            date: new Date(contractEndDate),
          },
          dateAddInfo: (
            <GrayDark80Text component="p" variant="100">
              <Translation id={translatePath('contractEndDate')} />{' '}
              {renderCurrentEndDate(contractEndDate)}
            </GrayDark80Text>
          ),
        }}
        time={{
          enabled: !this.hideTime,
          options: timeOptions,
          title: translatePath('pickup.time'),
        }}
        selectedDate={flowData.returnDate && new Date(flowData.returnDate)}
        selectedTime={initialTimePeriod}
        onSubmit={this.handleNextButtonClick}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'todayDate' | 'contractEndDate'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  todayDate: moment().startOf('day').format(),
  contractEndDate: contractEndDateSelector(state),
})

export { SelectDateStep }
export default connect(mapStateToProps)(SelectDateStep)
