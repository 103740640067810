import { TimelineItemStatus } from '@leaseplan/ui'
import { VelocityTheme } from '@velocity/ui'

import { Contract } from 'mlp-client/src/contracts/types'
import { formatDate } from 'mlp-client/src/utils'
import { QuotesStatuses } from 'mlp-client/src/quotes/enums'
import { isOrderOrContract } from 'mlp-client/src/contracts/utils'
import { isQuote } from 'mlp-client/src/quotes/utils'
import { Quote } from 'mlp-client/src/quotes/types'

import {
  TimelineItemConfig,
  TimelineItemConfigItem,
  BaseOrderInfo,
  VehicleDetails,
} from './types'
import { OrderStatuses } from './enums'

export const getTimelineItemColor = (
  status: TimelineItemStatus,
  theme: VelocityTheme,
): string => {
  switch (status) {
    case 'active':
      return theme.styling.primary.dark
    case 'success':
      return theme.styling.gray.main
    default:
      return theme.styling.gray['60']
  }
}

export const getBaseOrderInfo = (
  contract: Contract | Quote,
): BaseOrderInfo | undefined => {
  if (contract && isOrderOrContract(contract)) {
    const {
      number,
      vehicle,
      estimatedDeliveryDate,
      simulatedEndDate,
    } = contract

    return {
      orderNumber: number,
      make: vehicle ? vehicle.make : '',
      model: vehicle ? vehicle.model : '',
      lastUpdate: simulatedEndDate
        ? formatDate(estimatedDeliveryDate, 'LL')
        : '',
      expectedDelivery: estimatedDeliveryDate
        ? formatDate(estimatedDeliveryDate, 'LL')
        : '',
    }
  } else if (contract && isQuote(contract)) {
    const { make, model, quoteNumber } = contract

    return {
      orderNumber: quoteNumber + '',
      make: make || '',
      model: model || '',
    }
  }
}

/*@TODO this could be temporary decision, for now, this is all info for right block on order status page*/
export const getVehicleDetails = (
  contract: Contract,
): VehicleDetails | undefined => {
  if (contract && contract.vehicle) {
    const {
      make,
      model,
      numberOfDoors,
      fuelType,
      typeOfTransmission,
      actualYearlyMileage,
    } = contract.vehicle

    return {
      make,
      model,
      actualYearlyMileage,
      doors: numberOfDoors,
      fuel: fuelType,
      transmission: typeOfTransmission,
      /*@TODO for now contract does not contain those values */
      consumption: '',
      power: '',
    }
  }
}

const isQuoteStep = (itemStatus: string, orderStatus: string): boolean =>
  itemStatus === '0' &&
  Object.values(QuotesStatuses).some(v => v === orderStatus)

export const getCurrentStatusValue = (
  itemStatus: string,
  orderStatus: string,
): TimelineItemStatus => {
  if (itemStatus === orderStatus || isQuoteStep(itemStatus, orderStatus)) {
    return 'active'
  } else if (parseInt(itemStatus, 10) < parseInt(orderStatus, 10)) {
    return 'success'
  }

  return 'inactive'
}

export const getCurrentTimeLineItem = (
  timeLineItemsConfig: TimelineItemConfig,
  orderStatus: string,
): TimelineItemConfigItem | undefined =>
  timeLineItemsConfig.find(item => item.itemId === orderStatus)

export const getOrderStatusColor = (
  orderStatus: string,
  theme: VelocityTheme,
): string => {
  switch (orderStatus) {
    case OrderStatuses.ORDER_AT_DEALER:
      return theme.styling.secondary.fresh.default
    case OrderStatuses.CONFIRMED_BY_DEALER:
    case OrderStatuses.ORDER_VALIDATED:
    case OrderStatuses.CAR_READY_TO_DELIVERY:
    case OrderStatuses.DELIVERED:
      return theme.styling.secondary.dark.default
    default:
      return theme.styling.gray.darkest
  }
}

export const getOrderDetailsColor = (
  orderStatus: string,
  theme: VelocityTheme,
): string => {
  switch (orderStatus) {
    case OrderStatuses.ORDER_AT_DEALER:
      return theme.styling.system.success.light
    case OrderStatuses.CONFIRMED_BY_DEALER:
    case OrderStatuses.ORDER_VALIDATED:
    case OrderStatuses.CAR_READY_TO_DELIVERY:
    case OrderStatuses.DELIVERED:
      return theme.styling.system.info.light
    default:
      return theme.styling.gray['20']
  }
}
