import { buffers, END, eventChannel } from 'redux-saga'
import superagent from 'superagent'
import { ImageFile } from 'react-dropzone'

import { getAccessToken } from 'mlp-client/src/auth/auth'
import { File as FileType } from 'mlp-client/src/form/components/fields/file-upload-field/types'
import { getFileExtension } from 'mlp-client/src/form/components/fields/file-upload-field/utils'
import { defaultRequestHeadersSelector } from 'mlp-client/src/api/selectors'
import { AppState } from 'mlp-client/src/types'

export const createUploadFileChannel = (
  endpoint: string,
  file: ImageFile,
  meta: FileType,
  state: AppState,
) =>
  eventChannel(
    emitter => {
      const onProgress = (e: ProgressEvent) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100

          emitter({ progress })
        }
      }

      const onFailure = (err: Error) => {
        emitter({ err })
        emitter(END)
      }

      const accessToken = getAccessToken()
      const defaultHeaders = defaultRequestHeadersSelector(state)
      const headerParams = {
        ...defaultHeaders,
        Authorization: accessToken !== null ? `Bearer ${accessToken}` : '',
      }

      const request = superagent
        .post(endpoint)
        .set(headerParams)
        .attach('file', file)
        .field('name', meta.name)
        .field('fileExtension', getFileExtension(meta.name))
        .field('documentType', '')
        .on('progress', onProgress)
        .on('error', onFailure)

      request
        .then(response => {
          emitter({ success: true, result: response.body })
          emitter(END)
        })
        .catch(onFailure)

      return () => {
        request.abort()
      }
    },
    // Channel buffering. With `sliding`, overflow will insert the new message at the end and drop the oldest message in the buffer.
    // This way, upload resolve will always be caught, even though some `progress` events might be lost.
    buffers.sliding(2),
  )
