import { compose } from 'redux'
import memoize from 'memoize-immutable'

import { Route } from 'mlp-client/src/types'
import RedirectToCorrectAuth0Locale from 'mlp-client/src/auth/views/RedirecdtToCorrectAuth0Locale/RedirectToCorrectAuth0Locale'
import Authorize from 'mlp-client/src/views/Authorize'
import LoginRedirect from 'mlp-client/src/views/LoginRedirect'
import LogoutRedirect from 'mlp-client/src/views/LogoutRedirect'
import { RequestAccount } from 'mlp-client/src/attach-car/components'
import protectRouteHOC from 'mlp-client/src/auth/protectRoute'
import PasswordReset from 'mlp-client/src/auth/views/PasswordReset'
import { ThankYouPage as ComplaintsThankYouPage } from 'mlp-client/src/complaints/components/ThankYouPage'
import withMyLeaseplanAppHOC from 'mlp-client/src/components/my-leaseplan-app/withMyLeaseplanApp'
import MyleaseplanFeature from 'mlp-client/src/components/myleaseplan-feature/MyleaseplanFeature'
import withUserContractsHOC from 'mlp-client/src/contracts/components/withUserContracts/withUserContracts'
import withUserQuotesHOC from 'mlp-client/src/contracts/components/withUserQuotes/withUserQuotes'
import DamageListStep from 'mlp-client/src/flows/damage-report/components/pages/damage-list-page/DamageListPage'
import OnePartyDamagePage from 'mlp-client/src/flows/damage-report/components/pages/one-party-damage-page/OnePartyDamagePage'
import StolenVehiclePage from 'mlp-client/src/flows/damage-report/components/pages/stolen-vehicle-page/StolenVehiclePage'
import Dashboard from 'mlp-client/src/dashboard/Dashboard'
import HomePage from 'mlp-client/src/homepage/HomePage'
import Page from 'mlp-client/src/order-status/components/page/Page'
import { ThankYouPage as RequestForInformationThankYouPage } from 'mlp-client/src/request-for-information/components/ThankYouPage'
import BookingDetails from 'mlp-client/src/timeline-details/components/views/booking-details/BookingDetails'
import Vehicles from 'mlp-client/src/vehicles/Vehicles'
import Login from 'mlp-client/src/components/login/Login'
import Unauthorized from 'mlp-client/src/views/Unauthorized'
import ViewProfile from 'mlp-client/src/views/ViewProfile'
import UnderMaintenance from 'mlp-client/src/components/under-maintenance/UnderMaintenance'
import SomethingWentWrongPage from 'mlp-client/src/components/something-went-wrong/SomethingWentWrongPage'
import Complaints from 'mlp-client/src/complaints/components/Complaints'
import RequestForInformation from 'mlp-client/src/request-for-information/components/RequestForInformation'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import { Routes } from 'mlp-client/src/localization/types'
import { getLocalizedRouteMatch } from 'mlp-client/src/localization/utils'
import { PageTitle } from 'mlp-client/src/analytics/types'

interface MyleaseplanRoute extends Route {
  isAuthProtected: boolean
  requiresContract?: true
  requiresOrder?: true
  requiresQuote?: true
}

type HocFunc<P = {}, P1 = P> = (
  component: React.ComponentType<P>,
) => React.ComponentType<P1>

type ConditionFunc = (route: MyleaseplanRoute) => boolean

const applyHoc = (hoc: HocFunc, condition: ConditionFunc) => (
  route: MyleaseplanRoute,
): MyleaseplanRoute =>
  condition(route)
    ? {
        ...route,
        component: hoc(route.component),
      }
    : route

const protectRoute = applyHoc(protectRouteHOC, route => route.isAuthProtected)
const withUserContracts = applyHoc(
  withUserContractsHOC,
  route => route.requiresContract === true,
)

const withUserQuotes = applyHoc(
  withUserQuotesHOC,
  route => route.requiresQuote === true,
)

const withMyLeaseplanApp = applyHoc(withMyLeaseplanAppHOC, () => true)

const wrapRouteComponent = compose(
  protectRoute,
  withMyLeaseplanApp,
  withUserContracts,
  withUserQuotes,
)

const authRoutes: readonly Route[] = [
  // Used after login/logout to myLeasePlan to redirect the user to
  // the correct page
  {
    path: '/:locale/auth/authorize/',
    exact: true,
    component: Authorize,
    pageTitle: PageTitle.AUTHORIZE,
  },
  {
    path: '/auth/login-redirect/',
    exact: true,
    component: LoginRedirect,
    pageTitle: PageTitle.LOGIN_REDIRECT,
  },
  {
    path: '/auth/logout-redirect/',
    exact: true,
    component: LogoutRedirect,
    pageTitle: PageTitle.LOGOUT_REDIRECT,
  },
  {
    path: '/auth/redirect-to-correct-auth0-locale/',
    exact: true,
    component: RedirectToCorrectAuth0Locale,
    pageTitle: PageTitle.REDIRECT_TO_CORRECT_LOCALE,
  },
]

const mylpRoutes = (noContractAllowedLocale: boolean): MyleaseplanRoute[] => [
  {
    path: 'myLeaseplan.homepage',
    enabledForFeature: ['myLeasePlan'],
    isAuthProtected: true,
    requiresContract: true,
    exact: true,
    component: HomePage,
    pageTitle: PageTitle.HOME,
    pageTitleKey: 'myLeaseplan.shared.home',
  },
  {
    path: 'myLeaseplan.dashboard',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    component: Dashboard,
    pageTitle: PageTitle.DASHBOARD,
    pageTitleKey: 'myLeaseplan.subNavigation.dashboard',
    ...(noContractAllowedLocale ? {} : { requiresContract: true }),
  },
  {
    path: 'myLeaseplan.vehicles',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: Vehicles,
    pageTitle: PageTitle.VEHICLES,
    pageTitleKey: 'myLeaseplan.subNavigation.vehicles',
  },
  {
    path: 'myLeaseplan.vehicleOrder',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    ...(noContractAllowedLocale
      ? { requiresQuote: true }
      : { requiresContract: true }),
    component: Page,
    pageTitle: PageTitle.VEHICLE_ORDER,
    pageTitleKey: 'myLeaseplan.orderStatus.title',
  },
  {
    path: 'myLeaseplan.timeline.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'timeline',
    },
    pageTitle: PageTitle.TIMELINE,
    pageTitleKey: 'myLeaseplan.timeline.base.title',
    ...(noContractAllowedLocale ? {} : { requiresContract: true }),
  },
  {
    path: 'myLeaseplan.timeline.item.maintenanceBooking',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: BookingDetails,
    pageTitle: PageTitle.MAINTENANCE_BOOKING_DETAILS,
    pageTitleKey: 'myLeaseplan.timeline.base.title',
  },
  {
    path: 'myLeaseplan.timeline.item.glassBooking',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: BookingDetails,
    pageTitle: PageTitle.GLASS_BOOKING_DETAILS,
    pageTitleKey: 'myLeaseplan.timeline.base.title',
  },
  {
    path: 'myLeaseplan.timeline.item.vehicleOrder',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: Page,
    pageTitle: PageTitle.VEHICLE_ORDER,
    pageTitleKey: 'myLeaseplan.orderStatus.title',
  },
  {
    path: 'myLeaseplan.profile.edit',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'editProfile',
    },
    pageTitle: PageTitle.PROFILE_EDIT,
    pageTitleKey: 'myLeaseplan.profile.title',
  },
  {
    path: 'myLeaseplan.login',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: false,
    component: Login,
    pageTitle: PageTitle.LOGIN,
    pageTitleKey: 'myLeaseplan.login.title',
  },
  {
    path: 'myLeaseplan.profile.detail',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    component: ViewProfile,
    pageTitle: PageTitle.PROFILE,
    pageTitleKey: 'myLeaseplan.profile.edit.edit',
    ...(noContractAllowedLocale ? {} : { requiresContract: true }),
  },
  {
    path: 'myLeaseplan.contract.detail',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    ...(noContractAllowedLocale ? {} : { requiresContract: true }),
    component: MyleaseplanFeature,
    props: {
      feature: 'contracts',
    },
    pageTitle: PageTitle.CONTRACT_DETAILS,
    pageTitleKey: 'myLeaseplan.contract.title',
  },
  {
    path: 'myLeaseplan.maintenance.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'maintenance',
    },
    pageTitle: PageTitle.MAINTENANCE,
    pageTitleKey: 'myLeaseplan.serviceRequest.maintenance.dashboard.title',
  },
  {
    path: 'myLeaseplan.damageReport.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'damageReport',
    },
    pageTitle: PageTitle.DAMAGE_REPORT,
    pageTitleKey: 'myLeaseplan.damageReport.dashboard.title',
  },
  {
    path: 'myLeaseplan.damageReport.stolenVehicle',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: StolenVehiclePage,
    pageTitle: PageTitle.STOLEN_VEHICLE,
    pageTitleKey: 'myLeaseplan.damageReport.steps.stolenVehicle.title',
  },
  {
    path: 'myLeaseplan.damageReport.oneParty',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: OnePartyDamagePage,
    pageTitle: PageTitle.ONE_PARTY_DAMAGE,
    pageTitleKey: 'myLeaseplan.damageReport.onePartyIframe.title',
  },
  {
    path: 'myLeaseplan.damageReport.damageList',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: DamageListStep,
    pageTitle: PageTitle.DAMAGE_REPORT_LIST,
    pageTitleKey: 'myLeaseplan.damageReport.steps.vehicleDamages.pageTitle',
  },
  {
    path: 'myLeaseplan.damageReport.damageRepair',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'damageRepair',
    },
    pageTitle: PageTitle.DAMAGE_REPAIR,
    pageTitleKey: 'myLeaseplan.damageReport.damageRepair',
  },
  {
    path: 'myLeaseplan.fuelCard.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'fuelCard',
    },
    pageTitle: PageTitle.FUEL_CARD,
    pageTitleKey: 'myLeaseplan.fuelCard.dashboard.title',
  },
  {
    path: 'myLeaseplan.mileageUpdate.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'mileageUpdate',
    },
    pageTitle: PageTitle.UPDATE_MILEAGE,
    pageTitleKey: 'myLeaseplan.mileageUpdate.dashboard.title',
  },
  {
    path: 'myLeaseplan.cashClaim.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'cashClaims',
    },
    pageTitle: PageTitle.CASH_CLAIM,
    pageTitleKey: 'myLeaseplan.cashClaim.dashboard.title',
  },
  {
    path: 'myLeaseplan.glassRepair.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'glassRepair',
    },
    pageTitle: PageTitle.GLASS_REPAIR,
    pageTitleKey: 'myLeaseplan.glassRepair.dashboard.title',
  },
  {
    path: 'myLeaseplan.tyres.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'tyres',
    },
    pageTitle: PageTitle.TYRES,
    pageTitleKey: 'myLeaseplan.tyres.dashboard.title',
  },
  {
    path: 'myLeaseplan.buy.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'buy',
    },
    pageTitle: PageTitle.VEHICLE_BUY,
    pageTitleKey: 'myLeaseplan.but.dashboard.title',
  },
  {
    path: 'myLeaseplan.return.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'return',
    },
    pageTitle: PageTitle.VEHICLE_RETURN,
    pageTitleKey: 'myLeaseplan.return.dashboard.summary',
  },
  {
    path: 'myLeaseplan.endOfLease.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'endOfLease',
    },
    pageTitle: PageTitle.END_OF_LEASE,
    pageTitleKey: 'myLeaseplan.endOfLease.dashboard.title',
  },
  {
    path: 'myLeaseplan.complaints.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: Complaints,
    pageTitle: PageTitle.COMPLAINTS,
    pageTitleKey: 'myLeaseplan.complaint.metaTitle',
  },
  {
    path: 'myLeaseplan.complaints.thankYou',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: ComplaintsThankYouPage,
    pageTitle: PageTitle.COMPLAINTS_THANK_YOU,
    pageTitleKey: 'myLeaseplan.complaint.thankYou.pageTitle',
  },
  {
    path: 'myLeaseplan.requestForInformation.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: RequestForInformation,
    pageTitle: PageTitle.REQUEST_FOR_INFORMATION,
    pageTitleKey: 'myLeaseplan.requestForInformation.pageTitle',
  },
  {
    path: 'myLeaseplan.requestForInformation.thankYou',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: RequestForInformationThankYouPage,
    pageTitle: PageTitle.REQUEST_FOR_INFORMATION_THANK_YOU,
    pageTitleKey: ' myLeaseplan.requestForInformation.thankYou.pageTitle',
  },
  {
    path: 'myLeaseplan.vehicleDocuments.selectDocument',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'vehicleDocumentsPage',
    },
    pageTitle: PageTitle.SELECT_VEHICLE_DOCUMENT,
    pageTitleKey: 'myLeaseplan.vehicleDocuments.steps.selectDocument.pageTitle',
  },
  {
    path: 'myLeaseplan.vehicleDocuments.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'vehicleDocuments',
    },
    pageTitle: PageTitle.VEHICLE_DOCUMENTS,
    pageTitleKey: 'myLeaseplan.vehicleDocuments.dashboard.title',
    ...(noContractAllowedLocale ? {} : { requiresContract: true }),
  },
  {
    path: 'myLeaseplan.attachCarToDriver.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'attachCarToDriver',
    },
    pageTitle: PageTitle.ATTACH_CAR,
    pageTitleKey: 'myLeaseplan.attachCarToDriver.title',
  },
  {
    path: 'myLeaseplan.unauthorized',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: false,
    component: Unauthorized,
    pageTitle: PageTitle.UNAUTHORIZED,
    pageTitleKey: 'myLeaseplan.unauthorized.title',
  },
  {
    path: 'myLeaseplan.passwordReset',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: false,
    component: PasswordReset,
    pageTitle: PageTitle.RESET_PASSWORD,
    pageTitleKey: 'myLeaseplan.auth.passwordReset.title',
  },
  {
    path: 'myLeaseplan.requestAccount.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: false,
    component: RequestAccount,
    pageTitle: PageTitle.REQUEST_ACCOUNT,
    pageTitleKey: 'myLeaseplan.requestAccount.title',
  },
  /* Route fix for the mobile app. MLP-3442 */
  {
    path: 'myLeaseplan.contract.detailFr',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'contracts',
    },
    pageTitle: PageTitle.CONTRACT_DETAIL_FR,
    pageTitleKey: 'myLeaseplan.contract.car.title',
  },
  {
    path: 'myLeaseplan.profile.detailFr',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: ViewProfile,
    pageTitle: PageTitle.PROFILE_FR,
    pageTitleKey: 'myLeaseplan.profile.detail.title',
  },
  {
    path: 'myLeaseplan.profile.editFr',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'editProfile',
    },
    pageTitle: PageTitle.PROFILE_EDIT_FR,
    pageTitleKey: 'myLeaseplan.profile.edit.title',
  },
  {
    path: 'myLeaseplan.fuelHistory.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'fuelHistory',
    },
    pageTitle: PageTitle.FUEL_HISTORY,
    pageTitleKey: 'myLeaseplan.fuelHistory.dashboard.title',
  },
  {
    path: 'myLeaseplan.fines.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'fines',
    },
    pageTitle: PageTitle.FINES,
    pageTitleKey: 'myLeaseplan.fines.dashboard.title',
  },
  {
    path: 'myLeaseplan.extendContract.base',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresContract: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'extendContract',
    },
    pageTitle: PageTitle.EXTEND_CONTRACT,
    pageTitleKey:
      'myLeaseplan.extendContract.steps.extendLeaseContract.pageTitle',
  },
  {
    path: 'myLeaseplan.underMaintenance',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: false,
    component: UnderMaintenance,
    pageTitle: PageTitle.UNDER_MAINTENANCE,
    pageTitleKey: 'myLeaseplan.pageNotAvailable.title',
  },
  {
    path: 'myLeaseplan.somethingWentWrong',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: false,
    component: SomethingWentWrongPage,
    pageTitle: PageTitle.SOMETHING_WENT_WRONG,
    pageTitleKey: 'myLeaseplan.somethingWentWrong.title',
  },
]

const quoteRoutes: MyleaseplanRoute[] = [
  {
    path: 'myLeaseplan.quoteDetails',
    enabledForFeature: ['myLeasePlan'],
    exact: true,
    isAuthProtected: true,
    requiresQuote: true,
    component: MyleaseplanFeature,
    props: {
      feature: 'quotes',
    },
    pageTitle: PageTitle.QUOTE_DETAILS,
    pageTitleKey: 'myLeaseplan.quoteDetails.title',
  },
]

const catchAllRoute: MyleaseplanRoute = {
  component: NotFound,
  enabledForFeature: ['myLeasePlan'],
  exact: false,
  isAuthProtected: false,
  status: 404,
  path: '/',
  pageTitle: PageTitle.NOT_FOUND,
  pageTitleKey: 'myLeaseplan.error.notFound.title',
}

export const finalRoutes = (noContractAllowed: boolean) => [
  ...authRoutes,
  ...mylpRoutes(noContractAllowed).map(wrapRouteComponent),
  ...(noContractAllowed ? quoteRoutes.map(wrapRouteComponent) : []),
]

export const getLocalizedRoutes = memoize(
  (
    routes: Routes,
    currentLocale: string = 'en-us',
    noContractAllowedLocale: boolean,
  ): Route[] => {
    const localizedRoutes = finalRoutes(noContractAllowedLocale).reduce(
      (acc: Route[], route) => {
        const routeMatchLookup = getLocalizedRouteMatch(
          routes,
          currentLocale,
          route.path,
        )

        return routeMatchLookup
          ? [...acc, { ...route, path: routeMatchLookup }]
          : acc
      },
      [],
    )

    return [...localizedRoutes, withMyLeaseplanApp(catchAllRoute)]
  },
)

export default getLocalizedRoutes
