import React from 'react'
import { RouteComponentProps } from 'react-router'

import Maintenance from 'mlp-client/src/timeline-details/components/views/maintenance/Maintenance'
import TimelineBookingDetails from 'mlp-client/src/timeline-details/components/views/timeline-booking-details/TimelineBookingDetails'

interface Params {
  id: string
}

export type Props = RouteComponentProps<Params>

export const BookingDetails: React.FC<Props> = props => (
  <TimelineBookingDetails {...props} component={Maintenance} />
)
export default BookingDetails
