import { DeepPartial } from 'redux'

import { AppState, AppConfiguration } from 'mlp-client/src/types'
import {
  isMobileApp,
  setBootstrapHandler,
} from 'mlp-client/src/auth/mobile-utils'

import { initAuth } from './auth'

interface MobileToken {
  accessToken: string
  expiresAt: string
}

interface MyLeaseplanAuth {
  isMobileApp: boolean
  identityClientId: string
  mobileToken?: MobileToken
}

type AuthConfiguration = Pick<
  AppConfiguration,
  | 'IDENTITY_API'
  | 'IDENTITY_AUDIENCE'
  | 'IDENTITY_CLIENT_ID'
  | 'IDENTITY_CLIENT_ID_MOBILE'
>

const initMyLeaseplanAuthState = async (
  config: AuthConfiguration,
): Promise<MyLeaseplanAuth> => {
  const isMobile = isMobileApp()

  if (isMobile) {
    return new Promise(resolve => {
      const callback = (accessToken: string, expiresAt: string): void => {
        const authState: MyLeaseplanAuth = {
          identityClientId: config.IDENTITY_CLIENT_ID_MOBILE,
          isMobileApp: isMobile,
          mobileToken: {
            accessToken,
            expiresAt,
          },
        }

        resolve(authState)
      }

      setBootstrapHandler(callback)
    })
  }

  return {
    identityClientId: config.IDENTITY_CLIENT_ID,
    isMobileApp: isMobile,
  }
}

export const initMyLeaseplanAuth = async (
  config: AuthConfiguration,
): Promise<DeepPartial<AppState>> => {
  const mylpAuth = await initMyLeaseplanAuthState(config)

  initAuth({
    domain: config.IDENTITY_API,
    clientId: mylpAuth.identityClientId,
    audience: config.IDENTITY_AUDIENCE,
    isMobile: mylpAuth.isMobileApp,
    initialMobileCredentials: mylpAuth.mobileToken,
  })

  return {
    auth: {
      identityClientId: mylpAuth.identityClientId,
      isMobileApp: mylpAuth.isMobileApp,
      loading: false,
      nextRoute: null,
    },
  }
}
