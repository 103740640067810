import React, { Component } from 'react'

import SomethingWentWrongPage from 'mlp-client/src/components/something-went-wrong/SomethingWentWrongPage'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <SomethingWentWrongPage />
    }

    return this.props.children
  }
}

export default ErrorBoundary
