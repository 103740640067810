import {
  ChevronDownIcon,
  Grid,
  GridItem,
  Hide,
  List,
  ListItem,
  Modal,
  Spacing,
} from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { getUser } from 'mlp-client/src/user/selectors'
import { User } from 'mlp-client/src/user/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import TextCenter from 'mlp-client/src/components/styled/TextCenter'
import { AppState } from 'mlp-client/src/types'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

import {
  getWelcomeScreenAllowedServices,
  isWelcomeScreenVisible,
} from './selectors'

interface State {
  isOpen: boolean
}

export interface Props {
  isModalEnable: boolean
  allowedServices?: readonly string[]
  user: User
}

const StyledModal = styled(Modal)`
  max-width: 586px;
`

const translatePath = makeTranslationPath('myLeaseplan.welcomePopup')

const ColorIcon = () => (
  <ChevronDownIcon color="grassGreen" width="24px" height="24px" />
)

export const getWelcomeImageSrc = () => {
  if (isLeaseplan()) {
    return '/static/images/my-leaseplan/welcome-screen.png'
  }

  return '/static/images/my-leaseplan/leasys-welcome-screen.png'
}

class WelcomeScreen extends React.PureComponent<Props> {
  state: State = {
    isOpen: true,
  }

  hideModal = () => {
    const { user } = this.props

    this.setState({ isOpen: false })

    if (user) {
      window.localStorage.setItem(`welcomeScreen-${user.id}`, '1')
    }
  }

  renderListItem = (item: string) => (
    <ListItem key={item}>
      <Text
        data-e2e-id={`welcomeScreen_listItem_${item}`}
        data-test={`welcomeScreen_listItem_${item}`}
      >
        <Translation id={translatePath(item)} />
      </Text>
    </ListItem>
  )

  render() {
    const { isOpen } = this.state
    const { user, allowedServices, isModalEnable } = this.props
    const welcomeImageSrc = getWelcomeImageSrc()

    if (
      !user ||
      !isModalEnable ||
      !allowedServices ||
      !allowedServices.length ||
      !user.firstName ||
      window.localStorage.getItem(`welcomeScreen-${user.id}`)
    ) {
      return null
    }

    return (
      <Hide until="tablet" implementation="js">
        <StyledModal
          data-e2e-id="welcomeScreen_modal"
          closeButtonType="none"
          open={isOpen}
          onClose={this.hideModal}
        >
          <div data-e2e-id="welcomeScreen_modal">
            <img src={welcomeImageSrc} alt="Welcome" />
            <Spacing mh={3} mv={2}>
              <Headline variant="300" withMarginBottom component="h1">
                <TextCenter>
                  <Translation
                    id={translatePath('welcome')}
                    replace={{ username: user.firstName }}
                  />
                </TextCenter>
              </Headline>
            </Spacing>
            <Spacing mh={3} mv={2}>
              <List icon={ColorIcon}>
                {allowedServices.map(this.renderListItem)}
              </List>
            </Spacing>
            <Spacing mh={3} mt={2} mb={4}>
              <Grid justifyContent="center">
                <GridItem span={7}>
                  <StyledButton
                    data-e2e-id="welcomeScreen_button_gotIt"
                    stretch={true}
                    variant="primary"
                    onClick={this.hideModal}
                  >
                    <Translation id={translatePath('button')} />
                  </StyledButton>
                </GridItem>
              </Grid>
            </Spacing>
          </div>
        </StyledModal>
      </Hide>
    )
  }
}

type ReduxProps = Pick<Props, 'user' | 'isModalEnable' | 'allowedServices'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  user: getUser(state),
  isModalEnable: isWelcomeScreenVisible(state),
  allowedServices: getWelcomeScreenAllowedServices(state),
})

export { WelcomeScreen }
export default connect(mapStateToProps)(WelcomeScreen)
