import { BookingData } from 'mlp-client/src/timeline-details/types'

export const enum ActionType {
  LOAD_BOOKING = '@@myLeaseplan/timeline-details/LOAD_BOOKING',
  LOAD_BOOKING_SUCCESS = '@@myLeaseplan/timeline-details/LOAD_BOOKING_SUCCESS',
  LOAD_BOOKING_FAILED = '@@myLeaseplan/timeline-details/LOAD_BOOKING_FAILED',
  CANCEL_BOOKING = '@@myLeaseplan/timeline-details/CANCEL_BOOKING',
  CANCEL_BOOKING_SUCCESS = '@@myLeaseplan/timeline-details/CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAILED = '@@myLeaseplan/timeline-details/CANCEL_BOOKING_FAILED',
  RESET_CANCELLATION_STATUS = '@@myLeaseplan/timeline-details/RESET_CANCELLATION_STATUS',
}

export class LoadBooking {
  readonly type = ActionType.LOAD_BOOKING
  constructor(public payload: { id: string }) {}
}

export class LoadBookingSuccess {
  readonly type = ActionType.LOAD_BOOKING_SUCCESS
  constructor(public payload: BookingData) {}
}

export class LoadBookingFailed {
  readonly type = ActionType.LOAD_BOOKING_FAILED
}

export class CancelBooking {
  readonly type = ActionType.CANCEL_BOOKING
  constructor(public payload: { caseId: string }) {}
}

export class CancelBookingSuccess {
  readonly type = ActionType.CANCEL_BOOKING_SUCCESS
}

export class CancelBookingFailed {
  readonly type = ActionType.CANCEL_BOOKING_FAILED
}

export class ResetCancellationStatus {
  readonly type = ActionType.RESET_CANCELLATION_STATUS
}

export type Action =
  | LoadBooking
  | LoadBookingSuccess
  | LoadBookingFailed
  | CancelBooking
  | CancelBookingSuccess
  | CancelBookingFailed
  | ResetCancellationStatus
