import { BreakpointName, Spacing, withBreakpoint } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import { SetCurrentContract } from 'mlp-client/src/contracts/actions'
import { getLeaseContracts } from 'mlp-client/src/contracts/selectors'
import { Contract, Contracts } from 'mlp-client/src/contracts/types'
import { AppState } from 'mlp-client/src/types'
import { isMobileBreakpoint } from 'mlp-client/src/utils'

import VehicleOverviewTileMobile from './VehicleOverviewTileMobile'
import VehicleOverviewTileWide from './VehicleOverviewTileWide'

export interface Props {
  breakpoint: BreakpointName
  contracts: Contracts
  setActiveContract(contract: Contract): void
}
class VehicleOverviewTile extends React.PureComponent<Props> {
  getVehicleOverviewComponent(breakpoint: BreakpointName) {
    const isMobile = isMobileBreakpoint(breakpoint)

    return isMobile ? VehicleOverviewTileMobile : VehicleOverviewTileWide
  }

  render() {
    const { breakpoint, contracts, setActiveContract } = this.props
    const VehicleOverviewComponent = this.getVehicleOverviewComponent(
      breakpoint,
    )

    return (
      <>
        {contracts.map(contract => (
          <Spacing mb={2} key={contract.id}>
            <VehicleOverviewComponent
              contract={contract}
              setActiveContract={setActiveContract}
            />
          </Spacing>
        ))}
      </>
    )
  }
}

type ReduxProps = Pick<Props, 'contracts'>
type DispatchProps = Pick<Props, 'setActiveContract'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contracts: getLeaseContracts(state),
})

const mapDispatchToProps: DispatchProps = {
  setActiveContract: (contract: Contract) =>
    new SetCurrentContract({ contractId: contract.id }),
}

export { VehicleOverviewTile }
export default withBreakpoint(
  connect(mapStateToProps, mapDispatchToProps)(VehicleOverviewTile),
)
