import React from 'react'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'

export const FlowContext = React.createContext<FlowProps>(null)

export function withFlowContext<P>(
  Comp: React.ComponentType<P>,
): React.ComponentClass<P> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return class ComponentWithFlowContext extends React.PureComponent<P> {
    static displayName: string = `withFlowContext(${
      Comp.displayName || Comp.name
    })`

    render() {
      return (
        <FlowContext.Consumer>
          {flowContext => <Comp {...this.props} {...flowContext} />}
        </FlowContext.Consumer>
      )
    }
  }
}
