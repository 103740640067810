import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/attach-car/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { ApiSagas, RequestWithParams } from 'mlp-client/src/api'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { config } from 'mlp-client/src/app-config'

export function* attachCar(action: actions.PostAttachCar): SagaIterator {
  const {
    attachCar: {
      salutation,
      firstName,
      lastName,
      emailAddress,
      telephoneNumber,
      licensePlate,
    },
    onComplete,
  } = action.payload

  /*
   * Contract number is provided to the backend as
   * an empty string
   * but currently isn't being used in the frontend
   * Future cases might provide this but for now
   * only a link through licensePlate is done.
   */
  const body = {
    salutation,
    firstName,
    lastName,
    emailAddress,
    telephoneNumber,
    licensePlate,
    contractNumber: '',
    detailedMessage: '',
  }

  const options = {
    throwError: true,
  }

  try {
    /**
     * Our API has two different endpoints for this. If the user is already
     * known and logged in, we access a url that is restricted by an
     * authorization check. If the user is unknown, we call the non-auth
     * protected route
     */
    const user = yield select(getUser)
    const knownUser = user && user.id

    yield call(
      ApiSagas.post,
      `/myleaseplan/lpis/attach-${knownUser ? '' : 'unknown-'}car`,
      { body },
      options,
    )
    yield call(onComplete)
  } catch {
    const error = yield select(
      getTranslation,
      'myLeaseplan.shared.genericAPIError',
    )

    yield call(onComplete, { error })
  }
}

export function* checkLicensePlate(
  action: actions.ValidateLicensePlate,
): SagaIterator {
  const requestUrl = '/myleaseplan/lpis/driver-details'
  const { licensePlate } = action.payload

  const requestParams: RequestWithParams = {
    params: {
      licensePlate,
    },
    headers: {
      token: process.env.API_TOKEN || config.API_TOKEN,
    },
  }

  try {
    const result = yield call(ApiSagas.get, requestUrl, requestParams)

    yield put(new actions.ValidateLicensePlateSuccess(result))
  } catch {
    const error = yield select(
      getTranslation,
      'myLeaseplan.shared.genericAPIError',
    )

    return { error }
  }
}

export default [
  takeLatest(actions.ActionTypes.ATTACH_CAR, attachCar),
  takeLatest(actions.ActionTypes.VALIDATE_LICENSE_PLATE, checkLicensePlate),
]
