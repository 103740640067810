import { Hide } from '@leaseplan/ui'
import React from 'react'

import { PhoneNumber } from 'mlp-client/src/flows/components/phone-number/PhoneNumber'
import { FrenchPhoneNumber } from 'mlp-client/src/components/french-phone-number/FrenchPhoneNumber'

export const FrenchPhoneRenderer: React.FC<{
  children: readonly string[]
}> = props => (
  <>
    <Hide until="lap">
      <PhoneNumber phone={props.children.toString()} />
    </Hide>
    <Hide from="lap">
      <FrenchPhoneNumber number={props.children.toString()} />
    </Hide>
  </>
)
