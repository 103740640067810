import * as React from 'react'

interface Props {
  onClick(): void
  expanded: boolean
}

const BreadcrumbsCollapsed: React.FunctionComponent<Props> = (props: Props) => (
  <li {...props} tabIndex={0} role="button" aria-expanded={props.expanded}>
    ...
  </li>
)

export default BreadcrumbsCollapsed
