import {
  CashClaimSubmitErrors,
  FlowData,
} from 'mlp-client/src/flows/cash-claim/types'

export const enum ActionTypes {
  SUBMIT_CASH_CLAIM_REQUEST = '@@myLeaseplan/cashClaim/SUBMIT_CASH_CLAIM_REQUEST',
}

export class SubmitCashClaimRequest {
  readonly type = ActionTypes.SUBMIT_CASH_CLAIM_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(error?: CashClaimSubmitErrors): void
    },
  ) {}
}

export type Action = SubmitCashClaimRequest
