import { FlowData } from './types'

export const enum ActionTypes {
  EXTEND_CONTRACT_REQUEST = '@@myLeaseplan/extendContract/EXTEND_CONTRACT_REQUEST',
}

export class SubmitExtendContractRequest {
  readonly type = ActionTypes.EXTEND_CONTRACT_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(isError: boolean): void
    },
  ) {}
}
