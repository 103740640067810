import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import Supplier from 'mlp-client/src/components/suppliers/Supplier'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { GlassRepairStaticPageConfiguration } from 'mlp-client/src/flows/glass-repair/types'
import { AppState, SupplierCard as SupplierType } from 'mlp-client/src/types'
import { DashboardBreadcrumbs } from 'mlp-client/src/dashboard/components/DashboardBreadcrumbs'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'

export interface Props
  extends MyLeaseplanFeatureProps<GlassRepairStaticPageConfiguration> {
  title: string
  subTitle: string
  contactTitle: string
  contactSummary: string
  dashboard: string
  contractId: string
}

class GlassSuppliersStaticPage extends React.PureComponent<Props> {
  renderHeader(): React.ReactNode {
    const { contactSummary, contactTitle } = this.props

    return (
      <>
        <Headline variant="400" component="h1" withMarginBottom>
          {contactTitle}
        </Headline>
        <Text component="p" loose>
          {contactSummary}
        </Text>
      </>
    )
  }

  renderSupplierList(): React.ReactNode {
    const {
      featureConfig: { configuration },
    } = this.props
    const suppliersList = configuration ? configuration.suppliersList : []

    if (!suppliersList || !suppliersList.length) {
      return null
    }

    return suppliersList.map(this.renderSupplier)
  }

  renderSupplier(glassSupplier: SupplierType): React.ReactElement<{}> {
    const { name } = glassSupplier

    return (
      <Spacing mb={2} key={name}>
        <Supplier supplier={glassSupplier} />
      </Spacing>
    )
  }

  getBanner = () => {
    const { title, subTitle, contractId } = this.props

    return (
      <FlowStepPoster
        imgMedium="/static/images/my-leaseplan/glass-repair-header-medium.jpg"
        imgLarge="/static/images/my-leaseplan/glass-repair-header-large.jpg"
        imgSmall="/static/images/my-leaseplan/mobile/glass-repair-mobile.png"
        title={title}
        text={subTitle}
        breadcrumbs={
          <DashboardBreadcrumbs
            currentStepTitle={title}
            contractId={contractId}
          />
        }
      />
    )
  }

  render() {
    const header = this.renderHeader()
    const main = this.renderSupplierList()
    const { title, contactTitle } = this.props

    return (
      <MyleaseplanPage
        metaTitle={title}
        metaDescription={contactTitle}
        banner={this.getBanner()}
      >
        <Spacing mb={3}>
          <GridLayout main={header} mainSpan={12} />
        </Spacing>

        <GridLayout main={main} />
      </MyleaseplanPage>
    )
  }
}

export type ReduxProps = Pick<
  Props,
  | 'title'
  | 'subTitle'
  | 'contactTitle'
  | 'contactSummary'
  | 'contractId'
  | 'dashboard'
>

const mapTranslationsToProps = {
  title: 'myLeaseplan.glassRepair.dashboard.title',
  subTitle: 'myLeaseplan.glassRepair.dashboard.subTitle',
  contactTitle: 'myLeaseplan.glassRepair.page.contact.title',
  contactSummary: 'myLeaseplan.glassRepair.page.contact.summary',
  dashboard: 'myLeaseplan.subNavigation.dashboard',
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  ...getTranslations(state, mapTranslationsToProps),
  contractId: getCurrentContractId(state),
})

export { GlassSuppliersStaticPage }
export default connect(mapStateToProps)(GlassSuppliersStaticPage)
