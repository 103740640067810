import * as actions from 'mlp-client/src/timeline-details/actions'
import { CancellationStatus } from 'mlp-client/src/timeline-details/enums'
import { State } from 'mlp-client/src/timeline-details/types'

export const defaultState: State = {
  isLoading: false,
  isFailed: false,
  isCancelling: false,
  cancellationStatus: undefined,
  booking: undefined,
}

export const setLoading = (state: State) => ({
  ...state,
  isCancelling: false,
  isFailed: false,
  isLoading: true,
})

export const setFailed = (state: State) => ({
  ...state,
  isCancelling: false,
  isFailed: true,
  isLoading: false,
})

export const setCancelling = (state: State) => ({
  ...state,
  isCancelling: true,
  isFailed: false,
  isLoading: false,
})

export const setCancellationSuccess = (state: State) => ({
  ...state,
  isCancelling: false,
  cancellationStatus: CancellationStatus.SUCCESS,
})

export const setCancellationFail = (state: State) => ({
  ...state,
  isCancelling: false,
  cancellationStatus: CancellationStatus.FAILED,
})

export const resetCancellationStatus = (state: State) => ({
  ...state,
  cancellationStatus: defaultState.cancellationStatus,
})

export const setBookingDetails = (
  state: State,
  action: actions.LoadBookingSuccess,
) => ({
  ...state,
  isFailed: false,
  isLoading: false,
  booking: action.payload,
})

const timelineDetailsReducer = (
  state: State = defaultState,
  action: actions.Action,
): State => {
  switch (action.type) {
    case actions.ActionType.LOAD_BOOKING:
      return setLoading(state)
    case actions.ActionType.LOAD_BOOKING_FAILED:
      return setFailed(state)
    case actions.ActionType.LOAD_BOOKING_SUCCESS:
      return setBookingDetails(state, action)
    case actions.ActionType.CANCEL_BOOKING:
      return setCancelling(state)
    case actions.ActionType.CANCEL_BOOKING_FAILED:
      return setCancellationFail(state)
    case actions.ActionType.CANCEL_BOOKING_SUCCESS:
      return setCancellationSuccess(state)
    case actions.ActionType.RESET_CANCELLATION_STATUS:
      return resetCancellationStatus(state)
    default:
      return state
  }
}

export default timelineDetailsReducer
