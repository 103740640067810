import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { createStructuredSelector } from 'reselect'

import { Locales } from 'mlp-client/src/localization/enums'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { LoadFooterMenu } from 'mlp-client/src/content/actions'
import { State as ContentState } from 'mlp-client/src/content/reducers'
import { getFooter } from 'mlp-client/src/content/selectors'
import { Footer } from 'mlp-client/src/components/footer/Footer'
import { AppState } from 'mlp-client/src/types'

interface Props {
  className?: string
  userLocale: Locales
  footer: ContentState['footer']
  isMobileApp: boolean
  loadFooterLinks(language: string): void
}

export class SmallFooter extends React.PureComponent<Props> {
  componentDidMount(): void {
    const { userLocale, footer, loadFooterLinks } = this.props

    if (!footer || footer.locale !== userLocale) {
      loadFooterLinks(userLocale)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { userLocale, loadFooterLinks } = this.props

    if (prevProps.userLocale !== userLocale) {
      loadFooterLinks(userLocale)
    }
  }

  render() {
    const {
      className,
      isMobileApp,
      footer: { footerLinks },
    } = this.props

    if (isMobileApp) {
      return null
    }

    return <Footer className={className} items={footerLinks} />
  }
}

type ReduxProps = Pick<Props, 'isMobileApp' | 'footer' | 'userLocale'>
type DispatchProps = Pick<Props, 'loadFooterLinks'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  userLocale: getUserLocale,
  isMobileApp: getIsMobileApp,
  footer: getFooter,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadFooterLinks: (userLocale: Locales) =>
    dispatch(new LoadFooterMenu({ userLocale })),
})

export default connect(mapStateToProps, mapDispatchToProps)(SmallFooter)
