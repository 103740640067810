import React from 'react'
import { useSelector } from 'react-redux'

import {
  getContract,
  showMultipleVehicles as getShowMultipleVehicles,
} from 'mlp-client/src/contracts/selectors'
import { mapContractToVehicle } from 'mlp-client/src/contracts/utils'

import {
  Props as VehicleModelProps,
  VehicleModelLicense,
} from './VehicleModelLicense'

type Props = Omit<VehicleModelProps, 'vehicle'>

export const ActiveVehicleModelLicense: React.FC<Props> = props => {
  const vehicle = mapContractToVehicle(useSelector(getContract))
  const showMultipleVehicles = useSelector(getShowMultipleVehicles)

  return showMultipleVehicles ? (
    <VehicleModelLicense {...props} vehicle={vehicle} />
  ) : null
}

export default ActiveVehicleModelLicense
