import { ButtonChevronRightIcon } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'

interface BackToDashboardButton {
  disabled?: boolean
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void
}

export default (props: BackToDashboardButton) => (
  <StyledButton
    disabled={props.disabled}
    stretch={true}
    icon={ButtonChevronRightIcon}
    data-e2e-id="button-back-to-dashboard"
    onClick={props.onClick}
  >
    <Translation id="myLeaseplan.newSetup.shared.backToDashboard" />
  </StyledButton>
)
