import { NearbySuppliers } from 'mlp-client/src/types'

import * as actions from './actions'
import { LoadingStatuses } from './enums'
import {
  Contracts,
  Notification,
  PolicyDocuments,
  RegistrationCertificate,
} from './types'

export interface State {
  currentContract: string
  loading: boolean
  contracts: Contracts
  isLoaded: boolean
  loadingPolicyDocuments: boolean
  nearbySuppliers: NearbySuppliers
  policyDocuments: PolicyDocuments
  registrationCertificate: RegistrationCertificate
  notifications: readonly Notification[]
  showPredictiveMileageModalForContractId: string | null
}

export const defaultState: State = {
  currentContract: null,
  loading: false,
  contracts: [],
  isLoaded: false,
  policyDocuments: [],
  registrationCertificate: null,
  loadingPolicyDocuments: false,
  notifications: [],
  nearbySuppliers: {
    status: '',
    suppliers: [],
    searchLocation: null,
    noSuppliersByMalfunctions: false,
    isSuppliersNumberFetched: false,
  },
  showPredictiveMileageModalForContractId: null,
}

const setCurrentContract = (state: State, id: string) => ({
  ...state,
  currentContract: id,
})

export const loadContracts = (state: State) => ({
  ...state,
  loading: true,
})

export const loadContractsSuccess = (
  state: State,
  action: actions.LoadContractsSuccess,
) => ({
  ...state,
  contracts: action.payload.contracts,
  loading: false,
  isLoaded: true,
})

export const loadContractsFailed = (state: State): State => ({
  ...state,
  contracts: defaultState.contracts,
  loading: false,
  isLoaded: false,
})

export const loadPolicyDocuments = (state: State) => ({
  ...state,
  loadingPolicyDocuments: true,
})

export const loadPolicyDocumentsSuccess = (
  state: State,
  action: actions.LoadPolicyDocumentsSuccess,
) => ({
  ...state,
  policyDocuments: action.payload.policyDocuments,
  loadingPolicyDocuments: false,
})

export const loadPolicyDocumentsFailed = (state: State): State => ({
  ...state,
  loadingPolicyDocuments: false,
})

export const downloadPolicyDocument = (state: State) => ({
  ...state,
  loadingPolicyDocuments: true,
})
export const downloadPolicyDocumentSuccess = (state: State) => ({
  ...state,
  loadingPolicyDocuments: false,
})

export const downloadPolicyDocumentFailed = (state: State) => ({
  ...state,
  loadingPolicyDocuments: false,
})

export const downloadRegistrationCertificate = (state: State) => ({
  ...state,
  loadingPolicyDocuments: true,
})

export const downloadRegistrationCertificateSuccess = (state: State) => ({
  ...state,
  loadingPolicyDocuments: false,
})

export const downloadRegistrationCertificateFailed = (state: State) => ({
  ...state,
  loadingPolicyDocuments: false,
})

export const setContractsLoading = (
  state: State,
  action: actions.SetContractsLoading,
) => ({
  ...state,
  loading: action.payload.loading,
  isLoaded: false,
})

// needed to preserve isSuppliersNumberFetched when load suppliers on different date, but with the same malfunctions
export const clearNearbySuppliers = (state: State) => ({
  ...state,
  nearbySuppliers: {
    ...defaultState.nearbySuppliers,
    isSuppliersNumberFetched: state.nearbySuppliers.isSuppliersNumberFetched,
  },
})

export const resetNearbySuppliers = (state: State) => ({
  ...state,
  nearbySuppliers: defaultState.nearbySuppliers,
})

const setNoSuppliersByMalfunctions = (
  state: State,
  action: actions.SetNoSuppliersByMalfunctions,
) => ({
  ...state,
  nearbySuppliers: {
    ...state.nearbySuppliers,
    noSuppliersByMalfunctions: action.payload.noSuppliersByMalfunctions,
    isSuppliersNumberFetched: true,
  },
})

export const setNearbySuppliers = (
  state: State,
  action: actions.LoadNearbySuppliersSuccess,
) => ({
  ...state,
  nearbySuppliers: {
    ...state.nearbySuppliers,
    status: LoadingStatuses.SUCCESS,
    suppliers: action.payload.data.results,
    searchLocation: action.payload.data.searchLocation,
  },
})

export const getNearbySuppliersFailed = (
  state: State,
  action: actions.LoadNearbySuppliersFailed,
) => ({
  ...state,
  nearbySuppliers: {
    ...state.nearbySuppliers,
    status: LoadingStatuses.FAILED,
    reason: action.payload.data.reason,
  },
})

export const loadNotificationsSuccess = (
  state: State,
  action: actions.LoadNotificationsSuccess,
) => ({
  ...state,
  notifications: action.payload,
})

export const resetNotifications = (state: State) => ({
  ...state,
  notifications: defaultState.notifications,
})

export const predictiveMileageModalShow = (
  state: State,
  action: actions.PredictiveMileageModalShow,
) => ({
  ...state,
  showPredictiveMileageModalForContractId: action.payload.contractId,
})

export const predictiveMileageModalShown = (state: State) => ({
  ...state,
  showPredictiveMileageModalForContractId: null as string,
})

export const getRegistrationCertificateData = (state: State) => ({
  ...state,
  loadingPolicyDocuments: true,
})

export const getRegistrationCertificateDataSuccess = (
  state: State,
  action: actions.GetRegistrationCertificateDataSuccess,
) => ({
  ...state,
  loadingPolicyDocuments: false,
  registrationCertificate: action.payload,
})

export const getRegistrationCertificateDataFailed = (state: State) => ({
  ...state,
  loadingPolicyDocuments: false,
})

export default (state = defaultState, action: actions.Action): State => {
  switch (action.type) {
    case actions.ActionTypes.SET_CURRENT_CONTRACT:
      const { contractId } = action.payload

      return setCurrentContract(state, contractId)
    case actions.ActionTypes.DEFINE_CURRENT_CONTRACT:
      return setCurrentContract(state, action.payload)
    case actions.ActionTypes.LOAD_CONTRACTS:
      return loadContracts(state)
    case actions.ActionTypes.LOAD_CONTRACTS_SUCCESS:
      return loadContractsSuccess(state, action)
    case actions.ActionTypes.LOAD_CONTRACTS_FAILED:
      return loadContractsFailed(state)
    case actions.ActionTypes.LOAD_POLICY_DOCUMENTS:
      return loadPolicyDocuments(state)
    case actions.ActionTypes.LOAD_POLICY_DOCUMENTS_SUCCESS:
      return loadPolicyDocumentsSuccess(state, action)
    case actions.ActionTypes.LOAD_POLICY_DOCUMENTS_FAILED:
      return loadPolicyDocumentsFailed(state)
    case actions.ActionTypes.DOWNLOAD_POLICY_DOCUMENT:
      return downloadPolicyDocument(state)
    case actions.ActionTypes.DOWNLOAD_POLICY_DOCUMENT_SUCCESS:
      return downloadPolicyDocumentSuccess(state)
    case actions.ActionTypes.DOWNLOAD_POLICY_DOCUMENT_FAILED:
      return downloadPolicyDocumentFailed(state)
    case actions.ActionTypes.SET_CONTRACTS_LOADING:
      return setContractsLoading(state, action)
    case actions.ActionTypes.LOAD_NEARBY_SUPPLIERS:
      return clearNearbySuppliers(state)
    case actions.ActionTypes.RESET_NEARBY_SUPPLIERS:
      return resetNearbySuppliers(state)
    case actions.ActionTypes.NO_AVAILABLE_SUPPLIER:
      return setNoSuppliersByMalfunctions(state, action)
    case actions.ActionTypes.LOAD_NEARBY_SUPPLIERS_SUCCESS:
      return setNearbySuppliers(state, action)
    case actions.ActionTypes.LOAD_NEARBY_SUPPLIERS_FAILED:
      return getNearbySuppliersFailed(state, action)
    case actions.ActionTypes.LOAD_NOTIFICATIONS_SUCCESS:
      return loadNotificationsSuccess(state, action)
    case actions.ActionTypes.RESET_NOTIFICATIONS:
      return resetNotifications(state)
    case actions.ActionTypes.PREDICTIVE_MILEAGE_MODAL_SHOW:
      return predictiveMileageModalShow(state, action)
    case actions.ActionTypes.PREDICTIVE_MILEAGE_MODAL_SHOWN:
      return predictiveMileageModalShown(state)
    default:
      return state
  }
}

export const registrationCertificateReducer = (
  state = defaultState, //NOSONAR
  action: actions.Action,
): State => {
  switch (action.type) {
    case actions.ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE:
      return downloadRegistrationCertificate(state)
    case actions.ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE_SUCCESS:
      return downloadRegistrationCertificateSuccess(state)
    case actions.ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE_FAILED:
      return downloadRegistrationCertificateFailed(state)
    case actions.ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA:
      return getRegistrationCertificateData(state)
    case actions.ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA_SUCCESS:
      return getRegistrationCertificateDataSuccess(state, action)
    case actions.ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA_FAILED:
      return getRegistrationCertificateDataFailed(state)
    default:
      return state
  }
}
