import { Color } from '@leaseplan/ui'
import styled from 'styled-components'

export const StyledBreadcrumbsList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;

  li {
    display: flex;
    list-style: none;
    padding: 0 6px 0 0;
    color: ${props => Color[props.color]};
  }

  li a {
    color: ${props => Color[props.color]};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li p {
    color: ${props => Color[props.color]};
    margin: 0;
  }
`

StyledBreadcrumbsList.displayName = 'StyledBreadcrumbsList'
