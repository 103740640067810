import { ButtonLink, Grid, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import { CloseIcon } from '@velocity/ui/draft'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import SupplierOpeningHours from 'mlp-client/src/flows/components/pages/supplier-map-page/supplier-opening-hours/SupplierOpeningHours'
import { getFormattedAddress } from 'mlp-client/src/components/suppliers/utils'
import { AppState, Supplier } from 'mlp-client/src/types'
import { WhiteText } from 'mlp-client/src/components/styled/TextStyle'

import {
  Address,
  DistanceWrapper,
  IconWrapper,
  SupplierDetailsWrapper,
  SupplierName,
  SupplierWrapper,
} from './SupplierDetails.styled'

export interface Props {
  supplier: Supplier
  phoneLabel: string
  supplierClosed: string
  measureUnit: string
  onClose(): void
}

export class SupplierDetails extends React.PureComponent<Props> {
  render() {
    const { supplier, phoneLabel, measureUnit, onClose } = this.props
    const { name, distance, phone, website } = supplier

    return (
      <SupplierDetailsWrapper data-e2e-id="supplierDetailsCard">
        <DistanceWrapper>
          <WhiteText variant="100">{`${distance} ${measureUnit}`}</WhiteText>
        </DistanceWrapper>
        <Grid wrap="nowrap">
          <SupplierWrapper>
            <SupplierName variant="400" component="p" bold>
              {name}
            </SupplierName>
            <Address variant="200" component="p">
              {getFormattedAddress(supplier)}
            </Address>
            <Spacing mv={1}>
              <SupplierOpeningHours supplier={supplier} />
            </Spacing>

            <Text variant="200" bold>
              {phoneLabel}:{' '}
              <ButtonLink href={`tel:${phone}`}>{phone}</ButtonLink>
            </Text>
            {website && (
              <ButtonLink
                size="s"
                fontWeight="regular"
                href={website}
                data-e2e-id="supplierWebsite"
              >
                {website}
              </ButtonLink>
            )}
          </SupplierWrapper>
          <IconWrapper onClick={onClose}>
            <CloseIcon size="xs" color="#989A9A" />
          </IconWrapper>
        </Grid>
      </SupplierDetailsWrapper>
    )
  }
}

const mapTranslationsToProps = {
  phoneLabel: 'myLeaseplan.supplierMap.page.phone',
  supplierClosed: 'myLeaseplan.supplierMap.page.supplierClosed',
  measureUnit: 'myLeaseplan.shared.units.distance.kilometer',
}

type ReduxProps = Pick<Props, 'phoneLabel' | 'supplierClosed' | 'measureUnit'>

const mapStateToProps = (state: AppState): ReduxProps =>
  getTranslations(state, mapTranslationsToProps)

export default connect(mapStateToProps)(SupplierDetails)
