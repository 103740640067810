import React from 'react'

import RichText, {
  Props as RichTextProps,
} from 'mlp-client/src/components/rich-text/RichText'
import { StringReplacements } from 'mlp-client/src/utils'

import { LocalizationContext } from './LocalizationProvider'

type StrippedDownRichTextProps = Omit<RichTextProps, 'text'>

interface OwnProps {
  id: string
  replace?: StringReplacements
}

type Props = OwnProps & StrippedDownRichTextProps

const TranslatedRichText: React.FC<Props> = (props: Props) => {
  const { replace, id, ...richTextProps } = props
  const { translate } = React.useContext(LocalizationContext)
  const replaceArray =
    replace && Object.entries(replace).map(([key, value]) => ({ key, value }))
  const text = translate(id, replaceArray)

  return <RichText {...richTextProps} text={text} />
}

TranslatedRichText.displayName = 'TranslatedRichText'

export default TranslatedRichText
