import { Address } from 'mlp-client/src/user/types'
import { SupplierResponse } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'
import { BookingRequestType } from 'mlp-client/src/types'

export const enum ActionTypes {
  LOAD_SUPPLIERS = '@@supplierMapPage/LOAD_SUPPLIERS',
  LOAD_SUPPLIERS_SUCCESS = '@@supplierMapPage/LOAD_SUPPLIERS_SUCCESS',
  LOAD_SUPPLIERS_FAILURE = '@@supplierMapPage/LOAD_SUPPLIERS_FAILURE',
}

export class LoadSuppliers {
  readonly type = ActionTypes.LOAD_SUPPLIERS

  constructor(
    readonly payload: {
      address: Address
      bookingType: BookingRequestType
    },
  ) {}
}

export class LoadSuppliersSuccess {
  readonly type = ActionTypes.LOAD_SUPPLIERS_SUCCESS

  constructor(readonly payload: { data: SupplierResponse }) {}
}

export class LoadSuppliersFailure {
  readonly type = ActionTypes.LOAD_SUPPLIERS_FAILURE
}

export type Action = LoadSuppliersSuccess | LoadSuppliersFailure | LoadSuppliers
