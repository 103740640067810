import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

import Loader from 'mlp-client/src/components/loader/Loader'
import { LoadUser } from 'mlp-client/src/user/actions'
import { Logout } from 'mlp-client/src/auth/actions'
import {
  getAuthenticatedUser,
  getIsUserLoading,
  getIsUserLoadingFailed,
} from 'mlp-client/src/user/selectors'
import { User } from 'mlp-client/src/user/types'
import { AppState } from 'mlp-client/src/types'
import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'

export interface UserProps {
  user?: User
}

export interface Props extends UserProps, RouteComponentProps {
  isUserLoading: boolean
  isUserLoadingFailed: boolean
  logoutRoute: string
  children(user: User): React.ReactNode
  fetchCurrentUser(): void
  logout(nextRoute: string): void
}

export class LoadUserContainer extends React.PureComponent<Props> {
  private get shouldFetchUser(): boolean {
    const { user, isUserLoading } = this.props

    return isEmpty(user) && !isUserLoading
  }

  private get isUserFetched(): boolean {
    const { user, isUserLoading } = this.props

    return !isEmpty(user) && !isUserLoading
  }

  private loadUser(): void {
    if (this.shouldFetchUser) {
      this.props.fetchCurrentUser()
    }
  }

  componentDidMount() {
    this.loadUser()
  }

  componentDidUpdate() {
    if (this.props.isUserLoadingFailed) {
      const { logout, logoutRoute } = this.props

      logout(logoutRoute)
    }
  }

  render() {
    const { user, children } = this.props

    if (this.isUserFetched) {
      return children(user)
    }

    return <Loader loading={true} fixed={true} />
  }
}

type ReduxProps = Pick<
  Props,
  'user' | 'isUserLoading' | 'isUserLoadingFailed' | 'logoutRoute'
>
type DispatchProps = Pick<Props, 'fetchCurrentUser' | 'logout'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  user: getAuthenticatedUser(state),
  isUserLoading: getIsUserLoading(state),
  isUserLoadingFailed: getIsUserLoadingFailed(state),
  logoutRoute: getLocalizedRoute(state, 'myLeaseplan.somethingWentWrong'),
})

const mapDispatchToProps: DispatchProps = {
  fetchCurrentUser: () => new LoadUser({}),
  logout: nextRoute => new Logout({ nextRoute }),
}

const UserProvider = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadUserContainer),
)

export default <T extends UserProps>(
  ChildComponent: React.ComponentType<T>,
) => {
  const withUser: React.SFC<Omit<T, keyof UserProps>> = props => (
    <UserProvider>
      {user => <ChildComponent {...(props as T)} user={user} />}
    </UserProvider>
  )

  withUser.displayName = `withUser(${
    ChildComponent.displayName || ChildComponent.name
  })`

  return withUser
}
