import React, { AnchorHTMLAttributes } from 'react'
import { scroller as reactScroller } from 'react-scroll'

import DynamicLink from 'mlp-client/src/components/dynamic-link/DynamicLink'
import {
  transformUrlV2,
  isExternal,
  calculateOffset,
} from 'mlp-client/src/utils'
import { LinkContext } from 'mlp-client/src/components/rich-text/RichLinkContext'

interface Props {
  href: string
  title?: string
  className?: string
}

class RichTextLink extends React.PureComponent<Props> {
  static contextType = LinkContext

  linkClickHandler = (href: string) => (event: React.MouseEvent) =>
    this.context.handleClick?.(href, event)

  scrollToHash(ref: string) {
    reactScroller.scrollTo(ref.split('#')[1], {
      offset: calculateOffset(0),
    })
  }

  renderDefaultLink = (href: string, target: string): React.ReactNode => {
    const baseLinkProps = {
      href,
      title: this.props.title,
      className: this.props.className,
    }
    const isLocalHashHref =
      href.indexOf('#') > -1 && href.length > 2 && !isExternal(href)

    const anchorProps: AnchorHTMLAttributes<HTMLAnchorElement> = {
      ...baseLinkProps,
      ...(isLocalHashHref
        ? {
            onClick: () => this.scrollToHash(href),
          }
        : {
            target,
            onClick: this.linkClickHandler(href),
          }),
    }

    return (
      <a
        {...anchorProps}
        data-e2e-id={this.props['data-e2e-id'] || 'richTextLinkA'}
        data-tag-id={this.props['data-tag-id'] || 'rich-text-link-a'}
      >
        {this.props.children}
      </a>
    )
  }

  render() {
    /**
     * `href` prop may come in [targetXXX:][type:]url form, so we parse it first to extract
     * these parts.
     * targetXXX (optional): `targetBlank` is the only supported value at the moment
     * type (optional):
     *    - UrlV2: Sitecore format of URL. See `transformUrlV2` function for more info.
     *    - route: route key based URL.
     * Examples:
     *  `targetBlank:https://google.com` - URL to an external resource that has to be opened in a new window/tab
     *  `#info` - fragment identifier URL
     *  `route:myLeaseplan.dashboard` - route key URL
     *  `UrlV2:sitecore.content.BE.publicpages.Pages.Fleet_Management.Our_Services` - Sitecore specific URL
     */
    const { href, className, children } = this.props
    const matchedTarget = href.match(/^target\w+:/g)
    const to =
      matchedTarget === null ? href : href.slice(matchedTarget[0].length)
    const targetAsHtmlValue =
      matchedTarget !== null && matchedTarget[0] === 'targetBlank:'
        ? '_blank'
        : undefined

    const internalLink = to.substr(0, 1) === '/'
    const dynamicLink = to.substr(0, 6) === 'UrlV2:'
    const routeLink = to.substr(0, 6).toLowerCase() === 'route:'

    if (dynamicLink || routeLink || internalLink) {
      const route = dynamicLink
        ? transformUrlV2(to.split(':')[1])
        : routeLink
        ? to.split(':')[1]
        : to

      return (
        <DynamicLink
          to={route}
          className={className}
          target={targetAsHtmlValue}
          onClick={this.linkClickHandler(route)}
          data-e2e-id={this.props['data-e2e-id'] || 'richTextDynamicLink'}
          data-tag-id={this.props['data-tag-id'] || 'rich-text-dynamic-link'}
        >
          {children}
        </DynamicLink>
      )
    }

    return this.renderDefaultLink(to, targetAsHtmlValue)
  }
}

export { RichTextLink }
export default RichTextLink
