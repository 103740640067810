import { ButtonLink, Paper, Spacing, spacingUnit } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'

const PaperWrapper = styled(Paper)`
  min-height: 600px;
  height: calc(100vh - 300px);
  margin-top: ${spacingUnit * -2}px;
`
const IframeWrapper = styled(Spacing)`
  height: 100%;
`

export interface Props {
  title?: string
  iframeSrc: string
  breadcrumbTitle: string
}

export class IframePage extends React.PureComponent<Props> {
  renderHeader() {
    const { title, breadcrumbTitle } = this.props

    return (
      <>
        <Spacing mb={1}>
          <Breadcrumbs>
            <ButtonLink
              to={'myLeaseplan.dashboard'}
              component={RouteLink}
              size="s"
              fontWeight="regular"
              color="steel60"
            >
              <Translation id="myLeaseplan.subNavigation.dashboard" />
            </ButtonLink>
            <Translation id={breadcrumbTitle} />
          </Breadcrumbs>
        </Spacing>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id={title} />
        </Headline>
      </>
    )
  }

  renderMain() {
    return (
      <PaperWrapper>
        <IframeWrapper p={{ mobile: 1, mobileLandscape: 3 }}>
          <iframe
            src={this.props.iframeSrc}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          />
        </IframeWrapper>
      </PaperWrapper>
    )
  }

  render() {
    const header = this.renderHeader()
    const main = this.renderMain()

    return (
      <MyleaseplanPage metaTitle={this.props.title}>
        <Spacing mb={3}>
          <GridLayout main={header} mainSpan={12} mainMaxWidth="100%" />
        </Spacing>
        <GridLayout main={main} mainSpan={12} mainMaxWidth="100%" />
      </MyleaseplanPage>
    )
  }
}

export default IframePage
