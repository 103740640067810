import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  generatePath,
  withRouter,
  match,
  RouteComponentProps,
} from 'react-router'

import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import MyleaseplanFeature from 'mlp-client/src/components/myleaseplan-feature/MyleaseplanFeature'
import { isDashboardConfigurable } from 'mlp-client/src/dashboard/selectors'
import WelcomeScreen from 'mlp-client/src/welcome-screen/WelcomeScreen'
import onlyOrderExists from 'mlp-client/src/components/onlyOrderExists'
import { AppState } from 'mlp-client/src/types'
import {
  getCurrentContractId,
  showMultipleVehicles,
} from 'mlp-client/src/contracts/selectors'
import { getLocale } from 'mlp-client/src/localization/selectors'

interface MatchParams {
  contractId: string
}

export interface Props extends RouteComponentProps {
  isMobileApp?: boolean
  isMultipleVehicles?: boolean
  isDashboardConfigurable: boolean
  contractId: string
  locale: string
  match: match<MatchParams>
}

type DefaultProps = Required<Pick<Props, 'isMobileApp' | 'isMultipleVehicles'>>
type InternalProps = Props & DefaultProps

class Dashboard extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    isMobileApp: false,
    isMultipleVehicles: false,
  }

  componentDidMount() {
    const { history, match, contractId, locale } = this.props

    if (!match.params.contractId) {
      history.replace(generatePath(match.path, { contractId, locale }))
    }
  }

  render() {
    const { isMobileApp, isMultipleVehicles } = this.props

    return (
      <>
        {(!isMobileApp || !isMultipleVehicles) && <WelcomeScreen />}
        <MyleaseplanFeature feature="dashboard" />
      </>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'isMobileApp'
  | 'isMultipleVehicles'
  | 'isDashboardConfigurable'
  | 'locale'
  | 'contractId'
>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isMobileApp: getIsMobileApp,
  isMultipleVehicles: showMultipleVehicles,
  isDashboardConfigurable,
  locale: getLocale,
  contractId: getCurrentContractId,
})

export { Dashboard }
export default compose(
  connect(mapStateToProps),
  withRouter,
  onlyOrderExists,
)(Dashboard)
