import * as actions from 'mlp-client/src/timeline/actions'
import { Timeline as State } from 'mlp-client/src/timeline/types'
import { DEFAULT_NUMBER_OF_ITEMS_PER_PAGE } from 'mlp-client/src/constants'

export const defaultState: State = {
  timeline: [],
  isLoading: false,
  isFailed: false,
  selectedVehicles: [],
  pagination: {
    page: 0,
    itemsPerPage:
      Number(localStorage.getItem('itemsPerPage')) ||
      DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
    totalCount: 0,
  },
}

const loadingTimeline = (state: State) => ({
  ...state,
  isLoading: true,
})

const loadTimelineSuccess = (
  state: State,
  action: actions.LoadTimeLineSuccess,
) => ({
  ...state,
  timeline: action.timeline,
  isFailed: false,
  isLoading: false,
})

const loadTimelineFailed = (state: State) => ({
  ...state,
  isFailed: true,
  isLoading: false,
})

const selectVehicle = (
  state: State = defaultState,
  action: actions.SelectVehicle,
) => ({
  ...state,
  selectedVehicles: state.selectedVehicles.includes(action.selectedContractId)
    ? state.selectedVehicles.filter(id => id !== action.selectedContractId)
    : [...state.selectedVehicles, action.selectedContractId],
})

const changePaginationItemPerPage = (
  state: State = defaultState,
  actions: actions.ChangePaginationItemsPerPage,
) => ({
  ...state,
  pagination: {
    ...state.pagination,
    itemsPerPage: actions.itemsPerPage,
    page: 0,
  },
})

const changePaginationPage = (
  state: State = defaultState,
  actions: actions.ChangePaginationPage,
) => ({
  ...state,
  pagination: {
    ...state.pagination,
    page: actions.page,
  },
})

const setPaginationTotalCount = (
  state: State = defaultState,
  actions: actions.SetPaginationTotalCount,
) => ({
  ...state,
  pagination: {
    ...state.pagination,
    totalCount: actions.totalCount,
  },
})

const timelineReducer = (state = defaultState, action: actions.Action) => {
  switch (action.type) {
    case actions.ActionTypes.LOADING_TIMELINE:
      return loadingTimeline(state)
    case actions.ActionTypes.LOAD_TIMELINE_SUCCESS:
      return loadTimelineSuccess(state, action)
    case actions.ActionTypes.LOAD_TIMELINE_FAILED:
      return loadTimelineFailed(state)
    case actions.ActionTypes.SELECT_VEHICLE:
      return selectVehicle(state, action)
    case actions.ActionTypes.CHANGE_PAGINATION_ITEMS_PER_PAGE:
      return changePaginationItemPerPage(state, action)
    case actions.ActionTypes.CHANGE_PAGINATION_PAGE:
      return changePaginationPage(state, action)
    case actions.ActionTypes.SET_PAGINATION_TOTAL_COUNT:
      return setPaginationTotalCount(state, action)
    default:
      return state
  }
}

export default timelineReducer
