import React, { Suspense } from 'react'
import { Color } from '@leaseplan/ui'

import {
  TenantId,
  getTenantId,
  defaultLeaseplanTenant,
  lpluTenant,
} from 'mlp-client/src/themes/multiTenant'

import { LpluTheme } from './themes/LPLUTheme'

const DefaultTheme = React.lazy(() => import('./themes/DefaultTheme'))
const LPLUTheme = React.lazy(() => import('./themes/LPLUTheme'))

export const ColorEnumSelector = (color: string) => {
  const tenant = getTenantId(window.location.href)

  if (tenant === lpluTenant) {
    return LpluTheme[color]
  }

  return Color[color]
}

export interface Props {
  tenant: TenantId
  children: React.ReactNode
}

export const isLeaseplan = () =>
  getTenantId(window.location.href) === defaultLeaseplanTenant

export const VelocitySelector: React.FC<Props> = ({ tenant, children }) => {
  if (tenant === lpluTenant) {
    return (
      <Suspense fallback={null}>
        <LPLUTheme> {children}</LPLUTheme>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={null}>
      <DefaultTheme>{children}</DefaultTheme>
    </Suspense>
  )
}
