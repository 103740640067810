import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepChevronList from 'mlp-client/src/components/flow-step-chevron-list/FlowStepChevronList'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { ConfirmFlowButton } from 'mlp-client/src/flows/components/buttons'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { SubmitTyresRequest } from 'mlp-client/src/flows/tyres/actions'
import { StepNames, TyreFlowType } from 'mlp-client/src/flows/tyres/enums'
import { FlowData } from 'mlp-client/src/flows/tyres/types'
import { FormSubmission } from 'mlp-client/src/form/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'

const labelPrefix = 'myLeaseplan.newSetup.flows.tyres.steps.confirmation'

export interface StepConfig {
  flowType?: TyreFlowType
}

export interface Props
  extends StepViewProps<StepConfig, FlowData>,
    FormSubmission<FlowData> {}

interface TileProps {
  name: string
  title: string
  children?: React.ReactNode
}

class ConfirmationStep extends React.PureComponent<Props> {
  editStep = (stepName: string) => () => {
    const { goToStep } = this.props

    goToStep(stepName)
  }

  renderTile = ({ name, title, children }: TileProps) => (
    <FlowStepTile
      dataE2eId={name}
      key={name}
      title={<Translation id={title} />}
      btnLabel={<Translation id="myLeaseplan.newSetup.shared.edit" />}
      onBtnClick={this.editStep(name)}
    >
      <FlowStepChevronList children={React.Children.toArray(children)} />
    </FlowStepTile>
  )

  getTyreServiceItems = () => {
    const {
      flowData: { problemDescription, tyreServiceType },
    } = this.props

    return [
      <Translation
        key={tyreServiceType}
        id={`myLeaseplan.newSetup.flows.tyres.constants.services.${tyreServiceType}`}
      />,
      <Text key={tyreServiceType}>{problemDescription}</Text>,
    ]
  }

  render() {
    const { flowData, onSubmit } = this.props

    return (
      <Form onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel={`${labelPrefix}.title`}>
                {flowData.tyreServiceType &&
                  this.renderTile({
                    name: StepNames.TYRE_SERVICE,
                    title: `${labelPrefix}.listItems.tyreServiceType.title`,
                    children: this.getTyreServiceItems(),
                  })}
                <Spacing mv={1} pv={1}>
                  <Text component="p" variant="100">
                    <Translation id="myLeaseplan.newSetup.flows.tyres.steps.confirmation.legalNotice" />
                  </Text>
                </Spacing>
                <SubmitStatus submitting={submitting} failed={submitFailed}>
                  <ConfirmFlowButton
                    onClick={handleSubmit}
                    disabled={submitting}
                  />
                </SubmitStatus>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

type ReduxProps = Pick<Props, never>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof DispatchProps>

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep } = props

    dispatch(
      new SubmitTyresRequest({
        flowData,
        onComplete: error => {
          if (!error) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

const mapStateToProps = (): ReduxProps => ({})

export { ConfirmationStep }
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep)
