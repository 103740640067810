import { Button, NextButton } from '@leaseplan/ui'
import styled, { css } from 'styled-components'

import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

const buttonStyle = css`
  background-image: ${isLeaseplan() ? '' : 'none'};
  background-color: ${isLeaseplan() ? '' : ColorEnumSelector('bloodOrange')};
  border-radius: ${isLeaseplan() ? '' : '0px'};

  &:hover {
    background-color: ${isLeaseplan() ? '' : ColorEnumSelector('mist')};
  }
`

export const StyledButton = styled(Button)`
  ${buttonStyle}
`

export const StyledNextButton = styled(NextButton)`
  ${buttonStyle}
`

export const StyledButtonOutlined = styled(Button)`
  color: ${isLeaseplan() ? '' : ColorEnumSelector('bloodOrange')};
  border-radius: ${isLeaseplan() ? '' : '0px'};
  border-color: ${isLeaseplan() ? '' : ColorEnumSelector('bloodOrange')};
`
