import { TimelineFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import ViewTimeline from 'mlp-client/src/timeline/components/views/ViewTimeline/ViewTimeline'
import {
  TimelineBanners,
  TimelineEventTypes,
} from 'mlp-client/src/timeline/enums'

import { CONTACT_US_PHONE_NUMBER } from './data'

export const timelineConfig: MyLeaseplanFeature<TimelineFeatureConfig> = {
  enabled: true,
  view: ViewTimeline,
  configuration: {
    banner: {
      visible: true,
      viewId: TimelineBanners.CONTACT_US,
      config: {
        phoneNumber: CONTACT_US_PHONE_NUMBER,
      },
    },
    cards: {
      [TimelineEventTypes.DAMAGE_REPORT]: {
        title: 'myLeaseplan.timeline.damageReport.items.title',
        description: 'myLeaseplan.timeline.damageReport.items.description',
      },
      [TimelineEventTypes.BOOKING]: {
        title: 'myLeaseplan.timeline.maintenance.items.title',
        linkToDetails: 'myLeaseplan.timeline.item.maintenanceBooking',
        bookingDateVisible: true,
        statusVisible: true,
      },
      [TimelineEventTypes.TYRE_CHANGE]: {
        statusVisible: true,
        title: 'myLeaseplan.timeline.tyreChangeCase.items.title',
        description: 'myLeaseplan.timeline.tyreChangeCase.items.description',
      },
      [TimelineEventTypes.EOL_VEHICLE_BUY]: {
        statusVisible: false,
        title: 'myLeaseplan.timeline.vehicleOrder.items.title',
        description: 'myLeaseplan.timeline.vehicleOrder.items.description',
      },
      [TimelineEventTypes.EOL_PICKUP]: {
        statusVisible: false,
        title: 'myLeaseplan.timeline.vehiclePickUp.title',
        description: 'myLeaseplan.timeline.vehiclePickUp.subtitle',
      },
      [TimelineEventTypes.UPDATE_MILEAGE]: {
        statusVisible: false,
        title: 'myLeaseplan.timeline.mileage.title',
        description: 'myLeaseplan.timeline.mileage.subtitle',
      },
      [TimelineEventTypes.FUEL_CARD]: {
        statusVisible: false,
        title: 'myLeaseplan.timeline.fuelCardCase.items.title',
        description: 'myLeaseplan.timeline.fuelCardCase.items.description',
      },
      [TimelineEventTypes.FUEL_CARD_PIN]: {
        statusVisible: false,
        title: 'myLeaseplan.timeline.fuelCardPinCase.items.title',
        description: 'myLeaseplan.timeline.fuelCardPinCase.items.description',
      },
      [TimelineEventTypes.CASH_CLAIM]: {
        title: 'myLeaseplan.timeline.cashClaim.items.title',
        bookingDateVisible: true,
        statusVisible: true,
      },
      [TimelineEventTypes.VEHICLE_DOCUMENTATION]: {
        statusVisible: false,
        title: 'myLeaseplan.timeline.vehicleDocumentationCase.items.title',
      },
      [TimelineEventTypes.EOL_CONTRACT_EXTENSION_CASE]: {
        statusVisible: true,
        title: 'myLeaseplan.timeline.EOLContractExtensionCase.title',
      },
    },
  },
}
