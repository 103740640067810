import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { LoadContent } from 'mlp-client/src/content/actions'
import { getContentInfo } from 'mlp-client/src/selectors'
import { Locales } from 'mlp-client/src/localization/enums'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { isContentLangUnmatchesLocal } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { ContentEndpoint } from 'mlp-client/src/flows/damage-report/types'

export interface Props {
  locale: Locales
  content: string
  language: string
  loadContent(endpoint: string, locale?: string): void
  children?: React.ReactNode
}

export class ContentWrapper extends React.PureComponent<Props> {
  componentDidMount() {
    const { content, loadContent, locale } = this.props

    if (!content) {
      loadContent(ContentEndpoint.STOLEN_VEHICLE, locale)
    }
  }

  componentDidUpdate() {
    const { content, loadContent, language, locale } = this.props

    if (content && isContentLangUnmatchesLocal(language, locale)) {
      loadContent(ContentEndpoint.STOLEN_VEHICLE, locale)
    }
  }

  render() {
    return this.props.children
  }
}

type ReduxProps = Pick<Props, 'content' | 'language' | 'locale'>
type DispatchProps = Pick<Props, 'loadContent'>

const mapStateToProps = (state: AppState): ReduxProps => {
  const { language, content } = getContentInfo(
    state,
    ContentEndpoint.STOLEN_VEHICLE,
  )

  return {
    language,
    content,
    locale: getUserLocale(state),
  }
}

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadContent: (endpoint: string, locale?: string) => {
    dispatch(new LoadContent({ endpoint, locale }))
  },
})

const ConnectedWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentWrapper)

export default <T extends {}>(
  ChildComponent: React.ComponentType<T>,
): React.FC<T> => props => (
  <ConnectedWrapper>
    <ChildComponent {...props} />
  </ConnectedWrapper>
)
