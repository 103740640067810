import { RouteComponentProps } from 'react-router'
import pathToRegexp from 'path-to-regexp'

import { XCoordinates } from 'mlp-client/src/types'

export const contains = (parent: XCoordinates, child: XCoordinates) =>
  parent.left <= child.left && parent.right >= child.right

export const expand = (rectangle: XCoordinates, pixels: number) => ({
  left: rectangle.left - pixels,
  right: rectangle.right + pixels,
})

export const getOverflowingBoundingRect = (
  element: Pick<
    Element,
    'getBoundingClientRect' | 'scrollWidth' | 'scrollHeight'
  >,
): XCoordinates => {
  const { left } = element.getBoundingClientRect()

  return {
    left,
    right: left + element.scrollWidth,
  }
}

export const buildUrlForLanguage = (
  { pathname, search }: RouteComponentProps['location'],
  country: string,
  language: string,
): string =>
  `${pathname}${search}`.replace(/(\w{2}-\w{2})/, `${language}-${country}`)

/**
 * Transforms an Express-style path into a valid path.
 * `applyPathParams('/foo/:bar', {bar: 'moo'})` will resolve: `foo/moo`
 */
export const applyRouteParams = (
  path: string = '',
  params: GenericObject = {},
): string => {
  let newParams = params

  /**
   * If there is a prefix, then we want to replace the `/`
   * i.e: `prefix` can be `my-company/fleet/driver`, so we need to
   * escape the slash `/`
   */
  if (params.prefix) {
    newParams = {
      ...params,
      prefix: params.prefix.replace(/\//g, '\\/'),
    }
  }

  // We compile the new path, and replace the escaped slashes `\`, if they exist
  return pathToRegexp.compile(path)(newParams, {
    encode: value => value.replace(/\\/g, ''),
  })
}
