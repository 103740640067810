import { ButtonLink, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getContentInfo } from 'mlp-client/src/selectors'
import { SitecoreContentSection } from 'mlp-client/src/content/types'
import withContent from 'mlp-client/src/flows/components/stolen-vehicle/withContent'
import { ContentPage } from 'mlp-client/src/flows/components/steps/content-page/ContentPage'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { AppState } from 'mlp-client/src/types'
import { ContentEndpoint } from 'mlp-client/src/flows/damage-report/types'

export type Props = Pick<SitecoreContentSection, 'content' | 'intro' | 'title'>

class StolenVehiclePage extends React.PureComponent<Props> {
  breadcrumbs = (title: string) => (
    <Spacing mb={3}>
      <Breadcrumbs>
        <ButtonLink
          to="myLeaseplan.dashboard"
          component={RouteLink}
          size="s"
          fontWeight="regular"
        >
          <Translation id="myLeaseplan.subNavigation.dashboard" />
        </ButtonLink>
        <ButtonLink
          to="myLeaseplan.damageReport.damageList"
          component={RouteLink}
          size="s"
          fontWeight="regular"
        >
          <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.pageTitle" />
        </ButtonLink>
        {title}
      </Breadcrumbs>
    </Spacing>
  )

  render() {
    const { title, content, intro } = this.props

    return (
      <ContentPage
        breadcrumbs={this.breadcrumbs(title)}
        content={content}
        intro={intro}
        title={title}
      />
    )
  }
}

type ReduxProps = Props

const mapStateToProps = (state: AppState): ReduxProps => {
  const { content, intro, title } = getContentInfo(
    state,
    ContentEndpoint.STOLEN_VEHICLE,
  )

  return {
    content,
    intro,
    title,
  }
}

export { StolenVehiclePage }
export default connect(mapStateToProps)(withContent(StolenVehiclePage))
