import moment from 'moment'

import { SiteCoreDocument } from 'mlp-client/src/content/types'
import { Options } from 'mlp-client/src/form/types'
import { mapAddress } from 'mlp-client/src/utils/address/address'

import {
  DownloadedDocs,
  FlowData,
  ReturnNote,
  VehicleDropoffCaseRequest,
  VehicleReturnCaseRequest,
} from './types'
import { ReturnMethods } from './enums'

export const times = [9, 10, 11, 12, 13, 14, 15, 16, 17]

export const mapTimes = (times: readonly number[]): Options =>
  times.map(time => ({
    title: `${time.toString().padStart(2, '0')}:00`,
    value: time.toString(),
  }))

export const timesAsOptions = mapTimes(times)

export const renderCurrentEndDate = (contractEndDate: string): string =>
  moment(contractEndDate).diff(moment()) > 0
    ? moment(contractEndDate).format('dddd, LL')
    : moment().format('dddd, LL')

export const isDropoff = (returnMethod: ReturnMethods) =>
  ReturnMethods.DROP_OFF === returnMethod

export const getOptionsWithSubtitle = (
  returnMethods: Options,
  subtitles: { [values in ReturnMethods]: string },
): Options =>
  returnMethods.map(returnMethod => ({
    ...returnMethod,
    subtitle: subtitles[returnMethod.value],
  }))

export const getSubmitReturnRequestBody = (
  flowData: FlowData,
): VehicleReturnCaseRequest => ({
  contractId: flowData.contractId,
  userId: flowData.user.id,
  userEmail: flowData.email?.replace(/\s+/g, ' ').trim(),
  address: mapAddress(flowData.address),
  returnTime: moment(flowData.returnDate)
    .hours(0)
    .add(flowData.returnTime ? flowData.returnTime : 0, 'hours')
    .format(),
  dayPeriod: flowData.returnDayPeriod,
  shippingAddress: flowData.shippingAddress,
  mileage: flowData.mileage,
  contactPersonName: flowData.name?.replace(/\s+/g, ' ').trim(),
  contactPersonPhoneNumber: `${flowData.phone.code}${flowData.phone.number}`,
  specialInstructions: flowData.message || '',
  specialAccessories: Boolean(flowData.extraDimensions),
  addressType: flowData.addressType,
})

export const getSubmitDropoffRequestBody = (
  flowData: FlowData,
): VehicleDropoffCaseRequest => ({
  returnDate: moment(flowData.returnDate).endOf('day').format(),
  userId: flowData.user.id,
  address: mapAddress(flowData.address),
  contractId: flowData.contractId,
})

export const getReturnNotes = (
  documents: readonly SiteCoreDocument[],
  docsToShow: DownloadedDocs,
): readonly ReturnNote[] => {
  const { fairwear, checklist, checkReturnList } = docsToShow
  const fairwearDocument = documents.find(doc => doc._Name === fairwear)
  const checklistDocument = documents.find(doc => doc._Name === checklist)

  return [
    {
      text: 'myLeaseplan.return.steps.returnVehicle.fearTear',
      linkText: 'myLeaseplan.return.steps.returnVehicle.fearTearLink',
      downloadUrl: fairwearDocument && fairwearDocument.DownloadUrl,
      linkDataE2eId: 'fearTearLink',
    },
    {
      text: 'myLeaseplan.return.steps.returnVehicle.returnList',
      linkText: 'myLeaseplan.return.steps.returnVehicle.returnListLink',
      downloadUrl: checkReturnList || checklistDocument?.DownloadUrl,
      linkDataE2eId: 'returnListLink',
    },
  ]
}
