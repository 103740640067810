import { SagaIterator } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import * as actions from 'mlp-client/src/flows/tyres/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { ApiSagas } from 'mlp-client/src/api'

import { getSubmitTyresRequestBody } from './utils'

const defaultRequestOptions = {
  throwError: true,
}

export function* submitTyresReport({
  payload: { flowData, onComplete },
}: actions.SubmitTyresRequest): SagaIterator {
  const user = yield select(getUser)
  const body = yield call(getSubmitTyresRequestBody, flowData, user)
  const contractId = yield select(getCurrentContractId)

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/tyres/report',
      { body: { ...body, contractId } },
      defaultRequestOptions,
    )
    yield call(onComplete, false)
  } catch {
    yield call(onComplete, true)
  }
}

export default [
  takeLatest(actions.ActionTypes.SUBMIT_TYRES_REQUEST, submitTyresReport),
]
