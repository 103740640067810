import { User } from 'mlp-client/src/user/types'
import {
  getFranceUserHomeAddress,
  getUserHomeAddress,
} from 'mlp-client/src/user/utils'
import { SubmitProfileFormTypes } from 'mlp-client/src/profile/types'
import { getAddressModel } from 'mlp-client/src/utils'

export const getInitialValues = (user: User): SubmitProfileFormTypes => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    phoneCountryCode,
    mobilePhoneCountryCode,
    mobilePhoneNumber,
    correspondenceLanguageCode,
  } = user
  const {
    addressLine1,
    houseNumber,
    houseNumberSuffix,
    city,
    country,
    zipCode,
  } =
    correspondenceLanguageCode === 'fr'
      ? getFranceUserHomeAddress(user.addresses) || getAddressModel({})
      : getUserHomeAddress(user.addresses) || getAddressModel({})

  return {
    firstName,
    lastName,
    email,
    phone: {
      number: phoneNumber,
      code: phoneCountryCode,
    },
    mobile: {
      number: mobilePhoneNumber,
      code: mobilePhoneCountryCode,
    },
    correspondenceLanguageCode,
    address: {
      addressLine1,
      houseNumber,
      houseNumberSuffix,
      zipCode,
      city,
      country,
    },
  }
}
