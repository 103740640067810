export enum Weekday {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const enum Origin {
  Web = 'MyLP Web',
  Mobile = 'MyLP App',
}

export enum LowerCasedYesNo {
  YES = 'yes',
  NO = 'no',
}

export enum YesNoShortcut {
  YES = 'Y',
  NO = 'N',
}

export enum DaysOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export enum DistanceUnit {
  KM = 'kilometer',
  MILE = 'mile',
}

export const brandWhitelist = [
  'MACIF C PARTI',
  'Loc-Action',
  'LEASEPLAN',
  'DCS',
  'DIAL',
]
