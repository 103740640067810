import React from 'react'

interface DefaultProps {
  className: string
  height: number
  modifier: string
  title: string
  width: number
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

export default class FilesIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: '',
    height: 60,
    modifier: '',
    title: 'Files',
    width: 60,
  }

  render() {
    const { className, modifier, width, height, title } = this
      .props as PropsWithDefaults

    return (
      <svg
        width={width}
        height={height}
        className={`${className} files-icon files-icon--${modifier}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 250 250"
      >
        <title>{title}</title>
        <g id="Small">
          <path
            className="files-icon--path"
            d="M124.55,45.84H51a2.5,2.5,0,0,0,0,5h73.52a2.5,2.5,0,0,0,0-5Z"
          />
          <path
            className="files-icon--path"
            d="M124.55,70.44H51a2.5,2.5,0,0,0,0,5h73.52a2.5,2.5,0,0,0,0-5Z"
          />
          <path
            className="files-icon--path"
            d="M124.55,95.05H51a2.5,2.5,0,1,0,0,5h73.52a2.5,2.5,0,1,0,0-5Z"
          />
          <path
            className="files-icon--path"
            d="M83.5,135.1H51a2.5,2.5,0,0,0,0,5H83.5a2.5,2.5,0,0,0,0-5Z"
          />
          <path
            className="files-icon--path"
            d="M208.27,91.74l-1.18-.4a88.64,88.64,0,0,0-10.51-18.75l0-.05a10.89,10.89,0,0,0-8-9.37,2.51,2.51,0,0,0-.31-.38A89.2,89.2,0,0,0,173.5,50.72l.19-1.94a11,11,0,0,0-9.79-12l-16-1.61a2.47,2.47,0,0,0-.75,0V26.57a11,11,0,0,0-10.94-10.94H40.66A11,11,0,0,0,29.72,26.57V162.9a11,11,0,0,0,10.94,10.94h2.48A10.94,10.94,0,0,0,53,184.66l7,.71A88.64,88.64,0,0,0,167.76,202.7a2.51,2.51,0,0,0,.72-.4A88.77,88.77,0,0,0,213.71,125a89.78,89.78,0,0,0-.9-12.65l2.29-6.73A11,11,0,0,0,208.27,91.74Zm2.1,12.27-27.51,80.86a2.48,2.48,0,0,0-.12.65,84.26,84.26,0,0,1-12.14,9.62L192.94,91.8l13.72,4.67A6,6,0,0,1,210.37,104ZM194,86.88l1.51-7a83.62,83.62,0,0,1,5.15,9.27Zm-14.95-25.8-6.46-1.4.33-3.32Q176.1,58.59,179.06,61.09ZM34.72,162.9V26.57a6,6,0,0,1,5.94-5.94h95.56a6,6,0,0,1,5.94,5.94V162.9a6,6,0,0,1-5.94,5.94H40.66A6,6,0,0,1,34.72,162.9Zm14.76,14.64a5.9,5.9,0,0,1-1.34-3.7h88.08a11,11,0,0,0,10.94-10.94V40.12l.25.05,16,1.61a6,6,0,0,1,5.32,6.51L155.08,183.93a6,6,0,0,1-6.51,5.32l-95.08-9.56A5.9,5.9,0,0,1,49.48,177.54Zm18.36,8.62,80.23,8.07a11.11,11.11,0,0,0,1.11.06,10.94,10.94,0,0,0,10.87-9.85l12-119.74,14.82,3.2A6,6,0,0,1,191.47,75l-26.74,123.7a83.65,83.65,0,0,1-96.88-12.51ZM208.71,125A83.27,83.27,0,0,1,191,176.35l17.66-51.92C208.7,124.62,208.71,124.81,208.71,125Z"
          />
        </g>
      </svg>
    )
  }
}
