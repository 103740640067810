import {
  Collapse,
  CollapseContent,
  CollapseTrigger,
  Grid,
  GridItem,
  List,
  ListItem,
  Paper,
  SimpleCheckMarkIcon,
  Spacing,
} from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { ContentWrapper } from 'mlp-client/src/contracts/components/ContractPropertiesTable.styled'
import { ContractOptionsListProperties } from 'mlp-client/src/contracts/enums'
import { Contract } from 'mlp-client/src/contracts/types'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import {
  ContractOptionsSectionOptions,
  ServicesConfig,
  FuelCard as FuelCardWithServiceNumbers,
  FuelCards as FuelCardsWithServiceNumbers,
} from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { LoadFuelCards } from 'mlp-client/src/flows/fuel-card/actions'
import {
  getFuelCards,
  getFuelCardsStatus,
} from 'mlp-client/src/flows/fuel-card/selectors'
import { FuelCard, FuelCards } from 'mlp-client/src/flows/fuel-card/types'
import { AppState } from 'mlp-client/src/types'
import {
  filterFuelCardsByServices,
  getContractOptionData,
  isSectionAllowedByService,
} from 'mlp-client/src/contracts/utils'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

import ServiceProduct from './ServiceProduct'

export interface Props {
  isFuelCardsLoaded: boolean
  fuelCards: FuelCards
  contract: Contract
  loadFuelCards(): void
  options: ContractOptionsSectionOptions
  servicesConfig?: ServicesConfig
}

const layoutColumnSpan = {
  mobile: 12,
  desktop: 6,
}

class ContractOptionsList extends React.Component<Props> {
  componentDidMount() {
    const { isFuelCardsLoaded, loadFuelCards, servicesConfig } = this.props

    if (!isFuelCardsLoaded && !servicesConfig?.fuelCards?.cardsList) {
      loadFuelCards()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isFuelCardsLoaded, loadFuelCards, servicesConfig } = this.props

    if (
      !isFuelCardsLoaded &&
      prevProps.isFuelCardsLoaded !== isFuelCardsLoaded &&
      !servicesConfig?.fuelCards?.cardsList
    ) {
      loadFuelCards()
    }
  }

  renderFuelCards = (
    fuelCards: FuelCards | FuelCardsWithServiceNumbers,
  ): React.ReactNode => {
    const {
      contract: { services },
      servicesConfig,
    } = this.props

    if (!fuelCards || !fuelCards.length) {
      return null
    }

    if (services && servicesConfig?.fuelCards) {
      const { additionalServices } = servicesConfig.fuelCards
      const showFuelCards = isSectionAllowedByService(
        services,
        servicesConfig,
        'fuelCards',
      )

      return (
        showFuelCards && (
          <GridItem span={layoutColumnSpan} data-e2e-id="fuelCard">
            <Headline variant="200" withMarginBottom>
              <Translation id="myLeaseplan.contract.contract.options.fuelCard" />
            </Headline>
            <List icon={SimpleCheckMarkIcon} iconSize="s">
              {(fuelCards as FuelCardsWithServiceNumbers).map(
                ({ name }: FuelCardWithServiceNumbers) => (
                  <ListItem key={`fuel-card-${name}`}>
                    <SecondaryDarkText bold variant="100" component="span">
                      {name}
                    </SecondaryDarkText>
                  </ListItem>
                ),
              )}
              {additionalServices &&
                additionalServices.map(({ translation }) => (
                  <ListItem key={`fuel-card-${translation}`}>
                    <SecondaryDarkText bold variant="100" component="span">
                      <Translation id={translation} />
                    </SecondaryDarkText>
                  </ListItem>
                ))}
            </List>
          </GridItem>
        )
      )
    }

    return (
      <GridItem span={layoutColumnSpan} data-e2e-id="fuelCard">
        <Headline variant="200" withMarginBottom>
          <Translation id="myLeaseplan.contract.contract.options.fuelCard" />
        </Headline>
        <List icon={SimpleCheckMarkIcon} iconSize="s">
          {(fuelCards as FuelCards).map(({ cardNumber, name }: FuelCard) => (
            <ListItem key={`fuel-card-${cardNumber}`}>
              <SecondaryDarkText bold variant="100" component="span">
                {name}
              </SecondaryDarkText>
              <Grid gutter={false}>
                <GridItem span="grow">
                  <Text variant="100" component="span">
                    <Translation id="myLeaseplan.contract.services.fuelCard.cardNumber" />
                  </Text>
                </GridItem>
                <GridItem span="grow">
                  <SecondaryDarkText variant="100" bold component="span">
                    {cardNumber}
                  </SecondaryDarkText>
                </GridItem>
              </Grid>
            </ListItem>
          ))}
        </List>
      </GridItem>
    )
  }

  renderServiceProduct = (
    serviceKey: ContractOptionsListProperties,
  ): React.ReactNode => {
    const { contract, options, servicesConfig } = this.props

    const serviceData = getContractOptionData(
      contract,
      options[serviceKey],
      serviceKey,
      servicesConfig,
    )

    if (!serviceData?.properties.length && !servicesConfig) {
      return null
    }

    if (servicesConfig && contract.services) {
      const showServiceSection = isSectionAllowedByService(
        contract.services,
        servicesConfig,
        serviceKey,
      )

      if (!showServiceSection) {
        return null
      }

      if (!serviceData?.properties.length && options[serviceKey].length > 0) {
        return null
      }
    }

    return (
      <ServiceProduct
        key={serviceKey}
        serviceKey={serviceKey}
        serviceData={serviceData}
      />
    )
  }

  render() {
    const {
      options,
      fuelCards,
      servicesConfig,
      contract: { services: contractServices },
    } = this.props
    const services = Object.keys(options)
      .map(this.renderServiceProduct)
      .filter(Boolean)
    let fuelCardsWithServiceNumbers: FuelCardsWithServiceNumbers

    if (contractServices && servicesConfig?.fuelCards) {
      const { cardsList } = servicesConfig.fuelCards

      fuelCardsWithServiceNumbers = cardsList.filter(
        filterFuelCardsByServices(contractServices),
      )
    }

    if (!services.length && !fuelCards.length) {
      return null
    }

    if (
      servicesConfig &&
      !services.length &&
      !fuelCardsWithServiceNumbers?.length
    ) {
      return (
        <Spacing mb={2}>
          <Paper>
            <ContentWrapper>
              <Headline variant="200" withMarginBottom>
                <Translation id="myLeaseplan.contract.additionalServices.noIncluded.title" />
              </Headline>
            </ContentWrapper>
          </Paper>
        </Spacing>
      )
    }

    return (
      <Spacing mb={2}>
        <Paper>
          <ContentWrapper>
            <Collapse defaultOpen={true}>
              <CollapseTrigger data-e2e-id="includedContract">
                <Headline variant="200" withMarginBottom>
                  <Translation id="myLeaseplan.contract.specifications.includedInContract" />
                </Headline>
              </CollapseTrigger>
              <CollapseContent>
                <Spacing mv={1}>
                  <Grid gutter={false}>
                    {services.length > 0 && (
                      <GridItem
                        span={layoutColumnSpan}
                        data-e2e-id="serviceProducts"
                      >
                        {services}
                      </GridItem>
                    )}
                    {this.renderFuelCards(
                      fuelCardsWithServiceNumbers
                        ? fuelCardsWithServiceNumbers
                        : fuelCards,
                    )}
                  </Grid>
                </Spacing>
              </CollapseContent>
            </Collapse>
          </ContentWrapper>
        </Paper>
      </Spacing>
    )
  }
}

type ReduxProps = Pick<Props, 'isFuelCardsLoaded' | 'fuelCards' | 'contract'>
type DispatchProps = Pick<Props, 'loadFuelCards'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  isFuelCardsLoaded: getFuelCardsStatus(state).isLoaded,
  fuelCards: getFuelCards(state),
  contract: getCurrentContract(state),
})

const mapDispatchToProps: DispatchProps = {
  loadFuelCards: () => new LoadFuelCards(),
}

export { ContractOptionsList }
export default connect(mapStateToProps, mapDispatchToProps)(ContractOptionsList)
