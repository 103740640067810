import { DefaultIconProps } from 'mlp-client/src/dashboard/type'

export const defaultIconProps: DefaultIconProps = {
  width: '64',
  height: '64',
  color: 'midOrange',
}

export const defaultLargeIconProps: DefaultIconProps = {
  width: '251',
  height: '251',
  color: 'midOrange',
}
