import React from 'react'
import moment from 'moment'
import { ErrorIcon, IconText, Spacing } from '@leaseplan/ui'
import { Form, FormRenderProps } from 'react-final-form'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import debounce from 'lodash/debounce'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import SelectDateAndLocationForm, {
  FormValues,
} from 'mlp-client/src/flows/maintenance/components/steps/select-date-and-location-step/SelectDateAndLocationForm'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { FlowData } from 'mlp-client/src/flows/maintenance/types'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { Options } from 'mlp-client/src/form/types'
import {
  getHolidaysDateList,
  supplierAvailabilityDateSelector,
} from 'mlp-client/src/selectors'
import { Address } from 'mlp-client/src/user/types'
import { AppState, BookingRequestType, Supplier } from 'mlp-client/src/types'
import {
  getNearbySuppliersSlots,
  getNearbySuppliersStatus,
  getNoSuppliersByMalfunctions,
} from 'mlp-client/src/contracts/selectors'
import {
  LoadNearbySuppliers,
  ResetNearbySuppliers,
} from 'mlp-client/src/contracts/actions'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import SuppliersNotFound from 'mlp-client/src/flows/components/suppliers-not-found/SuppliersNotFound'

export interface StepConfig {
  cities: Options
  enableWeekend: boolean
  timeSlots: readonly number[]
  hideCountry: boolean
  hideHouseNumber: boolean
}

interface RequestData {
  date: moment.Moment
  location: Address
}

export interface Props extends StepViewProps<StepConfig, FlowData> {
  nearbySupplier: Supplier
  nearbySuppliersStatus: string
  startDate: Date
  fetchSuppliers(
    requestData: RequestData,
    onComplete: (isError: boolean) => void,
    prevalidate: boolean,
  ): void
  noSuppliersByMalfunctions: boolean
  resetSuppliers: () => void
}

interface State {
  isLoading: boolean
  isSuppliersFetchError: boolean
  isSuppliersCompleted: boolean
}

class SelectDateAndLocationStep extends React.PureComponent<Props, State> {
  state: State = {
    isLoading: false,
    isSuppliersFetchError: false,
    isSuppliersCompleted: false,
  }

  componentWillUnmount() {
    this.props.resetSuppliers()
  }

  onSuppliersLoaded = (isError: boolean) => {
    if (isError) {
      this.setState({ isSuppliersFetchError: true })
    }

    this.setState({ isLoading: false, isSuppliersCompleted: true })
  }

  loadSuppliers = (values: FormValues, prevalidate: boolean) => {
    const { fetchSuppliers, setFlowData, resetSuppliers } = this.props
    const { date, time, zipCode, street: addressLine1, city } = values

    resetSuppliers()

    this.setState({ isLoading: true })
    fetchSuppliers(
      {
        date: moment(new Date(date)),
        location: {
          city,
          addressLine1,
          zipCode,
        },
      },
      this.onSuppliersLoaded,
      prevalidate,
    )
    setFlowData({ date, time, address: { addressLine1, zipCode, city } })
  }

  loadSuppliersDebounced = debounce(this.loadSuppliers, 500)

  submitHandler = (values: FormValues) => {
    const { setFlowData, goToNextStep } = this.props
    const flowData = this.getDataToProceedFlow(values)

    setFlowData(flowData, goToNextStep)
  }

  getDataToProceedFlow = (values: FormValues) => {
    const { id, name } = this.props.nearbySupplier

    return {
      date: values.date,
      time: values.time,
      address: {
        addressLine1: values.street,
        zipCode: values.zipCode,
        city: values.city,
        country: values.country,
        houseNumber: values.houseNumber,
      },
      supplier: { id, name },
      hasCollectAddress: true,
    }
  }

  renderForm = ({
    handleSubmit,
    hasValidationErrors,
    values,
  }: FormRenderProps): React.ReactElement => {
    const {
      config: {
        cities,
        timeSlots,
        enableWeekend,
        hideCountry,
        hideHouseNumber,
      },
      nearbySupplier,
      nearbySuppliersStatus,
      startDate,
    } = this.props
    const { isLoading, isSuppliersCompleted } = this.state
    const hasSupplier = !(
      nearbySuppliersStatus === 'failed' ||
      (!nearbySupplier && nearbySuppliersStatus === 'success')
    )
    const notBefore = {
      date: startDate,
      message:
        'myLeaseplan.newSetup.shared.forms.date.validations.dateNotInPast',
    }

    return (
      <MyLeaseplanContext.Consumer>
        {({ showSupplierNotFound }) => (
          <SelectDateAndLocationForm
            onSubmit={handleSubmit}
            hasValidationErrors={hasValidationErrors}
            values={values as FormValues}
            notBefore={notBefore}
            hasSupplier={hasSupplier}
            placeholder="myLeaseplan.genericNotifications.datePlaceholder"
            cities={cities}
            isLoading={isLoading}
            isSuppliersCompleted={isSuppliersCompleted}
            timeSlots={timeSlots}
            fetchSuppliers={this.loadSuppliersDebounced}
            enableWeekend={enableWeekend}
            hideCountry={hideCountry}
            hideHouseNumber={hideHouseNumber}
            prevalidate={showSupplierNotFound}
          />
        )}
      </MyLeaseplanContext.Consumer>
    )
  }

  render() {
    const {
      startDate,
      config: { timeSlots },
      flowData: { address, date, time, country },
    } = this.props
    const initialValues: FormValues = {
      country,
      date: date || startDate.toString(),
      time: time || String(timeSlots[0]),
      street: address?.addressLine1,
      zipCode: address?.zipCode,
      city: address?.city,
    }

    return (
      <StepLayout isLoading={this.state.isLoading}>
        <MyLeaseplanContext.Consumer>
          {context =>
            this.props.noSuppliersByMalfunctions &&
            context.showSupplierNotFound ? (
              <TwoColumnFlowStep
                titleLabel="myLeaseplan.maintenance.steps.selectDateAndLocation.title"
                mainSpan="grow"
                mainMaxWidth={null}
              >
                <SuppliersNotFound
                  onClick={this.props.closeFlow}
                  noSuppliersByMalfunctions
                />
              </TwoColumnFlowStep>
            ) : (
              <TwoColumnFlowStep titleLabel="myLeaseplan.maintenance.steps.selectDateAndLocation.title">
                <Form
                  keepDirtyOnReinitialize
                  onSubmit={this.submitHandler}
                  initialValues={initialValues}
                  render={this.renderForm}
                />
                {this.state.isSuppliersFetchError && (
                  <Spacing mt={1} pt={0.5}>
                    <IconText
                      iconSize="m"
                      icon={ErrorIcon}
                      color="error"
                      size="s"
                    >
                      <Translation id="myLeaseplan.glassRepair.page.errorLoadingSuppliers" />
                    </IconText>
                  </Spacing>
                )}
              </TwoColumnFlowStep>
            )
          }
        </MyLeaseplanContext.Consumer>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'nearbySupplier'
  | 'nearbySuppliersStatus'
  | 'startDate'
  | 'noSuppliersByMalfunctions'
>
type DispatchProps = Pick<Props, 'fetchSuppliers' | 'resetSuppliers'>
type OwnProps = Omit<Props, keyof ReduxProps>

const mapStateToProps = (state: AppState): ReduxProps => {
  const nearbySuppliers = getNearbySuppliersSlots(state)

  return {
    nearbySupplier: nearbySuppliers && nearbySuppliers[0],
    nearbySuppliersStatus: getNearbySuppliersStatus(state),
    startDate: supplierAvailabilityDateSelector(
      state,
      getHolidaysDateList(state),
    ),
    noSuppliersByMalfunctions: getNoSuppliersByMalfunctions(state),
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps => ({
  fetchSuppliers: (
    requestData,
    onComplete: (isError: boolean) => void,
    prevalidate,
  ) =>
    dispatch(
      new LoadNearbySuppliers({
        requestData: {
          ...requestData,
          bookingType: BookingRequestType.SERVICE,
          additionalServices: ownProps.flowData.additionalServices,
        },
        onComplete,
        prevalidate,
      }),
    ),
  resetSuppliers: () => {
    dispatch(new ResetNearbySuppliers())
  },
})

export { SelectDateAndLocationStep }
export default connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SelectDateAndLocationStep)
