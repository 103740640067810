import Classnames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'
import { connect } from 'react-redux'

import { scroller } from 'mlp-client/src/utils'
import Translation from 'mlp-client/src/localization/Translation'
import { DashboardNavigation } from 'mlp-client/src/analytics/actions'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'

interface TabItemProps {
  'data-tab-label': string
  'data-tab-id'?: string
}

type ItemElement = React.ReactElement<TabItemProps>

export interface Props {
  name?: string
  scroll?: boolean
  centered?: boolean
  /**
   * @name        collapseOnMobile
   * @default     true
   * @description Set to false to prevent tabs collapsing to <select> on smaller screens.
   *              By default, tabs will render as <select> on mobile devices.
   */
  collapseOnMobile?: boolean
  defaultSelected?: number
  className?: string
  onSelect?(selected: number, tabId?: string): void
  tabContainerClassName?: string
  thickUnderline?: boolean
  children: readonly ItemElement[]
  trackDashboardNavigation?(eventLabel: string): void
}

interface State {
  selected: number
}

type DefaultProps = Required<
  Pick<
    Props,
    'name' | 'scroll' | 'centered' | 'collapseOnMobile' | 'defaultSelected'
  >
>
type InternalProps = Props & DefaultProps

class Tabs extends React.PureComponent<InternalProps, State> {
  static defaultProps: DefaultProps = {
    name: 'tabs',
    scroll: true,
    centered: false,
    collapseOnMobile: true,
    defaultSelected: 0,
  }

  state: State = { selected: this.props.defaultSelected }

  componentDidMount() {
    this.select(this.state.selected, false)
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const { selected } = this.state

    if (selected !== prevState.selected) {
      const children = React.Children.toArray(
        this.props.children,
      ) as ItemElement[]
      const tabId = children[selected].props['data-tab-id']

      this.props.onSelect?.(selected, tabId)
    }
  }

  select = (selected: number, scroll: boolean = false) => {
    this.setState({ selected })

    if (this.props.scroll && scroll) {
      scroller.scrollTo(this.props.name, { smooth: true })
    }
  }

  handleSelectClick = (
    index: number,
    scroll: boolean,
    tabLabel: string,
  ) => () => {
    this.select(index, scroll)
    this.props.trackDashboardNavigation(tabLabel)
  }

  onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(e.target.value, 10)

    if (!isNaN(index)) {
      this.select(index, true)
    }
  }

  renderTabContent() {
    const children = React.Children.toArray(this.props.children)

    return children[this.state.selected]
  }

  renderTab = (tab: ItemElement, index: number) => {
    const { 'data-tab-label': tabLabel } = tab.props
    const isSelected = index === this.state.selected
    const liClasses = Classnames('tab-menu__item u-margin-right', {
      'tab-menu__item--selected': isSelected,
      'tab-menu__item--selected-thick': isSelected && this.props.thickUnderline,
      'u-margin-left': this.props.centered,
    })

    const buttonClasses = Classnames('tab-menu__button', {
      'tab-menu__button--centered': this.props.centered,
    })

    return (
      <li key={index} className={liClasses}>
        <LocalizationContext.Consumer>
          {({ translate }) => (
            <button
              className={buttonClasses}
              onClick={this.handleSelectClick(index, true, translate(tabLabel))}
              data-e2e-id={tab.props['data-e2e-id']}
            >
              <Translation id={tabLabel} className="tab-menu__button-label" />
            </button>
          )}
        </LocalizationContext.Consumer>
      </li>
    )
  }

  renderOption = (tab: ItemElement, i: number) => (
    <Translation
      id={tab.props['data-tab-label']}
      key={i}
      value={i}
      element="option"
    />
  )

  render() {
    const children = React.Children.toArray(this.props.children)
      // We want to filter out the null in the children:
      // Sometimes when a direct child is conditional and NOT rendered, a null appears in the children array.
      .filter(child => !!child) as ItemElement[]

    const tabsClassName = Classnames('tab-menu', {
      'u-hide-until@tablet': this.props.collapseOnMobile,
    })

    return (
      <Element name={this.props.name} className={this.props.className}>
        <ol className={tabsClassName}>{children.map(this.renderTab)}</ol>
        {this.props.collapseOnMobile && (
          <div className="field u-hide-from@tablet">
            <div className="field-select field-select--unboxed field-select--tab-menu">
              <select
                onChange={this.onChange}
                className="field-select__select"
                value={this.state.selected}
              >
                {children.map(this.renderOption)}
              </select>
            </div>
          </div>
        )}
        <div className={this.props.tabContainerClassName}>
          {this.renderTabContent()}
        </div>
      </Element>
    )
  }
}

export const TabItem = styled.div``

type DispatchProps = Pick<Props, 'trackDashboardNavigation'>

export const mapDispatchToProps: DispatchProps = {
  trackDashboardNavigation: (eventLabel: string) =>
    new DashboardNavigation({ eventLabel }),
}

export { Tabs }
export default connect(null, mapDispatchToProps)(Tabs)
