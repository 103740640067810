import * as React from 'react'
import styled from 'styled-components'

import { buildInfo } from 'mlp-client/src/config/buildTimeEnv'

const rotatedRibbonHeight = 61

const ribbonWidth = 200
const ribbonHeight = 15
const topPosition = rotatedRibbonHeight / 2 - ribbonHeight
const leftPosition = rotatedRibbonHeight / 2 - ribbonWidth / 2

interface Props {
  label: string
  hoverText: string
  color: string
}

const CornerRibbonContentStyled = styled.div<{ color: string }>`
  position: relative;
  width: ${ribbonWidth}px;
  height: ${ribbonHeight}px;
  font-size: 10px;
  font-family: Arial, sans-serif;
  top: -0.5px;
  color: white;
  user-select: none;
  cursor: default;
  background-color: ${({ color }) => color};
`

const CornerRibbonStyled = styled.div`
  position: fixed;
  z-index: 1000;
  top: ${topPosition}px;
  left: ${leftPosition}px;
  transform: rotate(-45deg);
  transform-origin: bottom center;
  text-align: center;
`

const CornerRibbon: React.FunctionComponent<Props> = ({
  label,
  hoverText,
  color,
}) => (
  <CornerRibbonStyled title={hoverText}>
    <CornerRibbonContentStyled color={color}>{label}</CornerRibbonContentStyled>
  </CornerRibbonStyled>
)

const getLabelAndColor = () => {
  if (buildInfo.isProductionBuild) {
    if (window.__DD_ENV__.startsWith('preview_')) {
      return { label: 'Preview', color: 'purple' }
    }

    const labelsAndColors = {
      local: { label: 'Local', color: 'black' },
      test: { label: 'Test', color: '#117703' }, // Green
      acceptance: { label: 'Acceptance', color: 'blue' },
      prod: { label: 'Production', color: '#d9552b' }, // Just for completeness, since we don't show a label on prod.
    }

    return (
      labelsAndColors[window.__DD_ENV__] ?? { label: 'Unknown', color: 'grey' }
    )
  } else {
    return buildInfo.isDevServer
      ? { label: 'Dev-server', color: '#e81414' } // Red, because of hot reloading.
      : { label: 'Dev bundle', color: 'blue' }
  }
}

export const BuildInfoRibbon = () => {
  const { label, color } = getLabelAndColor()

  return window.__DD_ENV__ === 'prod' ? (
    <div />
  ) : (
    <CornerRibbon
      label={label}
      hoverText={`SHA: [${buildInfo.sha}]\nbuild time: ${buildInfo.buildTime}`}
      color={color}
    />
  )
}
