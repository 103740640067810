import * as actions from './actions'
import { Quotes, Quote } from './types'

export interface State {
  currentQuote: string
  loading: boolean
  isLoaded: boolean
  quotes: Quotes
  currentQuoteDetails: Quote
}

export const defaultState: State = {
  currentQuote: null,
  loading: false,
  isLoaded: false,
  quotes: [],
  currentQuoteDetails: null,
}

export const loadQuotes = (state: State) => ({
  ...state,
  loading: true,
})

export const loadQuotesSuccess = (
  state: State,
  action: actions.LoadQuotesSuccess,
) => ({
  ...state,
  quotes: action.payload.quotes,
  loading: false,
  isLoaded: true,
})

export const loadQuotesFailed = (state: State): State => ({
  ...state,
  quotes: defaultState.quotes,
  loading: false,
  isLoaded: false,
})

const setCurrentQuote = (state: State, id: string) => ({
  ...state,
  currentQuote: id,
})

export const getQuoteById = (state: State) => ({
  ...state,
  loading: true,
})

export const getQuoteByIdSuccess = (
  state: State,
  action: actions.GetQuoteByIdSuccess,
) => ({
  ...state,
  currentQuoteDetails: action.payload.quote,
  loading: false,
  isLoaded: true,
})

export const getQuoteByIdFailed = (state: State): State => ({
  ...state,
  loading: false,
  isLoaded: false,
})

export default (state = defaultState, action: actions.Action): State => {
  switch (action.type) {
    case actions.ActionTypes.LOAD_QUOTES:
      return loadQuotes(state)
    case actions.ActionTypes.LOAD_QUOTES_SUCCESS:
      return loadQuotesSuccess(state, action)
    case actions.ActionTypes.LOAD_QUOTES_FAILED:
      return loadQuotesFailed(state)
    case actions.ActionTypes.DEFINE_CURRENT_QUOTE:
      return setCurrentQuote(state, action.payload)
    case actions.ActionTypes.GET_QUOTE_BY_ID:
      return getQuoteById(state)
    case actions.ActionTypes.GET_QUOTE_BY_ID_SUCCESS:
      return getQuoteByIdSuccess(state, action)
    case actions.ActionTypes.GET_QUOTE_BY_ID_FAILED:
      return getQuoteByIdFailed(state)
    default:
      return state
  }
}
