import { combineReducers } from 'redux'

import * as actions from 'mlp-client/src/flows/glass-repair/actions'
import { DamageReport } from 'mlp-client/src/flows/glass-repair/types'
import { BookingResult } from 'mlp-client/src/types'

export interface State {
  damageReports: readonly DamageReport[]
  bookingResult?: BookingResult
}

export const defaultState: State = {
  damageReports: [],
}

export const glassRepairReducer = (
  state: State = defaultState,
  action: actions.Action,
) => {
  switch (action.type) {
    case actions.ActionTypes.GET_DAMAGE_REPORTS_SUCCESS:
      return { ...state, damageReports: action.payload.data }
    case actions.ActionTypes.SUBMIT_GLASS_REPAIR_SUCCESS:
      return { ...state, bookingResult: action.payload.result }
    case actions.ActionTypes.GLASS_REPAIR_REQUEST_CLEAR:
      return state.bookingResult
        ? { ...state, bookingResult: undefined }
        : state
    default:
      return state
  }
}

export default combineReducers({
  flow: glassRepairReducer,
})
