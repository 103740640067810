import { FuelHistory } from 'mlp-client/src/flows/fuel-history/types'

export enum ActionTypes {
  LOAD_FUEL_HISTORY = '@@myLeaseplan/fuelHistoryRequest/LOAD_FUEL_HISTORY',
  LOAD_FUEL_HISTORY_SUCCESS = '@@myLeaseplan/fuelHistoryRequest/LOAD_FUEL_HISTORY_SUCCESS',
  LOAD_FUEL_HISTORY_FAIL = '@@myLeaseplan/fuelHistoryRequest/LOAD_FUEL_HISTORY_FAIL',
}

export class LoadFuelHistory {
  readonly type = ActionTypes.LOAD_FUEL_HISTORY
}

export class LoadFuelHistorySuccess {
  readonly type = ActionTypes.LOAD_FUEL_HISTORY_SUCCESS

  constructor(public payload: { fuelHistory: FuelHistory }) {}
}

export class LoadFuelHistoryFail {
  readonly type = ActionTypes.LOAD_FUEL_HISTORY_FAIL
}

export type Action =
  | LoadFuelHistory
  | LoadFuelHistorySuccess
  | LoadFuelHistoryFail
