import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/timeline-details/actions'
import { ApiSagas } from 'mlp-client/src/api'
import { CancellationReasons } from 'mlp-client/src/flows/predictive-maintenance/types'

const defaultRequestOptions = {
  throwError: true,
}

export function* loadBooking(action: actions.LoadBooking): SagaIterator {
  const { id } = action.payload
  const requestUrl = `/myleaseplan/lpis/booking/${id}`

  try {
    const result = yield call(
      ApiSagas.get,
      requestUrl,
      {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
      defaultRequestOptions,
    )

    yield put(new actions.LoadBookingSuccess(result))
  } catch {
    yield put(new actions.LoadBookingFailed())
  }
}

export function* cancelBooking(action: actions.CancelBooking): SagaIterator {
  try {
    yield call(
      ApiSagas.delete,
      `/myleaseplan/lpis/deleteBooking`,
      {
        body: {
          caseId: action.payload.caseId,
          cancellationReason: CancellationReasons.other,
        },
      },
      defaultRequestOptions,
    )

    yield put(new actions.CancelBookingSuccess())
  } catch {
    yield put(new actions.CancelBookingFailed())
  }
}

export default [
  takeLatest(actions.ActionType.LOAD_BOOKING, loadBooking),
  takeLatest(actions.ActionType.CANCEL_BOOKING, cancelBooking),
]
