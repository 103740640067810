import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import * as actions from 'mlp-client/src/flows/damage-report/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { getLocale } from 'mlp-client/src/localization/selectors'
import { ApiSagas } from 'mlp-client/src/api'
import { mapDamageReportRequestBody } from 'mlp-client/src/flows/damage-report/utils'

export function* loadDamageNatureTypes(): SagaIterator {
  const locale = yield select(getLocale)
  const user = yield select(getUser)
  const contractId = yield select(getCurrentContractId)
  const requestUrl = '/myleaseplan/lpis/damage-report/damageNatureTypes'
  const options = {
    throwError: true,
  }

  try {
    const requestParams = {
      params: {
        locale,
        contractId,
        userId: user.id,
      },
    }

    const result = yield call(ApiSagas.get, requestUrl, requestParams, options)

    yield put(new actions.GetDamageNatureTypesSuccess({ data: result }))
  } catch {
    // API_ERROR already dispatched
  }
}

export function* submitDamageReport({
  payload: { flowData, onComplete },
}: actions.SubmitDamageReportRequest): SagaIterator {
  const user = yield select(getUser)
  const contractId = yield select(getCurrentContractId)
  const body = yield call(mapDamageReportRequestBody, contractId, flowData)
  const requestUrl = '/myleaseplan/lpis/damage-report/submitDamageReport'
  const options = {
    throwError: true,
  }

  try {
    const requestBody = { body: { ...body, UserId: user.id } }

    yield call(ApiSagas.post, requestUrl, requestBody, options)
    yield call(onComplete, false)
  } catch {
    yield call(onComplete, true)
  }
}

export default [
  takeLatest(
    actions.ActionTypes.GET_DAMAGE_NATURE_TYPES_REQUEST,
    loadDamageNatureTypes,
  ),
  takeLatest(
    actions.ActionTypes.SUBMIT_DAMAGE_REPORT_REQUEST,
    submitDamageReport,
  ),
]
