import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class GlassIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps
  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M36.6 31.6a1.5 1.5 0 0 0-1.8 1.2l-1 8-5.8-7a1.5 1.5 0 0 0-1.7-.5l-9.8 3.5 5-5.2a1.5 1.5 0 0 0-.3-2.4L12 23.7l12.5 2a1.5 1.5 0 0 0 1.7-1.4l.4-8.2 5.7 7.6a1.5 1.5 0 0 0 1.9.5l8-3.6-2.7 4.1a1.5 1.5 0 0 0-.2 1.1c0 .4.3.8.7 1L52.5 35l-16-3.5zM60.9 37l-18-11.9 4.8-7.1a1.5 1.5 0 1 0-1.9-2.2L34 21l-7.6-10a1.5 1.5 0 0 0-2.6.8l-.5 10.7-18.6-3a1.5 1.5 0 0 0-1 2.7l14.4 8.6-8.2 8.5a1.5 1.5 0 0 0 1.6 2.4l14.8-5.2 7.4 9a1.5 1.5 0 0 0 2.7-.8l1.2-9.9 22.1 5A1.5 1.5 0 0 0 61 37z"
        />
      </svg>
    )
  }
}

export default GlassIcon
