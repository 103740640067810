import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'

export interface Props {
  pageTitle?: string
  pageDescription?: string
  buttonLabel?: string
}

class ThankYou extends React.PureComponent<Props> {
  renderMainContent = (): React.ReactElement<{}> => {
    const { pageTitle, pageDescription, buttonLabel } = this.props

    return (
      <div data-e2e-id="thankYouFeedback">
        <Headline variant="400" withMarginBottom component="h1">
          <Translation id={pageTitle} />
        </Headline>
        <Text>
          <Translation id={pageDescription} />
        </Text>
        <Spacing mt={2}>
          <StyledButton
            component={RouteLink}
            to="myLeaseplan.dashboard"
            data-e2e-id="backToDashboard"
          >
            <Translation id={buttonLabel} />
          </StyledButton>
        </Spacing>
      </div>
    )
  }

  render() {
    const { pageTitle, pageDescription } = this.props

    return (
      <MyleaseplanPage metaTitle={pageTitle} metaDescription={pageDescription}>
        <GridLayout main={this.renderMainContent()} />
      </MyleaseplanPage>
    )
  }
}

export { ThankYou }
