import { FAQItemType } from 'mlp-client/src/FAQ/types'

export const enum ActionTypes {
  FETCH_FAQ = '@@myLeaseplan/faq/FETCH_FAQ',
  FETCH_FAQ_SUCCESS = '@@myLeaseplan/faq/FETCH_FAQ_SUCCESS',
}

export class FetchFAQ {
  readonly type = ActionTypes.FETCH_FAQ

  constructor(
    public payload: {
      faqKey: string
      locale: string
    },
  ) {}
}

export class FetchFAQSuccess {
  readonly type = ActionTypes.FETCH_FAQ_SUCCESS

  constructor(public payload: { [key: string]: readonly FAQItemType[] }) {}
}

export type Action = FetchFAQ | FetchFAQSuccess
