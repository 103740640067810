import { connectRouter, routerMiddleware } from 'connected-react-router'
import {
  Action,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Dispatch,
  Middleware,
} from 'redux'
import sagaMiddlewareFactory from 'redux-saga'
import persistStateSession from 'redux-sessionstorage'
import createReduxWaitForMiddleware from 'redux-wait-for-action'

import {
  isLoadedFromMobileApp,
  mobileIntegrationMiddleware,
} from './auth/mobile-utils'
import { merge, slicer } from './utils'
import analyticsMiddleware from './analytics/middleware'
import { AppState } from './types'
import { default as rootReducer } from './reducers'
import { default as rootSaga } from './sagas'

const isAnalyticsEnabled = (): boolean =>
  // Take ANALYTICS_DISABLED from the env object rather than directly from `window`
  window.__ANALYTICS_DISABLED__ !== 'true'

export default (serverState: any, history: any) => {
  const sagaMiddleware = sagaMiddlewareFactory()
  const asPlainObjectMiddleware: Middleware = () => (
    next: Dispatch<Action>,
  ) => (action: Action) => next({ ...action })

  const middlewares = [
    asPlainObjectMiddleware,
    routerMiddleware(history),
    sagaMiddleware,
  ]

  if (isAnalyticsEnabled()) {
    middlewares.push(analyticsMiddleware)
  }

  middlewares.push(createReduxWaitForMiddleware())

  if (isLoadedFromMobileApp()) {
    middlewares.push(mobileIntegrationMiddleware)
  }

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  const appliedMiddleware = applyMiddleware(...middlewares)
  const storageEnhancers: any[] = []

  // TODO: Explore more modern alternatives to 'redux-sessionstorage'.
  storageEnhancers.push(persistStateSession(null, { slicer, merge }))

  const enhancer = composeEnhancers(appliedMiddleware, ...storageEnhancers)
  const combinedReducers = combineReducers({
    ...rootReducer,
    router: connectRouter(history),
  })
  const store = createStore<AppState, Action, void, void>(
    combinedReducers,
    serverState,
    enhancer,
  )
  const saga = sagaMiddleware.run(rootSaga)

  return { store, saga }
}
