import { Card, Grid, GridItem, Paper, Spacing } from '@leaseplan/ui'
import { Text, Headline } from '@velocity/ui'
import { Pagination } from '@velocity/ui/draft'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { CenteredGridItem } from 'mlp-client/src/components/styled/CenteredGridItem'
import { Contract } from 'mlp-client/src/contracts/types'
import { getVehicleByContractId } from 'mlp-client/src/contracts/utils'
import { TimelineFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { SiteCoreDocument } from 'mlp-client/src/content/types'
import { FuelCard } from 'mlp-client/src/timeline/components/cards/FuelCard'
import TimelineCard from 'mlp-client/src/timeline/components/TimelineCard/TimelineCard'
import VehicleSelector from 'mlp-client/src/timeline/components/VehicleSelector/VehicleSelector'
import { TimelineEventTypes } from 'mlp-client/src/timeline/enums'
import {
  SelectedVehicles,
  TimelineItem,
  TimelineItemData,
  Pagination as TimelinePagination,
} from 'mlp-client/src/timeline/types'
import {
  getCardContent,
  getTimelineEventType,
} from 'mlp-client/src/timeline/utils'
import FilesIcon from 'mlp-client/src/components/icons/files-icon/FilesIcon'
import {
  ITEMS_PER_PAGE_OPTIONS,
  DEFAULT_NUMBER_OF_ITEMS_PER_PAGE,
} from 'mlp-client/src/constants'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'
import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'

export interface Props {
  timelineItems: readonly TimelineItem[]
  vehicleDocuments: readonly SiteCoreDocument[]
  contracts: readonly Contract[]
  showMultipleVehicles: boolean
  selectedVehicles: SelectedVehicles
  selectVehicle(contractId: string): void
  banner: React.ReactNode
  featureConfig: TimelineFeatureConfig
  isLoading: boolean
  pagination: TimelinePagination
  totalTimelineItems: number
  changeItemsPerPage(itemsPerPage: number): void
  changePage(page: number): void
  loadTimeline(): void
  isMobileApp?: boolean
}

const FullWidth = styled.div`
  width: 100%;
`

export class Timeline extends React.PureComponent<Props> {
  getVehicleDocumentByFriendlyName = (friendlyName: string): SiteCoreDocument =>
    this.props.vehicleDocuments.find(
      (vehicleDocument: SiteCoreDocument) =>
        vehicleDocument.DocumentType?.FriendlyName === friendlyName,
    )

  getCard(item: TimelineItem) {
    const { featureConfig } = this.props
    const eventType = getTimelineEventType(item)
    const config = featureConfig.cards[eventType]
    const data = this.getData(item)
    const content = getCardContent(data)

    switch (eventType) {
      case TimelineEventTypes.FUEL_CARD:
      case TimelineEventTypes.FUEL_CARD_PIN:
      case TimelineEventTypes.FUEL_CARD_LOST_STOLEN:
        // FuelCard is different than the other cards
        // it Needs: Icon, replace param in title and description with cardID.
        return <FuelCard config={config} content={content} />

      case TimelineEventTypes.VEHICLE_DOCUMENTATION:
        if (content.eventDetails?.documentType) {
          const vehicleDocument = this.getVehicleDocumentByFriendlyName(
            content.eventDetails.documentType,
          )

          return (
            <TimelineCard
              config={config}
              content={{
                ...content,
                vehicleDocument,
              }}
            />
          )
        }

        break

      default:
        return <TimelineCard config={config} content={content} />
    }
  }

  getData = (item: TimelineItem): TimelineItemData => {
    const { contracts, showMultipleVehicles } = this.props
    const {
      caseId,
      contractId,
      modifiedDate,
      type,
      status,
      processPhase,
      eventDetails,
      childCases,
      createdDate,
    } = item
    const vehicle =
      showMultipleVehicles && contractId
        ? getVehicleByContractId(contracts, contractId)
        : undefined

    return {
      caseId,
      modifiedDate,
      type,
      status,
      processPhase,
      eventDetails,
      vehicle,
      showLeftInfo: showMultipleVehicles,
      childCases,
      createdDate,
    }
  }

  loadNextPage = () => {
    this.props.changePage(this.props.pagination.page + 1)
  }

  loadPreviousPage = () => {
    this.props.changePage(this.props.pagination.page - 1)
  }

  getPageCount = () =>
    Math.ceil(
      this.props.totalTimelineItems / this.props.pagination.itemsPerPage,
    )

  changePage = (page: number) => {
    this.props.changePage(page - 1)
    window.scrollTo(0, 0)
    this.props.loadTimeline()
  }

  changeItemsPerPage = (itemsPerPage: number) => {
    this.props.changeItemsPerPage(itemsPerPage)
    localStorage.setItem('itemsPerPage', itemsPerPage.toString())
    this.props.loadTimeline()
  }

  showPagination = () =>
    !this.props.isLoading &&
    this.props.totalTimelineItems > DEFAULT_NUMBER_OF_ITEMS_PER_PAGE

  renderLoadingCards = () => {
    const LoadingCard = ({ delay }: { delay: number }) => (
      <div
        className="animated fadeInUp"
        style={{ animationDelay: `${delay}s` }}
      >
        <Card>
          <div className="u-padding-large" />
        </Card>
      </div>
    )

    return [0, 0.05, 0.1].map(i => <LoadingCard key={`l${i}`} delay={i} />)
  }

  renderTimelineCards = () =>
    this.props.timelineItems.map((item: TimelineItem, i: number) => {
      const delay = i * 0.05
      const animationDelay = `${delay < 0.5 ? delay : 0.5}s`

      return (
        <div
          key={item.caseId}
          className="animated fadeInUp"
          style={{ animationDelay }}
          data-e2e-id={getTimelineEventType(item)}
        >
          {this.getCard(item)}
        </div>
      )
    })

  renderTimelineView = () => {
    const {
      isLoading,
      pagination: { page, itemsPerPage },
      totalTimelineItems,
    } = this.props

    return (
      <Grid alignItems="stretch">
        <GridItem span={12}>
          {!isLoading ? this.renderTimelineCards() : this.renderLoadingCards()}
        </GridItem>
        {this.showPagination() && (
          <GridItem span={12}>
            <Pagination
              onChangeItemsPerPage={this.changeItemsPerPage}
              onLoadNextPage={this.loadNextPage}
              onLoadPreviousPage={this.loadNextPage}
              onLoadPage={this.changePage}
              count={totalTimelineItems}
              currentPage={page + 1}
              itemsPerPage={itemsPerPage}
              pageCount={this.getPageCount()}
              pageSizeOptions={ITEMS_PER_PAGE_OPTIONS}
            />
          </GridItem>
        )}
      </Grid>
    )
  }

  renderEmptyTimeline = () => (
    <Grid direction="column">
      <GridItem span={{ mobile: 12, tablet: 12, desktop: 10 }}>
        <Paper
          border={true}
          borderColor="steel20"
          elevation={0}
          backgroundColor="mist"
          stretch={true}
        >
          <Grid justifyContent="center" alignItems="center">
            <CenteredGridItem span={{ mobile: 12, tablet: 12, desktop: 10 }}>
              <Spacing m={{ mobile: 2, tablet: 4, desktop: 8 }}>
                <FilesIcon modifier="steel-20" width={128} height={134} />
                <Headline variant="300" component="h4" withMarginBottom>
                  <Translation id="myLeaseplan.timeline.base.emptyHeader" />
                </Headline>
                <Text>
                  <Translation id="myLeaseplan.timeline.base.emptyText" />
                </Text>
                <Spacing mt={1}>
                  <StyledButton
                    component={RouteLink}
                    to="myLeaseplan.dashboard"
                    onClick={() =>
                      this.props.isMobileApp &&
                      postMessage({ type: PostMessageType.DashboardNavigation })
                    }
                  >
                    <Translation id="myLeaseplan.timeline.base.dashboardLink" />
                  </StyledButton>
                </Spacing>
              </Spacing>
            </CenteredGridItem>
          </Grid>
        </Paper>
      </GridItem>
    </Grid>
  )

  render() {
    const {
      isLoading,
      timelineItems,
      showMultipleVehicles,
      selectedVehicles,
      selectVehicle,
      banner,
    } = this.props

    return (
      <>
        <Spacing
          mt={{
            mobile: 0.5,
            lap: 2,
          }}
          mb={{
            mobile: 2,
            lap: 3,
          }}
          component={FullWidth}
        >
          {banner}
        </Spacing>
        <Grid>
          {showMultipleVehicles && (
            <GridItem span={{ mobile: 12, lap: 3 }}>
              <VehicleSelector
                selectedVehicles={selectedVehicles}
                onChange={selectVehicle}
              />
            </GridItem>
          )}
          <GridItem span={showMultipleVehicles ? { mobile: 12, lap: 9 } : 12}>
            {!isLoading && !timelineItems.length
              ? this.renderEmptyTimeline()
              : this.renderTimelineView()}
          </GridItem>
        </Grid>
      </>
    )
  }
}
