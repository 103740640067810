import { serverEnv } from 'mlp-server/src/config/serverEnv'

import {
  FlowData,
  FuelcardServiceType,
  StepKey,
} from 'mlp-client/src/flows/fuel-card/types'
import {
  CashClaimCategory,
  StepNames,
} from 'mlp-client/src/flows/cash-claim/enums'

export const getFuelCardsSteps = (flowData: FlowData): readonly StepKey[] => {
  const { fuelCardServiceType, hasWorkAddress } = flowData

  if (fuelCardServiceType === FuelcardServiceType.NEW) {
    return [StepKey.SELECT_SERVICE_TYPE, StepKey.FLEET_MANAGER]
  }

  if (!hasWorkAddress) {
    return [StepKey.FLEET_MANAGER]
  }

  return [
    StepKey.SELECT_SERVICE_TYPE,
    StepKey.SELECT_FUEL_CARD_TYPES,
    StepKey.CHECK_DETAILS,
    StepKey.THANK_YOU,
  ]
}

export const getPreviewPreatures = (): string[] =>
  (serverEnv.PREVIEW_FEATURES || window.__PREVIEW_FEATURES__ || '')
    .split(',')
    .map((entry: string) => entry && entry.trim())

export const isPreviewFeatureEnabled = (featureName: string): boolean =>
  !getPreviewPreatures().includes(featureName)

export const getSubCategoryStep = (category: string): StepNames | undefined => {
  switch (category) {
    case CashClaimCategory.ROAD_SIDE_ASSISTANCE:
      return StepNames.ROAD_SIDE_ASSISTANCE
    case CashClaimCategory.LIQUIDS:
      return StepNames.LIQUIDS
  }
}
