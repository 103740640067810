import React, { useState } from 'react'
import { Card, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Text, Headline, useVelocityTheme } from '@velocity/ui'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { Ribbon } from 'mlp-client/src/form/components/fields/RadioFields/supplier-card-radio-list/ribbon/Ribbon'
import { Option } from 'mlp-client/src/form/types'
import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'

const StyledWrapper = styled.div`
  position: relative;
`

export interface Props {
  option: Option
  isHighlighted?: boolean
  onHover(value: Option['value']): () => void
}

export const CardInput: React.FC<Props> = props => {
  const { option, isHighlighted, onHover } = props
  const [isHover, setIsHover] = useState(false)
  const theme = useVelocityTheme()
  const hover = !option.disabled && (isHighlighted || isHover)
  const color = hover ? 'midOrange' : 'petrolBlue'

  return (
    <Card border={true} stretch={true} hover={true} hoverElevation={4}>
      <StyledWrapper
        onMouseEnter={() => {
          setIsHover(true)
          onHover(option.value)()
        }}
        onMouseLeave={() => {
          setIsHover(false)
        }}
      >
        {option.title || option.subtitle ? (
          <Spacing ph={2} pt={2} pb={1}>
            <Grid direction="row" alignItems="center">
              <GridItem span="grow">
                <Headline
                  variant="100"
                  component="h6"
                  style={{
                    color: hover
                      ? theme.styling.primary.main
                      : theme.styling.secondary.dark.default,
                  }}
                  withMarginBottom
                >
                  <Translation id={option.title} />
                </Headline>
                {option.subtitle && (
                  <Spacing mt={0.5}>
                    <Text variant="100">
                      <Translation id={option.subtitle} />
                    </Text>
                  </Spacing>
                )}
              </GridItem>
              <GridItem>
                <ChevronRightIcon color={color} />
              </GridItem>
            </Grid>
          </Spacing>
        ) : null}
        {option.ribbon && <Ribbon backgroundColor={color} {...option.ribbon} />}
      </StyledWrapper>
    </Card>
  )
}
