import { Card, CardContent, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { FormatDate } from 'mlp-client/src/localization/FormatDate'
import { DamageRecordStatus } from 'mlp-client/src/contracts/enums'
import { DamageRecord } from 'mlp-client/src/contracts/types'
import { CardTag } from 'mlp-client/src/components/card-tag/CardTag'

export const DamageCard: React.FC<DamageRecord> = ({
  date,
  description,
  number,
  status,
}) => (
  <Spacing mb={0.5}>
    <Card>
      <CardContent>
        <Grid>
          <GridItem span="grow">
            <Headline variant="100" withMarginBottom>
              {description}
            </Headline>
            <Text variant="200">
              <FormatDate date={date} dateFormat="LL" />
            </Text>
            <Text variant="200">
              <Translation
                id="myLeaseplan.damageReport.steps.vehicleDamages.number"
                replace={{ number }}
              />
            </Text>
          </GridItem>
          <GridItem>
            {status === DamageRecordStatus.CLOSED && (
              <CardTag
                dataE2eId={`cardTag-${status}Status`}
                backgroundColor="steel80"
              >
                <Translation id="myLeaseplan.damageReport.steps.vehicleDamages.archived" />
              </CardTag>
            )}
          </GridItem>
        </Grid>
      </CardContent>
    </Card>
  </Spacing>
)
