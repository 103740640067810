import { Options } from 'mlp-client/src/form/types'

export const getTitle = (
  value: string,
  options: Options,
): string | undefined => {
  const option = options.find(item => item.value === value)

  return option && option.title
}

export const getSubCategoryTitle = (
  subCategory: string,
  category: string,
  subCategories: { [key: string]: Options },
): string | undefined => {
  const options = subCategories[category]

  return options && getTitle(subCategory, options)
}
