import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import { FlowData } from 'mlp-client/src/flows/cash-claim/types'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export interface StepConfig {
  days: number
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

const ThankYouStep = ({ closeFlow, config: { days } }: Props) => (
  <ThankyouPageLayout
    metaTitle="myLeaseplan.newSetup.flows.cashClaim.steps.thankYou.title"
    onClick={closeFlow}
  >
    <Headline component="h1" variant="400" withMarginBottom>
      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.thankYou.title" />
    </Headline>
    <Section headerText="myLeaseplan.newSetup.flows.cashClaim.steps.thankYou.subTitle">
      <Translation
        key="viewOnTimeline"
        id="myLeaseplan.newSetup.flows.cashClaim.steps.thankYou.viewOnTimeline"
        replace={{
          timeline: () => (
            <RouteLink
              to="myLeaseplan.timeline.base"
              style={{ color: ColorEnumSelector('bloodOrange') }}
            >
              <Translation id="myLeaseplan.timeline.base.title" />
            </RouteLink>
          ),
        }}
      />
      <Translation
        key="reviewInfo"
        id="myLeaseplan.newSetup.flows.cashClaim.steps.thankYou.reviewInfo"
        replace={{ days }}
      />
    </Section>
  </ThankyouPageLayout>
)

export default ThankYouStep
