/**
 * MLP-specific copy of src/app/utils/Responsive.tsx
 *
 * Differences from the original:
 * - the implementation relies on mq utility functions from `@leaseplan/ui`
 */
import {
  BreakpointName,
  getBreakpointByWidth,
  isBreakpointBelow,
  isBreakpointAboveIncluding,
} from '@leaseplan/ui'

export const until = (referenceBreakpoint: BreakpointName): boolean =>
  isBreakpointBelow(
    referenceBreakpoint,
    getBreakpointByWidth(window.innerWidth),
  )

export const from = (referenceBreakpoint: BreakpointName): boolean =>
  isBreakpointAboveIncluding(
    referenceBreakpoint,
    getBreakpointByWidth(window.innerWidth),
  )
