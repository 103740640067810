import { Datum, RowsConfiguration } from './types'

export const groupByValues = (
  objectArray: readonly Datum[],
  property: string,
): RowsConfiguration =>
  objectArray.reduce((acc, obj) => {
    const key = obj[property]

    return {
      ...acc,
      [key]: acc[key] ? [...acc[key], obj] : [obj],
    }
  }, {})
