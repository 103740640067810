import React from 'react'
import { connect } from 'react-redux'

import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import TranslatedRedirect from 'mlp-client/src/localization//TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'
import { DamageReportFlowConfiguration } from 'mlp-client/src/flows/damage-report/types'
import { AppState } from 'mlp-client/src/types'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'

export interface Props
  extends MyLeaseplanFeatureProps<DamageReportFlowConfiguration> {
  contractId: string
}

class Flow extends React.PureComponent<Props> {
  static routePrefix = 'myLeaseplan.damageReport.steps.'
  static stepTitlePrefix = 'myLeaseplan.damageReport.'

  renderStep = (stepParameters: FlowProps) => {
    const { name } = stepParameters.activeStep
    const { configuration } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = configuration.allSteps[name]

    if (StepView) {
      return (
        <StepView
          {...stepParameters}
          config={{ ...stepConfig, ...configuration }}
        />
      )
    }

    return <NotFound />
  }

  onFlowClose = () => {
    const { match, contractId } = this.props

    return (
      <TranslatedRedirect
        to="myLeaseplan.dashboard"
        params={{ ...match.params, contractId }}
      />
    )
  }

  render() {
    const { featureConfig } = this.props

    return (
      <MyleaseplanFlow
        stepTitlePrefix={Flow.stepTitlePrefix}
        routePrefix={Flow.routePrefix}
        getSteps={featureConfig.configuration.getSteps}
        onClose={this.onFlowClose}
        render={this.renderStep}
        eventCategory={EventCategoryService.MAIN_SERVICE}
        flowName={EventCategoryFlow.DAMAGE_REPORT}
        initialFlowData={{
          thirdPartyInfo: {},
          europeanAccidentStatement: [],
        }}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'contractId'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contractId: getCurrentContractId(state),
})

export { Flow }
export default connect(mapStateToProps)(Flow)
