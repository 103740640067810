import React from 'react'
import ReactMarkdown, { Renderers } from 'react-markdown'

import {
  ItemRenderer,
  LinkRenderer,
  ListRenderer,
} from 'mlp-client/src/flows/components/markdown/renderers/standard'

interface Props {
  content: string
  renderers?: Renderers
}

export const Markdown: React.FC<Props> = ({ content, renderers = {} }) => (
  <ReactMarkdown
    source={content && content.split('\\n').join('\n')}
    transformLinkUri={url => url.replace('targetSelf:', '')}
    renderers={{
      Link: LinkRenderer,
      List: ListRenderer,
      Item: ItemRenderer,
      ...renderers,
    }}
  />
)
