import { GridItem, mq, Spacing } from '@leaseplan/ui'
import styled from 'styled-components'

export const SubmitButtonWrapper = styled(GridItem)`
  ${mq.from('tablet')`
    margin-top: 31px;
  `}
  ${mq.until('tablet')`
    padding-bottom: 12px;
  `}
`

export const OtherAddressWrapper = styled(Spacing)`
  background-color: white;
  box-shadow: 0 1px 4px 0 #ddd;
`
