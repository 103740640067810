import { FlowData } from './types'

export const enum ActionTypes {
  BUY_REQUEST = '@@myLeaseplan/buy/BUY_REQUEST',
}

export class SubmitBuyRequest {
  readonly type = ActionTypes.BUY_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(isError: boolean): void
    },
  ) {}
}
