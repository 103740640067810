import { match } from 'react-router'

import { Routes } from 'mlp-client/src/localization/types'
import { getLocalizedRouteFromRouteList } from 'mlp-client/src/localization/utils'
import { ItemPredicate, NavigationItem } from 'mlp-client/src/types'
import { isValidBrand } from 'mlp-client/src/utils/checks/checks'
import { formatBrand } from 'mlp-client/src/utils/formatters/formatters'

import { applyRouteParams } from './utils'

export const pageContentEndpoint = 'myleaseplan/page/index/dashboard' //todo
export const BRAND_LOGO = 'Logo' //'BrandLogo' //todo

// Returns the navigation-tree.
export const getItems = (
  navigationStructure: readonly NavigationItem[],
  isAuthenticated: boolean,
): readonly NavigationItem[] => {
  if (!isAuthenticated) {
    return []
  }

  // 'to' should NOT be empty.
  const filterEmpty = (item: NavigationItem) => item.to

  return navigationStructure.filter(filterEmpty)
}

const search = (
  nodes: readonly NavigationItem[],
  predicate: ItemPredicate,
): NavigationItem | undefined => nodes.find(node => predicate(node))

const urlMatcher = (
  routeList: Routes,
  locale: string,
  refUrl: string,
  predicate: (refUrl: string, url: string) => boolean,
) => (item: NavigationItem) => {
  if (!item.to) {
    return false
  }

  const itemUrl = getLocalizedRouteFromRouteList(
    routeList,
    locale,
    item.to,
    item.params,
  )

  return predicate(refUrl, itemUrl)
}

const identicalUrlPredicate = (refUrl: string, itemUrl: string) =>
  refUrl === itemUrl

const startsWithUrlPredicate = (refUrl: string, itemUrl: string) =>
  refUrl.startsWith(itemUrl)

export const getActiveItem = ({
  routeList,
  locale,
  items,
  match,
}: {
  routeList: Routes
  locale: string
  items: readonly NavigationItem[]
  match: match
}): NavigationItem => {
  const { path, params } = match
  const currentUrl = applyRouteParams(path, params)

  const exactMatch = urlMatcher(
    routeList,
    locale,
    currentUrl,
    identicalUrlPredicate,
  )
  const partialMatch = urlMatcher(
    routeList,
    locale,
    currentUrl,
    startsWithUrlPredicate,
  )

  return search(items, exactMatch) || search(items, partialMatch)
}

export const getBrandLogoPart = (brand?: string): string | null => {
  if (!brand || !isValidBrand(brand)) {
    return null
  }

  const brandFormatted = formatBrand(brand)

  return `${BRAND_LOGO}_${brandFormatted}`
}
