import {
  CardCollapse,
  CardCollapseContent,
  CardCollapseHeader,
} from '@leaseplan/ui'
import { Text, VelocityConsumer } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { TimelineItem as TimelineItemType } from 'mlp-client/src/order-status/types'
import { getTimelineItemColor } from 'mlp-client/src/order-status/utils'
import { GrayDarkText } from 'mlp-client/src/components/styled/TextStyle'

class TimelineItemContent extends React.PureComponent<TimelineItemType> {
  render() {
    const { status, subtitle, title } = this.props
    const isCollapsed = status !== 'active'
    const e2eId = title.split('.').pop()

    return (
      <CardCollapse status={status} defaultCollapsed={isCollapsed}>
        <CardCollapseHeader>
          <VelocityConsumer>
            {({ theme }) => (
              <Text
                component="p"
                bold
                style={{ color: getTimelineItemColor(status, theme) }}
                data-e2e-id={`orderStatus-timeLineItem-${e2eId}`}
              >
                <Translation id={title} />
              </Text>
            )}
          </VelocityConsumer>
        </CardCollapseHeader>
        <CardCollapseContent>
          <GrayDarkText component="p" variant="100">
            {subtitle}
          </GrayDarkText>
        </CardCollapseContent>
      </CardCollapse>
    )
  }
}

export { TimelineItemContent }
export default TimelineItemContent
