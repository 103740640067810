import { Text } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { Weekday } from 'mlp-client/src/enums'
import { AppState, Supplier } from 'mlp-client/src/types'
import { openingHoursAsString } from 'mlp-client/src/components/suppliers/utils'

import { DayWrapper, OpeningHourWrapper } from './SupplierOpeningHours.styled'

export interface Props {
  supplier: Supplier
  supplierClosed: string
}

export class SupplierOpeningHours extends React.PureComponent<Props> {
  render() {
    const { supplier, supplierClosed } = this.props

    if (!supplier.openingHours) {
      return null
    }

    return (
      <div data-e2e-id="openingHours">
        {Object.entries(supplier.openingHours).map(([day, openingHour]) => {
          const todayDate = new Date()
          const today = Weekday[todayDate.getDay()].toLowerCase()
          const fontWeight = day === today ? 'bold' : 'regular'

          return (
            <OpeningHourWrapper key={day}>
              <DayWrapper size="s" fontWeight={fontWeight}>
                {this.props[day]}
              </DayWrapper>
              <Text size="s" component="div" fontWeight={fontWeight}>
                {openingHoursAsString(openingHour) || supplierClosed}
              </Text>
            </OpeningHourWrapper>
          )
        })}
      </div>
    )
  }
}

const mapTranslationsToProps = {
  supplierClosed: 'myLeaseplan.supplierMap.page.supplierClosed',
  monday: 'myLeaseplan.shared.time.days.monday',
  tuesday: 'myLeaseplan.shared.time.days.tuesday',
  wednesday: 'myLeaseplan.shared.time.days.wednesday',
  thursday: 'myLeaseplan.shared.time.days.thursday',
  friday: 'myLeaseplan.shared.time.days.friday',
  saturday: 'myLeaseplan.shared.time.days.saturday',
  sunday: 'myLeaseplan.shared.time.days.sunday',
}

type ReduxProps = Pick<Props, 'supplierClosed'>

const mapStateToProps = (state: AppState): ReduxProps =>
  getTranslations(state, mapTranslationsToProps)

export default connect(mapStateToProps)(SupplierOpeningHours)
