import { DamageNatureTypes } from 'mlp-client/src/flows/damage-report/types'
import { StepNames } from 'mlp-client/src/flows/damage-report/enums'
import { getDamageReportTypesKey } from 'mlp-client/src/flows/damage-report/utils'
import { Options } from 'mlp-client/src/form/types'

export const getDamageNatureOptions = (
  damageNatureTypes: DamageNatureTypes,
): Options => {
  const stolenVehicleType = {
    value: StepNames.STOLEN_VEHICLE,
    title: `myLeaseplan.damageReport.steps.stolenVehicle.title`,
  }

  const options = damageNatureTypes.map(type => ({
    value: type.name,
    title: getDamageReportTypesKey(type.name),
  }))

  return options.length ? [stolenVehicleType, ...options] : []
}
