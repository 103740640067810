import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  ItemWithLink,
  Section,
} from 'mlp-client/src/components/steps/thank-you-step'
import {
  FlowData,
  FuelcardServiceType,
} from 'mlp-client/src/flows/fuel-card/types'
import { getDeliveryPeridosByServiceType } from 'mlp-client/src/flows/fuel-card/utils'

export interface Props extends FlowProps<FlowData> {
  config: {
    hideCashClaim?: boolean
    isSupportVisible?: boolean
    isSupportInNonMalfunctionVisible?: boolean
    isTimelineVisible?: boolean
    isTimelineIfMalfunctionVisible?: boolean
    isBlockCardLabelVisible?: boolean
    faqLink?: string
    deliveryPeriods?: {
      daysForCardDelivery: number
      daysForPinRestore: number
    }
  }
}

/*
 * This is dirtiest solution with such amount of flags here because of new way of country configuration where
 * we haven't create separate components for some particular cases.
 */
export class ThankYouStep extends React.PureComponent<Props> {
  render() {
    const { config, flowData, closeFlow } = this.props
    const { fuelCardServiceType } = flowData
    const isDriverForgotPin =
      fuelCardServiceType === FuelcardServiceType.FORGOT_PIN

    const daysAmount = config.deliveryPeriods
      ? getDeliveryPeridosByServiceType(
          fuelCardServiceType,
          config.deliveryPeriods,
        )
      : null

    const isCardLost =
      fuelCardServiceType === FuelcardServiceType.LOST_STOLEN ||
      fuelCardServiceType === FuelcardServiceType.ALL_STOLEN

    const titleLabel = daysAmount
      ? `myLeaseplan.fuelCard.steps.thankYou.${fuelCardServiceType}.subtitleWithDynamicDays`
      : `myLeaseplan.fuelCard.steps.thankYou.${fuelCardServiceType}.subtitleWithUnknownDays`

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id={titleLabel} replace={{ daysAmount }} />
        </Headline>
        <Section headerText="myLeaseplan.fuelCard.steps.thankYou.description">
          {isDriverForgotPin && (
            <Translation id="myLeaseplan.fuelCard.steps.thankYou.listItems.notifyPinAccordingPolicy" />
          )}
          {config.isBlockCardLabelVisible && isCardLost && (
            <Translation id="myLeaseplan.fuelCard.steps.thankYou.listItems.blockCard" />
          )}
          {config.isTimelineVisible && (
            <ItemWithLink
              label="myLeaseplan.fuelCard.steps.thankYou.listItems.timeline"
              to="myLeaseplan.timeline.base"
              linkLabel="myLeaseplan.fuelCard.steps.thankYou.listItems.timelineLink"
            />
          )}
          {config.isTimelineIfMalfunctionVisible &&
            fuelCardServiceType === FuelcardServiceType.DAMAGED && (
              <ItemWithLink
                label="myLeaseplan.fuelCard.steps.thankYou.listItems.timeline"
                to="myLeaseplan.timeline.base"
                linkLabel="myLeaseplan.fuelCard.steps.thankYou.listItems.timelineLink"
              />
            )}
          {config.hideCashClaim && (
            <ItemWithLink
              label="myLeaseplan.fuelCard.steps.thankYou.listItems.cashClaim"
              to="myLeaseplan.cashClaim.base"
              linkLabel="myLeaseplan.fuelCard.steps.thankYou.listItems.cashClaimLink"
            />
          )}
          {config.isSupportVisible && (
            <ItemWithLink
              label="myLeaseplan.fuelCard.steps.thankYou.listItems.faq"
              to="myLeaseplan.external.faq"
              linkLabel="myLeaseplan.fuelCard.steps.thankYou.listItems.faqLink"
            />
          )}
          {config.isSupportInNonMalfunctionVisible &&
            fuelCardServiceType !== FuelcardServiceType.DAMAGED && (
              <ItemWithLink
                label="myLeaseplan.fuelCard.steps.thankYou.listItems.faq"
                to={config.faqLink || 'myLeaseplan.external.faq'}
                linkLabel="myLeaseplan.fuelCard.steps.thankYou.listItems.faqLink"
              />
            )}
        </Section>
      </ThankyouPageLayout>
    )
  }
}

export default ThankYouStep
