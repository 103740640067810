import { Grid, GridItem, mq } from '@leaseplan/ui'
import { colorMixins } from '@velocity/ui'
import styled from 'styled-components'

export const ListAndMapWrapper = styled(Grid)`
  ${mq.from('lap')`
    background-color: white;
    height: 50vh;
    box-shadow: 0 1px 4px 0 #ddd;
  `};
`

export const Center = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`

// fixes scrolling bug in Chrome on Windows
export const SuppliersListWrapper = styled(GridItem)`
  width: 100%;
  max-height: 100%;
`

export const ViewOnMap = styled.div`
  display: flex;
  margin: 12px 0;
  color: ${colorMixins.primaryDarkColor};
  ${mq.from('lap')`
    display: none;
  `}
`
