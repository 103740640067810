import {
  BreakpointName,
  GridItem,
  Loader,
  Text,
  withBreakpoint,
} from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import {
  getCountry,
  getTranslations,
} from 'mlp-client/src/localization/selectors'
import SupplierMap from 'mlp-client/src/flows/components/steps/select-supplier-step/suppliers-map/SupplierMap'
import { getGoogleMapCoordinates } from 'mlp-client/src/flows/components/steps/select-supplier-step/utils'
import { LoadSuppliers } from 'mlp-client/src/flows/components/pages/supplier-map-page/actions'
import AddressSelector from 'mlp-client/src/flows/components/pages/supplier-map-page/address-selector/AddressSelector'
import { Header } from 'mlp-client/src/flows/components/pages/supplier-map-page/header/Header'
import { OtherAddress } from 'mlp-client/src/flows/components/pages/supplier-map-page/other-address/OtherAddress'
import {
  Center,
  ListAndMapWrapper,
  SuppliersListWrapper,
  ViewOnMap,
} from 'mlp-client/src/flows/components/pages/supplier-map-page/SupplierMapPage.styled'
import NoSupplierFound from 'mlp-client/src/flows/components/pages/supplier-map-page/no-supplier-found/NoSupplierFound'
import SupplierDetails from 'mlp-client/src/flows/components/pages/supplier-map-page/supplier-details/SupplierDetails'
import { SuppliersList } from 'mlp-client/src/flows/components/pages/supplier-map-page/suppliers-list/SuppliersList'
import { SuppliersMapModal } from 'mlp-client/src/flows/components/pages/supplier-map-page/suppliers-map-modal/SuppliersMapModal'
import { getUser } from 'mlp-client/src/user/selectors'
import { Address, AddressTypes, User } from 'mlp-client/src/user/types'
import {
  getUserHomeAddress,
  getUserWorkAddress,
} from 'mlp-client/src/user/utils'
import EmptyMapMarker from 'mlp-client/src/components/icons/empty-map-marker-icon/EmptyMapMarkerIcon'
import {
  getAddressModel,
  isDesktopBreakpoint,
  isAddressValid,
} from 'mlp-client/src/utils'
import { AppState, BookingRequestType, Supplier } from 'mlp-client/src/types'
import { getStaticPageSuppliers } from 'mlp-client/src/flows/components/pages/supplier-map-page/selectors'
import {
  State,
  StaticPageSuppliersData,
} from 'mlp-client/src/flows/components/pages/supplier-map-page/types'

export interface Props {
  user: User
  subTitle: string
  title: string
  country: string
  errorLoadingSuppliers: string
  searchLabel: string
  suppliersData: StaticPageSuppliersData
  breakpoint: BreakpointName
  bookingType: BookingRequestType
  showOpeningHours: boolean
  loadSuppliers(address: Address): void
}

export class PageContent extends React.PureComponent<Props, State> {
  getDefaultOtherAddress = () =>
    getAddressModel({
      type: AddressTypes.OTHER,
      country: this.getUserAddress()?.country,
      addressLine1: '',
      city: '',
      zipCode: '',
    })

  getUserAddress = () => {
    const {
      country,
      user: { addresses },
    } = this.props

    const userAddress =
      getUserWorkAddress(addresses) || getUserHomeAddress(addresses)

    if (!userAddress?.country) {
      return {
        ...userAddress,
        country,
      }
    }

    return userAddress
  }

  state: State = {
    selectedAddress: this.getUserAddress(),
    selectedSupplier: null,
    isSupplierMapModalOpen: false,
    otherAddress: this.getDefaultOtherAddress(),
  }

  componentDidMount() {
    const { selectedAddress } = this.state

    if (this.checkUserAddresses()) {
      this.props.loadSuppliers(selectedAddress)
    }
  }

  getSupplierById = (supplierId: string): Supplier =>
    this.props.suppliersData.suppliers.find(
      supplier => supplier.id === supplierId,
    )

  openSupplierDetails = (supplierId: string) => () => {
    const { breakpoint } = this.props
    const supplier = this.getSupplierById(supplierId)

    this.setState(() => ({
      selectedSupplier: supplier,
      // if its a mobile resolution, open the details and the map modal.
      isSupplierMapModalOpen: !isDesktopBreakpoint(breakpoint),
    }))
  }

  closeSupplierDetails = () => {
    this.setState(() => ({
      selectedSupplier: null,
    }))
  }

  openMapModal = () => {
    this.setState(() => ({
      isSupplierMapModalOpen: true,
    }))
  }

  closeMapModal = () => {
    this.setState(() => ({
      isSupplierMapModalOpen: false,
      selectedSupplier: null,
    }))
  }

  fetchSuppliers = (address: Address) => {
    if (isAddressValid(address)) {
      this.props.loadSuppliers(address)
    }
  }

  onAddressChange = (selectedAddress: Address) => () => {
    if (selectedAddress) {
      const updatedSelectedAddress = selectedAddress.country
        ? selectedAddress
        : {
            ...selectedAddress,
            country: this.props.country,
          }

      this.fetchSuppliers(updatedSelectedAddress)
      this.setState(() => ({
        selectedAddress: updatedSelectedAddress,
        selectedSupplier: null,
      }))
    }
  }

  onOtherAddressSearch = (otherAddress: Address) => {
    this.fetchSuppliers(otherAddress)
    this.setState(() => ({
      otherAddress,
      selectedAddress: otherAddress,
      selectedSupplier: null,
    }))
  }

  renderSupplierListOrDetails = () => {
    const { errorLoadingSuppliers, suppliersData, breakpoint } = this.props
    const { error, isLoading, suppliers } = suppliersData
    const { selectedSupplier, selectedAddress } = this.state

    if (isLoading) {
      return (
        <Center>
          <Loader />
        </Center>
      )
    }

    if (error) {
      return (
        <Center>
          <Text>{errorLoadingSuppliers}</Text>
        </Center>
      )
    }

    // If the suppliers response is empty, or the selected address is not valid
    // because when the address is not valid, the search will be country level.
    if (!suppliers || !suppliers.length || !isAddressValid(selectedAddress)) {
      return <NoSupplierFound />
    }

    // Show suppliers details modal only if opening hours are available
    if (
      selectedSupplier &&
      selectedSupplier.openingHours &&
      isDesktopBreakpoint(breakpoint)
    ) {
      return (
        <SupplierDetails
          supplier={selectedSupplier}
          onClose={this.closeSupplierDetails}
        />
      )
    }

    return (
      <>
        <ViewOnMap onClick={this.openMapModal}>
          <EmptyMapMarker />
          <Translation id="myLeaseplan.serviceRequest.supplier.linkToMap" />
        </ViewOnMap>
        <SuppliersList
          selectedSupplier={selectedSupplier}
          suppliers={suppliers}
          onSupplierClick={this.openSupplierDetails}
        />
      </>
    )
  }

  getMapBoundsPadding = (): google.maps.Padding | undefined => {
    const { isSupplierMapModalOpen, selectedSupplier } = this.state

    if (isSupplierMapModalOpen && selectedSupplier) {
      return {
        top: 80,
        bottom: 400,
        left: 20,
        right: 20,
      }
    }
  }

  getSuppliersMap = () => {
    const { searchLocation, suppliers } = this.props.suppliersData
    const { selectedSupplier } = this.state
    const supplierId = selectedSupplier ? selectedSupplier.id : null

    // by default set the map center to search location.
    const mapCenter = searchLocation
      ? getGoogleMapCoordinates(searchLocation)
      : null

    return (
      <SupplierMap
        suppliers={suppliers}
        searchLocation={searchLocation}
        highlightedSupplierId={supplierId}
        showSupplierNameOnMarkers={false}
        onMarkerClick={this.openSupplierDetails}
        mapBoundsPadding={this.getMapBoundsPadding()}
        mapOptions={{
          center: mapCenter,
        }}
      />
    )
  }

  checkUserAddresses = (): boolean => {
    const { user } = this.props
    const userAddresses = user?.addresses

    if (
      userAddresses &&
      (getUserHomeAddress(userAddresses) || getUserWorkAddress(userAddresses))
    ) {
      return true
    }

    return false
  }

  renderOtherAddressForm = () => {
    const { otherAddress, selectedAddress } = this.state
    const { searchLabel } = this.props

    if (
      otherAddress.type === selectedAddress.type ||
      !this.checkUserAddresses()
    ) {
      return (
        <OtherAddress
          searchLabel={searchLabel}
          onSearch={this.onOtherAddressSearch}
          otherAddress={otherAddress}
        />
      )
    }

    return null
  }

  clearOtherAddress = () => {
    this.setState({ otherAddress: this.getDefaultOtherAddress() })
  }

  render() {
    const { title, subTitle, user, breakpoint } = this.props
    const {
      selectedAddress,
      otherAddress,
      isSupplierMapModalOpen,
      selectedSupplier,
    } = this.state
    const isDesktop = isDesktopBreakpoint(breakpoint)

    return (
      <>
        <Header title={title} subTitle={subTitle} />
        <AddressSelector
          homeAddress={getUserHomeAddress(user.addresses)}
          workAddress={getUserWorkAddress(user.addresses)}
          otherAddress={otherAddress}
          selectedAddressType={selectedAddress.type}
          onAddressChange={this.onAddressChange}
        />
        {this.renderOtherAddressForm()}
        <ListAndMapWrapper gutter={false}>
          <SuppliersListWrapper span={{ desktop: 6, lap: 6 }}>
            {this.renderSupplierListOrDetails()}
          </SuppliersListWrapper>

          <GridItem span={{ desktop: 6, lap: 6 }}>
            {this.getSuppliersMap()}
          </GridItem>
        </ListAndMapWrapper>
        {!isDesktop && (
          <SuppliersMapModal
            supplier={selectedSupplier}
            isVisible={isSupplierMapModalOpen}
            closeModal={this.closeMapModal}
            onDetailsClose={this.closeSupplierDetails}
            suppliersMap={this.getSuppliersMap()}
          />
        )}
      </>
    )
  }
}

const mapTranslationsToProps = {
  searchLabel: 'myLeaseplan.supplierMap.page.search',
  errorLoadingSuppliers: 'myLeaseplan.supplierMap.page.errorLoadingSuppliers',
}

export type DispatchProps = Pick<Props, 'loadSuppliers'>
export type ReduxProps = Pick<
  Props,
  'user' | 'country' | 'searchLabel' | 'suppliersData' | 'errorLoadingSuppliers'
>
export type OwnProps = Omit<Props, keyof (DispatchProps & ReduxProps)>

const mapStateToProps = (
  state: AppState,
  { showOpeningHours }: OwnProps,
): ReduxProps => ({
  ...getTranslations(state, mapTranslationsToProps),
  user: getUser(state),
  country: getCountry(state),
  suppliersData: getStaticPageSuppliers(state, showOpeningHours),
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  { bookingType }: OwnProps,
): DispatchProps => ({
  loadSuppliers: (address: Address) => {
    dispatch(new LoadSuppliers({ address, bookingType }))
  },
})

const connectComponent = connect(mapStateToProps, mapDispatchToProps)

export default withBreakpoint(connectComponent(PageContent))
