import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/timeline/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { ApiSagas } from 'mlp-client/src/api'

import { getPagination, getSelectedVehicles } from './selectors'

// This is default API options for all the sagas in this file
// if you want custom options, you can override them per api call
const defaultRequestOptions = {
  throwError: true,
}

export function* loadTimeline(): SagaIterator {
  const user = yield select(getUser)
  const { page, itemsPerPage } = yield select(getPagination)
  const contractIds = yield select(getSelectedVehicles)

  yield put(new actions.LoadingTimeLine())

  try {
    // Tell the browser explicitly to not load from cache.
    const headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    }
    const params = {
      page,
      itemsPerPage,
      ...(contractIds.length > 0 ? { contractIds: contractIds.join(',') } : {}),
    }

    const timeline = yield call(
      ApiSagas.get,
      `/myleaseplan/lpis/timeline/${user.id}`,
      { headers, params },
      defaultRequestOptions,
    )

    yield put(new actions.LoadTimeLineSuccess(timeline.events))
    yield put(new actions.SetPaginationTotalCount(timeline.metadata.totalCount))
  } catch {
    yield put(new actions.LoadTimeLineFailed())
  }
}

export default [takeLatest(actions.ActionTypes.LOAD_TIMELINE, loadTimeline)]
