export enum StepNames {
  CONFIRMATION = 'confirmation',
  DAMAGE_TYPE = 'damageType',
  PROBLEM_DESCRIPTION = 'problemDescription',
  SEASONAL_TYRES_SUPPLIER = 'seasonalTyresSupplier',
  TYRES_SUPPLIER = 'tyresSupplier',
  THANK_YOU = 'thankYou',
  TYRE_SERVICE = 'tyreService',
  ROADSIDE_ASSISTANCE = 'roadsideAssistance',
}

export enum TyreServiceTypes {
  WORN_OUT_TYRES = 'wornOutTyres',
  DAMAGED_TYRES = 'damagedTyres',
  SEASONAL_TYRE_CHANGE = 'seasonalTyreChange',
  TYRE_PRESSURE_WARNING = 'tyrePressureWarning',
  STEERING_PULLING_LEFT_RIGHT = 'steeringPullingLeftRight',
  STEERING_VIBRATION = 'steeringVibration',
  OTHER = 'other',
}

export enum TyreDamageTypes {
  WORN_OUT = 'Worn Out',
  DAMAGED = 'Damaged',
  PUNCTURE = 'Puncture',
  TYRE_PRESSURE = 'Tyre Pressure',
  VEHICLE_PULLING = 'Vehicle steering pulling left / right',
  VIBRATION = 'Vibration in steering',
  OTHER = 'Other',
}

export enum TyreFlowType {
  REPORT = 'report',
}

export enum ContentEndpoint {
  ROADSIDE_ASSISTANCE = 'myleaseplan/page/index/Roadside assistance',
}
