import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'
import { Spacing, Text } from '@leaseplan/ui'

import Translation from 'mlp-client/src/localization/Translation'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import {
  DateLocationTile,
  GeneralListTile,
} from 'mlp-client/src/components/confirm-step-tiles'
import { Coordinates } from 'mlp-client/src/components/map/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import ActiveVehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/ActiveVehicleModelLicense'
import { ReturnMethods, StepNames } from 'mlp-client/src/flows/return/enums'
import { StepViewProps, FlowData } from 'mlp-client/src/flows/return/types'
import { makeTranslationPath, formatPhoneNumber } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import Geocoder from 'mlp-client/src/components/geocoder/Geocoder'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import { FormSubmission } from 'mlp-client/src/form/types'
import { getFormattedAddress } from 'mlp-client/src/utils/address/address'
import {
  SubmitDropoffRequest,
  SubmitReturnRequest,
} from 'mlp-client/src/flows/return/actions'

const translatePath = makeTranslationPath(
  'myLeaseplan.newSetup.flows.return.steps.confirm',
)

const translatePathGeneric = makeTranslationPath(
  'myLeaseplan.genericNotifications',
)

export interface StepConfig {
  dateEditStep: StepNames
  contactEditStep: StepNames
  extraInfoStep: StepNames
}

export interface Props
  extends StepViewProps<StepConfig>,
    FormSubmission<FlowData> {
  locationTitle: string
  returnDayPeriod: string
}

class ConfirmReturnStep extends React.PureComponent<Props> {
  getLocation = (coordinates: Coordinates) => {
    const {
      locationTitle,
      flowData: { address },
    } = this.props

    return {
      coordinates,
      address: getFormattedAddress(address),
      title: locationTitle,
    }
  }

  handleEditClick = (step: StepNames) => () => this.props.goToStep(step)

  render() {
    const {
      locationTitle,
      returnDayPeriod,
      config: { dateEditStep, contactEditStep, extraInfoStep },
      flowData: {
        returnDate,
        returnTime,
        name,
        phone,
        email,
        shippingAddress,
        message,
        address,
      },
      onSubmit,
    } = this.props

    const dateTime = moment(returnDate)
      .hours(0)
      .add(returnTime ? returnTime : 0, 'hours')
      .format()

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={this.props.flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel={translatePath('pageTitle')}>
                <Spacing mb={4}>
                  <GeneralListTile
                    dataE2eId="contract"
                    title={<Translation id={translatePath('returnVehicle')} />}
                    data-test-id="contractTile"
                  >
                    <ActiveVehicleModelLicense />
                  </GeneralListTile>
                  <Geocoder address={getFormattedAddress(address)}>
                    {(coordinates: Coordinates) => (
                      <DateLocationTile
                        dateFormat={!returnTime ? 'LL' : 'LLL'}
                        dateTime={dateTime}
                        dayPeriod={returnDayPeriod}
                        location={this.getLocation(coordinates)}
                        title={locationTitle}
                        onClick={this.handleEditClick(dateEditStep)}
                      />
                    )}
                  </Geocoder>

                  {(name || phone?.number || email) && (
                    <GeneralListTile
                      dataE2eId="contactInformation"
                      title={
                        <Translation
                          id={translatePathGeneric(
                            'contactInformation.contactInformation',
                          )}
                        />
                      }
                      onClick={this.handleEditClick(contactEditStep)}
                    >
                      {[
                        name,
                        formatPhoneNumber(phone?.code, phone?.number),
                        email,
                      ]}
                    </GeneralListTile>
                  )}
                  {(shippingAddress || message) && (
                    <GeneralListTile
                      dataE2eId="additionalInformation"
                      title={
                        <Translation
                          id={translatePathGeneric('AdditionalInfo')}
                        />
                      }
                      onClick={this.handleEditClick(extraInfoStep)}
                    >
                      {shippingAddress && (
                        <Translation
                          id={translatePath('additionalInfo.shippingAddress')}
                          replace={{ shippingAddress }}
                        />
                      )}
                      {message}
                    </GeneralListTile>
                  )}
                  <Spacing mv={1} pv={0.5}>
                    <Text
                      size="xs"
                      component="p"
                      data-e2e-id="sharePersonalInfoPartners"
                    >
                      <Translation
                        id={translatePathGeneric('sharePersonalInfoPartners')}
                      />
                    </Text>
                  </Spacing>
                </Spacing>
                <Spacing mb={2}>
                  <SubmitStatus submitting={submitting} failed={submitFailed}>
                    <SubmitButton
                      stretch={true}
                      onClick={handleSubmit}
                      dataE2eId="buttonConfirmStep"
                      label="myLeaseplan.return.steps.confirm.nextButton"
                      disabled={submitting}
                    />
                  </SubmitStatus>
                </Spacing>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

export type ReduxProps = Pick<Props, 'locationTitle' | 'returnDayPeriod'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, props: Props): ReduxProps => ({
  locationTitle: getTranslation(
    state,
    props.flowData.returnMethod === ReturnMethods.DROP_OFF
      ? translatePath('dropoffAddress')
      : translatePath('pickUpAddress'),
    '',
  ),
  returnDayPeriod: getTranslation(
    state,
    `myLeaseplan.newSetup.flows.return.steps.selectDate.dayPeriod.${props.flowData.returnDayPeriod}`,
    '',
  ),
})

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete): void => {
    const { goToNextStep } = props

    const dataResult = {
      flowData,
      onComplete: (isError: boolean) => {
        if (!isError) {
          goToNextStep()
          formOnComplete()
        } else {
          formOnComplete({ isError })
        }
      },
    }

    if (flowData.returnMethod === ReturnMethods.DROP_OFF) {
      dispatch(new SubmitDropoffRequest(dataResult))
    } else {
      dispatch(new SubmitReturnRequest(dataResult))
    }
  },
})

export { ConfirmReturnStep }
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmReturnStep)
