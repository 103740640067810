import _get from 'lodash/get'
import moment from 'moment'
import numeral from 'numeral'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { config } from 'mlp-client/src/app-config'
import {
  getFormatting,
  getLocale,
  getMonthNamesAccusative,
  translationsEnSelector,
  translationsSelector,
} from 'mlp-client/src/localization/selectors'
import { LocalizationContextProps } from 'mlp-client/src/localization/types'
import { Dictionary } from 'mlp-client/src/dictionary/selectors'
import { AppState } from 'mlp-client/src/types'
import {
  isStringReplacementsArray,
  replaceContent,
  replaceContentStringByEntries,
  Replacement,
  StringReplacement,
  ReplacementItem,
} from 'mlp-client/src/utils'
import { getDebugTranslations } from 'mlp-client/src/selectors'
import { Formatting } from 'mlp-client/src/config/types'

function translateNotDefined(
  id: string,
  replace?: ReadonlyArray<ReplacementItem<StringReplacement>>,
): string
function translateNotDefined(
  id: string,
  replace: ReadonlyArray<ReplacementItem<Replacement>>,
): React.ReactNodeArray
function translateNotDefined(
  id: string,
  replace?: ReadonlyArray<ReplacementItem<Replacement>>,
): string | React.ReactNodeArray {
  const result =
    'Translation is unavailable. Do you use the localization context outside of the provider?'

  if (!replace) {
    return result
  }

  if (isStringReplacementsArray(replace)) {
    return result
  }

  return [result]
}

export const LocalizationContext = React.createContext<LocalizationContextProps>(
  {
    locale: config.DEFAULT_LOCALE,
    debugTranslations: false,
    translate: translateNotDefined,
    englishTranslation: translateNotDefined,
  },
)

export interface Props {
  locale: string
  monthNamesAccusative?: string[]
  translations: Dictionary
  enTranslations?: Dictionary
  formatting: Formatting
  debugTranslations?: boolean
  children?: React.ReactNode
}

export class LocalizationProvider extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.translate = this.translate.bind(this)
    this.englishTranslation = this.englishTranslation.bind(this)
    this.updateLocale()
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.locale !== this.props.locale) {
      this.updateLocale()
    }
  }

  updateLocale = () => {
    const { formatting, monthNamesAccusative, locale } = this.props

    this.registerNumeralLocale(locale, formatting)
    this.setLocale(locale)

    if (monthNamesAccusative && monthNamesAccusative.length) {
      moment.updateLocale(locale, {
        months: {
          format: monthNamesAccusative,
          standalone: moment.months(),
          isFormat: /D[oD]?(\[[^\[\]]*\]|\s|\.+)+MMMM?/,
        },
      })
    }
  }

  registerNumeralLocale(locale: string, numeralLocale: Formatting) {
    if (!numeral.locales[locale.toLocaleLowerCase()]) {
      numeral.register('locale', locale, numeralLocale)
    }
  }

  setLocale(locale: string) {
    numeral.locale(locale)
    moment.locale(locale)
  }

  // There's an issue with false duplication reporting when using overloads:
  // https://github.com/typescript-eslint/typescript-eslint/issues/291
  /* eslint-disable no-dupe-class-members */
  /**
   * Translate a string and apply all replacements.
   */
  translate(
    id: string,
    replace?: ReadonlyArray<ReplacementItem<StringReplacement>>,
  ): string
  translate(
    id: string,
    replace: ReadonlyArray<ReplacementItem<Replacement>>,
  ): React.ReactNodeArray
  translate(
    id: string,
    replace?: ReadonlyArray<ReplacementItem<Replacement>>,
  ): string | React.ReactNodeArray {
    const { translations } = this.props

    if (!translations || !id) {
      return null
    }

    const translation: string = _get(translations, id.split('.'), id)

    if (translation === id) {
      return translation
    }

    if (replace) {
      if (isStringReplacementsArray(replace)) {
        const entries: Array<[string, StringReplacement]> = replace.reduce(
          (acc, { key, value }) => {
            acc.push([key, value])

            return acc
          },
          [],
        )

        return replaceContentStringByEntries(translation, entries)
      } else {
        const replacements = replace.reduce((acc, { key, value }) => {
          // Re-creating objects on each render can have performance implications
          // eslint-disable-next-line no-param-reassign
          acc[key] = value

          return acc
        }, {})

        return replaceContent(translation, replacements)
      }
    }

    return translation
  }
  /* eslint-enable no-dupe-class-members */

  englishTranslation(id: string): string {
    const { enTranslations } = this.props

    if (!enTranslations || !id) {
      return null
    }

    return _get(enTranslations, id.split('.'), id)
  }

  render() {
    const { debugTranslations, children, locale } = this.props

    return (
      <LocalizationContext.Provider
        value={{
          locale,
          debugTranslations,
          translate: this.translate,
          englishTranslation: this.englishTranslation,
        }}
      >
        {children}
      </LocalizationContext.Provider>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'debugTranslations'
  | 'formatting'
  | 'locale'
  | 'monthNamesAccusative'
  | 'translations'
  | 'enTranslations'
>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  debugTranslations: getDebugTranslations,
  formatting: getFormatting,
  locale: getLocale,
  monthNamesAccusative: getMonthNamesAccusative,
  translations: translationsSelector,
  enTranslations: translationsEnSelector,
})

export default connect(mapStateToProps)(LocalizationProvider)
