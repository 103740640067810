import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import * as actions from 'mlp-client/src/content/actions'
import { ApiSagas, RequestWithParams } from 'mlp-client/src/api'
import { getLocale } from 'mlp-client/src/localization/selectors'
import { mapFooterToNavigationItems } from 'mlp-client/src/content/utils'

export function* loadContentSaga({
  payload,
}: actions.LoadContent): SagaIterator {
  const { endpoint } = payload
  const contentPath = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint
  const params: RequestWithParams = {}

  const defaultLocale = yield select(getLocale)
  const locale = payload.locale || defaultLocale

  if (!payload.cache) {
    // Tell the browser explicitly to not load from cache.
    params.headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    }
  }

  const result = yield call(
    ApiSagas.get,
    `/content/${locale}/${contentPath}`,
    params,
  )

  if (!result.error) {
    yield put(
      new actions.LoadContentSuccess({
        result,
        endpoint,
      }),
    )
  }
}

export function* loadFooterMenu(action: actions.LoadFooterMenu): SagaIterator {
  try {
    const { userLocale } = action.payload
    const footerInfo = yield call(
      ApiSagas.get,
      `/content/small-footer/${encodeURIComponent(userLocale)}`,
    )

    yield put(
      new actions.LoadFooterMenuSuccess({
        locale: userLocale,
        result: mapFooterToNavigationItems(footerInfo),
      }),
    )
  } catch (e) {
    yield put(new actions.LoadFooterMenuFailed())
  }
}

export default [
  takeLatest(actions.ActionTypes.LOAD_FOOTER_MENU, loadFooterMenu),
  takeEvery(actions.ActionTypes.LOAD_CONTENT, loadContentSaga),
]
