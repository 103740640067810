import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  DamageCauses,
  FlowData,
  PartyDamages,
} from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { Options } from 'mlp-client/src/form/types'
import { RadioCardField } from 'mlp-client/src/form/components/fields/RadioFields'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { required } from 'mlp-client/src/form/validations'
import { RevealingItemList } from 'mlp-client/src/components/item-list/RevealingItemList'
import { mapPartyDamagesToOptions } from 'mlp-client/src/flows/damage-report/utils'

export interface StepConfig {
  whoseFaultOptions: Options
  whatHappenedOptions: PartyDamages
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

export type FormData = Pick<FlowData, 'whoseFault' | 'selectWhatHappened'>

class WhatHappenedStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData(formData, goToNextStep)
  }

  render() {
    const {
      config: { whoseFaultOptions, whatHappenedOptions },
      flowData: { damageCause, whoseFault, selectWhatHappened },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.whatHappened.stepTitle">
          <Form
            onSubmit={this.submitHandler}
            initialValues={{ whoseFault, selectWhatHappened }}
            render={({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit}>
                  {damageCause === DamageCauses.TWO_PARTY && (
                    <>
                      <RadioCardField
                        name="whoseFault"
                        validations={[required]}
                        options={whoseFaultOptions}
                        label={{
                          id:
                            'myLeaseplan.damageReport.steps.whatHappened.whoFault',
                        }}
                        dataE2eId="whoseFaultTypeList"
                      />
                      <Spacing mt={2} />
                    </>
                  )}
                  <RevealingItemList
                    batchSize={6}
                    items={mapPartyDamagesToOptions(
                      whatHappenedOptions,
                      damageCause,
                    )}
                  >
                    {options => (
                      <RadioCardField
                        name="selectWhatHappened"
                        validations={[required]}
                        options={options}
                        label={{
                          id:
                            'myLeaseplan.damageReport.steps.whatHappened.whatHappened',
                        }}
                        dataE2eId={
                          damageCause === DamageCauses.ONE_PARTY
                            ? 'onePartyDamageTypesList'
                            : 'thirdPartyDamageTypesList'
                        }
                      />
                    )}
                  </RevealingItemList>
                  <Spacing mt={4} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                  <Spacing mb={2} />
                </form>
              )
            }}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export { WhatHappenedStep }
export default WhatHappenedStep
