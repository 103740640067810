import { Hide } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import {
  BannerContent,
  FullWidthBanner,
} from 'mlp-client/src/vehicles/components/TopBanner.styled'
import {
  WhiteHeadline,
  WhiteText,
} from 'mlp-client/src/components/styled/TextStyle'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

export interface Props {
  name: string
}

class TopBanner extends React.PureComponent<Props> {
  render() {
    const { name } = this.props

    return (
      <FullWidthBanner
        image={
          isLeaseplan() ? '/static/images/my-leaseplan/journey-line.svg' : ''
        }
      >
        <BannerContent>
          <WhiteHeadline
            variant="400"
            data-e2e-id="homepageHeader"
            withMarginBottom
            component="h1"
          >
            <Translation
              replace={{ name }}
              id="myLeaseplan.dashboard.welcome"
            />
          </WhiteHeadline>
          <Hide until="tablet">
            <WhiteText variant="400" data-e2e-id="desktopHomepageSubheader">
              <Translation id="myLeaseplan.multipleCars.titles.subtitle" />
            </WhiteText>
          </Hide>
        </BannerContent>
      </FullWidthBanner>
    )
  }
}

export { TopBanner }
export default TopBanner
