import RCSlider, { SliderProps } from 'rc-slider'
import React from 'react'

import { StyledSlider } from 'mlp-client/src/components/slider/Slider.styled'

interface OwnProps {
  dataE2EId?: string
}

type SomeSliderProps = Pick<
  SliderProps,
  'value' | 'onChange' | 'min' | 'max' | 'step'
>

type Props = OwnProps & SomeSliderProps

export const Slider: React.FC<Props> = ({ dataE2EId, ...sliderProps }) => (
  <StyledSlider data-e2e-id={dataE2EId || 'filter-slider'}>
    <RCSlider {...sliderProps} />
  </StyledSlider>
)

Slider.displayName = 'Slider'
