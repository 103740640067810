import range from 'lodash/range'
import uniq from 'lodash/uniq'
import moment from 'moment'

import { Option, Options } from 'mlp-client/src/form/types'
import { Weekday } from 'mlp-client/src/enums'
import {
  Geolocation,
  Supplier,
  SupplierAddress,
  Suppliers,
} from 'mlp-client/src/types'
import { timeToInt } from 'mlp-client/src/components/suppliers/utils'

const defaultHours = [
  { from: '09:00', until: '12:00' },
  { from: '12:00', until: '18:00' },
]

const combineArrays = <T extends {}>(
  prev: readonly T[],
  next: readonly T[],
): readonly T[] => [...prev, ...next]

export const getFormattedAddress = (address: SupplierAddress): string => {
  const { street, number, suffix, postcode, city, country } = address

  return `${street}${number ? ` ${number}` : ''}${suffix ? `${suffix}` : ''},${
    postcode ? ` ${postcode}` : ''
  }${city ? ` ${city}` : ''}${country ? `, ${country}` : ''}`
}

export const supplierToOption = (measureUnit: string) => (
  supplier: Supplier,
): Option => ({
  title: supplier.name,
  subtitle: getFormattedAddress(supplier.address),
  value: supplier.id,
  ribbon: {
    text: `${supplier.distance.toFixed(1)} ${measureUnit}`,
  },
  dataE2eId: `supplier-${supplier.id}`,
})

export const getHoursBetween = ({
  from,
  until,
}: {
  from: string
  until: string
}): number[] => {
  const start = timeToInt(from, true)
  const end = timeToInt(until, false)

  return range(start, end)
}

export const addDefaultOpeningHours = (supplier: Supplier): Supplier => {
  if (!supplier.openingHours) {
    return {
      ...supplier,
      openingHours: {
        monday: defaultHours,
        tuesday: defaultHours,
        wednesday: defaultHours,
        thursday: defaultHours,
        friday: defaultHours,
        saturday: defaultHours,
        sunday: defaultHours,
      },
    }
  }

  return supplier
}

export const mapHours = (
  supplier: Supplier,
  weekday: number,
): readonly number[] => {
  const today = Weekday[weekday].toLowerCase()

  const supplierWithDefault = addDefaultOpeningHours(supplier)
  const timeBlocks =
    (supplierWithDefault.openingHours[today] &&
      supplierWithDefault.openingHours[today].map(getHoursBetween)) ||
    []

  return timeBlocks.reduce(combineArrays, [])
}

export const getTimesFromAllSuppliers = (
  suppliers: Suppliers,
): readonly number[] => {
  const availableHours = suppliers
    .map(supplier => supplier.availableHours || [])
    .reduce(combineArrays, [])

  const sorted = [...availableHours].sort((prev, next) => prev - next)

  return uniq(sorted)
}

export const mapSuppliers = (
  suppliers: Suppliers,
  weekday: number,
): Suppliers =>
  suppliers.map(supplier => ({
    ...supplier,
    availableHours: mapHours(supplier, weekday),
  }))

export const mapTimes = (times: readonly number[]): Options =>
  times.map(time => ({
    title: getFormattedTime(time),
    value: time.toString(),
  }))

export const getFormattedTime = (time: number): string => {
  const minutes = (time - Math.floor(time)) * 60

  return moment().hours(time).minutes(minutes).format('HH:mm')
}

/**
 * @desc Convert supplier coordinates to Google map coordinates.
 */
export const getGoogleMapCoordinates = (
  location: Geolocation,
): google.maps.LatLngLiteral => ({
  lat: location.latitude,
  lng: location.longitude,
})

/**
 * @desc Returns Google coordinates of the given suppliers.
 */
export const getSupplierGoogleMapCoordinates = (
  supplier: Supplier,
): google.maps.LatLngLiteral => getGoogleMapCoordinates(supplier.location)

export const showMessageBar = (suppliers: Suppliers, distance: number) =>
  distance &&
  Boolean(suppliers.length) &&
  suppliers.every(supplier => supplier.distance > distance)
