import { Link, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import GearsIcon from 'mlp-client/src/components/under-maintenance/GearsIcon'
import { formatDate } from 'mlp-client/src/utils'
import NoNavigationWrapper from 'mlp-client/src/components/NoNavigationWrapper'

export interface Props {
  endDate?: Date
}

export const UnderMaintenance: React.FC<Props> = ({ endDate }) => (
  <NoNavigationWrapper>
    <Spacing mb={1}>
      <GearsIcon width="64" height="64" color="petrolBlue" />
    </Spacing>
    <Headline
      variant="400"
      withMarginBottom
      data-e2e-id="titleNotAvailable"
      component="h1"
    >
      <Translation id="myLeaseplan.pageNotAvailable.title" />
    </Headline>
    <Text component="p">
      {endDate ? (
        <Translation
          id="myLeaseplan.pageNotAvailable.descriptionTimeKnown"
          replace={{ duration: formatDate(endDate) }}
        />
      ) : (
        <Translation id="myLeaseplan.pageNotAvailable.descriptionTimeNotKnown" />
      )}
    </Text>
    <Text component="p">
      <Translation id="myLeaseplan.pageNotAvailable.dashboard" />{' '}
      <Link
        component={RouteLink}
        to="myLeaseplan.external.mainHomePage"
        data-e2e-id="mainHomePageLink"
      >
        <Translation id="myLeaseplan.pageNotAvailable.homepageLink" />
      </Link>
    </Text>
  </NoNavigationWrapper>
)

export default UnderMaintenance
