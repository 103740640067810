import { List, ListItem, Spacing } from '@leaseplan/ui'
import { Text, Headline } from '@velocity/ui'
import { connect } from 'react-redux'
import React, { ReactElement } from 'react'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepTile from 'mlp-client/src/components/flow-step-tile/FlowStepTile'
import { Attachment } from 'mlp-client/src/types'
import {
  DateLocationTile,
  GeneralListTile,
  PhotoTile,
} from 'mlp-client/src/components/confirm-step-tiles'
import { StepNames } from 'mlp-client/src/flows/damage-report/enums'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  DamageCauses,
  FlowData,
  impactPoints,
  PartyDamages,
} from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { SubmitDamageReportRequest } from 'mlp-client/src/flows/damage-report/actions'
import ConfirmFlowButton from 'mlp-client/src/flows/components/buttons/confirm-flow-button/ConfirmFlowButton'
import { getWhatHappenedTitle } from 'mlp-client/src/flows/damage-report/components/steps/check-details-step/utils'
import {
  getDamageReportTypesKey,
  isThirdPartyInfoEmpty,
  mapIncidentDateTime,
} from 'mlp-client/src/flows/damage-report/utils'
import CarPointSelect from 'mlp-client/src/form/components/car-point-select/CarPointSelect'
import { FormSubmission } from 'mlp-client/src/form/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import ActiveVehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/ActiveVehicleModelLicense'

export interface StepConfig {
  whatHappenedOptions?: PartyDamages
  isInsuranceEnabled?: boolean
}

export interface Props extends FlowProps<FlowData>, FormSubmission<FlowData> {
  config: StepConfig
}

interface TileProps {
  step: string
  items?: ReadonlyArray<string | React.ReactElement>
  attachments?: readonly Attachment[]
  dataE2eId?: string
}

class CheckDetailsStep extends React.PureComponent<Props> {
  onEdit = (stepName: string) => () => {
    const { goToStep } = this.props

    goToStep(stepName)
  }

  renderListItem = (translation: string | ReactElement) => {
    if (typeof translation === 'string') {
      return (
        translation && (
          <ListItem key={translation}>
            <Text variant="200">{translation}</Text>
          </ListItem>
        )
      )
    }

    return (
      translation && (
        <ListItem key={translation.key || translation.props.id}>
          <Text variant="200">{translation}</Text>
        </ListItem>
      )
    )
  }

  renderTile = ({ step, items, dataE2eId = step }: TileProps) => (
    <FlowStepTile
      dataE2eId={dataE2eId}
      key={step}
      title={
        <Translation
          id={`myLeaseplan.damageReport.steps.checkDetails.tiles.${step}`}
          data-e2e-id={`${dataE2eId}Title`}
        />
      }
      btnLabel={
        <Translation id="myLeaseplan.damageReport.steps.checkDetails.editLabel" />
      }
      onBtnClick={this.onEdit(step)}
    >
      <List listStyleType="none">
        {items && items.map(this.renderListItem)}
      </List>
    </FlowStepTile>
  )

  render() {
    const {
      config: { whatHappenedOptions, isInsuranceEnabled },
      onSubmit,
      flowData,
    } = this.props
    const {
      description,
      picture,
      policeStationName,
      thirdPartyInfo,
      noThirdPartyInfo,
      europeanAccidentStatement,
      firstImpactPoint,
      damagePoints,
      where,
      date,
      time,
      selectWhatHappened,
      whoseFault,
      damageNatureType,
      damageCause,
    } = flowData

    const {
      insuranceCompany,
      policyNumber,
      firstName,
      lastName,
      licensePlate,
      phoneNumber,
    } = thirdPartyInfo

    const listOverviewLabel = (
      <Spacing mb={1}>
        <Headline variant="100" component="h6">
          <Translation
            id="myLeaseplan.damageReport.steps.pointSelection.otherDamages"
            data-e2e-id="otherDamages"
          />
        </Headline>
      </Spacing>
    )

    return (
      <Form onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.checkDetails.pageTitle">
                {damageNatureType &&
                  this.renderTile({
                    step: StepNames.SELECT_DAMAGE_NATURE_TYPE,
                    items: [
                      <Translation
                        key={damageNatureType}
                        id={getDamageReportTypesKey(damageNatureType)}
                      />,
                      <ActiveVehicleModelLicense key="activeVehicle" />,
                    ],
                  })}
                {selectWhatHappened &&
                  this.renderTile({
                    step: StepNames.WHAT_HAPPENED,
                    dataE2eId: 'damageType',
                    items: [
                      <Translation
                        key={selectWhatHappened}
                        id={getWhatHappenedTitle(
                          whatHappenedOptions,
                          selectWhatHappened,
                        )}
                      />,
                      whoseFault && (
                        <Translation
                          key={whoseFault}
                          id={`myLeaseplan.damageReport.steps.whatHappened.${whoseFault}`}
                        />
                      ),
                      <ActiveVehicleModelLicense key="activeVehicle" />,
                    ],
                  })}
                {(description || Boolean(picture?.length)) && (
                  <PhotoTile
                    dataE2eId="whatHappened"
                    photos={picture}
                    title={
                      <Translation id="myLeaseplan.damageReport.steps.checkDetails.tiles.uploadDamage" />
                    }
                    onClick={this.onEdit(StepNames.UPLOAD_DAMAGE)}
                  >
                    <Text variant="200">{description}</Text>
                    {policeStationName && (
                      <>
                        <Text variant="200" bold component="span">
                          <Translation id="myLeaseplan.damageReport.steps.uploadDamage.policeStation" />
                          {`: `}
                        </Text>
                        <Text variant="200" component="span">
                          {policeStationName}
                        </Text>
                      </>
                    )}
                  </PhotoTile>
                )}
                {isInsuranceEnabled && damageCause === DamageCauses.TWO_PARTY && (
                  <GeneralListTile
                    dataE2eId="damageReportInsurance"
                    title={
                      <Translation
                        data-e2e-id="damageReportInsuranceTitle"
                        id="myLeaseplan.damageReport.steps.insurance.title"
                      />
                    }
                    onClick={this.onEdit(StepNames.INSURANCE)}
                  >
                    {(noThirdPartyInfo ||
                      isThirdPartyInfoEmpty(thirdPartyInfo)) && (
                      <Translation id="myLeaseplan.damageReport.steps.insurance.checkBox" />
                    )}
                    {insuranceCompany && (
                      <>
                        <Translation id="myLeaseplan.damageReport.steps.insurance.company" />
                        : {insuranceCompany}
                      </>
                    )}
                    {policyNumber && (
                      <>
                        <Translation id="myLeaseplan.damageReport.steps.insurance.policy" />
                        : {policyNumber}
                      </>
                    )}
                    {firstName && (
                      <>
                        <Translation id="myLeaseplan.damageReport.steps.insurance.firstName" />
                        : {firstName}
                      </>
                    )}
                    {lastName && (
                      <>
                        <Translation id="myLeaseplan.damageReport.steps.insurance.lastName" />
                        : {lastName}
                      </>
                    )}
                    {licensePlate && (
                      <>
                        <Translation id="myLeaseplan.damageReport.steps.insurance.licensePlate" />
                        : {licensePlate}
                      </>
                    )}
                    {phoneNumber && (
                      <>
                        <Translation id="myLeaseplan.damageReport.steps.insurance.phoneNumber" />
                        : {phoneNumber}
                      </>
                    )}
                  </GeneralListTile>
                )}
                {Boolean(europeanAccidentStatement?.length) && (
                  <PhotoTile
                    dataE2eId="europeanAccidentStatement"
                    photos={europeanAccidentStatement}
                    title={
                      <Translation id="myLeaseplan.damageReport.steps.checkDetails.tiles.europeanAccidentStatement" />
                    }
                    onClick={this.onEdit(StepNames.EUROPEAN_ACCIDENT_STATEMENT)}
                  />
                )}
                <DateLocationTile
                  dateTime={mapIncidentDateTime(date, time).toDate()}
                  location={where}
                  onClick={this.onEdit(StepNames.WHEN_AND_WHERE)}
                />
                <FlowStepTile
                  key={StepNames.POINT_SELECTION}
                  dataE2eId="ImpactPoint"
                  title={
                    <Translation id="myLeaseplan.serviceRequest.steps.pointSelection.firstImpactPoint" />
                  }
                  btnLabel={
                    <Translation id="myLeaseplan.damageReport.steps.checkDetails.editLabel" />
                  }
                  onBtnClick={this.onEdit(StepNames.POINT_SELECTION)}
                >
                  <CarPointSelect
                    multiSelect={false}
                    points={impactPoints}
                    value={firstImpactPoint}
                    disabled={true}
                    name="firstImpactPoint"
                    hideList={true}
                  />
                  <Headline variant="100" component="h6">
                    <Translation
                      id="myLeaseplan.serviceRequest.steps.pointSelection.damagePoints"
                      data-e2e-id="damagePoints"
                    />
                  </Headline>
                  <CarPointSelect
                    multiSelect={true}
                    points={impactPoints}
                    value={damagePoints}
                    disabled={true}
                    listOverview={true}
                    name="damagePoints"
                    listOverviewLabel={listOverviewLabel}
                    hideList={true}
                  />
                </FlowStepTile>
                <Spacing mt={4} />
                <SubmitStatus submitting={submitting} failed={submitFailed}>
                  <ConfirmFlowButton
                    onClick={handleSubmit}
                    disabled={submitting}
                  />
                </SubmitStatus>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof DispatchProps>

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep } = props

    dispatch(
      new SubmitDamageReportRequest({
        flowData,
        onComplete: error => {
          if (!error) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

export { CheckDetailsStep }
export default connect(null, mapDispatchToProps)(CheckDetailsStep)
