import React, { PureComponent, ReactNode } from 'react'

import CellWrapper from './CellWrapper'
import { CellStyled, RowStyled } from './Table.styled'
import { Column, Datum } from './types'

export interface Props {
  columns: readonly Column[]
  item: Datum
  defaultValue?: ReactNode | null
  rowsProps?: GenericObject
}

export class Row extends PureComponent<Props> {
  render() {
    const { columns, item, defaultValue, rowsProps } = this.props

    return (
      <RowStyled data-e2e-id={item.key}>
        {columns.map(({ key, cell: Cell }) => (
          <CellStyled key={key}>
            <CellWrapper
              columnKey={key}
              item={item}
              cell={Cell}
              defaultValue={defaultValue}
              rowsProps={rowsProps}
            />
          </CellStyled>
        ))}
      </RowStyled>
    )
  }
}

export default Row
