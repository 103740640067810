import React from 'react'

import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

export const LinkRenderer: React.FC<{
  href: string
  children: string
}> = ({ href, children }) => (
  <StyledButtonLink href={href} target="_blank" fontWeight="light" size="s">
    {children}
  </StyledButtonLink>
)
