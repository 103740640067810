import { SagaIterator } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'

import * as actions from './actions'
import {
  getSubmitDropoffRequestBody,
  getSubmitReturnRequestBody,
} from './utils'

const defaultRequestOptions = {
  throwError: true,
}

export function* returnRequest({
  payload: { flowData, onComplete },
}: actions.SubmitReturnRequest): SagaIterator {
  const body = yield call(getSubmitReturnRequestBody, flowData)
  const contract = yield select(getCurrentContract)
  let isRequestSuccessful: boolean = true

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/vehicle-return/',
      {
        body: {
          ...body,
          licensePlate: contract.vehicle.licenseRegistration,
        },
      },
      defaultRequestOptions,
    )
  } catch {
    isRequestSuccessful = false
  } finally {
    yield call(onComplete, !isRequestSuccessful)
  }
}

export function* dropoffRequest({
  payload: { flowData, onComplete },
}: actions.SubmitDropoffRequest): SagaIterator {
  const body = yield call(getSubmitDropoffRequestBody, flowData)

  let isRequestSuccessful: boolean = true

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/vehicle-dropoff/',
      {
        body,
      },
      defaultRequestOptions,
    )
  } catch {
    isRequestSuccessful = false
  } finally {
    yield call(onComplete, !isRequestSuccessful)
  }
}

export default [
  takeLatest(actions.ActionTypes.RETURN_REQUEST, returnRequest),
  takeLatest(actions.ActionTypes.DROPOFF_REQUEST, dropoffRequest),
]
