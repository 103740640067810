import styled from 'styled-components'

import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

const backgroundColorSelector = () => {
  if (!isLeaseplan) {
    return ColorEnumSelector('midOrange')
  }

  return ColorEnumSelector('bloodOrange')
}

export const StyledSlider = styled.div`
  /* === BASE ===
   * --------------------------------------------------
   */

  /* RC-SLIDER */

  .rc-slider {
    position: relative;

    width: 100%;
    height: 28px;

    padding: 11px 0;

    border-radius: 8px;

    touch-action: none;
  }

  /* === PARTS ===
   * --------------------------------------------------
   */

  /* RAIL */

  .rc-slider-rail {
    position: absolute;

    width: 100%;
    height: 6px;

    border-radius: 8px;

    background-color: ${ColorEnumSelector('steel20')};
  }

  /* TRACK */

  .rc-slider-track {
    position: absolute;
    left: 0;

    height: 6px;

    border-radius: 8px;

    background-color: ${ColorEnumSelector('aquaBlue')};
  }

  /* HANDLE */

  .rc-slider-handle {
    position: absolute;

    width: 32px;
    height: 32px;

    margin-top: -13px;
    margin-left: -16px;

    border: solid 4px ${ColorEnumSelector('white')};

    border-radius: 50%;

    background-color: ${backgroundColorSelector()};

    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);

    cursor: grab;

    touch-action: pan-x;

    &:after {
      position: absolute;
      top: 8px;
      left: 9px;

      width: 5px;
      height: 8px;

      border-right: 1px solid ${ColorEnumSelector('white')};
      border-left: 3px double ${ColorEnumSelector('white')};

      content: '';
      opacity: 0.5;
    }

    &:active {
      cursor: grabbing;
    }

    &:focus {
      outline-color: ${ColorEnumSelector('thunder')};
      outline-style: dotted;
      outline-offset: 3px;
      outline-width: 1px;
      cursor: grabbing;
    }
  }

  /* DOTS */

  .rc-slider-dot {
    position: absolute;
    bottom: 9px;

    width: 10px;
    height: 10px;

    background-color: #fff;

    border: 2px solid #ddd;

    border-radius: 50%;

    cursor: pointer;

    margin-left: -4px;

    &-active {
      border-color: ${ColorEnumSelector('aquaBlue')};
    }
  }
`

StyledSlider.displayName = 'StyledSlider'
