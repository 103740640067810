/**
 * Wrapper for react-scroller to get a hook for fixing IE scrolling.
 */
import { detect } from 'detect-browser'
import { animateScroll, Events, scroller as reactScroller } from 'react-scroll'

interface ScrollerOptions {
  offset?: number
  smooth?: boolean
  delay?: number
  duration?: number
}

const browser = detect()
const isIE = browser && browser.name === 'ie'

export const calculateOffset = (givenOffset: number) => {
  const topNavigationPlaceholder = document.getElementById(
    'top-navigation__placeholder',
  )

  return topNavigationPlaceholder
    ? givenOffset - topNavigationPlaceholder.offsetHeight * 1.5
    : givenOffset
}

const internetExplorerScroll = (name: string, options: ScrollerOptions) => {
  const elems = document.getElementsByName(name)

  if (elems?.[0]) {
    elems[0].scrollIntoView()

    if (options.offset) {
      elems[0].scrollTop += options.offset
    }
  }
}

const waitForScroll = (cb: () => void) => {
  if (isIE) {
    return cb()
  }

  Events.scrollEvent.register('end', () => {
    Events.scrollEvent.remove('end')
    cb()
  })
}

export const scroller = {
  scrollToTop: (options: {}, cb?: () => void) => {
    if (isIE) {
      window.scrollTo(0, 0)
    } else {
      animateScroll.scrollToTop(options)
    }

    if (cb) {
      waitForScroll(cb)
    }
  },
  scrollTo: (name: string, options?: ScrollerOptions, cb?: () => void) => {
    const offset = calculateOffset(options?.offset || 0)

    if (isIE) {
      internetExplorerScroll(name, {
        ...options,
        offset,
      })
    } else {
      reactScroller.scrollTo(name, {
        ...options,
        offset,
      })
    }

    if (cb) {
      waitForScroll(cb)
    }
  },
}
