interface LicensePlateConfig {
  formats: readonly string[]
  separator: '-'
}

interface LicensePlateCountries {
  [property: string]: LicensePlateConfig
}

const templates: LicensePlateCountries = {
  fr: {
    formats: [
      '000-AA-00',
      '000-AAA-00',
      '0000-AA-00',
      '0000-AAA-00',
      'AA-000-AA',
    ],
    separator: '-',
  },
}

const rules = {
  '0+': '[0-9]',
  'A+': '[a-zA-Z]',
}

export const isLicensePlateValid = (
  licensePlate: string = '',
  country: string,
): boolean => {
  const { formats, separator } = templates[country]

  /* Licenseplate to be checked without seperator */

  const licensePlateCheck = licensePlate.replace(
    new RegExp(separator, 'gi'),
    '',
  )

  /*
   * Convert template rules to regex arrays
   * and find a possible match for the licenseplate
   */

  const licensePossibleFormatsRegExp = formats.map(format =>
    format
      .split(separator)
      .map(part => {
        const [, regex] = Object.entries(rules).find(
          ([rule]) => part.match(rule) !== null,
        )

        return `(${regex}{${part.length}})`
      })
      .join(''),
  )

  return licensePossibleFormatsRegExp.some(
    format => licensePlateCheck.match(`^${format}$`) !== null,
  )
}
