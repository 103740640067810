import { Hide, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'
import { Table } from 'mlp-client/src/components/table'
import { Datum, RowsConfiguration } from 'mlp-client/src/components/table/types'
import { SetCurrentContract } from 'mlp-client/src/contracts/actions'
import { getLeaseContracts } from 'mlp-client/src/contracts/selectors'
import { Contract, Contracts } from 'mlp-client/src/contracts/types'
import withUser from 'mlp-client/src/user/components/withUser'
import { User } from 'mlp-client/src/user/types'
import { AppState } from 'mlp-client/src/types'
import { TableData } from 'mlp-client/src/components/overview-table/types'
import RowLink from 'mlp-client/src/components/overview-table/RowLink'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

import { getData, tableColumns } from './utils'

export interface Props {
  contracts: Contracts
  setActiveContract(contract: Contract): void
  user?: User
  pageRoute: string | null
}

class VehiclesOverviewTableComponent extends React.PureComponent<Props> {
  selectContract = (item: TableData): void => {
    const { contracts, setActiveContract } = this.props
    const contract = contracts.find(contract => contract.id === item.id)

    setActiveContract(contract)
  }

  getRows = (): RowsConfiguration => ({
    TrComponent: RowLink,
    props: {
      handleClick: this.selectContract,
      dashboardRoute: this.props.pageRoute,
    },
  })

  getTableData = (tableData: readonly TableData[]): readonly Datum[] => {
    if (!tableData || tableData.length === 0) {
      return null
    }

    return tableData.map(tableItem => ({
      ...tableItem,
      key: tableItem.id,
    }))
  }

  render() {
    const { contracts, user } = this.props
    const tableData = contracts.map(contract => getData(contract))

    return (
      <>
        <Spacing pb={2} pt={2}>
          <Headline component="h1" variant="400" withMarginBottom>
            <Translation
              replace={{ name: user?.firstName }}
              id="myLeaseplan.dashboard.welcome"
            />
          </Headline>
          <Text data-e2e-id="vehicleTableSubheader">
            <Translation id="myLeaseplan.multipleCars.titles.subtitle" />
          </Text>
        </Spacing>
        <Hide>
          <Spacing mb={2}>
            <SecondaryDarkText variant="500" bold>
              <Translation id="myLeaseplan.multipleCars.titles.featureTitle" />
            </SecondaryDarkText>
          </Spacing>
        </Hide>
        <Table
          data={this.getTableData(tableData)}
          columns={tableColumns}
          rows={this.getRows()}
          defaultValue="-"
          dataE2EId="vehiclesOverviewTable"
        />
      </>
    )
  }
}

type ReduxProps = Pick<Props, 'contracts' | 'pageRoute' | 'user'>
type DispatchProps = Pick<Props, 'setActiveContract'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contracts: getLeaseContracts(state),
  pageRoute: getLocalizedRoute(state, 'myLeaseplan.dashboard'),
})

const mapDispatchToProps: DispatchProps = {
  setActiveContract: (contract: Contract) =>
    new SetCurrentContract({ contractId: contract.id }),
}

export { VehiclesOverviewTableComponent }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withUser(VehiclesOverviewTableComponent))
