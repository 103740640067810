import { SagaIterator } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import * as actions from 'mlp-client/src/flows/cash-claim/actions'
import {
  getMappedCashClaimErrors,
  getSubmitCashClaimRequestBody,
} from 'mlp-client/src/flows/cash-claim/utils'
import { getUserId } from 'mlp-client/src/user/selectors'
import { CashClaimSubmitErrors } from 'mlp-client/src/flows/cash-claim/types'

const defaultRequestOptions = {
  throwError: true,
}

export function* submitCashClaim({
  payload: { flowData, onComplete },
}: actions.SubmitCashClaimRequest): SagaIterator {
  const body = yield call(getSubmitCashClaimRequestBody, flowData)
  const contractId = yield select(getCurrentContractId)
  const userId = yield select(getUserId)

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/cashClaim/',
      {
        body: {
          ...body,
          contractId,
          userId,
        },
      },
      defaultRequestOptions,
    )
    yield call(onComplete)
  } catch (error) {
    const errors: CashClaimSubmitErrors = getMappedCashClaimErrors(
      error?.error?.rawError?.errors,
    ) || { error: true }

    yield call(onComplete, errors)
  }
}

export default [
  takeLatest(actions.ActionTypes.SUBMIT_CASH_CLAIM_REQUEST, submitCashClaim),
]
