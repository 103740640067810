import styled from 'styled-components'

import { QUOTE_STATUSES_COLORS_CONFIG } from 'mlp-client/src/quotes/constants'
import { QuotesStatuses } from 'mlp-client/src/quotes/enums'

export const Status = styled.div<{ status: QuotesStatuses }>`
  background: ${({ status }) =>
    QUOTE_STATUSES_COLORS_CONFIG[status].background};
  color: ${({ status }) => QUOTE_STATUSES_COLORS_CONFIG[status].color};
  font-weight: 400;
  padding: 0 0.5em;
  white-space: nowrap;
  border-radius: 2px;
  display: flex;
  align-items: center;
  height: 18px;
  line-height: 16px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-family: Inter, sans-serif;
`
