import { Icon, IconProps } from '@leaseplan/ui'
import React from 'react'

export default class ChevronSolidCircleIcon extends React.PureComponent<IconProps> {
  static defaultProps: IconProps = {
    title: 'Arrow inside solid circle',
    viewBox: '0 0 20 20',
  }

  render() {
    return (
      <Icon {...this.props}>
        <path
          d="M10,20 C4.4771525,20 0,15.5228475 0,10 
        C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 
        C20,15.5228475 15.5228475,20 10,20 Z M5.19999695,8.85993092 
        L10.0279159,13.4204033 L14.8558349,8.85993092 L13.9454738,8 
        L10.0279159,11.7005415 L6.11035808,8 L5.19999695,8.85993092 Z"
        />
      </Icon>
    )
  }
}
