import { IconText, Spacing } from '@leaseplan/ui'
import styled from 'styled-components'

export const IconTextStyled = styled(IconText)`
  display: flex;
  padding: 0;
`

export const SpacingModelStyled = styled(Spacing)`
  line-height: 35px;
`
