import { OrderStatus } from 'mlp-client/src/features-configuration/types'

export const orderStatusFeature: OrderStatus = {
  isBannerVisibleOnDashboard: true,
  showVehicleDetails: false,
  showOrderNumber: false,
  firstWorkingDay: 1,
  lastWorkingDay: 5,
  openingHours: 8,
  closingHours: 16,
  contacts: {
    phone: 'myLeaseplan.orderStatus.phone',
    email: 'lp@leaseplan.dk',
  },
  showOrders: true,
}
