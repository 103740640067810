import { ListItem } from '@leaseplan/ui'
import { colorMixins, Headline } from '@velocity/ui'
import styled from 'styled-components'

import LicensePlateOrStatus from './LicensePlateOrStatus'

export const Divider = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  left: 0;
  width: 100%;
  border-top: 1px solid ${colorMixins.grayLightColor};
`
export const Container = styled.div`
  position: relative;
  height: 60px;
`
export const WrapperOuter = styled.div<{ selectable?: boolean }>`
  margin: -12px -24px;
  position: absolute;
  z-index: 1;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'auto')};
  width: calc(100% + 48px);
`
export const WrapperInner = styled.div`
  margin: 12px 24px;
`
export const SelectedItemWrapper = styled.div<{ compact?: boolean }>`
  height: ${props => (props.compact ? '40px' : '80px')};
  display: flex;
  align-items: center;
`
export const DropdownWrapper = styled.div``

export const SelectedCarWrapper = styled.div<{ flexWrap: boolean }>`
  display: flex;
  flex-wrap: ${props => (props.flexWrap ? 'wrap' : 'nowrap')};
  width: auto;
  min-width: 0;
  align-items: center;
`
export const SelectedVehicleModelStyled = styled(Headline)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-right: 8px;
`
export const ChevronIconWrapper = styled.div<{ selectable?: boolean }>`
  margin-left: 13px;
  width: 24px;
  text-align: center;
  display: ${({ selectable }) => (selectable ? 'block' : 'none')};
`
export const VehicleModelStyled = styled(Headline)`
  margin-right: 8px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ListItemStyled = styled(ListItem)`
  margin-bottom: -4px;
  padding-top: 10px;
`
export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 8px;
`
export const LicensePlateOrStatusSmall = styled(LicensePlateOrStatus)`
  margin-left: 10px;
`
export const LicensePlateOrStatusStyled = styled(LicensePlateOrStatus)`
  margin-left: 13px;
`
