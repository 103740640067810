import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class DamageIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M5.5 41.8A1.5 1.5 0 0 1 4 40.3v-4.6a1.5 1.5 0 0 1 3 0v3h11.8a1.5 1.5 0 1 1 0 3H5.5zm26.3 10.5a2.4 2.4 0 1 1-4.9-.5 2.4 2.4 0 0 1 4.9.5zm-6.3 2.9a4.9 4.9 0 0 0 3.3 1.8h1c1-.1 2-.5 2.7-1.1a4.9 4.9 0 0 0 1.8-3.3 5 5 0 0 0-5-5.4 4.9 4.9 0 0 0-3 1 4.9 4.9 0 0 0-1.8 3.4 4.9 4.9 0 0 0 1 3.6zM48.3 57a1.5 1.5 0 1 1 0 3H30.1a8 8 0 0 1-.7 0H5.5a1.5 1.5 0 0 1 0-3h17.6a7.9 7.9 0 0 1-1.5-3.5H5.5a1.5 1.5 0 0 1 0-3h16.1a7.9 7.9 0 0 1 2.8-4.6 7.9 7.9 0 0 1 5.8-1.7 8 8 0 0 1 7 6.3 1.5 1.5 0 0 1 .2 0h4l-1.5-1.6a1.5 1.5 0 0 1 .2-2.2l2.4-1.9-1.5-3L27.5 39a1.5 1.5 0 0 1-.5-.3L20 34c0-.1-4.6-3.5-11-3.5H5.5a1.5 1.5 0 0 1 0-3H9c7.5 0 12.6 4 12.8 4l6.6 4.7 13.9 3c.4 0 .8.3 1 .7l2.4 4.8a1.5 1.5 0 0 1-.4 1.8l-2.1 1.7 2.6 2.9a1.5 1.5 0 0 1-1.1 2.5h-7.3-.3a7.8 7.8 0 0 1-1.5 3.5h12.7zM43 20c-1.6-1-3.5-2.4-3.8-5.2-.3-3 1.3-4.6 2.6-5.9 1.2-1.3 1.9-2 1.7-3.5a1.5 1.5 0 1 1 3-.3c.3 3-1.3 4.6-2.6 5.9-1.2 1.2-1.8 2-1.7 3.4.2 1.5 1 2 2.4 3 1.6 1 3.5 2.4 3.8 5.2.3 3-1.3 4.6-2.6 5.9-1.2 1.3-1.9 2-1.7 3.5a1.5 1.5 0 1 1-3 .3c-.3-3 1.3-4.6 2.5-5.9 1.2-1.2 2-2 1.8-3.4-.2-1.5-1-2-2.4-3zm16.3 2.6c.3 3-1.2 4.6-2.5 5.9-1.2 1.3-2 2-1.8 3.5a1.5 1.5 0 1 1-3 .3c-.2-3 1.3-4.6 2.6-5.9 1.2-1.2 2-2 1.8-3.4-.2-1.5-1-2-2.5-3s-3.4-2.4-3.7-5.2c-.3-3 1.3-4.6 2.6-5.9 1.2-1.3 1.9-2 1.7-3.5a1.5 1.5 0 0 1 3-.3c.3 3-1.3 4.6-2.6 5.9-1.2 1.2-1.9 2-1.7 3.4.1 1.5 1 2 2.4 3 1.5 1 3.4 2.4 3.7 5.2z"
        />
      </svg>
    )
  }
}

export default DamageIcon
