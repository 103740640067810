import React, { ComponentClass, PureComponent, ReactNode } from 'react'

import { Datum } from './types'

type CellAsComponent = ComponentClass<GenericObject>
type CellAsFunction = (props: GenericObject) => ReactNode

export interface Props {
  rowsProps?: GenericObject
  item: Datum
  columnKey: string
  cell?: CellAsComponent | CellAsFunction
  emptyState?: ReactNode | null
  defaultValue?: ReactNode | null
}

export class CellWrapper extends PureComponent<Props> {
  render() {
    const { cell, item, rowsProps, defaultValue, columnKey } = this.props

    if (!cell) {
      return item[columnKey] || defaultValue || null
    }

    if (!cell.prototype.isReactComponent) {
      return (cell as CellAsFunction)({
        item,
        rowsProps,
        value: item[columnKey],
      })
    }

    const Cell = cell as CellAsComponent

    return <Cell item={item} value={item[columnKey]} {...rowsProps} />
  }
}

export default CellWrapper
