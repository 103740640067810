import React from 'react'

import LocationStep, {
  LocationFlowData,
  StepConfig as LocationStepConfig,
} from 'mlp-client/src/flows/components/steps/location/LocationStep'
import SelectDateField from 'mlp-client/src/flows/return/components/select-date-field/SelectDateField'
import { StepViewProps } from 'mlp-client/src/flows/return/types'

export interface StepConfig extends LocationStepConfig {
  daysToPickup: number
}

export interface Props extends StepViewProps<StepConfig> {}

class SelectDateLocationStep extends React.PureComponent<Props> {
  setFlowData = (
    { address, addressType, date }: LocationFlowData,
    goToNextStep: () => void,
  ) =>
    this.props.setFlowData(
      { address, addressType, returnDate: date },
      goToNextStep,
    )

  render() {
    const {
      config: { daysToPickup, ...locationConfig },
      flowData: { address, returnDate },
      ...locationProps
    } = this.props

    return (
      <LocationStep
        titleLabel="myLeaseplan.newSetup.flows.return.steps.selectDateLocation.pageTitle"
        additionalComponents={[
          {
            Component: SelectDateField,
            config: { daysToPickup },
          },
        ]}
        config={locationConfig}
        flowData={{ address, date: returnDate }}
        {...locationProps}
        setFlowData={this.setFlowData}
      />
    )
  }
}

export { SelectDateLocationStep }
export default SelectDateLocationStep
