import React from 'react'
import { connect } from 'react-redux'
import { List, ListItem, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'

import Translation from 'mlp-client/src/localization/Translation'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { postMessage, PostMessageType } from 'mlp-client/src/auth/mobile-utils'
import { AppState } from 'mlp-client/src/types'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { GrayMainHeading } from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  isMobileApp?: boolean
}

type ReduxProps = Pick<Props, 'isMobileApp'>
export type OwnProps = Omit<Props, keyof ReduxProps>

class NotFound extends React.PureComponent<Props> {
  componentDidMount() {
    if (this.props.isMobileApp) {
      postMessage({ type: PostMessageType.NotFound })
    }
  }

  render() {
    return (
      <MyleaseplanPage
        metaTitle="404 Not Found"
        meta={[{ name: 'robots', content: 'noindex,follow' }]}
      >
        <Spacing pv="8">
          <Spacing mb={2}>
            <GrayMainHeading variant="500" withMarginBottom>
              <Translation id="myLeaseplan.error.notFound.title" />
            </GrayMainHeading>
          </Spacing>
          <Spacing mb={2}>
            <Text bold>
              <Translation id="myLeaseplan.error.notFound.subtitle" />
            </Text>
          </Spacing>
          <Spacing ml={2}>
            <List>
              <ListItem>
                <Translation id="myLeaseplan.error.notFound.bullet1" />
              </ListItem>
              <ListItem>
                <Translation id="myLeaseplan.error.notFound.bullet2" />
              </ListItem>
              <ListItem>
                <Translation id="myLeaseplan.error.notFound.bullet3" />
              </ListItem>
            </List>
          </Spacing>
        </Spacing>
      </MyleaseplanPage>
    )
  }
}

const mapStateToProps = (state: AppState): ReduxProps => ({
  isMobileApp: getIsMobileApp(state),
})

export { NotFound }
export default connect(mapStateToProps)(NotFound)
