import { Currencies } from 'mlp-client/src/localization/enums'
import {
  ContractOptionsListProperty,
  SectionProperty,
} from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { BookingRequestType } from 'mlp-client/src/types'

import {
  ContractTypes,
  DamageRecordStatus,
  NotificationType,
  NotificationStatus,
} from './enums'

export interface Tyres {
  contractTyreBrand: string
  contractTyreCategory: string
  contractTyreLimit: string
  standardTyreUsed: string
  winterTyreUsed: string
  contractTyrePickup: string
  contractTyreSize: string
  contractTyreType: string
  contractTyresModule: boolean
  seasonalTyreChange: boolean
  contractTyresModuleDescription?: string
  tyreStorage: boolean
  tyreStorageLocationName?: string
  vehicleTyreBrand: string
  vehicleTyreSize: string
  vehicleTyreType: string
  vehicleWinterSummerTyres: string
}

export interface VehicleHistory {
  fines?: readonly FinesRecord[]
  damages?: DamageRecords
  mostRecentRepair: string
  mostRecentReplacementRentalVehicle: string
  mostRecentService: string
  mostRecentTyreChange: string
}

export interface ContractInsurance {
  coverage: {
    description: string
    number: string
    insuranceCompany: string
    insuranceType: string
    validTo?: string
    validFrom?: string
  }
  fullDescription?: string
}

export interface Contract {
  id: string
  nolsId: string
  nolsDisplayNumber: string
  contractType: ContractTypes
  isAvailableForBooking: boolean
  isFuelCardsAvailable: boolean
  isInsuranceAvailable: boolean
  policyNumber?: string
  number: string
  objectId: string
  // We don't know exact fileds for this property
  additionalFields: GenericObject
  buybackAvailable: boolean
  damageModule: boolean
  damageModuleDescription?: string
  duration: string
  endDate: string
  // This field is used as endDate by LPFI
  simulatedEndDate: string
  fuelCards: boolean
  maintenanceModule: boolean
  maintenanceModuleDescription?: string
  taxationValue?: number
  environmentalTaxationValue?: number
  maximumAllowedMileage: string
  pickupAndDelivery: string
  replacementVehicleModule: boolean
  replacementVehicleModuleDescription?: string
  roadsideAssistanceModule: boolean
  roadsideAssistanceModuleDescription?: string
  startDate: string
  status: string
  type?: string
  fullTypeOfContract?: string
  generalLedgerCode: string
  insurance: ContractInsurance
  estimatedDeliveryDate?: string
  orderStatus?: string
  tyres?: Tyres
  vehicle: ContractVehicle
  vehicleHistory: VehicleHistory
  carWash?: string
  services?: Services
  isRetrievedFromNols?: boolean
}

export type Contracts = readonly Contract[]

export interface ContractVehicle {
  licenseRegistration: string
  actualYearlyMileage: string
  dateLastKnownMileage?: string
  co2?: number
  colour: string
  costCentre: string
  engineOil: string
  engineSize: string
  firstRegistrationDate: string
  nextInspectionDate: string
  fuelType: string
  lastKnownMileage: string
  lastKnownFuelMileage: string
  maintenanceInterval: string
  make: string
  model: string
  numberOfDoors: string
  ownerDetails: string
  performance: string
  type: string
  typeOfTransmission: string
  vin: string
  warrantyStartDate: string
  estimatedFuelKm?: number
}

export interface VehicleProperties {
  licensePlate: string
  make: string
  model: string
  type: string
  color: string
  fuel: string
  power: string
  vin: string
  co2?: number
  transmission?: string
  estimatedFuelKm?: number
}

export interface ContractProperties {
  number: string
  nolsDisplayNumber: string
  objectId: string
  startDate: string
  type: string
  endDate: string
  dateLastKnownMileage?: string
  maxMileage: number | string
  duration: number | string
  taxationValue?: number | string
  environmentalTaxationValue?: number | string
  lastKnownMileage?: number | string
  lastKnownFuelMileage?: number | string
  maintenanceModule?: string
  tyresModule?: string
  insuranceModule?: string
  fuelModule?: string
}

export interface InsuranceProperties {
  insuranceType: string
  number: string
  policyNumber?: string
  insuranceCompany: string
  fullDescription: string
  validTo?: string
  validFrom?: string
}

export interface TyreProperties {
  brand?: string
  category?: string
  limit?: string
  size?: string
  contractTyreBrand?: string
  contractTyreCategory?: string
  contractTyreLimit?: string
  standardTyreUsed?: string
  winterTyreUsed?: string
  remainingTyres?: string
  contractTyrePickup?: string
  contractTyreSize?: string
  vehicleTyreType?: string
  vehicleWinterSummerTyres?: string
  contractTyresModuleDescription?: string
}

export interface PolicyDocumentAttachment {
  id: string
  name: string
  contentType: string
}

export type PolicyDocumentAttachments = readonly PolicyDocumentAttachment[]

export interface PolicyDocument {
  id: string
  name: string
  attachments: PolicyDocumentAttachments
}

export type PolicyDocuments = readonly PolicyDocument[]

export interface RegistrationCertificate {
  globalDocumentId: string
  fileFormat: string
  fileName: string
}

export interface IncludedServices {
  maintenanceModule?: boolean
  replacementVehicleModule?: boolean
  carWash?: boolean
  roadsideAssistanceModule?: boolean
  contractTyresModule?: boolean
  tyreStorage?: boolean
  tyreStorageLocation?: {
    enabled: boolean
    locationName: string
  }
  isFuelCardsEnabled?: boolean
  pickUpAndDeliveryModule?: boolean
  damageService?: boolean
  radioLicenceFee?: boolean
}

export interface OptionsProperty {
  key: ContractOptionsListProperty
  title: string
  value: boolean
  description?: string
}

export type OptionListProperties = readonly OptionsProperty[]

export interface OptionTileInfo {
  title: string
  properties: OptionListProperties
}

export interface FinesRecord {
  date: string
  amount: number
  isoCurrencyCode: Currencies
  violationTypeDescription: string
  offenceDate: string
  offenceCountry: string
  offencePostalCode: string
  offenceTown: string
}

export type DamageRecords = readonly DamageRecord[]

export interface DamageRecord {
  date: string
  description: string
  number: string
  status: DamageRecordStatus
}

interface NotificationDetails {
  caseId: string
  predictedMileage: number
  lastKnownMileage?: number
}

export interface Notification {
  notificationDetails: NotificationDetails
  contractId: string
  notificationType: NotificationType
  status?: NotificationStatus
}

export interface Service {
  serviceNumber: string
  description: string
}

export type Services = readonly Service[]

interface Property {
  key: SectionProperty
  value: React.ReactChild
  // Use this flag to indicate that the value is translation key
  // and its should be translated on render.
  translateValue?: boolean
  included?: boolean
}

interface LabeledProperty extends Property {
  label: string
}

export interface ParagraphProperty extends Property {}

export type ListProperty = LabeledProperty | ParagraphProperty

export type ListProperties = readonly ListProperty[]

export const FUEL_MANAGEMENT_TYPE = 'Fuel Management'
export const OCS_FLEET_MANAGEMENT = 'OCS Fleet Management'

export interface IsAnySupplierAvailableRequestData {
  street: string
  city: string
  houseNumber?: string
  postcode: string
  country: string
  contractId: string
  locale: string
  services: readonly string[]
  bookingType: BookingRequestType
  selectedMalfunctions?: string[]
  mileage?: string
}
