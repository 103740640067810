import { Routes } from 'mlp-client/src/localization/types'

export const enum ActionType {
  SET_ROUTES = '@@localization/SET_ROUTES',
  SET_LOCATION = '@@localization/SET_LOCATION',
  SWITCH_LOCALE = '@@localization/SWITCH_LOCALE',
}

export class SetLocation {
  readonly type = ActionType.SET_LOCATION

  constructor(public payload: { pathname: string }) {}
}

export class SetRoutes {
  readonly type = ActionType.SET_ROUTES

  constructor(public payload: { routes: Routes }) {}
}

export class SwitchLocale {
  readonly type = ActionType.SWITCH_LOCALE

  constructor(public payload: { locale: string }) {}
}

export type Actions = SetRoutes | SetLocation | SwitchLocale
