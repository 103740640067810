export const saveFile = (
  blob: BlobPart,
  fileName: string,
  documentType: string,
) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: documentType })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)

    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')

  link.href = data
  link.download = fileName
  link.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window }),
  )
  window.URL.revokeObjectURL(data)
}

export const createBlob = (fileContents: string, contentType: string) => {
  const byteString = window.atob(fileContents)
  const arrayBuffer = new ArrayBuffer(byteString.length)

  const int8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; ++i) {
    int8Array[i] = byteString.charCodeAt(i)
  }

  return new Blob([int8Array], { type: contentType })
}

export const downloadFile = (url: string, name: string) => {
  const element = document.createElement('a')

  element.href = url
  element.download = name
  element.target = 'self'
  element.click()
}
