import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const TyresIconLeasys: FC<IconProps> = props => {
  TyresIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g fill={ColorEnumSelector(color)}>
        <g>
          <path
            d="M34.54,28.02c0,1.42-1.15,2.57-2.57,2.57c-1.42,0-2.57-1.15-2.57-2.57c0-1.42,1.15-2.57,2.57-2.57
               C33.39,25.45,34.54,26.6,34.54,28.02 M23.43,20.12c0,0.86-0.7,1.56-1.56,1.56H6.52c-0.86,0-1.56-0.7-1.56-1.56v-2.59
               c0-0.86,0.7-1.56,1.56-1.56h15.35c0.86,0,1.56,0.7,1.56,1.56V20.12z M23.43,12.41c0,0.86-0.7,1.56-1.56,1.56H6.52
               c-0.86,0-1.56-0.7-1.56-1.56V9.83c0-0.86,0.7-1.56,1.56-1.56h15.35c0.86,0,1.56,0.7,1.56,1.56V12.41z M4.96,25.23
               c0-0.86,0.7-1.56,1.56-1.56h15.35c0.21,0,0.4,0.04,0.58,0.12c-0.57,1.29-0.9,2.72-0.9,4.23c0,0.46,0.04,0.92,0.1,1.36H6.52
               c-0.86,0-1.56-0.7-1.56-1.56V25.23z M4.96,32.94c0-0.86,0.7-1.56,1.56-1.56h15.35c0.09,0,0.18,0.04,0.27,0.05
               c0.32,0.91,0.75,1.76,1.29,2.54v1.55c0,0.86-0.7,1.56-1.56,1.56H6.52c-0.86,0-1.56-0.7-1.56-1.56V32.94z M42.39,28.02
               c0-5.76-4.67-10.42-10.42-10.42c-2.48,0-4.75,0.87-6.54,2.31v-2.39c0-1-0.42-1.91-1.1-2.56c0.67-0.65,1.1-1.55,1.1-2.56V9.83
               c0-1.96-1.6-3.56-3.56-3.56H6.52c-1.96,0-3.56,1.6-3.56,3.56v2.59c0,1,0.42,1.91,1.1,2.56c-0.67,0.65-1.1,1.55-1.1,2.56v2.59
               c0,1,0.42,1.91,1.1,2.56c-0.67,0.65-1.1,1.55-1.1,2.56v2.59c0,1.01,0.42,1.91,1.1,2.56c-0.67,0.65-1.1,1.55-1.1,2.56v2.59
               c0,1.96,1.6,3.56,3.56,3.56h15.35c1.77,0,3.23-1.31,3.5-3.01c1.8,1.47,4.09,2.36,6.6,2.36C37.73,38.44,42.39,33.77,42.39,28.02"
          />
        </g>
      </g>
    </svg>
  )
}

export default TyresIconLeasys
