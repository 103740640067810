import React from 'react'
import { Headline } from '@velocity/ui'
import { AccordionDetails } from '@material-ui/core'
import { camelCase } from 'camel-case'
import { useSelector } from 'react-redux'

import { StyledCardChild } from 'mlp-client/src/timeline/components/TimelineCard/TimelineCard.styled'
import Translation from 'mlp-client/src/localization/Translation'
import { CardTag } from 'mlp-client/src/components/card-tag/CardTag'
import { TimelineItem } from 'mlp-client/src/timeline/types'
import {
  formatProcessPhase,
  getColorFromStatus,
  extractNumber,
  getReportDamagePhase,
  caseSubcategorySelector,
} from 'mlp-client/src/timeline/utils'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { AppState } from 'mlp-client/src/types'

interface Props {
  childCase: TimelineItem
}

export const TimelineChildCard: React.FC<Props> = ({ childCase }) => {
  const processPhaseNumber = extractNumber(childCase?.processPhase)

  const translatedProcessPhase = useSelector((state: AppState) =>
    getTranslation(
      state,
      `myLeaseplan.timeline.${caseSubcategorySelector(
        childCase?.subcategory,
      )}.processPhase${
        childCase?.subcategory?.includes('Report')
          ? getReportDamagePhase(childCase?.processPhase)
          : processPhaseNumber
      }`,
      formatProcessPhase(childCase?.processPhase),
    ),
  )

  const renderCard = () => (
    <StyledCardChild>
      <Headline variant="100" component="h6">
        {`${translatedProcessPhase}`}
      </Headline>
      <Headline variant="100" component="h6">
        <CardTag
          backgroundColor={getColorFromStatus(childCase?.status)}
          dataE2eId={`${camelCase(childCase?.status)}Status`}
        >
          <Translation
            id={`myLeaseplan.timeline.status.${camelCase(childCase?.status)}`}
          />
        </CardTag>
      </Headline>
    </StyledCardChild>
  )

  return <AccordionDetails>{renderCard()}</AccordionDetails>
}
