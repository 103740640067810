import { Button, ButtonProps } from '@leaseplan/ui'
import styled from 'styled-components'

import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

export const RevealButton = styled(Button).attrs<ButtonProps>({
  variant: 'transparent',
  stretch: true,
})`
  text-transform: none;
  border-width: 0;
  color: ${ColorEnumSelector('petrolBlue')};
  justify-content: center;

  &:focus {
    outline-width: 0;
  }
  & svg {
    margin: 0.2em 0 0 0.2em;
  }

  &:hover {
    color: ${isLeaseplan() ? '' : ColorEnumSelector('bloodOrange')} !important;
  }
`
