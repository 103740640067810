import React from 'react'
import { Color } from '@leaseplan/ui'

interface DefaultProps {
  width: number
  height: number
  color: Color
}

type Props = Partial<DefaultProps>

class OrderAndDeliveryIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    width: 125,
    height: 125,
    color: Color.midOrange,
  }

  render() {
    const { width, height, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 250 250"
        width={width}
        height={height}
      >
        <g fillRule="evenodd" fill={color}>
          <path d="M154.2 92L121 96.6l-1.3-9a2.5 2.5 0 1 0-4.9.7l1.2 9-22.5 3.1a2.5 2.5 0 1 0 .7 5l60.8-8.4a2.5 2.5 0 1 0-.7-5m-22.8 14.7l-9.1 1.3a2.5 2.5 0 1 0 .6 4.9l9.2-1.3a2.5 2.5 0 0 0-.7-4.9m49 6.8a5.2 5.2 0 1 0 .4 10.4 5.2 5.2 0 0 0-.3-10.4M84 126.8a5.2 5.2 0 1 0 .4 10.3 5.2 5.2 0 0 0-.4-10.3" />
          <path d="M216.3 109.5a7.3 7.3 0 0 1-4.8 2.9l-13.7 1.9a16.2 16.2 0 0 0-31.6 4.4l-64.7 8.8a16.1 16.1 0 0 0-16-11.4c-8.6.3-15.4 7.3-15.7 15.7l-8.7 1.2a5 5 0 0 1-3.7-1 5 5 0 0 1-2-3.2l-.9-7 4-.6c4.7-.6 10-3.3 8.8-12.1l-.8-5.8 21.5-16A22 22 0 0 1 98.2 83l32.2-4.4a48.3 48.3 0 0 1 26 4.2l17.4 8 .9.2 20.3 1.6c.3 2.2 1.3 5.3 4 7.9 3 2.8 7.2 4.2 12.6 4.2 1.7 0 3.4-.2 5.3-.4l.9-.1c.2 1.8-.3 3.7-1.5 5.2zm-25.7 17.3c-2 2.2-4.9 3.5-7.9 3.5a11.2 11.2 0 1 1-.7-22.4h.4a11.3 11.3 0 0 1 8.2 19zM99 142.1c2-2.8 3.2-6.2 3.2-9.7l64.6-8.9c1 3.3 2.8 6.2 5.4 8.3h-7.9-.3zm109.1-5.3a82.9 82.9 0 0 1-7.4 25H165.5a16.1 16.1 0 0 0-17-13.4 16.1 16.1 0 0 0-14.9 13.3h-8a16 16 0 0 0-16.9-13.3 16.1 16.1 0 0 0-15 13.3H46v-6.1l118.5-18.8zm-19.5 43.4H154c6.2-1.8 10.8-7.1 11.6-13.5h32.5a83.9 83.9 0 0 1-9.5 13.5zm-63.2 29c-22 0-43-8.7-58.6-24h117.1a83.2 83.2 0 0 1-58.5 24zm13.4-32.5c1.8 1.6 4 2.8 6.2 3.5h-30.8c6.2-1.8 10.8-7.1 11.6-13.5h7.7c.5 3.9 2.4 7.5 5.3 10zm22-12.4a11.2 11.2 0 1 1 0-.4v.4zm-62.3 1a11.3 11.3 0 1 1 22.5-1.4 11.3 11.3 0 0 1-22.5 1.4zm-23.7-32.6a11.3 11.3 0 1 1 22.5-.7 11.3 11.3 0 0 1-22.5.7zm-21-15.9l-.3-2.2a7 7 0 0 1 5-7.7l3.4-1 .5 3.9c.6 4.2-.6 6-4.6 6.5zm151.7-23.2c3.2.6 9.4 2.7 11.3 5.8h-.6c-6.3 1-11 0-13.7-2.4a8.1 8.1 0 0 1-2.4-3.9zm16.7 6.5c-1.1-8.4-14.4-11.2-15.9-11.5h-.2l-8.6-.7-22-1.7-17-7.8c-1.1-.6-13.6-6.7-28.8-4.6l-32.2 4.4A27 27 0 0 0 85 83.3l-23.2 17.4-4.7 1.4a12 12 0 0 0-8.5 13.2l.6 4.6v.1l1.4 9.5a9.9 9.9 0 0 0 11.2 8.5l8.7-1.2c1 3.6 3.2 6.7 6.3 8.8L43 151a2.5 2.5 0 0 0-2.1 2.4v10.8c0 1.4 1 2.5 2.5 2.5h50.1a16 16 0 0 0 11.6 13.5h-43c-2.2-2.5-4.1-5-6-7.7a2.5 2.5 0 1 0-4.1 2.8A88.5 88.5 0 0 0 214 125.7a2.5 2.5 0 1 0-5 0l-.3 6h-16.1a16 16 0 0 0 6-12.5l13.7-1.9a12.2 12.2 0 0 0 10.5-13.9l-.5-3.3zm0 0" />
          <path d="M42.7 142.9a83.6 83.6 0 0 1 153.6-60 2.5 2.5 0 0 0 4.3-2.6A88.6 88.6 0 0 0 37.8 144a2.5 2.5 0 0 0 4.9-1m198.5-71.4c-1-1-2.5-1-3.5-.2l-12.8 11.8a2.5 2.5 0 1 0 3.4 3.7L241.1 75c1-1 1-2.5.1-3.5m1.1 25.5l-10.1-.7a2.5 2.5 0 1 0-.4 5l10.2.7a2.5 2.5 0 1 0 .3-5m-29.7-16.2c1.4 0 2.5-1 2.7-2.3l.7-10.2a2.5 2.5 0 1 0-5-.3l-.7 10.1c-.1 1.4 1 2.6 2.3 2.7m-99.4 83a5.2 5.2 0 1 0-10.4.7 5.2 5.2 0 0 0 10.4-.6m34.3-4.9a5.2 5.2 0 1 0 .6 10.4 5.2 5.2 0 0 0-.6-10.4" />
        </g>
      </svg>
    )
  }
}

export default OrderAndDeliveryIcon
