import { DisabledDay, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { contractEndDateSelector } from 'mlp-client/src/contracts/selectors'
import { Weekday } from 'mlp-client/src/enums'
import { renderCurrentEndDate } from 'mlp-client/src/flows/return/utils'
import { DatePickerField } from 'mlp-client/src/form/components/fields/DatePickerFields'
import {
  dateNotBefore,
  dateNotOnWeekends,
  invalidDate,
  required,
} from 'mlp-client/src/form/validations'
import { getCountryHolidays } from 'mlp-client/src/selectors'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { GrayDark80Text } from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  contractEndDate: string
  datePlaceholder: string
  todayDate: string
  publicHolidays: DisabledDay[]
  config: {
    daysToPickup: number
  }
  editClickHandler(): void
}

const translatePath = makeTranslationPath(
  'myLeaseplan.newSetup.flows.return.steps.selectDateLocation',
)
const translateFormPath = makeTranslationPath(
  'myLeaseplan.newSetup.shared.forms',
)

class SelectDateField extends React.PureComponent<Props> {
  render() {
    const {
      contractEndDate,
      publicHolidays,
      todayDate,
      config: { daysToPickup },
    } = this.props

    return (
      <>
        <Spacing mb={2}>
          <Text>
            <Translation
              id={translatePath('returnNote')}
              replace={{ value: daysToPickup }}
            />
          </Text>
        </Spacing>
        <Spacing mb={2}>
          <Headline variant="200" withMarginBottom>
            <Translation id={translatePath('dateGroupTitle')} />
          </Headline>
          <DatePickerField
            name="date"
            placeholder={{
              id: 'myLeaseplan.genericNotifications.datePlaceholder',
            }}
            validations={[
              invalidDate,
              dateNotBefore(new Date(todayDate)),
              dateNotOnWeekends,
              required,
            ]}
            disabledDays={[
              {
                message: translateFormPath('date.validations.dateNotInPast'),
                before: new Date(todayDate),
              },
              {
                daysOfWeek: [Weekday.Saturday, Weekday.Sunday],
                message: translateFormPath(
                  'date.validations.notDuringWeekends',
                ),
              },
              ...publicHolidays,
            ]}
          />
          <GrayDark80Text component="p" variant="100">
            <Translation id={translatePath('contractEndDate')} />{' '}
            {renderCurrentEndDate(contractEndDate)}
          </GrayDark80Text>
        </Spacing>
      </>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'contractEndDate' | 'publicHolidays' | 'todayDate'
>
type DispatchProps = Pick<Props, never>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState): ReduxProps => ({
  todayDate: moment().startOf('day').format(),
  contractEndDate: contractEndDateSelector(state),
  publicHolidays: getCountryHolidays(state),
})

export { SelectDateField }
export default connect<ReduxProps, DispatchProps, OwnProps>(mapStateToProps)(
  SelectDateField,
)
