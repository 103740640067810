import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Classnames from 'classnames'

import DropdownPanel from 'mlp-client/src/components/layout/top-navigation/desktop/DropdownPanel'
import Flag from 'mlp-client/src/localization/Flag'
import { ChevronDownIcon } from 'mlp-client/src/components/icons'
import { getLocaleParams } from 'mlp-client/src/localization/selectors'
import { trimZerosCountryCode } from 'mlp-client/src/utils'
import { GrayDark80Text } from 'mlp-client/src/components/styled/TextStyle'

import CountryList from './CountryList'
import { StyledButton } from './PhoneInput.styled'
import { CountryCodeInfo, CountryPhoneCodes } from './types'
import { SORTED_COUNTRIES } from './constants'

interface Props {
  onChange: (value: string) => void
  value: string
  fieldName: string
}

const CountryListButton: React.FC<Props> = ({ onChange, value, fieldName }) => {
  const { countryCode } = useSelector(getLocaleParams)
  const initialCountryCodeInfo: CountryCodeInfo = SORTED_COUNTRIES.find(
    ({ countryPhoneCode }) => countryPhoneCode === trimZerosCountryCode(value),
  ) || {
    flagName: countryCode,
    countryPhoneCode: CountryPhoneCodes[countryCode.toUpperCase()],
  }
  const [countryCodeInfo, setCountryCodeInfo] = useState<CountryCodeInfo>(
    initialCountryCodeInfo,
  )
  const [showCountryList, setShowCountryList] = useState(false)

  const mainElement = useRef<HTMLElement>()

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        !mainElement.current.contains(e.target as Element) &&
        showCountryList
      ) {
        setShowCountryList(false)
      }
    },
    [showCountryList],
  )

  useEffect(() => {
    if (!value) {
      onChange(`00${initialCountryCodeInfo.countryPhoneCode}`)
    }

    window.addEventListener('click', handleClickOutside)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [
    handleClickOutside,
    initialCountryCodeInfo.countryPhoneCode,
    onChange,
    value,
  ])

  const countryListButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()

    setShowCountryList(state => !state)
  }

  const attachMainElement = (element: HTMLElement) => {
    mainElement.current = element
  }

  const classNamesDownIcon = Classnames('icon dropdown-menu-item__chevron', {
    'dropdown-menu-item__chevron--open': showCountryList,
  })

  return (
    <div ref={attachMainElement} className="phone-input-anchor">
      <StyledButton
        variant="transparent"
        stretch={true}
        onClick={countryListButtonHandler}
        data-e2e-id={`${fieldName}CodeButton`}
        data-tag-id="button-toggle-countryList.myLeasePlan"
      >
        <Flag
          code={countryCodeInfo.flagName}
          label={countryCodeInfo.flagName}
        />
        <ChevronDownIcon
          className={classNamesDownIcon}
          title="Show country list"
        />
        <GrayDark80Text>+{countryCodeInfo.countryPhoneCode}</GrayDark80Text>
      </StyledButton>
      {showCountryList && (
        <DropdownPanel open={showCountryList}>
          <CountryList
            onChange={onChange}
            countryCodeInfo={countryCodeInfo}
            setCountryCodeInfo={setCountryCodeInfo}
            setShowCountryList={setShowCountryList}
            dataE2eId={`${fieldName}CodeDropdownPanel`}
          />
        </DropdownPanel>
      )}
    </div>
  )
}

export default CountryListButton
