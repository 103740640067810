import { ImageFile } from 'react-dropzone'

import {
  File as FileType,
  FileUploadResponse,
} from 'mlp-client/src/form/components/fields/file-upload-field/types'

export const enum ActionTypes {
  SUBMIT_FILE_UPLOAD = '@@myLeaseplan/form/lpis/SUBMIT_FILE_UPLOAD_REQUEST',
  FILE_UPLOAD_PROGRESS = '@@myLeaseplan/form/lpis/FILE_UPLOAD_PROGRESS',
  FILE_UPLOAD_SUCCESS = '@@myLeaseplan/form/lpis/FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAILURE = '@@myLeaseplan/form/lpis/FILE_UPLOAD_FAILURE',
  REMOVE_FILE = '@@myLeaseplan/form/lpis/REMOVE_FILE',
  ADD_FILES = '@@myLeaseplan/form/lpis/ADD_FILES',
}

export class SubmitFileUpload {
  readonly type = ActionTypes.SUBMIT_FILE_UPLOAD

  constructor(
    public payload: {
      file: ImageFile
      meta: FileType
    },
  ) {}
}

export class FileUploadProgress {
  readonly type = ActionTypes.FILE_UPLOAD_PROGRESS

  constructor(
    public payload: {
      meta: FileType
      progress: number
    },
  ) {}
}

export class FileUploadSuccess {
  readonly type = ActionTypes.FILE_UPLOAD_SUCCESS

  constructor(
    public payload: {
      meta: FileType
      result: FileUploadResponse
    },
  ) {}
}

export class FileUploadFailure {
  readonly type = ActionTypes.FILE_UPLOAD_FAILURE

  constructor(
    public payload: {
      meta: FileType
      err: Error
    },
  ) {}
}

export class RemoveFile {
  readonly type = ActionTypes.REMOVE_FILE

  constructor(
    public payload: {
      fileId: string
    },
  ) {}
}

export class AddFiles {
  readonly type = ActionTypes.ADD_FILES

  constructor(
    public payload: {
      meta: FileType[]
    },
  ) {}
}

export type Action =
  | SubmitFileUpload
  | FileUploadProgress
  | FileUploadSuccess
  | FileUploadFailure
  | RemoveFile
  | AddFiles
