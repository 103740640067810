import { TyreServiceTypes } from 'mlp-client/src/flows/tyres/enums'
import { Options } from 'mlp-client/src/form/types'

interface Props {
  tyreServices: Options
  isSeasonalTyreChangeEnabled: boolean
}

export const filterTyreServicesOnSeasonalChange = ({
  tyreServices,
  isSeasonalTyreChangeEnabled,
}: Props): Options =>
  !isSeasonalTyreChangeEnabled
    ? tyreServices.filter(
        service => service.value !== TyreServiceTypes.SEASONAL_TYRE_CHANGE,
      )
    : tyreServices
