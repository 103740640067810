import { QuotesStatuses } from 'mlp-client/src/quotes/enums'

export const QUOTE_TYPE = 'Quote'

export const QUOTE_STATUSES_COLORS_CONFIG = {
  [QuotesStatuses.APPROVED]: {
    background: '#E5FAEB',
    color: '#00AB12',
  },
}
