import { ValidationState } from '@leaseplan/ui'
import { FieldMetaState } from 'react-final-form'

import { StringValidations, TranslationType } from './types'

export const determineValidationState = (
  meta: FieldMetaState<any>,
): ValidationState => {
  if (meta.pristine && !meta.touched) {
    return undefined
  }

  if ((meta.touched || meta.modified) && meta.error) {
    return 'error'
  }

  if (meta.valid) {
    return 'valid'
  }

  return undefined
}

export const getErrorMessage = (
  meta: FieldMetaState<any>,
  translate: (
    id: TranslationType['id'],
    replace?: TranslationType['replaceValues'],
  ) => string,
): string =>
  meta.touched && meta.error && translate(meta.error.id, meta.error.replace)

export const validateString = (validations: StringValidations) =>
  composeValidators(validations)

export const composeValidators: <T>(
  validators: ReadonlyArray<(value: T) => TranslationType>,
) => (value: T) => TranslationType = validators => value =>
  validators.reduce<TranslationType>(
    (error, validator) => error || validator(value),
    undefined,
  )

export const addSelectedValue = (
  value: string = '',
  valueToAdd: string,
): string =>
  value.length ? [...value.split(','), valueToAdd].join(',') : valueToAdd

export const hasSelectedValue = (
  value: string = '',
  valueToSearch: string,
): boolean => value.split(',').includes(valueToSearch)

export const onlyNumbersAndSpaces = (value: string = '') =>
  value.replace(/[^0-9 \,]/, '')

export const removeSelectedValue = (
  value: string = '',
  valueToRemove: string,
): string =>
  value
    .split(',')
    .filter(val => val !== valueToRemove)
    .join(',')

export const onlyNumbers = (value: string = '') => value.replace(/\D/g, '')

export const onlyNumbersAndFractional = (value: string = ''): string =>
  value
    .replace(/,/g, '.')
    .replace(/[^0-9.]/g, '')
    .replace(/(.*\..*)\./, '$1')
