import {
  CashClaimIcon,
  DamageIcon,
  DocumentsIcon,
  FinesIcon,
  FuelCardIcon,
  FuelHistoryIcon,
  GlassIcon,
  MaintenanceIcon,
  MileageIcon,
  ReturnIcon,
  TyresIcon,
} from '..'
import { IconTypes } from '../types'

export const defaultIcons: IconTypes = {
  maintenance: MaintenanceIcon,
  tyre: TyresIcon,
  cashClaim: CashClaimIcon,
  damage: DamageIcon,
  documents: DocumentsIcon,
  fines: FinesIcon,
  fuelCard: FuelCardIcon,
  fuelHistory: FuelHistoryIcon,
  glass: GlassIcon,
  mileage: MileageIcon,
  return: ReturnIcon,
}
