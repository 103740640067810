import { Radio, Text } from '@leaseplan/ui'
import React from 'react'
import { Field } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StringValidations, TranslationType } from 'mlp-client/src/form/types'
import { validateString } from 'mlp-client/src/form/utils'

interface Props {
  name: string
  value: string
  label?: TranslationType
  disabled?: boolean
  validations?: StringValidations
}

const RadioField: React.FC<Props> = ({
  name,
  value,
  label,
  disabled,
  validations = [],
}) => (
  <LocalizationContext.Consumer>
    {({ translate }) => (
      <Field
        name={name}
        type="radio"
        value={value}
        validate={validateString(validations)}
      >
        {({ input }) => (
          <Radio
            disabled={disabled}
            size="s"
            {...input}
            checked={input.checked || false}
            onChange={({ value }) => input.onChange(value)}
            label={
              label && (
                <Text component="span" size="s" data-e2e-id={value}>
                  {translate(label.id, label.replaceValues)}
                </Text>
              )
            }
          />
        )}
      </Field>
    )}
  </LocalizationContext.Consumer>
)

export default RadioField
