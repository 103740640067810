import { Textarea } from '@leaseplan/ui'
import React from 'react'
import { Field } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StringValidations, TranslationType } from 'mlp-client/src/form/types'
import {
  determineValidationState,
  getErrorMessage,
  validateString,
} from 'mlp-client/src/form/utils'
import { maxLength as maxLengthValidation } from 'mlp-client/src/form/validations'

interface Props {
  name: string
  label?: TranslationType
  validations?: StringValidations
  legalNote?: TranslationType
  placeholder?: TranslationType
  maxLength?: number
  dataE2eId?: string
}

export default ({
  name,
  validations,
  legalNote,
  placeholder,
  label,
  maxLength = 5000,
  dataE2eId = name,
}: Props) => (
  <LocalizationContext.Consumer>
    {({ translate }) => {
      const validators = [
        ...(validations || []),
        maxLengthValidation(maxLength),
      ]

      const getMessage = (value: string) => {
        const charactersLeft = maxLength - value.length

        return translate('myLeaseplan.shared.textareaField.charactersLeft', [
          {
            key: 'number',
            value: String(charactersLeft),
          },
        ])
      }

      return (
        <Field name={name} validate={validators && validateString(validators)}>
          {({ input, meta }) => (
            <Textarea
              {...input}
              label={label && translate(label.id, label.replaceValues)}
              onChange={e => input.onChange(e.value)}
              legalNote={
                legalNote && translate(legalNote.id, legalNote.replaceValues)
              }
              validationState={determineValidationState(meta)}
              hintMessage={getMessage(input.value)}
              errorMessage={meta.error && getErrorMessage(meta, translate)}
              placeholder={
                placeholder &&
                translate(placeholder.id, placeholder.replaceValues)
              }
              data-e2e-id={dataE2eId}
            />
          )}
        </Field>
      )
    }}
  </LocalizationContext.Consumer>
)
