import { CancellationReasons } from './types'

export const enum ActionTypes {
  DELETE_PREDICTIVE_MAINTENANCE_CASE = '@@myLeaseplan/predictiveMaintenance/DELETE_PREDICTIVE_MAINTENANCE_CASE',
  DELETE_PREDICTIVE_MAINTENANCE_CASE_SUCCESS = '@@myLeaseplan/predictiveMaintenance/DELETE_PREDICTIVE_MAINTENANCE_CASE_SUCCESS',
  DELETE_PREDICTIVE_MAINTENANCE_CASE_FAILED = '@@myLeaseplan/predictiveMaintenance/DELETE_PREDICTIVE_MAINTENANCE_CASE_FAILED',
}

export class DeletePredictiveMaintenanceCaseRequest {
  readonly type = ActionTypes.DELETE_PREDICTIVE_MAINTENANCE_CASE

  constructor(
    public payload: {
      caseId: string
      mileage: string
      cancellationReason: CancellationReasons
      onComplete(error: boolean): void
      loadNotifications?: boolean
    },
  ) {}
}

export class DeletePredictiveMaintenanceCaseSuccess {
  readonly type = ActionTypes.DELETE_PREDICTIVE_MAINTENANCE_CASE_SUCCESS
}

export class DeletePredictiveMaintenanceCaseFailed {
  readonly type = ActionTypes.DELETE_PREDICTIVE_MAINTENANCE_CASE_FAILED
}

export type Action =
  | DeletePredictiveMaintenanceCaseRequest
  | DeletePredictiveMaintenanceCaseSuccess
  | DeletePredictiveMaintenanceCaseFailed
