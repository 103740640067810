import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  BuyFlow,
  ConfirmStep,
  DateStep,
  ThankYouStep,
  UpdateMileageStep,
} from 'mlp-client/src/flows/buy/components'
import { StepNames } from 'mlp-client/src/flows/buy/enums'
import { BuyFlowConfiguration, Steps } from 'mlp-client/src/flows/buy/types'
import { getFormName } from 'mlp-client/src/utils'
import { EventCategoryFlow } from 'mlp-client/src/analytics/types'

const flowName = EventCategoryFlow.BUY

const allSteps: Steps = {
  [StepNames.UPDATE_MILEAGE]: {
    stepView: UpdateMileageStep,
    stepConfig: { flowName },
  },
  [StepNames.DATE]: {
    stepView: DateStep,
    stepConfig: {
      disableDatesBeforeEndOfContract: true,
      beforeContractDateMessage: { enabled: false },
      afterContractDateMessage: { enabled: false },
    },
  },
  [StepNames.CONFIRM]: {
    stepView: ConfirmStep,
    stepConfig: {},
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {},
  },
}

const flowStepForms = [StepNames.UPDATE_MILEAGE].map(stepName =>
  getFormName(flowName, stepName),
)

export const buyVehicleFeature: MyLeaseplanFeature<BuyFlowConfiguration> = {
  enabled: true,
  view: BuyFlow,
  configuration: {
    getSteps: () => [
      StepNames.UPDATE_MILEAGE,
      StepNames.DATE,
      StepNames.CONFIRM,
      StepNames.THANK_YOU,
    ],
    getStepForms: () => flowStepForms,
    allSteps,
  },
}
