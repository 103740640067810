import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RouteComponentProps, withRouter } from 'react-router'

import { ChevronDownIcon } from 'mlp-client/src/components/icons'
import DropdownPanel from 'mlp-client/src/components/layout/top-navigation/desktop/DropdownPanel'
import CurrentLocale from 'mlp-client/src/components/layout/top-navigation/desktop/CurrentLocale'
import { AppState } from 'mlp-client/src/types'
import {
  getCurrentCountryLabel,
  getLocaleAvailableLanguages,
  getCountry,
} from 'mlp-client/src/localization/selectors'
import Flag from 'mlp-client/src/localization/Flag'
import Translation from 'mlp-client/src/localization/Translation'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'
import { buildUrlForLanguage } from 'mlp-client/src/components/layout/top-navigation/utils'
import { Language } from 'mlp-client/src/localization/types'
import { MainMenuTopNavigation } from 'mlp-client/src/analytics/actions'

export interface Props extends RouteComponentProps {
  availableLanguages: readonly Language[]
  currentCountryLabel: string
  country: string
  trackMainMenuTopNavigation?(eventLabel: string): void
}

interface State {
  open: boolean
}

class LanguageSelector extends React.PureComponent<Props, State> {
  mainElement: HTMLElement
  state = { open: false }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside)
  }

  closePanel = () => {
    this.setState({ open: false })
  }

  attachMainElement = (element: HTMLElement) => {
    this.mainElement = element
  }

  handleClickOutside = (e: MouseEvent) => {
    if (!this.mainElement.contains(e.target as Element) && this.state.open) {
      this.closePanel()
    }
  }

  handleButtonClick = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  handleLanguageSelect = (
    event: React.MouseEvent<HTMLAnchorElement>,
    label?: string,
  ) => {
    const { location, country, trackMainMenuTopNavigation } = this.props
    const { language } = event.currentTarget.dataset
    const eventLabel = `Language - ${label}`

    trackMainMenuTopNavigation(eventLabel)
    window.location.replace(buildUrlForLanguage(location, country, language))
  }

  renderAvailableLanguages = (preferredLanguageCode: string) => ({
    label,
    code: language,
  }: Language) => (
    <li key={label} className="dropdown-menu-item__item">
      <a
        onClick={e => this.handleLanguageSelect(e, label)}
        data-language={language}
        className={Classnames('main-menu-widgets__link --larger', {
          'dropdown-menu-item__item--active-flag':
            preferredLanguageCode === language,
        })}
      >
        {label}
      </a>
    </li>
  )

  render() {
    return (
      <TopNavigationContext.Consumer>
        {({ parsedLocale: { country, language } }) => {
          const { availableLanguages, currentCountryLabel } = this.props
          const { open } = this.state

          return (
            <div ref={this.attachMainElement}>
              <button
                className="main-menu-widgets__link u-hide-until@lap"
                type="button"
                onClick={this.handleButtonClick}
                aria-expanded={open}
                data-e2e-id="navMenuLanguageSelectorButton-myLeasePlan"
                data-tag-id="button-toggle-languageSelector.myLeasePlan"
              >
                <CurrentLocale className="main-menu-widgets__link u-hide-until@lap" />
                <ChevronDownIcon
                  className={Classnames(
                    'icon u-margin-left-small dropdown-menu-item__chevron',
                    { 'dropdown-menu-item__chevron--open': open },
                  )}
                  title="Open menu"
                />
              </button>
              <div className="u-hide-until@lap">
                <DropdownPanel open={open}>
                  <ul className="o-list-bare u-padding-horizontal u-padding-vertical-tiny sub-menu-item">
                    <li className="main-menu-item-dropdown__item--label">
                      <Translation id="myLeaseplan.languageSelector.location" />
                    </li>
                    <li className="main-menu-widgets__link --larger text--capitalize --no-link current-locale">
                      <Flag code={country} label={country} />
                      <span>{currentCountryLabel}</span>
                    </li>
                    <li className="main-menu-item-dropdown__item--label">
                      <Translation id="myLeaseplan.languageSelector.language" />
                    </li>
                    {availableLanguages?.map(
                      this.renderAvailableLanguages(language),
                    )}
                  </ul>
                </DropdownPanel>
              </div>
            </div>
          )
        }}
      </TopNavigationContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'currentCountryLabel' | 'availableLanguages' | 'country'
>
type DispatchProps = Pick<Props, 'trackMainMenuTopNavigation'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  currentCountryLabel: getCurrentCountryLabel,
  availableLanguages: getLocaleAvailableLanguages,
  country: getCountry,
})

export const mapDispatchToProps: DispatchProps = {
  trackMainMenuTopNavigation: (eventLabel: string) =>
    new MainMenuTopNavigation({ eventLabel }),
}

export { LanguageSelector }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LanguageSelector))
