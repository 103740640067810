import { Paper } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import Price from 'mlp-client/src/localization/Price'
import { Currencies, Locales } from 'mlp-client/src/localization/enums'
import { ProgressBarWidget } from 'mlp-client/src/components/progress-bar/ProgressBarWidget'
import { ContentWrapper } from 'mlp-client/src/contracts/components/ContractPropertiesTable.styled'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { FuelSpendingRecord } from 'mlp-client/src/flows/fuel-history/types'
import { formatPrice } from 'mlp-client/src/localization/utils'
import { getLocale } from 'mlp-client/src/localization/selectors'
import {
  ErrorText,
  GrayDark80Text,
} from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  fuelSpendingRecord: FuelSpendingRecord
  locale: Locales
}

const translatePath = makeTranslationPath('myLeaseplan.fuelHistory.widget')

class FuelSpendingWidget extends React.PureComponent<Props> {
  render() {
    const {
      fuelSpendingRecord: { invoiced, costs, balance, isoCurrencyCode },
      locale,
    } = this.props

    const allowedInvoiced = Number(invoiced)
    const positiveBalance = balance >= 0

    if (!invoiced || !costs) {
      return null
    }

    return (
      <Paper data-e2e-id="fuelHistoryWidget">
        <ContentWrapper>
          <Headline
            variant="200"
            data-e2e-id="titleOfFuelHistoryWidget"
            withMarginBottom
          >
            <Translation id={translatePath('title')} />
          </Headline>
          <Price currency={Currencies[isoCurrencyCode]}>
            {formatPrice => (
              <Headline
                variant="300"
                data-e2e-id="priceOfFuelHistoryWidget"
                withMarginBottom
              >
                {formatPrice(costs)}
              </Headline>
            )}
          </Price>
          <ProgressBarWidget
            maxValue={allowedInvoiced}
            currentValue={costs}
            expectedValue={allowedInvoiced}
            dataE2eId="fuelSpending"
            formatter={formatPrice(locale, Currencies[isoCurrencyCode])}
            contextText={
              !!balance && (
                <Translation
                  id={translatePath(
                    positiveBalance ? 'underBudget' : 'subtitle',
                  )}
                  replace={{
                    amount: () => (
                      <Price currency={Currencies[isoCurrencyCode]}>
                        {formatPrice =>
                          positiveBalance ? (
                            <Text variant="200" bold component="span">
                              {formatPrice(balance)}
                            </Text>
                          ) : (
                            <ErrorText variant="200">
                              {formatPrice(-balance)}
                            </ErrorText>
                          )
                        }
                      </Price>
                    ),
                  }}
                />
              )
            }
          />
          <GrayDark80Text variant="200">
            <Translation
              id={translatePath('note')}
              data-e2e-id="messageWidget"
            />
          </GrayDark80Text>
        </ContentWrapper>
      </Paper>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  locale: getLocale,
})

export { FuelSpendingWidget }
export default connect(mapStateToProps)(FuelSpendingWidget)
