import React from 'react'
import { Form } from 'react-final-form'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { FlowData } from 'mlp-client/src/flows/tyres/types'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { Options } from 'mlp-client/src/form/types'

export interface StepConfig {
  damageTypes: Options
}

export interface Props extends StepViewProps<StepConfig, FlowData> {}

export type FormValues = Pick<FlowData, 'damageType'>

class DamageTypeStep extends React.PureComponent<Props> {
  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData({ damageType: values.damageType }, goToNextStep)
  }

  render() {
    const {
      config: { damageTypes },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.newSetup.flows.tyres.steps.damageType.pageTitle">
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit }) => (
              <RadioClickAndGoList
                validations={[]}
                name="damageType"
                options={damageTypes}
                onChange={handleSubmit}
              />
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default DamageTypeStep
