import React from 'react'
import { ModalTrigger } from '@leaseplan/ui'

import { TileProps, ModalContent } from '../type'
import { AccidentIconCircled } from './icons'
import { DashboardModalContent } from './DashboardModalContent'

const modalContent: ModalContent = {
  title: 'myLeaseplan.accidentManagement.page.title',
  icon: AccidentIconCircled,
  immediateAssistance: 'myLeaseplan.accidentManagement.immediateAssistance',
  hotline: 'myLeaseplan.accidentManagement.hotline',
  phoneNumber: 'myLeaseplan.accidentManagement.phone',
  damageReport: 'myLeaseplan.accidentManagement.page.damageReport',
}

export const AccidentManagementModalTrigger: React.FC<TileProps> = props => (
  <ModalTrigger
    trigger={props.children}
    modalContent={
      <DashboardModalContent {...props} modalContent={modalContent} />
    }
  />
)
