import { Action, ActionTypes } from './actions'

export const defaultState: GenericObject = {
  myLeaseplan: {},
}

const dictionaryReducer = (
  state: GenericObject = defaultState,
  action: Action,
): GenericObject => {
  switch (action.type) {
    case ActionTypes.FETCH_DICTIONARY_COMPLETED:
      return {
        myLeaseplan: action.payload.result,
        myLeaseplanEn: action.payload.enResult,
      }
    default:
      return state
  }
}

export default dictionaryReducer
