import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { FormApi, SubmissionErrors } from 'final-form'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { UpdateUserMobilePhoneNumber } from 'mlp-client/src/user/actions'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import { CheckboxField } from 'mlp-client/src/form/components/fields/CheckboxFields'
import { required, validEmail } from 'mlp-client/src/form/validations'
import { getUser } from 'mlp-client/src/user/selectors'
import { AppState } from 'mlp-client/src/types'
import { Phone } from 'mlp-client/src/profile/types'
import PhoneInput from 'mlp-client/src/form/components/fields/PhoneInput/PhoneInput'

export interface ContactDetailsFlowData {
  email?: string
  phone?: Phone
  savePhoneNumber?: boolean
  isUserUpdateFailed?: boolean
  isContractDetailsEnabled?: boolean
}

export interface StepConfig {
  title?: string
}

export interface Props
  extends StepViewProps<StepConfig, ContactDetailsFlowData> {
  email: string
  phone: Phone
  updateUserMobilePhoneNumber(
    phone: Phone,
    onComplete: (errors?: SubmissionErrors) => void,
  ): void
}

type FormValues = ContactDetailsFlowData

const defaultTitle = 'myLeaseplan.tyres.steps.checkContactDetails.pageTitle'

class ContactDetailsStep extends React.PureComponent<Props> {
  handleSubmit = (
    { email, phone, savePhoneNumber }: FormValues,
    form: FormApi,
    onComplete: (errors?: SubmissionErrors) => void,
  ) => {
    const {
      goToNextStep,
      setFlowData,
      updateUserMobilePhoneNumber,
    } = this.props

    if (savePhoneNumber) {
      updateUserMobilePhoneNumber(phone, errors => {
        setFlowData(
          {
            email,
            phone,
            isUserUpdateFailed: !!errors,
            isContractDetailsEnabled: true,
          },
          goToNextStep,
        )
        onComplete(errors)
      })
    } else {
      setFlowData(
        {
          email,
          phone,
          isUserUpdateFailed: false,
        },
        goToNextStep,
      )
      onComplete()
    }
  }
  render() {
    const {
      config: { title = defaultTitle },
      email,
      phone,
      flowData,
    } = this.props

    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{
          email: flowData.email || email,
          phone: flowData.phone || phone,
          savePhoneNumber: true,
        }}
        render={({
          handleSubmit,
          hasValidationErrors,
          submitting,
        }: FormRenderProps) => (
          <StepLayout isLoading={submitting}>
            <TwoColumnFlowStep titleLabel={title}>
              <form onSubmit={handleSubmit}>
                <Spacing mb={2}>
                  <TextField
                    name="email"
                    validations={[required, validEmail]}
                    label={{
                      id: 'myLeaseplan.newSetup.shared.forms.email.label',
                    }}
                    placeholder={{
                      id: 'myLeaseplan.shared.input.email.placeholder',
                    }}
                  />
                </Spacing>
                <Spacing mb={2}>
                  <PhoneInput
                    name="phone"
                    label="myLeaseplan.newSetup.shared.forms.phoneNumber.label"
                    placeholder={{
                      id: 'myLeaseplan.shared.input.phone.placeholder',
                    }}
                    required
                  />
                </Spacing>
                <Spacing mb={2}>
                  <CheckboxField
                    name="savePhoneNumber"
                    label={
                      <Translation id="myLeaseplan.newSetup.shared.saveMobileNumber" />
                    }
                  />
                </Spacing>
                <NextStepButton
                  disabled={hasValidationErrors}
                  onClick={handleSubmit}
                />
              </form>
            </TwoColumnFlowStep>
          </StepLayout>
        )}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'email' | 'phone'>
type DispatchProps = Pick<Props, 'updateUserMobilePhoneNumber'>

export const mapStateToProps = (state: AppState): ReduxProps => {
  const user = getUser(state)

  return {
    email: user.email,
    phone: {
      code: user.mobilePhoneNumber
        ? user.mobilePhoneCountryCode
        : user.phoneCountryCode,
      number: user.mobilePhoneNumber || user.phoneNumber,
    },
  }
}

const mapDispatchToProps: DispatchProps = {
  updateUserMobilePhoneNumber: (phone, onComplete) =>
    new UpdateUserMobilePhoneNumber({ phone, onComplete }),
}

export { ContactDetailsStep }
export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsStep)
