import React from 'react'

import { ThankYou } from 'mlp-client/src/components/thank-you/ThankYou'

const ThankYouPage: React.FC = () => (
  <ThankYou
    pageDescription="myLeaseplan.requestForInformation.thankYou.pageDescription"
    buttonLabel="myLeaseplan.requestForInformation.thankYou.buttonLabel"
    pageTitle="myLeaseplan.requestForInformation.thankYou.pageTitle"
  />
)

export { ThankYouPage }
