import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { lastKnownMileageSelector } from 'mlp-client/src/contracts/selectors'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import MileageField from 'mlp-client/src/form/components/fields/mileage-field/Mileage'
import { MileageLabel } from 'mlp-client/src/form/components/mileage/MileageLabel'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState, Attachments } from 'mlp-client/src/types'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import FileUpload from 'mlp-client/src/form/components/fields/file-upload-field/FileUploadField'
import { filesRequiredAndUploaded } from 'mlp-client/src/form/validations'
import { StepWithFormProps } from 'mlp-client/src/flows/buy/types'
import Translation from 'mlp-client/src/localization/Translation'

const buildTranslationId = makeTranslationPath(
  'myLeaseplan.buy.steps.updateMileage',
)

interface FormData {
  mileage: string
  photos?: Attachments
}

export interface Props extends StepWithFormProps {
  lastKnownMileage: number | undefined
  onStepExit(): void
}

export class UpdateMileageStep extends React.PureComponent<Props> {
  submitHandler = ({ mileage, photos }: FormData) => {
    const { setFlowData, goToNextStep } = this.props
    const mileageNumber = Number.parseInt(mileage, 10)
    const mileageValue = Number.isNaN(mileageNumber) ? undefined : mileageNumber

    setFlowData({ mileage: mileageValue, photos }, goToNextStep)
  }

  render() {
    const {
      lastKnownMileage,
      config: { uploadPictureLabel },
      flowData: { mileage, photos = [] },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel={buildTranslationId('title')}
          textLabel={buildTranslationId('text')}
          side={<FAQ faqKey={FAQKeys.VEHICLE_BUY_PAGE} />}
        >
          <ReactFinalForm
            onSubmit={this.submitHandler}
            initialValues={{ mileage, photos }}
            keepDirtyOnReinitialize={true}
            data-test-id="mileage-form"
          >
            {({
              handleSubmit,
              hasValidationErrors,
              values,
            }: FormRenderProps) => (
              <form onSubmit={handleSubmit}>
                <MileageField
                  required={true}
                  name="mileage"
                  lastKnownMileage={lastKnownMileage}
                  label={
                    <MileageLabel labelKey={buildTranslationId('label')} />
                  }
                />
                <Spacing mb={2} />
                <Spacing mb={2}>
                  <Spacing mb={0.5}>
                    <Text bold>
                      <Translation
                        id={
                          uploadPictureLabel ||
                          'myLeaseplan.updateMileage.uploadPicture.label'
                        }
                      />
                    </Text>
                  </Spacing>
                  <FileUpload
                    name="photos"
                    validations={[filesRequiredAndUploaded]}
                    accept="image/*"
                    disabled={values.photos?.length > 0}
                    multiple={false}
                  />
                </Spacing>
                <NextStepButton
                  disabled={hasValidationErrors}
                  onClick={handleSubmit}
                />
              </form>
            )}
          </ReactFinalForm>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'lastKnownMileage'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  lastKnownMileage: lastKnownMileageSelector,
})

export default connect(mapStateToProps)(UpdateMileageStep)
