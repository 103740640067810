import { Button, ModalTrigger } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import { getRoadsideAssistanceTileButton } from 'mlp-client/src/components/road-side-assistance/selectors'
import { DashboardRoadsideAssistance } from 'mlp-client/src/components/road-side-assistance/types'
import DashboardBanner from 'mlp-client/src/dashboard/components/DashboardBanner'
import { dashboardContentPath } from 'mlp-client/src/constants'
import { getPageContent } from 'mlp-client/src/selectors'
import { getContentSection } from 'mlp-client/src/content/utils'
import { ParagraphSection } from 'mlp-client/src/content/types'
import { AppState } from 'mlp-client/src/types'
import PhoneNumberModalContent from 'mlp-client/src/dashboard/components/PhoneNumberModalContent'

export interface Props {
  bannerTitle: string
  contactButton: string
  customerSupportContent?: ParagraphSection
  roadsideAssistanceButtonSupport: DashboardRoadsideAssistance
}

class ContactUsWithModal extends React.PureComponent<Props> {
  renderButton = (label: string, dataTagId: string) => {
    const {
      customerSupportContent,
      roadsideAssistanceButtonSupport,
    } = this.props

    if (!customerSupportContent || !customerSupportContent.Subtitle) {
      return null
    }

    const { Subtitle: telephone, Title: message } = customerSupportContent
    const { modalTitle } = roadsideAssistanceButtonSupport

    return (
      <ModalTrigger
        modalContent={
          <PhoneNumberModalContent
            phoneNumber={telephone}
            title={modalTitle}
            message={message}
          />
        }
        trigger={
          <Button
            component="button"
            variant="secondary"
            data-tag-id={dataTagId}
            data-e2e-id="serviceSupport"
          >
            {label}
          </Button>
        }
      />
    )
  }

  render() {
    const { bannerTitle, contactButton } = this.props

    return (
      <DashboardBanner
        title={bannerTitle}
        SupportButton={this.renderButton(
          contactButton,
          'roadside-assistance-support-phonenumber-button',
        )}
      />
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'bannerTitle'
  | 'contactButton'
  | 'roadsideAssistanceButtonSupport'
  | 'customerSupportContent'
>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  bannerTitle: getTranslation(state, 'myLeaseplan.timeline.base.bannerTitle'),
  contactButton: getTranslation(
    state,
    'myLeaseplan.timeline.base.contactButton',
  ),
  roadsideAssistanceButtonSupport: getRoadsideAssistanceTileButton(
    state,
    'support',
  ),
  customerSupportContent: getContentSection(
    getPageContent(state, dashboardContentPath)?.Sections,
    'Customersupport',
  ),
})

export { ContactUsWithModal }
export default connect(mapStateToProps)(ContactUsWithModal)
