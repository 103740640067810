import { IconProps } from '@leaseplan/ui'
import React from 'react'

interface Props extends IconProps {
  className?: string
}

const SpinningLoader: React.FC<Props> = (props: Props) => (
  <div className={props.className || 'spinner'}>
    <div className="spinner__ball" />
    <div className="spinner__ball" />
  </div>
)

export default SpinningLoader
