import { Hide, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import {
  getLocale,
  getLocalizedRoute,
} from 'mlp-client/src/localization/selectors'
import { Table } from 'mlp-client/src/components/table'
import { Datum, RowsConfiguration } from 'mlp-client/src/components/table/types'
import { getOrders } from 'mlp-client/src/contracts/selectors'
import { Contract, Contracts } from 'mlp-client/src/contracts/types'
import { AppState } from 'mlp-client/src/types'
import { SetCurrentContract } from 'mlp-client/src/contracts/actions'
import { TableData } from 'mlp-client/src/components/overview-table/types'
import RowLink from 'mlp-client/src/components/overview-table/RowLink'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import { Locales } from 'mlp-client/src/localization/enums'
import { isFrance } from 'mlp-client/src/order-status/selectors'

import { getData, tableColumns } from './utils'

export interface Props {
  contracts: Contracts
  pageRoute: string | null
  locale: Locales
  setActiveContract(contract: Contract): void
}

class OrdersOverviewTable extends React.PureComponent<Props> {
  selectContract = (item: TableData): void => {
    const { contracts, setActiveContract } = this.props
    const contract = contracts.find(contract => contract.id === item.id)

    setActiveContract(contract)
  }

  getRows = (): RowsConfiguration => ({
    TrComponent: RowLink,
    props: {
      handleClick: this.selectContract,
      vehicleRoute: this.props.pageRoute,
    },
  })

  getTableData = (tableData: readonly TableData[]): readonly Datum[] => {
    if (!tableData || tableData.length === 0) {
      return null
    }

    return tableData.map(tableItem => ({
      ...tableItem,
      key: tableItem?.id,
    }))
  }

  render() {
    const { locale } = this.props
    const isFrenchLocale = isFrance(locale)
    const tableData = this.props.contracts.map(contract =>
      getData(contract, isFrenchLocale),
    )

    return (
      <>
        <Hide until="lap">
          <Spacing mb={2}>
            <SecondaryDarkText variant="500" bold>
              <Translation id="myLeaseplan.orderCars.titles.featureTitle" />
            </SecondaryDarkText>
          </Spacing>
        </Hide>
        <Table
          data={this.getTableData(tableData)}
          columns={tableColumns}
          rows={this.getRows()}
          defaultValue="-"
          dataE2EId="ordersOverviewTable"
        />
      </>
    )
  }
}

type ReduxProps = Pick<Props, 'contracts' | 'pageRoute' | 'locale'>
type DispatchProps = Pick<Props, 'setActiveContract'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contracts: getOrders(state),
  pageRoute: getLocalizedRoute(state, 'myLeaseplan.vehicleOrder'),
  locale: getLocale(state),
})

const mapDispatchToProps: DispatchProps = {
  setActiveContract: (contract: Contract) =>
    new SetCurrentContract({ contractId: contract.id }),
}

export { OrdersOverviewTable }
export default connect(mapStateToProps, mapDispatchToProps)(OrdersOverviewTable)
