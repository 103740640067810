import {
  ModalContent,
  ModalTrigger,
  mq,
  Spacing,
  Grid,
  GridItem,
  ModalButtonGroup,
} from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import { CloseIcon } from '@velocity/ui/draft'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { dashboardRoute } from 'mlp-client/src/constants'
import { AppState } from 'mlp-client/src/types'
import { FlowCancellation } from 'mlp-client/src/analytics/actions'
import { FlowCancellationPayload } from 'mlp-client/src/analytics/types'
import { withFlowContext } from 'mlp-client/src/components/myleaseplan-flow/flow/withFlowContext'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import {
  StyledButton,
  StyledButtonOutlined,
} from 'mlp-client/src/components/button/StyledButton'

const StyledModalContent = styled(ModalContent)`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${mq.from('mobileLandscape')`
    width: 540px;
    max-width: 100vw;
    min-height: 140px;
  `};
`
const StyledWrapper = styled.div`
  display: flex;

  & > span {
    display: flex;
  }
`

export interface Props extends FlowProps {
  submitTo?: string
  isOpen: boolean
  openCancelFlowModal?: () => void
  closeCancelFlowModal?: () => void
  trackFlowCancellation(payload: FlowCancellationPayload): void
  contractId: string
}

export interface State {
  isOpen: boolean
}

class CancelFlowModal extends React.PureComponent<Props, State> {
  state: State = {
    isOpen: this.props.isOpen || false,
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({ isOpen: this.props.isOpen })
    }
  }

  closeModal = () => {
    this.setState({
      isOpen: false,
    })

    this.props?.closeCancelFlowModal()
  }

  openModal = () => {
    this.setState({
      isOpen: true,
    })

    this.props?.openCancelFlowModal()
  }

  onClickCancelFlow = () => {
    const {
      trackFlowCancellation,
      stepsCount,
      activeStep,
      eventCategory,
      flowName,
    } = this.props

    trackFlowCancellation({
      eventCategory: `${eventCategory} - ${flowName}`,
      flowName,
      currentStepNr: activeStep.index + 1,
      totalStepNrFlow: stepsCount,
    })
  }

  renderModalContent = () => {
    const { contractId, submitTo } = this.props

    return (
      <StyledModalContent>
        <Grid data-e2e-id="cancelFlow_modal">
          <GridItem span={12}>
            <Headline variant="200" withMarginBottom>
              <Translation id="myLeaseplan.flow.cancelFlowModal.description" />
            </Headline>
            <Text component="span">
              <Translation id="myLeaseplan.flow.cancelFlowModal.question" />
            </Text>
          </GridItem>
          <GridItem span={12}>
            <Spacing mr={{ mobile: 2 }}>
              <ModalButtonGroup>
                <StyledButton
                  component={RouteLink}
                  to={submitTo || dashboardRoute}
                  data-tag-id="myleaseplan-exit-flow-button"
                  stretch={true}
                  params={{ contractId }}
                  onClick={this.onClickCancelFlow}
                >
                  <Translation id="myLeaseplan.flow.cancelFlowModal.submit" />
                </StyledButton>
                <StyledButtonOutlined
                  variant="secondary"
                  data-tag-id="myleaseplan-exit-flow-cancel-button"
                  data-e2e-modal-close-button=""
                  stretch={true}
                >
                  <Translation id="myLeaseplan.flow.cancelFlowModal.cancel" />
                </StyledButtonOutlined>
              </ModalButtonGroup>
            </Spacing>
          </GridItem>
        </Grid>
      </StyledModalContent>
    )
  }

  render() {
    return (
      <StyledWrapper>
        <ModalTrigger
          open={this.state.isOpen}
          onClose={this.closeModal}
          trigger={
            <button
              data-tag-id="myleaseplan-flow-close-button"
              data-e2e-id="flowCloseButton"
              className="flow-nav__close-button"
              onClick={this.openModal}
            >
              <CloseIcon size="xs" color="black" />
            </button>
          }
          modalContent={this.renderModalContent()}
        />
      </StyledWrapper>
    )
  }
}

type DispatchProps = Pick<Props, 'trackFlowCancellation'>
type FlowContextProps = Omit<Props, keyof FlowProps>
type ReduxProps = Pick<Props, 'contractId'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contractId: getCurrentContractId(state),
})

const mapDispatchToProps: DispatchProps = {
  trackFlowCancellation: (payload: FlowCancellationPayload) =>
    new FlowCancellation(payload),
}

export { CancelFlowModal }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFlowContext<FlowContextProps>(CancelFlowModal))
