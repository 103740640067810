import { SagaIterator } from 'redux-saga'
import { call, select, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'
import { FuelHistory } from 'mlp-client/src/flows/fuel-history/types'
import { getUserId } from 'mlp-client/src/user/selectors'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'

import {
  ActionTypes,
  LoadFuelHistorySuccess,
  LoadFuelHistoryFail,
} from './actions'

export const loadFuelHistorySuccessAction = (result: FuelHistory) =>
  new LoadFuelHistorySuccess({ fuelHistory: result })
export const loadFuelHistoryErrorAction = () => new LoadFuelHistoryFail()

export function* loadFuelHistory(): SagaIterator {
  const userId: string = yield select(getUserId)
  const contract: Contract = yield select(getCurrentContract)
  const requestUrl = `/myleaseplan/lpis/fuel-history/${userId}/${contract.number}`

  yield call(ApiSagas.get, requestUrl, {
    successAction: loadFuelHistorySuccessAction,
    errorAction: loadFuelHistoryErrorAction,
  })
}

export default [takeLatest(ActionTypes.LOAD_FUEL_HISTORY, loadFuelHistory)]
