import {
  ChevronRightIcon,
  ColorName,
  IconSize,
  List,
  ListItem,
} from '@leaseplan/ui'
import { Text, TextProps } from '@velocity/ui'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReadonlyArray<string | ReactNode>
  wrapItemInText?: boolean
  textSize?: TextProps['variant']
  iconSize?: IconSize
  color?: ColorName
  width?: string
  height?: string
}

const BreakingText = styled(Text)`
  word-wrap: break-word;
`

export const ListStyled = styled(List)`
  li {
    margin-left: 1.8em;
  }
`

type DefaultProps = Required<
  Pick<
    Props,
    'textSize' | 'iconSize' | 'color' | 'width' | 'height' | 'wrapItemInText'
  >
>
type InternalProps = Props & DefaultProps

class FlowStepChevronList extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    textSize: '200',
    iconSize: 's',
    color: 'steel60',
    width: '16px',
    height: '16px',
    wrapItemInText: true,
  }

  renderItem = (content: string, key: number): ReactNode => {
    const { wrapItemInText, textSize } = this.props
    const itemContent = wrapItemInText ? (
      <BreakingText variant={textSize} component="span">
        {content}
      </BreakingText>
    ) : (
      content
    )

    return <ListItem key={key}>{itemContent}</ListItem>
  }

  render() {
    const { children, color, iconSize, width, height } = this.props
    const chevronIconProps = { color, width, height }

    if (!children.length) {
      return null
    }

    return (
      <ListStyled
        icon={ChevronRightIcon}
        iconProps={chevronIconProps}
        iconAlignment="top"
        iconSize={iconSize}
      >
        {children.map(this.renderItem)}
      </ListStyled>
    )
  }
}

export default FlowStepChevronList
