import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Modal } from '@velocity/ui/draft'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import {
  isSeasonalTyreChangeEnabled,
  getCurrentContract,
} from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { FlowData } from 'mlp-client/src/flows/tyres/types'
import { TyreServiceTypes } from 'mlp-client/src/flows/tyres/enums'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { Options } from 'mlp-client/src/form/types'
import { DashboardBreadcrumbs } from 'mlp-client/src/dashboard/components/DashboardBreadcrumbs'
import { AppState } from 'mlp-client/src/types'

import { filterTyreServicesOnSeasonalChange } from './utils'

export interface StepConfig {
  tyreServices: Options
  showModal?: {
    [key in TyreServiceTypes]?: {
      text: string
    }
  }
}

interface State {
  isModalOpened: boolean
  modalText: string
}

export interface Props extends StepViewProps<StepConfig, FlowData> {
  isSeasonalTyreChangeEnabled: boolean
  contract: Contract
}

type FormValues = Pick<FlowData, 'tyreServiceType'>

class TyreServiceStep extends React.PureComponent<Props, State> {
  state = {
    isModalOpened: false,
    modalText: '',
  }

  onSubmit = (values: FormValues) => {
    const {
      goToNextStep,
      setFlowData,
      config: { showModal },
      contract,
    } = this.props
    const showModalText = showModal && showModal[values.tyreServiceType]?.text

    if (showModalText && !contract.tyres?.tyreStorage) {
      this.setState({
        isModalOpened: true,
        modalText: showModalText,
      })
    } else {
      setFlowData({ tyreServiceType: values.tyreServiceType }, goToNextStep)
    }
  }

  render() {
    const {
      config: { tyreServices },
      isSeasonalTyreChangeEnabled,
      contract,
    } = this.props

    // remove seasonal tyre change service if user does not have seasonalChange in contract
    const tyreServicesOptions = filterTyreServicesOnSeasonalChange({
      tyreServices,
      isSeasonalTyreChangeEnabled,
    })

    const main = (
      <Form
        onSubmit={this.onSubmit}
        render={({ handleSubmit }) => (
          <RadioClickAndGoList
            validations={[]}
            name="tyreServiceType"
            options={tyreServicesOptions}
            onChange={handleSubmit}
            dataE2eId="tyreServiceTypeList"
          />
        )}
      />
    )
    const banner = (
      <FlowStepPoster
        title={
          <Translation
            id="myLeaseplan.newSetup.flows.tyres.steps.tyreService.posterTitle"
            hideOnEmpty={true}
          />
        }
        titleE2eId="tyreServicePosterTitle"
        text={
          <Translation
            id="myLeaseplan.newSetup.flows.tyres.steps.tyreService.posterText"
            hideOnEmpty={true}
          />
        }
        textE2eId="tyreServicePosterText"
        imgMedium="/static/images/my-leaseplan/tyre-services-poster-medium.jpg"
        imgLarge="/static/images/my-leaseplan/tyre-services-poster-medium.jpg"
        breadcrumbs={
          <DashboardBreadcrumbs
            currentStepId="myLeaseplan.newSetup.flows.tyres.steps.tyreService.posterTitle"
            contractId={contract.id}
          />
        }
      />
    )

    return (
      <MyleaseplanPage
        metaTitle="myLeaseplan.newSetup.flows.tyres.steps.tyreService.pageTitle"
        banner={banner}
        showMobileBackButton={true}
        header="myLeaseplan.newSetup.flows.tyres.steps.tyreService.pageTitle"
      >
        <GridLayout
          main={main}
          side={<FAQ faqKey={FAQKeys.TYRE_SERVICES_PAGE} />}
        />
        <Modal
          isOpened={this.state.isModalOpened}
          onClose={() => this.setState({ isModalOpened: false })}
        >
          <Modal.Body>
            <Translation id={this.state.modalText} />
          </Modal.Body>
        </Modal>
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'isSeasonalTyreChangeEnabled' | 'contract'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isSeasonalTyreChangeEnabled,
  contract: getCurrentContract,
})

export { TyreServiceStep }
export default connect(mapStateToProps)(TyreServiceStep)
