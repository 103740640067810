import { Spacing } from '@leaseplan/ui'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import { GridLayout } from 'mlp-client/src/components/layout'
import { NoRecords } from 'mlp-client/src/components/no-records/NoRecords'
import FuelCardsIcon from 'mlp-client/src/components/icons/fuel-card-icon/FuelCardIcon'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  padding-top: 64px;
`

export interface Props {
  posterTitleLabel?: string
  posterTextLabel?: string
  posterImgMedium?: string
  posterImgLarge?: string
  posterImgSmall?: string
}

type DefaultProps = Required<
  Pick<
    Props,
    | 'posterTitleLabel'
    | 'posterTextLabel'
    | 'posterImgMedium'
    | 'posterImgLarge'
    | 'posterImgSmall'
  >
>
type InternalProps = Props & DefaultProps

class NoFuelCard extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    posterTitleLabel: 'myLeaseplan.fuelCard.steps.selectFuelCard.title',
    posterTextLabel: 'myLeaseplan.fuelCard.steps.selectFuelCard.posterSubtitle',
    posterImgMedium: '/static/images/my-leaseplan/fuel-card-flow-small.jpg',
    posterImgLarge: '/static/images/my-leaseplan/fuel-card-flow-big.jpg',
    posterImgSmall: '/static/images/my-leaseplan/mobile/fuel-card-mobile.png',
  }

  render() {
    const {
      posterTextLabel,
      posterTitleLabel,
      posterImgMedium,
      posterImgLarge,
      posterImgSmall,
    } = this.props

    return (
      <Wrapper>
        <FlowStepPoster
          title={<Translation id={posterTitleLabel} hideOnEmpty={true} />}
          text={<Translation id={posterTextLabel} hideOnEmpty={true} />}
          imgMedium={posterImgMedium}
          imgLarge={posterImgLarge}
          imgSmall={posterImgSmall}
        />
        <GridLayout
          main={
            <Spacing mt={4}>
              <NoRecords
                headerTextLabel="myLeaseplan.fuelCard.types.noCards"
                contentTextLabel="myLeaseplan.fuelCard.steps.selectFuelCard.contactFleetManager"
              >
                <FuelCardsIcon width="110px" height="110px" color="steel40" />
              </NoRecords>
            </Spacing>
          }
          mainMaxWidth="100%"
          mainSpan={12}
        />
      </Wrapper>
    )
  }
}

export { NoFuelCard }
export default NoFuelCard
