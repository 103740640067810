import { createSelector } from 'reselect'
import _get from 'lodash/get'

import { AppState, NavigationParams } from 'mlp-client/src/types'
import { Country } from 'mlp-client/src/config/types'
import log from 'mlp-client/src/log'
import {
  Dictionary,
  getDictionaryState,
} from 'mlp-client/src/dictionary/selectors'

import { expandRoute, getLocalizedRouteFromRouteList } from './utils'
import { CountryCode, LanguageCode, Locales } from './enums'
import { Language, Routes, TranslationFallback } from './types'

// This is an intentional re-export to keep external dependencies scoped
// eslint-disable-next-line no-restricted-imports

export const getLocale = (state: AppState) => state.localization.locale

export const getCountries = (state: AppState) => state.localization.countries

export const countryDefaultLocaleSelector = createSelector(
  getCountries,
  (_state: AppState, props: { countryCode: CountryCode }) => props.countryCode,
  (countries, countryCode): Locales | undefined => {
    const languages = countries.find(country => country.code === countryCode)
      ?.languages

    if (languages) {
      return (languages.find(lang => lang.preferred === true) || languages[0])
        ?.locale
    }
  },
)

export const getCountryConfig = (state: AppState): Country => {
  const locale = getLocale(state)

  return getCountries(state).find(country =>
    country.languages.some(language => locale === language.locale),
  )
}

export const getFormatting = createSelector(
  getCountryConfig,
  config => config.formatting,
)

export const getTimezone = createSelector(
  getCountryConfig,
  config => config.timezone || '',
)

export const isLanguageSelectorEnabled = createSelector(
  getCountryConfig,
  config => config.enableLanguageSelector,
)

export const getLocaleAvailableLanguages = createSelector(
  getCountryConfig,
  isLanguageSelectorEnabled,
  (config, isLanguageSelectorEnabled) => {
    const availableLanguages = config.languages.map(language => ({
      label: language.label,
      code: language.code,
    }))

    const hasEnglish: boolean = availableLanguages.some(
      ({ code }: Language) => code === LanguageCode.en,
    )

    // It could be that some countries do not have english in the current
    // country config, if that is the case, we should add it lazilly.
    // only if country enabled language selector
    return isLanguageSelectorEnabled && !hasEnglish
      ? [...availableLanguages, { label: 'English', code: LanguageCode.en }]
      : availableLanguages
  },
)

export const getAvailableLanguagesLocales = createSelector(
  getCountryConfig,
  isLanguageSelectorEnabled,
  (config): Locales[] => config.languages.map(({ locale }) => locale),
)

export const getCurrentCountryLabel = createSelector(
  getCountryConfig,
  config => config.label,
)

export const noContractAllowedLocale = createSelector(
  getCountryConfig,
  config => config.noContractAllowed,
)

export const getUserLocale = getLocale

export const getLocaleParams = (state: AppState) => ({
  locale: getLocale(state),
  countryCode: getCountry(state),
  languageCode: getLanguage(state),
})

export const getCountry = createSelector(
  getLocale,
  locale => locale.split('-')[1].toLowerCase() as CountryCode,
)

export const getLanguage = createSelector(
  getLocale,
  locale => locale.split('-')[0].toLowerCase() as LanguageCode,
)

export const isCountryVisible = createSelector(
  getCountryConfig,
  config => config.visible,
)

// Routing

export const getRoutes = (state: AppState): Routes => state.localization?.routes

export const routesSelector = getRoutes

// Returns a given route and replaces possible variables (such as /some/route/:carTypeId) with values from the given replace object
// TODO: Function needs documentation. What is `path`? Looks like it is a dot-separated key in the routes list, but it is also called
//       with slash-separated paths (as the comment above also suggests).
export const getLocalizedRoute = (
  state: AppState,
  path: string,
  params: string | GenericObject = {},
) => {
  const locale = getLocale(state)

  return getLocalizedRouteFromRouteList(getRoutes(state), locale, path, params)
}

export const getLocalizedRouteFromState = createSelector(
  getRoutes,
  getLocale,
  (state: AppState, props: { to: string; params?: NavigationParams }) => ({
    path: props.to,
    params: props.params,
  }),
  (routes, locale, { path, params }) => {
    if (!path) {
      log.warn('getLocalizedRouteFromState: Path empty')

      return null
    }

    const route = String(_get(routes, `${locale}.${path}`) || '')

    if (!route) {
      log.warn(`getLocalizedRouteFromState: Route ${path} not found in state`)

      return null
    }

    return expandRoute(route, locale, params)
  },
)

export const translationsSelector = (state: AppState): Dictionary => ({
  myLeaseplan: getDictionaryState(state).myLeaseplan,
})

export const translationsEnSelector = (state: AppState): Dictionary => ({
  myLeaseplan: getDictionaryState(state).myLeaseplanEn,
})

export const getTranslationEn = (
  state: AppState,
  translationKey: string,
  fallback?: TranslationFallback,
) =>
  _get(
    translationsEnSelector(state),
    translationKey.split('.'),
    // if no fallback was given, use the translation key as fallback.
    fallback !== undefined ? fallback : translationKey,
  )

export const getTranslation = (
  state: AppState,
  translationKey: string,
  fallback?: TranslationFallback,
) =>
  _get(
    translationsSelector(state),
    translationKey.split('.'),
    // if no fallback was given, use the translation key as fallback.
    fallback !== undefined ? fallback : translationKey,
  )
export const getTranslations = <T extends { [key: string]: string }>(
  state: AppState,
  labels: T,
): T =>
  Object.entries(labels).reduce(
    (acc, [key, label]) => ({
      ...acc,
      [key]: getTranslation(state, label, label),
    }),
    labels,
  )

export const getMonthNamesAccusative = (state: AppState) => {
  const translation = getTranslation(
    state,
    'myLeaseplan.shared.monthNamesAccusative',
    '',
  )

  if (translation) {
    return translation.split('_')
  }

  return []
}
