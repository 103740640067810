import React, { useEffect } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import {
  maxLength,
  phoneNumber,
  required,
} from 'mlp-client/src/form/validations'
import { CheckboxField } from 'mlp-client/src/form/components/fields/CheckboxFields'
import Translation from 'mlp-client/src/localization/Translation'
import { isThirdPartyInfoEmpty } from 'mlp-client/src/flows/damage-report/utils'

export interface StepConfig {}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}

export type FormData = Pick<FlowData, 'thirdPartyInfo' | 'noThirdPartyInfo'>

export class InsuranceStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData({ ...formData }, goToNextStep)
  }

  render() {
    const {
      flowData: { thirdPartyInfo, noThirdPartyInfo },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.insurance.pageTitle">
          <Form
            onSubmit={this.submitHandler}
            initialValues={{
              thirdPartyInfo,
              noThirdPartyInfo,
            }}
            render={({
              handleSubmit,
              hasValidationErrors,
              values,
            }: FormRenderProps) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit}>
                  <CheckboxField
                    name="noThirdPartyInfo"
                    label={
                      <Translation id="myLeaseplan.damageReport.steps.insurance.checkBox" />
                    }
                    disabled={
                      values.thirdPartyInfo &&
                      !isThirdPartyInfoEmpty(values.thirdPartyInfo)
                    }
                  />
                  <Spacing mt={2} />
                  <TextField
                    name="thirdPartyInfo.insuranceCompany"
                    validations={
                      values.noThirdPartyInfo
                        ? [maxLength(100)]
                        : [required, maxLength(100)]
                    }
                    disabled={values.noThirdPartyInfo}
                    label={{
                      id: 'myLeaseplan.damageReport.steps.insurance.company',
                    }}
                  />
                  <Spacing mt={2} />
                  <TextField
                    name="thirdPartyInfo.policyNumber"
                    validations={
                      values.noThirdPartyInfo
                        ? [maxLength(100)]
                        : [required, maxLength(100)]
                    }
                    disabled={values.noThirdPartyInfo}
                    label={{
                      id: 'myLeaseplan.damageReport.steps.insurance.policy',
                    }}
                  />
                  <Spacing mt={2} />
                  <TextField
                    name="thirdPartyInfo.firstName"
                    validations={values.noThirdPartyInfo ? [] : [required]}
                    disabled={values.noThirdPartyInfo}
                    label={{
                      id: 'myLeaseplan.damageReport.steps.insurance.firstName',
                    }}
                  />
                  <Spacing mt={2} />
                  <TextField
                    name="thirdPartyInfo.lastName"
                    validations={values.noThirdPartyInfo ? [] : [required]}
                    disabled={values.noThirdPartyInfo}
                    label={{
                      id: 'myLeaseplan.damageReport.steps.insurance.lastName',
                    }}
                  />
                  <Spacing mt={2} />
                  <TextField
                    name="thirdPartyInfo.licensePlate"
                    validations={values.noThirdPartyInfo ? [] : [required]}
                    disabled={values.noThirdPartyInfo}
                    label={{
                      id:
                        'myLeaseplan.damageReport.steps.insurance.licensePlate',
                    }}
                  />
                  <Spacing mt={2} />
                  <TextField
                    name="thirdPartyInfo.phoneNumber"
                    validations={
                      values.noThirdPartyInfo
                        ? [phoneNumber]
                        : [required, phoneNumber]
                    }
                    disabled={values.noThirdPartyInfo}
                    label={{
                      id:
                        'myLeaseplan.damageReport.steps.insurance.phoneNumber',
                    }}
                  />
                  <Spacing mt={4} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                </form>
              )
            }}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default InsuranceStep
