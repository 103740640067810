import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const GlassIconLeasys: FC<IconProps> = props => {
  GlassIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g>
        <path
          fill={ColorEnumSelector(color)}
          d="M22.48,25.84c2.66,0,5.38,0.71,8.08,2.1l0.06,0.03l0.07-0.03c0.09-0.04,0.24-0.14,0.35-0.28l8.85-11.56
				c0.12-0.16,0.12-0.29,0.1-0.38c-0.04-0.24-0.25-0.5-0.54-0.68c-2.6-1.63-5.24-2.89-7.84-3.76l-0.09-0.03l-3.42,3.6l5.79-0.29
				l-7.71,9.97l-1.52-1.18l5.16-6.67l-6.41,0.32l6.04-6.37l-0.25-0.06c-2.14-0.52-4.31-0.78-6.44-0.78c-1.37,0-2.77,0.11-4.15,0.32
				c-0.46,0.07-0.92,0.16-1.39,0.25l-0.29,0.07c-0.28,0.06-0.57,0.13-0.85,0.2c-0.33,0.08-0.66,0.17-0.99,0.26l-0.22,0.06
				c-0.83,0.24-1.69,0.53-2.56,0.85c-2.13,0.81-4.27,1.86-6.35,3.13c-0.3,0.18-0.51,0.44-0.55,0.68c-0.02,0.09-0.02,0.22,0.1,0.38
				l8.94,11.41c0.12,0.15,0.27,0.24,0.36,0.28l0.06,0.03l0.06-0.03c0.92-0.46,1.86-0.85,2.8-1.16l0.15-0.05l-3.33-7.92
				c-0.1-0.24-0.1-0.51,0-0.75c0.1-0.25,0.29-0.44,0.54-0.54c0.12-0.05,0.25-0.08,0.38-0.08c0.4,0,0.77,0.24,0.92,0.61l3.48,8.29
				l0.12-0.02C20.84,25.91,21.66,25.84,22.48,25.84 M22.22,37.5c-1.78,0-3.23-1.43-3.25-3.19c-0.01-0.92,0.37-1.77,1.07-2.4
				l0.08-0.07l-1.5-3.56l-0.13,0.04c-0.94,0.31-1.83,0.67-2.72,1.12c-0.28,0.14-0.58,0.21-0.9,0.21c-0.75,0-1.54-0.41-2.06-1.08
				L3.88,17.17c-0.43-0.55-0.6-1.23-0.48-1.89c0.15-0.78,0.68-1.5,1.46-1.97c4.19-2.56,8.49-4.24,12.75-4.98
				c0.23-0.04,1.03-0.17,1.03-0.17c1.38-0.2,2.77-0.3,4.15-0.3c5.91,0,11.88,1.86,17.73,5.51c0.77,0.48,1.3,1.21,1.44,2
				c0.12,0.68-0.04,1.36-0.47,1.92l-8.85,11.56c-0.52,0.67-1.3,1.09-2.04,1.09c-0.31,0-0.61-0.07-0.89-0.22
				c-2.43-1.27-4.86-1.91-7.23-1.91c-0.55,0-1.1,0.03-1.65,0.1l-0.2,0.02l1.32,3.13l0.24-0.02c1.81,0,3.26,1.43,3.28,3.19
				c0.02,1.78-1.43,3.23-3.22,3.25H22.22z"
        />
      </g>
    </svg>
  )
}

export default GlassIconLeasys
