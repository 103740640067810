import { Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import OutlinedLocationIcon from 'mlp-client/src/components/icons/outlined-location-icon/OutlinedLocationIcon'
import { AppState } from 'mlp-client/src/types'

import { NoSupplierFoundLayout } from './NoSupplierFound.styled'

export interface Props {
  noSuppliersFound: string
  tryDifferentLocation: string
}

export class NoSupplierFound extends React.PureComponent<Props> {
  render() {
    const { noSuppliersFound, tryDifferentLocation } = this.props

    return (
      <NoSupplierFoundLayout data-e2e-id="noSupplierFound">
        <Spacing mb={1}>
          <OutlinedLocationIcon color="steel40" />
        </Spacing>
        <Headline variant="300" component="h4" withMarginBottom>
          {noSuppliersFound}
        </Headline>
        <Text>{tryDifferentLocation}</Text>
      </NoSupplierFoundLayout>
    )
  }
}

const mapTranslationsToProps = {
  noSuppliersFound: 'myLeaseplan.supplierMap.page.noSuppliersFound',
  tryDifferentLocation: 'myLeaseplan.supplierMap.page.tryDifferentLocation',
}

type ReduxProps = Pick<Props, 'noSuppliersFound' | 'tryDifferentLocation'>

const mapStateToProps = (state: AppState): ReduxProps =>
  getTranslations(state, mapTranslationsToProps)

export default connect(mapStateToProps)(NoSupplierFound)
