import { ButtonLink, Hide, Text } from '@leaseplan/ui'
import React from 'react'

export const PhoneRenderer: React.FC<{
  href: string
  children: string
}> = props => (
  <>
    <Hide until="lap" implementation="js">
      <Text color="petrolBlue" fontWeight="bold">
        {props.children}
      </Text>
    </Hide>
    <Hide from="lap" implementation="js">
      <ButtonLink href={props.href} target="_blank" fontWeight="light" size="s">
        {props.children}
      </ButtonLink>
    </Hide>
  </>
)
