import { ButtonChevronRightIcon, ButtonLink, Grid } from '@leaseplan/ui'
import { Text, VelocityConsumer } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import { AppState, Supplier } from 'mlp-client/src/types'
import {
  todayOpeningHoursAsString,
  getFormattedAddress,
} from 'mlp-client/src/components/suppliers/utils'
import { WhiteText } from 'mlp-client/src/components/styled/TextStyle'

import {
  Address,
  DistanceWrapper,
  IconWrapper,
  ItemWrapper,
  SupplierName,
  SupplierWrapper,
} from './SupplierItem.styled'

export interface Props {
  supplier: Supplier
  phoneLabel: string
  supplierClosed: string
  measureUnit: string
  highlighted: boolean
  onClick(): void
}

export class SupplierItem extends React.PureComponent<Props> {
  render() {
    const {
      supplier,
      onClick,
      phoneLabel,
      measureUnit,
      supplierClosed,
      highlighted,
    } = this.props
    const { name, distance, phone, openingHours, website, id } = supplier

    return (
      <ItemWrapper data-e2e-id={id} onClick={onClick}>
        <DistanceWrapper highlighted={highlighted}>
          <WhiteText variant="100">{`${distance} ${measureUnit}`}</WhiteText>
        </DistanceWrapper>
        <Grid wrap="nowrap">
          <SupplierWrapper>
            <VelocityConsumer>
              {({ theme }) => (
                <SupplierName
                  variant="200"
                  component="p"
                  bold
                  style={{
                    color: highlighted
                      ? theme.styling.primary.main
                      : theme.styling.secondary.dark.default,
                  }}
                  data-e2e-id="supplierName"
                >
                  {name}
                </SupplierName>
              )}
            </VelocityConsumer>
            <Address variant="100" component="p">
              {getFormattedAddress(supplier)}
            </Address>
            {openingHours && (
              <Text variant="200" component="div">
                {todayOpeningHoursAsString(supplier) || supplierClosed}
              </Text>
            )}
            {phone && (
              <Text variant="200" bold component="div">
                {phoneLabel}:{' '}
                <ButtonLink href={`tel:${phone}`}>{phone}</ButtonLink>
              </Text>
            )}
            {website && (
              <ButtonLink size="s" fontWeight="regular" href={website}>
                {website}
              </ButtonLink>
            )}
          </SupplierWrapper>
          {openingHours && (
            <IconWrapper>
              <ButtonChevronRightIcon />
            </IconWrapper>
          )}
        </Grid>
      </ItemWrapper>
    )
  }
}

const mapTranslationsToProps = {
  phoneLabel: 'myLeaseplan.supplierMap.page.phone',
  supplierClosed: 'myLeaseplan.supplierMap.page.supplierClosed',
  measureUnit: 'myLeaseplan.shared.units.distance.kilometer',
}

type ReduxProps = Pick<Props, 'phoneLabel' | 'supplierClosed' | 'measureUnit'>

const mapStateToProps = (state: AppState): ReduxProps =>
  getTranslations(state, mapTranslationsToProps)

export default connect(mapStateToProps)(SupplierItem)
