import { ButtonChevronLeftIcon } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import SupplierDetails from 'mlp-client/src/flows/components/pages/supplier-map-page/supplier-details/SupplierDetails'
import { Supplier } from 'mlp-client/src/types'

import {
  BackToList,
  MobileMapWrapper,
  SupplierDetailWrapper,
} from './SuppliersMapModal.styled'

export interface Props {
  supplier: Supplier
  suppliersMap: React.ReactNode
  isVisible: boolean
  closeModal(): void
  onDetailsClose(): void
}

interface State {
  isSupplierDetailsVisible: boolean
}

export class SuppliersMapModal extends React.PureComponent<Props, State> {
  state = {
    isSupplierDetailsVisible: true,
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.supplier !== this.props.supplier) {
      this.showSupplierDetails()
    }
  }

  hideSupplierDetails = () => {
    const { onDetailsClose } = this.props

    this.setState(
      () => ({
        isSupplierDetailsVisible: false,
      }),
      onDetailsClose,
    )
  }

  showSupplierDetails = () => {
    this.setState(() => ({
      isSupplierDetailsVisible: true,
    }))
  }

  renderSupplierDetails = () => {
    const { supplier } = this.props
    const { isSupplierDetailsVisible } = this.state
    const isVisible = supplier && isSupplierDetailsVisible

    return (
      <SupplierDetailWrapper
        isVisible={isVisible}
        // if supplier has no opnening hours, show small card on the map.
        cardHeight={isVisible && supplier.openingHours ? '400px' : '200px'}
      >
        {supplier && (
          <SupplierDetails
            supplier={supplier}
            onClose={this.hideSupplierDetails}
          />
        )}
      </SupplierDetailWrapper>
    )
  }

  render() {
    const { suppliersMap, closeModal, isVisible } = this.props

    return (
      <MobileMapWrapper isVisible={isVisible}>
        <BackToList
          variant="secondary"
          onClick={closeModal}
          icon={ButtonChevronLeftIcon}
          iconPosition="left"
        >
          <Translation id="myLeaseplan.serviceRequest.supplier.map.returnToList" />
        </BackToList>
        {suppliersMap}
        {this.renderSupplierDetails()}
      </MobileMapWrapper>
    )
  }
}

export default SuppliersMapModal
