import { Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'

export interface Props {
  text: string
}

export const Header: React.StatelessComponent<Props> = props => (
  <Spacing pv={1}>
    <Headline variant="200" component="h3" withMarginBottom>
      <Translation id={props.text} />
    </Headline>
  </Spacing>
)

export default Header
