import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { Options } from 'mlp-client/src/form/types'
import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { AppState } from 'mlp-client/src/types'

export interface StepConfig {
  options(isMobileApp?: boolean): Options
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
  isMobileApp: boolean
}

export type FormData = Pick<FlowData, 'damageCause'>

export class DamageCauseStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData(formData, goToNextStep)
  }

  render() {
    const {
      config: { options },
      isMobileApp,
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel="myLeaseplan.damageReport.steps.insurance.pageTitle"
          side={
            <Spacing mt={0.5}>
              <FAQ faqKey={FAQKeys.DAMAGE_REPORT_PAGE} />
            </Spacing>
          }
        >
          <Form
            onSubmit={this.submitHandler}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} data-e2e-id="damageCauseList">
                <RadioClickAndGoList
                  validations={[]}
                  name="damageCause"
                  options={options(isMobileApp)}
                  onChange={handleSubmit}
                />
              </form>
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export type ReduxProps = Pick<Props, 'isMobileApp'>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isMobileApp: getIsMobileApp,
})

export default connect(mapStateToProps)(DamageCauseStep)
