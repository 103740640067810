import { put, select, takeEvery } from 'redux-saga/effects'

import {
  getCountry,
  getLanguage,
  getTranslation,
} from 'mlp-client/src/localization/selectors'

import { PageView, ActionTypes, TrackPageViewEvent } from './actions'
import { getCIDFromCookie } from './utils'

export function* pushPageViewEvent(action: TrackPageViewEvent) {
  try {
    const language = yield select(getLanguage)
    const country = yield select(getCountry)
    const displayName = action.payload.pageTitleKey
      ? yield select(getTranslation, action.payload.pageTitleKey)
      : ''

    const event = {
      userType: 'driver',
      pageURL: window.location.href,
      referrer: document.referrer,
      itemName: action.payload.pageTitle.toLowerCase(),
      valueStream: 'myleaseplan',
      pageTitle: 'leaseplan',
      pageId: `${language}-${country} | myleaseplan | ${action.payload.pageTitle.toLowerCase()}`,
      pathName: window.location.pathname,
      cid: getCIDFromCookie(),
      hostname: window.location.origin,
      redirect: 0,
      displayName,
      language,
      country,
    }

    yield put(new PageView(event))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Could not push to GTM datalayer: ', e)
  }
}

export default [takeEvery(ActionTypes.TRACK_PAGE_VIEW_EVENT, pushPageViewEvent)]
