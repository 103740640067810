import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class MaintenanceIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M55.3 20.8A9.2 9.2 0 0 1 45 22.5a1.5 1.5 0 0 0-1.7.3l-8.5 8.4-12.2 12.2a1.5 1.5 0 0 0-.4 1.4l.2 1v.2a9 9 0 0 1-2.2 9.4 9.2 9.2 0 0 1-8.2 2.4l5.8-5.7c.3-.2.5-.6.5-1V46a1.5 1.5 0 0 0-1.5-1.5h-5.1c-.4 0-.8 0-1 .4l-5.8 5.7a9.2 9.2 0 0 1 2.5-8.2 9.1 9.1 0 0 1 10.4-1.7 1.5 1.5 0 0 0 1.7-.3l20.8-20.6c.4-.4.6-1.1.3-1.7a9.1 9.1 0 0 1 1.9-10.3 9.1 9.1 0 0 1 8.2-2.4L44.9 11a1.5 1.5 0 0 0-.4 1v5.1a1.5 1.5 0 0 0 1.4 1.5H51c.4 0 .8 0 1-.4l5.9-5.7a9.2 9.2 0 0 1-2.6 8.2m4.6-11.6a1.5 1.5 0 0 0-1.1-.8 1.5 1.5 0 0 0-1.3.4l-7 7h-3v-3l7-7a1.5 1.5 0 0 0-.4-2.4 12.2 12.2 0 0 0-13.7 2.3 12.2 12.2 0 0 0-3 12.7L18.2 37.5a12.2 12.2 0 0 0-12.8 2.8A12.2 12.2 0 0 0 2.8 54a1.5 1.5 0 0 0 2.4.4l7.1-7h3v3l-7.1 7a1.5 1.5 0 0 0 .4 2.4 12 12 0 0 0 5.2 1.2 12.1 12.1 0 0 0 11.5-16l11.6-11.7 7.8-7.6a12.2 12.2 0 0 0 12.7-2.8 12.2 12.2 0 0 0 2.5-13.7"
        />
      </svg>
    )
  }
}

export default MaintenanceIcon
