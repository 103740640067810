import moment from 'moment'

import { Address } from 'mlp-client/src/user/types'
import {
  BookingRequestType,
  Geolocation,
  Suppliers,
} from 'mlp-client/src/types'
import { Imalfunction } from 'mlp-client/src/flows/maintenance/types'

import {
  Contracts,
  Notification,
  PolicyDocuments,
  RegistrationCertificate,
} from './types'

export const enum ActionTypes {
  LOAD_CONTRACTS = '@@my-contracts/LOAD_CONTRACTS',
  LOAD_CONTRACTS_SUCCESS = '@@my-contracts/LOAD_CONTRACTS_SUCCESS',
  LOAD_CONTRACTS_FAILED = '@@my-contracts/LOAD_CONTRACTS_FAILED',

  SET_CURRENT_CONTRACT = '@@my-contracts/SET_CURRENT_CONTRACT',
  DEFINE_CURRENT_CONTRACT = '@@my-contracts/DEFINE_CURRENT_CONTRACT',

  LOAD_POLICY_DOCUMENTS = '@@my-contracts/LOAD_POLICY_DOCUMENTS',
  LOAD_POLICY_DOCUMENTS_SUCCESS = '@@my-contracts/LOAD_POLICY_DOCUMENTS_SUCCESS',
  LOAD_POLICY_DOCUMENTS_FAILED = '@@my-contracts/LOAD_POLICY_DOCUMENTS_FAILED',
  DOWNLOAD_POLICY_DOCUMENT = '@@my-contracts/DOWNLOAD_POLICY_DOCUMENT',
  DOWNLOAD_POLICY_DOCUMENT_SUCCESS = '@@my-contracts/DOWNLOAD_POLICY_DOCUMENT_SUCCESS',
  DOWNLOAD_POLICY_DOCUMENT_FAILED = '@@my-contracts/DOWNLOAD_POLICY_DOCUMENT_FAILED',
  DOWNLOAD_REGISTRATION_CERTIFICATE = '@@my-contracts/DOWNLOAD_REGISTRATION_CERTIFICATE',
  DOWNLOAD_REGISTRATION_CERTIFICATE_SUCCESS = '@@my-contracts/DOWNLOAD_REGISTRATION_CERTIFICATE_SUCCESS',
  DOWNLOAD_REGISTRATION_CERTIFICATE_FAILED = '@@my-contracts/DOWNLOAD_REGISTRATION_CERTIFICATE_FAILED',
  SET_CONTRACTS_LOADING = '@@my-contracts/SET_CONTRACTS_LOADING',

  LOAD_NEARBY_SUPPLIERS = '@@my-contracts/LOAD_NEARBY_SUPPLIERS',
  LOAD_NEARBY_SUPPLIERS_SUCCESS = '@@my-contracts/LOAD_NEARBY_SUPPLIERS_SUCCESS',
  LOAD_NEARBY_SUPPLIERS_FAILED = '@@my-contracts/LOAD_NEARBY_SUPPLIERS_FAILED',
  RESET_NEARBY_SUPPLIERS = '@@my-contracts/RESET_NEARBY_SUPPLIERS',
  NO_AVAILABLE_SUPPLIER = '@@my-contracts/NO_AVAILABLE_SUPPLIER',

  LOAD_NOTIFICATIONS = '@@my-contracts/LOAD_NOTIFICATIONS',
  LOAD_NOTIFICATIONS_SUCCESS = '@@my-contracts/LOAD_NOTIFICATIONS_SUCCESS',
  LOAD_NOTIFICATIONS_FAILED = '@@my-contracts/LOAD_NOTIFICATIONS_FAILED',
  RESET_NOTIFICATIONS = '@@my-contracts/RESET_NOTIFICATIONS',

  PREDICTIVE_MILEAGE_MODAL_SHOW = '@@my-contracts/PREDICTIVE_MILEAGE_MODAL_SHOW',
  PREDICTIVE_MILEAGE_MODAL_SHOWN = '@@my-contracts/PREDICTIVE_MILEAGE_MODAL_SHOWN',

  VALIDATE_BY_ADDRESS = '@@my-contracts/VALIDATE_BY_ADDRESS',

  GET_REGISTRATION_CERTIFICATE_DATA = '@@my-contracts/GET_REGISTRATION_CERTIFICATE_DATA',
  GET_REGISTRATION_CERTIFICATE_DATA_SUCCESS = '@@my-contracts/GET_REGISTRATION_CERTIFICATE_DATA_SUCCESS',
  GET_REGISTRATION_CERTIFICATE_DATA_FAILED = '@@my-contracts/GET_REGISTRATION_CERTIFICATE_DATA_FAILED',
}

export class LoadContracts {
  readonly type = ActionTypes.LOAD_CONTRACTS

  constructor(
    readonly payload: {
      selectedContractId?: string
    },
  ) {}
}

export class SetCurrentContract {
  readonly type = ActionTypes.SET_CURRENT_CONTRACT

  constructor(
    readonly payload: {
      contractId: string
    },
  ) {}
}

export class DefineCurrentContractId {
  readonly type = ActionTypes.DEFINE_CURRENT_CONTRACT

  constructor(readonly payload: string) {}
}

export class LoadContractsSuccess {
  readonly type = ActionTypes.LOAD_CONTRACTS_SUCCESS

  constructor(
    readonly payload: {
      contracts: Contracts
    },
  ) {}
}

export class LoadContractsFailed {
  readonly type = ActionTypes.LOAD_CONTRACTS_FAILED
}

export class LoadPolicyDocuments {
  readonly type = ActionTypes.LOAD_POLICY_DOCUMENTS
}

export class LoadPolicyDocumentsSuccess {
  readonly type = ActionTypes.LOAD_POLICY_DOCUMENTS_SUCCESS

  constructor(
    readonly payload: {
      policyDocuments: PolicyDocuments
    },
  ) {}
}

export class LoadPolicyDocumentsFailed {
  readonly type = ActionTypes.LOAD_POLICY_DOCUMENTS_FAILED
}

export class DownloadPolicyDocument {
  readonly type = ActionTypes.DOWNLOAD_POLICY_DOCUMENT

  constructor(
    public payload: {
      fileId: string
      documentType: string
      fileName: string
    },
  ) {}
}

export class DownloadPolicyDocumentSuccess {
  readonly type = ActionTypes.DOWNLOAD_POLICY_DOCUMENT_SUCCESS
}

export class DownloadPolicyDocumentFailed {
  readonly type = ActionTypes.DOWNLOAD_POLICY_DOCUMENT_FAILED
}

export class DownloadRegistrationCertificate {
  readonly type = ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE

  constructor(
    public payload: {
      documentId: string
    },
  ) {}
}

export class DownloadRegistrationCertificateSuccess {
  readonly type = ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE_SUCCESS
}

export class DownloadRegistrationCertificateFailed {
  readonly type = ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE_FAILED
}

export class SetContractsLoading {
  readonly type = ActionTypes.SET_CONTRACTS_LOADING

  constructor(
    readonly payload: {
      loading: boolean
    },
  ) {}
}

export class LoadNearbySuppliers {
  readonly type = ActionTypes.LOAD_NEARBY_SUPPLIERS

  constructor(
    public payload: {
      requestData: {
        date: moment.Moment
        location: Address
        additionalServices: readonly string[]
        bookingType: BookingRequestType
        selectedMalfunctions?: Imalfunction[]
        mileage?: string
      }
      onComplete: (isError: boolean) => void
      prevalidate: boolean
    },
  ) {}
}

export class ResetNearbySuppliers {
  readonly type = ActionTypes.RESET_NEARBY_SUPPLIERS
}

export class LoadNearbySuppliersSuccess {
  readonly type = ActionTypes.LOAD_NEARBY_SUPPLIERS_SUCCESS

  constructor(
    public payload: {
      data: { results: Suppliers; searchLocation: Geolocation }
    },
  ) {}
}

export class LoadNearbySuppliersFailed {
  readonly type = ActionTypes.LOAD_NEARBY_SUPPLIERS_FAILED

  constructor(public payload: { data: { reason: string } }) {}
}

export class SetNoSuppliersByMalfunctions {
  readonly type = ActionTypes.NO_AVAILABLE_SUPPLIER

  constructor(readonly payload: { noSuppliersByMalfunctions: boolean }) {}
}

export class LoadNotifications {
  readonly type = ActionTypes.LOAD_NOTIFICATIONS
}

export class LoadNotificationsSuccess {
  readonly type = ActionTypes.LOAD_NOTIFICATIONS_SUCCESS

  constructor(readonly payload: readonly Notification[]) {}
}

export class LoadNotificationsFailed {
  readonly type = ActionTypes.LOAD_NOTIFICATIONS_FAILED
}

export class ResetNotifications {
  readonly type = ActionTypes.RESET_NOTIFICATIONS
}

export class PredictiveMileageModalShow {
  readonly type = ActionTypes.PREDICTIVE_MILEAGE_MODAL_SHOW

  constructor(readonly payload: { contractId: string }) {}
}

export class PredictiveMileageModalShown {
  readonly type = ActionTypes.PREDICTIVE_MILEAGE_MODAL_SHOWN
}

export class ValidateByAddress {
  readonly type = ActionTypes.VALIDATE_BY_ADDRESS

  constructor(
    public payload: {
      location: Address
      onComplete: (isError: boolean) => void
    },
  ) {}
}

export class GetRegistrationCertificateData {
  readonly type = ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA
}

export class GetRegistrationCertificateDataSuccess {
  readonly type = ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA_SUCCESS

  constructor(readonly payload: RegistrationCertificate) {}
}

export class GetRegistrationCertificateDataFailed {
  readonly type = ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA_FAILED
}

export type Action =
  | LoadContracts
  | SetCurrentContract
  | DefineCurrentContractId
  | LoadContractsSuccess
  | LoadContractsFailed
  | LoadPolicyDocuments
  | LoadPolicyDocumentsFailed
  | LoadPolicyDocumentsSuccess
  | DownloadPolicyDocument
  | DownloadPolicyDocumentFailed
  | DownloadPolicyDocumentSuccess
  | DownloadRegistrationCertificate
  | DownloadRegistrationCertificateFailed
  | DownloadRegistrationCertificateSuccess
  | SetContractsLoading
  | LoadNearbySuppliers
  | LoadNearbySuppliersSuccess
  | LoadNearbySuppliersFailed
  | LoadNotifications
  | LoadNotificationsSuccess
  | LoadNotificationsFailed
  | ResetNearbySuppliers
  | SetNoSuppliersByMalfunctions
  | ResetNotifications
  | PredictiveMileageModalShow
  | PredictiveMileageModalShown
  | ValidateByAddress
  | GetRegistrationCertificateData
  | GetRegistrationCertificateDataFailed
  | GetRegistrationCertificateDataSuccess
