import moment from 'moment'
import React from 'react'

import { Quote, Quotes, ListProperties } from 'mlp-client/src/quotes/types'
import { ContractCurrency } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { formatDate, formatNumber } from 'mlp-client/src/utils'
import { YesNoShortcut } from 'mlp-client/src/enums'
import { MileageFormatter } from 'mlp-client/src/contracts/components/MileageFormatter/MileageFormatter'
import { localizedPrice } from 'mlp-client/src/components/price/Price'
import { SectionPropertyList } from 'mlp-client/src/features-configuration/types'

import {
  DealerBrokerSectionProperties,
  ContractSectionProperties,
  VehicleSectionProperties,
  ViewQuoteSections,
  QUOTE_TYPE,
  VehiclePriceSectionProperties,
  ViewQuoteTabs,
  IncludedServicesSectionProperties,
} from './enums'
import {
  ContractProperties,
  DealerBrokerProperties,
  InsuranceProperties,
  VehicleProperties,
  VehiclePriceProperties,
  IncludedServices,
} from './types'

export const getFirstQuoteId = (quotes: Quotes): string | undefined =>
  quotes.length ? quotes[0].id : null

export const isQuote = (entity: any): entity is Quote =>
  entity && entity.contractType && entity.contractType === QUOTE_TYPE

export const formatContractDate = (contractDate: moment.Moment) =>
  formatDate(contractDate, 'LL')

export const getTabTitle = (tabId: string): string => {
  switch (tabId) {
    case ViewQuoteTabs.Contract:
      return 'myLeaseplan.contract.subTitle'

    case ViewQuoteTabs.Vehicle:
      return 'myLeaseplan.contract.title'
  }
}

export const isEmptyObject = (obj: object): boolean =>
  !Object.values(obj).some(prop => prop)

export const getExtension = (fileName: string) =>
  (fileName.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1] || null

export const getBaseFileName = (fileName: string) =>
  fileName.replace(/\.[^/.]+$/, '')

export const isShortcutTrue = (value: string): boolean =>
  value === YesNoShortcut.YES

/**
 * Dealer/Broker sections
 */

export const getDealerBrokerData = (quote: Quote): DealerBrokerProperties => {
  if (!quote) {
    return null
  }

  return {
    dealer: quote.dealer,
    salesPersonId: quote.salesPersonId,
    salesPersonName: quote.salesPersonName,
  }
}

export const getDealerBrokerProperties = (quote: Quote): ListProperties => {
  const dealerBrokerProperties = getDealerBrokerData(quote)

  if (!dealerBrokerProperties || isEmptyObject(dealerBrokerProperties)) {
    return []
  }

  return [
    {
      label: 'Dealer', //'myLeaseplan.contract.dealerBroker.properties.dealer',
      key: DealerBrokerSectionProperties.DEALER,
      value: dealerBrokerProperties.dealer,
    },
    {
      label: 'Salesperson', //'myLeaseplan.contract.dealerBroker.properties.salesperson',
      key: DealerBrokerSectionProperties.SALESPERSON,
      value: dealerBrokerProperties.salesPersonName,
    },
  ]
}

/**
 * Contract sections
 */

export const getContractData = (quote: Quote): ContractProperties => {
  if (!quote) {
    return null
  }

  return {
    annualMileage: quote.annualMileage || 0,
    duration: quote.duration || 0,
    initialNumberOfPayments: quote.initialNumberOfPayments || 0,
    monthlyPriceIncVat: quote.monthlyPriceIncVat || 0,
    monthlyPriceExclVat: quote.monthlyPriceExclVat || 0,
  }
}

export const getContractProperties = (quote: Quote): ListProperties => {
  const contractProperties = getContractData(quote)

  if (!contractProperties || isEmptyObject(contractProperties)) {
    return []
  }

  return [
    {
      label: 'Annual mileage', //'myLeaseplan.contract.contract.properties.annualMileage',
      key: ContractSectionProperties.ANNUAL_MILEAGE,
      value: <MileageFormatter mileage={contractProperties.annualMileage} />,
    },
    {
      label: 'Duration', //'myLeaseplan.contract.contract.properties.duration',
      key: ContractSectionProperties.DURATION,
      value: contractProperties.duration,
    },
    {
      label: 'Initial no. of payments', //'myLeaseplan.contract.contract.properties.initialNumberOfPayments',
      key: ContractSectionProperties.INITIAL_NUMBER_OF_PAYMENTS,
      value: contractProperties.initialNumberOfPayments,
    },
    {
      label: 'Price incl. VAT', //'myLeaseplan.contract.contract.properties.monthlyPriceIncVat',
      key: ContractSectionProperties.MONTHLY_PRICE_INCL_VAT,
      value: contractProperties.monthlyPriceIncVat,
    },
    {
      label: 'Price excl. VAT', //'myLeaseplan.contract.contract.properties.monthlyPriceExclVat',
      key: ContractSectionProperties.MONTHLY_PRICE_EXCL_VAT,
      value: contractProperties.monthlyPriceExclVat,
    },
  ]
}

/**
 * Vehicle section
 */

export const getVehicleData = (quote: Quote): VehicleProperties | null => {
  if (!quote) {
    return null
  }

  return {
    make: quote.make || '',
    model: quote.model || '',
    modelType: quote.modelType || '',
    modelYear: quote.modelYear || '',
    co2: quote.co2Emission || 0,
    energyType: quote.energyType || '',
    doors: quote.numberOfDoors || 0,
    seats: quote.numberOfSeats || 0,
    transmission: quote.transmissionType || '',
  }
}

export const getVehicleProperties = (quote: Quote): ListProperties => {
  const vehicleProperties = getVehicleData(quote)

  if (!vehicleProperties || isEmptyObject(vehicleProperties)) {
    return []
  }

  return [
    {
      key: VehicleSectionProperties.MAKE,
      label: 'myLeaseplan.contract.car.properties.make',
      value: vehicleProperties.make,
    },
    {
      key: VehicleSectionProperties.MODEL,
      label: 'myLeaseplan.contract.car.properties.model',
      value: vehicleProperties.model,
    },
    {
      key: VehicleSectionProperties.MODEL_TYPE,
      label: 'Model type', //'myLeaseplan.contract.car.properties.modelType',
      value: vehicleProperties.modelType,
    },
    {
      key: VehicleSectionProperties.MODEL_YEAR,
      label: 'Model year', //'myLeaseplan.contract.car.properties.modelYear',
      value: vehicleProperties.modelYear,
    },
    {
      key: VehicleSectionProperties.CO2_EMISSION,
      label: 'myLeaseplan.contract.car.properties.co2',
      value: formatNumber(vehicleProperties.co2),
    },
    {
      key: VehicleSectionProperties.ENERGY_TYPE,
      label: 'myLeaseplan.contract.car.properties.energyType',
      value: vehicleProperties.energyType,
    },
    {
      key: VehicleSectionProperties.TRANSMISSION,
      label: 'myLeaseplan.contract.specifications.transmission',
      value: vehicleProperties.transmission,
    },
    {
      key: VehicleSectionProperties.DOORS,
      label: 'myLeaseplan.contract.car.properties.doors',
      value: vehicleProperties.doors,
    },
    {
      key: VehicleSectionProperties.SEATS,
      label: 'Seats', //'myLeaseplan.contract.car.properties.seats',
      value: vehicleProperties.seats,
    },
  ]
}

/**
 * Insurance Section
 */

export const getInsuranceData = (quote: Quote): InsuranceProperties => ({
  number: quote.id || '',
})

export const getInsuranceProperties = (quote: Quote): ListProperties => {
  const insuranceProperties = getInsuranceData(quote)

  if (!insuranceProperties || isEmptyObject(insuranceProperties)) {
    return []
  }

  return [
    {
      label: 'myLeaseplan.contract.contract.properties.number',
      key: ContractSectionProperties.NUMBER,
      value: insuranceProperties.number,
    },
  ]
}

/**
 * Vehicle Price sections
 */

export const getVehiclePriceData = (quote: Quote): VehiclePriceProperties => {
  if (!quote) {
    return null
  }

  return {
    priceExclVat: quote.priceExclVat,
    priceIncVat: quote.priceIncVat,
    accessoriesIncVat: quote.accessoriesIncVat,
    accessoriesExclVat: quote.accessoriesExclVat,
    totalOtherCostsIncVat: quote.totalOtherCostsIncVat,
    totalOtherCostsExclVat: quote.totalOtherCostsExclVat,
  }
}

export const getVehiclePriceProperties = (
  quote: Quote,
  currency?: ContractCurrency,
): ListProperties => {
  const vehiclePriceProperties = getVehiclePriceData(quote)

  if (!vehiclePriceProperties || isEmptyObject(vehiclePriceProperties)) {
    return []
  }

  return [
    {
      label: 'Price excl. VAT',
      key: VehiclePriceSectionProperties.PRICE_EXCL_VAT,
      value: currency
        ? localizedPrice(
            Number(vehiclePriceProperties.priceExclVat),
            currency.code,
            currency.format,
          )
        : localizedPrice(Number(vehiclePriceProperties.priceExclVat), 'EUR'),
    },
    {
      label: 'Price incl. VAT',
      key: VehiclePriceSectionProperties.PRICE_INCL_VAT,
      value: currency
        ? localizedPrice(
            Number(vehiclePriceProperties.priceIncVat),
            currency.code,
            currency.format,
          )
        : localizedPrice(Number(vehiclePriceProperties.priceIncVat), 'EUR'),
    },
    {
      label: 'Accesories excl. VAT',
      key: VehiclePriceSectionProperties.ACCESSORIES_EXCL_VAT,
      value: currency
        ? localizedPrice(
            Number(vehiclePriceProperties.accessoriesExclVat),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(vehiclePriceProperties.accessoriesExclVat),
            'EUR',
          ),
    },
    {
      label: 'Accesories incl. VAT',
      key: VehiclePriceSectionProperties.ACCESSORIES_INCL_VAT,
      value: currency
        ? localizedPrice(
            Number(vehiclePriceProperties.accessoriesIncVat),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(vehiclePriceProperties.accessoriesIncVat),
            'EUR',
          ),
    },
    {
      label: 'Total other costs excl. VAT',
      key: VehiclePriceSectionProperties.TOTAL_OTHER_EXCL_VAT,
      value: currency
        ? localizedPrice(
            Number(vehiclePriceProperties.totalOtherCostsExclVat),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(vehiclePriceProperties.totalOtherCostsExclVat),
            'EUR',
          ),
    },
    {
      label: 'Total other costs incl. VAT',
      key: VehiclePriceSectionProperties.TOTAL_OTHER_INCL_VAT,
      value: currency
        ? localizedPrice(
            Number(vehiclePriceProperties.totalOtherCostsIncVat),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(vehiclePriceProperties.totalOtherCostsIncVat),
            'EUR',
          ),
    },
  ]
}

/**
 * Included Services sections
 */

export const getincludedServicesData = (quote: Quote): IncludedServices => {
  if (!quote.services || !quote.services.length) {
    return null
  }

  const services = quote.services.reduce(
    (acc, service) => ({
      ...acc,
      [service.detail.name]: {
        price: service.pricing.monthlyCostPrice || null,
        included: service.detail.included,
      },
    }),
    {},
  )

  return services
}

export const getIncludedServicesProperties = (
  quote: Quote,
  currency?: ContractCurrency,
): ListProperties => {
  const includedServicesProperties = getincludedServicesData(quote)

  if (
    !includedServicesProperties ||
    isEmptyObject(includedServicesProperties)
  ) {
    return []
  }

  return [
    {
      label: 'Maintenance',
      key: IncludedServicesSectionProperties.MAINTENANCE,
      value: currency
        ? localizedPrice(
            Number(includedServicesProperties.MAINTENANCE.price),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(includedServicesProperties.MAINTENANCE.price),
            'EUR',
          ),
      included: includedServicesProperties.MAINTENANCE.included,
    },
    {
      label: 'Replacement Vehicle',
      key: IncludedServicesSectionProperties.REPLACEMENT_VEHICLE,
      value: currency
        ? localizedPrice(
            Number(includedServicesProperties.REPLACEMENT_VEHICLE.price),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(includedServicesProperties.REPLACEMENT_VEHICLE.price),
            'EUR',
          ),
      included: includedServicesProperties.REPLACEMENT_VEHICLE.included,
    },
    {
      label: 'Road Assistance',
      key: IncludedServicesSectionProperties.ROAD_ASSISTANCE,
      value: currency
        ? localizedPrice(
            Number(includedServicesProperties.ROAD_ASSISTANCE.price),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(includedServicesProperties.ROAD_ASSISTANCE.price),
            'EUR',
          ),
      included: includedServicesProperties.ROAD_ASSISTANCE.included,
    },
    {
      label: 'Vehicle Tax Initial',
      key: IncludedServicesSectionProperties.VEHICLE_TAX_INITIAL,
      value: currency
        ? localizedPrice(
            Number(includedServicesProperties.VEHICLE_TAX_INITIAL.price),
            currency.code,
            currency.format,
          )
        : localizedPrice(
            Number(includedServicesProperties.VEHICLE_TAX_INITIAL.price),
            'EUR',
          ),
      included: includedServicesProperties.VEHICLE_TAX_INITIAL.included,
    },
  ]
}

export const getSectionData = (
  quote: Quote,
  sectionConfig: SectionPropertyList,
  sectionKey: ViewQuoteSections,
  currency?: ContractCurrency,
): { title: string; properties: ListProperties } => {
  let sectionProperties: ListProperties = []
  let properties: ListProperties
  let title = ''

  switch (sectionKey) {
    case ViewQuoteSections.DealerBroker:
      sectionProperties = getDealerBrokerProperties(quote)
      title = 'Dealer/broker' //'myLeaseplan.contract.dealerBroker.title'
      break
    case ViewQuoteSections.Contract:
      sectionProperties = getContractProperties(quote)
      title = 'Contract' //'myLeaseplan.contract.contract.title'
      break
    case ViewQuoteSections.Insurance:
      sectionProperties = getInsuranceProperties(quote)
      title = 'Insurance' //'myLeaseplan.contract.insurance.insurance'
      break
    case ViewQuoteSections.VehicleDetails:
      sectionProperties = getVehicleProperties(quote)
      title = 'Car details' //'myLeaseplan.contract.vehicleProperties.title'
      break
    case ViewQuoteSections.VehiclePrice:
      sectionProperties = getVehiclePriceProperties(quote, currency)
      title = 'Vehicle price' //'myLeaseplan.contract.vehiclePrice.title'
      break
    case ViewQuoteSections.IncludedServices:
      sectionProperties = getIncludedServicesProperties(quote, currency)
      title = 'Included Services' //'myLeaseplan.contract.vehiclePrice.title'

      return {
        title,
        properties: sectionProperties,
      }
  }

  properties = sectionProperties.filter(
    ({ key, value }) => sectionConfig.includes(key) && (value || value === 0),
  )

  return {
    title,
    properties,
  }
}
