import { SagaIterator } from 'redux-saga'
import { put, select, takeLatest, take, call, race } from 'redux-saga/effects'

import { UpdateUser, ActionTypes } from 'mlp-client/src/user/actions'
import { getUser } from 'mlp-client/src/user/selectors'
import { getUserHomeAddress, getUserModel } from 'mlp-client/src/user/utils'
import * as actions from 'mlp-client/src/profile/actions'
import { ApiSagas } from 'mlp-client/src/api'
import { getLanguage, getLocale } from 'mlp-client/src/localization/selectors'
import { getAddressModel } from 'mlp-client/src/utils'
import { isFrance } from 'mlp-client/src/order-status/selectors'
import { getFranceAddressModel } from 'mlp-client/src/utils/address/address'

export function* submitProfileForm({
  payload: { formData, onComplete },
}: actions.SubmitProfileForm): SagaIterator {
  const user = yield select(getUser)
  const locale = yield select(getLocale)
  const currentHomeAddress =
    getUserHomeAddress(user.addresses) || getAddressModel({})
  const { address, email, ...userData } = formData
  const homeAddress = {
    ...currentHomeAddress,
    ...formData.address,
  }

  const updatedHomeAddress = !isFrance(locale)
    ? getAddressModel(homeAddress)
    : getFranceAddressModel(homeAddress)

  const updatedUser = getUserModel({
    ...user,
    ...userData,
    // We only update the Home address
    addresses: [updatedHomeAddress],
    phoneCountryCode: userData.phone?.number && userData.phone?.code,
    phoneNumber: userData.phone?.number,
    mobilePhoneNumber: userData.mobile?.number,
    mobilePhoneCountryCode: userData.mobile?.number && userData.mobile?.code,
  })

  yield put(new UpdateUser({ user: updatedUser }))
  const { success } = yield race({
    success: take(ActionTypes.UPDATE_USER_SUCCESS),
    failed: take(ActionTypes.UPDATE_USER_FAILED),
  })

  if (success) {
    yield call(onComplete)
  } else {
    yield call(onComplete, { error: '' })
  }
}

export function* loadCountryProfile() {
  const lang = yield select(getLanguage)

  try {
    const result = yield call(ApiSagas.get, '/lookups/country-profile', {
      params: { lang },
    })

    yield put(new actions.LoadedCountryProfile(result))
  } catch (e) {
    // no error handler
  }
}

export default [
  takeLatest(actions.ActionTypes.SUBMIT_PROFILE_FORM, submitProfileForm),
  takeLatest(actions.ActionTypes.LOAD_COUNTRY_PROFILE, loadCountryProfile),
]
