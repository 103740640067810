import React from 'react'
import { Card, CardMedia, Grid, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'

import { CenteredGridItem } from 'mlp-client/src/components/styled/CenteredGridItem'
import Translation from 'mlp-client/src/localization/Translation'

const ThankYou: React.FC = () => (
  <Spacing mh={4} mv={8}>
    <Grid
      justifyContent="center"
      alignItems="center"
      data-e2e-id="thankYouForm"
    >
      <CenteredGridItem span={4}>
        <Card elevation={0} border={false}>
          <CardMedia
            image="/static/images/my-leaseplan/attach-car-to-driver.png"
            title=""
          />
        </Card>
      </CenteredGridItem>
      <CenteredGridItem>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id="myLeaseplan.attachCarToDriver.confirmationTitle" />
        </Headline>
        <Text variant="400">
          <Translation id="myLeaseplan.attachCarToDriver.confirmationMessage" />
        </Text>
      </CenteredGridItem>
    </Grid>
  </Spacing>
)

export default ThankYou
