import React, { ReactChild } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { config } from 'mlp-client/src/app-config'
import { Currencies, Locales } from 'mlp-client/src/localization/enums'
import { AppState } from 'mlp-client/src/types'
import { getLocale } from 'mlp-client/src/localization/selectors'

import NumberFormatOptions = Intl.NumberFormatOptions

interface Props {
  children(formatPrice: (price: number) => string): ReactChild
  locale: Locales
  currency?: Currencies
  formatOptions?: NumberFormatOptions
}

export const currencyMap: Partial<{ [value in Locales]: Currencies }> = {
  [Locales.DA_DK]: Currencies.DKK,
  [Locales.EN_DK]: Currencies.DKK,
}

const createIntlFormatter = (
  locale: Locales,
  currency?: Currencies,
  options?: NumberFormatOptions,
) =>
  new Intl.NumberFormat([locale, config.DEFAULT_LOCALE], {
    style: 'currency',
    currency: currency || currencyMap[locale] || Currencies.EUR,
    ...options,
  })

export class Price extends React.PureComponent<Props> {
  intlFormatter = createIntlFormatter(
    this.props.locale,
    this.props.currency,
    this.props.formatOptions,
  )

  componentDidUpdate({ locale, currency }: Props) {
    if (locale !== this.props.locale || currency !== this.props.currency) {
      this.intlFormatter = createIntlFormatter(
        this.props.locale,
        this.props.currency,
      )
    }
  }

  formatPrice = (price: number) => this.intlFormatter.format(price)

  render() {
    return this.props.children(this.formatPrice)
  }
}

type ReduxProps = Pick<Props, 'locale'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  locale: getLocale,
})

export default connect(mapStateToProps)(Price)
