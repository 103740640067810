import React, { PureComponent, ReactNode } from 'react'
import { Breakpoint, BreakpointName, withBreakpoint } from '@leaseplan/ui'
import { Text } from '@velocity/ui'

import TableMobile from './TableMobile'
import TableWide from './TableWide'
import { Column, Datum, RowsConfiguration, TableProps } from './types'
import { groupByValues } from './utils'

export interface Props {
  columns: readonly Column[]
  rows?: RowsConfiguration
  data: readonly Datum[]
  emptyState?: ReactNode | null
  breakpoint: BreakpointName
  defaultValue?: ReactNode | null
  dataE2EId?: string
}

export class Table extends PureComponent<Props> {
  getTableComponent(
    breakpoint: BreakpointName,
  ): React.ComponentType<TableProps> {
    switch (breakpoint) {
      case Breakpoint[Breakpoint.mobile]:
      case Breakpoint[Breakpoint.mobileLandscape]:
      case Breakpoint[Breakpoint.tablet]:
        return TableMobile
      case Breakpoint[Breakpoint.lap]:
      case Breakpoint[Breakpoint.desktop]:
      case Breakpoint[Breakpoint.wide]:
      default:
        return TableWide
    }
  }

  getVisibleColumns(): readonly Column[] {
    const { columns } = this.props
    const emptyKeys = this.getEmptyKeys()

    return columns.filter(item => !emptyKeys.includes(item.key))
  }

  getEmptyKeys(): string[] {
    const { columns, data } = this.props
    const keysArray: string[] = []

    columns.forEach(item => {
      const valuesObject = groupByValues(data, item.key)

      keysArray.push(this.getNotExistValues(valuesObject, item.key))
    })

    return keysArray.filter(item => item !== '')
  }

  getNotExistValues(valuesObject: GenericObject, property: string): string {
    const { data } = this.props
    const nullValue = 'null'
    const undefinedValue = 'undefined'

    if (
      valuesObject[nullValue] &&
      data.length === valuesObject[nullValue].length
    ) {
      return property
    } else if (
      valuesObject[undefinedValue] &&
      data.length === valuesObject[undefinedValue].length
    ) {
      return property
    } else {
      return ''
    }
  }

  render() {
    const {
      rows,
      data,
      emptyState,
      breakpoint,
      defaultValue,
      dataE2EId,
    } = this.props

    const visibleColumns = this.getVisibleColumns()
    const TableComponent = this.getTableComponent(breakpoint)

    return (
      <Text variant="200" data-e2e-id={dataE2EId}>
        <TableComponent
          columns={visibleColumns}
          data={data}
          rows={rows}
          emptyState={emptyState}
          defaultValue={defaultValue}
        />
      </Text>
    )
  }
}

export default withBreakpoint(Table)
