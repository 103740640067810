import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const DocumentsIconLeasys: FC<IconProps> = props => {
  DocumentsIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g fill={ColorEnumSelector(color)}>
        <path
          d="M35.25,34.5c0,2.09-1.7,3.8-3.8,3.8H13.9c-2.09,0-3.79-1.7-3.79-3.8V10.85c0-2.09,1.7-3.79,3.79-3.79h12.96
				c0.57,0,1.12,0.17,1.6,0.46v5.66c0,1.43,1.16,2.58,2.58,2.58h4.18c0.01,0.11,0.03,0.22,0.03,0.33V34.5z M35.94,12.66L30.7,6.78
				c-0.98-1.09-2.37-1.72-3.84-1.72H13.9c-3.19,0-5.79,2.6-5.79,5.79V34.5c0,3.2,2.6,5.8,5.79,5.8h17.55c3.2,0,5.8-2.6,5.8-5.8
				V16.08C37.25,14.82,36.78,13.61,35.94,12.66"
        />
        <path d="M30.25,21.22H15.1c-0.55,0-1,0.45-1,1s0.45,1,1,1h15.15c0.55,0,1-0.45,1-1S30.8,21.22,30.25,21.22" />
        <path d="M30.25,26.6H15.1c-0.55,0-1,0.45-1,1s0.45,1,1,1h15.15c0.55,0,1-0.45,1-1S30.8,26.6,30.25,26.6" />
        <path
          d="M30.25,31.99H15.1c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h15.15c0.55,0,1-0.45,1-1
				C31.25,32.43,30.8,31.99,30.25,31.99"
        />
      </g>
    </svg>
  )
}

export default DocumentsIconLeasys
