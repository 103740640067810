import React from 'react'
import { Text } from '@velocity/ui'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export interface Props {
  text?: string
  translationId?: string
  backgroundColor?: string
}

const StyledRibbon = styled.div<{ backgroundColor?: string }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0.5rem;
  height: 1.25rem;
  border-radius: 0 0 0 0.15rem;
  background: ${props => ColorEnumSelector(props.backgroundColor) || '#004a5d'};
  display: flex;
  align-items: center;
  justify-content: center;
`

const SText = styled(Text)`
  color: ${props => props.theme.velocity.styling.gray.white};
  font-size: 10px;
`

StyledRibbon.displayName = 'StyledRibbon'

const Ribbon: React.FC<Props> = ({ text, translationId, backgroundColor }) => (
  <StyledRibbon backgroundColor={backgroundColor}>
    <SText bold>
      {translationId ? <Translation id={translationId} /> : text}
    </SText>
  </StyledRibbon>
)

export { Ribbon }
