import {
  Grid,
  GridItem,
  Hide,
  SimpleCheckMarkIcon,
  Spacing,
} from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import { CloseIcon } from '@velocity/ui/draft'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { ColumnLayout } from 'mlp-client/src/contracts/enums'
import {
  ListProperties,
  ParagraphProperty,
} from 'mlp-client/src/contracts/types'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

export interface Props {
  properties: ListProperties
  columnLayout?: ColumnLayout
  translatePropertyLabels?: boolean
}
type DefaultProps = Required<Pick<Props, 'translatePropertyLabels'>>
type InternalProps = Props & DefaultProps

const labelColumnSpan = {
  mobile: 4,
  desktop: 3,
}
const valueColumnSpan = {
  mobile: 12 - labelColumnSpan.mobile,
  desktop: 12 - labelColumnSpan.desktop,
}
const layoutColumnSpan = {
  [ColumnLayout.double]: {
    mobile: 12,
    desktop: 6,
  },
}

export class ContractPropertiesTable extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    translatePropertyLabels: true,
  }

  renderPropertiesTable(properties: ListProperties): React.ReactNode {
    return (
      <>
        <Hide from="tablet" implementation="js">
          {this.renderMobile(properties)}
        </Hide>
        <Hide until="tablet" implementation="js">
          {this.renderDesktop(properties)}
        </Hide>
      </>
    )
  }

  renderParagraphProperty = (property: ParagraphProperty): React.ReactNode => {
    const { key, value, translateValue } = property

    return (
      <GridItem span={12} key={`${key}.value`}>
        <SecondaryDarkText variant="200">
          {translateValue ? <Translation id={value.toString()} /> : value}
        </SecondaryDarkText>
      </GridItem>
    )
  }

  renderMobile = (properties: ListProperties): React.ReactNode => {
    const { translatePropertyLabels } = this.props

    return (
      <Grid direction="column">
        {properties.map(property => {
          const { key, value, translateValue } = property

          return 'label' in property && property.label ? (
            <GridItem key={key}>
              <Text variant="100">
                {translatePropertyLabels ? (
                  <Translation id={property.label} />
                ) : (
                  property.label
                )}
              </Text>
              <Spacing>
                <SecondaryDarkText variant="200" bold>
                  {translateValue ? (
                    <Translation id={value.toString()} />
                  ) : (
                    value
                  )}
                </SecondaryDarkText>
              </Spacing>
            </GridItem>
          ) : (
            this.renderParagraphProperty(property)
          )
        })}
      </Grid>
    )
  }

  renderDesktop = (properties: ListProperties): React.ReactNode => {
    const { translatePropertyLabels } = this.props

    return (
      <Grid verticalGutter={false}>
        {properties.map(property => {
          const { key, value, translateValue, included } = property

          return 'label' in property && property.label
            ? [
                <GridItem span={labelColumnSpan} key={`${key}.label`}>
                  <Text variant="100" loose component="span">
                    {translatePropertyLabels ? (
                      <Translation id={property.label} />
                    ) : (
                      property.label
                    )}
                  </Text>
                </GridItem>,
                <GridItem
                  span={valueColumnSpan}
                  key={`${key}.value`}
                  data-e2e-id={`${property.label}.value`}
                >
                  <SecondaryDarkText bold component="span">
                    {Object(property).hasOwnProperty('included') && (
                      <Spacing component="span" mr={2}>
                        {included ? (
                          <SimpleCheckMarkIcon />
                        ) : (
                          <CloseIcon size="xs" color="#2D2D2D" />
                        )}
                      </Spacing>
                    )}
                    {translateValue ? (
                      <Translation id={value.toString()} />
                    ) : (
                      value
                    )}
                  </SecondaryDarkText>
                </GridItem>,
              ]
            : this.renderParagraphProperty(property)
        })}
      </Grid>
    )
  }

  render() {
    const { properties, columnLayout } = this.props

    if (!properties || !properties.length) {
      return null
    }

    // For double-column layout we divide data into two sets and
    // render each half as a separate column.
    if (columnLayout === ColumnLayout.double) {
      const firstColumn = properties.slice(0, Math.ceil(properties.length / 2))
      const secondColumn = properties.slice(Math.ceil(properties.length / 2))

      return (
        <Grid>
          <GridItem span={layoutColumnSpan[ColumnLayout.double]}>
            {this.renderPropertiesTable(firstColumn)}
          </GridItem>
          <GridItem span={layoutColumnSpan[ColumnLayout.double]}>
            {this.renderPropertiesTable(secondColumn)}
          </GridItem>
        </Grid>
      )
    }

    return this.renderPropertiesTable(properties)
  }
}
