import { ButtonLink, Grid, GridItem, Spacing, Text } from '@leaseplan/ui'
import moment from 'moment'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { timesAsOptions } from 'mlp-client/src/flows/return/utils'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import { required } from 'mlp-client/src/form/validations'
import { makeTranslationPath } from 'mlp-client/src/utils'

export interface Props {
  editClickHandler(): void
  config: {
    returnDate: string
  }
}

const translatePath = makeTranslationPath(
  'myLeaseplan.return.steps.selectTimeLocation',
)

class SelectTimeField extends React.PureComponent<Props> {
  render() {
    const {
      config: { returnDate },
      editClickHandler,
    } = this.props

    return (
      <>
        <Spacing>
          <Spacing component="span" pr={0.5}>
            <Text fontWeight="bold">
              <Translation id={translatePath('pickUpTime')} />
              {moment(returnDate).format('dddd, LL')}
            </Text>
          </Spacing>
          <ButtonLink fontWeight="regular" onClick={editClickHandler}>
            <Translation id={translatePath('edit')} />
          </ButtonLink>
        </Spacing>
        <Grid direction="row">
          <GridItem span={3}>
            <Spacing mb={1}>
              <SelectField
                name="time"
                options={timesAsOptions}
                validations={[required]}
              />
            </Spacing>
          </GridItem>
        </Grid>
      </>
    )
  }
}

export { SelectTimeField }
export default SelectTimeField
