import 'url-search-params-polyfill'
import 'raf/polyfill'
import 'whatwg-fetch'
import 'location-origin'

import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import moment from 'moment-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import superAgent from 'superagent'
import { LoadScript } from '@react-google-maps/api'

import { VelocitySelector } from 'mlp-client/src/VelocitySelector'
import { getTenantId } from 'mlp-client/src/themes/multiTenant'

import { config } from './app-config'
import getStore from './store'
import LocalizationProvider from './localization/LocalizationProvider'
import RoutesProvider from './localization/RoutesProvider'
import App from './App'
import log from './log' // Main FE logger, see https://leaseplan-digital.atlassian.net/browse/FCQ-428
import { buildInfo } from './config/buildTimeEnv'
import { getTimezone } from './localization/selectors'
import { initMobileIntegration } from './auth/mobile-utils'
import { initMyLeaseplanAuth } from './auth/authState'
import Initializer from './components/my-leaseplan-app/Initializer'
import 'mlp-client/src/styles/globalStyles'

const startApplication = async () => {
  const initialAppState = await initMyLeaseplanAuth(config)

  const history = createBrowserHistory()
  const { store } = getStore(initialAppState, history)
  // get state to init auth0
  const state = store.getState()

  const timezone = getTimezone(state)

  if (timezone && moment.tz) {
    moment.tz.setDefault(timezone)
  }

  initMobileIntegration(store.dispatch)

  // On history changes
  let initInitialHistoryCall = true

  const scrollPositions: any[] = []
  const currentPath = history.location.pathname

  const scrollTo = (x = 0, y = 0) => {
    document.documentElement.classList.add('no-smooth-scroll')
    setTimeout(() => {
      window.scrollTo(x, y)
      document.documentElement.classList.remove('no-smooth-scroll')
    }, 0)
  }

  history.listen((location, action) => {
    // Remember current scroll position on route change
    if (action === 'PUSH') {
      scrollPositions.push([window.pageXOffset, window.pageYOffset])
    }

    // Reset scroll position to last position on browser back/forward or to top on normal route change
    if (action === 'POP' && scrollPositions.length) {
      scrollTo(...scrollPositions.pop())
    } else if (
      action !== 'REPLACE' &&
      currentPath !== location.pathname &&
      !location.pathname.match(/\/q\//) // Don't move to top when new path has query params
    ) {
      scrollTo(0, 0)
    }

    // Ping back location changes
    if (!initInitialHistoryCall) {
      superAgent
        .post(`${config.API_BASE_URL}/ping`)
        .send({
          ht: 'page',
          p: location.pathname,
          ul: navigator.language,
        })
        .end() // Calling the end function will send the request
    }

    initInitialHistoryCall = false
  })

  // Show welcome message on console

  // When built from a GitLab pipeline, the branch is always HEAD and isDirty is false, so we don't show them.
  const [formattedBranch, formattedDirty] =
    buildInfo.branch !== 'HEAD'
      ? [
          `\n\n branch: ${buildInfo.branch}`,
          `(${buildInfo.isDirty ? 'dirty' : 'clean'})`,
        ]
      : ['', '']

  const formattedBuildInfo =
    `${buildInfo.version} [${buildInfo.sha}] ${formattedDirty}`.padEnd(
      48 - buildInfo.buildTime.length,
    ) +
    buildInfo.buildTime +
    formattedBranch

  // eslint-disable-next-line no-console
  console.log(`
    _                         _____  _
   | |                       |  __ \\| |
   | |     ___   _ _ ___  ___| |__) | |  _ _ _ __
   | |    / _ \\ /_\` / __|/ _ \\  ___/| | /_\` | '_ \\
   | |___|  __//(_| \\__ \\  __/ |    | |/(_| | | | |
my |______\\__/ \\__,_|___/\\___|_|    |/ \\__,_|_| |_|

 ${formattedBuildInfo}
 ------------------------------------------------
            Interested in joining us?
         Visit https://join.leaseplan.com
 ------------------------------------------------

`)

  log.info(
    `Client start: ${buildInfo.version} [${buildInfo.sha}] (build time: ${buildInfo.buildTime})`,
  )

  const render = (renderApp: React.ReactNode) => (
    <div>
      <Provider store={store}>
        <HelmetProvider>
          <LocalizationProvider>
            <RoutesProvider>
              <ConnectedRouter history={history}>{renderApp}</ConnectedRouter>
            </RoutesProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </Provider>
    </div>
  )

  const appNode = document.getElementById('app')

  const tenant = getTenantId(window.location.href)

  // Render the app
  ReactDOM.render(
    render(
      <Initializer>
        <LoadScript googleMapsApiKey={config.GOOGLE_MAPS_API_KEY}>
          <VelocitySelector tenant={tenant}>
            <App renderSpan={null} />
          </VelocitySelector>
        </LoadScript>
      </Initializer>,
    ),
    appNode,
  )
}

startApplication()
