import { DisabledDay } from '@leaseplan/ui'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import { getContractStartUnixTime } from 'mlp-client/src/contracts/selectors'
import { AppState } from 'mlp-client/src/types'

export const getDisabledDays = (state: AppState): DisabledDay[] => [
  {
    after: new Date(),
    message: getTranslation(
      state,
      'myLeaseplan.validations.dateNotInFutureError',
    ),
  },
  {
    before: new Date(getContractStartUnixTime(state)),
    message: getTranslation(state, 'myLeaseplan.validations.notEarlierThan'),
  },
]
