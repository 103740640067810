import React from 'react'
import { IconProps, IconText, List, ListItem, Spacing } from '@leaseplan/ui'
import { MessageBar } from '@velocity/ui/draft'
import styled, { keyframes } from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import SpinningLoader from 'mlp-client/src/form/components/fields/submit-status/spinning-loader/SpinningLoader'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'

const SpinningLoaderIcon = styled(SpinningLoader).attrs<IconProps>({
  className: 'spinner',
})`
  position: absolute;
  top: 4px;
  left: 0;
  right: auto;
  margin: 0;
  width: 20px;
  height: 20px;
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: auto;
  }
`

const DelayVisibilityBlock = styled.div`
  animation: 0.5s linear 1s forwards ${fadeIn};
  opacity: 0;
  height: 0;
  position: relative;
  z-index: 1;
`

export interface Props {
  submitting: boolean
  submittingTranslation?: string
  success?: boolean
  successTranslation?: string
  ignoreSuccess?: boolean
  failed: boolean
  failedTranslation?: string
  failedMessages?: readonly string[]
}

const SubmitStatus: React.FC<Props> = props => {
  const {
    children = null,
    submitting,
    submittingTranslation = 'myLeaseplan.shared.formSubmittingMessage',
    failed,
    failedMessages,
    failedTranslation = 'myLeaseplan.shared.formSubmitFailedMessage',
    ignoreSuccess,
    success,
    successTranslation = 'myLeaseplan.shared.formSubmitSuccessMessage',
  } = props

  if (submitting) {
    return (
      <>
        <div className="submit-status submit-status--submitting">
          {children}
        </div>
        <DelayVisibilityBlock>
          <Spacing mb={1}>
            <IconText icon={SpinningLoaderIcon} iconSize="m" fontWeight="bold">
              <Translation id={submittingTranslation} />
            </IconText>
          </Spacing>
        </DelayVisibilityBlock>
      </>
    )
  }

  if (failed) {
    const failMessages = failedMessages
    const renderMessage = failMessages && failMessages.length > 0
    const renderList = renderMessage && failMessages.length > 1

    return (
      <>
        <SMessageBarContainer data-e2e-id="errorMessage">
          <MessageBar variant="alert">
            {renderList ? (
              <List>
                {failMessages.map(message => (
                  <ListItem key={message}>
                    <MessageBar.Text>{message}</MessageBar.Text>
                  </ListItem>
                ))}
              </List>
            ) : (
              <MessageBar.Text>
                {renderMessage ? (
                  failMessages[0]
                ) : (
                  <Translation id={failedTranslation} />
                )}
              </MessageBar.Text>
            )}
          </MessageBar>
        </SMessageBarContainer>
        <div className="submit-status submit-status--failed">{children}</div>
      </>
    )
  }

  if (success && !ignoreSuccess) {
    return (
      <>
        <SMessageBarContainer>
          <MessageBar variant="success">
            <MessageBar.Text>
              <Translation id={successTranslation} />
            </MessageBar.Text>
          </MessageBar>
        </SMessageBarContainer>
        <div className="submit-status submit-status--success">{children}</div>
      </>
    )
  }

  return <div className="submit-status">{children}</div>
}

export default SubmitStatus
