import { push } from 'connected-react-router'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  getLanguage,
  getLocalizedRoute,
  getLocale,
} from 'mlp-client/src/localization/selectors'
import { ClearUser } from 'mlp-client/src/user/actions'
import {
  ActionType as ApiActionType,
  ApiError,
  ApiSagas,
} from 'mlp-client/src/api'
import { isFeatureEnabled } from 'mlp-client/src/selectors'

import * as actions from './actions'
import * as auth from './auth'
import { getIsMobileApp, getIdentityClientId, getNextRoute } from './selectors'
import { getExternalLogoutLinkByLocale } from './utils'
import { postMessage, PostMessageType } from './mobile-utils'

export function* authorize({
  payload: { connection, route },
}: actions.Authorize): SagaIterator {
  yield put(new actions.SetLoading({ loading: true }))
  yield put(new actions.SetNextRoute({ route }))
  const language = yield select(getLanguage)

  yield call(auth.authorize, language, connection)
  yield put(new actions.SetLoading({ loading: false }))
}

export function* login(): SagaIterator {
  yield put(new actions.SetLoading({ loading: true }))

  const nextRoute = yield select(getNextRoute)

  if (!nextRoute) {
    /**
     * Normally nextRoute will already have been set by protectRoute, except
     * when a direct url to the (unprotected) login page is used, in which case
     * we set it to the default route here.
     */
    const defaultNextRoute = yield select(
      getLocalizedRoute,
      'myLeaseplan.homepage',
    )

    yield put(new actions.SetNextRoute({ route: defaultNextRoute }))
  }

  const language = yield select(getLanguage)

  yield call(auth.authorize, language)
  yield put(new actions.SetLoading({ loading: false }))
}

export function* logout({
  payload: { nextRoute },
}: actions.Logout): SagaIterator {
  const externalLogout = yield select(
    isFeatureEnabled,
    'isExternalLoginEnabled',
  )
  const locale = yield select(getLocale)
  const redirectRoute = externalLogout
    ? getExternalLogoutLinkByLocale(locale)
    : nextRoute

  yield put(new actions.SetLoading({ loading: true }))
  yield put(new actions.SetNextRoute({ route: redirectRoute }))
  yield call(auth.logout)
  yield put(new actions.SetLoading({ loading: false }))
}

/**
 * TODO: Maybe we can use the `default_locale` from the access-token metadata to
 * choose the locale, see
 * https://leaseplan-digital.atlassian.net/wiki/spaces/ARCH/pages/539164726/Identity+and+Access+Management
 * Currently we just use `nextRoute`, which has been localized already on
 * initiating the login process, but this causes problems if we login from a
 * certain locale with a user that is not configured for that locale.
 * If we can and want to use `default_locale` we need a dot-separated route key
 * rather than a nextRoute path though since we will need to localize it here.
 */
export function* processLoginResponse({
  payload: { nextRoute },
}: actions.ProcessLoginResponse): SagaIterator {
  yield put(new actions.SetLoading({ loading: true }))

  try {
    yield call(auth.processLogin)

    /**
     * The login-redirect page does not contain the locale, which means we need
     * to do a full refresh here, since we cannot change the locale on a
     * client-side route change.
     * TODO: Fix this. (Maybe store locale in a cookie and use that if url does
     * not contain locale?)
     */
    window.location.replace(nextRoute)
  } catch (e) {
    // TODO: fix or remove as a URL rather than route key should be pushed
    yield put(push('myLeaseplan.somethingWentWrong'))
  } finally {
    yield put(new actions.ClearNextRoute())
    yield put(new actions.SetLoading({ loading: false }))
  }
}

export function* processLogoutResponse(
  action: actions.ProcessLogoutResponse,
): SagaIterator {
  yield put(new actions.SetLoading({ loading: true }))
  yield put(new ClearUser())

  /**
   * Full refresh is necessary because logout-redirect has no locale anymore,
   * see processLoginResponse().
   */
  window.location.replace(action.payload.nextRoute)

  yield put(new actions.ClearNextRoute())
  yield put(new actions.SetLoading({ loading: false }))
}

export function* handle401(action: ApiError): SagaIterator {
  if (
    action.payload &&
    action.payload.error &&
    action.payload.error.status === 401
  ) {
    const unauthorizedRoute = yield select(
      getLocalizedRoute,
      'myLeaseplan.unauthorized',
    )

    yield call(auth.logout)

    if (unauthorizedRoute && window.location.pathname !== unauthorizedRoute) {
      const isMobileApp = yield select(getIsMobileApp)

      if (isMobileApp) {
        return yield call(postMessage, { type: PostMessageType.Unauthorized })
      }

      yield put(new actions.SetNextRoute({ route: window.location.pathname }))
      yield put(push(unauthorizedRoute))
    }
  }
}

export function* changePassword({
  payload: { email },
}: actions.ChangePassword): SagaIterator {
  const clientId = yield select(getIdentityClientId)

  yield call(ApiSagas.post, `/myleaseplan/change-password`, {
    body: {
      email,
      client_id: clientId,
      password: '',
      connection: 'Username-Password-Authentication',
    },
  })
}

export default [
  takeLatest(actions.ActionTypes.AUTHORIZE, authorize),
  takeLatest(actions.ActionTypes.LOGIN, login),
  takeLatest(actions.ActionTypes.LOGOUT, logout),
  takeLatest(actions.ActionTypes.PROCESS_LOGIN_RESPONSE, processLoginResponse),
  takeLatest(
    actions.ActionTypes.PROCESS_LOGOUT_RESPONSE,
    processLogoutResponse,
  ),
  takeLatest(actions.ActionTypes.CHANGE_PASSWORD, changePassword),
  takeEvery(ApiActionType.API_ERROR, handle401),
]
