import React from 'react'
import Classnames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom'
import { Action, Dispatch } from 'redux'
import { Span } from 'dd-trace'

import { CountryCode, LanguageCode } from 'mlp-client/src/localization/enums'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

import { AppState, Route as RouteType } from './types'
import getLocalizedRoutes from './routes'
import * as localizationSelectors from './localization/selectors'
import { BuildInfoRibbon } from './BuildInfoRibbon'
import { Routes } from './localization/types'

export interface Props extends RouteComponentProps<{}> {
  routes: Routes
  languageCode: string
  countryCode: string
  locale: string
  noContractAllowedLocale: boolean
  countryVisible: boolean
  dispatch: Dispatch<Action>
  renderSpan: Span | null
}

class App extends React.PureComponent<Props> {
  routeWithSubRoutes = (route: RouteType) => (
    <Route
      key={route.path}
      exact={route.exact}
      strict={route.strict}
      path={route.path}
      render={(props: GenericObject) => {
        const { staticContext } = props

        if (staticContext) {
          staticContext.statusCode = route.status || 200
        }

        if (this.props.renderSpan) {
          this.props.renderSpan.setTag('route', route.path)
        }

        return (
          <div>
            <route.component
              {...route.props}
              pageTitle={route.pageTitle}
              pageTitleKey={route.pageTitleKey}
              {...props}
            />
          </div>
        )
      }}
    />
  )

  render() {
    const {
      countryCode,
      locale,
      routes,
      languageCode,
      noContractAllowedLocale,
    } = this.props

    const robotsDefault = this.props.countryVisible
      ? 'index,follow'
      : 'noindex,nofollow'

    const localizedRoutes = getLocalizedRoutes(
      routes,
      locale,
      noContractAllowedLocale,
    )

    /**
     * Map languages to a onetrust counterpart. We do this because onetrust does not support all languages,
     * but we must display the cookie banner and cookie policy in a local format.
     */
    const oneTrustLanguageMap = {
      [LanguageCode.nb]: LanguageCode.no,
    }

    return (
      <div
        className={Classnames([
          {
            // We do not own a Cyrillic font. instead we default to Noir Pro on most browsers except IE, where we default to Arial
            'u-font-family--russian': countryCode === CountryCode.ru,
          },
          // { 'u-font-family--luxemburg': !isLeaseplan() }, //TO UPDATE
        ])}
      >
        <BuildInfoRibbon />
        <Helmet
          defaultTitle={isLeaseplan() ? 'LeasePlan' : 'Leasys'}
          titleTemplate={`%s | ${isLeaseplan() ? 'LeasePlan' : 'Leasys'}`}
          meta={[
            { name: 'description', content: 'LeasePlan' },
            { name: 'robots', content: robotsDefault },
            { name: 'format-detection', content: 'telephone-no' },
          ]}
          htmlAttributes={{
            lang: oneTrustLanguageMap[languageCode] || languageCode,
          }}
        />

        <Switch>{localizedRoutes.map(this.routeWithSubRoutes)}</Switch>
      </div>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'routes'
  | 'countryCode'
  | 'languageCode'
  | 'countryVisible'
  | 'locale'
  | 'noContractAllowedLocale'
>

const mapStateToProps = (state: AppState): ReduxProps => {
  const localeParams = localizationSelectors.getLocaleParams(state)
  const countryVisible = localizationSelectors.isCountryVisible(state)
  const routes = localizationSelectors.getRoutes(state)
  const noContractAllowedLocale = localizationSelectors.noContractAllowedLocale(
    state,
  )

  return {
    routes,
    countryVisible,
    languageCode: localeParams.languageCode,
    countryCode: localeParams.countryCode,
    locale: localeParams.locale,
    noContractAllowedLocale,
  }
}

// Don't apply `hot` if `module.hot` is false, to prevent misconfiguration errors on development non-dev-server builds.
const hotOrNot = module.hot ? hot : (c: React.ComponentType<any>) => c

export default hotOrNot(withRouter(connect(mapStateToProps)(App)))
