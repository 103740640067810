import { Grid, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import styled from 'styled-components'
import { OverlayView } from '@react-google-maps/api'

import { getPixelPositionOffset } from 'mlp-client/src/components/map-with-markers/utils'
import SupplierMarkerIcon from 'mlp-client/src/components/icons/supplier-marker-icon/SupplierMarkerIcon'

import { closedIconSize, openedIconSize } from './utils'

const Header = styled(Spacing)`
  background-color: white;
`

export const MarkerIconWrapper = styled.div`
  margin-top: 10px;
`

const HeaderWrapper = styled(Header)`
  z-index: 10;
`

export interface Props {
  supplierName: string
  isOpen: boolean
  showSupplierName: boolean
  position: google.maps.LatLngLiteral
  onClick(event?: React.SyntheticEvent<HTMLDivElement>): void
}

export class SupplierMarker extends React.PureComponent<Props> {
  render() {
    const {
      position,
      supplierName,
      onClick,
      isOpen,
      showSupplierName,
    } = this.props
    const iconSize = isOpen ? openedIconSize : closedIconSize

    return (
      <OverlayView
        getPixelPositionOffset={getPixelPositionOffset}
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Grid direction="column" alignItems="center">
          {isOpen && showSupplierName ? (
            <HeaderWrapper p={0.5}>
              <Text variant="100">{supplierName}</Text>
            </HeaderWrapper>
          ) : null}

          <MarkerIconWrapper onClick={onClick}>
            <SupplierMarkerIcon {...iconSize} title={supplierName} />
          </MarkerIconWrapper>
        </Grid>
      </OverlayView>
    )
  }
}
