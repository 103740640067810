import { Color, IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'

class VehicleIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        <g fill={Color[color]} fillRule="evenodd">
          <path d="M1 15a1 1 0 112 0v1a1 1 0 11-2 0v-1zm1 5a1 1 0 110-2h1a1 1 0 110 2H2zm4 0a1 1 0 110-2h2a1 1 0 110 2H6zm5 0a1 1 0 110-2h1a1 1 0 110 2h-1zm4 0a1 1 0 110-2h1a1 1 0 110 2h-1z" />
          <path d="M22 28a2 2 0 10-4 0 2 2 0 004 0" />
          <path d="M17 30a4 4 0 01-1-2l1-3a4 4 0 013-1 4 4 0 014 4 4 4 0 01-4 4h-1a4 4 0 01-2-2m11-9a3 3 0 01-1-1h2l4 3c-3 0-4-1-5-2m6 11H24a6 6 0 001-3h5a4 4 0 005-4v-2c0-3-4-4-5-4l-3-1-4-1-4-1-5-3s-4-3-9-3H2a1 1 0 100 2h3c5 0 8 2 8 2l5 4h1l2 1h1l4 1 1 2c1 2 3 3 6 3a3 3 0 01-3 2h-5a6 6 0 00-1-3 6 6 0 00-4-2 6 6 0 00-4 2 6 6 0 00-2 3H2a1 1 0 000 2h12a6 6 0 002 3H2a1 1 0 100 2h17a5 5 0 001 0h14a1 1 0 100-2" />
        </g>
      </svg>
    )
  }
}

export default VehicleIcon
