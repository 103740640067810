import _omit from 'lodash/omit'

import { FileStatus } from 'mlp-client/src/form/components/fields/file-upload-field/enums'
import { FileList } from 'mlp-client/src/form/components/fields/file-upload-field/types'
import * as actions from 'mlp-client/src/form/components/fields/file-upload-field/actions'

export interface State {
  fileUpload: FileList
}

const initialState = {
  fileUpload: {},
}

export const submitFileUpload = (
  state: State,
  action: actions.SubmitFileUpload,
) => {
  const fileUpload = state.fileUpload

  fileUpload[action.payload.meta.id] = action.payload.meta

  return {
    ...state,
    fileUpload,
  }
}

export const uploadProgress = (
  state: State,
  action: actions.FileUploadProgress,
) => ({
  ...state,
  fileUpload: {
    ...state.fileUpload,
    [action.payload.meta.id]: {
      ...state.fileUpload[action.payload.meta.id],
      progress: action.payload.progress,
    },
  },
})

export const uploadSuccess = (
  state: State,
  action: actions.FileUploadSuccess,
) => ({
  ...state,
  fileUpload: {
    ...state.fileUpload,
    [action.payload.meta.id]: {
      ...state.fileUpload[action.payload.meta.id],
      status: FileStatus.SUCCESS,
      ...action.payload.result,
    },
  },
})

export const uploadFailed = (
  state: State,
  action: actions.FileUploadFailure,
) => ({
  ...state,
  fileUpload: {
    ...state.fileUpload,
    [action.payload.meta.id]: {
      ...state.fileUpload[action.payload.meta.id],
      status: FileStatus.ERROR,
    },
  },
})

export const removeFile = (state: State, action: actions.RemoveFile) => {
  const fileUpload = _omit(state.fileUpload, [action.payload.fileId])

  return {
    ...state,
    fileUpload,
  }
}

export const addFiles = (state: State, action: actions.AddFiles) => {
  const fileUpload = action.payload.meta.reduce(
    (acc, value) => ({
      ...acc,
      [value.id]: value,
    }),
    state.fileUpload,
  )

  return {
    ...state,
    fileUpload,
  }
}

const formReducer = (
  state: State = initialState,
  action: actions.Action,
): State => {
  switch (action.type) {
    case actions.ActionTypes.SUBMIT_FILE_UPLOAD:
      return submitFileUpload(state, action)
    case actions.ActionTypes.FILE_UPLOAD_PROGRESS:
      return uploadProgress(state, action)
    case actions.ActionTypes.FILE_UPLOAD_SUCCESS:
      return uploadSuccess(state, action)
    case actions.ActionTypes.FILE_UPLOAD_FAILURE:
      return uploadFailed(state, action)
    case actions.ActionTypes.REMOVE_FILE:
      return removeFile(state, action)
    case actions.ActionTypes.ADD_FILES:
      return addFiles(state, action)
    default:
      return state
  }
}

export default formReducer
