import React from 'react'
import { Form } from 'react-final-form'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import RadioClickAndGoList from 'mlp-client/src/form/components/fields/RadioFields/RadioClickAndGoList/RadioClickAndGoList'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { Options } from 'mlp-client/src/form/types'

export interface StepConfig {
  title: string
  options: Options
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
}
type FormValues = Pick<FlowData, 'oneOrTwo'>

export class DamageSideStep extends React.PureComponent<Props> {
  onSubmit = ({ oneOrTwo }: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData({ oneOrTwo }, goToNextStep)
  }

  render() {
    const {
      config: { title, options },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel={title}>
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit }) => (
              <RadioClickAndGoList
                validations={[]}
                name="oneOrTwo"
                options={options}
                onChange={handleSubmit}
                dataE2eId={'damageSideList'}
              />
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default DamageSideStep
