import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { TileProps } from 'mlp-client/src/dashboard/type'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles/BasicTile'
import { canReturn, getEndDate } from 'mlp-client/src/contracts/selectors'
import { isContractExpired } from 'mlp-client/src/contracts/utils'
import { formatDate } from 'mlp-client/src/utils'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import Translation from 'mlp-client/src/localization/Translation'

export const ReturnTile: React.FC<TileProps> = props => {
  const canReturnFunction = useSelector(canReturn)
  const endDate = useSelector(getEndDate)

  const context = useContext(MyLeaseplanContext)

  const isReturnEnabled = canReturnFunction(context)

  if (!isReturnEnabled) {
    return null
  }

  const isExpired = isContractExpired(endDate)
  const formattedEndDate = endDate ? formatDate(endDate, 'LL') : ''

  const title = isExpired ? (
    <Translation
      id="myLeaseplan.return.steps.endLease.ended"
      replace={{ date: formattedEndDate }}
    />
  ) : (
    <Translation
      id="myLeaseplan.return.dashboard.title"
      replace={{ endDate: formattedEndDate }}
    />
  )

  const tileProps = {
    ...props,
    title: props.title || title,
  }

  return <BasicTile {...tileProps} />
}
