import React from 'react'
import { connect } from 'react-redux'
import { Color, Link, Spacing } from '@leaseplan/ui'
import styled from 'styled-components'

import { PrimaryDarkText } from 'mlp-client/src/components/styled/TextStyle'
import Translation from 'mlp-client/src/localization/Translation'
import { AppState } from 'mlp-client/src/types'
import { getQuoteById } from 'mlp-client/src/quotes/selectors'
import { Quote } from 'mlp-client/src/quotes/types'
import ChevronRightIcon from 'mlp-client/src/components/icons/chevron-right-icon/ChevronRightIcon'
import RouteLink from 'mlp-client/src/localization/RouteLink'

interface Props {
  quote?: Quote
  children?: React.ReactNode
  quoteId?: string
}

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${Color.thunder};
  &:hover {
    text-decoration: none;
  }
`

class PendingApprovalQuote extends React.PureComponent<Props> {
  renderYourVehicleLabel = (): React.ReactNode => (
    <Translation key="yourVehicle" id="myLeaseplan.orderStatus.yourVehicle" />
  )

  render() {
    const { quoteId } = this.props

    return (
      <>
        Here’s your quote. Once approved, we’ll create your new lease contract.
        <Spacing mt={1}>
          <LinkStyled
            component={RouteLink}
            to="myLeaseplan.quoteDetails"
            params={{ quoteId }}
          >
            <PrimaryDarkText variant="100" bold>
              <ChevronRightIcon
                color="midOrange"
                className="u-margin-right-tiny"
              />
              View quote
            </PrimaryDarkText>
          </LinkStyled>
        </Spacing>
      </>
    )
  }
}

type ReduxProps = Pick<Props, 'quote'>
type OwnProps = Omit<Props, keyof ReduxProps>

export const mapStateToProps = (
  state: AppState,
  props: OwnProps,
): ReduxProps => ({
  quote: getQuoteById(state)(props.quoteId),
})

export { PendingApprovalQuote }
export default connect(mapStateToProps)(PendingApprovalQuote)
