import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import React from 'react'
import Measure, { ContentRect } from 'react-measure'

import {
  contains,
  expand,
  getOverflowingBoundingRect,
} from 'mlp-client/src/components/layout/top-navigation/utils'
import { XCoordinates } from 'mlp-client/src/types'

interface DefaultProps {
  element: string
}

interface NonDefaultProps {
  childRefs: GenericObject
  onOverflowChange(overflow: readonly boolean[]): void
  className?: string
}

type Props = Partial<DefaultProps> & NonDefaultProps

interface State {
  overflow: readonly boolean[]
}

/**
 * This container can detect if its child-elements are overflowing.
 *
 * Example usage:
 *
 *  <Overflow
 *    childRefs={this.overflowChildRefs}
 *    onOverflowChange={this.handleOverflowChange}
 *  >
 *    <div ref={ref => {this.overflowChildRefs.myReference = ref}}>
 *      Am I overflowing?
 *    </div>
 *  </Overflow>
 *
 */
class Overflow extends React.PureComponent<Props, State> {
  static defaultProps: DefaultProps = {
    element: 'div',
  }

  state: State = { overflow: [] }

  handleResize = ({ bounds: { left, right } }: ContentRect) => {
    this.calculateOverflow({ left, right })
  }

  handleOverflowChange = () => {
    this.props.onOverflowChange(this.state.overflow)
  }

  calculateOverflow = (bounds: XCoordinates) => {
    const childRefs = this.props.childRefs

    // Calculate overflow per childReference.
    const overflow = Object.keys(childRefs)
      .filter(key => childRefs[key])
      .reduce(
        (acc, key) => ({
          ...acc,
          // Calculate wether or not the childReference is overflowing, and save it in the accumulator using the key.
          [key]: !contains(
            expand(bounds, 2),
            getOverflowingBoundingRect(childRefs[key]),
          ),
        }),
        // We start off with our current saved state.
        this.state.overflow,
      )

    if (!isEqual(overflow, this.state.overflow)) {
      // ... Calculated overflow was different than the saved state.
      // Let's save the state and notify everyone who wants to know.
      this.setState({ overflow }, this.handleOverflowChange)
    }
  }

  render() {
    const elementProps = omit(this.props, [
      'element',
      'childRefs',
      'onOverflowChange',
    ])

    return (
      <Measure bounds={true} onResize={this.handleResize}>
        {({ measureRef }) =>
          React.createElement(
            this.props.element,
            { ...elementProps, ref: measureRef },
            this.props.children,
          )
        }
      </Measure>
    )
  }
}

export default Overflow
