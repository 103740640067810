import { DefinitionListItem, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import {
  getTranslations,
  noContractAllowedLocale,
} from 'mlp-client/src/localization/selectors'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import { getAddressFormat } from 'mlp-client/src/selectors'
import { userProfileSelector } from 'mlp-client/src/user/selectors'
import { hasContracts } from 'mlp-client/src/contracts/selectors'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { AddressFormat, UserProfile } from 'mlp-client/src/user/types'
import {
  formatAddress,
  formatPersonName,
  formatPhoneNumber,
} from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

import { UserProfileList } from './ViewProfile.styled'
import { getAddressLabels } from './utils'

interface ProfileItem {
  id: string
  value: string | React.ReactElement<any>
}

export interface Props {
  metaDescription: string
  metaTitle: string
  userProfile: UserProfile
  addressFormat: AddressFormat
  hasContracts: boolean
  noContractAllowedLocale: boolean
}

const StyledText = styled(Text)`
  color: ${props => props.theme.velocity.styling.gray.main};
`

class ViewProfile extends React.PureComponent<Props> {
  private renderProfileItem = ({ id, value }: ProfileItem) => {
    if (!value) {
      return null
    }

    const term = (
      <StyledText data-e2e-id={`field-${id}`} variant="200" component="span">
        <Translation id={`myLeaseplan.profile.user.${id}`} />
      </StyledText>
    )
    const description = (
      <SecondaryDarkText data-e2e-id={id} variant="200" bold component="span">
        {value}
      </SecondaryDarkText>
    )

    return <DefinitionListItem key={id} term={term} description={description} />
  }

  renderMainContent = (context: MyleaseplanConfig) => {
    const {
      addressFormat,
      userProfile,
      hasContracts,
      noContractAllowedLocale,
    } = this.props
    const { showCompanyAddress, showPrivateAddressLabel, profile } = context
    const isEditButtonVisible =
      !(!hasContracts && noContractAllowedLocale) &&
      context.editProfile?.enabled
    const addressLabels = getAddressLabels(
      showPrivateAddressLabel,
      showCompanyAddress,
    )

    const profileItemAddresses = userProfile.addresses
      .filter(address => Boolean(addressLabels[address.type]))
      .map(address => {
        const formattedAddress = formatAddress(address, addressFormat)

        return {
          id: addressLabels[address.type],
          value: (
            <>
              {formattedAddress.map((line, idx) => (
                <div key={idx}>{line}</div>
              ))}
            </>
          ),
        }
      })

    const profileItemConfig: readonly ProfileItem[] = [
      { id: 'fullName', value: formatPersonName(userProfile) },
      { id: 'email', value: userProfile.email },
      {
        id: 'phoneNumber',
        value: profile?.hideCountryCode
          ? userProfile.phone
          : formatPhoneNumber(userProfile.phoneCountryCode, userProfile.phone),
      },
      {
        id: 'mobilePhoneNumber',
        value: profile?.hideCountryCode
          ? userProfile.mobile
          : formatPhoneNumber(
              userProfile.mobilePhoneCountryCode,
              userProfile.mobile,
            ),
      },
      ...profileItemAddresses,
    ]

    return (
      <Spacing mb={8}>
        <Spacing mb={2}>
          <Headline variant="400" component="h1" withMarginBottom>
            <Translation id="myLeaseplan.profile.title" />
          </Headline>
        </Spacing>

        <UserProfileList descriptionAlignment="left" verticalSpacing={1}>
          {profileItemConfig.map(this.renderProfileItem)}
        </UserProfileList>

        <Spacing mt={3}>
          <Grid gutter={false}>
            <GridItem span={{ mobile: 12, tablet: 6 }}>
              {isEditButtonVisible && (
                <StyledButton
                  stretch={true}
                  component={RouteLink}
                  to="myLeaseplan.profile.edit"
                >
                  <Translation id="myLeaseplan.profile.edit.edit" />
                </StyledButton>
              )}
            </GridItem>
            {/* TODO: Add "change password" button when the page is ready */}
          </Grid>
        </Spacing>
      </Spacing>
    )
  }

  render() {
    const { userProfile, metaTitle, metaDescription } = this.props

    return (
      <MyLeaseplanContext.Consumer>
        {context => (
          <MyleaseplanPage
            metaTitle={metaTitle}
            metaDescription={metaDescription}
          >
            {!isEmpty(userProfile) && (
              <GridLayout main={this.renderMainContent(context)} />
            )}
          </MyleaseplanPage>
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'metaDescription'
  | 'metaTitle'
  | 'userProfile'
  | 'addressFormat'
  | 'hasContracts'
  | 'noContractAllowedLocale'
>

const mapTranslationsToProps = {
  metaTitle: 'myLeaseplan.profile.detail.title',
  metaDescription: 'myLeaseplan.profile.detail.subtitle',
}

export const mapStateToProps = (state: AppState): ReduxProps => ({
  userProfile: userProfileSelector(state),
  addressFormat: getAddressFormat(state),
  hasContracts: hasContracts(state),
  noContractAllowedLocale: noContractAllowedLocale(state),
  ...getTranslations(state, mapTranslationsToProps),
})

export { ViewProfile }
export default connect(mapStateToProps)(ViewProfile)
