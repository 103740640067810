/*

This is a very basic disposable module that provides a `slicer` and `merge` for the 'redux-sessionstorage' package
to persist the MLP redux state. We only need this for storing `nextRoute` and `loading` before navigating to Auth0,
so these values can be retrieved after navigating back to LeasePlan (Auth0 callback urls cannot include paths or
parameters).

TODO: Get rid of this module! The most recent release of 'redux-sessionstorage' a version 0.4.0 from 2015, with only 3
master commits since. Since we're migrating to a plain JavaScript object redux store, persistence will be easier, and
there should be more modern and elegant solutions for it.

*/

import { AppState } from 'mlp-client/src/types'

/**
 * The type of the object we keep in session storage. Note that we do not distinguish between immutableJS and plain
 * JavaScript objects, so no tricky (de)serialization is needed.
 */
interface PersistedSlice {
  auth: { loading: boolean; nextRoute: string }
}

export const slicer = () => (state: AppState): PersistedSlice => {
  const { loading, nextRoute } = state?.auth ?? {}

  return { auth: { loading, nextRoute } }
}

export const merge = (
  state: AppState,
  persistedSlice: PersistedSlice,
): AppState => {
  const { loading: persistedLoading, nextRoute: persistedNextRoute } =
    persistedSlice?.auth ?? {}

  // Create a copy of nonImmutable with persistedLoading & persistedNextRoute if they exist.
  return {
    ...state,
    auth: {
      ...state.auth,
      ...(typeof persistedLoading !== 'undefined'
        ? { loading: persistedLoading }
        : {}),
      ...(typeof persistedNextRoute !== 'undefined'
        ? { nextRoute: persistedNextRoute }
        : {}),
    },
  }
}
