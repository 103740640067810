import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { Contract } from 'mlp-client/src/contracts/types'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import * as actions from 'mlp-client/src/flows/fuel-card/actions'
import { getFuelCardSubmitBody } from 'mlp-client/src/flows/fuel-card/selectors'
import {
  FuelCards,
  FuelcardServiceType,
} from 'mlp-client/src/flows/fuel-card/types'
import { ApiSagas } from 'mlp-client/src/api'

const defaultOptions = {
  throwError: true,
}

export function* loadFuelCardTypes(): SagaIterator {
  const requestUrl = '/myleaseplan/lpis/fuelcard-types/'

  try {
    const data = yield call(ApiSagas.get, requestUrl, {}, defaultOptions)

    yield put(
      new actions.GetFuelCardTypesSuccess({
        data,
      }),
    )
  } catch {
    yield put(new actions.GetFuelCardTypesFail())
  }
}

export function* submitFuelCard({
  payload: { flowData, onComplete },
}: actions.SubmitFuelCard): SagaIterator {
  const body = yield select(getFuelCardSubmitBody, flowData)
  let fuelCardEndpoint = '/myleaseplan/lpis/submit-fuel-card/replace-card'

  if (body.reason === FuelcardServiceType.FORGOT_PIN) {
    fuelCardEndpoint = '/myleaseplan/lpis/submit-fuel-card/new-pin'
  } else if (
    body.reason === FuelcardServiceType.LOST_STOLEN ||
    body.reason === FuelcardServiceType.ALL_STOLEN
  ) {
    fuelCardEndpoint = '/myleaseplan/lpis/submit-fuel-card/lost-stolen'
  }

  try {
    yield call(
      ApiSagas.post,
      fuelCardEndpoint,
      {
        body,
      },
      defaultOptions,
    )

    yield call(onComplete, false)
  } catch {
    yield call(onComplete, true)
  }
}

export function* loadFuelCards(): SagaIterator {
  const contract: Contract = yield select(getCurrentContract)
  const requestUrl = `/myleaseplan/lpis/fuel-cards/${contract.nolsId}`

  try {
    const fuelCards: FuelCards = yield call(
      ApiSagas.get,
      requestUrl,
      {},
      defaultOptions,
    )

    yield put(
      new actions.LoadFuelCardsSuccess({
        fuelCards,
      }),
    )
  } catch {
    yield put(new actions.LoadFuelCardsFail())
  }
}

export default [
  takeLatest(
    actions.ActionTypes.GET_FUEL_CARD_TYPES_REQUEST,
    loadFuelCardTypes,
  ),
  takeLatest(actions.ActionTypes.SUBMIT_FUEL_CARD, submitFuelCard),
  takeLatest(actions.ActionTypes.LOAD_FUEL_CARDS, loadFuelCards),
]
