import ViewContract from 'mlp-client/src/contracts/components/ViewContractFlow'
import {
  ViewContractTabs,
  ContractOptionsListMaintenanceProperties,
  ContractOptionsListProperties,
  ContractOptionsListRoadsideAssistanceProperties,
  ContractOptionsListTyresProperties,
  ContractSectionProperties,
  InsuranceSectionProperties,
  VehicleSectionProperties,
  ViewContractSections,
} from 'mlp-client/src/contracts/enums'
import { ContractsFeatureConfig as LpisContractsFeatureConfig } from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'

export const contractsConfig: MyLeaseplanFeature<LpisContractsFeatureConfig> = {
  enabled: true,
  view: ViewContract,
  configuration: {
    policyDocuments: false,
    contractStatusBlock: false,
    showErrorIfEmptyContracts: true,
    contractOptionsSection: {
      [ContractOptionsListProperties.DAMAGE_SERVICE]: [],
      [ContractOptionsListProperties.MAINTENANCE]: [
        ContractOptionsListMaintenanceProperties.MAINTENANCE_MODULE,
        ContractOptionsListMaintenanceProperties.REPLACEMENT_VEHICLE_MODULE,
        ContractOptionsListMaintenanceProperties.CAR_WASH_MODULE,
      ],
      [ContractOptionsListProperties.ROADSIDE_ASSISTANCE]: [
        ContractOptionsListRoadsideAssistanceProperties.EUROPEAN_COVERAGE_MODULE,
      ],
      [ContractOptionsListProperties.TYRES]: [
        ContractOptionsListTyresProperties.TYRE_REPLACEMENT_MODULE,
      ],
      [ContractOptionsListProperties.RADIO_LICENCE_FEE]: [],
    },
    tabs: {
      [ViewContractTabs.Contract]: {
        [ViewContractSections.VehicleDetails]: [
          VehicleSectionProperties.LICENSE_PLATE,
          VehicleSectionProperties.MAKE,
          VehicleSectionProperties.MODEL,
          VehicleSectionProperties.FUEL,
          VehicleSectionProperties.COLOR,
        ],
        [ViewContractSections.Contract]: [
          ContractSectionProperties.START_DATE,
          ContractSectionProperties.END_DATE,
          ContractSectionProperties.TAXATION_VALUE,
          ContractSectionProperties.LAST_KNOWN_MILEAGE,
          ContractSectionProperties.LAST_KNOWN_FUEL_MILEAGE,
          ContractSectionProperties.MAX_MILEAGE,
        ],
        [ViewContractSections.Insurance]: [
          InsuranceSectionProperties.COMPANY,
          InsuranceSectionProperties.TYPE,
        ],
      },
    },
    services: {
      [ViewContractSections.Insurance]: {
        serviceNumbers: ['910'],
        [InsuranceSectionProperties.TYPE]: {
          serviceNumbers: ['910'],
        },
      },
      [ContractOptionsListProperties.MAINTENANCE]: {
        [ContractOptionsListMaintenanceProperties.MAINTENANCE_MODULE]: {
          description: 'Fahrerbetreuung',
        },
        [ContractOptionsListMaintenanceProperties.PICK_UP_AND_DELIVERY_MODULE]: {
          description: 'Fahrerbetreuung',
        },
        [ContractOptionsListMaintenanceProperties.REPLACEMENT_VEHICLE_MODULE]: {
          serviceNumbers: ['497', '500', '511', '560', '570', '562'],
        },
        [ContractOptionsListMaintenanceProperties.CAR_WASH_MODULE]: {
          description: 'Fahrerbetreuung',
        },
      },
      [ContractOptionsListProperties.ROADSIDE_ASSISTANCE]: {
        [ContractOptionsListRoadsideAssistanceProperties.EUROPEAN_COVERAGE_MODULE]: {
          serviceNumbers: ['1300'],
        },
      },
      [ContractOptionsListProperties.TYRES]: {
        [ContractOptionsListTyresProperties.TYRE_REPLACEMENT_MODULE]: {
          serviceNumbers: ['201', '202', '2201', '2202', '300'],
        },
      },
      [ContractOptionsListProperties.DAMAGE_SERVICE]: {
        serviceNumbers: ['1150', '1151', '1152', '1153'],
      },
      [ContractOptionsListProperties.RADIO_LICENCE_FEE]: {
        serviceNumbers: ['700'],
      },
      fuelCards: {
        serviceNumbers: ['420', '430'],
        cardsList: [
          {
            name: 'Aral',
            serviceNumbers: ['430'],
          },
          {
            name: 'Shell',
            serviceNumbers: ['420'],
          },
        ],
        additionalServices: [
          {
            translation:
              'myLeaseplan.contract.additionalServices.carCleaning.title',
          },
          {
            translation:
              'myLeaseplan.contract.contract.properties.smallPartsReplacementOil',
          },
        ],
      },
    },
  },
}
