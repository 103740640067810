import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const CashClaimIconLeasys: FC<IconProps> = props => {
  CashClaimIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.35 45.35"
      width={width}
      height={height}
    >
      <g fill={ColorEnumSelector(color)}>
        <path
          d="M26.86,7.06c0.35,0,0.53,0.05,0.6,0.06v6.05c0,1.43,1.16,2.58,2.58,2.58h5.18c0.01,0.11,0.03,0.22,0.03,0.33
				V34.5c0,2.09-1.7,3.79-3.79,3.79H13.9c-2.09,0-3.8-1.7-3.8-3.79V10.85c0-2.09,1.7-3.8,3.8-3.8H26.86z M37.25,34.5V16.08
				c0-1.26-0.46-2.48-1.3-3.42L30.7,6.78c-0.97-1.09-2.37-1.72-3.84-1.72H13.9c-3.19,0-5.8,2.6-5.8,5.8V34.5
				c0,3.19,2.6,5.79,5.8,5.79h17.55C34.65,40.3,37.25,37.7,37.25,34.5"
        />
        <path
          d="M27.96,18.94c0-0.55-0.45-1-1-1c-4,0-7.34,2.36-8.47,5.59h-2.93c-0.55,0-1,0.45-1,1s0.45,1,1,1h2.53
				c-0.01,0.1-0.03,0.2-0.03,0.31c0,0.27,0.06,0.53,0.09,0.79H16.5c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h2.16
				c1.28,2.98,4.5,5.1,8.3,5.1c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1c-2.62,0-4.87-1.27-6.04-3.1h0.7c0.55,0,1-0.45,1-1
				c0-0.55-0.45-1-1-1h-1.47c-0.04-0.26-0.09-0.52-0.09-0.79c0-0.11,0.03-0.2,0.04-0.31h3.08c0.55,0,1-0.45,1-1s-0.45-1-1-1h-2.57
				c1.05-2.11,3.5-3.59,6.34-3.59C27.51,19.94,27.96,19.49,27.96,18.94"
        />
      </g>
    </svg>
  )
}

export default CashClaimIconLeasys
