import { mq } from '@leaseplan/ui'
import styled from 'styled-components'

import { MainContentWrapper } from 'mlp-client/src/components/layout/main-content-wrapper/MainContentWrapper'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

export const FullWidthBanner = styled.div<{
  image: string
}>`
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  hyphens: auto;
  word-break: break-word;
  background-size: auto 100%;
  background-position: 40% 100%;
  background-image: url(${p => p.image}),
    ${isLeaseplan()
      ? `linear-gradient(
      180deg,
      ${ColorEnumSelector('petrolBlue80')},
      ${ColorEnumSelector('aquaBlue40')}
    )`
      : `linear-gradient(90deg, ${ColorEnumSelector(
          'bloodOrange',
        )} 30%, ${ColorEnumSelector('midOrange')} 60%)`};
  align-items: center;
  height: 140px;
  ${mq.from('lap')`
    height: 200px;
    background-position: 50% 100%;
`}
`

export const BannerContent = styled(MainContentWrapper)`
  flex: 1 0 100%;
  padding: 0 24px;
  ${mq.until('tablet')`
    text-align: center;
`}
`
