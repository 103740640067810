import queryString from 'qs'

import { CountryCode } from 'mlp-client/src/localization/enums'

import { AttachCarFormParams, FrenchAttachCarParams } from './types'

export const getAttachCarUrlParams = (
  countryCode: CountryCode,
  search: string,
): Partial<AttachCarFormParams> => {
  /*
   * Query params are currently only for France
   * But others can be provided here as well
   */
  const queryParams: FrenchAttachCarParams = queryString.parse(search || '', {
    ignoreQueryPrefix: true,
  })

  if (countryCode === CountryCode.fr) {
    return {
      salutation: queryParams.civilite,
      firstName: queryParams.prenom,
      lastName: queryParams.nom,
      email: queryParams.email,
      phone: queryParams.telephone,
      licensePlate: queryParams.immatriculation,
    }
  }

  return {}
}
