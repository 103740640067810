import React from 'react'
import { connect } from 'react-redux'

import { AppState } from 'mlp-client/src/types'
import { isLanguageSelectorEnabled } from 'mlp-client/src/localization/selectors'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import CurrentLocale from 'mlp-client/src/components/layout/top-navigation/desktop/CurrentLocale'
import Hamburger from 'mlp-client/src/components/layout/top-navigation/desktop/Hamburger'
import LanguageSelector from 'mlp-client/src/components/layout/top-navigation/desktop/LanguageSelector'
import LoggedInButton from 'mlp-client/src/components/layout/top-navigation/desktop/LoggedInButton'
import LoginButton from 'mlp-client/src/components/layout/top-navigation/desktop/LoginButton'
import { getUser } from 'mlp-client/src/user/selectors'

export interface Props {
  isLanguageSelectorEnabled: boolean
  isAuthenticated: boolean
  hideLoginLink?: boolean
}

class MainMenuWidgets extends React.PureComponent<Props> {
  render() {
    const {
      isLanguageSelectorEnabled,
      isAuthenticated,
      hideLoginLink,
    } = this.props

    return (
      <MyLeaseplanContext.Consumer>
        {({ accountLinkEnabled }) => (
          <div className="top-navigation__widgets">
            <ul className="main-menu-widgets">
              {isAuthenticated && (
                <li className="main-menu-widgets__widget u-hide-until@tablet main-menu-widgets__navigation">
                  <LoggedInButton />
                </li>
              )}
              {!isAuthenticated && accountLinkEnabled && !hideLoginLink && (
                <li className="main-menu-widgets__widget u-hide-until@tablet main-menu-widgets__navigation">
                  <LoginButton />
                </li>
              )}

              <li
                className="main-menu-widgets__widget main-menu-widgets__navigation"
                data-e2e-id="currentLocale"
              >
                {isLanguageSelectorEnabled ? (
                  <LanguageSelector />
                ) : (
                  <CurrentLocale className="main-menu-widgets__link u-hide-until@lap" />
                )}

                <Hamburger />
              </li>
            </ul>
          </div>
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<Props, 'isLanguageSelectorEnabled' | 'isAuthenticated'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  isLanguageSelectorEnabled: isLanguageSelectorEnabled(state),
  isAuthenticated: !!getUser(state),
})

export { MainMenuWidgets }
export default connect(mapStateToProps)(MainMenuWidgets)
