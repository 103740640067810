import {
  DefinitionList,
  DefinitionListItem,
  Grid,
  GridItem,
  Hide,
  Link,
  Spacing,
  Ticket,
  TicketContent,
  TicketImage,
} from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import truncate from 'lodash/truncate'
import _upperFirst from 'lodash/upperFirst'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import CellphoneHandIcon from 'mlp-client/src/components/icons/cellphone-hand-icon/CellphoneHandIcon'
import TextCenter from 'mlp-client/src/components/styled/TextCenter'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { VehicleDetails } from 'mlp-client/src/order-status/types'
import { AppState } from 'mlp-client/src/types'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { OrderStatus } from 'mlp-client/src/features-configuration/types'

export interface Props {
  isMobileApp: boolean
  vehicleDetails?: VehicleDetails
  powerUnitLabel: string
  distanceLabel: string
}

class OrderDetails extends React.PureComponent<Props> {
  prepareInfoSubtitle = (): readonly string[] => {
    const { powerUnitLabel, distanceLabel, vehicleDetails } = this.props

    if (!vehicleDetails) {
      return []
    }

    const { fuel, power, transmission, consumption, unit } = vehicleDetails
    const fuelLabel = fuel ? `${_upperFirst(fuel)}` : ''
    const powerLabel = power ? `${power}${powerUnitLabel}` : ''
    const unitLabel = unit
      ? truncate(unit, {
          length: 3,
          omission: '',
        })
      : ''
    const consumptionLabel = consumption
      ? `${consumption}${unitLabel}/100${distanceLabel}`
      : ''

    return [
      fuelLabel,
      powerLabel,
      transmission ? `${_upperFirst(transmission)}` : '',
      consumptionLabel,
    ].filter(item => item !== '')
  }

  getWorkingSchedule = (orderStatus: OrderStatus): string => {
    if (!orderStatus) {
      return null
    }

    const {
      firstWorkingDay,
      lastWorkingDay,
      openingHours,
      closingHours,
    } = orderStatus

    if (firstWorkingDay && lastWorkingDay && openingHours && closingHours) {
      return `(${moment.weekdaysShort(
        firstWorkingDay,
      )} - ${moment.weekdaysShort(lastWorkingDay)}
        ${moment(openingHours, 'hh').format('ha')} - ${moment(
        closingHours,
        'hh',
      ).format('ha')})`
    }
  }

  render() {
    const { isMobileApp, vehicleDetails } = this.props

    const marginTop = isMobileApp ? 3 : 4
    // TODO replace with correct url when ONB-1484 ready
    const carImage = '/static/images/occasions/missing-car-small.jpg'

    return (
      <MyLeaseplanContext.Consumer>
        {context => (
          <Grid justifyContent="center">
            <GridItem span={{ mobile: 12, tablet: 11, lap: 9 }}>
              <Spacing mb={4} mt={marginTop}>
                {vehicleDetails && context.orderStatus?.showVehicleDetails && (
                  <Hide until="tablet">
                    <Spacing mb={4}>
                      <Ticket>
                        <TicketContent>
                          <Headline variant="300">
                            <Translation
                              replace={{
                                make: _upperFirst(vehicleDetails.make),
                                model: _upperFirst(vehicleDetails.model),
                                doors: vehicleDetails.doors,
                              }}
                              id="myLeaseplan.orderStatus.vehicleDetails.infoTitle"
                            />
                          </Headline>
                          <Text>{this.prepareInfoSubtitle().join(', ')}</Text>
                        </TicketContent>
                        <Spacing ph={3} pb={2}>
                          <TicketImage src={carImage} title="" stretch={true} />
                        </Spacing>
                        <TicketContent>
                          <DefinitionList>
                            <DefinitionListItem
                              term={
                                <Translation id="myLeaseplan.orderStatus.vehicleDetails.infoTerm" />
                              }
                              description=""
                              divider={true}
                            />
                            <DefinitionListItem
                              term={
                                <Translation id="myLeaseplan.orderStatus.vehicleDetails.infoMileage" />
                              }
                              description={vehicleDetails.actualYearlyMileage}
                            />
                          </DefinitionList>
                        </TicketContent>
                      </Ticket>
                    </Spacing>
                  </Hide>
                )}
                {context.orderStatus?.contacts && (
                  <Spacing>
                    <Hide from="tablet">
                      <Spacing mt={2} mb={3}>
                        <hr />
                      </Spacing>
                    </Hide>
                    <Ticket>
                      <Spacing pt={2}>
                        <TextCenter>
                          <CellphoneHandIcon height={64} width={64} />
                        </TextCenter>
                      </Spacing>
                      <TicketContent>
                        {/* TODO it is impossible to align text by center using only velocity */}
                        <TextCenter>
                          <Headline variant="100" withMarginBottom>
                            <Translation id="myLeaseplan.orderStatus.vehicleDetails.anyQuestion" />
                          </Headline>
                        </TextCenter>
                        <Text variant="200" loose>
                          <Translation
                            replace={{
                              phone: () => (
                                <Translation
                                  id={context.orderStatus?.contacts?.phone}
                                />
                              ),
                              schedule: this.getWorkingSchedule(
                                context.orderStatus,
                              ),
                            }}
                            id="myLeaseplan.orderStatus.vehicleDetails.contactWithSchedule"
                          />{' '}
                          {context.orderStatus?.contacts?.email && (
                            <>
                              <Translation
                                replace={{ email: '' }}
                                id="myLeaseplan.orderStatus.vehicleDetails.emailInfo"
                              />
                              <Link
                                href={`mailto:${context.orderStatus?.contacts?.email}`}
                              >
                                {context.orderStatus?.contacts?.email}
                              </Link>
                            </>
                          )}
                        </Text>
                      </TicketContent>
                    </Ticket>
                  </Spacing>
                )}
              </Spacing>
            </GridItem>
          </Grid>
        )}
      </MyLeaseplanContext.Consumer>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'isMobileApp' | 'powerUnitLabel' | 'distanceLabel'
>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  isMobileApp: getIsMobileApp(state),
  powerUnitLabel: getTranslation(state, 'myLeaseplan.shared.units.power'),
  distanceLabel: getTranslation(
    state,
    'myLeaseplan.shared.units.distance.kilometer',
  ),
})

export { OrderDetails }
export default connect(mapStateToProps)(OrderDetails)
