export enum StepNames {
  RETURN_VEHICLE = 'returnVehicle',
  RETURN_METHOD = 'returnMethod',
  DETAILS = 'details',
  SELECT_DATE = 'selectDate',
  SELECT_DATE_LOCATION = 'selectDateLocation',
  SELECT_TIME_LOCATION = 'selectTimeLocation',
  CONTACT_INFORMATION = 'contactInformation',
  ADDITIONAL_INFORMATION = 'additionalInformation',
  CONFIRM = 'confirm',
  THANK_YOU = 'thankYou',
}

export enum ReturnMethods {
  DROP_OFF = 'dropoff',
  PICK_UP = 'pickup',
}

export const enum ReplacementTypes {
  EMAIL = 'email',
  LINKS = 'links',
}
