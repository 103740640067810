import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import { ExtendContractSlider } from 'mlp-client/src/flows/extend-contract/components/extend-contract-slider/ExtendContractSlider'
import { contractEndDateSelector } from 'mlp-client/src/contracts/selectors'
import {
  ExtendLeaseContractStepView,
  StepViewProps,
  FlowData,
} from 'mlp-client/src/flows/extend-contract/types'
import { getExtensionDate } from 'mlp-client/src/flows/extend-contract/utils'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { FormSubmission } from 'mlp-client/src/form/types'
import { SubmitExtendContractRequest } from 'mlp-client/src/flows/extend-contract/actions'

const buildTranslationId = makeTranslationPath(
  'myLeaseplan.extendContract.steps.extendLeaseContract',
)

export interface Props
  extends StepViewProps<ExtendLeaseContractStepView>,
    FormSubmission<FlowData> {
  contractEndDate: string
}

interface State {
  duration: number
}

class ExtendLeaseContract extends React.PureComponent<Props, State> {
  state = {
    duration: 6,
  }

  componentDidMount() {
    this.props.setFlowData({
      extensionDate: getExtensionDate(
        this.state.duration,
        this.props.contractEndDate,
      ),
    })
  }

  onSliderChange = (duration: number) => {
    this.setState({ duration })
    this.props.setFlowData({
      extensionDate: getExtensionDate(duration, this.props.contractEndDate),
    })
  }

  render() {
    const {
      flowData,
      config: { minExtensionDuration, maxExtensionDuration },
      contractEndDate,
      onSubmit,
    } = this.props
    const { duration } = this.state

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep
                titleLabel={buildTranslationId('pageTitle')}
                textLabel={buildTranslationId('pageSubtitle')}
              >
                <ExtendContractSlider
                  extensionDate={flowData.extensionDate}
                  duration={duration}
                  minExtensionDuration={minExtensionDuration}
                  maxExtensionDuration={maxExtensionDuration}
                  handleChange={this.onSliderChange}
                  contractEndDate={contractEndDate}
                />
                <Spacing mt={2}>
                  <Text>
                    <Translation id={buildTranslationId('note')} />
                  </Text>
                </Spacing>
                <Spacing mt={2}>
                  <SubmitStatus submitting={submitting} failed={submitFailed}>
                    <SubmitButton
                      onClick={handleSubmit}
                      dataE2eId="buttonExtendLeaseContract"
                      label="myLeaseplan.genericNotifications.submitRequest"
                      disabled={submitting}
                      stretch
                    />
                  </SubmitStatus>
                </Spacing>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

export type ReduxProps = Pick<Props, 'contractEndDate'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  contractEndDate: contractEndDateSelector(state),
})

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete) => {
    const { goToNextStep } = props

    dispatch(
      new SubmitExtendContractRequest({
        flowData,
        onComplete: isError => {
          if (!isError) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ isError })
          }
        },
      }),
    )
  },
})

export { ExtendLeaseContract }
export default connect(mapStateToProps, mapDispatchToProps)(ExtendLeaseContract)
