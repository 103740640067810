import {
  PointSelectionStep,
  WhatHappenedStep,
  WhenAndWhereStep,
} from 'mlp-client/src/flows/damage-report/components/steps'
import {
  partyDamages,
  whoseFault,
} from 'mlp-client/src/features-configuration/data'

export const pointSelectionConfig = {
  stepView: PointSelectionStep,
  stepConfig: {},
}

export const whenAndWhereConfig = {
  stepView: WhenAndWhereStep,
  stepConfig: {},
}

export const whatHappenedConfig = {
  stepView: WhatHappenedStep,
  stepConfig: {
    whoseFaultOptions: whoseFault,
    whatHappenedOptions: partyDamages,
  },
}
