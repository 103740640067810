import { ButtonChevronRightIcon } from '@leaseplan/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'

interface Props {
  disabled?: boolean
  label?: string
  stretch?: boolean
  dataE2eId?: string
  onClick?(values: GenericObject): void
}

const SubmitButton: React.FC<Props> = ({
  disabled,
  label,
  stretch = true,
  dataE2eId,
  onClick,
}) => (
  <StyledButton
    disabled={disabled}
    stretch={stretch}
    icon={ButtonChevronRightIcon}
    data-e2e-id={dataE2eId}
    onClick={onClick}
  >
    <Translation id={label} />
  </StyledButton>
)

export default SubmitButton
