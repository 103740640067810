import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Action, Dispatch } from 'redux'

import * as actions from 'mlp-client/src/auth/actions'
import { getLoading } from 'mlp-client/src/auth/selectors'
import Loader from 'mlp-client/src/components/loader/Loader'
import { AppState } from 'mlp-client/src/types'

/*
  Parse url search parameters for `connection` and `path`, then trigger auth0 authentication using `connection`, and
  redirect to `path`.

  Url search Parameters:
  - connection (optional): connection property to pass to auth0
  - path (optional): path to redirect to after authentication, defaults to myLeaseplan.external.mainHomePage

  Example: https://www.leaseplan.com/nl-nl/auth/authorize?connection=Leaseplan-Corp&path=%2Fnl-nl%2Fmijn%2Fprofiel

*/

interface Props extends RouteComponentProps {
  dispatch: Dispatch<Action>
  loading: boolean
}

class Authorize extends React.PureComponent<Props> {
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search)
    const connection = searchParams.get('connection')
    const path = searchParams.get('path')

    this.props.dispatch(new actions.Authorize({ connection, route: path }))
  }

  render() {
    return (
      <Loader loading={this.props.loading}>
        <Helmet meta={[{ name: 'robots', content: 'noindex,nofollow' }]} />
      </Loader>
    )
  }
}

type ReduxProps = Pick<Props, 'loading'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  loading: getLoading(state),
})

export { Authorize }
export default connect(mapStateToProps)(Authorize)
