import {
  Action as ContractActions,
  ActionTypes as ContractActionTypes,
} from 'mlp-client/src/contracts/actions'
import {
  Action,
  ActionTypes,
  GetDamageNatureTypesFailed,
  GetDamageNatureTypesSuccess,
} from 'mlp-client/src/flows/damage-report/actions'
import {
  DamageNatureTypes,
  RequestStatus,
} from 'mlp-client/src/flows/damage-report/types'

export interface State {
  status: RequestStatus
  damageNatureTypes?: DamageNatureTypes
  error: string
}

export const defaultState: State = {
  status: RequestStatus.Idle,
  damageNatureTypes: [],
  error: '',
}

export const loadDamageNatureTypes = (): State => ({
  status: RequestStatus.Pending,
  damageNatureTypes: [],
  error: '',
})

export const loadDamageNatureTypesSuccess = (
  action: GetDamageNatureTypesSuccess,
) => ({
  damageNatureTypes: action.payload.data,
  status: RequestStatus.Success,
  error: '',
})

export const loadDamageNatureTypesFailed = (
  action: GetDamageNatureTypesFailed,
): State => ({
  status: RequestStatus.Failed,
  error: action.payload.error,
})

export const damageReportReducer = (
  state: State = defaultState,
  action: Action | ContractActions,
): State => {
  switch (action.type) {
    case ActionTypes.GET_DAMAGE_NATURE_TYPES_REQUEST:
      return loadDamageNatureTypes()
    case ActionTypes.GET_DAMAGE_NATURE_TYPES_SUCCESS:
      return loadDamageNatureTypesSuccess(action)
    case ActionTypes.GET_DAMAGE_NATURE_TYPES_FAILED:
      return loadDamageNatureTypesFailed(action)
    case ContractActionTypes.SET_CURRENT_CONTRACT:
      return defaultState
    default:
      return state
  }
}

export default damageReportReducer
