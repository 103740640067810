import { BreakpointName, withBreakpoint } from '@leaseplan/ui'
import React from 'react'

import { CarMissingIcon } from 'mlp-client/src/components/icons'
import { isDesktopBreakpoint } from 'mlp-client/src/utils'

import {
  IconTextStyled,
  SpacingModelStyled,
} from './VehicleOverviewTable.styled'
import { TableData } from './types'

interface Props {
  item: TableData
  breakpoint: BreakpointName
}

export class MakeModelCell extends React.PureComponent<Props> {
  render() {
    const { item, breakpoint } = this.props
    const isDesktop = isDesktopBreakpoint(breakpoint)

    return (
      <>
        {isDesktop ? (
          <IconTextStyled
            icon={CarMissingIcon}
            iconProps={{ color: 'steel', width: '35px', height: '35px' }}
          >
            <SpacingModelStyled ml={0.5}>{item.makeModel}</SpacingModelStyled>
          </IconTextStyled>
        ) : (
          item.makeModel
        )}
      </>
    )
  }
}

export default withBreakpoint(MakeModelCell)
