export const isWelcomeVisible = (
  isWelcomeScreenEnabled: boolean,
  isContractsLoaded: boolean,
  isContractsLoading: boolean,
  hasContracts: boolean,
): boolean =>
  isWelcomeScreenEnabled &&
  !isContractsLoading &&
  isContractsLoaded &&
  hasContracts
