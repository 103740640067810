import React from 'react'

import FAQ from 'mlp-client/src/FAQ/components/FAQ'
import { FAQKeys } from 'mlp-client/src/FAQ/enums'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  ConfirmStep,
  ContactInformationStep,
  ReturnFlow,
  ReturnVehicleStep,
  SelectDateLocationStep,
  ThankYouStep,
} from 'mlp-client/src/flows/return/components'
import { ReplacementTypes, StepNames } from 'mlp-client/src/flows/return/enums'
import {
  ReturnFlowConfiguration,
  Steps,
} from 'mlp-client/src/flows/return/types'
import { AddressTypes } from 'mlp-client/src/user/types'

const fairwearName = 'Fairwear'
const checklistName = 'Returnchecklist'

const allSteps: Steps = {
  [StepNames.RETURN_VEHICLE]: {
    stepView: ReturnVehicleStep,
    stepConfig: {
      fairwear: fairwearName,
      checklist: checklistName,
    },
  },
  [StepNames.SELECT_DATE_LOCATION]: {
    stepView: SelectDateLocationStep,
    stepConfig: {
      daysToPickup: 4,
      hideCountry: true,
      addressToHide: [AddressTypes.HOME],
      showOtherLocation: true,
      side: <FAQ faqKey={FAQKeys.VEHICLE_RETURN_PAGE} />,
      isValidateByGeo: true,
    },
  },
  [StepNames.CONTACT_INFORMATION]: {
    stepView: ContactInformationStep,
    stepConfig: {},
  },
  [StepNames.CONFIRM]: {
    stepView: ConfirmStep,
    stepConfig: {
      dateEditStep: StepNames.SELECT_DATE_LOCATION,
      contactEditStep: StepNames.CONTACT_INFORMATION,
      extraInfoStep: StepNames.CONTACT_INFORMATION,
    },
  },
  [StepNames.THANK_YOU]: {
    stepView: ThankYouStep,
    stepConfig: {
      listItemLabels: [
        { key: 'requestForApproval', showForLateReturn: true },
        { key: 'requestToPickup' },
        { key: 'guidelinesReview', replacement: ReplacementTypes.LINKS },
        { key: 'noVehicleBack' },
      ],
      downloadedDocs: { fairwear: fairwearName, checklist: checklistName },
    },
  },
}

export const returnVehicleFeature: MyLeaseplanFeature<ReturnFlowConfiguration> = {
  enabled: true,
  view: ReturnFlow,
  configuration: {
    getSteps: () => [
      StepNames.RETURN_VEHICLE,
      StepNames.SELECT_DATE_LOCATION,
      StepNames.CONTACT_INFORMATION,
      StepNames.CONFIRM,
      StepNames.THANK_YOU,
    ],
    allSteps,
  },
}
