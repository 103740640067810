export const enum TimelineEventTypes {
  FUEL_CARD = 'fuelCardCase',
  FUEL_CARD_PIN = 'fuelCardPinCase',
  FUEL_CARD_LOST_STOLEN = 'fuelCardLostStolenCase',
  EOL_PICKUP = 'EOLPickupCase',
  EOL_VEHICLE_BUY = 'EOLVehicleBuyCase',
  BOOKING = 'booking',
  UPDATE_MILEAGE = 'updateMileageCase',
  VEHICLE_DOCUMENTATION = 'vehicleDocumentationCase',
  TYRE_CHANGE = 'tyreChangeCase',
  DAMAGE_REPORT = 'damageReport',
  GLASS_DAMAGE = 'glassDamage',
  CASH_CLAIM = 'cashClaimCase',
  TYRE_BOOKING = 'tyreBooking',
  EOL_CONTRACT_EXTENSION_CASE = 'EOLContractExtensionCase',
}

export enum TimelineItemStatus {
  NEW = 'new',
  CREATED = 'created',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
  ARCHIVED = 'archived',
  CONFIRMED = 'confirmed',
  ACCEPTED = 'accepted',
  SEND = 'send',
  PROCESSING = 'processing',
  UNCONFIRMED = 'unconfirmed',
  AWAITING_CONFIRMATION = 'awating confirmation',
  COMPLETED = 'completed',
  OPEN = 'open',
  PAID = 'paid',
  PENDING = 'pending',
  CLOSED = 'closed',
}

export enum ReadableCashClaimStatus {
  ALLOW_CANCELLATION = 'allowCancellation',
  IN_PROGRESS = 'inProgress',
  ACCEPTED = 'accepted',
  TO_BE_PAID = 'toBePaid',
  CANCELLED = 'cancelled',
}

export enum TimelineBanners {
  CONTACT_US = 'CONTACT_US',
  CONTACT_US_WITH_MODAL = 'CONTACT_US_WITH_MODAL',
}

export const enum ServiceTypes {
  TYRE = 'Tyres',
}
