import Classnames from 'classnames'
import React from 'react'
import { Color, Text } from '@leaseplan/ui'
import styled from 'styled-components'

import { formatTelephoneHref } from 'mlp-client/src/utils'

enum Types {
  Paid = 'Paid',
  Free = 'Free',
}

type Type = keyof typeof Types

// correct green color is not available in @leaseplan/ui
const NumberText = styled(Text)<{ type: Type }>`
  color: ${props => (props.type === Types.Paid ? Color.purple : '#7ab51d')};
`

NumberText.displayName = 'NumberText'

interface Props {
  number?: string
  type?: Type
  macifPhone?: string
}

type DefaultProps = Required<Pick<Props, 'type'>>
type InternalProps = Props & DefaultProps

export class FrenchPhoneNumber extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    type: Types.Paid,
  }

  render() {
    const { number, type, macifPhone } = this.props
    const numberForLink = formatTelephoneHref(number || macifPhone)

    return (
      <a
        className={Classnames(
          'french-phone-number',
          `french-phone-number--large`,
        )}
        href={numberForLink}
        data-e2e-id="french-phone-number"
      >
        <span className="french-phone-number__number">
          <span className="french-phone-number__number-text">
            <NumberText className="text" size="l" fontWeight="bold" type={type}>
              {number || macifPhone}
            </NumberText>
          </span>
        </span>

        <span
          className={Classnames(
            'french-phone-number__fee-label',
            `french-phone-number__fee-label--${type.toLowerCase()}`,
          )}
        >
          <span className="french-phone-number__fee-label-text" />
        </span>
      </a>
    )
  }
}
