import React from 'react'
import reactStringReplace from 'react-string-replace'

/**
 * Types used in various classes in combination with replacement
 * logic (ie. Translations component)
 */
export type StringReplacement = boolean | number | string
type ReactReplacement = (match: string, index: number) => React.ReactNode

export type Replacement = StringReplacement | ReactReplacement

export interface ReplacementItem<T extends Replacement> {
  key: string
  value: T
}

export type StringReplacementItem = ReplacementItem<StringReplacement>

/**
 * Flat key-value pair interface of which value is of type `StringReplacement`
 * Used as parameter in the `replaceContentString` util function
 */
export interface StringReplacements {
  [key: string]: StringReplacement
}

/**
 * Flat key-value pair interface of which value is of type `Replacement`
 * Used as parameter in the `replaceContent` util function
 */
export interface Replacements {
  [key: string]: Replacement
}

/**
 * Small util to type check whether `Replacement` input is of type string
 *
 * @returns boolean
 */
const isStringReplacement = (r: Replacement): r is StringReplacement =>
  !isReactReplacement(r)

/**
 * Small util to type check whether `Replacement` input is of type function
 *
 * @returns boolean
 */
const isReactReplacement = (r: Replacement): r is ReactReplacement =>
  typeof r === 'function'

/**
 * Small util to type check whether a replacement array input consists only of string replacements
 *
 * @returns boolean
 */
export const isStringReplacementsArray = (
  r: ReadonlyArray<ReplacementItem<Replacement>>,
): r is ReadonlyArray<ReplacementItem<StringReplacement>> =>
  r.every(item => isStringReplacement(item.value))

/**
 * Local function which returns string value
 * Matches everything within `a` with `b` and replaces it with `c`
 *
 * @param a string value containing value within accolades ie. { variable: 'foo' }
 * @param b string value representing key matching the value within accolades in `a`
 * @param c string value representing replacement string value
 * @returns string result of regular expression
 */
const replaceString = ({ a, b, c }: { a: string; b: string; c: string }) =>
  a.replace(new RegExp(`{${b}}`, 'g'), c)

/**
 * Util function which is used in various classes (ie. Paragraph component ).
 * It accepts either string or null values, which are processed
 * by `replaceContentStringByEntries`and returns string
 *
 * @param source of type string | null
 * @param replacements of type `StringReplacements`
 * @returns string value
 */
export const replaceContentString = (
  source: string | null,
  replacements: StringReplacements = {},
): string => {
  if (!source) {
    return ''
  }

  return replaceContentStringByEntries(source, Object.entries(replacements))
}

/**
 * Util function which loops through given array and reduces with local
 * `replaceString` function and returns string
 *
 * @param source of type string
 * @param replacementEntries of type array with string, `StringReplacement` combination
 * @returns string value
 */
export const replaceContentStringByEntries = (
  source: string,
  replacementEntries: Array<[string, StringReplacement]>,
): string =>
  replacementEntries.reduce(
    (res, [key, repl]) => replaceString({ a: res, b: key, c: '' + repl }),
    source,
  )

/**
 * Util function which is used in two different locations (Translation, CarPromo components)
 * Basically because this function is creating dependencies all over the place, we need to make
 * sure these integrations are still working - therefore the union type output.
 * In the function several filters are necessary in order to make sure the reduce function at
 * the bottom is typesafe and will only process `ReactNodeArray` type
 * Otherwise a covariance issue happens, which currently is not being handled by our typescript config
 * @see https://medium.com/@michalskoczylas/covariance-contravariance-and-a-little-bit-of-typescript-2e61f41f6f68
 *
 * @param source
 * @param replacements
 * @returns string value
 */
export const replaceContent = (
  source: string | null,
  replacements: Replacements = {},
): string | React.ReactNodeArray => {
  if (!source) {
    return ''
  }

  const replacementEntries: Array<
    [string, StringReplacement | ReactReplacement]
  > = Object.entries(replacements)

  const stringReplacementEntries = replacementEntries.filter(([, r]) =>
    isStringReplacement(r),
  ) as Array<[string, StringReplacement]>

  const reactReplacementKeys = replacementEntries.filter(([, r]) =>
    isReactReplacement(r),
  ) as Array<[string, ReactReplacement]>

  const stringReplacedResult = replaceContentStringByEntries(
    source,
    stringReplacementEntries,
  )

  return reactReplacementKeys.reduce(
    (res: React.ReactNodeArray, [key, r]) =>
      reactStringReplace(res, `{${key}}`, r),
    [stringReplacedResult],
  )
}
