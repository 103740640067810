import React from 'react'
import { ButtonChevronLeftIcon, ProgressBar } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import InstallMobileApp from 'mlp-client/src/components/modals/instal-mobile-app/InstallMobileApp'
import CancelFlowModal from 'mlp-client/src/components/modals/cancel-flow-modal/CancelFlowModal'
import { GrayDark60Text } from 'mlp-client/src/components/styled/TextStyle'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'

import { FlowProps } from '../types'
import { withFlowContext } from '../flow/withFlowContext'

export interface Props extends FlowProps {
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

const StyledBarWrapper = styled.div`
  > div {
    background-image: ${isLeaseplan() ? '' : 'none'};
    background-color: ${isLeaseplan() ? '' : ColorEnumSelector('midOrange')};
  }
`

class FlowNav extends React.Component<Props> {
  get flowProgress() {
    const { activeStep, stepsCount } = this.props
    const progress = (activeStep.index + 1) / stepsCount

    return progress * 100
  }

  render() {
    const {
      activeStep,
      stepsCount,
      goToPreviousStep,
      isModalOpen,
      closeModal,
      openModal,
    } = this.props

    return (
      <div className="flow-nav">
        <InstallMobileApp />
        <nav className="flow-nav__nav">
          <button
            type="button"
            onClick={goToPreviousStep}
            data-tag-id="myleaseplan-flow-previous-button"
            className="flow-nav__back-button"
          >
            <ButtonChevronLeftIcon />
          </button>
          <div
            className="flow-nav__step-info"
            data-tag-id="myLeasePlan-step-counter"
            data-active-step-index={activeStep.index}
            data-active-step-route={activeStep.route}
            data-e2e-id={activeStep.index}
          >
            <GrayDark60Text bold variant="100">
              {activeStep.index + 1}/{stepsCount}
            </GrayDark60Text>
            {activeStep.title && (
              <Text variant="200" bold>
                <Translation id={activeStep.title} />
              </Text>
            )}
          </div>

          <CancelFlowModal
            isOpen={isModalOpen}
            closeCancelFlowModal={closeModal}
            openCancelFlowModal={openModal}
          />
        </nav>
        <StyledBarWrapper>
          <ProgressBar percentage={this.flowProgress} />
        </StyledBarWrapper>
      </div>
    )
  }
}

type FlowContextProps = Omit<Props, keyof FlowProps>

export { FlowNav }
export default connect(undefined)(withFlowContext<FlowContextProps>(FlowNav))
