import { SubmissionErrors } from 'final-form'

import { FormData } from 'mlp-client/src/components/information-form/InformationForm'

export const enum ActionTypes {
  SUBMIT_REQUEST_FOR_INFORMATION = '@@requestForInformation/lpis/SUBMIT_REQUEST_FOR_INFORMATION',
}

export class SubmitRequestForInformation {
  readonly type = ActionTypes.SUBMIT_REQUEST_FOR_INFORMATION

  constructor(
    public payload: {
      formData: FormData
      onComplete: (errors?: SubmissionErrors) => void
    },
  ) {}
}

export type Action = SubmitRequestForInformation
