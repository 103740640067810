import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class ReturnIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps
  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M4.5 42.8A1.5 1.5 0 0 1 3 41.3v-4.6a1.5 1.5 0 0 1 3 0v3h11.8a1.5 1.5 0 1 1 0 3H4.5zm26.3 10.5a2.4 2.4 0 1 1-4.9-.5 2.4 2.4 0 0 1 4.9.5zm-6.3 2.9a4.9 4.9 0 0 0 3.3 1.8h1c1-.1 2-.5 2.7-1.1a4.9 4.9 0 0 0 1.8-3.3 5 5 0 0 0-5-5.4 4.9 4.9 0 0 0-3 1 4.9 4.9 0 0 0-1.8 3.4 4.9 4.9 0 0 0 1 3.6zm15-12.3c1 1.2 2.9 1.8 5.6 1.9-.9-1.6-3.5-2.6-4.3-2.7a354.5 354.5 0 0 1-2-.5c0 .4.3.9.6 1.3zm7.8 14a1.5 1.5 0 1 1 0 3H29.1a8 8 0 0 1-.7.1H4.5a1.5 1.5 0 0 1 0-3h17.6a7.9 7.9 0 0 1-1.5-3.5H4.5a1.5 1.5 0 0 1 0-3h16.1a7.9 7.9 0 0 1 2.8-4.6 7.9 7.9 0 0 1 5.8-1.7 8 8 0 0 1 7 6.3h6.2a3 3 0 0 0 3-2.7c-4 0-6.7-1-8.3-3a6.6 6.6 0 0 1-1.4-3.9l-4.3-1-1.2-.1-3.7-.9a1.5 1.5 0 0 1-.5-.2L19 35c0-.1-4.6-3.5-11-3.5H4.5a1.5 1.5 0 0 1 0-3H8c7.5 0 12.6 4 12.8 4l6.6 4.7 4.6 1 5.5 1.2 4 .8c1.4.3 7 2.4 7 6.7v1.6a6 6 0 0 1-6.1 6H42 36a7.8 7.8 0 0 1-1.5 3.6h12.7zM53 9.2a10.2 10.2 0 0 1 0 20.5H27.7a1.5 1.5 0 1 1 0-3h25.2a7.2 7.2 0 0 0 0-14.5H36.2l5 5a1.5 1.5 0 1 1-2 2.1l-7.5-7.4a1.5 1.5 0 0 1 0-2l7.4-7.5a1.5 1.5 0 1 1 2.1 2.1l-4.6 4.7h16.3z"
        />
      </svg>
    )
  }
}

export default ReturnIcon
