import { ModalContent, ModalTitle, ModalTrigger, Spacing } from '@leaseplan/ui'
import React from 'react'

import { TileProps } from 'mlp-client/src/dashboard/type'
import Translation from 'mlp-client/src/localization/Translation'
import { FrenchPhoneNumber } from 'mlp-client/src/components/french-phone-number/FrenchPhoneNumber'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'

const macifNumber = 'myLeaseplan.macif.phone'

export const MacifModalTrigger: React.FC<TileProps> = props => (
  <ModalTrigger
    trigger={props.children}
    modalContent={
      <ModalContent>
        <ModalTitle>
          <Translation id="myLeaseplan.macif.tile.title" />
        </ModalTitle>
        <Spacing mb={2}>
          <Translation id="myLeaseplan.macif.modal.text" />
        </Spacing>
        <LocalizationContext.Consumer>
          {({ translate }) => (
            <div>
              <FrenchPhoneNumber
                type="Free"
                macifPhone={translate(macifNumber)}
              />
            </div>
          )}
        </LocalizationContext.Consumer>
      </ModalContent>
    }
  />
)
