import { FlowData } from './types'

export const enum ActionTypes {
  RETURN_REQUEST = '@@myLeaseplan/return/RETURN_REQUEST',
  DROPOFF_REQUEST = '@@myLeaseplan/return/DROPOFF_REQUEST',
}

export class SubmitReturnRequest {
  readonly type = ActionTypes.RETURN_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(isError: boolean): void
    },
  ) {}
}

export class SubmitDropoffRequest {
  readonly type = ActionTypes.DROPOFF_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(isError: boolean): void
    },
  ) {}
}
