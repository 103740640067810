import React from 'react'
import styled from 'styled-components'
import { Button, Text, Spacing, Heading } from '@leaseplan/ui'

import Translation from 'mlp-client/src/localization/Translation'
import EmptyMapMarkerIcon from 'mlp-client/src/components/icons/empty-map-marker-icon/EmptyMapMarkerIcon'

export interface Props {
  onClick(): void
  noSuppliersByMalfunctions: boolean
}

const Wrapper = styled(Spacing)`
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  flex-wrap: wrap;
`

export const Header = styled(Heading)`
  text-align: center;
`

export const Description = styled(Text)`
  text-align: center;
`

const CenteredButton = styled(Button)`
  display: block;
  margin: 0 auto;
`

const IconWrapper = styled(Spacing)`
  text-align: center;
`

const defaultKeyBase = 'myLeaseplan.serviceRequest.steps.selectSupplier'

const getFullKey = (key: string) => `${defaultKeyBase}.${key}`

const SuppliersNotFound: React.FC<Props> = (props: Props) => {
  const labels = props.noSuppliersByMalfunctions
    ? {
        title: getFullKey('noSupplierByMalfunctions.title'),
        subtitle: getFullKey('noSupplierByMalfunctions.subtitle'),
        button: 'myLeaseplan.welcomePopup.button',
      }
    : {
        title: getFullKey('noSupplier.title'),
        subtitle: getFullKey('noSupplier.subtitle'),
        button: getFullKey('button'),
      }

  return (
    <Wrapper mv={3} mh={4} pv={6} data-e2e-id="supplierNotFound">
      <IconWrapper>
        <EmptyMapMarkerIcon width={60} height={82} color="#aaadb3" />
      </IconWrapper>
      <Header component="h3" size="s">
        <Translation id={labels.title} />
      </Header>
      {labels.subtitle && (
        <Spacing mv={2}>
          <Description component="p" size="m">
            <Translation id={labels.subtitle} />
          </Description>
        </Spacing>
      )}
      <CenteredButton
        component="button"
        size="m"
        onClick={props.onClick}
        data-e2e-id="selectDifferentLocation"
      >
        <Translation id={labels.button} />
      </CenteredButton>
    </Wrapper>
  )
}

export default SuppliersNotFound
