import * as React from 'react'
import styled from 'styled-components'
import { ColorName, Grid, GridItem, mq, Paper, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'

import BannerIcon from './BannerIcon'

export interface Props {
  title: string
  titleColor?: ColorName
  subtitle?: string
  AssistanceButton?: React.ReactNode
  SupportButton?: React.ReactNode
  mobIcon?: any
  desktopIcon?: any
}

export const GridStyled = styled(Grid)`
  ${mq.until('tablet')`flex-flow: column;`};
`

// fix for IE
// because of nesting button into span, click does not always work
// `display: block` make span height enough to catch click event
const GridItemStyled = styled(GridItem)`
  span {
    display: block;
    cursor: pointer;
  }
`

const StyledHeading = styled(Headline)`
  ${mq.until('tablet')`text-align: center;`}
`

class DashboardBanner extends React.PureComponent<Props> {
  render() {
    const {
      title,
      subtitle,
      titleColor,
      mobIcon,
      desktopIcon,
      AssistanceButton,
      SupportButton,
    } = this.props

    return (
      <Paper>
        <Spacing p={2}>
          <GridStyled alignItems="center" verticalGutter={false}>
            <GridItem>
              <BannerIcon mobIcon={mobIcon} desktopIcon={desktopIcon} />
            </GridItem>

            <GridItem span="grow">
              <GridStyled justifyContent="space-between" alignItems="center">
                <GridItem span={{ desktop: 'grow', tablet: 12 }}>
                  <StyledHeading
                    variant="200"
                    component="h6"
                    style={{ color: titleColor ? titleColor : 'petrolBlue' }}
                  >
                    {title}
                  </StyledHeading>
                  {subtitle && <Text>{subtitle}</Text>}
                </GridItem>

                <GridItem data-e2e-id="roadAssistance">
                  <Grid direction="row">
                    {SupportButton && (
                      <GridItemStyled>{SupportButton}</GridItemStyled>
                    )}

                    {AssistanceButton && (
                      <GridItemStyled>{AssistanceButton}</GridItemStyled>
                    )}
                  </Grid>
                </GridItem>
              </GridStyled>
            </GridItem>
          </GridStyled>
        </Spacing>
      </Paper>
    )
  }
}

export default DashboardBanner
