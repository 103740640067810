import { SizeConstraint, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Form as ReactFinalForm, FormRenderProps } from 'react-final-form'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { SubmitFuelCard } from 'mlp-client/src/flows/fuel-card/actions'
import { FlowData } from 'mlp-client/src/flows/fuel-card/types'
import { FormSubmission } from 'mlp-client/src/form/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'

export interface Props extends FlowProps<FlowData>, FormSubmission<FlowData> {}

class LostAndStolenConfirmStep extends React.PureComponent<Props> {
  render() {
    const { flowData, onSubmit } = this.props

    return (
      <ReactFinalForm<FlowData> onSubmit={onSubmit} initialValues={flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep
                titleLabel="myLeaseplan.fuelCard.types.allStolen"
                mainSpan={12}
                marginTitle={3}
              >
                <Headline variant="200">
                  <Translation id="myLeaseplan.fuelCard.types.sorry" />
                </Headline>
                <Spacing mb={2}>
                  <Text component="p" data-e2e-id="fuelCardStolenInfo">
                    <Translation id="myLeaseplan.fuelCard.steps.selectServiceType.allStolenInfo" />
                  </Text>
                </Spacing>
                <SubmitStatus
                  submitting={submitting}
                  failed={submitFailed}
                  submittingTranslation="myLeaseplan.timeline.fuelCard.items.status.processing"
                  failedTranslation="myLeaseplan.error.callServiceDesk"
                >
                  <SizeConstraint minWidth={180}>
                    <StyledButton
                      onClick={handleSubmit}
                      stretch={true}
                      disabled={submitting}
                      data-e2e-id="confirmLostOrStolen"
                    >
                      <Translation id="myLeaseplan.fuelCard.steps.checkDetails.nextButton" />
                    </StyledButton>
                  </SizeConstraint>
                </SubmitStatus>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </ReactFinalForm>
    )
  }
}

type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof DispatchProps>

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, formApi, formOnComplete) => {
    const { closeFlow } = props

    dispatch(
      new SubmitFuelCard({
        flowData,
        onComplete: error => {
          if (!error) {
            closeFlow()
            formOnComplete()
          } else {
            formOnComplete({ error })
          }
        },
      }),
    )
  },
})

export { LostAndStolenConfirmStep }
export default connect(null, mapDispatchToProps)(LostAndStolenConfirmStep)
