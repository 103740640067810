/*
MLP-specific copy of: src/app/features/shared/components/icons/loader-icon/LoaderIcon.tsx

Differences with original:
- `modifier` prop has been removed
*/

import { VelocityConsumer } from '@velocity/ui'
import React from 'react'

interface Props {
  className?: string
  height?: number
  width?: number
  title?: string
}

export const LoaderIcon: React.FC<Props> = props => {
  const { width = 100, height = 100, title = 'Loader', className } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>{title}</title>
      <g>
        <VelocityConsumer>
          {({ theme }) => (
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="10"
              y1="60"
              x2="110"
              y2="60"
            >
              <stop offset="0.3" style={{ stopColor: '#ccdbdf' }} />
              <stop
                offset="0.9"
                style={{ stopColor: theme.styling.icons.primary.main }}
              />
            </linearGradient>
          )}
        </VelocityConsumer>
        <path
          style={{ fill: 'url(#SVGID_1_)' }}
          d="M60,18c23.2,0,42,18.8,42,42s-18.8,42-42,42S18,83.2,18,60S36.8,18,60,18 M60,10c-27.6,0-50,22.4-50,50 s22.4,50,50,50s50-22.4,50-50S87.6,10,60,10L60,10z"
        />
      </g>
    </svg>
  )
}

LoaderIcon.displayName = 'LoaderIcon'
