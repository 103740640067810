import { DisabledDay, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { Currencies } from 'mlp-client/src/localization/enums'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { getDisabledDays } from 'mlp-client/src/flows/cash-claim/components/steps/purchase-details-step/selectors'
import { FlowData } from 'mlp-client/src/flows/cash-claim/types'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { DatePickerField } from 'mlp-client/src/form/components/fields/DatePickerFields'
import FileUpload from 'mlp-client/src/form/components/fields/file-upload-field/FileUploadField'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import {
  filesRequiredAndUploaded,
  invalidDate,
  required,
  minValue,
} from 'mlp-client/src/form/validations'
import { Options } from 'mlp-client/src/form/types'
import { onlyNumbersAndFractional } from 'mlp-client/src/form/utils'
import { AppState } from 'mlp-client/src/types'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'

export interface StepConfig {
  currencies: Options
  isReasonEnabled: boolean
  defaultCurrency: Currencies
}

export interface Props extends FlowProps<FlowData> {
  disabledDays: DisabledDay[]
  config: StepConfig
  isMobileApp: boolean
}

export type FormValues = Pick<
  FlowData,
  'receipt' | 'amount' | 'currency' | 'date' | 'reason'
>

const GiantSpacing = styled.div`
  margin-top: 220px;
`

class PurchaseDetailsStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormValues) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData({ ...formData }, goToNextStep)
  }

  render() {
    const {
      disabledDays,
      isMobileApp,
      config: { currencies, isReasonEnabled, defaultCurrency },
      flowData: { amount, reason, currency, date, receipt = [] },
    } = this.props
    const validations = [invalidDate, required]

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.newSetup.flows.cashClaim.steps.purchaseDetails.header">
          <Form
            onSubmit={this.submitHandler}
            keepDirtyOnReinitialize={true}
            initialValues={{
              receipt,
              currency: currency || defaultCurrency,
              date: date || new Date(),
              amount,
              reason,
            }}
            render={({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form
                  onSubmit={handleSubmit}
                  data-test-id="photo-form"
                  data-e2e-id="PurchaseDetailsForm"
                >
                  <Spacing mb={0.5}>
                    <Text bold component="p">
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.purchaseDetails.receipt.label" />
                    </Text>
                  </Spacing>
                  <Spacing mb={4}>
                    <FileUpload
                      name="receipt"
                      validations={[filesRequiredAndUploaded]}
                      accept="image/*"
                    />
                  </Spacing>
                  <Spacing mb={0.5}>
                    <Text bold component="p">
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.purchaseDetails.amount.label" />
                    </Text>
                  </Spacing>
                  <Spacing mb={2}>
                    <Grid>
                      <GridItem span={{ mobile: 6, tablet: 3 }}>
                        <TextField
                          name="amount"
                          validations={[required, minValue(1)]}
                          parse={value => onlyNumbersAndFractional(value)}
                        />
                      </GridItem>
                      <GridItem span={{ mobile: 6, tablet: 3 }}>
                        <SelectField
                          validations={[required]}
                          name="currency"
                          options={currencies}
                        />
                      </GridItem>
                    </Grid>
                  </Spacing>
                  <Spacing mb={3}>
                    <Text bold component="p">
                      <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.purchaseDetails.date.label" />
                    </Text>
                    <DatePickerField
                      name="date"
                      validations={validations}
                      disabledDays={disabledDays}
                    />
                  </Spacing>
                  {isReasonEnabled && (
                    <Spacing mb={2}>
                      <TextAreaField
                        name="reason"
                        validations={[required]}
                        label={{
                          id:
                            'myLeaseplan.newSetup.flows.cashClaim.steps.purchaseDetails.reason.label',
                        }}
                      />
                    </Spacing>
                  )}
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                  {
                    // @todo this is hotfix for mobile app, remove after layout fix
                    isMobileApp && !isReasonEnabled && <GiantSpacing />
                  }
                </form>
              )
            }}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'disabledDays' | 'isMobileApp'>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  disabledDays: getDisabledDays,
  isMobileApp: getIsMobileApp,
})

export { PurchaseDetailsStep }
export default connect(mapStateToProps)(PurchaseDetailsStep)
