import React from 'react'

interface DefaultProps {
  className: string
  height: number
  modifier: string
  title: string
  width: number
}

type Props = Partial<DefaultProps>
type PropsWithDefaults = Readonly<DefaultProps>

export default class RoadsideAssistanceIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    className: '',
    height: 110,
    modifier: '',
    title: 'Roadside Assistance',
    width: 110,
  }

  render() {
    const { className, modifier, width, height } = this
      .props as PropsWithDefaults

    return (
      <svg
        width={width}
        height={height}
        className={`${className} roadsideassistance-icon roadsideassistance-icon--${modifier}`}
        viewBox="0 0 250 250"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Artboard 2</title>
        <path
          className="cls-1"
          d="M156.53,148.1h0V75.93h28.27a10.2,10.2,0,0,1,7.7,3.51L211.22,101a23.33,23.33,0,0,1,5.73,15.31v25.77a6,6,0,0,1-6,6H197.54"
        />
        <polyline
          className="cls-1"
          points="178.19 89.55 178.19 111.14 202.19 111.14"
        />
        <circle
          className="cls-1"
          transform="translate(14.5 313.58) rotate(-83.97)"
          cx="181.48"
          cy="148.73"
          r="16.07"
        />
        <line
          className="cls-1"
          x1="38.56"
          x2="211.93"
          y1="164.71"
          y2="164.71"
        />
        <line
          className="cls-1"
          x1="164.44"
          x2="88.74"
          y1="148.48"
          y2="148.48"
        />
        <circle
          className="cls-1"
          transform="translate(14.5 313.58) rotate(-83.97)"
          cx="181.48"
          cy="148.73"
          r="6.07"
        />
        <circle
          className="cls-1"
          transform="translate(-82.87 205.38) rotate(-83.97)"
          cx="72.68"
          cy="148.73"
          r="16.07"
        />
        <circle
          className="cls-1"
          transform="translate(-82.87 205.38) rotate(-83.97)"
          cx="72.68"
          cy="148.73"
          r="6.07"
        />
        <path
          className="cls-1"
          d="M56.65,148.73h-25V122.4H81.49a9.11,9.11,0,0,0,7-3.3l19-23h29.64v52.41"
        />
        <polyline
          className="cls-1"
          points="137.19 96.07 137.19 60.55 122.08 60.55 115.37 96.07"
        />
        <line className="cls-1" x1="137.19" x2="156.53" y1="131" y2="131" />
        <path
          className="cls-1"
          d="M116,86.59,22.81,59.78a5.08,5.08,0,0,1-3.56-6.14h0a5.08,5.08,0,0,1,6.05-3.7L119,70.58"
        />
        <path
          className="cls-1"
          d="M25.38,92.29v1.79c0,4.75,3.39,4.18,5.36,8.16,2.21,4.65-.42,9.23-5.36,9.23a6.06,6.06,0,0,1-6.09-5.33"
        />
        <rect
          className="cls-1"
          transform="translate(50.48 167.52) rotate(180)"
          x="20.73"
          y="77.52"
          width="9.01"
          height="12.48"
        />
        <line className="cls-1" x1="25.24" x2="25.24" y1="77.52" y2="60.55" />
        <circle className="cls-2" cx="127.47" cy="80.68" r="3.18" />
        <path className="cls-1" d="M201.54,164.71a86.24,86.24,0,0,1-153.08,0" />
        <path
          className="cls-1"
          d="M69.73,58.83A86.24,86.24,0,0,1,205.5,94.07"
        />
        <path
          className="cls-1"
          d="M38.83,122.4A85.83,85.83,0,0,1,58.55,70.07"
        />
      </svg>
    )
  }
}
