import { GridItemProps, Spacing, SpacingProps } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import Classnames from 'classnames'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import FlowStepPoster from 'mlp-client/src/components/flow-step-poster/FlowStepPoster'
import { GridLayout } from 'mlp-client/src/components/layout'
import { AppState } from 'mlp-client/src/types'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  padding-top: 64px;
`

export interface Props {
  icon?: React.ReactNode
  titleLabel?: string
  titleLabelReplace?: GenericObject
  posterTitleLabel?: string
  posterTextLabel?: string
  posterImgMedium?: string
  posterImgLarge?: string
  posterImgSmall?: string
  metaTitle?: string
  translatedMetaTitle: string
  posterPosition?: string
  summaryContentSectionName?: string
  side?: React.ReactNode
  textLabel?: string
  dispatch: Dispatch<Action>
  hideTitle?: boolean
  marginTitle?: SpacingProps['m']
  // Grid layout config
  // TODO: Refactor this component
  mainMaxWidth?: string
  sideMaxWidth?: string
  mainSpan?: GridItemProps['span']
  sideSpan?: GridItemProps['span']
  children?: React.ReactNode
}

type DefaultProps = Required<Pick<Props, 'marginTitle'>>

type InternalProps = Props & DefaultProps
class TwoColumnFlowStep extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    marginTitle: 3,
  }

  render() {
    const {
      children,
      icon,
      titleLabel,
      titleLabelReplace,
      translatedMetaTitle,
      posterTitleLabel,
      posterTextLabel,
      posterImgMedium,
      posterImgLarge,
      posterImgSmall,
      posterPosition,
      side,
      textLabel,
      hideTitle,
      mainSpan,
      sideSpan,
      mainMaxWidth,
      sideMaxWidth,
      marginTitle,
    } = this.props

    const poster =
      posterImgMedium && posterImgLarge ? (
        <FlowStepPoster
          title={<Translation id={posterTitleLabel} hideOnEmpty={true} />}
          text={<Translation id={posterTextLabel} hideOnEmpty={true} />}
          imgMedium={posterImgMedium}
          imgLarge={posterImgLarge}
          imgSmall={posterImgSmall}
          position={posterPosition}
        />
      ) : null

    const header = (
      <div>
        <Helmet title={translatedMetaTitle} />
        {icon && (
          <div className="o-layout--center u-margin-bottom-large">{icon}</div>
        )}

        {!hideTitle && titleLabel && (
          <div
            className={Classnames({
              'u-margin-bottom-small': textLabel,
            })}
          >
            <Headline component="h1" variant="400" withMarginBottom>
              <Translation id={titleLabel} replace={titleLabelReplace} />
            </Headline>
          </div>
        )}

        {textLabel && (
          <SecondaryDarkText variant="500" component="p">
            <Translation id={textLabel} />
          </SecondaryDarkText>
        )}
      </div>
    )

    return (
      <Wrapper>
        {poster}
        <Spacing pt={{ mobile: 3, desktop: 4 }}>
          <Spacing mb={marginTitle}>
            <GridLayout main={header} mainSpan={12} />
          </Spacing>

          <GridLayout
            main={children}
            side={side}
            mainSpan={mainSpan}
            sideSpan={sideSpan}
            mainMaxWidth={mainMaxWidth}
            sideMaxWidth={sideMaxWidth}
          />
        </Spacing>
      </Wrapper>
    )
  }
}

type ReduxProps = Pick<Props, 'translatedMetaTitle'>
type DispatchProps = Pick<Props, 'dispatch'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (
  state: AppState,
  { metaTitle }: OwnProps,
): ReduxProps => ({
  translatedMetaTitle: getTranslation(state, metaTitle || ''),
})

export { TwoColumnFlowStep }
export default connect(mapStateToProps)(TwoColumnFlowStep)
