import {
  checkDetailsStepConfig,
  confirmBookingStepConfig,
  getSteps,
  locationStepConfig,
  maintenanceTypeStepConfig,
  malfunctionsStepConfig,
  selectSupplierStepConfig,
  specialInstructionsStepConfig,
  thankYouStepConfig,
} from 'mlp-client/src/features-configuration/default/maintenance'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { MaintenanceFlow } from 'mlp-client/src/flows/maintenance/components'
import { StepNames } from 'mlp-client/src/flows/maintenance/enums'
import {
  MaintenanceFlowConfiguration,
  Steps,
} from 'mlp-client/src/flows/maintenance/types'

const allSteps: Steps = {
  [StepNames.SELECT_MAINTENANCE_TYPE]: maintenanceTypeStepConfig,
  [StepNames.SELECT_LOCATION]: {
    ...locationStepConfig,
    stepConfig: {
      ...locationStepConfig.stepConfig,
      hideCountry: true,
    },
  },
  [StepNames.SELECT_MALFUNCTIONS]: malfunctionsStepConfig,
  [StepNames.SPECIAL_INSTRUCTIONS]: specialInstructionsStepConfig,
  [StepNames.SELECT_SUPPLIER]: selectSupplierStepConfig,
  [StepNames.CONFIRM_BOOKING]: confirmBookingStepConfig,
  [StepNames.CHECK_CONTACT_DETAILS]: checkDetailsStepConfig,
  [StepNames.THANK_YOU]: thankYouStepConfig,
}

export const maintenanceConfig: MyLeaseplanFeature<MaintenanceFlowConfiguration> = {
  enabled: true,
  view: MaintenanceFlow,
  configuration: {
    getSteps: getSteps(),
    allSteps,
  },
}
