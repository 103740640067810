import { Color, IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'

class EcoDrivingIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 34"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" fill="none" fillRule="evenodd">
          <g transform="translate(-132.000000, -30.000000)" fill={Color[color]}>
            <g transform="translate(129.000000, 27.000000)">
              <path d="M12.6454375,29.4088125 C7.1335625,23.8969375 5.4485625,9.1181875 5.0829375,5.0513125 C9.0610625,5.2906875 23.3229375,6.5600625 29.4085625,12.6456875 C34.0316875,17.2688125 36.2979375,24.3244375 31.3310625,30.0063125 L28.3854375,27.0600625 L28.3854375,17.5963125 C28.3854375,17.0781875 27.9660625,16.6588125 27.4479375,16.6588125 C26.9298125,16.6588125 26.5104375,17.0781875 26.5104375,17.5963125 L26.5104375,25.1850625 L17.6923125,16.3669375 C17.3260625,16.0006875 16.7329375,16.0006875 16.3666875,16.3669375 C16.0004375,16.7331875 16.0004375,17.3269375 16.3666875,17.6925625 L25.1841875,26.5106875 L17.5960625,26.5106875 C17.0779375,26.5106875 16.6585625,26.9300625 16.6585625,27.4481875 C16.6585625,27.9656875 17.0779375,28.3856875 17.5960625,28.3856875 L27.0591875,28.3856875 L29.9973125,31.3231875 C25.7310625,34.8606875 18.3710625,35.1344375 12.6454375,29.4088125 M36.7048125,35.3794375 L32.6579375,31.3325625 C36.9641875,26.4294375 37.9798125,18.5656875 30.7341875,11.3200625 C23.1329375,3.7188125 4.8616875,3.1463125 4.0879375,3.1256875 C3.8104375,3.1138125 3.5704375,3.2225625 3.3879375,3.4119375 C3.2054375,3.6013125 3.1104375,3.8588125 3.1273125,4.1213125 C3.1773125,4.9238125 4.4323125,23.8475625 11.3198125,30.7344375 C14.8285625,34.2431875 18.8998125,35.7481875 22.6810625,35.7481875 C25.9710625,35.7481875 29.0341875,34.5994375 31.3216875,32.6475625 L35.3791875,36.7050625 C35.5623125,36.8881875 35.8023125,36.9800625 36.0423125,36.9800625 C36.2816875,36.9800625 36.5216875,36.8881875 36.7048125,36.7050625 C37.0710625,36.3394375 37.0710625,35.7456875 36.7048125,35.3794375" />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default EcoDrivingIcon
