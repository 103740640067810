import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { LoadContent } from 'mlp-client/src/content/actions'
import { getContentInfo } from 'mlp-client/src/selectors'
import { SitecoreContentSection } from 'mlp-client/src/content/types'
import { Locales } from 'mlp-client/src/localization/enums'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import { ContentPage } from 'mlp-client/src/flows/components/steps/content-page/ContentPage'
import { ContentEndpoint } from 'mlp-client/src/flows/tyres/enums'
import { isContentLangUnmatchesLocal } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'

export interface Props extends SitecoreContentSection {
  locale: Locales
  loadContent(endpoint: string, locale?: string): void
}

class RoadSideAssistancePage extends React.PureComponent<Props> {
  componentDidMount() {
    const { content, loadContent, locale } = this.props

    if (!content) {
      loadContent(ContentEndpoint.ROADSIDE_ASSISTANCE, locale)
    }
  }

  componentDidUpdate() {
    const { content, loadContent, language, locale } = this.props

    if (content && isContentLangUnmatchesLocal(language, locale)) {
      loadContent(ContentEndpoint.ROADSIDE_ASSISTANCE, locale)
    }
  }
  render() {
    const { content, intro, title } = this.props

    return <ContentPage content={content} intro={intro} title={title} />
  }
}

type ReduxProps = Pick<
  Props,
  'content' | 'language' | 'locale' | 'intro' | 'title'
>
type DispatchProps = Pick<Props, 'loadContent'>

const mapStateToProps = (state: AppState): ReduxProps => {
  const { content, intro, title, language } = getContentInfo(
    state,
    ContentEndpoint.ROADSIDE_ASSISTANCE,
  )

  return {
    content,
    intro,
    title,
    language,
    locale: getUserLocale(state),
  }
}

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadContent: (endpoint: string, locale?: string) => {
    dispatch(new LoadContent({ endpoint, locale }))
  },
})

export { RoadSideAssistancePage }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoadSideAssistancePage)
