import { ButtonChevronRightIcon, Grid } from '@leaseplan/ui'
import React from 'react'
import { Field } from 'react-final-form'
import { FormApi } from 'final-form'

import { WhiteText } from 'mlp-client/src/components/styled/TextStyle'

import {
  Address,
  CardLabel,
  DistanceWrapper,
  IconWrapper,
  SupplierName,
  SupplierWrapper,
} from './SupplierMapCard.styled'

export interface Props {
  supplierName: string
  supplierAddress: string
  distance: string
  value: string
  form: FormApi
}

export class SupplierMapCard extends React.PureComponent<Props> {
  render() {
    const { distance, supplierName, supplierAddress, form, value } = this.props

    return (
      <CardLabel
        className="swiper-slide"
        onClick={() => form.change('supplier', value)}
      >
        <Field name="supplier" type="submit">
          {({ input }) => (
            <input {...input} className="card-radio-list__item-input" />
          )}
        </Field>
        <DistanceWrapper>
          <WhiteText variant="100">{distance}</WhiteText>
        </DistanceWrapper>
        <Grid wrap="nowrap">
          <SupplierWrapper>
            <SupplierName variant="200" component="p" bold>
              {supplierName}
            </SupplierName>
            <Address variant="100" component="p">
              {supplierAddress}
            </Address>
          </SupplierWrapper>
          <IconWrapper>
            <ButtonChevronRightIcon />
          </IconWrapper>
        </Grid>
      </CardLabel>
    )
  }
}
