import { MobileAppConfig } from 'mlp-client/src/features-configuration/types'
import { SupplierMapPage } from 'mlp-client/src/flows/components/pages/supplier-map-page/SupplierMapPage'
import { SupplierMapConfiguration } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'
import { BookingRequestType } from 'mlp-client/src/types'

export const damageRepairConfig: MobileAppConfig<SupplierMapConfiguration> = {
  enabled: true,
  mobileApp: {
    showNativeHeader: true,
  },
  view: SupplierMapPage,
  configuration: {
    title: 'myLeaseplan.damageReport.damageRepair',
    breadcrumbLinkTitle: 'myLeaseplan.damageReport.damageRepair',
    breadcrumbBaseTitle: 'myLeaseplan.timeline.base.title',
    breadcrumbBaseLink: 'myLeaseplan.timeline.base',
    showOpeningHours: false,
    bookingType: BookingRequestType.DAMAGE,
  },
}
