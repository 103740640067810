import React from 'react'
import { Headline } from '@velocity/ui'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import { Section } from 'mlp-client/src/components/steps/thank-you-step'
import Translation from 'mlp-client/src/localization/Translation'
import { ThankyouPageLayout } from 'mlp-client/src/components/myleaseplan-flow/thankyou-page-layout/ThankyouPageLayout'

export interface Props extends FlowProps<FlowData> {
  config: {}
}

export class ThankYouStep extends React.PureComponent<Props> {
  render() {
    const { closeFlow } = this.props

    return (
      <ThankyouPageLayout onClick={closeFlow}>
        <Headline component="h1" variant="400" withMarginBottom>
          <Translation id="myLeaseplan.mileageUpdate.steps.thankYou.title" />
        </Headline>
        <Section headerText="myLeaseplan.mileageUpdate.steps.thankYou.subtitle" />
      </ThankyouPageLayout>
    )
  }
}

export default ThankYouStep
