import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getUserInfoData } from 'mlp-client/src/user/selectors'
import { UserInfo } from 'mlp-client/src/user/types'
import { ApiSagas } from 'mlp-client/src/api'
import * as contractsActions from 'mlp-client/src/contracts/actions'

import * as actions from './actions'
import { Quote, Quotes } from './types'
import { getFirstQuoteId } from './utils'

export function* loadQuotes(): SagaIterator {
  const options = {
    throwError: true,
  }

  try {
    const { gin: userId }: UserInfo = yield select(getUserInfoData)
    const quotes: Quotes = yield call(
      ApiSagas.get,
      `/myleaseplan/lpis/${userId}/quotes`,
      {},
      options,
    )
    const firstQuoteId = getFirstQuoteId(quotes)

    if (firstQuoteId) {
      yield put(new actions.SetCurrentQuote({ quoteId: firstQuoteId }))
    }

    yield put(new actions.LoadQuotesSuccess({ quotes }))
  } catch {
    yield put(new actions.LoadQuotesFailed())
  }
}

export function* handleSetCurrentQuote({
  payload,
}: actions.SetCurrentQuote): SagaIterator {
  yield put(new actions.DefineCurrentQuoteId(payload.quoteId))
  yield put(new actions.GetQuoteById({ quoteId: payload.quoteId }))
  yield put(new contractsActions.DefineCurrentContractId(null))
}

export function* getQuoteById(action: actions.GetQuoteById): SagaIterator {
  const options = {
    throwError: true,
  }
  const { quoteId } = action.payload

  try {
    const { gin: userId }: UserInfo = yield select(getUserInfoData)
    const quote: Quote = yield call(
      ApiSagas.get,
      `/myleaseplan/lpis/${userId}/quotes/${quoteId}`,
      {},
      options,
    )

    yield put(new actions.GetQuoteByIdSuccess({ quote }))
  } catch {
    yield put(new actions.GetQuoteByIdFailed())
  }
}

export default [
  takeLatest(actions.ActionTypes.LOAD_QUOTES, loadQuotes),
  takeLatest(actions.ActionTypes.SET_CURRENT_QUOTE, handleSetCurrentQuote),
  takeLatest(actions.ActionTypes.GET_QUOTE_BY_ID, getQuoteById),
]
