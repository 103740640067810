import React from 'react'
import { connect } from 'react-redux'

import { getCountry, getLocale } from 'mlp-client/src/localization/selectors'
import { Locales } from 'mlp-client/src/localization/enums'
import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { filterRouteParams } from 'mlp-client/src/components/myleaseplan-flow/utils'
import NotFound from 'mlp-client/src/components/not-found/NotFound'
import {
  canDoMaintenanceBooking,
  getCurrentContractId,
} from 'mlp-client/src/contracts/selectors'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { MaintenanceFlowConfiguration } from 'mlp-client/src/flows/maintenance/types'
import { Address } from 'mlp-client/src/user/types'
import { getUser } from 'mlp-client/src/user/selectors'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'
import { AppState } from 'mlp-client/src/types'
import { isPredictiveMaintenanceEnabled } from 'mlp-client/src/flows/predictive-maintenance/selectors'
import { getLocationById } from 'mlp-client/src/selectors'

export interface Props
  extends MyLeaseplanFeatureProps<MaintenanceFlowConfiguration> {
  country: string
  contractId: string
  email: string
  phoneNumber: string
  phoneCountryCode: string
  address: Address
  isPredictiveFeatureEnabled: boolean
  locale: Locales
  isMaintenanceAllowed: boolean
}

export class Flow extends React.PureComponent<Props> {
  static stepTitlePrefix = 'myLeaseplan.maintenance'

  predictiveMileage: number

  constructor(props: Props) {
    super(props)

    const {
      location: { search },
    } = props

    if (search) {
      const query = new URLSearchParams(search)

      if (query.get('predictiveMileage')) {
        this.predictiveMileage = Number(query.get('predictiveMileage'))
      }
    }
  }

  renderStep = (stepParameters: FlowProps<any>) => {
    const { name } = stepParameters.activeStep
    const {
      configuration: { allSteps },
    } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = allSteps[name] as any

    if (StepView) {
      return <StepView {...stepParameters} config={{ ...stepConfig }} />
    }

    return <NotFound />
  }

  onFlowClose = () => {
    const { match, contractId } = this.props
    const params = {
      ...filterRouteParams(match.params),
      contractId,
    }

    return <TranslatedRedirect to="myLeaseplan.dashboard" params={params} />
  }

  render() {
    const {
      featureConfig,
      match: { params },
      contractId,
      email,
      phoneCountryCode,
      phoneNumber,
      locale,
      address,
      country,
      isPredictiveFeatureEnabled,
      isMaintenanceAllowed,
    } = this.props

    const isContractDetailsEnabled = !phoneNumber || !phoneCountryCode

    if (
      (!isPredictiveFeatureEnabled && params.prefix === 'predictive') ||
      !isMaintenanceAllowed
    ) {
      return <NotFound />
    }

    if (params?.prefix === 'predictive' && !this.predictiveMileage) {
      return (
        <TranslatedRedirect
          to="myLeaseplan.dashboard"
          params={{
            predictive: `predictive/?contractId=${contractId}`,
          }}
        />
      )
    }

    return (
      <MyleaseplanFlow
        stepTitlePrefix={Flow.stepTitlePrefix}
        routePrefix="myLeaseplan.maintenance.steps"
        getSteps={featureConfig.configuration.getSteps}
        onClose={this.onFlowClose}
        render={this.renderStep}
        initialFlowData={{
          address,
          contractId,
          country,
          email,
          phoneNumber,
          phone: {
            code: phoneCountryCode,
            number: phoneNumber,
          },
          locale,
          predictiveMileage: this.predictiveMileage,
          isContractDetailsEnabled,
        }}
        eventCategory={EventCategoryService.MAIN_SERVICE}
        flowName={EventCategoryFlow.MAINTENANCE}
      />
    )
  }
}

type ReduxProps = Pick<
  Props,
  | 'country'
  | 'isPredictiveFeatureEnabled'
  | 'contractId'
  | 'email'
  | 'phoneCountryCode'
  | 'phoneNumber'
  | 'address'
  | 'locale'
  | 'isMaintenanceAllowed'
>
type DispatchProps = Pick<Props, never>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): ReduxProps => {
  const { configuration } = ownProps.featureConfig
  const address = getLocationById(state, configuration?.defaultAddressType)

  const {
    email,
    mobilePhoneNumber,
    phoneNumber,
    mobilePhoneCountryCode,
    phoneCountryCode,
  } = getUser(state)

  return {
    email,
    phoneCountryCode: mobilePhoneNumber
      ? mobilePhoneCountryCode
      : phoneCountryCode,
    phoneNumber: mobilePhoneNumber || phoneNumber,
    country: getCountry(state),
    isPredictiveFeatureEnabled: isPredictiveMaintenanceEnabled(state),
    contractId: getCurrentContractId(state),
    locale: getLocale(state),
    ...(address && { address }),
    isMaintenanceAllowed: canDoMaintenanceBooking(state),
  }
}

export default connect<ReduxProps, DispatchProps, OwnProps>(mapStateToProps)(
  Flow,
)
