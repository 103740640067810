export enum StepNames {
  SELECT_WINDOW_TYPE = 'selectWindowType',
  SELECT_WINDOW_DAMAGE = 'selectWindowDamage',
  SELECT_LOCATION = 'selectLocation',
  CHECK_CONTACT_DETAILS = 'checkContactDetails',
  CHECK_DETAILS = 'checkDetails',
  THANK_YOU = 'thankYou',
  HAS_DAMAGE_REPORT = 'hasDamageReport',
  SELECT_SUPPLIER = 'selectSupplier',
  FIND_SUPPLIER = 'findSupplier',
  REPORTED_DAMAGE = 'reportedDamage',
}

export enum TimeWindows {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
}

export enum DamageType {
  SmallCrack = 'smallDamage',
  BigCrack = 'bigDamage',
  Panorama = 'panoramaDamage',
}
