import {
  DamageNatureType,
  FlowData,
} from 'mlp-client/src/flows/damage-report/types'

export const enum ActionTypes {
  GET_DAMAGE_NATURE_TYPES_REQUEST = '@@myLeaseplan/damageReport/GET_DAMAGE_NATURE_TYPES_REQUEST',
  GET_DAMAGE_NATURE_TYPES_SUCCESS = '@@myLeaseplan/damageReport/GET_DAMAGE_NATURE_TYPES_SUCCESS',
  GET_DAMAGE_NATURE_TYPES_FAILED = '@@myLeaseplan/damageReport/GET_DAMAGE_NATURE_TYPES_FAILED',
  SUBMIT_DAMAGE_REPORT_REQUEST = '@@myLeaseplan/damageReport/SUBMIT_DAMAGE_REPORT_REQUEST',
}

export class GetDamageNatureTypesRequest {
  readonly type = ActionTypes.GET_DAMAGE_NATURE_TYPES_REQUEST
}

export class GetDamageNatureTypesSuccess {
  readonly type = ActionTypes.GET_DAMAGE_NATURE_TYPES_SUCCESS

  constructor(public payload: { data: readonly DamageNatureType[] }) {}
}

export class GetDamageNatureTypesFailed {
  readonly type = ActionTypes.GET_DAMAGE_NATURE_TYPES_FAILED

  constructor(public payload: { error: string }) {}
}

export class SubmitDamageReportRequest {
  readonly type = ActionTypes.SUBMIT_DAMAGE_REPORT_REQUEST

  constructor(
    public payload: {
      flowData: FlowData
      onComplete(error: boolean): void
    },
  ) {}
}

export type Action =
  | GetDamageNatureTypesRequest
  | GetDamageNatureTypesSuccess
  | GetDamageNatureTypesFailed
  | SubmitDamageReportRequest
