import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import queryString from 'qs'
import { Action, Dispatch, compose } from 'redux'

import {
  getTranslation,
  noContractAllowedLocale,
} from 'mlp-client/src/localization/selectors'
import DashboardWithIcons from 'mlp-client/src/dashboard/components/layout/DashboardWithIcons'
import { TopBanner } from 'mlp-client/src/dashboard/components/TopBanner'
import { TileOptions, TilesConfig } from 'mlp-client/src/dashboard/type'
import {
  MyLeaseplanFeature,
  MyLeaseplanFeaturePropsWithRouteComponentProps,
  RoadsideAssistanceConfig,
} from 'mlp-client/src/features-configuration/types'
import { getUserName } from 'mlp-client/src/dashboard/utils'
import { getUser } from 'mlp-client/src/user/selectors'
import { User } from 'mlp-client/src/user/types'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import {
  getCurrentContract,
  getNotifications,
  showMultipleVehicles,
} from 'mlp-client/src/contracts/selectors'
import { hasMultipleQuotesAndContracts } from 'mlp-client/src/quotes/selectors'
import { Notification, Contract } from 'mlp-client/src/contracts/types'
import MultipleContractTopBanner from 'mlp-client/src/dashboard/components/MultipleContractTopBanner'
import BannerCardWrapper from 'mlp-client/src/order-status/components/banner/BannerCardWrapper'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { AppState } from 'mlp-client/src/types'
import { isPredictiveMaintenanceEnabled } from 'mlp-client/src/flows/predictive-maintenance/selectors'
import {
  LoadNotifications,
  PredictiveMileageModalShow,
} from 'mlp-client/src/contracts/actions'
import { getShowAllFeatures } from 'mlp-client/src/content/selectors'

import { getTileConfig } from './selectors'

export interface Config {
  showFullNameSalutation?: boolean
  alwaysDisplayFuelCardTile?: boolean
  numberOfDaysToReturn?: number
  hideServicesTitle?: boolean
  hideServicesSubtitle?: boolean
  showServicesWarning?: boolean
  showNolsWarning?: boolean
  showNoContractContent?: boolean
  servicesConfig(contract?: Contract): TileOptions
  quickActionsConfig(contract?: Contract): TileOptions
  roadsideAssistanceConfig?(
    contract?: Contract,
  ): MyLeaseplanFeature<RoadsideAssistanceConfig>
}

export interface Props
  extends MyLeaseplanFeaturePropsWithRouteComponentProps<Config> {
  servicesConfig: TilesConfig
  quickActionsConfig: TilesConfig
  roadsideAssistanceConfig?: MyLeaseplanFeature<RoadsideAssistanceConfig>
  metaTitle: string
  metaDescription: string
  isMobileApp?: boolean
  isMultipleVehicles?: boolean
  isShowFeaturePredictiveMileage?: boolean
  user: User
  notifications: readonly Notification[]
  currentContract: Contract
  showMultipleVehicles: boolean
  isPredictiveFeatureEnabled: boolean
  showNoContractContent: boolean
  hasMultipleQuotesAndContracts: boolean
  onLoadNotifications(): void
  onShowPredictiveMileageModal(contractId: string): void
}

const ConfigurableDashboard: React.FC<Props> = (props: Props) => {
  const { predictiveMaintenance } = useContext(MyLeaseplanContext)

  const {
    onLoadNotifications,
    onShowPredictiveMileageModal,
    showMultipleVehicles,
    isPredictiveFeatureEnabled,
    location: { pathname, search },
    history,
    servicesConfig,
    quickActionsConfig,
    roadsideAssistanceConfig,
    metaTitle,
    metaDescription,
    user,
    isMobileApp,
    notifications,
    isShowFeaturePredictiveMileage,
    showNoContractContent,
    hasMultipleQuotesAndContracts,
    currentContract,
    featureConfig: {
      configuration: {
        showFullNameSalutation,
        hideServicesTitle,
        hideServicesSubtitle,
        showServicesWarning,
        showNolsWarning,
      },
    },
  } = props

  useEffect(() => {
    if (
      isPredictiveFeatureEnabled &&
      !showMultipleVehicles &&
      predictiveMaintenance
    ) {
      onLoadNotifications()
    }
  }, [
    predictiveMaintenance,
    onLoadNotifications,
    showMultipleVehicles,
    isPredictiveFeatureEnabled,
  ])

  useEffect(() => {
    if (pathname.endsWith('/predictive/')) {
      const { contractId } = queryString.parse(
        (search || '').replace(/\/$/, ''),
        {
          ignoreQueryPrefix: true,
        },
      )

      history.replace(pathname.replace('/predictive', ''), {
        contractId:
          contractId && typeof contractId === 'string' ? contractId : null,
      })

      if (contractId && typeof contractId === 'string') {
        onShowPredictiveMileageModal(contractId)
      }
    }
  }, [history, pathname, search, onShowPredictiveMileageModal])

  if (isMobileApp) {
    return null
  }

  const topBanner =
    showMultipleVehicles ||
    (showNoContractContent && hasMultipleQuotesAndContracts) ? (
      <MultipleContractTopBanner />
    ) : (
      <TopBanner name={user && getUserName(showFullNameSalutation, user)} />
    )

  let roadsideAssistance

  if (roadsideAssistanceConfig) {
    const {
      view: RoadsideAssistanceView,
      ...roadsideAssistanceParams
    } = roadsideAssistanceConfig

    roadsideAssistance = RoadsideAssistanceView && (
      <RoadsideAssistanceView
        feature="roadsideAssistance"
        featureConfig={roadsideAssistanceParams}
      />
    )
  }

  const dashboardProps = {
    metaTitle,
    metaDescription,
    showMultipleVehicles,
    topBanner,
    notifications: isPredictiveFeatureEnabled ? notifications : undefined,
    isShowFeaturePredictiveMileage,
    currentContract,
    servicesConfig,
    quickActionsConfig,
    roadsideAssistance,
    orderStatusBanner: <BannerCardWrapper />,
    hideServicesTitle,
    hideServicesSubtitle,
    showServicesWarning,
    showNolsWarning,
    showNoContractContent,
    hasMultipleQuotesAndContracts,
  }

  return <DashboardWithIcons {...dashboardProps} />
}

type ReduxProps = Pick<
  Props,
  | 'servicesConfig'
  | 'quickActionsConfig'
  | 'roadsideAssistanceConfig'
  | 'metaTitle'
  | 'metaDescription'
  | 'user'
  | 'isMobileApp'
  | 'isMultipleVehicles'
  | 'showMultipleVehicles'
  | 'isPredictiveFeatureEnabled'
  | 'notifications'
  | 'currentContract'
  | 'isShowFeaturePredictiveMileage'
  | 'showNoContractContent'
  | 'hasMultipleQuotesAndContracts'
>
type DispatchProps = Pick<
  Props,
  'onLoadNotifications' | 'onShowPredictiveMileageModal'
>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (
  state: AppState,
  {
    featureConfig: {
      configuration: {
        servicesConfig,
        quickActionsConfig,
        roadsideAssistanceConfig,
      },
    },
  }: OwnProps,
): ReduxProps => {
  const currentContract = getCurrentContract(state)

  return {
    currentContract,
    metaTitle: getTranslation(state, 'myLeaseplan.dashboard.title'),
    metaDescription: getTranslation(state, 'myLeaseplan.dashboard.subtitle'),
    isMobileApp: getIsMobileApp(state),
    isMultipleVehicles: showMultipleVehicles(state),
    servicesConfig: getTileConfig(state, servicesConfig(currentContract)),
    quickActionsConfig: getTileConfig(
      state,
      quickActionsConfig(currentContract),
    ),
    roadsideAssistanceConfig:
      roadsideAssistanceConfig && roadsideAssistanceConfig(currentContract),
    user: getUser(state),
    showMultipleVehicles: showMultipleVehicles(state),
    hasMultipleQuotesAndContracts: hasMultipleQuotesAndContracts(state),
    isPredictiveFeatureEnabled: isPredictiveMaintenanceEnabled(state),
    notifications: getNotifications(state),
    isShowFeaturePredictiveMileage: getShowAllFeatures(state),
    showNoContractContent: noContractAllowedLocale(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  onLoadNotifications: () => {
    dispatch(new LoadNotifications())
  },
  onShowPredictiveMileageModal: (contractId: string) => {
    dispatch(new PredictiveMileageModalShow({ contractId }))
  },
})

export { ConfigurableDashboard }
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ConfigurableDashboard)
