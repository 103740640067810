/*
MLP-specific copy of: src/app/features/localization/components/RouteLink.tsx

Differences with original:
- Added support for external links.
External links are now stored in src/mlp-client/src/translations/{LOCALE}/routes.json in `external` section.
If `to` prop contains `myLeaseplan.external` than we prepend url with `https://leaseplan.com`
*/

import { LocationDescriptor, LocationState } from 'history'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import parseUrl from 'url-parse'

import { isExternal, isPhone, isMail } from 'mlp-client/src/utils'
import { RoutesContextProps } from 'mlp-client/src/localization/RoutesProvider'
import { getRouteTranslation } from 'mlp-client/src/localization/utils'
import { withRoutes } from 'mlp-client/src/localization/withRoutes'
import { config } from 'mlp-client/src/app-config'
import { NavigationParams } from 'mlp-client/src/types'

import { localeToPathMappingLU } from './utils'

const DATA_E2E_ID = 'data-e2e-id'
const DATA_TAG_ID = 'data-tag-id'

export interface Props extends RoutesContextProps {
  to: string
  locationState?: LocationState
  baseUrl?: string
  params?: NavigationParams
  [DATA_E2E_ID]?: string
  [DATA_TAG_ID]?: string
  className?: string
  style?: React.CSSProperties
  exact?: boolean
  strict?: boolean
  isActive?(): boolean
  activeClassName?: string
  rel?: string
  target?: string
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void
  children?: React.ReactNode
  /*
   This property was added to remove fallback data-tag-ids.
   'data-tag-id' tags were blocking click events on anchors in IE 11
   on a client-side-rendered velocity component

   But the root cause is unknown (data-* tags should be supported in html 5 cross browser)
   this is an intermediate measure to exclude / include the glfallback data-tag-id's
   added as part of  d585aed0
  */
  includeDataTags?: boolean
}

class RouteLink extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    exact: false,
    strict: false,
    includeDataTags: true,
  }

  render() {
    let to = getRouteTranslation(
      this.props.locale,
      this.props.to,
      this.props.params,
      this.props.routes,
    )

    const shouldAddLeaseplanBaseUrl = this.props.to?.includes(
      'myLeaseplan.external',
    )

    const isExternalLink = isExternal(to) || isPhone(to) || isMail(to)

    if (shouldAddLeaseplanBaseUrl && to) {
      Object.entries(localeToPathMappingLU).forEach(([locale, mapping]) => {
        if (to.includes(locale)) {
          to = to.replace(locale, mapping)
        }
      })
    }

    if (to?.[0] !== '/' && !isExternalLink) {
      return null
    }

    // NavLinks or Links should never get a target: _self, as it causes a reload.
    const target = this.props.target === '_self' ? undefined : this.props.target

    // If baseUrl is set, create a custom link. Eg: it is not internal
    if (this.props.baseUrl || shouldAddLeaseplanBaseUrl || isExternalLink) {
      const link = isExternalLink
        ? to
        : shouldAddLeaseplanBaseUrl
        ? `${config.LEGACY_MLP_REDIRECT || 'https://www.leaseplan.com'}${to}`
        : `${this.props.baseUrl}${to}`

      return (
        <a
          href={link}
          data-e2e-id={this.props[DATA_E2E_ID]}
          data-tag-id={
            this.props[DATA_TAG_ID] ||
            (this.props.includeDataTags ? `link-${this.props.to}` : undefined)
          }
          className={this.props.className}
          target={target}
          rel={this.props.rel || 'follow'}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </a>
      )
    }

    const { locationState } = this.props
    let location: LocationDescriptor

    if (locationState) {
      const urlObject = parseUrl(to, {})
      // url-parse package typings are wrong: query property can be either string or an object
      // depending on the 3rd parameters to parseUrl function. By default it's value is false
      // and the query string is returned as unparsed string. Type assertion is required to
      // come around the issue.
      const search = (urlObject.query as unknown) as string

      location = {
        search,
        pathname: urlObject.pathname,
        hash: urlObject.hash,
        state: locationState,
      }
    }

    return this.props.activeClassName ? (
      <NavLink
        to={location || to}
        data-e2e-id={this.props[DATA_E2E_ID]}
        data-tag-id={
          this.props[DATA_TAG_ID] ||
          (this.props.includeDataTags ? `link-${this.props.to}` : undefined)
        }
        className={this.props.className}
        style={this.props.style}
        activeClassName={this.props.activeClassName}
        onClick={this.props.onClick}
        exact={this.props.exact}
        strict={this.props.strict}
        isActive={this.props.isActive}
        target={target}
        rel={this.props.rel || 'follow'}
      >
        {this.props.children}
      </NavLink>
    ) : (
      <Link
        to={location || to}
        data-e2e-id={this.props[DATA_E2E_ID] || `${this.props.to}`}
        data-tag-id={
          this.props[DATA_TAG_ID] ||
          (this.props.includeDataTags ? `link-${this.props.to}` : undefined)
        }
        className={this.props.className}
        style={this.props.style}
        target={target}
        rel={this.props.rel || 'follow'}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Link>
    )
  }
}

export { RouteLink }
const WithRoutesComponent = withRoutes(RouteLink)

WithRoutesComponent.displayName = 'RouteLink'
export default WithRoutesComponent
