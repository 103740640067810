import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import { MessageBar } from '@velocity/ui/draft'
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { isLeaseplan } from 'mlp-client/src/VelocitySelector'
import { AppState } from 'mlp-client/src/types'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import Translation from 'mlp-client/src/localization/Translation'
import { MyleaseplanPage } from 'mlp-client/src/components/layout'
import { getMakeModel } from 'mlp-client/src/contracts/utils'
import {
  NotificationMileageTile,
  NotificationTile,
} from 'mlp-client/src/dashboard/components'
import DashboardTabs from 'mlp-client/src/dashboard/components/DashboardTabs'
import {
  DashboardLayoutProps,
  TilesConfig,
} from 'mlp-client/src/dashboard/type'
import {
  NotificationStatus,
  NotificationType,
  ContractTypes,
} from 'mlp-client/src/contracts/enums'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'

import { isLuxembourg } from '../../utils'

const StyledGridItem = styled(GridItem)`
  display: flex;
`

class DashboardWithIcons extends React.PureComponent<DashboardLayoutProps> {
  getTiles(tileConfig: TilesConfig) {
    const { currentContract } = this.props

    return tileConfig.map(config => {
      const { name, view: TileView } = config

      return <TileView key={name} disabled={!currentContract} {...config} />
    })
  }

  getNotifications() {
    const { notifications = [], currentContract } = this.props
    const predictiveMaintenance = notifications.find(
      ({ notificationType }) =>
        notificationType === NotificationType.PREDICTIVE_MAINTENANCE,
    )
    const predictiveMileage = notifications.find(
      ({ notificationType }) =>
        notificationType === NotificationType.PREDICTIVE_MILEAGE,
    )

    const notification = predictiveMaintenance || predictiveMileage

    return (
      <Spacing mb={3}>
        <Spacing mb={2}>
          <Headline variant="200" component="h2">
            <Translation id="myLeaseplan.dashboard.comingNext.title" />
          </Headline>
        </Spacing>
        <Grid>
          <StyledGridItem
            key={`${notification.notificationType}-${notification.notificationDetails.caseId}`}
            data-e2e-id={notification.notificationType}
            span="grow"
          >
            {predictiveMaintenance ? (
              <NotificationTile
                {...notification}
                makeModel={getMakeModel(currentContract)}
              />
            ) : (
              predictiveMileage &&
              predictiveMileage.status === NotificationStatus.ACTIVE && (
                <NotificationMileageTile
                  {...notification}
                  makeModel={getMakeModel(currentContract)}
                />
              )
            )}
          </StyledGridItem>
        </Grid>
      </Spacing>
    )
  }

  getShowWarning() {
    if (this.props.showNoContractContent) {
      return false
    }

    const {
      currentContract: { services, isRetrievedFromNols, contractType },
      showServicesWarning,
      showNolsWarning,
    } = this.props

    const noServices = !services || services?.length === 0
    const isDataNotFromNols =
      !isRetrievedFromNols && contractType === ContractTypes.LEASE

    return (
      (noServices && showServicesWarning) ||
      (isDataNotFromNols && showNolsWarning)
    )
  }

  defaultMessages = {
    warningTitle: 'LeasePlan Luxembourg becomes Leasys',
    warningText:
      'LeasePlan Luxembourg becomes Leasys, a new European mobility leader on your side to offer you the best leasing experience.',
    warningLink: 'Discover more',
  }

  renderMainContent = (): React.ReactElement<{}> => {
    const {
      roadsideAssistance,
      notifications = [],
      servicesConfig,
      quickActionsConfig,
      hideServicesTitle,
      hideServicesSubtitle,
      isShowFeaturePredictiveMileage,
      locale,
    } = this.props

    const predictiveMaintenance = notifications.find(
      ({ notificationType }) =>
        notificationType === NotificationType.PREDICTIVE_MAINTENANCE,
    )
    const predictiveMileage = notifications.find(
      ({ notificationType }) =>
        notificationType === NotificationType.PREDICTIVE_MILEAGE,
    )

    const leasysUrl = 'https://www.leasys.lu'

    const shouldShowWarningBanner = (): boolean => {
      if (!(isLuxembourg(locale) && isLeaseplan())) {
        return false
      }

      return true
    }

    return (
      <>
        {Boolean(
          predictiveMaintenance ||
            (isShowFeaturePredictiveMileage &&
              predictiveMileage?.status === NotificationStatus.ACTIVE),
        ) && this.getNotifications()}
        {this.getShowWarning() && (
          <SMessageBarContainer>
            <MessageBar variant="warning">
              <MessageBar.Text>
                <Translation
                  id="myLeaseplan.error.serversLimited.text"
                  data-e2e-id="noServicesWarningMessage"
                />
              </MessageBar.Text>
            </MessageBar>
          </SMessageBarContainer>
        )}
        {shouldShowWarningBanner() && (
          <SMessageBarContainer>
            <MessageBar variant="warning">
              <MessageBar.Title data-e2e-id="warning-title">
                <Translation
                  id="myLeaseplan.lplu.rebrandingWarning.title"
                  defaultLabel={this.defaultMessages.warningTitle}
                />
              </MessageBar.Title>
              <>
                <Translation
                  id="myLeaseplan.lplu.rebrandingWarning.text"
                  data-e2e-id="warning-text"
                  defaultLabel={this.defaultMessages.warningText}
                />
              </>
              <MessageBar.Text>
                <a href={leasysUrl} data-e2e-id="warning-link" target="_blank">
                  <Translation
                    id="myLeaseplan.lplu.rebrandingWarning.linkText"
                    defaultLabel={this.defaultMessages.warningLink}
                  />
                </a>
              </MessageBar.Text>
            </MessageBar>
          </SMessageBarContainer>
        )}
        {Boolean(servicesConfig.length) && (
          <>
            <Spacing mb={2} mt={3}>
              {!hideServicesTitle && (
                <Headline variant="200" component="h2">
                  <Translation id="myLeaseplan.dashboard.serviceRequest.title" />
                </Headline>
              )}
              {!hideServicesSubtitle && (
                <Text variant="200">
                  <Translation id="myLeaseplan.dashboard.serviceRequest.subtitle" />
                </Text>
              )}
            </Spacing>
            <Grid data-e2e-id="serviceRequest">
              {this.getTiles(servicesConfig)}
            </Grid>
          </>
        )}
        {Boolean(quickActionsConfig.length) && (
          <>
            <Spacing mb={2} mt={3}>
              <Headline variant="200" component="h2">
                <Translation id="myLeaseplan.dashboard.quickActions.title" />
              </Headline>
              <Text variant="200" component="span">
                <Translation id="myLeaseplan.dashboard.quickActions.subtitle" />
              </Text>
            </Spacing>
            <Grid data-e2e-id="quickActions">
              {this.getTiles(quickActionsConfig)}
            </Grid>
          </>
        )}
        <Spacing mb={4} mt={1} />
        {roadsideAssistance}
      </>
    )
  }

  render() {
    const {
      metaTitle,
      metaDescription,
      showMultipleVehicles,
      topBanner,
      showNoContractContent,
      hasMultipleQuotesAndContracts,
    } = this.props
    const mainContent = this.renderMainContent()

    return (
      <MyleaseplanPage
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        banner={topBanner}
      >
        {showMultipleVehicles ||
        (showNoContractContent && hasMultipleQuotesAndContracts) ? (
          <DashboardTabs dashboardContent={mainContent} />
        ) : (
          mainContent
        )}
      </MyleaseplanPage>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  locale: getUserLocale(state),
})

export { DashboardWithIcons }
export default connect(mapStateToProps)(DashboardWithIcons)
