import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import { NavigationItem } from 'mlp-client/src/types'
import { DynamicLink } from 'mlp-client/src/components/dynamic-link/DynamicLink'
import Translation from 'mlp-client/src/localization/Translation'
import { TopNavigationContext } from 'mlp-client/src/components/layout/top-navigation/TopNavigationContext'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { MainMenuTopNavigation } from 'mlp-client/src/analytics/actions'

import MainMenuDropdownItem from './MainMenuDropdownItem'
import Overflow from './Overflow'

export interface Props {
  hidden?: boolean
  onOverflowChange(overflow: boolean): void
  contractId: string
  quoteId?: string
  trackMainMenuTopNavigation?(eventLabel: string): void
}

interface State {
  overflowCalculated: boolean
  overflow: { [key: number]: boolean }
}

class MainMenuItems extends React.PureComponent<Props, State> {
  // We need to keep a reference to child elements.
  // We use them to determine if they're overflowing their container.
  overflowChildRefs: HTMLElement[] = []
  state = { overflowCalculated: false, overflow: {} }

  attachChildReference = (element: HTMLElement, index: number) => {
    this.overflowChildRefs[index] = element
  }

  handleOverflowChange = (overflow: { [key: number]: boolean }) => {
    this.setState({ overflow, overflowCalculated: true })
  }

  getItemsBehindMoreButton = (navItems: readonly NavigationItem[]) =>
    navItems.filter(
      (item, i) => this.state.overflow[i] || this.state.overflow[i + 1],
    )

  render() {
    const { overflow } = this.state
    const { contractId, quoteId } = this.props
    const linkParams = (item: NavigationItem) => ({
      ...(item.useContractId ? { contractId } : {}),
      ...(item.useQuoteId ? { quoteId } : {}),
    })

    return (
      <TopNavigationContext.Consumer>
        {({ navActiveItem, navItems }) => (
          <Overflow
            element="ul"
            className={Classnames('o-list-inline main-menu-items', {
              'main-menu-items--invisible': this.props.hidden,
            })}
            childRefs={this.overflowChildRefs}
            onOverflowChange={this.handleOverflowChange}
          >
            {navItems.map((item, i) => {
              const to = item.to || item.url || ''

              return (
                <li
                  key={to}
                  ref={ref => this.attachChildReference(ref, i)}
                  className={Classnames(
                    'o-list-inline__item main-menu-items__item',
                    {
                      'main-menu-items__item--invisible': overflow[i],
                    },
                  )}
                >
                  <LocalizationContext.Consumer>
                    {({ translate }) => (
                      <DynamicLink
                        data-tag-id={`main-menu-item-link-${to}`}
                        to={to}
                        params={linkParams(item)}
                        data-e2e-id={`mainMenuItem-${to}`}
                        target={item.target || '_self'}
                        className={Classnames('main-menu-items__button', {
                          'main-menu-items__button--inactive':
                            navActiveItem && item !== navActiveItem,
                          'main-menu-items__button--invisible':
                            !overflow[i] && overflow[i + 1],
                        })}
                        aria-expanded={item === navActiveItem}
                        onClick={() =>
                          this.props.trackMainMenuTopNavigation(
                            translate(item.title),
                          )
                        }
                      >
                        {item.title && <Translation id={item.title} />}
                      </DynamicLink>
                    )}
                  </LocalizationContext.Consumer>

                  {!overflow[i] && overflow[i + 1] && (
                    // We render a 'More...' button when this item is NOT overflowing, but the next one is.
                    <div>
                      <MainMenuDropdownItem
                        className={Classnames('main-menu-items__button-more', {
                          'main-menu-items__button-more--inactive': navActiveItem,
                        })}
                        items={this.getItemsBehindMoreButton(navItems)}
                        label="myLeaseplan.shared.navigation.more"
                        contractId={contractId}
                      />
                    </div>
                  )}
                </li>
              )
            })}
          </Overflow>
        )}
      </TopNavigationContext.Consumer>
    )
  }
}

type DispatchProps = Pick<Props, 'trackMainMenuTopNavigation'>

export const mapDispatchToProps: DispatchProps = {
  trackMainMenuTopNavigation: (eventLabel: string) =>
    new MainMenuTopNavigation({ eventLabel }),
}

export { MainMenuItems }
export default connect(undefined, mapDispatchToProps)(MainMenuItems)
