import React from 'react'
import ReactMarkdown, { AllowNode } from 'react-markdown'
import sanitizeHtml from 'sanitize-html'
import Classnames from 'classnames'

import RichTextLink from './RichTextLink'
import RichTextList from './RichTextList'

export interface Props {
  type?: 'markdown' | 'html'
  text?: string
}

type DefaultProps = Required<Pick<Props, 'type' | 'text'>>
type InternalProps = Props & DefaultProps

class RichText extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    type: 'markdown',
    text: '',
  }

  customRenderers = {
    Link: RichTextLink,
    List: RichTextList,
  }

  decorateElement = (el: AllowNode) => !!el

  render() {
    const { type, text } = this.props

    switch (type) {
      case 'markdown': {
        const renderers = {
          ...ReactMarkdown.renderers,
          ...this.customRenderers,
        }

        return (
          <ReactMarkdown
            className={Classnames('richText')}
            allowNode={this.decorateElement}
            escapeHtml={true}
            softBreak="br"
            source={text.split('\\n').join('\n')}
            renderers={renderers}
          />
        )
      }

      case 'html': {
        return <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />
      }
    }
  }
}

export default RichText
