import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { RouteComponentProps, withRouter, match } from 'react-router'
import { compose } from 'redux'

import Loader from 'mlp-client/src/components/loader/Loader'
import { LoadQuotes } from 'mlp-client/src/quotes/actions'
import { Quote, Quotes } from 'mlp-client/src/quotes/types'
import {
  getQuotes,
  isQuotesLoaded,
  isQuotesLoading,
} from 'mlp-client/src/quotes/selectors'
import withUser, { UserProps } from 'mlp-client/src/user/components/withUser'
import { AppState } from 'mlp-client/src/types'

type UserQuotes = readonly Quote[]

interface QuoteProps {
  quotes: UserQuotes
}

interface MatchProps {
  quoteId: string
}

export interface Props extends UserProps, QuoteProps, RouteComponentProps {
  isQuotesLoading: boolean
  isQuotesLoaded: boolean
  fetchUserQuotes(selectedQuoteId: string): void
  fetchQuoteById(quoteId: string): void
  children(quotes: UserQuotes): React.ReactNode
  match: match<MatchProps>
}

export class LoadQuotesContainer extends React.PureComponent<Props> {
  private get shouldFetchQuotes(): boolean {
    const { isQuotesLoading, isQuotesLoaded } = this.props

    return !isQuotesLoading && !isQuotesLoaded
  }

  loadQuotes = (forceQuoteUpdate?: boolean) => {
    const {
      fetchUserQuotes,
      location: { search },
    } = this.props

    if (this.shouldFetchQuotes || forceQuoteUpdate) {
      const query = new URLSearchParams(search)
      const quoteId = query.get('quoteId')

      fetchUserQuotes(quoteId)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isQuotesLoaded, user } = this.props
    const shouldReloadQuotes = prevProps.isQuotesLoaded && !isQuotesLoaded
    const isUserChanged = prevProps.user.id !== user.id

    if (shouldReloadQuotes || isUserChanged) {
      this.loadQuotes(true)
    }
  }

  render() {
    const { isQuotesLoaded, children, isQuotesLoading, quotes } = this.props

    if (isQuotesLoading || !isQuotesLoaded) {
      return <Loader loading={true} fixed={true} />
    }

    return children(quotes)
  }
}

type ReduxProps = Pick<Props, 'quotes' | 'isQuotesLoading' | 'isQuotesLoaded'>
type DispatchProps = Pick<Props, 'fetchUserQuotes'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  isQuotesLoading,
  isQuotesLoaded,
  quotes: getQuotes,
})

const mapDispatchToProps: DispatchProps = {
  fetchUserQuotes: selectedQuoteId => new LoadQuotes({ selectedQuoteId }),
}

const QuotesProvider = compose(
  withUser,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LoadQuotesContainer)

export default <T extends Partial<QuoteProps>>(
  ProtectedComponent: React.ComponentType<T>,
) => {
  const withQuotes: React.SFC<Omit<T, keyof QuoteProps>> = props => (
    <QuotesProvider>
      {(quotes: Quotes) => (
        <ProtectedComponent {...(props as T)} quotes={quotes} />
      )}
    </QuotesProvider>
  )

  withQuotes.displayName = `withQuotes(${
    ProtectedComponent.displayName || ProtectedComponent.name
  })`

  return withQuotes
}
