import { Spacing } from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { StepViewProps } from 'mlp-client/src/flows/types'
import { FlowData } from 'mlp-client/src/flows/tyres/types'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { required } from 'mlp-client/src/form/validations'

export interface Props extends StepViewProps<{}, FlowData> {}

export type FormValues = Pick<FlowData, 'problemDescription'>

class ProblemDescriptionStep extends React.PureComponent<Props> {
  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData({ problemDescription: values.problemDescription }, goToNextStep)
  }

  render() {
    const { problemDescription } = this.props.flowData

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.newSetup.flows.tyres.steps.problemDescription.pageTitle">
          <Form
            onSubmit={this.onSubmit}
            initialValues={{
              problemDescription,
            }}
            render={({ handleSubmit, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <Spacing mb={2}>
                  <TextAreaField
                    name="problemDescription"
                    validations={[required]}
                    legalNote={{
                      id: 'myLeaseplan.newSetup.shared.openTextLegalNote',
                    }}
                    placeholder={{
                      id:
                        'myLeaseplan.newSetup.shared.forms.problemDescription.placeholder',
                    }}
                  />
                </Spacing>
                <NextStepButton
                  onClick={handleSubmit}
                  disabled={hasValidationErrors}
                />
              </form>
            )}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

export default ProblemDescriptionStep
