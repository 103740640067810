import React, { PureComponent } from 'react'

import { HeaderCellWrapper } from './HeaderCellWrapper'
import { TableStyled } from './Table.styled'
import TableWideBody from './TableWideBody'
import { TableProps } from './types'

export class TableWide extends PureComponent<TableProps> {
  render() {
    const { columns, rows, data, emptyState, defaultValue } = this.props

    return (
      <TableStyled>
        <thead>
          <tr>
            {columns.map(({ header, headerCell, ...props }) => (
              <HeaderCellWrapper
                key={props.key}
                header={header}
                headerCell={headerCell}
                dataE2EId={props.key}
                {...props}
              />
            ))}
          </tr>
        </thead>
        <TableWideBody
          columns={columns}
          data={data}
          rows={rows}
          emptyState={emptyState}
          defaultValue={defaultValue}
        />
      </TableStyled>
    )
  }
}

export default TableWide
