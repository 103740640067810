import {
  ButtonChevronDownIcon,
  Card,
  Color,
  Grid,
  GridItem,
  Link,
  Spacing,
} from '@leaseplan/ui'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { FlowData } from 'mlp-client/src/flows/cash-claim/types'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { RadioCardField } from 'mlp-client/src/form/components/fields/RadioFields'
import { Options } from 'mlp-client/src/form/types'
import { required } from 'mlp-client/src/form/validations'
import { AppState } from 'mlp-client/src/types'
import { getFuelType } from 'mlp-client/src/contracts/selectors'
import { SecondaryDarkText } from 'mlp-client/src/components/styled/TextStyle'

interface State {
  showAllFuelTypes: boolean
}

export interface StepConfig {
  options: Options
  optionsListE2eId?: string
}

export interface Props extends FlowProps<FlowData> {
  currentFuelType?: string
  config: StepConfig
}

type FormData = Pick<FlowData, 'subCategory'>

export const StyledItemCard = styled(Card)`
  & ${Link} {
    text-decoration: none;
    color: ${Color.thunder};
  }
`

class FuelTypeStep extends React.PureComponent<Props, State> {
  state = {
    showAllFuelTypes: !this.props.currentFuelType,
  }

  showAllFuelTypes = () => {
    this.setState({
      showAllFuelTypes: true,
    })
  }

  currentTypeOptions = (): Options => {
    const {
      config: { options },
      currentFuelType,
    } = this.props

    return options.filter(fuelType => fuelType.value === currentFuelType)
  }

  submitHandler = (values: FormData) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData({ ...values }, goToNextStep)
  }

  render() {
    const { showAllFuelTypes } = this.state
    const {
      config: { options: allOptions, optionsListE2eId },
      currentFuelType,
    } = this.props
    const options = showAllFuelTypes ? allOptions : this.currentTypeOptions()
    const form = (
      <Form
        onSubmit={this.submitHandler}
        initialValues={{
          subCategory: currentFuelType,
        }}
      >
        {({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <RadioCardField
              name="subCategory"
              options={options}
              validations={[required]}
              dataE2eId={optionsListE2eId}
            />
            {!showAllFuelTypes && (
              <Spacing mt={1}>
                <StyledItemCard
                  border={true}
                  borderColor="steel20"
                  hover={true}
                  hoverElevation={4}
                  data-e2e-id="showAllFuelTypes"
                >
                  <Link onClick={this.showAllFuelTypes}>
                    <Spacing mh={2} mv={1}>
                      <Grid alignItems="center">
                        <GridItem span="grow">
                          <SecondaryDarkText>
                            <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.fuelType.usedReplacementVehicle" />
                          </SecondaryDarkText>
                          <SecondaryDarkText bold>
                            <Translation id="myLeaseplan.newSetup.flows.cashClaim.steps.fuelType.viewMoreOptions" />
                          </SecondaryDarkText>
                        </GridItem>
                        <GridItem span={{ tablet: 1 }}>
                          <ButtonChevronDownIcon />
                        </GridItem>
                      </Grid>
                    </Spacing>
                  </Link>
                </StyledItemCard>
              </Spacing>
            )}
            <Spacing mt={3}>
              <NextStepButton
                onClick={handleSubmit}
                disabled={hasValidationErrors}
              />
            </Spacing>
          </form>
        )}
      </Form>
    )

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.newSetup.flows.cashClaim.steps.fuelType.header">
          {form}
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'currentFuelType'>

export const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  currentFuelType: getFuelType,
})

export { FuelTypeStep }
export default connect(mapStateToProps)(FuelTypeStep)
