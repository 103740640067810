import { FinesRecord } from 'mlp-client/src/contracts/types'
import { getContract } from 'mlp-client/src/contracts/selectors'
import { AppState } from 'mlp-client/src/types'

export const finesListSelector = (
  state: AppState,
): readonly FinesRecord[] | undefined => {
  const fines = getContract(state)?.vehicleHistory?.fines

  return fines?.length > 0 ? fines : undefined
}
