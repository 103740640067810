import React from 'react'

import {
  StyledFee,
  StyledFeeWrapper,
  StyledPhoneNumber,
  StyledPhoneNumberBlock,
  StyledPhoneNumberText,
  StyledPhoneNumberWrapper,
} from 'mlp-client/src/flows/components/phone-number/PhoneNumber.styled'

export interface Props {
  phone: string
}

class PhoneNumber extends React.PureComponent<Props> {
  render() {
    const { phone } = this.props

    return (
      <StyledPhoneNumberBlock href={`tel:${phone}`}>
        <StyledPhoneNumberWrapper>
          <StyledPhoneNumber>
            <StyledPhoneNumberText size="l" fontWeight="bold" color="purple">
              {phone}
            </StyledPhoneNumberText>
          </StyledPhoneNumber>
        </StyledPhoneNumberWrapper>
        <StyledFeeWrapper>
          <StyledFee />
        </StyledFeeWrapper>
      </StyledPhoneNumberBlock>
    )
  }
}

export { PhoneNumber }
