import { Locales } from 'mlp-client/src/localization/enums'
import { NavigationItem } from 'mlp-client/src/types'

import { Content } from './types'

export const enum ActionTypes {
  SET_SHOW_ALL_FEATURES = '@@my-leaseplan/shared/SET_SHOW_ALL_FEATURES',
  LOAD_CONTENT = '@@my-leaseplan/shared/LOAD_CONTENT',
  LOAD_CONTENT_SUCCESS = '@@my-leaseplan/shared/LOAD_CONTENT_SUCCESS',
  SET_DEBUG_TRANSLATIONS = '@@my-leaseplan/shared/SET_DEBUG_TRANSLATIONS',
  LOAD_FOOTER_MENU = '@@myLeaseplan/content/small-footer/LOAD_FOOTER_MENU',
  LOAD_FOOTER_MENU_SUCCESS = '@@myLeaseplan/content/small-footer/LOAD_FOOTER_MENU_SUCCESS',
  LOAD_FOOTER_MENU_FAILED = '@@myLeaseplan/content/small-footer/LOAD_FOOTER_MENU_FAILED',
}

export class SetShowAllFeatures {
  readonly type = ActionTypes.SET_SHOW_ALL_FEATURES
}

export class LoadContent {
  readonly type = ActionTypes.LOAD_CONTENT

  constructor(
    public payload: {
      endpoint: string
      locale?: string
      cache?: boolean
    },
  ) {
    if (payload.cache === undefined) {
      this.payload.cache = true
    }
  }
}

export class LoadContentSuccess {
  readonly type = ActionTypes.LOAD_CONTENT_SUCCESS

  constructor(public payload: { endpoint: string; result: Content }) {}
}

export class SetDebugTranslations {
  readonly type = ActionTypes.SET_DEBUG_TRANSLATIONS
}

export class LoadFooterMenu {
  readonly type = ActionTypes.LOAD_FOOTER_MENU

  constructor(public payload: { userLocale: Locales }) {}
}

export class LoadFooterMenuSuccess {
  readonly type = ActionTypes.LOAD_FOOTER_MENU_SUCCESS

  constructor(
    public payload: {
      result: readonly NavigationItem[]
      locale: Locales
    },
  ) {}
}

export class LoadFooterMenuFailed {
  readonly type = ActionTypes.LOAD_FOOTER_MENU_FAILED
}

export type Actions =
  | SetShowAllFeatures
  | LoadContent
  | LoadContentSuccess
  | SetDebugTranslations
  | LoadFooterMenu
  | LoadFooterMenuSuccess
  | LoadFooterMenuFailed
