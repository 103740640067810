import { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'

import { ApiSagas } from 'mlp-client/src/api'

import * as actions from './actions'
import { getSubmitExtendContractRequestBody } from './utils'

const defaultRequestOptions = {
  throwError: true,
}

export function* extendContractRequest({
  payload: { flowData, onComplete },
}: actions.SubmitExtendContractRequest): SagaIterator {
  const body = yield call(getSubmitExtendContractRequestBody, flowData)
  let isRequestSuccessful: boolean = true

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/contract-extension',
      { body },
      defaultRequestOptions,
    )
    yield call(onComplete, false)
  } catch {
    isRequestSuccessful = false
  } finally {
    yield call(onComplete, !isRequestSuccessful)
  }
}

export default [
  takeLatest(
    actions.ActionTypes.EXTEND_CONTRACT_REQUEST,
    extendContractRequest,
  ),
]
