import {
  ButtonChevronRightIcon,
  ButtonLink,
  Grid,
  Hide,
  Spacing,
} from '@leaseplan/ui'
import { MessageBar as VelocityMessageBar } from '@velocity/ui/draft'
import React from 'react'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { SMessageBarContainer } from 'mlp-client/src/components/styled/MessageBar'
import { ColorEnumSelector, isLeaseplan } from 'mlp-client/src/VelocitySelector'
import { StyledButton } from 'mlp-client/src/components/button/StyledButton'

const StyledWrapperLU = styled.div`
  color: ${ColorEnumSelector('midOrange')};
`

const ButtonWrapperLU = styled.div`
  a {
    color: ${ColorEnumSelector('midOrange')} !important;
  }
`

export interface Props {
  phone: string
  distance: number
}

type TranslateFunction = (
  key: string,
  replacements?: Record<string, any>,
) => string

class MessageBar extends React.PureComponent<Props> {
  renderFindNearServiceCenter = (distance: number) => {
    if (isLeaseplan()) {
      return (
        <Translation
          id="myLeaseplan.genericNotifications.findNearServiceCentre"
          replace={{ number: distance }}
        />
      )
    } else {
      return (
        <StyledWrapperLU>
          <Translation
            id="myLeaseplan.genericNotifications.findNearServiceCentre"
            replace={{ number: distance }}
          />
        </StyledWrapperLU>
      )
    }
  }

  renderButtonLink = (translate: TranslateFunction, phoneNumber: string) => (
    <ButtonLink
      component="a"
      href={`tel:${translate(phoneNumber)}`}
      icon={ButtonChevronRightIcon}
    >
      {translate(phoneNumber)}
    </ButtonLink>
  )

  renderWrappedButton = (translate: TranslateFunction, phoneNumber: string) => {
    if (!isLeaseplan()) {
      return (
        <ButtonWrapperLU>
          {this.renderButtonLink(translate, phoneNumber)}
        </ButtonWrapperLU>
      )
    } else {
      return this.renderButtonLink(translate, phoneNumber)
    }
  }

  render() {
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <SMessageBarContainer>
            <VelocityMessageBar variant="warning">
              <VelocityMessageBar.Title>
                {this.renderFindNearServiceCenter(this.props.distance)}
              </VelocityMessageBar.Title>
              <VelocityMessageBar.Text>
                <Translation id="myLeaseplan.genericNotifications.contactUsOtherOptions" />
              </VelocityMessageBar.Text>
              <Hide from="desktop">
                <Grid justifyContent="center">
                  <Spacing mt={2}>
                    <StyledButton
                      component="a"
                      href={`tel:${translate(this.props.phone)}`}
                    >
                      <Translation id="myLeaseplan.genericNotifications.call" />
                      {translate(this.props.phone)}
                    </StyledButton>
                  </Spacing>
                </Grid>
              </Hide>
              <Hide until="desktop" component="span">
                <Spacing ml={1} component="span">
                  {this.renderWrappedButton(translate, this.props.phone)}
                </Spacing>
              </Hide>
            </VelocityMessageBar>
          </SMessageBarContainer>
        )}
      </LocalizationContext.Consumer>
    )
  }
}

export { MessageBar }
export default MessageBar
