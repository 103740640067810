import { Suppliers } from 'mlp-client/src/flows/tyres/types'
import { SupplierCard } from 'mlp-client/src/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import {
  DamageCauses,
  PartyDamages,
} from 'mlp-client/src/flows/damage-report/types'
import { Options } from 'mlp-client/src/form/types'
import * as data from 'mlp-client/src/features-configuration/data'
import { CountryCode } from 'mlp-client/src/localization/enums'
import {
  CashClaimCategory,
  FuelType,
  RoadSideAssistanceType,
} from 'mlp-client/src/flows/cash-claim/enums'

const selectDamageCauseTranslations = makeTranslationPath(
  'myLeaseplan.damageReport.steps.selectDamageCause',
)

const selectCashClaimCategoriesTranslations = makeTranslationPath(
  'myLeaseplan.cashClaim.categories',
)

export const glassSuppliers: readonly SupplierCard[] = [
  {
    name: 'Autoglas Luxemburg',
    phone: 'myLeaseplan.glassRepair.page.contact.autoglasLuxemburgPhone',
    logo: '/static/images/my-leaseplan/glass-suppliers/autoglas-luxemburg.jpg',
    urls: [
      {
        websiteLabel: 'www.autoglas.lu/fr',
        websiteUrl: 'http://www.autoglas.lu/fr/',
      },
      {
        websiteLabel: 'www.autoglas.lu/en',
        websiteUrl: 'http://www.autoglas.lu/en/',
      },
    ],
  },
  {
    name: 'Autovitres Belgique – Luxembourg',
    phone: 'myLeaseplan.glassRepair.page.contact.autovitresBelgiquePhone',
    logo: '/static/images/my-leaseplan/glass-suppliers/auto-vitres-logo.png',
    urls: [
      {
        websiteLabel: 'www.autovitres.com',
        websiteUrl: 'https://www.autovitres.com/',
      },
    ],
  },
]

export const tyresSuppliers: Suppliers = [
  {
    logo: '/static/images/my-leaseplan/tyre-services-suppliers/lu/goedert.png',
    name: 'Autocenter Goedert',
    urls: [
      {
        websiteLabel: 'www.autocentergoedert.lu',
        websiteUrl: 'https://www.autocentergoedert.lu',
      },
    ],
  },

  {
    logo: '/static/images/my-leaseplan/tyre-services-suppliers/lu/rc-pneus.png',
    name: 'RC Pneus',
    urls: [
      {
        websiteLabel: 'www.rcpneus.lu',
        websiteUrl: 'https://www.rcpneus.lu/',
      },
    ],
  },

  {
    logo:
      '/static/images/my-leaseplan/tyre-services-suppliers/lu/nord-pneus.png',
    name: 'Nord Pneus',
    urls: [
      {
        websiteLabel: 'www.nordpneus.com',
        websiteUrl: 'http://www.nordpneus.com/',
      },
    ],
  },

  {
    logo:
      '/static/images/my-leaseplan/tyre-services-suppliers/lu/schafer-pneuservice.png',
    name: 'Schaefer Pneuservice',
    urls: [
      {
        websiteLabel: 'www.schaefer-pneuservice.lu',
        websiteUrl: 'https://www.schaefer-pneuservice.lu/',
      },
    ],
  },

  {
    logo:
      '/static/images/my-leaseplan/tyre-services-suppliers/lu/rodighiero-pneus.png',
    name: 'Rodighiero Pneus',
    urls: [
      {
        websiteLabel: 'www.pneu.lu',
        websiteUrl: 'https://www.pneu.lu',
      },
    ],
  },

  {
    logo:
      '/static/images/my-leaseplan/tyre-services-suppliers/lu/muller-pneus.png',
    name: 'Muller Pneus',
    urls: [
      {
        websiteLabel: 'www.mullerpneus.lu',
        websiteUrl: 'https://www.mullerpneus.lu/',
      },
    ],
  },
]

export const damageCauses = (): Options => [
  {
    title: selectDamageCauseTranslations('oneParty.title'),
    subtitle: selectDamageCauseTranslations('oneParty.subtitle'),
    value: DamageCauses.ONE_PARTY,
    to:
      'https://www.ubench.com/ubench/xhtml/claims/declaration/damageDeclaration.xhtml?owner=LEASEPLAN_LU&lang=fr',
    target: '_blank',
  },
  {
    title: selectDamageCauseTranslations('twoParty.title'),
    value: DamageCauses.TWO_PARTY,
  },
  {
    title: selectDamageCauseTranslations('glassOnly.title'),
    subtitle: selectDamageCauseTranslations('glassOnly.subtitle'),
    value: DamageCauses.GLASS_ONLY,
    to: 'myLeaseplan.glassRepair.base',
  },
]

export const CONTACT_US_PHONE_NUMBER = 'myLeaseplan.timeline.fuelCard.phone'

export const partyDamages: PartyDamages = [
  data.animalsOnePartyDamage,
  data.chainCollisionTwoPartyDamage,
  data.changedLanesTwoPartyDamage,
  data.damageNonFaultTwoPartyDamage,
  data.damageSupplierTwoPartyDamage,
  data.damageToPropertyTwoPartyDamage,
  data.fireOnePartyDamage,
  data.intakeCarDamageOnePartyDamage,
  data.naturalPerilsOnePartyDamage,
  data.occurencePossibleWbfTwoPartyDamage,
  data.oneSidedCollisionOnePartyDamage,

  data.openedDoorTwoPartyDamage,
  data.otherOnePartyDamage,
  data.otherAtFaultTwoPartyDamage,
  data.parkedCarTwoPartyDamage,
  data.partialTheftOnePartyDamage,
  data.pedestrianBicycleTramTwoPartyDamage,
  data.rearendedTwoPartyDamage,
  data.reversingTwoPartyDamage,
  data.rightOfWayTwoPartyDamage,
  data.rsaFnolReportOnePartyDamage,
  data.satNavTheftOnePartyDamage,
  data.uninsuredLossOnePartyDamage,
  data.vandalismOnePartyDamage,
  data.vehicleTheftDamageOnePartyDamage,
]

const countryTranslations = makeTranslationPath(
  'myLeaseplan.serviceRequest.steps.selectLocation.countries',
)

export const countries: Options = [
  {
    title: countryTranslations('germany'),
    value: CountryCode.de,
  },
  {
    title: countryTranslations('france'),
    value: CountryCode.fr,
  },
  {
    title: countryTranslations('luxembourg'),
    value: CountryCode.lu,
  },
  {
    title: countryTranslations('belgium'),
    value: CountryCode.be,
  },
]

export const categories: Options = [
  {
    title: selectCashClaimCategoriesTranslations('liquids.title'),
    subtitle: selectCashClaimCategoriesTranslations('liquids.subtitle'),
    value: CashClaimCategory.LIQUIDS,
  },
  {
    title: selectCashClaimCategoriesTranslations('roadSideAssistance.title'),
    subtitle: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subtitle',
    ),
    value: CashClaimCategory.ROAD_SIDE_ASSISTANCE,
  },
  {
    title: selectCashClaimCategoriesTranslations('damageRepair.title'),
    value: CashClaimCategory.DAMAGE_REPAIR,
  },
  {
    title: selectCashClaimCategoriesTranslations('fuel.title'),
    value: CashClaimCategory.FUEL,
  },
  {
    title: selectCashClaimCategoriesTranslations('maintenance.title'),
    value: CashClaimCategory.MAINTENANCE,
  },
  {
    title: selectCashClaimCategoriesTranslations('mot.title'),
    value: CashClaimCategory.MOT_EXPENSES,
  },
  {
    title: selectCashClaimCategoriesTranslations('tyres.title'),
    value: CashClaimCategory.TYRE_SERVICE,
  },
]

export const fuel: Options = [
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.diesel.title',
    ),
    value: FuelType.DIESEL,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.electric.title',
    ),
    value: FuelType.ELECTRIC,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.euro95.title',
    ),
    value: FuelType.EURO_95,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.eurosuper.title',
    ),
    value: FuelType.EURO_SUPER,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.highPerformance.title',
    ),
    value: FuelType.HIGH_PERFOMANCE,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.hydrogen.title',
    ),
    value: FuelType.HYDROGEN,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.premiumDiesel.title',
    ),
    value: FuelType.PREMIUM_DIESEL,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.premiumPetrol.title',
    ),
    value: FuelType.PREMIUM_PETROL,
  },
]

export const roadSideAssistance: Options = [
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.accommodation.title',
    ),
    value: RoadSideAssistanceType.ACCOMMODATION,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.other.title',
    ),
    value: RoadSideAssistanceType.OTHER,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.publicTransport.title',
    ),
    value: RoadSideAssistanceType.PUBLIC_TRANSPORT,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.replacementVehicle.title',
    ),
    value: RoadSideAssistanceType.REPLACEMENT_VEHICLE,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.taxi.title',
    ),
    value: RoadSideAssistanceType.TAXI,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.transportationOfTheVehicle.title',
    ),
    value: RoadSideAssistanceType.TRANSPORTATION_VEHICLE,
  },
]
