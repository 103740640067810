import React from 'react'

import {
  BannerContent,
  BreadcrumbsContent,
  FullWidthImage,
} from 'mlp-client/src/components/layout/full-width-banner/FullWidthBanner.styled'

interface Props {
  imgMedium: string
  imgLarge: string
  imgSmall?: string
  position?: string
  breadcrumbs?: React.ReactNode
  title?: React.ReactNode
  text?: React.ReactNode
}

/**
 * This component is here to help you render the top full width banner
 */
export class FullWidthBanner extends React.PureComponent<Props> {
  render() {
    const {
      imgMedium,
      imgLarge,
      imgSmall,
      children,
      position,
      breadcrumbs,
    } = this.props

    return (
      <FullWidthImage
        imgMedium={imgMedium}
        imgLarge={imgLarge}
        imgSmall={imgSmall}
        position={position}
      >
        {breadcrumbs && <BreadcrumbsContent>{breadcrumbs}</BreadcrumbsContent>}
        <BannerContent>{children}</BannerContent>
      </FullWidthImage>
    )
  }
}
