import { ButtonLink, Spacing } from '@leaseplan/ui'
import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import { getTranslations } from 'mlp-client/src/localization/selectors'
import { Breadcrumbs } from 'mlp-client/src/components/breadcrumbs'
import { GridLayout, MyleaseplanPage } from 'mlp-client/src/components/layout'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { AppState } from 'mlp-client/src/types'
import SupplierMapPageContent from 'mlp-client/src/flows/components/pages/supplier-map-page/page-content/PageContent'
import { SupplierMapConfiguration } from 'mlp-client/src/flows/components/pages/supplier-map-page/types'

export interface Props
  extends MyLeaseplanFeatureProps<SupplierMapConfiguration> {
  title: string
  subTitle: string
}

export class SupplierMapPage extends React.PureComponent<Props> {
  render() {
    const {
      title,
      subTitle,
      featureConfig: {
        configuration: {
          bookingType,
          title: titleKey,
          subTitle: subTitleKey,
          breadcrumbBaseTitle,
          breadcrumbBaseLink,
          breadcrumbLinkTitle,
          showOpeningHours,
        },
      },
    } = this.props

    return (
      <MyleaseplanPage metaTitle={title} metaDescription={subTitle}>
        <Spacing mb={1}>
          <Breadcrumbs>
            <ButtonLink
              to={breadcrumbBaseLink}
              component={RouteLink}
              size="s"
              fontWeight="regular"
              color="steel60"
            >
              <Translation id={breadcrumbBaseTitle} />
            </ButtonLink>
            <Translation id={breadcrumbLinkTitle} />
          </Breadcrumbs>
        </Spacing>
        <GridLayout
          main={
            <SupplierMapPageContent
              bookingType={bookingType}
              title={titleKey}
              subTitle={subTitleKey}
              showOpeningHours={showOpeningHours}
            />
          }
          mainMaxWidth="100%"
          mainSpan={12}
          sideSpan={12}
        />
      </MyleaseplanPage>
    )
  }
}

type ReduxProps = Pick<Props, 'title' | 'subTitle'>
type OwnProps = Omit<Props, keyof ReduxProps>

const mapStateToProps = (
  state: AppState,
  {
    featureConfig: {
      configuration: { title, subTitle },
    },
  }: OwnProps,
): ReduxProps => ({
  ...getTranslations(state, { title, subTitle }),
})

export default connect(mapStateToProps)(SupplierMapPage)
