import routes from './index'

export const getRoutesByLocale = (locale: string) => routes[locale]

/**
 * This function responsible to return id of each translation keys
 * Some flow and features has `resourceSegment` that will append to id after `myLeaseplan`
 *
 * @param {boolean} [resourceSegment] - if flow has resourceSegment inside translation keys
 * @param {boolean} [typeKey] - type key of translation keys
 * @param {boolean} [withoutParentKey] - remove `myLeaseplan` from id, mostly used for mobile end point.
 */
export const getIdOfTranslation = (
  id: string,
  resourceSegment: string,
  typeKey: string,
  withoutParentKey?: boolean,
) =>
  `${withoutParentKey ? 'myLeaseplan' : 'myLeaseplan'}${
    resourceSegment ? `.${resourceSegment}` : ''
  }.${typeKey}.${id}`.replace(/^\./, '')
