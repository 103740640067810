import { Address, MappedAddress, MappedLocation } from 'mlp-client/src/types'
import {
  Address as UserAddress,
  AddressFormat,
  AddressTypes,
  UserProfileAddress,
} from 'mlp-client/src/user/types'

const buildLine = (delimeter: string, ...fields: string[]): string =>
  fields.filter(field => !!field).join(delimeter)

export const formatAddressCard = (address: Partial<Address>): string => {
  const { street, number, zipCode, city, country } = address
  const streetLine = buildLine(' ', street, number)
  const zipLine = buildLine(' ', zipCode, city)

  return buildLine(', ', streetLine, zipLine, country)
}

export const formatAddressLine = (
  address: UserProfileAddress,
  format: string,
): string => {
  const matches = format.match(/{(.*?)}/g)

  if (!matches) {
    return ''
  }

  let formattedAddress = format

  matches.forEach(match => {
    const key = match.replace(/[\{\}]/g, '')

    formattedAddress = formattedAddress.replace(match, address[key] || '')
  })

  return formattedAddress
}

export const formatAddress = (
  address: UserProfileAddress,
  format: AddressFormat,
): readonly string[] => format.map(line => formatAddressLine(address, line))

export const isAddressValid = (address: UserAddress): boolean =>
  Boolean(address.zipCode && address.city && address.country)

export const getAddressModel = (address: Partial<UserAddress>) => ({
  type: address.type ?? AddressTypes.HOME,
  addressLine1: address.addressLine1 ?? '',
  houseNumber: address.houseNumber ?? '',
  houseNumberSuffix: address.houseNumberSuffix ?? '',
  zipCode: address.zipCode ?? '',
  city: address.city ?? '',
  province: address.province ?? '',
  country: address.country ?? '',
})

export const getFranceAddressModel = (address: Partial<UserAddress>) => ({
  ...getAddressModel(address),
  houseNumber: '',
  houseNumberSuffix: '',
})

export const getFormattedAddress = (address: UserAddress): string => {
  const {
    addressLine1,
    houseNumber,
    houseNumberSuffix,
    zipCode,
    city,
    country,
  } = address

  return `${addressLine1}${houseNumber ? ` ${houseNumber}` : ''}${
    houseNumberSuffix ? `${houseNumberSuffix}` : ''
  },${zipCode ? ` ${zipCode}` : ''}${city ? ` ${city}` : ''}${
    country ? `, ${country}` : ''
  }`
}

export const mapAddress = (address: UserAddress): MappedAddress => ({
  street: address.addressLine1,
  houseNumber: address.houseNumber,
  numberSuffix: address.houseNumberSuffix,
  zipcode: address.zipCode,
  city: address.city,
  province: address.province,
  country: address.country,
})

export const mapLocation = (location: UserAddress): MappedLocation => ({
  street: location.addressLine1,
  houseNumber: location.houseNumber || '',
  city: location.city,
  postcode: location.zipCode,
  state: '',
  country: location.country,
  number: location.houseNumber || '',
  suffix: location.houseNumberSuffix || '',
})
