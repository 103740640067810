import { ButtonLink } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import RouteLink from 'mlp-client/src/localization/RouteLink'
import {
  getLanguage,
  getLocale,
  getLocaleParams,
} from 'mlp-client/src/localization/selectors'
import { Login } from 'mlp-client/src/auth/actions'
import { getShowAllFeatures, isFeatureEnabled } from 'mlp-client/src/selectors'
import { AppState } from 'mlp-client/src/types'
import { LanguageCode, Locales } from 'mlp-client/src/localization/enums'
import { isDecomissioning } from 'mlp-client/src/order-status/selectors'

import { Login as LoginType, LoginPageLinks } from './types'
import LoginPageListItem from './LoginPageListItem'

export interface Props {
  dispatch: Dispatch<Action>
  login: LoginType
  showMyLeasePlanLoginLink: boolean
  language: string
  languageCode: LanguageCode
  locale: Locales
}

class LoginPageList extends React.PureComponent<Props> {
  onLogin = () => {
    this.props.dispatch(new Login())
  }

  renderLoginPages = (loginPages: LoginPageLinks, locale: Locales) => {
    const translationFallback =
      'Due to a recent change in ownership, unfortunately MyLeasePlan Service is no longer available.'
    const linkTranslationFallback =
      'Please visit www.drivalia.fi for more information about driving with your leasing car.'
    const defautLabelTranslationFi =
      isDecomissioning(locale) && translationFallback
    const defaultLinkTextTranslationFi =
      isDecomissioning(locale) && linkTranslationFallback

    return loginPages?.map((item, i) => {
      const labelText = item.labelTranslation && (
        <Translation
          id={item.labelTranslation}
          defaultLabel={defautLabelTranslationFi}
        />
      )
      const { languageCode } = this.props
      const linkText = (
        <Translation
          id={item.linkTextTranslation}
          defaultLabel={defaultLinkTextTranslationFi}
        />
      )

      return (
        <LoginPageListItem
          key={`loginPage-${i}`}
          labelText={labelText}
          linkText={linkText}
          action={
            item.shouldAppendLangToUrl ? item.url + languageCode : item.url
          }
        />
      )
    })
  }

  render() {
    const {
      login: { loginPages, showRegistrationLink, hideLoginSubtitle },
      showMyLeasePlanLoginLink,
      locale,
    } = this.props

    return (
      <>
        <Headline component="h3" variant="400" withMarginBottom>
          <Translation id="myLeaseplan.loginPages.title" />
        </Headline>
        {hideLoginSubtitle ? null : (
          <div className="u-margin-top-tiny">
            <Headline component="h4" variant="200">
              <Translation id="myLeaseplan.loginPages.subtitle" />
            </Headline>
          </div>
        )}
        <div className="u-padding-top-small">
          {showMyLeasePlanLoginLink && (
            <>
              <LoginPageListItem
                labelText={
                  <Translation id="myLeaseplan.loginPages.newMyLeasePlan.label" />
                }
                linkText={
                  <Translation id="myLeaseplan.loginPages.newMyLeasePlan.linkText" />
                }
                action={this.onLogin}
              />
              {showRegistrationLink && (
                <Text variant="200">
                  <Translation id="myLeaseplan.requestAccount.loginPage.noAccount" />{' '}
                  <ButtonLink
                    component={RouteLink}
                    size="s"
                    fontWeight="regular"
                    to="myLeaseplan.requestAccount.base"
                  >
                    <Translation id="myLeaseplan.requestAccount.loginPage.link" />
                  </ButtonLink>
                </Text>
              )}
            </>
          )}
          {this.renderLoginPages(loginPages, locale)}
        </div>
      </>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'showMyLeasePlanLoginLink' | 'language' | 'languageCode' | 'locale'
>

export const mapStateToProps = (state: AppState): ReduxProps => {
  const showAllFeatures = getShowAllFeatures(state)
  const showMyLeasePlanLoginLink =
    showAllFeatures ||
    (isFeatureEnabled(state) && isFeatureEnabled(state, 'loginEnabled'))

  let { languageCode } = getLocaleParams(state)

  if (!languageCode) {
    languageCode = LanguageCode.en
  }

  return {
    showMyLeasePlanLoginLink,
    language: getLanguage(state),
    languageCode,
    locale: getLocale(state),
  }
}

export { LoginPageList }
export default connect(mapStateToProps)(LoginPageList)
