import { DisabledDay, Grid, GridItem, Paper, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import moment from 'moment'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { contractEndDateSelector } from 'mlp-client/src/contracts/selectors'
import { Weekday } from 'mlp-client/src/enums'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import {
  ReturnDateMessages,
  StepViewProps,
} from 'mlp-client/src/flows/return/types'
import { renderCurrentEndDate } from 'mlp-client/src/flows/return/utils'
import { DatePickerField } from 'mlp-client/src/form/components/fields/DatePickerFields'
import {
  dateNotBefore,
  dateNotOnWeekends,
  invalidDate,
  required,
} from 'mlp-client/src/form/validations'
import { getCountryHolidays } from 'mlp-client/src/selectors'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'

export interface Props extends StepViewProps {
  contractEndDate: string
  todayDate: string
  publicHolidays: DisabledDay[]
}

export interface FormValues {
  date?: string
}

interface State {
  selectedDate?: string
}

const buildTranslationId = makeTranslationPath(
  'myLeaseplan.return.steps.details',
)

const buildValidationMessageId = makeTranslationPath(
  'myLeaseplan.newSetup.shared.forms.date.validations',
)

const buildTranslationGenericPath = makeTranslationPath(
  'myLeaseplan.endOfLease.notifications',
)

const defaultMessages: Required<ReturnDateMessages> = {
  beforeContractDateMessage: {
    enabled: true,
    messageId: buildTranslationGenericPath('beforeEndDate'),
  },
  afterContractDateMessage: {
    enabled: true,
    messageId: buildTranslationGenericPath('afterEndDate'),
  },
  exactContractDateMessage: {
    enabled: true,
    messageId: buildTranslationGenericPath('exactEndDate'),
  },
}

class DetailsStep extends React.PureComponent<Props, State> {
  state = {
    selectedDate: this.props.flowData.returnDate,
  }

  onDateChange = (selectedDate: string): void => {
    this.setState({ selectedDate })
  }

  onSubmit = (values: FormValues) => {
    const { goToNextStep, setFlowData } = this.props

    setFlowData(
      {
        returnDate: values.date,
      },
      goToNextStep,
    )
  }

  render() {
    const {
      contractEndDate,
      publicHolidays,
      flowData: { returnDate },
      todayDate,
    } = this.props

    const {
      afterContractDateMessage,
      beforeContractDateMessage,
      exactContractDateMessage,
    } = defaultMessages

    const { selectedDate } = this.state
    const beforeDateWarningMessage =
      beforeContractDateMessage.enabled &&
      selectedDate &&
      moment(selectedDate).isBefore(contractEndDate, 'day')
        ? beforeContractDateMessage.messageId
        : undefined
    const afterDateWarningMessage =
      afterContractDateMessage.enabled &&
      selectedDate &&
      moment(selectedDate).isAfter(contractEndDate, 'day')
        ? afterContractDateMessage.messageId
        : undefined
    const exactDateWarningMessage =
      exactContractDateMessage.enabled &&
      selectedDate &&
      moment(selectedDate).isSame(contractEndDate, 'day')
        ? exactContractDateMessage.messageId
        : undefined
    const showWarning = Boolean(
      beforeDateWarningMessage ||
        afterDateWarningMessage ||
        exactDateWarningMessage,
    )

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.endOfLease.terminateContract.title">
          <LocalizationContext.Consumer>
            {({ translate }) => (
              <Form
                onSubmit={this.onSubmit}
                initialValues={{
                  date: returnDate || '',
                }}
                render={({ handleSubmit, hasValidationErrors }) => (
                  <form onSubmit={handleSubmit}>
                    <Spacing mb={2}>
                      <Headline variant="200" withMarginBottom>
                        <Translation id="myLeaseplan.endOfLease.terminateContract.earliestReturnDate" />
                      </Headline>
                      <Paper>
                        <Spacing m={2}>
                          <Grid>
                            <GridItem>
                              <DatePickerField
                                name="date"
                                placeholder={{
                                  id: buildTranslationId('selectDate'),
                                }}
                                onChange={this.onDateChange}
                                validations={[
                                  invalidDate,
                                  dateNotBefore(new Date(todayDate)),
                                  dateNotOnWeekends,
                                  required,
                                ]}
                                disabledDays={[
                                  {
                                    message: translate(
                                      buildValidationMessageId('dateNotInPast'),
                                    ),
                                    before: new Date(todayDate),
                                  },
                                  {
                                    daysOfWeek: [
                                      Weekday.Saturday,
                                      Weekday.Sunday,
                                    ],
                                    message: translate(
                                      buildValidationMessageId(
                                        'notDuringWeekends',
                                      ),
                                    ),
                                  },
                                  ...publicHolidays,
                                ]}
                              />
                            </GridItem>
                            <GridItem>
                              <Text component="p" variant="200">
                                <Translation
                                  id={buildTranslationId('currentEndDate')}
                                />
                              </Text>
                              <Text
                                bold
                                component="p"
                                variant="200"
                                data-test-id="contractEndDate"
                              >
                                {renderCurrentEndDate(contractEndDate)}
                              </Text>
                            </GridItem>
                          </Grid>
                        </Spacing>
                        {showWarning && (
                          <Spacing m={2}>
                            <hr />
                            <Spacing m={1}>
                              <Text>
                                {Boolean(afterDateWarningMessage) && (
                                  <Translation
                                    data-e2e-id="dateAfterEndDate"
                                    id={afterDateWarningMessage}
                                  />
                                )}
                                {Boolean(beforeDateWarningMessage) && (
                                  <Translation
                                    data-e2e-id="dateBeforeEndDate"
                                    id={beforeDateWarningMessage}
                                  />
                                )}
                                {Boolean(exactDateWarningMessage) && (
                                  <Translation
                                    data-e2e-id="exactEndDate"
                                    id={exactDateWarningMessage}
                                  />
                                )}
                              </Text>
                            </Spacing>
                          </Spacing>
                        )}
                      </Paper>
                    </Spacing>
                    <NextStepButton
                      onClick={handleSubmit}
                      disabled={hasValidationErrors}
                    />
                  </form>
                )}
              />
            )}
          </LocalizationContext.Consumer>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'todayDate' | 'contractEndDate' | 'publicHolidays'
>

const mapStateToProps = (state: AppState): ReduxProps => ({
  todayDate: moment().startOf('day').format(),
  contractEndDate: contractEndDateSelector(state),
  publicHolidays: getCountryHolidays(state),
})

export { DetailsStep }
export default connect(mapStateToProps)(DetailsStep)
