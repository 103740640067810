import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Form, FormRenderProps } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'

import Translation from 'mlp-client/src/localization/Translation'
import {
  DateTimeTile,
  GeneralListTile,
  MileageTile,
} from 'mlp-client/src/components/confirm-step-tiles'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import ActiveVehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/ActiveVehicleModelLicense'
import { StepNames } from 'mlp-client/src/flows/buy/enums'
import { StepViewProps, FlowData } from 'mlp-client/src/flows/buy/types'
import { getTranslatedDistanceUnit } from 'mlp-client/src/selectors'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { FormSubmission } from 'mlp-client/src/form/types'
import SubmitStatus from 'mlp-client/src/form/components/fields/submit-status/SubmitStatus'
import SubmitButton from 'mlp-client/src/form/components/fields/submit-button/SubmitButton'
import { SubmitBuyRequest } from 'mlp-client/src/flows/buy/actions'

const buildTranslationId = makeTranslationPath('myLeaseplan.buy.steps.confirm')

export interface StepConfig {
  hideDateTile?: boolean
}

export interface Props
  extends StepViewProps<StepConfig>,
    FormSubmission<FlowData> {
  distanceUnitLabel: string
}

type DefaultProps = Pick<StepConfig, 'hideDateTile'>

type InternalProps = Props & DefaultProps

class ConfirmBuyStep extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    hideDateTile: false,
  }

  handleEditClick = (step: StepNames) => () => this.props.goToStep(step)

  render() {
    const {
      flowData: { mileage, buyDate, photos },
      config: { hideDateTile },
      distanceUnitLabel,
      onSubmit,
    } = this.props

    return (
      <Form<FlowData> onSubmit={onSubmit} initialValues={this.props.flowData}>
        {({ handleSubmit, submitting, submitFailed }: FormRenderProps) => (
          <form>
            <StepLayout isLoading={submitting} hideSpinner>
              <TwoColumnFlowStep titleLabel={buildTranslationId('title')}>
                <Spacing mb={4}>
                  <GeneralListTile
                    dataE2eId="contract"
                    title={
                      <Translation id={buildTranslationId('contract.title')} />
                    }
                    data-test-id="contractTile"
                  >
                    <ActiveVehicleModelLicense />
                  </GeneralListTile>
                  {!hideDateTile && (
                    <DateTimeTile
                      dateTime={buyDate}
                      dateFormat="dddd, LL"
                      title={
                        <Translation id={buildTranslationId('date.title')} />
                      }
                      onClick={this.handleEditClick(StepNames.DATE)}
                    />
                  )}
                  <MileageTile
                    mileage={mileage}
                    photos={photos}
                    title={
                      <Translation id={buildTranslationId('mileage.title')} />
                    }
                    distanceUnitLabel={distanceUnitLabel}
                    onClick={this.handleEditClick(StepNames.UPDATE_MILEAGE)}
                  />
                </Spacing>
                <Spacing mb={2}>
                  <SubmitStatus submitting={submitting} failed={submitFailed}>
                    <SubmitButton
                      stretch={true}
                      onClick={handleSubmit}
                      dataE2eId="buttonConfirmStep"
                      label="myLeaseplan.buy.steps.confirm.nextButton"
                      disabled={submitting}
                    />
                  </SubmitStatus>
                </Spacing>
              </TwoColumnFlowStep>
            </StepLayout>
          </form>
        )}
      </Form>
    )
  }
}

export type ReduxProps = Pick<Props, 'distanceUnitLabel'>
type DispatchProps = Pick<Props, 'onSubmit'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  distanceUnitLabel: getTranslatedDistanceUnit(state),
})

export const mapDispatchToProps = (
  dispatch: Dispatch,
  props: OwnProps,
): DispatchProps => ({
  onSubmit: (flowData, _formApi, formOnComplete): void => {
    const { goToNextStep } = props

    dispatch(
      new SubmitBuyRequest({
        flowData,
        onComplete: isError => {
          if (!isError) {
            goToNextStep()
            formOnComplete()
          } else {
            formOnComplete({ isError })
          }
        },
      }),
    )
  },
})

export { ConfirmBuyStep }
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBuyStep)
