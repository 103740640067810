import { Button, Grid, GridItem, Hide, Paper, Spacing } from '@leaseplan/ui'
import { Text, Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { RouteLink } from 'mlp-client/src/localization/RouteLink'
import TextCenter from 'mlp-client/src/components/styled/TextCenter'

export interface Props {
  title: React.ReactNode | string
  subTitle: React.ReactNode | string
  statusInfo: React.ReactNode | string
  icon: React.ReactNode
  to: string
  buttonLabel: string
}

class Banner extends React.PureComponent<Props> {
  getOrderInfo = () => {
    const { title, subTitle, statusInfo } = this.props

    return (
      <>
        {typeof statusInfo === 'string' ? (
          <Text bold>
            <Translation id={statusInfo} />
          </Text>
        ) : (
          statusInfo
        )}
        {typeof title === 'string' ? (
          <Headline variant="300" component="h4">
            <Translation id={title} />
          </Headline>
        ) : (
          title
        )}
        {typeof subTitle === 'string' ? <Text>{subTitle}</Text> : subTitle}
      </>
    )
  }

  gridItems() {
    const { to, buttonLabel, icon } = this.props

    return (
      <>
        <GridItem>{icon}</GridItem>
        <GridItem span="grow">
          <Grid>
            <GridItem>
              <Hide until="mobileLandscape">{this.getOrderInfo()}</Hide>
              <Hide from="mobileLandscape">
                <TextCenter>{this.getOrderInfo()}</TextCenter>
              </Hide>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem>
          <Hide from="mobileLandscape">
            <Spacing mt={2} />
          </Hide>
          <Button
            data-e2e-id="dashboard_order-status-button"
            variant="secondary"
            component={RouteLink}
            to={to}
          >
            <Translation id={buttonLabel} />
          </Button>
        </GridItem>
      </>
    )
  }

  render() {
    return (
      <Paper>
        <Hide until="mobileLandscape">
          <Spacing pv={2} pl={1} pr={4}>
            <Grid alignItems="center" verticalGutter={false}>
              {this.gridItems()}
            </Grid>
          </Spacing>
        </Hide>
        <Hide from="mobileLandscape">
          <Spacing ph={2} pt={1} pb={3}>
            <Grid direction="column" alignItems="center" verticalGutter={false}>
              {this.gridItems()}
            </Grid>
          </Spacing>
        </Hide>
      </Paper>
    )
  }
}

export { Banner }
export default Banner
