/*

MLP-specific copy of: src/app/features/shared/components/topnavigation/components/desktop/DropdownPanel.tsx

Differences with original:
- doesn't use WithAnimationFrame HOC as it's absolutely unnecessary. It does however update
  its horizontal position (if necessary) upon being opened.
*/

import Classnames from 'classnames'
import React, { useLayoutEffect, useRef } from 'react'

interface Props {
  open: boolean
}

const DropdownPanel: React.FC<Props> = ({ open, children }) => {
  const panelElementRef = useRef<HTMLDivElement>()

  // adjust panel horizontal position if its right edge is beyond the screen
  useLayoutEffect(() => {
    if (!open) {
      return
    }

    const { current: panelElement } = panelElementRef
    const { right } = panelElement.getBoundingClientRect()
    const offsetLeft = Math.min(0, window.innerWidth - right)

    if (offsetLeft < 0) {
      panelElement.style.transform = `translate(${offsetLeft}px)`
    }
  }, [open])

  return (
    <div
      className={Classnames('dropdown-panel__wrap', {
        'dropdown-panel__wrap--hidden': !open,
      })}
    >
      <div
        ref={panelElementRef}
        className={Classnames('dropdown-panel', {
          'dropdown-panel--hidden': !open,
        })}
      >
        {children}
      </div>
    </div>
  )
}

DropdownPanel.displayName = 'DropdownPanel'

export { DropdownPanel }
export default DropdownPanel
