import Dashboard, {
  Config,
} from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import {
  CarContractIcon,
  DamageIcon,
  AccidentIcon,
  DriversCheck,
  FuelCardIcon,
  GlassIcon,
  MaintenanceIcon,
  ReturnIcon,
  TyresIcon,
  EvChargingIcon,
  EcoDrivingIcon,
  VehicleIcon,
} from 'mlp-client/src/dashboard/components/icons'
import { AccidentManagementModalTrigger } from 'mlp-client/src/dashboard/components/AccidentManagementModalTrigger'
import { RolandAppModalTrigger } from 'mlp-client/src/dashboard/components/RolandAppModalTrigger'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { ReturnTile } from 'mlp-client/src/dashboard/components/tiles'
import { filterTilesByServiceNumbers } from 'mlp-client/src/dashboard/utils'
import { TileOptions } from 'mlp-client/src/dashboard/type'

export const dashboardConfig: MyLeaseplanFeature<Config> = {
  enabled: true,
  view: Dashboard,
  configuration: {
    showFullNameSalutation: true,
    numberOfDaysToReturn: 183,
    hideServicesTitle: true,
    hideServicesSubtitle: true,
    showServicesWarning: true,
    servicesConfig: ({ services = [] }) => {
      const config: TileOptions = [
        {
          modalTrigger: RolandAppModalTrigger,
          hasModal: true,
          title: 'myLeaseplan.app.roland.dashboard.title',
          summary: 'myLeaseplan.app.roland.dashboard.summary',
          to: 'https://leaseplan.autohilfe.eu/#/LLLLLPPPPPDDDDD',
          icon: DamageIcon,
          name: 'rolandApp',
          target: '_blank',
          serviceNumbersIncluded: ['1300'],
        },
        {
          modalTrigger: AccidentManagementModalTrigger,
          hasModal: true,
          title: 'myLeaseplan.accidentManagement.dashboard.title',
          summary: 'myLeaseplan.accidentManagement.dashboard.summary',
          icon: AccidentIcon,
          name: 'accidentManagement',
          target: '_blank',
          serviceNumbersIncluded: ['1150', '1151', '1152', '1153', '1180'],
        },
        {
          title: 'myLeaseplan.serviceRequest.maintenance.dashboard.title',
          summary: 'myLeaseplan.serviceRequest.maintenance.dashboard.summary',
          to: 'https://bookingtool.leaseplan.de/web-app-bookingtool',
          icon: MaintenanceIcon,
          name: 'maintenance',
          target: '_blank',
        },
        {
          title: 'myLeaseplan.glassRepair.dashboard.title',
          summary: 'myLeaseplan.glassRepair.dashboard.summary',
          localizedLinks: {
            de:
              'https://www.leaseplan.de/ihr-leaseplan-fahrzeug/glasschaden?app=1',
            en:
              'https://www.leaseplan.de/en/your-leaseplan-vehicle/glass-repair?app=1',
          },
          icon: GlassIcon,
          name: 'glassRepair',
          target: '_blank',
          serviceNumbersIncluded: ['1150', '1151', '1152', '1153'],
        },
        {
          title: 'myLeaseplan.tyres.dashboard.title',
          summary: 'myLeaseplan.tyres.dashboard.summary',
          localizedLinks: {
            de: 'https://www.leaseplan.de/ihr-leaseplan-fahrzeug/reifen?app=1',
            en:
              'https://www.leaseplan.de/en/your-leaseplan-vehicle/tryes?app=1',
          },
          icon: TyresIcon,
          name: 'tyres',
          target: '_blank',
          serviceNumbersIncluded: ['300', '201', '202', '2201', '2202'],
        },
        {
          title: 'myLeaseplan.fuelCard.dashboard.title',
          summary: 'myLeaseplan.fuelCard.dashboard.summary',
          localizedLinks: {
            de:
              'https://www.leaseplan.com/de-de/fahrerservices/tankkarte-ladekarte',
            en:
              'https://www.leaseplan.com/en-de/driver-services/fuelcard-chargingcard',
          },
          icon: FuelCardIcon,
          name: 'fuelCard',
          target: '_blank',
          serviceNumbersIncluded: ['420', '430'],
        },
        {
          title: 'myLeaseplan.app.evCharging.dashboard.title',
          summary: 'myLeaseplan.app.evCharging.dashboard.summary',
          to: 'https://www.goingelectric.de/stromtankstellen/',
          icon: EvChargingIcon,
          name: 'evCharging',
          target: '_blank',
        },
        {
          title: 'myLeaseplan.rentalCar.dashboard.title',
          summary: 'myLeaseplan.rentalCar.dashboard.summary',
          localizedLinks: {
            de: 'https://www.leaseplan.com/de-de/fahrerservices/mietwagen',
            en: 'https://www.leaseplan.com/en-de/driver-services/rental-car',
          },
          icon: VehicleIcon,
          name: 'rentalCar',
          target: '_blank',
          serviceNumbersIncluded: ['497', '500', '511', '560', '570', '562'],
        },
        {
          title: 'myLeaseplan.trafficLaw.dashboard.title',
          summary: 'myLeaseplan.trafficLaw.dashboard.summary',
          localizedLinks: {
            de:
              'https://www.leaseplan.de/ihr-leaseplan-fahrzeug/rechtsberatung?app=1',
            en:
              'https://www.leaseplan.de/en/your-leaseplan-vehicle/legal-advice?app=1',
          },
          icon: CarContractIcon,
          name: 'trafficLaw',
          target: '_blank',
          serviceNumbersExcluded: ['1150', '1151', '1152', '1153'],
        },
        {
          title: 'myLeaseplan.app.driversCheck.dashboard.title',
          summary: 'myLeaseplan.app.driversCheck.dashboard.summary',
          localizedLinks: {
            de:
              'https://www.leaseplan.com/de-de/fahrerservices/drivers-check-app/',
            en:
              'https://www.leaseplan.com/en-de/driver-services/drivers-check-app/',
          },
          icon: DriversCheck,
          name: 'driversCheck',
          target: '_blank',
          serviceNumbersIncluded: ['863'],
        },
        {
          title: 'myLeaseplan.return.dashboard.form.title',
          summary: 'myLeaseplan.return.dashboard.form.summary',
          localizedLinks: {
            de:
              'https://www.leaseplan.com/de-de/mein-leasingauto/ihr-auto-kaufen-oder-zurueckgeben/',
            en:
              'https://www.leaseplan.com/en-de/my-lease-car/return-or-buy-car/',
          },
          icon: ReturnIcon,
          name: 'return',
          target: '_blank',
          view: ReturnTile,
        },
        {
          title: 'myLeaseplan.ecoDriving.title',
          summary: 'myLeaseplan.ecoDriving.summary',
          localizedLinks: {
            de:
              'https://www.leaseplan.com/de-de/fahrerservices/oekologisches-fahren/',
            en: 'https://www.leaseplan.com/en-de/driver-services/eco-driving/',
          },
          icon: EcoDrivingIcon,
          name: 'ecoDriving',
          target: '_blank',
        },
      ]

      return config.filter(filterTilesByServiceNumbers(services))
    },
    quickActionsConfig: () => [],
  },
}
