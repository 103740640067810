import React from 'react'
import { connect } from 'react-redux'

import { getContract } from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import { getLastKnownMileage } from 'mlp-client/src/contracts/utils'
import MileageUpdate from 'mlp-client/src/components/mileage-update-modal/MileageUpdate'
import { AppState } from 'mlp-client/src/types'

export interface Props {
  activeContract: Contract
}

class MileageUpdateWithContract extends React.PureComponent<Props> {
  render() {
    const { activeContract } = this.props
    const lastKnownMileage = getLastKnownMileage(activeContract)

    return (
      <MileageUpdate
        contractId={activeContract.id}
        lastKnownMileage={lastKnownMileage}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'activeContract'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  activeContract: getContract(state),
})

export { MileageUpdateWithContract }
export default connect(mapStateToProps)(MileageUpdateWithContract)
