import React, { PureComponent } from 'react'

import { HeaderCellStyled } from './Table.styled'

interface Props {
  dataE2EId: string
}

export class HeaderCell extends PureComponent<Props> {
  render() {
    const { dataE2EId } = this.props

    return (
      <HeaderCellStyled data-e2e-id={dataE2EId}>
        {this.props.children}
      </HeaderCellStyled>
    )
  }
}

export default HeaderCell
