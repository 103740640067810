import { List, ListItem, Paper, Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { Locales } from 'mlp-client/src/localization/enums'
import { getUserLocale } from 'mlp-client/src/localization/selectors'
import Translation from 'mlp-client/src/localization/Translation'
import DownloadIcon from 'mlp-client/src/flows/return/components/return-notes/DownloadIcon.styled'
import { ReturnNote } from 'mlp-client/src/flows/return/types'
import { getReturnNotes } from 'mlp-client/src/flows/return/utils'
import { LoadPageContent } from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/actions'
import {
  getDocuments,
  pageContentEndpoint,
} from 'mlp-client/src/flows/vehicle-documents/components/pages/vehicle-documents-page/selectors'
import { AppState } from 'mlp-client/src/types'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'
import { StyledLink } from 'mlp-client/src/components/link/StyledLink'

export interface Props {
  notes: readonly ReturnNote[]
  fairwear?: string
  checklist?: string
  checkReturnList?: string
  locale: Locales
  loadDocumentLink(locale: Locales): void
  hasDocuments?: boolean
}

class ReturnNotes extends React.PureComponent<Props> {
  componentDidMount() {
    const { loadDocumentLink, locale } = this.props

    loadDocumentLink(locale)
  }

  renderItem = (item: ReturnNote) => {
    const { downloadUrl, linkText, text, linkDataE2eId } = item

    return (
      <ListItem key={text}>
        <Text component="span">
          <Translation id={text} />
          {(linkText || downloadUrl) && (
            <StyledLink
              data-e2e-id={linkDataE2eId}
              href={downloadUrl}
              target="_blank"
              download={true}
            >
              <Translation id={linkText} />
              <DownloadIcon
                color={ColorEnumSelector('bloodOrange')}
                height={16}
                width={16}
                title="myLeaseplan.shared.icons.chevronDownload.title"
              />
            </StyledLink>
          )}
        </Text>
      </ListItem>
    )
  }

  render() {
    const { notes } = this.props

    return (
      <Paper>
        <Spacing pv={3} ph={2}>
          <List>{notes.map(this.renderItem)}</List>
        </Spacing>
      </Paper>
    )
  }
}

type ReduxProps = Pick<Props, 'hasDocuments' | 'notes' | 'locale'>
type DispatchProps = Pick<Props, 'loadDocumentLink'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): ReduxProps => {
  const documents = getDocuments(state)
  const { fairwear, checklist, checkReturnList } = ownProps

  return {
    hasDocuments: Boolean(documents.length),
    notes: getReturnNotes(documents, { fairwear, checklist, checkReturnList }),
    locale: getUserLocale(state),
  }
}

export const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  ownProps: Props,
): DispatchProps => ({
  loadDocumentLink: (locale: Locales): void => {
    if (!ownProps.hasDocuments) {
      dispatch(
        new LoadPageContent({
          locale,
          endpoint: pageContentEndpoint,
        }),
      )
    }
  },
})

export { ReturnNotes }

export default connect(mapStateToProps, mapDispatchToProps)(ReturnNotes)
