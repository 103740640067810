import { ReducersMapObject } from 'redux'

import { default as damageReport } from 'mlp-client/src/flows/damage-report/reducers'
import { default as analytics } from 'mlp-client/src/analytics/reducer'
import { default as dictionary } from 'mlp-client/src/dictionary/reducer'

import { default as auth } from './auth/reducers'
import { default as content } from './content/reducers'
import {
  default as contracts,
  registrationCertificateReducer,
} from './contracts/reducers'
import { default as quotes } from './quotes/reducers'
import { default as FAQ } from './FAQ/reducers'
import { default as fuelCard } from './flows/fuel-card/reducers'
import { default as fuelHistory } from './flows/fuel-history/reducers'
import { default as glassRepair } from './flows/glass-repair/reducers'
import { default as vehicleDocuments } from './flows/vehicle-documents/components/pages/vehicle-documents-page/reducers'
import { default as maintenance } from './flows/maintenance/reducers'
import { default as form } from './form/components/fields/file-upload-field/reducers'
import { default as timelineDetails } from './timeline-details/reducers'
import { default as timeline } from './timeline/reducers'
import { default as user } from './user/reducers'
import { default as staticPageSuppliers } from './flows/components/pages/supplier-map-page/reducers'
import { default as init } from './init/reducers'
import { baseConfig as countries } from './features-configuration/index'
import { default as profile } from './profile/reducers'
import { default as localization } from './localization/reducers'
import { AppState } from './types'
import { default as driverDetails } from './attach-car/reducer'
import { default as downloadableDocuments } from './downloadable-documents/reducers'

export const features: ReducersMapObject<Omit<AppState, 'router'>> = {
  auth,
  contracts,
  quotes,
  FAQ,
  fuelCard,
  fuelHistory,
  maintenance,
  timeline,
  timelineDetails,
  user,
  vehicleDocuments,
  glassRepair,
  damageReport,
  content,
  form,
  staticPageSuppliers,
  init,
  countries: (state = countries) => state,
  profile,
  localization,
  analytics,
  dictionary,
  driverDetails,
  registrationCertificateDocs: registrationCertificateReducer,
  downloadableDocuments,
}

export default features
