import filter from 'lodash/filter'
import indexOf from 'lodash/indexOf'

import { Contract } from 'mlp-client/src/contracts/types'
import { TileOption, TileOptions } from 'mlp-client/src/dashboard/type'
import Dashboard, {
  Config,
} from 'mlp-client/src/dashboard/components/configurable-dashboard/ConfigurableDashboard'
import {
  DamageIcon,
  DocumentsIcon,
  GlassIcon,
  MaintenanceIcon,
  TyresIcon,
  FuelCardIcon,
  ReturnIcon,
  MacifIcon,
  MileageIcon,
  FinesIcon,
  FuelHistoryIcon,
  CashClaimIcon,
} from 'mlp-client/src/dashboard/components/icons'
import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import {
  FuelCardTile,
  MacifTile,
  MaintenanceTile,
  ReturnTile,
} from 'mlp-client/src/dashboard/components/tiles'
import { RoadSideAssistanceWithModals } from 'mlp-client/src/components/road-side-assistance/components'
import { MacifModalTrigger } from 'mlp-client/src/dashboard/components/MacifModalTrigger'
import { MileageUpdateModalTrigger } from 'mlp-client/src/dashboard/components/MileageUpdateModalTrigger'

import { getPreviewPreatures } from './utils'

export const dashboardConfig: MyLeaseplanFeature<Config> = {
  enabled: true,
  view: Dashboard,
  configuration: {
    alwaysDisplayFuelCardTile: true,
    numberOfDaysToReturn: 60,
    showNolsWarning: true,
    servicesConfig: (contract: Contract) => {
      const features: TileOptions = [
        {
          view: MaintenanceTile,
          title: 'myLeaseplan.serviceRequest.maintenance.dashboard.title',
          summary: 'myLeaseplan.serviceRequest.maintenance.dashboard.summary',
          disabledTitle:
            'myLeaseplan.serviceRequest.maintenance.dashboard.notAvailableTitle',
          disabledSummary:
            'myLeaseplan.serviceRequest.maintenance.dashboard.notAvailableSummary',
          to: 'myLeaseplan.maintenance.base',
          icon: MaintenanceIcon,
          name: 'maintenance',
          shouldShow: contract?.maintenanceModule,
        },
        {
          name: 'damageReport',
          title: 'myLeaseplan.damageReport.dashboard.title',
          summary: 'myLeaseplan.damageReport.dashboard.summary',
          icon: DamageIcon,
          to: 'myLeaseplan.damageReport.base',
          shouldShow: contract?.damageModule,
        },
        {
          name: 'tyres',
          title: 'myLeaseplan.tyres.dashboard.title',
          summary: 'myLeaseplan.tyres.dashboard.summary',
          to: 'myLeaseplan.tyres.base',
          icon: TyresIcon,
          shouldShow: contract?.tyres?.contractTyresModule,
        },
        {
          title: 'myLeaseplan.glassRepair.dashboard.title',
          summary: 'myLeaseplan.glassRepair.dashboard.summary',
          to: 'myLeaseplan.glassRepair.base',
          icon: GlassIcon,
          name: 'glassRepair',
          shouldShow: contract?.damageModule,
        },
      ]

      return features.filter(f => f.shouldShow)
    },
    quickActionsConfig: (contract: Contract) => {
      const features: TileOptions = [
        {
          modalTrigger: MileageUpdateModalTrigger,
          hasModal: true,
          title: 'myLeaseplan.mileageUpdate.dashboard.title',
          summary: 'myLeaseplan.mileageUpdate.dashboard.summary',
          icon: MileageIcon,
          name: 'mileageUpdate',
          to: 'myLeaseplan.mileageUpdate.base',
          shouldShow: true,
        },
        {
          view: ReturnTile,
          summary: 'myLeaseplan.return.dashboard.summary',
          to: 'myLeaseplan.endOfLease.base',
          icon: ReturnIcon,
          name: 'return',
          shouldShow: true,
        },
        {
          view: FuelCardTile,
          name: 'fuelCard',
          title: 'myLeaseplan.fuelCard.dashboard.title',
          summary: 'myLeaseplan.fuelCard.dashboard.summary',
          to: 'myLeaseplan.fuelCard.base',
          icon: FuelCardIcon,
          shouldShow: contract?.isFuelCardsAvailable,
        },
        {
          name: 'vehicleDocuments',
          title: 'myLeaseplan.vehicleDocuments.dashboard.title',
          summary: 'myLeaseplan.vehicleDocuments.dashboard.summary',
          to: 'myLeaseplan.vehicleDocuments.selectDocument',
          icon: DocumentsIcon,
          shouldShow: true,
        },
        {
          name: 'macif',
          title: 'myLeaseplan.macif.tile.title',
          summary: 'myLeaseplan.macif.tile.subtitle',
          icon: MacifIcon,
          hasModal: true,
          modalTrigger: MacifModalTrigger,
          view: MacifTile,
          shouldShow: true,
        },
        ...addPreviewModules(),
      ]

      return features.filter(f => f.shouldShow)
    },
    roadsideAssistanceConfig: (contract: Contract) => ({
      enabled: contract?.roadsideAssistanceModule,
      view: RoadSideAssistanceWithModals,
      configuration: {},
    }),
  },
}

export function addPreviewModules(): TileOptions {
  const previewFeatures = getPreviewPreatures()

  return filter(
    [
      {
        name: 'fines',
        title: 'myLeaseplan.fines.dashboard.title',
        summary: 'myLeaseplan.fines.dashboard.summary',
        to: 'myLeaseplan.fines.base',
        icon: FinesIcon,
        shouldShow: true,
      },

      {
        name: 'fuelHistory',
        title: 'myLeaseplan.fuelHistory.dashboard.title',
        summary: 'myLeaseplan.fuelHistory.dashboard.summary',
        to: 'myLeaseplan.fuelHistory.base',
        icon: FuelHistoryIcon,
        shouldShow: true,
      },
      {
        name: 'cashClaims',
        title: 'myLeaseplan.cashClaim.dashboard.title',
        summary: 'myLeaseplan.cashClaim.dashboard.summary',
        to: 'myLeaseplan.cashClaim.base',
        icon: CashClaimIcon,
        shouldShow: true,
      },
    ] as TileOption[],
    feature => indexOf(previewFeatures, feature.name) === -1,
  )
}
