import React from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import { Action, Dispatch } from 'redux'

import { ProcessLogoutResponse } from 'mlp-client/src/auth/actions'
import { getLoading, getNextRoute } from 'mlp-client/src/auth/selectors'
import Loader from 'mlp-client/src/components/loader/Loader'
import { AppState } from 'mlp-client/src/types'

interface Props {
  dispatch: Dispatch<Action>
  loading: boolean
  nextRoute?: string
}

class LogoutRedirect extends React.PureComponent<Props> {
  componentDidMount() {
    const { dispatch, nextRoute } = this.props

    dispatch(new ProcessLogoutResponse({ nextRoute }))
  }

  render() {
    return (
      <Loader loading={this.props.loading}>
        <Helmet meta={[{ name: 'robots', content: 'noindex,nofollow' }]} />
      </Loader>
    )
  }
}

type ReduxProps = Pick<Props, 'loading' | 'nextRoute'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  loading: getLoading(state),
  nextRoute: getNextRoute(state),
})

export { LogoutRedirect }
export default connect(mapStateToProps)(LogoutRedirect)
