import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class FuelHistoryIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M14.5 55h29v-4h-29v4zM59 55a1.5 1.5 0 1 1 0 3H6a1.5 1.5 0 1 1 0-3h5.5V10.264A5.27 5.27 0 0 1 16.764 5h24.472a5.27 5.27 0 0 1 5.264 5.264V34.5a1.5 1.5 0 1 1-3 0V10.264A2.267 2.267 0 0 0 41.236 8H16.764a2.267 2.267 0 0 0-2.264 2.264V48h29v-3.102a1.5 1.5 0 1 1 3 0V55H59zM21.5 27h15V15h-15v12zm-.676 3a2.326 2.326 0 0 1-2.324-2.324V14.318A2.32 2.32 0 0 1 20.817 12h16.366a2.32 2.32 0 0 1 2.317 2.318v13.358A2.326 2.326 0 0 1 37.176 30H20.824zM56.5 22c-.551 0-1 .45-1 1 0 .551.449 1 1 1 .551 0 1-.449 1-1 0-.55-.449-1-1-1zM18.221 39.898a1.5 1.5 0 0 1 1.5-1.5h26.907c2.587 0 4.694 2 4.914 4.53.026.111.043.225.043.343a1.957 1.957 0 0 0 2.335 1.923c.901-.162 1.58-1.062 1.58-2.092V26.858c-1.72-.447-3-2-3-3.858 0-1.403.729-2.635 1.826-3.349a8.066 8.066 0 0 0-2.478-3.068 1.5 1.5 0 1 1 1.792-2.406 11.072 11.072 0 0 1 3.722 4.922A4.004 4.004 0 0 1 60.5 23c0 1.474-.81 2.75-2 3.444v16.658c0 2.5-1.702 4.622-4.046 5.044a5.052 5.052 0 0 1-.905.082 4.941 4.941 0 0 1-4.953-4.773c-.003-.034-.011-.066-.011-.1a1.959 1.959 0 0 0-1.957-1.957H19.721a1.5 1.5 0 0 1-1.5-1.5z"
        />
      </svg>
    )
  }
}

export default FuelHistoryIcon
