import { IconProps } from '@leaseplan/ui'
import React, { FC } from 'react'

import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

import { defaultIconProps } from '../options'

const DamageIconLeasys: FC<IconProps> = props => {
  DamageIconLeasys.defaultProps = defaultIconProps
  const { height, width, color } = props

  return (
    <svg
      version="1.1"
      id="Livello_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width}
      height={height}
    >
      <g fill={ColorEnumSelector(color)}>
        <g>
          <path
            d="M53.28,38.57c-1.18,0-5.03,0.33-5.03-1.09c0-1.42,3.85-4.06,5.03-4.06c1.18,0,2.14,1.15,2.14,2.58
					C55.41,37.42,54.46,38.57,53.28,38.57 M54.33,43.63c-0.05,0.16-0.12,0.32-0.22,0.48c-0.1,0.16-0.23,0.32-0.4,0.48
					c-0.33,0.3-0.8,0.59-1.47,0.83l-2.41,0h-2.67l2.74-3.67h4.11C54.01,41.74,54.63,42.64,54.33,43.63 M44.09,41.03H31.22
					c-1.34,0-2.43-1.93-2.43-4.3h17.73C46.52,39.1,45.43,41.03,44.09,41.03 M43.57,45.41H31.74c-1.23,0-2.24-1.17-2.24-2.61h16.3
					C45.8,44.24,44.8,45.41,43.57,45.41 M26.3,45.41h-0.81l-2.41,0c-0.66-0.24-1.14-0.53-1.47-0.83c-0.17-0.15-0.3-0.31-0.4-0.47
					c-0.1-0.16-0.18-0.32-0.22-0.48c-0.3-0.99,0.32-1.9,0.32-1.9h4.11l2.74,3.67H26.3z M19.9,36c0-1.42,0.95-2.58,2.13-2.58
					c1.18,0,5.03,2.64,5.03,4.06c0,1.42-3.85,1.09-5.03,1.09C20.85,38.57,19.9,37.42,19.9,36 M29.07,24.6
					c0.49-0.48,1.35-0.84,1.78-0.84h13.62c0.43,0,1.29,0.36,1.78,0.84l4.58,5.18H24.48L29.07,24.6z M54.59,30.81
					c-0.03-0.04-0.05-0.07-0.08-0.1l-1.35-1.52c1.12-0.3,2.33-1.4,2.33-2.52c0-1.32-1.56-1.78-2.89-1.78c-1.39,0-1.55,1.11-1.57,1.9
					l-3.49-3.93c-0.71-0.82-2.06-1.47-3.06-1.47H30.85c-1,0-2.35,0.65-3.06,1.47l-3.49,3.93c-0.02-0.79-0.18-1.9-1.57-1.9
					c-1.34,0-2.9,0.46-2.9,1.78c0,1.12,1.21,2.22,2.33,2.52L20.8,30.7c-0.03,0.03-0.05,0.07-0.08,0.1c-1.45,1.45-2.4,4.12-2.4,6.07
					v5.78c0,1.4,0.47,2.67,1.24,3.62v2.24c0,1.31,0.94,2.38,2.1,2.38h2.54c1.16,0,2.1-1.06,2.1-2.38v-0.57h22.72v0.57
					c0,1.31,0.94,2.38,2.1,2.38h2.53c1.16,0,2.1-1.06,2.1-2.38v-2.24c0.77-0.95,1.24-2.22,1.24-3.62v-5.78
					C56.98,34.93,56.04,32.25,54.59,30.81"
          />
        </g>
        <polygon
          points="17.6,18.25 11.36,12.87 12.24,21.1 5.38,23.84 10.89,27.97 8.85,35.81 18.8,30.33 17.85,28.59 
				11.92,31.86 13.2,27.15 9.41,24.36 14.45,22.42 13.9,17.67 17.7,20.95 21.74,16.91 21.18,21.61 23.15,21.84 24.39,11.47 			"
        />
      </g>
    </svg>
  )
}

export default DamageIconLeasys
