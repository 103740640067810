import { Card, CardContent, Spacing } from '@leaseplan/ui'
import React from 'react'

import { Markdown } from 'mlp-client/src/flows/components/markdown/Markdown'

export const MainContent: React.FC<{ content: string; e2eId: string }> = ({
  content,
  e2eId,
}) =>
  content ? (
    <>
      <Card data-e2e-id={e2eId}>
        <CardContent>
          <Markdown content={content} />
        </CardContent>
      </Card>
      <Spacing mb={2} />
    </>
  ) : null
