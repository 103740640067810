import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { Contract } from 'mlp-client/src/contracts/types'
import { getOrderById } from 'mlp-client/src/order-status/selectors'
import { formatDate } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'

export interface Props {
  orderContract: Contract
  orderId?: string
  children?: React.ReactNode
}

class ReadyToDelivery extends React.PureComponent<Props> {
  render() {
    const { orderContract } = this.props

    if (!orderContract) {
      return null
    }

    const { pickupAndDelivery } = orderContract

    if (pickupAndDelivery) {
      return (
        <Translation
          replace={{ pickupAndDelivery: formatDate(pickupAndDelivery) }}
          id="myLeaseplan.genericNotifications.statusCarService.carReadyDeliveryInfo.subtitleWithpuckupDate"
        />
      )
    }

    return (
      <Translation id="myLeaseplan.genericNotifications.statusCarService.carReadyDeliveryInfo.subtitle" />
    )
  }
}
type ReduxProps = Pick<Props, 'orderContract'>
type OwnProps = Omit<Props, keyof ReduxProps>

export const mapStateToProps = (
  state: AppState,
  props: OwnProps,
): ReduxProps => ({
  orderContract: getOrderById(state)(props.orderId),
})

export { ReadyToDelivery }
export default connect(mapStateToProps)(ReadyToDelivery)
