import { Color, ColorName } from '@leaseplan/ui'
import { colorMixins } from '@velocity/ui'
import styled from 'styled-components'

export const StyledProgressBar = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${colorMixins.gray40Color};
  display: flex;
`

StyledProgressBar.displayName = 'StyledProgressBar'

export interface ProgressProps {
  width: number
  expectedPercent: number
  strokeColor: ColorName
  excessStrokeColor: ColorName
}

export const StyledProgress = styled('div')<ProgressProps>`
  width: ${({ width }) => width}%;
  height: 4px;
  position: relative;
  top: -1px;
  left: -1px;
  border-radius: 2px;
  background-image: linear-gradient(
    ${p => {
      const strokeColorCode = Color[p.strokeColor]
      const excessStrokeColorCode = Color[p.excessStrokeColor]

      return `90deg,
        ${strokeColorCode} 0% , ${strokeColorCode} ${p.expectedPercent}%, ${excessStrokeColorCode}
        ${p.expectedPercent}%, ${excessStrokeColorCode} 100%`
    }}
  );
`

export interface BreakpointProps {
  position: number
}

StyledProgress.displayName = 'StyledProgress'
export const StyledBreakpoint = styled('div')<BreakpointProps>`
  width: 1px;
  height: 8px;
  background-color: ${colorMixins.gray60Color};
  position: relative;
  top: -3px;
  left: ${({ position }) => position}%;
  z-index: 1;
`

StyledBreakpoint.displayName = 'StyledBreakpoint'
