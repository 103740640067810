import { createSelector } from 'reselect'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import {
  getCurrentContractId,
  isFuelCardsAvailable,
} from 'mlp-client/src/contracts/selectors'
import {
  FlowData,
  FuelCardTypes,
  StaticSuppliers,
} from 'mlp-client/src/flows/fuel-card/types'
import {
  formatFuelCardNames,
  getMappedSupplier,
  getSupplierByName,
  mapFuelCardsToOptions,
  selectCardName,
} from 'mlp-client/src/flows/fuel-card/utils'
import {
  getLocationById,
  getTranslationSelector,
} from 'mlp-client/src/selectors'
import { AppState, SupplierCard } from 'mlp-client/src/types'
import { Address, AddressTypes } from 'mlp-client/src/user/types'
import { Options } from 'mlp-client/src/form/types'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import { mapAddress } from 'mlp-client/src/utils/address/address'

export const getFuelCardTypes = (state: AppState): FuelCardTypes =>
  state.fuelCard.fuelCardTypes.data

export const isFuelCardsLoading = (state: AppState) =>
  state.fuelCard.fuelCardTypes.loading

export const getFuelCards = (state: AppState) => state.fuelCard.fuelCards.data

export const getFuelCardOptions = createSelector(
  getFuelCards,
  mapFuelCardsToOptions,
)

export const getTranslatedFuelCardTypes = createSelector(
  (state: AppState) => state,
  getFuelCardTypes,
  (state: AppState, cards: FuelCardTypes): Options =>
    cards.map(type => ({
      value: type,
      title: getTranslationSelector(
        `fuelCard.cards.${type.toLowerCase()}`,
        type,
      )(state),
    })),
)

export const getFuelCardNames = (fuelCardType: readonly string[]) =>
  createSelector(getTranslatedFuelCardTypes, (types: Options) =>
    formatFuelCardNames(types, fuelCardType),
  )

export const getSelectedFuelCardLocation = (state: AppState): Address =>
  getLocationById(state, AddressTypes.WORK)

export const getSelectedCardName = (fuelCardID: string) =>
  createSelector(getFuelCardOptions, fuelCardOptions =>
    selectCardName(fuelCardID, fuelCardOptions),
  )

export const getSelectedSupplier = (
  state: AppState,
  suppliers: StaticSuppliers,
  fuelCardID: string,
): SupplierCard | undefined => {
  const cardName = getSelectedCardName(fuelCardID)(state)
  const supplier = getSupplierByName(cardName, suppliers)

  return (
    supplier &&
    getMappedSupplier(
      getTranslation(
        state,
        'myLeaseplan.fuelCard.steps.selectServiceType.supplierContactInfo',
      ),
      supplier,
      workHoursTranslationSelector(state),
    )
  )
}

export const getFuelCardSubmitBody = (state: AppState, flowData: FlowData) => {
  const { fuelCardTypes, fuelCardIDs, fuelCardServiceType } = flowData
  const contractId = getCurrentContractId(state)
  const address = getSelectedFuelCardLocation(state)

  return {
    contractId,
    // Some countries have cards IDs, some have cards types.
    fuelcardIds: fuelCardTypes || fuelCardIDs,
    address: address ? mapAddress(address) : {},
    reason: fuelCardServiceType,
  }
}

export const getFuelCardsStatus = (
  state: AppState,
): { isLoaded: boolean; isLoading: boolean } => ({
  isLoaded: state.fuelCard.fuelCards.isLoaded,
  isLoading: state.fuelCard.fuelCards.loading,
})

export const workHoursTranslationSelector = (state: AppState): string =>
  getTranslation(
    state,
    'myLeaseplan.newSetup.flows.fuelCard.steps.allLostAndStolen.contactWorkHours',
  )

export const isFuelCardEnabled = (state: AppState) => (
  config: MyleaseplanConfig,
) => {
  const isFuelCardAlwaysDisplayed =
    config.dashboard?.configuration?.alwaysDisplayFuelCardTile
  const isFuelCards = isFuelCardsAvailable(state)

  return isFuelCardAlwaysDisplayed || isFuelCards
}
