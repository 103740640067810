export const enum StepNames {
  EUROPEAN_ACCIDENT_STATEMENT = 'europeanAccidentStatement',
  POINT_SELECTION = 'pointSelection',
  SELECT_DAMAGE_NATURE_TYPE = 'selectDamageNatureType',
  SELECT_ONE_OR_TWO_SIDED_DAMAGE = 'selectOneOrTwoSidedDamage',
  SELECT_DAMAGE_CAUSE = 'selectDamageCause',
  WHAT_HAPPENED = 'whatHappened',
  UPLOAD_DAMAGE = 'uploadDamage',
  WHEN_AND_WHERE = 'whenAndWhere',
  CHECK_DETAILS = 'checkDetails',
  THANK_YOU = 'thankYou',
  STOLEN_VEHICLE = 'stolenVehicle',
  INSURANCE = 'insurance',
}

export const enum LIABILITY {
  OWN = 'own',
  THIRD_PARTY = 'thirdParty',
  NONE = 'none',
}

export const enum DamageSides {
  ONE_SIDED = 'one',
  TWO_SIDED = 'two',
  TYRE = 'tyre',
  GLASS = 'glass',
  NONE = 'none',
}

export const enum Views {
  FLOW = 'Flow',
  PAGE = 'Page',
}
