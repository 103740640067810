import * as React from 'react'

import Loader from 'mlp-client/src/components/loader/Loader'

interface Props {
  loading?: boolean
  children?: React.ReactNode
}

type DefaultProps = Required<Pick<Props, 'loading'>>

type InternalProps = Props & DefaultProps
class TimelineDetailLayout extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    loading: false,
  }

  render() {
    const { loading, children } = this.props

    return (
      <Loader loading={loading} fixed={true}>
        <div className="my-leaseplan-view">{children}</div>
      </Loader>
    )
  }
}

export default TimelineDetailLayout
