/* eslint-disable no-console */
import { push } from 'connected-react-router'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { deleteSession } from 'mlp-client/src/auth/auth'
import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'
import { ApiSagas } from 'mlp-client/src/api'

import * as actions from './actions'
import { getUser, getUserInfoData } from './selectors'
import { User, UserInfo } from './types'
import { getLoadUser, getUserModel } from './utils'

// This is default API options for all the sagas in this file
// if you want custom options, you can overide them per api call
const defaultRequestOptions = {
  throwError: true,
}

export function* setUserRoles(): SagaIterator {
  // TODO: user doesn't have roles anymore
  // previous supported roles: ['Fleet Manager', 'Driver']

  return yield put(new actions.SetUserRoles([]))
}

export function* getUserInfo(): SagaIterator {
  try {
    const userInfo = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/users/info',
      {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: '0',
        },
      },
      defaultRequestOptions,
    )

    yield put(new actions.LoadUserInfoSuccess(userInfo))
  } catch (error) {
    deleteSession()

    yield put(new actions.LoadUserInfoFailed())
  }
}

export function* loadUser(action: actions.LoadUser): SagaIterator {
  try {
    const { gin: userId }: UserInfo = yield select(getUserInfoData)

    const user: User = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/user',
      {
        params: { userId },
        ...(action.payload.cacheDisabled && {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
          },
        }),
      },
      defaultRequestOptions,
    )

    yield put(new actions.LoadUserSuccess(getLoadUser(user)))
    yield call(setUserRoles)
  } catch {
    yield put(new actions.LoadUserFailed())
  }
}

export function* updateUser(action: actions.UpdateUser): SagaIterator {
  const { user } = action.payload

  try {
    yield call(
      ApiSagas.put,
      '/myleaseplan/lpis/user',
      { body: getLoadUser(user) },
      defaultRequestOptions,
    )
    yield put(new actions.UpdateUserSuccess())

    // We need to load the user again with the new data
    yield put(new actions.LoadUser({ cacheDisabled: true }))

    // redirect to my profile page
    const myProfileRoute = yield select(
      getLocalizedRoute,
      'myLeaseplan.profile.detail',
    )

    yield put(push(myProfileRoute))
  } catch (error) {
    yield put(new actions.UpdateUserFailed())
  }
}

export function* updateMobilePhoneNumber(
  action: actions.UpdateUserMobilePhoneNumber,
): SagaIterator {
  const {
    phone: { number, code },
    onComplete,
  } = action.payload
  const user = yield select(getUser)

  const updatedUser = getUserModel({
    ...user,
    mobilePhoneNumber: number,
    mobilePhoneCountryCode: code,
  })

  try {
    yield call(
      ApiSagas.put,
      '/myleaseplan/lpis/user',
      { body: updatedUser },
      defaultRequestOptions,
    )

    yield put(new actions.UpdateUserSuccess())
    // We need to load the user again with the new data
    yield call(loadUser, new actions.LoadUser({ cacheDisabled: true }))
    yield call(onComplete)
  } catch {
    yield put(new actions.UpdateUserMobilePhoneNumberFailed())
    yield call(onComplete, { error: '' })
  }
}

export default [
  takeLatest(actions.ActionTypes.LOAD_USER_INFO, getUserInfo),
  takeLatest(actions.ActionTypes.LOAD_USER, loadUser),
  takeLatest(actions.ActionTypes.UPDATE_USER, updateUser),
  takeLatest(
    actions.ActionTypes.UPDATE_USER_MOBILE_PHONE_NUMBER,
    updateMobilePhoneNumber,
  ),
]
