import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getTyreStorageLocationName } from 'mlp-client/src/contracts/selectors'
import { StepViewProps } from 'mlp-client/src/flows/types'
import TyreServices, {
  Props as TyreServicesProps,
} from 'mlp-client/src/flows/tyres/components/tyre-services/TyreServices'
import { FlowData } from 'mlp-client/src/flows/tyres/types'
import { AppState } from 'mlp-client/src/types'

import { filterSuppliers } from './utils'

export interface StepConfig extends TyreServicesProps {}

export interface Props extends StepViewProps<StepConfig, FlowData> {
  tyreStorageLocationName?: string
}
class TyreServicesStep extends React.PureComponent<Props> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      tyreStorageLocationName,
      config,
      flowData: { tyreServiceType },
    } = this.props

    const { suppliers } = config

    const filteredSuppliers = filterSuppliers(
      suppliers,
      tyreStorageLocationName,
    )
    const tyreServicesProps = {
      ...config,
      suppliers: filteredSuppliers.length ? filteredSuppliers : suppliers,
      headerTitleLabel: tyreServiceType
        ? `myLeaseplan.newSetup.flows.tyres.constants.services.${tyreServiceType}`
        : undefined,
    }

    return <TyreServices {...tyreServicesProps} />
  }
}

type ReduxProps = Pick<Props, 'tyreStorageLocationName'>

const mapStateToProps = createStructuredSelector<AppState, ReduxProps>({
  tyreStorageLocationName: getTyreStorageLocationName,
})

export { TyreServicesStep }
export default connect(mapStateToProps)(TyreServicesStep)
