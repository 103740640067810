import { Text } from '@velocity/ui'
import styled from 'styled-components'

import ChevronSolidCircleIcon from 'mlp-client/src/components/icons/chevron-solid-circle-icon/ChevronSolidCircleIcon'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

export const AnswerStyled = styled(Text)`
  word-break: break-word;
`

export const ChevronSolidCircleTopIcon = styled(ChevronSolidCircleIcon)`
  align-self: flex-start;
  margin-top: 3px;
  transform: rotate(180deg);
  width: 20px;
  height: 20px;
  fill: ${ColorEnumSelector('midOrange')};
`

export const ChevronSolidCircleRightIcon = styled(ChevronSolidCircleIcon)`
  align-self: flex-start;
  margin-top: 3px;
  transform: rotate(-90deg);
  width: 20px;
  height: 20px;
  fill: ${ColorEnumSelector('petrolBlue')};
`

export const ChevronSolidCircleBottomIcon = styled(ChevronSolidCircleIcon)`
  align-self: flex-start;
  margin-top: 3px;
  width: 20px;
  height: 20px;
  fill: ${ColorEnumSelector('midOrange')};
`
