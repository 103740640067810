import { Card, GridItem, IconText, Paper } from '@leaseplan/ui'
import styled, { css } from 'styled-components'

import RouteLink from 'mlp-client/src/localization/RouteLink'

const noUnderlineStyle = css`
  a {
    text-decoration: none;
  }
`

export const StyledIconText = styled(IconText)`
  display: block;
`

export const StyledCard = styled(Card)`
  ${noUnderlineStyle};
`

export const StyledPaper = styled(Paper)`
  ${noUnderlineStyle};
`

export const StyledLink = styled(RouteLink)`
  display: block;
`

export const NotificationCardContent = styled('div')`
  padding: 12px 24px;
`

export const ActionBlock = styled(GridItem)`
  margin-top: 12px;
  text-align: right;
  display: flex;
  flex-flow: column nowrap;
  > * + * {
    margin-top: 12px;
  }
`
