import { createSelector } from 'reselect'

import { AppState } from 'mlp-client/src/types'
import { getCountry } from 'mlp-client/src/localization/selectors'
import { MyleaseplanConfig } from 'mlp-client/src/features-configuration/types'
import {
  Content,
  ContentPage,
  ContentTopics,
  ParagraphSection,
  SitecoreContentSection,
  ImageParagraphSection,
} from 'mlp-client/src/content/types'
import { getContentSection } from 'mlp-client/src/content/utils'

export const getFooter = (state: AppState) => state.content.footer

export const getMyLeaseplanConfig = createSelector(
  state => state,
  getCountry,
  (myLeaseplan, country): MyleaseplanConfig => myLeaseplan.countries[country],
)
// Counterpart of getShowAllFeatures from src/app/features/shared/selectors
export const getShowAllFeatures = (state: AppState) =>
  state.content.showAllFeatures

export const getDebugTranslations = (state: AppState) =>
  state.content.debugTranslations

const isContentTopics = (content: Content): content is ContentTopics =>
  Array.isArray(content)

const isContentPage = (content: Content): content is ContentPage =>
  content && 'Sections' in content

export const getPageContent = (
  state: AppState,
  endpoint: string,
): ContentPage | undefined => {
  const content = state.content.content?.[endpoint]

  return isContentPage(content) ? content : undefined
}

export const getContentTopics = (
  state: AppState,
  endpoint: string,
): ContentTopics | undefined => {
  const content = state.content.content?.[endpoint]

  return isContentTopics(content) ? content : undefined
}

export const getContentInfo = (
  state: AppState,
  endpoint: string,
): SitecoreContentSection => {
  const pageContent = getPageContent(state, endpoint)
  const paragraph = getContentSection<ParagraphSection>(
    pageContent?.Sections,
    'Paragraph',
  )

  return {
    content: paragraph?.RichText ?? '',
    intro: paragraph?.Intro ?? '',
    title: paragraph?.Title ?? '',
    language: paragraph?.__Language?.toLowerCase() ?? '',
  }
}

export const getContentImage = (
  part: string | null,
  pageContent: ContentPage | undefined,
): ImageParagraphSection | null => {
  if (!part) {
    return null
  }

  const imageSection = getContentSection<ImageParagraphSection>(
    pageContent?.Sections,
    part,
  )

  return imageSection ?? null
}
