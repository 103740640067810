import React from 'react'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import { isLeaseplan } from 'mlp-client/src/VelocitySelector'

const ClickableLogo: React.FC = () => {
  const logoSrc = isLeaseplan()
    ? '/static/images/logo-navigation'
    : '/static/images/logo-leasys'

  return (
    <RouteLink
      to="myLeaseplan.external.mainHomePage"
      className="clickable-logo"
      rel="home"
      data-e2e-id="mainMenuLogoLink"
    >
      <img
        src={`${logoSrc}.png`}
        srcSet={`${logoSrc}@2x.png 2x`}
        className={
          isLeaseplan() ? 'clickable-logo__logo' : 'clickable-logo__logo__lplu'
        }
        alt="LeasePlan"
        data-e2e-id="mainMenuLogoImage"
      />
    </RouteLink>
  )
}

export default ClickableLogo
