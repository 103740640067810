import { TextInput } from '@leaseplan/ui'
import React from 'react'
import { Field } from 'react-final-form'

import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { StringValidations, TranslationType } from 'mlp-client/src/form//types'
import {
  determineValidationState,
  getErrorMessage,
  validateString,
} from 'mlp-client/src/form/utils'

export interface Props {
  name: string
  validations: StringValidations
  label?: TranslationType
  placeholder?: TranslationType
  className?: string
  type?: string
  onChange?(value: string): void
  parse?: (value: any, name: string) => any
  disabled?: boolean
  dataE2eId?: string
}

const TextField: React.FC<Props> = ({
  type = 'text',
  name,
  validations,
  label,
  placeholder,
  className,
  onChange,
  parse = value => value,
  disabled,
  dataE2eId = name,
}) => (
  <div className={className}>
    <LocalizationContext.Consumer>
      {({ translate }) => (
        <Field name={name} validate={validateString(validations)} parse={parse}>
          {({ input, meta }) => (
            <TextInput
              {...input}
              onChange={e => {
                input.onChange(e.value)

                if (onChange) {
                  onChange(e.value)
                }
              }}
              disabled={disabled}
              label={label && translate(label.id, label.replaceValues)}
              validationState={determineValidationState(meta)}
              errorMessage={meta.error && getErrorMessage(meta, translate)}
              placeholder={
                placeholder &&
                translate(placeholder.id, placeholder.replaceValues)
              }
              type={type}
              data-e2e-id={dataE2eId}
            />
          )}
        </Field>
      )}
    </LocalizationContext.Consumer>
  </div>
)

export default TextField
