import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import { MileageUpdateFlowConfig } from 'mlp-client/src/flows/mileage-update/types'
import { StepNames } from 'mlp-client/src/flows/mileage-update/enums'
import EnterCarMileageStep from 'mlp-client/src/flows/mileage-update/components/steps/enter-car-mileage-step/EnterCarMileageStep'
import Flow from 'mlp-client/src/flows/mileage-update/components/flow/Flow'
import ThankYouStep from 'mlp-client/src/flows/mileage-update/components/steps/thank-you-step/ThankYouStep'

export const mileageUpdateConfig: MyLeaseplanFeature<MileageUpdateFlowConfig> = {
  enabled: true,
  view: Flow,
  configuration: {
    getSteps: () => [StepNames.ENTER_CAR_MILEAGE, StepNames.THANK_YOU],
    allSteps: {
      [StepNames.ENTER_CAR_MILEAGE]: {
        stepView: EnterCarMileageStep,
        stepConfig: {},
      },
      [StepNames.THANK_YOU]: {
        stepView: ThankYouStep,
        stepConfig: {},
      },
    },
    uploadImage: false,
  },
}
