import { AddressTypes } from 'mlp-client/src/user/types'

export interface AddressLabels {
  [AddressTypes.HOME]: string
  [AddressTypes.WORK]: string
}

export const getAddressLabels = (
  showPrivateAddressLabel: boolean,
  showCompanyAddress: boolean,
): AddressLabels => ({
  [AddressTypes.HOME]: showPrivateAddressLabel
    ? 'address.privateAddress'
    : 'address.fullAddress',
  [AddressTypes.WORK]: showCompanyAddress ? 'address.companyAddress' : '',
})
