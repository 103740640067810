import { Spacing } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import React from 'react'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getTranslation } from 'mlp-client/src/localization/selectors'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { getCurrentContract } from 'mlp-client/src/contracts/selectors'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { getSupplierNameById } from 'mlp-client/src/flows/maintenance/selectors'
import { SelectSupplierFlowData } from 'mlp-client/src/flows/components/steps/select-supplier-step/SelectSupplierStep'
import { StepViewProps } from 'mlp-client/src/flows/types'
import MileageField from 'mlp-client/src/form/components/fields/mileage-field/Mileage'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { getLastKnownMileage } from 'mlp-client/src/flows/mileage-update/utils'
import { AppState } from 'mlp-client/src/types'

export interface SpecialInstructionsFlowData {
  mileage?: string
  specialInstructions?: string
  predictiveMileage?: string
}

export interface StepConfig {
  titleLabel: string
  metaTitle: string
  subTitle: string
  instructionsTitle: string
  legalNote: string
  showDescription?: boolean
}

export interface Props
  extends StepViewProps<
    StepConfig,
    SpecialInstructionsFlowData & SelectSupplierFlowData
  > {
  isLoading: boolean
  clearForm(): void
  lastKnownMileage: number
  supplierName: string
}

class SpecialInstructionsStep extends React.PureComponent<Props> {
  submitHandler = (values: SpecialInstructionsFlowData) => {
    const {
      goToNextStep,
      setFlowData,
      flowData: { predictiveMileage },
    } = this.props
    const mileage = predictiveMileage || values.mileage

    setFlowData({ ...values, mileage }, goToNextStep)
  }

  render() {
    const {
      config: {
        titleLabel,
        subTitle,
        instructionsTitle,
        legalNote,
        showDescription,
      },
      lastKnownMileage,
      supplierName,
      flowData: { mileage, specialInstructions, predictiveMileage },
    } = this.props

    const replace = supplierName ? { name: supplierName } : undefined

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel={titleLabel}>
          <Form
            keepDirtyOnReinitialize
            onSubmit={this.submitHandler}
            initialValues={{
              mileage,
              specialInstructions,
            }}
          >
            {({ handleSubmit, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                {subTitle && (
                  <Spacing mb={2}>
                    <Text component="span" loose>
                      <Translation id={subTitle} replace={replace} />
                    </Text>
                  </Spacing>
                )}
                {!predictiveMileage && (
                  <MileageField
                    name="mileage"
                    variant="primary"
                    required={true}
                    allowOnlyIntegers={true}
                    lastKnownMileage={lastKnownMileage}
                    label={
                      <Translation id="myLeaseplan.maintenance.steps.updateMileage.title" />
                    }
                  />
                )}
                <Spacing mb={2} />
                {instructionsTitle && showDescription && (
                  <>
                    <Spacing mb={0.5}>
                      <Text bold component="span">
                        <Translation id={instructionsTitle} replace={replace} />
                      </Text>{' '}
                      <Text component="span">
                        <Translation id="myLeaseplan.maintenance.steps.specialInstructions.optional" />
                      </Text>
                    </Spacing>
                    <TextAreaField
                      name="specialInstructions"
                      data-e2e-id="extraInformation"
                      legalNote={{
                        id: legalNote,
                      }}
                    />
                  </>
                )}
                <Spacing mb={2} />
                <NextStepButton
                  onClick={handleSubmit}
                  disabled={hasValidationErrors}
                />
              </form>
            )}
          </Form>
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'lastKnownMileage' | 'supplierName'>
type DispatchProps = Pick<Props, 'clearForm'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, ownProps: OwnProps): ReduxProps => ({
  lastKnownMileage:
    parseInt(getLastKnownMileage(getCurrentContract(state)), 10) || 0,
  supplierName:
    getSupplierNameById(state, ownProps.flowData?.supplier?.id) ||
    getTranslation(
      state,
      'myLeaseplan.maintenance.steps.specialInstructions.supplier',
    ),
})

export { SpecialInstructionsStep }
export default connect(mapStateToProps)(SpecialInstructionsStep)
