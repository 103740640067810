import { SubmissionErrors } from 'final-form'

import { AttachCar } from 'mlp-client/src/attach-car/types'

export const enum ActionTypes {
  ATTACH_CAR = '@@attachCar/ATTACH_CAR',
  VALIDATE_LICENSE_PLATE = '@@attachCar/VALIDATE_LICENSE_PLATE',
  VALIDATE_LICENSE_PLATE_SUCCESS = '@@attachCar/VALIDATE_LICENSE_PLATE_SUCCESS',
}

export class PostAttachCar {
  readonly type = ActionTypes.ATTACH_CAR

  constructor(
    readonly payload: {
      attachCar: AttachCar
      onComplete: (errors?: SubmissionErrors) => void
    },
  ) {}
}
export class ValidateLicensePlate {
  readonly type = ActionTypes.VALIDATE_LICENSE_PLATE

  constructor(
    readonly payload: {
      licensePlate: string
    },
  ) {}
}

export class ValidateLicensePlateSuccess {
  readonly type = ActionTypes.VALIDATE_LICENSE_PLATE_SUCCESS

  constructor(readonly payload: boolean) {}
}

export type Action =
  | PostAttachCar
  | ValidateLicensePlate
  | ValidateLicensePlateSuccess
