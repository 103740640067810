import { DamageReportFeature } from 'mlp-client/src/features-configuration/types'
import { DamageReportFlowConfiguration } from 'mlp-client/src/flows/damage-report/types'
import Flow from 'mlp-client/src/flows/damage-report/components/flow/Flow'
import { StepNames } from 'mlp-client/src/flows/damage-report/enums'
import {
  CheckDetailsStep,
  DamageCauseStep,
  DamageUploadStep,
  EuropeanAccidentStatementStep,
  InsuranceStep,
  ThankYouStep,
  WhatHappenedStep,
} from 'mlp-client/src/flows/damage-report/components/steps'
import {
  damageCauses,
  partyDamages,
} from 'mlp-client/src/features-configuration/lu/data'
import {
  pointSelectionConfig,
  whenAndWhereConfig,
} from 'mlp-client/src/features-configuration/default/damage-report'
import { whoseFault } from 'mlp-client/src/features-configuration/data'

export const whatHappenedConfig = {
  stepView: WhatHappenedStep,
  stepConfig: {
    whoseFaultOptions: whoseFault,
    whatHappenedOptions: partyDamages,
  },
}

export const damageReportConfig: DamageReportFeature<DamageReportFlowConfiguration> = {
  enabled: true,
  view: Flow,
  tileRoute: 'myLeaseplan.damageReport.damageList',
  onePartyIframeSrc: 'https://myleaseplan.ubenchinternational.com/lu',
  mobileApp: {
    disabled: true,
  },
  configuration: {
    getSteps: () => [
      StepNames.SELECT_DAMAGE_CAUSE,
      StepNames.WHAT_HAPPENED,
      StepNames.UPLOAD_DAMAGE,
      StepNames.POINT_SELECTION,
      StepNames.WHEN_AND_WHERE,
      StepNames.EUROPEAN_ACCIDENT_STATEMENT,
      StepNames.CHECK_DETAILS,
      StepNames.THANK_YOU,
    ],
    allSteps: {
      [StepNames.SELECT_DAMAGE_CAUSE]: {
        stepView: DamageCauseStep,
        stepConfig: {
          options: damageCauses,
        },
      },
      [StepNames.WHAT_HAPPENED]: whatHappenedConfig,
      [StepNames.UPLOAD_DAMAGE]: {
        stepView: DamageUploadStep,
        stepConfig: {
          isPoliceStationEnabled: false,
        },
      },
      [StepNames.POINT_SELECTION]: pointSelectionConfig,
      [StepNames.WHEN_AND_WHERE]: whenAndWhereConfig,
      [StepNames.EUROPEAN_ACCIDENT_STATEMENT]: {
        stepView: EuropeanAccidentStatementStep,
        stepConfig: {},
      },
      [StepNames.INSURANCE]: {
        stepView: InsuranceStep,
        stepConfig: {},
      },
      [StepNames.CHECK_DETAILS]: {
        stepView: CheckDetailsStep,
        stepConfig: {
          whatHappenedOptions: partyDamages,
        },
      },
      [StepNames.THANK_YOU]: {
        stepView: ThankYouStep,
        stepConfig: {
          showInsuranceDepartmentLabel: true,
          showTimelineLabel: false,
          amountOfWorkingDays: 2,
        },
      },
    },
  },
}
