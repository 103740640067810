import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, match } from 'react-router'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import {
  EventCategoryFlow,
  EventCategoryService,
  PageViewEventPayload,
} from 'mlp-client/src/analytics/types'
import {
  FlowNavigation,
  TrackPageViewEvent,
} from 'mlp-client/src/analytics/actions'
import { FlowNavigationPayload } from 'mlp-client/src/analytics/middleware'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { AppState } from 'mlp-client/src/types'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import { SetCurrentContract } from 'mlp-client/src/contracts/actions'

import { FlowContext } from './withFlowContext'
import { isActiveStepMatchRoute } from '../selectors'
import { FlowEnd, RegisterFlow } from './actions'
import { ActiveFlowStep, FlowProps } from '../types'
import {
  filterRouteParams,
  flattenFlowData,
  normalizePrefix,
  trackFlowNavigationBySteps,
} from '../utils'

interface MatchParams {
  contractId: string
}

/**
 * This is flow API provider.
 * should not contain any logic related to some specific flows.
 */
export interface Props extends RouteComponentProps {
  stepTitlePrefix: string
  customStepTitle?: string
  routePrefix: string
  onClose?(isMoveBack: boolean): any
  getSteps(flowData: GenericObject): readonly string[]
  isActiveStepRoute(stepName: string, pathname: string): boolean
  render(stepParameters: FlowProps): React.ReactNode
  flowDidStart(): void
  flowWillEnd(): void
  trackFlowNavigation(navigationData: FlowNavigationPayload): void
  pushPageViewEvent(event: PageViewEventPayload): void
  setCurrentContract(contractId: string): void
  isMobileApp: boolean
  initialFlowData?: GenericObject
  eventCategory: EventCategoryService
  flowName: EventCategoryFlow
  contractId: string
  labelEn?: string
  match: match<MatchParams>
}

interface State {
  activeStepIndex: number
  flowData: readonly GenericObject[]
  exitFlowState: undefined | 'close' | 'moveBack'
  isModalOpen: boolean
}

class MyleaseplanFlow extends React.PureComponent<Props, State> {
  static contextType = LocalizationContext
  context!: React.ContextType<typeof LocalizationContext>
  unblock: null | (() => void)

  state: State = {
    flowData: [],
    exitFlowState: undefined,
    activeStepIndex: 0,
    isModalOpen: false,
  }

  componentDidMount() {
    const {
      isMobileApp,
      flowDidStart,
      match: { params },
      contractId,
      setCurrentContract,
    } = this.props
    const { activeStepIndex } = this.state

    if (params.contractId && params.contractId !== contractId) {
      setCurrentContract(params.contractId)
    }

    if (isMobileApp) {
      // It dispatchs REGISTER_FLOW action
      // that previous mobile app versions depends on.
      flowDidStart()
    }

    if (this.getSteps().length > 1) {
      this.unblock = this.blockHistory()
    }

    this.sendAnalytics(activeStepIndex)
  }

  componentDidUpdate() {
    if (this.getSteps().length > 1 && !this.unblock) {
      this.unblock = this.blockHistory()
    }
  }

  sendAnalytics = (stepIndex: number, previousStep: number = 0) => {
    const {
      trackFlowNavigation,
      eventCategory,
      flowName,
      pushPageViewEvent,
    } = this.props

    pushPageViewEvent({
      pageTitle: `${flowName} - ${this.getActiveStep().name}`,
      pageTitleKey: this.getActiveStep().title,
    })

    trackFlowNavigationBySteps({
      payload: {
        eventCategory: `${eventCategory} - ${flowName}`,
        flowName,
        currentStepNr: stepIndex + 1,
        eventLabel: this.context
          .translate(this.getActiveStep().title)
          .toLowerCase(),
        eventLabelEn: this.context
          .englishTranslation(this.getActiveStep().title)
          .toLowerCase(),
        totalStepNrFlow: this.getSteps().length,
      },
      dispatchFlowNavigation: trackFlowNavigation,
      stepIndex,
      previousStep,
    })
  }

  componentWillUnmount() {
    if (this.props.isMobileApp) {
      // It dispatchs FLOW_END action
      // that previous mobile app versions depends on.
      this.props.flowWillEnd()
    }

    this.unblock?.()
    window.scrollTo(0, 0)
  }

  blockHistory = () =>
    this.props.history.block((location, action) => {
      if (action === 'POP') {
        this.openModal()

        return false
      }
    })

  setFlowData = (stepData: GenericObject, callback?: () => void) =>
    this.setState(({ flowData }) => {
      const { index } = this.getActiveStep()

      if (index === 0) {
        return {
          flowData: [stepData],
        }
      }

      // We need to store the step data, by the step name
      // so we can reset this part of the data when the user navigates back.
      return {
        flowData: Object.assign([], flowData, {
          [index]: {
            ...flowData[index],
            ...stepData,
          },
        }),
      }
    }, callback)

  goToStepByIndex = (stepIndex: number) => {
    if (stepIndex >= 0 && stepIndex < this.getSteps().length) {
      const { flowData, activeStepIndex } = this.state

      this.setState(
        {
          flowData,
          activeStepIndex: stepIndex,
        },
        () => this.sendAnalytics(stepIndex, activeStepIndex),
      )
    }
  }

  goToStep = (stepName: string) => {
    const stepIndex = this.getSteps().indexOf(stepName)

    this.goToStepByIndex(stepIndex)
  }

  toNextStep = () => {
    const { activeStepIndex } = this.state
    const newStepIndex = activeStepIndex + 1

    this.goToStepByIndex(newStepIndex)
  }

  toPreviousStep = () => {
    if (this.isFirstStep) {
      this.exitFlow(true)
    }

    const activeStep = this.getActiveStep()
    const newStepIndex = activeStep.index - 1

    this.goToStepByIndex(newStepIndex)
  }

  getSteps = () => {
    const { getSteps } = this.props

    if (!this.props.hasOwnProperty('getSteps')) {
      throw new Error(
        `getSteps function should be defined in your config file.`,
      )
    }

    const steps = getSteps(this.flowData)

    if (!steps || !Array.isArray(steps) || !steps.length) {
      throw new Error(
        `getSteps should return array of steps (strings), instead it returns ${typeof steps}.`,
      )
    }

    return steps
  }

  getActiveStep = (): ActiveFlowStep => {
    const { activeStepIndex } = this.state
    const steps = this.getSteps()
    const stepName = steps[activeStepIndex]

    return {
      index: activeStepIndex,
      name: stepName,
      route: this.getStepRoute(stepName),
      title: this.getStepTitle(stepName),
    }
  }

  get flowData() {
    const { flowData } = this.state

    return flattenFlowData(flowData, this.props.initialFlowData)
  }

  get isLastStep() {
    const { activeStepIndex } = this.state

    return activeStepIndex + 1 === this.getSteps().length
  }

  get isFirstStep() {
    const { activeStepIndex } = this.state

    return activeStepIndex === 0
  }

  exitFlow = (isMoveBack: boolean) => {
    this.setState({ exitFlowState: isMoveBack ? 'moveBack' : 'close' })
  }

  closeFlow = () => this.exitFlow(false)

  closeModal = () => this.setState({ isModalOpen: false })

  openModal = () => this.setState({ isModalOpen: true })

  getStepRoute = (stepName: string) => {
    const prefix = normalizePrefix(this.props.routePrefix)

    return `${prefix}${stepName}`
  }

  getStepTitle = (stepName: string) => {
    const prefix = normalizePrefix(this.props.stepTitlePrefix)

    return `${prefix}steps.${stepName}.${this.props.customStepTitle || 'title'}`
  }

  render() {
    const {
      render,
      location,
      isActiveStepRoute,
      onClose,
      match,
      contractId,
      eventCategory,
      flowName,
    } = this.props
    const { exitFlowState, isModalOpen } = this.state

    // TODO: MLP-3377 this approach of rendering something (usually Redirect component) on flow exist
    // should be overhauled. It causes a flash of empty white page before a redirect happens.
    if (exitFlowState && onClose) {
      return onClose(exitFlowState === 'moveBack')
    }

    const stepsCount = this.getSteps().length
    const activeStep = this.getActiveStep()

    if (!stepsCount) {
      return null
    }

    // This is used to update the URL with current step.
    if (
      activeStep.name &&
      !isActiveStepRoute(activeStep.route, location.pathname) &&
      match.params.contractId === contractId
    ) {
      return (
        <TranslatedRedirect
          to={activeStep.route}
          params={{ ...filterRouteParams(match.params), contractId }}
        />
      )
    }

    // Primitive API to provide for the steps
    const stepParameters: FlowProps = {
      activeStep,
      stepsCount,
      isModalOpen,
      closeModal: this.closeModal,
      openModal: this.openModal,
      closeFlow: this.closeFlow,
      flowData: this.flowData,
      setFlowData: this.setFlowData,
      goToPreviousStep: this.toPreviousStep,
      goToNextStep: this.toNextStep,
      goToStep: this.goToStep,
      eventCategory,
      flowName,
    }

    return (
      <FlowContext.Provider value={stepParameters}>
        {render(stepParameters)}
      </FlowContext.Provider>
    )
  }
}

type ReduxProps = Pick<
  Props,
  'isActiveStepRoute' | 'isMobileApp' | 'contractId'
>
type DispatchProps = Pick<
  Props,
  | 'flowWillEnd'
  | 'flowDidStart'
  | 'trackFlowNavigation'
  | 'setCurrentContract'
  | 'pushPageViewEvent'
>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  isActiveStepRoute: isActiveStepMatchRoute(state),
  isMobileApp: getIsMobileApp(state),
  contractId: getCurrentContractId(state),
})

export const mapDispatchToProps: DispatchProps = {
  trackFlowNavigation: (navigationData: FlowNavigationPayload) =>
    new FlowNavigation(navigationData),
  flowDidStart: () => new RegisterFlow(),
  flowWillEnd: () => new FlowEnd(),
  setCurrentContract: (contractId: string) =>
    new SetCurrentContract({ contractId }),
  pushPageViewEvent: (event: PageViewEventPayload) =>
    new TrackPageViewEvent(event),
}

const MyleaseplanFlowWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyleaseplanFlow)

export { MyleaseplanFlow }
export default withRouter(MyleaseplanFlowWithRedux)
