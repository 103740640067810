import { Color, IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'

class CarContractIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 250 250"
        width={width}
        height={height}
        stroke={Color[color]}
        strokeWidth="5"
      >
        <path d="M152.6,57.14a2.5,2.5,0,0,0-3.13-1.64l-83,25.82A2.5,2.5,0,1,0,68,86.09l83-25.82A2.5,2.5,0,0,0,152.6,57.14Z" />
        <path d="M160.24,81.7a2.5,2.5,0,0,0-3.13-1.64l-83,25.82a2.5,2.5,0,1,0,1.49,4.77l83-25.82A2.5,2.5,0,0,0,160.24,81.7Z" />
        <path d="M167.89,106.27a2.5,2.5,0,0,0-3.13-1.64l-83,25.82a2.5,2.5,0,1,0,1.49,4.77l83-25.82A2.5,2.5,0,0,0,167.89,106.27Z" />
        <path d="M243.19,82.43,240,79.92l1.64-2.07a10.83,10.83,0,1,0-17-13.44L206.52,87.33l-.71.9a88.65,88.65,0,0,0-28.47-35,2.48,2.48,0,0,0-.75-.36l-8.85-28.45a10.86,10.86,0,0,0-13.57-7.13L41,52.5a10.84,10.84,0,0,0-7.13,13.57L42.1,92.7A88.73,88.73,0,0,0,72.45,196.63c1.07.78,2.18,1.55,3.28,2.29A88.81,88.81,0,0,0,213.05,114l10.45-13.21,13.4-16.94,3.18,2.51c4.45,3.52,2.07,6.53,1.17,7.66l-17.91,22.63a2.5,2.5,0,1,0,3.92,3.1l17.91-22.63C249.11,92.14,248.35,86.52,243.19,82.43ZM228.58,67.5a5.83,5.83,0,0,1,9.15,7.24l-16.6,21-4.57-3.62L212,88.47Zm-26.3,25.18-9.55,12.07L179.12,61A83.52,83.52,0,0,1,202.28,92.68ZM44.6,100.74l27.88,89.62A83.74,83.74,0,0,1,44.6,100.74Zm148,73.68A83.64,83.64,0,0,1,79,195.08a2.48,2.48,0,0,0-.1-.9L38.59,64.59a5.84,5.84,0,0,1,3.84-7.31L155.65,22.05A5.84,5.84,0,0,1,163,25.9l25.76,82.8a2.47,2.47,0,0,0,.34.68L166.45,138h0l-.49.62-3,3.82h0l-5.78,7.31a10.84,10.84,0,0,0,0,13.43L151,170.93a2.5,2.5,0,1,0,3.92,3.1l6.16-7.79a10.83,10.83,0,0,0,13.07-3.09l9.3-11.75h0l13.33-16.84,6.3,20.24c0,.08.06.15.09.22A84,84,0,0,1,192.56,174.42ZM162,161a5.84,5.84,0,0,1-1-8.19l4.23-5.35,9.15,7.24L170.22,160A5.84,5.84,0,0,1,162,161Zm43.8-14.15-5.22-16.78a2.44,2.44,0,0,0-.1-.23l8.05-10.18A84.27,84.27,0,0,1,205.83,146.85Zm-28.27,3.93-9.14-7.24,2-2.48h0l38.51-48.67L213.46,96,218,99.63Z" />
        <path d="M134.3,175.89,101,186.26a2.5,2.5,0,1,0,1.49,4.77l33.33-10.37a2.5,2.5,0,1,0-1.49-4.77Z" />
        <path d="M222.81,48.52A2.5,2.5,0,0,0,227.57,47L222.29,30.6a2.5,2.5,0,0,0-4.76,1.53Z" />
        <path d="M238.66,50.83a2.5,2.5,0,0,0,3.4-1l4.89-8.81a2.5,2.5,0,1,0-4.37-2.43l-4.89,8.81A2.5,2.5,0,0,0,238.66,50.83Z" />
        <path d="M203.41,54.73l8.81,4.89a2.5,2.5,0,1,0,2.43-4.37l-8.81-4.89a2.5,2.5,0,0,0-2.43,4.37Z" />
      </svg>
    )
  }
}

export default CarContractIcon
