import { FuelHistory } from 'mlp-client/src/flows/fuel-history/types'

import { Action, ActionTypes, LoadFuelHistorySuccess } from './actions'

export interface State extends FuelHistory {
  isLoading: boolean
  hasError: boolean
}

export const defaultState: State = {
  fuelRecords: [],
  fuelSpendingRecord: undefined,
  isLoading: false,
  hasError: false,
}

const loadFuelHistory = (state: State): State => ({
  ...state,
  isLoading: true,
  hasError: false,
})

const loadFuelHistorySuccess = (
  state: State,
  { payload }: LoadFuelHistorySuccess,
): State => ({
  ...state,
  fuelRecords: payload.fuelHistory.fuelRecords ?? null,
  fuelSpendingRecord: payload.fuelHistory.fuelSpendingRecord ?? null,
  isLoading: false,
})

const loadFuelHistoryFail = (): State => ({
  ...defaultState,
  hasError: true,
})

const fuelHistoryReducer = (
  state: State = defaultState,
  action: Action,
): State => {
  switch (action.type) {
    case ActionTypes.LOAD_FUEL_HISTORY:
      return loadFuelHistory(state)
    case ActionTypes.LOAD_FUEL_HISTORY_SUCCESS:
      return loadFuelHistorySuccess(state, action)
    case ActionTypes.LOAD_FUEL_HISTORY_FAIL:
      return loadFuelHistoryFail()
    default:
      return state
  }
}

export default fuelHistoryReducer
