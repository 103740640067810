import styled from 'styled-components'
import { Card } from '@leaseplan/ui'
import { colorMixins } from '@velocity/ui'

export const StyledCard = styled(Card)`
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    transform: translateY(-4px);

    .translation-label {
      color: ${colorMixins.primaryDarkColor};
    }

    .camera-icon {
      stroke: ${colorMixins.primaryDarkColor};
    }

    .plus-icon {
      color: ${colorMixins.primaryDarkColor};
    }
  }
`

export const StyledField = styled.div`
  .field-file--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
