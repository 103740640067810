import { CardContent } from '@leaseplan/ui'
import { Headline, Text, VelocityConsumer } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import lowerCase from 'lodash/lowerCase'

import { FlowActivation } from 'mlp-client/src/analytics/actions'
import {
  StyledCard,
  StyledLink,
} from 'mlp-client/src/dashboard/components/tiles/DashboardTile.styled'
import { TileProps } from 'mlp-client/src/dashboard/type'
import TextCenter from 'mlp-client/src/components/styled/TextCenter'

class TileWithIcons extends React.PureComponent<TileProps> {
  handleTileClick = () => {
    const { name, trackFlowActivation } = this.props

    trackFlowActivation(lowerCase(name))
  }

  renderDisabledTile(): React.ReactNode {
    const {
      disabledTitle,
      disabledSummary,
      defaultContent,
      title,
      summary,
      icon: IconComponent,
    } = this.props

    return (
      <VelocityConsumer>
        {({ theme }) => (
          <StyledCard stretch={true}>
            <CardContent>
              <TextCenter>
                {IconComponent && (
                  <IconComponent width="36" height="36" color="steel60" />
                )}
                <Headline
                  component="h1"
                  variant="100"
                  style={{ color: theme.styling.gray['60'] }}
                  withMarginBottom
                >
                  {defaultContent ? title : disabledTitle}
                </Headline>
                <Text
                  variant="200"
                  style={{ color: theme.styling.gray['60'] }}
                  component="span"
                >
                  {defaultContent ? summary : disabledSummary}
                </Text>
              </TextCenter>
            </CardContent>
          </StyledCard>
        )}
      </VelocityConsumer>
    )
  }

  renderLinkedTile(): React.ReactNode {
    const {
      title,
      summary,
      icon: IconComponent,
      to,
      toParams,
      target,
    } = this.props

    return (
      <VelocityConsumer>
        {({ theme }) => (
          <StyledCard hover={true} stretch={true}>
            <StyledLink
              to={to}
              params={toParams}
              target={target}
              onClick={this.handleTileClick}
            >
              <CardContent>
                <TextCenter>
                  {IconComponent && <IconComponent width="36" height="36" />}
                  <Headline
                    variant="100"
                    component="h1"
                    style={{ color: theme.styling.primary.dark }}
                    withMarginBottom
                  >
                    {title}
                  </Headline>
                  <Text
                    variant="200"
                    component="span"
                    style={{ color: theme.styling.gray.main }}
                  >
                    {summary}
                  </Text>
                </TextCenter>
              </CardContent>
            </StyledLink>
          </StyledCard>
        )}
      </VelocityConsumer>
    )
  }

  renderModalTile(): React.ReactNode {
    const {
      title,
      summary,
      icon: IconComponent,
      modalTrigger: ModalTriggerComponent,
    } = this.props

    return (
      <VelocityConsumer>
        {({ theme }) => (
          <div onClick={this.handleTileClick}>
            <StyledCard hover={true} stretch={true}>
              <ModalTriggerComponent {...this.props}>
                <CardContent>
                  <TextCenter>
                    {IconComponent && <IconComponent width="36" height="36" />}
                    <Headline
                      variant="100"
                      style={{ color: theme.styling.primary.dark }}
                      withMarginBottom
                      component="h1"
                    >
                      {title}
                    </Headline>
                    <Text variant="200" component="span">
                      {summary}
                    </Text>
                  </TextCenter>
                </CardContent>
              </ModalTriggerComponent>
            </StyledCard>
          </div>
        )}
      </VelocityConsumer>
    )
  }

  render() {
    const { disabled, hasModal } = this.props

    switch (true) {
      case disabled:
        return this.renderDisabledTile()
      case hasModal:
        return this.renderModalTile()
      default:
        return this.renderLinkedTile()
    }
  }
}

type DispatchProps = Pick<TileProps, 'trackFlowActivation'>

export const mapDispatchToProps: DispatchProps = {
  trackFlowActivation: (eventLabel: string) =>
    new FlowActivation({ eventLabel }),
}

export { TileWithIcons }
export default connect(undefined, mapDispatchToProps)(TileWithIcons)
