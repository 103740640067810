import React from 'react'
import { connect } from 'react-redux'

import { getTranslation } from 'mlp-client/src/localization/selectors'
import { FAQ } from 'mlp-client/src/FAQ/components/FAQ'
import { AppState } from 'mlp-client/src/types'

export interface Props {
  title?: string
  info?: string
  phoneNumber: string
}

class NotificationBlock extends React.PureComponent<Props> {
  render() {
    const { title, info } = this.props

    return (
      <FAQ
        dataE2eId="createFuelCard"
        faqItems={[
          {
            id: 'createFuelCard',
            question: title,
            answer: info,
          },
        ]}
      />
    )
  }
}

type ReduxProps = Pick<Props, 'title' | 'info'>
type OwnProps = Omit<Props, keyof ReduxProps>

export const mapStateToProps = (
  state: AppState,
  { phoneNumber }: OwnProps,
): ReduxProps => ({
  title: getTranslation(
    state,
    'myLeaseplan.fuelCard.steps.selectFuelCard.orderNewCard',
  ),
  info: getTranslation(
    state,
    'myLeaseplan.fuelCard.steps.selectFuelCard.customerService',
  ).replace('{Phone}', phoneNumber),
})

export { NotificationBlock }
export default connect(mapStateToProps)(NotificationBlock)
