import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'
import { AppState } from 'mlp-client/src/types'

export const isActiveStepMatchRoute = (state: AppState) => (
  stepRoute: string,
  pathname: string,
) => {
  const route = getLocalizedRoute(state, stepRoute)

  if (route) {
    // Example: /nl-nl/mijn/fuel-card-requests/select-fuelcard/
    // localizedStepName => select-fuelcard
    const localizedStepName = route
      .split('/')
      .filter(x => x)
      .reverse()[0]

    // and then we check if the step name present in the current pathname
    return pathname.includes(localizedStepName)
  }
}
