import React from 'react'
import { connect } from 'react-redux'

import { getContentInfo } from 'mlp-client/src/selectors'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { FAQ } from 'mlp-client/src/flows/components/stolen-vehicle/faq/FAQ'
import { MainContent } from 'mlp-client/src/flows/components/stolen-vehicle/main-content/MainContent'
import withContent from 'mlp-client/src/flows/components/stolen-vehicle/withContent'
import { AppState } from 'mlp-client/src/types'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import {
  ContentEndpoint,
  FlowData,
} from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'

export interface Props extends FlowProps<FlowData> {
  content: string
  intro: string
  title: string
}

class StolenVehicleStep extends React.PureComponent<Props> {
  render() {
    const { title, intro, content } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep
          titleLabel={title}
          side={
            <FAQ
              intro={intro}
              phoneRenderer="french"
              e2eId="stolen-vehicle-block-side"
            />
          }
        >
          <MainContent content={content} e2eId="stolen-vehicle-block-main" />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

type ReduxProps = Pick<Props, 'content' | 'intro' | 'title'>

const mapStateToProps = (state: AppState): ReduxProps => {
  const { content, intro, title } = getContentInfo(
    state,
    ContentEndpoint.STOLEN_VEHICLE,
  )

  return {
    content,
    intro,
    title,
  }
}

export { StolenVehicleStep }
export default connect(mapStateToProps)(withContent(StolenVehicleStep))
