import { TyreDamageTypes } from 'mlp-client/src/flows/tyres/enums'
import {
  FlowData,
  SubmitTyreRequestData,
} from 'mlp-client/src/flows/tyres/types'
import { User } from 'mlp-client/src/user/types'

export const getSubmitTyresRequestBody = (
  flowData: FlowData,
  user: User,
): SubmitTyreRequestData => ({
  tyreService: flowData.tyreServiceType,
  // whichTyres is mandatory property, even if you don't have any tyres
  // selected during the flow.
  whichTyres: [''],
  email: user.email,
  phoneNumber: user.phoneNumber,
  problemDescription: flowData.problemDescription,
  tyreDamage: TyreDamageTypes.OTHER,
})
