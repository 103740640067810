import moment, { MomentInput } from 'moment'
import Holidays, { Holiday } from 'date-holidays-parser'
import { DisabledDay } from '@leaseplan/ui'

import { CountryCode, LanguageCode } from 'mlp-client/src/localization/enums'

import { Weekday } from '../../enums'
import data from './holidays.json'

export const getNextWorkingDate = (
  daysFromNow: number,
  publicHolidays: readonly string[],
  beginDate: moment.Moment = moment(),
): Date => {
  let workingDays = 0

  while (workingDays < daysFromNow) {
    beginDate.add(1, 'days')

    if (isWorkingDay(beginDate, publicHolidays)) {
      workingDays++
    }
  }

  return beginDate.toDate()
}

export const isWorkingDay = (
  date: moment.Moment,
  publicHolidays: readonly string[],
): boolean => {
  if (date.day() === Weekday.Saturday || date.day() === Weekday.Sunday) {
    return false
  }

  return !publicHolidays.includes(date.format('L'))
}

export const formatDate = (
  date: moment.Moment | string | Date,
  dateFormat = 'dddd, LL',
) =>
  moment.isMoment(date)
    ? date.format(dateFormat)
    : moment(date).format(dateFormat)

const getHolidaysForTwoYears = (
  countryCode: CountryCode,
  language: LanguageCode,
): Holiday[] => {
  // @ts-ignore - for some reason it doesn't have correct constructor types
  const holidays = new Holidays(data, countryCode, {
    languages: language,
    types: ['public'],
  })

  return [
    ...holidays.getHolidays(moment().year(), language),
    ...holidays.getHolidays(moment().add(1, 'year').year(), language),
  ]
}

export const getCountryHolidays = (
  countryCode: CountryCode,
  language: LanguageCode,
  isPublicHolidaysDisabled: boolean,
): DisabledDay[] => {
  if (!isPublicHolidaysDisabled) {
    return []
  }

  return getHolidaysForTwoYears(countryCode, language).map(
    (item: Holiday): DisabledDay => {
      const date = new Date(item.date)

      return {
        from: date,
        to: date,
        message: item.name,
      }
    },
  )
}

export const mapHolidaysDate = (
  countryCode: CountryCode,
  language: LanguageCode,
  isPublicHolidaysDisabled: boolean,
): readonly string[] => {
  if (!isPublicHolidaysDisabled) {
    return []
  }

  return getHolidaysForTwoYears(
    countryCode,
    language,
  ).map((item: Holiday): string => moment(item.date).format('L'))
}

export const formatRelativeDate = (date: MomentInput): string => {
  const dateObj = moment(date)

  return dateObj > moment('1900-01-01') ? dateObj.fromNow() : ''
}
