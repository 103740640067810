import { ChevronRightIcon, Paper, SizeConstraint, Spacing } from '@leaseplan/ui'
import React from 'react'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import VehicleModelLicense from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import { Contract } from 'mlp-client/src/contracts/types'
import { mapContractToVehicle } from 'mlp-client/src/contracts/utils'
import { CarMissingIcon } from 'mlp-client/src/components/icons'

import { LinkStyled, Right, SpacingRow } from './VehicleOverviewTile.styled'

export interface Props {
  contract: Contract
  setActiveContract(contract: Contract): void
}

class VehicleOverviewTileMobile extends React.PureComponent<Props> {
  render() {
    const { contract, setActiveContract } = this.props
    const vehicle = mapContractToVehicle(contract)

    return (
      <Paper
        hover={true}
        data-e2e-id={`vehicleOverview-${vehicle.licensePlate}`}
      >
        <LinkStyled
          component={RouteLink}
          to="myLeaseplan.dashboard"
          params={{ contractId: contract.id }}
          onClick={() => setActiveContract(contract)}
        >
          <SpacingRow m={1}>
            <SizeConstraint minWidth={40}>
              <CarMissingIcon height={40} width={40} />
            </SizeConstraint>
            <Spacing ml={0.5}>
              <VehicleModelLicense vehicle={vehicle} bold textSize="300" />
            </Spacing>
            <Right>
              <ChevronRightIcon color="steel80" />
            </Right>
          </SpacingRow>
        </LinkStyled>
      </Paper>
    )
  }
}

export { VehicleOverviewTileMobile }
export default VehicleOverviewTileMobile
