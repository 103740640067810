import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class CashClaimIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M54.3 49.4a8 8 0 0 0 .3-2.3c-.2-5.2-5.1-12.8-7-15.2l-8.4-12h-12L19 31.8c-.3.4-7 9.1-7 15.3 0 .7 0 1.5.3 2.3 1 4 4.8 6.7 9 6.7h23.9c4.2 0 8-2.8 9-6.7zM27 6.6c-.4.4-1 1-2 1.3 1 1.5 1.9 3.2 2.5 5H39c.6-1.8 1.4-3.5 2.4-5-1-.2-1.7-1-2-1.3v-.1h-.2c-.4.6-1.3 1.6-3 1.6-1.6 0-2.4-1-2.9-1.5a2.5 2.5 0 0 1 0-.1 1.7 1.7 0 0 1-.1 0c-.5.6-1.4 1.6-3 1.6s-2.5-1-3-1.5v-.1a4.1 4.1 0 0 1-.1 0zm.5 9.7c0 .3.2.5.5.5h10.3a.5.5 0 0 0 0-1H28.1a.5.5 0 0 0-.5.5zM50 30.1s7.4 9.6 7.6 17a11 11 0 0 1-.5 3c-1.4 5.3-6.3 9-12 9H21.5c-5.7 0-10.6-3.7-12-9a11 11 0 0 1-.4-3C9 40 15.8 31 16.6 30l8.3-12a3.4 3.4 0 0 1-.3-1.6c0-.5.2-1 .4-1.6-1-3.5-3.3-6.7-5-8.9a1.5 1.5 0 0 1 1-2.4c1.3 0 2 .7 2.4 1.2.4.4.5.5.7.5.3 0 .4-.1.8-.5.4-.5 1-1.2 2.3-1.2 1.2 0 1.8.7 2.3 1.2.4.4.5.5.7.5s.3-.1.7-.5c.5-.5 1.1-1.2 2.3-1.2 1.3 0 2 .7 2.3 1.2.4.4.5.5.8.5.2 0 .3-.1.7-.5.4-.5 1-1.2 2.3-1.2 1.2 0 1.9.7 2.3 1.2.4.4.5.5.7.5.3 0 .4-.1.8-.5.5-.6 1-1.2 2.3-1.2A1.5 1.5 0 1 1 46.5 6c-1.7 2.1-4 5.4-5 8.9a3.4 3.4 0 0 1 0 3v.1L50 30zM37.6 44.5a1.5 1.5 0 1 1 1.3 2.7 9 9 0 0 1-3.9 1 9 9 0 0 1-8.1-5.3h-2.5a1.5 1.5 0 1 1 0-3H26a9.2 9.2 0 0 1 0-.8v-.5h-1.7a1.5 1.5 0 1 1 0-3h2.3a9 9 0 0 1 12-4.7 1.5 1.5 0 0 1-1.3 2.8 6 6 0 0 0-7.2 2h4.4a1.5 1.5 0 0 1 0 3H29a6.2 6.2 0 0 0 0 1.2h5.6a1.5 1.5 0 1 1 0 3h-4.2A6 6 0 0 0 35 45a6 6 0 0 0 2.6-.6z"
        />
      </svg>
    )
  }
}

export default CashClaimIcon
