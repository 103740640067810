import React from 'react'

import LocationStep, {
  LocationFlowData,
  StepConfig,
} from 'mlp-client/src/flows/components/steps/location/LocationStep'
import SelectTimeField from 'mlp-client/src/flows/return/components/select-time-field/SelectTimeField'
import { StepViewProps } from 'mlp-client/src/flows/return/types'
import { times } from 'mlp-client/src/flows/return/utils'

export interface Props extends StepViewProps<StepConfig> {}

class SelectTimeLocationStep extends React.PureComponent<Props> {
  setFlowData = (
    { address, addressType, time }: LocationFlowData,
    goToNextStep: () => void,
  ) =>
    this.props.setFlowData(
      { address, addressType, returnTime: time },
      goToNextStep,
    )

  getInitialTime = (returnTime?: string): string =>
    returnTime || times[0].toString()

  render() {
    const {
      flowData: { address, returnTime, returnDate },
      ...locationProps
    } = this.props

    return (
      <LocationStep
        titleLabel="myLeaseplan.return.steps.selectTimeLocation.pageTitle"
        additionalComponents={[
          {
            Component: SelectTimeField,
            config: {
              returnDate,
            },
          },
        ]}
        flowData={{ address, time: this.getInitialTime(returnTime) }}
        {...locationProps}
        setFlowData={this.setFlowData}
      />
    )
  }
}

export { SelectTimeLocationStep }
export default SelectTimeLocationStep
