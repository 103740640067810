import React from 'react'

import TranslatedRedirect from 'mlp-client/src/localization/TranslatedRedirect'
import MyleaseplanFlow from 'mlp-client/src/components/myleaseplan-flow/flow/MyleaseplanFlow'
import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { NotFound } from 'mlp-client/src/components/not-found/NotFound'
import { MyLeaseplanFeatureProps } from 'mlp-client/src/features-configuration/types'
import { TyresFlowConfiguration } from 'mlp-client/src/flows/tyres/types'
import {
  EventCategoryFlow,
  EventCategoryService,
} from 'mlp-client/src/analytics/types'

export interface Props
  extends MyLeaseplanFeatureProps<TyresFlowConfiguration> {}

class Flow extends React.PureComponent<Props> {
  static routePrefix = 'myLeaseplan.tyres.steps.'
  static stepTitlePrefix = 'myLeaseplan.newSetup.flows.tyres'

  renderStep = (stepParameters: FlowProps) => {
    const { name } = stepParameters.activeStep
    const { configuration } = this.props.featureConfig
    const { stepConfig, stepView: StepView } = configuration.allSteps[name]

    if (StepView) {
      return (
        <StepView
          {...stepParameters}
          config={{ ...stepConfig, ...configuration }}
        />
      )
    }

    return <NotFound />
  }

  onFlowClose = () => {
    const { match } = this.props

    return (
      <TranslatedRedirect to="myLeaseplan.dashboard" params={match.params} />
    )
  }

  render() {
    const { featureConfig } = this.props

    return (
      <MyleaseplanFlow
        stepTitlePrefix={Flow.stepTitlePrefix}
        customStepTitle="stepTitle"
        routePrefix={Flow.routePrefix}
        getSteps={featureConfig.configuration.getSteps}
        onClose={this.onFlowClose}
        render={this.renderStep}
        eventCategory={EventCategoryService.MAIN_SERVICE}
        flowName={EventCategoryFlow.TYRES}
      />
    )
  }
}

export default Flow
