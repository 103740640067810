import Classnames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { CloseIcon } from '@velocity/ui/draft'

import { FileStatus } from 'mlp-client/src/form/components/fields/file-upload-field/enums'
import { File } from 'mlp-client/src/form/components/fields/file-upload-field/types'
import { getFile } from 'mlp-client/src/form/components/fields/file-upload-field/selectors'
import { PdfIcon } from 'mlp-client/src/components/icons'
import { AppState } from 'mlp-client/src/types'
import { StyledImagePreview } from 'mlp-client/src/components/image-preview/ImagePreview'

interface Props {
  onFileRemove(fileId: string): void
  fileId: string
  file?: File
  thumbnail?: JSX.Element
}

export class FileItem extends React.PureComponent<Props> {
  handleFileRemove = () => this.props.onFileRemove(this.props.fileId)

  getFilePreview = (file: File): React.ComponentClass<PdfIcon> | JSX.Element =>
    file.name.endsWith('.pdf') ? (
      <PdfIcon />
    ) : (
      <StyledImagePreview src={file.preview} />
    )

  render() {
    const { file, thumbnail } = this.props
    const width = file ? `${file.progress}%` : 0

    return file ? (
      <div
        className={Classnames(
          'field-file__file',
          `field-file__file--${file.status}`,
        )}
      >
        <div className="field-file__upload-progress" style={{ width }} />
        <div className="field-file__thumbnail">
          {thumbnail || this.getFilePreview(file)}
        </div>
        <div className="field-file__filename">{file.name}</div>
        {file.status !== FileStatus.UPLOADING && (
          <div className="field-file__remove" onClick={this.handleFileRemove}>
            <CloseIcon size="xs" color="#2D2D2D" />
          </div>
        )}
      </div>
    ) : null
  }
}

type ReduxProps = Pick<Props, 'file'>
type DispatchProps = Pick<Props, never>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

export const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): ReduxProps => ({
  file: getFile(state, ownProps.fileId),
})

export default connect(mapStateToProps)(FileItem)
