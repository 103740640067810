import { camelCase } from 'camel-case'
import moment from 'moment'

import { DamageType } from 'mlp-client/src/flows/glass-repair/enums'
import { BookingCancellation } from 'mlp-client/src/timeline-details/types'
import { TimelineItemStatus } from 'mlp-client/src/timeline-details/enums'
import { GlassDamageType } from 'mlp-client/src/types'

export const removeTimezone = (date: string): string =>
  date.replace(/([+-][0-9]{2}:[0-9]{2})/, '')

export const getCancellationAvailability = (
  booking: BookingCancellation,
): boolean => {
  const dateWithoutTimezone: string = removeTimezone(booking.bookingDate)
  const isNotInThePast: boolean = moment().diff(dateWithoutTimezone) < 0
  let isCancellable: boolean = false

  switch (camelCase(booking.status)) {
    case TimelineItemStatus.NEW:
    case TimelineItemStatus.IN_PROGRESS:
    case TimelineItemStatus.CONFIRMED:
    case TimelineItemStatus.UNCONFIRMED:
      isCancellable = true
      break
    default:
      isCancellable = false
  }

  return isCancellable && isNotInThePast
}

export const mapGlassDamageToLabels = (damageType: GlassDamageType): string => {
  let damage

  switch (damageType) {
    case GlassDamageType.SMALL_CHIP:
      damage = DamageType.SmallCrack
      break
    case GlassDamageType.LARGE_CHIP:
      damage = DamageType.BigCrack
      break
    case GlassDamageType.CRACK:
      damage = DamageType.Panorama
      break
    default:
      damage = damageType
  }

  return `myLeaseplan.glassRepair.types.${damage}.title`
}
