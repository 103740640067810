import { Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { NotificationProps } from 'mlp-client/src/dashboard/type'
import PredictiveMileageUpdate from 'mlp-client/src/flows/predictive-mileage/PredictiveMileageUpdate'
import { GrayDarkText } from 'mlp-client/src/components/styled/TextStyle'

import {
  ActionBlock,
  NotificationCardContent,
  StyledCard,
} from './DashboardTile.styled'

export interface Props extends NotificationProps {}

class NotificationMileageTile extends React.PureComponent<Props> {
  render() {
    const { makeModel, contractId, notificationDetails } = this.props

    return (
      <StyledCard hover={false} stretch={true}>
        <NotificationCardContent>
          <Grid alignItems="center" verticalGutter={false}>
            <GridItem span="grow">
              <Spacing mb={1}>
                <Headline variant="100">
                  <Translation
                    id="myLeaseplan.notifications.mileage.title"
                    replace={{ makeModel }}
                  />
                </Headline>
              </Spacing>
              <Grid gutter={false}>
                <GrayDarkText variant="200">
                  <Translation id="myLeaseplan.notifications.mileage.description" />
                </GrayDarkText>
              </Grid>
            </GridItem>
            <ActionBlock span={{ lap: 'auto', mobile: 12 }}>
              <PredictiveMileageUpdate
                contractId={contractId}
                lastKnownMileage={notificationDetails.lastKnownMileage}
              />
            </ActionBlock>
          </Grid>
        </NotificationCardContent>
      </StyledCard>
    )
  }
}

export default NotificationMileageTile
