import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { getLocale } from 'mlp-client/src/localization/selectors'
import { getUser, getUserInfoData } from 'mlp-client/src/user/selectors'
import { UserInfo } from 'mlp-client/src/user/types'
import {
  ActionType as ApiActionType,
  ApiError,
  ApiSagas,
} from 'mlp-client/src/api'
import { saveFile } from 'mlp-client/src/utils'
import { Geolocation, Suppliers } from 'mlp-client/src/types'
import { mapLocation } from 'mlp-client/src/utils/address/address'
import { isQuotesFeatureEnabled } from 'mlp-client/src/quotes/selectors'
import * as quotesActions from 'mlp-client/src/quotes/actions'
import { downloadFile } from 'mlp-client/src/utils/file/file'
import { CountryCode, Locales } from 'mlp-client/src/localization/enums'
import { config } from 'mlp-client/src/app-config'

import * as actions from './actions'
import { getFirstContractId, mapContracts } from './utils'
import {
  Contract,
  Contracts,
  Notification,
  IsAnySupplierAvailableRequestData,
  RegistrationCertificate,
} from './types'
import {
  getCurrentContract,
  getFirstContract,
  getLeaseContracts,
  getSuppliersNumberFetched,
} from './selectors'
import { NotificationStatus } from './enums'

interface Results {
  contracts: Contracts
}

const INCORRECT_SUPPLIERS_ADDRESS_STATUS_CODE = 409

export function* loadUserContracts(): SagaIterator {
  yield put(new actions.SetContractsLoading({ loading: true }))

  const options = {
    throwError: true,
  }

  try {
    const isQuotesEnabled = yield select(isQuotesFeatureEnabled)
    const { gin: userId }: UserInfo = yield select(getUserInfoData)
    const result: Results = yield isQuotesEnabled
      ? call(
          ApiSagas.get,
          `/myleaseplan/lpis/${userId}/NGContracts`,
          {},
          options,
        )
      : call(
          ApiSagas.get,
          '/myleaseplan/lpis/contracts',
          {
            params: { userId },
          },
          options,
        )
    const contracts = mapContracts(result.contracts) || []
    const firstContractId = getFirstContractId(contracts)

    // We need to define the first contract as the current one
    // till we check the choosed one by the user and then we override it
    if (firstContractId) {
      yield put(new actions.SetCurrentContract({ contractId: firstContractId }))
    }

    yield put(new actions.LoadContractsSuccess({ contracts }))
  } catch {
    yield put(new actions.LoadContractsFailed())
  }
}

export function* loadNotifications(): SagaIterator {
  const locale = yield select(getLocale)
  const user = yield select(getUser)
  const contract = yield select(getCurrentContract)
  const options = {
    throwError: true,
  }

  try {
    const result: Notification[] = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/notifications',
      {
        params: {
          locale,
          userId: user.id,
          contractId: contract.id,
        },
      },
      options,
    )

    if (!result.length) {
      sessionStorage.removeItem(`notification_status_${contract.id}`)
    }

    const setStatus = (notification: Notification): NotificationStatus => {
      const notificationStatus = sessionStorage.getItem(
        `notification_status_${contract.id}`,
      )

      if (notification && notificationStatus === NotificationStatus.DELETED) {
        return NotificationStatus.PENDING
      }

      return NotificationStatus.ACTIVE
    }
    const resultWithStatus = result.map<Notification>(notification => ({
      ...notification,
      status: setStatus(notification),
    }))

    yield put(new actions.LoadNotificationsSuccess(resultWithStatus))
  } catch {
    yield put(new actions.LoadNotificationsFailed())
  }
}

export function* loadPolicyDocuments(): SagaIterator {
  const user = yield select(getUser)
  const options = {
    throwError: true,
  }

  try {
    const result = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/policyDocuments',
      {
        params: {
          userId: user.id,
        },
      },
      options,
    )

    const policyDocuments = result.policyDocuments || []

    yield put(new actions.LoadPolicyDocumentsSuccess({ policyDocuments }))
  } catch {
    yield put(new actions.LoadPolicyDocumentsFailed())
  }
}

export function* downloadPolicyDocument(
  action: actions.DownloadPolicyDocument,
): SagaIterator {
  const { fileId, fileName, documentType } = action.payload
  const requestUrl = `/myleaseplan/lpis/policyDocument`
  const requestParams = {
    params: {
      documentType,
      docId: fileId,
    },
    headers: {
      Accept: 'application/octet-stream',
    },
  }

  const options = {
    throwError: true,
  }

  try {
    const blob: Blob = yield call(
      ApiSagas.get,
      requestUrl,
      requestParams,
      options,
    )

    yield call(saveFile, blob, fileName, documentType)

    yield put(new actions.DownloadPolicyDocumentSuccess())
  } catch {
    yield put(new actions.DownloadPolicyDocumentFailed())
  }
}

export function* downloadRegistrationCertificate(
  action: actions.DownloadRegistrationCertificate,
): SagaIterator {
  const { documentId } = action.payload

  try {
    const apiURL = process.env.PLATFORM_URL || config.PLATFORM_URL
    const requestUrl = `${apiURL}/api2/myleaseplan/lpis/vehicle-registration-certificate-file-contents?documentId=${documentId}&countryCode=${CountryCode.fr}`

    downloadFile(requestUrl, `${documentId}.pdf`)

    yield put(new actions.DownloadRegistrationCertificateSuccess())
  } catch {
    yield put(new actions.DownloadRegistrationCertificateFailed())
  }
}

export function* getIsAnySupplierAvailable(
  requestData: IsAnySupplierAvailableRequestData,
  onComplete: (error: boolean) => void,
) {
  try {
    const availableSuppliersNumber: number = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/isAnySupplierAvailable',
      {
        params: { ...requestData },
      },
      { throwError: true },
    )

    yield put(
      new actions.SetNoSuppliersByMalfunctions({
        noSuppliersByMalfunctions: availableSuppliersNumber === 0,
      }),
    )

    return availableSuppliersNumber > 0
  } catch (error) {
    yield call(onComplete, true)
  }
}

export function* loadNearbySuppliers(
  action: actions.LoadNearbySuppliers,
): SagaIterator {
  const {
    requestData: {
      additionalServices,
      date,
      location,
      bookingType,
      selectedMalfunctions,
      mileage,
    },
    onComplete,
    prevalidate,
  } = action.payload
  const { street, city, postcode, country, houseNumber } = mapLocation(location)
  const locale = yield select(getLocale)
  const contract = yield select(getCurrentContract)

  const params: IsAnySupplierAvailableRequestData = {
    locale,
    street,
    houseNumber,
    city,
    postcode,
    country,
    bookingType,
    contractId: contract.nolsId,
    services: additionalServices,
    selectedMalfunctions: selectedMalfunctions?.map(sm => sm.malfunction),
    mileage,
  }
  let shouldRequestSuppliers: boolean = true

  if (prevalidate) {
    const suppliersNumberFetched = yield select(getSuppliersNumberFetched)

    if (!suppliersNumberFetched) {
      shouldRequestSuppliers = yield call(
        getIsAnySupplierAvailable,
        params,
        onComplete,
      )
    }
  }

  if (shouldRequestSuppliers) {
    try {
      const result: {
        results: Suppliers
        searchLocation: Geolocation
      } = yield call(
        ApiSagas.get,
        '/myleaseplan/lpis/nearbySuppliersForBooking',
        {
          params: {
            ...params,
            selectedBookingDate: date.toISOString(true),
          },
        },
        { throwError: true },
      )

      yield put(new actions.LoadNearbySuppliersSuccess({ data: result }))
      yield call(onComplete, false)
    } catch ({ error }) {
      yield call(onComplete, true)
      // API_ERROR already dispatched
    }
  } else {
    yield call(onComplete, false)
  }
}

export function* handleError(action: ApiError): SagaIterator {
  if (
    action.payload?.error?.status === INCORRECT_SUPPLIERS_ADDRESS_STATUS_CODE
  ) {
    yield put(
      new actions.LoadNearbySuppliersFailed({
        data: { reason: 'invalidAddress' },
      }),
    )
  }
}

export function* validateByAddress(
  action: actions.ValidateByAddress,
): SagaIterator {
  const { location, onComplete } = action.payload
  const { street, city, postcode, country } = mapLocation(location)

  try {
    const result = yield call(
      ApiSagas.get,
      '/myleaseplan/lpis/suppliers/findgeobyaddress',
      {
        params: {
          street,
          city,
          postcode,
          country,
        },
      },
      { throwError: true },
    )

    yield call(onComplete, !result.success)
  } catch {
    yield call(onComplete, true)
  }
}

export function* loadContracts({
  payload: { selectedContractId },
}: actions.LoadContracts): SagaIterator {
  yield call(loadUserContracts)

  const currentContract = yield select(getCurrentContract)
  const onlyLeaseContracts = yield select(getLeaseContracts)

  // set current contract if we have selectedContractId and it's valid
  // need it for mobile app multiple vehicle support
  if (
    selectedContractId &&
    onlyLeaseContracts.some(({ id }: Contract) => id === selectedContractId)
  ) {
    yield put(
      new actions.SetCurrentContract({
        contractId: selectedContractId,
      }),
    )
  } else if (
    onlyLeaseContracts.length &&
    (!currentContract ||
      !onlyLeaseContracts.some(({ id }: Contract) => id === currentContract.id))
  ) {
    const firstContract = yield select(getFirstContract)

    yield put(new actions.SetCurrentContract({ contractId: firstContract.id }))
  }
}

export function* handleSetCurrentContract({
  payload,
}: actions.SetCurrentContract): SagaIterator {
  yield put(new actions.DefineCurrentContractId(payload.contractId))
  yield put(new quotesActions.DefineCurrentQuoteId(null))
}

export function* getRegistrationCertificateData(): SagaIterator {
  const locale: Locales = yield select(getLocale)
  const allowedLocales = [Locales.FR, Locales.EN_FR]

  if (!allowedLocales.includes(locale)) {
    return
  }

  const contract = yield select(getCurrentContract)
  const requestUrl = '/myleaseplan/lpis/registration-certificate-data'
  const requestParams = {
    params: {
      registrationNumber: contract.vehicle.licenseRegistration,
    },
    headers: {
      Accept: 'application/json',
    },
  }

  const options = {
    throwError: true,
  }

  try {
    const fileContent: RegistrationCertificate = yield call(
      ApiSagas.get,
      requestUrl,
      requestParams,
      options,
    )

    yield put(new actions.GetRegistrationCertificateDataSuccess(fileContent))
  } catch {
    yield put(new actions.GetRegistrationCertificateDataFailed())
  }
}

export default [
  takeLatest(actions.ActionTypes.LOAD_CONTRACTS, loadContracts),
  takeLatest(actions.ActionTypes.LOAD_NOTIFICATIONS, loadNotifications),
  takeLatest(actions.ActionTypes.LOAD_POLICY_DOCUMENTS, loadPolicyDocuments),
  takeLatest(
    actions.ActionTypes.DOWNLOAD_POLICY_DOCUMENT,
    downloadPolicyDocument,
  ),
  takeLatest(
    actions.ActionTypes.DOWNLOAD_REGISTRATION_CERTIFICATE,
    downloadRegistrationCertificate,
  ),
  takeEvery(actions.ActionTypes.VALIDATE_BY_ADDRESS, validateByAddress),
  takeEvery(actions.ActionTypes.LOAD_NEARBY_SUPPLIERS, loadNearbySuppliers),
  takeEvery(ApiActionType.API_ERROR, handleError),
  takeLatest(
    actions.ActionTypes.SET_CURRENT_CONTRACT,
    handleSetCurrentContract,
  ),
  takeLatest(
    actions.ActionTypes.GET_REGISTRATION_CERTIFICATE_DATA,
    getRegistrationCertificateData,
  ),
]
