import React from 'react'
import { GetProps } from 'react-redux'

import RouteLink from 'mlp-client/src/localization/RouteLink'
import { isExternal, isJavascript, isMail, isPhone } from 'mlp-client/src/utils'

type RouteLinkProps = GetProps<typeof RouteLink>

export interface Props extends RouteLinkProps {
  'data-intent'?: string
  'data-journey'?: string
}

export const DynamicLink: React.FC<Props> = props => {
  const { to } = props
  const isToExternal = isExternal(to)

  if (isToExternal || isPhone(to) || isJavascript(to) || isMail(to)) {
    const targetAttr = props.target || (isToExternal ? '_blank' : '_self')
    // for security reasons it's recommended to set `rel` attribute when
    // a link is opened in a new window
    const relAttr = targetAttr === '_blank' ? 'noopener noreferrer' : undefined

    return (
      <a
        href={to}
        className={props.className}
        target={targetAttr}
        rel={relAttr}
        data-e2e-id={props['data-e2e-id']}
        data-tag-id={props['data-tag-id' || `link-${to}`]}
        data-intent={props['data-intent']}
        data-journey={props['data-journey']}
        style={props.style}
        onClick={props.onClick}
      >
        {props.children}
      </a>
    )
  }

  if (to) {
    return (
      <RouteLink
        baseUrl={props.baseUrl}
        to={to}
        locationState={props.locationState}
        exact={true}
        className={props.className}
        activeClassName={props.activeClassName}
        style={props.style}
        params={props.params}
        target={props.target}
        onClick={props.onClick}
        data-e2e-id={props['data-e2e-id']}
        data-tag-id={props['data-tag-id']}
        data-intent={props['data-intent']}
        data-journey={props['data-journey']}
      >
        {props.children}
      </RouteLink>
    )
  }

  return (
    <span
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </span>
  )
}

DynamicLink.displayName = 'DynamicLink'

export default DynamicLink
