import React from 'react'
import { Field } from 'react-final-form'

import { composeValidators } from 'mlp-client/src/form//utils'
import FileUpload from 'mlp-client/src/form/components/fields/file-upload-field/File'
import { TranslationType } from 'mlp-client/src/form/types'
import { Files } from 'mlp-client/src/form/components/fields/file-upload-field/types'

interface Props {
  name: string
  accept?: string
  validations?: ReadonlyArray<(value: Files) => TranslationType>
  label?: string
  disabled?: boolean
  multiple?: boolean
}

const FileUploadField: React.FC<Props> = ({
  name,
  validations,
  accept,
  label,
  disabled,
  multiple,
}) => (
  <Field name={name} validate={composeValidators(validations)}>
    {({ input }) => (
      <FileUpload
        onChange={input.onChange}
        value={input.value}
        accept={accept}
        label={label}
        disabled={disabled}
        multiple={multiple}
      />
    )}
  </Field>
)

export default FileUploadField
