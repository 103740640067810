import { Card, CardContent, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getTranslations } from 'mlp-client/src/localization/selectors'
import Map from 'mlp-client/src/components/map/Map'
import MarkerTooltip from 'mlp-client/src/components/map/MarkerTooltip'
import { Coordinates } from 'mlp-client/src/components/map/types'
import { AppState } from 'mlp-client/src/types'
import { StyledButtonLink } from 'mlp-client/src/components/button-link/StyledButtonLink'

const StyledSpacing = styled(Spacing)`
  width: 3rem;
  text-align: center;
  line-height: 0;
`

const StyledCardContent = styled.div`
  flex: 1 0 auto;
  text-overflow: ellipsis;
  overflow: hidden;
`

interface Props {
  title?: React.ReactNode
  icon?: React.ReactNode
  dataE2eId?: string
  label?: React.ReactNode
  btnLabel?: React.ReactNode
  onBtnClick?(e: React.MouseEvent<HTMLElement>): void
  location?: Location
  hasMargin?: boolean
  viewOnGoogleMaps: string
  children?: React.ReactNode
}

export interface Location {
  title: React.ReactNode
  coordinates: Coordinates
  address: string
}

type DefaultProps = Required<Pick<Props, 'hasMargin'>>

type InternalProps = Props & DefaultProps
export class FlowStepTile extends React.PureComponent<InternalProps> {
  static defaultProps: DefaultProps = {
    hasMargin: true,
  }

  render() {
    const {
      title,
      children,
      dataE2eId,
      icon,
      label,
      btnLabel,
      onBtnClick,
      location,
      hasMargin,
      viewOnGoogleMaps,
    } = this.props

    const main = (
      <Card>
        <CardContent>
          <StyledCardContent data-e2e-id={dataE2eId}>
            {icon && <StyledSpacing mr={2}>{icon}</StyledSpacing>}
            <StyledCardContent>
              <Grid
                alignContent="flex-start"
                alignItems="center"
                verticalGutter={false}
              >
                <GridItem span="grow">
                  <Headline variant="100" component="h6">
                    {title}
                  </Headline>
                </GridItem>
                <GridItem span="auto">
                  {onBtnClick && btnLabel && (
                    <StyledButtonLink
                      fontWeight="regular"
                      onClick={onBtnClick}
                      gutter={false}
                    >
                      {btnLabel}
                    </StyledButtonLink>
                  )}
                </GridItem>
              </Grid>
              <StyledCardContent>{children}</StyledCardContent>
            </StyledCardContent>
            {label}
          </StyledCardContent>
          {location && (
            <Spacing mt={2}>
              <Map
                customMarker={
                  <MarkerTooltip
                    title={location.title}
                    address={location.address}
                    viewOnGoogleMaps={viewOnGoogleMaps}
                  />
                }
                coordinates={location.coordinates}
              />
            </Spacing>
          )}
        </CardContent>
      </Card>
    )

    return hasMargin ? <Spacing mb={1}> {main} </Spacing> : main
  }
}

const mapStateToProps = (state: AppState) =>
  getTranslations(state, {
    viewOnGoogleMaps: 'myLeaseplan.timeline.maintenance.detail.map.route',
  })

export default connect(mapStateToProps)(FlowStepTile)
