import _get from 'lodash/get'
import moment, { MomentInput } from 'moment'
import React from 'react'

import { Vehicle } from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicense'
import { isFuelManagement } from 'mlp-client/src/features-configuration/lu/utils'
import {
  ContractCurrency,
  ContractOptionsListPropertyList,
  ContractsFeatureConfig,
  FuelCard,
  SectionPropertyList,
  ServicesConfig,
  ServiceSectionKeys,
} from 'mlp-client/src/features-configuration/features-types/LpisTypes'
import { localizedPrice } from 'mlp-client/src/components/price/Price'
import { MileageFormatter } from 'mlp-client/src/contracts/components/MileageFormatter/MileageFormatter'
import { formatDate, formatNumber } from 'mlp-client/src/utils'
import { Quote } from 'mlp-client/src/quotes/types'
import { isQuote } from 'mlp-client/src/quotes/utils'
import { YesNoShortcut } from 'mlp-client/src/enums'
import Translation from 'mlp-client/src/localization/Translation'

import {
  ColumnLayout,
  ContractOptionsListMaintenanceProperties,
  ContractOptionsListProperties,
  ContractOptionsListRoadsideAssistanceProperties,
  ContractOptionsListTyresProperties,
  ContractSectionProperties,
  ContractTypes,
  InsuranceSectionProperties,
  TyresSectionProperties,
  VehicleSectionProperties,
  ViewContractSections,
  ViewContractTabs,
} from './enums'
import {
  Contract,
  ContractProperties,
  Contracts,
  ContractVehicle,
  IncludedServices,
  InsuranceProperties,
  ListProperties,
  ListProperty,
  OCS_FLEET_MANAGEMENT,
  OptionListProperties,
  OptionsProperty,
  OptionTileInfo,
  Service,
  Services,
  TyreProperties,
  Tyres,
  VehicleProperties,
} from './types'

export const isOrderOrContract = (
  entity: Contract | Quote,
): entity is Contract =>
  entity &&
  entity.contractType &&
  Object.values(ContractTypes).some(v => v === entity.contractType)

export const formatPower = (power: string): string =>
  power ? formatNumber(parseInt(power, 10)) : ''

export const getEndDateOfContract = (contract: Contract) => {
  const date = getEndDateOfContractAsString(contract)

  return date ? moment(date) : undefined
}

export const getEndDateOfContractAsString = (
  contract: Contract,
): string | undefined => {
  if (!contract) {
    return undefined
  }

  return 'simulatedEndDate' in contract && contract.simulatedEndDate
    ? contract.simulatedEndDate
    : contract.endDate
}

export const formatContractDate = (contractDate: moment.Moment) =>
  formatDate(contractDate, 'LL')

export const canReturnCar = (
  contractEndDate: moment.Moment,
  daysToReturn: number,
  currentDate: moment.Moment = moment(),
  disableEndOfLeaseAfterCurrentDate: boolean = true,
): boolean => {
  if (!contractEndDate) {
    return false
  }

  const isPossibleToReturnCar =
    moment
      .duration(contractEndDate.diff(currentDate.startOf('day')))
      .asDays() <= daysToReturn

  return disableEndOfLeaseAfterCurrentDate
    ? contractEndDate.isSameOrAfter(currentDate) && isPossibleToReturnCar
    : isPossibleToReturnCar
}

export const isContractExpired = (
  contractEndDate: moment.Moment,
  currentDate: moment.Moment = moment(),
): boolean => (contractEndDate ? contractEndDate.isBefore(currentDate) : false)

export const getContractId = (contract: Contract) => contract.nolsId

export const getLicensePlate = (
  contract: Contract | Quote,
): string | undefined => _get(contract, ['vehicle', 'licenseRegistration'])

// @TODO logic for top navigation which is not guarantee expected result
export const getMakeModel = (contract: Contract | Quote): string => {
  if (isOrderOrContract(contract)) {
    const { make, model } = contract.vehicle

    // `make` is sometimes empty, it seems, but it's copied into `model`
    const prefix = make ? `${make} ` : ''

    return `${prefix}${model}`
  } else if (isQuote(contract)) {
    const { make, model } = contract

    const prefix = make ? `${make} ` : ''

    return `${prefix}${model}`
  }

  return ''
}

export const getShortMakeModel = (contract: Contract | Quote): string =>
  getMakeModel(contract).trim().split(' ', 2).join(' ')

export const mapContractToVehicle = (contract: Contract | Quote): Vehicle => ({
  id: contract.id,
  model: getShortMakeModel(contract),
  ...(isOrderOrContract(contract)
    ? { isFuelManagement: isFuelManagement(contract.type) }
    : {}),
  ...(isQuote(contract)
    ? { imageUrl: contract.imageUrl, status: contract.status }
    : { licensePlate: getLicensePlate(contract) }),
})

export const getVehicleByContractId = (
  contracts: Contracts,
  id: string,
): Vehicle => {
  const contract = contracts.find(contract => contract.id === id)

  return contract && mapContractToVehicle(contract)
}

export const getColumnSizeFromLayout = (layout: ColumnLayout): number => {
  if (layout === ColumnLayout.double) {
    return 6
  }

  return 12
}

export const getTabTitle = (tabId: string): string => {
  switch (tabId) {
    case ViewContractTabs.Contract:
      return 'myLeaseplan.contract.subTitle'

    case ViewContractTabs.Vehicle:
      return 'myLeaseplan.contract.title'
  }
}

export const isEmptyObject = (obj: object): boolean =>
  !Object.values(obj).some(prop => prop)

export const getExtension = (fileName: string) =>
  (fileName.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1] || null

export const getBaseFileName = (fileName: string) =>
  fileName.replace(/\.[^/.]+$/, '')

export const getLastKnownMileage = (contract: Contract): number | undefined => {
  if (!contract) {
    return undefined
  }

  const { lastKnownFuelMileage, lastKnownMileage } = contract.vehicle

  if (!lastKnownMileage) {
    return undefined
  }

  const parsedLastKnownFuelMileage = parseInt(lastKnownFuelMileage, 10)
  const parsedLastKnownMileage = parseInt(lastKnownMileage, 10)

  return parsedLastKnownFuelMileage &&
    parsedLastKnownFuelMileage > parsedLastKnownMileage
    ? parsedLastKnownFuelMileage
    : parsedLastKnownMileage
}

export const getMaximumAllowedMileage = (
  contract: Contract,
): number | undefined => {
  if (!contract) {
    return undefined
  }

  const maximumAllowedMileage = contract.maximumAllowedMileage

  return maximumAllowedMileage ? parseInt(maximumAllowedMileage, 10) : undefined
}

export const getVehicleType = (vehicle: ContractVehicle): string =>
  vehicle?.type ?? ''

export const findOrderContract = (contracts: Contracts): Contract | undefined =>
  contracts.find(contract => contract.contractType === ContractTypes.ORDER)

export const filterOrderContracts = (contracts: Contracts): Contracts =>
  contracts.filter(contract => contract.contractType === ContractTypes.ORDER)

export const filterOrderContractsIfMoreThanOne = (
  contracts: Contracts,
): Contracts => {
  const contractsWithoutOrder = contracts.filter(
    contract => contract.contractType !== 'Order',
  )
  const hasMoreThanOneOrderContract =
    contracts.length - contractsWithoutOrder.length > 1

  return hasMoreThanOneOrderContract ? contractsWithoutOrder : contracts
}

export const getFirstContractId = (
  contracts: Contracts,
): string | undefined => {
  const firstLeaseContract = contracts.find(
    contract => contract.contractType === ContractTypes.LEASE,
  )

  if (firstLeaseContract) {
    return firstLeaseContract.id
  }
}

export const getRoadsideAssistanceProperties = (
  includedServices: IncludedServices,
): OptionListProperties => [
  {
    key:
      ContractOptionsListRoadsideAssistanceProperties.EUROPEAN_COVERAGE_MODULE,
    title:
      'myLeaseplan.contract.services.roadSideAssistance.includedEuropeanCoverage',
    value: includedServices.roadsideAssistanceModule,
  },
]
export const getTyresModuleProperties = (
  includedServices: IncludedServices,
): OptionListProperties => [
  {
    key: ContractOptionsListTyresProperties.TYRE_REPLACEMENT_MODULE,
    title: 'myLeaseplan.contract.services.tyres.tyreReplacement.title',
    value: includedServices.contractTyresModule,
  },
  {
    key: ContractOptionsListTyresProperties.TYRE_STORAGE,
    title: 'myLeaseplan.contract.services.tyres.tyreSeasonalChange.title',
    value: includedServices.tyreStorage,
  },
  {
    key: ContractOptionsListTyresProperties.TYRE_STORAGE_LOCATION,
    title: 'myLeaseplan.contract.services.tyres.tyreStorageLocation',
    value: Boolean(includedServices.tyreStorageLocation?.enabled),
    description: includedServices.tyreStorageLocation?.locationName ?? '',
  },
]
export const getMaintenanceProperty = (
  includedServices: IncludedServices,
): OptionListProperties => [
  {
    key: ContractOptionsListMaintenanceProperties.MAINTENANCE_MODULE,
    title: 'myLeaseplan.contract.services.types.maintenanceService',
    value: includedServices.maintenanceModule,
  },
  {
    key: ContractOptionsListMaintenanceProperties.REPLACEMENT_VEHICLE_MODULE,
    title: 'myLeaseplan.contract.services.types.replacementCar',
    value: includedServices.replacementVehicleModule,
  },
  {
    key: ContractOptionsListMaintenanceProperties.CAR_WASH_MODULE,
    title: 'myLeaseplan.contract.additionalServices.carWash.title',
    value: includedServices.carWash,
  },
  ...(Boolean(includedServices?.pickUpAndDeliveryModule)
    ? [
        {
          key:
            ContractOptionsListMaintenanceProperties.PICK_UP_AND_DELIVERY_MODULE,
          title: 'myLeaseplan.contract.additionalServices.pickupDelivery.title',
          value: includedServices.pickUpAndDeliveryModule,
        },
      ]
    : []),
]

export const getIncludedServices = (
  contract: Contract,
  servicesConfig?: ServicesConfig,
): IncludedServices => {
  const {
    maintenanceModule,
    replacementVehicleModule,
    roadsideAssistanceModule,
    tyres,
    carWash,
    fuelCards,
  } = contract
  let includedServices: IncludedServices = {
    maintenanceModule,
    replacementVehicleModule,
    roadsideAssistanceModule,
    carWash: isShortcutTrue(carWash),
    isFuelCardsEnabled: fuelCards,
    contractTyresModule: false,
    tyreStorage: false,
  }

  if (tyres) {
    const { contractTyresModule, tyreStorage, tyreStorageLocationName } = tyres

    includedServices = {
      ...includedServices,
      contractTyresModule,
      tyreStorage,
      tyreStorageLocation: tyreStorageLocationName
        ? {
            enabled: true,
            locationName: tyreStorageLocationName,
          }
        : null,
    }
  }

  if (servicesConfig) {
    includedServices = {
      ...includedServices,
      pickUpAndDeliveryModule: Boolean(
        servicesConfig.maintenance?.pickUpAndDelivery,
      ),
      damageService: Boolean(servicesConfig.damageService),
      radioLicenceFee: Boolean(servicesConfig.radioLicenceFee),
    }
  }

  return includedServices
}

export const isShortcutTrue = (value: string): boolean =>
  value === YesNoShortcut.YES
/**
 * Contract sections
 */

export const getBooleanTranslationKey = (value?: boolean): string =>
  'myLeaseplan.genericNotifications.' + (value ? 'yes' : 'No')

export const getContractData = (contract: Contract): ContractProperties => {
  if (!contract) {
    return null
  }

  const DATE_FORMAT = 'LL'
  const durationValue = contract.duration
  const maxMilesValue = contract.maximumAllowedMileage
  const startDateValue = contract.startDate
  const endDateValue = getEndDateOfContractAsString(contract)
  const taxationValue = contract.taxationValue
  const environmentalTaxationValue = contract.environmentalTaxationValue

  const nextInspectionDate =
    contract.vehicle && contract.vehicle.nextInspectionDate
      ? moment(contract.vehicle.nextInspectionDate).format(DATE_FORMAT)
      : ''

  const { lastKnownMileage, dateLastKnownMileage, lastKnownFuelMileage } =
    contract.vehicle ?? {}

  return {
    number: contract.id,
    nolsDisplayNumber: contract.nolsDisplayNumber,
    objectId: contract.objectId,
    taxationValue: taxationValue || '',
    environmentalTaxationValue: environmentalTaxationValue || '',
    startDate: startDateValue ? moment(startDateValue).format(DATE_FORMAT) : '',
    endDate: endDateValue
      ? moment(endDateValue).format(DATE_FORMAT)
      : nextInspectionDate,
    dateLastKnownMileage: dateLastKnownMileage
      ? moment(dateLastKnownMileage).format(DATE_FORMAT)
      : '',
    type: contract.type,
    maxMileage: maxMilesValue ? parseInt(maxMilesValue, 10) : '',
    duration: durationValue ? parseInt(durationValue, 10) : '',
    lastKnownMileage: Number(lastKnownMileage) || '',
    lastKnownFuelMileage: Number(lastKnownFuelMileage) || '',
    maintenanceModule: getBooleanTranslationKey(contract.maintenanceModule),
    insuranceModule: getBooleanTranslationKey(contract.isInsuranceAvailable),
    fuelModule: getBooleanTranslationKey(contract.isFuelCardsAvailable),
    tyresModule: getBooleanTranslationKey(contract.tyres?.contractTyresModule),
  }
}

export const getContractProperties = (
  contract: Contract,
  currency?: ContractCurrency,
): ListProperties => {
  const contractProperties = getContractData(contract)

  if (!contractProperties || isEmptyObject(contractProperties)) {
    return []
  }

  return [
    {
      label: 'myLeaseplan.contract.contract.properties.number',
      key: ContractSectionProperties.NUMBER,
      value: contractProperties.number,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.number',
      key: ContractSectionProperties.DISPLAY_NUMBER,
      value: contractProperties.nolsDisplayNumber,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.number',
      key: ContractSectionProperties.OBJECT_ID,
      value: contractProperties.objectId,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.startDate',
      key: ContractSectionProperties.START_DATE,
      value: contractProperties.startDate,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.endDate',
      key: ContractSectionProperties.END_DATE,
      value: contractProperties.endDate,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.maintenance',
      key: ContractSectionProperties.CONTRACTED_MAINTENANCE,
      value: contractProperties.maintenanceModule,
      translateValue: true,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.tyres',
      key: ContractSectionProperties.CONTRACTED_TYRES,
      value: contractProperties.tyresModule,
      translateValue: true,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.insurance',
      key: ContractSectionProperties.CONTRACTED_INSURANCE,
      value: contractProperties.insuranceModule,
      translateValue: true,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.fuel',
      key: ContractSectionProperties.CONTRACTED_FUEL,
      value: contractProperties.fuelModule,
      translateValue: true,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.taxationValue',
      key: ContractSectionProperties.TAXATION_VALUE,
      value:
        contractProperties.taxationValue &&
        (currency
          ? localizedPrice(
              Number(contractProperties.taxationValue),
              currency.code,
              currency.format,
            )
          : localizedPrice(Number(contractProperties.taxationValue), 'EUR')),
    },
    {
      label:
        'myLeaseplan.contract.contract.properties.environmentalTaxationValue',
      key: ContractSectionProperties.ENVIRONMENTAL_TAXATION_VALUE,
      value:
        contractProperties.environmentalTaxationValue &&
        currency &&
        localizedPrice(
          Number(contractProperties.environmentalTaxationValue),
          currency.code,
          currency.format,
        ),
    },
    {
      label: 'myLeaseplan.contract.contract.properties.maxMileage',
      key: ContractSectionProperties.MAX_MILEAGE,
      value: <MileageFormatter mileage={contractProperties.maxMileage} />,
    },
    {
      //only DE has lastKnownFuelMileage and in this case we use another key
      label: contractProperties.lastKnownFuelMileage
        ? 'myLeaseplan.contract.car.mileageGarage'
        : 'myLeaseplan.contract.car.lastKnownMileage',
      key: ContractSectionProperties.LAST_KNOWN_MILEAGE,
      value: contractProperties.lastKnownMileage && (
        <MileageFormatter mileage={contractProperties.lastKnownMileage} />
      ),
    },
    {
      label: 'myLeaseplan.contract.car.mileageFuelStop',
      key: ContractSectionProperties.LAST_KNOWN_FUEL_MILEAGE,
      value: contractProperties.lastKnownFuelMileage && (
        <MileageFormatter mileage={contractProperties.lastKnownFuelMileage} />
      ),
    },
    {
      label: 'myLeaseplan.contract.car.dateLastKnownMileage',
      key: ContractSectionProperties.DATE_LAST_KNOWN_MILEAGE,
      value: contractProperties.dateLastKnownMileage,
    },
    {
      label: 'myLeaseplan.contract.contract.properties.type',
      key: ContractSectionProperties.TYPE,
      value: contractProperties.type,
    },
  ]
}
/**
 * Tyres section
 */

export const getTyresData = (contract: Contract): TyreProperties => {
  const { tyres } = contract

  if (!tyres?.contractTyresModule) {
    return null
  }

  return {
    contractTyreBrand: tyres.contractTyreBrand || '',
    contractTyreCategory: tyres.contractTyreCategory || '',
    contractTyreLimit: isNaN(Number(tyres.contractTyreLimit))
      ? ''
      : tyres.contractTyreLimit,
    standardTyreUsed: getUsedTyres(tyres),
    remainingTyres: getRemainingTyres(tyres),
    contractTyreSize: tyres.contractTyreSize || '',
    contractTyrePickup: tyres.contractTyrePickup || '',
    vehicleTyreType: tyres.vehicleTyreType || '',
    vehicleWinterSummerTyres: tyres.vehicleWinterSummerTyres || '',
    contractTyresModuleDescription: tyres.contractTyresModuleDescription || '',
  }
}

export const getUsedTyres = (tyres: Tyres): string => {
  if (!tyres.standardTyreUsed || isNaN(Number(tyres.standardTyreUsed))) {
    return ''
  }

  let usedTyres = isNaN(Number(tyres.standardTyreUsed))
    ? 0
    : Number(tyres.standardTyreUsed)

  usedTyres += isNaN(Number(tyres.winterTyreUsed))
    ? 0
    : Number(tyres.winterTyreUsed)

  return usedTyres.toString()
}

export const getRemainingTyres = (tyres: Tyres): string => {
  if (!tyres.contractTyreLimit || isNaN(Number(tyres.contractTyreLimit))) {
    return ''
  }

  let remainingTyres = +tyres.contractTyreLimit

  remainingTyres -= isNaN(Number(tyres.standardTyreUsed))
    ? 0
    : Number(tyres.standardTyreUsed)

  remainingTyres -= isNaN(Number(tyres.winterTyreUsed))
    ? 0
    : Number(tyres.winterTyreUsed)

  if (remainingTyres < 0) {
    remainingTyres = 0
  }

  return remainingTyres.toString()
}

export const getTyreCategoryType = (tyresProperties: TyreProperties) => {
  if (tyresProperties?.contractTyreCategory?.length) {
    return (
      <Translation
        id={`myLeaseplan.contract.car.tyres.categoryType.${tyresProperties?.contractTyreCategory.toLowerCase()}`}
        defaultLabel={tyresProperties?.contractTyreCategory}
      />
    )
  }

  return null
}

export const getTyresProperties = (contract: Contract): ListProperties => {
  const tyresProperties = getTyresData(contract)

  if (!tyresProperties || isEmptyObject(tyresProperties)) {
    return []
  }

  return [
    {
      key: TyresSectionProperties.BRAND,
      label: 'myLeaseplan.contract.car.tyres.brand',
      value: tyresProperties.contractTyreBrand,
    },
    {
      key: TyresSectionProperties.CATEGORY,
      label: 'myLeaseplan.contract.car.tyres.category',
      value: getTyreCategoryType(tyresProperties),
    },
    {
      key: TyresSectionProperties.LIMIT,
      label: 'myLeaseplan.contract.car.tyres.limit',
      value: tyresProperties.contractTyreLimit,
    },
    {
      key: TyresSectionProperties.STANDARD_TYRE_USED,
      label: 'myLeaseplan.contract.car.tyres.used.standard',
      value: tyresProperties.standardTyreUsed,
    },
    {
      key: TyresSectionProperties.WINTER_TYRE_USED,
      label: 'myLeaseplan.contract.car.tyres.used.winter',
      value: tyresProperties.winterTyreUsed,
    },
    {
      key: TyresSectionProperties.REMAINING_TYRES,
      label: 'myLeaseplan.contract.car.tyres.remaining',
      value: tyresProperties.remainingTyres,
    },
    {
      key: TyresSectionProperties.SIZE,
      label: 'myLeaseplan.contract.car.tyres.size',
      value: tyresProperties.contractTyreSize,
    },
    {
      key: TyresSectionProperties.TYRES_MODULE_DESCRIPTION,
      label: 'myLeaseplan.tyres.dashboard.title',
      value: tyresProperties.contractTyresModuleDescription,
    },
  ]
}
/**
 * Vehicle section
 */

export const getVehicleData = (
  contract: Contract,
): VehicleProperties | null => {
  const { vehicle } = contract

  if (!vehicle) {
    return null
  }

  return {
    licensePlate: vehicle.licenseRegistration,
    make: vehicle.make || '',
    model: vehicle.model || '',
    type: getVehicleType(vehicle),
    fuel: vehicle.fuelType || '',
    color: vehicle.colour || '',
    power: formatPower(vehicle.performance || ''),
    vin: vehicle.vin || '',
    co2: vehicle.co2 || 0,
    transmission: vehicle.typeOfTransmission || '',
    estimatedFuelKm: vehicle.estimatedFuelKm,
  }
}

export const getVehicleProperties = (contract: Contract): ListProperties => {
  const vehicleProperties = getVehicleData(contract)

  if (!vehicleProperties || isEmptyObject(vehicleProperties)) {
    return []
  }

  return [
    {
      key: VehicleSectionProperties.LICENSE_PLATE,
      label: 'myLeaseplan.contract.car.properties.licensePlate',
      value: vehicleProperties.licensePlate,
    },
    {
      key: VehicleSectionProperties.MAKE,
      label: 'myLeaseplan.contract.car.properties.make',
      value: vehicleProperties.make,
    },
    {
      key: VehicleSectionProperties.MODEL,
      label: 'myLeaseplan.contract.car.properties.model',
      value: vehicleProperties.model,
    },
    {
      key: VehicleSectionProperties.COLOR,
      label: 'myLeaseplan.contract.car.properties.color',
      value: vehicleProperties.color,
    },
    {
      key: VehicleSectionProperties.TYPE,
      label: 'myLeaseplan.contract.car.properties.type',
      value: vehicleProperties.type,
    },
    {
      key: VehicleSectionProperties.VIN,
      label: 'myLeaseplan.contract.car.properties.vin',
      value: vehicleProperties.vin,
    },
    {
      key: VehicleSectionProperties.FUEL,
      label: 'myLeaseplan.contract.car.properties.fuel',
      value: vehicleProperties.fuel,
    },
    {
      key: VehicleSectionProperties.POWER,
      label: 'myLeaseplan.contract.car.properties.power',
      value: formatNumber(vehicleProperties.power),
    },
    {
      key: VehicleSectionProperties.CO2,
      label: 'myLeaseplan.contract.car.properties.co2',
      value: formatNumber(vehicleProperties.co2),
    },
    {
      key: VehicleSectionProperties.ESTIMATED_FUEL_KM,
      label: 'myLeaseplan.contract.car.properties.estimatedKm',
      value: formatNumber(vehicleProperties.estimatedFuelKm, '', '0.[00]'),
    },
    {
      key: VehicleSectionProperties.TRANSMISSION,
      label: 'myLeaseplan.contract.specifications.transmission',
      value: vehicleProperties.transmission,
    },
  ]
}
/**
 * Insurance Section
 */

export const getInsuranceData = (contract: Contract): InsuranceProperties => {
  const { insurance, policyNumber } = contract
  const { coverage, fullDescription = '' } = insurance || {}

  if (coverage) {
    const {
      insuranceType,
      number,
      insuranceCompany,
      validTo,
      validFrom,
    } = coverage

    return {
      insuranceType,
      number,
      policyNumber,
      insuranceCompany,
      fullDescription,
      validTo,
      validFrom,
    }
  }

  return {
    insuranceType: '',
    number: '',
    insuranceCompany: '',
    policyNumber: '',
    validTo: '',
    validFrom: '',
    fullDescription,
  }
}

export const getInsuranceProperties = (contract: Contract): ListProperties => {
  const insuranceProperties = getInsuranceData(contract)
  // default `validTo` can be set in NOLS and have value '0001-01-01T00:00:00',
  // insurance with default `validTo` date should be shown according to MYLP-5737
  const isNotDefaultValidTo = moment(insuranceProperties.validTo).isAfter(
    insuranceProperties.validFrom,
  )

  if (
    !insuranceProperties ||
    isEmptyObject(insuranceProperties) ||
    (moment().isAfter(insuranceProperties.validTo) && isNotDefaultValidTo)
  ) {
    return []
  }

  return [
    {
      key: InsuranceSectionProperties.COMPANY,
      label: 'myLeaseplan.contract.contract.properties.insuranceCompany',
      value: insuranceProperties.insuranceCompany,
    },
    {
      key: InsuranceSectionProperties.TYPE,
      label: 'myLeaseplan.contract.contract.properties.insuranceType',
      value: insuranceProperties.insuranceType,
    },
    {
      key: InsuranceSectionProperties.NUMBER,
      label: 'myLeaseplan.contract.contract.properties.number',
      value: insuranceProperties.number,
    },
    {
      key: InsuranceSectionProperties.POLICY_NUMBER,
      label: 'myLeaseplan.contract.contract.properties.number',
      value: insuranceProperties.policyNumber,
    },
    {
      key: InsuranceSectionProperties.FULL_DESCRIPTION,
      value: insuranceProperties.fullDescription,
    },
  ]
}

export const getSectionData = (
  contract: Contract,
  sectionConfig: SectionPropertyList,
  sectionKey: ViewContractSections,
  currency?: ContractCurrency,
  servicesConfig?: ServicesConfig,
): { title: string; properties: ListProperties } => {
  let sectionProperties: ListProperties = []
  let properties: ListProperties
  let title = ''

  switch (sectionKey) {
    case ViewContractSections.Contract:
      sectionProperties = getContractProperties(contract, currency)
      title = 'myLeaseplan.contract.contract.title'
      break

    case ViewContractSections.Insurance:
      sectionProperties = getInsuranceProperties(contract)
      title = 'myLeaseplan.contract.contract.insurance'
      break

    case ViewContractSections.VehicleDetails:
      sectionProperties = getVehicleProperties(contract)
      title = 'myLeaseplan.contract.car.title'
      break

    case ViewContractSections.Tyres:
      sectionProperties = getTyresProperties(contract)
      title = 'myLeaseplan.tyres.dashboard.title'
      break
  }

  // Filter the section properties based on the configuration
  // this enables us to configure the properties we want to display
  if (servicesConfig && contract.services) {
    properties = sectionProperties.filter(
      filterSectionDataByServices(
        contract.services,
        servicesConfig,
        sectionKey,
        sectionConfig,
      ),
    )
  } else {
    properties = sectionProperties.filter(
      ({ key, value }) => sectionConfig.includes(key) && (value || value === 0),
    )
  }

  return {
    title,
    properties,
  }
}

export const getContractOptionData = (
  contract: Contract,
  sectionConfig: ContractOptionsListPropertyList,
  sectionKey: ContractOptionsListProperties,
  servicesConfig?: ServicesConfig,
): OptionTileInfo | null => {
  let sectionProperties: OptionListProperties = []
  let properties: OptionListProperties = []
  let title = ''

  const includedServices: IncludedServices = getIncludedServices(
    contract,
    servicesConfig,
  )

  if (!includedServices || isEmptyObject(includedServices)) {
    return null
  }

  switch (sectionKey) {
    case ContractOptionsListProperties.MAINTENANCE:
      sectionProperties = getMaintenanceProperty(includedServices)
      title = 'myLeaseplan.contract.contract.maintenance'
      break

    case ContractOptionsListProperties.ROADSIDE_ASSISTANCE:
      sectionProperties = getRoadsideAssistanceProperties(includedServices)
      title = 'myLeaseplan.contract.contract.roadsideAssistance'
      break

    case ContractOptionsListProperties.TYRES:
      sectionProperties = getTyresModuleProperties(includedServices)
      title = 'myLeaseplan.contract.contract.tyres'
      break

    case ContractOptionsListProperties.DAMAGE_SERVICE:
      title = 'myLeaseplan.contract.additionalServices.damageService.title'
      break

    case ContractOptionsListProperties.RADIO_LICENCE_FEE:
      title = 'myLeaseplan.contract.contract.properties.radioLicence'
      break
  }

  if (servicesConfig && contract.services) {
    properties = sectionProperties.filter(
      filterContractOptionDataByServices(
        contract.services,
        servicesConfig,
        sectionKey,
        sectionConfig,
      ),
    )
  } else {
    properties = sectionProperties.filter(
      existAndIncludesInConfig(sectionConfig),
    )
  }

  return {
    title,
    properties,
  }
}

export const getMonthDiff = (from: MomentInput, to: MomentInput): number =>
  moment(from).diff(moment(to), 'months')
export const calculateExpectedMileage = (
  max: number,
  totalMonths: number,
  expiredMonths: number,
): number | undefined =>
  totalMonths > 0 && expiredMonths > 0 && max > 0
    ? (max / totalMonths) * expiredMonths
    : undefined
export const existAndIncludesInConfig = (
  sectionConfig: ContractOptionsListPropertyList,
) => ({ key, value }: OptionsProperty): boolean =>
  sectionConfig.includes(key) && value

export const isSectionAllowedByService = (
  services: Services,
  servicesConfig: ServicesConfig,
  sectionKey: ServiceSectionKeys,
): boolean => {
  const sectionServiceNumbers = servicesConfig[sectionKey]?.serviceNumbers

  return Boolean(sectionServiceNumbers)
    ? services.some(service =>
        sectionServiceNumbers.includes(service.serviceNumber),
      )
    : true
}

export const filterSectionDataByServices = (
  services: Services,
  servicesConfig: ServicesConfig,
  sectionKey: ViewContractSections,
  sectionConfig: SectionPropertyList,
) => ({ key, value }: ListProperty): boolean => {
  const serviceSection = servicesConfig[sectionKey]
  const isPropertyInTabsConfigAndHasValue =
    sectionConfig.includes(key) && (Boolean(value) || value === 0)

  if (!serviceSection) {
    return isPropertyInTabsConfigAndHasValue
  }

  const isPropertyInServicesConfig = Object.keys(serviceSection).includes(key)

  if (!isPropertyInServicesConfig && isPropertyInTabsConfigAndHasValue) {
    return true
  }

  const serviceSectionProperty = serviceSection[key]

  if (!serviceSectionProperty) {
    return false
  }

  return (
    isPropertyInServicesConfig &&
    services.some(
      ({ serviceNumber, description }: Service) =>
        (serviceSectionProperty.serviceNumbers?.includes(serviceNumber) ||
          serviceSectionProperty.description === description) &&
        (value || value === 0),
    )
  )
}

export const filterContractOptionDataByServices = (
  services: Services,
  servicesConfig: ServicesConfig,
  sectionKey: ContractOptionsListProperties,
  sectionConfig: ContractOptionsListPropertyList,
) => ({ key, value }: OptionsProperty): boolean => {
  const serviceSection = servicesConfig[sectionKey]
  const isPropertyInContractOptionsConfigAndHasValue =
    sectionConfig.includes(key) && value

  if (!serviceSection) {
    return isPropertyInContractOptionsConfigAndHasValue
  }

  const isPropertyInServicesConfig = Object.keys(serviceSection).includes(key)

  if (
    !isPropertyInServicesConfig &&
    isPropertyInContractOptionsConfigAndHasValue
  ) {
    return true
  }

  const serviceSectionProperty = serviceSection[key]

  if (!serviceSectionProperty) {
    return false
  }

  return (
    isPropertyInServicesConfig &&
    services.some(
      ({ serviceNumber, description }: Service) =>
        serviceSectionProperty.serviceNumbers?.includes(serviceNumber) ||
        serviceSectionProperty.description === description,
    )
  )
}

export const filterFuelCardsByServices = (services: Services) => (
  card: FuelCard,
): boolean =>
  services.some(({ serviceNumber }: Service) =>
    card?.serviceNumbers ? card?.serviceNumbers?.includes(serviceNumber) : true,
  )
export const getFuelType = (contract: Contract): string =>
  (getVehicleData(contract).fuel || '').toLowerCase()

export const mapContracts = (contracts: Contracts): Contracts =>
  contracts.map(contract => ({
    ...contract,
    vehicle: {
      ...contract.vehicle,
      make: contract.vehicle?.make?.toUpperCase(),
      model: contract.vehicle?.model?.toUpperCase(),
    },
  }))

const filterTaxationValuesFromContractConfig = (
  contractDetails: SectionPropertyList,
): SectionPropertyList =>
  contractDetails.filter(
    contractProperty =>
      !(
        contractProperty === ContractSectionProperties.TAXATION_VALUE ||
        contractProperty ===
          ContractSectionProperties.ENVIRONMENTAL_TAXATION_VALUE
      ),
  )

export const filterTaxationValues = (
  configuration: ContractsFeatureConfig,
  contractType?: string,
): ContractsFeatureConfig => {
  if (contractType?.includes(OCS_FLEET_MANAGEMENT)) {
    return {
      ...configuration,
      tabs: {
        ...configuration.tabs,
        [ViewContractSections.Contract]: {
          ...configuration.tabs[ViewContractSections.Contract],
          [ViewContractSections.Contract]: filterTaxationValuesFromContractConfig(
            configuration.tabs[ViewContractSections.Contract][
              ViewContractSections.Contract
            ],
          ),
        },
      },
    }
  }

  return configuration
}
