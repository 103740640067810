export const enum ActionTypes {
  SET_LOADING = '@@auth/SET_LOADING',
  AUTHORIZE = '@@auth/AUTHORIZE',
  LOGIN = '@@auth/LOGIN',
  LOGOUT = '@@auth/LOGOUT',
  PROCESS_LOGIN_RESPONSE = '@@auth/PROCESS_LOGIN_RESPONSE',
  PROCESS_LOGOUT_RESPONSE = '@@auth/PROCESS_LOGOUT_RESPONSE',
  SET_NEXT_ROUTE = '@@auth/SET_NEXT_ROUTE',
  CLEAR_NEXT_ROUTE = '@@auth/CLEAR_NEXT_ROUTE',
  CHANGE_PASSWORD = '@@auth/CHANGE_PASSWORD',
}

export class SetLoading {
  readonly type = ActionTypes.SET_LOADING

  constructor(public payload: { loading: boolean }) {}
}

export class Authorize {
  readonly type = ActionTypes.AUTHORIZE

  constructor(public payload: { connection?: string; route?: string }) {}
}

export class Login {
  readonly type = ActionTypes.LOGIN
}

export class Logout {
  readonly type = ActionTypes.LOGOUT

  constructor(public payload: { nextRoute: string }) {}
}

export class ProcessLoginResponse {
  readonly type = ActionTypes.PROCESS_LOGIN_RESPONSE

  constructor(public payload: { nextRoute: string }) {}
}

export class ProcessLogoutResponse {
  readonly type = ActionTypes.PROCESS_LOGOUT_RESPONSE

  constructor(public payload: { nextRoute: string }) {}
}

export class SetNextRoute {
  readonly type = ActionTypes.SET_NEXT_ROUTE

  constructor(public payload: { route: string }) {}
}

export class ClearNextRoute {
  readonly type = ActionTypes.CLEAR_NEXT_ROUTE
}

export class ChangePassword {
  readonly type = ActionTypes.CHANGE_PASSWORD

  constructor(public payload: { email: string }) {}
}

export type Actions =
  | SetLoading
  | Authorize
  | Login
  | Logout
  | ProcessLoginResponse
  | ProcessLogoutResponse
  | SetNextRoute
  | ClearNextRoute
  | ChangePassword
