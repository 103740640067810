import { Text } from '@leaseplan/ui'
import styled from 'styled-components'

export const OpeningHourWrapper = styled.div`
  display: flex;
`

export const DayWrapper = styled(Text)`
  flex-basis: 35%;
`
