import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import queryString from 'qs'

import { getAccessToken } from 'mlp-client/src/auth/auth'

import { defaultRequestHeadersSelector } from './selectors'
import { Api } from './api'
import * as ApiTypes from './types'

/**
 * Do API calls in a saga with 'call'.
 * Example: yield call(ApiSagas.get, '/cars', { params: locale: 'en-GB' })
 */
type ApiMethod =
  | typeof Api.get
  | typeof Api.post
  | typeof Api.put
  | typeof Api.patch

const getAuthorizationHeader = (): { Authorization: string } | undefined => {
  const accessToken = getAccessToken()

  return accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined
}

const stringifyHeader = (
  defaultHeaders: GenericObject,
  authorization: string,
) => `${queryString.stringify(defaultHeaders)}&Authorization=${authorization}`

export const enum ActionType {
  API_ERROR = 'API_ERROR',
}

export class ApiError {
  readonly type = ActionType.API_ERROR

  constructor(public payload: { error: GenericObject }) {}
}

export class ApiSagas {
  static *saga(
    fn: ApiMethod,
    url: string,
    props: {
      body?: GenericObject
      params?: GenericObject
      headers?: GenericObject
    },
    successAction?: ApiTypes.SuccessAction,
    errorAction?: ApiTypes.ErrorAction,
    options: ApiTypes.Options = {},
  ): SagaIterator {
    try {
      const authHeader = getAuthorizationHeader()
      const defaultHeaders: GenericObject = yield select(
        defaultRequestHeadersSelector,
      )
      const data = {
        ...props,
        headers: {
          ...defaultHeaders,
          ...authHeader,
          ...props.headers,
        },
      }
      const response = yield call(fn, url, data)
      const body = response?.body

      if (successAction) {
        yield put(successAction(body))
      }

      return body
    } catch (errorResponse) {
      const body = errorResponse.response?.body ?? {}
      const error = {
        status: errorResponse.status || 500,
        ...body,
      }

      if (errorAction) {
        yield put(errorAction(error))
      }

      // Do NOT build a catch all redirect for this action.
      yield put(new ApiError({ error }))

      if (options.throwError) {
        throw { error }
      }

      return { error }
    }
  }

  static *get(
    url: string,
    {
      params = {},
      headers = {},
      successAction,
      errorAction,
    }: ApiTypes.RequestWithParams = {},
    options: ApiTypes.Options = {},
  ): SagaIterator {
    return yield call(
      ApiSagas.saga,
      Api.get,
      url,
      { params, headers },
      successAction,
      errorAction,
      options,
    )
  }

  static *getStringifiedHeader() {
    const defaultHeaders: GenericObject = yield select(
      defaultRequestHeadersSelector,
    )
    const authHeader = getAuthorizationHeader()

    return stringifyHeader(defaultHeaders, authHeader.Authorization)
  }

  static *delete(
    url: string,
    {
      body = {},
      params = {},
      headers = {},
      successAction,
      errorAction,
    }: ApiTypes.RequestWithBodyAndParams = {},
    options: ApiTypes.Options = {},
  ): SagaIterator {
    return yield call(
      ApiSagas.saga,
      Api.delete,
      url,
      { body, params, headers },
      successAction,
      errorAction,
      options,
    )
  }

  static *post(
    url: string,
    {
      body = {},
      headers = {},
      successAction,
      errorAction,
    }: ApiTypes.RequestWithBody = {},
    options: ApiTypes.Options = {},
  ): SagaIterator {
    return yield call(
      ApiSagas.saga,
      Api.post,
      url,
      { body, headers },
      successAction,
      errorAction,
      options,
    )
  }

  static *put(
    url: string,
    {
      body = {},
      headers = {},
      params = {},
      successAction,
      errorAction,
    }: ApiTypes.RequestWithBodyAndParams = {},
    options: ApiTypes.Options = {},
  ): SagaIterator {
    return yield call(
      ApiSagas.saga,
      Api.put,
      url,
      { body, params, headers },
      successAction,
      errorAction,
      options,
    )
  }

  static *patch(
    url: string,
    {
      body = {},
      headers = {},
      successAction,
      errorAction,
    }: ApiTypes.RequestWithBody = {},
    options: ApiTypes.Options = {},
  ): SagaIterator {
    return yield call(
      ApiSagas.saga,
      Api.patch,
      url,
      { body, headers },
      successAction,
      errorAction,
      options,
    )
  }
}
