import { push } from 'connected-react-router'
import { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  getLocalizedRoute,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import { getCurrentContractId } from 'mlp-client/src/contracts/selectors'
import * as actions from 'mlp-client/src/request-for-information/actions'
import { User } from 'mlp-client/src/user/types'
import { getUser } from 'mlp-client/src/user/selectors'
import { ApiSagas } from 'mlp-client/src/api'

// This is default API options for all the sagas in this file
// if you want custom options, you can overide them per api call
const defaultRequestOptions = {
  throwError: true,
}

export function* submitRequestForInformation({
  payload: { formData, onComplete },
}: actions.SubmitRequestForInformation): SagaIterator {
  const user: User = yield select(getUser)
  const contractId = yield select(getCurrentContractId)
  const { topic, description } = formData
  let parsedTopic

  try {
    parsedTopic = JSON.parse(topic)
  } catch {
    parsedTopic = {}
  }

  const body = {
    contractId,
    userId: user.id,
    detailedMessage: description,
    caseCategory: parsedTopic.category,
    caseSubCategory: parsedTopic.subCategory,
    serviceType: parsedTopic.serviceType,
  }

  try {
    yield call(
      ApiSagas.post,
      '/myleaseplan/lpis/customerSupport',
      { body },
      defaultRequestOptions,
    )

    const thankYouUrl: string = yield select(
      getLocalizedRoute,
      'myLeaseplan.requestForInformation.thankYou',
    )

    yield call(onComplete)
    yield put(push(thankYouUrl))
  } catch {
    const error = yield select(
      getTranslation,
      'myLeaseplan.requestForInformation.failed',
    )

    yield call(onComplete, { error })
  }
}

export default [
  takeLatest(
    actions.ActionTypes.SUBMIT_REQUEST_FOR_INFORMATION,
    submitRequestForInformation,
  ),
]
