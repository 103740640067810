import {
  ButtonChevronRightIcon,
  ButtonLink,
  Grid,
  GridItem,
  Modal,
  ModalContent,
  Spacing,
} from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import { CalendarIcon } from '@velocity/ui/draft'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import {
  ActionBlock,
  NotificationCardContent,
  StyledCard,
} from 'mlp-client/src/dashboard/components/tiles/DashboardTile.styled'
import { NotificationProps } from 'mlp-client/src/dashboard/type'
import PredictiveMaintenanceUpdate from 'mlp-client/src/flows/predictive-maintenance/components/predictive-maintenance-update/PredictiveMaintenanceUpdate'
import PredictiveMaintenanceRejection from 'mlp-client/src/flows/predictive-maintenance/components/predictive-maintenance-rejection/PredictiveMaintenanceRejection'
import { AppState } from 'mlp-client/src/types'
import { getPredictiveMileageModalContractId } from 'mlp-client/src/contracts/selectors'
import { LoadNotifications } from 'mlp-client/src/contracts/actions'
import { GrayDarkText } from 'mlp-client/src/components/styled/TextStyle'

export interface Props extends NotificationProps {
  predictiveMileageModalContractId?: string
  loadNotifications(): void
}

interface State {
  showMaintenanceModal: boolean
  showMaintenanceRejectionModal: boolean
}

const StyledButtonLink = styled(ButtonLink)`
  margin-right: 20px;
`

class NotificationTile extends React.PureComponent<Props, State> {
  state = {
    showMaintenanceModal: false,
    showMaintenanceRejectionModal: false,
  }

  static getDerivedStateFromProps(nextProps: Props) {
    if (
      nextProps.predictiveMileageModalContractId &&
      nextProps.predictiveMileageModalContractId === nextProps.contractId
    ) {
      return { showMaintenanceModal: true }
    }

    return null
  }

  handleMaintenanceModalClose = () => {
    this.props.loadNotifications()
    this.setState({ showMaintenanceModal: false })
  }

  handleMaintenanceTriggerClick = () =>
    this.setState({ showMaintenanceModal: true })

  handleMaintenanceRejectionModalClose = () =>
    this.setState({ showMaintenanceRejectionModal: false })

  handleMaintenanceRejectionTriggerClick = () =>
    this.setState({ showMaintenanceRejectionModal: true })

  renderMaintenanceTile = () => {
    const { makeModel, contractId, notificationDetails } = this.props
    const { showMaintenanceModal, showMaintenanceRejectionModal } = this.state
    const bookButtonLink = (
      <>
        <Modal
          open={showMaintenanceModal}
          onClose={this.handleMaintenanceModalClose}
          data-e2e-id="notificationTilePredictiveMileagePopup"
        >
          <PredictiveMaintenanceUpdate
            contractId={contractId}
            predictedMileage={notificationDetails.predictedMileage}
            caseId={notificationDetails.caseId}
          />
        </Modal>
        <ButtonLink
          size="s"
          icon={ButtonChevronRightIcon}
          iconPosition="right"
          fontWeight="regular"
          onClick={this.handleMaintenanceTriggerClick}
          data-e2e-id="notificationTileViewDetailsButton"
        >
          <Translation id="myLeaseplan.notifications.maintenance.viewDetails" />
        </ButtonLink>
      </>
    )

    const noThanksButtonLink = (
      <>
        <Modal
          open={showMaintenanceRejectionModal}
          onClose={this.handleMaintenanceRejectionModalClose}
          data-e2e-id="notificationTilePredictiveMileageRejectionPopup"
        >
          <ModalContent>
            <PredictiveMaintenanceRejection
              contractId={contractId}
              caseId={notificationDetails.caseId}
              onFinished={this.handleMaintenanceRejectionModalClose}
            />
          </ModalContent>
        </Modal>
        <StyledButtonLink
          size="s"
          fontWeight="regular"
          data-e2e-id="notificationTileNoThanksButton"
          color="steel80"
          onClick={this.handleMaintenanceRejectionTriggerClick}
        >
          <Translation id="myLeaseplan.notifications.generic.noThanks" />
        </StyledButtonLink>
      </>
    )

    return (
      <StyledCard hover={false} stretch={true}>
        <NotificationCardContent>
          <Grid alignItems="center" verticalGutter={false}>
            <GridItem span="grow">
              <Spacing mb={1}>
                <Headline variant="100">
                  <Translation
                    id="myLeaseplan.notifications.maintenance.title"
                    replace={{ makeModel }}
                  />
                </Headline>
              </Spacing>
              <Grid gutter={false}>
                <GridItem>
                  <CalendarIcon size="s" color="#2D2D2D" />
                </GridItem>
                <GridItem span="grow">
                  <Spacing ml={0.5}>
                    <GrayDarkText variant="200">
                      <Translation id="myLeaseplan.notifications.maintenance.description" />
                    </GrayDarkText>
                  </Spacing>
                </GridItem>
              </Grid>
            </GridItem>
            <ActionBlock span={{ lap: 'auto', mobile: 12 }}>
              {bookButtonLink}
              {noThanksButtonLink}
            </ActionBlock>
          </Grid>
        </NotificationCardContent>
      </StyledCard>
    )
  }
  render() {
    return this.renderMaintenanceTile()
  }
}

export { NotificationTile }

type ReduxProps = Pick<Props, 'predictiveMileageModalContractId'>
type DispatchProps = Pick<Props, 'loadNotifications'>

export const mapStateToProps = (state: AppState): ReduxProps => ({
  predictiveMileageModalContractId: getPredictiveMileageModalContractId(state),
})

export const mapDispatchToProps: DispatchProps = {
  loadNotifications: () => new LoadNotifications(),
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTile)
