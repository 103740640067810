import { FlowCancellationPayload, PageViewEventPayload } from './types'

export const enum ActionTypes {
  FLOW_ACTIVATION = '@@myLeaseplan/analytics/FLOW_ACTIVATION',
  FLOW_NAVIGATION = '@@myLeaseplan/analytics/FLOW_NAVIGATION',
  PAGE_VIEW = '@@myLeaseplan/analytics/PAGE_VIEW',
  TRACK_PAGE_VIEW_EVENT = '@@myLeaseplan/analytics/TRACK_PAGE_VIEW_EVENT',
  MAIN_MENU_TOP_NAVIGATION = '@@myLeaseplan/analytics/MAIN_MENU_TOP_NAVIGATION',
  FOOTER_NAVIGATION = '@@myLeaseplan/analytics/FOOTER_NAVIGATION',
  FLOW_CANCELLATION = '@@myLeaseplan/analytics/FLOW_CANCELLATION',
  DASHBOARD_NAVIGATION = '@@myLeaseplan/analytics/DASHBOARD_NAVIGATION',
  VEHICLE_SELECTION = '@@myLeaseplan/analytics/VEHICLE_SELECTION',
}

export class FlowActivation {
  readonly type = ActionTypes.FLOW_ACTIVATION

  constructor(public payload: { eventLabel: string }) {}
}

export class FlowNavigation {
  readonly type = ActionTypes.FLOW_NAVIGATION

  constructor(
    public payload: {
      eventCategory: string
      eventAction: string
      eventLabel?: string
      flowName?: string
      currentStepNumber?: number
      totalStepNumberFlow?: number
    },
  ) {}
}

const objectValuesToLowercase = <T extends Record<string, any>>(obj: T): T =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string' ? value.toLowerCase() : value,
    }),
    {},
  ) as T

export class PageView {
  readonly type = ActionTypes.PAGE_VIEW
  payload: GenericObject

  constructor(payload: GenericObject) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export class TrackPageViewEvent {
  readonly type = ActionTypes.TRACK_PAGE_VIEW_EVENT
  payload: PageViewEventPayload

  constructor(payload: PageViewEventPayload) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export class FooterNavigation {
  readonly type = ActionTypes.FOOTER_NAVIGATION
  payload: { eventLabel: string }

  constructor(payload: { eventLabel: string }) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export class MainMenuTopNavigation {
  readonly type = ActionTypes.MAIN_MENU_TOP_NAVIGATION
  payload: { eventLabel: string }

  constructor(payload: { eventLabel: string }) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export class DashboardNavigation {
  readonly type = ActionTypes.DASHBOARD_NAVIGATION
  payload: { eventLabel: string }

  constructor(payload: { eventLabel: string }) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export class VehicleSelection {
  readonly type = ActionTypes.VEHICLE_SELECTION
  payload: { eventLabel: string }

  constructor(payload: { eventLabel: string }) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export class FlowCancellation {
  readonly type = ActionTypes.FLOW_CANCELLATION
  payload: FlowCancellationPayload

  constructor(payload: FlowCancellationPayload) {
    this.payload = objectValuesToLowercase(payload)
  }
}

export type Action = PageView | TrackPageViewEvent
