import React from 'react'
import { VelocityProvider } from '@velocity/ui'

import 'mlp-client/src/styles/main.scss'

const DefaultTheme: React.FC = ({ children }) => (
  <React.Fragment>
    <VelocityProvider>{children}</VelocityProvider>
  </React.Fragment>
)

export default DefaultTheme
