import { Suppliers } from 'mlp-client/src/flows/tyres/types'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { Options } from 'mlp-client/src/form/types'
import {
  DamageCauses,
  PartyDamages,
} from 'mlp-client/src/flows/damage-report/types'
import {
  CashClaimCategory,
  FuelType,
  RoadSideAssistanceType,
} from 'mlp-client/src/flows/cash-claim/enums'

import * as data from '../data'

const selectDamageCauseTranslations = makeTranslationPath(
  'myLeaseplan.damageReport.steps.selectDamageCause',
)

const selectCashClaimCategoriesTranslations = makeTranslationPath(
  'myLeaseplan.cashClaim.categories',
)

export const glassSuppliers: Suppliers = [
  {
    name: 'Carglass',
    phone: 'myLeaseplan.glassRepair.page.contact.carglassPhone',
    logo:
      '/static/images/my-leaseplan/glass-suppliers/carglass-supplier-logo.png',
    urls: [
      {
        websiteLabel: 'www.carglass.dk/partner/leaseplan',
        websiteUrl: 'https://www.carglass.dk/partner/leaseplan',
      },
    ],
  },
  {
    name: 'Dansk Bilglas',
    phone: 'myLeaseplan.glassRepair.page.contact.danskBilglasPhone',
    logo: '/static/images/my-leaseplan/glass-suppliers/dansk-bilglas.jpeg',
    urls: [
      {
        websiteLabel: 'www.bilglas.dk',
        websiteUrl: 'https://www.bilglas.dk/',
      },
    ],
  },
]

export const tyresSuppliers: Suppliers = [
  {
    logo:
      '/static/images/my-leaseplan/tyre-services-suppliers/lpdk-logo-euromaster.png',
    name: 'Euromaster',
    phone: 'myLeaseplan.tyres.tyreServices.seasonalTyreChange.euromasterPhone',
    urls: [
      {
        websiteLabel: 'www.euromaster.dk',
        websiteUrl: 'https://www.euromaster.dk/',
      },
    ],
  },
  {
    logo:
      '/static/images/my-leaseplan/tyre-services-suppliers/lpdk-logo-superdaek.png',
    name: 'Superdæk',
    urls: [
      {
        websiteLabel: 'www.sds.dk',
        websiteUrl: 'http://www.sds.dk/',
      },
    ],
  },
]

export const damageCauses = (_isMobileApp: boolean): Options => [
  {
    title: selectDamageCauseTranslations('oneParty.title'),
    subtitle: selectDamageCauseTranslations('oneParty.subtitle'),
    value: DamageCauses.ONE_PARTY,
  },
  {
    title: selectDamageCauseTranslations('twoParty.title'),
    value: DamageCauses.TWO_PARTY,
  },
  {
    title: selectDamageCauseTranslations('glassOnly.title'),
    subtitle: selectDamageCauseTranslations('glassChip.subtitle'),
    value: DamageCauses.GLASS_ONLY,
    to: 'myLeaseplan.glassRepair.base',
  },
]

export const CONTACT_US_PHONE_NUMBER =
  'myLeaseplan.fuelCard.steps.selectServiceType.lostStolenNew.workingHoursPhone'

export const partyDamages: PartyDamages = [
  data.otherOnePartyDamage,
  data.vandalismOnePartyDamage,
  data.burglaryOnePartyDamage,
  data.oneSidedCollisionOnePartyDamage,
  data.openedDoorTwoPartyDamage,
  data.otherTwoPartyDamage,
  data.twoSidedCollisionTwoPartyDamage,
  data.multipleCollisionTwoPartyDamage,
  data.lostEffectsTwoPartyDamage,
  data.changedLanesTwoPartyDamage,
  data.rightOfWayTwoPartyDamage,
]

export const categories: Options = [
  {
    title: selectCashClaimCategoriesTranslations('fuel.title'),
    value: CashClaimCategory.FUEL,
  },
  {
    title: selectCashClaimCategoriesTranslations('liquids.title'),
    subtitle: selectCashClaimCategoriesTranslations('liquids.subtitle'),
    value: CashClaimCategory.LIQUIDS,
  },
  {
    title: selectCashClaimCategoriesTranslations('toll.title'),
    value: CashClaimCategory.TOLL,
  },
  {
    title: selectCashClaimCategoriesTranslations('maintenance.title'),
    value: CashClaimCategory.MAINTENANCE,
  },
  {
    title: selectCashClaimCategoriesTranslations('tyres.title'),
    value: CashClaimCategory.TYRE_SERVICE,
  },
  {
    title: selectCashClaimCategoriesTranslations('roadSideAssistance.title'),
    subtitle: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subtitle',
    ),
    value: CashClaimCategory.ROAD_SIDE_ASSISTANCE,
  },
  {
    title: selectCashClaimCategoriesTranslations('damageRepair.title'),
    value: CashClaimCategory.DAMAGE_REPAIR,
  },
]

export const fuel: Options = [
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.benzin.title',
    ),
    value: FuelType.BENZIN,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.diesel.title',
    ),
    value: FuelType.DIESEL,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'fuel.subcategories.electric.title',
    ),
    value: FuelType.ELECTRIC,
  },
]

export const roadSideAssistance: Options = [
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.accommodation.title',
    ),
    value: RoadSideAssistanceType.ACCOMMODATION,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.publicTransport.title',
    ),
    value: RoadSideAssistanceType.PUBLIC_TRANSPORT,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.replacementVehicle.title',
    ),
    value: RoadSideAssistanceType.REPLACEMENT_VEHICLE,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.taxi.title',
    ),
    value: RoadSideAssistanceType.TAXI,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.transportationOfTheVehicle.title',
    ),
    value: RoadSideAssistanceType.TRANSPORTATION_VEHICLE,
  },
  {
    title: selectCashClaimCategoriesTranslations(
      'roadSideAssistance.subcategories.other.title',
    ),
    value: RoadSideAssistanceType.OTHER,
  },
]
