import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { Spacing } from '@leaseplan/ui'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { FlowProps } from 'mlp-client/src/components/myleaseplan-flow/types'
import { FlowData } from 'mlp-client/src/flows/damage-report/types'
import { StepLayout } from 'mlp-client/src/components/myleaseplan-flow/step-layout/StepLayout'
import TwoColumnFlowStep from 'mlp-client/src/components/two-column-flow-step/TwoColumnFlowStep'
import { NextStepButton } from 'mlp-client/src/flows/components/buttons'
import { TextAreaField } from 'mlp-client/src/form/components/fields/TextAreaFields'
import { required, timeIsValid } from 'mlp-client/src/form/validations'
import { DatePickerField } from 'mlp-client/src/form/components/fields/DatePickerFields'
import { LocalizationContext } from 'mlp-client/src/localization/LocalizationProvider'
import { getContractStartUnixTime } from 'mlp-client/src/contracts/selectors'
import TimeInputField from 'mlp-client/src/form/components/fields/time-input-field/TimeInputField'
import { getIsMobileApp } from 'mlp-client/src/auth/selectors'

export interface StepConfig {
  label?: string
}

export interface Props extends FlowProps<FlowData> {
  config: StepConfig
  contractStartUnixTime: number
  isMobileApp: boolean
}

export type FormData = Pick<FlowData, 'where' | 'date' | 'time'>

export class WhenAndWhereStep extends React.PureComponent<Props> {
  submitHandler = (formData: FormData) => {
    const { setFlowData, goToNextStep } = this.props

    setFlowData(formData, goToNextStep)
  }
  render() {
    const {
      flowData: { where, date, time },
      contractStartUnixTime,
      isMobileApp,
      config: { label },
    } = this.props

    return (
      <StepLayout>
        <TwoColumnFlowStep titleLabel="myLeaseplan.damageReport.steps.whenAndWhere.title">
          <Form
            onSubmit={this.submitHandler}
            initialValues={{ date: date || new Date(), where, time }}
            keepDirtyOnReinitialize
            render={({ handleSubmit, hasValidationErrors, values }) => {
              useEffect(() => {
                this.props.setFlowData(values)
              }, [values])

              return (
                <form onSubmit={handleSubmit}>
                  <TextAreaField
                    name="where"
                    label={{
                      id:
                        label ||
                        'myLeaseplan.damageReport.steps.whenAndWhere.whereLabel',
                    }}
                    legalNote={{
                      id: 'myLeaseplan.shared.openTextLegalNote',
                    }}
                    validations={[required]}
                  />
                  <Spacing mt={2} />
                  <LocalizationContext.Consumer>
                    {({ translate }) => (
                      <DatePickerField
                        name="date"
                        label={{
                          id:
                            'myLeaseplan.damageReport.steps.whenAndWhere.whenLabel',
                        }}
                        placeholder={{
                          id:
                            'myLeaseplan.genericNotifications.datePlaceholder',
                        }}
                        validations={[required]}
                        disabledDays={[
                          {
                            message: translate(
                              'myLeaseplan.validations.dateNotInFutureError',
                            ),
                            after: new Date(),
                          },
                          {
                            message: translate(
                              'myLeaseplan.validations.notEarlierThan',
                            ),
                            before: contractStartUnixTime
                              ? new Date(contractStartUnixTime)
                              : null,
                          },
                        ]}
                      />
                    )}
                  </LocalizationContext.Consumer>
                  <Spacing mt={2} />
                  <TimeInputField name="time" validations={[timeIsValid]} />
                  <Spacing mt={4} />
                  <NextStepButton
                    onClick={handleSubmit}
                    disabled={hasValidationErrors}
                  />
                  {
                    // @todo this is hotfix for mobile app, remove after layout fix
                    isMobileApp && <Spacing mt={8} />
                  }
                </form>
              )
            }}
          />
        </TwoColumnFlowStep>
      </StepLayout>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  contractStartUnixTime: getContractStartUnixTime,
  isMobileApp: getIsMobileApp,
})

export default connect(mapStateToProps)(WhenAndWhereStep)
