import { ModalCloseButton, Spacing } from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import { LocationDescriptorObject } from 'history'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Action, Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getLocalizedRoute } from 'mlp-client/src/localization/selectors'
import { getContractById } from 'mlp-client/src/contracts/selectors'
import { getLastKnownMileage } from 'mlp-client/src/contracts/utils'
import { getAllowedMileageBelowPrediction } from 'mlp-client/src/flows/predictive-maintenance/selectors'
import { isMileagePredictionValid } from 'mlp-client/src/flows/predictive-maintenance/utils'
import {
  FormValues,
  MileageUpdate,
} from 'mlp-client/src/components/mileage-update-modal/MileageUpdate'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { AppState } from 'mlp-client/src/types'
import { DeletePredictiveMaintenanceCaseRequest } from 'mlp-client/src/flows/predictive-maintenance/actions'
import { CancellationReasons } from 'mlp-client/src/flows/predictive-maintenance/types'
import { FormSubmission } from 'mlp-client/src/form/types'
import { PredictiveMileageModalShown } from 'mlp-client/src/contracts/actions'

import { ModalContentCenteredStyled } from './PredictiveMaintenanceUpdate.styled'

export interface Props {
  contractId: string
  lastKnownMileage: number
  predictedMileage: number
  redirectTo: string
  allowedMileageBelowPrediction: number
  caseId: string
  onShown(): void
  deleteCase(data: {
    caseId: string
    mileage: string
    onComplete(error: boolean): void
  }): void
}

const translatePath = makeTranslationPath(
  'myLeaseplan.predictiveMaintenance.updateMileage',
)

class PredictiveMaintenanceUpdate extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.onShown()
  }

  onSubmit: FormSubmission<FormValues>['onSubmit'] = (
    { mileage },
    _formApi,
    formOnComplete,
  ) => {
    const {
      predictedMileage,
      allowedMileageBelowPrediction,
      caseId,
      deleteCase,
    } = this.props

    if (
      !isMileagePredictionValid(
        predictedMileage,
        mileage,
        allowedMileageBelowPrediction,
      )
    ) {
      return deleteCase({
        caseId,
        mileage: String(mileage),
        onComplete: error => formOnComplete(error && { error: true }),
      })
    }

    formOnComplete()
  }

  getRedirectLocationDescriptor = (
    mileage: number,
  ): LocationDescriptorObject<{
    predictiveMileage: number
  }> => {
    const { redirectTo } = this.props

    return {
      pathname: redirectTo,
      search: `predictiveMileage=${mileage}`,
    }
  }

  renderMileageUpdateSuccess = (userReportedMileage: number): JSX.Element => {
    const { predictedMileage, allowedMileageBelowPrediction } = this.props

    return isMileagePredictionValid(
      predictedMileage,
      userReportedMileage,
      allowedMileageBelowPrediction,
    ) ? (
      <Redirect to={this.getRedirectLocationDescriptor(userReportedMileage)} />
    ) : (
      <>
        <div>
          <ModalContentCenteredStyled data-e2e-id="predictiveMileageUpdateThankYouMessage">
            <Headline variant="300" component="h3" withMarginBottom>
              <Translation id={translatePath('thankYou.title')} />
            </Headline>
            <Text component="p" variant="200">
              <Translation id={translatePath('thankYou.text')} />
            </Text>
          </ModalContentCenteredStyled>
          <Spacing mt={5}>
            <ModalCloseButton>
              <Translation id={translatePath('thankYou.nextButton')} />
            </ModalCloseButton>
          </Spacing>
        </div>
      </>
    )
  }

  render() {
    const { contractId, lastKnownMileage } = this.props

    return (
      <MileageUpdate
        contractId={contractId}
        lastKnownMileage={lastKnownMileage}
        labels={{
          formHeading: translatePath('update.title'),
          submitButton: translatePath('update.nextButton'),
          formText: null,
        }}
        onSubmit={this.onSubmit}
        renderSuccess={this.renderMileageUpdateSuccess}
      />
    )
  }
}

type ReduxProps = Pick<
  Props,
  'lastKnownMileage' | 'redirectTo' | 'allowedMileageBelowPrediction'
>
type DispatchProps = Pick<Props, 'onShown' | 'deleteCase'>
type OwnProps = Omit<Props, keyof (ReduxProps & DispatchProps)>

const mapStateToProps = (state: AppState, ownProps: OwnProps): ReduxProps => {
  const contract = getContractById(state)(ownProps.contractId)
  const allowedMileageBelowPrediction = getAllowedMileageBelowPrediction(state)

  return {
    lastKnownMileage: getLastKnownMileage(contract),
    redirectTo: getLocalizedRoute(state, 'myLeaseplan.maintenance.base', {
      prefix: 'predictive',
    }),
    allowedMileageBelowPrediction,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  onShown: () => dispatch(new PredictiveMileageModalShown()),
  deleteCase: ({ mileage, caseId, onComplete }) =>
    dispatch(
      new DeletePredictiveMaintenanceCaseRequest({
        cancellationReason: CancellationReasons.tooEarly,
        loadNotifications: false,
        mileage,
        caseId,
        onComplete,
      }),
    ),
})

export { PredictiveMaintenanceUpdate }
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PredictiveMaintenanceUpdate)
