import { Button } from '@leaseplan/ui'
import styled, { css } from 'styled-components'

export const MobileMapWrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: white;
  ${p =>
    !p.isVisible &&
    css`
      display: none;
    `};
`

export const BackToList = styled(Button)`
  position: absolute;
  top: 17px;
  left: 12px;
  z-index: 5;
`

export const SupplierDetailWrapper = styled.div<{
  isVisible: boolean
  cardHeight: string
}>`
  width: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  transition: height 500ms ease-in-out;
  height: ${p => (p.isVisible ? p.cardHeight : '0')};
`
