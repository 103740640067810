import { createSelector } from 'reselect'

import {
  getCountry,
  getTranslation,
} from 'mlp-client/src/localization/selectors'
import {
  canReturn,
  getEndDate,
  isFuelCardsAvailable,
} from 'mlp-client/src/contracts/selectors'
import { isContractExpired } from 'mlp-client/src/contracts/utils'
import { formatDate } from 'mlp-client/src/utils'
import {
  FuelCardIcon,
  ReturnIcon,
} from 'mlp-client/src/dashboard/components/icons'
import { TileProps } from 'mlp-client/src/dashboard/type'
import { getMyLeaseplanFeatureConfig } from 'mlp-client/src/features-configuration/config'
import {
  MyleaseplanConfig,
  MyLeaseplanFeatureName,
} from 'mlp-client/src/features-configuration/types'
import { isFuelCardAlwaysDisplayed } from 'mlp-client/src/dashboard/utils'
import { AppState } from 'mlp-client/src/types'

export const getConfigItem = (
  state: AppState,
  name: MyLeaseplanFeatureName,
): TileProps => ({
  name,
  title: getTranslation(state, `myLeaseplan.${name}.dashboard.title`),
  summary: getTranslation(state, `myLeaseplan.${name}.dashboard.summary`),
  to: `myLeaseplan.${name}.base`,
})

export const getEndOfLeaseTile = (state: AppState) => (
  config: MyleaseplanConfig,
): TileProps | undefined => {
  if (canReturn(state)(config)) {
    const endDate = getEndDate(state)
    const isExpired = isContractExpired(endDate)
    const formattedEndDate = endDate ? formatDate(endDate, 'LL') : ''
    const title = isExpired
      ? getTranslation(
          state,
          'myLeaseplan.return.steps.endLease.ended',
        ).replace('{date}', formattedEndDate)
      : getTranslation(state, 'myLeaseplan.return.dashboard.title').replace(
          '{endDate}',
          formattedEndDate,
        )

    return {
      title,
      summary: getTranslation(state, 'myLeaseplan.return.dashboard.summary'),
      to: 'myLeaseplan.endOfLease.base',
      icon: ReturnIcon,
      name: 'return',
    }
  }
}

export const getFuelCardTile = (state: AppState) => (
  config: MyleaseplanConfig,
): TileProps | undefined => {
  if (isFuelCardAlwaysDisplayed(config) || isFuelCardsAvailable(state)) {
    return { ...getConfigItem(state, 'fuelCard'), icon: FuelCardIcon }
  }
}

export const isDashboardConfigurable = createSelector(getCountry, country =>
  Boolean(getMyLeaseplanFeatureConfig(country, 'dashboard')),
)
