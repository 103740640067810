import { MyLeaseplanFeature } from 'mlp-client/src/features-configuration/types'
import EditProfile from 'mlp-client/src/profile/components/edit-profile/EditProfile'
import { EditProfileConfig } from 'mlp-client/src/profile/types'
import {
  maxLength,
  required,
  validEmail,
} from 'mlp-client/src/form/validations'

export const editProfileConfig: MyLeaseplanFeature<EditProfileConfig> = {
  enabled: true,
  view: EditProfile,
  configuration: {
    userFields: [
      {
        name: 'firstName',
        validations: [required, maxLength(40)],
        label: {
          id: 'myLeaseplan.shared.input.fullName.firstName.label',
        },
        placeholder: {
          id: 'myLeaseplan.shared.input.fullName.firstName.placeholder',
        },
        dataE2eId: 'firstName',
      },
      {
        name: 'lastName',
        validations: [required, maxLength(80)],
        label: {
          id: 'myLeaseplan.shared.input.fullName.lastName.label',
        },
        placeholder: {
          id: 'myLeaseplan.shared.input.fullName.lastName.placeholder',
        },
        dataE2eId: 'lastName',
      },
      {
        name: 'email',
        validations: [validEmail, required],
        label: { id: 'myLeaseplan.shared.input.email.label' },
        disabled: true,
        placeholder: {
          id: 'myLeaseplan.shared.input.email.placeholder',
        },
        dataE2eId: 'email',
      },
    ],
    addressFields: [
      {
        name: 'address.addressLine1',
        validations: [required, maxLength(255)],
        label: { id: 'myLeaseplan.shared.input.address.streetname.label' },
        placeholder: {
          id: 'myLeaseplan.shared.input.address.streetname.placeholder',
        },
        dataE2eId: 'street',
      },
      {
        name: 'address.houseNumber',
        validations: [required, maxLength(10)],
        label: { id: 'myLeaseplan.shared.input.address.housenumber.label' },
        placeholder: {
          id: 'myLeaseplan.shared.input.address.housenumber.placeholder',
        },
        wrapperProps: {
          span: { mobile: 6, tablet: 3 },
        },
        dataE2eId: 'houseNumber',
      },
      {
        name: 'address.houseNumberSuffix',
        validations: [maxLength(10)],
        label: { id: 'myLeaseplan.shared.input.address.addition.label' },
        placeholder: {
          id: 'myLeaseplan.shared.input.address.addition.placeholder',
        },
        dataE2eId: 'addition',
        wrapperProps: {
          span: { mobile: 6, tablet: 3 },
        },
      },
      {
        name: 'address.zipCode',
        validations: [required, maxLength(10)],
        label: { id: 'myLeaseplan.shared.input.address.zip.label' },
        placeholder: { id: 'myLeaseplan.shared.input.address.zip.placeholder' },
        dataE2eId: 'zipCode',
        wrapperProps: {
          span: { mobile: 12, tablet: 3 },
        },
      },
      {
        name: 'address.city',
        validations: [required, maxLength(255)],
        label: { id: 'myLeaseplan.shared.input.address.city.label' },
        placeholder: {
          id: 'myLeaseplan.shared.input.address.city.placeholder',
        },
        dataE2eId: 'city',
      },
    ],
    isCountryEnabled: true,
    isLanguageEnabled: true,
  },
}
