import {
  File,
  Files,
} from 'mlp-client/src/form/components/fields/file-upload-field/types'

export const addFile = (files: Files, file: File): Files => [...files, file]

export const removeFile = (files: Files, fileId: string): Files =>
  files.filter(file => file.id !== fileId)

export const getFileExtension = (fileName: string): string =>
  fileName.split('.').pop()
