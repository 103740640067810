export const enum ActionTypes {
  INIT = '@@my-leaseplan/INIT',
  INIT_FINISHED = '@@my-leaseplan/INIT_FINISHED',
}

export class Init {
  readonly type = ActionTypes.INIT

  constructor(
    readonly payload: {
      selectedContractId: string
    },
  ) {}
}

export class InitFinished {
  readonly type = ActionTypes.INIT_FINISHED
}

export type Actions = Init | InitFinished
