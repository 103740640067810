import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { TileProps } from 'mlp-client/src/dashboard/type'
import { BasicTile } from 'mlp-client/src/dashboard/components/tiles/BasicTile'
import { MyLeaseplanContext } from 'mlp-client/src/components/my-leaseplan-app/context/MyLeaseplanContext'
import { isFuelCardEnabled } from 'mlp-client/src/flows/fuel-card/selectors'

export const FuelCardTile: React.FC<TileProps> = props => {
  const isEnabled = useSelector(isFuelCardEnabled)
  const context = useContext(MyLeaseplanContext)

  if (!isEnabled(context)) {
    return null
  }

  return <BasicTile {...props} />
}
