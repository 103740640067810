import { IconProps } from '@leaseplan/ui'
import React from 'react'

import { defaultIconProps } from 'mlp-client/src/dashboard/components/icons/options'
import { DefaultIconProps } from 'mlp-client/src/dashboard/type'
import { ColorEnumSelector } from 'mlp-client/src/VelocitySelector'

class MileageIcon extends React.PureComponent<IconProps> {
  static defaultProps: DefaultIconProps = defaultIconProps

  render() {
    const { height, width, color } = this.props

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={ColorEnumSelector(color)}
          fillRule="evenodd"
          d="M59 33a26.8 26.8 0 0 0-2.5-12.2l-14.2 8.4a1.5 1.5 0 0 1-2-.5A9.7 9.7 0 0 0 32 24h-.8l4 7a3.6 3.6 0 0 1-3 5.7l-1.2-.3a3.6 3.6 0 0 1-2.3-2.4L26 26a9.6 9.6 0 0 0-2.3 2.7l-.2.2v.1a1.5 1.5 0 0 1-.3.2 1.4 1.4 0 0 1-.4.1 1.5 1.5 0 0 1-.3 0h-.1a1.4 1.4 0 0 1-.3 0 1.5 1.5 0 0 1-.4-.1L7.5 20.8A26.8 26.8 0 0 0 5.1 32 27 27 0 0 0 29 58.8H29.6l1.4.2h.5a27.2 27.2 0 0 0 2.3 0l.6-.1a26.7 26.7 0 0 0 2-.2l.5-.2 1.4-.2.8-.2 1.2-.4a26.6 26.6 0 0 0 2.6-1 27 27 0 0 0 16-23.7zm-26.3-.4l-3.1-5.4 2 5.9a.6.6 0 0 0 .4.5.6.6 0 0 0 .7-1zM32 5A27 27 0 0 0 9 18L22 26a12.6 12.6 0 0 1 2.3-2.3l.7-.5-3.3-9.7a1.5 1.5 0 0 1 2.7-1.2l5.2 9 .7-.1L32 21c4 0 7.6 1.9 10 5l13.1-7.8A27 27 0 0 0 32.1 5zm0-3a30 30 0 0 1 30 30 30.4 30.4 0 0 1-.1 2.8 30 30 0 0 1-20.1 25.5h-.3c-.7.3-1.3.5-2 .6l-.5.2-1.7.3-.5.1a30.7 30.7 0 0 1-4.8.4 31.3 31.3 0 0 1-1.4 0h-.8l-.7-.1-.4-.1H28A30 30 0 0 1 2 32a29.8 29.8 0 0 1 3.4-13.9l.1-.2A30 30 0 0 1 32 2zm8.5 43.8a1.5 1.5 0 1 1 0 3h-17a1.5 1.5 0 1 1 0-3h17z"
        />
      </svg>
    )
  }
}

export default MileageIcon
