import { Card, Grid, GridItem, Spacing } from '@leaseplan/ui'
import { Headline } from '@velocity/ui'
import React from 'react'

import Translation from 'mlp-client/src/localization/Translation'
import { SelectField } from 'mlp-client/src/form/components/fields/SelectFields'
import { TextField } from 'mlp-client/src/form/components/fields/TextFields'
import { Options } from 'mlp-client/src/form/types'
import { required } from 'mlp-client/src/form/validations'

export interface FormValues {
  street?: string
  houseNumber?: string
  zipCode?: string
  city?: string
  country?: string
}

export interface Props {
  countries?: Options
  cities?: Options
  hideCountry?: boolean
  hideHouseNumber?: boolean
}

export class OtherLocationFieldgroup extends React.PureComponent<Props> {
  render() {
    const { cities, hideHouseNumber, hideCountry, countries } = this.props

    return (
      <Spacing mb={2}>
        <Spacing mb={1}>
          <Headline variant="100" withMarginBottom>
            <Translation id="myLeaseplan.newSetup.shared.forms.location.otherDescription" />
          </Headline>
        </Spacing>
        <Card>
          <Spacing p={2}>
            <Grid>
              <GridItem span="grow" data-e2e-id="street">
                <TextField
                  name="street"
                  validations={[required]}
                  label={{
                    id: 'myLeaseplan.shared.input.address.streetname.label',
                  }}
                />
              </GridItem>
              {!hideHouseNumber && (
                <GridItem span={4} data-e2e-id="houseNumber">
                  <TextField
                    name="houseNumber"
                    validations={[required]}
                    label={{
                      id: 'myLeaseplan.shared.input.address.housenumber.label',
                    }}
                  />
                </GridItem>
              )}
            </Grid>
            <Grid>
              <GridItem
                span={{ mobile: 12, tablet: 6 }}
                data-e2e-id="postalCode"
              >
                <TextField
                  name="zipCode"
                  validations={[required]}
                  label={{
                    id: 'myLeaseplan.shared.input.address.zip.label',
                  }}
                />
              </GridItem>
              <GridItem span={{ mobile: 12, tablet: 6 }} data-e2e-id="city">
                {cities?.length > 0 ? (
                  <SelectField
                    name="city"
                    label={{
                      id: 'myLeaseplan.shared.input.address.city.label',
                    }}
                    validations={[required]}
                    options={cities}
                    placeholder={{
                      id: 'myLeaseplan.shared.input.address.city.label',
                    }}
                  />
                ) : (
                  <TextField
                    name="city"
                    validations={[required]}
                    label={{
                      id: 'myLeaseplan.shared.input.address.city.label',
                    }}
                  />
                )}
              </GridItem>
            </Grid>
            <Grid className={hideCountry && 'u-hidden'}>
              <GridItem span={12} data-e2e-id="country">
                {countries && (
                  <SelectField
                    name="country"
                    validations={!hideCountry ? [required] : []}
                    options={countries}
                    label={{
                      id: 'myLeaseplan.shared.input.address.country.label',
                    }}
                  />
                )}
              </GridItem>
            </Grid>
          </Spacing>
        </Card>
      </Spacing>
    )
  }
}

export default OtherLocationFieldgroup
