import { Color, GridItem } from '@leaseplan/ui'
import { Text } from '@velocity/ui'
import styled from 'styled-components'

export const SupplierDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  background-color: white;
`

export const DistanceWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  min-height: 23px;
  background-color: ${Color.petrolBlue};
`

export const SupplierName = styled(Text)`
  margin-bottom: 10px;
  line-height: 1.5em;
  color: ${Color.petrolBlue}; ;
`

export const Address = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  color: ${Color.thunder};
`

export const SupplierWrapper = styled(GridItem)`
  width: 90%;
  overflow-y: auto;
`

export const IconWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 18px;
  transform: translate(-50%, -50%);
  cursor: pointer;
`
