import { Locales } from 'mlp-client/src/localization/enums'

import en_dk from './en-dk/routes.json'
import da_dk from './da-dk/routes.json'
import de_de from './de-de/routes.json'
import en_de from './en-de/routes.json'
import en_fr from './en-fr/routes.json'
import fr_fr from './fr-fr/routes.json'
import en_lu from './en-lu/routes.json'
import fr_lu from './fr-lu/routes.json'
import en_lp from './en-lp/routes.json'

export default {
  [Locales.EN_DK]: en_dk,
  [Locales.DA_DK]: da_dk,
  [Locales.DE]: de_de,
  [Locales.EN_DE]: en_de,
  [Locales.EN_FR]: en_fr,
  [Locales.FR]: fr_fr,
  [Locales.EN_LU]: en_lu,
  [Locales.FR_LU]: fr_lu,
  [Locales.EN_LP]: en_lp,
}
