import {
  BreakpointName,
  Checkbox,
  CheckboxList,
  Collapse,
  CollapseContent,
  CollapseTrigger,
  Paper,
  Spacing,
  withBreakpoint,
} from '@leaseplan/ui'
import { colorMixins, Headline } from '@velocity/ui'
import { FiltersIcon } from '@velocity/ui/draft'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Translation from 'mlp-client/src/localization/Translation'
import VehicleModelLicenseLabel from 'mlp-client/src/components/vehicle-model-license/VehicleModelLicenseLabel'
import { getLeaseContracts } from 'mlp-client/src/contracts/selectors'
import { Contract } from 'mlp-client/src/contracts/types'
import {
  getLicensePlate,
  getShortMakeModel,
} from 'mlp-client/src/contracts/utils'
import { SelectedVehicles } from 'mlp-client/src/timeline/types'
import { AppState } from 'mlp-client/src/types'
import { isDesktopBreakpoint } from 'mlp-client/src/utils'
import { isFuelManagement } from 'mlp-client/src/features-configuration/lu/utils'

export interface Props {
  contracts: readonly Contract[]
  onChange(contractId: string): void
  selectedVehicles: SelectedVehicles
  breakpoint: BreakpointName
}

const FilterIconStyled = styled(FiltersIcon)`
  vertical-align: bottom;
  color: ${colorMixins.grayMainColor};
`

class VehicleSelector extends React.PureComponent<Props> {
  handleChange = ({ value }: { value: string }) => {
    const { onChange } = this.props

    onChange(value)
  }

  renderForm = () => {
    const { contracts, selectedVehicles } = this.props

    return (
      <CheckboxList>
        {contracts.map((contract: Contract) => (
          <Checkbox
            key={contract.id}
            value={contract.id}
            checked={selectedVehicles.includes(contract.id)}
            onChange={this.handleChange}
            label={
              <VehicleModelLicenseLabel
                makeModel={getShortMakeModel(contract)}
                licensePlate={getLicensePlate(contract)}
                isFuelManagement={isFuelManagement(contract.type)}
              />
            }
          />
        ))}
      </CheckboxList>
    )
  }

  render() {
    if (isDesktopBreakpoint(this.props.breakpoint)) {
      return (
        <Paper data-e2e-id="timelineDesktopVehicleSelector">
          <Spacing p={2}>
            <Headline variant="100" withMarginBottom>
              <Translation id="myLeaseplan.timeline.filter.showEventsFor" />
            </Headline>
            {this.renderForm()}
          </Spacing>
        </Paper>
      )
    } else {
      return (
        <Spacing mt={2}>
          <Paper data-e2e-id="timelineMobileVehicleSelector">
            <Spacing p={2}>
              <Collapse>
                <CollapseTrigger>
                  <Headline variant="100" withMarginBottom>
                    <FilterIconStyled size="s" />
                    <Spacing component="span" ml={1}>
                      <Translation id="myLeaseplan.timeline.filter.filter" />
                    </Spacing>
                  </Headline>
                </CollapseTrigger>
                <CollapseContent>
                  <Spacing mt={2}>{this.renderForm()}</Spacing>
                </CollapseContent>
              </Collapse>
            </Spacing>
          </Paper>
        </Spacing>
      )
    }
  }
}

type ReduxProps = Pick<Props, 'contracts'>

const mapStateToProps = (state: AppState): ReduxProps => ({
  contracts: getLeaseContracts(state),
})

export { VehicleSelector }
export default withBreakpoint(connect(mapStateToProps)(VehicleSelector))
