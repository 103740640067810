import React from 'react'

interface DefaultProps {
  title: string
  color: string
  width: number
  height: number
}

interface NonDefaultProps {
  fill?: string
  className?: string
}

type Props = Partial<DefaultProps> & NonDefaultProps

export default class ChevronLeftIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    title: 'Arrow left',
    color: '#2D2D2D',
    width: 9,
    height: 14,
  }

  render() {
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        className={this.props.className}
        viewBox="23 23 9 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{this.props.title}</title>
        <polygon
          stroke="none"
          fill={this.props.color}
          fillRule="evenodd"
          transform="translate(27.500000, 30.023810) rotate(-360.000000) translate(-27.500000, -30.023810)"
          points="30.2470786 23.5238095 23.4761905 30.0238095 30.2470786 36.5238095 31.5238095 35.2981578 26.0296523 30.0238095 31.5238095 24.7494613"
        />
      </svg>
    )
  }
}
