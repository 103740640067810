import {
  Modal,
  Spacing,
  ButtonChevronRightIcon,
  ButtonLink,
  Button,
} from '@leaseplan/ui'
import { Headline, Text } from '@velocity/ui'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Translation from 'mlp-client/src/localization/Translation'
import MileageUpdate from 'mlp-client/src/components/mileage-update-modal/MileageUpdate'
import { makeTranslationPath } from 'mlp-client/src/utils'
import { ModalContentCenteredStyled } from 'mlp-client/src/flows/predictive-maintenance/components/predictive-maintenance-update/PredictiveMaintenanceUpdate.styled'
import { ResetNotifications } from 'mlp-client/src/contracts/actions'
import { NotificationStatus } from 'mlp-client/src/contracts/enums'

export interface Props {
  contractId: string
  lastKnownMileage: number
  onResetNotification(): void
}

interface State {
  showMileageModal: boolean
}

const translatePath = makeTranslationPath(
  'myLeaseplan.predictiveMaintenance.updateMileage',
)

class PredictiveMileageUpdate extends React.PureComponent<Props, State> {
  state = {
    showMileageModal: false,
  }

  handleModalClose = () => {
    const notificationStatus = sessionStorage.getItem(
      `notification_status_${this.props.contractId}`,
    )

    if (notificationStatus === NotificationStatus.DELETED) {
      return this.props.onResetNotification()
    }

    this.setState({ showMileageModal: false })
  }

  handleModalOpen = () => this.setState({ showMileageModal: true })

  renderMileageUpdateSuccess = (): JSX.Element => (
    <div>
      <ModalContentCenteredStyled data-e2e-id="predictiveMileageUpdateThankYouMessage">
        <Headline variant="300" component="h3" withMarginBottom>
          <Translation id={translatePath('thankYou.title')} />
        </Headline>
        <Text variant="200" component="p">
          <Translation id={translatePath('thankYou.text')} />
        </Text>
      </ModalContentCenteredStyled>
      <Spacing mt={5}>
        <Button
          onClick={() => this.props.onResetNotification()}
          variant="secondary"
          data-e2e-id="notificationsResetButton"
        >
          <Translation id={translatePath('thankYou.nextButton')} />
        </Button>
      </Spacing>
    </div>
  )

  render() {
    const { contractId, lastKnownMileage } = this.props
    const { showMileageModal } = this.state

    return (
      <>
        <Modal
          open={showMileageModal}
          onClose={this.handleModalClose}
          data-e2e-id="notificationTilePredictiveMileagePopup"
        >
          <MileageUpdate
            contractId={contractId}
            lastKnownMileage={lastKnownMileage}
            labels={{
              formHeading: translatePath('update.title'),
              submitButton: translatePath('update.nextButton'),
              formText: null,
            }}
            renderSuccess={this.renderMileageUpdateSuccess}
          />
        </Modal>
        <ButtonLink
          size="s"
          icon={ButtonChevronRightIcon}
          iconPosition="right"
          fontWeight="regular"
          onClick={this.handleModalOpen}
          data-e2e-id="notificationTileViewDetailsButton"
        >
          <Translation id="myLeaseplan.notifications.mileage.viewDetails" />
        </ButtonLink>
      </>
    )
  }
}

type DispatchProps = Pick<Props, 'onResetNotification'>

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onResetNotification: () => dispatch(new ResetNotifications()),
})

export { PredictiveMileageUpdate }
export default connect(null, mapDispatchToProps)(PredictiveMileageUpdate)
