import { SharedMyLeaseplanConfig } from 'mlp-client/src/features-configuration/types'
import {
  CountryCode,
  LanguageCode,
  Locales,
} from 'mlp-client/src/localization/enums'

type PartialDeep<T> = { [P in keyof T]?: PartialDeep<T[P]> }

interface Features {
  myLeasePlan: SharedMyLeaseplanConfig
}

export type Feature = keyof Features

export interface Language {
  preferred?: boolean
  label: string
  code: LanguageCode
  locale: Locales
  url?: string
  features?: PartialDeep<Features>

  /** Hide the label from locale picker */
  hidden?: boolean
}

export enum MileageUnit {
  kilometer = 'km',
  mile = 'mi',
  scandinavianMile = 'mil',
}

export interface Formatting {
  delimiters: {
    thousands: string
    decimal: string
  }
  licensePlate: {
    skipCharacters: number
  }
  currency: {
    symbolSuffix: boolean
    symbolPrefix: boolean
    priceSuffix: boolean
  }
  addressDirection: 'ltr' | 'rtl'
  secondAddressLineDirection: 'ltr' | 'rtl'
  mileageUnit: MileageUnit
}

export interface CountryDefaults {
  visible: boolean
  minimalFooter: boolean
  features: Features
  formatting: Formatting
  enableLanguageSelector: boolean
}

export type CountrySpecific = PartialDeep<CountryDefaults> & {
  label: string
  code: CountryCode
  timezone?: string
  tld: string
  languages: readonly Language[]
  pardotTracking?: {
    piAId: string
    piCId: string
  }
  noContractAllowed: boolean
}

export type Country = CountryDefaults & CountrySpecific
