export const enum ActionType {
  FLOW_END = '@@shared/flow/FLOW_END',
  REGISTER_FLOW = '@@shared/flow/REGISTER_FLOW',
}

/**
 * These actions are only dispatched to support previous Mobile App versions
 * We'll remove it as soon as we stop supporting the old versions
 */

export class RegisterFlow {
  readonly type = ActionType.REGISTER_FLOW
  payload = {
    flow: { name: '__FlowName__' },
  }
}

export class FlowEnd {
  readonly type = ActionType.FLOW_END
  payload = {
    flow: '__FlowName__',
  }
}
