import React from 'react'

import SupplierItem from 'mlp-client/src/flows/components/pages/supplier-map-page/supplier-item/SupplierItem'
import { Supplier, Suppliers } from 'mlp-client/src/types'

import { SuppliersListWrapper } from './SuppliersList.styled'

export interface Props {
  suppliers: Suppliers
  selectedSupplier: Supplier | null
  onSupplierClick(supplierId: string): () => void
}

export class SuppliersList extends React.PureComponent<Props> {
  render() {
    const { selectedSupplier, suppliers, onSupplierClick } = this.props

    return (
      <SuppliersListWrapper>
        {suppliers.map(supplier => (
          <SupplierItem
            key={supplier.id}
            supplier={supplier}
            onClick={onSupplierClick(supplier.id)}
            highlighted={
              selectedSupplier && selectedSupplier.id === supplier.id
            }
          />
        ))}
      </SuppliersListWrapper>
    )
  }
}
