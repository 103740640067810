import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { loadContentSaga } from 'mlp-client/src/content/sagas'
import { LoadContent } from 'mlp-client/src/content/actions'

import {
  ActionTypes,
  LoadPageContent,
  LoadPageContentFailed,
  LoadPageContentSuccess,
} from './actions'

export function* loadContentPageSaga(action: LoadPageContent): SagaIterator {
  try {
    yield call(loadContentSaga, new LoadContent(action.payload))

    yield put(new LoadPageContentSuccess())
  } catch {
    yield put(new LoadPageContentFailed())
  }
}

export default [takeLatest(ActionTypes.LOAD_PAGE_CONTENT, loadContentPageSaga)]
