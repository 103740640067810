import { mq } from '@leaseplan/ui'
import styled from 'styled-components'

export interface MainPaddingsProps {
  isMobileApp: boolean
}

/*
 * To avoid footer logo from overlapping on content
 * And to manage spacing w/o mobile app
 */
export const MainPaddings = styled.div<MainPaddingsProps>`
  ${p =>
    !p.isMobileApp &&
    mq.from('tablet')`
      padding-top: 48px;
      padding-bottom: 48px;
  `}
  ${mq.until('tablet')`
      padding-top: 24px;
      padding-bottom: 24px;
  `}

  ${mq.from('tablet')`
    margin-bottom: 96px
  `};
`
