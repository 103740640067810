import React from 'react'
import { connect } from 'react-redux'

import Translation from 'mlp-client/src/localization/Translation'
import { getBaseOrderInfo } from 'mlp-client/src/order-status/utils'
import { BaseOrderInfo } from 'mlp-client/src/order-status/types'
import { AppState } from 'mlp-client/src/types'
import { getOrderById } from 'mlp-client/src/order-status/selectors'

export interface Props {
  baseOrderInfo: BaseOrderInfo
  children?: React.ReactNode
  orderId?: string
}

class ConfirmedByDealer extends React.PureComponent<Props> {
  render() {
    const expectedDelivery = this.props.baseOrderInfo?.expectedDelivery

    if (expectedDelivery) {
      return (
        <Translation
          replace={{ expectedDelivery }}
          id="myLeaseplan.genericNotifications.statusCarService.confirmedByDealerInfo.subtitleWithDeliveryDate"
        />
      )
    }

    return (
      <Translation id="myLeaseplan.genericNotifications.statusCarService.confirmedByDealerInfo.subtitle" />
    )
  }
}

type ReduxProps = Pick<Props, 'baseOrderInfo'>
type OwnProps = Omit<Props, keyof ReduxProps>

export const mapStateToProps = (
  state: AppState,
  props: OwnProps,
): ReduxProps => ({
  baseOrderInfo: getBaseOrderInfo(getOrderById(state)(props.orderId)),
})

export { ConfirmedByDealer }
export default connect(mapStateToProps)(ConfirmedByDealer)
