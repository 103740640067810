import { TimelineItem } from 'mlp-client/src/timeline/types'

export const enum ActionTypes {
  SELECT_VEHICLE = '@@my-timeline/SELECT_VEHICLE',
  LOAD_TIMELINE = '@@my-timeline/LOAD_TIMELINE',
  LOADING_TIMELINE = '@@my-timeline/LOADING_TIMELINE',
  LOAD_TIMELINE_SUCCESS = '@@my-timeline/LOAD_TIMELINE_SUCCESS',
  LOAD_TIMELINE_FAILED = '@@my-timeline/LOAD_TIMELINE_FAILED',
  CHANGE_PAGINATION_PAGE = '@@my-timeline/CHANGE_PAGINATION_PAGE',
  CHANGE_PAGINATION_ITEMS_PER_PAGE = '@@my-timeline/CHANGE_PAGINATION_ITEMS_PER_PAGE',
  SET_PAGINATION_TOTAL_COUNT = '@@my-timeline/SET_PAGINATION_TOTAL_COUNT',
}

export class SelectVehicle {
  readonly type = ActionTypes.SELECT_VEHICLE
  constructor(readonly selectedContractId: string) {}
}

export class LoadTimeLine {
  readonly type = ActionTypes.LOAD_TIMELINE
}

export class LoadingTimeLine {
  readonly type = ActionTypes.LOADING_TIMELINE
}

export class LoadTimeLineSuccess {
  readonly type = ActionTypes.LOAD_TIMELINE_SUCCESS
  constructor(readonly timeline: readonly TimelineItem[]) {}
}

export class LoadTimeLineFailed {
  readonly type = ActionTypes.LOAD_TIMELINE_FAILED
}

export class ChangePaginationPage {
  readonly type = ActionTypes.CHANGE_PAGINATION_PAGE
  constructor(readonly page: number) {}
}

export class ChangePaginationItemsPerPage {
  readonly type = ActionTypes.CHANGE_PAGINATION_ITEMS_PER_PAGE
  constructor(readonly itemsPerPage: number) {}
}

export class SetPaginationTotalCount {
  readonly type = ActionTypes.SET_PAGINATION_TOTAL_COUNT
  constructor(readonly totalCount: number) {}
}

export type Action =
  | LoadTimeLine
  | LoadingTimeLine
  | LoadTimeLineSuccess
  | LoadTimeLineFailed
  | SelectVehicle
  | ChangePaginationItemsPerPage
  | ChangePaginationPage
  | SetPaginationTotalCount
